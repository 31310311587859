import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private adalService: AdalService) {}
  authInit(config) {
    // Initiate Adal auth with required config
    this.adalService.init(config);
  }
  logOut() {
    this.adalService.logOut();
  }
  login() {
    // Check if the user is authenticated. If not, call the login() method
    this.adalService.handleWindowCallback();
    if (!this.adalService.userInfo.authenticated) {
      this.adalService.login();
    }
    return true;
  }

  getUserInfo() {
    // returns profile and user name as observables
    return this.adalService.getUser();
  }
}
