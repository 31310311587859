import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
import * as _ from "lodash"
import { Globals } from '../../globals';
@Injectable({
  providedIn: 'root'
})
export class PackageCourseListService {
  config: Ienvironment;

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private globals: Globals
  ) {
    this.config = this._envSvc.getEnvironment();
  }

  getCourseIdsOfPackageOld(packageList) {
    if (packageList.length > 0) {
      packageList.forEach(packageData => {
        if (
          (null == packageData.accountId && null == packageData.projectId) ||
          (packageData.accountId === undefined &&
            packageData.projectId === undefined)
        ) {
          this.http
            .get<any>(
              this.config.apiUrl + 'Courses/Package/' + packageData.packageId
            )
            .subscribe(data => {
              if (
                data !== null &&
                data !== undefined &&
                data !==  _.isEmpty({}) &&
                data.length > 0
              ) {
                data.forEach(courseId => {
                  this.globals.packageCourseList.push(courseId.toString());
                  this.globals.coursesPackageMapping[courseId.toString()] =
                    packageData.packageId;
                });
              }
            });
        } else {
          let Url = '';
          let payload = {
            AccountOrProjectId: '',
            PackageId: packageData.packageId
          };
          if (null != packageData.projectId && '' != packageData.projectId) {
            //project
            Url = this.config.apiUrl + 'Course/Project/NonYorbitPackage';
            payload.AccountOrProjectId =
              packageData.projectId != null
                ? packageData.projectId.trim()
                : null;
          } else {
            //account
            Url = this.config.apiUrl + 'Course/Account/NonYorbitPackage';
            payload.AccountOrProjectId =
              packageData.accountId != null
                ? packageData.accountId.trim()
                : null;
          }
          this.http.post<any>(Url, payload).subscribe(data => {
            if (
              data !== null &&
              data !== undefined &&
              data !== _.isEmpty({}) &&
              data.length > 0
            ) {
              data.forEach(courseId => {
                this.globals.packageCourseList.push(courseId);
                this.globals.coursesPackageMapping[courseId.toString()] =
                  packageData.packageId;
              });
            }
          });
        }
      });
    }
  }

  getCourseIdsOfPackage(packageData) {
    if (packageData) {
      if (
        (null == packageData.accountId && null == packageData.projectId) ||
        (packageData.accountId === undefined &&
          packageData.projectId === undefined)
      ) {
        return this.http
          .get<any>(
            this.config.apiUrl + 'Courses/Package/' + packageData.packageId
          )
          .toPromise();
      } else {
        let Url = '';
        let payload = {
          AccountOrProjectId: '',
          PackageId: packageData.packageId
        };
        if (null != packageData.projectId && '' != packageData.projectId) {
          //project
          Url = this.config.apiUrl + 'Course/Project/NonYorbitPackage';
          payload.AccountOrProjectId =
            packageData.projectId != null ? packageData.projectId.trim() : null;
        } else {
          //account
          Url = this.config.apiUrl + 'Course/Account/NonYorbitPackage';
          payload.AccountOrProjectId =
            packageData.accountId != null ? packageData.accountId.trim() : null;
        }
        return this.http.post<any>(Url, payload).toPromise();
      }
    }
  }
}
