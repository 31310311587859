import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { LearningPathService } from '../../learning-path/learning-path.service';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../user-details/store';
import * as fromCloudlabStore from './store';

import { AddedContentDataService } from '../services/added-content-data.service';
import { inject } from 'adal-angular';

@Injectable({
  providedIn: 'root'
})
export class CloudlabStoreService {
  config: Ienvironment;
  routeState: any;
  courseIdList: any;
  cloudLab: any;
  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private _lpSvc: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private cloudlabStore: Store<fromCloudlabStore.IcloudlabState>,
    private addedContentDataService: AddedContentDataService
  ) {
    this.config = this._envSvc.getEnvironment();
    this.routeState = [];
    this.courseIdList = [];
    this.cloudLab = [];
  }

  getCloudLabDetails(payload): Observable<any> {
    return this.http
      .post(this.config.apiUrl + 'Course/GetLabDetails/lab', payload)
      .pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
      );
  }

  getCourseIds() {
    const userDefinedPaths = this._lpSvc.getUserCreatedLPs();
    const systemDefinedPaths = this._lpSvc.getSystemGeneratedLPs();
    const skillPaths = this._lpSvc.getSkillPaths();

    if (userDefinedPaths) {
      userDefinedPaths.forEach(lp => {
        this.getId(lp);
      });
    }
    if (systemDefinedPaths) {
      systemDefinedPaths.forEach(lp => {
        this.getId(lp);
      });
    }
    if (skillPaths) {
      skillPaths.forEach(lp => {
        this.getId(lp);
      });
    }
    this.fetchLabsInBatch(this.courseIdList);
  }

  getId(lp) {
    if (lp.PackageList.length > 0) {
      lp.PackageList.forEach((item, index) => {
        if (item.ItemType.toLowerCase() == 'course') {
          if (this.courseIdList.indexOf(item.ItemId) == -1) {
            this.courseIdList.push(item.ItemId);
          }
        } else if (
          item.ItemType.toLowerCase() == 'package' ||
          item.ItemType.toLowerCase() == 'family package'
        ) {
          this.addedContentDataService.contentAddedDetailsForPackagesLoaded.subscribe(
            isLoaded => {
              if (isLoaded) {
                // //console.log(
                //   'packagescourses loaded cloud lab comp',
                //   item.ItemId
                // );
                if (
                  this.addedContentDataService.packageCourseMappings[
                  item.ItemId
                  ]
                ) {
                  let coursesList = this.addedContentDataService.packageCourseMappings[
                    item.ItemId
                  ];
                  if (coursesList && coursesList.length > 0) {
                    coursesList.forEach((id, index) => {
                      if (this.courseIdList.indexOf(id) == -1) {
                        this.courseIdList.push(id);
                      }
                    })
                  }
                } else {

                }
              }
            }
          );
        }
      });
    }
  }

  fetchLabsInBatch(courseIdList) {
    let sub = this.cloudlabStore.select(fromCloudlabStore.getCloudlabListSelector).subscribe(data => {
      if (data.length > 0) {
        let payload = []
        if (courseIdList.length > 0) {
          courseIdList.forEach((id, index) => {
            let existInStore = data.find(x => x.CourseId.toString() == id.toString());
            if (!existInStore) {
              payload.push(id);
            }
          })
          if (payload.length > 0) {
            this.cloudlabStore.dispatch(new fromCloudlabStore.CloudlabGetStatus(payload));
          }
        }
      }      
    });
    if (sub) {
      sub.unsubscribe();
    }
  }
}
