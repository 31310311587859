import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PssEvaluationService } from './../pss-evaluation.service';
import { SystemService } from '../../../shared/services/system.service';

@Component({
  selector: 'yorbit-download-popup',
  templateUrl: './download-popup.component.html',
  styleUrls: ['./download-popup.component.scss']
})
export class DownloadPopup implements OnInit {

  requestId: string | number;
  evaluationType:string;
  requestUrls: any = [];
  urls = [];
  interval: any;
  popupHeading: string = "";
  constructor(private _PssEvaluationService: PssEvaluationService,
    public dialogRef: MatDialogRef<DownloadPopup>,private _systemSvc :SystemService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(this.data[1].includes('/scorecard/'))
      this.popupHeading = 'Scorecard Download';
    else
      this.popupHeading = 'Solution Download';
    this.urls = [];
    ////console.log(this.data, 'to bind to requestURL chkbox');
  }

  selectURL(event: any , url: string) {
    ////console.log('event', event);
    if (event.target.checked) {
      const idx = this.urls.indexOf(url);
      if (idx === -1) {
        this.urls.push(url);
      }
    } else {
      const idx = this.urls.indexOf(url);
      if (idx > -1) {
        this.urls.splice(idx, 1);
      }
    }
    ////console.log('selected', this.urls);
  }

  Download() {
    if (this.urls.length > 0) {
      for (let i = 0; i < this.urls.length; i++) {
        if(this.urls[i]!=null && this.urls[i]!='')
        {
          this._systemSvc.getBlobSasKey(this.urls[i])
          .then((response:any)=>{
            try{
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri,'_blank');
            }
            catch{}
          });
        }
        //window.open(this.urls[i],"_blank");
      }
    }
    this.dialogRef.close();
  }

  closePopup() {
    this.dialogRef.close();
  }
}
