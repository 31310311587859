<div class="marqueeHolder">
  <div class="marquee">
    <div *ngFor="let learner of filteredLearnerList;let learnerIndex=index" >
      <div class="congratsMsg" *ngIf="0===learnerIndex%4">
        <div class="redText">Congratulations!</div>
        <div class="regularText">to the</div>
        <div class="boldText">Coaches of the Digital Reset Program!</div>
        <!-- <div class="regularText">Program!</div> -->
      </div>

      <yorbit-recognition-profile [learner]=learner [showProgramName]="false"></yorbit-recognition-profile>
    </div>
  </div>
</div>
