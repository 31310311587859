<div class="parentDiv">
    <div fxLayout="column">
        <div [style.background-color]="title == 'TopCoach' ? bgColor : 'white'">
            <div class="nameAndMid" [ngClass]="{withoutHour: data.TotalHours == 0}" fxLayout="column">
                <div class="facilitator-name">
                    {{data.Name}}
                </div>
                <div class="facilitator-mid">
                    ({{data.MID}})
                </div>
            </div>
            <div *ngIf='data.TotalHours > 0' class="hoursDiv" fxLayout="row">
                <div class="hours-value">
                    {{data.TotalHours}}
                </div>
                <div class="hours-text">
                    Total hours spent in coaching
                </div>
            </div>
        </div>
        <div class="citation-text">
            {{data.Citation}}
        </div>
    </div>
    <div *ngIf="title == 'TopFacilitator'" class="bottom-border" [style.background-color]="bgColor">
    </div>
</div>