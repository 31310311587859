import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ManagerFeedbackComponent } from './manager-feedback/manager-feedback.component';
import { CourseFeedbackComponent } from './course-feedback/course-feedback.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { PipesModule } from '@YorbitWorkspace/pipes';

import { Pipe, PipeTransform } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

import { GeneralInfoComponent } from './general-info/general-info.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { PreloaderPopupComponent } from './preloader-popup/preloader-popup.component';
import { ChromeBrowserSuggestionComponent } from './chrome-browser-suggestion/chrome-browser-suggestion.component';
import { CookieCheckComponent } from './cookie-check/cookie-check.component';
import { TimeBoundLearningPopupComponent } from './time-bound-learning-popup/time-bound-learning-popup.component';

@Pipe({
  name: 'rating',
  pure: false
})
export class RatingPipe implements PipeTransform {
  transform(items: any[], filter: string): any {
    if (!items || filter === '') {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.rating === filter);
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,

    ReusableUiModule,
    PipesModule
  ],
  declarations: [
    ManagerFeedbackComponent,
    CourseFeedbackComponent,
    RatingPipe,
    GeneralInfoComponent,
    SessionTimeoutComponent,
    PreloaderPopupComponent,
    ChromeBrowserSuggestionComponent,
    CookieCheckComponent,
    TimeBoundLearningPopupComponent
  ],
  exports: [
    ManagerFeedbackComponent,
    CourseFeedbackComponent,
    RatingPipe,
    SessionTimeoutComponent,
    PreloaderPopupComponent,
    ChromeBrowserSuggestionComponent,
    CookieCheckComponent
  ]
})
export class GlobalPopupsModule {}
