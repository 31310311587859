import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video } from '../../../details-page/mobile-course-details-page/mobile-course-details-page.component';
import { PlayerVarsYoutube } from '../youtube-video-player/youtube-player.config';
import { DomSanitizer } from '@angular/platform-browser';
import { SystemService } from '../../services/system.service';
@Component({
  selector: 'yorbit-external-video-player',
  templateUrl: './external-video-player.component.html',
  styleUrls: ['./external-video-player.component.scss']
})
export class ExternalVideoPlayerComponent implements OnInit {
  playerVars: any;
  constructor(public dialogRef: MatDialogRef<ExternalVideoPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _system: SystemService,
    private _sanitizer: DomSanitizer) { }

  videoURL: any;
  videoTitle = '';
  videoConfig: Video;
  isStreamVideoLoaded:boolean=false;

  ngOnInit(): void {
    var videoId = '';

    //Setting video data
    this.playerVars = PlayerVarsYoutube;
    videoId = this.data.Type  == "stream" ?this.data.URL : this.YoutubeLinkParser(this.data.URL);

    if(this.data.Type=='stream'){
      this.getStreamVideoURL();
    }

    //Prepping the video data object
    const data = {
      ContentId: videoId,
      UnitId: '',
      CourseId: videoId,
      ItemId: videoId,
      ItemType: 'Video',
      PathId: 0,
      VideoId: videoId,
      sourceUrl: this.data.URL
    };

    this.videoConfig = {
      VideoDetails: data,
      VideoPlayerId: 'popup' + videoId,
      CanCaptureProgress: false,
      PlayerVars: this.playerVars,
      showInfoMsg: false
    };

    //Changing css using JS
    document.getElementsByClassName("cdk-overlay-pane")[0].setAttribute("style", "max-height:100% !important;height:90% !important;");
    document.getElementsByClassName("mat-dialog-container")[0].setAttribute("style", "box-shadow: none !important;background-color: transparent !important;");
    
    //Setting video title
    this.videoTitle = this.data.Title;
  }

  //Extracts Video id from youtube link
  YoutubeLinkParser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
   // console.log((match && match[7].length == 11) ? match[7] : false);
    return (match && match[7].length == 11) ? match[7] : false;
  }

  close() {
    this.dialogRef.close();
  }

  getSanitizedURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.data.URL);
  }

  getStreamVideoURL() {
    this._system.getBlobSasKey(this.data.URL).then(res => {
      this.data.URL=this._system.getAtoBDecode(res)
      this.isStreamVideoLoaded=true;
    });
  }

}
