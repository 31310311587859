<mat-sidenav-container fxFlex [hasBackdrop]="hasSideNavBackdrop" fxLayout="row" class="learning-path-container">
  <mat-sidenav #LPnavbar [mode]="LPnavbarMode" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="200px"
    (openedChange)="navbarOpenedChange($event)" [@.disabled]="true">
    <div *ngIf="!createLpEditMode" (click)="createNewLP()" class="createNewLpContainer" fxLayout="column"
      fxLayoutAlign="start stretch">
      <div class="createNewLpButton" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlexOffset="12px" fxFlex="100px" fxLayoutAlign="start center">
          Add New Learning Path
        </div>
        <div class="yorbitSprite iconAdd" fxFlex="14px" fxFlexOffset="20px"></div>
      </div>
    </div>
    <div *ngIf="createLpEditMode" class="createNewLpContainer editMode" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="createNewLpButton" fxLayout="row" fxLayoutAlign="start center">
        <input class="newLpTextbox" (keyup)="createLPKeyEvent($event)" [(ngModel)]="learningPathTitle" #lpTextbox
          type="text" placeholder="Enter a new title" fxFlex="123px" fxLayoutAlign="start center"
          (focusout)="onInputFocusOut($event)" />
        <div fxFlex="12px" fxFlexOffset="4px" *ngIf="learningPathTitle && '' !== learningPathTitle"
          (click)="clearInputTextName($event)">
          <div class="yorbitSprite iconClear"></div>
        </div>
        <div (click)="createLearningPath($event)" (mouseenter)="onMouseEnterOnIconTick()"
          (mouseleave)="onMouseLeaveOnIconTick()" class="yorbitSprite iconTick" fxFlex="15px" fxFlexOffset="5px"></div>
      </div>
    </div>
    <div class="path-divider" fxLayout="row" fxLayoutAlign="center center">
      <mat-divider fxFlex="150px"></mat-divider>
    </div>
    <div class="learning-path-tabs" fxLayout="column" fxFlex fxLayoutGap="0px" id="scrollable-lp">
      <div class="mandatory-paths tab" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="(null !== myComplianceLp && myComplianceLp.length != 0)||(null !== mandatoryLps && mandatoryLps.length != 0)">
        <div class="path-container" fxLayout="column" fxFlex>
          <div class="container-header" fxFlex="40px">
            <div fxFlex="125px">Mandatory Learning Path{{((myComplianceLp.length > 0 && mandatoryLps.length >
              0)||myComplianceLp.length > 1 ||mandatoryLps.length
              > 1)?'s':''}}</div>
          </div>
          <div class="container-body" fxLayout="column" fxFlex>
            <button mat-button class="title" [ngClass]="{
                  lpSelected: (LPBreadcrumbs.LPDetails.LPName|uppercase) == (lp.PathName|uppercase)
                }" id="{{'LP'+(lp.PathName|lowercase)}}" *ngFor="let lp of myComplianceLp" fxFlex="40px" fxLayout="row"
              fxLayoutAlign="start center" (click)="navigateAcrossLP('myCompliance', lp.PathName, lp)">
              <div fxFlexFill [ngStyle]="{ 'height.px': 14 }" fxLayoutAlign="start center" fxFlexOffset="8px">
                <div fxFlex class="learningPathTitle mandatoryLpTitle textTruncate custom" title="{{ lp.PathName }}">
                  {{ lp.PathName | lowercase }}
                </div>
                <div fxFlex="20px"
                  *ngIf="(lp.PathName==globals.CocLpName.toUpperCase() || lp.PathName==globals.BEACLpName.toUpperCase()) && ((_contentTileLpService.MandatoryLPProgressStatus | async)!= '100' && ((globals.mandatoryReminderCount >= 1 && globals.isNewJoinee) || (globals.mandatoryReminderCount >= 2 && !globals.isNewJoinee)))"
                  [ngClass.lt-lg]="{'showDeleteIcon':true}" class="yorbitSprite nonCompliantIcon nonCompliantIconPosLP"
                  title="{{(lp.PathName==globals.CocLpName.toUpperCase()) ?  (globals.CocLpName + 'Non-Compliant') : 'Business Ethics Non-Compliant'}}">
                </div>
                <div fxFlex="20px"
                  *ngIf="(lp.PathName=='SEXUAL HARASSMENT PREVENTION TRAINING - USA' || lp.PathName.toUpperCase() == globals.newPOSHUSLpName) && (_contentTileLpService.MandatoryLPProgressStatus | async) != '100' && (globals.mandatoryReminderCountForPoshUSA >= 2 && globals.counterPOSHUSA >= 2)"
                  [ngClass.lt-lg]="{'showDeleteIcon':true}" class="yorbitSprite nonCompliantIcon nonCompliantIconPosLP"
                  title="Sexual Harassment Prevention Training - USA Non-Compliant">
                </div>
                <mat-icon fxFlex="20px" class="keyboard-arrow-right mousePointer"
                  *ngIf="(LPBreadcrumbs.LPDetails.LPName|uppercase) == (lp.PathName|uppercase)">keyboard_arrow_right</mat-icon>
              </div>

            </button>
            <button mat-button class="title" id="{{'LP'+lp.PathName|lowercase}}" [ngClass]="{
                    lpSelected: (LPBreadcrumbs.LPDetails.LPName|uppercase) == (lp.PathName|uppercase)
                  }" *ngFor="let lp of mandatoryLps" fxFlex="40px" fxLayout="row" fxLayoutAlign="start center"
              (click)="navigateAcrossLP('mandatory', lp.PathName, lp)">
              <div fxFlexFill [ngStyle]="{ 'height.px': 14 }" fxFlexOffset="8px" fxLayoutAlign="start center">
                <div fxFlex class="learningPathTitle mandatoryLpTitle textTruncate" title="{{ lp.PathName }}">
                  {{ lp.PathName | lowercase }}
                </div>

                <img *ngIf="lp.IsTimeBound==true" class="timerIcon"
                  src="./../../assets/images/time bound learning/timer_icon.png">
                <mat-icon class="keyboard-arrow-right mousePointer"
                  *ngIf="(LPBreadcrumbs.LPDetails.LPName|uppercase) == (lp.PathName|uppercase)"
                  fxFlex="20px">keyboard_arrow_right</mat-icon>
              </div>

            </button>
          </div>
        </div>
      </div>

      <!-- <div class="madatory-paths" fxLayout="column" fxLayoutAlign="start stretch">
            <button mat-button class="title" [ngClass]="{
            lpSelected: (LPBreadcrumbs.LPDetails.LPName|uppercase) == (lp.PathName|uppercase)
          }"
              *ngFor="let lp of myComplianceLp" fxFlex="40px" fxLayout="row" fxLayoutAlign="start center" (click)="navigateAcrossLP('myCompliance', lp.PathName, lp)">
              <div fxFlex="130px" [ngStyle]="{ 'height.px': 14 }" fxLayoutAlign="start center" fxFlexOffset="12px">
                <div class="learningPathTitle mandatoryLpTitle textTruncate" title="{{ lp.PathName }}">
                  {{ lp.PathName | lowercase }}
                </div>
                <div fxFlex="18px" *ngIf="lp.PathName=='CODE OF CONDUCT' && (globals.mandatoryLPProgress != '100' && ((globals.mandatoryReminderCount >= 1 && globals.isNewJoinee) || (globals.mandatoryReminderCount >= 2 && !globals.isNewJoinee)))"
                  [ngClass.lt-lg]="{'showDeleteIcon':true}" class="yorbitSprite nonCompliantIcon nonCompliantIconPosLP"
                  title="Business Ethics Non-Compliant">
                </div>
                <div fxFlex="18px" *ngIf="lp.PathName=='SEXUAL HARASSMENT PREVENTION TRAINING - USA' && globals.mandatoryLPProgressForPoshUSA != '100' && (globals.mandatoryReminderCountForPoshUSA >= 2 && globals.counterPOSHUSA >= 2)"
                    [ngClass.lt-lg]="{'showDeleteIcon':true}" class="yorbitSprite nonCompliantIcon nonCompliantIconPosLP"
                    title="Sexual Harassment Prevention Training - USA Non-Compliant">
                  </div>
              </div>
            </button>
          </div> -->
      <!-- <div class="path-divider" fxLayout="row" fxLayoutAlign="center center" *ngIf="myComplianceLp?.length != 0">
            <mat-divider fxFlex="150px"></mat-divider>
          </div> -->
      <!-- <div class="madatory-paths" fxLayout="column" fxLayoutAlign="start stretch">
            <button mat-button class="title" [ngClass]="{
                lpSelected: (LPBreadcrumbs.LPDetails.LPName|uppercase) == (lp.PathName|uppercase)
              }"
              *ngFor="let lp of mandatoryLps" fxFlex="40px" fxLayout="row" fxLayoutAlign="start center" (click)="navigateAcrossLP('mandatory', lp.PathName, lp)">
              <div fxFlex="130px" [ngStyle]="{ 'height.px': 14 }" fxLayoutAlign="start center" fxFlexOffset="12px">
                <div class="learningPathTitle mandatoryLpTitle textTruncate" title="{{ lp.PathName }}">
                  {{ lp.PathName | lowercase }}
                </div>
              </div>
            </button>
          </div> -->
      <!-- <div class="path-divider" fxLayout="row" fxLayoutAlign="center center" *ngIf="mandatoryLps?.length != 0">
            <mat-divider fxFlex="150px"></mat-divider>
          </div> -->
      <div class="skilling-paths tab" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="null !== skillingLps && skillingLps.length != 0">
        <div class="path-container" fxLayout="column" fxFlex>
          <div class="container-header" fxFlex="40px">
            <div fxFlex="125px">Skilling Project{{skillingLps.length>1?'s':''}}</div>
          </div>
          <div class="container-body" fxLayout="column">
            <button mat-button class="skill-paths title" [ngClass]="{
                    lpSelected: LPBreadcrumbs.LPDetails.LPName == lp.PathName
                  }" id="{{'LP'+lp.PathId|lowercase}}" *ngFor="let lp of skillingLps" fxFlex="40px" fxLayout="row"
              fxLayoutAlign="start center" (click)="navigateAcrossLP('skilling', lp.PathId, lp)">
              <div fxFlexFill [ngStyle]="{ 'height.px': 14 }" fxLayoutAlign="start center" fxFlexOffset="8px">
                <div fxFlex class="learningPathTitle skillLpTitle textTruncate" title="{{ lp.PathName }}">
                  {{ lp.PathName | lowercase }}
                </div>
                <mat-icon class="keyboard-arrow-right mousePointer" *ngIf="LPBreadcrumbs.LPDetails.LPId == lp.PathId"
                  fxFlex="20px">keyboard_arrow_right</mat-icon>
              </div>

            </button>
          </div>
        </div>
      </div>
      <!-- <div class="path-divider" fxLayout="row" fxLayoutAlign="center center" *ngIf="null !== skillingLps && skillingLps.length != 0">
            <mat-divider fxFlex="150px"></mat-divider>
          </div> -->

      <div class="user-created-paths tab" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="degreedLps && degreedLps.length != 0">
        <div class="path-container" fxLayout="column" fxFlex>
          <div class="container-header" fxFlex="40px">
            <div fxFlex="125px">Degreed Learning{{degreedLps.length > 1 ? 's' : ''}}</div>
          </div>
          <div class="container-body" fxLayout="column" fxFlex>
            <div mat-button class="title" [ngClass]="{
                  lpSelected: LPBreadcrumbs.LPDetails.LPId == lp.PathId
                }" id="{{'LP'+lp.PathId|lowercase}}" *ngFor="let lp of degreedLps" fxFlex="40px" fxLayout="row" fxLayoutAlign="start center">
              <div fxFlexFill fxLayoutAlign="start center">
                <div fxFlex (click)="navigateAcrossLP('degreed', lp.PathId, lp)" class="learningPathTitle degreedLPTitle textTruncate mousePointer"
                  title="{{ lp.PathName == 'BACKUP-MANDATORYLP' ? 'This LP contains the back up of Non-Mandatory courses which was earlier present in your Mandatory LP' : lp.PathName }}">
                  {{ lp.PathName }}
                </div>
                <!-- <mat-icon class="delete-icon mousePointer" fxFlex="20px" (click)="deleteLP(lp)" [ngClass.lt-lg]="{'showDeleteIcon':false}">delete_outline</mat-icon> -->
                <mat-icon fxFlex="20px" class="keyboard-arrow-right mousePointer" *ngIf="LPBreadcrumbs.LPDetails.LPId == lp.PathId">keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-created-paths tab" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="null !== userCreatedLps && userCreatedLps.length != 0">
        <div class="path-container" fxLayout="column" fxFlex>
          <div class="container-header" fxFlex="40px">
            <div fxFlex="125px">Personal Learning Path{{userCreatedLps.length>1?'s':''}}</div>
          </div>
          <div class="container-body" fxLayout="column" fxFlex>
            <div mat-button class="title" [ngClass]="{
                  lpSelected: LPBreadcrumbs.LPDetails.LPId == lp.PathId
                }" id="{{'LP'+lp.PathId|lowercase}}" *ngFor="let lp of userCreatedLps" fxFlex="40px" fxLayout="row"
              fxLayoutAlign="start center">
              <div fxFlexFill fxLayoutAlign="start center">
                <div fxFlex (click)="navigateAcrossLP('self', lp.PathId, lp)"
                  class="learningPathTitle personalPathTitle textTruncate mousePointer"
                  title="{{ lp.PathName == 'BACKUP-MANDATORYLP' ? 'This LP contains the back up of Non-Mandatory courses which was earlier present in your Mandatory LP' : lp.PathName }}">
                  {{ lp.PathName }}
                </div>
                <mat-icon class="delete-icon mousePointer" fxFlex="20px" (click)="deleteLP(lp)"
                  [ngClass.lt-lg]="{'showDeleteIcon':true}">delete_outline</mat-icon>
                <mat-icon fxFlex="20px" class="keyboard-arrow-right mousePointer"
                  *ngIf="LPBreadcrumbs.LPDetails.LPId == lp.PathId">keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="user-created-paths" fxLayout="column" fxLayoutAlign="start stretch">
            <div mat-button class="title" [ngClass]="{
                lpSelected: LPBreadcrumbs.LPDetails.LPId == lp.PathId
              }"
              *ngFor="let lp of userCreatedLps" fxFlex="40px" fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="145px" fxLayoutAlign="start center">
                <div (click)="navigateAcrossLP('self', lp.PathId, lp)" class="learningPathTitle textTruncate mousePointer"
                  [ngClass]="{'no-truncate':lp.PathName == 'BACKUP-MANDATORYLP'}" title="{{ lp.PathName == 'BACKUP-MANDATORYLP' ? 'This LP contains the back up of Non-Mandatory courses which was earlier present in your Mandatory LP' : lp.PathName }}">
                  {{ lp.PathName }}
                </div>
              </div>
              <mat-icon class="delete-icon mousePointer" fxFlex="10px" (click)="deleteLP(lp)" [ngClass.lt-lg]="{'showDeleteIcon':true}">clear</mat-icon>
            </div>
          </div> -->
    </div>
    <!-- <mat-tab>
        <ng-template mat-tab-label>
          <div class="role-based-tab">
            <div class="assignment-tab-holder">
              <mat-icon class="done-icon">done</mat-icon>
            </div>
          </div>
        </ng-template>
        <div fxLayout="row">
          <button mat-button class="title" fxFlex="125px" fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="40px" fxLayoutAlign="start center">Individual</div>
          </button>
        </div>
      </mat-tab> -->
  </mat-sidenav>
  <mat-sidenav-content *ngIf="learningPathsLoadedSuccessfully" fxLayout="column" fxFlex fxLayoutAlign="start stretch"
    [ngClass]="{'lp-course-detail-page':LPBreadcrumbs.CourseDetails.CourseId!=null}">
    <div *ngIf="0<userCreatedLps?.length||0<skillingLps?.length||0<mandatoryLps?.length || 0<myComplianceLp?.length || 0<degreedLps?.length"
      class="lp-action-bar" fxFlex.xs="45px" fxFlexOffset.gt-xs="10px" fxLayout="row wrap"
      [ngClass.xs]="{'lp-action-bar-xs':true}">
      <div class="breadcrumbs" fxFlex.gt-xs fxLayout="row" fxLayoutAlign="start center" fxFlex.xs="40px">
        <div (click)="openLPNavBar()" fxShow.xs="true" fxShow.gt-xs="false" class="breadcrumbs-navbar-toggle-btn">MENU
        </div>
        <div fxShow.xs="false" fxFlex.gt-xs fxLayout="row">
          <div class="mousePointer"
            [ngClass]="{'mousePointer':(LPBreadcrumbs.CourseDetails.CourseId!=null||LPBreadcrumbs.PackageDetails.PackageId!=null)}"
            *ngIf="LPBreadcrumbs.LPDetails.LPName!=null" (click)="navigateTo('LP')" fxLayout="row"
            fxLayoutAlign="start center">
            <div class="yorbitSprite breadcrumbsBack alignIconBCBack"
              *ngIf="LPBreadcrumbs.CourseDetails.CourseName!=null"></div>
            <!-- <div *ngIf="LPBreadcrumbs.LPDetails.LPCategory==='skilling'">Skilling Project</div>
            <div *ngIf="LPBreadcrumbs.LPDetails.LPCategory==='skilling'" class="yorbitSprite breadcrumbsFront alignIconBCFront"></div> -->
            <!-- <div class="title" *ngIf="LPBreadcrumbs.LPDetails.LPName!='SEXUAL HARASSMENT PREVENTION TRAINING - USA' || (LPBreadcrumbs.LPDetails.LPName=='SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.CourseDetails.CourseId!=null)">{{LPBreadcrumbs.CourseDetails.CourseName==null?'Learning Path : '+LPBreadcrumbs.LPDetails.LPName:LPBreadcrumbs.LPDetails.LPName}}</div> -->
            <div class="title">Learning Path : </div>
            <div class="title bold" fxFlexOffset="5px"
              [ngClass]="{'camelCase':(LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'||LPBreadcrumbs.LPDetails.LPCategory=='mandatory')}">
              {{(LPBreadcrumbs.LPDetails.LPCategory=='myCompliance'||LPBreadcrumbs.LPDetails.LPCategory=='mandatory')?(LPBreadcrumbs.LPDetails.LPName|lowercase):LPBreadcrumbs.LPDetails.LPName}}
            </div>
          </div>
          <div [ngClass]="{'mousePointer':LPBreadcrumbs.CourseDetails.CourseId!=null}"
            *ngIf="LPBreadcrumbs.PackageDetails.PackageName!=null" fxLayout="row" fxLayoutAlign="start center">
            <div class="yorbitSprite breadcrumbsFront alignIconBCFront"></div>
            <div class="title" (click)="navigateTo('Package')">
              {{ LPBreadcrumbs.PackageDetails.PackageName }}
            </div>
          </div>
          <div
            *ngIf="LPBreadcrumbs.CourseDetails.CourseName != null && LPBreadcrumbs.LPDetails.LPName!='SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != globals.newPOSHUSLpName"
            fxLayout="row" fxLayoutAlign="start center">
            <div class="yorbitSprite breadcrumbsFront alignIconBCFront"></div>
            <div class="title">
              {{ LPBreadcrumbs.CourseDetails.CourseName }}
            </div>
          </div>
        </div>



      </div>
      <div fxFlex.xs fxFlex.gt-xs="380px" fxLayoutAlign="end center"
        *ngIf="this.LPBreadcrumbs.CourseDetails.CourseId == null">
        <div class="search" fxFlexOrder.gt-xs="1" fxFlexOrder.xs="0" fxFlex="200px" fxLayoutAlign="start center"
          fxLayoutAlign.sm="end center" fxLayoutAlign.xs="center center" *ngIf="canSearchInLP">
          <!-- <input fxShow.gt-xs="true" [fxShow.xs]="showSearchBarInLP" [(ngModel)]="searchLPModel" (onblur)="showSearchBarInLP = false"
            (ngModelChange)="updateSearchModel()" fxFlex placeholder="Search course/package" /> -->
          <input type="text" placeholder="Search" fxFlex fxShow.gt-xs="true" [fxShow.xs]="showSearchBarInLP"
            [(ngModel)]="searchLPModel" (ngModelChange)="updateSearchModel($event)" (onblur)="showSearchBarInLP = false"
            aria-label="Seach" matInput [matAutocomplete]="autoComplete">
          <mat-autocomplete #autoComplete="matAutocomplete" class="lp-search-autocomplete"
            (closed)="searchResults=[];searchLPModel='';searchResultsLoaded=false;">
            <mat-option *ngIf="searchResults.length>0 && searchLPModel.length==0 && searchResults[0].Name!='preloader'">
              <div class="search-option">
                <b>Recent search items</b>
              </div>
            </mat-option>
            <mat-option *ngIf="searchResults.length === 0 && searchLPModel.length !== 0 && searchResultsLoaded">
              <div class="search-option">
                No data available
              </div>
            </mat-option>
            <mat-option *ngFor="let option of searchResults" [value]="option.Name"
              (click)="navigateToSearchedContent(option)" title="{{option.Name}}">
              <div class="search-option" title="{{option.Name}}" fxLayout="row" fxLayoutAlign="start center"
                fxLayoutGap="10px" *ngIf="option.Name!='preloader'">
                <div
                  class="category-icon yorbitSprite {{option.Type=='Course'?'courseIcon':'packageIcon'}}{{option.PathName === this.globals.degreedLPName ? 'Degreed' : option.Expertise}}"
                  fxFlex="21px"></div>
                <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
                  <div class="package-label"
                    *ngIf="!option.IsSkillPlan && option.PackageId!= '' && option.Type=='Course' && option.PackageId.length>0">
                    <b>Package:{{option.PackageName}}</b></div>
                  <div class="package-label"
                    *ngIf="option.IsSkillPlan && option.PathName != '' && option.Type=='Course' && option.PathName.length>0">
                    <b>Skilling Project:{{option.PathName}}</b></div>
                  <div class="name">{{option.Name}}</div>
                </div>
                <!-- {{(option.Type=='Course'?"Course/":"Package/")+option.Expertise+"/"+option.Name}} -->
              </div>
              <div class="search-option" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
                *ngIf="option.Name=='preloader'">
                <div fxLayoutAlign="center center" fxFlex class="preloader-container-search-lp">
                  <app-preloader></app-preloader>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <mat-icon class="close-search-icon mousePointer" [fxShow.gt-xs]="searchLPModel.length>0"
            [fxShow.xs]="showSearchBarInLP"
            (click)="showSearchBarInLP = false;searchLPModel='';searchResults=[]">clear</mat-icon>
          <mat-icon class="search-mat-icon mousePointer" [fxShow.gt-xs]="searchLPModel.length==0"
            [fxShow.xs]="!showSearchBarInLP" (click)="showSearchBarInLP = true">search</mat-icon>
          <!-- <div class="search-results-dropdown" *ngIf="showSearchResults" fxLayout="column" fxLayoutAlign="start stretch">
            <div class="no-data" *ngIf="searchResultsLoaded && searchResults.length==0" fxFlex="200px" fxLayoutAlign="center center">
              No course available
            </div>
            <div class="preloader" *ngIf="!searchResultsLoaded"  fxFlex="200px" fxLayoutAlign="center center">
              <app-preloader></app-preloader>
            </div>
            <div class="data" *ngIf="searchResultsLoaded && searchResults.length>0">
              <div class="item" *ngFor="let item of searchResults" (click)="showSearchResults=false;navigateToSearchedContent(item)">
              {{item.Name}}
              </div>
            </div>
          </div> -->
        </div>
        <div class="resume mousePointer" *ngIf="resumeDetails!=null" fxFlex fxFlexOrder.gt-xs="0" fxFlexOrder.xs="1"
          fxFlex="180px" fxFlex.xs="30px" (click)="resumeLearningPath()" fxLayoutAlign="start center">
          <div fxShow.xs="false">Resume where you left</div>
          <div class="yorbitSprite iconResume" [ngStyle.gt-xs]="{ margin: '0 0 0 8px' }"
            [ngStyle.xs]="{ margin: '0 0 0 4px' }"></div>
        </div>
      </div>
    </div>

    <div fxLayout="row" *ngIf="LPBreadcrumbs.LPDetails.LPName &&
    LPBreadcrumbs.LPDetails.LPName.toUpperCase()=='ROLE BASED LEARNING' &&
    (''==LPBreadcrumbs.CourseDetails.CourseName||null==LPBreadcrumbs.CourseDetails.CourseName)
    ">
      <yorbit-role-road-map></yorbit-role-road-map>
    </div>

    <div class="noLpMessage"
      *ngIf="learningPathsLoadedSuccessfully && !(0<userCreatedLps?.length||0<skillingLps?.length||0<mandatoryLps?.length || 0<myComplianceLp?.length || 0<degreedLps?.length)">
      Create a New Learning Path and Add Courses to begin your Journey on Shoshin School
    </div>
    <div class="lp-content-tiles-container" fxFlex fxLayout="column"
      *ngIf="learningPathsLoadedSuccessfully && (0<userCreatedLps?.length||0<skillingLps?.length||0<mandatoryLps?.length|| 0<myComplianceLp?.length || 0<degreedLps?.length)">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
  <mat-sidenav-content fxLayout="column" fxFlex fxLayoutAlign="center center" *ngIf="!learningPathsLoadedSuccessfully">
    <app-preloader></app-preloader>
  </mat-sidenav-content>
</mat-sidenav-container>