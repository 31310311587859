<div fxLayout="row" class="genre-view-container" fxFlex [ngClass.xs]="{ 'genre-view-container-xs': true }"
  *ngIf="!showPagePreloader">
  <div fxFlex="220px" fxFlex.xs="100%" fxLayout="column" [fxShow.xs]="!showCourses">
    <div fxFlex="45px" [fxShow.gt-xs]="true" fxShow.xs="false" fxLayout="row" class="back-button mousePointer"
      fxLayoutAlign="center center">
      <div fxFlex="210px" (click)="goToAcademyView()" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <div fxFlexOffset="5px" *ngIf="!isAccountBrowse">Back To Academy</div>
        <div fxFlexOffset="5px" *ngIf="isAccountBrowse">Back To Accounts</div>
      </div>
    </div>
    <div class="genres-list overflow" fxFlex>
      <div *ngFor="
          let genre of mediaBreakPoint == 'xs'
            ? (selectedAcademy.GenreTerms
              | arrayPropertyFilter
                : { property: 'UniqueId', flag: this.selectedGenre.UniqueId })
            : selectedAcademy.GenreTerms
        " fxLayout="column">
        <div class="genre mousePointer" [ngClass]="{
            genreSelected:
              genre.UniqueId == selectedGenre.UniqueId &&
              selectedGenre.showSkills
          }" fxLayout="row" fxLayoutAlign="start center" (click)="onGenreSelection(genre)">
          <div fxFlex="155px" fxFlex.xs fxLayoutAlign="start center" class="title" title="{{ genre.Name }}">
            {{ genre.Name }}
          </div>
          <mat-icon fxFlex="25px" fxShow.xs="true" fxShow.gt-xs="false">keyboard_arrow_right</mat-icon>
          <mat-icon fxFlex="25px" [fxShow.gt-xs]="
              !(
                genre.UniqueId == selectedGenre.UniqueId &&
                selectedGenre.showSkills
              )
            " [fxHide.xs]="true">add</mat-icon>
          <!--<mat-icon fxFlex="25px" [fxShow.gt-xs]="
              genre.UniqueId == selectedGenre.UniqueId &&
              selectedGenre.showSkills
            " [fxHide.xs]="true">remove</mat-icon>-->
        </div>
        <div>
          <div class="skills-list" fxFlex fxFlex.xs="100%" [fxShow]="
              genre.UniqueId == selectedGenre.UniqueId &&
              selectedGenre.showSkills
            " fxLayout="column" fxLayoutAlign="center stretch">
            <div class="skill mousePointer" *ngFor="let skill of selectedGenre.SkillTerms" fxFlex="30px" fxLayout="row"
              fxLayoutAlign="start center" (click)="filterBySkills(skill)" title="{{ skill.Name }}">
              <div fxFlex="25px">
                <mat-icon *ngIf="skill.isSelected">check_box</mat-icon>
                <mat-icon *ngIf="!skill.isSelected">check_box_outline_blank</mat-icon>
              </div>
              <div class="title" fxFlex="150px" fxFlex.xs fxFlexOffset="5px">
                {{ skill.Name }}
              </div>
            </div>
          </div>
        </div>
        <mat-divider fxFlex="1px" *ngIf="mediaBreakPoint != 'xs'"></mat-divider>
      </div>
    </div>
  </div>
  <div fxFlex [fxShow.gt-xs]="!showCoursesPreloader && coursesLoadedSuccessfully" fxLayout="column"
    [fxShow.xs]="showCourses && !showCoursesPreloader && coursesLoadedSuccessfully">
    <div class="content-fiter-section" *ngIf="!roleList.CUSTOMER" [ngClass.xs]="{ xs: true }" fxFlex.xs="90px"
      fxFlex.gt-xs="0 1 90px" fxLayoutAlign.xs="start center" fxLayoutAlign.gt-xs="start center" fxLayout="row"
      fxLayout.xs="column">
      <div fxFlex.xs="90px" fxFlex.gt-xs fxLayout.xs="column">
        <div class="expertise-filter-section" fxFlex.lt-md="24px" fxFlex.gt-sm="258px"
          [ngClass.lt-md]="{ 'lt-md': true }" fxLayoutAlign="start center" fxShow.gt-sm="true" fxShow.lt-md="false">
          <mat-icon fxFlexOffset.sm="0px" class="more_vert_icon" fxShow.gt-sm="false" fxShow.lt-md="true"
            (click)="showExpertiseFiltersMenu = !showExpertiseFiltersMenu">more_vert</mat-icon>
          <div class="overlay-backdrop" fxShow.gt-sm="false" [fxShow.lt-md]="showExpertiseFiltersMenu"
            (click)="showExpertiseFiltersMenu = !showExpertiseFiltersMenu"></div>
          <div fxFlex.lt-md="110px" (click)="$event.stopPropagation()" class="expertise-filters"
            [ngClass.lt-md]="{ 'mat-elevation-z2': true }" fxShow.gt-sm="true" [fxShow.lt-md]="showExpertiseFiltersMenu"
            fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutAlign.lt-md="start stretch"
            fxLayoutAlign.gt-sm="start center">
            <div fxFlexOffset.lt-md="0px" fxFlexOffset.gt-sm="0px" fxFlex.lt-md="30px" fxFlex.gt-sm="60px"
              class="menu-item" fxLayoutAlign="start center">
              <div fxFlex="22px" class="browseCheckbox" fxLayoutAlign="start center" class="mousePointer">
                <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedExpertiseToFilter.length == 3"
                  (click)="filterByExpertise('all', false)">radio_button_checked</mat-icon>
                <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedExpertiseToFilter.length != 3"
                  (click)="filterByExpertise('all', true)">radio_button_unchecked</mat-icon>
              </div>
              <div class="mouseDefault">All</div>
            </div>
            <div fxFlex.lt-md="30px" fxFlex.gt-sm="60px" class="menu-item" fxLayoutAlign="start center">
              <div fxFlex="22px" class="browseCheckbox" fxLayoutAlign="start center" class="mousePointer">
                <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedExpertiseToFilter.indexOf('101') != -1"
                  (click)="filterByExpertise('101', false)">check_box</mat-icon>
                <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedExpertiseToFilter.indexOf('101') == -1"
                  (click)="filterByExpertise('101', true)">check_box_outline_blank</mat-icon>
              </div>
              <div class="mouseDefault">101</div>
            </div>
            <div fxFlex.lt-md="30px" fxFlex.gt-sm="60px" class="menu-item" fxLayoutAlign="start center">
              <div fxFlex="22px" class="browseCheckbox" fxLayoutAlign="start center" class="mousePointer">
                <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedExpertiseToFilter.indexOf('201') != -1"
                  (click)="filterByExpertise('201', false)">check_box</mat-icon>
                <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedExpertiseToFilter.indexOf('201') == -1"
                  (click)="filterByExpertise('201', true)">check_box_outline_blank</mat-icon>
              </div>
              <div class="mouseDefault">201</div>
            </div>
            <div fxFlex.lt-md="30px" fxFlex.gt-sm="60px" class="menu-item" fxLayoutAlign="start center">
              <div fxFlex="22px" class="browseCheckbox" fxLayoutAlign="start center" class="mousePointer">
                <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedExpertiseToFilter.indexOf('301') != -1"
                  (click)="filterByExpertise('301', false)">check_box</mat-icon>
                <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedExpertiseToFilter.indexOf('301') == -1"
                  (click)="filterByExpertise('301', true)">check_box_outline_blank</mat-icon>
              </div>
              <div class="mouseDefault">301</div>
            </div>
          </div>
        </div>

        <div fxShow.gt-sm="false" fxShow.lt-md="true">
          <ng-template [ngTemplateOutlet]="SearchDiv"></ng-template>
        </div>

        <div [ngClass.xs]="{ 'mobileViewRow': true }">
          <div fxFlexOffset.sm="10px" class="course-type-filter" fxFlex.lt-md="130px" fxFlex.gt-sm="115px"
            fxShow.gt-sm="false" fxShow.lt-md="true">
            <mat-menu #ExpertiseFilter="matMenu" [overlapTrigger]="false">
              <div fxFlexOffset.lt-md="0px" fxFlexOffset.gt-sm="0px" fxFlex.lt-md="30px" fxFlex.gt-sm="60px"
                class="course-type allRadioButton" fxLayoutAlign="start center">
                <div fxFlex="22px" class="browseCheckbox" fxLayoutAlign="start center" class="mousePointer">
                  <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedExpertiseToFilter.length == 3"
                    (click)="filterByExpertise('all', false)">radio_button_checked</mat-icon>
                  <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedExpertiseToFilter.length != 3"
                    (click)="filterByExpertise('all', true)">radio_button_unchecked</mat-icon>
                </div>
                <div class="mouseDefault">All</div>
              </div>
              <div mat-menu-item *ngFor="let expertise of expertises" (click)="$event.stopPropagation()"
                class="course-type">
                <div fxFlex="22px" class="mousePointer">
                  <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedExpertiseToFilter.indexOf(expertise) != -1"
                    (click)="filterByExpertise(expertise, false)">check_box</mat-icon>
                  <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedExpertiseToFilter.indexOf(expertise) == -1"
                    (click)="filterByExpertise(expertise, true)">check_box_outline_blank</mat-icon>
                </div>
                <div>{{ expertise }}</div>
              </div>
              <div *ngIf="expertises.length == 0" fxLayout="column" fxFlex="170px" fxLayoutAlign="center center">
                <div fxFlex="30px" class="noDataInfo">No data available</div>
              </div>
            </mat-menu>
            <button fxFlex.lt-md="130px" fxFlex.gt-sm="115px" [ngClass.xs]="{ xs: true }"
              [matMenuTriggerFor]="ExpertiseFilter" fxLayoutAlign="start center" *ngIf="!isAccountBrowse"
              class="mousePointer">
              <div fxFlex>Expertise</div>
              <div class="courseTypeDropIconContainer" [fxShow.gt-sm]="true" [fxShow.lt-md]="false" fxFlex="20px">
                <mat-icon class="typeDropIcon" *ngIf="!ExpertiseFilter.menuOpen">keyboard_arrow_down</mat-icon>
                <mat-icon class="typeDropIcon" *ngIf="ExpertiseFilter?.menuOpen">keyboard_arrow_up</mat-icon>
              </div>
            </button>
          </div>

          <div fxFlexOffset="10px" class="course-type-filter" fxFlex.lt-md="130px" fxFlex.gt-sm="115px">
            <mat-menu #CourseTypeFilter="matMenu" [overlapTrigger]="false">
              <div mat-menu-item *ngFor="let type of courseTypes" (click)="$event.stopPropagation()"
                class="course-type">
                <div fxFlex="22px" class="mousePointer">
                  <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedCourseTypeToFilter.indexOf(type) != -1"
                    (click)="filterByCourseType(type, false)">check_box</mat-icon>
                  <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedCourseTypeToFilter.indexOf(type) == -1"
                    (click)="filterByCourseType(type, true)">check_box_outline_blank</mat-icon>
                </div>
                <div>{{ type }}</div>
              </div>
              <div *ngIf="courseTypes.length == 0" fxLayout="column" fxFlex="170px" fxLayoutAlign="center center">
                <div fxFlex="30px" class="noDataInfo">No data available</div>
              </div>
            </mat-menu>
            <button fxFlex.lt-md="130px" fxFlex.gt-sm="115px" [ngClass.xs]="{ xs: true }"
              [matMenuTriggerFor]="CourseTypeFilter" fxLayoutAlign="start center" *ngIf="!isAccountBrowse"
              class="mousePointer">
              <div fxFlex>Course Type</div>
              <div class="courseTypeDropIconContainer" [fxShow.gt-sm]="true" [fxShow.lt-md]="false" fxFlex="20px">
                <mat-icon class="typeDropIcon" *ngIf="!CourseTypeFilter.menuOpen">keyboard_arrow_down</mat-icon>
                <mat-icon class="typeDropIcon" *ngIf="CourseTypeFilter?.menuOpen">keyboard_arrow_up</mat-icon>
              </div>
            </button>
          </div>
        </div>

        <div>
          <div fxFlexOffset="10px" class="course-type-filter" fxFlex.lt-md="130px" fxFlex.gt-sm="90px">
            <mat-menu #vendorFilter="matMenu" [overlapTrigger]="false">
              <div mat-menu-item *ngFor="let type of vendors" (click)="$event.stopPropagation()" class="course-type">
                <div fxFlex="22px" class="mousePointer">
                  <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedVendorToFilter.indexOf(type) != -1"
                    (click)="filterByVendor(type, false)">check_box</mat-icon>
                  <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedVendorToFilter.indexOf(type) == -1"
                    (click)="filterByVendor(type, true)">check_box_outline_blank</mat-icon>
                </div>
                <div>{{ type }}</div>
              </div>
              <div *ngIf="vendors.length == 0" fxLayout="column" fxFlex="170px" fxLayoutAlign="center center">
                <div fxFlex="30px" class="noDataInfo">No data available</div>
              </div>
            </mat-menu>
            <button fxFlex.lt-md="130px" fxFlex.gt-sm="90px" [ngClass.xs]="{ xs: true }"
              [matMenuTriggerFor]="vendorFilter" fxLayoutAlign="start center" *ngIf="!isAccountBrowse"
              class="mousePointer">
              <div fxFlex>Vendor</div>
              <div class="courseTypeDropIconContainer" [fxShow.gt-sm]="true" [fxShow.lt-md]="false" fxFlex="20px">
                <mat-icon class="typeDropIcon" *ngIf="!vendorFilter.menuOpen">keyboard_arrow_down</mat-icon>
                <mat-icon class="typeDropIcon" *ngIf="vendorFilter?.menuOpen">keyboard_arrow_up</mat-icon>
              </div>
            </button>
          </div>

          <div fxFlexOffset="10px" class="course-type-filter" fxFlex.lt-md="130px" fxFlex.gt-sm="100px">
            <mat-menu #CreditsFilter="matMenu" [overlapTrigger]="false">
              <div mat-menu-item *ngFor="let credit of credits" (click)="$event.stopPropagation()" class="course-type">
                <div fxFlex="22px" class="mousePointer">
                  <mat-icon class="checkboxExpertiseSelected" *ngIf="selectedCreditsToFilter.indexOf(credit) != -1"
                    (click)="filterByCredits(credit, false)">check_box</mat-icon>
                  <mat-icon class="checkboxExpertiseBlank" *ngIf="selectedCreditsToFilter.indexOf(credit) == -1"
                    (click)="filterByCredits(credit, true)">check_box_outline_blank</mat-icon>
                </div>
                <div>{{ credit }}</div>
              </div>
              <div *ngIf="credits.length == 0" fxLayout="column" fxFlex="170px" fxLayoutAlign="center center">
                <div fxFlex="30px" class="noDataInfo">No data available</div>
              </div>
            </mat-menu>
            <button fxFlex.lt-md="130px" fxFlex.gt-sm="100px" [ngClass.xs]="{ xs: true }"
              [matMenuTriggerFor]="CreditsFilter" fxLayoutAlign="start center" *ngIf="!isAccountBrowse"
              class="mousePointer">
              <div fxFlex>Credits</div>
              <div class="courseTypeDropIconContainer" [fxShow.gt-sm]="true" [fxShow.lt-md]="false" fxFlex="20px">
                <mat-icon class="typeDropIcon" *ngIf="!CourseTypeFilter.menuOpen">keyboard_arrow_down</mat-icon>
                <mat-icon class="typeDropIcon" *ngIf="CourseTypeFilter?.menuOpen">keyboard_arrow_up</mat-icon>
              </div>
            </button>
          </div>
        </div>
        <div fxShow.gt-sm="true" fxShow.lt-md="false" class="resetAllDiv">
          <button class="resetAllButton" aria-label="Go" (click)="resetAllFilters()">
            <i class="spriteIcons floatRight resetAllIcon"></i>
            Reset All Filters
          </button>
        </div>
      </div>

      <div fxShow.gt-sm="true" fxShow.lt-md="false">
        <ng-template [ngTemplateOutlet]="SearchDiv"></ng-template>
      </div>

    </div>
    <div class="browse-content-tile-container overflow-theme-dark" fxFlex #scrollingBlock fxLayout="row wrap"
      fxLayoutAlign="start start" fxLayoutAlign.xs="center start" class="browse-content-tile-container"
      *ngIf="filteredPackagesAndCoursesList.length!=0">
      <virtual-scroller #scroll [items]="filteredPackagesAndCoursesList" [parentScroll]="scrollingBlock">

        <div class="content-tile-holder" [ngClass]="{'partition':item.ItemType=='partition'}" *ngFor="
        let item of scroll.viewPortItems;let index = index
      " fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="
          (!roleList.CUSTOMER || (roleList.CUSTOMER && item.HasCustomerAccess)) &&
      (item.ItemType=='Package'||item.ItemType=='FamilyPackage')
        " fxFlex="330px" fxLayoutAlign="center center">
            <yorbit-package-tile [cardDetails]="item" [featuredIn]="'Browse'" fxFlex="225px" fxLayoutAlign="start start"
              fxFlexFill>
            </yorbit-package-tile>
          </div>
          <mat-divider *ngIf="item.ItemType=='partition'">
          </mat-divider>
          <div *ngIf="
          (!roleList.CUSTOMER || (roleList.CUSTOMER && item.HasCustomerAccess)) &&
      (item.ItemType=='Course')
        " fxFlex="300px" fxLayoutAlign="center center"class="cardMargin">
            <yorbit-content-tile-browse [cardDetails]="item" [featuredIn]="'Browse'" fxFlex="225px"
              fxLayoutAlign="start start" fxFlexFill>
            </yorbit-content-tile-browse>
          </div>
        </div>
      </virtual-scroller>
    </div>
    <div fxFlex fxLayoutAlign="center center" *ngIf="filteredPackagesAndCoursesList.length == 0">
      No Data Available
    </div>
  </div>
  <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="showCoursesPreloader">
    <app-preloader></app-preloader>
  </div>
</div>
<div fxLayout="row" class="genre-view-container" fxFlex fxLayoutAlign="center center" *ngIf="showPagePreloader">
  <app-preloader></app-preloader>
</div>
<div fxLayout="row" class="genre-view-container" fxFlex fxLayoutAlign="center center"
  *ngIf="!showPagePreloader && !pageLoadedSuccessfully">
  Technical issue in loading content. Try later.
</div>

<ng-template #SearchDiv>
  <div>
    <div class="search" [ngClass.xs]="{ xs: true }" fxFlex="200px" fxFlex.xs fxLayoutAlign="start center"
      fxLayoutAlign.sm="end center" fxLayoutAlign.xs="center center">
      <input [(ngModel)]="searchContentTilesModel" (ngModelChange)="onSearchInputChange()" placeholder="Search"
        [ngClass.lt-md]="{ 'searchInputXs': true }" />
      <mat-icon class="search-mat-icon" [ngClass.lt-md]="{ 'searchIconXs': true }" *ngIf="showSearchIcon">search
      </mat-icon>
      <mat-icon class="close-search-icon mousePointer" *ngIf="!showSearchIcon"
        (click)="showSearchIcon = true; searchContentTilesModel = '';filterPackagesList()">clear</mat-icon>
    </div>
    <div fxShow.gt-sm="false" fxShow.lt-md="true">
      <i class="spriteIcons floatRight resetAllIcon" [ngClass.lt-md]="{ 'resetIconBlue': true }"
        (click)="resetAllFilters()"></i>
    </div>
  </div>
</ng-template>
