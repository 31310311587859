import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { PssService } from '../pss.service';
import { PssFiltersService } from '../pss-filters/pss-filters.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PssStatusMessageComponent } from '../pss-status-message/pss-status-message.component';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscriber } from 'rxjs';
import { SystemService } from '../../shared/services/system.service';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Component({
  selector: 'yorbit-certification-requests',
  templateUrl: './certification-requests.component.html',
  styleUrls: ['./certification-requests.component.scss']
})
export class CertificationRequestsComponent implements OnInit, OnDestroy {
  certificationActionedAPISub: any;
  //selected tab inside certification requests -
  selectedTab: string;
  //content index is the index of the starting request in current paginated page
  contentIndex: number;
  //api response
  requests: Array<any>;
  //flag to hide/show preloader
  showPreloader: boolean;
  //flag to show whether filters are applied or not
  filtersApplied: boolean;
  //filters selected from pss filters comp to fetch requests
  certRequestsFilters: any;
  //unique course names list from the fetched requests
  courseNames: Array<string>;
  disableOtherRequest: boolean;
  tileSetup: {
    textAreaInput: any[];
    requestInProgress: any[];
    acceptButtonTitle: any[];
    denyButtonTitle: any[];
    failButtonTitle: any[];

  };
  showCertificationDate: any[];
  showCertificationScore:any[];
  ScoreError:{}[];
  ScoreErrorMsg:{}[];
  certificationTabSubscription: any;
  maxDate: any;
  appUrl: string;
  certificateScoreChanged: string;
  constructor(
    private pssFiltersService: PssFiltersService,
    private _pssService: PssService,
    private _GraphDataService: GraphDataService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _systemSvc: SystemService,
    private _envService: EnvironmentService,
    private snackBar: MatSnackBar,
  ) {
    this.appUrl=this._envService.getEnvironment().appUrl;
    this.certificationActionedAPISub = {};
    this.selectedTab = '';
    this.contentIndex = 0;
    this.requests = [];
    this.showPreloader = false;
    this.filtersApplied = false;
    this.certRequestsFilters = {};
    this.courseNames = [];
    this.disableOtherRequest = false;
    this.showCertificationDate = [];
    this.showCertificationScore=[];
    this.tileSetup = {
      textAreaInput: [],
      requestInProgress: [],
      acceptButtonTitle: [],
      denyButtonTitle: [],
      failButtonTitle: []
    };
    this.certificationTabSubscription = {};
    this.maxDate = new Date();
    this.ScoreErrorMsg = [];
    this.ScoreError = [];
  }

  ngOnInit() {
    this.subscribeToRouterParams();
    //subscribe to the filter options  and call api to get results on every update
    this.subscribeToCertRequestsFilters();
    //subscribe to results loaded status
    this.subscribeToLoadResultsStatus();
  }
  subscribeToRouterParams() {
    this.certificationTabSubscription.routeParamsSub = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.selectedTab = params['type'];
        this.contentIndex = 0;
        this.requests = [];
        this.showPreloader = false;
        this.filtersApplied = false;
        this.certRequestsFilters = {};
        this.courseNames = [];
        this.disableOtherRequest = false;
        this.showCertificationDate = [];
        this.showCertificationScore=[];
        this.tileSetup = {
          textAreaInput: [],
          requestInProgress: [],
          acceptButtonTitle: [],
          denyButtonTitle: [],
          failButtonTitle: []
        };
      }
    );
  }
  subscribeToCertRequestsFilters() {
    this.certificationTabSubscription.certFiltersSub = this.pssFiltersService
      .getCertificateRequestsFilters()
      .subscribe((res: any) => {
        //res wil have filter options and url parameters
        if (res != null) {
          //filters applied
          this.requests = [];
          this.filtersApplied = true;
          //show preloader
          this.showPreloader = true;
          this.certRequestsFilters = res.filters;
          //fetch data from api
          this.getRequests(res.filters, res.urlParams, res.subSelection);
        }
      });
  }
  subscribeToLoadResultsStatus() {
    this.certificationTabSubscription.canResultsLoadStatus = this._pssService
      .getResultsLoadedStatus()
      .subscribe((res: any) => {
        if (res.count == 0) {
          this.contentIndex = 0;
          this.requests = [];
          this.showPreloader = false;
          this.certRequestsFilters = {};
          this.courseNames = [];
          this.disableOtherRequest = false;
          this.showCertificationDate = [];
          this.showCertificationScore=[];
          this.tileSetup = {
            textAreaInput: [],
            requestInProgress: [],
            acceptButtonTitle: [],
            denyButtonTitle: [],
            failButtonTitle: []
          };
        }
        if (!res.status) {
          this.filtersApplied = false;
          this.cancelActionedReqAPICallsInProgresss();
        }
      });
  }
  getRequests(postdata, urlParams, subSelection) {
    this.certificateScoreChanged = '';
    if (this.selectedTab == 'pending') {
      this._pssService
        .getPssCertificatePendingItems(postdata, urlParams)
        .then((res: any) => {
          //hide preloader and show the data
          this.showPreloader = false;
          this._pssService.updateResultsLoadedStatus({
            status: true,
            count: res.count
          });

          //update total pagination count as the length of the paginator
          this.pssFiltersService.updateTotalPaginationCount(res.count);
          //content index
          this.courseNames = [];
          this.contentIndex = urlParams.count;
          //this.requests = res.PSSCertificateRequestItemList;
          this.requests = res.PSSCertificateRequestItemList.filter(item=> item.isCourseCleared == true);
          this.getGraphDetailsOfUsers();
        });
    } else if (this.selectedTab == 'actioned') {
      //cancel any pending apicalls in accepted or denied tabs
      this.certificationActionedAPISub[
        subSelection
      ] = this._pssService
        .getPssCertificateActionedItems(postdata, urlParams, subSelection)
        .subscribe((res: any) => {
          //hide preloader and show the data
          this._pssService.updateResultsLoadedStatus({
            status: true,
            count: res.count
          });
          this.showPreloader = false;
          //update total pagination count as the length of the paginator
          this.pssFiltersService.updateTotalPaginationCount(res.count);
          //content index
          this.contentIndex = urlParams.count;
          this.requests = res.PSSCertificateRequestItemList;
          this.getGraphDetailsOfUsers();
        });
    }
  }
  cancelActionedReqAPICallsInProgresss() {
    for (let subscriberKey in this.certificationActionedAPISub) {
      let subscriber = this.certificationActionedAPISub[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  getGraphDetailsOfUsers() {
    // getting the graph details of user and setting it to the model
    this.requests.forEach((value, key) => {
      if (!this.certRequestsFilters.IsArrangeByDate) {
        if (this.courseNames.indexOf(value.CourseName) == -1) {
          this.courseNames.push(value.CourseName);
          value.showCourseName = true;
        } else {
          value.showCourseName = false;
        }
      } else {
        value.showCourseName = false;
      }
      this.certificationTabSubscription.getUserNameFromMIDSub = this._GraphDataService
        .getUserName(value.MID)
        .subscribe(responseUserName => {
          value.Name = responseUserName.displayName;
        });
      if (null !== value.LastmodifiedBy) {
        this.certificationTabSubscription.getLastmodifiedByNameSub = this._GraphDataService
          .getUserName(value.LastmodifiedBy)
          .subscribe(responseUserName => {
            value.ActionedByName = responseUserName.displayName;
          });
      }
      //set initial values for requests
      this.showCertificationDate[value.RequestId] = true;
      this.showCertificationScore[value.RequestId] = true;
      value.UserGivenCertDate = value.Certification_CompletionDate;
      this.requests[key].Certification_CompletionDateForAPI =
        new Date(value.UserGivenCertDate).getMonth() +
        1 +
        '-' +
        new Date(value.UserGivenCertDate).getDate() +
        '-' +
        new Date(value.UserGivenCertDate).getFullYear();
      value.IsCertificationDateChanged = false;
      this.tileSetup.textAreaInput[value.RequestId] = [];
      this.tileSetup.requestInProgress[value.RequestId] = false;
      this.tileSetup.acceptButtonTitle[value.RequestId] = 'accept';
      this.tileSetup.denyButtonTitle[value.RequestId] = 'send back';
      this.showCertificationScore[value.RequestId] = true;
      value.UserGivenCertDate = value.LearnerScore;
      this.tileSetup.failButtonTitle[value.RequestId] = 'not clear';
    });
  }

  onDateChangeEvent(event, index, dateType) {
    if ('certfication-date' === dateType) {
      this.requests[index].Certification_CompletionDate = event.value;
      let previousDate = new Date(this.requests[index].UserGivenCertDate);
      let changedDate = new Date(
        this.requests[index].Certification_CompletionDate
      );
      if (
        previousDate.getDate() == changedDate.getDate() &&
        previousDate.getMonth() == changedDate.getMonth() &&
        previousDate.getFullYear() == changedDate.getFullYear()
      ) {
        this._pssService.delReqIdFromUnsavedList(
          this.requests[index].RequestId
        );
        this.requests[index].IsCertificationDateChanged = false;
      } else {
        this._pssService.addRequestidToUnsavedList(
          this.requests[index].RequestId
        );
        this.requests[index].IsCertificationDateChanged = true;
      }
      this.requests[index].Certification_CompletionDateForAPI =
        event.value.getMonth() +
        1 +
        '-' +
        event.value.getDate() +
        '-' +
        event.value.getFullYear();
      this.showCertificationDate[this.requests[index].RequestId] = true;
    }
  }
  
  accept(request) {
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.acceptButtonTitle[request.RequestId] = 'accepting...';
    if (
      null === this.tileSetup.textAreaInput[request.RequestId] ||
      this.tileSetup.textAreaInput[request.RequestId].length === 0
    ) {
      this.tileSetup.textAreaInput[request.RequestId] = null;
    }
    const obj = {
      RequestId: request.RequestId,
      AcceptOrDeny: 'ACCEPTED',
      CourseId: request.CourseId,
      CourseUniqueId: request.CourseUniqueId,
      Remarks: this.tileSetup.textAreaInput[request.RequestId],
      LearnerId: request.MID,
      Expertise: request.Expertise,
      // PSS_Score: request.LearnerScore,
      Certi_CompletionDate: request.Certification_CompletionDateForAPI,
      //Learner_Score:request.LearnerScore,
      PSS_Score:request.LearnerScore
    };
    this._pssService
      .takeActionOnPendingRequest(obj)
      .then(response => {
        this.disableOtherRequest = false;
        if (response === 'ACCEPTED') {
          this._pssService.delReqIdFromUnsavedList(request.RequestId);
          this.tileSetup.acceptButtonTitle[request.RequestId] = 'accepted';
          this.openSnackBar("Certificate is approved");
        } else {
          //Failed popup
          // $state.go( "NoAutoCloseModal.popupTmpl", {
          //     'selectedContext': "PSSInternalCertPostActionMessage",
          //     data: {
          //         "message": response
          //     }
          // } );
          this.tileSetup.acceptButtonTitle[request.RequestId] = 'Failed';
          this.tileSetup.requestInProgress[request.RequestId] = false;
          this.disableOtherRequest = false;
          this.openMessagePopup(response);
        }
      })
      .catch(function (error) {
        this.tileSetup.acceptButtonTitle[request.RequestId] = 'Failed';
        this.tileSetup.requestInProgress[request.RequestId] = false;
        this.disableOtherRequest = false;
        this.openMessagePopup(error);
      });
  }
  deny(request) {
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.denyButtonTitle[request.RequestId] = 'processing...';
    const obj = {
      RequestId: request.RequestId,
      AcceptOrDeny: 'DENIED',
      CourseId: request.CourseId,
      CourseUniqueId: request.CourseUniqueId,
      Remarks: this.tileSetup.textAreaInput[request.RequestId],
      LearnerId: request.MID,
      Expertise: request.Expertise,
      Learner_Score:request.LearnerScore,
      PSS_Score:request.LearnerScore,
      Certi_CompletionDate: request.Certification_CompletionDateForAPI
    };
    this._pssService
      .takeActionOnPendingRequest(obj)
      .then(response => {
        this.disableOtherRequest = false;
        if (response === 'DENIED') {
          this._pssService.delReqIdFromUnsavedList(request.RequestId);
          this.tileSetup.denyButtonTitle[request.RequestId] = 'sent back';
          this.openSnackBar("Request is sent back to the learner to re-upload");
        } else {
          this.tileSetup.denyButtonTitle[request.RequestId] = 'Failed';
          this.tileSetup.requestInProgress[request.RequestId] = false;
          this.disableOtherRequest = false;
          this.openMessagePopup(response);
        }
      })
      .catch(function (error) {
        this.tileSetup.denyButtonTitle[request.RequestId] = 'Failed';
        this.disableOtherRequest = false;
        this.tileSetup.requestInProgress[request.RequestId] = false;
        this.openMessagePopup(error);
      });
  }

  downloadCertificate(filePath) {
    if (filePath != null) {
      this._systemSvc.getBlobSasKey(filePath)
        .then((response: any) => {
          try {
            let decodedUri = this._systemSvc.getAtoBDecode(response);
            window.open(decodedUri, '_blank');
          }
          catch{}
        });
    }
    //window.open(filePath, '_blank');
  }
  openMessagePopup(message) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //pssMessageDialogContainer
    dialogConfig.data = {
      message: message
    };
    this.dialog.open(PssStatusMessageComponent, dialogConfig);
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.certificationTabSubscription) {
      let subscriber = this.certificationTabSubscription[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }

  gotoCourseDetailpage(content){
    const webLink= this._envService.getEnvironment().appUrl+'detailsPage/Course/' + content.Expertise + '/' + content.CourseId;
    window.open(webLink, '_blank');
  }
  editScore(requestInProgress, requestId, id) {
  
    if(this.requests[id].LearnerScore == "NULL") {
      this.requests[id].LearnerScore = "";
    }
    if(!requestInProgress[requestId]) {
      this.showCertificationScore[requestId] = false;
    }
  
  }

  scoreChange(requestId, index) {
    this.showCertificationScore[requestId] = true;
    let regexp = /^(?!0+$)\d{1,3}$/;
     let result = false;
     if(this.requests[index].LearnerScore != null){
       result = regexp.test(this.requests[index].LearnerScore);
     }
     if(this.requests[index].LearnerScore ==''){
      this.ScoreError[requestId] = false;
     }
    if(result && !Number.isNaN(this.requests[index].LearnerScore))
    {
      this.ScoreError[requestId] = false;
      this.requests[index].PSSScore = this.requests[index].LearnerScore;
      this.ScoreErrorMsg[requestId]='';
    }
    else if(this.requests[index].LearnerScore)
    {
      this.ScoreError[requestId] = true;
      this.ScoreErrorMsg[requestId]='Please enter a valid score';
    }    
  }
  fail(request) {
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.failButtonTitle[request.RequestId] = 'processing...';
    const obj = {
      RequestId: request.RequestId,
      AcceptOrDeny: 'not cleared',
      CourseId: request.CourseId,
      CourseUniqueId: request.CourseUniqueId,
      Remarks: this.tileSetup.textAreaInput[request.RequestId],
      LearnerId: request.MID,
      Expertise: request.Expertise,
      Certi_CompletionDate: request.Certification_CompletionDateForAPI
    };
    this._pssService
      .takeActionOnPendingRequest(obj)
      .then(response => {
        this.disableOtherRequest = false;
        if (response === 'not cleared') {
          this._pssService.delReqIdFromUnsavedList(request.RequestId);
          this.tileSetup.failButtonTitle[request.RequestId] = 'Not Cleared';
          this.openSnackBar("Retake is enabled for the learner");
        } else {
          this.tileSetup.failButtonTitle[request.RequestId] = 'Failed';
          this.tileSetup.requestInProgress[request.RequestId] = false;
          this.disableOtherRequest = false;
          this.openMessagePopup(response);
        }
      })
      .catch(function (error) {
        this.tileSetup.failButtonTitle[request.RequestId] = 'Failed';
        this.disableOtherRequest = false;
        this.tileSetup.requestInProgress[request.RequestId] = false;
        this.openMessagePopup(error);
      });
  }
  openSnackBar(message:string) {
    let config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'end';
    config.panelClass="pssSnackBar";
    this.snackBar.open(
      message,
      undefined,
      config
    );
  }
}
