import {IContentTileLP,IBadgeDetails,IPackageProgress,IVideoProgressList} from './content-tile-lp.interface';
export const BadgeDetailsModel:IBadgeDetails={
    BadgeId: "",
    BadgeImage: "",
    BadgeName:"",
    CompletedDate: "",
    CourseId:"",
    IsExternalCertificate: false,
    IsFeedbackMandatory: false,
    IsFeedbackSet: false,
    IsRetakeMandatory: false,
    Score:0,
    Status: false,
    UniqueId: null,
    isMigrated: false
};
export const PackageProgressModel:IPackageProgress={
    ConsumedTime: "",
    Progress: "",
    TimeLeft: "",
    TotalTime: ""
};
export const VideoProgressListModel:IVideoProgressList={
    ContentId: "",
    CourseId:"",
    CurrentTime: "",
    Progress:""
};
export const ContentTileLPModel: IContentTileLP ={
    AccountId: null,
    AccountPackage: false,
    AssignedBy:null,
    AssignorRole: null,
    BadgeIds: null,
    Badges: [],
    Category: null,
    CompletedDate:"",
    ContentCount: "",
    CreatedDate: "",
    Declaration: false,
    DeletedDate: "",
    Department: null,
    DueDate: "",
    ImageUrl: "",
    IsBlocked: false,
    IsCancelable: false,
    IsDeleted: false,
    IsInIDP: false,
    IsMandatory: false,
    ItemExpertise: "",
    ItemId: "",
    ItemName: "",
    ItemType: "",
    ModifiedDate: "",
    PackageProgress: PackageProgressModel,
    ProgressList: [],
    ProjectId: null,
    ProjectPackage: false,
    RequestId: null,
    SubStatus: null ,
    VideoCount: 0,
    WorflowStatus: null,
    WorkflowStatus: null
}
