import { Input, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBadge } from '../store/my-page-details.interface';
import { Observable } from 'rxjs';
import * as fromMyPageStore from '../store';
import { Store } from '@ngrx/store';
import { MyPageService } from '../my-page.service';
import {
  OrderbyDatePipe,
  BadgeQuarterPipe,
  ValidDatePipe,
  ExpertisePipe,
} from '@YorbitWorkspace/pipes';

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { LearningBadgesComponent } from '../learning-badges/learning-badges.component';
import { BadgeDetailsComponent } from '../badge-details/badge-details.component';
import { jsPDF } from 'jspdf';
import {
  OpenSansRegularFont,
  OpenSansBoldFont,
  OpenSansSemiBoldFont,
  EdwardianFont,
  list,
} from '../../../assets/font/binaryFonts';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Globals } from '../../globals';

@Component({
  selector: 'yorbit-learning-history',
  templateUrl: './learning-history.component.html',
  styleUrls: ['./learning-history.component.scss'],
})
export class LearningHistoryComponent implements OnInit {
  MId: string;
  role: string;
  historyDetails: Array<IBadge> = [];
  rawData: Array<IBadge> = [];

  firstQuarterHistory: Array<IBadge> = [];
  secondQuarterHistory: Array<IBadge> = [];
  thirdQuarterHistory: Array<IBadge> = [];
  fourthQuarterHistory: Array<IBadge> = [];
  reverseOrderCompletedDate: Array<IBadge> = [];
  minandmax = [];

  selectedYear: number;
  showResultsForYear: number;
  latestDOCYear: number;
  oldDOCYear: number;
  badgesLength: number;
  date: Date;
  filters: any;
  openExpansion: Array<boolean>;
  historyLoading: boolean;
  courseFiltersProperties: Array<string>;
  minDate = new Date();
  maxDate = new Date();
  startDate: { value: any; date: any; month: any; apiFormat: any };
  endDateForm: FormControl;
  startDateForm: FormControl;
  endDate: { value: any; date: any; month: any; apiFormat: any };
  selected = 'All';
  outputList: any;
  expertise: string;
  isMenuOpen: boolean = false;
  expertiselLevelGroup: {
    value: string;
    viewValue: string;
  }[];
  pdf: any = {};
  loadingCourseNames: boolean = false;
  isGoBtnClicked: boolean = false;

  //Suffix:Date Ordinal (st, nd, rd, th)
  nth = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  constructor(
    private route: ActivatedRoute,
    private _myPageSvc: MyPageService,
    private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    private badgeQuarterPipe: BadgeQuarterPipe,
    private orderbyDatePipe: OrderbyDatePipe,
    private validDatePipe: ValidDatePipe,
    private expertisePipe: ExpertisePipe,
    private _dialog: MatDialog,
    public datePipe: DatePipe,
    private _router: Router,
    private globals: Globals,
  ) {
    this.courseFiltersProperties = ['expertise'];
  }

  ngOnInit() {
    this.date = new Date();
    this.selectedYear = this.date.getFullYear();
    this.showResultsForYear = this.date.getFullYear();
    this.firstLoadInit();
    // this.startDate.value = this.date;
    // this.endDate.value = this.date;
    this.endDateForm = new FormControl(new Date());
    this.startDateForm = new FormControl(new Date());

    this.route.params.subscribe((params) => {
      this.MId = params.id;
      this.role = params.role;
      this.getHistoryDetails();
    });

    this.openExpansion = new Array(4);
    this.openExpansion[0] = false;
    this.openExpansion[1] = false;
    this.openExpansion[2] = false;
    this.openExpansion[3] = false;
    // this.initialiseVariables();
    // this.filtersInit();
    // this.setMinAndMaxDates();
  }

  dateInit() {
    const today = new Date();
    this.endDateForm = new FormControl(new Date(today));
    this.setDateValue(today, 'end');
    this.startDateForm = new FormControl(
      new Date(today.setDate(today.getDate() - 1)).toISOString()
    );
    this.setDateValue(today, 'start');
  }

  getHistoryDetails() {
    if (this.role === 'self') {
      this.myPageStore
        .select(fromMyPageStore.getMyPageHistoryLoading)
        .subscribe((data) => {
          this.historyLoading = data;
        });
      this.myPageStore
        .select(fromMyPageStore.getMyPageHistory)
        .subscribe((data) => {
          this.historyDetails = data;
          this.rawData = data;
          if (this.historyDetails.length > 0) {
            this.initialiseVariables();
            this.filtersInit();
            this.setMinAndMaxDates();
            if (this.historyDetails.length > 0) {
              this.getDOCYears(this.historyDetails);
              this.filterByYear(this.showResultsForYear, null);
            }
          } else {
            this.setDefaultStartAndEndDate();
          }
        });
    } else if (this.role === 'learner') {
      this.historyLoading = true;
      this._myPageSvc.getBadgesListOfLearner(this.MId, 'learner').subscribe(
        (data) => {
          this.historyDetails = data;
          this.rawData = data;
          this.historyLoading = false;
          if (this.historyDetails.length > 0) {
            this.setMinAndMaxDates();
            this.initialiseVariables();
            this.filtersInit();
            // const validDateBadgeList: Array<any> = this.validDatePipe.transform(
            //   this.historyDetails as any
            // );
            if (this.historyDetails.length > 0) {
              this.getDOCYears(this.historyDetails);
              this.filterByYear(this.showResultsForYear, null);
            }
          } else {
            this.setDefaultStartAndEndDate();
          }
        },
        (error) => { 
          console.log("Don't have permission");
          this._router.navigate(['/my-page/', this.globals.EmpId, 'self']);
        }
      );
    }
  }

  initialiseVariables() {
    //date vairables init
    this.dateVariablesInit();
  }

  filtersInit() {
    //overall filter options is listed in variable filters
    this.filters = {
      Expertise: [],
      StartDate: this.startDate.value,
      EndDate: this.endDate.value,
    };
  }

  dateVariablesInit() {
    // this.endDateForm = new FormControl('');
    //  this.startDateForm = new FormControl('');
    this.startDate = {
      value: '',
      date: '',
      month: '',
      apiFormat: '',
    };
    this.endDate = {
      value: '',
      date: '',
      month: '',
      apiFormat: '',
    };
  }

  //set dates chosen in specific format
  setDateValue(value, dateType) {
    if ('start' === dateType) {
      this.startDate = {
        value: value,
        date: value.getDate(),
        month: value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:
          value.getMonth() +
          1 +
          '-' +
          value.getDate() +
          '-' +
          value.getFullYear(),
      };
    } else if ('end' === dateType) {
      let nextDay = new Date(value);
      nextDay.setDate(value.getDate() + 1);
      this.endDate = {
        value: value,
        date: value.getDate(),
        month: value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:
          nextDay.getMonth() +
          1 +
          '-' +
          nextDay.getDate() +
          '-' +
          nextDay.getFullYear(),
      };
    }
    // console.log("StartDate.Value===new DAte:", this.startDate.value, new Date(value).toDateString());
    // console.log("StartDate.Value===new DAte:", this.startDate.value.toDateString() === new Date(value).toDateString());
    if (this.startDate.value.toDateString() === new Date().toDateString()) {
      this.startDateForm = new FormControl(new Date());
      this.endDateForm = new FormControl(new Date());
    } else {
      this.startDateForm = new FormControl(new Date(this.startDate.value));
      this.endDateForm = new FormControl(new Date(this.endDate.value));
    }
  }
  //on date change event triggered by date picker
  onDateChangeEvent(event, dateType) {
    this.isGoBtnClicked = false;
    this.setDateValue(event.value, dateType);
    this.filters = {
      ...this.filters,
      StartDate: this.startDate.apiFormat,
      EndDate: this.endDate.apiFormat,
    };
    //this.historyDetails = [];
    //this.rawData.forEach((row, index) => {
    //this.historyDetails.push(row);
    //});
  }

  filterByYear(selectedYear, moveForward) {
    let year;
    if (moveForward == null) {
      year = selectedYear;
    } else if (moveForward === true) {
      year = selectedYear + 1;
    } else if (moveForward === false) {
      year = selectedYear - 1;
    }

    if (year >= this.oldDOCYear && year <= this.latestDOCYear) {
      this.selectedYear = year;
      this.firstQuarterHistory = this.badgeQuarterPipe.transform(
        this.historyDetails,
        year,
        'Q1'
      );
      this.secondQuarterHistory = this.badgeQuarterPipe.transform(
        this.historyDetails,
        year,
        'Q2'
      );
      this.thirdQuarterHistory = this.badgeQuarterPipe.transform(
        this.historyDetails,
        year,
        'Q3'
      );
      this.fourthQuarterHistory = this.badgeQuarterPipe.transform(
        this.historyDetails,
        year,
        'Q4'
      );
      this.setColor(this.fourthQuarterHistory);
    } else {
      //  vmLearningHistory.selectedYear = year;
    }
  }
  firstLoadInit() {
    this.expertiselLevelGroup = [
      {
        value: 'all',
        viewValue: 'All',
      },
      {
        value: 'external learning',
        viewValue: 'External Learning',
      },
      {
        value: '101',
        viewValue: '101',
      },
      {
        value: '201',
        viewValue: '201',
      },
      {
        value: '301',
        viewValue: '301',
      },
    ];
  }

  getDOCYearsOld(badges) {
    this.reverseOrderCompletedDate = this.orderbyDatePipe.transform(
      badges,
      'CompletedDate'
    );
    if (this.reverseOrderCompletedDate.length > 0) {
      this.badgesLength = this.reverseOrderCompletedDate.length;
      this.latestDOCYear = new Date(
        this.reverseOrderCompletedDate[0].CompletedDate
      ).getFullYear();
      this.oldDOCYear = new Date(
        this.reverseOrderCompletedDate[this.badgesLength - 1].CompletedDate
      ).getFullYear();
      this.selectedYear = this.latestDOCYear;
      this.showResultsForYear = this.latestDOCYear;
    } else {
      this.latestDOCYear = this.date.getFullYear();
      this.oldDOCYear = this.date.getFullYear();
      this.selectedYear = this.latestDOCYear;
      this.showResultsForYear = this.latestDOCYear;
    }
  }
  getDOCYears(badges) {
    if (badges.length > 0) {
      this.badgesLength = badges.length;
      this.latestDOCYear = new Date(
        badges[this.badgesLength - 1].CompletedDate
      ).getFullYear();
      this.oldDOCYear = new Date(badges[0].CompletedDate).getFullYear();
      this.selectedYear = this.latestDOCYear;
      this.showResultsForYear = this.latestDOCYear;
    } else {
      this.latestDOCYear = this.date.getFullYear();
      this.oldDOCYear = this.date.getFullYear();
      this.selectedYear = this.latestDOCYear;
      this.showResultsForYear = this.latestDOCYear;
    }
  }

  setColor(badgeList) {
    const _101Array = this.expertisePipe.transform(badgeList, ['101']);
  }

  openDialog(context, quarter) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      context: context,
      mid: this.MId,
      year: this.selectedYear,
      quarter: quarter,
    };
    dialogConfig.panelClass = 'popupDialogContainer';
    this._dialog.open(LearningBadgesComponent, dialogConfig);
  }

  open(context) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      context: context,
      mid: this.MId,
    };
    this._dialog.open(BadgeDetailsComponent, dialogConfig);
  }

  spaceTrim(input) {
    if (input) {
      return input.toString().trim();
    } else {
      return '';
    }
  }
  selectExpertise(expertise) {
    this.isGoBtnClicked = false;
    this.selected = expertise.viewValue;
  }

  getBadgesFilter() {
    this.isGoBtnClicked = true;
    this.outputList = [];
    this.rawData.forEach((badge, index) => {
      let completedDate = new Date(
        new Date(badge.CompletedDate).toDateString()
      );

      let startDate = new Date(new Date(this.startDate.value).toDateString());
      let endDate = new Date(new Date(this.endDate.value).toDateString());
      if (this.selected.toLowerCase() == 'all') {
        if (completedDate >= startDate && completedDate <= endDate) {
          this.outputList.push(badge);
        }
      } else if (this.selected.toLowerCase() == 'external learning') {
        if (
          (badge.CourseId == '' || badge.CourseId == null) &&
          completedDate >= startDate &&
          completedDate <= endDate
        ) {
          this.outputList.push(badge);
        }
      } else if (
        completedDate >= startDate &&
        completedDate <= endDate &&
        this.selected == badge.Expertise
      ) {
        this.outputList.push(badge);
      }
    });
    this.selectedYear = this.date.getFullYear();
    this.showResultsForYear = this.date.getFullYear();
    this.historyDetails = this.outputList;
    this.getDOCYears(this.historyDetails);
    this.filterByYear(this.showResultsForYear, null);
  }

  setMinAndMaxDates() {
    this.historyDetails.forEach((element) => {
      let d = new Date(element.CompletedDate);
      this.minandmax.push(d);
    });
    this.minandmax.sort((a, b) => a - b);
    let startDate =
      this.minandmax.length > 0 ? new Date(this.minandmax[0]) : new Date();
    let endDate =
      this.minandmax.length > 0
        ? new Date(this.minandmax[this.minandmax.length - 1])
        : new Date();
    //If there are no badges, set the start date as current day and end date as current day
    this.minDate = this.minandmax.length > 0 ? this.minandmax[0] : new Date();
    this.maxDate =
      this.minandmax.length > 0
        ? this.minandmax[this.minandmax.length - 1]
        : new Date();
    this.setDateValue(startDate, 'start');
    this.setDateValue(endDate, 'end');
  }

  getCourseNames() {
    this.loadingCourseNames = true;
    let listOfCourseIds = [];
    if (this.historyDetails.length > 0) {
      this.historyDetails.forEach((row, index) => {
        if (row.CourseId != null) {
          listOfCourseIds.push(row.CourseId);
        }
      });

      this._myPageSvc.getCourseNames(listOfCourseIds).subscribe(
        (data) => {
          let inputForDownload = [];

          this.historyDetails.forEach((badge, index) => {
            let badgeDetail = badge;
            if (badge.CourseId != null) {
              badgeDetail.CourseName = data
                .filter(
                  (x) => x.CourseId.toString() == badge.CourseId.toString()
                )[0]
                .CourseName.trim();
            } else {
              badgeDetail.CourseName = badge.BadgeName;
            }
            inputForDownload.push(badgeDetail);
          });

          if (inputForDownload.length > 0) {
            this.downloadCertificate(inputForDownload);
          }
          this.loadingCourseNames = false;
        },
        (error) => {
          this.loadingCourseNames = false;
        }
      );
    }
  }

  downloadCertificate(list) {
    let l = list;
    let list_101 = [];
    let list_201 = [];
    let list_301 = [];
    let list_Degreed = [];
    let list_external = [];
    let declaredBadgeCount = 0;

    l.forEach((badge, index) => {
      if (badge.IsDeclared) {
        declaredBadgeCount++;
      }
      switch (badge.RepoType == 'Degreed'? badge.RepoType : badge.Expertise) {
        case '101':
          list_101.push(badge);
          break;
        case '201':
          list_201.push(badge);
          break;
        case '301':
          list_301.push(badge);
          break;
        case 'Degreed':
          list_Degreed.push(badge);
          break;
        default:
          list_external.push(badge);
          badge.CourseName = badge.BadgeName;
          break;
      }
    });

    let doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4',
      putOnlyUsedFonts: false,
    });

    //  doc.setDocumentProperties({'title': 'M1032547'});

    this.addFontsToDoc(doc);

    this.pdf = {
      pageWidth: doc.internal.pageSize.getWidth(),
      pageHeight: doc.internal.pageSize.getHeight(),
      marginOffset: 30,
      fontSize: 8,
      paddingTop: 10,
      totalConsumedPageHeight: 0,
      x_axis: 0,
      y_axis: 0,
      declareIconWd: 11,
      declareIconHt: 10,
    };

    this.pdf.totalConsumedPageHeight =
      this.pdf.marginOffset + this.pdf.paddingTop;

    this.setPageBG(doc);
    this.addMindtreeLogo(doc, this.pdf.marginOffset);
    this.addYorbitLogo(doc, this.pdf.marginOffset, this.pdf.pageWidth);
    this.displayProfile(
      doc,
      this.pdf.marginOffset,
      this.pdf.pageWidth,
      declaredBadgeCount
    );

    let newElmHeight = 0;
    let endOfPage = this.isEndOfPage(
      this.pdf.marginOffset,
      this.pdf.pageHeight,
      this.pdf.totalConsumedPageHeight,
      newElmHeight,
      doc
    );

    if (list_101.length > 0) {
      this.displayExpertiseCategory(
        doc,
        this.pdf.marginOffset,
        this.pdf.pageWidth,
        this.pdf.pageHeight,
        '101'
      );
      this.displayCourses(doc, list_101);
    }

    if (list_201.length > 0) {
      newElmHeight = 50;
      endOfPage = this.isEndOfPage(
        this.pdf.marginOffset,
        this.pdf.pageHeight,
        this.pdf.totalConsumedPageHeight,
        newElmHeight,
        doc
      );
      if (endOfPage) {
        doc.addPage();
        this.setPageBG(doc);
        this.pdf.totalConsumedPageHeight =
          this.pdf.marginOffset + this.pdf.paddingTop;
      }
      this.displayExpertiseCategory(
        doc,
        this.pdf.marginOffset,
        this.pdf.pageWidth,
        this.pdf.pageHeight,
        '201'
      );
      this.displayCourses(doc, list_201);
    }

    if (list_301.length > 0) {
      newElmHeight = 50;
      endOfPage = this.isEndOfPage(
        this.pdf.marginOffset,
        this.pdf.pageHeight,
        this.pdf.totalConsumedPageHeight,
        newElmHeight,
        doc
      );
      if (endOfPage) {
        doc.addPage();
        this.setPageBG(doc);
        this.pdf.totalConsumedPageHeight =
          this.pdf.marginOffset + this.pdf.paddingTop;
      }
      this.displayExpertiseCategory(
        doc,
        this.pdf.marginOffset,
        this.pdf.pageWidth,
        this.pdf.pageHeight,
        '301'
      );
      this.displayCourses(doc, list_301);
    }

    if (list_Degreed.length > 0) {
      newElmHeight = 50;
      endOfPage = this.isEndOfPage(
        this.pdf.marginOffset,
        this.pdf.pageHeight,
        this.pdf.totalConsumedPageHeight,
        newElmHeight,
        doc
      );
      if (endOfPage) {
        doc.addPage();
        this.setPageBG(doc);
        this.pdf.totalConsumedPageHeight =
          this.pdf.marginOffset + this.pdf.paddingTop;
      }
      this.displayExpertiseCategory(
        doc,
        this.pdf.marginOffset,
        this.pdf.pageWidth,
        this.pdf.pageHeight,
        'Degreed'
      );
      this.displayCourses(doc, list_Degreed);
    }

    if (list_external.length > 0) {
      newElmHeight = 50;
      endOfPage = this.isEndOfPage(
        this.pdf.marginOffset,
        this.pdf.pageHeight,
        this.pdf.totalConsumedPageHeight,
        newElmHeight,
        doc
      );
      if (endOfPage) {
        doc.addPage();
        this.setPageBG(doc);
        this.pdf.totalConsumedPageHeight =
          this.pdf.marginOffset + this.pdf.paddingTop;
      }
      this.displayExpertiseCategory(
        doc,
        this.pdf.marginOffset,
        this.pdf.pageWidth,
        this.pdf.pageHeight,
        'external'
      );
      this.displayCourses(doc, list_external);
    }

    var blob = doc.output('blob');
    const url = URL.createObjectURL(blob);
    //window.open(url);
    URL.revokeObjectURL(url);
    doc.save('My Learnings Certificate.pdf');
  }

  addFontsToDoc(doc) {
    doc.addFileToVFS('EdwardianScriptITC-normal.ttf', EdwardianFont);
    doc.addFont(
      'EdwardianScriptITC-normal.ttf',
      'edwardian-script-ITC',
      'normal'
    );

    doc.addFileToVFS('OpenSans-SemiBold-normal.ttf', OpenSansSemiBoldFont);
    doc.addFont(
      'OpenSans-SemiBold-normal.ttf',
      'open-sans-semi-bold',
      'normal'
    );

    doc.addFileToVFS('OpenSans-Regular-normal.ttf', OpenSansRegularFont);
    doc.addFont('OpenSans-Regular-normal.ttf', 'open-sans-regular', 'normal');

    doc.addFileToVFS('OpenSans-Bold-normal.ttf', OpenSansBoldFont);
    doc.addFont('OpenSans-Bold-normal.ttf', 'open-sans-bold', 'normal');
  }

  addMindtreeLogo(doc, marginOffset) {
    let MindtreeLogoWidth = 100;
    let MindtreeLogoHeight = 37;
    this.pdf.totalConsumedPageHeight += 20;

    doc.addImage(
      // './../../assets/images/Logo_Full_Tag.png',
      './../../assets/images/LTIMindtree_Logo.png',
      'PNG',
      marginOffset - 10,
      marginOffset - 5,
      MindtreeLogoWidth,
      MindtreeLogoHeight
    );
  }

  addYorbitLogo(doc, marginOffset, pageWidth) {
    // let YorbitLogoWidth = 80;
    // let YorbitLogoHeight = 14;

    let YorbitLogoWidth = 100;
    let YorbitLogoHeight = 25;
    
    this.pdf.totalConsumedPageHeight += YorbitLogoHeight;

    doc.addImage(
      './../../assets/images/Yorbit-Identity.png',
      'PNG',
      pageWidth - (marginOffset + YorbitLogoWidth),
      marginOffset + 5, // plus 5px to match the mindtree logo placing
      YorbitLogoWidth,
      YorbitLogoHeight
    );
  }

  displayProfile(doc, marginOffset, pageWidth, declaredBadgeCount) {
    let fontSize = 9;
    let padding = 1;
    let userName = this._myPageSvc.graphData.displayName;
    let userJobTitle = this._myPageSvc.graphData.jobTitle;
    let userMID = this._myPageSvc.graphData.MId;
    if(this.role == "self") {
      userMID = this.globals.MId;
    }
    //Name of the Mind
    doc.setFont('open-sans-semi-bold', 'normal');
    doc.setFontSize(fontSize);
    doc.text('Name : ', marginOffset, this.pdf.totalConsumedPageHeight);

    doc.setFont('open-sans-regular', 'normal');
    let lines = doc.splitTextToSize(userName, 200);
    let lineHt = doc.getTextDimensions(lines);
    doc.text(lines, marginOffset + 25, this.pdf.totalConsumedPageHeight);

    //Duration
    let startDate = this.formatDate(
      new Date(this.historyDetails[0].CompletedDate),
      'dd MMMM yyyy'
    );
    let endDate = this.formatDate(
      new Date(
        this.historyDetails[this.historyDetails.length - 1].CompletedDate
      ),
      'dd MMMM yyyy'
    );
    let duration = startDate + ' to ' + endDate;
    let durationLabelLength = 'Duration'.toString().length;
    let durationTextLength = duration.length;

    doc.setFont('open-sans-semi-bold', 'normal');
    doc.setFontSize(fontSize);
    let lineSpacing = 4.2;

    doc.text(
      'Duration : ',
      this.pdf.pageWidth -
      (durationTextLength + durationLabelLength) * lineSpacing,
      this.pdf.totalConsumedPageHeight
    );
    doc.setFont('open-sans-regular', 'normal');
    doc.text(
      duration,
      this.pdf.pageWidth - durationTextLength * lineSpacing,
      this.pdf.totalConsumedPageHeight
    );
    this.pdf.totalConsumedPageHeight += fontSize * lines.length;

    //MID of the Mind
    this.pdf.totalConsumedPageHeight += padding;
    doc.setFont('open-sans-semi-bold', 'normal');
    doc.setFontSize(fontSize);
    doc.text('PSID : ', marginOffset, this.pdf.totalConsumedPageHeight);

    doc.setFont('open-sans-regular', 'normal');
    doc.text(userMID, marginOffset + 20, this.pdf.totalConsumedPageHeight);
    this.pdf.totalConsumedPageHeight += fontSize;

    //Designation of the Mind
    this.pdf.totalConsumedPageHeight += padding;
    doc.setFont('open-sans-semi-bold', 'normal');
    doc.setFontSize(fontSize);
    doc.text('Designation : ', marginOffset, this.pdf.totalConsumedPageHeight);

    doc.setFont('open-sans-regular', 'normal');
    doc.text(userJobTitle, marginOffset + 45, this.pdf.totalConsumedPageHeight);

    //Declared Skills Legend
    if (declaredBadgeCount > 0) {
      doc.setFont('open-sans-semi-bold', 'normal');
      doc.setFontSize(fontSize);
      doc.text(
        'Declared Skills',
        pageWidth - marginOffset - 65,
        this.pdf.totalConsumedPageHeight
      );
      doc.addImage(
        './../../assets/images/Badges/declare_icon.png',
        'PNG',
        pageWidth - marginOffset - 15,
        this.pdf.totalConsumedPageHeight - 8,
        this.pdf.declareIconWd,
        this.pdf.declareIconHt
      );
    }

    this.pdf.totalConsumedPageHeight += fontSize + 6;
    //this.pdf.totalConsumedPageHeight += 6;
  }

  displayExpertiseCategory(
    doc,
    marginOffset,
    pageWidth,
    pageHeight,
    expertise
  ) {
    let BadgeWidth = 32;
    let BadgeHeight = 32;
    let badgeImageURL = '';

    if (expertise == '101') {
      badgeImageURL = './../../assets/images/Badges/badge_101.jpg';
    } else if (expertise == '201') {
      badgeImageURL = './../../assets/images/Badges/badge_201.jpg';
    } else if (expertise == '301') {
      badgeImageURL = './../../assets/images/Badges/badge_301.jpg';
    } else if (expertise == 'Degreed'){
      badgeImageURL = './../../assets/images/Badges/badge_degreed.jpg';
    } else {
      badgeImageURL = './../../assets/images/Badges/badge_external_learning.jpg';
    }

    let categoryTitle = '';
    if (expertise != 'external') {
      categoryTitle = 'Expertise level : ' + expertise;
    } else {
      categoryTitle = 'External Learning : ';
    }

    let fontColor = '#6d7f91';
    switch (expertise) {
      case '101':
        fontColor = '#2374cd';
        break;
      case '201':
        fontColor = '#22b1a8';
        break;
      case '301':
        fontColor = '#f99723';
        break;
      case 'Degreed':
        fontColor = '#312e7f';
        break;
      default:
        fontColor = '#6d7f91';
    }

    doc.addImage(
      badgeImageURL,
      'PNG',
      marginOffset,
      this.pdf.totalConsumedPageHeight,
      BadgeWidth,
      BadgeHeight
    );

    doc.setFont('open-sans-bold', 'normal');
    doc.setTextColor(fontColor);
    doc.setFontSize(14);
    doc.text(
      categoryTitle,
      marginOffset + 40,
      this.pdf.totalConsumedPageHeight + BadgeHeight / 2 + 8 // BadgeHeight/2 + 8 px is to position the text in alignment with the badge image
    );

    doc.setTextColor('#000000');
    doc.setFontSize(8);
    doc.text(
      'Date of Completion :',
      pageWidth - 135,
      this.pdf.totalConsumedPageHeight + BadgeHeight / 2 + 8 // BadgeHeight/2 + 8 px is to position the text in alignment with the badge image
    );

    this.pdf.totalConsumedPageHeight =
      this.pdf.totalConsumedPageHeight + BadgeHeight + 8; //+8 px is to apply some padding below the title
  }

  //Date Ordinal
  formatDate(date, format) {
    const result = new Date(date);
    format = 'd MMMM YYYY';
    let newDate = new DatePipe('en-US').transform(result, format).split(' ');
    newDate[0] += this.nth(newDate[0]);
    return newDate.join(' ');
  }

  isEndOfPage(
    marginOffset,
    pageHeight,
    totalConsumedPageHeight,
    newElmHeight,
    doc
  ) {
    if (pageHeight > totalConsumedPageHeight + newElmHeight + marginOffset) {
      //add the element in same page
      return false;
    } else {
      //move to new page
      return true;
    }
  }

  setPageBG(doc) {
    this.pdf.totalConsumedPageHeight =
      this.pdf.marginOffset + this.pdf.paddingTop;
    // doc.setDrawColor('#bdbcbc');
    // doc.setLineWidth(0.1);
    // doc.line(20, 20, 20, this.pdf.pageHeight - 20);
    // doc.line(
    //   this.pdf.pageWidth - 20,
    //   20,
    //   this.pdf.pageWidth - 20,
    //   this.pdf.pageHeight - 20
    // );
    // doc.line(20, 20, this.pdf.pageWidth - 20, 20);
    // doc.line(
    //   20,
    //   this.pdf.pageHeight - 20,
    //   this.pdf.pageWidth - 20,
    //   this.pdf.pageHeight - 20
    // );

    doc.addImage(
      './../../assets/images/learning_history_cert_bg_image.jpg',
      'PNG',
      0, //+10 is to adjust the position of the declare icon along the row
      0, // -8 is to adjust the position of the declare icon along the column
      this.pdf.pageWidth,
      this.pdf.pageHeight
    );
  }

  displayCourses(doc, list) {
    list.forEach((badge, index) => {
      this.pdf.x_axis = this.pdf.marginOffset + 40;
      this.pdf.y_axis = this.pdf.totalConsumedPageHeight;

      //  if(badge.CourseName)
      let courseName = doc.splitTextToSize(badge.CourseName, 210);

      let newElmHeight = courseName.length * this.pdf.fontSize;
      let paddingForRow = 8;
      //this.pdf.y_axis += paddingForRow;

      let endOfPage = this.isEndOfPage(
        this.pdf.marginOffset,
        this.pdf.pageHeight,
        this.pdf.totalConsumedPageHeight + paddingForRow,
        newElmHeight,
        doc
      );

      doc.setTextColor('black');
      if (endOfPage) {
        doc.addPage();
        this.setPageBG(doc);
        this.pdf.x_axis = this.pdf.marginOffset + 40;
        this.pdf.y_axis = this.pdf.totalConsumedPageHeight;
      }
      if (badge.IsDeclared) {
        doc.addImage(
          './../../assets/images/Badges/declare_icon.png',
          'PNG',
          this.pdf.marginOffset + 10, //+10 is to adjust the position of the declare icon along the row
          this.pdf.y_axis - 8, // -8 is to adjust the position of the declare icon along the column
          this.pdf.declareIconWd,
          this.pdf.declareIconHt
        );
      }
      doc.setFontSize(9);
      // Serial Number
      doc.setFont('open-sans-semi-bold', 'normal');
      doc.text((index + 1).toString() + '.', this.pdf.x_axis, this.pdf.y_axis);
      // Course Name
      doc.setFont('open-sans-regular', 'normal');
      doc.text(courseName, this.pdf.x_axis + 16, this.pdf.y_axis); // +14 is to move the course name with a 14px gap from index number
      // Date of completion
      doc.setFont('open-sans-regular', 'normal');
      doc.text(
        this.formatDate(badge.CompletedDate, 'dd MMMM yyyy'),
        this.pdf.pageWidth - 135,
        this.pdf.y_axis
      );
      let htOfCurrentRow =
        courseName.length * this.pdf.fontSize + paddingForRow;
      this.pdf.totalConsumedPageHeight += htOfCurrentRow; // Adding the height of the current row to the total consumed page height
    });
  }

  setDefaultStartAndEndDate() {
    const today = new Date();
    //this.endDateForm = new FormControl(new Date(today));
    //this.startDateForm = new FormControl(new Date(today));
    this.setDateValue(today, 'start');
    this.setDateValue(today, 'end');
    
  }
}
