import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AoComponent} from './ao.component';
import { ContentUploadComponent } from './content-upload/content-upload.component';
import { ContentVerificationComponent } from '../content-verification/content-verification.component';

const routes: Routes = [
  {
  path: '',
    children: [
      {
        path:"",
        component: AoComponent
      },
      { path: "contentupload", component: ContentUploadComponent},
      {
        path: 'contentupload/:courseUniqueId/:status',
        component: ContentUploadComponent
      },
      {
        path: 'contentuploadGrid/:role',
        component: ContentVerificationComponent
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AoRoutingModule { }
