<mat-dialog-content>
  <!-- <div class="headerTitle">PSS Certificate Request Error</div> -->
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="popup-content">
    <div class="message">
      {{data.message}}
    </div>
    <div class="action-btn">
      <button (click)="closePopup()" class="action-button">Ok</button>
    </div>
  </div>
</mat-dialog-content>