import { Component, Input, OnInit,OnChanges } from '@angular/core';
import{PssEvaluationService} from '../pss-evaluation.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';



import { Injectable } from "@angular/core";

@Component({
  selector: 'yorbit-download-evaluation-sheet',
  templateUrl: './download-evaluation-sheet.component.html',
  styleUrls: ['./download-evaluation-sheet.component.scss']
})
export class DownloadEvaluationSheetComponent implements OnInit {

  courseId:any;
  courseStatus: any;
  fileName:string;
  isEvaluationTemplateAvailable: boolean;
  downloadStatus: string;
  showPreloader: boolean;
  contentLoadedSuccessfully: boolean;

  constructor(
    private router: Router,
    //private http: http,
    private activatedRoute: ActivatedRoute,
    private pssEvaluationService: PssEvaluationService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>

  ) { 
    this.courseStatus = 'Loading...';
    this.fileName="EvaluationTemplate";
    this.isEvaluationTemplateAvailable = false;
    
    this.showPreloader = true;
    this.contentLoadedSuccessfully = false;
  }

  ngOnInit() {
  }

downloadEvaluationSheet(){
  this.courseId='DI_1141';
  ////console.log('courseid', this.courseId, this.isEvaluationTemplateAvailable);
  if(this.courseId != null || this.courseId !='undefined')
  {
    this.downloadStatus='Downloading';

  }

  this.pssEvaluationService.getEvaluationTemplate(this.courseId).then((response:any)=>{
    var link = document.createElement('a');
    link.style.display='none';
    document.body.appendChild(link);
    link.href=response;
    link.download=response.substr(response.lastIndexOf('/')+1);
    link.click();
    this.downloadStatus='Evaluation template downloaded';
  })
  .catch(err=>{
    this.downloadStatus='Unable to download the evaluation summary sheet. Please retry after some time';
  })
}
}
