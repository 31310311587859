export interface PssEvaluation {
}

export interface CheckboxList {
    value: string;
    isSelected: boolean;
}

export interface CourseAcademy {
    CourseName: string;
    Academy: string;
    IsSelected: boolean;
}

export interface CheckboxDisableList {
    value: string;
    isSelected: boolean;
    isEnabled: boolean;
}

export interface EvaluatorDetails {
    MID: string;
    EvaluatorName: string;
}

export interface YorbitCourseRequestList {
    RequestId: number;
    CourseName: string;
    Academy: string;
    CourseVendor: string;
    EvaluatorVendor: string;
    LearningOPMSPOC: string;
    CertificationSubStatus: string;
    CourseType: string;
    IsSelected: boolean;
    IsEnabled: boolean;
    EvaluatorType: string;
}

export class InputParameters {
    StartDate: any;
    EndDate: any;
    Academy: string[];
    CourseUID: string[];
    CourseType: string[];
    CourseVendor: string[];
    EvaluatorVendor: string[];
    SPOC: string[];
    SubStatus: string[];
    SearchMid: string[];
    SearchRequestId: number[];
    EvaluationType: string;
    Evaluator: string[];
    DueForPublish: boolean;
    SLAHitRequest: boolean;
    AssignmentRequest: boolean;
    ProjectRequest: boolean;
}

export class EmailAlertList {
    EvaluationRequestId: number;
    RequestId: number;
    CourseName: string;
    EvaluatorName: string;
    EvaluatorEmail: string;
    MID: string;
    SubmissionDate: Date;
    SLAHitDate: Date;
    DaysPostSLA: number;
}

export class PublishResult {
    EvaluationRequestId: number;
    RequestId: number;
    RequestType: string;
    Result: string;
    Scorecard: string;
    Score: string;
    LearnerId: string;
    CourseName: string;
    MaximumScore: number;
    Comments: string;
}

export class PublishSelectedPopupDataList {
    EvaluationRequestId: number;
    IsEditedByOPM: boolean;
    RequestId: number;
    Score: number;
    Result: string;
    FieldsEdited: string;
}

export class UpdateScoreDetails {
    EvaluationRequestId: number;
    RequestType: string;
    Comments: string;
    Score: number;
    Result: string;
}

export class GridColumnResults{
    RequestType:any;
    RequestId:any;
    MID:string;
    CourseName:string;
    CourseType:string;
    CourseUniqueId:string;
    Academy:string;
    CourseVendor:string;
    EvaluationType:string;
    EvaluatorName:string;
    EvaluatorVendor:string;
    SubStatus:string;
    SubmissionDate:any;
    EvaluationDate:any;
    SPOC:string;
    Score:any;
    SolutionFile:string;
}

export class PssLog {
    RequestId: number;
    FieldsEdited: string;
    ValueBeforeEdit: string;
    ValueAfterEdit: string;
}
