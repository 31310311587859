import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopCoachesComponent } from './top-coaches/top-coaches.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoachesAndFacilitatorPopupComponent } from './coaches-and-facilitator-popup/coaches-and-facilitator-popup.component';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';


@NgModule({
  declarations: [TopCoachesComponent, CoachesAndFacilitatorPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReusableUiModule 
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TopCoachesComponent],
 
})
export class TopCoachesModule { }
