<div class="container" fxLayout="column">
  <div class="header-container">

    <img src="../../assets/images/riskbrigade/home-image1.jpg"  alt="banner" width="100%">
  
    <img src="../../assets/images/riskbrigade/puzzle.png" class="puzzle">
  
    <span class="header-text">
       A focused organization wide learning initiative designed in partnership with Delivery Excellence Team, to build a specialized cohort of
                <b>“Risk Brigade Members”</b> who will  effectively evaluate risks from the time of pursuit to execution.

    </span>

    <div class="course-info-stripe" fxLayout="row" fxLayoutAlign="center center" >
        <img src="../../assets/images/riskbrigade/flag1.png" alt="">
        <span>Foundation Courses (P4 and above)</span>
        <img  src="../../assets/images/riskbrigade/flag2.png" alt="">
        <span >Advance Courses (P5 and above)</span>
    </div>
  </div>
  
<div class="video-speaker-container">
            <!-- <div class="homePageText">
               A focused organization wide learning initiative designed in partnership with Delivery Excellence Team, to build a specialized cohort of
                <b>“Risk Brigade Members”</b> who will  effectively evaluate risks from the time of pursuit to execution.
            </div> -->
       <img src="../../assets/images/riskbrigade/speaker-container-left-puzzle.png" class="left-puzzle">
      
      <!--<div class="vid-container"  *ngIf="video!=undefined && showVideo==true">
            <yorbit-video-tile [align]="'row'" [video]="video"></yorbit-video-tile>
      </div>
      
      <div class="speaker-container" *ngIf="speaker!=undefined">
            <yorbit-speaker-tile [align]="'row'" [speaker]="speaker" (popupOpened)='popUpOpened($event)'></yorbit-speaker-tile>
      </div>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isDataLoading==true">
            <app-preloader></app-preloader>
      </div>
      
      <img src="../../assets/images/riskbrigade/speaker-container-right-puzzle.png" class="right-puzzle">  -->
</div>


<div class="knowldge-hub-container">
      <div class="knowldge-hub" fxLayout="column" *ngIf="knowledgeHub"> 
            <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="title">Knowledge Hub</div> 
                  <!-- <div  fxLayoutAlign="center" class="show-more" [routerLink]="['/riskbrigade/knwoledge-hub']">
                        Show More
                  </div> -->
            </div>
           
            <div class="holder" fxLayout="row" fxLayoutGap="10px">
                  <div *ngFor="let item of knowledgeHub; let i=index">
                        <yorbit-knowledge-hub-tile *ngIf="i<4" [item]="item"></yorbit-knowledge-hub-tile>
                  </div>
                  <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isDataLoading==true">
                        <app-preloader></app-preloader>
                  </div>
            </div>
      </div>

  <img src="../../assets/images/riskbrigade/home-bottom-puzzle.png" class="bottom-puzzle"> 

      <div class="footer">
            <div class="row" fxLayout="row" fxLayoutGap="50px">
                  <img  fxFlex="50" src="../../assets/images/riskbrigade/about-risk-brigade-puzzle.jpg" alt="">
                  <!-- <div fxFlex="50" class="column" fxLayout="column">
                        <div class="title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, tempora?</div>
                         <yorbit-read-more-text [maxHeight]="39"></yorbit-read-more-text> 
                        
                  </div> -->
            </div>
      </div>

</div>


</div>
