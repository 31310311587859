import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { PssOpmContentApprovalComponent } from './pss-opm-content-approval/pss-opm-content-approval.component';
//import { url } from 'inspector';

@Injectable({ providedIn: 'root' })
export class PssService {
  certDateChangesList: any;
  config: Ienvironment;
  showPopupForPendingCertChanges: BehaviorSubject<any>;
  resultsLoaded: BehaviorSubject<any>;
  linkType: string;
  courseApprovalPage: boolean;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.certDateChangesList = [];
    this.showPopupForPendingCertChanges = new BehaviorSubject<any>({
      status: false
    });
    this.resultsLoaded = new BehaviorSubject<any>({
      status: false,
      count: 0
    });
  }
  //getting PSS course requests
  getCourseRequests(filters, urlParam, hasClassRoom) {
    const Url =
      this.config.apiUrl +
      'PSS/CourseRequests/' +
      urlParam.limit +
      '/' +
      urlParam.count +
      '/' +
      urlParam.nextOrPrevious;
      filters.hasClassRoom = hasClassRoom;
    return this.http.post<any>(Url, filters);
  }
  // pss search for
  getPSSSearchItems(range, urlParam) {
    const Url =
      this.config.apiUrl +
      'PSS/Search/' +
      urlParam.limit +
      '/' +
      urlParam.count +
      '/' +
      urlParam.nextOrPrevious +
      '/' +
      range.SearchMid;
    return this.http.get<any>(Url).toPromise();
  }
  //getting PSS certificate pending data list
  getPssCertificatePendingItems(range, urlParam) {
    const Url =
      this.config.apiUrl +
      'PSS/CertificateRequest/Pending/' +
      urlParam.limit +
      '/' +
      urlParam.count +
      '/' +
      urlParam.nextOrPrevious;
    return this.http.post<any>(Url, range).toPromise();
  }

  //getting PSS certificate actioned data list
  getPssCertificateActionedItems(range, urlParam, status) {
    const Url =
      this.config.apiUrl +
      'PSS/CertificateRequest/Actioned/' +
      status +
      '/' +
      urlParam.limit +
      '/' +
      urlParam.count +
      '/' +
      urlParam.nextOrPrevious;
    return this.http.post<any>(Url, range);
  }
  //getting PSS External Certificate Actioned Data List
  getPssExternalCertificateActionedItems(range,urlParam,status){
    var status1 = (status == "accepted")?"approved":"rejected"
    const Url=
    this.config.apiUrl +
    'PSS/CertificateRequestForExternalAct/Actioned/' +
    status1 +
    '/' +
    urlParam.limit +
    '/' +
    urlParam.count +
    '/' +
    urlParam.nextOrPrevious;
    return this.http.post<any>(Url,range);
  }
  //getting pss External Certificate for Pending Data List
  getPssExternalCertificatePendingItems(range,urlParam){
    const Url =
    this.config.apiUrl +
    'PSS/CertificateRequestForExternal/Pending/' +
    urlParam.limit +
    '/' +
    urlParam.count +
    '/' +
    urlParam.nextOrPrevious;
    return this.http.post<any>(Url,range).toPromise();
  }
  //PSS certificate action
  takeActionOnPendingRequest(payload) {
    const Url = this.config.apiUrl + 'PSS/InternalCertificateAction';
    return this.http.post<any>(Url, payload).toPromise();
  }
  addRequestidToUnsavedList(id) {
    ////console.log('addition to unsaved list', this.certDateChangesList);
    if (this.certDateChangesList.indexOf(id) == -1) {
      this.certDateChangesList.push(id);
      this.certDateChangesList = [...this.certDateChangesList];
    }
  }
  delReqIdFromUnsavedList(id) {
    ////console.log('deletion from unsaved list', this.certDateChangesList);
    this.certDateChangesList.splice(this.certDateChangesList.indexOf(id), 1);
  }
  getPendingCertDateChangesStatus() {
    return this.showPopupForPendingCertChanges.asObservable();
  }
  updateCertDateChangesStatus(data) {
    return this.showPopupForPendingCertChanges.next(data);
  }
  getResultsLoadedStatus() {
    return this.resultsLoaded.asObservable();
  }
  updateResultsLoadedStatus(data) {
    this.resultsLoaded.next(data);
  }
  //getExternalCertificateApproval(limit, index, nextOrPrevious, pendingOrAccepted, selectedSpoc = "") {
  //  let url;
  //  url = 'Get/ExternalCertificateDetails/';
  //  if(selectedSpoc == "")
  //  return this.http
  //    .post(
   //     this.config.apiUrl + url + limit + '/' + index + '/' + nextOrPrevious + '/' + pendingOrAccepted,
    //    {}
   //   )
   //   .toPromise();
   //   else 
   //   return this.http
   //   .post(
    //    this.config.apiUrl + url + limit + '/' + index + '/' + nextOrPrevious + '/' + pendingOrAccepted + '/' + selectedSpoc,
    //    {}
     // )
    //  .toPromise();
//  }
  CheckCourseUniqueId(courseUniqueId) {
    const Url =
      this.config.apiUrl +
      'PSS/CheckIfCourseUniqueIdExists/' + courseUniqueId;
    return this.http.get<any>(Url);
  }
}
@Injectable()
export class ContentVerificationGuardDeactivate
  implements CanDeactivate<PssOpmContentApprovalComponent> {
  url: string;
  canShowBrowserAlert: boolean;
  constructor(private pssService: PssService) { }
  canDeactivate(component: PssOpmContentApprovalComponent): boolean {
    this.url = window.location.href;
    let siblingTabs = [
      'evaluation-requests',
      'certificationg',
      'course-requests'
    ];
    // if(siblingTabs.includes(this.pssService.linkType)){
    //   this.canShowBrowserAlert = false;
    // }else{
    //   this.canShowBrowserAlert = true;
    // } 

    if (this.pssService.linkType == 'content-verification' && this.pssService.courseApprovalPage) {
      //  if (this.url.indexOf('content-verification') != -1 && this.canShowBrowserAlert) {      
      let msg =
        'Any unsaved changes will be lost. Do you want to leave this page?';
      let res = confirm(msg);
      return res;
    }
    return true;
  }
  check
  

}
