<div class="browse-nav-bar" fxFlex="45px" fxLayout="column" fxLayoutAlign="center start" fxShow.xs="true" fxShow.gt-xs="false">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
        <div *ngIf="!isAccountBrowse && (showSkillsFlagStatus || showCoursesFlagStatus)" fxFlexOffset="15px" fxFlex fxLayoutAlign="start center">
            <div *ngIf="!isAccountBrowse" fxFlex="100px" (click)="showSkillsFlagStatus? goToAcademyView():showSkillsView()" fxLayoutAlign="start center">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <div fxFlex="40px" [fxShow]="showSkillsFlagStatus">Back</div>
                <div fxFlex="100px" [fxShow]="showCoursesFlagStatus">Back To Skills</div>
            </div>
        </div>
        <div *ngIf="isAccountBrowse" fxFlexOffset="15px" fxFlex fxLayoutAlign="start center">
            <div fxFlex="100px" (click)="goToAccountsView()" fxLayoutAlign="start center">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <div fxFlex="40px" [fxShow]="showSkillsFlagStatus">Accounts</div>
            </div>
        </div>


        <div fxFlex fxLayoutAlign="end center" [fxShow]="!isAccountBrowse&&!showCoursesFlagStatus && !showSkillsFlagStatus">
            <div fxFlex="150px">ACADEMY / GENRE</div>
        </div>
        <div fxFlex fxLayoutAlign="end center" [fxShow]="!isAccountBrowse && showSkillsFlagStatus">
            <div fxFlex="85px">SKILLS</div>
        </div>
        <div fxFlex fxLayoutAlign="end center" [fxShow]="!isAccountBrowse&&showCoursesFlagStatus">
            <div fxFlex="85px">COURSES</div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>