<div
  fxFlex="35px"
  [matMenuTriggerFor]="bellNotifications"
  class="notification-icon"
  (click)="getNewNotification()"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div class="mousePointer yorbitSprite bellIcon"></div>
  <div class="notifications-count" *ngIf="newNotificationsCount != 0">
    {{ newNotificationsCount }}
  </div>
</div>

<mat-menu
  #bellNotifications="matMenu"
  xPosition="before"
  [overlapTrigger]="false"
  class="alert-menu-comp"
>
  <!--
    -->
  <div class="alerts-container">
    <!--preloader-->
    <div class="preloader-section" mat-menu-item *ngIf="showPreloader">
      <app-preloader></app-preloader>
    </div>
    <div
      class="alert-msgs"
      *ngIf="contentLoadedSuccessfully && notificationsShownToUser.length != 0"
    >
      <div
        mat-menu-item
        fxLayout="row"
        class="msg"
        *ngFor="let notification of notificationsShownToUser; last as last"
        [ngClass]="{ msgActioned: notification.IsActioned }"
        (click)="onClickOfNotifications(notification)"
      >
        <div class="img" fxFlex="45px" fxFlexAlign="center">
          <img
            [ngClass]="{
              'no-color':
                (notification.CertificationStatus | lowercase) !=
                'course completed'
            }"
            [src]="getImageUrl(notification.Expertise)"
            *ngIf="
              notificationCategories.indexOf(notification.Category) != -1 &&
              notification.ItemType != 'FamilyPackage' &&
              notification.ItemType != 'Unit'
            "
          />
          <img
            src="assets/icons/icon-96x96.png"
            *ngIf="
              notificationCategories.indexOf(notification.Category) == -1 ||
              (notificationCategories.indexOf(notification.Category) != -1 &&
                (notification.ItemType == 'FamilyPackage' ||
                  notification.ItemType == 'Unit'))
            "
          />
        </div>
        <div class="text" fxFlex fxFlexAlign="center"
         title="{{notification.MessageTemplate != undefined? notification.MessageTemplate:notification.Message}}">
          {{
            notification.MessageTemplate != undefined
              ? notification.MessageTemplate
              : notification.Message
          }}
        </div>
      </div>
      <div
        class="show-more"
        (click)="stopEventPropagation($event)"
        *ngIf="
          (notificationsCollection.length > notificationsPointer ||
            (notificationsCollection.length == notificationsPointer &&
              !notificationsState.OldNotificationsLoadedSuccesfully)) &&
          !notificationsState.OldNotificationsLoading
        "
      >
        <div
          class="show-more-btn"
          (click)="stopEventPropagation($event); loadNextTenNotifications()"
        >
          ShowMore
        </div>
      </div>
      <div
        class="show-more"
        (click)="stopEventPropagation($event)"
        *ngIf="notificationsState.OldNotificationsLoading"
      >
        Loading...
      </div>
    </div>

    <div
      class="alert-msgs"
      mat-menu-item
      *ngIf="contentLoadedSuccessfully && notificationsShownToUser.length == 0"
    >
      <div class="noNotificationsInfo">
        No notifications are available.
      </div>
    </div>
    <div mat-menu-item *ngIf="!showPreloader && !contentLoadedSuccessfully">
      Failed to fetch data. Please try later.
    </div>
  </div>
  <div
    class="blocked-info"
    *ngIf="!showPreloader && isPushNotificationsBlocked"
    (click)="stopEventPropagation($event)"
  >
    Push notifications are blocked.<span (click)="showDialogueForBlockedUsers()"
      >Unblock</span
    >
  </div>
</mat-menu>
