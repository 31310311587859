import { Directive,
  Input,
  Output,
  ElementRef,
  HostListener,
  EventEmitter,
  OnChanges } from '@angular/core';

@Directive({
  selector: '[YorbitWorkspaceChangeLabel]'
})
export class ChangeLabelDirective implements OnChanges{
  @Input() label: string;
  constructor(private element: ElementRef) { }
  ngOnInit(){
    this.element.nativeElement.innerText = this.label;  
  }
  ngOnChanges(changes){    
     this.element.nativeElement.innerText = this.label;    
  }
}
