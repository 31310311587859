import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
//import { type } from 'os';
//import { string } from 'yargs';
import { Video } from '../../details-page/mobile-course-details-page/mobile-course-details-page.component';
import { PlayerVarsIntroVideoO365 } from '../../shared/video-players/o365-video-player/o365-player.config';

@Component({
  selector: 'yorbit-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss']
})
export class VideoContainerComponent implements OnInit, OnChanges {
  @Input('videoData') videoData: any;
  videoConfig: Video;
  type:string;
  introVideoUrl;
  relaodVideo = true;
  constructor() {}

  ngOnInit(): void {
    this.playVideo();
  }

  ngOnChanges(changeObj) {
    ////console.log(changeObj);
    this.relaodVideo = false;

    this.playVideo();
  }

  playVideo() {
    let videoId;
    this.introVideoUrl = {
      Id: 12528,
      UniqueId: 'BH_098_U1_T1',
      UnitId: '3205',
      Expertise: '101',
      Title: 'Pronunciation Training Techniques',
      Duration: '00.12.51',
      SourceUrl: this.videoData.URl,
      Image: this.videoData.ThumNailImageURl,
      SourceType: this.videoData.VideoType,
      Progress: 0,
      Compliance: false,
      AccountPackage: false,
      ProjectPackage: false,
      Account: null,
      Project: null,
      ItemType: 'Content',
      IsBroken: false
    };
    //check for add
    if (this.introVideoUrl.SourceType.toUpperCase() === 'YOUTUBE') {
      videoId = this.introVideoUrl.SourceUrl.split('embed/')[1];
    } else if (this.introVideoUrl.SourceType === 'O365') {
      videoId = this.introVideoUrl.UniqueId;
    }

    const data = {
      ContentId: this.videoData.ContentId,
      UnitId: '773',
      CourseId: this.videoData.ContentId,
      ItemId: this.videoData.ContentId,
      ItemType: 'Content',
      PathId: 0,
      VideoId: videoId,
      sourceUrl: this.introVideoUrl.SourceUrl
    };
   // //console.log(data);

    this.videoConfig = {
      VideoDetails: data,
      VideoPlayerId: 'IntroVideo' + videoId,
      CanCaptureProgress: false,
      PlayerVars: PlayerVarsIntroVideoO365,
      showInfoMsg: false
    };

    setTimeout(() => {
      this.relaodVideo = true;
    }, 1500);
  }
}
