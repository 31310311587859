import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class ShareContentService {
  config: Ienvironment;

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService
  ) {
    this.config = this._envSvc.getEnvironment();
   }

   shareYorbitContent(userList, id, type) {
    const payload = {
      "useremails":userList
    }
    return this.http
      .post(this.config.apiUrl + 'Share/'+type+'/'+id, payload)
      .toPromise();
  }
}
