
<div class="container">
  <mat-dialog-content
  class="videoPopup"
>
  <div class="close">
    <button mat-button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="header">{{ dialogVideoData.Title }}</div>
  <mat-divider></mat-divider>
  <div class="youtubevideoplayer">
    <yorbit-youtube-video-player
    *ngIf="dialogVideoData.VideoType.toLowerCase() == 'youtube'"
    [PlayerConfig]="videoConfig"
  ></yorbit-youtube-video-player>

  <yorbit-o365-video-player
    *ngIf="dialogVideoData.VideoType.toLowerCase() == 'o365'"
    [PlayerConfig]="videoConfig"
  ></yorbit-o365-video-player>
  </div>


</mat-dialog-content>
</div>

