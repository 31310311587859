<div>
  <div class="resultsDiv">Results</div>
  <div class="buttonsDiv">
    <input type="checkbox" class="selectAllCheckBox" [checked]="multiSelect" (change)="onSelectAllChange()" />
    <div class="selectAll">Select All</div>
    <button class="buttons downloadButton" mat-raised-button aria-label="Go" (click)="downloadDocumentType()" [disabled]="isCommonButtonsDisabled">
              <i class="spriteIcons iconDownloadButton downloadWhiteIcon"></i>
              Download Selected
    </button>
    <button class="buttons publishSelectedButton" mat-raised-button aria-label="Go" (click)="publishSelectedRequests()" [disabled]="isCommonButtonsDisabled">
              <i class="spriteIcons iconDownloadButton publishIcon"></i>
              Publish Selected
    </button>
    <button class="buttons alertButton" mat-raised-button aria-label="Go" (click)="alertSelectedRequests()" [disabled]="isCommonButtonsDisabled">
              <i class="spriteIcons iconDownloadButton alertIconWhite"></i>
              Alert Selected
    </button>
    <div class="floatRight">
      <div class="pagination">
        <div class="navigateButton" *ngIf='!isPreviousButtonDisabled' (click)="getNextOrPrevious('previous')">❮</div>
        <div class="navigateButton greyedOut" *ngIf='isPreviousButtonDisabled'>❮</div>
        <div class="navigateButton">{{startingNumber}} - {{endingNumber}} of {{totalRecords}}</div>
        <div class="navigateButton" *ngIf='!isNextButtonDisabled' (click)="getNextOrPrevious('next')">❯</div>
        <div class="navigateButton greyedOut" *ngIf='isNextButtonDisabled'>❯</div>
      </div>

      <div class="selectDropdown">
        <select [ngModel]="selectedOption" (ngModelChange)="onChange($event)" matTooltipClass="tooltipStyle" matTooltip="{{selectedOption}} evaluation requests per page">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
    </div>
  </div>

  <div class="preloaderStyle recordsPreloader" *ngIf="isRecordsLoading">
    <app-preloader></app-preloader>
  </div>
  <!-- <div class="resultParentDiv" *ngFor="let result of requestList; index as i; let checkBoxChecked=false"> -->
  <div *ngIf="!isRecordsLoading">
    <div class="resultParentDiv" *ngFor="let result of requestList; index as i; ">
      <div class="floatLeft">
        <!-- <input type="checkbox" [checked]="multiSelect" [(ngModel)]="result.checkBoxChecked" (change)= "onSelectChange(result.EvaluationRequestId)"/> -->
        <input type="checkbox" [checked]="multiSelect" (change)="onSelectChange(result.EvaluationRequestId)" />

      </div>
      <div class="resultsParent font12">
        <div class="resultDataColumns columnOne">
          <div class="reqeuestAndMidRow">
            <div class="floatLeft bold">Request ID : </div>
            <div>{{result.RequestId}}</div>
            <div class="floatLeft bold">PSID : </div>
            <div>{{result.MID}}</div>
            <div class="floatLeft bold" *ngIf="(result.RequestType | lowercase) == 'assignment'">Assignment :</div>
            <div *ngIf="(result.RequestType | lowercase) == 'assignment'">{{result.AssignmentSessionId | slice: -2}}</div>
            <div class="floatLeft bold" *ngIf="(result.RequestType | lowercase) != 'assignment'">Project : </div>
            <div *ngIf="(result.RequestType | lowercase) != 'assignment'">01</div>
          </div>

          <div class="datesRow">
            <div class="floatLeft bold">Submission Date : </div>
            <div>{{result.SubmissionDate }}</div>
            <div class="floatLeft bold">Evaluation Date : </div>
            <div>{{result.EvaluationDate }}</div>
            <div class="floatLeft bold">SLA Hit Date : </div>
            <div>{{result.SLA_Date }}</div>
            <div *ngIf="result.DaysToSLA < 0 && !result.IsPublishButtonDisabled" class="floatLeft bold">Days Post SLA : </div>
            <div *ngIf="result.DaysToSLA < 0 && !result.IsPublishButtonDisabled">{{result.PostSLA}}</div>
            <div *ngIf="result.DaysToSLA > 0 && !result.IsPublishButtonDisabled" class="floatLeft bold">Days to Hit SLA : </div>
            <div *ngIf="result.DaysToSLA > 0 && !result.IsPublishButtonDisabled">{{result.DaysToSLA}}</div>
          </div>

        </div>

        <div class="resultDataColumns columnTwo">
          <table>
            <tr class="tableRow">
              <td class="contents" valign="top">Course Name</td>
              <td valign="top">:</td>
              <td>
                <div class="courseNameLimit" title="{{result.CourseName}}">{{result.CourseName}}</div>
              </td>
            </tr>
            <tr>
              <td class="contents">Academy</td>
              <td>:</td>
              <td>{{result.Academy}}</td>
            </tr>
            <tr>
              <td class="contents">Course Vendor</td>
              <td>:</td>
              <td>{{result.CourseVendor}}</td>
            </tr>
            <tr>
              <td class="contents">Evaluation Type</td>
              <td>:</td>
              <td>{{result.EvaluationType}}</td>
            </tr>
            <tr>
              <td class="contents">Evaluator Name</td>
              <td>:</td>
              <td>
                <div class="evaluatorName">{{result.EvaluatorName}}</div>
                <div class="spriteIcons changeEvaluatorIcon floatRight mousePointer" *ngIf="result.SubStatus.includes('Result awaited') && result.HasMultipleEvaluators && (result.Result == 'Not-Cleared' || result.Result == '' || result.Result == 'Error')"
                  (click)="changeEvaluator(i, result.EvaluationRequestId, result.RequestType, result.CourseUniqueId, result.AssignmentSessionId)"
                  matTooltipClass="tooltipStyle" matTooltip="Click to remap evaluator"></div>
                <div class="spriteIcons changeEvaluatorIcon floatRight greyedOut disableIcon" matTooltipClass="tooltipStyle" matTooltip="{{remapEvaluatorToolTip}}"
                  (mouseenter)="onRemapIconHover(i)" *ngIf="!result.SubStatus.includes('Result awaited') || !result.HasMultipleEvaluators || (result.Result != 'Not-Cleared' && result.Result != '' && result.Result != 'Error')"></div>
              </td>
            </tr>
            <tr>
              <td class="contents">SPOC</td>
              <td>:</td>
              <td>{{result.SPOC}}</td>
            </tr>
            <tr *ngIf="result.Result != 'Clear'">
              <td class="contents">Sub Status</td>
              <td>:</td>
              <td>{{result.SubStatus}}</td>
            </tr>
          </table>
        </div>

        <div class="resultDataColumns columnThree">
          <div class="scoreRow">
            <div class="scoreText">Score :</div>
            <div class="scoreBox">
              <div class="scoreSecured mousePointer" *ngIf="!result.IsPublishButtonDisabled" (click)="editScore(i, result.EvaluationRequestId, result.RequestType, result.Score, result.MinScore, result.MaxScore)"
                matTooltipClass="tooltipStyle" matTooltip="Click to update score">
                {{result.Score == 0 ? "NA" : result.Score}}</div>
              <div class="scoreSecured disabled" *ngIf="result.IsPublishButtonDisabled" matTooltipClass="tooltipStyle" matTooltip="Sorry! Currently, you will not be able to update the score">
                {{result.Score == 0 ? "NA" : result.Score}}</div>

              <div class="minAndMaxRow">
                <div class="minAndMax">Min </div>
                <div class="minAndMaxScore">{{result.MinScore}} </div>
                <div class="minAndMax">Max </div>
                <div class="minAndMaxScore">{{result.MaxScore}}</div>
              </div>
            </div>
          </div>
          <div class="resultRow">
            <div class="resultAndAttempt">
              <div class="resultText floatLeft">Result :</div>
              <div>{{result.Result == null ? "NA" : result.Result == "Clear" ? "Cleared" : result.Result}}</div>
            </div>
          </div>
          <div>
            <div class="resultAndAttempt">
              <div class="resultText floatLeft">Attempt :</div>
              <div>{{result.Attempts}}</div>
            </div>
          </div>
        </div>

        <div class="resultDataColumns columnFour">
          <div class="scorecardRow">
            <div class="subHeading">Scorecard :</div>
            <!-- <div class="downloadBox" *ngIf="result.ScoreCard"> -->
            <div class="downloadBox">
              <div class="downloadFileName" *ngIf="result.ScoreCard" matTooltip="{{scoreCardToolTip}}" matTooltipClass="tooltipStyle" (mouseenter)="onScoreCardHover(i)"
                ng-show="result.ScoreCard">{{(result.ScoreCard | filenameFromUrl | slice:0:10)+'..'}}</div>


              <div class="spriteIcons downloadIcon floatRight mousePointer" *ngIf="result.ScoreCard && !isScoreCardChanged" (click)="openScorecardAttemptwise(result)"></div>
              <div class="spriteIcons downloadIcon floatRight greyedOut" *ngIf="!result.ScoreCard || isScoreCardChanged"></div>

              <div *ngIf="!result.ScoreCard" class="downloadFileName">Not Available</div>
            </div>

            <div>
              <div class="uploadText">Upload Score Card</div>
              <div class="spriteIcons uploadIcon floatRight mousePointer" *ngIf="!result.IsPublishButtonDisabled" (click)="uploadScoreCard(result.EvaluationRequestId, result.RequestType)"></div>
              <div class="spriteIcons uploadIcon floatRight greyedOut" *ngIf="result.IsPublishButtonDisabled"></div>
            </div>
          </div>
          <div class="evaluationSheeRow">
            <div class="subHeading">Evaluation Sheet :</div>
            <div class="downloadBox">
              <div class="downloadFileName" *ngIf="result.EvaluationSummarySheet" matTooltip="{{evaluationSheetToolTip}}" matTooltipClass="tooltipStyle"
                (mouseenter)="onEvaluationSheetHover(i)" ng-show="result.EvaluationSummarySheet">{{(result.EvaluationSummarySheet | filenameFromUrl | slice:0:10)+'..'}}</div>

              <div class="spriteIcons downloadIcon floatRight mousePointer" *ngIf="result.EvaluationSummarySheet" (click)="downloadEvaluationSheet(i)"></div>
              <div class="spriteIcons downloadIcon floatRight greyedOut" *ngIf="!result.EvaluationSummarySheet"></div>

              <div *ngIf="!result.EvaluationSummarySheet " class="downloadFileName">Not Available</div>

            </div>
          </div>
        </div>

        <div class="resultDataColumns columnFive">
          <div class="questionsRow">
            <div class="subHeading">Problem Statement :</div>
            <div class="downloadBox">
              <div class="downloadFileName" *ngIf="result.ProblemStatement" matTooltip="{{problemStatementToolTip}}" matTooltipClass="tooltipStyle"
                (mouseenter)="onProblemStatementHover(i)">{{(result.ProblemStatement | filenameFromUrl | slice:0:10)+'..'}}</div>

              <div class="spriteIcons downloadIcon floatRight mousePointer" *ngIf="result.ProblemStatement" (click)="downloadProblemStatement(i)"></div>
              <div class="spriteIcons downloadIcon floatRight greyedOut" *ngIf="!result.ProblemStatement"></div>
              <div *ngIf="!result.ProblemStatement" class="downloadFileName">Not Available</div>
            </div>
          </div>

          <div class="errorsRow">
            <div class="subHeading">Error File :</div>
            <div class="downloadBox">
              <div class="downloadFileName" *ngIf="result.ErrorFile" matTooltip="{{errorToolTip}}" matTooltipClass="tooltipStyle" (mouseenter)="onErrorHover(i)"
                ng-show="result.ErrorFile">{{(result.ErrorFile | filenameFromUrl | slice:0:10)+'..'}}</div>

              <!-- <div class="spriteIcons downloadIcon floatRight" title="{{!isErrorFileAvailable?'Project Error file link is not available':'Click here to download the error file'}}"
                (click)="openError(this.RequestId)"></div> downloadErrorFile(result.ErrorFile)-->
              <div class="spriteIcons downloadIcon floatRight mousePointer" *ngIf="result.ErrorFile && !isErrorFileChanged" (click)="downloadErrorFile(i)"></div>
              <div class="spriteIcons downloadIcon floatRight greyedOut" *ngIf="!result.ErrorFile || isErrorFileChanged"></div>
              <div class="downloadFileName" *ngIf="!result.ErrorFile">Not Available</div>
            </div>
            <div>
              <div class="uploadText">Upload Error File</div>
              <div class="spriteIcons uploadIcon floatRight mousePointer" *ngIf="!result.IsPublishButtonDisabled" (click)="uploadErrorFile(result.EvaluationRequestId, result.RequestType)"></div>
              <div class="spriteIcons uploadIcon floatRight greyedOut" *ngIf="result.IsPublishButtonDisabled"></div>
            </div>
          </div>
        </div>

        <div class="resultDataColumns columnSix">
          <div class="solutionsRow">
            <div class="subHeading solutionHeight">
              <div class="floatLeft">Solutions :</div>
            </div>
            <div class="downloadBox">
              <div class="downloadFileName" id="sol" *ngIf="result.SolutionFile" matTooltip="{{solutionToolTip}}" matTooltipClass="tooltipStyle"
                (mouseenter)="onSolutionHover(i)" ng-show="result.SolutionFile">{{(result.SolutionFile | filenameFromUrl | slice:0:10)+'..'}}</div>


              <div class="spriteIcons downloadIcon floatRight mousePointer" name="SolFileDownload" *ngIf="result.SolutionFile" (click)="openSolutionAttemptwise(result)"></div>
              <div class="spriteIcons downloadIcon floatRight greyedOut" *ngIf="!result.SolutionFile"></div>
              <div *ngIf="!result.SolutionFile" class="downloadFileName">Not Available</div>
            </div>
          </div>
          <div class="logAndAlertRow">
            <div class="logDiv floatLeft">
              <div class="logText">Log : </div>
              <div class="spriteIcons logIcon floatLeft mousePointer" matTooltip="{{'Click to download log file'}}" matTooltipClass="tooltipStyle"
                (click)="downloadLog(result.RequestId)"></div>
            </div>
            <div *ngIf="result.PostSLA > 0 && result.SubStatus && result.SubStatus.toLowerCase().indexOf('result awaited')!=-1" class="logDiv floatLeft">
              <div class="slaAlertText">SLA Alert : </div>
              <div class="spriteIcons alertIcon floatLeft mousePointer" matTooltip="{{'Click to alert the evaluator on SLA'}}" matTooltipClass="tooltipStyle"
                (click)="sendIndividualAlert(result.EvaluationRequestId)"></div>
            </div>
          </div>
          <div>
            <div class="publishbuttonmaindiv" matTooltip="{{result.IsPublishButtonDisabled?'Evaluation result not due to publish':'Publish Result'}}"
              [matTooltipPosition]="'above'" matTooltipClass="tooltipStyle">
              <button class="buttons publishButton disableIcon" mat-raised-button aria-label="Go" [disabled]="result.IsPublishButtonDisabled"
                *ngIf="result.IsPublishButtonDisabled">Publish
                </button>
              <button class="buttons publishButton mousePointer" mat-raised-button aria-label="Go" (click)="publishChanges($event.target, 'Published', result.EvaluationRequestId)"
                *ngIf="!result.IsPublishButtonDisabled">Publish
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>