import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimeBoundLearningService } from '../../services/time-bound-learning.service';

@Component({
  selector: 'yorbit-time-bound-learning-popup',
  templateUrl: './time-bound-learning-popup.component.html',
  styleUrls: ['./time-bound-learning-popup.component.scss']
})
export class TimeBoundLearningPopupComponent implements OnInit {
  showInstructions: boolean = false;
  showTimeUp: boolean = false;
  showConfirmation: boolean = false;
  timerMessage: string = "";
  timeBoundCourseList;
  selectedCourseIndex;
  loading:boolean=false;

  constructor(
    private dialogRef: MatDialogRef<TimeBoundLearningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public courseList: any,
    private _timeBoundCourseService: TimeBoundLearningService,
    private _router:Router,
    private _snackBar: MatSnackBar
  ) {
    this.timeBoundCourseList=courseList.TimeBoundCourses
  }

  ngOnInit() {

  }
  closeDialog() {
    this.dialogRef.close();
  }

  closeConfirmation(yesOrNo) {
    if(yesOrNo == 'Yes')
      this.dialogRef.close(true);
    else
      this.dialogRef.close(false);

  }

  getFormattedDate(dateValue){
    var date=new Date(dateValue);
    return new DatePipe('en-US').transform(date, 'dd MMMM yyyy')
  }


  checkExpiryDate(dateValye){
    var currentDate=new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    var dueDate= new Date(dateValye);
    var days = Math.floor((currentDate.getTime() - dueDate.getTime()) / 1000 / 60 / 60 / 24);
    if(days<0){
      return false
    }else{
      return true
    }
  }

  takeAction(item,index){
    this.loading=true;
    this.selectedCourseIndex=index;
    const courseData = {
      "UserId":item.UserId,
      "assignorId": item.assignorId,
      "assignorRole":item.assignorRole, 
      "IntentId": 2,
      "IntentDescription": "Time Bound Course",
      "itemId": item.itemId,
      "uniqueId": item.uniqueId,
      "itemType": item.itemType,
      "itemName": item.itemName,
      "itemExpertise": item.itemExpertise,
      "duration": item.duration,
      "dueDate": item.dueDate
    };

    this._timeBoundCourseService.assignTimeBoundCourses(courseData).toPromise().then(res=>{
      this.loading=false;
      //Navigate To Learning Path And Close Popup
      this.removeActionTakenObject(item);
      this._snackBar.open("\""+item.itemName+"\" Course Added to Your Learning Path","Ok", {
        duration: 4000,
        panelClass: "success-dialog"
      });
      if(this.timeBoundCourseList.length==0){
       this._router.navigate(['/learningpath'])
       this.closeDialog()
      }
    })
  }

  removeActionTakenObject(course): void {
     this.timeBoundCourseList = this.timeBoundCourseList.filter(({ itemId }) => itemId !== course.itemId);        
  }


  checkTimeBoundCourseExpiryDate1(dateValue){
    var currentDate=new Date();
    currentDate.setDate(currentDate.getDate() + 5);
    var dueDate= new Date(dateValue);
    var days = Math.floor((currentDate.getTime() - dueDate.getTime()) / 1000 / 60 / 60 / 24);
    if(days<0){
      return false
    }else{
      return true 
    }
  }


  get sortedTimeBoundData() {
    return this.timeBoundCourseList.sort((a, b) => {
      return <any>new Date(a.dueDate) - <any>new Date(b.dueDate);
    });
  }

}
