import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
    selector: 'app-tooltip',
    templateUrl: './isupport-tooltip.component.html',
    styleUrls: ['./isupport-tooltip.component.css']
  })
export class iSupportTooltipComponent implements OnInit,OnChanges  {

    @Input() showsTooltip = true;
    @Input() topPosition = 215;
    @Input() leftPosition = 400;
  
    constructor() {}
 
    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
      //console.log(changes)
      }
  }