import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryFilterPipe } from './category-filter.pipe';
import { ExpertisePipe } from './expertise.pipe';
import { ItemTypePipe } from './item-type.pipe';
import { CourseTypePipe } from './course-type.pipe';
import { AcademyPipe } from './academy.pipe';
import { GenrePipe } from './genre.pipe';
import { SkillPipe } from './skill.pipe';
import { VendorPipe } from './vendor.pipe';
import { SearchOrderByPipe } from './search-order-by.pipe';
import { SearchTextPipe } from './search-text.pipe';
import { OrderbyDatePipe } from './orderby-date.pipe';
import { BadgeQuarterPipe } from './badge-quarter.pipe';
import { ValidDatePipe } from './valid-date.pipe';
import { DatePipe } from './date.pipe';
import { ArrayPropertyFilterPipe } from './array-property-filter.pipe';
import { FilterContentTileByItsPropertiesPipe } from './filter-content-tile-by-its-properties.pipe';
import { ContentPropertyInArrayPipe } from './content-property-in-array.pipe';
import { CharacterFilterPipe } from './character-filter.pipe';
import { OrderByPropertyPipe } from './order-by-property.pipe';
import { AlphabetPipe } from './alphabet.pipe';
import { AccountDashboardPipe } from './account-dashboard.pipe';
import { AccountFilterMindPipe } from './account-filter-mind.pipe';
import { OrderByPipe } from './order-by.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { AccountAssignGapMindsPipe } from './account-assign-gap-minds.pipe';
import { AccountAssignGapSkillPipe } from './account-assign-gap-skill.pipe';
import { AccountAssignGapCoursePipe } from './account-assign-gap-course.pipe';
import { AccountAssignNongapPipe } from './account-assign-nongap.pipe';
import { FilterBrowseContentPipe } from './filter-browse-content.pipe';
import { FilenameFromUrlPipe } from "./FilenameFromUrlPipe";
import { SearchArrayPipe } from './search-array.pipe';
import { DateWithoutConversionPipe } from './date-without-conversion.pipe';
import { TabFilterPipe } from './tab-filter.pipe'
@NgModule({
  imports: [CommonModule],
  declarations: [
    CategoryFilterPipe,
    OrderbyDatePipe,
    BadgeQuarterPipe,
    ValidDatePipe,
    DatePipe,
    ArrayPropertyFilterPipe,
    CategoryFilterPipe,
    ExpertisePipe,
    ItemTypePipe,
    CourseTypePipe,
    AcademyPipe,
    GenrePipe,
    SkillPipe,
    VendorPipe,
    SearchOrderByPipe,
    ContentPropertyInArrayPipe,
    CharacterFilterPipe,
    OrderByPropertyPipe,
    FilterContentTileByItsPropertiesPipe,
    OrderByPipe,
    AlphabetPipe,
    AccountDashboardPipe,
    AccountFilterMindPipe,
    LinkifyPipe,
    AccountAssignNongapPipe,
    AccountAssignGapMindsPipe,
    AccountAssignGapSkillPipe,
      AccountAssignGapCoursePipe,      SearchTextPipe,      FilenameFromUrlPipe,
      TabFilterPipe,
    FilterBrowseContentPipe
,
    DateWithoutConversionPipe 
,
    SearchArrayPipe  ],

  exports: [
    CategoryFilterPipe,
    OrderbyDatePipe,
    BadgeQuarterPipe,
    ValidDatePipe,
    DatePipe,
    ArrayPropertyFilterPipe,
    CategoryFilterPipe,
    CourseTypePipe,
    ExpertisePipe,
    ItemTypePipe,
    AcademyPipe,
    GenrePipe,
    SkillPipe,
    VendorPipe,
    SearchOrderByPipe,
    ContentPropertyInArrayPipe,
    CharacterFilterPipe,
    OrderByPropertyPipe,
    FilterContentTileByItsPropertiesPipe,
    AlphabetPipe,
    AccountDashboardPipe,
    AccountFilterMindPipe,
    OrderByPipe,
    LinkifyPipe,
    AccountAssignNongapPipe,
    AccountAssignGapMindsPipe,
    AccountAssignGapSkillPipe,
    AccountAssignGapCoursePipe,
    SearchTextPipe,
    SearchArrayPipe,
    FilenameFromUrlPipe,
      DateWithoutConversionPipe,
      TabFilterPipe
  ],
  providers: [
    CategoryFilterPipe,
    OrderbyDatePipe,
    BadgeQuarterPipe,
    ValidDatePipe,
    DatePipe,
    ArrayPropertyFilterPipe,
    CategoryFilterPipe,
    CourseTypePipe,
    ExpertisePipe,
    ItemTypePipe,
    AcademyPipe,
    GenrePipe,
    SkillPipe,
    VendorPipe,
    SearchOrderByPipe,
    ContentPropertyInArrayPipe,
    CharacterFilterPipe,
    OrderByPropertyPipe,
    FilterContentTileByItsPropertiesPipe,
    AlphabetPipe,
    AccountDashboardPipe,
    AccountFilterMindPipe,
    OrderByPipe,
    LinkifyPipe,
    AccountAssignNongapPipe,
    AccountAssignGapMindsPipe,
    AccountAssignGapSkillPipe,
    AccountAssignGapCoursePipe,
    SearchTextPipe,
    SearchArrayPipe,
    FilenameFromUrlPipe,
      DateWithoutConversionPipe,
      TabFilterPipe
  ]
})
export class PipesModule {}
