<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="searchDiv">
        <input [(ngModel)]="searchText" type="text" class="textBoxStyle" placeholder="Search by academy">
    </div>
    <div class="font12 scrollHorizontal row floatLeft" fxLayout="column wrap" fxLayoutAlign="start start">
    <div class="academyList font12" *ngFor='let academy of displayList | searchFilter: searchText'>
        <div fxLayout="row" fxFlex>
        <input type="checkbox" [disabled]="!academy.isEnabled" [checked]="academy.isSelected"
            (change)="updateCheckedOptions(academy.value, $event)">
        <div>{{academy.value}}</div>
    </div>
    </div>
    </div>