import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadBlobPopupComponent } from './download-blob-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

@NgModule({
  imports: [
    CommonModule,

    ReusableUiModule,

    MatDialogModule,
    MatIconModule,
    MatDividerModule
  ],
  declarations: [DownloadBlobPopupComponent],
  exports:[DownloadBlobPopupComponent]
})
export class DownloadBlobPopupModule { }
