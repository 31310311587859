import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareContentComponent } from './share-content.component';
import { ShareContentPopupComponent } from './share-content-popup/share-content-popup.component';
import { ShareUserProfileComponent } from './share-user-profile/share-user-profile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { ContentTileBrowseComponent } from '../../content-tiles/content-tile-browse/content-tile-browse.component';

@NgModule({
  imports: [
    CommonModule,

    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatBadgeModule,

    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ShareContentComponent, ShareContentPopupComponent, ShareUserProfileComponent],
  exports: [ShareContentComponent, ShareContentPopupComponent, ShareUserProfileComponent],
  providers: [ContentTileBrowseComponent]
})
export class ShareContentModule { }
