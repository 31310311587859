import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ShareContentPopupService } from '../../../shared/card-feature/share-content/share-content-popup/share-content-popup.service';

@Component({
  selector: 'yorbit-pss-search',
  templateUrl: './pss-search.component.html',
  styleUrls: ['./pss-search.component.scss']
})
export class PssSearchComponent implements OnInit {
  @Output() searchUpdateEvent = new EventEmitter<any>();
  search: any;
  searched: boolean;
  SearchMid: string;
  SearchRequestId: number;
  MidRegExp = new RegExp(/[0-9]+$/i);
  reqIdRegExp = new RegExp('^[0-9]+$');
  nameRegExp = new RegExp('^[abc|ABC|s]+$');
  suggestionList: any;
  mailNickname: string;
  constructor(private _shareContentPopupService: ShareContentPopupService) {}

  ngOnInit() {
    this.search =''
    this.searched = false;
  }

  onSearch() {
    const searchItems={
      SearchMid:this.SearchMid,
      SearchRequestId:this.SearchRequestId
    }
    this.searchUpdateEvent.emit(searchItems);
    this.searched = true;
  }
  onClear() {
    const searchItems={
      SearchMid:'',
      SearchRequestId:0
    }
    this.search =''
    this.searched = false;
    this.searchUpdateEvent.emit(searchItems);
  }

  //sent search data to get PSS data list
  searchResult(search) {
    if (null === search || '' === search) {
      this.SearchMid = '';
      this.SearchRequestId = 0;
    } else {
      let getDataList = true;
      //this.StartDate = "";
      //this.EndDate = "";
      this.SearchMid = '';
      this.SearchRequestId = 0;
      //check for type of search using RegExp and set the data in payload
      if (this.reqIdRegExp.test(search)) {
        this.SearchRequestId = search;
      } else if (true || this.MidRegExp.test(search)) {
        this.SearchMid = search;
      } else {
        if ('' !== this.mailNickname) {
          this.SearchMid = this.mailNickname;
          this.mailNickname = '';
        } else {
          getDataList = false;
          // this.contentListItems.pssItems = [];
          // this.contentListItems.count = 0;
        }
      }
      // getting data list
      if (getDataList) {
        // this.getPssListData("next");
        this.onSearch()
      }
    }
  }

  searchUser(user, event) {
    let keyEventCheck = true;
    if (
      1 !== event.key.length &&
      'Backspace' !== event.key &&
      'Delete' !== event.key
    ) {
      keyEventCheck = false;
    }
    if (!this.reqIdRegExp.test(user) && (true || !this.MidRegExp.test(user))) {
      if (user.length >= 3 && keyEventCheck) {
        this._shareContentPopupService
          .getSuggestionList(user)
          .then((response: any) => {
            this.suggestionList = response.value;
          });
      }
      if ('Enter' === event.key) {
        this.getMid(user);
      }
    }else{
      if ('Enter' === event.key) {
        this.searchResult(user);
      }
    }
  }

  userSelectHandler(event){
    this.getMid(this.search)
  }

  getMid(user) {
    this.suggestionList=[];
    this.mailNickname = user.userPrincipalName.split("@")[0];
    this.search = user.displayName;
    this.searchResult(this.mailNickname);
  }

}
