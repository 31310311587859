import * as fromBrowseAcademyAction from '../actions/browse.action';
import { IBrowseAcademy } from '../browse-academy.interface';

export interface IbrowseAcademyReducerState {
  data: IBrowseAcademy;
  loading: boolean;
  loaded: boolean;
  contentLoading:boolean;
  contentLoaded:boolean;
  browseProperties?:IbrowseDetails
}
export interface IbrowseDetails{
  Academy:string;
  Genre:string;
}
export const browseAcademyInit = {
  data:{},
  loading:false,
  loaded: false,
  contentLoading:false,
  contentLoaded:false,
  browseProperties:{
    Academy:'',
    Genre:''
  }
}

export function reducer(
  state=browseAcademyInit,
  action: fromBrowseAcademyAction.BrowseAcademyActions
  ):IbrowseAcademyReducerState{
    switch (action.type) {
      case fromBrowseAcademyAction.BROWSE_GET_ACADEMY_LIST: {
        return {
          ...state,
          loading:true,
        }
      }
      case fromBrowseAcademyAction.BROWSE_GET_ACADEMY_LIST_FAIL: {
        return {
          ...state,
          loading:false,
          loaded:false,
        };
      }
      case fromBrowseAcademyAction.BROWSE_GET_ACADEMY_LIST_SUCCESS:{
        return{
          ...state,
          data: action.payload,
          loaded:true,
          loading:false,
        }
      }
      case fromBrowseAcademyAction.BROWSE_GET_ACADEMY_CONTENT: {
        return {
          ...state,
          contentLoading:true,
          contentLoaded:false,
        }
      }
      case fromBrowseAcademyAction.BROWSE_GET_ACADEMY_CONTENT_FAIL: {
        return {
          ...state,
          contentLoading:false,
          contentLoaded:false,
        };
      }
      case fromBrowseAcademyAction.BROWSE_GET_ACADEMY_CONTENT_SUCCESS:{
        const data=updateContent(state.data,action.payload)
        return{
          ...state,
          data,
          contentLoading:false,
          contentLoaded:true,
        }
      }
    }
    return state;
  }

  function updateContent(data,payload){
    let modifiedData = JSON.parse(JSON.stringify(data));
    const academyIndex = data.AcademyTerms.findIndex(academy => academy.Name === payload.AcademyData.Academy);
    const genreIndex = data.AcademyTerms[academyIndex].GenreTerms.findIndex(genre => genre.Name === payload.AcademyData.Genre);
    modifiedData.AcademyTerms[academyIndex].GenreTerms[genreIndex].Contents=payload.Content
    return modifiedData
  }

  export const getBrowseAcademy =(state:IbrowseAcademyReducerState)=>state.data;

  export const getBrowseAcademyLoaded = (state: IbrowseAcademyReducerState) => state.loaded;
  export const getBrowseAcademyLoading = (state: IbrowseAcademyReducerState) => state.loading;
  export const getBrowseAcademyContentLoaded = (state: IbrowseAcademyReducerState) => state.contentLoaded;
  export const getBrowseAcademyContentLoading = (state: IbrowseAcademyReducerState) => state.contentLoading;
