import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ContentUploadService } from '../../ao/content-upload/content-upload.service';
import { Globals } from '../../globals';
import { ContentVerificationService } from '../content-verification.service';

@Component({
  selector: 'yorbit-content-upload-home-popups',
  templateUrl: './content-upload-home-popups.component.html',
  styleUrls: ['./content-upload-home-popups.component.scss']
})
export class ContentUploadHomePopupsComponent implements OnInit {

  expertise: string = '';
  heading: string = ''
  courseDropDown = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;
  courseList101: any[] = [];
  courseList201: any[] = [];
  searchForm = new FormGroup({});
  isValid: boolean = true;
    isExpertiseValid: boolean = true;
    allowed101List: any;
    allowedPCUList = [];
    allowedAoList = [];
    allowed301List = [];
  isValidCategory: boolean =true;
  categoriesList :any[]=[];
  newCategory:string='';
  courseExpertise: string = '101';
  cuuserroles: any;
  
  
  //isAddCategoryDisabled:boolean=false;
  
  constructor(
    private dialogRef: MatDialogRef<ContentUploadHomePopupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _contentUploadService: ContentUploadService,
    private _contentVerificationService: ContentVerificationService,
    private globals:Globals
    ) { }

  ngOnInit() {
    
    //this.allowedAoList = this.globals.allowedAoList;
    //this.allowedPCUList = this.globals.allowedPCUList;
    //this.allowed301List = this.globals.allowed301List;
    this.isValid = true;
    this.isExpertiseValid = true;
    //this.allowed101List=this.globals.allowedDevList;
    this.isValidCategory =true;
    this.newCategory='';
    this.getRoles(); 

    //this.isAddCategoryDisabled=false;
    if (this.data == 'createQuiz') {
      this.heading = 'Select a course to proceed';
       
      var courseList101= this._contentVerificationService.get101CourseWithoutQuiz();
      var courseList201=this._contentVerificationService.get201CourseWithQuiz();
      forkJoin([courseList101,courseList201]).subscribe(([response1,response2])=>{
          this.courseList101=response1;
          this.courseList201=response2;
      });
      // this._contentVerificationService.get101CourseWithoutQuiz().then(
      //   response => {
      //     this.courseList101 = response;
      //     console.log('courselist', this.courseList101);
      //   }
      // );
    }
    else if(this.data=='AddCategory')//else if(this.data=='DisableAddCategory' ||this.data=='EnableAddCategory')
    {
       this.heading='Add new category';
    }
     else {
      this.heading = 'Select the content type to proceed';
    }
    //console.log(this.courseExpertise)
    this.filteredOptions = this.courseDropDown.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) :( this.courseExpertise=='101'?this.courseList101.slice():this.courseList201) )
      );

  }

  displayFn(course: any): string {
    return course && course.Name ? course.Name : '';
  }   

  onInputChange() {
    this.isValid = true;
  }
  onAddingNewCategory()
  {
  this.isValidCategory =true;
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    if(this.courseExpertise=='101'){
      return this.courseList101.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }else{
      return this.courseList201.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  selectExpertise(expertise: string) {
    this.isExpertiseValid = true;
    this.expertise = expertise;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  search() {
    this.isValid = true;
   // //console.log('fasd', this.courseDropDown);
    if (this.courseDropDown.value == null || this.courseDropDown.value.UniqueId == undefined) {
      this.isValid = false;
    }
  }

     //CUuserRole
     getRoles()
      {
       this._contentUploadService.getContentUploadUserRoles().toPromise().then(response => {
         this.cuuserroles = response 
         this.courseExpertise=this.cuuserroles.indexOf('101')!=-1?'101':'201';
       })
      }

  proceed() {
    this.isValid = true;
    this.isExpertiseValid = true;
    this.isValidCategory =true;
    if (this.data == 'createQuiz') {
      if (this.courseDropDown.value == null || this.courseDropDown.value.UniqueId == undefined) {
        this.isValid = false;
      }
      if (this.isValid) {
        var course = {
          'UniqueId': this.courseDropDown.value.UniqueId.toString(),
          'Name': this.courseDropDown.value.Name.toString(),
          'FromHomePage': true,
          'expertise':this.courseExpertise
        }
        this.dialogRef.close(course);
      }
    } else if (this.data == 'createContent') {
      if(this.expertise != '') {
      this.dialogRef.close(this.expertise);
      } else {
        this.isExpertiseValid = false;
      }
    }
    else if(this.data=='AddCategory')
    {
      if ( this.newCategory == undefined||this.newCategory == null ||this.newCategory == '') 
      {
      this.isValidCategory = false;
      }
      else {
        this.dialogRef.close(this.newCategory);
      }
    }
  }

  courseExpertiseSelected(expertise) {
    this.courseDropDown.setValue('')
    this.courseExpertise = expertise;
    this.filteredOptions = this.courseDropDown.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.Name),
      map(name => name ? this._filter(name) :( this.courseExpertise=='101'?this.courseList101.slice():this.courseList201) )
    );
  }
}
