import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'orderbyDate'
})
export class OrderbyDatePipe implements PipeTransform {
  transform(array: Array<any>, field: string, reverse?: boolean): any {
    if (array != null && array.length != 0) {
      //old first recent last
      if (reverse === true) {
        array.sort((a: any, b: any) : number => {
          // if (new Date(a[field]) > new Date(b[field])) {
          //   return 1;
          // } else if (new Date(a[field]) < new Date(b[field])) {
          //   return -1;
          // } else {
          //   return 0;
          // }
         return (+(new Date(a[field])) - +(new Date(b[field])))
        });
        return array;
      } else {
        //recent first old last
        array.sort((a: any, b: any) => {
          if (new Date(a[field]) < new Date(b[field])) {
            return 1;
          } else if (new Date(a[field]) > new Date(b[field])) {
            return -1;
          } else {
            return 0;
          }
        });
        return array;
      }
    }
    return array;
  }
}
