
<div class="overflowHeightFix borderBottomSplitLine" fxLayout="column" fxFlex="100%" *ngIf="pssFilters.loaded as loaded; else loading">
  <div class="overflowHeightFix borderBottomSplitLine" fxLayout="row" fxFlex="100%">
    <div class="floatLeft dateFilterContainer">
      <yorbit-date-filter (dateUpdateEvent)="updateFilter($event)"></yorbit-date-filter>
    </div>

    <div class="courseNameFilter floatLeft">
      <yorbit-course-name [filterData]="pssFilters"
      (courseNameUpdateEvent)="updateFilter($event)"
      ></yorbit-course-name>
    </div>

    <div class="floatLeft">
        <button
    class="arrangeByButton"
    mat-raised-button
    aria-label="Arrange By"
    (click)="orderByCourseDate(orderBy.type)"
  >
    {{orderBy.text}}
  </button>
    </div>

    <div class="floatLeft">
        <!-- <yorbit-pss-upload-download [pssFilterRange]="uploadDownloadData" [isCourseSelected]="isCourseSelected"
        [subSelection]="subSelection"
        >
          </yorbit-pss-upload-download> -->
    </div>

  </div>

  <div >
    <yorbit-type-filter [filterData]="pssFilters"
    [isCourseSelected]="isCourseSelected"
    (resetFilterEvent)="resetFilter($event)"
    (typeUpdateEvent)="updateFilter($event)"></yorbit-type-filter>
  </div>



</div>

<ng-template #loading>
  <app-preloader></app-preloader>
</ng-template>
