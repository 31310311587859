<mat-dialog-content class="popupDialog">
    <!--Close Popup Icon-->
    <div class="closePopupDialog">
      <button mat-button mat-icon-button (click)="closePopup()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    
    <!--Popup Header-->
    <div class="popupHeader">Remap Evaluator</div>
    
    <!--Mat Divider-->
    <mat-divider></mat-divider>

    <div class= "messageText">
      <div>
        Evaluator<span class="asterisk"><sup>*</sup></span>
      </div>
      <div>
        <select class="evaluatorDropDown"
          (change)="onSelectChange($event)" name="evaluator">
          <option value="" hidden selected>Select Evaluator</option>
          <option *ngFor="let evaluator of evaluatorList" [ngValue]="evaluator">
            {{evaluator.EvaluatorName}}
          </option>
        </select>
        </div>
    </div>
    
    <!--No Experts Message-->
    <div class= "messageText">
      <div>
        Reason for Update<span class="asterisk"><sup>*</sup></span>
      </div>
      <div>
        <textarea class="textAreaDiv" rows="5" (keyup)="onTextAreaChange($event)"></textarea>
      </div>
    </div>
    
     <!--Mat Divider-->
    <mat-divider></mat-divider>
  
    <div class="actionButtonContainer">
      <div>
        <button mat-raised-button class="actionButton upload-btn" [disabled]="disableSubmit" 
        (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </mat-dialog-content>
    
