import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { AuthService } from './auth.service';
import { AdalService } from 'adal-angular4';
import { AuthGuard } from './auth.guard';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { authReducers, effectsAuth } from './store';

export const authRoutes: Route[] = [];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('Auth', authReducers),
    EffectsModule.forFeature(effectsAuth)
  ],
  providers: [AuthService, AdalService, AuthGuard]
})
export class AuthModule {}
