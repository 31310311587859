<div fxLayout="column" fxLayoutAlign="center center" [ngStyle.gt-xs]="{ padding: '2% 10%' }" class="faq">
  <div class="faq-header" fxLayoutAlign="start center" fxShow.gt-xs="true" [fxShow.xs]="selectedDepartment==''">
    FAQs
  </div>
  <div class="faq-header" fxLayoutAlign="start center" fxShow.gt-xs="false" [fxShow.xs]="selectedDepartment!=''">
   <mat-icon fxFlexOffset="10px" (click)="unselectDepartment()">chevron_left</mat-icon> <div fxFlexOffset="5px">{{selectedDepartment}}</div>
  </div>
  <div class="faq-container" fxLayout="row" fxFlex="1 1 auto" fxShow fxHide.xs="true">
    <div fxFlex="20%">
      <div class="category-name" [ngClass]="{'active-category':selectedCategory === category.categoryName}" *ngFor="let category of faqCategories; let i=index"
        (click)="setSelectedCategory(category)">
        {{category.categoryName }}
      </div>
    </div>
    <div fxFlex="80%" class="faqContent">
      <div *ngFor="let faq of (faqs | categoryFilter: selectedCategory); let i = index" fxLayout="row">
        <div fxFlex="5">
          <div class="question">{{i+1}}.</div>
        </div>
        <div fxFlex="95">
          <div [innerHTML]="faq.Q" class="question"></div>
          <div [innerHTML]="faq.A" class="answer" *ngIf="selectedCategory !== 'Learning Business Partner Details'"></div>
          <div *ngIf="selectedCategory === '201/301 Course' && faq.Img !== undefined">
            <img [src]="faq.Img" [ngStyle]="{'height':faq.Style.height}" />
          </div>
          <div  *ngIf="selectedCategory==='Learning Business Partner Details'" fxLayout="column" fxLayoutAlign="start stretch" >
            <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlexOffset="20px" fxLayoutGap="20px" fxLayoutAlign="start center">
              <div fxFlex.gt-sm="55%">{{faq.A}}</div>
              <div fxFlex.gt-sm="45%"  class="learning-parnters-icon-snapshot">
                <img src="assets/images/LearningPartnersIconSnapshot.jpg" />
               </div>
            </div>
            <div *ngIf="!cdmMappingsLoaded" fxFlex="200px" fxLayoutAlign="center center">
              <app-preloader></app-preloader>
            </div>
            <div class="cdm-mapping-data"  fxFlexOffset="20px" *ngIf="cdmMappingsLoaded" fxLayout="column" fxLayoutAlign="start stretch">
              <div fxLayoutAlign="start stretch" class="header" >
                <div fxFlex="200px" fxLayoutAlign="center center"> BASE BU</div>
                <mat-divider fxFlex="1px" vertical="true"></mat-divider>
                <div fxFlex="200px" fxLayoutAlign="center center"> BASE BU DESCRIPTION</div>
                <mat-divider fxFlex="1px" vertical="true"></mat-divider>
                <div fxLayoutAlign="center center" fxFlex> LEARNING BUSINESS PARTNER</div>
              </div>
              <div class="data" fxFlex="50px"  *ngIf="cdmMappings.length==0" fxLayoutAlign="center center">
                No data available.
              </div>
              <div class="data" *ngFor="let row of cdmMappings" fxLayoutAlign="start stretch" >
                <div class="departmentName" fxFlex="200px">
                  {{row.Department}}
                </div>
                <mat-divider fxFlex="1px" vertical="true"></mat-divider>
                <div class="departmentName" fxFlex="200px">
                  {{row.DepartmentDescription}}
                </div>
                <mat-divider fxFlex="1px" vertical="true"></mat-divider>
                <div fxFlex fxLayoutAlign="start start" class="list" fxLayout="row wrap">
                  <div class="lpName" *ngFor="let lp of row.LearningPartners" (click)="sendMail(lp.MID)">{{lp.Name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</div>

<div fxLayout="column" fxLayoutAlign="space-around center" fxHide [fxShow.xs]="selectedDepartment==''">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let category of faqCategories" (click)="setSelectedCategory(category)" [expanded]="category.categoryName===selectedCategory">
      <mat-expansion-panel-header>
        <div class="category-name">{{ category.categoryName }}</div>
      </mat-expansion-panel-header>
      <div *ngFor="let faq of (faqs | categoryFilter: selectedCategory); let i=index">
        <div fxFlex="10">
          <div class="question">{{i+1}}.</div>
        </div>
        <div fxFlex="90">
          <div [innerHTML]="faq.Q" class="question"></div>
          <div [innerHTML]="faq.A" class="answer" *ngIf="selectedCategory !=='Learning Business Partner Details'"></div>
          <div *ngIf="selectedCategory === '201/301 course' && faq.Img !== undefined">
            <img [src]="faq.Img" [ngStyle.lt-md]="{'height':'80px'}" />
          </div>
          <div *ngIf="selectedCategory==='Learning Business Partner Details'" fxLayout="column" fxLayoutAlign="start stretch" >
            <div fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutAlign="start center">
              <div>{{faq.A}}</div>
              <div class="learning-parnters-icon-snapshot">
               <img src="assets/images/LearningPartnersIconSnapshot.jpg" />
              </div>
            </div>
            <div *ngIf="!cdmMappingsLoaded" fxFlex="200px" fxLayoutAlign="center center">
              <app-preloader></app-preloader>
            </div>
          
            <div class="cdm-mapping-data" fxFlexOffset="20px" *ngIf="cdmMappingsLoaded" fxLayout="column" fxLayoutAlign="start stretch">
              <div fxLayoutAlign="start stretch" class="header" >
                <div fxFlex fxLayoutAlign="center center"> DEPARTMENT</div>
              </div>
              <div class="data" *ngIf="cdmMappings.length==0" fxFlex="50px" fxLayoutAlign="center center">
                No data available.
              </div>
              <div class="data" *ngFor="let row of cdmMappings" fxLayoutAlign="start stretch" >
                <div class="departmentName" fxFlex (click)="selectDepartment(row.Department)" fxLayoutAlign="start center">
                  <div fxFlex>
                    {{row.Department}}
                  </div>
                  <mat-icon fxFlexOffset="10px">chevron_right</mat-icon> 
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class="cdm-mapping-data selectedDepartmentData" [fxShow.xs]="selectedDepartment!=''" fxShow.gt-xs="false" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayoutAlign="start stretch" class="header" >
    <div fxLayoutAlign="center center" fxFlex> LEARNING BUSINESS PARTNER</div>
  </div>
  <div class="data" *ngFor="let lp of selectedDepartmentLPList" fxLayoutAlign="start stretch" >
    <div fxFlex fxLayoutAlign="start center" class="list" role="button" (click)="sendMail(lp.MID)">
      <div class="lpName" fxFlex>{{lp.Name}}</div>
      <div fxFlex="24px">
        <mat-icon>mail_outline</mat-icon>
      </div>
    </div>
  </div>
</div>

