import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class StartCourseRequestService {
  vendor: string;
  config: Ienvironment;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }
  validateCourseRequest(courseId) {
    return this.http
      .get(this.config.apiUrl + 'Course/Request/Validate/' + courseId)
      .toPromise();
  }

  sendCourseRequestPrerequisiteCheck(payload) {
    return this.http
      .post(this.config.apiUrl + 'LearningPlan/Package/CheckForPrerequisites', payload)
      .toPromise();
  }
  sendCourseRequest(payload) {
    return this.http
      .post(this.config.apiUrl + 'LearningPlan/Package/GetApproval', payload)
      .toPromise();
  }
  getCloudDetailsOfLoggedInUser(courseId) {
    return this.http
      .get(this.config.apiUrl + 'Course/GetLabRequest/' + courseId + '/lab')
      .toPromise();
  }
  requestCloudLabAccess(payload) {
    return this.http
      .post(
        this.config.apiUrl +
        'Course/UpdateRequest/' +
        payload.CourseId +
        '/' +
        payload.CloudLabUniqueId +
        '/lab',
        {}
      )
      .toPromise();
  }
  getCourseOrPackageDetails(id, type) {
    if (type == 'Course') {
      return this.http.get(this.config.apiUrl + 'Course/' + id).toPromise();
    } else {
      return this.http.get(this.config.apiUrl + 'Package/' + id + '/' + type).toPromise();
    }
  }

  getClassroomDetailsForBatchSelection(courseId: number) {
    return this.http
      .get(
        this.config.apiUrl +
        'User/GetClassRoomBatchDetailsForLearner/' + courseId);
  }
  getIntentAndTaggingDetails(requestId):Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'LearningPlan/GetIntentDetails/' + requestId);
  }
}
