import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-restartcoursepopup',
  templateUrl: './restartcoursepopup.component.html',
  styleUrls: ['./restartcoursepopup.component.scss']
})
export class RestartcoursepopupComponent implements OnInit {
  description: string = '';

  constructor(public dialogRef: MatDialogRef<RestartcoursepopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.description = 'Do you opt to Retake?' ;
  }
  save() {
    this.dialogRef.close("yes");
  }

  close() {
    this.dialogRef.close("");
  }

}
