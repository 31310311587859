import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { FileSaverModule } from 'ngx-filesaver';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipesModule } from '@YorbitWorkspace/pipes';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { DirectivesModule } from '@YorbitWorkspace/directives';

import { ShareContentModule } from '../shared/card-feature/share-content/share-content.module';

import { PssRouterModule } from './pss-router/pss-router.module';
import { PssComponent } from './pss.component';

import { CourseRequestsComponent } from './course-requests/course-requests.component';
import { CertificationRequestsComponent } from './certification-requests/certification-requests.component';

import { CertDateChangeAlertComponent } from './cert-date-change-alert/cert-date-change-alert.component';
import { PssStatusMessageComponent } from './pss-status-message/pss-status-message.component';

import { PssFilterComponent } from './pss-filter/pss-filter.component';
import { DateFilterComponent } from './pss-filter/date-filter/date-filter.component';
import { CourseNameComponent } from './pss-filter/course-name/course-name.component';
import { TypeFilterComponent } from './pss-filter/type-filter/type-filter.component';
import { PssPaginateComponent } from './pss-filter/pss-paginate/pss-paginate.component';
import { PssSearchComponent } from './pss-filter/pss-search/pss-search.component';

import { PssFiltersComponent } from './pss-filters/pss-filters.component';
import { PssDownloadPopupComponent } from './pss-filters/pss-download-popup/pss-download-popup.component';
import { PssUploadPopupComponent } from './pss-filters/pss-upload-popup/pss-upload-popup.component';

import { PssEvaluationComponent } from './pss-evaluation/pss-evaluation.component';
import { PssCourseVendorComponent } from './pss-evaluation/pss-course-vendor/pss-course-vendor.component';
import { PssCourseTypeComponent } from './pss-evaluation/pss-course-type/pss-course-type.component';
import { PssAcademyComponent } from './pss-evaluation/pss-academy/pss-academy.component';
import { PssEvaluatorVendorComponent } from './pss-evaluation/pss-evaluator-vendor/pss-evaluator-vendor.component';
import { PssCourseNameComponent } from './pss-evaluation/pss-course-name/pss-course-name.component';
import { PssCertificationSubstatusComponent } from './pss-evaluation/pss-certification-substatus/pss-certification-substatus.component';
import { PssLearningOpmspocComponent } from './pss-evaluation/pss-learning-opmspoc/pss-learning-opmspoc.component';
import { DownloadProblemStatementComponent } from './pss-evaluation/download-problem-statement-component/download-problem-statement-component';
import { DownloadEvaluationSheetComponent } from './pss-evaluation/download-evaluation-sheet/download-evaluation-sheet.component';
import { DownloadSolutionFileComponent } from './pss-evaluation/download-solution-file/download-solution-file.component';
import { DownloadPopup } from './pss-evaluation/download-popup/download-popup.component';
import { DownloadDocumentPopupComponent } from './pss-evaluation/download-document-popup/download-document-popup.component';
import { DownloadErrorFileComponent } from './pss-evaluation/download-error-file/download-error-file.component';
import { ErrorDownloadPopup } from './pss-evaluation/error-download-popup/error-download-popup.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PssEvaluationContentComponent } from './pss-evaluation/pss-evaluation-content/pss-evaluation-content.component';
import { EvaluationFileUploadComponent } from './evaluation-file-upload/evaluation-file-upload.component';
import { PssEditScoreCardPopupComponent } from './pss-evaluation/pss-edit-score-card-popup/pss-edit-score-card-popup.component';
import { ConfirmationDialogComponent } from './pss-evaluation/confirmation-dialog/confirmation-dialog.component';
import { PssRemapEvaluatorComponent } from './pss-evaluation/pss-remap-evaluator/remap-evaluator.component';
import { PssPublishPopupComponent } from './pss-evaluation/pss-publish-popup/pss-publish-popup.component';
import { PssErrorUploadPopupComponent } from './pss-evaluation/pss-error-upload-popup/pss-error-upload-popup.component';
import { PopupMessagesComponent } from './pss-evaluation/popup-messages/popup-messages.component';
import { CoursePropAndStatusesFilterComponent } from './pss-filters/course-prop-and-statuses-filter/course-prop-and-statuses-filter.component';
//import { DownloadDocumentPopupComponent } from './pss-evaluation/download-document-popup/download-document-popup.component';

import { ContentVerificationModule } from '../content-verification/content-verification.module';
import { PssContentApprovalComponent } from './pss-content-approval/pss-content-approval.component';
import { PssContentUpdatePopupComponent } from './pss-content-approval/pss-content-update-popup/pss-content-update-popup.component';
import { PssContentActionPopupComponent } from './pss-content-approval/pss-content-action-popup/pss-content-action-popup.component';
import { SearchEvaluatorPopupComponent } from '../ao/content-upload/search-evaluator/search-evaluator-popup/search-evaluator-popup.component';
import { SearchEvaluatorModule } from '../ao/content-upload/search-evaluator/search-evaluator.module';
import { PssOpmContentApprovalComponent } from './pss-opm-content-approval/pss-opm-content-approval.component';
import { EditPopupComponent } from './edit-popup/edit-popup.component';
import { DownloadBlobPopupComponent } from '../shared/download-blob-popup/download-blob-popup.component';
import { DownloadBlobPopupModule } from '../shared/download-blob-popup/download-blob-popup.module';
import { ExternalCertificateComponent } from './external-certificate/external-certificate.component';
import { CourseProcedureUploadComponent } from './course-procedure-upload/course-procedure-upload.component';
import { PssClassroomPopupComponent } from './pss-filters/pss-classroom-popup/pss-classroom-popup.component';
@NgModule({

  exports: [PssContentApprovalComponent, CoursePropAndStatusesFilterComponent, CertDateChangeAlertComponent, PssStatusMessageComponent, CourseRequestsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatDividerModule,
    MatTabsModule,
    MatExpansionModule,
    PssRouterModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    FileSaverModule,
    ShareContentModule,
    PipesModule,
    ReusableUiModule,
    NgbModule,
    MatTooltipModule,
    MatDividerModule,
    DirectivesModule,
    ReusableUiModule,
    ContentVerificationModule,
    SearchEvaluatorModule,
    VirtualScrollerModule,
    DownloadBlobPopupModule
  ],
  declarations: [
    PssComponent,
    PssEvaluationComponent,
    PssEditScoreCardPopupComponent,
    PssDownloadPopupComponent,
    PssUploadPopupComponent,
    CertDateChangeAlertComponent,
    PssFiltersComponent,
    CourseRequestsComponent,
    CertificationRequestsComponent,
    PssContentApprovalComponent,
    PssContentUpdatePopupComponent,
    PssContentActionPopupComponent,
    PssStatusMessageComponent,
    PssCourseTypeComponent,
    PssAcademyComponent,
    PssEvaluatorVendorComponent,
    PssCourseNameComponent,
    PssCertificationSubstatusComponent,
    PssLearningOpmspocComponent,
    PssEvaluationContentComponent,
    EvaluationFileUploadComponent,
    PssEditScoreCardPopupComponent,
    ConfirmationDialogComponent,
    DownloadProblemStatementComponent,
    DownloadDocumentPopupComponent,
    DownloadEvaluationSheetComponent,
    DownloadSolutionFileComponent,
    DownloadPopup,
    ErrorDownloadPopup,
    DownloadErrorFileComponent,
    ConfirmationDialogComponent,
    PssRemapEvaluatorComponent,
    PssPublishPopupComponent,
    PopupMessagesComponent,
    PssErrorUploadPopupComponent,
    PssEvaluationContentComponent,
    EvaluationFileUploadComponent,
    PssLearningOpmspocComponent,
    PssCertificationSubstatusComponent,
    PssCourseNameComponent,
    PssEvaluatorVendorComponent,
    PssAcademyComponent,
    PssCourseTypeComponent,
    PssCourseVendorComponent,
    PssSearchComponent,
    PssPaginateComponent,
    PssFilterComponent,
    DateFilterComponent,
    CourseNameComponent,
    TypeFilterComponent,
    PopupMessagesComponent,
    CoursePropAndStatusesFilterComponent,
    PssOpmContentApprovalComponent,
    EditPopupComponent,
    ExternalCertificateComponent,
    CourseProcedureUploadComponent,
    PssClassroomPopupComponent
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ],
})
export class PssModule {}
