import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RoleAccessService } from '../../role-access.service';
import * as RoleActions from './../actions/role.action';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class RoleEffects {
  constructor(
    private actions$: Actions,
    private roleAccessService: RoleAccessService
  ) {}

  
  roleGetAccessList$ = createEffect(() => this.actions$.pipe(
    ofType(RoleActions.ROLE_GET_ACCESS_LIST),
      switchMap(() => {
        return this.roleAccessService.getRoles().pipe(
          map(roleAccess => {
            return new RoleActions.RoleGetAccessListSuccess(roleAccess);
          }),
          catchError(error => of(new RoleActions.RoleGetAccessListFail(error)))
        );
      })
    ));
}
