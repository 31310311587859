import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CloudLabService } from './cloud-lab.service';
import { LearningPathService } from '../learning-path.service';
import { LPBreadcrumbsStateModel } from '../learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from '../learning-path-breadcrumbs.interface';
import { Subscriber } from 'rxjs';
import { Router } from '@angular/router';
import { DetailsPageService } from '../../details-page/details-page.service';
@Component({
  selector: 'yorbit-cloud-lab',
  templateUrl: './cloud-lab.component.html',
  styleUrls: ['./cloud-lab.component.scss']
})
export class CloudLabComponent implements OnInit, OnDestroy {
  LPBreadcrumbs: ILPBreadcrumbs;
  getLabDetailsInput: any;
  courseId: any;
  courseExpertise: any;
  cloudLabDetails: Array<any>;
  cloudLabDetailsForUI:Array<any>;
  disableCloudLabRequestButton: Array<boolean>;
  statusMessage: Array<string>;
  getRequestProcessing: boolean;
  getRequestError: boolean;
  cloudLabCompSubscriptions: any;
  vendor: any;
  workflowStatus: any = '';
  workflowManipulated: any = '';
  requestCloudAccessProcessing: Array<boolean>;
  requestCloudAccessError: Array<boolean>;
  isCloudAPInvoked: boolean;
  //@Input('courseDetails') courseDetails:any;

  cloudLabToolTipMessages: any;
  hovered: Array<boolean>;
  clicked: Array<boolean>;
  disableMsg: any;
  showMoreClicked: boolean;
  constructor(
    private _cloudSvc: CloudLabService,
    private _lpSvc: LearningPathService,
    private _router: Router,
    private detailsPageService: DetailsPageService
  ) {
    this.cloudLabToolTipMessages = {
      RequestAccess: {
        message1:
          'Cloud lab enables hands on access to the required software for your learning.',
        message2: 'Cloud lab access duration is : '
      },
      AccessRequested: {
        message1:
          'Your cloud lab request is sent! You will receive further communications in 2-3 working days.',
        message1_AutoApproval:
          'Your cloud lab request is sent! It will be processed once the course request is processed.',
        message1_RMApproval:
          'Your cloud lab request is sent! It will be processed once the course request is approved.',
        message2: 'You are not required to do anything until then.'
      },
      AccessGranted: {
        message1: 'Please note that the cloud lab duration is for ',
        message2: 'Post which your access will be automatically revoked.'
      },
      AccessRevoked: {
        message1:
          'Access to cloud lab has been revoked as you have exceeded the usage duration.',
        message2:
          'In case of further queries you can write to Vlabs.Yorbit@mindtree.com'
      },
      VendorProvidedLab: {
        message1: 'Cloud lab is provided as a part of the course on ',
        message2: 'You can utilize the same for hands on learning.'
      }
    };
  }

  ngOnInit() {
    this.hovered = [];
    this.clicked = [];
    this.cloudLabCompSubscriptions = {};
    this.isCloudAPInvoked = false;
    this.subscribeToBreadcrumbs();
    this.showMoreClicked = false;
    // this.getCourseCloudLabDetails(unit1);
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  getCourseCloudLabDetails(unit) {
    // added to recall the get api after the request cloud lab action is done
    this.getLabDetailsInput = unit;
    this.courseId = null;
    this.courseExpertise = null;
    this.cloudLabDetails = new Array();
    this.cloudLabDetailsForUI = new Array();
    this.disableCloudLabRequestButton = new Array();
    this.statusMessage = new Array();
    //Get Cloud lab details for courses only
    if (unit.ItemType.toLowerCase() === 'course') {
      this.getRequestProcessing = true;
      this.getRequestError = false;
      // Set courseId to UniqueId for Project & Account Courses, courseId to ItemId for Yorbit Courses
      this.courseExpertise = unit.ItemExpertise;
      // if (unit.AccountPackage || unit.ProjectPackage) {
      //   this.courseId = unit.UniqueId;
      // } else {
      this.courseId = unit.ItemId;
      // }
      //Call the Course/Get/{CourseId}/lab api
      //Input : courseId
      //Output : List of objects which has the Cloud lab details
      //Method : GET
      if (this.courseId != null) {
        if (true || unit.AccountPackage || unit.ProjectPackage) {
          this.getlabAPI(unit.ItemId);
        } else {
          this.detailsPageService
            .getPackageDetails(this.courseId, 'Course')
            .toPromise()
            .then(res => {
              if (res !== null) {
                this.getlabAPI(res.UniqueId);
              }
            });
        }
      }
    }
  }
  getlabAPI(UniqueId) {
    this._cloudSvc.getCloudLabDetails(UniqueId).subscribe(
      response => {
        this.cloudLabDetails = response;
        this.cloudLabDetailsForUI = []
        if(this.showMoreClicked){
          this.cloudLabDetailsForUI = response;
        }else{
          this.cloudLabDetailsForUI.push(response[0]);         
        }
        setTimeout(() => {
          this.getRequestProcessing = false;
        }, 2000);
        if (this.cloudLabDetails.length > 0) {
          if (this.cloudLabDetails != null) {
            this.requestCloudAccessProcessing = new Array(
              this.cloudLabDetails.length
            );
            this.requestCloudAccessError = new Array(
              this.cloudLabDetails.length
            );
            this.hovered = new Array(this.cloudLabDetails.length);
            this.clicked = new Array(this.cloudLabDetails.length);
            this.disableMsg = new Array(this.cloudLabDetails.length);

            this.hovered.fill(false);
            this.clicked.fill(false);
          }
          const _201NotApprovedMessage =
            'Only Approved 201 courses be allowed to Request for Cloudlab Access';
          const _301NotApprovedMessage =
            'Only Approved 301 courses be allowed to Request for Cloudlab Access';

          response.forEach((value, key) => {
            this.statusMessage[key] = value.statusMessage;
            if (value.cloudLabDetails != null) {
              this.disableMsg[key] = '';
              switch (this.courseExpertise) {
                case '101':
                  this.disableCloudLabRequestButton[key] = false;
                  break;
                case '201':
                  if (
                    this.workflowManipulated == 'Not Started'
                    // _201NotApprovedMessage.toLowerCase() ===
                    //   value.statusMessage.toLowerCase()
                  ) {
                    this.disableCloudLabRequestButton[key] = true;
                    this.disableMsg[key] =
                      'Cloud lab can be requested once you start this course.';
                  } else {
                    this.disableCloudLabRequestButton[key] = false;
                  }
                  break;
                case '301':
                  if (
                    this.workflowManipulated == 'Not Started'
                    // _301NotApprovedMessage.toLowerCase() ===
                    //   value.statusMessage.toLowerCase()
                  ) {
                    this.disableCloudLabRequestButton[key] = true;
                    this.disableMsg[key] =
                      'Cloud lab can be requested once you start this course.';
                  } else {
                    this.disableCloudLabRequestButton[key] = false;
                  }
                  break;
                default:
                  this.disableCloudLabRequestButton[key] = false;
              }
            }
          });
        }
      },
      error => {
        this.getRequestProcessing = false;
        if (error.status === '403') {
          //this.cloudLabDetails[0] = error.data.cloudLabDetails;
          this.cloudLabDetails[0] = { cloudLabDetails: null };
        } else {
          this.getRequestError = true;
        }
      }
    );
  }

  requestCloudLabAccess(cloud, index) {
    const payload = {
      CourseId: cloud.CourseId,
      CloudLabUniqueId: cloud.CloudLabUniqueId
    };
    this.requestCloudAccessProcessing[index] = true;
    this.requestCloudAccessError[index] = false;
    //Call the Course/UpdateRequest/lab api
    //Input : payload
    //Output : an object with a string true/false
    //true => requested successfully. false => request not created
    //Method : POST
    this._cloudSvc.requestCloudLabAccess(payload).subscribe(
      response => {
        if (response.toLowerCase() === 'success') {
          this.requestCloudAccessProcessing[index] = false;
          this.cloudLabDetails[index].cloudLabDetails.AccessStatus =
            'Access Requested';
          this.getRequestProcessing = true;
          //need to update the lab details
          this.getCourseCloudLabDetails(this.getLabDetailsInput);
        }
      },
      error => {
        this.requestCloudAccessProcessing[index] = false;
        this.requestCloudAccessError[index] = true;
      }
    );
  }

  disableOtherCloudLabReqButtons() {
    //disables other cloud lab request buttons when a request is get processed.
    if (this.cloudLabDetails != null) {
      for (let count = 0; count <= this.cloudLabDetails.length; count++) {
        if (this.requestCloudAccessProcessing[count]) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  subscribeToBreadcrumbs() {
    this.cloudLabCompSubscriptions.breadcrumbsSubscription = this._lpSvc
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        this.LPBreadcrumbs = breadcrumbs;
        if (this.LPBreadcrumbs.CourseDetails.CourseId !== null) {
          if (
            this.cloudLabCompSubscriptions.breadcrumbsSubscription !== undefined
          ) {
            this.cloudLabCompSubscriptions.breadcrumbsSubscription.unsubscribe();
          } else {
            const unit = {
              ItemType: 'Course',
              ItemExpertise: this.LPBreadcrumbs.CourseDetails.Expertise,
              AccountPackage: this.LPBreadcrumbs.CourseDetails.IsAccount,
              ProjectPackage: this.LPBreadcrumbs.CourseDetails.IsProject,
              ItemId: this.LPBreadcrumbs.CourseDetails.CourseId
            };
            this.vendor = this.LPBreadcrumbs.CourseDetails.Vendor;
            this.workflowStatus = this.LPBreadcrumbs.CourseDetails.Workflow
              ? this.LPBreadcrumbs.CourseDetails.Workflow.toUpperCase()
              : null;
            this.workflowManipulated = this.getWorkflow(
              this.LPBreadcrumbs.CourseDetails.Workflow
            );
            if (!this.isCloudAPInvoked) {
              this.isCloudAPInvoked = true;
              setTimeout(() => {
                this.getCourseCloudLabDetails(unit);
              }, 1000);
            }
          }
        }
      });
  }
  unsubscribeAllSubscriptions() {
    for (const subscriberKey in this.cloudLabCompSubscriptions) {
      if (this.cloudLabCompSubscriptions[subscriberKey] instanceof Subscriber) {
        this.cloudLabCompSubscriptions[subscriberKey].unsubscribe();
      }
    }
  }

  goToCloudHelp() {
    this._router.navigate(['/info/faq/Cloud Lab']);
  }

  isNotRequested(status) {
    if (
      status.toLowerCase() == '' ||
      status.toLowerCase() == 'access rejected' ||
      status.toLowerCase() == 'not yet approved' ||
      status.toLowerCase() == 'course cancelled'
    ) {
      return true;
    }
    return false;
  }

  canDisableRequestBtn(cloudIndex) {
    if (
      this.requestCloudAccessProcessing[cloudIndex] ||
      this.disableCloudLabRequestButton[cloudIndex] ||
      this.disableOtherCloudLabReqButtons()
    ) {
      return true;
    }
    return false;
  }

  getWorkflow(workflow) {
    const workflowStatus = workflow ? workflow.toLowerCase() : '';
    let status = '';
    //  if (workflowStatus != '') {
    switch (workflowStatus) {
      //not started status
      case '':
      case null:
      case 'start course':
      case 'not started':
      case 'cancelled':
      case 'request':
      case 'request cancelled':
      case 'rejected':
      case 'pre-approved':
      case 'preapproved':
      case 'send request':
        status = 'Not Started';
        break;
      case 'pending':
      case 'under review':
        status = 'Pending Approval';
        break;
      case 'on hold':
      case 'request on hold':
        status = 'Request On Hold';
        break;
      case 'approved':
      case 'planning in-progress':
      case 'course procedure shared':
      case 'course in-progress':
        status = 'Course In Progress';
        break;
      case 'completed':
      case 'course completed':
      case 'course completed - resigned':
        status = 'Course Completed';
        break;
      default:
        status = 'Course In Progress';
    }
    //  }
    return status;
  }

  expand() {
    this.showMoreClicked = true;
    this.cloudLabDetailsForUI = this.cloudLabDetails;
  }

  collapse() {
    this.showMoreClicked = false;
    this.cloudLabDetailsForUI = [];
    this.cloudLabDetailsForUI.push(this.cloudLabDetails[0]);
  }
}
