import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MyPageService } from '../my-page.service';
import * as fromMyPageStore from '../store';
import { Store } from '@ngrx/store';
import {
  IUserSkills,
  IPrimarySkill,
  IAcquiredOrDeclaredSkill
} from '../store/my-page-details.interface';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DeclareSkillPopupComponent } from './declare-skill-popup/declare-skill-popup.component';
import {ContentPropertyInArrayPipe} from '@YorbitWorkspace/pipes'

@Component({
  selector: 'yorbit-skill-profile',
  templateUrl: './skill-profile.component.html',
  styleUrls: ['./skill-profile.component.scss']
})
export class SkillProfileComponent implements OnInit {
  MId: string;
  role: string;
  skillProfileDetails: IUserSkills;
  primarySkill: IPrimarySkill;
  acquired101SkillList: Array<IAcquiredOrDeclaredSkill> = [];
  acquired201SkillList: Array<IAcquiredOrDeclaredSkill> = [];
  acquired301SkillList: Array<IAcquiredOrDeclaredSkill> = [];
  declared101SkillList: Array<IAcquiredOrDeclaredSkill> = [];
  declared201SkillList: Array<IAcquiredOrDeclaredSkill> = [];
  declared301SkillList: Array<IAcquiredOrDeclaredSkill> = [];
  acquiredTabClicked: boolean;
  declaredTabClicked: boolean;
  showPreLoader: boolean;
  isPrimarySkillsLoading: boolean;
  isSkillProfileLoading: boolean;

  constructor(
    private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    private route: ActivatedRoute,
    private _myPageSvc: MyPageService,
    private _popup: MatDialog
  ) {}

  ngOnInit() {
    this.acquiredTabClicked = true;
    this.declaredTabClicked = false;
    this.showPreLoader = false;
    this.isPrimarySkillsLoading = false;
    this.isSkillProfileLoading = true;

    this.route.params.subscribe(params => {
      this.MId = params.id;
      this.role = params.role;
      this.getPrimarySkillDetails();
      this.getSkillProfileDetails();
    });
  }
  getPrimarySkillDetails() {
    this.isPrimarySkillsLoading = true;
    if (this.role === 'self') {
      this.myPageStore
        .select(fromMyPageStore.getMyPagePrimarySkill)
        .subscribe(data => {
          this.isPrimarySkillsLoading = false;
          this.primarySkill = data;
        });
    } else if (this.role === 'learner') {
      this._myPageSvc.getUserPrimarySkill(this.MId, 'learner').subscribe(data => {
        this.isPrimarySkillsLoading = false;
        this.primarySkill = data;
      });
    }
  }

  getSkillProfileDetails() {
    this.isSkillProfileLoading = true;
    if (this.role === 'self') {
      this.myPageStore
        .select(fromMyPageStore.getMyPageSkillProfile)
        .subscribe(data => {
          this.isSkillProfileLoading = false;
          this.setData(data);
        });
    } else if (this.role === 'learner') {
      this._myPageSvc.getDeclaredAndAquiredSkill(this.MId, 'learner').subscribe(
        data => {
          this.isSkillProfileLoading = false;
          this.setData(data);
        },
        error => {
        }
      );
    }
  }
getSkillFilterByExpertise(skills,aquired){
  skills.forEach(skill => {
    const filterPipe = new ContentPropertyInArrayPipe();
    const arrangeSkillByExpertise={
      "E101" :{
        "Name":skill.Name,
         "CourseInfo":filterPipe.transform(skill.CourseInfo,{'property':'Expertise','list':['101']})
        },
        "E201" :{
          "Name":skill.Name,
           "CourseInfo":filterPipe.transform(skill.CourseInfo,{'property':'Expertise','list':['201']})
          },
          "E301" :{
            "Name":skill.Name,
             "CourseInfo":filterPipe.transform(skill.CourseInfo,{'property':'Expertise','list':['301']})
            }
    }
    this.setSkill(arrangeSkillByExpertise,aquired);
  });
}
setSkill(arrangeSkillByExpertise,aquired){
  if(aquired){
    if(0!==arrangeSkillByExpertise.E101.CourseInfo.length){
      this.acquired101SkillList.push(arrangeSkillByExpertise.E101);
    }
    if(0!==arrangeSkillByExpertise.E201.CourseInfo.length){
      this.acquired201SkillList.push(arrangeSkillByExpertise.E201);
    }
    if(0!==arrangeSkillByExpertise.E301.CourseInfo.length){
      this.acquired301SkillList.push(arrangeSkillByExpertise.E301);
    }
  }else{
    if(0!==arrangeSkillByExpertise.E101.CourseInfo.length){
      this.declared101SkillList.push(arrangeSkillByExpertise.E101);
    }
    if(0!==arrangeSkillByExpertise.E201.CourseInfo.length){
      this.declared201SkillList.push(arrangeSkillByExpertise.E201);
    }
    if(0!==arrangeSkillByExpertise.E301.CourseInfo.length){
      this.declared301SkillList.push(arrangeSkillByExpertise.E301);
    }
  }
}
  acquiredSkillClick() {
    this.acquiredTabClicked = true;
    this.declaredTabClicked = false;
  }
  declaredSkillClick() {
    this.acquiredTabClicked = false;
    this.declaredTabClicked = true;
  }

  setData(data){
    this.acquired101SkillList=[];
    this.acquired201SkillList=[];
    this.acquired301SkillList=[];
    this.declared101SkillList=[];
    this.declared201SkillList=[];
    this.declared301SkillList=[];
    this.skillProfileDetails = {
      AcquiredSkills: data.AcquiredSkills,
      DeclaredSkills: data.DeclaredSkills
    };
    this.getSkillFilterByExpertise(
      this.skillProfileDetails.AcquiredSkills,
      true
    )
    this.getSkillFilterByExpertise(
      this.skillProfileDetails.DeclaredSkills,
      false
    )
  }

  declareSkill(event) {
    event.stopPropagation();

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //addDialogContainer

      dialogConfig.data = {
        role: this.role
      };

      const dialogRef = this._popup.open(DeclareSkillPopupComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(response => {
        this.getSkillProfile()
      });
    }

    getSkillProfile() {
      this.myPageStore.dispatch(
        new fromMyPageStore.GetMyPageSkillProfile(this.MId)
      );
    }

}
