import { Component, Input, OnInit,OnChanges } from '@angular/core';
import{PssEvaluationService} from '../pss-evaluation.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';

import { Injectable } from "@angular/core";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {DownloadPopup} from '../download-popup/download-popup.component';

@Component({
  selector: 'yorbit-download-solution-file',
  templateUrl: './download-solution-file.component.html',
  styleUrls: ['./download-solution-file.component.scss']
})
export class DownloadSolutionFileComponent implements OnInit {
  requestId:any;
  type: any;
  LearnerId:string;
  EvaluationRequestId:any;
  isProblemStatementAvailable: boolean;
  MID:string;
  downloadStatus: string;
  showPreloader: boolean;
  contentLoadedSuccessfully: boolean;
  closeResult: string;
  isSolutionFileAvailable: boolean;
  constructor(
    private router: Router,
    //private http: http,
    private activatedRoute: ActivatedRoute,
    private pssEvaluationService: PssEvaluationService,
    private modalService: NgbModal,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>
  ) {

    this.isProblemStatementAvailable = false;

    this.showPreloader = true;
    this.contentLoadedSuccessfully = false;

    this.isSolutionFileAvailable=false;
   }

  ngOnInit() {
    /* if (this.CourseId != null || this.CourseId != undefined) {
      this.pssEvaluationService.getProblemStatement(this.CourseId)
      .then(response=>{
        ////console.log(response);
        if(response==null || response=='')
        {
          this.isProblemStatementAvailable=false;
        }
        else{
          this.isProblemStatementAvailable=true;
        }
        this.showPreloader=false;
        this.contentLoadedSuccessfully=true;
      })
      .catch(function(error){
        this.showPreloader=false;
        this.contentLoadedSuccessfully=false;
        this.isProblemStatementAvailable=false;

      });

} */

  }

//   downloadSolutionFile() {
//     //////console.log( course );



//       this.downloadStatus = 'downloading...';
//       ////console.log('calling the API to fetch Azure URL for downloading');
//       this.pssEvaluationService
//         .getLearnerProjectFile(this.type,this.requestId)
//         .then((response: any) => {



// /* let headers = new HttpHeaders();
// headers = headers.set('Accept', 'application/pdf');
// return this.http.get(response, { headers: headers, responseType: 'blob' as 'json' }); */
//       //  saveAs(response, this.fileName);



//          // ////console.log( response.data );
//          /*  ////console.log(response);
//           response.setHeader('content=disposition','attachment');
//           response.write
//  var link = document.createElement('a');
//           //link.style.display='none';
//          // document.body.appendChild(link);
//           link.href=response; */
//          // link.setAttribute('href',window.URL.createObjectURL(response.URL));
//         // link.setAttribute('download', this.fileName);
//         // saveAs(response, this.fileName);

//           // link.download=response.substr(response.lastIndexOf('/')+1);
//           // link.click();
//           //document.body.removeChild(link);

//           //link.href=response;
//         // link.download=response.substr(response.lastIndexOf('/')+1);
//          // document.body.appendChild(link);
//          // link.click();
//          //document.body.removeChild(link);
//          window.open(response, '_self');
//        //window.location.href=response.URL;
//           this.downloadStatus = 'Downloaded';
//         })
//         .catch(err => {
//           this.downloadStatus = 'Download';
//         });

//   }

  open(requestId: string | number) {
    const modelRef = this.modalService.open(DownloadPopup);
    modelRef. componentInstance.requestId = requestId;
    modelRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
