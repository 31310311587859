import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupService } from '../../shared/global-popups/popup.service';
@Component({
  selector: 'yorbit-content-upload-status-popup',
  templateUrl: './content-upload-status-popup.component.html',
  styleUrls: ['./content-upload-status-popup.component.scss']
})
export class ContentUploadStatusPopupComponent implements OnInit, OnDestroy {
  formData: any;
  type: any;
  title: any;
  field :any;
  sesssionPopUpSubscription : any;
  constructor(
    public dialogRef: MatDialogRef<ContentUploadStatusPopupComponent>,
    private _popupService : PopupService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.formData = data.data;
    this.type = data.type;
    //  this.title = this.formData.title ? this.formData.title : this.formData;
    this.title = this.formData.title ? this.formData.title : '';
    this.field = this.formData.field ? this.formData.field : '';
  }

  ngOnInit() {
    this.sesssionPopUpSubscription = this._popupService.getSessionTimeoutPopupStatus().subscribe(isOpen =>{
      if(isOpen){
        this.dialogRef.close(false);
      }
    })
  }

  close(canSubmit, isCloseBtnClicked) {
    if ((this.type == 'deleteConfirmation' || this.type == 'submit') && isCloseBtnClicked == true) {
      this.dialogRef.close(true);    
    }else if(this.type == 'exit' && isCloseBtnClicked){
      this.dialogRef.close(true);
    }else{
      this.dialogRef.close(canSubmit);
    }
  }
  ngOnDestroy(){
    if(this.sesssionPopUpSubscription){
      this.sesssionPopUpSubscription.unsubscribe();
    }
  }
}
