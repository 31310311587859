import { Component, OnInit } from '@angular/core';
import { ContentUploadService } from '../../ao/content-upload/content-upload.service';
import { Globals } from '../../globals';
import {PssService} from '../pss.service'

@Component({
  selector: 'yorbit-pss-opm-content-approval',
  templateUrl: './pss-opm-content-approval.component.html',
  styleUrls: ['./pss-opm-content-approval.component.scss']
})
export class PssOpmContentApprovalComponent implements OnInit {
  currentUser: string;
  allowedList: string[];
  cuuserroles: any;
  //private _contentUploadService: any;

  constructor(
    private pssSvc:PssService,
    private _contentUploadService:ContentUploadService,
    public globals: Globals,
    ) {}
  selectedContent: any;
  courseApproval: boolean;

  ngOnInit() {
    this.courseApproval = false;
    this.selectedContent = {};
    this.pssSvc.courseApprovalPage = this.courseApproval;
    this.getRoles(); 

    // this.allowedList=[
    //     "M1019251", "M1032547", "M1048693", "M1049247", "M1019415", "M1032598", "M1032565", "M1048205", "M1056003", "M1035866", "M1053819", "M1048472","M1069860","M9011637","M9011637"
    // ];
    //this.allowedList=this.globals.allowedPSSList;
    

  }

  getRoles()
  {
    this._contentUploadService.getContentUploadUserRoles().toPromise().then(response => {
      this.cuuserroles = response 
      //console.log(this.cuuserroles)
    })
  }

  verifyCourse(myEvent){
    this.selectedContent=myEvent;
    this.courseApproval=true;
    this.pssSvc.courseApprovalPage = this.courseApproval;
  }
  enableCourseSelection(event){
    this.courseApproval=false;
    this.pssSvc.courseApprovalPage = this.courseApproval;
  }

}
