import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[yorbitWorkspaceAutofocus]'
})
export class AutofocusDirective implements OnChanges {
  @Input() setfocus: boolean;
  constructor(private ele: ElementRef) {}
  ngOnChanges() {
    if (this.setfocus) {
      this.ele.nativeElement.tabIndex = '1';
      setTimeout(this.ele.nativeElement.focus(), 500);
    }
  }
}
