import { Component, OnInit, Input, Output, EventEmitter, Type } from '@angular/core';
import { YorbitCoursesService } from '../../services/yorbit-courses.service';
enum Events {
  OK = 0,
  CANCEL = 1
}

@Component({
  selector: 'yorbit-courses',
  templateUrl: './yorbit-courses.component.html',
  styleUrls: ['./yorbit-courses.component.scss']
})
export class YorbitCoursesComponent implements OnInit {
  selectedCourses: any[] = [];
  searchKey: string = '';
  filteredCourses: any[] = [];
  coursesFinal = [];
  isCoursesLoading: boolean = false;
  Events: typeof Events = Events;
  showSearchBar: boolean;

  @Input() courses: any;
  @Input() expertise: any;

  @Input() Account:boolean;
  @Input() Project:boolean;

  @Input() AccountId: string;
  @Input() ProjectId: string;

  @Output() select = new EventEmitter<any>();

  constructor(private courseSvc: YorbitCoursesService) { }

  ngOnInit() {

    if (this.Account == true) {
      this.showSearchBar = false;
      this.selectedCourses = this.courses.map(x => x);
      this.getAllAccountCourses();
    }

    else if (this.Project == true) {
      this.showSearchBar = false;
      this.selectedCourses = this.courses.map(x => x);
      this.getAllProjectCourses();
    }

    else {
      this.showSearchBar = false;
      this.selectedCourses = this.courses.map(x => x);
      this.getYorbitCourses(this.expertise);
    }
  }

  getAllAccountCourses() {
   
    this.isCoursesLoading = true;
      this.isCoursesLoading = false;
      this.courseSvc.CoursesList = this.courseSvc.AccountCoursesList.filter(ele=>ele.RepoId == this.AccountId);
      this.setData(this.courseSvc.CoursesList);
   
    // this.courseSvc.getAllAccountCourses().subscribe(
    //   data => {
    //     data = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
    //     this.courseSvc.CoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
    //     this.courseSvc.CoursesList = this.courseSvc.CoursesList.filter(ele=>ele.RepoId == this.AccountId);
       
    //     this.isCoursesLoading = false;
    //     this.setData(this.courseSvc.CoursesList);
    //   },
    //   error => {
    //     this.isCoursesLoading = false;
    //     this.coursesFinal = [];
    //     this.filteredCourses = [];
    //   }
    // );
  }

  getAllProjectCourses() {

    this.isCoursesLoading = true;
    this.isCoursesLoading = false;
    
    this.courseSvc.CoursesList = this.courseSvc.ProjectCoursesList.filter(ele=>ele.RepoId == this.ProjectId);
    this.setData(this.courseSvc.CoursesList);

    // this.courseSvc.getAllProjectCourses().subscribe(
    //   data => {
    //     data = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
    //     this.courseSvc.CoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
    //     this.isCoursesLoading = false;
    
    //     this.courseSvc.CoursesList = this.courseSvc.CoursesList.filter(ele=>ele.RepoId == this.ProjectId);
    //     this.setData(this.courseSvc.CoursesList);
    //   },
    //   error => {
    //     this.isCoursesLoading = false;
    //     this.coursesFinal = [];
    //     this.filteredCourses = [];
    //   }
    // );
  }

  removeAccountProjectItem(course) {
    let itemInList = [];
    let courseList = [];

    courseList = this.selectedCourses;
    itemInList = courseList.filter(x => x.UniqueId === course.UniqueId);

    if (itemInList || itemInList.length !== 0) {
      const pos = courseList
        .map(function (e) {
          return e.UniqueId;
        })
        .indexOf(course.UniqueId);
      courseList.splice(pos, 1);
    }

    this.filteredCourses.filter(x => x.UniqueId === course.UniqueId)[0].checked = false;

    this.selectedCourses = courseList.map(x => x);
  }

  removeItem(course) {

    if(this.Account || this.Project) {
      this.removeAccountProjectItem(course);
      return;
    }

    let itemInList = [];
    let courseList = [];

    courseList = this.selectedCourses;
    itemInList = courseList.filter(x => x.Id === course.Id);

    if (itemInList || itemInList.length !== 0) {
      const pos = courseList
        .map(function (e) {
          return e.Id;
        })
        .indexOf(course.Id);
      courseList.splice(pos, 1);
    }

    this.filteredCourses.filter(x => x.Id === course.Id)[0].checked = false;

    this.selectedCourses = courseList.map(x => x);
  }

  filterCourses() {
    this.filteredCourses = this.filterName(this.searchKey);
  }

  filterName(course) {
    let courses = [];
    if (this.searchKey != '') {
      courses = this.coursesFinal.filter(x => {
        const name = x.Name.toLowerCase();
        if (name.indexOf(course.toLowerCase()) !== -1) return true;
      });
      if (courses.length > 0) {
        return courses;
      }
      return [];
    }
    else {
      let courses = [];
      courses = this.coursesFinal;
      return courses;
    }
  }

  /* Watch method for selecting Account or Project Courses differ because they have to be
  tracked using UniqueId and not Id */

  watchChangeAccountProject(event, course) {
    let itemInList = [];
    let courseList = [];

    courseList =
      this.selectedCourses && this.selectedCourses.length > 0
        ? this.selectedCourses
        : [];

    if (courseList.length > 0) {
      itemInList = courseList.filter(x => x.UniqueId === course.UniqueId)[0];
    }

    if (event.target.checked) {
      if (itemInList === undefined || itemInList.length === 0) {
        courseList.push({
          // Id: course.Id,
          Name: course.Name,
          Expertise: course.Expertise,
          UniqueId: course.UniqueId
        });
      }
    } else if (!event.target.checked) {
      if (itemInList.length !== 0) {
        const pos = courseList
          .map(function (e) {
            return e.UniqueId;
          })
          .indexOf(course.UniqueId);
        courseList.splice(pos, 1);
      }
    }

    this.selectedCourses = courseList.map(x => x);
  }


  /* watchChange will redirect depending on Account / Project or Yorbit Course becaues of change in 
  JSON Object Structure */
  watchChange(event, course) {

    if (this.Account || this.Project) {
      this.watchChangeAccountProject(event, course);
      return;
    }

    let itemInList = [];
    let courseList = [];

    courseList =
      this.selectedCourses && this.selectedCourses.length > 0
        ? this.selectedCourses
        : [];

    if (courseList.length > 0) {
      itemInList = courseList.filter(x => x.Id === course.Id)[0];
    }

    if (event.target.checked) {
      if (itemInList === undefined || itemInList.length === 0) {
        courseList.push({
          Id: course.Id,
          Name: course.Name,
          Expertise: course.Expertise,
          UniqueId: course.UniqueId
        });
      }
    } else if (!event.target.checked) {
      if (itemInList.length !== 0) {
        const pos = courseList
          .map(function (e) {
            return e.Id;
          })
          .indexOf(course.Id);
        courseList.splice(pos, 1);
      }
    }

    this.selectedCourses = courseList.map(x => x);
  }

  getYorbitCourses(expertise) {
    // return new Promise((resolve, reject) => {
   
    this.isCoursesLoading = true;
    //if (this.courseSvc.yorbitCoursesList.length <= 0) {
    if (expertise == '101') {
      this.isCoursesLoading = false;
      this.courseSvc.CoursesList = this.courseSvc.YorbitCoursesList.filter(x=>x.Expertise == '101');
      this.setData(this.courseSvc.CoursesList);
      // this.courseSvc.getAllYorbit101Courses().subscribe(
      //   data => {
      //     //   resolve(data);
      //     data = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
      //     this.courseSvc.CoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
      //     this.isCoursesLoading = false;
      //     this.setData(data);
      //   },
      //   error => {
      //     //  reject("Error");
      //     this.isCoursesLoading = false;
      //     this.coursesFinal = [];
      //     this.filteredCourses = [];
      //   }
      // );
    }
    else{
      this.isCoursesLoading = false;
      this.courseSvc.CoursesList = this.courseSvc.YorbitCoursesList.map(x=>x);
      this.setData(this.courseSvc.CoursesList);
    }
    // else {
    //   this.courseSvc.getAllYorbitCourses().subscribe(
    //     data => {
    //       data = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
    //       //   resolve(data);
    //       this.courseSvc.CoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
    //       this.isCoursesLoading = false;
    //       this.setData(data);
    //     },
    //     error => {
    //       //  reject("Error");
    //       this.isCoursesLoading = false;
    //       this.coursesFinal = [];
    //       this.filteredCourses = [];
    //     }
    //   );
    // }

  }

  emitEvent(event) {
    switch (event) {
      case this.Events.OK:
        this.select.emit({
          canSubmit: true,
          courses: this.selectedCourses.length > 0 ? this.selectedCourses : []
        });
        break;
      case this.Events.CANCEL:
        this.select.emit({
          canSubmit: false,
          courses: this.courses
        });
        break;
    }
  }

  updateCourseSelection() {
    this.selectedCourses.forEach((course, key) => {
      let row = this.filteredCourses.filter(x => x.Id === course.Id)[0];
      if (row) {
        row.checked = true;
      }
    });
  }

  /* updateCourse selection for Account or Project Courses will be different because of 
  difference in JSON Object structure*/
  updateAccountProjectCourseSelection() {
    this.selectedCourses.forEach((course, key) => {
      let row = this.filteredCourses.filter(x => x.UniqueId === course.UniqueId)[0];
      if (row) {
        row.checked = true;
      }
    });
  }

  

  setData(data) {
    this.coursesFinal = data && data.length > 0 ? data : [];
    this.filteredCourses = data.map(x => x);

    this.filteredCourses.forEach(element => {
      element.checked = false;
    });

    if(this.Account || this.Project) {
      this.updateAccountProjectCourseSelection();
    }
    else {
      this.updateCourseSelection();
    }

  }
}
