import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { effectsBrowse } from './';
import * as fromBrowseReducer from './reducers/browse.reducer';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('browse',fromBrowseReducer.reducer),
    EffectsModule.forFeature(effectsBrowse)
  ],
  declarations: []
})
export class BrowseStoreModule { }
