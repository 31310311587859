import { Component, OnInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromBrowseStore from '../store';
import { BrowseService } from '../browse.service';
import { Subscriber } from 'rxjs';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { NewAndTrendingService } from '../../shared/services/new-and-trending.service';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
@Component({
  selector: 'yorbit-academy-view',
  templateUrl: './academy-view.component.html',
  styleUrls: ['./academy-view.component.scss']
})
export class AcademyViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isAccountBrowse: boolean;
  @Input() viewMode: string;
  @Input() exploreMenuTrigger: any;
  @Input() selectedAccount: any;
  showSkillList: boolean;
  academyViewCompSubscriptions: any;
  routeParams: any;
  academyList: any;
  selectedAcademy: any;
  filterByProperty = new ArrayPropertyFilterPipe();
  newCoursesList: any;
  roleList: fromRoleAccessStore.IroleData;
  showPagePreloader: boolean;
  contentLoadedSuccessfully: boolean;
  showPreloaderInNewCoursesList: boolean;
  newCoursesLoadedSuccessfully: boolean;
  selectedGenreIndex: any;
  config: Ienvironment;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private browseStore: Store<fromBrowseStore.IbrowseAcademyReducerState>,
    private browseService: BrowseService,
    private _newAndTrendingService: NewAndTrendingService,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private _envSvc: EnvironmentService
  ) {
    this.showSkillList = true;
    this.selectedAcademy = {};
    this.selectedAcademy.GenreTerms = [];
    this.academyList = [];
    this.academyViewCompSubscriptions = {};
    this.newCoursesList = [];
    this.showPagePreloader = true;
    this.contentLoadedSuccessfully = false;
    this.showPreloaderInNewCoursesList = false;
    this.newCoursesLoadedSuccessfully = false;
    this.config = this._envSvc.getEnvironment();
  }
  subscribeToRouterParams() {
    this.academyViewCompSubscriptions.routerParamsSub = this.activatedRoute.params.subscribe(
      params => {
        this.routeParams = params;
        const academyId = parseInt(params['academyId']);
        if (academyId != undefined || academyId != null) {
          //filter academyList with academyId
          this.selectedAcademy = this.filterByProperty.transform(
            this.academyList,
            { property: 'UniqueId', flag: academyId }
          )[0];
          this.selectedAcademy.showGenres = true;
          this.browseService.updateSelectedAcademy(this.selectedAcademy);
        }
      }
    );
  }
  subscribeToBrowseStore() {
    this.academyViewCompSubscriptions.browseStoreSub = this.browseStore
      .select(fromBrowseStore.getBrowseAcadmeyList)
      .subscribe(academies => {
        //if loaded

        if (academies && academies.AcademyTerms) {
          //this.academyList = [...academies.AcademyTerms];
          this.academyList = JSON.parse(JSON.stringify(academies.AcademyTerms));

          this.academyList.forEach((academy, academyKey) => {
            academy.GenreTerms.forEach((genre, key) => {
              //genre.Name = genre.Name.toLowerCase();
              if (genre.Name != '' && genre.Name != null) {
                genre.SkillTerms.forEach((skill, index) => {
                  //genre.Name = genre.Name.toLowerCase();
                  if (skill.Name == '' || skill.Name == null) {
                    //genre.SkillTerms.splice(index, 1);
                    this.academyList[academyKey].GenreTerms[key].SkillTerms.splice(index, 1);
                  }
                });
                if (genre.SkillTerms.length > 10) {
                  genre.SkillTerms.maxItems = 10;
                }
              } else {
                academy.GenreTerms.splice(key, 1);
              }
            });
          });
          if (this.viewMode == undefined || this.viewMode == null) {
            this.subscribeToRouterParams();
          } else {
            if (this.router.url.indexOf('genre') == -1) {
              this.selectedAcademy = this.academyList[0];
              this.selectedAcademy.showGenres = true;
            }
          }
          this.showPagePreloader = false;
          this.contentLoadedSuccessfully = true;
        }
      });
  }
  onAcademySelection(academy) {
    if (!this.isAccountBrowse) {
      //change academyid ini url

      if (this.viewMode == undefined || this.viewMode == null || this.router.url.indexOf('genre') == 1) {
        if (academy.UniqueId == this.selectedAcademy.UniqueId) {
          this.selectedAcademy.showGenres = !this.selectedAcademy.showGenres;
        } else {
          this.selectedAcademy.showGenres = true;
        }
        this.router.navigate(['academy/' + academy.UniqueId], {
          relativeTo: this.activatedRoute.parent
        });
      } else {
        this.selectedAcademy = academy;
      }
    } else {
      this.selectedAcademy = academy;
    }
  }
  onGenreSelection(genre) {
    if (!this.isAccountBrowse) {
      if (this.viewMode == undefined || this.viewMode == null) {
        this.router.navigate(['genre/' + genre.UniqueId], {
          relativeTo: this.activatedRoute
        });
      } else if (this.viewMode && this.viewMode == 'popover') {
        this.exploreMenuTrigger.closeMenu();
        this.router.navigate([
          '/browse/academy/' +
          this.selectedAcademy.UniqueId +
          '/genre/' +
          genre.UniqueId
        ]);
      }
    } else {
      this.router.navigate([
        '/browse/accountsAndProjects/' + genre.AccountId + '/' + genre.ProjectId
      ]);
    }
  }
  onSkillSelection(genre, skill) {
    this.browseService.updateSelectedSkill([skill.Name]);
    this.onGenreSelection(genre);
  }
  validateRouterUrl() {
    if (
      (this.viewMode && this.viewMode == 'popover') ||
      this.router.url.indexOf('account') == -1 ||
      this.router.url.indexOf('genre') != -1
    ) {
      this.showSkillList = false;
      this.browseStore.dispatch(new fromBrowseStore.BrowseGetAcademyList());
      this.getNewCoursesList();
      this.subscribeToBrowseStore();
    } else if (this.router.url.indexOf('account') != -1) {
      this.isAccountBrowse = true;
      this.academyList = [];
      this.selectedAcademy = {};
      this.selectedAcademy.GenreTerms = [];
      this.browseService.getAccountsListFromAPI();
      this.loadAccountsList();
    }
  }
  ngOnInit() {
    this.validateRouterUrl();
    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe(roleList => {
        this.roleList = roleList;
      });
  }
  loadAccountsList() {
    this.browseService.getAccountsList().subscribe(accountList => {
      if (accountList.length > 0) {
        this.academyList = accountList;
        if (this.selectedAccount == undefined) {
          this.selectedAcademy = accountList[0];
        } else {
          this.selectedAcademy = this.filterByProperty.transform(accountList, {
            property: 'UniqueId',
            flag: this.selectedAccount.AccountId
          })[0];
          //scroll to the element
          //document.getElementsByClassName('academies-list')[0].scrollTop=0;
          // let ele: any = document.getElementsByClassName(
          //   this.selectedAcademy.Name
          // )[0];
          // if (ele) {
          //   ele.scrollIntoView(true);
          // }
        }
        this.showPagePreloader = false;
        this.contentLoadedSuccessfully = true;
      }
    });
  }
  ngOnChanges(changeObj) {
    if (changeObj.selectedAccount != undefined) {
      this.loadAccountsList();
    }
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.academyViewCompSubscriptions) {
      let subscriber = this.academyViewCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  goToBrowseView() {
    this.exploreMenuTrigger.closeMenu();
    this.router.navigate(['/browse']);
  }
  getNewCoursesList() {
    this.showPreloaderInNewCoursesList = true;
    this.newCoursesLoadedSuccessfully = false;
    this._newAndTrendingService
      .getNewAndTrendingList()
      .subscribe((response: any) => {
        this.newCoursesList = this.filterByProperty.transform(
          response.newPackages.packages,
          { property: 'ItemType', flag: 'Course' }
        );
        this.showPreloaderInNewCoursesList = false;
        this.newCoursesLoadedSuccessfully = true;
      })
    // .catch(err => {
    //   this.showPreloaderInNewCoursesList = false;
    //   this.newCoursesLoadedSuccessfully = false;
    // });
  }
  setGenreHoverIndex(index) {
    this.selectedGenreIndex = index - 1;
  }
  goToDetailsPage(content) {
    //////console.log(content);
    this.exploreMenuTrigger.closeMenu();
    let webLink, URL;
    if (
      content.AccountPackage === 'true' ||
      content.ProjectPackage === 'true' ||
      content.AccountPackage === true ||
      content.ProjectPackage === true
    ) {
      //URL = this.config.apiUrlAPYammer;
      // this.config.apiUrlYammer.split('/detailsPage')[0] + '/PdetailsPage/';
      URL = 'PdetailsPage/';
    } else {
      //URL = this.config.apiUrlYorbitYammer;
      // URL = this.config.apiUrlYammer.split('/detailsPage')[0] + '/detailsPage';
      URL = 'detailsPage/';
    }
    if (content.ItemType === 'FamilyPackage' || content.ItemType === 'Course') {
      //for yorbit content
      if (
        (content.AccountPackage == null ||
          content.AccountPackage === 'false' ||
          content.AccountPackage === false) &&
        (content.ProjectPackage == null ||
          content.ProjectPackage === 'false' ||
          content.ProjectPackage === false)
      ) {
        webLink =
          URL + content.ItemType + '/' + content.Expertise + '/' + content.Id;
      }
      //for account and project content
      else {
        if (content.AccountId === undefined) {
          content.AccountId = content.Account;
        }
        if (content.ProjectId === undefined) {
          content.ProjectId = content.Project;
        }
        if (
          content.AccountPackage === true ||
          content.AccountPackage === 'true'
        ) {
          content.ProjectId = 0;
          content.AccountPackage = true;
          content.ProjectPackage = false;
        } else {
          content.AccountPackage = false;
          content.ProjectPackage = true;
        }
        // content.UniqueId = content.Id;
        webLink =
          URL +
          content.UniqueId +
          '/' +
          content.AccountPackage +
          '/' +
          content.ProjectPackage +
          '/' +
          content.AccountId +
          '/' +
          content.ProjectId +
          '/' +
          content.ItemType +
          '/' +
          content.Expertise +
          '/' +
          content.Id;
      }
    }
    // if (window.location.pathname.search('search') === 1) {
    //   window.open(webLink, '_blank');
    // } else {
    //   window.open(webLink);
    // }
    this.router.navigate([webLink]);
  }
}
