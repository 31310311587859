import { Component, OnInit, Inject } from '@angular/core';
import { TopLearnersService } from '../../../shared/services/top-learners.service';
import { GraphDataService, getName } from '@YorbitWorkspace/graph';
import { WINDOW } from '../../../shared/services/window.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../globals';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { AppInsightService } from '@YorbitWorkspace/app-insight';

@Component({
  selector: 'yorbit-top-learners',
  templateUrl: './top-learners.component.html',
  styleUrls: ['./top-learners.component.scss']
})
export class TopLearnersComponent implements OnInit {
  topLearnersYear = '';
  topLearnersQuarter = '';
  isTopLearnersLoading = true;
  topLearnersList = [];
  sliderActiveIndex = 0;
  setIntervalSubscription: any = null;
  bullets = [];
  mediaBreakPoint = '';
  numberOfTopLearners = 1;
  constructor(
    private _topLearnersService: TopLearnersService,
    private _graphSvc: GraphDataService,
    private globals: Globals,
    @Inject(WINDOW) private _window: any,
    private sanitizer: DomSanitizer,
    private mediaObserver: MediaObserver,
    private _insightService: AppInsightService
  ) {
    this.subscribeToMediaObserver();
    this.getTopLearners();
  }
  subscribeToMediaObserver() {
    this.mediaObserver.media$.subscribe((media: MediaChange) => {
      this.mediaBreakPoint = media.mqAlias;
      if (this.mediaBreakPoint == 'xs') {
        this.numberOfTopLearners = 1;
      } else if (this.mediaBreakPoint == 'sm') {
        this.numberOfTopLearners = 2;
      } else if (this.mediaBreakPoint == 'md') {
        this.numberOfTopLearners = 3;
      } else if (this.mediaBreakPoint == 'lg') {
        this.numberOfTopLearners = 4;
      } else {
        this.numberOfTopLearners = 4;
      }
      if(this.topLearnersList.length > 0 && this.sliderActiveIndex!=0){
        this.bullets = new Array(
          Math.ceil(this.topLearnersList.length / this.sliderActiveIndex)
        );
      }

    });
  }
  ngOnInit() {}
  getTopLearners() {
    this.isTopLearnersLoading = true;
    this._topLearnersService.getTopLearners().then(
      (data: any) => {
        this.isTopLearnersLoading = false;
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            this.topLearnersYear == ''
              ? (this.topLearnersYear = data[i].Year)
              : '';
            this.topLearnersQuarter == ''
              ? (this.topLearnersQuarter = data[i].Quarter)
              : '';
            data[i].Image = null;
            if (
              data[i].Designation != null &&
              data[i].Designation.length != 0
            ) {
              data[i].Designation = data[i].Designation;
            } else {
              data[i].Designation = null;
            }
            if (data[i].Name != null && data[i].Name.length != 0) {
              data[i].Name = data[i].Name;
            } else {
              data[i].Name = null;
            }
            if (!Array.isArray(data[i].Courses301)) {
              data[i].Courses301 = parseInt(data[i].Courses301);
            }
            if (!Array.isArray(data[i].Courses201)) {
              data[i].Courses201 = parseInt(data[i].Courses201);
            }
            if (!Array.isArray(data[i].Courses101)) {
              data[i].Courses101 = parseInt(data[i].Courses101);
            }
          }
          this.globals.topLearnersData = data;
          this.topLearnersList = this.globals.topLearnersData;
          this.resetShowMore();
          this.getTopLearnerInfo();
        }
        this.setIntervalForSlider(5000); //5 secs
        this.bullets = new Array(
          Math.ceil(this.topLearnersList.length / this.numberOfTopLearners)
        );
        return this.topLearnersList;
      },
      error => {
        this.isTopLearnersLoading = false;
        this.topLearnersList = [];
        return this.topLearnersList;
      }
    );
  }

  getTopLearnerInfo() {
    for (let i = 0; i < this.topLearnersList.length; i++) {
      if (this.globals.topLearnersData[i].Image == null) {
        this._graphSvc
          .getUserImage(this.globals.topLearnersData[i].MID)
          .subscribe(
            data => {
              this.globals.topLearnersData[i].Image = this.createImageURL(data);
            },
            error => {
              this.globals.topLearnersData[i].Image = null;
            }
          );
      }
      if (this.globals.topLearnersData[i].Name == null) {
        this._graphSvc
          .getUserName(this.globals.topLearnersData[i].MID)
          .subscribe(
            data => {
              this.globals.topLearnersData[i].Name = data.displayName;
            },
            error => {
              this.globals.topLearnersData[i].Name = 'NA';
            }
          );
      }
      if (this.globals.topLearnersData[i].Designation == null) {
        this._graphSvc
          .getUserJobTitle(this.globals.topLearnersData[i].MID)
          .subscribe(
            data => {
              this.globals.topLearnersData[i].Designation = data.jobTitle;
            },
            error => {
              this.globals.topLearnersData[i].Designation = 'NA';
            }
          );
      }
    }
  }

  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }
  showNextSlide() {
    this.trackEvent('next');

    if (
      this.sliderActiveIndex ==
      Math.ceil(this.topLearnersList.length / this.numberOfTopLearners) - 1
    ) {
      this.sliderActiveIndex = 0;
    } else {
      this.sliderActiveIndex++;
    }
    if (this.setIntervalSubscription != null) {
      clearInterval(this.setIntervalSubscription);
      this.setIntervalSubscription = null;
    } else {
      this.setIntervalForSlider(5000);
    }
  }
  pauseSlider($event) {
    this.trackEvent('hover');
    if (this.setIntervalSubscription != null) {
      clearInterval(this.setIntervalSubscription);
      this.setIntervalSubscription = null;
    }
  }
  showPreviousSlide() {
    this.trackEvent('previous');

    if (this.sliderActiveIndex == 0) {
      this.sliderActiveIndex =
        Math.ceil(this.topLearnersList.length / this.numberOfTopLearners) - 1;
    } else {
      this.sliderActiveIndex--;
    }
    if (this.setIntervalSubscription != null) {
      clearInterval(this.setIntervalSubscription);
      this.setIntervalSubscription = null;
      this.setIntervalForSlider(5000);
    }
  }
  setIntervalForSlider(duration) {
    if (this.setIntervalSubscription != null) {
      clearInterval(this.setIntervalSubscription);
      this.setIntervalSubscription = null;
    }
    this.setIntervalSubscription = setInterval(() => {
      this.resetShowMore();
      if (
        this.sliderActiveIndex ==
        Math.ceil(this.topLearnersList.length / this.numberOfTopLearners) - 1
      ) {
        this.sliderActiveIndex = 0;
      } else {
        this.sliderActiveIndex++;
      }
    }, duration);
  }
  resetShowMore() {
    for (let index = 0; index < this.topLearnersList.length; index++) {
      this.topLearnersList[index].ShowMore = false;
    }
  }
  trackEvent(event) {
    let payload = {
      action: '',
      location: 'Home Page',
      trackEvent: 'Top Learners Quarter Wise'
    };
    if (event == 'hover') {
      payload.action = 'Hover';
    } else if (event == 'next') {
      payload.action = 'Next Top Learners';
    } else if (event == 'previous') {
      payload.action = 'Previous Top Learners';
    } else if (event == 'showmore') {
      payload.action = 'Show More';
      payload.location = 'Home Page Mobile';
    } else if (event == 'showless') {
      payload.action = 'Show Less';
      payload.location = 'Home Page Mobile';
    }
    this.trackNewFeaturesInsights(payload);
  }
  trackNewFeaturesInsights(payload) {
    const insightPayload = {
      Action: payload.action,
      Location: payload.location
    };
    this._insightService.trackEvent(payload.trackEvent, insightPayload);
  }
}
