<div class="unitsparentDiv">
  <div class="fixedHeading">
    <div class="courseNameTile">
      <div class="courseExpertise">101</div>
      <div>{{course.Name}}</div>
    </div>
    <div class="headingDiv">
      <div class="heading">Create Course Topics/Units</div>
      <div class="info-icon" (click)="openInformation()">i</div>
      <div class="buttonsDiv" *ngIf="!isSubmittedOrApproved">
        <button class="form-action-btn azureVideoButton" type="button" *ngIf="isYorbitCourse"
          (click)="uploadAzureVideos()">UPLOAD AZURE
          VIDEOS</button>
        <button class="form-action-btn" type="button" (click)="addNewUnit()">ADD NEW UNIT</button>
      </div>
    </div>
  </div>
  <div [formGroup]="unitsTopicForm">
    <div formArrayName='unitsAndTopics'
      *ngFor="let units of unitsTopicForm.controls.unitsAndTopics['controls']; let i = index;">
      <div class="unitDiv" fxFlex>
        <div [formGroupName]='i' fxLayout="column">
          <div class="unitHeadingDiv">
            <div>{{i+1}}. Unit Name
              <input type="text" class="unitTextBox" (change)="onTextChange()" formControlName="unitName">
              <div class="iconsDiv" *ngIf="unitFormDummy.unitsAndTopics.length > 1 && !isSubmittedOrApproved">
                <span class="spriteIcons upIcon" *ngIf="i>0" (click)="moveUp(i)"></span>
                <span class="spriteIcons downIcon" *ngIf="i!=unitFormDummy.unitsAndTopics.length-1"
                  (click)="moveDown(i)"></span>
                <span class="spriteIcons deleteIcon" *ngIf="unitFormDummy.unitsAndTopics.length > 1"
                  (click)="removeUnits(i)"></span>
              </div>
            </div>
            <div class="errorMessage unitNameError"
              *ngIf="(units.controls.unitName.dirty || submitted) && units.controls.unitName.errors?.required">
              *Unit Name cannot be blank
            </div>
          </div>
          <div class="unitBox" fxLayout="row" cdkDropList cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dropOption($event, i)">
            <div formArrayName='topics' class='topicsBox'
              *ngFor="let optionss of unitsTopicForm.controls.unitsAndTopics.controls[i].controls.topics.controls; let j = index;"
              cdkDrag>
              <div class="topicsDiv" (mouseover)="mouseEnter(i,j)" (mouseout)="mouseLeave(i,j)" [formGroupName]='j'>
                <div class="sourceTypeDiv" [ngClass]="(isYorbitCourse) ? 'borderYorbitCourse' : 'noBorderYorbitCourse'">
                  <!-- <input id="videoType_{{i}}_{{j}}" type="radio" *ngIf="isYorbitCourse"
                  (change)="videoTypeChange('youtube', i, j)" class="sourceTypeRadio" value="youtube"
                    formControlName="videoType">
                  <label for="videoType_{{i}}_{{j}}" *ngIf="isYorbitCourse" class="sourceTypeLabel">Youtube</label>
                  <input id="videoType_{{i}}_{{j}}" type="radio" *ngIf="isYorbitCourse"
                    (change)="videoTypeChange('azure', i, j)" class="sourceTypeRadio" value="azure"
                    formControlName="videoType">
                  <label for="videoType_{{i}}_{{j}}" *ngIf="isYorbitCourse" class="sourceTypeLabel">Azure</label>
                  <input id="videoType_{{i}}_{{j}}" type="radio" *ngIf="isYorbitCourse"
                    (change)="videoTypeChange('scorm', i, j)" class="sourceTypeRadio" value="scorm"
                    formControlName="videoType">
                  <label for="videoType_{{i}}_{{j}}" *ngIf="isYorbitCourse">Scorm</label>
                  <span class="spriteIcons closeIcon"
                  *ngIf="unitFormDummy.unitsAndTopics[i].topics.length > 1 && !isSubmittedOrApproved"
                  (click)="removeTopics(i,j)"></span> -->
                  <select class="selectVideoType" *ngIf="isYorbitCourse" (change)="videoTypeChange(i, j)"
                    formControlName="videoType">
                    <!-- <option *ngIf="isYorbitCourse" [selected]="optionss.controls.videoType.value === 'youtube'"
                      value="youtube">
                      Youtube
                    </option>
                    <option *ngIf="isYorbitCourse" [selected]="optionss.controls.videoType.value === 'azure'"
                      value="azure">
                      Azure
                    </option>
                    <option *ngIf="isYorbitCourse" [selected]="optionss.controls.videoType.value === 'scorm'"
                      value="scorm">
                      Scorm
                    </option> -->
                    <option *ngFor="let type of videoTypes" [selected]="optionss.controls.videoType.value === type"
                      [value]="type">
                      {{type.charAt(0).toUpperCase() + type.slice(1)}}
                    </option>
                  </select>
                  <span class="spriteIcons closeIcon" [ngClass]="{'closeIconPos': !isYorbitCourse}"
                    *ngIf="unitFormDummy.unitsAndTopics[i].topics.length > 1 && !isSubmittedOrApproved"
                    (click)="removeTopics(i,j)"></span>
                </div>
                <div class="detailsDiv">
                  <span class="detailsLabel">Topic Name</span>
                  <span class="errorMessage"
                    *ngIf="(optionss.controls.topicName.dirty || submitted) && optionss.controls.topicName.errors?.required">*</span>
                  <input type="text" class="detailsTextBox" (change)="onTextChange()" formControlName="topicName">
                  <!-- <span class="detailsLabel" *ngIf="optionss.controls.videoType.value !== 'scorm'">Source Link</span> -->
                  <span class="detailsLabel">Source Link</span>
                  <span class="errorMessage"
                    *ngIf="((optionss.controls.sourceLink.dirty || submitted) && optionss.controls.sourceLink.errors?.required)">*</span>
                  <input type="text" class="detailsTextBox" formControlName="sourceLink"
                    (change)="sourceLinkChange(i,j)"
                    [ngClass]="(!unitFormDummy.unitsAndTopics[i].topics[j]?.isSourceLinkValid && !unitFormDummy.unitsAndTopics[i].topics[j]?.isScormFileValid) ? 'inValidTextBox' : 'detailsTextBox'">
                  <!-- <div *ngIf="unitsTopicForm.value.unitsAndTopics[i]?.topics[j]?.videoType == 'azure'">
                    <div class="assetDiv">
                      <span class="detailsLabel">Asset ID</span>
                      <span class="errorMessage"
                        *ngIf="(optionss.controls.assetId.dirty || submitted) && optionss.controls.assetId.errors?.required">*</span>
                      <input type="text" class="smallTextBox" formControlName="assetId">
                    </div>
                    <div>
                      <span class="detailsLabel">Content Key</span>
                      <span class="errorMessage"
                        *ngIf="(optionss.controls.contentKey.dirty || submitted) && optionss.controls.contentKey.errors?.required">*</span>
                      <input type="text" class="smallTextBox" formControlName="contentKey">
                    </div>
                  </div> -->
                  <div *ngIf="optionss.controls.videoType.value === 'scorm'">
                    <!-- <span class="detailsLabel">Scorm File <span class="errorMessage">*</span></span> -->
                    <!-- <div class="scormFileUploadLabel"
                      [ngClass]="(!unitFormDummy.unitsAndTopics[i].topics[j]?.isSourceLinkValid && !unitFormDummy.unitsAndTopics[i].topics[j]?.isScormFileValid) ? 'inValidScormFile' : 'scormFileUploadLabel'">
                      <input type="file" id="scormFileUpload" [disabled]="isSubmittedOrApproved"
                        formControlName="scormFileName" #file (change)="onFilesAdded(file.files, i, j)" />
                      <div class="fileNameLabel"
                        [title]="optionss.controls.scormFileName.value !== 'Select a file' ? optionss.controls.scormFileName.value : ''"
                        [ngClass]="{'disableLabel': isSubmittedOrApproved}">
                        {{optionss.controls.scormFileName.value}}</div>
                      <button mat-raised-button class="browseFileButton" (click)="file.click()">Browse</button>
                    </div> -->
                    <!-- 
                    <button class="scormFileUploadBtn"
                      [ngClass]="{'disableFileUpload': optionss.controls.scormFile.value === ''}"
                      [disabled]="optionss.controls.scormFile.value === ''"
                      (click)="uploadScormFile(i, j)">Upload Scorm File</button> -->

                    <button class="scormFileUploadBtn" [ngClass]="{'disableFileUpload': isSubmittedOrApproved}"
                      [disabled]="isSubmittedOrApproved" (click)="uploadScormPopup(i, j)">Upload Scorm File</button>
                  </div>

                  <span class="detailsLabel">Duration</span>
                  <span class="errorMessage" *ngIf="(optionss.controls.durationHour.dirty || optionss.controls.durationMins.dirty
                     || optionss.controls.durationSeconds.dirty || submitted) && 
                     (optionss.controls.durationHour.errors?.required || optionss.controls.durationMins.errors?.required
                     || optionss.controls.durationSeconds.errors?.required)">*</span>
                  <div class="durationTextBox" fxLayout="row" fxLayoutAlign="start center">
                    <input class="durationHour" type="text" name="durationHour" matTooltip="Please enter a valid input"
                      [matTooltipDisabled]="!(optionss.controls.durationHour.errors?.errorMessage === '*Invalid Input'
                      && !optionss.controls.durationHour.valid)" formControlName="durationHour">
                    <input class="durationMins" type="text" name="durationMins"
                      matTooltip="Please enter a valid input between 0 and 59" [matTooltipDisabled]="!(optionss.controls.durationMins.errors?.isInvalid 
                      && !optionss.controls.durationMins.valid)" formControlName="durationMins">
                    <input class="durationSeconds" type="text" name="durationSeconds"
                      matTooltip="Please enter a valid input between 0 and 59" [matTooltipDisabled]="!(optionss.controls.durationSeconds.errors?.isInvalid
                      && !optionss.controls.durationSeconds.valid)" formControlName="durationSeconds">
                  </div>
                  <div>
                    <span class="hourSpan">hr</span>
                    <span class="minSpan">min</span>
                    <span class="secSpan">sec</span>
                  </div>
                  <!-- <div *ngIf="optionss.controls.videoType.value === 'scorm'" class="scormFileUploadMsg">
                    <span class="errorMessage">*</span>
                    <div class="msg">
                      Browse and select the SCORM zip file, then click upload to save the file.
                    </div>
                  </div> -->
                </div>
                <div *ngIf="!isSubmittedOrApproved" class="spriteIcons swapIcon"
                  title="Click and drag to re-order the Topics in the Unit" [hidden]="!(unitFormDummy.unitsAndTopics[i].topics.length > 1 && 
                unitFormDummy.unitsAndTopics[i].topics[j].onHover)" cdkDragHandle></div>
              </div>
            </div>
          </div>
          <div class="errorMessage topicsError" *ngIf="!unitsTopicForm.value.unitsAndTopics[i].isValid">
            *Mandatory fields cannot be empty
          </div>
          <div class="errorMessage topicsError"
            *ngIf="!unitsTopicForm.value.unitsAndTopics[i].durationValid && unitsTopicForm.value.unitsAndTopics[i].isValid">
            *One or more duration field is invalid in Unit "<b>{{unitsTopicForm.value.unitsAndTopics[i].unitName}}</b>"
          </div>
          <div class="addTopicDiv" *ngIf="!isSubmittedOrApproved">
            <span class="spriteIcons addIcon" (click)="addTopicsToUnits(i)"></span>
            <span class="addOption">ADD TOPIC</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="proceedButtonDiv">
  <div class="exitForm" (click)="onExit()">EXIT FORM</div>
  <div class="buttonsDiv">
    <input type="submit" class="proceedButton" (click)="onBackPressed()" value="BACK" />
    <input type="submit" class="proceedButton" *ngIf="isYorbitCourse"
      [ngClass]="{disabledButton: submitDisabled || isSubmittedOrApproved}"
      [disabled]="submitDisabled || isSubmittedOrApproved" (click)="validateSourceLink()" value="{{validateButton}}" />
    <input type="submit" class="proceedButton" [ngClass]="{disabledButton: !saveDisabled || isSubmittedOrApproved}"
      [disabled]="!saveDisabled || isSubmittedOrApproved" (click)="onSave()" value="{{saveButton}}" />
    <input type="submit" class="proceedButton" [ngClass]="{disabledButton: !submitted && !isSubmittedOrApproved}"
      [disabled]="!submitted && !isSubmittedOrApproved" (click)="onSubmit()" value="NEXT" />
  </div>
</div>