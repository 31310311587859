<div class="article-container" fxLayout="column" [ngClass.sm]="['sm']" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']"
  (click)="gotoArticle()">
  <div class="article-cover" fxFlex="1 1 50%"
    [style.background-image]="articleDetail.ThumNailImageURl?'url(' + articleDetail.ThumNailImageURl + ')' :'url(../../../../assets/images/DandI/article-img.jpg)'"
    fxLayout="row" fxLayoutAlign="center center" (click)="playVideo()">

  </div>
  <div class="article-description" fxFlex="1 1 50%" fxLayout="column wrap" fxLayoutGap.gt-md="5px" fxLayoutGap.md="7px"
    fxLayoutGap.xs="3px">
    <div class="title" [ngClass.xs]="['xs']" title="{{articleDetail.Title}}">{{articleDetail.Title}}</div>
    <div class="description" [ngClass.xs]="['xs']" [ngClass.md]="['md']">{{articleDetail.Description}}</div>

    <div fxLayout="row" fxLayoutAlign="space-between" style="margin-top: 5px;">
      <span class="video" (click)="playVideo()">
        Watch Now
      </span>
      <span class="speaker" (click)="openResource()" *ngIf="articleDetail.Resources!=''">Resources</span>
    </div>



  </div>
</div>