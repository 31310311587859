<mat-dialog-content>
    <div class="closePopupBtn" (click)="close()">
        <!-- <mat-icon class="floatRight close-icon" (click)="close()">close</mat-icon> -->
        <button class="mat-button mat-icon-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="popup-title">
        <div fxLayout="row">
            <div class="representation">Report An Issue</div>
        </div>
    </div>
    <div class="report-issue-container">
        <div class="content-holder" fxLayout="column">
            <div class="selectCategory">
                <div class="categoryLabel">
                    Select a category
                    <span class="error">*</span>
                </div>
                <div>
                    <select class="categoryDropDown" (change)="selectChange()" [(ngModel)]="selectedCategory">
                        <option value="" disabled selected>Select a category</option>
                        <option *ngFor="let issue of issues" [value]="issue.value">
                            {{issue.value}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="selectCategory">
                <div class="categoryLabel">
                    Write a comment
                    <span class="error">*</span>
                </div>
                <div>
                    <textarea class="textArea" (keyup)="commentsChange()" [(ngModel)]="comments"></textarea>
                </div>
            </div>
            <div fxLayoutAlign="center center" class="close-btn-holder">
                <button class="action-button" [ngClass]="{disabled: (!isCategorySelected || !isCommentsFilled)}"
                    [disabled]="!isCategorySelected || !isCommentsFilled" (click)="submit()">SUBMIT</button>
            </div>
        </div>
    </div>
</mat-dialog-content>