import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Inject
} from '@angular/core';
import { WINDOW } from '../../../services/window.service';
import { GraphDataService } from '@YorbitWorkspace/graph';

@Component({
  selector: 'yorbit-share-user-profile',
  templateUrl: './share-user-profile.component.html',
  styleUrls: ['./share-user-profile.component.scss']
})
export class ShareUserProfileComponent implements OnInit {
  @Input() user: any;
  @ViewChild('userImage', { static: true }) userImage: ElementRef;

  constructor(
    private graphDataService: GraphDataService,
    @Inject(WINDOW) private _window: any
  ) { }
  ngOnInit() {
    this.getUserImageFromGraph();
  }
  getUserImageFromGraph() {
    this.graphDataService
      .getUserImage(this.user.userPrincipalName.split("@")[0])
      .subscribe(res => {
        this.userImage.nativeElement.src = this._window.URL.createObjectURL(
          res
        );
      });
  }
}
