import { NgModule } from '@angular/core';
import { Routes, RouterModule, LoadChildren } from '@angular/router';
import { AuthGuard } from '@YorbitWorkspace/auth';
import { BrowseComponent } from './browse/browse.component';
import { reusableUiRoutes } from '@YorbitWorkspace/reusable-ui';
import { CourseRequestComponent } from './course-request/course-request.component';
import { MyPageComponent } from './my-page/my-page.component';
import { LearningHistoryComponent } from './my-page/learning-history/learning-history.component';
import { LearningComplianceComponent } from './my-page/learning-compliance/learning-compliance.component';
import { LearningPathComponent } from './learning-path/learning-path.component';

import { PowerbiComponent } from './powerbi/powerbi.component';
import { CanActivateRouteChange } from './app-router-guard';
import { HomeComponent } from './home/home.component';
import { UnauthorizedComponent } from './shared/guard-fail-page/unauthorized/unauthorized.component';
import { IdpComponent } from './idp/idp.component';
import { SiteDownComponent } from './shared/guard-fail-page/site-down/site-down.component';
import { QuizComponent } from './quiz/quiz/quiz.component';
import { QuizLandingComponent } from './quiz/quiz-landing/quiz-landing.component';
import { HMMAndSparkComponent } from './hmm-and-spark/hmm-and-spark.component';
import { QuizGuardDeactivate } from './quiz/quiz.service';
import { CookiesDisabledComponent } from './shared/guard-fail-page/cookies-disabled/cookies-disabled.component';
import { FutureSkillsComponent } from './future-skills/future-skills.component';
import { ContentUploadGuardDeactivate } from './ao/content-upload/content-upload.service';
import { MsalGuard } from '@azure/msal-angular';
import { DiversityAndInclusionComponent } from './diversity-and-inclusion/diversity-and-inclusion.component';
import { RoleBasedLearningComponent } from './role-based-learning/role-based-learning.component';
import { BehavioralAssessmentAssignmentModule } from './behavioral-assessment-assignment/behavioral-assessment-assignment.module';
import { BitmovinnewcomponentComponent } from './bitmovinnewcomponent/bitmovinnewcomponent.component';
import { title } from 'process';
import { OurAiStrategyComponent } from './our-ai-strategy/our-ai-strategy.component';

//@dynamic
const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard, CanActivateRouteChange],
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Home' }
      },
      {
        path: 'browse',
        loadChildren: () =>
          import('./browse/browse.module').then(m => m.BrowseModule),
        // component: AccountComponent,
        data: { title: 'Browse' }
      },

      {
        path: 'browse/HMM-Spark',
        component: HMMAndSparkComponent,
        data: { title: 'HMMAndSparkComponent' }
      },
      // {
      //   path: 'futureskills',
      //   component: FutureSkillsComponent,
      //   data: { title: 'FutureSkillsComponent' }
      // },
      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then(m => m.AccountModule),
        // component: AccountComponent,
        data: { title: 'ACCOUNTS' }
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search/search.module').then(m => m.SearchModule),
        //component: SearchComponent,
        data: { title: 'Search' }
      },
      ...reusableUiRoutes,
      // {
      //   path: 'course-request',
      //   component: CourseRequestComponent,
      //   data: { title: 'Course Request' }
      // },
      {
        path: 'my-page/:id/:role',
        component: MyPageComponent,
        data: { title: 'My Page' }
      },
      {
        path: 'learningpath',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/learning-path/learning-path.module').then(
            m => m.LearningPathModule
          ),
        data: { title: 'Learning Path' }
      },
      {
        path: 'c2ops',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/c2ops/c2ops.module').then(
            m => m.C2opsModule
          ),
        data: { title: 'C2OPS' }
      },
      {
        path: 'detailsPage',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/details-page/details-page.module').then(
            m => m.DetailsPageModule
          ),
        data: { title: 'Details Page' }
      },
      {
        path: 'PdetailsPage',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/pdetails-page/pdetails-page.module').then(
            m => m.PdetailsPageModule
          ),
        data: { title: 'Project Details Page' }
      },
      // {
      //   path: 'quiz/',
      //   component: QuizComponent,
      //   data: { title: 'Quiz' }
      // },
      // {
      //   path: 'quiz/:courseid/:lpid/:category',
      //   component: QuizComponent,
      //   data: { title: 'Quiz' },
      //   canDeactivate: [QuizGuardDeactivate]

      // },
      {
        path: 'quiz/:courseid/:lpid/:category/:packageid',
        component: QuizLandingComponent,
        data: { title: 'Quiz' },
        canDeactivate: [QuizGuardDeactivate]
      },
      // {
      //   path: 'powerbireports',
      //   component: PowerbiComponent,
      //   data: { title: 'PowerBi Reports' }
      // },
      {
        path: 'pss',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/pss/pss.module').then(
            m => m.PssModule
          ),
        data: { title: 'PSS' }
      },
      {
        path: 'certificateUpload',
        loadChildren: () => import('apps/yorbit-ui/src/app/certificate-upload/certificate-upload.module').then(m => m.CertificateUploadModule),
        data: { title: 'Certificate Upload' }
      },
      {
        path: 'iCertificationUpload',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/i-certificate-upload/i-certificate-upload.module').then(
            m => m.ICertificateUploadModule
          ),
        data: { title: 'Internal Certificate Page' }
      },
      {
        path: 'eCertificationUpload',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/e-certificate-upload/e-certificate-upload.module').then(
            m => m.ECertificateUploadModule
          ),
        data: { title: 'External Certificate Page' }
      },
      {
        path: 'content-partner',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/ao/ao.module').then(m => m.AoModule),
        data: { title: 'content-partner' },
        canDeactivate: [ContentUploadGuardDeactivate]
      },
      {
        path: 'sme',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/sme/sme.module').then(m => m.SmeModule),
        data: { title: 'SME' }
      },
      {
        path: 'lbp',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/cdm/cdm.module').then(
            m => m.CdmModule
          ),
        data: { title: 'LBP' }
      },
      {
        path: 'rm',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/cdm/cdm.module').then(
            m => m.CdmModule
          ),
        data: { title: 'RM' }
      },
      {
        path: 'classroom',
        loadChildren: () =>
          import('apps/yorbit-ui/src/app/classroom/classroom.module').then(
            m => m.ClassroomModule
          ),
        data: { title: 'C2OPS' }
      },
      {
        path: 'IDP/:guid',
        component: IdpComponent,
        data: { title: 'IDP' }
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        data: { title: 'Unauthorized' }
      },
      // {
      //   path: 'bitmovin',
      //   component: BitmovinnewcomponentComponent,
      //   data: { title: 'bitmovin' }
      // },
      // {
      //   path: 'downtime',
      //   component: SiteDownComponent,
      //   data: { title: 'Site is Down' }
      // }
      {
        path: 'downtime',
        redirectTo: '',
        pathMatch: 'full'
      },
      {
        path: 'learning-recommendations',
        loadChildren: () =>
          import('./recommendations/recommendations.module').then(
            m => m.RecommendationsModule
          ),
        // component: AccountComponent,
        data: { title: 'Browse' }
      },
      {
        path: 'diversity-and-inclusion',
        loadChildren: () =>
          import('./diversity-and-inclusion/diversity-and-inclusion.module').then(
            m => m.DiversityAndInclusionModule
          ),
        data: { title: 'Diversity And Inclusion' }
        // component: DiversityAndInclusionComponent
      }, 
      {
        path: 'our-ai-strategy',
        loadChildren: () =>
          import('./our-ai-strategy/our-ai-strategy.module').then(
            m => m.OurAiStrategyModule
          ),
        data: { title: 'Our AI Strategy.module' }
      },
      {
        path: 'aceing-leadership',
        loadChildren: () =>
          import('./aceing-leadership/aceing-leadership.module').then(
            m => m.AceingLeadershipModule
          ),
        data: { title: 'AceingLeadership' }
        // component: DiversityAndInclusionComponent
      },
      {
        path: 'environment-and-sustainability',
        loadChildren: () =>
          import('./environment-and-sustainability/environment-and-sustainability.module').then(
            m => m.EnvironmentAndSustainabilityModule
          ),
        data: { title: 'Environment And Sustainability' }
        // component: DiversityAndInclusionComponent
      }, {
        path: 'role-based-learning/:type',
        // loadChildren: () =>
        //   import('./role-based-learning/role-based-learning.module').then(
        //     m => m.RoleBasedLearningModule
        //   ),
        data: { title: 'Role Based Learning' },
        component: RoleBasedLearningComponent
      },
      {
        path: 'riskbrigade',
        loadChildren: () =>
          import('./risk-brigade/risk-brigade.module').then(
            m => m.RiskBrigadeModule
          ),
        data: { title: 'Risk Brigade' }
      },
      {
        path: 'behavioral-assessment-assignment',
        loadChildren: () => import('./behavioral-assessment-assignment/behavioral-assessment-assignment.module').then(m => m.BehavioralAssessmentAssignmentModule),
        data: { title: 'Behavioral Assessment Assignment' }
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: 'cookiesdisabled',
    component: CookiesDisabledComponent,
    data: { title: 'cookiesdisabled' }
  }
  // {
  //   path: 'footer',
  //   canActivateChild: [AuthGuard],
  //   children: reusableUiRoutes
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutesRoutingModule { }
