import * as fromMyPageActions from '../actions/my-page.action';
import {
  ICompliance,
  IBadgeList,
  IUserSkills,
  ICoreSkills,
  ISkill,
  ICrossSkillList,
  IBadge,
  IPrimarySkill,
  IFutureSkillPaths
} from '../my-page-details.interface';
//import { state } from '@angular/animations';
import {
  complainceInitialState,
  historyInitialState,
  skillProfileInitialState,
  coreSkillsInititalState,
  supportSkillsInitialState,
  crossSkillsInitialState,
  primarySkillInitialState,
  futureSkillsInitialState
} from '../my-page-details.model';

export interface IMyPageReducerState {
  primarySkill: {
    data: IPrimarySkill;
    loaded: boolean;
    loading: boolean;
  };
  compliance: {
    data: ICompliance;
    loaded: boolean;
    loading: boolean;
  };
  history: {
    data: IBadge[];
    loaded: boolean;
    loading: boolean;
  };
  skillProfile: {
    data: IUserSkills;
    loaded: boolean;
    loading: boolean;
  };
  coreSkills: {
    data: ICoreSkills;
    loaded: boolean;
    loading: boolean;
  };
  supportSkills: {
    data: ISkill[];
    loaded: boolean;
    loading: boolean;
  };
  crossSkills: {
    data: ICrossSkillList[];
    loaded: boolean;
    loading: boolean;
  };
  futureSkills:{
    data : any;
    loaded:boolean;
    loading:boolean;
  };
}

export const initialState = {
  primarySkill: {
    data: primarySkillInitialState,
    loaded: false,
    loading: true
  },
  compliance: {
    data: complainceInitialState,
    loaded: false,
    loading: true
  },
  history: {
    data: historyInitialState,
    loaded: false,
    loading: true
  },
  skillProfile: {
    data: skillProfileInitialState,
    loaded: false,
    loading: true
  },
  coreSkills: {
    data: coreSkillsInititalState,
    loaded: false,
    loading: true
  },
  supportSkills: {
    data: supportSkillsInitialState,
    loaded: false,
    loading: true
  },
  crossSkills: {
    data: crossSkillsInitialState,
    loaded: false,
    loading: true
  },
  futureSkills:{
    data : futureSkillsInitialState,
    loaded:false,
    loading:true
  }
};

export function myPageReducer(
  state = initialState,
  action: fromMyPageActions.MyPageActions
): IMyPageReducerState {
  switch (action.type) {
    case fromMyPageActions.GET_MY_PAGE_LEARNING_HISTORY: {
      return {
        ...state,
        history: initialState.history
      };
    }
    case fromMyPageActions.GET_MY_PAGE_LEARNING_HISTORY_SUCCESS: {
      return {
        ...state,
        history: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_LEARNING_HISTORY_FAIL: {
      return {
        ...state,
        history:{
          data: action.payload,
          loaded: false,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_COMPLIANCE: {
      return {
        ...state,
        compliance: initialState.compliance
      };
    }
    case fromMyPageActions.GET_MY_PAGE_COMPLIANCE_SUCCESS: {
      return {
        ...state,
        compliance: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_COMPLIANCE_FAIL: {
      return {
        ...state,
        compliance: {
          data: initialState.compliance.data,
          loaded: true,
          loading: false
        }
      };
    }

    case fromMyPageActions.GET_MY_PAGE_SKILL_PROFILE: {
      return {
        ...state,
        skillProfile: initialState.skillProfile
      };
    }
    case fromMyPageActions.GET_MY_PAGE_SKILL_PROFILE_SUCCESS: {
      return {
        ...state,
        skillProfile: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_SKILL_PROFILE_FAIL: {
      return {
        ...state
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CORE_SKILLS: {
      return {
        ...state,
        coreSkills: initialState.coreSkills
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CORE_SKILLS_SUCCESS: {
      return {
        ...state,
        coreSkills: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CORE_SKILLS_FAIL: {
      return {
        ...state
      };
    }
    case fromMyPageActions.GET_MY_PAGE_SUPPORT_SKILLS: {
      return {
        ...state,
        supportSkills: {
          data: supportSkillsInitialState,
          loaded: false,
          loading: true
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_SUPPORT_SKILLS_SUCCESS: {
      return {
        ...state,
        supportSkills: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_SUPPORT_SKILLS_FAIL: {
      return {
        ...state
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CROSS_SKILLS: {
      return {
        ...state,
        crossSkills: {
          data: crossSkillsInitialState,
          loaded: false,
          loading: true
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CROSS_SKILLS_SUCCESS: {
      return {
        ...state,
        crossSkills: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CROSS_SKILLS_FAIL: {
      return {
        ...state
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CROSS_SKILL_PATH: {
      return {
        ...state
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CROSS_SKILL_PATH_SUCCESS: {
      return {
        ...state,
        crossSkills: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_CROSS_SKILL_PATH_FAIL: {
      return {
        ...state
      };
    }
    case fromMyPageActions.GET_MY_PAGE_PRIMARY_SKILL: {
      return {
        ...state,
        primarySkill: {
          data: primarySkillInitialState,
          loaded: false,
          loading: true
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_PRIMARY_SKILL_SUCCESS: {
      return {
        ...state,
        primarySkill: {
          data: action.payload,
          loaded: true,
          loading: false
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_PRIMARY_SKILL_FAIL: {
      return {
        ...state
      };
    }

    case fromMyPageActions.GET_MY_PAGE_FUTURE_SKILLS:{
      return {
        ...state,
        futureSkills: {
          data: futureSkillsInitialState,
          loaded: false,
          loading: true
        }
      };
    }
    case fromMyPageActions.GET_MY_PAGE_FUTURE_SKILLS_SUCCESS:{
      return{
        ...state,
        futureSkills:{
          data:action.payload,
          loaded:true,
          loading:false
        }
      }
    }
    case fromMyPageActions.GET_MY_PAGE_FUTURE_SKILLS_FAIL:{
      return{
        ...state,
        futureSkills:{
          data:action.payload,
          loaded:false,
          loading:false
        }
      }
    }
    case fromMyPageActions.GET_MY_PAGE_FUTURE_SKILL_PATH:{
      return{
        ...state,
        futureSkills:{
          data:action.payload,
          loaded:false,
          loading:true
        }
      }
    }
    case fromMyPageActions.GET_MY_PAGE_FUTURE_SKILL_PATH_SUCCESS:{
      return{
        ...state,
        futureSkills:{
          data:action.payload,
          loaded:true,
          loading:false
        }
      }
    }
    case fromMyPageActions.GET_MY_PAGE_FUTURE_SKILL_PATH_FAIL:{
      return{
        ...state,
        futureSkills:{
          data:action.payload,
          loaded:false,
          loading:false
        }
      }
    }
  }
  return state;
}
export const getHistoryData = (state: IMyPageReducerState) => {
  return state.history.data;
};
export const getHistoryDataLoading = (state: IMyPageReducerState) => {
  return state.history.loading;
};
export const getHistoryDataLoaded = (state: IMyPageReducerState) => {
  return state.history.loaded;
};

export const getComplianceData = (state: IMyPageReducerState) => {
  return state.compliance.data;
};
export const getComplianceDataLoading = (state: IMyPageReducerState) => {
  return state.compliance.loading;
};
export const getComplianceDataLoaded = (state: IMyPageReducerState) => {
  return state.compliance.loaded;
};

export const getSkillProfileData = (state: IMyPageReducerState) => {
  return state.skillProfile.data;
};
export const getSkillProfileDataLoading = (state: IMyPageReducerState) => {
  return state.skillProfile.loading;
};
export const getSkillProfileDataLoaded = (state: IMyPageReducerState) => {
  return state.skillProfile.loaded;
};

export const getCoreSkillsData = (state: IMyPageReducerState) => {
  return state.coreSkills.data;
};
export const getCoreSkillsDataLoading = (state: IMyPageReducerState) => {
  return state.coreSkills.loading;
};
export const getCoreSkillsDataLoaded = (state: IMyPageReducerState) => {
  return state.coreSkills.loaded;
};

export const getSupportSkillsData = (state: IMyPageReducerState) => {
  return state.supportSkills.data;
};
export const getSupportSkillsDataLoading = (state: IMyPageReducerState) => {
  return state.supportSkills.loading;
};
export const getSupportSkillsDataLoaded = (state: IMyPageReducerState) => {
  return state.supportSkills.loaded;
};

export const getCrossSkillsData = (state: IMyPageReducerState) => {
  return state.crossSkills.data;
};
export const getCrossSkillsDataLoading = (state: IMyPageReducerState) => {
  return state.crossSkills.loading;
};
export const getCrossSkillsDataLoaded = (state: IMyPageReducerState) => {
  return state.crossSkills.loaded;
};

export const getCrossSkillPathData = (state: IMyPageReducerState) => {
  return state.crossSkills.data;
};
export const getCrossSkillPathDataLoading = (state: IMyPageReducerState) => {
  return state.crossSkills.loading;
};
export const getCrossSkillPathDataLoaded = (state: IMyPageReducerState) => {
  return state.crossSkills.loaded;
};
export const getPrimarySkillData = (state : IMyPageReducerState)=>{
  return state.primarySkill.data;
}
export const getPrimarySkillDataLoading = (state : IMyPageReducerState)=>{
  return state.primarySkill.loading;
}
export const getPrimarySkillDataLoaded = (state : IMyPageReducerState)=>{
  return state.primarySkill.loaded;
}

export const getFutureSkillsData = (state: IMyPageReducerState) => {
  return state.futureSkills.data;
};
export const getFutureSkillsDataLoading = (state: IMyPageReducerState) => {
  return state.futureSkills.loading;
};
export const getFutureSkillsDataLoaded = (state: IMyPageReducerState) => {
  return state.futureSkills.loaded;
};

export const getFutureSkillPathData = (state: IMyPageReducerState) => {
  return state.futureSkills.data;
};
export const getFutureSkillPathDataLoading = (state: IMyPageReducerState) => {
  return state.futureSkills.loading;
};
export const getFutureSkillPathDataLoaded = (state: IMyPageReducerState) => {
  return state.futureSkills.loaded;
};
