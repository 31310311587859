import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignContentPopupComponent } from './assign-content-popup/assign-content-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssignContentComponent } from './assign-content.component';
import { AssignContentService } from './assign-content.service';
import { AssignConfirmationPopupComponent } from './assign-confirmation-popup/assign-confirmation-popup.component';
import { AssignResponsePopupComponent } from './assign-response-popup/assign-response-popup.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

@NgModule({
  imports: [CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatChipsModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatRadioModule,
    MatMenuModule,
    MatTooltipModule,

    FormsModule,
    ReusableUiModule,
    ReactiveFormsModule,
     FlexLayoutModule],
  declarations: [
    AssignContentComponent,
    AssignContentPopupComponent,
    AssignConfirmationPopupComponent,
    AssignResponsePopupComponent
  ],
  exports: [
    AssignContentComponent,
    AssignContentPopupComponent,
    AssignConfirmationPopupComponent,
    AssignResponsePopupComponent
  ],

  providers: [AssignContentService]
})
export class AssignContentModule {}
