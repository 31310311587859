import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector
} from '@ngrx/store';

import * as fromUserDetailsReducer from './user-details.reducer';
import { IuserDetails } from '../user-details.interface';

export interface IuserDetailsState {
  user: {
    data: IuserDetails;
  };
}
export const userDetailsReducerCollection = fromUserDetailsReducer.reducer;
export const userDetailsReducers: ActionReducerMap<IuserDetailsState> = {
  user: fromUserDetailsReducer.reducer
};
export const getUserDetailsState = createFeatureSelector<IuserDetailsState>('userDetails');

export const getuserDetailsReducerState = createSelector(
  getUserDetailsState,
  (state: IuserDetailsState) =>  state.user
);

export const getUserDetailObject = createSelector(
  getuserDetailsReducerState,
  fromUserDetailsReducer.getUserDetails
);

export const getUserDetailLoadedStatus = createSelector(
  getuserDetailsReducerState,
  fromUserDetailsReducer.getUserDetailsLoaded
);

export const getUserDetailLearningPath = createSelector(
  getuserDetailsReducerState,
  fromUserDetailsReducer.getLearningPath
);

export const getUserDetailLoaded = createSelector(
  getuserDetailsReducerState,
  fromUserDetailsReducer.getUserDetailsLoaded
);

export const getUserDetailLoading= createSelector(
  getuserDetailsReducerState,
  fromUserDetailsReducer.getUserDetailsLoading
);
