import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PssContentApprovalComponent } from '../pss-content-approval.component';
import { PssContentApprovalService } from '../pss-content-approval.service';

@Component({
  selector: 'yorbit-pss-content-update-popup',
  templateUrl: './pss-content-update-popup.component.html',
  styleUrls: ['./pss-content-update-popup.component.scss']
})
export class PssContentUpdatePopupComponent implements OnInit {
  validationList: { valid: string[]; invalid: string[]; };
  completed: { CreditSpecial:boolean;Infrastructure: boolean; CourseLogin: boolean; CourseCompletion: boolean; ClassRoom: boolean; ProjectWork: boolean; };
  saveActivated: boolean;
  updateSuccess: { CreditSpecial:boolean; Infrastructure: boolean; CourseLogin: boolean; CourseCompletion: boolean; ClassRoom: boolean; ProjectWork: boolean; };

  constructor(
    public dialogRef: MatDialogRef<PssContentApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pssContentApprovalService: PssContentApprovalService,
  ) { }

  ngOnInit() {
    this.saveActivated=false;
    this.completed={
      CreditSpecial:false,
      Infrastructure:false,
      CourseLogin:false,
      CourseCompletion:false,
      ClassRoom:false,
      ProjectWork:false
    }
    this.updateSuccess={
      CreditSpecial:false,
      Infrastructure:false,
      CourseLogin:false,
      CourseCompletion:false,
      ClassRoom:false,
      ProjectWork:false
    }
    //console.log(this.data);
    this.onFirstLoad();
  }
  onFirstLoad(){
    this.validationCheckList();
  }
  saveAll(){
    this.saveActivated=true;
    this.updateCredit(this.data.creditPayload);
    this.updateInfrastructure(this.data.infrastructurePayload,this.data.validationUpdate.Infrastructure);
    this.updateCourseLogin(this.data.courseLoginPayload,this.data.validationUpdate.CourseLogin);
    this.updateCourseCompletion(this.data.courseCompletionPayload,this.data.validationUpdate.CourseCompletion);
    this.updateClassRoomDetails(this.data.classRoomPayload,this.data.validationUpdate.ClassRoom);
    this.saveProjectWorkDetails(this.data.projectWorkPayload,this.data.validationUpdate.ProjectWork)
  }
  validationCheckList(){
    this.validationList={
      valid:[],
      invalid:[]
    }
    this.pushValidationList("Infrastructure",this.data.validationUpdate.Infrastructure);
    this.pushValidationList("Course Login",this.data.validationUpdate.CourseLogin);
    this.pushValidationList("Course Completion",this.data.validationUpdate.CourseCompletion);
    this.pushValidationList("ClassRoom",this.data.validationUpdate.ClassRoom);
    this.pushValidationList("Project Work",this.data.validationUpdate.ProjectWork);
  }
  pushValidationList(contentType,valid){
    if(valid){
      this.validationList.valid.push(contentType)
    }else{
      this.validationList.invalid.push(contentType)
    }
  }

  updateCredit(creditPayload) {
      this.pssContentApprovalService
      .updateCreditUpdate(creditPayload)
      .then(response => {
        this.completed.CreditSpecial=true;
        this.updateSuccess.CreditSpecial=true;
      })
      .catch(error => {
        this.completed.CreditSpecial=true;
      });
  }
  updateInfrastructure(infrastructurePayload,valid) {
    if(valid){
      this.pssContentApprovalService
      .updateCourseInfrastructure(infrastructurePayload)
      .then(response => {
        this.completed.Infrastructure=true;
        this.updateSuccess.Infrastructure=true;
      })
      .catch(error => {
        this.completed.Infrastructure=true;
      });
    }else{
      this.completed.Infrastructure=true;
    }
  }
  updateCourseLogin(courseLoginPayload,valid) {
    if(valid){
    this.pssContentApprovalService
      .updateCourseLoginDetails(courseLoginPayload)
      .then(response => {
        this.completed.CourseLogin=true;
        this.updateSuccess.CourseLogin=true;
      })
      .catch(error => {
        this.completed.CourseLogin=true;
      });
    }else{
      this.completed.CourseLogin=true;
    }
  }
  updateCourseCompletion(courseCompletionPayload,valid) {
    if(valid){
    this.pssContentApprovalService
      .updateCourseCompletionDetails(courseCompletionPayload)
      .then(response => {
        this.completed.CourseCompletion=true;
        this.updateSuccess.CourseCompletion=true;
      })
      .catch(error => {
        this.completed.CourseCompletion=true;
      });
    }else{
      this.completed.CourseCompletion=true;
    }
  }
  updateClassRoomDetails(classRoomPayload,valid) {
    if(valid){
    this.pssContentApprovalService
      .updateClassRoomDetails(classRoomPayload)
      .then(response => {
        this.completed.ClassRoom=true;
        this.updateSuccess.ClassRoom=true;
      })
      .catch(error => {
        this.completed.ClassRoom=true;
      });
    }else{
      this.completed.ClassRoom=true;
    }
  }
  saveProjectWorkDetails(projectWorkPayload,valid) {
    if(valid){
    this.pssContentApprovalService
      .updateProjectWorkDetails(projectWorkPayload)
      .then(response => {
        this.completed.ProjectWork=true;
        this.updateSuccess.ProjectWork=true;
      })
      .catch(error => {
        this.completed.ProjectWork=true;
      });
    }else{
      this.completed.ProjectWork=true;
    }
  }


  /**
   * Common functions
   */
  closeUpdatePopup() {
    this.dialogRef.close(this.updateSuccess);
  }
}
