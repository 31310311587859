import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentVerificationComponent } from './content-verification.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {ReusableUiModule} from '@YorbitWorkspace/reusable-ui';
import { RouterModule, Routes } from '@angular/router';
import { ContentUploadComponent } from '../ao/content-upload/content-upload.component';
import { ContentUploadHomePopupsComponent } from './content-upload-home-popups/content-upload-home-popups.component';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@YorbitWorkspace/pipes';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [ContentVerificationComponent, ContentUploadHomePopupsComponent],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ReusableUiModule,
    MatIconModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatTableModule,
    MatMenuModule,
    FormsModule,
    PipesModule,
    MatPaginatorModule
  ],
  exports: [
    ContentVerificationComponent
  ],
 
})
export class ContentVerificationModule { }
