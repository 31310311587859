<mat-dialog-content>
    <div class="closePopupBtn">
        <button class="mat-button mat-icon-button" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="popup-title" title="{{data.itemName}}">
        <div class="heading">{{data.itemName}}</div>
      </div>
      <div class="popup-content">
        {{data.data}}
      </div>
      <div class="action-btn-holder">
        <button class="action-button" (click)="closeDialog()">OK</button>
      </div>
</mat-dialog-content>
