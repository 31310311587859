import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchArray'
})
export class SearchArrayPipe implements PipeTransform {
  transform(values: any, args?: any): any {
    if (values == null || values.length == 0) {
      return [];
    } else {
      if (args.length == 0) {
        return values;
      } else {
        let filteredValues = values.filter(value => {
          if (value.toLowerCase().indexOf(args.toLowerCase()) != -1) {
            return true;
          } else {
            return false;
          }
        });
        return filteredValues;
      }
    }
  }
}
