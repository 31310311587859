import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video } from '../../details-page/mobile-course-details-page/mobile-course-details-page.component';
import { Globals } from '../../globals';
import { AccordionComponent } from '../../shared/accordion/accordion.component';
import { PlayerVarsO365 } from '../../shared/video-players/o365-video-player/o365-player.config';
import { PlayerVarsYoutube } from '../../shared/video-players/youtube-video-player/youtube-player.config';

@Component({
  selector: 'yorbit-risk-brigade-video-popup',
  templateUrl: './risk-brigade-video-popup.component.html',
  styleUrls: ['./risk-brigade-video-popup.component.scss']
})
export class RiskBrigadeVideoPopupComponent implements OnInit {
  videoConfig: Video;
  playerVars = null;
  constructor(
    public dialogRef: MatDialogRef<AccordionComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogVideoData: any,
    private _popup: MatDialog,
    private globals: Globals
  ) {}

  ngOnInit() {
    let videoId;
    if (this.dialogVideoData.VideoType.toLowerCase() === 'youtube') {
      this.playerVars = PlayerVarsYoutube;
      videoId = this.dialogVideoData.VideoLink.split('embed/')[1];
    } else if (this.dialogVideoData.VideoType.toLowerCase() === 'o365') {
      this.playerVars = PlayerVarsO365;
      videoId = this.dialogVideoData.VideoId;
    } 
    const data = {
      ContentId:videoId,
      UnitId: '',
      CourseId:videoId,
      ItemId: videoId,
      ItemType: 'Course',
      PathId: 0,
      VideoId: videoId,
      sourceUrl: this.dialogVideoData.VideoLink
    };
    this.videoConfig = {
      VideoDetails: data,
      VideoPlayerId: 'popup' + videoId,
      CanCaptureProgress: false,
      PlayerVars:   this.playerVars ,
      showInfoMsg: false
    };
    //console.log(this.videoConfig)
  }
  close() {
    this.dialogRef.close();
  }
}
