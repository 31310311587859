import { Component, OnInit } from '@angular/core';
import { PssService } from '../pss.service';
import { SystemService } from '../../shared/services/system.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DownloadBlobPopupComponent } from '../../shared/download-blob-popup/download-blob-popup.component';
import { ContentUploadService } from '../../ao/content-upload/content-upload.service';

@Component({
  selector: 'yorbit-course-procedure-upload',
  templateUrl: './course-procedure-upload.component.html',
  styleUrls: ['./course-procedure-upload.component.scss']
})
export class CourseProcedureUploadComponent implements OnInit {
  courseUniqueId: string;
  message: string;
  courseRefLink: any;
  fileUploadInvalid: any;
  errorMsgs: any;
  courseRefUploadFile: any;
  cuuserroles: any;
  downloadAvailable: boolean;
  fileToBeUploaded: any;
  errmsg: string;
  fileName: string;
  displayUpload: boolean;
  uploadStatus: string;
  constructor(
    private _pssService: PssService,
    private _contentUploadSvc: ContentUploadService,
    private _systemSvc: SystemService,
    private _popup: MatDialog,
    private _contentUploadService:ContentUploadService
    ) { 
      this.courseUniqueId='';
      this.resetFlags();
      this.getRoles();
    }


  ngOnInit(): void {
  }

  resetFlags(){
    this.downloadAvailable=false;
    this.message='';
    this.fileName="Select a File";
    this.displayUpload=false;
    this.uploadStatus='';
    this.errmsg=''
  }

  uniqueidUpdate(){
    this.resetFlags();

  }

  checkUniqueId(courseUniqueId) {
    //this.downloadAvailable=true;
    //this.displayUpload=true;
    this.fileName="Select a File";
    this.fileToBeUploaded=new FormData();

    this._pssService.CheckCourseUniqueId(courseUniqueId).subscribe((res) => {
      if(res != null) {
        if(res == "Course is not available"){
          this.message = 'Course Unique Id is not available';
          this.downloadAvailable=false;
          this.displayUpload=false;
        }else if (res=='CourseProcedureReferenceDocument already present'){
          this.message = 'Course Procedure Reference Document already present';
          this.downloadAvailable=true;
          this.displayUpload=true;
        }else if(res=="CourseProcedureReferenceDocument is not present"){
          this.message = 'Course Procedure Reference Document is not present';
          this.downloadAvailable=false;
          this.displayUpload=true;
        }else{
          this.message = res;
        }
      }
    });
  }
  downloadCourseRefLink(){
    // DI_926
    this._contentUploadSvc.getCourseProcedureReferenceDocument(this.courseUniqueId.trim())
    .toPromise()
    .then((refDocument: any) => {
      this.openDownloadFileWithSasKey(refDocument);
      this.courseRefUploadFile=refDocument;
    })
   
  }
  openDownloadFileWithSasKey(url) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
   
    dialogConfig.data = {
      url:url
    };

    const dialogRef = this._popup.open(
      DownloadBlobPopupComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(response => {
    });
  }

  uploadRefDoc(){
  this.message ="Uploading File";

    let file=new FormData();
    file.append('file', this.fileToBeUploaded.get('file'), this.fileName); 
this._contentUploadService.uploadToBlob(file.get('file'), this.courseUniqueId,'','','CourseProcedureReferenceDocument','',null).then( (result:any) => {
  // this.uploadStatus = result;
  if(result.Status){
    this.message ="File uploaded successfully";
  }else{
    this.message ="File upload Failed";
  }
  //console.log(this.cuuserroles)
}).catch((err)=>{
  this.message ="File upload Failed";
})
}
  
  getRoles()
  {
    this._contentUploadService.getContentUploadUserRoles().toPromise().then(response => {
      this.cuuserroles = response 
      //console.log(this.cuuserroles)
    })
  }

  onFilesAdded(files, event){
    this.errmsg='';
    this.fileToBeUploaded=new FormData();
    const target: DataTransfer = <DataTransfer>(event.target);
    const extension = files[0].name.split('.').pop().toLowerCase();

    if(extension == 'msg' || extension == 'eml'){

    if (files.length === 0){
      this.fileName="Select a File";
      this.fileToBeUploaded=new FormData();
      return;
    }
    
    const uploadedFileSize = files[0].size / (1024 * 1024);
  // let extension = files[0].name.split('.').pop().toLowerCase();
  // if (extension == 'zip' || extension == "7z") {
  // }
    const formData = new FormData();
    if (uploadedFileSize < 10) {
      for (const file of files) {
        formData.append('file', file, file.name);
        this.fileName = file.name;
        
      }let reader = new FileReader(); 
      reader.onload = (e: any) => {
        var bstr = e.target.result;  
      };
    
      reader.readAsBinaryString(target.files[0]); 
     
      this.fileToBeUploaded.append('file', formData.get('file'), this.fileName); 
    }else{
      this.errmsg='Please choose file less than 10MB'
    }
  }else{
    this.errmsg='Please upload file in msg or eml extension'
    this.fileName="Select a File";
    this.fileToBeUploaded=new FormData();
  }
  
  }



}
