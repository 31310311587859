import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from '@YorbitWorkspace/auth';
import { UserDetailsModule } from '../shared/user-details/user-details.module';
import { BellNotificationModule } from '../header/bell-notifications/bell-notifications.module';
import { MyPageModule } from '../my-page/my-page.module';
import { RoleAccessModule } from '@YorbitWorkspace/role-access';
import { DeclareStoreModule } from '../shared/declare-store/declare-store.module';
import { CloudlabStoreModule } from '../shared/cloudlab-store/cloudlab-store.module';
import { RolebasedStoreModule } from '../shared/content-tiles/rolebased-store/rolebased-store.module';

@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    UserDetailsModule,
    BellNotificationModule,
    MyPageModule,
    RoleAccessModule,
    DeclareStoreModule,
    CloudlabStoreModule,
    RolebasedStoreModule
  ],
  declarations: [],
  providers: []
})
export class RootStoreModule {}
