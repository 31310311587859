import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-popup-messages',
  templateUrl: './popup-messages.component.html',
  styleUrls: ['./popup-messages.component.scss']
})
export class PopupMessagesComponent implements OnInit {

  message: string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<PopupMessagesComponent>) { 
    this.message = data;
  }

  ngOnInit() {    
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
