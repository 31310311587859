import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'yorbit-cookies-disabled',
  templateUrl: './cookies-disabled.component.html',
  styleUrls: ['./cookies-disabled.component.scss']
})
export class CookiesDisabledComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private _route: Router) { }

  ngOnInit() {
    if(navigator.cookieEnabled){
      this._route.navigate(['/']);
    }
  }
  refreshPage() {
    window.location.reload();
  }
}
