<!--
<p class="content-view" (click)="openLinkinNewTab()">Click here to open document</p>


    <embed type="text/html" src="{{playlist?(playlist[this.contentIndex]?playlist[this.contentIndex].sourceUrl:''):''}}">
        
        <iframe src="{{playlist?(playlist[this.contentIndex]?playlist[this.contentIndex].sourceUrl:''):''}}"
        
        ></iframe>
    -->



<!-- <p *ngIf="downloadable" class="content-view" (click)="openLinkinNewTab()">Click here to download document</p> -->

<div *ngIf="downloadable && !isExternalLink" class="content-box">
    <a href="{{this.playlist[this.contentIndex].sourceUrl}}" download="{{documentName}}" class="content-box-text" (click)="openLinkinNewTab()">Click here to download document</a>
</div>
<div *ngIf="!downloadable && isExternalLink" class="content-box">
    <a href="{{this.playlist[this.contentIndex].sourceUrl}}" target="_blank" class="content-box-text" (click)="openLinkinNewTab()">Click here to open link</a>
</div>
<div id="multicontentPlayerHolder">
    <div id="multicontentPlayer" *ngIf="!downloadable && !isExternalLink" class="max"></div>
</div>
