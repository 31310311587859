import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphDataService {
  graphUrl: string;
  baseUrl: string;
  capacityReportDetails: any;
  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    //this.graphUrl = this._envSvc.getEnvironment().graphUrl;
    this.graphUrl = 'https://graph.microsoft.com/v1.0/users';
    this.baseUrl = this._envSvc.getEnvironment().apiUrl;
  }

  getUserName(MId) {
   
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });

    //.subscribe(
    //   data => {
    //     const Url =
    //     this.graphUrl + '/' +data[0]+'?$select=displayName';
    //      console.log(Url) 
    //   //this.graphUrl + '/' + MId + '@mindtree.com/displayName?api-version=1.6';
    //   return this._http
    //     .get<any>(Url, { headers: headers })
    //     .pipe(catchError((error: any) => throwError(error)));
    //   },
    //   error => {
    //     console.log(error)
    //   }
    // );

    if (this.isAnMid(MId)) {
      const Url =  this.graphUrl + '/' + MId + '@ltimindtree.com?$select=displayName';
      //const Url =  this.graphUrl + '/61069860@ltimindtree.com?$select=displayName';

      //this.graphUrl + '/' + MId + '@mindtree.com/displayName?api-version=1.6';
      return this._http
        .get<any>(Url, { headers: headers })
        .pipe(catchError((error: any) => throwError(error)));
    } else {
      return this.getDetailsFromCapacityReport(MId);
    }
  }

  getUserFirstName(MId) {
   
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    if (this.isAnMid(MId)) {
      const Url = this.graphUrl + '/' + MId + '@ltimindtree.com?$select=givenName';
      // const Url =
      //   this.graphUrl + '/' + MId + '@mindtree.com/givenName?api-version=1.6';
      if (MId != null && MId !== '') {
        return this._http
          .get<any>(Url, { headers: headers })
          .pipe(catchError((error: any) => throwError(error)));
      }
    } else {
      //console.log(MId)
      return this.getDetailsFromCapacityReport(MId);
    }
  }

  getUserJobTitle(MId) {
   
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });

    if (this.isAnMid(MId)) {
      const Url = this.graphUrl + '/' + MId + '@ltimindtree.com?$select=jobTitle';
      // const Url =
      //   this.graphUrl + '/' + MId + '@mindtree.com/jobTitle?api-version=1.6';

      return this._http
        .get<any>(Url, { headers: headers })
        .pipe(catchError((error: any) => throwError(error)));
    } else {
      return this.getDetailsFromCapacityReport(MId);
    }
  }

  getUserMailId(MId) {
   
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    if (this.isAnMid(MId)) {
      const Url = this.graphUrl + '/' + MId + '@ltimindtree.com?$select=mail';
      // const Url =
      //   this.graphUrl + '/' + MId + '@mindtree.com/mail?api-version=1.6';
      return this._http
        .get<any>(Url, { headers: headers })
        .pipe(catchError((error: any) => throwError(error)));
    } else {
      return this.getDetailsFromCapacityReport(MId);
    }
  }

  getUserImage(MId): Observable<Blob> {
   
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    

    // if (this.isAnMid(MId)) {
    const Url = this.graphUrl + '/' + MId + '@ltimindtree.com/photo/$value';
    // const Url =
    //   this.graphUrl +
    //   '/' +
    //   MId +
    //   '@mindtree.com/thumbnailPhoto?api-version=1.6';
    return this._http.get(Url, { responseType: 'blob' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
    // }
  }

  getExpertImage(MId): Observable<Blob> {
   
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    if (this.isAnMid(MId)) {
      const Url = this.graphUrl + '/' + MId + '@ltimindtree.com/photo/$value';
      //  const Url = this.graphUrl + '/' + MId + '/thumbnailPhoto?api-version=1.6';
      return this._http
        .get(Url, { responseType: 'blob' })
        .pipe(catchError((error: any) => throwError(error)));
    }
  }

  getUsersListByName(name) {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    const filter =
      'startswith(displayName,' +
      "'" +
      name +
      "'" +
      ')or endsWith(userPrincipalName,' +
      "'" +
      name +
      "%40ltimindtree.com'" +
      ')&$top=4&ConsistencyLevel=eventual&$count=true';

    const Url = this.graphUrl + '?$filter=' + filter;
    //const Url = this.graphUrl + '?api-version=1.6&$filter=' + filter;
    return this._http.get<any>(Url, { headers: headers }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getUsersListByMailId(mailId) {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    const filter =
    'startswith(displayName,' +
    "'" +
    mailId +
    "'" +
    ')or endsWith(userPrincipalName,' +
    "'" +
    mailId +
    "%40ltimindtree.com'" +
    ')&$top=4&ConsistencyLevel=eventual&$count=true';
    const Url = this.graphUrl + '?$filter=' + filter;
    //const Url = this.graphUrl + '?api-version=1.6&$filter=' + filter;
    return this._http
      .get<any>(Url, { headers: headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getUsersListByMail(mailId) {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    const filter = 'startswith(mail,' + "'" + mailId + "'" + ')&$top=1';
    const Url = this.graphUrl + '?$filter=' + filter;
    //    const Url = this.graphUrl + '?api-version=1.6&$filter=' + filter;
    return this._http
      .get<any>(Url, { headers: headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getLearnerDepartment(MId) {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    if (this.isAnMid(MId)) {
      const Url =
        this.graphUrl + '/' + MId + '@ltimindtree.com?$select=department';
      // const Url =
      //   this.graphUrl + '/' + MId + '@mindtree.com/department?api-version=1.6';
      return this._http
        .get<any>(Url, { headers: headers })
        .pipe(catchError((error: any) => throwError(error)));
    } else {
      return this.getDetailsFromCapacityReport(MId);
    }
  }

  batchRequestsForDepartmentAndName(payload) {
    const batchURL1 = 'https://graph.microsoft.com/v1.0/$batch';

    return this._http.post<any>(batchURL1, payload).toPromise();
  }

  batchRequests(input) {
    const batchURL1 = 'https://graph.microsoft.com/v1.0/$batch';
    const batchURL =
      'https://graph.windows.net/mindtree.com/$batch?api-version=1.6';

    let graphRequests = [];
    if (input && input.length > 0) {
      input.forEach((req, index) => {
        graphRequests.push({
          id: index + 1,
          method: 'GET',
          url:
            this.graphUrl +
            '/' +
            req.UserId +
            '@ltimindtree.com/thumbnailPhoto?api-version=1.6'
        });
      });

      let payload = {
        requests: graphRequests
      };
      this._http
        .post<any>(batchURL, payload)
        .toPromise()
        .then(success => {
          //console.log('Batch Request', success);
        });
    }
  }

  getDetailsFromCapacityReport(userId)
   {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    // let id = window.btoa(userId);
    const Url = this.baseUrl + 'User/GetUserDataFromCapacityReport';
    return this._http
      .post<any>(Url,{"Id":userId}, { headers: headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDetailsFromCapacityReportBatchCall(ids) {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    const Url = this.baseUrl + 'User/GetUserDataFromCapacityReportBatch';
    return this._http
      .post<any>(Url, ids, { headers: headers })
      .pipe(catchError((error: any) => throwError(error)));
  }


  getUserEmailWithPSId(psId) {
    let headers = new HttpHeaders({
      Authorization:
        'Bearer ' +
        localStorage.getItem(
          'adal.access.token.keyd5324e3c-3c47-4053-b30c-08d5ca192eb9'
        )
    });
    const Url = this.baseUrl + 'User/GetEmailFromPSID';
    var array:any[]=[]
    array.push(psId)
    return this._http
      .post<any>(Url, array, { headers: headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  isAnMid(id) {
    const regEx = new RegExp(/^(([Mm]{1}[0-9]{7}\s{0})+)$/, 'g');
    const isValid = regEx.test(id);
    return isValid;
  }

  isAnExtId(id) {
    const regEx = new RegExp(/^([0-9]{6,})$/, 'g');
    const isExtId = regEx.test(id);
    return isExtId;
  }
}
