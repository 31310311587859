import { Component, OnInit } from '@angular/core';
import { RoleBasedLearningStoreService } from '../shared/role-based-learning-store/role-based-learning-store.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { RblCoursePopupComponent } from './rbl-course-popup/rbl-course-popup.component';
import { Store } from '@ngrx/store';
import * as fromRBLStore from '../shared/role-based-learning-store/store';
import { Observable, Subject } from 'rxjs';
import { I } from '@angular/cdk/keycodes';
import { SystemService } from '../shared/services/system.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'yorbit-role-based-learning',
  templateUrl: './role-based-learning.component.html',
  styleUrls: ['./role-based-learning.component.scss']
})
export class RoleBasedLearningComponent implements OnInit {
  taxonomy: any = [];
  competencies: Array<string> = [];
  roles: Array<any> = [];
  families: Array<string> = [];
  levels: Array<any> = [];
  splGroups: any = [];
  mindsDataMapping: any;
  isFamilySelected: boolean = true;
  isRoleSelected: boolean = false;
  isLevelSelected: boolean = false;
  selectedFamilyIndex: number = 0;
  selectedRoleIndex: number = 0;
  selectedSplGrpId: string = "";
  selectedLevel:any = '';
  mindsFamilyIndex: number = 2;
  mindsRoleIndex: number = 1;
  mindsSplGrpIndex: number = 100;
  selectedFamilyName: string = '';
  selectedRoleName: string = '';
  selectedLevelCompetency: Array<string> = [];
  selectedSplGroupData : any = [];
  rblData$: Observable<any>;
  empRoleTagging: any;
  selectedSpl: any;
  preloadLoading: boolean;

  constructor(
    private rblStoreService: RoleBasedLearningStoreService,
    private _dialog: MatDialog,
    private rblStore : Store<fromRBLStore.IRoleBasedLearningState>,
    private _systemSvc :SystemService,
    private _activateRouter:ActivatedRoute
  ) {
    this.empRoleTagging=null;
  }

  ngOnInit(): void {
    this.competencies = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8'];
    this.preloadLoading=false;
    //this.getMindsRBLMapping();
    this.selectedSpl="";
    // this.rblStore.dispatch(new fromRBLStore.RBLGetFamilyRoleLevel());
    // this.rblData$ = this.rblStore.select(fromRBLStore.getRoleBasedLearningStatus);
    // this.rblData$.subscribe(data => {
    //   console.log(data);
    // })

    this.rblStoreService.getFamilyRoleLevelTaxonomy().subscribe(
      taxonomy => {
        this.families = [];
        this.taxonomy = taxonomy;
        //console.log(this.taxonomy);
        this.taxonomy.forEach(element => {
          element.Roles.forEach(roleData => {
            roleData.Levels.forEach(levelData => {
              levelData.Competency = levelData.LevelSalaryGrade.split('.')[1];
            });
          });
          this.families.push(element.FamilyName);
        });
        this.rblStoreService.getEmployeeRoleTagging().subscribe(
          empRoleTagging => {
            this.empRoleTagging=empRoleTagging;
            if(empRoleTagging){
              for (let index = 0; index < this.families.length; index++) {
                if(this.families[index]==this.empRoleTagging.Family){
                  this.familySelected(index, this.families[index]);
                }
              };
              //this.familySelected(familyIndex, family)

            }else{
              //To select the first role and levels by default on page load.
              this.setRoles(this.taxonomy[0].FamilyName);
              this.setLevels(this.taxonomy[0].Roles[0]);
            }
            /**
             * FRLS_UniqueId: "TESTING_02"
    Family: "Testing"
    Level: "Senior SDET "
    LevelSalaryGrade: "SDET.C4"
    MID: "M1032565"
    Role: "SDET"
    Specialization: "Automation"
    SpecializationId: 971
    */
          },
          error => {}
        );
      },
      error => {
        //console.log(error);
      }
    );
  }
  familySelected(familyIndex, family) {
    this.selectedFamilyIndex = familyIndex;
    this.selectedFamilyName = family.FamilyName;

    this.isRoleSelected = true;
    this.isLevelSelected = false;
    this.selectedLevel = "";
    this.selectedRoleIndex = 0;

    this.setRoles(family);
  }
  roleSelected(roleIndex, role) {
    this.isRoleSelected = true;
    this.selectedRoleIndex = roleIndex;
    this.isLevelSelected = false;
    this.selectedLevel = "";
    this.showLevels();
    this.setLevels(role);
  }
  levelSelected(levleIndex, level) {
    this.isLevelSelected = true;
    this.selectedLevel = level;

    // reset spl
    this.selectedSplGrpId = "";
    this.selectedSplGroupData = "";
    this.selectedSpl="";
    this.setSpecializationGroup(level.FRLS_UniqueId);
  }
  showLevels() {
    //Show levels will show all the levels based on salary grade under a role
    this.isLevelSelected = false;
  }
  setRoles(familyName) {
    this.roles = this.taxonomy.filter(
      x => x.FamilyName.toUpperCase() == familyName.toUpperCase()
      )[0].Roles;
    if(this.empRoleTagging){
      var selectedRole=false;
      for (let index = 0; index < this.roles.length; index++) {
        if(this.roles[index].RoleName==this.empRoleTagging.Role){
          this.roleSelected(index,this.roles[index]);
           this.setLevels(this.roles[index]);
           selectedRole=true;
        }
      }
      if(selectedRole==false)
        this.setLevels(this.roles[0]);
    }else{
      this.setLevels(this.roles[0]);
    }

  }
  setLevels(role) {
    this.selectedRoleName = role.RoleName;
    this.levels = this.roles.filter(
      x => x.RoleName.toUpperCase() == role.RoleName.toUpperCase()
    )[0].Levels;
    var routeParam=this._activateRouter.snapshot.params['type'];
    if(routeParam=='specialization'){
      var level=this.levels.filter(x=>x.LevelSalaryGrade==this.empRoleTagging.LevelSalaryGrade)[0]
      this.levelSelected(0,level)
    } 
  }

  setSpecializationGroup(FRLS_UniqueId) {
    this.splGroups = [];
    this.preloadLoading=true;
    this.rblStoreService.getSpecializationGroupDetails(FRLS_UniqueId).subscribe(
      (splGroups:any[]) => {
        this.preloadLoading=false;
        this.splGroups = splGroups;

        if(this.empRoleTagging && splGroups){
          for(let i=0;i<splGroups.length;i++){
              for(let j=0;j<splGroups[i].SpecializationWithPackageDetails.length;j++){
                if(this.empRoleTagging.Specialization==splGroups[i].SpecializationWithPackageDetails[j].SpecializationName){
                  this.selectedSplGrpId = splGroups[i].GroupId;
                  this.selectedSplGroupData = splGroups[i];
                  //(this.selectedSplGroupData);
                  this.selectedSpl=splGroups[i].SpecializationWithPackageDetails[j];
                }
              }
          }
        }else{
        for(let i=0;i<splGroups.length;i++){
          if(splGroups && splGroups[i].GroupId){
              this.selectedSplGrpId = splGroups[i].GroupId;
              this.selectedSplGroupData = splGroups[i];
              //console.log(this.selectedSplGroupData)
              this.selectedSpl=splGroups[i].SpecializationWithPackageDetails[0];
              break;
          }
        }
      }


        if(""==this.selectedSpl){
          this.selectedSplGrpId = splGroups[0].GroupId;
          this.selectedSplGroupData = splGroups[0];
          this.selectedSpl=splGroups[0].SpecializationWithPackageDetails[0];
        }
      },
      error => {
        this.splGroups = [];
      }
    );
  }

  splGrpSelected(splGrpIndex, splGrp) {
    this.selectedSplGrpId = splGrpIndex;
    this.selectedSplGroupData = splGrp;
  }
  splSelected(splGrp, spl) {
    //console.log(splGrp)
    this.selectedSplGrpId = splGrp.GroupId;
    this.selectedSplGroupData=splGrp;
    this.selectedSpl=spl;

    /**
     *
     this.selectedSplGrpIndex = splGrpIndex;
     this.selectedSplGroupData = splGrp;
     */
  }

  getMindsRBLMapping() {
    this.rblStoreService.getEmployeeRoleTagging().subscribe(
      empRoleTagging => {
        this.empRoleTagging=empRoleTagging;
        // console.log('EMP TAGGING');
        // console.log(empRoleTagging);
        /**
         * FRLS_UniqueId: "TESTING_02"
Family: "Testing"
Level: "Senior SDET "
LevelSalaryGrade: "SDET.C4"
MID: "M1032565"
Role: "SDET"
Specialization: "Automation"
SpecializationId: 971
         */
      },
      error => {}
    );
  }

  packageClicked(isMandatory){
    let data = {
      'isPackageMandatory' : isMandatory,
      'splGrpData': this.selectedSplGroupData
    }
    this.openCoursesDialog(data);
  }
  openCoursesDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = data;
    const declarationPopup = this._dialog.open(
      RblCoursePopupComponent,
      dialogConfig
    );
    declarationPopup.afterClosed().subscribe(payload => {});
  }
}
