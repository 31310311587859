<!-- <div class="video-container bgBlack" *ngIf="showPreloader && !canShowMessage" fxLayout="column" 
 [ngStyle.xs]="{'height.px':'320'}" fxLayoutAlign="center center">
    <div class="playerNotworkingMsg">Loading...</div>
</div> -->
<!-- 
<div class="video-container" fxLayoutAlign="center center" [ngStyle.xs]="{'height.px':'10'}" [ngClass]="{'hidePlayer':(canShowMessage||showPreloader)}">
    <video id="{{videoPlayerId}}" class="azuremediaplayer amp-default-skin" fxFlex="100%">
        <p class="amp-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
        </p>
    </video>
</div> -->

<div #MyVideoContainer id="video-container" fxLayoutAlign="center center" [ngStyle]="{'height.px':'400'}" ></div>


<div *ngIf="canShowMessage && !showPreloader" class="video-container  bgBlack" fxLayout="column" fxLayoutAlign="center center"
    [ngStyle.xs]="{'height.px':'320'}">
    <div *ngIf="isCompliance" class="playerNotworkingMsg">
        <div>Sorry, this video cannot be played. We are working towards resolving the same.</div>
        <div>Your email will not be blocked until this issue is resolved.</div>
        <div>Hence, no action is required from your end.</div>
    </div>
    <div *ngIf="!isCompliance" class="playerNotworkingMsg">
        <div>Sorry, this video cannot be played. We are working towards resolving the same.</div>
        <div>No action is required from your end.</div>
        <div>We will be back soon!</div>
    </div>
</div>