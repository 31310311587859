import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationEnd
} from '@angular/router';
import { LearningPathService } from '../learning-path.service';
import * as fromUserDetailsStore from '../../shared/user-details/store';
import {
  LearningPath,
  PackageList
} from '../../shared/user-details/store/user-details.interface';
import { Store } from '@ngrx/store';
import {
  LPBreadcrumbsStateModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
  IUnitDetailsModel
} from '../learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from '../learning-path-breadcrumbs.interface';
import { FilterContentTileByItsPropertiesPipe } from '@YorbitWorkspace/pipes';
import { Subscriber } from 'rxjs';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { MatDialog } from '@angular/material/dialog';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

import { ContentTileLpComponent } from '../../shared/content-tiles/content-tile-lp/content-tile-lp.component';
import { Globals } from '../../globals';
//import { AnimationGroupPlayer } from '@angular/animations/src/players/animation_group_player';
@Component({
  selector: 'yorbit-lp-detailed',
  templateUrl: './lp-detailed.component.html',
  styleUrls: ['./lp-detailed.component.scss']
})
export class LPDetailedComponent implements OnInit, OnDestroy, AfterViewInit {
  arrayPropertyFilterPipe: any;
  LPBreadcrumbs: ILPBreadcrumbs;
  learningPath: LearningPath;
  packageList: PackageList[];
  LPDeatilsCompSubscriptions: any;
  targetSkill: any;
  filterContentTiles: any;
  showTargetSkill: boolean;
  showSelectedCourseFromTargetSkill: boolean;
  selectedCourseFromTargetSkill: PackageList;
  showNoSearchResultsInfo: boolean;
  searchBy: string;
  addedLinkClick = false;
  highlightedContentId = '';
  mediaBreakPoint = '';
  showPreloader = true;
  routeParams = {
    category: "",
    lpId: ""
  };
  learningPathsLoadedSuccessfully = false;
  DegreedPathwayURL: string;
  @ViewChild(ContentTileLpComponent, { static: false }) lpContentTile;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private dialog: MatDialog,
    private mediaObserver: MediaObserver,
    private global: Globals
  ) {
    this.LPDeatilsCompSubscriptions = {};
    this.learningPath = {};
    this.learningPath.PackageList = [];
    this.targetSkill = {};
    this.packageList = [];
    this.filterContentTiles = new FilterContentTileByItsPropertiesPipe();
    this.arrayPropertyFilterPipe = new ArrayPropertyFilterPipe();
    this.selectedCourseFromTargetSkill = {};
    this.showNoSearchResultsInfo = false;
    this.searchBy = '';
  }

  ngOnInit() {
    this.LPDeatilsCompSubscriptions.getUserDetailLoaded = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailLoaded)
      .subscribe(loaded => {
        //console.log("user details loaded");
        if (loaded && !this.learningPathsLoadedSuccessfully) {
          this.learningPathsLoadedSuccessfully = true;
          this.packageList = [];
          this.subscribeMediaChanges();
          this.LPDeatilsCompSubscriptions.getLearningPathListEntities = null;
          this.subscribeToRouterEvents();
          this.subscribeToLPFilterModel();
          this.subscribeToBreadcrumbs();
          //console.log("last");
          this.showPreloader = false;
          this.subscribeToDialogCloseEvent();
        } else if (loaded && this.learningPathsLoadedSuccessfully) {
          // this.subscribeToRouterEvents();
          // this.subscribeToBreadcrumbs();
        }
        else if (!loaded) {
          this.packageList = [];
          this.showPreloader = true;
        }
      });
  }
  subscribeMediaChanges() {
    this.LPDeatilsCompSubscriptions.mediaChangeSubscription = this.mediaObserver.media$.subscribe(
      (media: MediaChange) => {
        //console.log("media sub 2");
        this.mediaBreakPoint = media.mqAlias;
      }
    );
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.addedLinkClick) {
        if (document.getElementById(this.highlightedContentId) != undefined) {
          this.highlightAndNavigateToSearchedTile();
        }
      }
    }, 1000);
  }
  highlightAndNavigateToSearchedTile() {
    if (
      this.mediaBreakPoint == 'xs' &&
      this.highlightedContentId.indexOf('Course') != -1 &&
      (this.LPBreadcrumbs.LPDetails.LPCategory == 'myCompliance' ||
        (this.LPBreadcrumbs.LPDetails.LPCategory == 'skilling' &&
          this.targetSkill.ItemId !=
          this.highlightedContentId.split('Course')[1]))
    ) {
      this.showCourseFromTargetSkill({
        ItemId: this.highlightedContentId.split('Course')[1]
      });
    }
    //console.log(document.getElementById(this.highlightedContentId));
    let ele = document.getElementById(this.highlightedContentId);
    //console.log('this.highlightedContentId', this.highlightedContentId);
    //console.log('ele.offsetTop', ele.offsetTop);
    //console.log('ele.top', ele.getBoundingClientRect().top);
    if (ele.offsetTop > 300 || ele.offsetTop < 0) {
      document.getElementById('scrollable-content').scrollTop = ele.offsetTop;
      //ele.getBoundingClientRect().top;
    }
  }
  subscribeToBreadcrumbs() {
    this.LPDeatilsCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        //console.log("breadcrumbs sub 5", breadcrumbs);
        this.LPBreadcrumbs = breadcrumbs;
      });
  }
  subscribeToLPFilterModel() {
    this.lpService.getSearchLPModel().subscribe(model => {
      let filteredList;
      this.searchBy = model.toString();
      if (model.length != 0) {
        filteredList = this.filterContentTiles.transform(
          this.learningPath.PackageList,
          model
        );
      } else {
        filteredList = this.learningPath.PackageList;
      }
      this.packageList = this.arrayPropertyFilterPipe.transform(filteredList, {
        property: 'IsDeleted',
        flag: false
      });
      if (this.packageList.length == 0 && model.length != 0) {
        this.showNoSearchResultsInfo = true;
      } else {
        this.showNoSearchResultsInfo = false;
      }
    });
  }
  subscribeToRouterEvents() {
    this.LPDeatilsCompSubscriptions.routeParamsSubscriptions = this.activatedRoute.params.subscribe(
      params => {
        //console.log("router sub 3");
        //console.log('route params', params);
        this.routeParams.category = params["category"];
        this.routeParams.lpId = params["lpId"];
        if (this.LPDeatilsCompSubscriptions.getLearningPathListEntities) {
          this.LPDeatilsCompSubscriptions.getLearningPathListEntities.unsubscribe();
          this.LPDeatilsCompSubscriptions.getLearningPathListEntities = null;
        }
        this.getSelectedLearningPathData();
        this.lpService.updateBreadcrumbs(
          this.learningPath,
          null,
          null,
          null,
          null
        );
      }
    );
    this.LPDeatilsCompSubscriptions.routeQueryParamsSubscriptions = this.activatedRoute.queryParams.subscribe(
      queryParams => {
        //console.log('query params', queryParams);
        // Defaults to 0 if no query param provided.
        if (
          queryParams['courseId'] != undefined ||
          queryParams['packageId'] != undefined
        ) {
          //scroll content till the content tile only if its a course
          this.addedLinkClick = true;
          if (
            queryParams['courseId'] != undefined &&
            queryParams['packageId'] == undefined
          ) {
            this.highlightedContentId = 'Course' + queryParams['courseId'];
          } else if (
            queryParams['courseId'] == undefined &&
            queryParams['packageId'] != undefined
          ) {
            this.highlightedContentId =
              'FamilyPackage' + queryParams['packageId'];
          }
          if (
            !(
              queryParams['courseId'] != undefined &&
              queryParams['packageId'] != undefined
            )
          ) {
            setTimeout(() => {
              if (
                document.getElementById(this.highlightedContentId) != undefined
              ) {
                this.highlightAndNavigateToSearchedTile();
              }
            }, 1000);
          }
        } else {
          this.addedLinkClick = false;
          this.highlightedContentId = '';
        }
        //console.log(' this.highlightedContentId', this.highlightedContentId);
      }
    );
  }
  getSelectedLearningPathData() {
    this.LPDeatilsCompSubscriptions.getLearningPathListEntities = this.userDetailsStore.select(fromUserDetailsStore.getLearningPathListEntities).subscribe(res => {
      //console.log("getLearningPathListEntities sub 4");
      if (res[this.routeParams['lpId'].toUpperCase()] != undefined) {
        this.learningPath = res[this.routeParams['lpId'].toUpperCase()];
        this.packageList = this.arrayPropertyFilterPipe.transform(
          this.learningPath.PackageList,
          {
            property: 'IsDeleted',
            flag: false
          }
        );
        if (this.routeParams['category'] == 'skilling') {
          if (
            this.addedLinkClick &&
            this.targetSkill.ItemId !=
            this.highlightedContentId.split('Course')[1] &&
            this.mediaBreakPoint == 'xs'
          ) {
            this.showTargetSkill = false;
            this.showSelectedCourseFromTargetSkill = true;
          } else {
            this.showTargetSkill = true;
            this.showSelectedCourseFromTargetSkill = false;
          }
          this.targetSkill = {
            ...this.packageList[this.packageList.length - 1]
          };
          this.lpService.setSkillPathPackageList(
            this.packageList.slice(0, this.packageList.length - 1)
          );
        }

        if (this.routeParams['category'] == 'myCompliance') {
          if (this.addedLinkClick && this.mediaBreakPoint == 'xs') {
            this.showTargetSkill = false;
            this.showSelectedCourseFromTargetSkill = true;
          } else {
            this.showTargetSkill = true;
            this.showSelectedCourseFromTargetSkill = false;
          }
          this.targetSkill = {
            ...this.packageList[0]
          };
          this.lpService.setSkillPathPackageList(this.packageList);
        }

        if (this.routeParams['category'] == 'self') {
          this.showTargetSkill = false;
          this.showSelectedCourseFromTargetSkill = false;
          this.targetSkill = {
            ...this.packageList[0]
          };
          this.lpService.setSkillPathPackageList(this.packageList);
        }
      } else {
        this.packageList = [];
      }
    });
  }
  removeHighlightStyle(event) {
    this.highlightedContentId = '';
    this.addedLinkClick = false;
    if (event) {
      this.router.navigate([], { replaceUrl: true });
    }
  }
  navigateTo(data) {
    //////console.log(data);
    let accountId = data.AccountId, projectId = data.ProjectId;
    if (accountId === '' || accountId == undefined) {
      accountId = null;
    }
    if (accountId != null) {
      accountId = accountId.trim();
    }
    if (projectId === '' || projectId == undefined) {
      projectId = null;
    }
    if (projectId != null) {
      projectId = projectId.trim();
    }
    if(this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() === this.global.degreedLPName.toLowerCase()) {
      console.table(data);
      console.table(this.LPBreadcrumbs);
      this.DegreedPathwayURL = data['ImageUrl'];
      if(this.DegreedPathwayURL)
        {
          window.open(this.DegreedPathwayURL);
        }
    }
    else {
      if(data.ItemType==='Assessment'){
        this.router.navigate([
          'learningpath/behavioral-assessment/'
        ]);
      } else if (data.ItemType === 'Package' || data.ItemType === 'FamilyPackage' || data.ItemType === 'RoleBasedPackage') {
        this.router.navigate([
          'learningpath/category/' +
          this.LPBreadcrumbs.LPDetails.LPCategory +
          '/id/' +
          this.LPBreadcrumbs.LPDetails.LPId +
          '/package/' +
          data.ItemId
        ]);
      }else {
        this.router.navigate([
          'learningpath/category/' +
          this.LPBreadcrumbs.LPDetails.LPCategory +
          '/id/' +
          this.LPBreadcrumbs.LPDetails.LPId +
          '/package/null/course/' +
          data.ItemId +
          '/account/' +
          data.AccountPackage +
          '/project/' +
          data.ProjectPackage +
          '/accountId/' +
          accountId +
          '/projectId/' +
          projectId +
          '/tabs/playlist'
        ]);
      }
    }
  }




  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.LPDeatilsCompSubscriptions) {
      let subscriber = this.LPDeatilsCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  subscribeToDialogCloseEvent() {
    this.LPDeatilsCompSubscriptions.matDialogCloseSubscription = this.dialog.afterAllClosed.subscribe(
      val => {
        if (val != undefined && this.LPBreadcrumbs.LPDetails.LPId != null) {
          this.userDetailsStore
            .select(
              fromUserDetailsStore.getLearningPathByIdEntities(
                this.LPBreadcrumbs.LPDetails.IsLPMandatory
                  ? this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
                  : this.LPBreadcrumbs.LPDetails.LPId
              )
            )
            .subscribe(lp => {
              this.packageList = this.arrayPropertyFilterPipe.transform(
                lp.PackageList,
                {
                  property: 'IsDeleted',
                  flag: false
                }
              );
            });
        }
      }
    );
  }
  showCourseFromTargetSkill(event) {
    if (event.ItemId) {
      this.showTargetSkill = false;
      this.showSelectedCourseFromTargetSkill = true;
      this.selectedCourseFromTargetSkill = this.arrayPropertyFilterPipe.transform(
        this.packageList,
        {
          property: 'ItemId',
          flag: event.ItemId
        }
      )[0];
    }
  }
  updateDeclareForSkilling() {
    this.packageList = [];
    this.subscribeToRouterEvents();
    this.subscribeToLPFilterModel();
    this.subscribeToBreadcrumbs();
    this.subscribeToDialogCloseEvent();
    this.lpContentTile.reloadSkillTargetCourse();
  }
  goToHelp() {
    this.router.navigate(['/info/aboutus']);
  }
  goToFAQ() {
    if (
      this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() ==
      this.global.CocLpName.toLowerCase() || this.LPBreadcrumbs.LPDetails.LPName.toLowerCase() == this.global.BEACLpName.toLowerCase()
    ) {
      this.router.navigate(['/info/faq/', 'Business Ethics Compliance']);
    } else {
      this.router.navigate([
        '/info/faq/',
        'Sexual Harassment Prevention Training - USA'
      ]);
    }
  }
  goToFeedback() {
    this.router.navigate(['/info/feedback']);
  }

  openISuppport() {
    window.open(this.global.isupportUrl, '_blank')
  }
}
