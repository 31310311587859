import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  _baseURL: string;
  personalizedRecommendations: any;
  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
    this.personalizedRecommendations = null;
    //this.getLearningScopeFromDigitalInsideTeam();
  }
  getRecommendedCourses(userId) {
    if (this.personalizedRecommendations == null) {
      const url = this._baseURL + 'recommendations/byLearner';
      return this._http.post(url,{"Id":userId}).toPromise();
    } else {
      return new Promise((res, rej) => {
        res(this.personalizedRecommendations);
      });
    }
  }
  getNewTrendingPackages() {
    const url = this._baseURL + 'HomePage/TrendingAndNew';
    return this._http.get<any>(url);
  }
  canShowRoleBasedRecommendations() {
    const url = this._baseURL + 'User/ShowRoleBasedRecommendations';
    return this._http.get<any>(url);
  }
  getTopLearners() {
    const url = this._baseURL + 'User/TopLearners';
    return this._http.get<any>(url);
  }

  postFile(fileToUpload: File) {
    const endpoint = this._baseURL + 'HomePage/FutureSkillRecommendations/Dump';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this._http.post(endpoint, formData);
  }
  getPopupStatusForRelease() {
    const url = this._baseURL + 'HomePage/PopUpStatusForRelease';
    return this._http.get<any>(url);
  }
  getLearningScopeFromDigitalInsideTeam() {
    let headers = new HttpHeaders({
      Authorization: 'Apikey a645124f-aa8d-4aeb-b822-b1383aaeaae2'
    });

    let url =
      'https://bridge-builders.mindtree.com/igx-cmn-dev/gateway/learning-service/api/v1/learningscopes/employee/1';
    this._http
      .get(url, { headers: headers })
      .toPromise()
      .then(res => {
        ////console.log('learningscopes', res);
      });
  }
  getStats() {

    const url = this._baseURL + 'HomePage/StatsOnHomePage';

    return this._http.get<any>(url);

  }
}
