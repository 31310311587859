import { Action } from '@ngrx/store';

export const CLOUDLAB_GET_DETAILS = '[Cloudlab] Get Status';
export const CLOUDLAB_GET_DETAILS_SUCCESS = '[Cloudlab] Get Status Success';
export const CLOUDLAB_GET_DETAILS_ERROR = '[Cloudlab] Get Status Error';

export class CloudlabGetStatus implements Action {
  readonly type = CLOUDLAB_GET_DETAILS;
  constructor(public payload: any) {}
}
export class CloudlabGetStatusSuccess implements Action {
  readonly type = CLOUDLAB_GET_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class CloudlabGetStatusError implements Action {
  readonly type = CLOUDLAB_GET_DETAILS_ERROR;
  constructor(public payload: any) {}
}

export type CloudlabAction =
  | CloudlabGetStatus
  | CloudlabGetStatusSuccess
  | CloudlabGetStatusError;
