<div class="typeFiltercontainer">
  <div class="">

      <div class="typeSelectorContainer floatLeft" *ngIf="!isCourseSelected">
          <div class="typeSelectorInputContainer">
              <mat-form-field>
                  <mat-select placeholder="Expertise" [formControl]="typeFilterForm"
                  panelClass="pssTypeFilterSelect"
                  (selectionChange)='onTypeSelected($event)' [(ngModel)]="SelectedFilter.Expertise"
                  >
                    <mat-option value="All">All</mat-option>
                    <mat-option
                    *ngFor="let filterValue of filterList.Expertise"
                     value="{{filterValue}}">{{filterValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
          </div>
        </div>

    <div class="typeSelectorContainer floatLeft" *ngIf="isCourseSelected">
      <div class="typeSelectorInputContainer">
          <mat-form-field>
              <mat-select placeholder="CERTIFICATION STATUS" [formControl]="typeFilterForm"
              panelClass="pssTypeFilterSelect"
              (selectionChange)='onTypeSelected($event)' [(ngModel)]="SelectedFilter.CertificationStatuses"
              >
                <mat-option value="All">All</mat-option>
                <mat-option
                *ngFor="let filterValue of filterList.CertificationStatuses"
                 value="{{filterValue}}">{{filterValue}}</mat-option>
              </mat-select>
            </mat-form-field>
      </div>
    </div>
    <div class="typeSelectorContainer floatLeft" *ngIf="isCourseSelected">
        <div class="typeSelectorInputContainer">
            <mat-form-field>
                <mat-select placeholder="CERTIFICATION SUB-STATUS" [formControl]="typeFilterForm"
                panelClass="pssTypeFilterSelect"
                (selectionChange)='onTypeSelected($event)' [(ngModel)]="SelectedFilter.CertificationSubStatuses"
                >
                  <mat-option value="All">All</mat-option>
                  <mat-option
                  *ngFor="let filterValue of filterList.CertificationSubStatuses"
                   value="{{filterValue}}">{{filterValue}}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>

    <div class="typeSelectorContainer floatLeft">
      <div class="typeSelectorInputContainer">
          <mat-form-field>
              <mat-select placeholder="COURSE TYPE" [formControl]="typeFilterForm"
              panelClass="pssTypeFilterSelect"
              (selectionChange)='onTypeSelected($event)' [(ngModel)]="SelectedFilter.CourseTypes"
              >
                <mat-option value="All">All</mat-option>
                <mat-option
                *ngFor="let filterValue of filterList.CourseTypes"
                 value="{{filterValue}}">{{filterValue}}</mat-option>
              </mat-select>
            </mat-form-field>
      </div>
    </div>
    <div class="typeSelectorContainer floatLeft">
      <div class="typeSelectorInputContainer">
          <mat-form-field>
              <mat-select placeholder="VENDOR" [formControl]="typeFilterForm"
              panelClass="pssTypeFilterSelect"
              (selectionChange)='onTypeSelected($event)' [(ngModel)]="SelectedFilter.Vendors"
              >
                <mat-option value="All">All</mat-option>
                <mat-option
                *ngFor="let filterValue of filterList.Vendors"
                 value="{{filterValue}}">{{filterValue}}</mat-option>
              </mat-select>
            </mat-form-field>
      </div>
    </div>
    <div class="typeSelectorContainer floatLeft">
      <div class="typeSelectorInputContainer">
          <mat-form-field>
              <mat-select placeholder="ACADEMY" [formControl]="typeFilterForm"
              panelClass="pssTypeFilterSelect"
              (selectionChange)='onTypeSelected($event)' [(ngModel)]="SelectedFilter.Academies"
              >
                <mat-option value="All">All</mat-option>
                <mat-option
                *ngFor="let filterValue of filterList.Academies"
                 value="{{filterValue}}">{{filterValue}}</mat-option>
              </mat-select>
            </mat-form-field>
      </div>
    </div>
    <div class="typeSelectorContainer floatLeft"  *ngIf="false">
      <div class="typeSelectorInputContainer">
          <mat-form-field>
              <mat-select placeholder="LOCATION" [formControl]="typeFilterForm"
              panelClass="pssTypeFilterSelect"
              (selectionChange)='onTypeSelected($event)' [(ngModel)]="SelectedFilter.Locations"
              >
                <mat-option value="All">All</mat-option>
                <mat-option
                *ngFor="let filterValue of filterList.Locations"
                 value="{{filterValue}}">{{filterValue}}</mat-option>
              </mat-select>
            </mat-form-field>
      </div>
    </div>
    <button
    class="resetButton mousePointer"
    mat-raised-button
    aria-label="Reset"
    (click)="Reset()"
  >
  Reset
  </button>
  </div>
</div>
