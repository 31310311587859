import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PssEvaluationService } from '../pss-evaluation.service';
import { CheckboxList, YorbitCourseRequestList, CheckboxDisableList } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-pss-certification-substatus',
  templateUrl: './pss-certification-substatus.component.html',
  styleUrls: ['./pss-certification-substatus.component.scss']
})
export class PssCertificationSubstatusComponent implements OnInit {

  public searchText:any;
  dataList: YorbitCourseRequestList[] = []; 
  subStatusList: CheckboxList[] = [];
  changedItem: CheckboxList[];
  filteredDataList: YorbitCourseRequestList[] = [];
  uniqueSubStatusList: string[] = [];
  checkedCourseList: string[] = [];
  checkedAcademyList: string[] = [];
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  checkedCourseVendorList: string[] = [];
  displayList: CheckboxDisableList[] = [];
  changedSubStatusList: CheckboxDisableList[] = [];
  @Output() isSubStatusFiltered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _PssEvaluationService: PssEvaluationService) { }

  ngOnInit() {
    this.subStatusList = [];
    //this.subStatusList = this._PssEvaluationService.selectedSubStatusList;
    this.dataList = this._PssEvaluationService.dataList;
    this.checkedSubStatusList = this._PssEvaluationService.checkedSubStatusList;
    this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
    this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
    this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
    this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
    this.checkedLearningOPMSPOCList = this._PssEvaluationService.checkedLearningOPMSPOCList;
    //if(this.subStatusList.length == 0) {
      this.GetSubStatusList(); 
    //}
  }

  GetSubStatusList() {
    this.filteredDataList = this._PssEvaluationService.filterRecords('SubStatus');
    ////console.log('.....', this.filteredDataList);

    //For Displaying all unique Sub status list
    for(let i=0; i<this.dataList.length;i++) {
      if(this.uniqueSubStatusList.indexOf(this.dataList[i].CertificationSubStatus) == -1) {
        this.uniqueSubStatusList.push(this.dataList[i].CertificationSubStatus);
      }
    }

    //Checking if atleast any one of the filter is applied
    //If no filter is applied, Display all the unique Sub status list without disabling any option
    //and selecting any option
    if(this.checkedCourseList.length == 0 
      && this.checkedAcademyList.length == 0
      && this.checkedCourseTypeList.length == 0
      && this.checkedLearningOPMSPOCList.length == 0
      && this.checkedCourseVendorList.length == 0) {
      this.uniqueSubStatusList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: true
        }
      }).forEach(item=>this.displayList.push(item));
    }
    //If any filter is applied, disable all the options and deselect all the options
    else {
      this.uniqueSubStatusList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
    }
    ////console.log('Filterdata', this.filteredDataList);
    ////console.log('fasond', this.displayList);
    this.displayList = this.displayList.filter(x => x.value != "");
    this.enableFilteredSubStatusList();   
  }

  //For enabling the Sub status list for which is only present in the FilteredDataList
  enableFilteredSubStatusList() {
    ////console.log('enablefilter', this.filteredDataList);
    for(let i=0; i<this.filteredDataList.length; i++) {
      this.changedSubStatusList = [];
      this.changedSubStatusList = this.displayList.filter(item => item.value === this.filteredDataList[i].CertificationSubStatus);
      this.changedSubStatusList[0].isEnabled = true;
      this.changedSubStatusList[0].isSelected = false;
    }
    ////console.log('filterdatafadsfads', this.displayList);
    this.preserveSelected();
  }

  //For selecting the Sub status list which was previously selected
  preserveSelected() {
    for(let i=0; i<this.checkedSubStatusList.length; i++) {
      this.changedSubStatusList = [];
      this.changedSubStatusList = this.displayList.filter(item => item.value === this.checkedSubStatusList[i]);
      this.changedSubStatusList[0].isEnabled = true;
      this.changedSubStatusList[0].isSelected = true;
      ////console.log('changeditem', this.changedSubStatusList);
    }
    ////console.log('DisplayVenodr', this.displayList);
  }

  updateCheckedOptions(option, event) {
    if(this.checkedSubStatusList.indexOf(option) == -1)
      this.checkedSubStatusList.push(option);
    else
      this.checkedSubStatusList.splice(this.checkedSubStatusList.indexOf(option),1);
    ////console.log('checked item', this.checkedSubStatusList);
    this._PssEvaluationService.checkedSubStatusList = this.checkedSubStatusList;
    this.isSubStatusFiltered.emit(this.checkedSubStatusList.length>0);
  }
}
