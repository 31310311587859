import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class ContentTileDeletePopupService {
  config: Ienvironment;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }
  // Removing the content from the learning plan
  removeContent(itemId, pathId, itemType, isProjectPackage, isAccountPackage) {
    let Url = '';
    if (isProjectPackage || isAccountPackage)
      Url =
        this.config.apiUrl +
        'User/Remove/AccountPackage/' +
        pathId +
        '/' +
        itemId +
        '/' +
        itemType;
    else
      Url =
        this.config.apiUrl +
        'User/Remove/Package/' +
        pathId +
        '/' +
        itemId +
        '/' +
        itemType;
    return this.http.delete(Url).toPromise();
  }
}
