export interface IUserGraphProfile{
  user_name ?: string,
  user_first_name ?:string,
  user_job_title ?:string,
  user_image ?: Blob,
  error ?: string
}

export const UserGraphInitialState : IUserGraphProfile = {
  user_name : '',
  user_first_name :'',
  user_job_title:'',
  user_image: new Blob(),
  error: ''
}
