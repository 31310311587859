import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Globals } from '../globals';
import { HeaderService } from '../header/header.service';
import { NasscomRecommendationsService } from '../shared/nasscom/nasscom-recommendations.service';

@Component({
  selector: 'yorbit-future-skills',
  templateUrl: './future-skills.component.html',
  styleUrls: ['./future-skills.component.scss']
})
export class FutureSkillsComponent implements OnInit, OnDestroy {

  constructor(private _nasscomSvc: NasscomRecommendationsService,
    private headerService: HeaderService,
    private _InsightService: AppInsightService,
    public globals: Globals) { }
  futureSkillsList: any = [];
  masterList: any = [];
  masterListMobile: any = [];
  totalItem: any = 10;

  ngOnInit() {
    this.getFutureSkills();
    this.emitFutureSkillValue(true);
  }

  emitFutureSkillValue(val) {
    this.headerService.futureSkillButtonAnimation.next(val);
  }
  
  ngOnDestroy() {
    this.emitFutureSkillValue(false);
  }

  getFutureSkills() {
    this.futureSkillsList = [];
    this.masterList = [];
    this._nasscomSvc.getFutureSkils().subscribe(
      data => {
        if (data && data.length > 0) {
          this.futureSkillsList = data;
        }
        for(let i = 0; i<this.futureSkillsList.length; i += 5) {
            var a = this.futureSkillsList.slice(i,i+5);
            this.masterList.push(a);
        }
        for(let j = 0; j<this.futureSkillsList.length; j += 2) {
          var b = this.futureSkillsList.slice(j,j+2);
          this.masterListMobile.push(b);
        }
      },
    );

    
  }
  redirectToSite(skill) {
    const insightPayload = {​​
      MID: this.globals.MId,
      ChannelName: skill.ChannelName,
      ChannelUrl: skill.SkillUrl
    }​​;

    this._InsightService.trackEvent('Future Skill Channel clicked', insightPayload);
    window.open(skill.SkillUrl, '_blank');
  }
}
