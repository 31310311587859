import { Component, OnInit, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { WINDOW } from '../../../shared/services/window.service';

@Component({
  selector: 'yorbit-recognition-profile',
  templateUrl: './recognition-profile.component.html',
  styleUrls: ['./recognition-profile.component.scss']
})
export class RecognitionProfileComponent implements OnInit {
  @Input() learner: any;
  @Input() showProgramName: boolean;
  @ViewChild('learnerImage', { static: true }) learnerImage: ElementRef;

  constructor(
    private graphDataService: GraphDataService,
    @Inject(WINDOW) private _window: any
  ) { }

  ngOnInit() {
    if (this.learner.imgURL) {
      //if imgURL property is present, get image from assets
      this.getUserImageFromAssets(this.learner.imgURL);
    } else {
      //if imgURL property is null or undefined, get image from graph
      this.getUserImageFromGraph();
    }
  }

  getUserImageFromGraph() {
    this.graphDataService
      .getUserImage(this.learner.MID)
      .subscribe(res => {
        this.learnerImage.nativeElement.src = this._window.URL.createObjectURL(
          res
        );
      },
        error => {
          //////console.log('Error', error);
        });
  }
  getUserImageFromAssets(imgUrl) {
    this.learnerImage.nativeElement.src = imgUrl;
  }
}
