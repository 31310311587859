import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PssEvaluationService } from '../pss-evaluation.service';
import { CheckboxList, CheckboxDisableList, YorbitCourseRequestList } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-pss-evaluator-vendor',
  templateUrl: './pss-evaluator-vendor.component.html',
  styleUrls: ['./pss-evaluator-vendor.component.scss']
})
export class PssEvaluatorVendorComponent implements OnInit {

  dataList: YorbitCourseRequestList[] = []; 
  evaluatorVendorList: CheckboxList[] = [];
  changedItem: CheckboxList[];
  filteredDataList: YorbitCourseRequestList[] = [];
  uniqueEvalVendorList: string[] = [];
  checkedCourseList: string[] = [];
  checkedAcademyList: string[] = [];
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  public searchText:any;
  checkedCourseVendorList: string[] = [];
  checkedEvaluatorVendorList: string[] = [];
  displayList: CheckboxDisableList[] = [];
  changedEvalVendorList: CheckboxDisableList[] = [];
  @Output() isEvalVendorFiltered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _PssEvaluationService: PssEvaluationService) { }

  ngOnInit() {
    this.evaluatorVendorList = [];
    this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
    this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
    this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
    this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
    this.checkedEvaluatorVendorList = this._PssEvaluationService.checkedEvaluatorVendorList;
    this.dataList = this._PssEvaluationService.dataList;
    if(this.evaluatorVendorList.length == 0) {
      this.GetEvaluatorVendorList(); 
    }
  }

  GetEvaluatorVendorList() {
    this.filteredDataList = this._PssEvaluationService.filterRecords('EvaluatorVendor');

    //For Displaying all unique Evaluator Vendor list
    for(let i=0; i<this.dataList.length;i++) {
      if(this.uniqueEvalVendorList.indexOf(this.dataList[i].EvaluatorVendor) == -1) {
        this.uniqueEvalVendorList.push(this.dataList[i].EvaluatorVendor);
      }
    }

    this.uniqueEvalVendorList = this.uniqueEvalVendorList.filter(function(x){
      return x.trim() != '';
    });

    ////console.log('EvaluatorVendorListUnique', this.uniqueEvalVendorList);

    //Checking if atleast any one of the filter is applied
    //If no filter is applied, Display all the unique Evaluator vendor list without disabling any option
    //and selecting any option
    if(this.checkedCourseList.length == 0 
      && this.checkedAcademyList.length == 0
      && this.checkedCourseTypeList.length == 0
      && this.checkedLearningOPMSPOCList.length == 0
      && this.checkedSubStatusList.length == 0
      && this.checkedCourseVendorList.length == 0) {
      this.uniqueEvalVendorList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: true
        }
      }).forEach(item=>this.displayList.push(item));
    }
    //If any filter is applied, disable all the options and deselect all the options
    else {
      this.uniqueEvalVendorList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
    }
    ////console.log('Filterdata', this.filteredDataList);
    ////console.log('fasond', this.displayList);
    this.enableFilteredEvaluatorVendorList();
  }

  //For enabling the Evaluator Vendor list for which is only present in the FilteredDataList
  enableFilteredEvaluatorVendorList() {
    ////console.log('enablefilter', this.filteredDataList);
    for(let i=0; i<this.filteredDataList.length; i++) {
      this.changedEvalVendorList = [];
      this.changedEvalVendorList = this.displayList.filter(item => item.value == this.filteredDataList[i].EvaluatorVendor);
      if(this.changedEvalVendorList[0] != null) {
        this.changedEvalVendorList[0].isEnabled = true;
        this.changedEvalVendorList[0].isSelected = false;
      }
    }
    ////console.log('filterdatafadsfads', this.displayList);
    this.preserveSelected();
  }

  //For selecting the Evaluator Vendor list which was previously selected
  preserveSelected() {
    for(let i=0; i<this.checkedEvaluatorVendorList.length; i++) {
      this.changedEvalVendorList = [];
      this.changedEvalVendorList = this.displayList.filter(item => item.value === this.checkedEvaluatorVendorList[i]);
      this.changedEvalVendorList[0].isEnabled = true;
      this.changedEvalVendorList[0].isSelected = true;
      ////console.log('changeditem', this.changedEvalVendorList);
    }
    ////console.log('DisplayVenodr', this.displayList);
  }

  updateCheckedOptions(option) {
    if(this.checkedEvaluatorVendorList.indexOf(option) == -1)
      this.checkedEvaluatorVendorList.push(option);
    else
      this.checkedEvaluatorVendorList.splice(this.checkedEvaluatorVendorList.indexOf(option),1);
    this._PssEvaluationService.checkedEvaluatorVendorList = this.checkedEvaluatorVendorList;
    this.isEvalVendorFiltered.emit(this.checkedEvaluatorVendorList.length>0);
    ////console.log('chekkedlist', this.checkedEvaluatorVendorList, this._PssEvaluationService.checkedEvaluatorVendorList);
  }
}