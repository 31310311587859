<div class="diversity-and-inclusion" fxLayout="column" fxLayoutAlign="start stretch" >

    <!--D&I Banner -->
    <div class="banner-holder" fxLayout="column">
        <div class="banner" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']">
            <div class="desktop-banner"  [ngClass.xs]="['xs']"
                [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']" style="margin-left: 9%;">
             
                <span  class="page-title"> Our AI Strategy</span>
               
            </div>


        </div>
    </div>

  

    <div fxLayout="column" class="video-section1" [ngClass.xs]="['xs']"fxLayoutAlign="start stretch">


        <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isVideoLoading">
            <app-preloader></app-preloader>
        </div>

       
       

         <!-- Video's Section -->
         <div class="video-section" id="videoSection" [ngClass.xs]="['xs']" fxLayout="row" fxLayoutAlign="center stretch">
            <div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayout.xs="column" fxLayoutGap="6%" fxFlex.xs="100%"
                fxFlex.md="888px" fxFlex.gt-md="1080px">
                <div fxLayout="column" class='video' fxFlex.md="1 0 70%" fxFlex.gt-md="1 0 70%">
                    <!-- <yorbit-video-container *ngIf="selectedVideo" [videoData]="selectedVideo">
                    </yorbit-video-container> -->
                    <iframe class="fullScreen" id="contentPlayer" style="width: 100%;height: 80%;min-height: 400px;"></iframe>
                    <label class="video-title">{{selectedVideo.Title}}</label>
                    <label class="video-desciption">{{selectedVideo.Description}}</label>
                </div>
                <div fxLayout="column" class='related-video-section' fxFlex="1 1 auto" fxFlex.md="1 0 25%"
                    fxFlex.gt-md="1 0 25%" >
                    <label class='related-videos' *ngIf="selectedVideo.Id<2">Next Up</label>

                    <!-- <div fxLayout="row"  fxFlex fxLayoutAlign="center center" *ngIf="!isVideoLoading && videoList.length<=1">
            <span  class="not-data-found">Related Videos Not Found!!! </span>
           </div> -->
                    <div class="related-video-container" fxLayout="column" fxLayout.xs="row" *ngIf="selectedVideo.Id<2">
                        <div *ngFor="let item of innovativeExchangeSeason1;index as i">
                            <yorbit-our-aistrategy-related-videos *ngIf="i==selectedVideo.Id || i==selectedVideo.Id+1" [videoData]="item"
                                (videoClicked)="clickOfReltatedVideoSelect(item)"></yorbit-our-aistrategy-related-videos>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <mat-divider class="line" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']"></mat-divider>

         <!-- Innovation Exchange Podcast GRID -->
         <div class="article-section" [ngClass.xs]="['xs']" fxLayout="row" fxLayoutAlign="center stretch">
            <div fxLayout="column" fxLayoutGap="5px" fxFlex.xs="95%" fxFlex.md="910px" fxFlex.gt-md="1110px"
                class="article-margin" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']">
                <!-- Header And Filter -->
                <div fxLayout="row" class="article-header" fxLayout.xs='column' [ngClass.xs]="['xs']"
                    [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']">
                    <div class="section-header" [ngClass.xs]="['xs']" fxFlex.md="1 0 70%" fxFlex.gt-md="1 0 60%">Episodes</div>
                    <!-- <div fxLayout="row" fxFlex.md="1 0 30%" fxFlex.gt-md="1 0 40%" fxLayoutAlign="end center"
                        fxLayoutAlign.xs="start center">
                        <select
                            class="dropDown"
                            [(ngModel)]="selectedSeason"
                            (change)="seasonSelected()"
                            >
                            <option selected disabled>Select Season</option>
                            <option [ngValue]="season" *ngFor="let season of seasonList">
                                {{ season }}
                            </option>
                            </select>
                    </div> -->
                </div>

                <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isArticleLoading">
                    <app-preloader></app-preloader>
                </div>

                <div fxLayout="row" fxFlex fxLayoutAlign="center center"
                    *ngIf="!isArticleLoading && articleList.length==0">
                    <span *ngIf="selectedArticleFilter=='article'" class="not-data-found">Article(s) currently not
                        available
                    </span>
                    <span *ngIf="selectedArticleFilter=='video'" class="not-data-found">Video(s) currently not available
                    </span>
                    <span *ngIf="selectedArticleFilter=='Publication'" class="not-data-found">Publication(s) currently
                        not
                        available </span>
                </div>


                <!-- Grid Articles -->
                <div fxLayout="row wrap" fxLayoutGap.gt-md="27px" fxLayoutGap.md="27px" *ngIf="!isArticleLoading">
                    <div *ngFor="let item of innovativeExchangeSeason1; index as i" class="article" [ngClass.xs]="['xs']">
                        <yorbit-our-aistrategy-video-tile (videoClicked)="clickOfArticleTile(item)"
                            [articleDetail]="item" *ngIf='i<8 || seeMoreFlag'></yorbit-our-aistrategy-video-tile>
                    </div>
                </div>

                <!-- Show More Artcles -->
                <!-- <div fxLayout="row" fxLayoutAlign="center" style="margin-top: -10px;" class="see-more"
            *ngIf="articleList.length>8">
            <span (click)="seeMoreFlag=!seeMoreFlag">{{seeMoreFlag?'See Less':'See More'}}</span>
          </div> -->

            </div>

        </div>

    
    </div>

    <div id="videoContainer"></div>

    <img id="scrollBtn" class="bottom-left-icon" src="../../../../assets/images/DandI/up-arrow.png"
    width="50" height="50" (click)="topFunction()">

</div>