import * as fromRoleAction from '../actions/role.action';

export interface IRoleReducerState{
role:IroleData;
loading:boolean;
loaded:boolean;
}

const roleAccessInit={
  role:{
    ACM:false,
    PCM:false,
    CDM:false,
    CUSTOMER:false,
    RM:false,
    PSS:false,
    TM:false,
    C2OPS:false,
    AO:false,
    GLOBALCDM:false,
    SME:false,
    BEAS:false
  },
  loading:false,
  loaded:false
}

export function roleReducer(
  state = roleAccessInit,
  action: fromRoleAction.RoleAction
):IRoleReducerState {
switch (action.type) {
  case fromRoleAction.ROLE_GET_ACCESS_LIST:{
    return{
      ...state,
      loading:true
    }
  }

  case fromRoleAction.ROLE_GET_ACCESS_LIST_SUCCESS:{
    const role=loadRoleAccess(state.role,action.payload)
    return{
      ...state,
      role,
      loading:false,
      loaded:true
    }
  }

  case fromRoleAction.ROLE_UPDATE_ACCESS_LIST:{
    const role=loadRoleAccess(state.role,action.payload)
    return{
      ...state,
      role,
      loading:false,
      loaded:true
    }
  }

  case fromRoleAction.ROLE_GET_ACCESS_LIST_FAIL:{
    return{
      ...state,
      loading:false,
      loaded:true
    }
  }
}
return state
}

function loadRoleAccess(data,payload){
  let newData={...data};
  if(payload)
payload.forEach(role => {
  switch (role) {
    case "ACM":
    newData.ACM=true;
    break;
    case "PCM":
    newData.PCM=true;
    break;
    case "CDM":
    newData.CDM=true;
    break;
    case "CUSTOMER":
    newData.CUSTOMER=true;
    break;
    case "RM":
      newData.RM=true;
    break;
    case "PSS":
    newData.PSS=true;
    break;
    case "TM":
    newData.TM=true;
    break;
    case "C2OPS":
    newData.C2OPS=true;
    break;
    case "AO":
    newData.AO=true;
    break;
    case "GLOBALCDM":
      newData.GLOBALCDM=true;
    break;
    case "SME":
      newData.SME=true;
    break;
    case "BEAS":
      newData.BEAS=true;
    default:
      break;
  }
});
  return newData
}

export const getRoleAccessList=(state:IRoleReducerState)=>state.role;
export const getRoleAccessListLoaded=(state:IRoleReducerState)=>state.loaded;
export const getRoleAccessListLoading=(state:IRoleReducerState)=>state.loading;

export interface IroleData{
  ACM?:boolean;
  PCM?:boolean;
  CDM?:boolean;
  CUSTOMER?:boolean;
  RM?:boolean;
  PSS?:boolean;
  TM?:boolean;
  C2OPS?:boolean;
  AO?:boolean;
  SME?:boolean;
  BEAS?:boolean;
}
