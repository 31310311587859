import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { PssFiltersService } from '../pss-filters.service';
import { FileSaverService } from 'ngx-filesaver';
import { PssUploadPopupComponent } from '../pss-upload-popup/pss-upload-popup.component';

@Component({
  selector: 'yorbit-pss-classroom-popup',
  templateUrl: './pss-classroom-popup.component.html',
  styleUrls: ['./pss-classroom-popup.component.scss']
})
export class PssClassroomPopupComponent implements OnInit {

  actionMessage: string;
  disableProceed: boolean;
  fileToBeUploaded: FormData;
  fileName: any;
  templateDownloadInProgress: boolean;
  excelUploadInProgress: boolean;
  classRoomExcelUploadInProgress: boolean;
  isUploadButtonDisabled: boolean = true;
  storeData: string | ArrayBuffer;
  secondSheetName: string;
  isUploadSuccess: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PssUploadPopupComponent>,
    private _pssFiltersService: PssFiltersService,
    private _FileSaverService: FileSaverService
  ) {}

  ngOnInit() {
    this.fileName = 'Select a File';
    this.templateDownloadInProgress = false;
    this.classRoomExcelUploadInProgress = false;
    this.isUploadButtonDisabled = true;
  }

  downloadTemplate() {
    this.templateDownloadInProgress = true;
    this._pssFiltersService
      .downloadTemplateForClassRoomUpload()
      .then(res => {
        this.templateDownloadInProgress = false;
        this.downloadExcel(res);
      })
      .catch(error => {
        this.templateDownloadInProgress = false;
        this.errorDownload(error);
      });
  }

  downloadExcel(res) {
    const blob = new Blob([<any>res.body], {
      type: 'application/octet-stream'
    });

    this._FileSaverService.save(blob, 'UploadTemplate.xlsx');
    this.disableProceed = false;
    this.actionMessage = 'Download Completed';
    this.hideActionMessage();
  }
  errorDownload(error) {
    this.disableProceed = false;
    const errorMessage = this.data.isCourseSelected
      ? 'Download Failed'
      : 'Download Failed or There is no data available in internal certificate details.';
    this.actionMessage = errorMessage;
  }

  hideActionMessage() {
    setTimeout(() => {
      this.actionMessage = '';
    }, 3000);
  }

  closePopup() {
    this.dialogRef.close();
  }
  onFilesAdded(files, event){
    const target: DataTransfer = <DataTransfer>(event.target);
    if (files.length === 0){
      this.fileName="Select a File";
      this.fileToBeUploaded=new FormData();
      return;
    }

    const formData = new FormData();

    for (const file of files) {
      formData.append(file.name, file, file.name);
      this.fileName = file.name;
    }let reader = new FileReader(); 
    reader.onload = (e: any) => {
      var bstr = e.target.result;  
      var workbook = XLSX.read(bstr, { type: "binary" }); 
      this.secondSheetName = workbook.SheetNames[1];  
      if(this.secondSheetName == 'MetaData') {
        this.actionMessage = "";
        this.isUploadButtonDisabled = false;        
      }
      else {
        this.actionMessage = "Sorry! Seems like you are using the old version of the PSS upload default template. Please download the updated version and re-upload.";
        this.isUploadButtonDisabled = true;
      }
    };
    reader.readAsBinaryString(target.files[0]); 
    this.fileToBeUploaded=formData; 
  }

  classRoomUpload() {
    this.actionMessage = 'Uploading...';
    this.classRoomExcelUploadInProgress = true;
    this._pssFiltersService
      .classRoomUploadService(this.fileToBeUploaded)
      .subscribe(res => {
      var response = res as string[];
      if(response == null || response.length == 0) {
        this.isUploadSuccess = true;
        this.classRoomExcelUploadInProgress = false;
        this.actionMessage="File has been successfully uploaded! For more details please refer to the log file sent to your email.";
      }
      else if(response.length > 0 && response.length < 6) {
        this.classRoomExcelUploadInProgress = false;
        this.actionMessage = "Sorry, upload was not successful for the given details. For more details please refer to the log file sent to your email."
      }
      else if(response.length > 5) {
        var firstFiveElements = response.slice(0,5);
        this.classRoomExcelUploadInProgress = false;
        this.actionMessage = "Sorry, upload has failed for the given details. For more details please refer to the log file sent to your email."
      }
    });
  }
}
