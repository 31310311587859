import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genre',
  pure:false
})
export class GenrePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value!==undefined){
      if (value.length !== 0) {
        let returnedValue;
        if(args.length!==0){
          returnedValue = [];
          value.forEach(element => {
            args.forEach(ele => {
              if (element.Genre === ele) {
                returnedValue.push(element);
                return returnedValue;
              }
            });
          });
        }
        else{
          returnedValue = value;
        }
        return returnedValue;
      }
    }

  }

}
