<!--new design-->
<div class="pss-certificate-request" fxLayoutGap="20px" fxLayout="column">
  <div fxLayout="row wrap" fxLayoutAlign="start start" *ngFor="let request of requests; let index = index">
    <div *ngIf="request.showCourseName" class="course-name-header" fxFlex="100%">
      {{ request.CourseName }}
    </div>
    <div *ngIf="selectedTab=='pending'" fxFlex="100%">
      <div class="request-index" fxFlex="20px">
        {{ contentIndex+index+1 }}.
      </div>
      <div class="request-details" *ngIf ="request.isCourseCleared == true && request.Certification_CompletionDate!='0001-01-01'" fxFlex>
        <div fxFlex="180px" fxLayout="column" class="user-profile" fxLayoutAlign="start stretch">
          <div class="section request-id" fxLayout="row wrap">
            <div class="header" fxFlex="80px">Request ID</div>
            :
            <div class="value" fxFlex="70px">&nbsp;{{ request.RequestId }}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="section" fxLayout="column" fxLayoutGap="10px">
            <div class="name" title="{{ request.Name }}">{{ request.Name }}</div>
            <div class="mid" fxLayout="row wrap">
              <div class="header" fxFlex="80px">PSID</div>:
              <div class="value" fxFlex="70px">&nbsp;{{ request.MID }}</div>
            </div>
            <div class="joining-date" fxLayout="row wrap">
              <div class="header" fxFlex="80px">Joining Date</div>:
              <div class="value" fxFlex="70px">&nbsp;{{ request.JoiningDate | date: "MM/dd/yyyy" }}</div>
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div class="section course-details" fxLayout="column" fxLayoutGap="10px" fxFlex>
          <div fxLayout="row">
            <div class="header" fxFlex="120px">Course Name</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseName }}" fxFlexOffset="5px">
              <a class="hyperlink" target="_blank"
                href="{{appUrl+'detailsPage/Course/' + request.Expertise + '/' + request.CourseId}}" rel="noreferrer">
                {{ request.CourseName }}
              </a>
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="120px">Course ID</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseId }}" fxFlexOffset="5px">
              {{ request.CourseId }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="120px">Expertise</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.Expertise }}" fxFlexOffset="5px">
              {{ request.Expertise }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="120px">Course Type</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseType }}" fxFlexOffset="5px">
              {{ request.CourseType }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="120px">Academy</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.Academy }}" fxFlexOffset="5px">
              {{ request.Academy }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="120px">Vendor Name</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.VendorName }}" fxFlexOffset="5px">
              {{ request.VendorName }}
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div fxFlex="170px" class="date-details">
          <div class="section certification-date" fxLayout="column" fxLayoutGap="5px">
            <div class="header">Date of Certification</div>
            <div *ngIf="showCertificationDate[request.RequestId]" fxFlex="30px" fxLayoutAlign="start center">
              <div *ngIf="request.Certification_CompletionDate == '0001-01-01T00:00:00'" fxFlex [ngClass]="{ fieldEdited: request.IsCertificationDateChanged }">
                NA
              </div>
              <div *ngIf="request.Certification_CompletionDate != '0001-01-01T00:00:00'" fxFlex [ngClass]="{ fieldEdited: request.IsCertificationDateChanged }">
                {{ request.Certification_CompletionDate | date: "MM/dd/yyyy" }}
              </div>
              <div *ngIf="request.isCourseCleared === false" fxFlex="25px" class="yorbitSprite editSpriteIcon mousePointer"  
              [ngClass]="{mouseDisabled:request.isCourseCleared === false}" [disabled]="request.isCourseCleared === false" title="{{(request.isCourseCleared === false)?'Certification date cannot be edited':'Click to edit Certification date'}}">
              </div>
              <div *ngIf="request.isCourseCleared === true" fxFlex="25px" class="yorbitSprite editSpriteIcon mousePointer"
                (click)="!tileSetup.requestInProgress[request.RequestId]?(showCertificationDate[request.RequestId] = false):''">
              </div>
            </div>
            <div *ngIf="!showCertificationDate[request.RequestId]" fxLayoutGap="10px" fxLayoutAlign="start center">
              <div class="date-picker">
                <div class="date-picker-holder" fxLayout="row" fxLayoutAlign="start center">
                  <mat-form-field class="datepickerForm">
                    <input matInput [matDatepicker]="certificationDatePicker" [min]="" [max]="maxDate" (dateChange)="
                        onDateChangeEvent($event, index, 'certfication-date')
                      " />
                    <mat-datepicker #certificationDatePicker></mat-datepicker>
                  </mat-form-field>
                  
                  <div class="date-value" fxFlex="90px" fxLayoutAlign="start center">
                    {{ request.Certification_CompletionDate | date: "MM/dd/yyyy" }}
                  </div>
                  <mat-divider vertical="true"></mat-divider>
                  <div fxFlex="25px" fxLayoutAlign="center center">
                    <mat-icon class="mousePointer" mat-raised-button (click)="certificationDatePicker.open()">
                      date_range
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div fxFlex="25px" class="yorbitSprite roundedCrossSpriteIcon mousePointer"
                (click)="showCertificationDate[request.RequestId] = true"></div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="section" fxLayout="column" fxLayoutGap="10px">
            <div class="expiry-date" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Expiry Date :</div>
              <div *ngIf="request.Expiry_Dt == '0001-01-01T00:00:00'">
                No Expiry
              </div>
              <div *ngIf="request.Expiry_Dt != '0001-01-01T00:00:00'">
                {{ request.Expiry_Dt | date: "MM/dd/yyyy" }}
              </div>
            </div>
            <div class="upload-date" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Date of Upload :</div>
              <div *ngIf="request.Certification_UploadDate == '0001-01-01T00:00:00'">
                NA
              </div>
              <div *ngIf="request.Certification_UploadDate != '0001-01-01T00:00:00'">
                {{ request.Certification_UploadDate | date: "MM/dd/yyyy" }}
              </div>
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>

        <div class="section attachment-download" fxFlex="170px" fxLayout="column" fxLayoutGap="10px">
          <div class="field-label" *ngIf="selectedTab=='pending'">
            <label class="field-name">Certification Score/Status
              <!-- <sup class="mandatory-field-indicator">*</sup> -->
            </label>

          </div>
          <!-- <div fxLayout="row" fxLayoutAlign="space-between center"> -->
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showCertificationScore[request.RequestId]">
            <!-- <input [(ngModel)]="CertificationScore" class="Score-field"
              placeholder="Score"/>-->
            <div style="padding-bottom: 9px;" fxFlex [ngClass]="{ fieldEdited: request.IsCertificationScoreChanged }">
              <!-- {{ certificateScoreChanged || request.LearnerScore }} -->
              {{ request.LearnerScore && request.LearnerScore != "NULL"  ? (request.LearnerScore) :'NA' }}
            </div>
            <!-- <div *ngIf="request.isCourseCleared === false" [ngClass]="{mouseDisabled:request.isCourseCleared === false}" [disabled]="request.isCourseCleared === false" title="{{(request.isCourseCleared === false)?'Score cannot be edited':'Click to edit score'}}" fxFlex="25px" class="yorbitSprite editSpriteIcon mousePointer">
            </div>
            <div *ngIf="request.isCourseCleared === true" fxFlex="25px" class="yorbitSprite editSpriteIcon mousePointer"
            (click)="editScore(tileSetup.requestInProgress, request.RequestId, index)">
            </div> -->
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!showCertificationScore[request.RequestId]">
            <div>
              <!-- <mat-form-field class="score-change"> -->
                <input [(ngModel)]="request.LearnerScore" matInput value="{{request.LearnerScore}}" class="score-change" style="width:80%"
               >
              <!-- </mat-form-field> -->
            </div>
            <div *ngIf="!showCertificationScore[request.RequestId]" fxFlex="25px"
              class="yorbitSprite roundedCrossSpriteIcon mousePointer" 
              (click)="scoreChange(request.RequestId, index)">
            </div>
          </div>
          <div class="mandatory-indicator" *ngIf="ScoreErrorMsg[request.RequestId] && showCertificationScore[request.RequestId] && request.LearnerScore != ''">
              {{ScoreErrorMsg[request.RequestId]}}
          </div>
          <mat-divider class="full-width-divider"></mat-divider>
          
          <!-- </div> -->
          <div class="header">Attachment</div>
          <div class="download-attachement" fxFlex="50px" fxLayout="column" fxLayoutAlign="start stretch">
            <button [ngClass]="{mouseDisabled:request.filepath === null}" [disabled]="request.filepath === null" title="{{(request.filepath === null)?'No download file available':'Click to download'}}" fxFlex="35px" class="downloadButton mousePointer" aria-label="Reset"
              (click)="downloadCertificate(request.filepath)" fxLayout="row" fxLayoutAlign="center center"
              fxLayoutGap="15px">
              <div>Download</div>
              <div class="mousePointer yorbitSprite downloadSpriteIconWhite "></div>
            </button>
          </div>
          <div class="header">CDP Attachment</div>
          <div class="download-attachement" fxFlex="50px" fxLayout="column" fxLayoutAlign="start stretch">
            <button [ngClass]="{mouseDisabled:request.cdp_filepath === null}" [disabled]="request.cdp_filepath === null" title="{{(request.cdp_filepath === null)?'No download file available':'Click to download'}}" fxFlex="35px" class="downloadButton mousePointer" aria-label="Reset"
              (click)="downloadCertificate(request.cdp_filepath)" fxLayout="row" fxLayoutAlign="center center"
              fxLayoutGap="15px">
              <div>Download</div>
              <div class="mousePointer yorbitSprite downloadSpriteIconWhite "></div>
            </button>
          </div>
        </div>
      </div>
      <div class="request-actions" *ngIf ="request.isCourseCleared == true && request.Certification_CompletionDate!='0001-01-01'" fxLayout="column" fxLayoutGap="15px" fxFlexOffset="20px" fxFlex>
        <div class="CommentsHeader">Comments: </div>
        <textarea fxFlex="140px" placeholder="Write comments" [ngClass]="{
            mouseDisabled:
              tileSetup.requestInProgress[request.RequestId] || disableOtherRequest
          }" [disabled]="
            tileSetup.requestInProgress[request.RequestId] || disableOtherRequest
          " [(ngModel)]="tileSetup.textAreaInput[request.RequestId]"></textarea>
        <div class="action-buttons" fxFlex="30px" fxLayoutGap="15px" F fxLayoutAlign="end end">
          <button
          *ngIf ="request.isCourseCleared == true && request.Certification_CompletionDate!='0001-01-01'"
            class="accept"
            fxFlex="100px"
            [ngClass]="{
              active: tileSetup.acceptButtonTitle[request.RequestId] == 'accepted',
              mouseDisabled:
              (request.IsCertificationDateChanged && (
                tileSetup.textAreaInput[request.RequestId] == null ||
                tileSetup.textAreaInput[request.RequestId] == ''
              ) )|| tileSetup.requestInProgress[request.RequestId] ||
                disableOtherRequest ||
                (tileSetup.acceptButtonTitle[request.RequestId] | lowercase) ==
                  'failed'|| ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
            }"
            title="{{
              (request.IsCertificationDateChanged && (
                tileSetup.textAreaInput[request.RequestId] == null ||
                tileSetup.textAreaInput[request.RequestId] == ''
              ) )
                ? 'Please enter comments to accept the request !'
                : 'Accept This Request'
            }}"
            [disabled]="
            (request.IsCertificationDateChanged && (
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''
            ) )|| tileSetup.requestInProgress[request.RequestId] ||
              disableOtherRequest ||
              (tileSetup.acceptButtonTitle[request.RequestId] | lowercase) ==
                'failed'|| ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
            "
            (click)="accept(request)"
          >
            {{ tileSetup.acceptButtonTitle[request.RequestId] }}
          </button>
          <button
          *ngIf ="request.isCourseCleared == true && request.Certification_CompletionDate!='0001-01-01'"
            class="deny"
            fxFlex="100px"
            [ngClass]="{
              active: tileSetup.denyButtonTitle[request.RequestId] == 'denied',
              mouseDisabled:
                tileSetup.textAreaInput[request.RequestId] == null ||
                tileSetup.textAreaInput[request.RequestId] == ''||
                tileSetup.requestInProgress[request.RequestId] ||
                disableOtherRequest ||
                (tileSetup.denyButtonTitle[request.RequestId] | lowercase) ==
                  'failed'|| ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
            }"
            title="{{
              (
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''
             )
                ? 'Please enter comments to send back the request !'
                : 'Send back to learner to re-upload'
            }}"
            [disabled]="
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''||
              tileSetup.requestInProgress[request.RequestId] ||
              disableOtherRequest ||
              (tileSetup.denyButtonTitle[request.RequestId] | lowercase) == 'failed'|| ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
            "
            (click)="deny(request)"
          >
            {{ tileSetup.denyButtonTitle[request.RequestId] }}
          </button>
          <button
          class="deny"
          fxFlex="100px"
          [ngClass]="{
            active: tileSetup.failButtonTitle[request.RequestId] == 'not cleared',
            mouseDisabled:
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''||
              tileSetup.requestInProgress[request.RequestId] ||
              disableOtherRequest ||
              (tileSetup.failButtonTitle[request.RequestId] | lowercase) ==
                'failed'|| ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
          }"
          title="{{
            (
            tileSetup.textAreaInput[request.RequestId] == null ||
            tileSetup.textAreaInput[request.RequestId] == ''
           )
              ? 'Please enter comments to set the request to not cleared !'
              : 'Enable Retake '
          }}" 
          [disabled]="
            tileSetup.textAreaInput[request.RequestId] == null ||
            tileSetup.textAreaInput[request.RequestId] == ''||
            tileSetup.requestInProgress[request.RequestId] ||
            disableOtherRequest ||
            (tileSetup.failButtonTitle[request.RequestId] | lowercase) == 'failed'|| ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
          "
          (click)="fail(request)"
        >
          {{ tileSetup.failButtonTitle[request.RequestId] }}
        </button>
        </div>
      </div>
    </div>
    <div *ngIf="selectedTab=='actioned'" fxFlex="100%">
      <div class="request-index" fxFlex="20px">
        {{ contentIndex+index+1 }}.
      </div>
      <div class="request-details" fxFlex>
        <div fxFlex="180px" fxLayout="column" class="user-profile" fxLayoutAlign="start stretch">
          <div class="section request-id" fxLayout="row wrap">
            <div class="header" fxFlex="80px">Request ID</div>
            :
            <div class="value" fxFlex="70px">&nbsp;{{ request.RequestId }}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="section" fxLayout="column" fxLayoutGap="10px">
            <div class="name" title="{{ request.Name }}">{{ request.Name }}</div>
            <div class="mid" fxLayout="row wrap">
              <div class="header" fxFlex="80px">PSID</div>:
              <div class="value" fxFlex="70px">&nbsp;{{ request.MID }}</div>
            </div>
            <div class="joining-date" fxLayout="row wrap">
              <div class="header" fxFlex="80px">Joining Date</div>:
              <div class="value" fxFlex="70px">&nbsp;{{ request.JoiningDate | date: "MM/dd/yyyy" }}</div>
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div class="section course-details" fxLayout="column" fxLayoutGap="10px" fxFlex="250px">
          <div fxLayout="row">
            <div class="header" fxFlex="90px">Course Name</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseName }}" fxFlexOffset="5px">
              <a (click)="gotoCourseDetailpage(request)" class="hyperlink">
                {{ request.CourseName }}
              </a>
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="90px">Course ID</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseId }}" fxFlexOffset="5px">
              {{ request.CourseId }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="90px">Expertise</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.Expertise }}"fxFlexOffset="5px">
              {{ request.Expertise }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="90px">Course Type</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseType }}" fxFlexOffset="5px">
              {{ request.CourseType }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="90px">Academy</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.Academy }}" fxFlexOffset="5px">
              {{ request.Academy }}
            </div>
          </div>
          <div fxLayout="row">
            <div class="header" fxFlex="90px">Vendor Name</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.VendorName }}" fxFlexOffset="5px">
              {{ request.VendorName }}
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div fxFlex="170px" class="date-details">
          <div class="section certification-date" fxLayout="column" fxLayoutGap="5px">
            <div class="header">Date of Certification :</div>
            <div class="value" fxFlex="30px" fxLayoutAlign="start center">
              {{ request.Certification_CompletionDate | date: "MM/dd/yyyy" }}
            </div>          
            <div class="expiry-date" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Expiry Date :</div>
              <div *ngIf="request.Expiry_Dt == '0001-01-01T00:00:00'">
                No Expiry
              </div>
              <div *ngIf="request.Expiry_Dt != '0001-01-01T00:00:00'">
                {{ request.Expiry_Dt | date: "MM/dd/yyyy" }}
              </div>
            </div>
            <div class="upload-date" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Date of Upload :</div>
              <div>
                {{ request.Certification_UploadDate | date: "MM/dd/yyyy" }}
              </div>
            </div>
            <div class="actioned-date" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Date of Action :</div>
              <div>
                {{ request.Certificate_ActionDate | date: "MM/dd/yyyy" }}
              </div>
            </div>
            <div class="PSS-Score" fxLayout="column" fxLayoutGap="5px">
              <div class="header">Certification Score/Status :</div>
              <div>
                {{ request.LearnerScore === null ? 'NA' : request.LearnerScore}}
              </div>
            </div>
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div class="section remarks" fxFlex fxLayout="column"  fxLayoutGap="10px">
          <div class="header">Comments :</div>
          <div class="comment-box">
            {{ request.Remarks == null || request.Remarks ==''? 'No comments' : request.Remarks}}
          </div>
        </div>
        <mat-divider vertical="true"></mat-divider>
        <div class="section status-and-substatus" fxFlex fxLayout="column" fxLayoutAlign="start stretch"
          fxLayoutGap="10px">
          <div class="status">
            {{ request.certificatestatus }}
          </div>
          <div class="substatus">
            {{ request.SubStatus }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--preloader section-->
  <div *ngIf="showPreloader" fxFlexFill fxLayoutAlign="center center">
    <app-preloader></app-preloader>
  </div>
  <!--No data info section-->
  <div *ngIf="!showPreloader && requests.length==0 && filtersApplied" class="noRequestsInfo" fxFlexFill
    fxLayoutAlign="center center">
    No requests available.
  </div>
</div>