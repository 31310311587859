import { Injectable } from '@angular/core';
import { Globals } from '../../globals';

const globals = new Globals();
@Injectable()
export class CourseList {
  list = [
    {
      Vendor: 'Pluralsight',
      Course: 'Managing Virtual Teams',
      Link: 'https://www.pluralsight.com/courses/managing-virtual-teams',
      exp: ''
    },
    {
      Vendor: 'Pluralsight',
      Course: 'Make Work From Home Work For You',
      Link: 'https://www.pluralsight.com/courses/make-work-from-home-work',
      exp: ''
    },
    {
      Vendor: 'Yorbit',
      Course: 'Working with Virtual Teams',
      Link:  globals.yorbitUrl + 'detailsPage/Course/101/3685',
      exp: '101'
    },
    {
      Vendor: 'Yorbit',
      Course: 'Working From Home',
      Link: globals.yorbitUrl + 'detailsPage/Course/101/3684',
      exp: '101'
    },
    {
      Vendor: 'Yorbit',
      Course: 'Working with Distributed Teams',
      Link: globals.yorbitUrl + 'detailsPage/Course/101/854',
      exp: '101'
    },
    {
      Vendor: 'Yorbit',
      Course: 'Managing Time And Results',
      Link: globals.yorbitUrl + 'detailsPage/Course/101/853',
      exp: '101'
    },   
    {
      Vendor: 'Yorbit',
      Course: 'Collaboration for Agile',
      Link: globals.yorbitUrl + 'detailsPage/Course/101/257',
      exp: '101'
    },
    {
      Vendor: 'Yorbit',
      Course: 'Self-Leadership',
      Link: globals.yorbitUrl + 'detailsPage/Course/101/3234',
      exp: '101'
    },
    {
      Vendor: 'Yorbit',
      Course: 'Learning How to Learn',
      Link: globals.yorbitUrl + 'detailsPage/Course/201/2658',
      exp: '201'
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Working Remotely',
      Link:
        'https://www.linkedin.com/learning/working-remotely-2/the-value-of-working-remotely',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Time Management: Working From Home',
      Link:
        'https://www.linkedin.com/learning/time-management-working-from-home',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Productivity Tips: Finding Your Productive Mindset',
      Link:
        'https://www.linkedin.com/learning/productivity-tips-finding-your-productive-mindset',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Leading at a Distance',
      Link: 'https://www.linkedin.com/learning/leading-at-a-distance',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course:
        'Thriving @ Work: the Connection between Well-being and Productivity',
      Link:
        'https://www.linkedin.com/learning/thriving-work-leveraging-the-connection-between-well-being-and-productivity',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Building Resilience',
      Link: 'https://www.linkedin.com/learning/building-resilience',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Developing Resourcefulness',
      Link: 'https://www.linkedin.com/learning/developing-resourcefulness',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Leading Virtual Meetings',
      Link:
        'https://www.linkedin.com/learning/leading-virtual-meetings/welcome-to-better-virtual-meetings?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Executive Presence on Video Conference Calls',
      Link:
        'https://www.linkedin.com/learning/executive-presence-on-video-conference-calls/your-video-conference-presence?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Managing Stress for Positive Change',
      Link:
        'https://www.linkedin.com/learning/managing-stress-for-positive-change/welcome?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Managing Virtual Teams',
      Link:
        'https://www.linkedin.com/learning/managing-virtual-teams-4/managing-people-at-a-distance?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Learning Zoom',
      Link:
        'https://www.linkedin.com/learning/learning-zoom/stay-connected-with-zoom-meetings?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Learning BlueJeans',
      Link:
        'https://www.linkedin.com/learning/learning-bluejeans-meetings/welcome?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Learning Webex',
      Link:
        'https://www.linkedin.com/learning/learning-webex/welcome?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Learning Skype',
      Link:
        'https://www.linkedin.com/learning/learning-skype-2/get-up-to-speed-quickly-with-skype?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    },
    {
      Vendor: 'LinkedIn',
      Course: 'Microsoft Teams Tips & Tricks',
      Link:
        'https://www.linkedin.com/learning/microsoft-teams-tips-and-tricks/welcome?trk=lilblog_03-11-20_new-to-working-remotely-resources_learning',
      exp: ''
    }
  ];
}
