<div class="startDatePicker floatLeft">
    <mat-form-field class="datepickerForm floatLeft">
      <input
        matInput
        [matDatepicker]="startDatePicker"
        placeholder="From"
        [min]="minDate"
        [max]="endDate.value"
        (dateChange)="onDateChangeEvent($event, 'start')"
        [formControl]="startDateForm"
      />
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <div class="dateMonthBlock floatLeft">
      <div class="font12">From</div>
      <input class="dateBlock" value="{{ startDate.date }}" readonly="true" />
      <input class="monthBlock" value="{{ startDate.month }}" readonly="true" />
    </div>
    <div class="calendarIconBlock floatLeft">
      <mat-icon
        class="mousePointer"
        mat-raised-button
        (click)="startDatePicker.open()"
      >
      date_range
      </mat-icon>
    </div>
  </div>

  <div class="dateRange floatLeft">
    <div class="leftArrow floatLeft"></div>
    <div class="rightArrow floatLeft"></div>
  </div>

  <div class="endDatePicker floatLeft">
    <mat-form-field class="datepickerForm floatLeft">
      <input
        matInput
        [matDatepicker]="endDatePicker"
        placeholder="To"
        [min]="startDate.value"
        [max]="maxDate"
        (dateChange)="onDateChangeEvent($event, 'end')"
        [formControl]="endDateForm"
      />
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
    <div class="dateMonthBlock floatLeft">
      <div class="font12">To</div>
      <input class="dateBlock" value="{{ endDate.date }}" readonly="true" />
      <input class="monthBlock" value="{{ endDate.month }}" readonly="true" />
    </div>
    <div class="calendarIconBlock floatLeft">
      <mat-icon
        class="mousePointer"
        mat-raised-button
        (click)="endDatePicker.open()"
      >
      date_range
      </mat-icon>
    </div>
  </div>

  <button
    class="dateGoButton"
    mat-raised-button
    aria-label="Go"
    (click)="filterDateChange()"
  >
    Go
  </button>
