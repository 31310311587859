import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RiskBrigadeService {
  config: Ienvironment;
  videosList: any=[];
  speakerList:any=[];
  coreCouncil:any=[];
  foundationCourses:any=[];
  AdvanceCourses:any=[];

  constructor(private http:HttpClient, private _envSvc: EnvironmentService) { 
    this.config=_envSvc.getEnvironment();
  }

  getVideos(){
    let promise = new Promise((resolve, reject) => {
      if (this.videosList.length === 0) {
        this.http
          .get(this.config.apiUrl + 'RiskBrigade/GetVideos')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.videosList = list;
            } else {
              this.videosList = [];
            }
            resolve(this.videosList);
          });
      } else {
        resolve(this.videosList);
      }
    });
    return promise;
  }

  getSpeaker(){
    let promise = new Promise((resolve, reject) => {
      if (this.speakerList.length === 0) {
        this.http
          .get(this.config.apiUrl + 'RiskBrigade/GetSpeakerDetails')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.speakerList = list;
            } else {
              this.speakerList = [];
            }
            resolve(this.speakerList);
          });
      } else {
        resolve(this.speakerList);
      }
    });
    return promise;
  }

  getCoreCouncil(){
    let promise = new Promise((resolve, reject) => {
      if (this.coreCouncil.length === 0) {
        this.http
          .get(this.config.apiUrl + 'RiskBrigade/GetCoreCouncilMembers')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.coreCouncil = list;
            } else {
              this.coreCouncil = [];
            }
            resolve(this.coreCouncil);
          });
      } else {
        resolve(this.coreCouncil);
      }
    });
    return promise;
  }

  getKnowledgehub(pageNumber=1,filterType='all'){
    var request={
      "pageNumber": pageNumber,
      "Filter": filterType
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let promise = new Promise((resolve, reject) => {
        this.http
          .post(this.config.apiUrl + 'RiskBrigade/GetKnowledgeHubData?numberOfRecords',JSON.stringify(request),{headers: headers})
          .toPromise()
          .then(data => {
            resolve(data);
          });
    });
    return promise;
  }

  getLearnData(CourseType='foundation',Expertise='all',pageNumber=1){
    var request={
      "CourseType": CourseType,
      "Expertise": Expertise,
      "pageNumber":pageNumber
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let promise = new Promise((resolve, reject) => {
        this.http
          .post(this.config.apiUrl + 'RiskBrigade/GetLearnPageDetails',JSON.stringify(request),{headers: headers})
          .toPromise()
          .then(data => {
            resolve(data);
          });
    });
    return promise;
  }


  getProgressData(CourseType='foundation',Expertise='all',pageNumber=1){
    var request={
      "CourseType": CourseType,
      "Expertise": Expertise,
      "pageNumber":pageNumber
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let promise = new Promise((resolve, reject) => {
        this.http
          .post(this.config.apiUrl + '/RiskBrigade/GetProgressDetails',JSON.stringify(request),{headers: headers})
          .toPromise()
          .then(data => {
            resolve(data);
          });
    });
    return promise;
  }

  
  CheckUserEligibilityForRiskBrigade() {
    return this.http.get(this.config.apiUrl + 'RiskBrigade/CheckUserEligibility').toPromise();
  }
}
