import {
  NgModule,
 // NgModuleFactoryLoader,
  //SystemJsNgModuleLoader,
  InjectionToken,
  ModuleWithProviders,
  ANALYZE_FOR_ENTRY_COMPONENTS,
  Type
} from '@angular/core';
import { ROUTES } from '@angular/router';
// service for locating and serving entry components
import { DynamicComponentLoaderService } from './dynamic-component-loader.service';
//dynamic component manifests
import {
  DYNAMIC_COMPONENT,
  DYNAMIC_COMPONENT_MANIFESTS,
  DYNAMIC_MODULE,
  DynamicComponentManifest
} from './dynamic-module-loader-manifest.interface';
//dynamic component manifests
import { Manifests } from './dynamic-module-loader-manifest.model';
import { FlexLayoutModule } from '@angular/flex-layout';
@NgModule({
  imports:[FlexLayoutModule]
})
export class DynamicComponentLoaderModule {
  static forRoot(): ModuleWithProviders<DynamicComponentLoaderModule> {
    return {
      ngModule: DynamicComponentLoaderModule,
      providers: [
        DynamicComponentLoaderService,
       // { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
        { provide: ROUTES, useValue: Manifests, multi: true },
        { provide: DYNAMIC_COMPONENT_MANIFESTS, useValue: Manifests }
      ]
    };
  }
  static forModule(manifest: DynamicComponentManifest): ModuleWithProviders<DynamicComponentLoaderModule> {
    return {
      ngModule: DynamicComponentLoaderModule,
      providers: [
        {
          provide: ROUTES,
          useValue: manifest,
          multi: true
        },
        // provider for @angular/router to parse
        { provide: ROUTES, useValue: manifest, multi: true },
        // provider for DynamicComponentLoader to analyze
        { provide: DYNAMIC_MODULE, useValue: manifest }
      ]
    };
  }
  static forChild(component: Type<any>): ModuleWithProviders<DynamicComponentLoaderModule> {
    return {
      ngModule: DynamicComponentLoaderModule,
      providers: [
        {
          provide: ROUTES,
          useValue: component,
          multi: true
        },
        // provider for @angular/router to parse
        { provide: ROUTES, useValue: [], multi: true },
        // provider for DynamicComponentLoader to analyze
        { provide: DYNAMIC_COMPONENT, useValue: component }
      ]
    };
  }
}
export {
  DynamicComponentManifest
} from './dynamic-module-loader-manifest.interface';
