import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TopFacilitatorsComponent } from './top-facilitators/top-facilitators.component';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';



@NgModule({
  declarations: [TopFacilitatorsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReusableUiModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TopFacilitatorsComponent]
})
export class TopFacilitatorsModule { }
