import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import {
  CheckboxList,
  CourseAcademy,
  YorbitCourseRequestList,
  InputParameters,
  EmailAlertList,
  PublishResult,
  UpdateScoreDetails,
  EvaluatorDetails,
  PssLog
} from './pss-evaluation.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PssEvaluationService {
  config: Ienvironment;
  courseAcademyList: CheckboxList[] = [];
  checkedAcademyList: string[] = [];
  checkedCourseList: string[] = [];
  checkedCourseTypeList: string[] = [];
  checkedCourseVendorList: string[] = [];
  checkedEvaluatorVendorList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedCourseAcademy: string[];
  selectedDataList: YorbitCourseRequestList[] = [];
  filteredDataList: YorbitCourseRequestList[] = [];
  changedItem: YorbitCourseRequestList[] = [];
  dataList: YorbitCourseRequestList[] = [];
  isScoreCardChanged: boolean = false;
  isErrorFileChanged: boolean = false;
  isScoreChanged: boolean = false;
  inputObject: InputParameters = new InputParameters();

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }

  getSelectedList(option: string) {
    const Url = this.config.apiUrl + 'PSS/GetSelectedList';
    return this.http.get(Url, { params: { selectedOption: option } });
  }

  getFilteredData(limit: number, count: number, nextOrPrevious: string) {
    ////console.log('InputObject', this.inputObject);
    const Url =
      this.config.apiUrl +
      'PSS/EvaluationRequest/' +
      limit +
      '/' +
      count +
      '/' +
      nextOrPrevious;
    ////console.log('URL', Url);
    return this.http.post(`${Url}`, this.inputObject);
  }

  getAllDataForDownloading() {
    const Url = this.config.apiUrl + 'PSS/DownloadAllEvaluationRequests';
    return this.http.get(Url);
  }

  rejectEvaluation(evaluationRequestId: number, requestType: string) {
    const Url =
      this.config.apiUrl +
      'PSS/RejectEvaluation/' +
      evaluationRequestId +
      '/' +
      requestType;
    return this.http.get(`${Url}`);
  }

  getFilteredDataForDownloading() {
    const Url = this.config.apiUrl + 'PSS/DownloadFilteredRequests';
    return this.http.post(`${Url}`, this.inputObject);
  }

  getFilterRecords() {
    const Url = this.config.apiUrl + 'PSS/GetFilterRecords';
    return this.http.get(Url);
  }

  sendIndividualSLAAlert(emailAlert: any) {
    const Url = this.config.apiUrl + 'PSS/SendIndividualSLAAlertEmail';
    return this.http.post(Url, emailAlert);
  }

  sendSelectedSLAALert(emailAlertList: EmailAlertList[]) {
    const Url = this.config.apiUrl + 'PSS/SendSelectedSLAAlertEmail';
    return this.http.post(Url, emailAlertList);
  }

  publishIndividualResult(publishResult: PublishResult) {
    const Url = this.config.apiUrl + 'PSS/PublishIndividualResult';
    return this.http.post(Url, publishResult);
  }

  publishSelectedResult(publishResultList: PublishResult[]) {
    const Url = this.config.apiUrl + 'PSS/PublishSelectedResult';
    return this.http.post(Url, publishResultList);
  }

  updateScore(newScoreDetais: any) {
    return this.http.post(
      this.config.apiUrl + 'PSS/UpdateScore',
      newScoreDetais
    );
  }

  updatePssLog(pssLog: PssLog) {
    return this.http.post(this.config.apiUrl + 'PSS/UpdatePSSLog', pssLog);
  }

  downloadLogData(requestId: number) {
    return this.http.get(
      this.config.apiUrl + 'PSS/DownloadLogData/' + requestId
    );
  }

  updateScoreForSelectedRequests(scoreDetailsList: UpdateScoreDetails[]) {
    const Url = this.config.apiUrl + 'PSS/UpdateScoreForSelectedRequests';
    return this.http.post(Url, scoreDetailsList);
  }

  uploadScoreCardToBlob(formData, evaluationRequestId, evaluationType) {
    return this.http.post(
      this.config.apiUrl +
        'PSS/EvaluationRequest/ScoreCardUpload/' +
        evaluationRequestId +
        '/' +
        evaluationType,
      formData
    );
  }

  uploadErrorFileToBlob(formData, evaluationRequestId, evaluationType) {
    return this.http.post(
      this.config.apiUrl +
        'PSS/EvaluationRequest/ErrorFileUpload/' +
        evaluationRequestId +
        '/' +
        evaluationType,
      formData
    );
  }

  getEvaluatorListForRemap(evalRequestType: string, courseOrSessionId: string) {
    const Url = this.config.apiUrl + 'PSS/GetEvaluatorListForRemap';
    return this.http.get(Url, {
      params: {
        evalRequestType: evalRequestType,
        courseOrSessionId: courseOrSessionId
      }
    });
  }

  updateEvalauator(updateData: any) {
    let postData = new FormData();
    const Url = this.config.apiUrl + 'PSS/UpdateEvaluator';
    return this.http.post(Url, updateData);
  }

  getProblemStatement(courseId) {
    return this.http
      .get(this.config.apiUrl + 'PSS/ProblemStatementDownload/' + courseId)
      .toPromise();
  }

  getEvaluationTemplate(courseId) {
    return this.http
      .get(this.config.apiUrl + 'PSS/EvaluationTemplateDownload/' + courseId)
      .toPromise();
  }
  getLearnerProjectSolutionFile(mid, courseUniqueId) {
    ////console.log('service call');
    return this.http.get(
      this.config.apiUrl +
        'User/ProjectSolutionFileDownload/' +
        mid +
        '/' +
        courseUniqueId
    );
  }

  getLearnerAssignmentSolutionFile(requestId, assignmentSessionId) {
    return this.http.get(
      this.config.apiUrl +
        'User/AssignmentSolutionFileDownload/' +
        requestId +
        '/' +
        assignmentSessionId
    );
  }

  getLearnerScorecard(mid, projectOrSessionId, requestId) {
    return this.http.get(
      this.config.apiUrl +
        'User/ProjectOrAssignmentScorecardDownload/' +
        mid +
        '/' +
        projectOrSessionId +
        '/' +
        requestId
    );
  }
  getLearnerErrorFile(requestId, evaluationType) {
    return this.http.get(
      this.config.apiUrl +
        'User/ProjectOrAssignmentErrorFileDownload/' +
        evaluationType +
        '/' +
        requestId
    );
  }

  getDataList(dataList: YorbitCourseRequestList[], selectedType: string) {
    this.dataList = dataList;
    if (this.dataList.length > 0) this.filterRecords(selectedType);
  }

  filterRecords(selectedType: string) {
    ////console.log('Service2', this.dataList);
    //Filtereing based on Academy
    if (selectedType != 'Academy') {
      if (this.checkedAcademyList.length > 0) {
        this.filteredDataList = [];
        for (let i = 0; i < this.checkedAcademyList.length; i++) {
          this.changedItem = this.dataList.filter(
            item => item.Academy == this.checkedAcademyList[i]
          );
          this.changedItem.forEach(element => {
            if (this.filteredDataList.indexOf(element) == -1)
              this.filteredDataList.push(element);
          });
        }
      }
    }
    ////console.log('ServiceCL', this.filteredDataList);

    //Filtering based on Course Name
    if (selectedType != 'CourseName') {
      if (this.checkedCourseList.length > 0) {
        this.filteredDataList = [];
        for (let i = 0; i < this.checkedCourseList.length; i++) {
          this.changedItem = this.dataList.filter(
            item => item.CourseName == this.checkedCourseList[i]
          );
          this.changedItem.forEach(element => {
            if (this.filteredDataList.indexOf(element) == -1)
              this.filteredDataList.push(element);
          });
        }
      }
    }

    //Filtering based on Course Type
    if (selectedType != 'CourseType') {
      if (this.checkedCourseTypeList.length > 0) {
        this.filteredDataList = [];
        for (let i = 0; i < this.checkedCourseTypeList.length; i++) {
          this.changedItem = this.dataList.filter(
            item => item.CourseType == this.checkedCourseTypeList[i]
          );
          this.changedItem.forEach(element => {
            if (this.filteredDataList.indexOf(element) == -1)
              this.filteredDataList.push(element);
          });
        }
      }
    }

    //Filtering based on Course Vendor
    if (selectedType != 'CourseVendor') {
      if (this.checkedCourseVendorList.length > 0) {
        this.filteredDataList = [];
        for (let i = 0; i < this.checkedCourseVendorList.length; i++) {
          this.changedItem = this.dataList.filter(
            item => item.CourseVendor == this.checkedCourseVendorList[i]
          );
          this.changedItem.forEach(element => {
            if (this.filteredDataList.indexOf(element) == -1)
              this.filteredDataList.push(element);
          });
        }
      }
    }

    //Filtering based on Sub Status
    if (selectedType != 'SubStatus') {
      if (this.checkedSubStatusList.length > 0) {
        this.filteredDataList = [];
        for (let i = 0; i < this.checkedSubStatusList.length; i++) {
          this.changedItem = this.dataList.filter(
            item => item.CertificationSubStatus == this.checkedSubStatusList[i]
          );
          this.changedItem.forEach(element => {
            if (this.filteredDataList.indexOf(element) == -1)
              this.filteredDataList.push(element);
          });
        }
      }
    }

    //Filtering based on Learning OPM SPOC
    if (selectedType != 'LearningOPMSPOC') {
      if (this.checkedLearningOPMSPOCList.length > 0) {
        this.filteredDataList = [];
        for (let i = 0; i < this.checkedLearningOPMSPOCList.length; i++) {
          this.changedItem = this.dataList.filter(
            item => item.LearningOPMSPOC == this.checkedLearningOPMSPOCList[i]
          );
          this.changedItem.forEach(element => {
            if (this.filteredDataList.indexOf(element) == -1)
              this.filteredDataList.push(element);
          });
        }
      }
    }

    //Filtering based on EvaluatorVendor
    if (selectedType != 'EvaluatorVendor') {
      if (this.checkedEvaluatorVendorList.length > 0) {
        this.filteredDataList = [];
        for (let i = 0; i < this.checkedEvaluatorVendorList.length; i++) {
          this.changedItem = this.dataList.filter(
            item => item.EvaluatorVendor == this.checkedEvaluatorVendorList[i]
          );
          this.changedItem.forEach(element => {
            if (this.filteredDataList.indexOf(element) == -1)
              this.filteredDataList.push(element);
          });
        }
      }
    }

    return this.filteredDataList;
  }
}
