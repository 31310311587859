import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'yorbit-data-template-dialog',
  templateUrl: './data-template-dialog.component.html',
  styleUrls: ['./data-template-dialog.component.scss']
})
export class DataTemplateDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DataTemplateDialogComponent>
  ) {
  }

  ngOnInit() {}
  closeDialog() {
    this.dialogRef.close();
  }
}
