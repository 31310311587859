import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from '../../../globals';
import { PopupService } from '../popup.service';

@Component({
  selector: 'yorbit-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {
  minutes: number;
  seconds: number;
  timer: any;
  sessionActive = true;
  message = null;
  tokenExpiresIn: number;
  offsetTimeoutForIdTokenRefresh = 5;
  constructor(
    private dialogRef: MatDialogRef<SessionTimeoutComponent>,
    @Inject(MAT_DIALOG_DATA) public popupData: any,
    public globals: Globals,
    private popupService: PopupService
  ) { }

  ngOnInit() {
    this.popupService.updateSessionTimeoutPopupStatus(true);
    this.offsetTimeoutForIdTokenRefresh = this.globals.offsetTimeoutForIdTokenRefresh;
    this.sessionActive = true;
    this.tokenExpiresIn = this.popupData.tokenExpiresIn;
    let currentTime = Math.floor(new Date().getTime() / 1000.0);
    //this.globals.offsetTimeoutForIdTokenRefresh - 5 is added in the below line for testing purposes
    //to test the session timeout popup feature in less time with out waiting for the whole session to complete
    //this.globals.offsetTimeoutForIdTokenRefresh is set to any range between 50 to 5 
    //in order to show timer accordingly wrt to this.globals.offsetTimeoutForIdTokenRefresh, this.globals.offsetTimeoutForIdTokenRefresh - 5 has been added
    let time = this.tokenExpiresIn - currentTime - 1 * 60 - (this.globals.offsetTimeoutForIdTokenRefresh - 5) * 60;
    this.minutes = Math.floor(time / 60);
    this.seconds = time - this.minutes * 60;
    if (this.popupData.page == 'quiz' && !this.popupData.canExitQuiz) {
      if (this.popupData.hasAttemptsRestriction) {
        this.message =
          'LOG OUT will lead to increment in attempts. Choose CONTINUE QUIZ to stay logged in and complete quiz in this attempt.';
      } else {
        this.message =
          'LOG OUT will lead to loss of your quiz data. Choose CONTINUE QUIZ to stay logged in and complete quiz.';
      }
    } else {
      this.message = null;
    };
    this.timer = setInterval(() => {
      let currentTime = Math.floor(new Date().getTime() / 1000.0);
      let time = this.tokenExpiresIn - currentTime - 1 * 60 - (this.globals.offsetTimeoutForIdTokenRefresh - 5) * 60;

      this.minutes = Math.floor(time / 60);
      this.seconds = time - this.minutes * 60;
      //////console.log(this.minutes, this.seconds);
      if ((this.minutes == 0 && this.seconds == 0) || this.minutes < 0) {
        clearInterval(this.timer);
        this.sessionActive = false;
        if (this.popupData.page == 'quiz') {
          if (!this.popupData.canExitQuiz) {
            this.message =
              'Quiz data is lost. Please LOG IN to re-attempt quiz';
          } else {
            this.message = null;
          }
        }
      } else if (
        this.minutes == 0 &&
        this.seconds < 15
      ) {
        //} else if (this.minutes == 0 && this.seconds < 15) {
        this.closeDialog('keeplearning');
      } else {
        if (this.seconds == 0) {
          if (this.minutes != 0) {
            this.minutes--;
          }
          this.seconds = 59;
        } else {
          this.seconds--;
        }
      }
    }, 1000);
  }
  closeDialog(data) {
    this.popupService.updateSessionTimeoutPopupStatus(false);
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.dialogRef.close(data);
  }
  ngOnDestroy() {
    ////console.log('destroy');
    this.popupService.updateSessionTimeoutPopupStatus(false);
  }
}
