import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class YammerService {
  config: Ienvironment;

  constructor(private http: HttpClient,
    private _envSvc: EnvironmentService) {
      this.config = this._envSvc.getEnvironment();
    }

  getYammerFeederID(
    apiUrlYammer,
    accountID,
    projectID,
    accountPackage,
    projectPackage
  ) {
    if (accountID != null || projectID != null) {
      let Url = null;
      if (accountPackage) {
        Url = apiUrlYammer + 'Social/YammerURL/' + accountID + '/' + true;
      } else if (projectPackage) {
        Url = apiUrlYammer + 'Social/YammerURL/' + projectID + '/' + false;
      }
      return this.http.get(Url).toPromise();
    } else {
      const promise = new Promise(resolve => {
        //const response = '7457097';
        const response = this.config.yammerFeedId;
        resolve(response);
      });
      return promise;
    }
  }
}
