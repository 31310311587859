<div class="problem-statement-download" fxFlex fxLayoutAlign="end center">
  
  <div class="yorbitSprite icon" title="{{!isProblemStatementAvailable?'Problem statement link is not availalbe':'Click here to download the problem statement'}}"
      (click)="downloadProblemStatement()"></div>

      <div fxLayoutAlign="end center">
        <div fxFlexOffset="-20px">{{downloadStatus}}</div>
    </div>

      <div *ngIf="showPreloader">
        <app-preloader></app-preloader>
</div>
<div *ngIf="!contentLoadedSuccessfully && !showPreloader">
    Technical issue in loading content. Please try again
</div>
