import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromGraphStore from '@YorbitWorkspace/graph';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { Store } from '@ngrx/store';
import * as fromMyPageStore from './store';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '../shared/services/window.service';
import { Globals } from '../globals';
import { HomeService } from '../home/home.service';
import {MyPageService} from './my-page.service';
@Component({
  selector: 'yorbit-my-page',
  templateUrl: './my-page.component.html',
  styleUrls: ['./my-page.component.scss']
})
export class MyPageComponent implements OnInit {
  graphUserName: string;
  graphUserImage: Blob;
  graphUserJobTitle: string;
  role: string;
  MId: string;
  tab: string;
  tabs: boolean[];
  hover: boolean[];

  profileImgStyle: any;
  events: string[] = [];
  opened: boolean;
  imageUrl: any;
  imageLoaded: boolean;

  complianceStatus: string;
  isCompliant: boolean;

  personalisedRecommendations: Array<any>;

  constructor(
    private graphStore: Store<fromGraphStore.IGraphReducerState>,
    private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    @Inject(WINDOW) private _window: any,
    private route: ActivatedRoute,
    private _graphSvc: GraphDataService,
    private sanitizer: DomSanitizer,
    private globals: Globals,
    private homeService: HomeService,
    private _router: Router,
    private _myPageSvc : MyPageService
  ) {}

  ngOnInit() {
    this.personalisedRecommendations = null;
    this.route.params.subscribe(params => {
      this.opened = false;
      this.role = params.role;
      this.MId = params.id;
      this.tab = params.tab;
      this.tabs = Array(6);
      this.hover = Array(6);
      this.tabs[0] = true;
      this.imageLoaded = false;
      // if (this.MId.length == 8) this.getGraphDetails();
      // if (this.MId.length == 8 && this.role === 'self') {
      //   if (
      //     this.MId.length == 8 &&
      //     this.globals.MId.length == 8 &&
      //     this.MId !== this.globals.MId
      //   ) {
      //     this._router.navigate(['/my-page/', this.globals.MId, 'self']);
      //   } else {
      if (true || this.MId.length == 8) this.getGraphDetails();
      if (this.role === 'self') {
        if (         
          this.MId !== this.globals.EmpId
        ) {
          this._router.navigate(['/my-page/', this.globals.EmpId, 'self']);
        } else {
          this.getLearningHistory();
          this.getPrimarySkills();
          this.getSkillProfile();
          this.getPersonalisedRecommendations();
        }
      }
      this.getCompliance();
    });

    if (this.globals.isSkillProfileClicked) {
      this.setTab(2);
      this.globals.isSkillProfileClicked = false;
    }
    if (this.globals.isRecommendationsClicked) {
      this.setTab(3);
      this.globals.isRecommendationsClicked = false;
    }
    if (this.globals.isPersonalisedRecommendationsClicked) {
      this.setTab(4);
      this.globals.isPersonalisedRecommendationsClicked = false;
    }
  }

  getGraphDetails() {
    this._myPageSvc.graphData.MId = this.MId;
    if (this.role === 'self') {
      this.graphStore.select(fromGraphStore.getGraphUserName).subscribe(res => {
        this.graphUserName = res;
        this._myPageSvc.graphData.displayName = res;
      });
      this.graphStore
        .select(fromGraphStore.getGraphUserImage)
        .subscribe(res => {
          this.graphUserImage = res;
          this.imageUrl = this.createImageURL(this.graphUserImage);
          if(res.size == 0)
            this.imageLoaded = false;
          else
          this.imageLoaded = true;
        } ,
        error => {
          this.imageLoaded = false;
        });
      this.graphStore
        .select(fromGraphStore.getGraphUserJobTitle)
        .subscribe(res => {
          this.graphUserJobTitle = res;
          this._myPageSvc.graphData.jobTitle = res;
        });
    } else if (this.role === 'learner') {
      this._graphSvc.getUserImage(this.MId).subscribe(
        data => {
          this.graphUserImage = data;
          this.imageUrl = this.createImageURL(this.graphUserImage);
          if(data.size == 0)
            this.imageLoaded = false;
          else
          this.imageLoaded = true;
        },
        error => {
          this.imageLoaded = false;
        }
      );
      this._graphSvc.getUserName(this.MId).subscribe(
        data => {
          this.graphUserName = data.displayName;
          this._myPageSvc.graphData.displayName = data.displayName;
        },
        error => {
          this.graphUserName = this.MId;
          this._myPageSvc.graphData.displayName = 'Not Available'
        }
      );
      this._graphSvc.getUserJobTitle(this.MId).subscribe(
        data => {
          this.graphUserJobTitle = data.jobTitle;
          this._myPageSvc.graphData.jobTitle = data.jobTitle;
        },
        error => {
          this.graphUserJobTitle = this.MId;
          this._myPageSvc.graphData.jobTitle = 'Not Available';
        }
      );
    }
  }

  getLearningHistory() {
    this.myPageStore.dispatch(
      new fromMyPageStore.GetMyPageLearningHistory(this.MId)
    );
  }

  getSkillProfile() {
    this.myPageStore.dispatch(
      new fromMyPageStore.GetMyPageSkillProfile(this.MId)
    );
  }

  getPrimarySkills() {
    this.myPageStore.dispatch(
      new fromMyPageStore.GetMyPagePrimarySkill(this.MId)
    );
  }
  getPersonalisedRecommendations() {
    this.homeService.getRecommendedCourses(this.MId).then((res: any) => {
      if (res != null && res.length !== 0) {
        this.personalisedRecommendations = res;
      } else {
        this.personalisedRecommendations = [];
      }
      this.homeService.personalizedRecommendations = res;
    });
  }
  setTab(tab) {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i] = false;
    }
    this.tabs[tab] = true;
  }

  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }

  getCompliance() {
    if (this.role === 'self') {
      this.myPageStore.select(fromMyPageStore.getMyPageCompliance).subscribe(
        data => {
          this.complianceStatus = data.ComplianceStatus;
          if (this.complianceStatus.toLowerCase() === 'non-compliant') {
            this.isCompliant = false;
          } else {
            this.isCompliant = true;
          }
        },
        error => {}
      );
    } else {
      this.isCompliant = this.globals.isLearnerCompliant;
    }
  }
}
