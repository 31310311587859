import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountFilterMind'
})
export class AccountFilterMindPipe implements PipeTransform {

  transform(value: any, args?: any): any {
        if (args === undefined || args === '') {
          return value;
        } else {
          value = value || [];
          let out = [];
          out = Object.assign([], value).filter(
            item => item.MindName.toLowerCase().indexOf(args.toLowerCase()) > -1
          );
          return out;
        }
  }

}
