import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaylistService } from '../playlist.service';
import {
  EnvironmentService,
  Ienvironment,
} from '@YorbitWorkspace/global-environments';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Globals } from '../../../globals';
import { LearningPathService } from '../../../learning-path/learning-path.service';
import { ILPBreadcrumbs } from '../../../learning-path/learning-path-breadcrumbs.interface';
import { Subscriber } from 'rxjs';
import { SystemService } from '../../services/system.service';
@Component({
  selector: 'yorbit-playlist-external-course',
  templateUrl: './playlist-external-course.component.html',
  styleUrls: ['./playlist-external-course.component.scss']
})
export class PlaylistExternalCourseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() course;
  @Input() courseMaterial;
  @Input() packageId;
  config: Ienvironment;
  courseStatus: any;
  isCourseProcedureAvailable: boolean;
  isCourseProcedureIconEnabled: boolean;
  downloadStatus: string;
  courseMatAvailable: any;
  courseMaterialType: any;
  showPreloader: boolean;
  contentLoadedSuccessfully: boolean;
  workFlowPhaseDetails: any;
  workFlowLoaded: boolean;
  isLWFRestricted: boolean;
  LPBreadcrumbs: ILPBreadcrumbs;
  playlistSubscriptions: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private playlistService: PlaylistService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _envSvc: EnvironmentService,
    private _InsightService: AppInsightService,
    public globals: Globals,
    private lpService: LearningPathService,
    private _systemSvc : SystemService

  ) {
    this.config = this._envSvc.getEnvironment();
    this.courseStatus = 'Loading...';
    this.isCourseProcedureAvailable = false;
    this.isCourseProcedureIconEnabled = false;
    this.showPreloader = true;
    this.contentLoadedSuccessfully = false;
    this.workFlowLoaded = false;
    //this.LWFNotAvailableLPList = this.globals.LWFNotAvailableLPs;
    this.playlistSubscriptions = {};
  }

  ngOnInit() {
    this.workFlowPhaseDetails = {};
    this.courseMatAvailable = this.courseMaterial.CourseMaterialLink != "" ? true : false;
    this.courseMaterialType = this.courseMaterial.CourseMaterialType;
    console.log(this.courseStatus);
    //this.courseStatus = 'In Progress';
    //this.navigateToExternalCourseView();
  }
  ngOnChanges(changeObj) {
    this.isLWFRestricted = false;
    console.log(this.course);
    if (changeObj['course'].currentValue != undefined) {
      if (this.course.Id != null || this.course.Id != undefined) {
        this.playlistSubscriptions.breadcrumbSub = this.lpService
          .getBreadCrumbs()
          .subscribe(breadcrumbs => {
            this.LPBreadcrumbs = breadcrumbs;
            if (
              this.course.Expertise == '201' ||
              this.course.Expertise == '301'
            ) {
              this.checkFor201ApprovalStatus();
              if (this.course.Expertise == '201') {
                //get the workflow phase details
                if (
                  this.globals.LWFNotAvailableLPs.includes(
                    this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
                  ) && this.LPBreadcrumbs.LPDetails.IsLPMandatory
                ) {
                  this.isLWFRestricted = true;
                  this.workFlowLoaded = false;
                } else {
                  // this.canShowLWF = true;
                  this.getWorkFlowStatus();
                }
                //  });
              }
              if (this.course.WorkflowStatus.toLowerCase() == 'course in-progress' ||
                this.course.WorkflowStatus.toLowerCase() ==
                'course procedure shared' ||
                this.course.WorkflowStatus.toLowerCase() ==
                'course completion certificate submitted' ||
                this.course.WorkflowStatus.toLowerCase() ==
                'certificate upload denied' ||
                this.course.WorkflowStatus.toLowerCase() ==
                'planning in-progress' || this.course.WorkflowStatus.toLowerCase() ==
                'classroom in-progress') {
                this.isCourseProcedureIconEnabled = true;
                //call course procedure api
                this.playlistService
                  .getCourseProcedure(this.course.Id)
                  .then(response => {
                    //////console.log(response);
                    if (response == null || response == '') {
                      this.isCourseProcedureAvailable = false;
                    } else {
                      this.isCourseProcedureAvailable = true;
                    }
                    this.showPreloader = false;
                    this.contentLoadedSuccessfully = true;
                  })
                  .catch(function (error) {
                    this.showPreloader = false;
                    this.contentLoadedSuccessfully = false;
                    this.isCourseProcedureAvailable = false;
                  });
              } else {
                this.showPreloader = false;
                this.contentLoadedSuccessfully = true;
              }
            } else {
              this.showPreloader = false;
              this.contentLoadedSuccessfully = true;
            }
          });
      } else {
        this.showPreloader = false;
        this.contentLoadedSuccessfully = false;
      }
    }
  }
  getWorkFlowStatus() {
    this.playlistService
      .getLearnerFlowStatus(this.course.Id)
      .subscribe(phases => {
        this.workFlowPhaseDetails = phases;
        console.log(this.workFlowPhaseDetails);
        if (this.workFlowPhaseDetails.PhaseDetails && this.workFlowPhaseDetails.PhaseDetails.AttemptCount != null)
          this.workFlowPhaseDetails.PhaseDetails.AttemptCount =
            this.workFlowPhaseDetails.PhaseDetails.AttemptCount * 1;
        this.workFlowLoaded = true;
        // //console.log(
        //   'learners workflow phase details',
        //   this.workFlowPhaseDetails
        // );
      });
  }
  checkFor201ApprovalStatus() {
    this.userDetailsStore
      .select(
        fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
          this.course.Id
        )
      )
      .subscribe(course => {
        this.course.WorkflowStatus = course.WorflowStatus;
        this.course.RequestId = course.RequestID;
        if (this.course.WorkflowStatus == undefined) {
          this.course.WorkflowStatus = this.course.WorkflowStatus;
        }
        if (this.course.WorkflowStatus == null) {
          this.course.WorkflowStatus = '';
        }
        const WorkflowStatus = this.course.WorkflowStatus.toLowerCase();
        if (
          '' == WorkflowStatus ||
          'request' == WorkflowStatus ||
          'cancelled' == WorkflowStatus ||
          'request cancelled' == WorkflowStatus ||
          'rejected' == WorkflowStatus ||
          'preapproved' == WorkflowStatus ||
          'pre-approved' == WorkflowStatus ||
          'send request' == WorkflowStatus ||
          'not started' == WorkflowStatus
        ) {
          this.courseStatus = 'Not Started';
        } else if ('pending' == WorkflowStatus) {
          this.courseStatus = 'Request Sent';
        } else if ('under review' == WorkflowStatus) {
          this.courseStatus = 'Processing';
        } else if ('approved' == WorkflowStatus) {
          this.courseStatus = 'Request Approved';
        } else {
          this.courseStatus = WorkflowStatus;
        }
        if (this.courseStatus == 'Not Started') {
          this.isLWFRestricted = true;
        }
      });
  }
  navigateToExternalCourseView() {
    this.router.navigate(['type/external'], {
      relativeTo: this.activatedRoute
    });
  }
  downloadCourseProcedure() {
    //////console.log( course );
    if (this.isCourseProcedureAvailable) {
      this.downloadStatus = 'Downloading...';
      this.playlistService
        .getCourseProcedure(this.course.Id)
        .then((response: any) => {
          this.downloadStatus = 'Downloaded';
          try {
            const insightPayload = {
              Action: "Download Course procedure",
              CourseUniqueID: this.course.UniqueId,
              User: this.globals.MId
            }
            this._InsightService.trackEvent("Learners Workflow", insightPayload)
          } catch (error) {
            //Insight Failed
          }
          //////console.log( response.data );

          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri, '_blank');
        })
        .catch(err => {
          this.downloadStatus = 'Download failed. Please try later';
        });
    }
  }
  updateWFStatus(wfStatus) {
    this.workFlowPhaseDetails = wfStatus;
    this.courseStatus= wfStatus.Status;
    // if(wfStatus.Status === 'Not Cleared Option Chosen')
    // {
    //   this.courseStatus= wfStatus.Status;
    // }
    // console.log(wfStatus);
    this.checkFor201ApprovalStatus();
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }

  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.playlistSubscriptions) {
      let subscriber = this.playlistSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  downloadCourseMaterial() {
    if(this.courseMatAvailable && this.courseMaterial.CourseMaterialType == 'Internal')
    {
      this._systemSvc.getBlobSasKey(this.courseMaterial.CourseMaterialLink)
      .then((response:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri,'_blank');
        }
        catch{}
      });
    }
    else
    {
    window.open(this.courseMaterial.CourseMaterialLink, '_blank');
    }
  }
}
