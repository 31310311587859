import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  config: Ienvironment;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }
  removeDuplicatesObject(arr, prop) {
    const obj = {};
    for (let i = 0, len = arr.length; i < len; i++) {
      if (!obj[arr[i][prop]]) {
        obj[arr[i][prop]] = arr[i];
      }
    }
    const newArr = [];
    for (var key in obj) {
      newArr.push(obj[key]);
    }
    return newArr;
  }
  getUsersAccountAndProjectList(): Observable<any> {
    return this.http.get(this.config.apiUrl + 'User/AccountsAndProjects').pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  getAvailableProjectRoleList(accountId, projectId): Observable<any> {
    return this.http
      .post(this.config.apiUrl + 'ExistingRolesMapped', {
        AccountId: accountId,
        ProjectId: projectId
      })
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getProjectRoleStatus(roleId): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'Role/SkillProgressStatus/' + roleId)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getDashboardMappedData(roleId): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'Dashboard/MappedData/Count/' + roleId)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getYorbitCourseList(): Observable<any> {
    return this.http.get(this.config.apiUrl + 'YorbitCourses').pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  getAccountProjectCourseList(accountId, projectId): Observable<any> {
    return this.http
      .post(this.config.apiUrl + 'AccountAndProject/Courses', {
        AccountId: accountId,
        ProjectId: projectId
      })
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getMindsListForAccountAndProject(accountId, projectId): Observable<any> {
    return this.http
      .post(this.config.apiUrl + 'GetTaggedMinds', {
        AccountId: accountId,
        ProjectId: projectId
      })
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getRoles(accountId, projectId): Observable<any> {
    return this.http
      .post(this.config.apiUrl + 'GetRoles', {
        AccountId: accountId,
        ProjectId: projectId
      })
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getRoleDetails(roleId): Observable<any> {
    return this.http.get(this.config.apiUrl + 'RoleData/' + roleId).pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  getRoleDetailsStatus(roleId): Observable<any> {
    return this.http.get(this.config.apiUrl + 'ViewSkillPlan/' + roleId).pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  getGapMindDetails(roleId): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'Role/GapDetailsOfMindsTagged/' + roleId)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getNonGapMindDetails(roleId): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'Role/NonGapMindsOfSimilarCourse/' + roleId)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  createProjectRole(payload): Observable<any> {
    return this.http.post(this.config.apiUrl + 'CreateRole', payload).pipe(
      tap((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  updateProjectRole(payload): Observable<any> {
    return this.http
      .post(this.config.apiUrl + 'ModifyExistingRole', payload)
      .pipe(
        tap((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  assignToMindForFirstTab(payload) {
    return this.http
      .post(this.config.apiUrl + 'AssignCourses/Assign', payload)
      .pipe(
        tap((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  assignToMindForSecondTab(role, payload) {
    return this.http
      .post(this.config.apiUrl + 'User/MandatoryLP/' + role, payload)
      .pipe(
        tap((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getPowerBiByGroup(groupId, content) {
    return this.http
      .get(
        'https://api.powerbi.com/v1.0/myorg/groups/' + groupId + '/' + content
      )
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getGroup() {
    return this.http.get('https://api.powerbi.com/v1.0/myorg/groups').pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  getToken() {
    return this.http.get('https://api.powerbi.com/v1.0/myorg/groups').pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
}
