import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterContentTileByItsProperties'
})
export class FilterContentTileByItsPropertiesPipe implements PipeTransform {
  transform(contentTiles: Array<any>, args?: any): any {
    if (
      contentTiles != undefined &&
      contentTiles.length != 0 &&
      args.length != 0
    ) {
      let matchKeys = [];
      let filteredArray = [];
      matchKeys = matchKeys.concat(args.split(' '));
      matchKeys = matchKeys.concat(args.split(','));
      matchKeys = matchKeys.concat(args.split('_'));
      matchKeys = matchKeys.concat(args.split('-'));
      let pattern = '';
      matchKeys.forEach((value, index) => {
        if (index < matchKeys.length - 1) {
          if (value != '') {
            pattern = pattern + value + '|';
          }
        } else {
          pattern = pattern + value;
        }
      });
      let regExp = new RegExp('(' + pattern + ')', 'i');
      return contentTiles.filter(content => {
        let contentTilePropValues = '';
        if (content.ItemName) {
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.ItemName.toString()
          );
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.ItemName.split('').join(' ')
          );
        } else if (content.Name) {
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.Name.toString()
          );
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.Name.split('').join(' ')
          );
        }
        if (content.ItemType) {
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.ItemType.toString()
          );
        } else if (content.Type) {
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.Type.toString()
          );
        }
        if (content.ItemExpertise) {
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.ItemExpertise.toString()
          );
        } else if (content.Expertise) {
          contentTilePropValues = contentTilePropValues.concat(
            ' ' + content.Expertise.toString()
          );
        }
        // if (content.AccountId) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.AccountId.toString()
        //   );
        // } else if (content.Account) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.Account.toString()
        //   );
        // }
        // if (content.ProjectId) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.ProjectId.toString()
        //   );
        // } else if (content.Project) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.Project.toString()
        //   );
        // }
        // if (content.WorflowStatus) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.WorflowStatus.toString()
        //   );
        // }
        // if (content.SubStatus) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.SubStatus.toString()
        //   );
        // }
        // if (content.AssignedBy) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.AssignedBy.toString()
        //   );
        // }
        // if (content.Description) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.Description.toString()
        //   );
        // }
        // if (content.Skill) {
        //   contentTilePropValues = contentTilePropValues.concat(
        //     ' ' + content.Skill
        //   );
        // }
        contentTilePropValues.replace('null', '');
        contentTilePropValues.replace('undefined', '');

        return regExp.test(contentTilePropValues.toString());
      });
    } else {
      return contentTiles;
    }
  }
}
