<mat-dialog-content style="width: 410px !important; height: 170px !important;">
    <div class="closePopupBtn">
      <button class="mat-button mat-icon-button" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="popup-title">
      <div class="title align-text-center">{{this.heading}}</div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="popup-content">
      <div class="message" [innerHTML]="dialogData.data">
       
      </div>
      <div class="actionButtonContainer">
        <button class="actionButton action-button" (click)="close()">
          Ok
        </button>
      </div>
    </div>
  </mat-dialog-content>
