import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'yorbit-our-aistrategy-related-videos',
  templateUrl: './our-aistrategy-related-videos.component.html',
  styleUrls: ['./our-aistrategy-related-videos.component.scss']
})
export class OurAIStrategyRelatedVideosComponent implements OnInit {

  @Input('videoData') videoData: any;
  @Output() videoClicked: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  playVideo(){
    this.videoClicked.emit(this.videoData)
  }

}
