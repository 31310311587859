import {
  ICompliance,
  IBadgeList,
  IUserSkills,
  IAcquiredOrDeclaredSkill,
  ICoreSkills,
  ICrossSkillList,
  IPrimarySkill,
  IFutureSkillPaths,
  IFutureSkillPath
} from './my-page-details.interface';

export const complainceInitialState: ICompliance = {
  AchievedCredits: 0,
  ComplianceCourses: [],
  CompliancePolicyUrl: '',
  ComplianceStatus: '',
  CycleEndDate: '',
  CycleStartDate: '',
  RequiredCredits: 0
};

export const historyInitialState = [];

export const skillProfileInitialState: IUserSkills = {
  AcquiredSkills: [],
  DeclaredSkills: []
};

export const coreSkillsInititalState: ICoreSkills = {
  CoreSkillCourses: [],
  Message: ''
};

export const supportSkillsInitialState = [];
export const crossSkillsInitialState: ICrossSkillList[] = [
  {
    CrossSkillName: '',
    CrossSkillPath: {},
    IsLoading: false
  }
];

export const primarySkillInitialState: IPrimarySkill = {
  PrimaryL1: '',
  PrimaryL2: '',
  PrimaryL3: '',
  PrimaryL4: ''
};

export const futureSkillsInitialState: IFutureSkillPaths[] = [
  {
    FutureSkillName: '',
    FutureSkillPaths: [],
    IsLoading: false,
    IsAdded:false
  }
];
