import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeclareStoreService {
  config: Ienvironment;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }
  getDeclareWorkflowStatusForAllCourses() {
    /**
     * No Payload.. Get the data using mid from user skills table
     *
     */
    return this.http.get(
      this.config.apiUrl + 'SkillPlan/GetDeclareFlagStatusForAllCourses'
    );
  }
  getDeclareWorkflowStatus(payload) {
    /**
     * payload:[{ CourseId:courseId, Expertise:expertise}]
     *
     */
    return this.http.post(
      this.config.apiUrl + 'SkillPlan/DeclareFlagStatus',
      payload
    );
  }
}
