<div class="confirmation">
    <mat-dialog-content>
        <div class="closePopupBtn" (click)="close()">
            <button class="mat-button mat-icon-button">
                <mat-icon class="close-popup-btn">close</mat-icon>
            </button>
        </div>
        <div *ngIf="data.id === 'DeleteConfirmation'">
            <div mat-dialog-title class="popup-title title">{{ data.title }}</div>
            <div class="batch">
                <div class="description batch-desc" [innerHTML]="data.content">
                </div>
                <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <button class="action-button" (click)="confirm()">Yes</button>
                    <button class="action-button" (click)="close()">No</button>
                </div>
            </div>
        </div>
        <div *ngIf="data.id === 'TabChangeConfirmation'" class="batch tab-change">
            <div mat-dialog-title class="popup-title title">{{ data.title }}</div>
            <div class="batch">
                <div class="description batch-desc" [innerHTML]="data.content">
                </div>
                <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <button class="action-button" (click)="confirm()">Yes</button>
                    <button class="action-button" (click)="close()">No</button>
                </div>
            </div>
        </div>

        <div *ngIf="data.id === 'CourseRequestReject'" class="batch tab-change">
            <div mat-dialog-title class="popup-title title">{{ data.title }}</div>
            <div class="batch">
                <div class="description batch-desc"
                    [title]="data.requestsId.length > 5 ? data.requestsId.join(',') : ''" [innerHTML]="content">
                </div>
                <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <button class="action-button" (click)="confirm()">Ok</button>
                    <!-- <button class="action-button" (click)="close()">No</button> -->
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>