
<div class="cnt">
  <img src="../../assets/images/riskbrigade/speaker-container-left-puzzle.png" class="left-puzzle">
    

  <div fxLayout="row" class="content center" fxLayoutGap="50px" >
        <div class="video-cnt" fxFlex="50">
          <yorbit-video-tile [align]="'column'" [video]="video" *ngIf="video!=undefined && showVideo==true"></yorbit-video-tile>
        </div>
        <div class="council-cnt" fxLayout="column" fxFlex="50" fxLayoutGap="20px">
          <div class="section-header">Core Council</div>
          <div *ngIf="coreCouncilResponse!=undefined" fxLayoutGap="10px">
            <yorbit-core-councile-tile *ngFor="let item of coreCouncilResponse" [coreCouncil]="item"></yorbit-core-councile-tile>
          </div>
          <yorbit-speaker-tile  [align]="'column'" [speaker]="speaker" *ngIf="speaker!=undefined" (popupOpened)='popUpOpened($event)'></yorbit-speaker-tile>
        </div>
        <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isDataLoading==true">
          <app-preloader></app-preloader>
        </div>
  </div>
  

  

  <img src="../../assets/images/riskbrigade/speaker-container-right-puzzle.png" class="right-puzzle"> 

</div>

