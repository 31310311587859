import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowseComponent } from './browse.component';
import { BrowseStoreModule } from './store/browse-store.module';
import { BrowseRouterModule } from './browse-router/browse-router.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';
import { PipesModule } from '@YorbitWorkspace/pipes';
import { FormsModule } from '@angular/forms';
import { AcademyViewComponent } from './academy-view/academy-view.component';
import { GenreViewComponent } from './genre-view/genre-view.component';
import { BrowseService } from './browse.service';
import { DirectivesModule } from '@YorbitWorkspace/directives';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
//import { ScrollingModule } from '@angular/cdk/scrolling';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

@NgModule({
  imports: [
    CommonModule,
    BrowseStoreModule,
    FlexLayoutModule,
    //ScrollingModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    VirtualScrollerModule,
    ContentTilesModule,
    PipesModule,
    FormsModule,
    BrowseRouterModule,
    DirectivesModule,
    ReusableUiModule
  ],
  declarations: [BrowseComponent, AcademyViewComponent, GenreViewComponent],
  exports: [AcademyViewComponent]
})
export class BrowseModule {}
