<div class="push-notifications-popup" >
  <div mat-dialog-title>Confirmation popup</div>
<div mat-dialog-content>
  <div class="msg">
   Quiz details comes here
  </div>
</div>
<div mat-dialog-actionsfxLayout="row" fxLayoutAlign="space-around center" >
  <button mat-button (click)="later()">No</button>
  <button mat-button (click)="onConfirm()" cdkFocusInitial>Yes</button>
</div>
</div>