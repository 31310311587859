<mat-toolbar  class="header" [ngClass]="{headerwhite: !currentRoute.includes('riskbrigade/home') && currentRoute!=undefined}"  >
  <img   class="logo"   src="../../assets/images/riskbrigade/logo.jpg" />

	<mat-nav-list class="list-horizontal" fxLayout="row" fxLayoutAlign="center center">
   

     <mat-list-item>
      <a mat-menu-item [routerLink]="['/riskbrigade/home']" routerLinkActive="active">Home</a>
    </mat-list-item>
      <div class="verticleLine"></div>
    <mat-list-item>
      <a mat-menu-item [routerLink]="['/riskbrigade/knwoledge-hub']" routerLinkActive="active">Knowledge Hub</a>
    </mat-list-item>
    <div class="verticleLine"></div>
  
    <mat-list-item>
      <a mat-menu-item [matMenuTriggerFor]="claimsmenu" 
      [ngClass]="{active:currentRoute.includes('riskbrigade/learn')}">Learn </a>
  
      <mat-menu #claimsmenu="matMenu">
        <button mat-menu-item [routerLink]="['/riskbrigade/learn/foundation-courses']" routerLinkActive="active">
          <span class="subMenu">Foundation Courses <span>(P4 and above)</span></span>
        </button>
        <!-- <div class="line"></div>
        <button mat-menu-item [routerLink]="['/riskbrigade/learn/advance-courses']">
          <span class="subMenu">Advance Courses <span>(C7 and above)</span> </span>
        </button> -->
      </mat-menu>
   </mat-list-item>

   <!-- <div class="verticleLine"></div>
    <mat-list-item>
      <a mat-menu-item [routerLink]="['/riskbrigade/community']" routerLinkActive="active">Community</a>
    </mat-list-item> -->
    
    <div class="verticleLine"></div>
    <mat-list-item>
      <a mat-menu-item [ngClass]="{active:currentRoute.includes('riskbrigade/progress')}" [routerLink]="['/riskbrigade/progress/foundation-courses']" >Progress</a>
    </mat-list-item>
    

  </mat-nav-list>

  <img *ngIf="currentRoute!='riskbrigade/home' && currentRoute!=undefined"  class="header-puzzle"  src="../../assets/images/riskbrigade/header-puzzle.png" />
</mat-toolbar>

<div class="basic-container" [ngStyle]="{'margin-top':!currentRoute.includes('riskbrigade/home')  && currentRoute!=undefined?'80px':'0px'}">
  <router-outlet></router-outlet>
  <div class="footer">
  </div>
</div>

