import { Component, OnInit, Inject } from '@angular/core';
import { CancelCourseRequestService } from './cancel-course-request.service';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../../../shared/user-details/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'yorbit-cancel-course-request',
  templateUrl: './cancel-course-request.component.html',
  styleUrls: ['./cancel-course-request.component.scss']
})
export class CancelCourseRequestComponent implements OnInit {
  showPreloader: boolean;
  validationResponse: string;
  cancellation: Array<string>;
  isSelectedOption: boolean;
  commentOrReason: string;
  reasonForCancelling: string;
  message: string;
  showButtons: boolean;
  requestCancellation: boolean;
  mandatoryMsg: any;
  canClosePopup: boolean;
  constructor(
    private cancelCourseRequestService: CancelCourseRequestService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private dialogRef: MatDialogRef<CancelCourseRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public cardDetails: any
  ) {
    this.requestCancellation = false;
    this.cancellation = [
      'Project Deadlines.',
      'Wrong course selected.',
      'Already completed one 201.',
      'Planning to take this course  later.',
      'Personal reasons.',
      'On Leave.',
      'Others'
    ];
    this.isSelectedOption = true;
    this.commentOrReason = this.cancellation[0];
    this.reasonForCancelling = '';
    this.showButtons = true;
    this.mandatoryMsg = null;
    this.canClosePopup = true;
  }

  ngOnInit() {
    this.message =
      'Are you sure you want to cancel the ' +
      this.cardDetails.ItemExpertise +
      ' request?';
  }
  cancelRequest() {
    this.canClosePopup = false;
    this.requestCancellation = true;
    this.showPreloader = true;
    this.validationResponse = '';
    let reason;
    if (
      '' == this.commentOrReason &&
      ('' == this.reasonForCancelling || undefined == this.reasonForCancelling)
    ) {
      this.mandatoryMsg = 'Give reasons for cancellation';
      setTimeout(() => {
        this.mandatoryMsg = null;
      }, 2000);
      this.canClosePopup = true;
    } else {
      if ('' == this.commentOrReason) {
        reason = this.reasonForCancelling;
      } else {
        reason = this.commentOrReason;
      }
      this.cancelCourseRequestService
        .cancelApprovalRequest(this.cardDetails.RequestId, reason,this.cardDetails.ItemId)
        .then((response: any) => {
          this.showPreloader = false;
          this.canClosePopup = true;
          let apiResponse = response;
          if ('cancelled' == apiResponse[this.cardDetails.RequestId]) {
            this.validationResponse = 'Cancellation is completed';
            this.updateWorkflowStatus('cancelled');
          } else {
            this.validationResponse = 'An error has occured';
          }
        });
    }
  }
  updateWorkflowStatus(status) {
    //requet id after cancellation shoud be set to 0
    this.userDetailsStore.dispatch({
      type: '[UserDetails] Update WorkflowStatus',
      payload: {
        WorflowStatus: status,
        ItemId: this.cardDetails.ItemId.toString(),
        RequestId: 0,
        PackageId: this.cardDetails.PackageId,
        LPId: this.cardDetails.LPId,
        IsLPMandatory: this.cardDetails.IsLPMandatory,
        ItemExpertise: this.cardDetails.ItemExpertise
      }
    });
  }
  closePopUp() {
    //close modal
    this.dialogRef.close();
  }
}
