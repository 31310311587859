<div [ngClass.lt-md]="'myPageContainerXs'" [ngClass.gt-sm]="'myPageContainer'" fxLayout="row" fxFlexFill>
  <div class="myPageSideMenu" [ngClass.md]="'myPageSideMenuMd'" fxHide fxShow.gt-sm="true">
    <div class="profile">
      <img [src]="imageUrl" class="profileImage" *ngIf="imageLoaded" />
      <div class="profileImageDefault" *ngIf="!imageLoaded">
        <mat-icon>face</mat-icon>
      </div>
      <div class="profileName">{{ graphUserName }}</div>
      <div class="profileDesignation">{{"(" + (graphUserJobTitle | lowercase) + ")"}}</div>
    </div>
    <div class="menuItems">
      <div class="item" (click)="setTab(0)" [ngClass]="{ activeMenu: tabs[0] }" (mouseover)="hover[0] = true" (mouseout)="hover[0] = false">
        <div class="tabLabel">
          <span class="vertBar" [ngClass]="{ hover: hover[0] }"></span>
          <div>
            Learning History
            <mat-icon class="tabActiveIcon" *ngIf="tabs[0]">chevron_right</mat-icon>
          </div>
        </div>
      </div>
      <div class="item" (click)="setTab(5)" [ngClass]="{ activeMenu: tabs[5] }" (mouseover)="hover[5] = true" (mouseout)="hover[5] = false">
        <div class="tabLabel">
          <span class="vertBar" [ngClass]="{ hover: hover[5] }"></span>
          <div>
            External Learnings
            <mat-icon class="tabActiveIcon" *ngIf="tabs[5]">chevron_right</mat-icon>
          </div>
        </div>
      </div>
      <div class="item" (click)="setTab(1)" [ngClass]="{ activeMenu: tabs[1] }" (mouseover)="hover[1] = true" (mouseout)="hover[1] = false">
        <div class="tabLabel">
          <div class="vertBar" [ngClass]="{ hover: hover[1] }"></div>
          <div>
            Check Compliance
            <mat-icon class="tabActiveIcon" *ngIf="tabs[1] && isCompliant !== false">chevron_right</mat-icon>
            <div *ngIf="!isCompliant" class="yorbitSprite nonCompliantIcon floatRight" title="Learning policy Non-Compliant"></div>
          </div>
        </div>
      </div>
      <div class="item" (click)="setTab(2)" [ngClass]="{ activeMenu: tabs[2] }" (mouseover)="hover[2] = true" (mouseout)="hover[2] = false">
        <div class="tabLabel">
          <span class="vertBar" [ngClass]="{ hover: hover[2] }"></span>
          <div>
            Skill Profile
            <mat-icon class="tabActiveIcon" *ngIf="tabs[2]">chevron_right</mat-icon>
          </div>
        </div>
      </div>

      <!--
      <div
        class="item"
        (click)="setTab(3)"
        [ngClass]="{ activeMenu: tabs[3] }"
        (mouseover)="hover[3] = true"
        (mouseout)="hover[3] = false"
      >
        <div class="tabLabel">
          <span class="vertBar" [ngClass]="{ hover: hover[3] }"></span>
          <div>
            Skilling Recommendations
            <mat-icon class="tabActiveIcon" *ngIf="tabs[3]">chevron_right</mat-icon>
          </div>
        </div>
      </div>
      -->

      <div
      class="item"
      (click)="setTab(4)"
      [ngClass]="{ activeMenu: tabs[4] }"
      (mouseover)="hover[4] = true"
      (mouseout)="hover[4] = false"
    >
      <div class="tabLabel">
        <span class="vertBar" [ngClass]="{ hover: hover[4] }"></span>
        <div>
          Personalised Recommendations
          <mat-icon class="tabActiveIcon" *ngIf="tabs[4]"
            >chevron_right</mat-icon
          >
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="myPageSideMenuXs" *ngIf="opened" fxHide fxShow.lt-md="true">
    <div class="profile">
      <img [src]="imageUrl" class="profileImage" *ngIf="imageLoaded" />
      <div class="profileImageDefault" *ngIf="!imageLoaded">
        <mat-icon>face</mat-icon>
      </div>
      <div class="profileName">{{ graphUserName }}</div>
      <div class="profileDesignation">{{"(" + (graphUserJobTitle | lowercase) + ")"}}</div>
    </div>
    <div class="menuItems">
      <div class="item" (click)="setTab(0); opened = !opened" [ngClass]="{ activeMenu: tabs[0] }">
        <div class="tabLabel">
          <!-- <span class="vertBar" [ngClass]="{ hover: hover[0] }"></span> -->
          Learning History
          <mat-icon class="tabActiveIcon" *ngIf="tabs[0]">chevron_right</mat-icon>
        </div>
      </div>
      <div class="item" (click)="setTab(5); opened = !opened" [ngClass]="{ activeMenu: tabs[5] }">
        <div class="tabLabel">
          <!-- <span class="vertBar" [ngClass]="{ hover: hover[0] }"></span> -->
          External Learnings
          <mat-icon class="tabActiveIcon" *ngIf="tabs[5]">chevron_right</mat-icon>
        </div>
      </div>
      <div class="item" (click)="setTab(1); opened = !opened" [ngClass]="{ activeMenu: tabs[1] }">
        <div class="tabLabel">
          Check Compliance
          <mat-icon class="tabActiveIcon" *ngIf="tabs[1] && isCompliant !== false">chevron_right</mat-icon>
          <div *ngIf="!isCompliant" class="yorbitSprite nonCompliantIcon  floatRight" title="Learning policy Non-Compliant"></div>
        </div>
      </div>
      <div class="item" (click)="setTab(2); opened = !opened" [ngClass]="{ activeMenu: tabs[2] }">
        <div class="tabLabel">
          Skill Profile
          <mat-icon class="tabActiveIcon" *ngIf="tabs[2]">chevron_right</mat-icon>
        </div>
      </div>
      <!--
      <div
        class="item"
        (click)="setTab(3); opened = !opened"
        [ngClass]="{ activeMenu: tabs[3] }"
      >
        <div class="tabLabel">
          Skilling Recommendations
          <mat-icon class="tabActiveIcon" *ngIf="tabs[3]"
            >chevron_right</mat-icon
          >
        </div>
      </div>
      -->
      <div
      class="item"
      (click)="setTab(4); opened = !opened"
      [ngClass]="{ activeMenu: tabs[4] }"
    >
      <div class="tabLabel">
        Personalised Recommendations
        <mat-icon class="tabActiveIcon" *ngIf="tabs[4]"
          >chevron_right</mat-icon
        >
      </div>
    </div>
    </div>
  </div>
  <div [ngClass.lt-md]="'myPageContentContainerXs'" [ngClass.gt-sm]="'myPageContentContainer'" [ngClass.md]="'myPageContentContainerMd'"
    *ngIf="!opened" [ngStyle.sm]="{ height: 'initial' }">
    <div class="menustyle" fxHide fxShow.lt-md="true">
      <!-- <mat-icon class="floatLeft" (click)="opened = !opened">menu</mat-icon> -->
      <div class="floatLeft mousePointer" (click)="opened = !opened">MENU</div>
    </div>
    <div class="myPageContent">
      <ng-template [ngTemplateOutlet]="rightPane"></ng-template>
    </div>
  </div>
</div>

<ng-template #learningHistory>
  <yorbit-learning-history></yorbit-learning-history>
</ng-template>

<ng-template #learningCompliance>
  <yorbit-learning-compliance></yorbit-learning-compliance>
  <!-- <div fxFlexFill fxLayout="column" fxLayoutAlign="center center">
    <b>
      Compliance status for the Jan’21 cycle will be available by 1st Oct’20
    </b>
<p>
  Currently the learning policy is being upgraded based on recent changes in the organisation structure
</p>
  </div> -->
</ng-template>

<ng-template #skillProfile>
  <yorbit-skill-profile></yorbit-skill-profile>
</ng-template>

<ng-template #skillingRecommendations>
  <yorbit-skilling-recommendations [role]="role" [MId]="MId" *ngIf="tabs[3]"></yorbit-skilling-recommendations>
</ng-template>

<ng-template #rightPane>
  <yorbit-learning-history *ngIf="tabs[0]"></yorbit-learning-history>
  <yorbit-learning-compliance *ngIf="tabs[1]"></yorbit-learning-compliance>
  <!-- <div fxFlexFill fxLayout="column" fxLayoutAlign="center center" *ngIf="tabs[1]">
    <b>
      Compliance status for the Jan’21 cycle will be available by 1st Oct’20
    </b>
<p>
  Currently the learning policy is being upgraded based on recent changes in the organisation structure
</p>
  </div> -->
  <yorbit-skill-profile *ngIf="tabs[2]"></yorbit-skill-profile>
  <yorbit-skilling-recommendations *ngIf="tabs[3]" [role]="role" [MId]="MId"></yorbit-skilling-recommendations>
  <div class="personalised-recommendations" *ngIf="tabs[4]" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="legend" *ngIf="personalisedRecommendations!=null"> Based on your learning history on Shoshin School the below courses are recommended for you.</div>
      <div *ngIf="personalisedRecommendations==null" fxFlex fxLayoutAlign="center center">
        <app-preloader></app-preloader>
      </div>
      <div *ngIf="personalisedRecommendations!=null && personalisedRecommendations.length==0" fxFlex fxLayoutAlign="center center">
          No Recommendations available for you.
        </div>
      <div class="recommended-courses" *ngIf="personalisedRecommendations!=null && personalisedRecommendations.length!=0" fxLayout="row wrap" fxLayoutAlign.gt-md="start start" fxLayoutAlign.lt-lg="center start">
          <div
          class="course-tile"
          fxFlex="245px"
          fxLayout="column"
          fxLayoutAlign="center center"
          *ngFor="let course of personalisedRecommendations; let i = index"
        >
          <div fxFlex="300px" fxLayoutAlign="center center"class="cardMargin">
            <yorbit-content-tile-browse
              class="mousePointer"
              *ngIf="!('FamilyPackage'===course.ItemType||'Package'===course.ItemType)"
              [cardDetails]="course"
              [featuredIn]="'Recommended Course for a Learner'"
              fxFlex="225px"
              fxLayoutAlign="start start"
              fxFlexFill
            >
            </yorbit-content-tile-browse>

            <yorbit-package-tile
            class="mousePointer"
            *ngIf="'FamilyPackage'===course.ItemType||'Package'===course.ItemType"
            [cardDetails]="course" [featuredIn]="'Recommended Course for a Learner'"
            fxFlex="215px"
            fxLayoutAlign="start start"
            fxFlexFill>
          </yorbit-package-tile>
        </div>
      </div>
    </div>
  </div>
  <yorbit-external-learnings *ngIf="tabs[5]"></yorbit-external-learnings>
</ng-template>