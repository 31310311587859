import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

import { QuizComponent } from './quiz/quiz.component';
import { GlobalPopupsModule } from '../shared/global-popups/global-popups.module';
import { CourseFeedbackComponent } from '../shared/global-popups/course-feedback/course-feedback.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuizInstructionsComponent } from './quiz-popups/quiz-instructions.component';
import { QuizLandingComponent } from './quiz-landing/quiz-landing.component';
import { QuizGuardDeactivate } from './quiz.service';
import { DeclarationFormPopupComponent } from './declaration-form-popup/declaration-form-popup.component';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatDividerModule,
    FlexLayoutModule,
    FormsModule,
    ReusableUiModule,
    MatTooltipModule,
    GlobalPopupsModule,
    MatRadioModule,
    MatDialogModule
  ],
  declarations: [
    QuizComponent,
    QuizInstructionsComponent,
    QuizLandingComponent,
    DeclarationFormPopupComponent
  ],
  exports: [QuizInstructionsComponent] ,
  providers: [QuizGuardDeactivate]
})
export class QuizModule {}
