import { Action } from '@ngrx/store';

export const ROLEBASED_GET_DETAILS = '[Rolebased] Get Status';
export const ROLEBASED_GET_DETAILS_SUCCESS = '[Rolebased] Get Status Success';
export const ROLEBASED_GET_DETAILS_ERROR = '[Rolebased] Get Status Error';

export class RolebasedGetStatus implements Action {
  readonly type = ROLEBASED_GET_DETAILS;
  constructor() {}
}
export class RolebasedGetStatusSuccess implements Action {
  readonly type = ROLEBASED_GET_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class RolebasedGetStatusError implements Action {
  readonly type = ROLEBASED_GET_DETAILS_ERROR;
  constructor(public payload: any) {}
}

export type RolebasedAction =
  | RolebasedGetStatus
  | RolebasedGetStatusSuccess
  | RolebasedGetStatusError;
