import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { AoService } from './ao.service';
import { CourseRequestService } from '../course-request/course-request.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ContentUploadStatusPopupComponent } from './content-upload-status-popup/content-upload-status-popup.component';

import { ContentUploadService } from './content-upload/content-upload.service';
import { Globals } from '../globals';
import { DeclareGetStatusForAllCourses } from '../shared/declare-store/store';
import { MatMenuTrigger } from '@angular/material/menu';
import { FileSaverService } from 'ngx-filesaver';
@Component({
  selector: 'yorbit-ao',
  templateUrl: './ao.component.html',
  styleUrls: ['./ao.component.scss']
})
export class AoComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) aoPaginater: MatPaginator;
  canLoad101DetailsPage: boolean = false;
  menuSelection: Array<boolean>;
  showPending: boolean;
  pendingRequests: Array<any>;
  actionedRequests: Array<any>;
  taxonomyList: any;
  reassignAcademyList: any;
  isRequestsLoading: boolean;
  loadingRequestsError: boolean;
  showQuizUpload: boolean;

  isTaxonomyLoading: boolean;
  taxonomyLoadingError: boolean;
  newRequestStatus: Array<any>;
  reassignAcademy: Array<any>;
  reassignStatus: Array<any>;
  @ViewChild('iSupportMenuTrigger', { static: false }) iSupportPopup:MatMenuTrigger;

  // Course request
  actionedTabsIndex = {
    ACCEPTED: 0,
    DENIED: 1,
    ONHOLD: 2,
    COMPLETED: 3
  };
  showAccepted: boolean;
  showDenied: boolean;
  showOnHold: boolean;
  showCompleted: boolean;
  nestedTabs: {
    Accepted: boolean;
    Denied: boolean;
    OnHold: boolean;
    Completed: boolean;
  };
  pageSizeOptions: number[];
  pageIndex: number;
  pageSizeLimit: number;
  pageLength: number;
  contentLength: number;
  TabTypeSelection: any;
  selectedActionTabIndex: any;

  showContentVerification: boolean;
  show101ContentUpload: boolean;
  showPackageContentUpload: boolean;
  show301ContentUpload: boolean;
  showUnitandTopicsUpload: any;
  selectedContent: any;

  prevTab: any;
  currentTab: any;
  allowedList: string[];
  showUnits: boolean;
  cuuserroles: any;
  showsTooltip = false;
  topPosition: any;
  leftPosition: any;
  tooltipTimer; 
  templateDownloadInProgress: boolean;
  templateDownload201InProgress: boolean;
 
  constructor(
    private _AOSvc: AoService,
    private _courseReqSvc: CourseRequestService,
    private _contentUploadService: ContentUploadService,
    private _popup: MatDialog,
    public globals: Globals,
    private _FileSaverService: FileSaverService,
  ) { }
  ngOnInit() {


    //this.allowedList = this.globals.allowedAoList;
   
    this.cuuserroles = [];
    this.menuSelection = new Array(3);
    this.menuSelection.fill(false);
    this.menuSelection[2] = true;
    this.prevTab = 2;
    this.currentTab = 2;
    this.showPending = true;
    this.pendingRequests = [];
    this.actionedRequests = [];
    this.reassignAcademyList = [];
    this.newRequestStatus = [];
    this.reassignAcademy = [];
    this.reassignStatus = [];

    this.pageSizeOptions = [10, 20];
    this.pageIndex = 0;
    this.pageLength = 0;
    this.pageSizeLimit = 20;
    this.TabTypeSelection = 'Pending';
    this.selectedActionTabIndex = 0;
    this.showUnits = false;
    this.templateDownloadInProgress = false;
    this.templateDownload201InProgress = false;
    this.getRoles();
    this.nestedTabs = {
      Accepted: this.showAccepted,
      Denied: this.showDenied,
      OnHold: this.showOnHold,
      Completed: this.showCompleted
    };
    this.showContentVerification = true;
    this.show101ContentUpload = false;
    this.showUnitandTopicsUpload = false;

    this.getPendingNewCourseRequests();
    this.getTaxonomy();
  }
  setCanLoadContentUploadCompStatus(tabId) {
    if (this.menuSelection[tabId] && !this.showContentVerification) {
      this._contentUploadService.canLoadContentUploadComponent.next(true);
    } else {
      this._contentUploadService.canLoadContentUploadComponent.next(false);
    }
  }
  
  exitFromContentUploadForm($event) {
    this.selectedContent = $event;
    this.changeTab(2);
  }

  changeTab(tabId) {
      for (let i = 0; i < this.menuSelection.length; i++) {
        this.menuSelection[i] = false;
      }
      this.menuSelection[tabId] = true;
      if (tabId == 2) {
        this.showQuizUpload = false;
        this.show101ContentUpload = false;
        this.showUnitandTopicsUpload = false;
        this.showContentVerification = true;
      }
      if (tabId == 0) {
        this.selectCourseRequestTab('Pending', true);
      }
  }

  openContentUpload(tabId) {
    const swapPrev = this.prevTab;
    const swapCurrent = this.currentTab;
    this.prevTab = this.currentTab;
    this.currentTab = tabId;

    //show exit popup
    this.checkBeforeExit(
      tabId,
      swapPrev,
      swapCurrent,
      this._contentUploadService.isApproved
    );
  }
  selectionHandler(tabId) {
    const swapPrev = this.prevTab;
    const swapCurrent = this.currentTab;
    this.prevTab = this.currentTab;
    this.currentTab = tabId;
    if (
      this.menuSelection[2] == true &&
      !this.showContentVerification && !this.showQuizUpload && !this.show101ContentUpload 
      // !this._contentUploadService.isApproved
    ) {
      ////console.log('hit if');
      //show exit popup
      this.checkBeforeExit(
        tabId,
        swapPrev,
        swapCurrent,
        this._contentUploadService.isApproved
      );
    }
    else {
      // //console.log('hit else');
      // for (let i = 0; i < this.menuSelection.length; i++) {
      //   this.menuSelection[i] = false;
      // }
      // this.menuSelection[tabId] = true;
      // if (tabId == 2) {
      //   this.showQuizUpload = false;
      //   this.show101ContentUpload = false;
      //   this.showContentVerification = true;
      // }
      // if (tabId == 0) {
      //   this.selectCourseRequestTab('Pending', true);
      // }
      this.changeTab(tabId);
    }
  }
  setcanLoad101DetailsPage() {
    this.canLoad101DetailsPage = true;
  }
  setcanUnitsAndTopicsPage() {
    this.showUnitandTopicsUpload = true;
  }
  getPendingNewCourseRequests() {
    this.showAccepted = false;
    this.showDenied = false;
    this.showOnHold = false;
    this.showCompleted = false;
    this.pendingRequests = [];
    this.isRequestsLoading = true;
    this.contentLength = 0;
    this.loadingRequestsError = false;
    const coursePayload = {
      arrangeByCourse: false,
      arrangeByDate: false,
      acceptedRequests: false,
      deniedRequests: false,
      onHoldRequests: false,
      CompletedRequests: false,
      ListIndex: this.pageIndex * this.pageSizeLimit,
      ListLimit: this.pageSizeLimit
    };

    this._AOSvc.getCoursePendingRequest(coursePayload).subscribe(
      data => {
        this.isRequestsLoading = false;
        this.loadingRequestsError = false;
        if (
          typeof data === 'string' &&
          data.toLowerCase() === 'no records to show'
        ) {
          this.pendingRequests = [];
          this.pageLength = 0;
        } else if (data.count > 0) {
          this.contentLength = data.count;
          this.pendingRequests = data.CourseList;
          this.pageLength = Math.ceil(data.count / this.pageSizeLimit);
        } else {
          this.pendingRequests = [];
          this.pageLength = 0;
        }
      },
      error => {
        this.isRequestsLoading = false;
        this.loadingRequestsError = true;
      }
    );
  }

  getTaxonomy() {
    this.isTaxonomyLoading = true;
    this.taxonomyLoadingError = false;
    this._courseReqSvc.getTaxonomy().subscribe(
      data => {
        this.isTaxonomyLoading = false;
        this.taxonomyLoadingError = false;
        if (
          data != null &&
          data.AcademyList != undefined &&
          data.AcademyList.length > 0
        ) {
          this.taxonomyList = data.AcademyList;
          this.taxonomyList.forEach((value, key) => {
            this.reassignAcademyList.push(value.Academy);
          });
        } else {
          this.taxonomyList = [];
        }
      },
      error => {
        this.isTaxonomyLoading = false;
        this.taxonomyLoadingError = true;
      }
    );
  }

  //newCoursePending()
  newCourseActioned() {
    this.showPending = false;
    this.actionTabsHandler(this.selectedActionTabIndex, false);
  }

  actionTabsHandler(tabIndex, onTabChange) {
    this.showAccepted = false;
    this.showDenied = false;
    this.showOnHold = false;
    this.showCompleted = false;
    this.selectedActionTabIndex = tabIndex;
    if (onTabChange) {
      this.pageIndex = 0;
    }

    let coursePayload = {
      arrangeByCourse: false,
      arrangeByDate: false,
      acceptedRequests: false,
      deniedRequests: false,
      onHoldRequests: false,
      CompletedRequests: false,
      ListIndex: this.pageIndex * this.pageSizeLimit,
      ListLimit: this.pageSizeLimit
    };

    switch (tabIndex) {
      case this.actionedTabsIndex.ACCEPTED:
        this.showAccepted = true;
        coursePayload.acceptedRequests = true;
        // this.completeReqInProgress.fill(false);
        // this.isReqComplete.fill(false);
        // this.completeReqErr.fill(false);
        //   vmAO.setTaxonomy();
        break;
      case this.actionedTabsIndex.DENIED:
        this.showDenied = true;
        coursePayload.deniedRequests = true;
        break;
      case this.actionedTabsIndex.ONHOLD:
        this.showOnHold = true;
        coursePayload.onHoldRequests = true;
        break;
      case this.actionedTabsIndex.COMPLETED:
        this.showCompleted = true;
        coursePayload.CompletedRequests = true;
        break;
    }
    this.getActionedRequests(coursePayload);
  }
  //CUuserRole
  getRoles()
  {
    this._contentUploadService.getContentUploadUserRoles().toPromise().then(response => {
      this.cuuserroles = response 
     // console.log(this.cuuserroles)
    })
  }

  getActionedRequests(coursePayload) {
    this.actionedRequests = [];
    this.loadingRequestsError = false;
    this.isRequestsLoading = true;
    this.contentLength = 0;

    this._AOSvc.getCourseActionedRequest(coursePayload).subscribe(
      data => {
        this.isRequestsLoading = false;
        if (data.count > 0) {
          this.contentLength = data.count;
          this.pageLength = Math.ceil(data.count / this.pageSizeLimit);
          this.actionedRequests = data.CourseList;
          // angular.forEach(this.actionedRequest, function (value, key) {
          //     pssService.getUserName(vmAO.actionedRequest[key].CreatedBy).then(function (response) {
          //         vmAO.actionedRequest[key].requestedByName = response.data.value;
          //     });
          //     vmAO.getUserProfilePic(vmAO.actionedRequest[key].CreatedBy);
          //   })
          // vmAO.setTaxonomy();
        } else {
          this.actionedRequests = [];
          this.pageLength = 0;
        }
      },
      error => {
        this.isRequestsLoading = false;
        this.loadingRequestsError = true;
      }
    );
  }

  onPageSizeLimitChange() {
    if (this.contentLength > 0) {
      this.aoPaginater.pageSize = this.pageSizeLimit;
      this.aoPaginater.firstPage();
    }
    this.pageIndex = 0;
    this.selectCourseRequestTab(this.TabTypeSelection, true);
    //this.onPaginate();
  }
  onPaginate(event) {
    ////console.log(event);
    this.pageIndex = event.pageIndex;
    this.selectCourseRequestTab(this.TabTypeSelection, false);
    //this.paginateUpdateEvent.emit(this.pssPaginater)
    //this.aoPaginater.
  }

  selectCourseRequestTab(type, reset) {
    this.TabTypeSelection = type;
    if (reset) {
      //this.aoPaginater.firstPage();
      this.pageIndex = 0;
    }
    switch (type) {
      case 'Pending':
        this.showPending = true;
        this.getPendingNewCourseRequests();
        break;
      case 'Actioned':
        this.showPending = false;
        this.newCourseActioned();
        break;
    }
  }

  fromExitContentUpload201toVerification($event) {
    this.showContentVerification = true;
    this.enableContentVerification($event);
  }

  enableContentUpload($event) {
    this.selectionHandler(2);
    this.showContentVerification = false;
    this.showQuizUpload = false;
    this.show101ContentUpload = false;
    this.showPackageContentUpload = false;
    this.selectedContent = $event;
    this.show301ContentUpload = false;
  }
  enableContentVerification($event) { 
    this.selectionHandler(2);
    this.showContentVerification = true;
    this.showQuizUpload = false;
    this.show101ContentUpload = false;
    this.showPackageContentUpload = false;
    this.selectedContent = $event;
    this.show301ContentUpload = false;
  }
  
  enablePackageContentUpload($event) {
    this.selectionHandler(2);
    this.showContentVerification = false;
    this.showQuizUpload = false;
    this.show101ContentUpload = false;
    this.showPackageContentUpload = true;
    this.selectedContent = $event;
    this.show301ContentUpload = false;
  }

  // enable301ContentUpload($event) {
  //   this.selectionHandler(2);
  //   this.showContentVerification=false;
  //   this.showQuizUpload=false;
  //   this.show301ContentUpload=true;
  //   this.showPackageContentUpload=false;
  //   this.selectedContent=$event;
  // }
  enableQuizUpload($event) {
    this.selectionHandler(2);
    this.showQuizUpload = true;
    this.showContentVerification = false;
    this.show101ContentUpload = false;
    this.showPackageContentUpload = false;
    this.selectedContent = $event;
    this.show301ContentUpload = false;
  }

  enable101ContentUpload($event) {
    this.selectionHandler(2);
    this.showContentVerification = false;
    this.showQuizUpload = false;
    this.show101ContentUpload = true;
    this.showPackageContentUpload = false;
    this.selectedContent = $event;
    this.show301ContentUpload = false;
  }

  enable301ContentUpload($event) {
    this.selectionHandler(2);
    this.showContentVerification = false;
    this.showQuizUpload = false;
    this.show101ContentUpload = false;
    this.showPackageContentUpload = false;
    this.selectedContent = $event;
    this.show301ContentUpload = true;
  }

  enableUnitsAndTopics($event) {
    this.selectionHandler(3);
    this.showContentVerification = false;
    this.showQuizUpload = false;
    this.show101ContentUpload = false;
    this.showUnitandTopicsUpload = true;
    this.selectedContent = $event;
  }
  checkBeforeExit(tabId, swapPrev, swapCurrent, isApproved) {
    this._contentUploadService.canLoadContentUploadComponent.next(false);
    let errorMsg = '';
    if (!isApproved) {
      errorMsg =
        'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?';
    } else {
      errorMsg = 'Are you sure you want to Exit Form?';
    }

    let dataPayload = {
      msg: errorMsg,
      title: 'Exit Form'
    };
    let type = 'exit';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: dataPayload,
      width: '250px',
      height: '250px',
      type: type
    };
    const response = this._popup.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
     response.afterClosed().subscribe(res => {
      if (res) {
        //do nothing
        this.prevTab = swapPrev;
        this.currentTab = swapCurrent;
      } else {
        if (swapPrev == 2) {
          
          if(this.show101ContentUpload || this.showQuizUpload || this.showUnitandTopicsUpload) {
            this.selectedContent='101';
          }
          else if(this.showPackageContentUpload) {
            this.selectedContent = 'package';
          }
          else if(this.show301ContentUpload) {
            this.selectedContent = '301';
          }
          else {
            this.selectedContent = '201';
          }
          
          this.showContentVerification = true;
          this.show101ContentUpload = false;
          this.showQuizUpload = false;
          this.showUnitandTopicsUpload = false;
          this.showPackageContentUpload = false;
          this.show301ContentUpload = false;
          //this.selectedContent = ';
        }
        for (let i = 0; i < this.menuSelection.length; i++) {
          this.menuSelection[i] = false;
        }
        this.menuSelection[tabId] = true;
        if (tabId == 0) {
          this.selectCourseRequestTab('Pending', true);
        }
      }
    });
  }

  openiSupportMenu( e: MouseEvent) {
    this.tooltipTimer= setTimeout(() => {
      var htmlElemnt=e.target as HTMLElement
      this.showsTooltip = true;
      this.topPosition = htmlElemnt.offsetTop+htmlElemnt.offsetHeight;
      this.leftPosition = htmlElemnt.offsetLeft;
  }, 800);
}

closeiSupportMenu() { 
  clearTimeout(this.tooltipTimer); 
  this.showsTooltip = false;
  this.topPosition = null;
  this.leftPosition = null;
}




}
