import { IuserDetails } from './user-details.interface';

export const userDetailsInitialState: IuserDetails = {
  id: '',
  LearningPaths: [],
  LearningPlanToResume: {
    PathId: '0',
    ItemId: '0',
    ItemType: '',
    CourseId: '0',
    UnitId: '0',
    ContentId: '0',
    Time: '0',
    TotalTime: '0',
    Expertise: ''
  },
  AllLearningPathProgress: {
    Progress: '0',
    ConsumedTime: '0',
    TimeLeft: '0',
    TotalTime: '0'
  },
  Interests: [],
  Achievements: { Points: '0', Badges: [] },
  Configuration: {
    GetStarted: true,
    LearningPathGetStarted: true,
    ConfigRequired: true
  },
  QuizDetails: [],
  DeletedVideoProgressListBackup:[],
  CourseProgressList: [],
  PackageProgressList: [],
  UnitProgressList: [],
  LikedCourses: [],
  Recommendations: [],
  Declarations: null,
  Roles: null,
  CDMDepartments: [],
  IsCustomer: false,
  PackagesListWithCourses:{}
};
