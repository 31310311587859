import { createSelector, createFeatureSelector} from '@ngrx/store';
import * as fromDeclareReducer from './../reducers/declare-store.reducer';
import { IdeclareState } from '../reducers';

export const getDeclareSelector = createFeatureSelector('declare');

export const getDeclareListSelector =createSelector(
  getDeclareSelector,
  (state:IdeclareState)=>state.declare.data
)

export const getDeclareStatusByEntities= createSelector(
  getDeclareListSelector,
  (declareStatusList)=>{
    let declareEntities;
    if(declareStatusList && 0!==declareStatusList.length){
      declareEntities=declareStatusList.reduce(
        (entities,status:fromDeclareReducer.IdeclareStatus)=>{
          return{
            ...entities,
            [status.CourseId]:status
          }
        },{}
      )
    }else{
      declareEntities={};
    }
    return declareEntities;
  }
)

export const getDeclareStatusByCourseId= id=>createSelector(
  getDeclareStatusByEntities,
  (declareStatus)=> {
    return declareStatus[id]
  }
)
