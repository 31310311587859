import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { LearningPathService } from '../../../learning-path/learning-path.service';
import { VideoPlayersService } from '../video-players.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaylistService } from '../../playlist/playlist.service';
import { Globals } from '../../../globals';
import { Subscriber } from 'rxjs';
import * as fromUserDetailsStore from './../../../shared/user-details/store';
import { Store } from '@ngrx/store';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { ScormCompletionPopupComponent } from '../scorm-player/scorm-completion-popup/scorm-completion-popup.component';


@Component({
  selector: 'yorbit-other-contents',
  templateUrl: './other-contents.component.html',
  styleUrls: ['./other-contents.component.scss'],
})
export class OtherContentsComponent implements OnInit, OnDestroy {
  videoCompSubscriptions: any;
  videoParams: any;
  playlist: any;
  contentId: any;
  contentIndex: any;
  LPBreadcrumbs: any;
  downloadable: boolean;
  insertionCount: number;
  documentName: string;
  multiContentCompletion: any;
  isExternalLink: boolean;
  routeParams: any;
  fullPlayListLoaded: boolean;
  badgeStatus: boolean;
  completionPopupOpen: boolean = false;
  currentPlaylistIndex: any;
  constructor(
    private lpService: LearningPathService,
    private activatedRoute: ActivatedRoute,
    private videoPlayersService: VideoPlayersService,
    private changeDetection: ChangeDetectorRef,
    private playlistService: PlaylistService,
    private globals: Globals,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    // private _popup: MatDialog
    private router: Router,
  ) {
    this.videoCompSubscriptions = {};
    this.fullPlayListLoaded = false;
    this.badgeStatus = false;
  }

  ngOnInit(): void {
    // this.subscribeToBreadCrumbEvents();
  }
  subscribeToBreadCrumbEvents() {
    this.videoCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe((breadcrumbs) => {
        this.LPBreadcrumbs = breadcrumbs;
      });
  }

  subscribeToRouteParams() {
    this.videoCompSubscriptions.routeParamsSubscriptions =
      this.activatedRoute.params.subscribe((params) => {
        this.routeParams = params;
        // this.currentVideoId = params['videoId'];
        this.insertionCount = 0;

        /*Update SelectedVideo data, so that in LP selected unit id ,video id and breadcrumbs are updated */
        if (this.fullPlayListLoaded) {
          this.playlistService.updateSelectedVideoId({
            source: 'others',
            id: this.routeParams['videoId'],
            playlistLength: this.playlist.length,
            allUnitsLoaded: true,
          });
        }
        this.videoPlayersService.getCompletionStatus().subscribe((res) => {
          this.multiContentCompletion = res;
        });
        this.subscribeToVideoPlaylist();
        // this.playlist.forEach((video, key) => {
        //   if (video.videoId.toString() === this.routeParams['videoId'].toString()) {
        //     this.currentPlaylistIndex = key;
        //   }
        // });
      });

  }

  subscribeToVideoPlaylist() {
    this.videoCompSubscriptions.playlistSubscription = this.videoPlayersService
      .getVideosPlaylist()
      .subscribe((data: any) => {
        this.playlist = data.playlist;
        if (data.playlist.length === 0) {
          this.playlistService.updateSelectedVideoId({
            source: 'others',
            id: this.routeParams['videoId'],
            playlistLength: 0,
            allUnitsLoaded: false,
          });
        }
        if (data.allUnitsLoaded && data.playlist.length !== 0) {
          // this.playlist.forEach((video, key) => {
          // if (video.videoId == this.videoParams['videoId']) {
          //   this.playlistService.updateSelectedVideoId({
          //     source: 'others',
          //     id: video.videoId,
          //     playlistLength: data.playlist.length,
          //     allUnitsLoaded: data.allUnitsLoaded
          //   });
          //   this.contentIndex = key;
          // }
          this.fullPlayListLoaded = true;
          this.playlistService.updateSelectedVideoId({
            source: 'others',
            id: this.routeParams['videoId'],
            playlistLength: data.playlist.length,
            allUnitsLoaded: data.allUnitsLoaded,
          });
          for (let i = 0; i < this.playlist.length; i++) {
            if (this.playlist[i].videoId == this.routeParams['videoId']) {
              this.contentIndex = i;
              this.currentPlaylistIndex = i;
              if (
                this.playlist &&
                this.playlist[this.contentIndex] &&
                this.playlist[this.contentIndex].sourceUrl
              ) {
                this.subscribeToBreadCrumbEvents();
                this.loadContentContainer(
                  this.playlist[this.contentIndex].sourceUrl
                );
                break;
              }
            }
          }
        }
      });
  }
  insertOtherDocumentType() {
    let payload = {
      PSId: this.globals.MId,
      CourseId: this.LPBreadcrumbs.CourseDetails.CourseId,
      ContentId: this.playlist[this.contentIndex].videoId,
      WorkflowStatus: 'completed',
      ContentType: this.playlist[this.contentIndex].sourceType,
      CreatedDate: new Date(),
      ModifiedDate: new Date(),
    };
    this.videoPlayersService
      .insertContentCompletion(payload)
      .subscribe((response) => {
        if (response) {
          setTimeout(() => {
            this.videoPlayersService.updateFetchCompletionStatus(this.multiContentCompletion, this.LPBreadcrumbs, payload.ContentId).then(result => {

              if(result.Response && this.LPBreadcrumbs.VideoDetails.VideoId == this.routeParams['videoId']){
                // this.playlist.forEach((video, key) => {
                //   if (video.videoId.toString() === this.routeParams['videoId'].toString()) {
                //     this.currentPlaylistIndex = key;
                //   }
                // });
                if(!(this.downloadable == false && this.isExternalLink == false)) {
                  this.playNextVideo();
                }
              }
            }).catch((error) => {
              console.log("Something went wrong:", error);
            });
          }, 1000);
          
          // let userDetails$ = this.userDetailsStore.select(
          //   fromUserDetailsStore.getUserDetailObject
          //   );
          // userDetails$.subscribe((UserDetails) => {
          //   if (UserDetails.id !== '') {
          //   UserDetails.Achievements.Badges.filter((course) => {
          //     if (course.CourseId == this.LPBreadcrumbs.CourseDetails.CourseId) {
          //       this.badgeStatus = course.Status.toLowerCase() == 'true'? true : false;
          //     }
          //   });
          //   }
          // });
          // if(this.badgeStatus) {
          //   const dialogConfig = new MatDialogConfig();
          //   dialogConfig.disableClose = true;
          //   dialogConfig.autoFocus = true;
          //   dialogConfig.panelClass = 'popupDialogContainer';
          //   dialogConfig.data = {
          //     title: Object.assign({}, this.LPBreadcrumbs.CourseDetails).CourseName,
          //     courseCompletion: true,
          //   };
          //   if (!this.completionPopupOpen) {
          //     this.completionPopupOpen = true;
          //     let dialogPopup = this._popup.open(
          //       ScormCompletionPopupComponent,
          //       dialogConfig
          //     );
          //     dialogPopup.afterClosed().subscribe((res) => {
          //       this.completionPopupOpen = false;
          //     });
          //   }
          // }
        } 
      });
  }

  playNextVideo() {
      if (this.playlist[this.currentPlaylistIndex + 1]) {
        let sourceType = this.playlist[
          this.currentPlaylistIndex + 1
        ].sourceType.toLowerCase();
        let sourceNav =
            sourceType == 'youtube' ||
            sourceType == 'o365' ||
            sourceType == 'scorm'
            ? sourceType  
            : 'others';
        this.router.navigate(
          [
            '../../../' + sourceNav + '/videoId/' +
              this.playlist[this.currentPlaylistIndex + 1].videoId,
          ],
          {
            relativeTo: this.activatedRoute,
          }
        );
      }
  }
  openLinkinNewTab() {
    // window.open("../../../../assets/coursematerials/6246/OnlineDoc/openSAP_ui51_Week_1_Unit_2_DUUCV_Exercises.docx", '_blank');
    // window.open(this.playlist[this.contentIndex].sourceUrl, '_blank');
    // let documentName = this.playlist[this.contentId].sourceUrl.Split(" ");
    this.documentName = this.playlist[this.contentIndex].sourceUrl
      .split('/')
      .pop();
    this.insertOtherDocumentType();
  }

  loadContentContainer(src) {
    if(src.toLowerCase().includes('.pdf') || src.toLowerCase().includes('.mp4')) {
      this.downloadable = false;
      this.isExternalLink = false;
      this.changeDetection.detectChanges();

      let contentNode = document.createElement('iframe');
      contentNode.className = 'fullScreen';
      contentNode.id = 'contentPlayer';
      contentNode.setAttribute('src', src);
      // contentNode.setAttribute(
      //   'src',
      //   'http://localhost:4200/assets/coursematerials/6246/OnlineDoc/Guidelines to complete Online Trainings.pdf'
      // );
      //contentNode.setAttribute('src', 'http://localhost:4200/assets/coursematerials/6011/CONTENTPACKAGE11002/Agility in SAP Activate.mp4');
      //contentNode.setAttribute('src', 'http://localhost:4200/assets/coursematerials/8054/CONTENTPACKAGE10001/01 Credit Card Basics.pptx.ppt');

      contentNode.setAttribute(
        'style',
        'width: 100%;height: 100%;min-height: 510px;'
      );
      // if(document.getElementById('multicontentPlayer').innerHTML != null) {
      // }
      // setTimeout(()=>{
      //   document.getElementById('multicontentPlayer').innerHTML = '';
      //       document.getElementById('multicontentPlayer').appendChild(contentNode);
      // }, 1000 );

      document.getElementById('multicontentPlayer').innerHTML = '';
      document.getElementById('multicontentPlayer').appendChild(contentNode);

      if (this.insertionCount == 0) {
        this.insertionCount++;
        this.insertOtherDocumentType();
        // window.setTimeout(() => {
        // }, 5000);
      }
    } else {
      if (src.toLowerCase().includes('.com')) {
        this.downloadable = false;
        this.isExternalLink = true;
      } else {
        this.downloadable = true;
        this.isExternalLink = false;
      }
      this.changeDetection.detectChanges();
      // document.getElementById('multicontentPlayer').innerHTML = '';
      //window.open("../../../../assets/images/Scorm/Guidelines to complete Online Trainings.pdf", '_blank');
    } 
  }

  ngAfterViewInit() {
    this.subscribeToRouteParams();
    // this.subscribeToVideoPlaylist();
    // this.subscribeToBreadCrumbEvents();
    //
  }

  ngOnDestroy() {
    let playerNode = document.getElementById('contentPlayer');
    if (playerNode) {
      playerNode.remove();
    }
    setTimeout(() => {
      this.unsubscribeAllSubscriptions();
    }, 1000);
  }

  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.videoCompSubscriptions) {
      let subscriber = this.videoCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
}
