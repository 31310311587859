import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizService } from '../quiz.service';
import { QuizComponent } from '../quiz/quiz.component';

@Component({
  selector: 'yorbit-declaration-form-popup',
  templateUrl: './declaration-form-popup.component.html',
  styleUrls: ['./declaration-form-popup.component.scss']
})
export class DeclarationFormPopupComponent implements OnInit {
  preloader: boolean;

  constructor(
    public dialogRef: MatDialogRef<QuizComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _quizSvc: QuizService
    ) { }

  ngOnInit(): void {
  }
  declare(){
    this.preloader=true;
    this._quizSvc.mandatoryDeclaration(this.dialogData.badgeId).toPromise().then((response)=>{
      //console.log(response);
    this.preloader=false;
    this.close(response);
    }).catch((err)=>{
    this.preloader=false;
    });
  }
  close(response) {
    let payload={
      response:response,
      badgeId:this.dialogData.badgeId,
      responseData:this.dialogData.responseData
    }
    this.dialogRef.close(payload);
  }
}
