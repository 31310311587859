import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userDetailsActions from '../actions/user-details.action';
import { UserDetailsService } from '../../user-details.service';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class UserDetailsEffects {
  constructor(
    private actions$: Actions,
    private userDetailsService: UserDetailsService
  ) {}

  
  getUserDetails$ = createEffect(() => this.actions$.pipe(
    ofType(userDetailsActions.USER_DETAILS_GET_DETAILS),
      switchMap(() => {
        return this.userDetailsService.getUserDetails().pipe(
          map(
            (details) => {
              return new userDetailsActions.UserDetailsGetDetailsSuccess(details)
            }
          ),
          catchError(error =>
            of(new userDetailsActions.UserDetailsGetDetailsFail(error))
          )
        );
      })
    ));
}
