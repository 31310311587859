import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssignContentComponent } from '../assign-content.component';
import { Globals } from 'apps/yorbit-ui/src/app/globals';

@Component({
  selector: 'yorbit-assign-response-popup',
  templateUrl: './assign-response-popup.component.html',
  styleUrls: ['./assign-response-popup.component.scss']
})
export class AssignResponsePopupComponent implements OnInit {
  canAssign: boolean;
  assignmentRestrictionMsg: string;

  constructor(
    private _globals: Globals,
    public dialogRef: MatDialogRef<AssignContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if(this.data.assignData.itemName.toUpperCase() == this._globals.LGBTCourseName.toUpperCase()) {
      this.canAssign = this.data.canAssign;
    this.assignmentRestrictionMsg =
      "Sorry! This Course cannot  be assigned, this course is a part of Diversity,Equity and Inclusion Module <br><center>and will be auto assigned by SHOSHIN SCHOOL to all the Associates.</center> <br/>Please raise a ticket on <a href='" + this._globals.isupportUrl + "' target='_blank'>iSupport</a> in case you want to add any exception.";
    } else {
      this.canAssign = this.data.canAssign;
      this.assignmentRestrictionMsg =
        'Sorry! You cannot assign this course as it is a part of ' +
        this.data.assignData.mandatoryLP +
        " Module on Shoshin School. <br/><br/> These courses are auto assigned by Shoshin School to new joiners as well as to Associates who need to retake the course. <br/>Please raise a ticket on <a href='" + this._globals.isupportUrl + "' target='_blank'>iSupport</a> in case you want to make any exception.";
    }
  }

  closeAssign() {
    this.dialogRef.close();
  }
}
