import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'yorbit-quiz-instructions',
  templateUrl: './quiz-instructions.component.html',
  styleUrls: ['./quiz-instructions.component.scss']
})
export class QuizInstructionsComponent implements OnInit {
  showInstructions: boolean = false;
  showTimeUp: boolean = false;
  showConfirmation: boolean = false; 
  timerMessage: string = "";
  constructor(
    private dialogRef: MatDialogRef<QuizInstructionsComponent>,
    @Inject(MAT_DIALOG_DATA) public quizDetails: any
  ) {}

  ngOnInit() {
    ////console.log('quiz detilas', this.quizDetails);
    if(this.quizDetails.PopupScenario == 'Instructions') {
      this.showConfirmation = false;
      this.showTimeUp = false;
      this.showInstructions = true;
    }
    else if(this.quizDetails.PopupScenario == 'TimeUp') {
      this.showConfirmation = false;
      this.showInstructions = false;
      this.showTimeUp = true;
    }
    else if(this.quizDetails.PopupScenario == 'Confirmation') {
      this.showInstructions = false;
      this.showTimeUp = false;
      this.showConfirmation = true;
    }

    if(this.quizDetails.IsTimeFramedQuiz && this.quizDetails.PopupScenario == 'Confirmation')
      this.setTimer();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  stopPropagation(event) {
    event.stopImmediatePropagation();
  }
  closeConfirmation(yesOrNo) {
    if(yesOrNo == 'Yes')
      this.dialogRef.close(true);
    else
      this.dialogRef.close(false);

  }
  setTimer() {
    var countDownDate = new Date();
    countDownDate = new Date(countDownDate.setSeconds(countDownDate.getSeconds() + 15));
    var timerInterval = setInterval(() => {
      
      var now = new Date().getTime();
      var distance = countDownDate.valueOf() - now.valueOf();
    
      // Time calculations for days, hours, minutes and seconds
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      
      // Output the result in an element with id="demo"
      //if(seconds > 0)
        this.timerMessage = 'This popup will close in '+ seconds + ' seconds';
      //else
        //this.dialogRef.close();

        if(seconds <= 0) {
          this.dialogRef.close(false);
        }
    });
  }
}
