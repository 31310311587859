import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderComponent } from '../header.component';

@Component({
  selector: 'yorbit-header-popup',
  templateUrl: './header-popup.component.html',
  styleUrls: ['./header-popup.component.scss']
})
export class HeaderPopupComponent implements OnInit {
  radioValueForCertificateUpload: any;
  enableBtn: boolean;
  constructor(
    public dialogRef: MatDialogRef<HeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.enableBtn = false;
  }
  radioButtonForCertificateUploadClicked() {
    this.enableBtn = true;
  }
  goToCertificateUploadPage() {
    //////console.log('selected option', this.radioValueForCertificateUpload);
    this.dialogRef.close({ data: this.radioValueForCertificateUpload });
  }
  closePopup() {
    this.dialogRef.close({ data: null });
  }
}
