import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class DetailsPageService {
  config: Ienvironment;
  routeState: any;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.routeState = [];
  }
  // savePrevRoute(route) {
  //   this.routeState.push(route);
  // }
  // getPrevRoute() {
  //   return this.routeState;
  // }

  getPackageDetails(id, type): Observable<any> {
    if (type === 'Course') {
      return this.http.get(this.config.apiUrl + 'Course/' + id).pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
    } else if (type === 'FamilyPackage') {
      return this.http
        .get(this.config.apiUrl + 'Package/' + id + '/' + type)
        .pipe(
          map((res: Response) => res),
          catchError((e: Response) => throwError(e))
        );
    }
  }
  getCourseContent(id): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'LearningPlan/Packages/Course/' + id)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getPackageContent(id): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'LearningPlan/FamilyPackages/' + id)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getContentInsideUnit(id): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'LearningPlan/Packages/Unit/' + id)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getCloudLabDetails(list): Observable<any> {
    let payload = []; 
    if(list && list.length > 0){
      list.forEach((element,index) => {
        payload.push(element);
      });
    }     
    return this.http
      .post(this.config.apiUrl + 'Course/GetLabDetails/lab', payload)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getFeedbackDetails(id): Observable<any> {
    return this.http.get(this.config.apiUrl + 'Course/Feedback/' + id).pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  getRecommendationDetails(id): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'recommendations/byCourse/' + id)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getCourseVideoUrl(id): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'Course/GetCourseDetailPageVideoUrl/' + id)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getProjectsFor301Course(id): Observable<any> {
    return this.http.get(this.config.apiUrl + 'Course/Projects/' + id).pipe(
      map((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  CountFormatter(count) {
    if (count >= 1000000000) {
      return (count / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    } else if (count >= 1000000) {
      return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else {
      return count;
    }
  }
}
