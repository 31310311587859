import { Component, Input, OnInit,OnChanges } from '@angular/core';
import{PssEvaluationService} from '../pss-evaluation.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';

import { Injectable } from "@angular/core";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ErrorDownloadPopup} from '../error-download-popup/error-download-popup.component';

@Component({
  selector: 'yorbit-download-error-file',
  templateUrl: './download-error-file.component.html',
  styleUrls: ['./download-error-file.component.scss']
})
export class DownloadErrorFileComponent implements OnInit {
  requestId:any;
  type: any;
  LearnerId:string;
  EvaluationRequestId:any;
  isProblemStatementAvailable: boolean;
  MID:string;
  downloadStatus: string;
  showPreloader: boolean;
  contentLoadedSuccessfully: boolean;
  closeResult: string;
  constructor(private router: Router,
    //private http: http,
    private activatedRoute: ActivatedRoute,
    private pssEvaluationService: PssEvaluationService,
    private modalService: NgbModal,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>) {
      this.isProblemStatementAvailable = false;
    
      this.showPreloader = true;
      this.contentLoadedSuccessfully = false;
     }

  ngOnInit() {
  }
  openErrorFile(requestId: string | number) {
    const modelRef = this.modalService.open(ErrorDownloadPopup);
    modelRef.componentInstance.requestId = requestId;
    modelRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  
  

}
