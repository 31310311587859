// import { configDevBunker } from './environment.dev-bunker';
// import { configDevKnights } from './environment.dev-knights';
// import { configDevFort } from './environment.dev-fort';
// import { configDev } from './environment.dev';
// import { configST } from './environment.st';
// import { configProd } from './environment.prod';
// import { configLocal } from './environment.local';
// import { configStStaging } from './environment.st-staging';
// import { configDevNext } from './environment.dev-next';
import { region, config } from './../../../../region';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor() {}
  
  static getEnv() {
    return config;
  }
  getEnvironment() {
    return config;
  }
}
