import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromUserDetailsStore from '../../shared/user-details/store';
import {
  Badge,
  IuserDetails
} from '../../shared/user-details/store/user-details.interface';
import { Subscriber } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeclarePopupService } from '../../shared/content-tiles/declare-popup/declare-popup.service';
import { Globals } from '../../globals';
import { DomSanitizer } from '@angular/platform-browser';
import { QuizService } from '../quiz.service';
import { LearningPathService } from '../../learning-path/learning-path.service';
import { VideoPlayersService } from '../../shared/video-players/video-players.service';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
@Component({
  selector: 'yorbit-quiz-landing',
  templateUrl: './quiz-landing.component.html',
  styleUrls: ['./quiz-landing.component.scss']
})
export class QuizLandingComponent implements OnInit, OnDestroy {
  userDetails: IuserDetails;
  userDetailsLoaded: boolean = false;
  courseId: string;
  courseName: string;
  courseDetails: any;
  itemType: string;
  quizDetails: any;
  // userQuizDetails: any;
  itemExpertise: string;
  badgeDetails: any;
  badgeImgURL: any;
  badgeName: string;
  badgeStatus: boolean;
  isFeedbackPending: boolean;
  quizNA: boolean;
  lpid: any;
  lpCategory: string;
  oldBadgeImage: any;
  allowedattempt: any;
  currentAttempt: any;
  quizDuration: any;
  currentDisplayAttempt: any;
  quesCount: any;
  cutoff: any;
  quizList: any;
  currAttempt: any;
  quizLanding: boolean;
  quizRefresh: any = null;
  showPreloader: boolean;
  paramObj: any;
  packageId: any;
  landingPageBadgeImage: string = '';
  loadingConfigDetails: boolean = false;
  updatingQuizAttempts: boolean = false;
  quizPageSubscriptions: any = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _quizSvc: QuizService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private declarePopupService: DeclarePopupService,
    private sanitizer: DomSanitizer,
    private globals: Globals,
    private _videoPlayerSvc: VideoPlayersService
  ) { }

  ngOnInit() {
    this.showPreloader = true;
    this.quizLanding = true;
    this.quizPageSubscriptions.routeParams = this.route.params.subscribe(params => {
      this.courseId = params.courseid;
      this.lpid = params.lpid;
      this.lpCategory = params.category;
      this.packageId = params.packageid;
    });

    this.getUserDetails();
    // this.getNewQuizDetails(this.courseId, this.itemType, this.courseDetails);

    setTimeout(() => {
      this.quizRefresh = localStorage.getItem('QuizRefresh');
      if (this.quizRefresh != null && this.quizRefresh != undefined) {
        localStorage.removeItem('QuizRefresh');
        this.exitQuiz();
        // this.backToLP();
      } else {
        this.showPreloader = false;
      }
    }, 2000);

    this._videoPlayerSvc.updateCachedVideoDetailsToCaptureProgress(null);
    this._videoPlayerSvc.updateVideoDetailsToCaptureProgress(null);
  }
  getUserDetails() {
    // this.quizNA = false;
    this.quizPageSubscriptions = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailObject)
      .subscribe(data => {
        if (data.id !== '' && !this.userDetailsLoaded) {
          this.userDetails = data;
          this.userDetailsLoaded = true;
          // ////console.log('userdetails...$$$$', this.userDetails);
          if (
            data.CourseProgressList.length !== 0 &&
            data.QuizDetails.length !== 0
          ) {
            const courseProgressList = this.getDataFromCourseProgressList(
              this.courseId
            )[0];

            this.quizList = this.getDataFromQuizList(this.courseId)[0];

            const badgeList = this.getDataFromBadgesList(this.courseId)[0];

            if (
              courseProgressList !== undefined &&
              this.quizList !== undefined &&
              badgeList !== undefined
            ) {
              if (courseProgressList !== undefined) {
                this.courseDetails = courseProgressList;
                this.itemExpertise = this.courseDetails.Expertise;
              }

              if (this.quizList !== undefined) {
                this.courseName = this.quizList.EntityName;
                this.itemType = this.quizList.EntityType;
              }

              if (badgeList !== undefined) {
                this.badgeDetails = badgeList;
                /**
                 *   const oldImage = this.badgeDetails.BadgeImage;
                this.oldBadgeImage = oldImage;
                const isDeclareBadge = this.declarePopupService.declareBadge(
                  this.badgeDetails.BadgeImage.trim()
                );
                   if (isDeclareBadge) {
                  if(this.quizList && this.quizList.HasPassed){
                    this.badgeDetails.BadgeImage = this.declarePopupService.acquiredBadge.skill;
                  }
                  this.badgeName = this.badgeDetails.BadgeName;
                  if (this.isMandateCourse(this.courseDetails.CourseId)) {
                    this.badgeDetails.BadgeImage = this.declarePopupService.acquiredBadge.compliance;
                  }
                }
                this.badgeImgURL = this.sanitizer.bypassSecurityTrustUrl(
                  this.badgeDetails.BadgeImage
                );
                this.badgeName = this.badgeDetails.BadgeName;
                 */

                // if (this.isMandateCourse(this.courseDetails.CourseId)) {
                //   if (this.courseDetails.CourseId.toString() == '2290') {
                //     this.landingPageBadgeImage = this.declarePopupService.acquiredBadge.skill;
                //   } else {
                //     this.landingPageBadgeImage = this.declarePopupService.acquiredBadge.compliance;
                //   }
                // } else {
                //   this.landingPageBadgeImage = this.declarePopupService.acquiredBadge.skill;
                // }
                // this.badgeImgURL = this.sanitizer.bypassSecurityTrustUrl(
                //   this.landingPageBadgeImage
                // );
                this.landingPageBadgeImage = this.badgeDetails.BadgeImage =
                  'assets/images/Badges/badge_' +
                  this.courseDetails.Expertise +
                  '.png';
                //this.badgeImgURL = 'assets/images/Badges/badge_101.jpg';
                this.badgeName = this.badgeDetails.BadgeName;
              }

              this.getNewQuizDetails(
                this.courseId,
                this.itemType,
                this.courseDetails
              );
            } else {
              this.quizNA = true;
            }
          } else {
            this.quizNA = true;
          }
        }
      });
  }

  getDataFromQuizList(courseId) {
    return this.userDetails.QuizDetails.filter(quiz => {
      if (quiz.EntityId === courseId) {
        return quiz;
      }
    });
  }

  getDataFromCourseProgressList(courseId) {
    return this.userDetails.CourseProgressList.filter(course => {
      if (course.CourseId === courseId) {
        return course;
      }
    });
  }

  getDataFromBadgesList(courseId) {
    return this.userDetails.Achievements.Badges.filter(badge => {
      if (badge.CourseId === courseId) {
        return badge;
      }
    });
  }

  getNewQuizDetails(courseId, itemType, courseDetails) {
    this.loadingConfigDetails = true;
    this.quizPageSubscriptions.getQuizByCourseIdSub = this._quizSvc
      .getQuizByCourseId(courseId, itemType, courseDetails)
      .subscribe(
      data => {
        this.loadingConfigDetails = false;
        this.quizDetails = data;
        //console.log(this.quizDetails)
        this.quizDuration = this.quizDetails.QuizDuration;
        this.allowedattempt = this.quizDetails.AllowedAttempt;
        this.quesCount = this.quizDetails.QuestionsCount;
        this.cutoff = this.quizDetails.CutOff;
        this.currAttempt = this.quizDetails.CurrentAttempt;

        this.getAttempts();
      },
      error => {
        this.loadingConfigDetails = false;
      }
      );
  }

  getAttempts() {
    if (this.currAttempt != 0 && this.currAttempt > this.allowedattempt) {
      if (this.currAttempt % this.allowedattempt == 0)
        this.currentDisplayAttempt = this.allowedattempt;
      else this.currentDisplayAttempt = this.currAttempt % this.allowedattempt;
    } else if (
      this.currAttempt <= this.allowedattempt &&
      this.allowedattempt > 0
    )
      this.currentDisplayAttempt = this.currAttempt;
  }

  isMandateCourse(courseId) {
    if (courseId != undefined) {
      const id = courseId.toString();
      if (
        this.globals.courseListForShowingLastCompletedDate.indexOf(id) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  updateAttempt(courseId, itemType, courseDetails) {
    this.updatingQuizAttempts = true;
    this.quizPageSubscriptions.updateAttemptsSub = this._quizSvc.updateAttempts(courseId, itemType, courseDetails).subscribe(
      data => {
        this.updatingQuizAttempts = false;
        //////console.log('data', data);
        this.quizLanding = false;
      },
      error => {
        this.updatingQuizAttempts = false;
      }
    );
  }

  navigateToQuiz() {
    this.updateAttempt(
      this.quizDetails.EntityId,
      this.quizDetails.EntityType,
      this.courseDetails
    );
    // this.router.navigate(['quiz/' + this.courseId + '/' + this.lpid + '/' + this.lpCategory ]);
  }

  exitQuiz() {
    let packageId = 'null';
    if (this.packageId) {
      packageId = this.packageId;
    }
    this.router.navigate([
      'learningpath/category/' +
      this.lpCategory +
      '/id/' +
      this.lpid +
      '/package/' +
      packageId +
      '/course/' +
      this.quizList.EntityId +
      '/account/' +
      this.quizList.IsAccount +
      '/project/' +
      this.quizList.IsProject +
      '/accountId/' +
      this.quizList.AccountId +
      '/projectId/' +
      this.quizList.ProjectId +
      '/tabs/playlist'
    ]);
    // ////console.log('********', this.quizList.EntityId, this.quizList.IsAccount, this.quizList.IsProject, this.quizList.AccountId, this.quizList.ProjectId );
  }

  backToLP() {
    if (this.lpid !== undefined && this.lpCategory !== undefined) {
      this.router.navigate([
        'learningpath/category/' + this.lpCategory + '/id/' + this.lpid
      ]);
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    if (
      localStorage.getItem('QuizRefresh') != null &&
      localStorage.getItem('QuizRefresh') != undefined
    ) {
      ////console.log('Local Storage being cleared');
      localStorage.removeItem('QuizRefresh');
    }
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.quizPageSubscriptions) {
      let subscriber = this.quizPageSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
}
