<mat-dialog-content>
  <div class="closePopupBtn" (click)="close(false)" *ngIf="true">
    <button class="mat-button mat-icon-button">
          <mat-icon>close</mat-icon>
        </button>
  </div>
  <div class="popup-title">
    <div class="heading courses-heading">{{ title }}</div>
  </div>
  <div class="popup-content">
    <yorbit-courses *ngIf="showCourses" [AccountId]="accountId" [ProjectId]="projectId" [Account]="isAccount" [Project]="isProject" [courses]="coursesSelected" [expertise]="expertise" (select)="onSelect($event)"></yorbit-courses>
  </div>
</mat-dialog-content>