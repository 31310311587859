import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-coaches-and-facilitator-popup',
  templateUrl: './coaches-and-facilitator-popup.component.html',
  styleUrls: ['./coaches-and-facilitator-popup.component.scss']
})
export class CoachesAndFacilitatorPopupComponent implements OnInit {

  data: any;
  bgColor: any;
  title: any;

  constructor(public dialogRef: MatDialogRef<CoachesAndFacilitatorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.title = data.title;
      this.data = data.data;
      this.bgColor = data.bgColor;
    }

  ngOnInit(): void {
    //console.log('data', this.data, this.title, this.bgColor);
  }

}
