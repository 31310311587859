import { Injectable } from '@angular/core';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class MandatoryCourseListService {
  constructor(private globals: Globals) {}

  isMandatoryCourse(courseId) {
    if (courseId != undefined) {
      const id = courseId.toString();
      if (
        this.globals.courseListForShowingLastCompletedDate.indexOf(id) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
