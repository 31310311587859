import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as GraphActions from '../actions/graph.action';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { GraphDataService } from '../../graph-data.service';

@Injectable()
export class GraphEffects {
  constructor(
    private actions$: Actions,
    private graphService: GraphDataService
  ) {
  }

  
  getGraphUserName$ = createEffect(() => this.actions$.pipe(
    ofType(GraphActions.GET_GRAPH_USER_NAME),
    switchMap((action: GraphActions.GetGraphUserName) => {
      return this.graphService.getUserName(action.payload).pipe(
        map(userInfo => {
          return new GraphActions.GetGraphUserNameSuccess(userInfo.displayName);
        }),
        catchError(error =>
          of(new GraphActions.GetGraphUserNameFail(error))
        )
      );
    })
    ));

  
  getGraphUserFirstName$ = createEffect(() => this.actions$.pipe(
    ofType(GraphActions.GET_GRAPH_USER_FIRST_NAME),
    switchMap((action: GraphActions.GetGraphUserFirstName) => {
      return this.graphService.getUserFirstName(action.payload).pipe(
        map(userInfo => {
          return new GraphActions.GetGraphUserFirstNameSuccess(userInfo.givenName);
        }),
        catchError(error =>
          of(new GraphActions.GetGraphUserFirstNameFail(error))
        )
      );
    })
    ));

  
  getGraphUserJobTitle$ = createEffect(() => this.actions$.pipe(
    ofType(GraphActions.GET_GRAPH_USER_JOB_TITLE),
    switchMap((action: GraphActions.GetGraphUserJobTitle) => {
      return this.graphService.getUserJobTitle(action.payload).pipe(
        map(userInfo => {
          return new GraphActions.GetGraphUserJobTitleSuccess(userInfo.jobTitle);
        }),
        catchError(error =>
          of(new GraphActions.GetGraphUserJobTitleFail(error))
        )
      );
    })
    ));

  
  getGraphUserImage$ = createEffect(() => this.actions$  .pipe(
    ofType(GraphActions.GET_GRAPH_USER_IMAGE),
    switchMap((action: GraphActions.GetGraphUserImage) => {
      return this.graphService.getUserImage(action.payload).pipe(
        map(userInfo => {
          return new GraphActions.GetGraphUserImageSuccess(userInfo);
        }),
        catchError(error =>
          of(new GraphActions.GetGraphUserImageFail(error))
        )
      );
    })
    ));
}
