import { Component, OnInit } from '@angular/core';
import { RiskBrigadeService } from '../../shared/services/risk-brigade.service';

@Component({
  selector: 'yorbit-knowledge-hub',
  templateUrl: './knowledge-hub.component.html',
  styleUrls: ['./knowledge-hub.component.scss']
})
export class KnowledgeHubComponent implements OnInit {
  
  selectedFilter: string = 'all';
  isDataLoading:boolean=false;
  dataList:any=[];
  pageNumber=1;
  showMore=true;

  constructor(private _riskBrigadeService:RiskBrigadeService) { }

  ngOnInit() {
    this.resetPagination();
    this.getAPIData();
  }


  getAPIData(){
    this.isDataLoading=true;
    this._riskBrigadeService.getKnowledgehub(this.pageNumber,this.selectedFilter).then(
      (data: any) => {
        if(data!=null){
          this.isDataLoading = false;
          this.dataList = this.dataList.concat(data);
          return this.dataList;
        }else{
          this.showMore=false;
        }
      },
      error => {
         this.isDataLoading = false;
         if(this.pageNumber==1){
          this.dataList = [];
          //console.log('Error', error);
         }
         return this.dataList;
      }
    );
  }
  resetPagination(){
    this.pageNumber=1;
    this.dataList =[]
    this.showMore=true;
  }

  clickSelectedFilter(filter) {
    this.selectedFilter = filter;
    this.resetPagination();
    this.getAPIData();
  }

  getMoreRecords(){
    this.pageNumber+=1;
    this.getAPIData();
  }
}
