import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryFilter'
})
export class CategoryFilterPipe implements PipeTransform {

  // input format
  /*
  items array should have a property called 'categoryName'
  [
    {
      "Q": "Question 1",
      "A": "Answer 1",
      "categoryName": "Category1"
    },
    {
      "Q": "Question 2",
      "A": "Answer 2",
      "categoryName": "Category2"
    }
  ]

  filter
  "Category1" , "Category2"
  */

  transform(items: any[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.categoryName.indexOf(filter) !== -1);
  }
}
