import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PowerbiService {
  constructor(private http: HttpClient) {}

  getPowerBiByGroup(groupId, content) {
    return this.http
      .get(
        'https://api.powerbi.com/v1.0/myorg/groups/' + groupId + '/' + content
      )
      .toPromise();
  }

  getGroup() {
    return this.http
      .get('https://api.powerbi.com/v1.0/myorg/groups')
      .toPromise();
  }
}
