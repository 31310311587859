<mat-dialog-content class="declarationFormPopup">

  <!-- header -->
  <div class="popup-title heading">
    Declaration
  </div>
  <mat-divider></mat-divider>
  <!-- message -->
  <div class="popup-contentcustom"  *ngIf="preloader">
    <app-preloader></app-preloader>
  </div>

  <div class="popup-content"  *ngIf="!preloader">
    <div class="message">
      <div class="acceptPreText" *ngIf="'quiz'!=dialogData.triggerType">
        You have not completed your integrity undertaking. Please accept the undertaking for marking the course complete and getting the Badge for the same.
      </div>
      LTIMindtree Limited is committed to fostering a culture of integrity and this commitment shapes every aspect of our business. As LTIMindtree Limited Associates, you are the key to our success and the reflection of the values LTIMindtree Limited strives to adhere to. Through inculcating the key values of honesty, transparency and accountability into your professional and personal lives, you embody LTIMindtree Limited’s commitment to integrity.
      <br/>
      <div class="acceptSubText">
        By clicking on the “I accept” button below, you confirm that you have read and understood Organization's Integrity Policy and pledge to abide by the guiding principles of the policy in all your actions.
      </div>
    </div>
  </div>
  <mat-divider class="popup-content" *ngIf="!preloader"></mat-divider>
  <div class="ButtonContainer" *ngIf="!preloader">
    <button class="action-button buttonSpace mousePointer" (click)="declare()">
      I Accept
    </button>
  </div>
  <mat-divider></mat-divider>

</mat-dialog-content>
