import * as fromGraphActions from '../actions/graph.action';
import {
  IUserGraphProfile,
  UserGraphInitialState
} from '../../graph.interface';

export interface IGraphReducerState {
  userGraphInfo: IUserGraphProfile;
  loaded: boolean;
  loading: boolean;
}

export const initialState = {
  userGraphInfo: UserGraphInitialState,
  loaded: false,
  loading: true
};

export function graphReducer(
  state = initialState,
  action: fromGraphActions.GraphActions
): IGraphReducerState {
  switch (action.type) {
    case fromGraphActions.GET_GRAPH_USER_NAME: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case fromGraphActions.GET_GRAPH_USER_NAME_SUCCESS: {
      return {
        ...state,
        userGraphInfo: {
          ...state.userGraphInfo,
          user_name: action.payload
        },
        loaded: true,
        loading: false
      };
    }
    case fromGraphActions.GET_GRAPH_USER_NAME_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    case fromGraphActions.GET_GRAPH_USER_FIRST_NAME: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case fromGraphActions.GET_GRAPH_USER_FIRST_NAME_SUCCESS: {
      return {
        ...state,
        userGraphInfo: {
          ...state.userGraphInfo,
          user_first_name: action.payload
        },
        loaded: true,
        loading: false
      };
    }
    case fromGraphActions.GET_GRAPH_USER_FIRST_NAME_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }
    case fromGraphActions.GET_GRAPH_USER_JOB_TITLE: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case fromGraphActions.GET_GRAPH_USER_JOB_TITLE_SUCCESS: {
      return {
        ...state,
        userGraphInfo: {
          ...state.userGraphInfo,
          user_job_title: action.payload
        },
        loaded: true,
        loading: false
      };
    }
    case fromGraphActions.GET_GRAPH_USER_JOB_TITLE_FAIL: {
      return {
        ...state,
        userGraphInfo: {
          ...state.userGraphInfo,
          error: action.payload
        },
        loaded: false,
        loading: false
      };
    }

    case fromGraphActions.GET_GRAPH_USER_IMAGE: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case fromGraphActions.GET_GRAPH_USER_IMAGE_SUCCESS: {
      return {
        ...state,
        userGraphInfo: {
          ...state.userGraphInfo,
          user_image: action.payload
        },
        loaded: true,
        loading: false
      };
    }

    case fromGraphActions.GET_GRAPH_USER_IMAGE_FAIL: {
      return {
        ...state,
        userGraphInfo: {
          ...state.userGraphInfo,
          error: action.payload
        },
        loaded: false,
        loading: false
      };
    }
  }
  return state;
}

export const getName = (state: IGraphReducerState) =>
  state.userGraphInfo.user_name;
export const getFirstName = (state: IGraphReducerState) =>
  state.userGraphInfo.user_first_name;
export const getJobTitle = (state: IGraphReducerState) =>
  state.userGraphInfo.user_job_title;
export const getUserImage = (state: IGraphReducerState) =>
  state.userGraphInfo.user_image;
export const getGraphInfoLoading = (state: IGraphReducerState) => state.loading;
export const getGraphInfoLoaded = (state: IGraphReducerState) => state.loaded;
