import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribePushNotificationsComponent } from './subscribe-push-notifications/subscribe-push-notifications.component';
import { BlockedPushNotificationsComponent } from './blocked-push-notifications/blocked-push-notifications.component';
import { PushNotificationService } from './push-notifications.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [CommonModule, FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,

    ReusableUiModule],
  declarations: [
    SubscribePushNotificationsComponent,
    BlockedPushNotificationsComponent
  ],
  providers: [PushNotificationService]
})
export class PushNotificationsModule {}
