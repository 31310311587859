<div class="skill-profile-container">
  <div>
    <!-- Primary Header Box -->
    <div
      *ngIf="!isPrimarySkillsLoading"
      [ngClass.lt-md]="'primarySkillsHolderXs'"
      [ngClass.gt-sm]="'primarySkillsHolderLg'"
    >
      <!-- PL1 -->
      <div
        class="primary-skill-details"
        [ngClass.lt-md]="'primarySkillDetailsXs'"
        [ngClass.gt-sm]="'primarySkillDetailsLg'"
      >
        <div class="status-label">
          Primary L1 <span class="floatRight">:</span>
        </div>
        <div class="value">
          <span *ngIf="primarySkill.PrimaryL1 != null">{{
            primarySkill.PrimaryL1 | lowercase
          }}</span>
          <span *ngIf="primarySkill.PrimaryL1 == null">---</span>
        </div>
      </div>
      <!-- PL2 -->
      <div
        class="primary-skill-details"
        [ngClass.lt-md]="'primarySkillDetailsXs'"
        [ngClass.gt-sm]="'primarySkillDetailsLg'"
      >
        <div class="status-label">
          Primary L2 <span class="floatRight">:</span>
        </div>
        <div class="value">
          <span *ngIf="primarySkill.PrimaryL2 != null">{{
            primarySkill.PrimaryL2 | lowercase
          }}</span>
          <span *ngIf="primarySkill.PrimaryL2 == null">---</span>
        </div>
      </div>
      <!-- PL3 -->
      <div
        class="primary-skill-details"
        [ngClass.lt-md]="'primarySkillDetailsXs'"
        [ngClass.gt-sm]="'primarySkillDetailsLg'"
      >
        <div class="status-label">
          Primary L3 <span class="floatRight">:</span>
        </div>
        <div class="value">
          <span *ngIf="primarySkill.PrimaryL3 != null">{{
            primarySkill.PrimaryL3 | lowercase
          }}</span>
          <span *ngIf="primarySkill.PrimaryL3 == null">---</span>
        </div>
      </div>
      <!-- PL4 -->
      <div
        class="primary-skill-details"
        [ngClass.lt-md]="'primarySkillDetailsXs'"
        [ngClass.gt-sm]="'primarySkillDetailsLg'"
      >
        <div class="status-label">
          Primary L4 <span class="floatRight">:</span>
        </div>
        <div class="value">
          <span *ngIf="primarySkill.PrimaryL4 != null">{{
            primarySkill.PrimaryL4 | lowercase
          }}</span>
          <span *ngIf="primarySkill.PrimaryL4 == null">---</span>
        </div>
      </div>
    </div>
    <div
      *ngIf="isPrimarySkillsLoading"
      [ngClass.lt-md]="'primarySkillsHolderXs'"
      [ngClass.gt-sm]="'primarySkillsHolderLg'"
    >
      <app-preloader></app-preloader>
    </div>
    <div class="noteForCDM" *ngIf="!isPrimarySkillsLoading">
      <!-- note for cdm -->
      <div>Note: Please contact your Learning Business Partner in case of any discrepancies.</div>
    </div>

    <!-- Aquired and Declared Box -->
    <div *ngIf="!isSkillProfileLoading" class="acquiredAndDeclaredBox">
      <!-- tabs -->
      <div
        class="acquiredAndDeclaredHeader"
        [ngClass.xs]="'acquiredAndDeclaredHeaderXs'"
      >
        <div
          class="acquiredSkillBox floatLeft mousePointer"
          (click)="acquiredSkillClick()"
          [ngClass]="{ activeTab: acquiredTabClicked }"
        >
          <div class="acquiredskillsstyle">Acquired Skills</div>
        </div>
        <!--
          declared101SkillList.length != 0 ||
          declared201SkillList.length != 0 ||
          declared301SkillList.length != 0
        -->
        <div
          class="declaredSkillBox floatLeft mousePointer"
          *ngIf="true"
          (click)="declaredSkillClick()"
          [ngClass]="{ activeTab: declaredTabClicked }"
        >
          <div class="declaredskillstyle">Declared Skills</div>
        </div>
      </div>

      <!-- skill details -->
      <div
        class="acquiredAndDeclaredDetails"
        [ngClass]="{expandHeight:declaredTabClicked}"
        [ngStyle.lt-md]="{ padding: '2px 20px' }"
      >
      <div class="declareSkillButtonContainer"
      *ngIf="declaredTabClicked && (role === 'self')"
      title="Declare a Skill"
      >
        <button  class="declareSkillButton"
        (click)="declareSkill($event)"
        [ngStyle.lt-md]="{ 'margin-bottom': '0px','margin-top': '10px' }">
        Declare a Skill
      </button>
      </div>

        <div class="acuireddeclaredstyle"
          fxShow
          fxHide.lt-md="true"
        >
          <!-- 101 box -->
          <div class="floatLeft acquiredAndDeclared101Box">
            <div class="expertiseLevel expertiseLevel101">101</div>
            <ng-template
              *ngIf="acquiredTabClicked"
              [ngTemplateOutlet]="acquiredSkills"
              [ngTemplateOutletContext]="{
                skills: acquired101SkillList
              }"
            ></ng-template>
            <ng-template
              *ngIf="declaredTabClicked"
              [ngTemplateOutlet]="declaredSkills"
              [ngTemplateOutletContext]="{
                skills: declared101SkillList
              }"
            ></ng-template>
          </div>

          <!-- 201 box -->
          <div
            class="floatLeft acquiredAndDeclared201Box"
            fxShow
            fxHide.lt-md="true"
          >
            <div class="expertiseLevel expertiseLevel201">201</div>
            <ng-template
              *ngIf="acquiredTabClicked"
              [ngTemplateOutlet]="acquiredSkills"
              [ngTemplateOutletContext]="{
                skills: acquired201SkillList
              }"
            ></ng-template>
            <ng-template
              *ngIf="declaredTabClicked"
              [ngTemplateOutlet]="declaredSkills"
              [ngTemplateOutletContext]="{
                skills: declared201SkillList
              }"
            ></ng-template>
          </div>

          <!-- 301 box -->
          <div
            class="floatLeft acquiredAndDeclared301Box"
            fxShow
            fxHide.lt-md="true"
          >
            <div class="expertiseLevel expertiseLevel301">301</div>
            <ng-template
              *ngIf="acquiredTabClicked"
              [ngTemplateOutlet]="acquiredSkills"
              [ngTemplateOutletContext]="{
                skills: acquired301SkillList
              }"
            ></ng-template>
            <ng-template
              *ngIf="declaredTabClicked"
              [ngTemplateOutlet]="declaredSkills"
              [ngTemplateOutletContext]="{
                skills: declared301SkillList
              }"
            ></ng-template>
          </div>
        </div>
        <div class="acquiredAndDeclared201BoxXs" fxHide fxShow.lt-md="true">
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="matHeader101">
                <div class="expertiseLevel">101</div>
              </mat-expansion-panel-header>
              <ng-template
                *ngIf="acquiredTabClicked && acquired101SkillList.length >= 0"
                [ngTemplateOutlet]="acquiredSkills"
                [ngTemplateOutletContext]="{
                  skills: acquired101SkillList
                }"
              ></ng-template>
              <ng-template
                *ngIf="declaredTabClicked && declared101SkillList.length >= 0"
                [ngTemplateOutlet]="declaredSkills"
                [ngTemplateOutletContext]="{
                  skills: declared101SkillList
                }"
              ></ng-template>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header class="matHeader201">
                <div class="expertiseLevel">201</div>
                <div class="borderFor101"></div>
              </mat-expansion-panel-header>

              <ng-template
                *ngIf="acquiredTabClicked"
                [ngTemplateOutlet]="acquiredSkills"
                [ngTemplateOutletContext]="{
                  skills: acquired201SkillList
                }"
              ></ng-template>
              <ng-template
                *ngIf="declaredTabClicked"
                [ngTemplateOutlet]="declaredSkills"
                [ngTemplateOutletContext]="{
                  skills: declared201SkillList
                }"
              ></ng-template>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header class="matHeader301">
                <div class="expertiseLevel">301</div>
              </mat-expansion-panel-header>
              <ng-template
                *ngIf="acquiredTabClicked"
                [ngTemplateOutlet]="acquiredSkills"
                [ngTemplateOutletContext]="{
                  skills: acquired301SkillList
                }"
              ></ng-template>
              <ng-template
                *ngIf="declaredTabClicked"
                [ngTemplateOutlet]="declaredSkills"
                [ngTemplateOutletContext]="{
                  skills: declared301SkillList
                }"
              ></ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div *ngIf="isSkillProfileLoading" class="acquiredAndDeclaredBox">
      <app-preloader></app-preloader>
    </div>
  </div>

  <ng-template #acquiredSkills let-skillsAcquired="skills">
    <div
      class="skillList"
      [ngStyle.lt-md]="{
        'max-height': '440px',
        height: 'auto',
        overflow: 'initial'
      }"
    >
      <!-- aquired tab skill details -->
      <div *ngIf="skillsAcquired.length >= 0">
        <ul>
          <li *ngFor="let acquiredSkill101 of skillsAcquired">
            <div class="acquiredSkillName">{{ acquiredSkill101.Name }}</div>
            <ul class="skillCourseInfo">
              <li *ngFor="let course of acquiredSkill101.CourseInfo">
            <div *ngIf="course.CourseName" class="acquiredSkillName">{{ course.CourseName }}</div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div *ngIf="skillsAcquired.length == 0" class="noSkillAvailableMsg">
        There are no skills available for display.<br />
        Access 1000+ courses on Shoshin School and acquire a skill today.
      </div>
    </div>
  </ng-template>
  <ng-template #declaredSkills let-skillsDeclared="skills">
    <div
      class="skillList"
      [ngStyle.lt-md]="{
        'max-height': '440px',
        height: 'auto',
        overflow: 'initial'
      }"
    >
      <!-- declared tab skill details -->
      <div *ngIf="skillsDeclared.length > 0">
        <ul>
          <li *ngFor="let declaredSkill101 of skillsDeclared">
            <div class="declaredSkillName">{{ declaredSkill101.Name }}</div>
            <ul class="skillCourseInfo">
              <li [hidden]="!course.CourseName" *ngFor="let course of declaredSkill101.CourseInfo">
            <div class="declaredSkillName">{{ course.CourseName }}</div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div
        *ngIf="skillsDeclared.length == 0"
        class="noSkillAvailableMsg"
        [ngStyle]="{ 'padding-top': '20px' }"
      >
        There are no skills available for display.<br />
        Access 1000+ courses on Shoshin School and acquire a skill today.
      </div>
    </div>
  </ng-template>
</div>
