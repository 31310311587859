import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as AuthActions from '../actions/auth.action';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '../../auth.service';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  
  authGetUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AUTH_GET_USER_PROFILE),
      switchMap(() => {
        return this.authService.getUserInfo().pipe(
          map(userInfo => {
            return new AuthActions.AuthGetUserProfileSuccess(userInfo);
          }),
          catchError(error => of(new AuthActions.AuthGetUserProfileFail(error)))
        );
      })
    ));
}
