import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yorbit-article-tile',
  templateUrl: './article-tile.component.html',
  styleUrls: ['./article-tile.component.scss']
})
export class ArticleTileComponent implements OnInit {
  @Input('articleDetail') articleDetail: any;

  constructor() {}

  ngOnInit(): void {}

  gotoArticle() {
    if(this.articleDetail.Type==='article' || this.articleDetail.Type==='publication'){
      window.open(this.articleDetail.URl, '_blank');
    }
  }
}
