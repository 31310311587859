<mat-dialog-content  class="assignConfirmPopup">

    <div class="closeAssign">
        <button mat-button mat-icon-button (click)="closeAssign()"
        [ngClass]="{'disabled':(false)}">
            <mat-icon>close</mat-icon>
          </button>
    </div>
    <!-- <div class="courseName">ASSIGNMENT STATUS</div> -->
    <div class="popup-title">
      <div class="heading-custom">Assignment Status</div>
    </div>
    <div class="courseName">
      <span fxFlex="82px" >{{('course'==data.assignData.itemType.toLowerCase()?"Course Name : ":"Package Name : ")}} </span>
      <span >{{data.assignData.itemName}}</span>
  </div>
  <div>
    <div class="successFailureContainer" *ngIf="data.responseData!=null">
      <div class="MidHeader" fxLayout="row">
        <div class="midText midTextHeader failedHeadersText">MIND(S)</div>
        <div class="nameText failedHeadersTextcustom">NAME(S)</div>
        <div class=" failedHeadersText failedReasoncustom">STATUS</div>
        <div class=" failedHeadersText" *ngIf="data?.assignData?.itemExpertise=='201' || data?.assignData?.itemExpertise=='301'">REQUEST ID</div>
      </div>
      <div class="scrollbar successScroll" *ngIf="data.assignData.UserIds&&(0!==data.assignData.UserIds?.length)">
        <div class="failedMidsRow" *ngFor="let responseData of data.responseData" fxLayout="row">
          <div class="midText" [ngStyle.gt-xs]="{'float': 'left', 'margin-left':'20px'}">
            <mat-form-field appearance="none">
              <mat-chip-list #chipList>
                <mat-chip [selectable]="false" [selected]="false" class="labelOpacity" [disabled]="true">
                  {{responseData.Mid}}
                </mat-chip>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="marginreason nameText textTruncate"  [ngClass.xs]="{'leftmar': true}" title="{{responseData.Status}}" >{{responseData.Name}}</div>
          <div class="marginreason failedReason textTruncate"  [ngClass.xs]="{'leftmar': true}" title="{{responseData.Status}}" >{{responseData.Status}}</div>
          <div class="marginreason failedReason textTruncate"  [ngClass.xs]="{'leftmar': true}"
           title="{{responseData.RequestId==0?'-':responseData.RequestId}}"
           *ngIf="data?.assignData?.itemExpertise=='201' || data?.assignData?.itemExpertise=='301'" >
           {{responseData.RequestId==0?"-":responseData.RequestId}}</div>
        </div>
      </div>
    </div>
    <div class="successFailureContainer info" *ngIf="data.responseData==null">
      Due to technical error, we are unable to fetch the assignment status. <br> Please reassign and check. If issue still persists <br> please raise a ticket on  <a href={{globals.isupportUrl}} target="_blank">{{globals.isupportUrl}}</a> for support.
    </div>
    <mat-divider></mat-divider>
    <div class="actionButtonContainer">
      <button [disabled]="0===data.assignData.UserIds.length && data.responseData!=null" [ngClass]="{'mouseDisabled':(0===data.assignData.UserIds.length && data.responseData!=null)}"
      class="actionButtons" (click)="closeAssign()">OK</button>
    </div>
  </div>
</mat-dialog-content>
