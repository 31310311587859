<mat-dialog-content>
  <div fxLayout="column">
    <div class="closePopupBtn">
      <button class="mat-button mat-icon-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

  <div class="popUpImg">
    <!-- <img fxShow.gt-sm="true" fxHide src="../../../../assets/images/new-release-details.jpg"/>
    <img fxShow.lt-md="true" fxHide  src="../../../../assets/images/new-release-details-m.jpg"/> -->
    <!-- <img fxShow.gt-sm="true" fxHide src="../../../../assets/images/Learners_workflow_new_mailer.jpg"/>
    <img fxShow.lt-md="true" fxHide  src="../../../../assets/images/Learners_workflow_new_mailer.jpg"/> -->
    <img class="externallearningimg" fxShow.gt-sm="true" fxHide src="../../../../assets/images/WhatsNew/External-learning.jpg"/>
    <img class="externallearningmob" fxShow.lt-md="true" fxHide src="../../../../assets/images/WhatsNew/External-learning-mobile.jpg"/>
  </div>
  </div>
</mat-dialog-content>