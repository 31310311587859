import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { ContentTileLpComponent } from './content-tile-lp/content-tile-lp.component';
import { ContentTileBrowseComponent } from './content-tile-browse/content-tile-browse.component';
import { ContentTileLpService } from './content-tile-lp/content-tile-lp.service';
import { StartCourseRequestComponent } from './content-tile-lp/start-course-request/start-course-request.component';
import { CancelCourseRequestComponent } from './content-tile-lp/cancel-course-request/cancel-course-request.component';
import { StartCourseRequestService } from './content-tile-lp/start-course-request/start-course-request.service';
import { CancelCourseRequestService } from './content-tile-lp/cancel-course-request/cancel-course-request.service';
import { ContentTileDeletePopupService } from './content-tile-lp/content-tile-delete-popup/content-tile-delete-popup.service';
import { DataTemplateDialogComponent } from './data-template-dialog/data-template-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardFeatureModule } from '../card-feature/card-feature.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LinkyModule } from 'angular-linky';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ContentTileDeletePopupComponent } from './content-tile-lp/content-tile-delete-popup/content-tile-delete-popup.component';
import { PipesModule } from '@YorbitWorkspace/pipes';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { DeclarePopupComponent } from './declare-popup/declare-popup.component';
import { MatDividerModule } from '@angular/material/divider';
import { PackageTileComponent } from './package-tile/package-tile.component';
import { LearnersIntentPopupComponent } from './content-tile-lp/learners-intent-popup/learners-intent-popup.component';
import { RblPackageComponent } from './rbl-package/rbl-package.component';
import { RoleRoadMapComponent } from './rbl-package/role-road-map/role-road-map.component';
import { ContentTileAssessmentComponent } from './content-tile-assessment-component/content-tile-assessment.component';
import { RblReportIssuePopupComponent } from './rbl-package/rbl-report-issue-popup/rbl-report-issue-popup.component';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatSelectModule,

    ReusableUiModule,
    FormsModule,
    ReactiveFormsModule,
    CardFeatureModule,
    FlexLayoutModule,
    PipesModule,
    LinkyModule,
    RoundProgressModule
  ],
  declarations: [
    ContentTileLpComponent,
    ContentTileBrowseComponent,
    PackageTileComponent,
    StartCourseRequestComponent,
    CancelCourseRequestComponent,
    DataTemplateDialogComponent,
    ContentTileDeletePopupComponent,
    DeclarePopupComponent,
    LearnersIntentPopupComponent,
    RblPackageComponent,
    RoleRoadMapComponent,
    ContentTileAssessmentComponent,
    RblReportIssuePopupComponent
  ],
  exports: [ContentTileLpComponent, ContentTileBrowseComponent, PackageTileComponent,ContentTileAssessmentComponent,RblPackageComponent,RoleRoadMapComponent],
  providers: [
    ContentTileLpService,
    StartCourseRequestService,
    CancelCourseRequestService,
    ContentTileDeletePopupService
  ],
  entryComponents: [
    StartCourseRequestComponent,
    CancelCourseRequestComponent,
    DataTemplateDialogComponent,
    ContentTileDeletePopupComponent,
    DeclarePopupComponent,
    LearnersIntentPopupComponent,
    RblReportIssuePopupComponent
  ]
})
export class ContentTilesModule { }
