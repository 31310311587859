import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { ShareContentModule } from '../share-content.module';
import { ShareContentPopupService } from './share-content-popup.service';
import { ShareContentService } from '../share-content.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ContentTileBrowseService } from '../../../content-tiles/content-tile-browse/content-tile-browse.service';
import { ContentTileBrowseComponent } from '../../../content-tiles/content-tile-browse/content-tile-browse.component';
@Component({
  selector: 'yorbit-share-content-popup',
  templateUrl: './share-content-popup.component.html',
  styleUrls: ['./share-content-popup.component.scss']
})
export class ShareContentPopupComponent implements OnInit {
  searchUserInput: string;
  suggestionList: any;
  userListToShare: any[];
  validateUserAdded: ErrorStateMatcher = {
    isErrorState: (control: FormControl) => {
      return this.UserAddedValidateFail;
    }
  };
  UserAddedValidateFail: any;
  UserAddedValidateError: string;
  shareStatus: string;
  shareState: {
    loading: boolean;
    loaded: boolean;
  };
  peopleCount: number;
  peopleCountMsg: string;

  constructor(
    public dialogRef: MatDialogRef<ShareContentModule>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _shareContentPopupService: ShareContentPopupService,
    private _shareContentService: ShareContentService,
    private mediaObserver: MediaObserver,
    private _contentTileBrowseService: ContentTileBrowseService,
    private _contentTileBrowseComponent: ContentTileBrowseComponent
  ) {}

  ShareControl = new FormControl();

  ngOnInit() {
    this.userListToShare = [];
    this.UserAddedValidateFail = false;
    this.shareState = {
      loading: false,
      loaded: false
    };
    this.peopleCount = this.data.TotalShares;
    // if(this.peopleCount > 1){
    //   this.peopleCountMsg = this.peopleCount + " people have shared this course";
    // }
    // else if(this.peopleCount === 1){
    //   this.peopleCountMsg = this.peopleCount+ " person has shared this course";
    // }
    // else{
    //   this.peopleCountMsg ="No shares for this course";
    // }
  }

  searchUser(user, event) {
    let keyEventCheck = true;
    this.UserAddedValidateFail = false;
    if (
      1 !== event.key.length &&
      'Backspace' !== event.key &&
      'Delete' !== event.key
    ) {
      keyEventCheck = false;
    }
    if (
      user.length >= 3 &&
      (keyEventCheck ||
        this.mediaObserver.isActive('xs') ||
        this.mediaObserver.isActive('sm')) &&
      !this._shareContentPopupService.isAnExtId(user)
    ) {
      this._shareContentPopupService
        .getSuggestionList(user)
        .then((response: any) => {
          this.suggestionList = response.value;
        });
    } else {
      if (user.length >= 6) {
        this._shareContentPopupService
          .getSuggestionListFromCapacityReport(user)
          .subscribe(response => {
            if (response.userPrincipalName != '')
              response.userPrincipalName = this.searchUserInput + '@' + response.userPrincipalName.split('@')[1];
              this.suggestionList[0]= response;          
          });
      }
    }
    if ('Enter' === event.key) {
      this.addUserFromSuggestionList(user);
    }
  }

  addUserFromSuggestionList(user) {
    this.resetUserInput();
    const userlist = this.userListToShare;
    const userPresentInList = userlist.filter(
      item => item.userPrincipalName === user.userPrincipalName
    );
    if (0 === userPresentInList.length) {
      this.userListToShare = [...this.userListToShare, user];
    } else {
      this.UserAddedValidateFail = true;
      this.UserAddedValidateError = 'User has already been added';
    }
  }
  removeUserFromSuggestionList(user) {
    const userlist = this.userListToShare;
    this.userListToShare = userlist.filter(item => item !== user);
  }

  resetUserInput() {
    this.suggestionList = [];
    this.searchUserInput = '';
    this.UserAddedValidateFail = false;
  }

  shareToUser() {
    if (0 < this.userListToShare.length) {
      let userMailList = [];
      this.userListToShare.forEach(val => {
        userMailList = [...userMailList, val.userPrincipalName];
      });
      this.shareState.loading = true;
      this.shareState.loaded = false;
      this._shareContentService
        .shareYorbitContent(userMailList, this.data.Id, this.data.ItemType)
        .then(response => {
          if (response) {
            this.shareStatus =
              ' Content shared successfully to the following people';
            this.peopleCount += userMailList.length;
            this._contentTileBrowseService.updatedShareCount.next(
              this.peopleCount
            );
          } else {
            this.shareStatus = 'Content sharing failed';
          }
          this.shareState.loading = false;
          this.shareState.loaded = true;
        });
    } else {
      this.UserAddedValidateFail = true;
      this.UserAddedValidateError = 'Share list is empty';
    }
  }

  closeShare() {
    this._contentTileBrowseComponent.subscribeToShareCount();
    this.dialogRef.close();
  }

  displayEmpty(user) {
    return user.diplayName;
  }

  userSelectHandler(event) {
    event.option.deselect();
  }
}
