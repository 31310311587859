import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LearningPathService } from '../../../learning-path/learning-path.service';
import { ScormPlayerService } from './scorm-player.service';
import { LPBreadcrumbsStateModel } from '../../../learning-path/learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from '../../../learning-path/learning-path-breadcrumbs.interface';
import { VideoPlayersService } from '../video-players.service';
import { Globals } from '../../../globals';
import { Observable } from 'rxjs';
import { IuserDetails } from '../../user-details/store/user-details.interface';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from './../../user-details/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScormCompletionPopupComponent } from './scorm-completion-popup/scorm-completion-popup.component';
import { PlaylistService } from '../../playlist/playlist.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'yorbit-scorm-player',
  templateUrl: './scorm-player.component.html',
  styleUrls: ['./scorm-player.component.scss'],
})
export class ScormPlayerComponent implements OnInit, OnDestroy {
  url: string;
  mode: string;
  suspendData: any;
  videoCompSubscriptions: any;
  LPBreadcrumbs: ILPBreadcrumbs;
  scormData: any;
  scormUrl: string;
  userDetails$: Observable<IuserDetails>;
  badgeId: any;
  badgeStatus: boolean;
  scormCompletionPopup: boolean = false;
  scormCourseId: any;
  scormBadgeId: string;
  scormCourseName: any;
  suspendDataObj: any;
  suspendDataObjOld: any;
  locationDataObj: any;
  locationDataObjOld: any;
  scormUpdateTimer: any;
  scormContentId: any;
  multiContentCompletion: any;
  completionPopupOpen: boolean = false;
  contentLoaded: boolean;
  videoParams: any;
  playlistData: any;
  isPopupOpened: boolean;
  isPopupAlreadyOpened: boolean;
  playList: any;
  currentPlaylistIndex: any;


  constructor(
    private lpService: LearningPathService,
    private scormPlayerService: ScormPlayerService,
    private videoPlayersService: VideoPlayersService,
    public globals: Globals,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _popup: MatDialog,
    private playlistService: PlaylistService,
    private changeDetection: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.videoCompSubscriptions = {};
    this.LPBreadcrumbs = { ...LPBreadcrumbsStateModel };
    this.scormUrl = '';
    this.badgeId = null;
    this.scormData = null;
    // this.subscribeToBreadCrumbEvents();
    this.badgeStatus = false;
   
    // this.lpService.getScormBadge().subscribe(status => {
    //   //this.resumeLP = status;

    //   if(status){
    //     this.badgeStatus =status;
    //   }
    // });
    this.contentLoaded = false;
    this.isPopupAlreadyOpened = false;
    this.playList = [];
    this.lpService.getScormBadge().subscribe((data) => {
      //this.resumeLP = status;
      if (data) {
        data.forEach((element) => {
          element.courseId == this.LPBreadcrumbs.CourseDetails.CourseId;
          {
            this.badgeStatus = element.status;
          }
        });
      }
    });
  }

  ngOnInit() {
    window.addEventListener(
      'message',
      (e) => {
        if (e.origin !== location.origin) {
          return;
        } else {
          if (e.data) {
            if (e.data.type == 'update') {
              this.ScormDataConsumer(e.data);
            }
            if (e.data.type == 'cmi') {
              this.sendCmiValue();
            }
          }
        }
      },
      false
    );
    this.activatedRoute.params.subscribe((params) => {
      this.videoParams = params;
      this.scormUrl = '';
      this.subscribeToVideoPlaylist();
      this.playList.forEach((video, key) => {
        if (video.videoId.toString() === this.videoParams['videoId'].toString()) {
          this.currentPlaylistIndex = key;
        }
      });
    });
    this.isPopupOpened = false;
    // this.subscribeToBreadCrumbEvents();
  }

  sendCmiValue() {
    let Message = {
      mode: 'browse',
      suspend_data: '',
      completion_status: '',
      success_status: '',
      location: '',
      url: this.scormUrl,
      student_id: this.globals.MId,
      student_name: this.globals.userName,
      Id: this.scormContentId,
      badgeID: this.badgeId,
      Name: this.scormCourseName,
    };

    if (this.scormData) {
      let scormContentData = this.scormData.find(
        (x) => x.ContentId == this.scormContentId
      );
      if (scormContentData) {
        //     Message = {
        //       mode: 'browse',
        //       suspend_data: scormContentData.SuspendData,
        //       completion_status: scormContentData.Completion,
        //       success_status: '',
        //       location:scormContentData.Location
        //     };
        //   }else{
        //     Message = {
        //       mode: 'browse',
        //       suspend_data: '',
        //       completion_status: '',
        //       success_status: '',
        //       location:""
        //     };
        //   }
        // } else {
        // Message = {
        //   mode: 'browse',
        //   suspend_data: '',
        //   completion_status: '',
        //   success_status: '',
        //   location:""
        Message = {
          mode: 'browse',
          suspend_data: scormContentData.SuspendData,
          completion_status: scormContentData.Completion,
          success_status: '',
          location: scormContentData.Location,
          url: this.scormUrl,
          student_id: this.globals.MId,
          student_name: this.globals.userName,
          Id: this.scormContentId,
          badgeID: this.badgeId,
          Name: this.scormCourseName,
        };
      } else if (this.scormData == null) {
        let payload = {
          Id: this.scormCourseId,
          Mid: this.globals.MId,
          SuspendData: null,
          Completion: null,
          Score: null,
          Location: null,
          CreatedDate: new Date(),
          ModifiedDate: new Date(),
        };
        this.updateScormData(payload, this.badgeId, this.scormCourseName);
      }
    }
    // else {
    //   Message = {
    //     mode: 'browse',
    //     suspend_data: '',
    //     completion_status: '',
    //     success_status: '',
    //     location: '',
    //     url: this.scormUrl,
    //     student_id: this.globals.MId,
    //     student_name: this.globals.userName,
    //     Id: this.scormCourseId,
    //     badgeID: this.badgeId,
    //     Name: this.scormCourseName
    //   };
    // }

    Message.suspend_data = Message.suspend_data ? Message.suspend_data : '';
    Message.location = Message.location ? Message.location : '';

    let scormNode: any = document.getElementById('scormId');
    scormNode.contentWindow.postMessage(Message, location.origin);
  }

  loadScormContainer() {
    let scormNode = document.createElement('iframe');
    //scormNode.frameBorder=0;
    scormNode.className = 'fullScreen';
    scormNode.id = 'scormId';
    // scormNode.setAttribute('src', this.scormUrl);
    scormNode.setAttribute('src', './../../../../assets/scorm/scorm.html');
    scormNode.setAttribute(
      'style',
      'width: 100%;height: 100%;min-height: 510px;'
    );

    document.getElementById('scormPlayerContainer').innerHTML = '';
    document.getElementById('scormPlayerContainer').appendChild(scormNode);
    //this.goFullScreen();
    //this.openPopup();

    this.suspendDataObj = {
      badgeID: '',
      name: '',
      payload: null,
    };

    this.suspendDataObjOld = {
      badgeID: '',
      name: '',
      payload: null,
    };
    this.locationDataObj = {
      badgeID: '',
      name: '',
      payload: null,
    };
    this.locationDataObjOld = {
      badgeID: '',
      name: '',
      payload: null,
    };
    this.videoPlayersService.getCompletionStatus().subscribe((res) => {
      this.multiContentCompletion = res;
    });
    this.scormUpdateTimer = setInterval(() => {
      if (this.suspendDataObj.payload != null) {
        if (
          this.suspendDataObjOld.payload == null ||
          this.suspendDataObj.payload.SuspendData !=
            this.suspendDataObjOld.payload.SuspendData
        ) {
          //console.log("Updating Scorm by Interval");
          this.updateScormData(
            this.suspendDataObj.payload,
            this.suspendDataObj.badgeID,
            this.suspendDataObj.name
          );
        }
      }
      if (this.locationDataObj.payload != null) {
        if (
          this.locationDataObjOld.payload == null ||
          this.locationDataObj.payload.SuspendData !=
            this.locationDataObjOld.payload.SuspendData
        ) {
          //console.log("Updating Scorm by Interval");
          this.updateScormData(
            this.locationDataObj.payload,
            this.locationDataObj.badgeID,
            this.locationDataObj.name
          );
        }
      }
    }, this.globals.scormTimerInterval);
  }

  goFullScreen() {
    document.getElementById('scormPlayerContainer').requestFullscreen();
  }

  ScormDataConsumer(data) {
    let payload = {
      Id: this.scormCourseId,
      Mid: this.globals.MId,
      SuspendData: null,
      Completion: null,
      Score: null,
      Location: null,
      CreatedDate: new Date(),
      ModifiedDate: new Date(),
      ContentId: data.Id,
    };

    switch (data.elementName) {
      case 'cmi.exit':
        break;
      case 'cmi.success_status':
        break;
      case 'cmi.session_time':
        break;
      case 'cmi.core.lesson_status':
      case 'cmi.completion_status':
        payload.Completion = data.elementVal;
        this.updateScormData(payload, data.badgeID, data.name);
        break;
      case 'cmi.score.raw':
        payload.Score = data.elementVal;
        this.updateScormData(payload, data.badgeID, data.name);
        break;
      case 'cmi.location':
        payload.Location = data.elementVal;
        //this.updateScormData(payload, data.badgeID, data.name);
        this.updateSuspendDataObject(data, payload, 'location');
        break;
      case 'cmi.suspend_data':
        payload.SuspendData = data.elementVal;
        // this.updateScormData(payload, data.badgeID, data.name);
        //console.log(payload.SuspendData);
        this.updateSuspendDataObject(data, payload, 'suspend_data');
        if (
          payload.Id &&
          -1 == this.globals.scormResumeIds.indexOf(payload.Id.toString())
        ) {
          this.globals.scormResumeIds.push(payload.Id.toString());
        }

        break;
      default:
        break;
    }
  }

  ScormComplete(status) {
    if ('completed' == status) {
    }
  }

  getScormData(id) {
    this.scormPlayerService.getScormDataById(id).then((response: any) => {
      if (response) {
        this.scormData = response;
      }
      this.loadScormContainer();
    });
  }

  updateScormData(payload, badgeId, Name) {
    if(payload.Id) {
      this.scormPlayerService
        .updateScormData(payload, badgeId)
        .then((response: any) => {
          //console.log('response', payload, response);
          if (payload.SuspendData != null) {
            this.suspendDataObjOld = {
              payload: payload,
              badgeID: badgeId,
              name: Name,
            };
          }
          if (payload.Location != null) {
            this.locationDataObjOld = {
              payload: payload,
              badgeID: badgeId,
              name: Name,
            };
          }
          if (
            payload.Completion &&
            (payload.Completion.toLowerCase() == 'completed' ||
              payload.Completion.toLowerCase() == 'complete' ||
              payload.Completion.toLowerCase() == 'passed') &&
            response.toLowerCase() == 'success'
          ) {
            if (
              payload.Id &&
              -1 != this.globals.scormResumeIds.indexOf(payload.Id.toString())
            ) {
              this.globals.scormResumeIds = this.globals.scormResumeIds.filter(
                (id) => id != payload.Id.toString()
              );
            }
            let userDetails$ = this.userDetailsStore.select(
            fromUserDetailsStore.getUserDetailObject
            );
            userDetails$.subscribe((UserDetails) => {
              if (UserDetails.id !== '') {
              UserDetails.Achievements.Badges.filter((course) => {
                if (course.CourseId == this.LPBreadcrumbs.CourseDetails.CourseId) {
                  badgeId = course.BadgeId;
                  this.badgeStatus = course.Status.toLowerCase() == 'true'? true : false;
                }
              });
              }
            });
            if (!this.badgeStatus) {
              this.badgeStatus = true;
              setTimeout(() => {
                this.videoPlayersService.updateFetchCompletionStatus(this.multiContentCompletion, this.LPBreadcrumbs, payload.ContentId).then(result => {
                  if(result.Response) {
                    if(!this.isPopupOpened && result.ContentId == this.LPBreadcrumbs.VideoDetails.VideoId && (-1 == this.globals.scormCompletionPopIds.indexOf(this.LPBreadcrumbs.VideoDetails.VideoId))) {
                      this.isPopupOpened = true;
                      this.globals.scormCompletionPopIds.push(this.LPBreadcrumbs.VideoDetails.VideoId);
                      this.openPopup(payload.Id, Name, false);
                    }
                    // if(-1 != this.globals.scormCompletionPopIds.indexOf(this.videoParams['videoId'])) {
                    //   this.playNextVideo();
                    // }
                  }
                }).catch((error) => {
                  console.log("Something went wrong:", error);
                });
              }, 2000);
            }
          } else if (
            payload.Completion &&
            (payload.Completion.toLowerCase() == 'completed' ||
              payload.Completion.toLowerCase() == 'complete' ||
              payload.Completion.toLowerCase() == 'passed') &&
            response.toLowerCase() == 'course completion badge awarded'
          ) {
            if (
              payload.Id &&
              -1 != this.globals.scormResumeIds.indexOf(payload.Id.toString())
            ) {
              this.globals.scormResumeIds = this.globals.scormResumeIds.filter(
                (id) => id != payload.Id.toString()
              );
            }
            if (!this.badgeStatus) {
              this.badgeStatus = true;
              this.videoPlayersService.updateFetchCompletionStatus(this.multiContentCompletion, this.LPBreadcrumbs, payload.ContentId).then(result => {
                if(result.Response && ((!result.HasOrder && !result.HasQuiz) || (result.HasOrder && !result.HasQuiz)) && result.ContentId == this.LPBreadcrumbs.VideoDetails.VideoId && (-1 == this.globals.scormCompletionPopIds.indexOf(this.LPBreadcrumbs.VideoDetails.VideoId))) {
                  this.userDetailsStore.dispatch(
                    new fromUserDetailsStore.UpdateAchievements({
                      badgeId: badgeId,
                      badgeStatus: true,
                      //badgeImage: badgeImageURL,
                      quizScore: null,
                    })
                  );
                  this.globals.scormCompletionPopIds.push(this.LPBreadcrumbs.VideoDetails.VideoId);
                  this.openPopup(payload.Id, Name, true);
                }
              }).catch((error) => {
                console.log("Something went wrong:", error);
              });
            }
          }
        });
    }
  }
  openPopup(courseId, Name, courseCompletion) {
    //if (!this.scormCompletionPopup) {

    //this.scormCompletionPopup = true;
    //let obj = Object.assign({}, this.LPBreadcrumbs.CourseDetails);
    this.lpService.updateScormBadge(courseId, true);
    //console.log("popup", this.lpService.updateScormBadge(this.LPBreadcrumbs.CourseDetails.CourseId, true));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      title: Name,
      courseCompletion: courseCompletion,
    };
    if (!this.completionPopupOpen) {
      this.completionPopupOpen = true;
      let dialogPopup = this._popup.open(
        ScormCompletionPopupComponent,
        dialogConfig
      );
      dialogPopup.afterClosed().subscribe((res) => {
        this.completionPopupOpen = false;
        this.playNextVideo();
      });
    }
    //}
  }
  /* get LP Breadcrumbs */
  subscribeToBreadCrumbEvents() {
    this.videoCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe((breadcrumbs) => {
        this.LPBreadcrumbs = breadcrumbs;
        this.scormCourseId = Object.assign(
          {},
          this.LPBreadcrumbs.CourseDetails
        ).CourseId;
        this.scormCourseName = Object.assign(
          {},
          this.LPBreadcrumbs.CourseDetails
        ).CourseName;
        // this.subscribeToVideoPlaylist();
        this.isPopupOpened = false;
      });
  }

  subscribeToVideoPlaylist() {
    this.videoCompSubscriptions.playlistSubscription = this.videoPlayersService
      .getVideosPlaylist()
      .subscribe((data: any) => {
        let playlist = data.playlist;
        this.playList = data.playlist;
        if (data.playlist.length > 0 && this.scormUrl == '') {
          playlist.forEach((video, key) => {
            if (this.videoParams['videoId'] == video.videoId) {
              this.contentLoaded = true;
              this.scormUrl = video.sourceUrl;
              this.scormContentId = video.videoId;
              this.userDetails$ = this.userDetailsStore.select(
                fromUserDetailsStore.getUserDetailObject
              );
              this.playlistService.updateSelectedVideoId({
                source: 'scorm',
                id: this.videoParams['videoId'],
                playlistLength: data.playlist.length,
                allUnitsLoaded: data.allUnitsLoaded,
              });
            }
          });
          this.userDetails$.subscribe((UserDetails) => {
            if (UserDetails.id !== '') {
              UserDetails.Achievements.Badges.filter((course) => {
                if (
                  course.CourseId == this.LPBreadcrumbs.CourseDetails.CourseId
                ) {
                  this.badgeId = course.BadgeId;
                  // this.badgeStatus = course.Status ? true : false;
                }
              });
            }
          });
          this.getScormData(this.LPBreadcrumbs.CourseDetails.CourseId);
        } else if (
          data.allUnitsLoaded &&
          data.playlist &&
          data.playlist.length > 0 &&
          this.scormUrl != ''
        ) {
          playlist.forEach((video, key) => {
            if (this.videoParams['videoId'] == video.videoId) {
              this.contentLoaded = true;
              this.scormUrl = video.sourceUrl;
              this.scormContentId = video.videoId;
              this.playlistService.updateSelectedVideoId({
                source: 'scorm',
                id: video.videoId,
                playlistLength: data.playlist.length,
                allUnitsLoaded: data.allUnitsLoaded,
              });
              this.getScormData(this.LPBreadcrumbs.CourseDetails.CourseId);
            }
          });
        }
      });
  }

  updateSuspendDataObject(data, payload, type) {
    if (type == 'suspend_data') {
      this.suspendDataObj = {
        badgeID: data.badgeID,
        name: data.name,
        payload: payload,
      };
    }

    if (type == 'location') {
      this.locationDataObj = {
        badgeID: data.badgeID,
        name: data.name,
        payload: payload,
      };
    }
    //  if(payload && payload.SuspendData != null){
    // this.scormPlayerService.updateSuspendDataToCaptureProgress(payload.SuspendData);
    //}
  }

  playNextVideo() {
    let sourceType = this.playList[
      this.currentPlaylistIndex + 1
    ].sourceType.toLowerCase();
    let sourceNav =
        sourceType == 'youtube' ||
        sourceType == 'o365' ||
        sourceType == 'scorm'
        ? sourceType  
        : 'others';
    if (this.playList[this.currentPlaylistIndex + 1]) {
      this.router.navigate(
        [
          '../../../' + sourceNav + '/videoId/' +
            this.playList[this.currentPlaylistIndex + 1].videoId,
        ],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
}

  ngOnDestroy() {
    if (this.scormUpdateTimer) {
      clearInterval(this.scormUpdateTimer);
    }
    if(this.videoCompSubscriptions.playlistSubscription) {
      this.videoCompSubscriptions.playlistSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    // this.subscribeToVideoPlaylist();
    this.subscribeToBreadCrumbEvents();
    // this.changeDetection.detectChanges();
  }
}

export interface IcmiModel {
  mode: string;
  success_status: string;
  completion_status: string;
  suspend_data: string;
  location: string;
  //score_raw:string
}
