import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YorbitCoursesComponent } from './courses/yorbit-courses.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { CoursesPopupComponent } from './courses-popup/courses-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, FormsModule, ReusableUiModule, MatDialogModule, MatIconModule],
  declarations: [YorbitCoursesComponent, CoursesPopupComponent],
  exports: [YorbitCoursesComponent, CoursesPopupComponent],
  
})
export class YorbitCoursesModule {}
