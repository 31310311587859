import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../../shared/user-details/store';

@Component({
  selector: 'yorbit-course-progress-tile',
  templateUrl: './course-progress-tile.component.html',
  styleUrls: ['./course-progress-tile.component.scss']
})
export class CourseProgressTileComponent implements OnInit {

  @Input('item') cardDetails:any;
  courseStatus:any;

  constructor( private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>) { }

  ngOnInit() {
    this.checkcCourseStatus();
  }

  checkcCourseStatus(){
    if (this.cardDetails.ItemExpertise == '101') {
      if (
        this.cardDetails.Badges != null &&
        this.cardDetails.Badges.length != 0
      ) {
        if (
          (this.cardDetails.Badges[0].Status == 'true' ||
            this.cardDetails.Badges[0].Status == true) &&
          !this.checkIsRetakeMandatory(this.cardDetails.ItemId)
        ) {
          this.courseStatus = 'Course Completed';
        } else if (
          (this.cardDetails.Badges[0].Status == 'true' ||
            this.cardDetails.Badges[0].Status == true) &&
          this.checkIsRetakeMandatory(this.cardDetails.ItemId)
        ) {
          this.courseStatus = 'Not Started';
        }
        else {
          this.courseStatus = 'Not Started';
        }
      }

      this.userDetailsStore
      .select(
      fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
        this.cardDetails.ItemId)).subscribe(course => {
        this.cardDetails.WorflowStatus = course.WorflowStatus;
        if (
          course != undefined &&
          this.cardDetails.PackageProgress.Progress != '0'
        ) {
          if ('course completed' != course.WorflowStatus.toLowerCase()) {
            this.courseStatus = 'Ongoing';
            // console.log(this.courseStatus)
            // console.log(this.cardDetails.ItemName)
          }
        }
        })
    }else{
      this.checkCourseStatus201And301()
    }
  }

  checkCourseStatus201And301(){
      this.userDetailsStore
        .select(
        fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
          this.cardDetails.ItemId
        )
        )
        .subscribe(course => {
          this.cardDetails.RequestId = course.RequestID;
          this.cardDetails.WorflowStatus = course.WorflowStatus;
          if (course.WorflowStatus == undefined) {
            course.WorflowStatus = course.WorkflowStatus;
          }
          if (course.WorflowStatus == null) {
            this.cardDetails.WorflowStatus = '';
            course.WorflowStatus = '';
          }
          let WorflowStatus = course.WorflowStatus.toLowerCase();
          if (
            '' == WorflowStatus ||
            'request' == WorflowStatus ||
            'cancelled' == WorflowStatus ||
            'request cancelled' == WorflowStatus ||
            'rejected' == WorflowStatus ||
            'preapproved' == WorflowStatus ||
            'pre-approved' == WorflowStatus ||
            'send request' == WorflowStatus ||
            'not started' == WorflowStatus
          ) {
            this.courseStatus = 'Not Started';
           
          } else if (
            (this.cardDetails.Badges[0].Status == 'true' ||
              this.cardDetails.Badges[0].Status == true) &&
            !this.checkIsRetakeMandatory(this.cardDetails.ItemId)
          ) {
            this.courseStatus = 'Course Completed';
          }else {
            this.courseStatus='Ongoing'
          }
         
        });
  }


  checkIsRetakeMandatory(itemId) {
    let retakeMandatory = false;
    this.userDetailsStore
      .select(fromUserDetailsStore.getBadgesByCourseIdSelector(itemId))
      .subscribe(badge => {
        retakeMandatory = badge.IsRetakeMandatory;
        return retakeMandatory;
      });
    return retakeMandatory;
  }
}
