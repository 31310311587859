<!--
  use as
    <yorbit-assign-content
    [card]="{
      'contentData':contentDetails
    }"
    ></yorbit-assign-content>

    legends
    -> contentData set to card content details as in browse, search,
     new and trending, detail page - package details
-->
<div
  *ngIf="!hideForAccount"
  class="custom-mat-outline"
  fxFlex="30px"
  fxFlexLayout="center center"
  (click)="openDialog()"
>
  <div
    title="Click To Assign Course/Package"
    class="yorbitSprite assignIcon mousePointer"
    [ngClass]="{
      'overlap-icon': true,
      iconForAccordion: card.assignedLocation == 'Details-Page-Accordion'
    }"
  ></div>
</div>
<!-- <mat-icon fxFlex="30px" class="mousePointer" [ngClass]="{'overlap-icon':true}" (click)='openDialog()'> assignment_turned_in </mat-icon> -->
<!-- <div class="custom-mat-outline" fxFlex="30px" fxFlexLayout="center center" (click)='openDialog()'>
  <mat-icon>radio_button_unchecked</mat-icon>
</div> -->
