<div class="row speaker-container" [fxLayout]="align=='row'?'row':'column'"
[ngClass]="{column: align=='column'}" >
  <div class="profile" fxFlex="50" fxLayoutAlign="start center" fxLayoutGap="15px">
        <img fxFlex="130px" [src]="speaker?.ProfileImage" alt="">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="name">{{speaker?.SpeakerName}}</div>
            <div class="desc">
              {{speaker?.SpeakerDescription}}
            </div>

            <button class="watch-video" (click)="openVideoPopup()">Watch Video</button>
        </div>
  </div>
  <div class="line" *ngIf="align=='row'"></div>
  <div class="topic-desc" fxFlex="50" fxLayout="column"
  [ngClass]="{columntopicdesc: align=='column'}">
      <div class="title">
        {{speaker?.Title}}
      </div>
      <div class="desc">
        {{speaker?.TitleDescription}}
      </div>
  </div>

</div>

