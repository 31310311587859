import { Component, Input, OnInit,OnChanges } from '@angular/core';
import{PssEvaluationService} from '../pss-evaluation.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';



import { Injectable } from "@angular/core";

@Component({
  selector: 'yorbit-download-problem-statement-component',
  templateUrl: './download-problem-statement-component.html',
  styleUrls: ['./download-problem-statement-component.scss']
})
export class DownloadProblemStatementComponent implements OnInit {
 courseId:any;
  courseStatus: any;
  fileName:string;
  isProblemStatementAvailable: boolean;
  downloadStatus: string;
  showPreloader: boolean;
  contentLoadedSuccessfully: boolean;
  constructor(
    private router: Router,
    //private http: http,
    private activatedRoute: ActivatedRoute,
    private pssEvaluationService: PssEvaluationService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>
  ) { 
    this.courseStatus = 'Loading...';
    this.fileName="ProblemStatement";
    this.isProblemStatementAvailable = false;
    
    this.showPreloader = true;
    this.contentLoadedSuccessfully = false;
   }

  ngOnInit() {
    /* if (this.CourseId != null || this.CourseId != undefined) {
      this.pssEvaluationService.getProblemStatement(this.CourseId)
      .then(response=>{
        ////console.log(response);
        if(response==null || response=='')
        {
          this.isProblemStatementAvailable=false;
        }
        else{
          this.isProblemStatementAvailable=true;
        }
        this.showPreloader=false;
        this.contentLoadedSuccessfully=true;
      })
      .catch(function(error){
        this.showPreloader=false;
        this.contentLoadedSuccessfully=false;
        this.isProblemStatementAvailable=false;
      
      });

} */

  }

  downloadProblemStatement() {
    //////console.log( course );
    this.courseId='DI_1141';
    ////console.log('courseid', this.courseId, this.isProblemStatementAvailable);
    
    if(this.courseId != null || this.courseId != undefined)
    {
      this.isProblemStatementAvailable=true;
    }
    
     if (this.isProblemStatementAvailable) 
    {
      this.downloadStatus = 'Downloading...';
      ////console.log('calling the API to fetch Azure URL for downloading');
      this.pssEvaluationService
        .getProblemStatement(this.courseId)
        .then((response: any) => {
          const file = new Blob([response], {type: 'application/pdf'});
          const fileURL = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download=response.substr(response.lastIndexOf('/')+1);
          link.click();
    this.downloadStatus='Problem statement downloaded';
  })
  .catch(err=>{
    this.downloadStatus='Unable to download the question paper/problem statement. Please retry after some time';


/* let headers = new HttpHeaders();
headers = headers.set('Accept', 'application/pdf');
return this.http.get(response, { headers: headers, responseType: 'blob' as 'json' }); */
        //saveAs(response, this.fileName);

   
       
         // ////console.log( response.data );
         /*  ////console.log(response);
          response.setHeader('content=disposition','attachment');
          response.write
 var link = document.createElement('a');
          //link.style.display='none';
         // document.body.appendChild(link); 
          link.href=response; */
         // link.setAttribute('href',window.URL.createObjectURL(response.URL));
        // link.setAttribute('download', this.fileName);
        // saveAs(response, this.fileName);
         
          // link.download=response.substr(response.lastIndexOf('/')+1);
          // link.click();
          //document.body.removeChild(link);
          
          //link.href=response;
        // link.download=response.substr(response.lastIndexOf('/')+1);
         // document.body.appendChild(link); 
         // link.click();
         //document.body.removeChild(link);
 // window.open(response, '_parent  ');
       //window.location.href=response.URL;
       /*    this.downloadStatus = 'Downloaded';
        })
        .catch(err => {
          this.downloadStatus = 'Download'; */
        });
    }
  }
}
