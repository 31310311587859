import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PssEvaluationService } from '../pss-evaluation.service';
import { CheckboxList, YorbitCourseRequestList, CheckboxDisableList } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-pss-learning-opmspoc',
  templateUrl: './pss-learning-opmspoc.component.html',
  styleUrls: ['./pss-learning-opmspoc.component.scss']
})
export class PssLearningOpmspocComponent implements OnInit {

  dataList: YorbitCourseRequestList[] = []; 
  opmSpocList: CheckboxList[] = [];
  changedItem: YorbitCourseRequestList[] = [];
  filteredDataList: YorbitCourseRequestList[] = [];
  checkedCourseList: string[] = [];
  checkedAcademyList: string[] = [];
  public searchText:any;
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedCourseVendorList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  uniqueLearningOPMSPOCList: string[] = [];
  displayList: CheckboxDisableList[] = [];
  changedLearningOPMSPOCList: CheckboxDisableList[] = [];
  @Output() isLearningOPMFiltered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _PssEvaluationService: PssEvaluationService) { }

  ngOnInit() {
    this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
    this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
    this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
    this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
    this.checkedLearningOPMSPOCList = this._PssEvaluationService.checkedLearningOPMSPOCList;
    this.checkedSubStatusList = this._PssEvaluationService.checkedSubStatusList;
    this.dataList = this._PssEvaluationService.dataList;
    this.opmSpocList = [];
    //this.opmSpocList = this._PssEvaluationService.selectedOpmSpocList;
    if(this.opmSpocList.length == 0) {
      this.GetEvaluatorVendorList(); 
    }
  }

  GetEvaluatorVendorList() {
    this.filteredDataList = this._PssEvaluationService.filterRecords('LearningOPMSPOC');
    ////console.log('.....', this.filteredDataList);

    //For Displaying all unique Sub status list
    for(let i=0; i<this.dataList.length;i++) {
      if(this.uniqueLearningOPMSPOCList.indexOf(this.dataList[i].LearningOPMSPOC) == -1) {
        this.uniqueLearningOPMSPOCList.push(this.dataList[i].LearningOPMSPOC);
      }
    }

    //Checking if atleast any one of the filter is applied
    //If no filter is applied, Display all the unique vendor list without disabling any option
    //and selecting any option
    if(this.checkedCourseList.length == 0 
      && this.checkedAcademyList.length == 0
      && this.checkedCourseTypeList.length == 0
      && this.checkedCourseVendorList.length == 0
      && this.checkedSubStatusList.length == 0) {
      this.uniqueLearningOPMSPOCList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: true
        }
      }).forEach(item=>this.displayList.push(item));
    }
    //If any filter is applied, disable all the options and deselect all the options
    else {
      this.uniqueLearningOPMSPOCList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
    }
    ////console.log('Filterdata', this.filteredDataList);
    ////console.log('fasond', this.displayList);
    this.displayList = this.displayList.filter(x => x.value != "");
    this.enableFilteredSubStatusList();
  }

  //For enabling the Sub status list for which is only present in the FilteredDataList
  enableFilteredSubStatusList() {
    ////console.log('enablefilter', this.filteredDataList);
    for(let i=0; i<this.filteredDataList.length; i++) {
      this.changedLearningOPMSPOCList = [];
      this.changedLearningOPMSPOCList = this.displayList.filter(item => item.value === this.filteredDataList[i].LearningOPMSPOC);
      this.changedLearningOPMSPOCList[0].isEnabled = true;
      this.changedLearningOPMSPOCList[0].isSelected = false;
    }
    ////console.log('filterdatafadsfads', this.displayList);
    this.preserveSelected();
  }

  //For selecting the Sub status list which was previously selected
  preserveSelected() {
    for(let i=0; i<this.checkedLearningOPMSPOCList.length; i++) {
      this.changedLearningOPMSPOCList = [];
      this.changedLearningOPMSPOCList = this.displayList.filter(item => item.value === this.checkedLearningOPMSPOCList[i]);
      this.changedLearningOPMSPOCList[0].isEnabled = true;
      this.changedLearningOPMSPOCList[0].isSelected = true;
      ////console.log('changeditem', this.changedLearningOPMSPOCList);
    }
    ////console.log('DisplayVenodr', this.displayList);
  }

  updateCheckedOptions(option, event) {
    if(this.checkedLearningOPMSPOCList.indexOf(option) == -1)
      this.checkedLearningOPMSPOCList.push(option);
    else
      this.checkedLearningOPMSPOCList.splice(this.checkedLearningOPMSPOCList.indexOf(option),1);
    ////console.log('checked item', this.checkedLearningOPMSPOCList);
    this._PssEvaluationService.checkedLearningOPMSPOCList = this.checkedLearningOPMSPOCList;
    this.isLearningOPMFiltered.emit(this.checkedLearningOPMSPOCList.length>0);
  }
}
