import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { effectRolebased } from './store/effects';
import { rolebasedReducers } from './store';
import { RolebasedStoreService } from './rolebased-store.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('rolebased', rolebasedReducers),
    EffectsModule.forFeature(effectRolebased)
  ],
  providers:[RolebasedStoreService]
})
export class RolebasedStoreModule { }
