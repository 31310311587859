import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';
import { Globals } from '../../../globals';
import * as fromRoleBasedStore from './store';

@Injectable({
  providedIn: 'root'
})
export class RolebasedStoreService {
  config: Ienvironment;

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private roleBasedStore: Store<fromRoleBasedStore.IrolebasedState>,
    private globals: Globals
  ) {
    this.config = this._envSvc.getEnvironment();
   }
  getRBLPackageofUser(){
    return this.http.get<any>(
      this.config.apiUrl + "RoleBasedLearning/GetRoleBasedPackagesofUser");
  }
}
