import { Component, OnInit, Input, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogConfig,
  MatDialog
} from '@angular/material/dialog';
import { BadgeDetailsComponent } from '../badge-details/badge-details.component';

@Component({
  selector: 'yorbit-learning-badges',
  templateUrl: './learning-badges.component.html',
  styleUrls: ['./learning-badges.component.scss']
})
export class LearningBadgesComponent implements OnInit {
  badges: Array<any>;
  mid: string;
  quarter: string;
  year: string;
  constructor(
    private dialogRef: MatDialogRef<LearningBadgesComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.quarter = data.quarter;
    this.badges = data.context;
    this.mid = data.mid;
    this.year = data.year;
  }
  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  openDialog(context) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      context: context,
      mid: this.mid
    };
    this._dialog.open(BadgeDetailsComponent, dialogConfig);
  }
  spaceTrim(input) {
    if (input) {
      return input.toString().trim();
    } else {
      return '';
    }
  }
}
