import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { forkJoin } from 'rxjs';
import { RiskBrigadeService } from '../../shared/services/risk-brigade.service';
import { WINDOW } from '../../shared/services/window.service';

@Component({
  selector: 'yorbit-risk-brigade-community',
  templateUrl: './risk-brigade-community.component.html',
  styleUrls: ['./risk-brigade-community.component.scss']
})
export class RiskBrigadeCommunityComponent implements OnInit {
 
  isDataLoading:boolean=false;
  video:any;
  speaker: any;
  coreCouncilResponse:any;
  showVideo=true;

  constructor( private _riskBrigadeService:RiskBrigadeService,
              private _graphSvc:GraphDataService,
              @Inject(WINDOW) private _window: any,
              private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getNetworkData();
  }

  getNetworkData(){
    var videoRequest=this._riskBrigadeService.getVideos();
    var speakerRequest=this._riskBrigadeService.getSpeaker();
    var coreCounsilRequest=this._riskBrigadeService.getCoreCouncil();
    this.isDataLoading=true
    forkJoin([videoRequest,speakerRequest,coreCounsilRequest]).subscribe(response=>{
      this.isDataLoading = false;
       var videoResponse:any=response[0];
       var speakerResponse:any=response[1];
       var coreCouncilResponse:any=response[2];
        if(videoResponse){
          this.video= videoResponse.filter((item)=>item.PageLocation=='community')[0]
        }

        if(speakerResponse){
          this.speaker= speakerResponse.filter((item)=>item.PageLocation=='community')[0]
        }
        if(coreCouncilResponse){
          this.getProfileImage(coreCouncilResponse)
        }
    },
    error => {
      this.isDataLoading = false;
    })
  }

  getProfileImage(coreCouncilResponseTmp){

    for (let i = 0; i < coreCouncilResponseTmp.length; i++) {
      if (coreCouncilResponseTmp[i].image == null) {
        this._graphSvc
          .getUserImage(coreCouncilResponseTmp[i].UserId)
          .subscribe(
            data => {
              coreCouncilResponseTmp[i].image = this.createImageURL(data);
              this.coreCouncilResponse=coreCouncilResponseTmp;
            },
            error => {
              coreCouncilResponseTmp[i].image= null;
              this.coreCouncilResponse=coreCouncilResponseTmp;
            }
          );
      }
      
    }



    // var requestArray=[]
    // coreCouncilResponseTmp.forEach(element => {
    //     requestArray.push(this._graphSvc.getUserImage(element.UserId))
    // });
    // console.log(requestArray)
    // forkJoin(requestArray).subscribe(response=>{
    //   console.log(response)
    //   for(var i=0;i<response.length;i++){
    //     coreCouncilResponseTmp[i]['image']=this.createImageURL(response[i]);
    //   }
    //   this.coreCouncilResponse=coreCouncilResponseTmp;
    //   console.log(this.coreCouncilResponse)
    // },(error) => console.log(error))
  }

  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }

  popUpOpened(event){
      this.showVideo=!event;
  }

}
