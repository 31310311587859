import { Action } from "@ngrx/store";

// load role access list
export const ROLE_GET_ACCESS_LIST = '[Role] Get AccessList';
export const ROLE_GET_ACCESS_LIST_FAIL='[Role] Get AccessList Fail';
export const ROLE_GET_ACCESS_LIST_SUCCESS='[Role] Get AccessList Success';
export const ROLE_UPDATE_ACCESS_LIST='[Role] Update AccessList';

export class RoleGetAccessList implements Action{
  readonly type=ROLE_GET_ACCESS_LIST;
}

export class RoleGetAccessListFail implements Action{
  readonly type=ROLE_GET_ACCESS_LIST_FAIL;
  constructor(public payload:any){}
}
export class RoleGetAccessListSuccess implements Action{
  readonly type=ROLE_GET_ACCESS_LIST_SUCCESS;
  constructor(public payload:any){}
}

export class RoleUpdateAccessList implements Action{
  readonly type=ROLE_UPDATE_ACCESS_LIST;
  constructor(public payload:any){}
}


export type RoleAction=
RoleGetAccessList
|RoleGetAccessListFail
|RoleGetAccessListSuccess
|RoleUpdateAccessList
