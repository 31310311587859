import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaylistExternalCourseComponent } from '../../playlist-external-course/playlist-external-course.component';
import { PlaylistService } from '../../playlist.service';

@Component({
  selector: 'yorbit-i-certificate-popup',
  templateUrl: './i-certificate-popup.component.html',
  styleUrls: ['./i-certificate-popup.component.scss']
})
export class ICertificatePopupComponent implements OnInit {
  heading: string = '';
  constructor(
    public dialogRef: MatDialogRef<PlaylistExternalCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private playlistService: PlaylistService
  ) {}

  ngOnInit() {
    this.heading = this.playlistService.popUpHeading;
  }
  close() {
    this.dialogRef.close();
  }
}
