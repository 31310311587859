import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NasscomRecommendationsComponent } from './nasscom-recommendations/nasscom-recommendations.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReusableUiModule],
  declarations: [NasscomRecommendationsComponent],
  exports: [NasscomRecommendationsComponent]
})
export class NasscomModule { }
