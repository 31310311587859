import { Action } from '@ngrx/store';

export const DECLARE_GET_STATUS_ALL_COURSES =
  '[Declare] Get Status For All Courses';
export const DECLARE_GET_STATUS_BY_COURSEIDS =
  '[Declare] Get Status By Course Ids';
export const DECLARE_GET_STATUS_SUCCESS = '[Declare] Get Status Success';
export const DECLARE_GET_STATUS_ERROR = '[Declare] Get Status Error';

export class DeclareGetStatusForAllCourses implements Action {
  readonly type = DECLARE_GET_STATUS_ALL_COURSES;
}
export class DeclareGetStatusByCourseIds implements Action {
  readonly type = DECLARE_GET_STATUS_BY_COURSEIDS;
  constructor(public payload: any) {}
}
export class DeclareGetStatusSuccess implements Action {
  readonly type = DECLARE_GET_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class DeclareGetStatusError implements Action {
  readonly type = DECLARE_GET_STATUS_ERROR;
  constructor(public payload: any) {}
}

export type DeclareAction =
  | DeclareGetStatusForAllCourses
  | DeclareGetStatusByCourseIds
  | DeclareGetStatusSuccess
  | DeclareGetStatusError;
