import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FileUploadPopupComponent } from './file-upload-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadPopupService } from './file-upload-popup.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [FileUploadPopupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [FileUploadPopupComponent],
  providers: [FileUploadPopupService]
})
export class FileUploadPopupModule { }
