import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Video } from '../../../details-page/mobile-course-details-page/mobile-course-details-page.component';
import { Globals } from '../../../globals';
import { PlayerVarsIntroVideoO365 } from '../../../shared/video-players/o365-video-player/o365-player.config';

@Component({
  selector: 'yorbit-banner-with-video',
  templateUrl: './banner-with-video.component.html',
  styleUrls: ['./banner-with-video.component.scss']
})
export class BannerWithVideoComponent implements OnInit {
  introVideoUrl: any;
  videoConfig: Video;
  initializeVideo:boolean = false;

  constructor(
    private globals: Globals,
    private _router: Router,) { }

  ngOnInit(): void {
    this.playVideo();
  }

  openCourse(){
    this._router.navigate(['/browse/academy/1/genre/84/livingmindfully']);
  }

  playVideo()
  {
    let videoId;

  const data = {
    ContentId: this.globals.livingMindFullVideoId,
    UnitId: '',
    CourseId:this.globals.livingMindFullVideoId,
    ItemId: this.globals.livingMindFullVideoId,
    ItemType: 'Content',
    PathId: 0,
    VideoId: this.globals.livingMindFullVideoId,
    sourceUrl: this.globals.livingMindFullyVideoLink
  };
 // console.log(data);
  this.videoConfig = {
    VideoDetails: data,
    VideoPlayerId: 'IntroVideo' + videoId,
    CanCaptureProgress: false,
    PlayerVars: PlayerVarsIntroVideoO365,
    showInfoMsg: false
  };
  setTimeout(() => {
    this.initializeVideo = true;
   // console.log(this.initializeVideo);
  }, 1500);
}
}


