<div fxLayout="column" class="toast-message-box"> 
    <div class="closeSnackBarBtn" fxLayoutAlign="center center" (click)="closeSnackBar()">   
        <mat-icon class="mousePointer" >close</mat-icon> 
    </div>
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="true">
    <div fxFlex="80px" class="toast-date" fxLayout="column">
      <div class="day">{{day}}</div>
      <div class="month">{{ currentMonth + " " + year}}</div>
    </div>
    <div fxFlex="215px" class="toast-message" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="5px">
      <div class="promotion-cycle">{{message +  promotionCycle}}</div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="false">
    <!-- <div fxFlex="330px" class="yorbit-down-msg">Yorbit will be down on 23<sup>rd</sup> and 24<sup>th</sup> of Dec 2019 for a major feature upgrade. Please plan your learning accordingly.</div> -->
    <div fxFlex.gt-xs="280px" class="yorbit-down-msg">
        <!-- Cut-off date to claim Certification Reimbursement for this financial year (2020-21) is 15-Mar-2021. -->
        Certification Reimbursement Cut-off date for FY 2021-22 is 15-Mar-2022
     </div>
  </div>
</div>