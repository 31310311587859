import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validDate'
})
export class ValidDatePipe implements PipeTransform {
  transform(list: Array<any>, args?: any): any {
    let filteredList = new Array();
    let invalidYear = 1;

    list.forEach(element => {
      const date = new Date(element.CompletedDate);
      if (date.getFullYear() != invalidYear) {
        filteredList.push(element);
      }
    });
    return filteredList;
  }
}
