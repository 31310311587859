import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { AppInsightsModule, AppInsightsService } from 'ng2-appinsights';
import { AppInsightService } from './app-insight.service';
@NgModule({
  imports: [
    CommonModule,
   // AppInsightsModule
  ],
  providers: [
   // AppInsightsService
  ]
})
export class AppInsightModule {}
