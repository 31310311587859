import * as fromCloudlabStoreAction from './../actions/cloudlab-store.action';

export interface IcloudlabDetailsReducerState {
  data: IcloudlabData[];
  loading: boolean;
  loaded: boolean;
}
export interface IcloudlabData {
  CourseId: number;
  LabDetails: Array<any>;
  statusMessage: string;
}
export const cloudlabStatusInit = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = cloudlabStatusInit,
  action: fromCloudlabStoreAction.CloudlabAction
): IcloudlabDetailsReducerState {
  switch (action.type) {
    case fromCloudlabStoreAction.CLOUDLAB_GET_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromCloudlabStoreAction.CLOUDLAB_GET_DETAILS_SUCCESS: {
      const data: IcloudlabData[] = updateStatus(state.data, action.payload);
      return {
        ...state,
        data,
        loading: false,
        loaded: true
      };
    }
    case fromCloudlabStoreAction.CLOUDLAB_GET_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
  }
  return state;
}

export const getCloudlabStatus = (state: IcloudlabDetailsReducerState) =>
  state.data;
export const getCloudlabLoading = (state: IcloudlabDetailsReducerState) =>
  state.loading;
export const getCloudlabLoaded = (state: IcloudlabDetailsReducerState) =>
  state.loaded;

function updateStatus(data, payload) {
  let uniqueDetails = [];
  payload.forEach(row => {
    if (data.find(datum => datum.CourseId == row.CourseId)) {
      // data = data.map(cloudData => {
      //   if (cloudData.CourseId !== row.CourseId) {
      //     return row;
      //   }
      //   return cloudData;
      // });
      //do nothing
    } else {
      uniqueDetails.push(row);
    }
  });
  if (uniqueDetails.length > 0) {
    return [...data, ...uniqueDetails];
  }
  return data;
}
