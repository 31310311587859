<div *ngIf="!showPreloaders && !noDataFound" class="filters-container" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="date-and-reset section" fxLayout="row" fxFlex="50px">
    <!--date filters-->
    <div class="date" fxFlex fxLayoutAlign="start center">
      <div fxLayout="row" fxFlexOffset="15px" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div>From</div>
        <div class="date-picker-holder" fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field class="datepickerForm">
            <input matInput [matDatepicker]="startDatePicker" placeholder="From" [min]="minDate"
              [max]="endDate?.value==''?maxDate:endDate.value" (dateChange)="onDateChangeEvent($event, 'start')"
              [formControl]="startDateForm" />
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
          <div class="date-value" fxFlex="140px" fxLayoutAlign="center center"
            [ngClass]="{placeholderstyle:endDate.value==''}">
            {{ startDate.value!=''? (startDate.value| date: "MM/dd/yyyy"):'Choose a date' }}
          </div>
          <div fxFlex="40px" fxLayoutAlign="center center">
            <div class="yorbitSprite calendarSpirteIcon mousePointer" (click)="startDatePicker.open()"></div>
          </div>
        </div>
        <div>To</div>
        <div class="date-picker-holder" fxLayout="row" fxLayoutAlign="start stretch">
          <mat-form-field class="datepickerForm">
            <input matInput [matDatepicker]="endDatePicker" placeholder="To"
              [min]="startdate?.value==''?minDate:startDate.value" [max]="maxDate"
              (dateChange)="onDateChangeEvent($event, 'end')" [formControl]="endDateForm" />
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
          <div class="date-value" fxFlex="140px" fxLayoutAlign="center center"
            [ngClass]="{placeholderstyle:endDate.value==''}">
            {{ endDate.value!=''? (endDate.value| date: "MM/dd/yyyy"):'Choose a date' }}
          </div>
          <div fxFlex="40px" fxLayoutAlign="center center">
            <div class="yorbitSprite calendarSpirteIcon mousePointer" (click)="endDatePicker.open()"></div>
          </div>
        </div>
      </div>
    </div>
    <!--reset all filters-->
    <div class="reset" fxFlex="165px" fxLayout="column" fxLayoutAlign="center center">
      <button fxFlex="35px" class="resetButton mousePointer" aria-label="Reset" (click)="resetFilter()" fxLayout="row"
        fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlexOffset="10px">Reset All Filters</div>
        <div class="yorbitSprite resetSmallSpriteIcon"></div>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="course-and-search section" fxLayout="row">
    <!--Course filters like course academy,vendor,type, name ,etc...-->
    <div class="course-filters" fxFlex>
      <yorbit-course-prop-and-statuses-filter (updateCourseFilters)="updateCourseFilters($event)"
        [selectedTab]="selectedTab"></yorbit-course-prop-and-statuses-filter>
    </div>
    <div class="search-filter" fxFlex="240px" fxLayout="column">
      <!--Search type section - name or mid's or request id's-->
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxFlex>
          <input fxFlex="15px" type="radio" name="searchBy" (click)="setSearchByValue('mid')"
            [checked]="searchBy == 'mid'" />
          <div fxFlex>PSID(s)</div>
        </div>
        <div fxLayout="row" fxFlex>
          <input fxFlex="15px" type="radio" name="searchBy" (click)="setSearchByValue('requestid')"
            [checked]="searchBy == 'requestid'" />
          <div class="requestidstyle" fxFlex>Request ID(s)</div>
        </div>
        <div fxLayout="row" fxFlex>
          <input fxFlex="15px" type="radio" name="searchBy" (click)="setSearchByValue('name')"
            [checked]="searchBy == 'name'" />
          <div fxFlex>Name</div>
        </div>
      </div>

      <!--Search bar-->
      <mat-form-field class="pssSearch" fxFlex="45px" [floatLabel]="'never'" fxLayoutAlign="center center">
        <input matInput [(ngModel)]="searchModel" (ngModelChange)="onSearchModelChange()"
          placeholder="{{searchBy=='mid'?'Search by PSID(s)':searchBy=='requestid'?'Search by Request ID(s)':searchBy=='name'?'Search by Name':''}}"
          [matAutocomplete]="auto"
          [matAutocompleteDisabled]="searchBy != 'name' || (searchBy=='name' && searchModel.length<3)" />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="suggestion-list"
          (optionSelected)="userSelectHandler($event)">
          <mat-option class="option" *ngFor="let user of suggestionList" [value]="user">
            <yorbit-share-user-profile [user]="user" (click)="getMid(user)"></yorbit-share-user-profile>
          </mat-option>
          <mat-option *ngIf="suggestionList.length==0 && !usersListLoaded && searched" class="preloader">
            <app-preloader></app-preloader>
          </mat-option>
          <mat-option *ngIf="suggestionList.length==0 && usersListLoaded && searched" class="preloader">
            No results found
          </mat-option>
        </mat-autocomplete>
        <button mat-button
          *ngIf="(searchModel.length == 0 && searchBy != 'name') || (searchBy == 'name' && SearchMid.length==0)"
          matSuffix mat-icon-button aria-label="search">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-button
          *ngIf="(SearchMid.length != 0 && searchBy =='name') || (searchModel.length != 0 && searchBy !='name')"
          matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchModel()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="showSearchErrorByName" class="error-msg">
        Please enter valid name and select associates from the auto suggestion list.
      </div>
      <div *ngIf="incorrectMIdFormat && !mixedFormatEntries && searchModel.length>0" class="error-msg">
        Please enter valid PSID(s)
      </div>
      <div *ngIf="incorrectReqIDFormat && !mixedFormatEntries && searchModel.length>0" class="error-msg">
        Please enter valid Request ID(s)
      </div>
      <div *ngIf="mixedFormatEntries && searchModel.length>0" class="error-msg">
        Please enter either PSID(s) or Request ID(s)
      </div>
      <div class="placeholder-msg" *ngIf="searchBy == 'requestid'||searchBy == 'mid'">
        Please enter comma/space separated {{searchBy == 'mid'?'PSID(s)':(searchBy == 'requestid'?'Request ID(s)':'')}}
      </div>
      <!--if the selected tab is evaluation requests then the below  evalution type,Due For Publishing,SLA Hit Requests,
        project Requests,Assignment Requests filters are shown-->
      <!--evaluation type-->
      <div fxLayout="column" class="Evaluation-filters" fxLayoutGap="5px" *ngIf="selectedTab == 'evaluation-requests'">
        <div fxFlex="30px" fxLayoutAlign="start start" class="header">Evaluation Type</div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <div fxLayoutAlign="center center">
            <input type="radio" id="internal" value="Internal" [(ngModel)]="filters.EvaluationType" />Internal
          </div>
          <div fxLayoutAlign="center center">
            <input type="radio" id="internal" value="External" [(ngModel)]="filters.EvaluationType" />External
          </div>
          <div fxLayoutAlign="center center">
            <input type="radio" id="internal" value="All" [(ngModel)]="filters.EvaluationType" />All
          </div>
        </div>
        <!--Due for publishing-->
        <div fxLayout="row" fxLayoutAlign="start center">
          <input type="checkbox" id="dueForPublish" [(ngModel)]="filters.DueForPublish" />
          <div>Due for Publish</div>
        </div>
        <!--SLA hit requests-->
        <div fxLayout="row" fxLayoutAlign="start center">
          <input type="checkbox" id="SLAHitRequest" [(ngModel)]="filters.SLAHitRequest" />
          <div>SLA Hit Requests</div>
        </div>
        <!--Project requests-->
        <div fxLayout="row" fxLayoutAlign="start center">
          <input type="checkbox" id="projectRequests" [(ngModel)]="filters.ProjectRequests" />
          <div>Project Requests</div>
        </div>
        <!--Assignment requests-->
        <div fxLayout="row" fxLayoutAlign="start center">
          <input type="checkbox" id="assignmentRequests" [(ngModel)]="filters.AssignmentRequests" />
          <div>Assignment Requests</div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="download-and-apply-filters section" fxLayout="row" fxFlex="50px" fxLayoutAlign="start center">
    <!--Download and upload excels for pss requests-->
    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex class="upload-download-container" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div class="download" fxLayout="row wrap">
          <div class="toggle-download-options" fxLayoutAlign="start center" fxLayoutGap="20px">
            <!-- <mat-slide-toggle
            class="toggle-slider"
            [ngClass]="{'actioned-tab':selectedTab=='certification-actioned'}"
            (change)="changeSubselection()"
            [checked]="downloadAccepted"
            *ngIf="selectedTab=='certification-actioned'"
          >
          <div *ngIf="downloadAccepted">
            Accepted
          </div>
            <div *ngIf="!downloadAccepted">Denied</div>
          </mat-slide-toggle> -->
            <mat-slide-toggle class="toggle-slider" (change)="changeDownloadOptions()" [checked]="!downloadAllSelected">
              <div *ngIf="downloadAllSelected">All</div>
              <div *ngIf="!downloadAllSelected">
                Filtered
              </div>
            </mat-slide-toggle>

          </div>
          <div class="mousePointer yorbitSprite downloadSpriteIcon" title="{{selectedTab=='evaluation-requests'?(downloadAllSelected?'Download all course evaluation requests':'Download filtered course evaluation requests'):(
              selectedTab == 'course-requests'
                ? downloadAllSelected
                  ? 'Download all course requests.'
                  : 'Download course requests based on the applied filter(s).'
                : downloadAllSelected
                ? 'Download all certification requests.'
                : 'Download certification requests based on the applied filter(s).')
            }}" (click)="downloadSelected()"></div>
        </div>
        <button class="upload" fxLayoutAlign="center center" *ngIf="selectedTab == 'course-requests'" fxLayoutGap="10px"
          fxFlex="150px" (click)="uploadPopup()">
          <div fxFlex="100px">Upload Excel</div>
          <div class="mousePointer yorbitSprite uploadSpriteIcon"></div>
        </button>
        <!-- <button class="upload" fxLayoutAlign="center center" *ngIf="selectedTab == 'course-requests'" fxLayoutGap="10px"
          fxFlex="150px" (click)="classRoomUploadPopup()">
          <div fxFlex="100px">ClassRoom Upload</div>
          <div class="mousePointer yorbitSprite uploadSpriteIcon"></div>
        </button> -->
      </div>
    </div>
    <!--Apply filters and call api to fetch data-->
    <div class="apply-search">
      <button fxFlex fxLayoutAlign="center center" class="dateGoButton apply-search" mat-raised-button aria-label="Go"
        (click)="applyFilters()">
        Apply & Search
      </button>
    </div>
  </div>
</div>
<div *ngIf="!showPreloaders && resultsLoaded && selectedTab!='evaluation-requests'" class="results-header" fxFlex="70px"
  fxLayoutAlign="start center">
  <div fxFlex="100px" class="header">Results</div>
  <!--Order Requests By Date or Course Name-->
  <div class="arrange-by-options" fxFlex fxLayoutAlign="end center" fxLayoutGap="15px">
    <div>Arrange By</div>
    <mat-slide-toggle #orderByToggleBtn class="toggle-slider" (change)="orderRequestsBy($event)"
      [checked]="orderBy.type == 'date'">
      <div *ngIf="orderBy.type == 'date'">Date</div>
      <div *ngIf="orderBy.type == 'course name'">Course</div>
    </mat-slide-toggle>
  </div>
  <!--Apply pagination to the course requests-->
  <div class="pagination">
    <div fxLayout="row" fxFlex="300px" fxLayoutAlign="end center">
      <div class="pageSizeDisplayData">
        <mat-paginator class="pssPaginate" [length]="paginateFilter.pageLength"
          [pageSize]="paginateFilter.pageSizeLimit" [hidePageSize]="true" [pageIndex]="paginateFilter.pageIndex"
          (nextPage)="onPaginate($event)" (page)="onPaginate($event)">
        </mat-paginator>
      </div>
      <div class="pageSizeContainer" fxFlex="60px" fxLayoutAlign="center center">
        <mat-form-field class="pageSizeSelect">
          <mat-select matNativeControl (openedChange)="openedChangeMatSelect($event)"
            [(value)]="paginateFilter.pageSizeLimit" panelClass="pssPaginateSelect"
            (selectionChange)="onPageSizeLimitChange($event)" [disableOptionCentering]="true">
            <mat-option *ngFor="let value of pageSizeOptions" [value]="value">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showPreloaders" class="preloaders-section" fxFlex="500px" fxLayoutAlign="center center">
  <app-preloader></app-preloader>
</div>
<div *ngIf="!showPreloaders && noDataFound" fxFlex="400px" fxLayoutAlign="center center">
  No {{selectedTab=='course-requests'?'course requests':(selectedTab=='certification-pending'?'pending
  certification':(this.selectedTab=='certification-actioned'?'actioned certification':''))}} requests found.
</div>
<!--comments to apply filters-->
<div class="subsection" *ngIf="!showPreloaders && !noDataFound && selectedTab=='externalrequest-actioned' || selectedTab=='certification-actioned' "
  fxLayout="column" fxLayoutAlign="start stretch">
  <div fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div class="certification-tabs accepted" [ngClass]="{'selected':downloadAccepted && !isFailed}"
      (click)="changeSubselection('accepted')" fxLayoutAlign="center center" fxFlex="100px">
      Accepted
    </div>
    <div class="certification-tabs denied" [ngClass]="{'selected':!downloadAccepted && !isFailed}"
      (click)="changeSubselection('denied')" fxLayoutAlign="center center" fxFlex="100px">
      Denied
    </div>
    <div class="certification-tabs failed" [ngClass]="{'selected':downloadAccepted && isFailed}"
    (click)="changeSubselection('not cleared')" fxLayoutAlign="center center" fxFlex="100px">
    Failed
  </div>
  </div>
  <mat-divider fxFlex="1px"></mat-divider>
  <div>

  </div>
</div>

<div *ngIf="!showPreloaders && !filtersApplied && !noDataFound" class="noFiltersAppliedInfo" fxFlex="200px"
  fxLayoutAlign="center center">
  Apply filters and search to view results.
</div>