<mat-dialog-content>
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title">
    <div class="title">{{title}}</div>
  </div>
  <div
    class="confirmationDialog"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <div class="publish-info" fxFlex="30px"  fxFlexOffset="10px" fxLayoutAlign="start center">
     {{description}}
    </div>
    <div fxFlex="35px" class="fileUpload" fxLayoutAlign="start stretch">
      <input
              type="file"
              id="error-file-upload"
              YorbitWorkspaceFileModel
              [modifyDOMElements]="true"
              [inputFor]="'pssErrorFileUpload'"
              (fileModel)="fileModel($event)"
            />
            <label
              fxFlex
              for="error-file-upload"
              ><span>Browse</span>
              <div title="" class="input-label">Select a file</div>
            </label>
    </div>
    <mat-divider fxFlex="1px" fxFlexOffset="10px"></mat-divider>
    <div
      class="actionButtonContainer"
      fxFlex="50px"
      fxLayoutAlign="center center"
    >
      <button class="actionButton action-button"
       [ngClass]="{'mouseDisabled':fileNotSelected||showErrorMsg}" [disabled]="fileNotSelected||showErrorMsg" (click)="uploadFile()">
        UPLOAD
      </button>
    </div>
    <div fxFlex="50px" fxLayoutAlign="start center">Choose only .zip file of size less than {{permittedSizeLimit/1000000}}MB. </div>
    <div *ngIf="showErrorMsg" class="errorMsg" fxLayoutAlign="center center">{{errorMsg}}</div>
    <mat-divider fxFlex="1px" fxFlexOffset="10px"></mat-divider>
  </div>
  <div class="footer" fxLayoutAlign="center center">
    <sup>*</sup>{{warningMsg}}
  </div>
</mat-dialog-content>
