import { NotificationsInitState } from '../notifications.model';
import { INotifications } from '../notifications.interface';
import * as NotificationActions from '../actions/notifications.actions';

export function NotificationsReducer(
  state: INotifications = NotificationsInitState,
  actions: NotificationActions.GetNotificationsActions
): INotifications {
  switch (actions.type) {
    case NotificationActions.GET_NEW_NOTIFICATIONS_SUCCESS:
      if (actions.payload == null) {
        actions.payload = state.Payload;
      }
      if (actions.payload.BellResultCollection == null) {
        actions.payload.BellResultCollection = [];
      }
      if (actions.payload.CommonMessageCollection == null) {
        actions.payload.CommonMessageCollection = [];
      }
      if (actions.payload.AdminMessageCollection == null) {
        actions.payload.AdminMessageCollection = [];
      }
      return {
        ...state,
        Payload: actions.payload,
        NewNotificationsLoading: false,
        NewNotificationsLoadedSuccesfully: true
      };
    case NotificationActions.GET_NEW_NOTIFICATIONS_FAILED:
      return {
        ...state,
        NewNotificationsLoading: false,
        NewNotificationsLoadedSuccesfully: false
      };
    case NotificationActions.LOADING_OLD_NOTIFICATIONS:
      return { ...state, OldNotificationsLoading: true };
    case NotificationActions.GET_OLD_NOTIFICATIONS_SUCCESS:
      if (actions.payload == null) {
        actions.payload = state.Payload;
      }
      if (actions.payload.BellResultCollection == null) {
        actions.payload.BellResultCollection = [];
      }
      if (actions.payload.CommonMessageCollection == null) {
        actions.payload.CommonMessageCollection = [];
      }
      if (actions.payload.AdminMessageCollection == null) {
        actions.payload.AdminMessageCollection = [];
      }
      actions.payload.BellResultCollection = [
        ...state.Payload.BellResultCollection,
        ...actions.payload.BellResultCollection
      ];
      actions.payload.CommonMessageCollection = [
        ...state.Payload.CommonMessageCollection,
        ...actions.payload.CommonMessageCollection
      ];
      actions.payload.AdminMessageCollection = [
        ...state.Payload.AdminMessageCollection,
        ...actions.payload.AdminMessageCollection
      ];
      return {
        ...state,
        Payload: actions.payload,
        OldNotificationsLoading: false,
        OldNotificationsLoadedSuccesfully: true
      };
    case NotificationActions.GET_OLD_NOTIFICATIONS_FAILED:
      return {
        ...state,
        OldNotificationsLoading: false,
        OldNotificationsLoadedSuccesfully: false
      };
  }
  return { ...state };
}
