import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DetailsPageService } from '../../details-page/details-page.service';

@Component({
  selector: 'yorbit-rbl-course-popup',
  templateUrl: './rbl-course-popup.component.html',
  styleUrls: ['./rbl-course-popup.component.scss']
})
export class RblCoursePopupComponent implements OnInit {
  coursesList: any;
  pageLoading: boolean;
  pageLoadedSuccessfully: boolean;
  constructor(
    private dialogRef: MatDialogRef<RblCoursePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public splGrpDetails: any,
    private detailsPageService : DetailsPageService,
    private router: Router,
  ) {
    this.coursesList = [];
  }

  ngOnInit(): void {
    // console.log('SPL GRP');
    // console.log(this.splGrpDetails)
    this.getCoursesInsideRBLPackage();
    this.checkOnLocationChange();
  }

  checkOnLocationChange(){
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (
          this.router.url.indexOf('learningpath') != -1 &&
          (this.router.url.indexOf('courseId') != -1 ||
            this.router.url.indexOf('packageId') != -1)
        ) {
          this.dialogRef.close();
        }
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  stopPropagation(event) {
    event.stopImmediatePropagation();
  }

  getCoursesInsideRBLPackage() {
          if(this.splGrpDetails){
            //start
            //this.coursesList = rblData.CoursesWithCategory
            this.pageLoading = false;
            if (this.splGrpDetails.length > 0) {
              let list = [];
              let category=[];
              this.splGrpDetails.forEach((course, index) => {
                list=course.CourseIds.split(',');
                if(-1==category.indexOf(course.Category)){
                  category.push(course.Category);
                  this.coursesList.push({
                    Category:course.Category,
                    Courses:[]
                  })
                }

                list.forEach((courseId)=>{
                this.detailsPageService.getPackageDetails(courseId,"Course").toPromise().then(
                  (response)=>{

                    let courseDetails = {
                      ItemId: response.Id.toString(),
                      RequestId: "",
                      ItemType: response.ItemType,
                      ItemName: response.Name,
                      ItemExpertise: response.Expertise,
                      ImageUrl: '',
                      VideoCount: response.ContentCount,
                      Badges: [],
                      BadgeIds: null,
                      PackageProgress: {
                        Progress: 0,
                        ConsumedTime: 0,
                        TimeLeft: 0,
                        TotalTime: 0
                      },
                      ProgressList: [],
                      Declaration: false,
                      IsMandatory: true,
                      AssignedBy: '',
                      AssignorRole: null,
                      Department: null,
                      IsCancelable: false,
                      AccountPackage: response.AccountPackage,
                      ProjectPackage: response.ProjectPackage,
                      Category: null,
                      AccountId: response.Account,
                      ProjectId: response.Project,
                      WorflowStatus: "",
                      SubStatus: "",
                      DueDate: '0001-01-01T00:00:00',
                      IsDeleted: false,
                      CreatedDate: '0001-01-01T00:00:00',
                      ModifiedDate: '0001-01-01T00:00:00',
                      DeletedDate: '0001-01-01T00:00:00',
                      CompletedDate: '0001-01-01T00:00:00',
                      IsInIDP: false,
                      IsBlocked: false
                    };
                    for (let index = 0; index < this.coursesList.length; index++) {
                      const element = this.coursesList[index];
                      if(this.coursesList[index].Category==course.Category){
                        this.coursesList[index].Courses.push(response);
                      }

                    }
                    //this.coursesList.push(courseDetails);
                    //console.log(courseDetails.ItemId,course.Category)
                  })
                })
              });

            }
            this.pageLoadedSuccessfully = true;

          }else{
            this.coursesList = [];
            this.pageLoading = false;
            this.pageLoadedSuccessfully = true;
          }
  }
}
