import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'yorbit-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  isCustomer: boolean;
  isMobile: boolean;
  constructor(private activatedRoute: ActivatedRoute, private _route: Router) {}

  ngOnInit() {
    this.isCustomer = false;
    const userAgentMobile =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i);
    if (null !== userAgentMobile) {
      this.isMobile = true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if ('customer' === params.forUser.toLowerCase()) {
        this.isCustomer = true;
      }
    });
  }
  gotoBrowse() {
    this._route.navigate(['/browse']);
  }
}
