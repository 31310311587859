import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExpertPopupComponent } from './expert-popup/expert-popup.component';

@Component({
  selector: 'yorbit-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class ExpertComponent implements OnInit {
  @Input() card: any;

  constructor(private _popup: MatDialog) {}

  ngOnInit() {}
  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //expertDialogContainer
    if (this.card.contentData.Experts == undefined) {
      this.card.contentData.Experts = [];
    }
    dialogConfig.data = {
      Title: this.card.contentData.Name,
      Experts: this.card.contentData.Experts,
      expertLocation: this.card.expertLocation
    };
    //this.card.contentData.Experts
    this._popup.open(ExpertPopupComponent, dialogConfig);
  }
}
