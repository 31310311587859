<div class="error-file-download" fxFlex fxLayoutAlign="end center">

  <div class="yorbitSprite icon"
      (click)="openErrorFile(34021)"></div>

      <div fxLayoutAlign="end center">
        <div fxFlexOffset="-20px">{{downloadStatus}}</div>
    </div>

      <div *ngIf="showPreloader">
        <app-preloader></app-preloader>
</div>
<div *ngIf="!contentLoadedSuccessfully && !showPreloader">
    Technical issue in loading content. Please try again
</div>
