import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class CancelCourseRequestService {
  config: Ienvironment;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }
  cancelApprovalRequest(RequestId, reasonForCancelling,courseId) {
    var obj = {
      ItemsIds: [RequestId],
      Comments: reasonForCancelling,
      courseId: [courseId]
    };
    return this.http
      .post(this.config.apiUrl + 'Course/Learner/Cancel', obj)
      .toPromise();
  }
}
