import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PssEvaluationService } from '../pss-evaluation.service';
import { CheckboxList, CourseAcademy, YorbitCourseRequestList, CheckboxDisableList } from '../pss-evaluation.interface';
//import { equalSegments } from '@angular/router/src/url_tree';

@Component({
  selector: 'yorbit-pss-course-name',
  templateUrl: './pss-course-name.component.html',
  styleUrls: ['./pss-course-name.component.scss']
})
export class PssCourseNameComponent implements OnInit {
  searchText: string;
  dataList: YorbitCourseRequestList[] = []; 
  allCourseList: CourseAcademy[] = [];
  filterdCourseList: CourseAcademy[] = [];
  selectedItem: CourseAcademy[] = [];
  changedItem: YorbitCourseRequestList[];
  selectedDataList: CheckboxList[] = [];
  selectedAcademyList: string[] = [];
  uniqueAcademyList: string[] = [];
  checkedCourseAcademy: string;
  displayList: CheckboxDisableList[] =[];
  uniqueCourseList: string[] = [];
  checkedAcademy: string[] = [];
  filteredDataList: YorbitCourseRequestList[] = [];
  filteredAcademyDataList: YorbitCourseRequestList[] = [];
  checkedCourseList: string[];
  changedCourseList: CheckboxDisableList[] = [];
  checkedAcademyList: string[] = [];
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  checkedCourseVendorList: string[] = [];
  checkedEvaluatorVendorList: string[] = [];
  @Output() isCourseNameFiltered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _PssEvaluationService: PssEvaluationService) { }

  ngOnInit() {
    this.allCourseList = [];
    this.dataList = this._PssEvaluationService.dataList;
    this.checkedSubStatusList = this._PssEvaluationService.checkedSubStatusList;
    this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
    this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
    this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
    this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
    this.checkedLearningOPMSPOCList = this._PssEvaluationService.checkedLearningOPMSPOCList;
    this.checkedEvaluatorVendorList = this._PssEvaluationService.checkedEvaluatorVendorList;
    this.selectedAcademyList = this._PssEvaluationService.checkedAcademyList;
    ////console.log('zero',this.selectedAcademyList.length);
    //if(this.checkedCourseList.length > 0)
      //this.GetFilteredCourseList();
    //else
      this.GetCourseList();
    //this.allCourseList = this._PssEvaluationService.selectedCourseList;
  }

  GetCourseList() {
    this.filteredDataList = this._PssEvaluationService.filterRecords('CourseName');
    //////console.log('.....', this.filteredDataList);
    //////console.log('DataListCN', this.dataList);

    //For Displaying all unique Course Name list
      for(let i=0; i<this.dataList.length;i++) {
        if(this.uniqueCourseList.indexOf(this.dataList[i].CourseName) == -1) {
          this.uniqueCourseList.push(this.dataList[i].CourseName);
        }
      }
    //////console.log('Unique', this.uniqueCourseList);
    
    if(this.checkedAcademyList.length > 0) {
      for(let i=0; i<this.checkedAcademyList.length; i++) {
        ////console.log('HIttt');
        this.changedItem = this.dataList.filter(item => item.Academy == this.checkedAcademyList[i])
        //this.filteredDataList = this.changedItem;
        this.changedItem.forEach(element=>{
          this.filteredAcademyDataList.push(element);
        });
      }
      this.uniqueCourseList = [];
      for(let i=0; i<this.filteredAcademyDataList.length;i++) {
        if(this.uniqueCourseList.indexOf(this.filteredAcademyDataList[i].CourseName) == -1) {
          this.uniqueCourseList.push(this.filteredAcademyDataList[i].CourseName);
        }
      }
    }
    ////console.log('UniqueCL', this.filteredAcademyDataList, this.uniqueCourseList );

    //Checking if atleast any one of the filter is applied
    //If no filter is applied, Display all the unique Sub status list without disabling any option
    //and selecting any option
    if(this.checkedCourseVendorList.length == 0 
      && this.checkedCourseTypeList.length == 0
      && this.checkedLearningOPMSPOCList.length == 0
      && this.checkedSubStatusList.length == 0
      && this.checkedCourseVendorList.length == 0
      && this.checkedEvaluatorVendorList.length == 0) {
        ////console.log('True');
        ////console.log('length', this.checkedCourseVendorList.length);
      this.uniqueCourseList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: true
        }
      }).forEach(item=>this.displayList.push(item));
    }
    //If any filter is applied, disable all the options and deselect all the options
    else {
      this.uniqueCourseList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
    }
    ////console.log('Filterdata', this.filteredDataList);
    ////console.log('fasond', this.displayList);
    this.enableFilteredSubStatusList();
  }

  //For enabling the Sub status list for which is only present in the FilteredDataList
  enableFilteredSubStatusList() {
    ////console.log('enablefilter', this.filteredDataList);

    for(let i=0; i<this.filteredDataList.length; i++) {
      this.changedCourseList = [];
      this.changedCourseList = this.displayList.filter(item => item.value === this.filteredDataList[i].CourseName);
      this.changedCourseList[0].isEnabled = true;
      this.changedCourseList[0].isSelected = false;
    }
    ////console.log('filterdatafadsfads', this.displayList);
    this.preserveSelected();
  }

  //For selecting the Sub status list which was previously selected
  preserveSelected() {
    for(let i=0; i<this.checkedCourseList.length; i++) {
      this.changedCourseList = [];
      this.changedCourseList = this.displayList.filter(item => item.value === this.checkedCourseList[i]);
      this.changedCourseList[0].isEnabled = true;
      this.changedCourseList[0].isSelected = true;
      ////console.log('changeditem', this.changedCourseList);
    }
    
    ////console.log('DisplayVenodr', this.displayList);
  }
  //   this.changedItem = [];
  //   this.filteredDataList = [];
  //   if(this.checkedAcademy.length == 0) 
  //     this.filteredDataList = this.dataList;
  //   for(let i=0; i<this.checkedAcademy.length; i++) {
  //     this.changedItem = this.dataList.filter(item => item.Academy == this.checkedAcademy[i])
  //     //this.filteredDataList = this.changedItem;
  //     this.changedItem.forEach(element=>{
  //       this.filteredDataList.push(element);
  //     });
  //   }
  //   if(this.checkedCourseList.length == 0) {
  //     this.filteredDataList.map(item => {
  //       return {
  //         value: item.CourseName,
  //         isSelected: false,
  //         isEnabled: true
  //       }
  //     }).forEach(item=>this.displayList.push(item));
  //   }
  //   else {
  //     this.filteredDataList.map(item => {
  //       return {
  //         value: item.CourseName,
  //         isSelected: false,
  //         isEnabled: true
  //       }
  //     }).forEach(item=>this.displayList.push(item));
  //     for(let i=0; i<this.checkedCourseList.length; i++) {
  //       this.changedCourseList = this.displayList.filter(item => item.value === this.checkedCourseList[i]);
  //       this.changedCourseList[0].isEnabled = true;
  //       this.changedCourseList[0].isSelected = true;
  //     }
  //   }
  //   ////console.log('DataList', this.dataList);
  //   ////console.log('Filtered', this.filteredDataList);
  //   ////console.log('Display', this.displayList);
  // }

  updateCheckedOptions(option, event) {
    if(this.checkedCourseList.indexOf(option) == -1)
      this.checkedCourseList.push(option);
    else
      this.checkedCourseList.splice(this.checkedCourseList.indexOf(option),1);
    this._PssEvaluationService.checkedCourseList = this.checkedCourseList;
    this.isCourseNameFiltered.emit(this.checkedCourseList.length>0);
  }

  GetFilteredCourseList() {
    this.changedItem = [];
    this.filteredDataList = [];
    if(this.checkedAcademy.length == 0) 
      this.filteredDataList = this.dataList;
    for(let i=0; i<this.checkedAcademy.length; i++) {
      this.changedItem = this.dataList.filter(item => item.Academy == this.checkedAcademy[i])
      //this.filteredDataList = this.changedItem;
      this.changedItem.forEach(element=>{
        this.filteredDataList.push(element);
      });
    }
    this.filteredDataList.map(item => {
      return {
        value: item.CourseName,
        isSelected: false,
        isEnabled: true
      }
    }).forEach(item=>this.displayList.push(item));
    for(let i=0; i<this.checkedCourseList.length; i++) {
      this.changedCourseList = this.displayList.filter(item => item.value === this.checkedCourseList[i]);
      this.changedCourseList[0].isEnabled = true;
      this.changedCourseList[0].isSelected = true;
    }  
  }
}
