<mat-dialog-content>
    <!--Close Popup Icon-->
    <div class="closePopupBtn">
        <button class="mat-button mat-icon-button" [disabled]="disableProceed || excelUploadInProgress" [ngClass]="{'disabled':disableProceed || excelUploadInProgress}"
            (click)="closePopup()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <!--Popup Header-->
    <div class="popup-title">
        <div class="heading">
            Upload PSS Template
        </div>
    </div>

    <div class="popup-content" fxLayout="column" fxLayoutAlign="center center">
        <!--Upload & Download-->
        <div class="uploadAndDownload">
            <button mat-raised-button class="downloadTemplateButton"
            (click)="downloadTemplate()" [disabled]="templateDownloadInProgress"
            [ngClass]="{'disabled':templateDownloadInProgress}">
            Download default template</button>
            <div class="messageTextCustom" *ngIf="templateDownloadInProgress">Downloading...</div>
            <div class="pssFileUploadLabel">
                <input type="file" id="pssFileUpload" #file (change)="onFilesAdded(file.files, $event)" />
                <div class="fileNameLabel">{{fileName}}</div>
                <button mat-raised-button class="browseFileButton" (click)="file.click()">Browse</button>
            </div>
        </div>

        <!--No Experts Message-->
        <div class="messageText">
            Note: Please clear browser cache (Ctrl+F5) and rename the file uniquely before every upload.
        </div>

        <div class="actionButtonContainer">
            <button class="actionButton action-button" [disabled]="isUploadButtonDisabled || disableProceed || excelUploadInProgress"
            [ngClass]="{'disabled':isUploadButtonDisabled || disableProceed || excelUploadInProgress}"
                (click)="upload()">Upload</button>
            <button class="actionButton action-button" [disabled]="disableProceed || excelUploadInProgress" 
            [ngClass]="{'disabled':disableProceed || excelUploadInProgress}"
            (click)="closePopup()">Cancel</button>
        </div>
        <div class="statusMessage" [ngStyle]="{'color': isUploadSuccess ? 'green' : 'red'}" *ngIf="''!==actionMessage">
            {{actionMessage}}
        </div>
    </div>
</mat-dialog-content>
