import { Component, OnInit, Inject } from '@angular/core';

import { PssEvaluationService } from './../pss-evaluation.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-error-download-popup',
  templateUrl: './error-download-popup.component.html',
  styleUrls: ['./error-download-popup.component.scss']
})
export class ErrorDownloadPopup implements OnInit {
  
   requestId: string | number;
  evaluationType:string;
  requestUrls: any = [];
  urls = [];
  interval: any;
  constructor(
    private _PssEvaluationService: PssEvaluationService,
    public dialogRef: MatDialogRef<ErrorDownloadPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
   }

  ngOnInit() {
    this.urls = [];
    ////console.log(this.data, 'to bind to requestURL chkbox');
 /*    if ( this.requestId ) {
      this._PssEvaluationService.getLearnerSolutionFile(this.requestId,this.evaluationType).subscribe(data=>{
        ////console.log(data);
        this.requestUrls = data;
      })
     /*  .catch(function(error){
        this.showPreloader=false;
        this.contentLoadedSuccessfully=false;
        this.isProblemStatementAvailable=false;
      }) */
  //  } */
  }

  selectURL(event: any , url: string) {
    ////console.log('event', event);
    if (event.target.checked) {
      const idx = this.urls.indexOf(url);
      if (idx === -1) {

        
        this.urls.push(url);
      }
    } else {
      const idx = this.urls.indexOf(url);
      if (idx > -1) {
        this.urls.splice(idx, 1);
      }
    }
  }

  Download() {
    if (this.urls.length > 1) {
      ////console.log(this.urls);
      const urls = this.urls;
      this.downloadFiles(urls)
      // this.interval = setInterval(() => {
      //   this.downloadFiles(urls)
      // }, 500)
    }
  }

  downloadFiles(urls) {
    // const url = urls.pop();
    urls.forEach((element) => {
      const a = document.createElement('a');
      a.setAttribute('href', element);
      a.setAttribute('download', '');
      a.setAttribute('target', '_blank');
      a.click();
    });
    
    
    // if (urls.length === 0) {
    //   clearInterval(this.interval)
    // }
  }

  
  closePopup() {
    this.dialogRef.close();
  }

}
