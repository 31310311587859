import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector
} from '@ngrx/store';

import * as fromMyPageReducer from './my-page.reducer';
import { IMyPageReducerState } from './my-page.reducer';

export const getMyPageState = createFeatureSelector<
  fromMyPageReducer.IMyPageReducerState
>('MyPage');

export const getMyPageReducerState = createSelector(
  getMyPageState,
  (state: fromMyPageReducer.IMyPageReducerState) => state
);

export const getMyPageHistory = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getHistoryData
);
export const getMyPageHistoryLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getHistoryDataLoading
);
export const getMyPageHistoryLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getHistoryDataLoaded
);

export const getMyPageCompliance = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getComplianceData
);
export const getMyPageComplianceLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getComplianceDataLoading
);
export const getMyPageComplianceLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getComplianceDataLoaded
);

export const getMyPageSkillProfile = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getSkillProfileData
);
export const getMyPageSkillProfileLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getSkillProfileDataLoading
);
export const getMyPageSkillProfileLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getSkillProfileDataLoaded
);

export const getMyPageCoreSkills = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCoreSkillsData
);
export const getMyPageCoreSkillsLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCoreSkillsDataLoading
);
export const getMyPageCoreSkillsLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCoreSkillsDataLoaded
);

export const getMyPageSupportSkills = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getSupportSkillsData
);
export const getMyPageSupportSkillsLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getSupportSkillsDataLoading
);
export const getMyPageSupportSkillsLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getSupportSkillsDataLoaded
);

export const getMyPageCrossSkills = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCrossSkillsData
);
export const getMyPageCrossSkillsLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCrossSkillsDataLoading
);
export const getMyPageCrossSkillsLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCrossSkillsDataLoaded
);

export const getMyPageCrossSkillPath = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCrossSkillPathData
);
export const getMyPageCrossSkillPathLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCrossSkillPathDataLoading
);
export const getMyPageCrossSkillPathLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getCrossSkillPathDataLoaded
);

export const getMyPagePrimarySkill = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getPrimarySkillData
);
export const getMyPagePrimarySkillLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getPrimarySkillDataLoading
);
export const getMyPagePrimarySkillLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getPrimarySkillDataLoaded
);

export const getMyPageFutureSkills = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getFutureSkillsData
);
export const getMyPageFutureSkillsLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getFutureSkillsDataLoading
);
export const getMyPageFutureSkillsLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getFutureSkillsDataLoaded
);

export const getMyPageFutureSkillPath = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getFutureSkillPathData
);
export const getMyPageFutureSkillPathLoading = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getFutureSkillPathDataLoading
);
export const getMyPageFutureSkillPathLoaded = createSelector(
  getMyPageReducerState,
  fromMyPageReducer.getFutureSkillPathDataLoaded
);
export * from './my-page.reducer';
