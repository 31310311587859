<!-- <h2 mat-dialog-title>Delete all</h2> -->
<mat-dialog-content>
  <div class="closePopupBtn">
    <!-- <mat-icon class="floatRight close-icon" (click)="close()">close</mat-icon> -->
    <button class="mat-button mat-icon-button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center">
    <div class="course-req-pop-up-content">
      <div class="msg">{{ message }}</div>
      <div class="btn-holder">
        <button [mat-dialog-close]="true" class="action-button">OK</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
