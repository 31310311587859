import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoleBasedStore from '../rolebased-store/store';
import * as fromUserDetailsStore from './../../user-details/store'
import * as fromDeclareStore from '../../declare-store/store';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RblCoursePopupComponent } from '../../../role-based-learning/rbl-course-popup/rbl-course-popup.component';
import { RoleBasedLearningStoreService } from '../../role-based-learning-store/role-based-learning-store.service';

@Component({
  selector: 'yorbit-rbl-package',
  templateUrl: './rbl-package.component.html',
  styleUrls: ['./rbl-package.component.scss']
})
export class RblPackageComponent implements OnInit {
  infoTileMessage: string;
  infoTileMessageAdvance:string;
  packageData: { UniqueId: string; ContentCount: number; IsAdvanced: boolean; Family: string; Role: string; Level: string; LevelSalaryGrade: string; SpecializationGroup: string; CoursesWithCategory: { CourseIds: any; Category: string; }[]; Specialization:string;};
  competency: string;

  @Input('contentData') contentData;
  @Input('contentLocation') contentLocation; // Use "LP" or "Browse"
  @Input('splData') splData; // Spl from browse page only; null for LP

  packageProgress: string;
  packagecompletionNote: string;
  isAdded: boolean;


  constructor(
    private roleBasedStore: Store<fromRoleBasedStore.IrolebasedData>,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private declareStore: Store<fromDeclareStore.IdeclareState>,
    private router: Router,
    private _dialog: MatDialog,
    private rblStoreService: RoleBasedLearningStoreService
  ) {
    this.infoTileMessage="";
    this.infoTileMessageAdvance="";

    this.packageProgress="3%";
    this.packagecompletionNote="";
   }

  ngOnInit(): void {
    //console.log("RBL",this.contentData);

    if("LP"==this.contentLocation){
      this.setupRBLLpData();
    }else{
      this.setupData();
    }
  }

    /*
    this.packageData={
      "UniqueId": "P_01",
      "ContentCount": 5,
      "IsAdvanced": false,
      "Family": "Testing",
      "Role": "SDET",
      "Level": "SDET",
      "LevelSalaryGrade": "SDET.C1",
      "SpecializationGroup": "Application",
      "CoursesWithCategory": [
      {
      "CourseIds": "BH_255,BH_256,BH_257",
      "Category": "Behavioral Skills"
      },
      {
      "CourseIds": "DI_1032,DI_1033",
      "Category": "Engineering Skills"
      }
      ]
      };
      */


      setupInfoTileMessage(){
        if(this.packageData.IsAdvanced){
          //this.infoTileMessage="*Advance Package under your role has been already added to the 'Role based Learning' section in Your LP and it will be activated once you finish the Mandatory Package";
          this.infoTileMessageAdvance="* Advance Package under your role will be activated once you finish the Basic Package. Then you can add it to the 'Role Based Learning' section in your LP"
        }else{
          this.rblStoreService.getEmployeeRoleTagging().subscribe(
            (empRoleTagging:any) => {
              if(empRoleTagging.Family==this.packageData.Family &&
                empRoleTagging.Role==this.packageData.Role &&
                empRoleTagging.Level==this.packageData.Level &&
                empRoleTagging.Family==this.packageData.Family &&
                empRoleTagging.Specialization==this.packageData.Specialization
                ){
                this.infoTileMessage="* Basic Package under your role has been already added to the 'Role based Learning' section in your LP";
              }
            },
            error => {}
          );
        }
      }
    setupRBLLpData(){
      if(this.contentData){
        this.roleBasedStore
        .select(fromRoleBasedStore.getRolebasedDetailsByPackageId(this.contentData.ItemId))
        .subscribe(rblData => {
          ////console.log("badges",badge);
          if(rblData){
            this.packageData=rblData;
            this.competency=rblData.LevelSalaryGrade.split('.')[1];
            this.getContentCount();
            this.calculateProgress();
          }
        });
    }
  }

  calculateProgress(){
    this.packageProgress="3%";
    this.packagecompletionNote="";

    let completed=0;
    if(this.packageData.CoursesWithCategory){
      this.packageData.CoursesWithCategory.forEach(course => {
        //element.CourseIds

        let courseIdList =[];

        if("string"== typeof course.CourseIds){
          course.CourseIds.split(',').forEach((id)=>{
            courseIdList.push(id);
          })
        }else{
          course.CourseIds.forEach((id)=>{
            courseIdList.push(id);
          })
        }


        courseIdList.forEach(element => {
        this.userDetailsStore
        .select(
          fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(element)
          ).subscribe(courseDetails => {
            if (courseDetails && courseDetails.CourseId) {


              if (
                courseDetails.WorflowStatus &&
                courseDetails.WorflowStatus != null &&
                (courseDetails.WorflowStatus.toLowerCase() ==
                  'course completed' ||
                  courseDetails.WorflowStatus.toLowerCase() == 'completed')
              ) {
                completed++;
              } else {
                  this.userDetailsStore
                    .select(
                    fromUserDetailsStore.getBadgesByCourseIdSelector(
                      courseDetails.CourseId
                    )
                    )
                    .subscribe(badge => {
                      if (badge&&(badge.Status == true || badge.Status == 'true')) {
                        completed++;
                        /**
                         *
                         this.declareStore
                         .select(
                           fromDeclareStore.getDeclareStatusByCourseId(
                             courseDetails.CourseId
                             )
                             )
                             .subscribe(declareStatus => {
                               //console.log('declare status', declareStatus);
                            if (
                              declareStatus != undefined &&
                              declareStatus.DeclareFlagStatus ==
                              'DeclaredAndApproved'
                              ) {
                              } else {
                                //no change
                              }
                            });
                            */
                      }
                    });

              }





        }
      });

    });
      });

      //Percentage calculation
      this.packagecompletionNote=completed+"/"+this.packageData.ContentCount;
      if(completed>0){
        let percentage=(completed/this.packageData.ContentCount)*100;
        this.packageProgress=percentage+"%";
      }

    }
  }

getContentCount(){
  let CoursesWithCategory: any= this.packageData.CoursesWithCategory;
  let count=0;
  for (let i = 0; i < CoursesWithCategory.length; i++) {
    let elements = CoursesWithCategory[i].CourseIds;
    if("string"== typeof elements){
      count+=elements.split(',').length;
    }else{
      count+=elements.length;
    }
  }

  this.packageData.ContentCount = count;
}

    setupData(){
      let payload={
        ContentCount: this.contentData.ContentCount,
        CoursesWithCategory:this.contentData.CoursesWithCategory,
        Expertise: this.contentData.Expertise,
        IsAdvanced: this.contentData.IsAdvanced,
        PackageName: this.contentData.PackageName,
        UniqueId: this.contentData.UniqueId,

        Family: this.splData.Family,
        Id: this.splData.Id,
        Level: this.splData.Level,
        LevelSalaryGrade: this.splData.LevelSalaryGrade,
        Role: this.splData.Role,
        Specialization: this.splData.Specialization,
        SpecializationGroup: this.splData.SpecializationGroup
      }

       this.packageData=payload;
       this.getContentCount();
       this.competency=payload.LevelSalaryGrade.split('.')[1];
       this.buttonSetup();
       this.setupInfoTileMessage();
  }

  buttonSetup(){
    this.roleBasedStore
        .select(fromRoleBasedStore.getRolebasedDetailsByPackageId(this.splData.Id))
        .subscribe(rblData => {
          //console.log("Button",rblData);
          if(rblData){
            this.isAdded=true;
          }else{
            this.isAdded=false;
          }
        });
  }

  gotoOrAddButton(){
    if(this.isAdded){
      this.navigateTo();
    }else{

    }
  }

  navigateTo() {
    //click action
    //send other route data to highlight the content in LP
    ///category/mandatory/id/Role%20Based%20Learning
      this.router.navigate(
        [
          'learningpath/category/mandatory/id/' + "Role Based Learning"
        ],
        {
          queryParams: {
            lpId: "Role Based Learning"
          }
        }
      );

  }

  openPopup(){
    if("LP"!=this.contentLocation){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = this.contentData.CoursesWithCategory;
      const declarationPopup = this._dialog.open(
        RblCoursePopupComponent,
        dialogConfig
      );
      declarationPopup.afterClosed().subscribe(payload => {});
    }
  }

}
