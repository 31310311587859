import { Action } from '@ngrx/store';

export const GET_NEW_NOTIFICATIONS = '[Notifications] Get New Notifications';
export const GET_NEW_NOTIFICATIONS_SUCCESS =
  '[Notifications] Get New Notifications Success';
export const GET_NEW_NOTIFICATIONS_FAILED =
  '[Notifications] Get New Notifications Failure';

export const GET_OLD_NOTIFICATIONS = '[Notifications] Get Old Notifications';
export const LOADING_OLD_NOTIFICATIONS =
  '[Notifications] Loading Old Notifications';
export const GET_OLD_NOTIFICATIONS_SUCCESS =
  '[Notifications] Get Old Notifications Success';
export const GET_OLD_NOTIFICATIONS_FAILED =
  '[Notifications] Get Old Notifications Failure';

export const UPDATE_ALL_NOTIFICATIONS =
  '[Notifications] Update AllNotifications';

export class GetNewNotifications implements Action {
  readonly type = GET_NEW_NOTIFICATIONS;
  constructor(public payload: any) {}
}

export class GetNewNotificationsSuccess implements Action {
  readonly type = GET_NEW_NOTIFICATIONS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetNewNotificationsFailure implements Action {
  readonly type = GET_NEW_NOTIFICATIONS_FAILED;
}
export class GetOldNotifications implements Action {
  readonly type = GET_OLD_NOTIFICATIONS;
  constructor(public payload: any) {}
}
export class LoadingOldNotifications implements Action {
  readonly type = LOADING_OLD_NOTIFICATIONS;
}
export class GetOldNotificationsSuccess implements Action {
  readonly type = GET_OLD_NOTIFICATIONS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetOldNotificationsFailure implements Action {
  readonly type = GET_OLD_NOTIFICATIONS_FAILED;
}
export type GetNotificationsActions =
  | GetNewNotifications
  | GetNewNotificationsSuccess
  | GetNewNotificationsFailure
  | GetOldNotifications
  | LoadingOldNotifications
  | GetOldNotificationsSuccess
  | GetOldNotificationsFailure;
