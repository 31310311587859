import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { APIResponse, Messages } from './../course-request.interface';

@Component({
  selector: 'yorbit-course-request-popup',
  templateUrl: './course-request-popup.component.html',
  styleUrls: ['./course-request-popup.component.scss']
})
export class CourseRequestPopupComponent implements OnInit {
  message: string;
  context: string;

  constructor(
    private dialogRef: MatDialogRef<CourseRequestPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.context = data.context;
    }

  ngOnInit() {
    this.setMessageOnLoad();
  }

  setMessageOnLoad() {
    switch (this.context) {
      case APIResponse.Success:
        this.message = Messages.SuccessMsg;
        break;
      case APIResponse.Error:
        this.message = Messages.ErrorMsg;
        break;
      default:
        this.dialogRef.close();
        break;
    }
  }
  close(){
    this.dialogRef.close();
  }
}
