import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutesRoutingModule } from './app-routes-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [CommonModule, NgxPaginationModule, AppRoutesRoutingModule],
  declarations: [],
  exports: [AppRoutesRoutingModule]
})
export class AppRoutesModule {}
