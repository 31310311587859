import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { UserChoiceOnAssessmentInterface } from '../../learning-path/behavioral-assessment/behavioral-assessment.model';
import { CanDeactivate } from '@angular/router';
import { AssessmentQuizeComponent } from '../../learning-path/behavioral-assessment/assessment-quize/assessment-quize.component';

@Injectable({
  providedIn: 'root'
})
export class BehavioralAssessmentService {
  config: Ienvironment;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }


  getQuizeDetails() {
    return this.http.get(this.config.apiUrl + 'Assessment/QuizDetails/BehaviouralAssessments').toPromise();
  }

  userChoice(userChoiceOnAssessmentModelArray:UserChoiceOnAssessmentInterface) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let promise = new Promise((resolve, reject) => {
        this.http
          .post(this.config.apiUrl + 'Assessment/UserChoice',JSON.stringify(userChoiceOnAssessmentModelArray),{headers: headers})
          .toPromise()
          .then(data => {
            resolve(data);
          });
    });
    return promise;
  }

  generateScore(MId?:any) {
    return this.http
    .post<any>(
      this.config.apiUrl + 'Assessment/Scores/' + MId,
      {}
    )
    .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  generateReport(MId?:any) {
    return this.http
    .post<any>(
      this.config.apiUrl + 'Assessment/PDFConvertor/' + MId,
      {}
    )
    .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getBlobLink(Mid:any){
    return this.http.get<any>(this.config.apiUrl+'Assessment/GetBlobLinks/'+Mid,{})
    .pipe(catchError((error:any)=>throwError(()=> error.json())));
  }
  

  downloadTemplateForUpload() {
    return this.http
      .get<any>(this.config.apiUrl + 'Assessment/Download/BAAssignTemplate', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
  
}




@Injectable()
export class AssessmentGuardDeactivate implements CanDeactivate<AssessmentQuizeComponent> {
  canExitQuiz: boolean;
  constructor(private quizService: BehavioralAssessmentService) {}
  canDeactivate(component: AssessmentQuizeComponent): boolean {
    if(this.canExitQuiz==false){
      let msg = 'Are you sure you want to exit Assessment?';
      let res = confirm(msg);
      return res;
    }
    return true;
  }
  
}