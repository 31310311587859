import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CourseRequestComponent } from './course-request.component';
import { CourseRequestService } from './course-request.service';
import { CourseRequestPopupComponent } from './course-request-popup/course-request-popup.component';
import {ReusableUiModule} from '@YorbitWorkspace/reusable-ui';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,

    MatIconModule,
    MatButtonModule,
    MatDialogModule,


    FormsModule,
    ReusableUiModule
  ],
  declarations: [CourseRequestComponent, CourseRequestPopupComponent],
  providers:[CourseRequestService],
  
})
export class CourseRequestModule { }
