<ng-container *ngIf="showsTooltip">
    <div
      class="tooltip"
      [style.top]="topPosition + 'px'"
      [style.left]="leftPosition + 'px'"
      >
      <div class="iSupport" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px" [ngClass.xs]="['xs']">
        <div fxLayout="row" fxLayoutGap="10" class="HRTAB"><span fxFlex="25%"><b>Step1</b></span> <span fxFlex="75%">Click on HR Tab >></span> </div>
        <div fxLayout="row" fxLayoutGap="10"><span fxFlex="25%"><b>Step2</b></span> <span fxFlex="75%">Shoshin school - learning and Development >></span></div>
        <div fxLayout="row" fxLayoutGap="10"><span fxFlex="25%"><b>Step3</b></span> <span fxFlex="75%">Learning Platform - Shoshin (Technical Issues)</span></div>
      </div>
    </div>
  </ng-container>