import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromDeclareAction from './../actions/declare-store.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DeclareStoreService } from '../../declare-store.service';

@Injectable()
export class DeclareEffect {
  constructor(
    private actions$: Actions,
    private declareStoreService: DeclareStoreService
  ) {}

  
  getDeclareStatusFlagForAll$ = createEffect(() => this.actions$.pipe(
    ofType(fromDeclareAction.DECLARE_GET_STATUS_ALL_COURSES),
      switchMap((action: fromDeclareAction.DeclareGetStatusForAllCourses) => {
        return this.declareStoreService
          .getDeclareWorkflowStatusForAllCourses()
          .pipe(
            map(declareFlagStatus => {
              return new fromDeclareAction.DeclareGetStatusSuccess(
                declareFlagStatus
              );
            }),
            catchError(error =>
              of(new fromDeclareAction.DeclareGetStatusError(error))
            )
          );
      })
    ));
  getDeclareStatusFlagByCourseIds$ = this.actions$.pipe(
    ofType(fromDeclareAction.DECLARE_GET_STATUS_BY_COURSEIDS),
      switchMap((action: fromDeclareAction.DeclareGetStatusByCourseIds) => {
        return this.declareStoreService
          .getDeclareWorkflowStatus(action.payload)
          .pipe(
            map(declareFlagStatus => {
              return new fromDeclareAction.DeclareGetStatusSuccess(
                declareFlagStatus
              );
            }),
            catchError(error =>
              of(new fromDeclareAction.DeclareGetStatusError(error))
            )
          );
      })
    );
}
