<mat-dialog-content>
  
  <div fxLayout="column"  [ngStyle]="{'min-height':quizDetails.IsTimeFramedQuiz ? '185px' : '160px' }">
    <ng-template
          *ngIf="showInstructions && !showTimeUp && !showConfirmation" 
          [ngTemplateOutlet]="instructions"
        ></ng-template>

    <ng-template *ngIf="showTimeUp && !showInstructions && !showConfirmation" [ngTemplateOutlet]="timeUp"></ng-template>

    <ng-template *ngIf="showConfirmation && !showInstructions && !showTimeUp" [ngTemplateOutlet]="confirmation"></ng-template>

    <ng-template #timeUp>
      <div class="closePopupBtn">
        <button class="mat-button mat-icon-button" (click)="closeDialog()">
              <mat-icon>close</mat-icon>
            </button>
      </div>
      <div class="popup-title">
        <div class="heading-custom">Quiz Time-Out</div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px" class="timeUpPopup">
        <div class="messageDiv">
          <span class="timeUpText">Sorry, you have exceeded the quiz time limit. Click 'OK' to view score.</span>
        </div>
      </div>
      <div class="okButtonDiv">
        <button class="actionButton action-button okayButton" (click)="closeDialog()">
            OK
          </button>
      </div>
    </ng-template>


    <ng-template #instructions>
      <div class="closePopupBtn">
        <button class="mat-button mat-icon-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
      </div>
      <div class="popup-title">
        <div class="heading-custom">Quiz instructions for {{quizDetails.CourseName}} course</div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px" class="popup-content">
        <ol>
          <li *ngIf="quizDetails.IsTimeFramedQuiz">Quiz will automatically terminate if you fail to attempt the same within the given time. The system will consider
            the questions answered within the time frame for evaluation.</li>
          <li>Shoshin School quiz works best on Chrome broswer.</li>
          <li>Questions where a
            <div class="radio yorbitSprite"></div> is displayed against the options, has ONE correct answer.</li>
          <li>Questions where a
            <div class="checkbox yorbitSprite"></div> is displayed against the options, have TWO or MORE correct answers.</li>
          <li>Answered question(s) will be displayed as
            <div class="question-number expertise{{quizDetails.Expertise}} answered">1</div> and not answered question(s) will be displayed as
            <div class="question-number expertise{{quizDetails.Expertise}}">1</div>
          </li>

        </ol>
      </div>
      <div class="action-btn-holder">
        <button class="actionButton action-button" (click)="closeDialog()">
      Close
    </button>
      </div>
    </ng-template>

    <ng-template #confirmation>
      <div class="closePopupBtn">
        <button class="mat-button mat-icon-button" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="popup-title">
        <div class="heading-custom">Submit Quiz</div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px" class="popup-content confirmation">
        <div>
          Are you sure you want to submit the quiz?
        </div>
      </div>
      <div class="action-btn-holder">
        <button class="actionButton action-button yes-button" (click)="closeConfirmation('Yes')">
          Yes
        </button>
        <button class="actionButton action-button" (click)="closeConfirmation('No')">
          No
      </button>
        <div class="timerStyle" *ngIf="quizDetails.IsTimeFramedQuiz">{{timerMessage}}</div>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>