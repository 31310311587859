<!-- RBL Package content tile -->

<div class="rblPackageTileContainer" *ngIf="packageData"
[ngClass]="{mousePointer:'LP'==contentLocation}">

  <div class="packageHeader">
    <div class="fillerContainer">
      <div class="fillers">
        <div class="filler"></div>
        <div class="filler"></div>
        <div class="filler"></div>
      </div>
    </div>

    <div class="title">
      <div class="competency">{{competency}}</div>
      <div class="application">
        <div class="splGroupText textTruncate"  title="{{packageData.SpecializationGroup}}">
          {{packageData.SpecializationGroup}}
        </div>
        <div class="splText textTruncate" title="{{packageData.Specialization}}">
          {{packageData.Specialization}}
        </div>
      </div>
    </div>
  </div>

  <div class="packageContainer {{packageData.IsAdvanced?'advanced':'mandatory'}}">
    <div (click)="openPopup()" class="courseNo mousePointer">{{packageData.ContentCount}} Course{{(packageData.ContentCount>1)?'s':''}}</div>


    <div class="frlContainer">
      <div class="IconContainer">
        <div class="frlIcon">F</div>
        <div class="frlIcon">R</div>
        <div class="frlIcon">L</div>
      </div>

      <div class="timelineContainer">
        <div class="timelineDot"></div>
        <div class="timelineLine"></div>
        <div class="timelineDot"></div>
        <div class="timelineLine"></div>
        <div class="timelineDot"></div>
      </div>

      <div class="frlTextContainer">
        <div class="frlText textTruncate" title="{{packageData.Family}}">{{packageData.Family}}</div>
        <div class="frlText textTruncate" title="{{packageData.Role}}">{{packageData.Role}}</div>
        <div class="frlText textTruncate" title="{{packageData.Level}}">{{packageData.Level}}</div>
      </div>

    </div>


    <div class="contentTile">{{packageData.IsAdvanced?'Advanced':'Basic'}}</div>
  </div>

  <div class="actionContainer mousePointer">
    <div class="actionText" [ngClass]="{'btn-disabled': !isAdded}"
     *ngIf="'LP'!=contentLocation" (click)="gotoOrAddButton()">
      {{isAdded?"GO TO LEARNING PATH":"ADD"}}
    </div>

    <div class="actionTexttwo" *ngIf="'LP'==contentLocation">
      <div class="progress"> {{packagecompletionNote}} </div>
      <div class="progressBar">
        <div class="progressComplete" [ngStyle]="{'width':packageProgress}"
        ></div>
      </div>
    </div>
  </div>

  <div class="adv-package-text" *ngIf="packageData.IsAdvanced && 'LP'!=contentLocation">
    PACKAGE {{splData.packageIndex}}
  </div>

  <div class="noteMessage" *ngIf="'LP'!=contentLocation">
    <span *ngIf="!packageData.IsAdvanced">{{infoTileMessage}}</span>
    <span *ngIf="packageData.IsAdvanced">{{infoTileMessageAdvance}}</span>
  </div>

</div>
