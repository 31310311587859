import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-pss-publish-popup',
  templateUrl: './pss-publish-popup.component.html',
  styleUrls: ['./pss-publish-popup.component.scss']
})
export class PssPublishPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private dialogRef: MatDialogRef<PssPublishPopupComponent>
  ) {
    this.data = data;
  }

  ngOnInit() {}
  closeDialog(data) {
    this.dialogRef.close(data);
  }

}
