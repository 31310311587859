<div class="video-container"  [ngClass]="{'hidePlayer':isLinkBroken}" fxLayout="row" fxLayoutAlign="center center" [ngStyle.xs]="{'height.px':'320'}">
<div id="{{videoPlayerId}}" [hidden]="videoPlayerLoading">
    video player loading...
</div>
<app-preloader class="videoLoading" *ngIf="videoPlayerLoading && !isLinkBroken"></app-preloader>
<div class="videoLoading" *ngIf="!videoPlayerLoading && isLinkBroken" fxFlexFill fxLayoutAlign="center center" fxLayout.lt-lg="column" fxLayout.gt-md="row" fxLayoutGap="5px">
    <div>Sorry! this video does not exist.</div><div fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="5px"><div>Please raise a ticket on </div><a href={{globals.isupportUrl}} target="_blank"> {{globals.isupportUrl}} </a></div><div> for further assistance.</div>
</div>
</div>


