
<div fxLayout="column" class="speaker-container">
  <div class="row" >
    <div class="profile" fxFlex="30" fxLayoutAlign="center center" fxLayoutGap="15px">
          <img fxFlex="100px" *ngIf="coreCouncil.image!=null" [src]="coreCouncil.image" alt="">
          <div fxFlex="100px" class="icon" *ngIf="coreCouncil.image==null">
                {{getNameInitials()}}
          </div>
    </div>
    <div class="line"></div>
    <div class="topic-desc" fxFlex="80" fxLayout="column">

      <div fxLayout="row" fxLayoutAlign="space-between center" >
          <div class="title">
          {{coreCouncil?.Name}}
          </div>
          <a class="corecouncilstyle" href="mailto: {{coreCouncil?.EmailId}}">
          <div  fxLayout="row" class="email" fxLayoutAlign="center center">
            <span>Write to us</span>
            <img src="../../assets/images/riskbrigade/icon-email.png"  >
          </div>
          </a>
      </div>
       
        <div class="userid">
          ({{coreCouncil?.UserId}})
        </div>
        <div class="desc">
          {{coreCouncil?.Description}}
        </div>
    </div>
  
  </div>
  <div class="stripe"></div>
</div>
