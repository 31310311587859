<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div class="searchDiv">
      <input [(ngModel)]="searchText" type="text" class="textBoxStyle" placeholder="Search by course type">
  </div>
  <div class="font12 scrollHorizontal row floatLeft" fxLayout="column wrap" fxLayoutAlign="start start">
<div class="courseTypeList font12" *ngFor='let courseType of displayList | searchFilter: searchText'  fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">

    <input fxFlex="15px" type="checkbox" class="floatLeft" [disabled]="!courseType.isEnabled" [checked]="courseType.isSelected" (change)="updateCheckedOptions(courseType.value, $event)">
 
  <div fxFlex>{{courseType.value}}</div>
</div>
</div>

