import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdpComponent } from './idp.component';
import { IdpPopupComponent } from './idp-popup/idp-popup.component';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,

    MatDialogModule,
    MatIconModule,
    MatButtonModule,


    ReusableUiModule
  ],
  declarations: [IdpComponent, IdpPopupComponent],
  
})
export class IdpModule { }
