import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  HostListener,
  Input
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

import * as fromUserDetailsStore from '../../shared/user-details/store';
import { IuserDetails } from '../../shared/user-details/store/user-details.interface';
import { Observable, Subscriber } from 'rxjs';
import { QuizService } from '../quiz.service';
import { DomSanitizer } from '@angular/platform-browser';

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CourseFeedbackComponent } from '../../shared/global-popups/course-feedback/course-feedback.component';

import { Globals } from '../../globals';
import { PopupService } from '../../shared/global-popups/popup.service';
import { ContentTileLpService } from '../../shared/content-tiles/content-tile-lp/content-tile-lp.service';
import { DeclarePopupService } from '../../shared/content-tiles/declare-popup/declare-popup.service';
//import { forEach } from '@angular/router/src/utils/collection';
import { QuizInstructionsComponent } from '../quiz-popups/quiz-instructions.component';
import { PlatformLocation } from '@angular/common';
import { QuizGuardDeactivate } from '../quiz.service';
import { LearningPathService } from '../../learning-path/learning-path.service';
import { ILPBreadcrumbs } from '../../learning-path/learning-path-breadcrumbs.interface';
import { AdalService } from 'adal-angular4';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import * as fromDeclareStore from '../../shared/declare-store/store';
import { DeclarationFormPopupComponent } from '../declaration-form-popup/declaration-form-popup.component';

@Component({
  selector: 'yorbit-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit, OnDestroy, OnChanges {
  mgrFeeedbackDialog: MatDialogRef<CourseFeedbackComponent>;
  instructionPopup: MatDialogRef<QuizInstructionsComponent>;
  quizComponentSubs: any = {};
  userDetails: IuserDetails;
  userDetailsLoaded: boolean = false;
  @Input() courseId: string;
  @Input() packageId: string;
  courseName: string;
  courseDetails: any;
  itemType: string;
  quizDetails: any;
  quizDetailsForCheckingHasPassed: any;
  LPBreadcrumbs: ILPBreadcrumbs;
  isInternalCourse: boolean;
  quizQuestions: Array<any>;
  shuffledQuizQuestions: Array<any>;
  isTimeFramedQuiz: boolean = false;
  courseProgress: any;
  quizAnswers = {
    QuizId: '',
    EntityId: '',
    EntityType: '',
    BadgeId: '',
    QuizDuration: 0,
    QuestionAndAnswers: [],
    TimeTaken: '',
    CurrentAttempt: 0,
    PackageId: ''
  };
  optionsSelected: Array<any>;
  selectedQuestion: any;
  questionNumber: number;
  isQuizLoaded: boolean;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  numberOfAnswered: number;
  progressPercent: any;
  quizStatusMsg: string;
  hasPassed: boolean = false;
  isPageLoaded: boolean;
  itemExpertise: string;
  quizSummary: any;
  submitInProgress: boolean;
  learningPathsLoadedSuccessfully: boolean;
  showQuizSummary: boolean;
  showQuizScore: boolean;
  quizResultFlags: any;
  quizCutOff: number;
  badgeDetails: any;
  badgeImgURL: any;
  badgeName: string;
  badgeStatus: boolean;
  isFeedbackPending: boolean;
  quizNA: boolean;
  @Input() lpid: any;
  @Input() lpCategory: string;
  oldBadgeImage: any;
  timer: any;
  answeredQuestionIds: Array<number>;
  currentAttempt: number;
  allowedAttempt: number;
  isRandomQuiz: boolean;
  currentDisplayAttempt: number;
  instructionMessage: string;
  isInstrctionsDisplyaed: boolean = false;
  distance: number = 0;
  timerInterval: any;
  countDownDate = new Date();
  totalTime: any;
  timeConsumed: string = '';
  remainingAttempt: number;
  canExit: boolean;
  quizList: any;
  quizPage: boolean = true;
  yorbitHelpClicked: boolean = false;
  quizPayloadOnRefresh: any = {};
  config: any;
  swbroadcast = null;
  mandatoryCourseListForDeclaration: any=["3223"];
  constructor(
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _delcareStore: Store<fromDeclareStore.IdeclareState>,
    private route: ActivatedRoute,
    private _quizSvc: QuizService,
    private sanitizer: DomSanitizer,
    private _dialog: MatDialog,
    private globals: Globals,
    private _adalService: AdalService,
    private _environmentService: EnvironmentService,
    private lpService: LearningPathService,
    private _popUpSvc: PopupService,
    private router: Router,
    private contentTileLpService: ContentTileLpService,
    private declarePopupService: DeclarePopupService,
    private location: PlatformLocation,
    private _quizGuard: QuizGuardDeactivate //  private dialogRef: MatDialogRef<QuizInstructionsComponent>,
  ) {
    this.isQuizLoaded = false;
    const that = this;
    window.onbeforeunload = function (event) {
      if (that.quizPage) {
        localStorage.setItem('QuizRefresh', 'true');
        if (!that._quizGuard.canExitQuiz) {
          event.returnValue = 'Are you sure you want to exit quiz?';
          return 'Are you sure you want to exit quiz?';
        }
      }
    };
    this.config = this._environmentService.getEnvironment();
    if (BroadcastChannel) {
      this.swbroadcast = new BroadcastChannel('Yorbit-Channel');
    }
  }
  ngOnChanges(changesObj) {
    if (
      changesObj['packageId'] &&
      changesObj['packageId'] != null &&
      changesObj['packageId'] != ''
    ) {
      this.packageId = changesObj['packageId'].currentValue;
      this.quizAnswers.PackageId = changesObj['packageId'].currentValue;
    }
  }
  ngOnInit() {
    // window.addEventListener('keyup', disableF5);
    // window.addEventListener('keydown', disableF5);
    // function disableF5(e) {
    //   if ((e.which || e.keyCode) == 116) e.preventDefault();
    // }
    this.quizPage = true;
    this.quizList = {};
    this.canExit = false;
    this._quizGuard.canExitQuiz = false;
    this.quizDetails = {};
    this.numberOfAnswered = 0;
    this.progressPercent = 0;
    this.submitInProgress = false;
    this.showQuizSummary = false;
    this.showQuizScore = false;
    this.badgeStatus = false;
    this.isFeedbackPending = false;
    this.answeredQuestionIds = [];
    this.quizPayloadOnRefresh = {
      CourseDetails: null,
      Answers: null
    };
    // this.route.params.subscribe(params => {
    //   this.courseId = params.courseid;
    //   this.lpid = params.lpid;
    //   this.lpCategory = params.category;
    // });

    this.getUserDetails();
  }

  setTimer() {
    this.countDownDate = new Date(
      this.countDownDate.setMinutes(
        this.countDownDate.getMinutes() + this.quizDetails.QuizDuration
      )
    );
    this.totalTime =
      this.countDownDate.valueOf() - new Date().getTime().valueOf();
    this.timerInterval = setInterval(() => {
      var now = new Date().getTime();
      this.distance = this.countDownDate.valueOf() - now.valueOf();

      // Time calculations for days, hours, minutes and seconds
      //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor(
        (this.distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

      function convert(number) {
        return number < 10 ? '0' + number.toString() : number.toString();
      }
      hours = convert(hours);
      minutes = convert(minutes);
      seconds = convert(seconds);
      // Output the result in an element with id="demo"
      if (this.distance > 0) this.timer = hours + ':' + minutes + ':' + seconds;
      else this.timer = '00:00:00';
      if (this.distance < 0 && (!this.showQuizSummary && !this.showQuizScore)) {
        clearInterval(this.timerInterval);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = {
          PopupScenario: 'TimeUp'
        };
        //courseFeedbackDialogContainer
        if (this.instructionPopup != null) {
          ////console.log('openhit');
          this.instructionPopup.close();
        }
        let isSessionExpiryPopupOpen;
        if (localStorage.getItem('isSessionExpiryPopupOpen') != null) {
          isSessionExpiryPopupOpen = parseInt(
            localStorage.getItem('isSessionExpiryPopupOpen')
          );
        } else {
          isSessionExpiryPopupOpen = false;
        }
        if (isSessionExpiryPopupOpen) {
          this._dialog.openDialogs.forEach(dialog => {
            ////console.log(dialog);
            if (dialog.id == 'SessionInformation') {
              this.quizComponentSubs.QuizInstructionsComponentSub = dialog.afterClosed().subscribe(() => {
                this.instructionPopup = this._dialog.open(
                  QuizInstructionsComponent,
                  dialogConfig
                );
                this.quizComponentSubs.instructionPopupAfterClosedSub = this.instructionPopup.afterClosed().subscribe(data => {
                  this.submitQuiz();
                });
              });
            }
          });
        } else {
          this.instructionPopup = this._dialog.open(
            QuizInstructionsComponent,
            dialogConfig
          );
          this.quizComponentSubs.instructionPopupAfterClosedSub = this.instructionPopup.afterClosed().subscribe(data => {
            this.submitQuiz();
          });
        }
      }
    }, 1000);
  }

  getUserDetails() {
    this.quizNA = false;
    this.quizComponentSubs.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        this.LPBreadcrumbs = breadcrumbs;
        this.isInternalCourse = this.LPBreadcrumbs.CourseDetails.IsInternal;
      });
    this.quizComponentSubs.getUserDetailObjectSub = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailObject)
      .subscribe(data => {
        if (data.id !== '' && !this.userDetailsLoaded) {
          this.userDetails = data;
          this.userDetailsLoaded = true;
          if (
            data.CourseProgressList.length !== 0 &&
            data.QuizDetails.length !== 0
          ) {
            const courseProgressList = this.getDataFromCourseProgressList(
              this.courseId
            )[0];

            const quizList = this.getDataFromQuizList(this.courseId)[0];
            this.quizList = this.getDataFromQuizList(this.courseId)[0];

            const badgeList = this.getDataFromBadgesList(this.courseId)[0];
            if (
              courseProgressList !== undefined &&
              quizList !== undefined &&
              badgeList !== undefined
            ) {
              if (courseProgressList !== undefined) {
                this.courseDetails = courseProgressList;
                this.itemExpertise = this.courseDetails.Expertise;
              }

              if (quizList !== undefined) {
                this.courseName = quizList.EntityName;
                this.itemType = quizList.EntityType;
              }

              if (badgeList !== undefined) {
                this.badgeDetails = badgeList;
                this.quizComponentSubs.getDeclareStatusByCourseIdSub = this._delcareStore
                  .select(
                  fromDeclareStore.getDeclareStatusByCourseId(this.courseId)
                  )
                  .subscribe(declareStatus => {
                    ////console.log('declare status', declareStatus);
                    if (
                      declareStatus != undefined &&
                      declareStatus.DeclareFlagStatus == 'DeclaredAndApproved'
                    ) {
                      //isDeclared = true;
                      this.badgeDetails.BadgeImage =
                        'assets/images/Badges/badge_declare_' +
                        this.courseDetails.Expertise +
                        '.png';
                    } else {
                      this.badgeDetails.BadgeImage =
                        'assets/images/Badges/badge_' +
                        this.courseDetails.Expertise +
                        '.png';
                    }
                  });
                this.oldBadgeImage = this.badgeDetails.BadgeImage;
                // const isDeclareBadge = this.declarePopupService.declareBadge(
                //   this.badgeDetails.BadgeImage.trim()
                // );
                // if (!isDeclareBadge) {
                //   this.badgeDetails.BadgeImage = this.declarePopupService.acquiredBadge.skill;
                //   if (this.isMandateCourse(this.courseDetails.CourseId)) {
                //     this.badgeDetails.BadgeImage = this.declarePopupService.acquiredBadge.compliance;
                //   }
                // }

                // let imgURL = '';
                // if (this.isMandateCourse(this.courseDetails.CourseId)) {
                //   if (this.courseDetails.CourseId.toString() == '2290') {
                //     imgURL = this.declarePopupService.acquiredBadge.skill;
                //   } else {
                //     imgURL = this.declarePopupService.acquiredBadge.compliance;
                //   }
                // } else {
                //   imgURL = this.declarePopupService.acquiredBadge.skill;
                // }

                this.badgeImgURL = this.badgeDetails.BadgeImage;
                this.badgeName = this.badgeDetails.BadgeName;
              }

              this.quizComponentSubs.getCourseProgressEntitiesByIdSelectorSub = this.userDetailsStore
                .select(
                fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
                  this.courseId
                )
                )
                .subscribe(course => {
                  if (course && course.CourseId) {
                    course.Progress = parseInt(course.Progress);
                    this.courseProgress = { ...course };
                  }
                });
              if (this.courseProgress.Progress) {
                this.courseProgress.Progress = parseInt(
                  this.courseProgress.Progress
                );
              }
              localStorage.setItem('isSessionExpiryPopupOpen', '0');
              this.getQuizQuestions(
                this.courseId,
                this.itemType,
                this.courseDetails
              );
              //Commented in MSAL build
              // this._adalService.clearCacheForResource(
              //   this.config.adalConfig.clientId
              // );
              // this._adalService
              //   .acquireToken(this.config.adalConfig.clientId)
              //   .toPromise()
              //   .then(data => {
              //     //////console.log('renewal success');
              //     if (this.swbroadcast != null) {
              //       this.swbroadcast.postMessage({
              //         payload: 'KEEPLEARNING'
              //       });
              //     } else {
              //       localStorage.setItem('isSessionExpiryPopupOpen', '0');
              //       window.localStorage.setItem(
              //         'IsTimerSetForIdTokenRenewal',
              //         '0'
              //       );
              //     }
              //     this.getQuizQuestions(
              //       this.courseId,
              //       this.itemType,
              //       this.courseDetails
              //     );
              //     //////console.log('renewal success end');
              //   })
              //   .catch(error => {
              //     //////console.log('renewal failed', error);
              //     this.getQuizQuestions(
              //       this.courseId,
              //       this.itemType,
              //       this.courseDetails
              //     );
              //   });
              //Commented in MSAL build
            } else {
              this.quizNA = true;
            }
          } else {
            this.quizNA = true;
          }
        }
      });
  }

  getDataFromQuizList(courseId) {
    return this.userDetails.QuizDetails.filter(quiz => {
      if (quiz.EntityId === courseId) {
        return quiz;
      }
    });
  }

  getDataFromCourseProgressList(courseId) {
    return this.userDetails.CourseProgressList.filter(course => {
      if (course.CourseId === courseId) {
        return course;
      }
    });
  }

  getDataFromBadgesList(courseId) {
    return this.userDetails.Achievements.Badges.filter(badge => {
      if (badge.CourseId === courseId) {
        return badge;
      }
    });
  }

  getQuizQuestions(courseId, itemType, courseDetails) {
    this.quizComponentSubs.getQuizByCourseIdSub = this._quizSvc
      .getQuizByCourseId(courseId, itemType, courseDetails)
      .subscribe(
      data => {
        this.quizDetails = data;
        this.quizQuestions = data.QuizDetails;
        this.currentAttempt = data.CurrentAttempt - 1;
        this.allowedAttempt = data.AllowedAttempt;
        this.isRandomQuiz = data.IsRandomQuiz;
        if (this.allowedAttempt > 0) {
          this._quizGuard.hasAttempts = true;
        } else {
          this._quizGuard.hasAttempts = false;
        }
        if (
          this.currentAttempt != 0 &&
          this.currentAttempt > this.allowedAttempt
        ) {
          if (this.currentAttempt % this.allowedAttempt == 0)
            this.currentDisplayAttempt = this.allowedAttempt;
          else
            this.currentDisplayAttempt =
              this.currentAttempt % this.allowedAttempt;
        } else if (this.currentAttempt <= this.allowedAttempt) {
          if (this.currentAttempt != 0)
            this.currentDisplayAttempt = this.currentAttempt;
          else this.currentDisplayAttempt = 1;
        }
        if (this.allowedAttempt > 0) {
          this.remainingAttempt =
            this.allowedAttempt - this.currentDisplayAttempt;
        }

        /*console.log(
          'remainging',
          this.remainingAttempt,
          this.allowedAttempt,
          this.currentDisplayAttempt,
          this.currentAttempt
        ); */
        this.shuffledQuizQuestions = this.shuffleQuizQuestions(
          this.quizQuestions.map(question => {
            question.IsAnswered = false;
            return question;
          })
        );
        this.createAnswersArray();
        if (this.quizDetails.QuizDuration > 0) {
          this.isTimeFramedQuiz = true;
          this.setTimer();
        } else {
          this.isTimeFramedQuiz = false;
        }
      },
      error => { }
      );
  }

  shuffleQuizQuestions(quizQuestions) {
    for (let i = quizQuestions.length - 1; i >= 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      const itemAtIndex = quizQuestions[randomIndex];
      quizQuestions[randomIndex] = quizQuestions[i];
      quizQuestions[i] = itemAtIndex;
    }
    return quizQuestions;
  }

  createAnswersArray() {
    this.quizAnswers = {
      QuizId: this.quizDetails.QuizId,
      EntityId: this.quizDetails.EntityId,
      EntityType: this.quizDetails.EntityType,
      BadgeId: this.quizDetails.BadgeId,
      QuizDuration: 60,
      QuestionAndAnswers: [],
      TimeTaken: 'NA',
      CurrentAttempt: 0,
      PackageId: this.packageId
    };

    this.quizAnswers.QuestionAndAnswers = new Array(
      this.shuffledQuizQuestions.length
    );

    this.shuffledQuizQuestions.forEach((detail, key) => {
      const QuestionId = detail.QuestionId;
      const Answer = [];
      this.quizAnswers.QuestionAndAnswers[key] = {
        QuestionId: QuestionId,
        SelectedOption: Answer
      };
      detail.Options.forEach(options => {
        options.selected = false;
      });
    });
    this.optionsSelected = new Array(this.quizQuestions.length + 1);
    for (let i = 1; i <= this.shuffledQuizQuestions.length; i++) {
      this.optionsSelected[i] = { updated: false };
    }

    this.questionNumber = 0;
    this.selectedQuestion = this.shuffledQuizQuestions[0];
    this.isQuizLoaded = true;
    this.isFirstQuestion = true;
    this.quizStatusMsg =
      'You will need to score ' +
      this.quizDetails.CutOff +
      '% in the Quiz to complete the course and earn the badge';

    this.updatePayloadForRefesh();
  }

  next(questionNumber) {
    // Disable next button if the user is reading the last question
    if (questionNumber + 1 === this.quizQuestions.length - 1) {
      this.isLastQuestion = true;
      this.isFirstQuestion = false;
      this.questionNumber = questionNumber + 1;
    }
    // Take the user to the next question
    else if (questionNumber < this.quizQuestions.length - 1) {
      this.questionNumber = questionNumber + 1;
      this.isFirstQuestion = false;
    }

    this.selectedQuestion = this.shuffledQuizQuestions[this.questionNumber];
  }

  previous(questionNumber) {
    // Disable Previous button if the user is reading the first question
    if (questionNumber - 1 === 0) {
      this.isFirstQuestion = true;
      this.questionNumber = questionNumber - 1;
    }
    // Take the user to the previous question
    else if (questionNumber > 0) {
      this.questionNumber = questionNumber - 1;
      this.isLastQuestion = false;
    }
    this.selectedQuestion = this.shuffledQuizQuestions[this.questionNumber];
  }
  updateAnswer(event, questionId, selectedOptionIndex, questionIndex) {
    if (!this.shuffledQuizQuestions[questionIndex].MultipleChoice) {
      this.shuffledQuizQuestions[questionIndex].Options.forEach(
        (value, key) => {
          if (key == selectedOptionIndex) {
            if (value.selected) {
              value.selected = false;
            } else {
              value.selected = true;
            }
          } else {
            value.selected = false;
          }
        }
      );
    }
    /* apply filter to retrive the question details*/
    const quesRow = this.shuffledQuizQuestions.filter(question => {
      if (question.QuestionId === questionId) {
        return question;
      }
    })[0];

    const ansRow = this.quizAnswers.QuestionAndAnswers.filter(answer => {
      if (answer.QuestionId === questionId) {
        return answer;
      }
    })[0];
    const ans = quesRow.Options[selectedOptionIndex].Answer.toString().split(
      '.'
    )[0];
    //check for the option id selected and store the same in output json
    if (quesRow.Options[selectedOptionIndex].selected) {
      if (this.answeredQuestionIds.indexOf(quesRow.QuestionId) == -1) {
        this.answeredQuestionIds.push(quesRow.QuestionId);
        this.numberOfAnswered = this.numberOfAnswered + 1;
      }
      if (this.shuffledQuizQuestions[questionIndex].MultipleChoice) {
        ansRow.SelectedOption.push(ans);
      } else {
        ansRow.SelectedOption = [ans];
      }
      ansRow.SelectedOption.sort();
      this.shuffledQuizQuestions[questionIndex].IsAnswered = true;
    } else if (!quesRow.Options[selectedOptionIndex].selected) {
      const deselectedVal = quesRow.Options[selectedOptionIndex];
      const indexOfDeselectedVal = ansRow.SelectedOption.indexOf(
        deselectedVal.Answer.toString().split('.')[0]
      );
      ansRow.SelectedOption.splice(indexOfDeselectedVal, 1);
      ansRow.SelectedOption.sort();
      //update the progress bar
      if (ansRow.SelectedOption.length === 0) {
        this.answeredQuestionIds.splice(
          this.answeredQuestionIds.indexOf(quesRow.QuestionId),
          1
        );

        this.numberOfAnswered = this.numberOfAnswered - 1;
        this.shuffledQuizQuestions[questionIndex].IsAnswered = false;
      } else {
        this.shuffledQuizQuestions[questionIndex].IsAnswered = true;
      }
    }
    //calculate progress percentage
    this.progressPercent = this.calculateProgressPercent(
      this.quizQuestions.length,
      this.numberOfAnswered
    );
  }

  calculateProgressPercent(total, numberOfAnswered) {
    return (numberOfAnswered / total) * 100;
  }

  submitConfirmation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      PopupScenario: 'Confirmation',
      IsTimeFramedQuiz: this.isTimeFramedQuiz
    };

    ////console.log('remaining time', this.distance);
    //courseFeedbackDialogContainer
    this.instructionPopup = this._dialog.open(
      QuizInstructionsComponent,
      dialogConfig
    );
    this.quizComponentSubs.instructPopupAfterClosedSub = this.instructionPopup.afterClosed().subscribe(response => {
      if (response == true) {
        clearInterval(this.timerInterval);
        this.submitQuiz();
      }
      this.isPageLoaded = false;
    });
  }

  submitQuiz() {
    this.showQuizScore = true;
    ////console.log('remainingtime', this.distance, this.totalTime);
    var timeTaken = this.totalTime - this.distance;

    // Time calculations for days, hours, minutes and seconds
    //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeTaken % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeTaken % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeTaken % (1000 * 60)) / 1000);

    function convert(number) {
      return number < 10 ? '0' + number.toString() : number.toString();
    }
    hours = convert(hours);
    minutes = convert(minutes);
    seconds = convert(seconds);
    this.quizAnswers.TimeTaken = '';
    this.timeConsumed = '';

    if (hours > 0) this.timeConsumed = hours + ' hour';
    if (this.timeConsumed != '') this.timeConsumed += ' ';
    if (minutes > 0) this.timeConsumed = this.timeConsumed + minutes + ' min';
    if (this.timeConsumed != '') this.timeConsumed += ' ';
    if (seconds > 0) this.timeConsumed = this.timeConsumed + seconds + ' sec';
    // Output the result in an element with id="demo"
    ////console.log('TimeTaken', hours + ':' + minutes + ':' + seconds);

    if (this.isTimeFramedQuiz)
      this.quizAnswers.TimeTaken = hours + ':' + minutes + ':' + seconds;
    else this.quizAnswers.TimeTaken = 'NA';

    if (this.allowedAttempt > 0)
      this.quizAnswers.CurrentAttempt = this.currentAttempt;
    else this.quizAnswers.CurrentAttempt = 0;
    this.isFirstQuestion = true;
    this.isLastQuestion = false;
    this.submitInProgress = true;
    this.quizAnswers.QuestionAndAnswers.sort(
      (a, b) => a.QuestionId - b.QuestionId
    );

    this._quizSvc
      .submitQuiz(this.quizAnswers, this.courseDetails)
      .toPromise()
      .then(data => {
        if((-1!=this.mandatoryCourseListForDeclaration.indexOf(this.courseId))&&(data.QuizResult.progress >= data.CutOff)){
          //Integrity Declaration
          this.openDeclarationDialog(data);
        }else{
          this.updateQuizData(data);
        }
      });
    //});
  }

  updateQuizData(data){
    let sub = this.userDetailsStore
          .select(fromUserDetailsStore.getQuizByCourseIdSelector(this.courseId))
          .subscribe(
          quiz => {
            if (quiz && quiz.EntityId) {
              this.quizDetailsForCheckingHasPassed = { ...quiz };
              this.hasPassed = this.quizDetailsForCheckingHasPassed.HasPassed;
            }
            this.canExit = true;
            this._quizGuard.canExitQuiz = true;
            this.yorbitHelpClicked = false;
            ////console.log('Result', data);
            if (this.isMandateCourse(this.courseDetails.CourseId)) {
              this.setProgressForMandatoryCourse(data);
              let lpName = '';
              if (this.isPoshUSACourse(this.courseDetails.CourseId)) {
                lpName = 'SEXUAL HARASSMENT PREVENTION TRAINING - USA';
              } else {
                lpName = this.globals.LpName=='coc'? this.globals.CocLpName.toUpperCase() :this.globals.BEACLpName.toUpperCase();
              }
              this.contentTileLpService
                .getMandatoryDates(lpName)
                .then(dates => {
                  if (lpName == this.globals.CocLpName.toUpperCase() || lpName == this.globals.BEACLpName.toUpperCase()) {
                    this.globals.isDateAPICalled = true;
                    this.globals.mandatoryReminderCount = dates.ReminderID;
                    this.globals.isNewJoinee = dates.IsNewJoinee;
                    this.globals.mandatoryRetakeDate = new Date(
                      dates.ReminderDate
                    );
                  } else if (
                    lpName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA'
                  ) {
                    this.globals.isDateAPICalledForPoshUSA = true;
                    this.globals.mandatoryReminderCountForPoshUSA =
                      dates.ReminderID;
                    this.globals.isNewJoineeForPoshUSA = dates.IsNewJoinee;
                    this.globals.mandatoryRetakeDateForPoshUSA = new Date(
                      dates.ReminderDate
                    );
                    this.globals.counterPOSHUSA = dates.Counter;
                  }
                });
            }
            this.currentAttempt = data.CurrentAttempt;
            this.allowedAttempt = data.AllowedAttempt;
            if (
              data.QuizResult.progress >= data.CutOff ||
              !this.isInternalCourse ||
              this.hasPassed
            ) {
              this.isInstrctionsDisplyaed = false;
            } else {
              if (
                this.currentAttempt == 1 &&
                this.courseProgress.Progress < 50
              ) {
                this.isInstrctionsDisplyaed = true;
                if (this.currentDisplayAttempt < this.allowedAttempt) {
                  this.instructionMessage =
                    'Please go through atleast 50% of the course content before re-attempting the quiz.';
                }
              } else if (this.currentDisplayAttempt == this.allowedAttempt) {
                this.isInstrctionsDisplyaed = true;
                this.instructionMessage =
                  'Sorry! You have exceeded maximum attempts allowed for this quiz. Please go through 100% of the course content before re-attempting the quiz.';
              } else if (this.courseProgress.Progress < 50) {
                this.isInstrctionsDisplyaed = true;
                this.instructionMessage =
                  'Please go through atleast 50% of the course content before re-attempting the quiz.';
              }
            }
            this.quizSummary = data as any;
            this.quizResultFlags = data.QuizResult;
            this.quizCutOff = data.CutOff;

            this.shuffledQuizQuestions.forEach((quiz, key) => {
              const questionStatus = this.quizSummary.QuizDetails.filter(
                question => {
                  if (question.QuestionId === quiz.QuestionId) {
                    return question;
                  }
                }
              )[0];
              quiz.Status = questionStatus.Status;
            });
            this.badgeStatus = this.quizResultFlags.badge;
            //isDeclared = true;
            this.badgeImgURL =
              'assets/images/Badges/badge_' +
              this.courseDetails.Expertise +
              '.png';


            // const badgeImageURL = this.badgeStatus
            //   ? this.badgeDetails.BadgeImage
            //   : this.oldBadgeImage;
            this.userDetailsStore.dispatch(
              new fromUserDetailsStore.UpdateAchievements({
                badgeId: this.quizSummary.BadgeId,
                badgeStatus: this.badgeStatus,
                //badgeImage: badgeImageURL,
                quizScore: this.quizResultFlags.progress
              })
            );

            /**Commenting as we are updating the store when we are navigating to LP*/
            // this.userDetailsStore.dispatch(
            //   new fromUserDetailsStore.UpdateQuizDetails({
            //     QuizId: this.quizSummary.QuizId,
            //     Progress: this.quizResultFlags.progress,
            //     HasPassed: this.quizResultFlags.hasPassed,
            //     RetakeQuiz: this.quizResultFlags.canRetake,
            //     CompletionDate: this.quizResultFlags.CompletedDate
            //   })
            // );
            /**Remove after the release */

            if (
              this.quizResultFlags.badge === true &&
              !this.courseDetails.IsAccount &&
              !this.courseDetails.IsProject
            ) {
              this.checkFeedback();
            }
            this.isPageLoaded = true;
            this.submitInProgress = false;
            if (sub) sub.unsubscribe();
          },
          error => {
            this.submitInProgress = false;
            if (sub) sub.unsubscribe();
          }
          );
  }

  resetData() {
    this.numberOfAnswered = 0;
    this.progressPercent = 0;
    this.questionNumber = 0;
    this.isFirstQuestion = true;
    this.isLastQuestion = false;
    this.submitInProgress = false;
    this.showQuizSummary = false;
    this.showQuizScore = false;
    this.badgeStatus = false;
    this.shuffledQuizQuestions = [];
    this.answeredQuestionIds = [];
    this.quizAnswers.TimeTaken = '';
    if (this.allowedAttempt > 0) {
      this.quizComponentSubs.updateAttemptsSub = this._quizSvc
        .updateAttempts(
        this.quizDetails.EntityId,
        this.quizDetails.entityType,
        this.courseDetails
        )
        .subscribe(data => {
          //////console.log('data', data);
          if (data) {
            this.getQuizQuestions(
              this.quizDetails.EntityId,
              this.quizDetails.entityType,
              this.courseDetails
            );
          }
          ////console.log('quiz', this.quizDetails);
        });
    } else {
      this.getQuizQuestions(
        this.quizDetails.EntityId,
        this.quizDetails.entityType,
        this.courseDetails
      );
    }
  }

  viewSummary() {
    this.showQuizSummary = true;
    this.showQuizScore = false;
  }

  showScore() {
    // //console.log(
    //   'data',
    //   this.currentAttempt,
    //   this.allowedAttempt,
    //   this.quizResultFlags.progress
    // );
    this.showQuizSummary = false;
    this.showQuizScore = true;
  }

  exitQuiz() {
    // this.resetData();
    //////console.log('fasdhit');

    // if (this.lpid !== undefined && this.lpCategory !== undefined) {
    //   this.goBackToLP();
    // } else {
    //   this.router.navigate(['/']);
    // }

    this.backToLPDetails();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //courseFeedbackDialogContainer
    this._dialog.open(CourseFeedbackComponent, dialogConfig);
  }

  checkFeedback() {
    if (!this.globals.isCustomerAccess) {
      this._popUpSvc.isCourseFeedbackPending().then(res => {
        if (res === true) {
          //if (!this.globals.isCourseFeedbackPopupShown) {
          this.openDialog();
          //}
        } else {
          //do nothing
        }
      });
    }
  }

  goBackToLP() {
    ////console.log('hitlp', this.lpCategory, this.lpid);
    this.router.navigate([
      'learningpath/category/' + this.lpCategory + '/id/' + this.lpid
    ]);
  }

  isMandateCourse(courseId) {
    if (courseId != undefined) {
      const id = courseId.toString();
      if (
        this.globals.courseListForShowingLastCompletedDate.indexOf(id) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  isPoshUSACourse(courseId) {
    if (courseId != undefined) {
      const id = courseId.toString();
      if (this.globals.poshUSACourseId.indexOf(id) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  openInstructionPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      TotalQuestions: this.shuffledQuizQuestions.length,
      PassingCriteria: this.quizDetails.CutOff,
      CourseName: this.courseName,
      Expertise: this.courseDetails.Expertise,
      IsTimeFramedQuiz: this.isTimeFramedQuiz,
      PopupScenario: 'Instructions'
    };
    //courseFeedbackDialogContainer
    this.instructionPopup = this._dialog.open(
      QuizInstructionsComponent,
      dialogConfig
    );
  }

  setProgressForMandatoryCourse(res) {
    if (!res.QuizResult.canRetake) {
      //update course progress list in store
      this.userDetailsStore.dispatch(
        new fromUserDetailsStore.UpdateCourseProgress({
          LPId: this.lpid,
          PackageId: null,
          CourseId: this.courseDetails.CourseId,
          Progress: '0'
        })
      );
    }
  }

  backToLPDetails() {
    let packageId = 'null';
    if (this.packageId) {
      packageId = this.packageId;
    }
    this.router.navigate([
      'learningpath/category/' +
      this.lpCategory +
      '/id/' +
      this.lpid +
      '/package/' +
      packageId +
      '/course/' +
      this.quizList.EntityId +
      '/account/' +
      this.quizList.IsAccount +
      '/project/' +
      this.quizList.IsProject +
      '/accountId/' +
      this.quizList.AccountId +
      '/projectId/' +
      this.quizList.ProjectId +
      '/tabs/playlist'
    ]);
    // ////console.log('********', this.quizList.EntityId, this.quizList.IsAccount, this.quizList.IsProject, this.quizList.AccountId, this.quizList.ProjectId );
  }

  ngOnDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    this._quizGuard.canExitQuiz = true;
    this.yorbitHelpClicked = false;
    this.unsubscribeAllSubscriptions();

  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.quizComponentSubs) {
      let subscriber = this.quizComponentSubs[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  isYorbitHelpClicked() {
    this.yorbitHelpClicked = true;
    setTimeout(() => {
      this.yorbitHelpClicked = false;
    }, 500);
  }

  updatePayloadForRefesh() {
    this.quizAnswers.CurrentAttempt = this.currentAttempt;
    this.quizAnswers.TimeTaken = '00:00:00';
    this.quizPayloadOnRefresh = {
      CourseDetails: this.courseDetails,
      Answers: this.quizAnswers
    };
    this._quizSvc.updateQuizSubmitPayload(this.quizPayloadOnRefresh);
  }

  openDeclarationDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      badgeId: this.quizDetails.BadgeId,
      responseData:data,
      triggerType:"quiz"
    };
    //courseFeedbackDialogContainer
    //this.quizAnswers, this.courseDetails
    const declarationPopup = this._dialog.open(DeclarationFormPopupComponent, dialogConfig);

    declarationPopup.afterClosed().subscribe(payload => {
      let quizUpdate =payload.responseData

      this.updateQuizData(quizUpdate);
    });
  }

}
