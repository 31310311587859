import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AceingLeadershipService {
  config: Ienvironment;
  coursesList: any;
  baseURL: string;
  contentList: any;
  speakerDetail: any;
  selfLearningContentCourses: any;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.baseURL = this.config.apiUrl;
    this.coursesList = [];
    this.contentList = [];
    this.speakerDetail = [];
    this.selfLearningContentCourses = [];
  }

  UserValidationforAceingLeadership() {
    return this.http
      .get(this.config.apiUrl + 'AceingLeadership/EligibleEmpIds')
      .toPromise();
  }

  getCoursesForAceingLeadership() {
    let promise = new Promise((resolve, reject) => {
      if (this.coursesList.length === 0) {
        this.http
          .get(this.config.apiUrl + 'User/GetCoursesForAceingLeadership')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.coursesList = list;
            } else {
              this.coursesList = [];
            }
            resolve(this.coursesList);
          });
      } else {
        resolve(this.coursesList);
      }
    });
    return promise;
  }

  getAceingLeadershipUserDetails() {
    let url = this.config.apiUrl + 'User/AceingLeadership';
    return this.http.post<any>(url, {});
  }

  getContentForAceingLeadership() {
    let promise = new Promise((resolve, reject) => {
      if (this.contentList.length === 0) {
        this.http
          .get(this.config.apiUrl + 'User/AceingLeadership/ContentDetails')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.contentList = list;
            } else {
              this.contentList = [];
            }
            resolve(this.contentList);
          });
      } else {
        resolve(this.contentList);
      }
    });
    return promise;
  }

  getSpeakerDetailForAceingLeadership() {
    let promise = new Promise((resolve, reject) => {
      if (this.speakerDetail.length === 0) {
        this.http
          .get(this.config.apiUrl + 'User/AceingLeadership/SpeakerDetails')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.speakerDetail = list;
            } else {
              this.speakerDetail = [];
            }
            resolve(this.speakerDetail);
          });
      } else {
        resolve(this.speakerDetail);
      }
    });
    return promise;
  }

  getAceingLeadershipSelfLearningContentCourses() {
    let promise = new Promise((resolve, reject) => {
      if (this.selfLearningContentCourses.length === 0) {
        this.http
          .get(this.config.apiUrl + 'User/AceingLeadership/SelfLearningContent')
          .toPromise()
          .then(data => {
            if (data != null) {
              this.selfLearningContentCourses = data;
            } else {
              this.selfLearningContentCourses = [];
            }
            resolve(this.selfLearningContentCourses);
          });
      } else {
        resolve(this.selfLearningContentCourses);
      }
    });
    return promise;
  }

  updateAceingLeadershipPhaseCompletion(phase_subPhase) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    // let id = {"id": phase_subPhase};
    let url = this.config.apiUrl + 'User/AceingLeadership/CompleteStatus';
    return this.http.post<any>(url, JSON.stringify(phase_subPhase), {
      headers: headers
    });
    // return this.http.post<any>(url, JSON.stringify(id));
  }
}
