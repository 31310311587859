<mat-dialog-content>
  <div class="closePopupBtn"
    *ngIf="canClosePopup || requestNotAllowed ||(!ProcessCompleted&&0<prerequisiteCourses.length)">
    <button class="mat-button mat-icon-button" (click)="closePopUp(true,false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title" title="{{cardDetails.ItemName}}">
    <div class="heading-custom" [ngClass]="{
      courseNameLimit2tile: (!ProcessCompleted&&2==prerequisiteCourses.length)||((ProcessCompleted&&recommendedCourses.length == 2)),
      courseNameLimit3tile: (!ProcessCompleted&&3<=prerequisiteCourses.length)||((ProcessCompleted&&recommendedCourses.length >= 3))
    }">
      {{((!ProcessCompleted&&prerequisiteCourses.length>0))?('Pre-requisite(s):'):('')}}{{('Pending' ==
      updatedStatus||ProcessCompleted||ProceedWithoutIntent)&&(!errorSpecialCase)?'Confirmation:':''}} {{(('error' ==
      processState)&&(!requestNotAllowed||errorSpecialCase))?'Information:' : ''}} {{cardDetails.ItemName}}
    </div>
    <!-- {{(!ProcessCompleted&&0<prerequisiteCourses.length)?'Pre-requisite(s):':''}}{{('Pending' == updatedStatus||ProcessCompleted||ProceedWithoutIntent)&&(!errorSpecialCase)?'Confirmation:':''}} {{(('error' == processState)&&(!requestNotAllowed||errorSpecialCase))?'Information:' : ''}} {{cardDetails.ItemName}}-->
  </div>
  <div>
    <div fxLayout="column" fxLayoutAlign="center center" class="popup-content">
      <div class="approval-message" *ngIf="'validate' == processState">
        <div *ngIf="!requestNotAllowed">
          Validating your request, Please Wait
        </div>
        <div *ngIf="requestNotAllowed" [innerHtml]="requestNotAllowedInfo">

        </div>
        <div class="action-btn-holder" *ngIf="requestNotAllowed">
          <button class="actionButton action-button" (click)="closePopUp(true,false)">OK</button>
        </div>
      </div>
      <div class="approval-message" *ngIf="'proceed' == processState || 'processing' == processState">
        <div *ngIf="'processing' != processState">
          {{ validationResponse.Alert }}
        </div>
        <div *ngIf="'processing' == processState">
          Processing your request, Please wait...
        </div>
        <div class="action-btn-holder">
          <button class="actionButton action-button" *ngIf="
              'validation failed but user can proceed' ==
                validationResponse.Status.toLowerCase() &&
              'processing' != processState
            " (click)="proceedRequest()">
            Proceed
          </button>
          <button class="actionButton action-button" *ngIf="
              'validation failed but user can proceed' ==
                validationResponse.Status.toLowerCase() &&
              'processing' != processState
            " (click)="closePopUp(true,false)">
            Cancel
          </button>
        </div>
      </div>

      <!--Success and Error messages-->
      <div class="approval-message"
        [ngClass]="{ maxWidthExtend2tile: !ProcessCompleted&&2==prerequisiteCourses.length,maxWidthExtend3tile: !ProcessCompleted&&3<=prerequisiteCourses.length }"
        *ngIf="'processed' == processState || 'error' == processState">
        <div class="message"  [innerHTML]="approvalResponse | linky"></div>

        <div class="action-btn-holder" *ngIf="ProcessCompleted||(prerequisiteCourses&&0==prerequisiteCourses.length)">
          <button class="action-button" (click)="closePopUp(true,true)">OK</button>
        </div>
      </div>

      <!-- Cloud Lab Request -->
      <div class="approval-message" *ngIf="'processed' == processState ">
        <div class="message">{{ cloudLabMessage }}</div>
        <div class="action-btn-holder" *ngIf="ShowRequestButtons">
          <button class="actionButton action-button" (click)="requestCloudLab()">
            Request Cloud lab access
          </button>
          <button class="actionButton action-button" (click)="closePopUp(true,false)">
            Not Now
          </button>
        </div>
      </div>
      <!-- Cloud Lab Request Ends -->

      <div fxLayout="column" fxLayoutAlign="center center" class="content-tiles-box"
        [ngClass]="{ expandTileBox: !ProcessCompleted&&1<prerequisiteCourses.length}">
        <!--Mat divider-->
        <mat-divider class="matdividerwidthmargin"></mat-divider>

        <!--Recommendations block-->
        <div fxLayout="column" fxLayoutAlign="start start" class="recommended-courses" *ngIf="
            recommendedCourses.length != 0 && (ProcessCompleted||prerequisiteCourses.length == 0) && !showPreloader
          ">
          <div class="header">
            You may also be interested in
          </div>
          <div fxFlex fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutAlign.xs="center start"
            class="browse-content-tile-container">
            <div class="content-tile-holder"
              *ngFor="let course of recommendedCourses | slice: 0 : (this.showMoreCourses ? (recommendedCourses.length > 3 ? 3 : recommendedCourses.length) : (recommendedCourses.length))"
              fxFlex="250px" fxLayout="column" fxLayoutAlign="center center">
              <div fxFlex="300px" fxLayoutAlign="center center" class="cardMargin">
                <yorbit-content-tile-browse [featuredIn]="'Recommendation Course'" fxFlex="225px" [cardDetails]="course"
                  fxLayoutAlign="start start" fxFlexFill></yorbit-content-tile-browse>
              </div>
            </div>
          </div>
          <div (click)="showMoreRecommendedCourses()" *ngIf="recommendedCourses.length>3 && showMoreCourses"
            class="expand">
            Show more
          </div>
          <div (click)="showMoreRecommendedCourses()" *ngIf="recommendedCourses.length>3 &&!showMoreCourses"
            class="collapse">
            Show less
          </div>
        </div>

        <!--Pre requisites block-->
        <div class="pre-requisite-courses" *ngIf="!ProcessCompleted&&prerequisiteCourses.length != 0 && !showPreloader"
          fxLayout="column">
          <div class="header alignRight">
            {{prerequisiteCount}} {{(prerequisiteCount > 1)?(" Courses"):(" Course")}}
          </div>
          <div fxFlex fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutAlign.xs="center start"
            class="browse-content-tile-container">
            <div class="content-tile-holder" *ngFor="let course of prerequisiteCourses" fxFlex="250px" fxLayout="column"
              fxLayoutAlign="center center">
              <div fxFlex="300px" fxLayoutAlign="center center" class="cardMargin">
                <yorbit-content-tile-browse [featuredIn]="'Prerequisite Course in Course Request'" fxFlex="225px"
                  [cardDetails]="course" fxLayoutAlign="start start" fxFlexFill></yorbit-content-tile-browse>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="proceedBtnContainer" *ngIf="!ProcessCompleted&&prerequisiteCourses.length != 0 && !showPreloader">
        <mat-divider class="matdividerwidthmargin"></mat-divider>
        <button class="actionButton action-button" (click)="validateIntentRequired()"
          *ngIf="prerequisiteCourses.length==declaredCourseList.length">
          Next
        </button>
        <button class="actionButton action-button" (click)="closePopUp(true,true)"
          *ngIf="prerequisiteCourses.length!=declaredCourseList.length">
          Ok
        </button>
      </div>
    </div>

    <div *ngIf="showPreloader">
      <app-preloader></app-preloader>
    </div>
  </div>
</mat-dialog-content>

<!-- <div *ngIf="!isClassroomLoaded  && showPreloader && !requestNotAllowed">
  <app-preloader></app-preloader>
</div> -->