<mat-dialog-content class="assignResponsePopup">
  <div class="closeAssign">
    <button mat-button mat-icon-button (click)="closeAssign()" [disabled]="false" [ngClass]="{'disabled':(false)}">
            <mat-icon>close</mat-icon>
          </button>
  </div>
  <div class="courseName">{{data.assignData.itemName}}</div>
  <div>
    <div class="successFailureContainer" *ngIf="canAssign">
      <div class="successFailureHeader">Assignment Status</div>
      <div class="failedMidsContainer">
        <div class="failedMidsRow">
          <div class="midText midTextHeader textTruncate floatLeft failedHeadersText">PSID</div>
          <div class="textTruncate floatLeft failedHeadersText">Status</div>
        </div>
        <div *ngFor="let responseData of data.responseData; let index = index" class="failedMidsRow">
          <div class="midText textTruncate floatLeft">{{index+1}}.{{responseData.Mid}}</div>
          <div class="textTruncate floatLeft failedReason" title="{{responseData.Status}}">{{responseData.Status}}</div>
        </div>
      </div>
    </div>

    <div class="successFailureContainer" *ngIf="!canAssign">
      <div class="cannotAssignMSg" [innerHTML]="assignmentRestrictionMsg"></div>
    </div>

    <div class="actionButtonContainer">
      <button class="actionButtons" mat-raised-button (click)="closeAssign()">OK</button>
    </div>
  </div>
</mat-dialog-content>