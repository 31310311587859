import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PlaylistService } from '../../../shared/playlist/playlist.service';
import { LearningPathService } from '../../learning-path.service';
import { LinkifyPipe } from '@YorbitWorkspace/pipes';
import { ILPBreadcrumbs } from '../../learning-path-breadcrumbs.interface';
import { filter } from 'rxjs/operators';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'yorbit-lp-course-description',
  templateUrl: './lp-course-description.component.html',
  styleUrls: ['./lp-course-description.component.scss']
})
export class LpCourseDescriptionComponent implements OnInit, OnDestroy {
  LPBreadcrumbs: ILPBreadcrumbs;
  externalCourseViewCompSubscriptions: any;
  description: any;
  descriptionLoadedSuccessfully: boolean;
  descriptionLoading: boolean;
  constructor(
    private lpService: LearningPathService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.externalCourseViewCompSubscriptions = {};
    this.subscribeToBreadcrumbs();
    this.subscribeToRouterEvents();
    this.subscribeToRouter();
    this.description = '';
    this.descriptionLoading = true;
    this.descriptionLoadedSuccessfully = false;
  }

  ngOnInit() {}
  subscribeToBreadcrumbs() {
    this.externalCourseViewCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        this.LPBreadcrumbs = breadcrumbs;
      });
  }
  subscribeToRouterEvents() {
    this.externalCourseViewCompSubscriptions.routerEventsSub = this.activatedRoute.parent.params.subscribe(
      parentParams => {
        let isAccountRelated = false;
        let payload = null;
        if (
          parentParams['accountPackage'] === 'true' ||
          parentParams['projectPackage'] === 'true'
        ) {
          isAccountRelated = true;
          payload = {
            AccountId: parentParams['accountId'],
            AccountPackage: parentParams['accountPackage'],
            ItemId: parentParams['courseId'],
            ProjectId: parentParams['projectId'],
            ProjectPackage: parentParams['projectPackage']
          };
        }
        if (parentParams['courseId']) {
          this.getExternalCourseDescription(
            parentParams['courseId'],
            'Course',
            isAccountRelated,
            payload
          );
        }
      }
    );
  }
  getExternalCourseDescription(courseId, type, isAccountRelated, payload) {
    this.externalCourseViewCompSubscriptions.getExternalCourseContentSub = this.lpService
      .getCourseDetails(courseId, type, isAccountRelated, payload)
      .then(
        (data: any) => {
          this.description = data.Description;
          this.descriptionLoading = false;
          this.descriptionLoadedSuccessfully = true;
        },
        error => {
          this.descriptionLoading = false;
          this.descriptionLoadedSuccessfully = false;
        }
      );
  }
  subscribeToRouter() {
    this.externalCourseViewCompSubscriptions.routerSub = this.router.events.subscribe(
      (event: any) => {
        // ////console.log(event.url.split("playlist"));
        if (event.url != undefined) {
          if (event.url.split('playlist')[1] == '') {
            if (this.LPBreadcrumbs.LPDetails.LPId != null) {
              if (this.LPBreadcrumbs.PackageDetails.PackageId != null) {
                this.router.navigate([
                  'learningpath/category/' +
                    this.LPBreadcrumbs.LPDetails.LPCategory +
                    '/id/' +
                    this.LPBreadcrumbs.LPDetails.LPId +
                    '/package/' +
                    this.LPBreadcrumbs.PackageDetails.PackageId
                ]);
              } else {
                this.router.navigate([
                  'learningpath/category/' +
                    this.LPBreadcrumbs.LPDetails.LPCategory +
                    '/id/' +
                    this.LPBreadcrumbs.LPDetails.LPId
                ]);
              }
            }
          }
        }
      }
    );
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.externalCourseViewCompSubscriptions) {
      let subscriber = this.externalCourseViewCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
}
