<mat-dialog-content>
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popupCourseTitle">
    <div class="title">{{ data.courseName }}</div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="popup-content"
    *ngIf="declareConfirmationPopup && !isClassroom && classroomCheckLoaded">
    Dear Learner,<br /><br />
    Please note the following points about 'Declaring' a course:<br />
    <ul>
      <li>
        You will be
        <strong class="coursecontent">unable to consume the course content</strong>
        once you choose to Declare this course.
      </li>
      <li>
        Credits will <strong class="coursecontent">not</strong> be awarded for
        declared courses.
      </li>
      <li>'Declared' Badge will be awarded for the courses.</li>
      <li>
        A mail will be triggered to your Reporting Manager once a course is
        declared.
      </li>
    </ul>
    <div class="popupMessage">Do you want to continue to Declare?</div>
    <div class="actionButtonContainer">
      <button class="actionButton action-button" (click)="closeDialog()">
        Cancel
      </button>
      <button class="actionButton action-button" (click)="declareConfirmationPopup = false">
        Proceed
      </button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" class="popup-content"
    *ngIf="!declareConfirmationPopup && (!isClassroom && classroomCheckLoaded)">
    <div class="" *ngIf="!declareSkillCallCompleted">
      <div class="mousePointer closeButton iconSpriteImage iconCrossInPopups" *ngIf="!disableDeclareSubmitButton"
        (click)="closeDialog()"></div>

      <div class="declareResultPopBody">
        <div class="declareTitle">Declare your skill</div>
        <!-- skill name row -->
        <div class="contentSpace" fxLayout="row" fxLayoutAlign="start start">
          <div class="" fxFlex="30">
            Skill name
          </div>
          <div>
            :
          </div>
          <div fxFlexOffset="5">
            {{ skillname }}
          </div>
        </div>
        <!-- expertise level row -->
        <div class="contentSpace" fxLayout="row" fxLayoutAlign="start start">
          <div class="" fxFlex="30">
            Expertise level
          </div>
          <div>
            :
          </div>
          <div fxFlexOffset="5">
            {{ data.expertise }}
          </div>
        </div>
        <!-- mode of acquiring row -->
        <div fxLayout="column" fxLayoutGap="5px" class="contentSpace">
          <div>
            Mode of acquiring
          </div>
          <div>
            <div class="declareDropdownBox">
              <select class="declareDropdown" [(ngModel)]="declareSkillModeOfAquiring"
                [disabled]="disableDeclareSubmitButton" (change)="declareModeOfAquireSelected()"
                [ngClass]="{ mouseDisabled: disableDeclareSubmitButton }">
                <option [ngValue]="mode" *ngFor="let mode of declareSkillModeOfAquiringList">
                  {{ mode }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- error message -->

        <!-- remarks row -->
        <div fxLayout="column" fxLayoutGap="5px" class="contentSpace">
          <div>
            Remarks
          </div>
          <div>
            <textarea class="remarkTextArea" [(ngModel)]="decalrePopupRemarks" [disabled]="disableDeclareSubmitButton"
              [ngClass]="{ mouseDisabled: disableDeclareSubmitButton }"></textarea>
          </div>
        </div>

        <div class="errMsg contentSpace" *ngIf="'' !== decalreErrorMessage">
          {{ decalreErrorMessage }}
        </div>

        <div class="actionButtonContainer contentSpace">
          <button class="actionButton action-button" (click)="closeDialog()" [disabled]="disableDeclareSubmitButton">
            Cancel
          </button>
          <button class="actionButton action-button"
            [ngClass]="{ mouseDisabled: disableDeclareSubmitButton||(skillname==='Loading...') }"
            [disabled]="disableDeclareSubmitButton||(skillname==='Loading...')"
            (click)="submitClickedForDeclarePopUP()">
            <span>{{ decalreSubmitButton }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="declarePopUp-box-data" *ngIf="declareSkillCallCompleted">
      <div class="mousePointer closeButton iconSpriteImage iconCrossInPopups" (click)="closeDialog()"></div>
      <div *ngIf="declareSkillSuccess" class="skillDeclareSuccessPopup">
        You have successfully declared: <br />
        <br />
        Course: {{ data.courseName }}<br />
        Skill: {{ skillname }}
        <br />
        <br />This skill has been added to your Skill Profile! <br />
      </div>

      <div class="actionButtonContainer">
        <button class="actionButton action-button" (click)="closeDialog()">
          Close
        </button>
        <button class="actionButton action-button" (click)="gotoMyPage()">
          Go to MY PAGE
        </button>
      </div>
      <div *ngIf="declareSkillFailed" class="skillDeclareFailedPopup">
        Something went wrong. Please try later.
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="popup-content classroom-msg"
    *ngIf="isClassroom && classroomCheckLoaded">
    This course is a part of classroom so you can not declare it.
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="popup-content"
    *ngIf="!isClassroom && !classroomCheckLoaded">
    <app-preloader></app-preloader>
  </div>
</mat-dialog-content>