import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowseModule } from '../browse/browse.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './header.component';
import { HeaderPopupComponent } from "./header-popup/header-popup.component";
import { BellNotificationsComponent } from './bell-notifications/bell-notifications.component';
import { RouterModule } from '@angular/router';
import {ReusableUiModule} from '@YorbitWorkspace/reusable-ui';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,


    RouterModule,
    FormsModule,
    BrowseModule,
    ReusableUiModule
  ],

  declarations: [HeaderComponent, BellNotificationsComponent, HeaderPopupComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}
