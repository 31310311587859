import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkillProfileComponent } from '../skill-profile.component';
import { DeclareSkillPopupService } from './declare-skill-popup.service';
import { DeclarePopupService } from '../../../shared/content-tiles/declare-popup/declare-popup.service';

@Component({
  selector: 'yorbit-declare-skill-popup',
  templateUrl: './declare-skill-popup.component.html',
  styleUrls: ['./declare-skill-popup.component.scss']
})
export class DeclareSkillPopupComponent implements OnInit {
  skillName: string;
  declareSubmitInProgress: boolean;
  showreportButton: boolean;
  reportCategory: string;
  isSubmitInProgress: boolean;
  declareSkillCallCompleted: boolean;
  declareSkillSuccess: boolean;
  declareSkillFailed: boolean;
  isReportSent: boolean;
  submitFailed: boolean;
  showPopup: boolean;
  skillList: any[];
  modeOfAcquired: any[];
  modeOfAcquiredSelected: string;
  role: any;
  reportComment: string;
  linkAvailable: boolean;
  complianceDetails: any;
  categoryOptions: string[];
  termsOfUse: string[];
  showTermsofUse: boolean;
  searched: boolean;
  skillSelected: { Skill: string; Expertise: string };
  errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<SkillProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private declareSkillPopupService: DeclareSkillPopupService,
    private declarePopupService: DeclarePopupService
  ) {}

  ngOnInit() {
    this.callOnFirstLoad();
  }

  callOnFirstLoad() {
    this.role = this.data.role;
    this.errorMessage = '';
    this.reportComment = '';
    this.showreportButton = true;
    this.reportCategory = '';
    this.isSubmitInProgress = false;
    this.declareSubmitInProgress = false;
    this.declareSkillCallCompleted = false;
    this.declareSkillSuccess = false;
    this.declareSkillFailed = false;
    this.isReportSent = false;
    this.submitFailed = false;
    this.showPopup = false;
    this.showTermsofUse = true;
    this.searched = false;
    this.skillSelected = {
      Skill: '',
      Expertise: ''
    };

    this.termsOfUse = [
      'You can use this feature on SHOSHIN SCHOOL to Declare your knowledge in skills which you have gained either by experience or by learning the same outside of SHOSHIN SCHOOL.',
      'By Declaring a skill on SHOSHIN SCHOOL you do not gain any credits or badges for the same.',
      'Declared skills will not be considered for Learning Policy Compliance.',
      "Once a skill is declared the same will be updated in your 'Skill Profile' under the 'Declared Skills' Tab.",
      'A mail will be triggered to your reporting manager with the details of Declared skill.',
      'Skills acquired by completing courses on SHOSHIN SCHOOL cannot be Declared.'
    ];
    this.modeOfAcquired = [
      'Select an option',
      'Self-acquired in current project at LTIMindtree Limited',
      'Self-acquired in previous project at LTIMindtree Limited',
      'Have done a personal proof of concept (POC)',
      'Learnt it in my previous job',
      'Did a course outside Shoshin School',
      'Did a certification outside Shoshin School'
    ];
    this.skillList = [];

    this.modeOfAcquiredSelected = this.modeOfAcquired[0];
  }

  submitDeclare() {
    this.declareSubmitInProgress = true;

    //////console.log( vmDeclarePopupTmpl.declareSkillManager );
    const data = {
      CourseId: 0,
      Skill: this.skillSelected.Skill,
      Expertise: this.skillSelected.Expertise,
      Approver: '',
      CertificateURL: '',
      ModeOfAquiring: this.modeOfAcquiredSelected,
      Remarks: '',
      IsCdmOrRm: '',
      SkillPlanId: 0,
      LearningPathId: 0,
      IsSkillPlan: false
    };
    //////console.log( "data for declare submit api:", data );
    this.declarePopupService
      .declareASkill(data)
      .then(
        (response: string) => {
          this.declareSkillCallCompleted = true;
          this.declareSkillSuccess = false;
          this.declareSkillFailed = false;
          this.showPopup = true;
          if ('success' === response) {
            this.declareSubmitInProgress = false;
            this.declareSkillSuccess = true;
          } else {
            this.errorMessage = response;
            this.declareSubmitInProgress = false;
            this.declareSkillFailed = true;
          }
        },
        error => {
          this.showPopup = true;
          this.declareSkillCallCompleted = true;
          this.declareSubmitInProgress = false;
          this.declareSkillFailed = true;
          this.declareSkillSuccess = false;
        }
      )
      .catch(ex => {
        this.declareSkillSuccess = false;
        this.showPopup = true;
        this.declareSkillCallCompleted = true;
        this.declareSubmitInProgress = false;
        this.declareSkillFailed = true;
      });
  }
  validateDeclare(status) {
    switch (status) {
      case this.modeOfAcquired[0]:
      case this.skillList[0]:
        break;

      default:
        break;
    }
  }

  searchSkill(skill, event) {
    let keyEventCheck = true;
    if (
      1 !== event.key.length &&
      'Backspace' !== event.key &&
      'Delete' !== event.key
    ) {
      keyEventCheck = false;
    }
    if (skill.length >= 3 && keyEventCheck) {
      this.declareSkillPopupService
        .getSkillListToDeclare(skill)
        .then((response: any) => {
          this.skillList = response;
        });
    }
    if ('Enter' === event.key) {
      //this.getSkill(skill);
    }
  }

  skillSelectHandler(event) {
    this.setSkill(this.skillName);
  }
  setSkill(SkillObject) {
    this.skillName = SkillObject.Skill;
    this.skillSelected.Skill = SkillObject.Skill;
    this.skillSelected.Expertise = SkillObject.Expertise;
  }

  onClear() {
    this.skillName = '';
    this.searched = false;
  }

  closeDialog() {
    this.dialogRef.close('');
  }
}
