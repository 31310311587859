import * as fromAuthAction from '../actions/auth.action';
import { IauthProfile } from '../auth.interface';

export interface IAuthReducerState {
  userInfo: IauthProfile;
}
export const authInitialState: IAuthReducerState = {
  userInfo:{}
};

export function reducer(
  state = authInitialState,
  action: fromAuthAction.AuthAction
): IAuthReducerState {
  switch (action.type) {
    case fromAuthAction.AUTH_GET_USER_PROFILE: {
      return {
        ...state
      }
    }
    case fromAuthAction.AUTH_GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userInfo: action.payload
      }
    }
    case fromAuthAction.AUTH_GET_USER_PROFILE_FAIL: {
      return {
        ...state
      }
    }
  }
  return state
}

export const getAuthUserInfo = (state: IAuthReducerState) => state;
