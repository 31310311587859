import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  config: Ienvironment;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }

  getFeedbackReports(courseId): Observable<any> {
    return this.http
      .get(this.config.apiUrl + 'Course/Feedback/' + courseId)
      .pipe(
        map((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
}
