import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { DatePipe } from '@angular/common';
import { VideoPlayersService } from '../../shared/video-players/video-players.service';
import { VideoProgressMandateCoursesService } from '../../shared/services/video-progress-mandate-courses.service';
import { SystemService } from '../../shared/services/system.service';

import * as fromDeclareStore from '../../shared/declare-store/store';
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationEnd,
  NavigationStart
} from '@angular/router';
import { LearningPathService } from '../learning-path.service';
import * as fromUserDetailsStore from '../../shared/user-details/store';
import {
  LearningPath,
  PackageList,
  Badge
} from '../../shared/user-details/store/user-details.interface';
import { Store } from '@ngrx/store';
import {
  LPBreadcrumbsStateModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
  IUnitDetailsModel
} from '../learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from '../learning-path-breadcrumbs.interface';
import { ArrayPropertyFilterPipe } from '@YorbitWorkspace/pipes';
import { Subscriber } from 'rxjs';
import { PlaylistService } from '../../shared/playlist/playlist.service';
import { Globals } from '../../globals';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { LinkifyPipe } from '@YorbitWorkspace/pipes';
import { filter } from 'rxjs/operators';
import { DeclarePopupService } from '../../shared/content-tiles/declare-popup/declare-popup.service';
@Component({
  selector: 'yorbit-lp-course-view',
  templateUrl: './lp-course-view.component.html',
  styleUrls: ['./lp-course-view.component.scss']
})
export class LPCourseViewComponent implements OnInit, OnDestroy, AfterViewInit {
  LPBreadcrumbs: ILPBreadcrumbs;
  learningPathsLoadedSuccessfully: boolean = false;
  coursesInsideComplianceLPsLoaded: boolean = false;
  courseDetails: any;
  courseMaterial: any;
  LPCourseCompSubscriptions: any;
  badgeImage: string;
  quizScore: number | string;
  badge: Badge;
  filterPipe = new ArrayPropertyFilterPipe();
  tabsList: Array<any>;
  resumeLP: boolean;
  newAray: Array<any>;
  canPlayAll: boolean;
  recommendedCourses: any;
  recommendationsNotAvailable: boolean;
  quizDetails: any;
  tempquizprogress: any;
  tempretakequiz: boolean;
  temptakequiz:boolean;
  courseProgress: any;
  quizNotAvailable: boolean;
  quizCheckInProgress: boolean;
  courseListForShowingLastCompletedDate: Array<string>;
  pageLoadedSuccessfully: boolean;
  pageLoading: boolean;
  isAccountRelated: boolean;
  courseAvailable: boolean;
  currentAttempt: number;
  allowedAttempt: number;
  currentDisplayAttempt: number;
  coursesInBusinessEthicsLP: any = [];
  coursesInPoshUSA: any = [];
  checkingProgress: boolean;
  attemptsCheck: boolean;
  attemptsCheckDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  isInternalCourse: boolean;
  canSeekMandatoryVideo: boolean;
  haveContentOwnerDetails = false;
  haveLearningOPMSpocDetails = false;
  disableQuizForMultiContentSequence: boolean;
  @ViewChild('CPandLOPMdetailsTrigger', { static: false }) CPandLOPMdetailsMenu;
  @ViewChild('iSupportMenuTrigger', { static: false }) iSupportPopup:MatMenuTrigger;
  @ViewChild('iSupportMenuTrigger1', { static: false }) iSupportPopup1:MatMenuTrigger;
  @ViewChild('iSupportMenuTrigger2', { static: false }) iSupportPopup2:MatMenuTrigger;
  mediaAlias = '';
  isTouchEvent = false;
  learningopmSpocName = '';
  contentOwnerName = '';
  coursesInAssignedLP: any=[];
  showsTooltip = false;
  topPosition: any;
  leftPosition: any;
  tooltipTimer; 
  packageId : any = null;
  quizDetailsShowDetails: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mediaObserver: MediaObserver,
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private playlistService: PlaylistService,
    private globals: Globals,
    private dialog: MatDialog,
    private _systemService: SystemService,
    private declarePopupService: DeclarePopupService,
    private videoPlayerService: VideoPlayersService,
    private videoProgressMandateCoursesService: VideoProgressMandateCoursesService,
    private _declareStore: Store<fromDeclareStore.IdeclareState>
  ) {
    this.pageLoading = true;
    this.pageLoadedSuccessfully = false;
    this.LPCourseCompSubscriptions = {};
    //this.tabsList = ['playlist', 'cloud', 'yammer'];
    this.tabsList = ['playlist', 'cloud'];
    this.resumeLP = false;
    this.newAray = new Array(5);
    this.badge = {
      BadgeId: 0,
      BadgeImage: null,
      BadgeName: '',
      CompletedDate: '0001-01-01T00:00:00',
      CourseId: null,
      IsExternalCertificate: false,
      IsFeedbackMandatory: false,
      IsFeedbackSet: false,
      IsRetakeMandatory: false,
      Score: 0,
      Status: null,
      UniqueId: '',
      isMigrated: false
    };
    this.recommendedCourses = [];
    this.recommendationsNotAvailable = false;
    this.quizDetails = {};
    this.courseProgress = {};
    this.quizNotAvailable = false;
    this.quizCheckInProgress = false;
    this.isAccountRelated = false;
    this.courseListForShowingLastCompletedDate = this.globals.courseListForShowingLastCompletedDate;
    this.courseAvailable = false;
    this.checkingProgress = false;
    this.attemptsCheck = false;
    this.disableQuizForMultiContentSequence = true;
  }
  ngAfterViewInit() { }

  openMenu(event) {
    setTimeout(() => {
      if (this.mediaAlias != 'xs') {
        if (
          this.CPandLOPMdetailsMenu != undefined &&
          !this.CPandLOPMdetailsMenu.menuOpen
        ) {
          this.CPandLOPMdetailsMenu.openMenu();
        }
      } else {
        if (
          this.mediaAlias == 'xs' &&
          event != 'hover' &&
          this.CPandLOPMdetailsMenu != undefined &&
          !this.CPandLOPMdetailsMenu.menuOpen
        ) {
          this.CPandLOPMdetailsMenu.openMenu();
        }
      }
    }, 0);
  }

  subscribeMediaChanges() {
    this.LPCourseCompSubscriptions.mediaChangeSubscription = this.mediaObserver.media$.subscribe(
      (media: MediaChange) => {
        this.mediaAlias = media.mqAlias;
      }
    );
  }
  ngOnInit() {
    this.canSeekMandatoryVideo = false;
    this.LPCourseCompSubscriptions.getUserDetailLoaded = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailLoaded)
      .subscribe(loaded => {
        //console.log("user details loaded");
        if (loaded && !this.learningPathsLoadedSuccessfully) {
          this.learningPathsLoadedSuccessfully = true;
          this.subscribeMediaChanges();
          this.subscribeToResumeStatus();
          this.subscribeToRouteParams();
          this.subscribeToScormBadge();
        }
      });     
  }

  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  subscribeToBreadcrumbs() {
    this.LPCourseCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        this.LPBreadcrumbs = breadcrumbs;
        this.checkQuizAvailability();  //to check the quiz availbility for 201 course
        //remove below call once external course playlist tab view is implemented
        if (this.LPBreadcrumbs.CourseDetails.CourseId) {
          this.isInternalCourse = this.LPBreadcrumbs.CourseDetails.IsInternal;
          if (
            !this.LPBreadcrumbs.CourseDetails.IsInternal ||
            (this.LPBreadcrumbs.CourseDetails.IsInternal &&
              this.LPBreadcrumbs.CourseDetails.Expertise !== '101') ||
            this.LPBreadcrumbs.CourseDetails.Expertise === '301' ||
            this.LPBreadcrumbs.CourseDetails.IsAccount ||
            this.LPBreadcrumbs.CourseDetails.IsProject
          ) {
            //remove tabslist setting to cloud alone when playlist for external course is implemented
            this.navigateToExternalCourseView();
          }
          this.videoPlayerService.fetchCompletionStatus(this.globals.MId, this.LPBreadcrumbs.CourseDetails.CourseId).subscribe((res) => {
            if(res.HasOrder && res.HasQuiz) {
              let totalContentCompleted = 0;
              if(res.ContentCompletionStatus) {
                res.ContentCompletionStatus.forEach(content => {
                  if(content.Completion) {
                    if(content.SourceType.toLowerCase() == 'scorm' && (-1 == this.globals.scormCompletionPopIds.indexOf(content.Id))) {
                      this.globals.scormCompletionPopIds.push(content.Id);
                    }
                    totalContentCompleted++;
                  }
                });
              }
              if(res.TotalContents == totalContentCompleted) {
                this.disableQuizForMultiContentSequence = false;
              }
              else {
              this.disableQuizForMultiContentSequence = true;
              }
            } else {
              this.disableQuizForMultiContentSequence = false;
            }
          });
        }
      });
  }
  subscribeToRouteParams() {
    this.LPCourseCompSubscriptions.routeParamsSubscriptions = this.activatedRoute.params.subscribe(
      params => {
        //console.log("route params", params);
        let payload = null;
        this.isAccountRelated = false;
        this.packageId = params['packageId'];
        if (
          params['accountPackage'] === 'true' ||
          params['projectPackage'] === 'true'
        ) {
          this.isAccountRelated = true;
          payload = {
            AccountId: params['accountId'],
            AccountPackage: params['accountPackage'],
            ItemId: params['courseId'],
            ProjectId: params['projectId'],
            ProjectPackage: params['projectPackage']
          };
        }

        // this.canSeekMandatoryVideo = this.getAttemptsCount(params['courseId']) > 0 ? true : false;
        this.getQuizDetails(params['courseId']);
        this.videoPlayerService.getContentOrderCompletionStatusForQuiz().subscribe((res) => {
          // let contents = res;
          // let totalContentCompletion = 0;
          // let totalCompletion = 0;
          // if(contents.length > 0) {
          //   for (let i = 0; i < contents.length; i++) {
          //     for(let j = 0; j < contents[i].length; j++) {
          //       if (contents[i][j].Completion) {
          //         totalContentCompletion++;
          //       }
          //       totalCompletion++;
          //     }
          //   }
          //   if(totalCompletion == totalContentCompletion) {
          //     this.disableQuizForMultiContentSequence = false;
          //   } else {
          //     this.disableQuizForMultiContentSequence = true;
          //   }
          // }
          if(res) {
            this.disableQuizForMultiContentSequence = false;
          } else {
            this.disableQuizForMultiContentSequence = true;
          }
        });
        this.getRecommendedCourses(params['courseId']);
        this.lpService
          .getCourseDetails(
          params['courseId'],
          'Course',
          this.isAccountRelated,
          payload
          )
          .then((data: any) => {
            if (data != null) {
              this.courseMaterial = {
                CourseMaterialType: data.CourseMaterialType,
                CourseMaterialLink: data.CourseMaterialLink
              };
              this.subscribeToBreadcrumbs();
              if (data.IsHidden) {
                var flag=true;
                this.userDetailsStore.select(fromUserDetailsStore.getLearningPathListEntities)
                .subscribe(res => {
                  for (let key in res) {
                    let value = res[key];
                      if(value.PathName=='Risk Brigade Courses'){
                        value.PackageList.forEach(element => {
                          if(element.ItemId==data.Id){
                            this.courseAvailable = true;
                            flag=false;
                          }
                        });
                      }
                    
                  }
                });

                if(flag){
                  this.userDetailsStore
                  .select(
                  fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
                    params['courseId']
                  )
                  )
                  .subscribe(course => {
                    if (course?.Expertise == '101') {
                      this.userDetailsStore
                        .select(
                        fromUserDetailsStore.getBadgesByCourseIdSelector(
                          params['courseId']
                        )
                        )
                        .subscribe(badge => {
                          if (
                            badge.Status === 'true' ||
                            parseInt(course.Progress) > 0
                          ) {
                            this.courseAvailable = true;
                          }
                        });
                    } else {
                      if (course.WorflowStatus != null) {
                        course.WorflowStatus = course.WorflowStatus.toLowerCase();
                      } else {
                        course.WorflowStatus = '';
                      }
                      if (
                        !(
                          '' == course.WorflowStatus ||
                          'request' == course.WorflowStatus ||
                          'cancelled' == course.WorflowStatus ||
                          'request cancelled' == course.WorflowStatus ||
                          'rejected' == course.WorflowStatus ||
                          'preapproved' == course.WorflowStatus ||
                          'pre-approved' == course.WorflowStatus ||
                          'send request' == course.WorflowStatus ||
                          'not started' == course.WorflowStatus
                        )
                      ) {
                        this.courseAvailable = true;
                      }
                    }
                  });
                }
              } else {
                this.courseAvailable = true;
              }
              if (this.courseAvailable) {
                this.courseDetails = data;

                if (
                  this.courseDetails.ContentOwner != null &&
                  this.courseDetails.ContentOwner.length > 0
                ) {
                  this.contentOwnerName = this.courseDetails.ContentOwner;
                  this._systemService
                    .getNameFromCapacityReportWithMID(
                    this.courseDetails.ContentOwner
                    )
                    .subscribe(
                    (data: any) => {
                      this.haveContentOwnerDetails = true;
                      this.contentOwnerName = data;
                    },
                    error => {
                      ////console.log('Error', error);
                    }
                    );
                } else {
                  this.haveContentOwnerDetails = false;
                }
                if (
                  this.courseDetails.Expertise != '101' &&
                  this.courseDetails.LearningOPMSPOC != null &&
                  this.courseDetails.LearningOPMSPOC.length > 0
                ) {
                  this.learningopmSpocName = this.courseDetails.LearningOPMSPOC;
                  this._systemService
                    .getNameFromCapacityReportWithMID(
                    this.courseDetails.LearningOPMSPOC
                    )
                    .subscribe(
                    (data: any) => {
                      this.haveLearningOPMSpocDetails = true;
                      this.learningopmSpocName = data;
                    },
                    error => {
                      ////console.log('Error', error);
                    }
                    );
                } else {
                  this.haveLearningOPMSpocDetails = false;
                }
                this.lpService.storeCourseDetails(this.courseDetails);
                this.pageLoadedSuccessfully = true;
                this.pageLoading = false;
                //update breadcrumbs and call getCoursesInsideComplianceLPs
                let getLearningPathListEntitiesSub = this.userDetailsStore
                  .select(fromUserDetailsStore.getLearningPathListEntities)
                  .subscribe(res => {
                    console.log("lp breadcrumbs update start", res);
                    let packageEntity = null;
                    if (res[params['lpId'].toUpperCase()] !== undefined) {
                      const learningPath = res[params['lpId'].toUpperCase()];
                      if (
                        params['packageId'] !== 'null' &&
                        params['packageId'] != null
                      ) {

                        //check  rbl lp
                        if(learningPath.PathName== "Role Based Learning"){
                          let rblPackages = this.filterPipe.transform(
                            learningPath.PackageList,
                            {
                              property: 'ItemType',
                              flag: 'RoleBasedPackage'
                            });
                            let undeletedEntities = this.filterPipe.transform(
                              rblPackages,
                              {
                                property: 'IsDeleted',
                                flag: false
                              }
                              );

                              packageEntity = this.filterPipe.transform(
                                undeletedEntities,
                                {
                                  property: 'ItemId',
                                  flag: params['packageId']
                                }
                              )[0];

                        }else{
                          let packages = this.filterPipe.transform(
                          learningPath.PackageList,
                          {
                            property: 'ItemType',
                            flag: 'Package'
                          }
                          );
                          let familyPackages = this.filterPipe.transform(
                          learningPath.PackageList,
                          {
                            property: 'ItemType',
                            flag: 'FamilyPackage'
                          }
                        );
                        let allPackageEntitiesInLP = packages.concat(
                          familyPackages
                        );
                        let undeletedEntities = this.filterPipe.transform(
                          allPackageEntitiesInLP,
                          {
                            property: 'IsDeleted',
                            flag: false
                          }
                          );

                          packageEntity = this.filterPipe.transform(
                            undeletedEntities,
                            {
                              property: 'ItemId',
                              flag: params['packageId']
                            }
                          )[0];
                        }
                        //console.log("lp breadcrumbs update end");
                        this.lpService.updateBreadcrumbs(
                          learningPath,
                          packageEntity,
                          this.courseDetails,
                          null,
                          null
                        );
                      } else {
                        //console.log("lp breadcrumbs update end");
                        this.lpService.updateBreadcrumbs(
                          learningPath,
                          null,
                          this.courseDetails,
                          null,
                          null
                        );
                      }
                    }
                    if (!this.coursesInsideComplianceLPsLoaded) {
                      this.getCoursesInsideComplianceLPs(res);
                    }
                  });
                getLearningPathListEntitiesSub.unsubscribe();
              } else {
                this.pageLoadedSuccessfully = true;
                this.pageLoading = false;
              }
            } else {
              this.courseMaterial = {
                CourseMaterialType: '',
                CourseMaterialLink: ''
              };

              this.pageLoadedSuccessfully = false;
              this.pageLoading = false;
            }
          });
      },
      error => {
        this.pageLoadedSuccessfully = false;
        this.pageLoading = false;
      }
    );
  }

  getQuizDetails(courseId) {
    let isDeclared = false;
    // get decalre status from declare store
    this.userDetailsStore
      .select(fromUserDetailsStore.getBadgesByCourseIdSelector(courseId))
      .subscribe(badge => {
        //////console.log("badges",badge);
        if (badge?.BadgeId) {
          this.badge = { ...badge };
          this._declareStore
            .select(fromDeclareStore.getDeclareStatusByCourseId(courseId))
            .subscribe(declareStatus => {
              ////console.log('declare status', declareStatus);
              if (
                declareStatus != undefined &&
                declareStatus.DeclareFlagStatus == 'DeclaredAndApproved'
              ) {
                isDeclared = true;
              } else {
                isDeclared = false;
              }
            });
        }
      });
    this.userDetailsStore
      .select(
      fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(courseId)
      )
      .subscribe(course => {
        if (course && course.CourseId) {
          //course.Progress = parseInt(course.Progress);

          this.courseProgress = { ...course,Progress : parseInt(course.Progress) };

          if (this.courseProgress.Expertise != '101') {
            if (
              (this.courseProgress.WorflowStatus.toLowerCase() ==
                'course completed' &&
                !this.badge.IsRetakeMandatory) ||
              isDeclared
            ) {
              this.badge.Status = 'true';
            } else {
              this.badge.Status = 'false';
            }
          }
        }
      });
    // this.userDetailsStore
    //   .select(fromUserDetailsStore.getQuizByCourseIdSelector(courseId))
    //   .subscribe(quiz => {
    //   });
    this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailObject)
      .subscribe(userDetails => {
        const quiz = userDetails.QuizDetails.find(
          quizdetails => quizdetails.EntityId == courseId
        );
        if (quiz && quiz.EntityId) {
          if (isDeclared && !quiz.HasPassed) {
            this.badge.BadgeImage =
              'assets/images/Badges/badge_declare_' +
              this.courseProgress.Expertise +
              '.png';
          } else {
            this.badge.BadgeImage =
              'assets/images/Badges/badge_' +
              this.courseProgress.Expertise +
              '.png';
          }
          this.quizDetails = { ...quiz };
          this.tempquizprogress = this.quizDetails.Progress;
          this.tempretakequiz = this.quizDetails.RetakeQuiz;
          this.temptakequiz = this.quizDetails.TakeQuiz;
          //quizDetails saved in another variable quizDetailsShowDetails to prevent data toggle
          this.quizDetailsShowDetails = this.quizDetails;
          this.canSeekMandatoryVideo =
            this.isMandateCourse(courseId) &&
              (this.getAttemptsCount(quiz) > 0 ||
                (this.courseProgress && this.courseProgress.Progress >= 50))
              ? true
              : false;

          if (this.courseProgress.Expertise === '101') {
            /**
             * Declare  BadgeStatus   BadgeURL
             * 1          False       Declared Badge (Colored Badge)
             * 1          True        Acquired Badge (Colored Badge)
             * 0          False       Acquired Badge (grayed out)
             * 0          True        Acquired Badge (Colored Badge)
             */
            if (isDeclared && this.quizDetails.HasPassed) {
              this.badge.BadgeImage =
                'assets/images/Badges/badge_' +
                this.courseProgress.Expertise +
                '.png';
            } else if (isDeclared) {
              this.badge.BadgeImage =
                'assets/images/Badges/badge_declare_' +
                this.courseProgress.Expertise +
                '.png';
            }
            if (isDeclared || this.quizDetails.HasPassed) {
              this.badge.Status = 'true';
            }
          }
        } else {
          if (isDeclared) {
            this.badge.BadgeImage =
              'assets/images/Badges/badge_declare_' +
              this.courseProgress.Expertise +
              '.png';
          } else {
            this.badge.BadgeImage =
              'assets/images/Badges/badge_' +
              this.courseProgress.Expertise +
              '.png';
          }
          this.quizDetails.QuizId = null;
        }
      });
  }
  getRecommendedCourses(id) {
    this.lpService
      .getRecommendationDetails(id)
      .then(response => {
        if (response != null && response !== undefined && response.length > 0) {
          let filteredArray = response.filter(data => {
            if (data.IsHidden) {
              return false;
            } else {
              return true;
            }
          });
          this.recommendedCourses = filteredArray;
          if (this.recommendedCourses.length == 0) {
            this.recommendationsNotAvailable = true;
          }
        } else {
          this.recommendationsNotAvailable = true;
        }
      })
      .catch(err => { });
  }
  getAttemptsCount(quiz): number {
    let result = 0;
    if (quiz && quiz.EntityId) {
      result = quiz.AttemptsCount;
    }
    return result;
  }
  subscribeToResumeStatus() {
    this.lpService.getResumeStatus().subscribe(status => {
      this.resumeLP = status;
    });
  }
  subscribeToScormBadge() {
    this.lpService.getScormBadge().subscribe(status => {
      //this.resumeLP = status;
      //console.warn(status);
      if(this.LPBreadcrumbs) {
        if(status && status.indexOf(this.LPBreadcrumbs.CourseDetails.CourseId)!= -1 && status[status.indexOf(this.LPBreadcrumbs.CourseDetails.CourseId)].status){
          this.badge.Status ="true";
        }
      }
    });
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.LPCourseCompSubscriptions) {
      let subscriber = this.LPCourseCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  navigateToExternalCourseView() {
    this.router.navigate(['type/external'], {
      relativeTo: this.activatedRoute
    });
  }

  startQuiz() {
    let courseId;
    let isYorbitQuiz;
    if (this.isAccountRelated) {
      courseId = this.courseDetails.UniqueId;
      isYorbitQuiz = false;
    } else {
      courseId = this.courseDetails.Id;
      isYorbitQuiz = true;
    }

    this.attemptsCheck = true;

    this.videoPlayerService.updateQuizPopupOpenStatus(true);

    let takeOrRetake = this.quizDetails.TakeQuiz ? 'take' : 'retake';
    const dialogConfig = new MatDialogConfig();
    (dialogConfig.panelClass = 'popupDialogContainer'),
      (dialogConfig.disableClose = true),
      (dialogConfig.data = {});
    dialogConfig.data.message1 = 'Please wait...';
    dialogConfig.data.message2 = '';

    dialogConfig.data.showButtons = false;
    dialogConfig.data.title = this.LPBreadcrumbs.CourseDetails.CourseName;
    dialogConfig.data.quizProgressPopup = false;
    dialogConfig.data.courseId = courseId;
    dialogConfig.data.takeOrRetake = takeOrRetake;
    dialogConfig.data.isMandatoryCourse = this.isMandateCourse(courseId);
    dialogConfig.data.isVideoProgressMandated = this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
      courseId
    );
    //If the preloader is shown for AttemptsCheck API
    dialogConfig.data.attemptsCheck = true;
    this.attemptsCheckDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );

    this.lpService.getAttemptDetails(courseId, isYorbitQuiz).subscribe(data => {
      this.attemptsCheckDialogRef.close();
      this.attemptsCheck = false;
      ////console.log('data', data);
      this.currentAttempt = data.CurrentAttempt;
      this.allowedAttempt = data.AllowedAttempt;
      if (
        this.currentAttempt != 0 &&
        this.currentAttempt > this.allowedAttempt
      ) {
        if (this.currentAttempt % this.allowedAttempt == 0)
          this.currentDisplayAttempt = this.allowedAttempt;
        else
          this.currentDisplayAttempt =
            this.currentAttempt % this.allowedAttempt;
      } else if (this.currentAttempt <= this.allowedAttempt)
        this.currentDisplayAttempt = this.currentAttempt;

      if (
        this.quizDetails.TakeQuiz &&
        !this.quizDetails.ShowProgress &&
        !this.quizDetails.RetakeQuiz &&
        !this.quizDetails.HasPassed &&
        !this.isMandateCourse(courseId) &&
        !this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
          courseId
        )
      ) {
        this.videoPlayerService.updateQuizPopupOpenStatus(false);
        this.router.navigate([
          'quiz/',
          this.LPBreadcrumbs.CourseDetails.CourseId,
          this.LPBreadcrumbs.LPDetails.LPId,
          this.LPBreadcrumbs.LPDetails.LPCategory,
          this.LPBreadcrumbs.PackageDetails.PackageId
            ? this.LPBreadcrumbs.PackageDetails.PackageId
            : 'null'
        ]);
      } else {
        this.LPCourseCompSubscriptions.progressSub = this.userDetailsStore
          .select(
          fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(courseId)
          )
          .subscribe(course => {
            if (course && course.CourseId) {
              course.Progress = parseInt(course.Progress);
              this.courseProgress = { ...course };
            }
            if (this.courseProgress.Progress) {
              this.courseProgress.Progress = parseInt(
                this.courseProgress.Progress
              );
            }
          });
        if (this.courseProgress.Progress) {
          this.courseProgress.Progress = parseInt(this.courseProgress.Progress);
        }

        if (
          (!this.quizDetails.HasPassed &&
            !this.isMandateCourse(courseId) &&
            !this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
              courseId
            ) &&
            ((this.courseProgress.Progress < 50 && this.allowedAttempt == 0) ||
              (this.courseProgress.Progress < 50 &&
                this.currentDisplayAttempt != 0 &&
                this.currentDisplayAttempt != -1) ||
              (this.currentDisplayAttempt === this.allowedAttempt &&
                this.courseProgress.Progress < 100 &&
                this.allowedAttempt > 0)) &&
            this.isInternalCourse) ||
          //For Business Ethics courses, video progress should be min 50% even in the first time. (Take Quiz)
          (this.isMandateCourse(courseId) &&
            this.courseProgress.Progress < 50) ||
          (this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
            courseId
          ) &&
            this.courseProgress.Progress < 100)
        ) {
          //this.videoPlayerService.updateQuizPopupOpenStatus(true);

          let takeOrRetake = this.quizDetails.TakeQuiz ? 'take' : 'retake';
          const dialogConfig = new MatDialogConfig();
          (dialogConfig.panelClass = 'popupDialogContainer'),
            (dialogConfig.disableClose = true),
            (dialogConfig.data = {});
          dialogConfig.data.message1 =
            'Content Progress: ' + this.courseProgress.Progress + '%';
          if (
            this.currentDisplayAttempt === this.allowedAttempt &&
            this.courseProgress.Progress < 100 &&
            this.allowedAttempt > 0
          ) {
            dialogConfig.data.message2 =
              'Sorry! You have exceeded maximum attempts allowed for this quiz. Please go through <span style="font-weight:bold;color:#fff">100%</span> of the course content before re-attempting the quiz.';
          } else if (this.courseProgress.Progress < 50) {
            dialogConfig.data.message2 =
              'Please go through atleast <span style="font-weight:bold;color:#fff">50%</span> of the course content before re-attempting the quiz.';
          } else if (
            this.isMandateCourse(courseId) &&
            this.courseProgress.Progress < 50
          ) {
            dialogConfig.data.message2 =
              'Watch atleast <span style="font-weight:bold;color:#fff">50%</span> of the course content to ' +
              takeOrRetake +
              ' the quiz.';
          } else if (
            this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
              courseId
            ) &&
            this.courseProgress.Progress < 100
          ) {
            dialogConfig.data.message2 =
              'Please watch <span style="font-weight:bold;color:#fff">100%</span> of the course content to ' +
              takeOrRetake +
              ' the quiz.';
          }
          dialogConfig.data.showButtons = false;
          dialogConfig.data.title = this.LPBreadcrumbs.CourseDetails.CourseName;
          dialogConfig.data.quizProgressPopup = true;
          dialogConfig.data.courseId = courseId;
          dialogConfig.data.takeOrRetake = takeOrRetake;
          dialogConfig.data.isMandatoryCourse = this.isMandateCourse(courseId);
          dialogConfig.data.isVideoProgressMandated = this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
            courseId
          );
          const quizProgressDialog = this.dialog.open(
            ConfirmationDialogComponent,
            dialogConfig
          );
          quizProgressDialog.afterClosed().subscribe(canRetakeQuiz => {
            this.videoPlayerService.updateQuizPopupOpenStatus(false);
            if (canRetakeQuiz) {
              this.router.navigate([
                'quiz/',
                this.LPBreadcrumbs.CourseDetails.CourseId,
                this.LPBreadcrumbs.LPDetails.LPId,
                this.LPBreadcrumbs.LPDetails.LPCategory,
                this.LPBreadcrumbs.PackageDetails.PackageId
                  ? this.LPBreadcrumbs.PackageDetails.PackageId
                  : 'null'
              ]);
            } else {
              // Do nothing. Just close the pop up.
            }
          });
        } else {
          this.videoPlayerService.updateQuizPopupOpenStatus(false);
          this.router.navigate([
            'quiz/',
            this.LPBreadcrumbs.CourseDetails.CourseId,
            this.LPBreadcrumbs.LPDetails.LPId,
            this.LPBreadcrumbs.LPDetails.LPCategory,
            this.LPBreadcrumbs.PackageDetails.PackageId
              ? this.LPBreadcrumbs.PackageDetails.PackageId
              : 'null'
          ]);
        }
      }
    });
  }
  goBackToLP() {
    this.router.navigate([
      'learningpath/category/' +
      this.LPBreadcrumbs.LPDetails.LPCategory +
      '/id/' +
      this.LPBreadcrumbs.LPDetails.LPId
    ]);
  }
  checkQuizAvailability() {
    let courseDetails = {
      IsAccount: this.LPBreadcrumbs.CourseDetails.IsAccount,
      IsProject: this.LPBreadcrumbs.CourseDetails.IsProject,
      AccountId: this.LPBreadcrumbs.CourseDetails.AccountId,
      ProjectId: this.LPBreadcrumbs.CourseDetails.ProjectId,
      entityType: 'course',
      courseId: this.LPBreadcrumbs.CourseDetails.CourseId
    };
    this.quizCheckInProgress = true;
    this.lpService
      .quizAvailabilityCheck(courseDetails)
      .then((response: any) => {
        if (response != null) {
          this.quizDetails = response;
          this.quizCheckInProgress = false;
          this.quizNotAvailable = false;
          this.userDetailsStore.dispatch(
            new fromUserDetailsStore.AddQuizDetails(response)
          );
        } else {
          this.quizCheckInProgress = false;
          this.quizNotAvailable = true;
          setTimeout(() => {
            this.quizNotAvailable = false;
          }, 5000);
        }
      })
      .catch(function () {
        this.quizCheckInProgress = false;
        this.quizNotAvailable = true;
        setTimeout(() => {
          this.quizNotAvailable = false;
        }, 5000);
      });
  }
  isDateValid(date) {
    const result = new Date(date);
    if (result.getFullYear() == 1) {
      return false;
    } else {
      return true;
      //  return this.datePipe.transform(date,'dd/MM/yyyy');
    }
  }
  getTime(date, format) {
    const result = new Date(date);
    return new DatePipe('en-US').transform(result, format);
  }
  isMandateCourse(courseId) {
    if (courseId != undefined) {
      const id = courseId.toString();
      if (this.courseListForShowingLastCompletedDate.indexOf(id) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  isPartOfBusinessEthicsLP(courseId) {
    let isPresent = false;
    if (courseId != undefined && this.coursesInBusinessEthicsLP) {
      if (this.coursesInBusinessEthicsLP.length > 0) {
        this.coursesInBusinessEthicsLP.forEach((value, row) => {
          if (value.toString() == courseId.toString()) {
            isPresent = true;
          }
        });
      } else {
        isPresent = false;
      }
    }
    return isPresent;
  }

  isPartOfCoursesInAssignedLP(courseId) {
    let isPresent = false;
    if (courseId != undefined && this.coursesInAssignedLP) {
      if (this.coursesInAssignedLP.length > 0) {
        this.coursesInAssignedLP.forEach((value, row) => {
          if (value.toString() == courseId.toString()) {
            isPresent = true;
          }
        });
      } else {
        isPresent = false;
      }
    }
    return isPresent;
  }
  isPartOfPoshUSA(courseId) {
    let isPresent = false;
    if (courseId != undefined && this.coursesInPoshUSA) {
      if (this.coursesInPoshUSA.length > 0) {
        this.coursesInPoshUSA.forEach((value, row) => {
          if (value.toString() == courseId.toString()) {
            isPresent = true;
          }
        });
      } else {
        isPresent = false;
      }
    }
    return isPresent;
  }
  disableMandatoryRetake(courseId) {
    if (
      this.isMandateCourse(courseId) &&
      (this.isPartOfBusinessEthicsLP(courseId))
    ) {
      if (
        (this.globals.mandatoryLPProgress != null &&
          this.globals.mandatoryLPProgress == '100' &&
          !this.badge.IsRetakeMandatory) ||
        (this.checkComplianceLPProgress() == 100 &&
          !this.badge.IsRetakeMandatory)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.isMandateCourse(courseId) &&
      this.isPartOfPoshUSA(courseId)
    ) {
      if (
        (this.globals.mandatoryLPProgressForPoshUSA != null &&
          this.globals.mandatoryLPProgressForPoshUSA == '100' &&
          !this.badge.IsRetakeMandatory) ||
        (this.checkComplianceLPProgress() == 100 &&
          !this.badge.IsRetakeMandatory)
      ) {
        return true;
      } else {
        return false;
      }
    }else if(this.isMandateCourse(courseId) &&this.isPartOfCoursesInAssignedLP(courseId)){

      if(!this.badge.IsRetakeMandatory){
        if (
          (this.quizDetails &&
            this.quizDetails.HasPassed)){
              return true;
            } else {
              return false;
            }
          }else{
            return false;
          }



    }else {
      return false;
    }
  }
  getMandatoryQuizTooltip() {
    if (
      this.LPBreadcrumbs.LPDetails.LPName == this.globals.CocLpName.toUpperCase() || this.LPBreadcrumbs.LPDetails.LPName == this.globals.BEACLpName.toUpperCase()
      || (this.LPBreadcrumbs.LPDetails.LPName == 'ASSIGNED'
      ) &&
      this.globals.mandatoryRetakeDate &&
      this.globals.mandatoryRetakeDate != null &&
      this.globals.mandatoryRetakeDate != ''
    ) {
      if(this.LPBreadcrumbs.LPDetails.LPName == 'ASSIGNED'){
        return (
          'The course will be enabled in ' + this.globals.LpName=='coc'? this.globals.CocLpName : this.globals.BEACLpName + ' Learning Path in ' +
          this.getTime(this.globals.mandatoryRetakeDate, 'MMM d, y')
          );
      }else{
        return (
          'This button will be enabled on ' +
          this.getTime(this.globals.mandatoryRetakeDate, 'MMM d, y')
          );
        }
    } else if (
     ( this.LPBreadcrumbs.LPDetails.LPName ==
      'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || 
      this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName
      ) &&
      this.globals.mandatoryRetakeDateForPoshUSA &&
      this.globals.mandatoryRetakeDateForPoshUSA != null &&
      this.globals.mandatoryRetakeDateForPoshUSA != ''
    ) {
      return (
        'This button will be enabled on ' +
        this.getTime(this.globals.mandatoryRetakeDateForPoshUSA, 'MMM d, y')
      );
    } else {
      return 'This button will be enabled on your next retake date';
    }
  }

  getCoursesInLP() {
    this.LPCourseCompSubscriptions.lpSubscription = this.userDetailsStore
      .select(fromUserDetailsStore.getLearningPathListEntities)
      .subscribe(lp => {
        let complainceLps = [
          this.globals.LpName=='coc'? this.globals.CocLpName.toUpperCase() :this.globals.BEACLpName.toUpperCase(),
          'SEXUAL HARASSMENT PREVENTION TRAINING - USA',
          this.globals.newPOSHUSLpName,
          'ASSIGNED'
        ];
        complainceLps.forEach(lpName => {
          let pathdetails = lp[lpName];
          let packagelist = [];
          if (pathdetails) {
            packagelist = pathdetails.PackageList;
            if (packagelist.length > 0) {
              if (lpName == this.globals.CocLpName.toUpperCase() ||lpName == this.globals.BEACLpName.toUpperCase()) {
                packagelist.forEach((value, key) => {
                  this.coursesInBusinessEthicsLP.push(value.ItemId);
                });
              } else if (
                lpName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || lpName.toUpperCase() == this.globals.newPOSHUSLpName
              ) {
                packagelist.forEach((value, key) => {
                  this.coursesInPoshUSA.push(value.ItemId);
                });
              }else if(lpName == 'ASSIGNED'){
                packagelist.forEach((value, key) => {
                  if(this.isMandateCourse(value.ItemId)){
                    this.coursesInAssignedLP.push(value.ItemId);
                  }
                });
              }
            }
          }
        });
      });
  }

  getCoursesInsideComplianceLPs(lps) {
    let complainceLps = [
      this.globals.LpName=='coc'? this.globals.CocLpName.toUpperCase() : this.globals.BEACLpName.toUpperCase(),
      'SEXUAL HARASSMENT PREVENTION TRAINING - USA',
      this.globals.newPOSHUSLpName,
      'ASSIGNED'
    ];
    complainceLps.forEach(lpName => {
      let pathdetails = lps[lpName];
      let packagelist = [];
      if (pathdetails) {
        packagelist = pathdetails.PackageList;
        if (packagelist.length > 0) {
          if (lpName == this.globals.CocLpName.toUpperCase() || lpName == this.globals.BEACLpName.toUpperCase()) {
            packagelist.forEach((value, key) => {
              this.coursesInBusinessEthicsLP.push(value.ItemId);
            });
          } else if (
            lpName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || lpName.toUpperCase() == this.globals.newPOSHUSLpName
          ) {
            packagelist.forEach((value, key) => {
              this.coursesInPoshUSA.push(value.ItemId);
            });
          }else if(lpName == 'ASSIGNED'){
            packagelist.forEach((value, key) => {
              if(this.isMandateCourse(value.ItemId)){
                this.coursesInAssignedLP.push(value.ItemId);
              }
            });
          }
        }
      }
    });
    this.coursesInsideComplianceLPsLoaded = true;
  }
  checkComplianceLPProgress() {
    let percent = 0;
    let coursesList = [];
    let completedCourses = [];
    if (
      (this.LPBreadcrumbs.LPDetails.LPName == this.globals.CocLpName.toUpperCase() || this.LPBreadcrumbs.LPDetails.LPName == this.globals.BEACLpName.toUpperCase()) ||
      (this.LPBreadcrumbs.LPDetails.LPName == 'ASSIGNED')
    ) {
      coursesList = this.coursesInBusinessEthicsLP;
    } else if (
      this.LPBreadcrumbs.LPDetails.LPName ==
      'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || 
      this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName
    ) {
      coursesList = this.coursesInPoshUSA;
    }
    if (coursesList.length > 0) {
      coursesList.forEach(itemId => {
        let sub = this.userDetailsStore
          .select(fromUserDetailsStore.getBadgesByCourseIdSelector(itemId))
          .subscribe(badge => {
            if (
              (badge.Status == true || badge.Status == 'true') &&
              !badge.IsRetakeMandatory
            ) {
              completedCourses.push(itemId);
            }
          });
        sub.unsubscribe();
      });
      if (completedCourses.length !== 0) {
        percent = (completedCourses.length / coursesList.length) * 100;
        return percent;
      } else return 0;
    }
    return percent;
  }

  openiSupportMenu( e: MouseEvent) {
   //console.log(e)
    this.tooltipTimer= setTimeout(() => {
      var htmlElemnt=e.target as HTMLElement
      this.showsTooltip = true;
      this.topPosition = htmlElemnt.offsetTop+htmlElemnt.offsetHeight;
      this.leftPosition = htmlElemnt.offsetLeft;
  }, 800);
}

closeiSupportMenu() { 
  clearTimeout(this.tooltipTimer); 
  this.showsTooltip = false;
  this.topPosition = null;
  this.leftPosition = null;
}


}
