import { ActionReducerMap, createSelector,createFeatureSelector} from '@ngrx/store';

import * as fromDeclareReducer from './declare-store.reducer';

export interface IdeclareState{
  declare:fromDeclareReducer.IdeclareStatusReducerState
}
export const declareReducerCollection=fromDeclareReducer.reducer;

export const declareReducers:ActionReducerMap<IdeclareState>={
  declare:fromDeclareReducer.reducer
}
export const getDeclareState= createFeatureSelector<IdeclareState>('declare')

export const getDeclareReducerState=createSelector(
  getDeclareState,
  (state:IdeclareState)=>state.declare
)

export const getDeclareStatus= createSelector(
  getDeclareReducerState,
  fromDeclareReducer.getDeclareStatus
)

export const getDeclareLoading = createSelector(
  getDeclareReducerState,
  fromDeclareReducer.getDeclareLoading
)

export const getDeclareLoaded= createSelector(
  getDeclareReducerState,
  fromDeclareReducer.getDelcareLoaded
)

export * from './declare-store.reducer';
