import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { PssFilterService } from './pss-filter.service';
import { CourseNameComponent } from './course-name/course-name.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { TypeFilterComponent } from './type-filter/type-filter.component';

@Component({
  selector: 'yorbit-pss-filter',
  templateUrl: './pss-filter.component.html',
  styleUrls: ['./pss-filter.component.scss']
})
export class PssFilterComponent implements OnInit {
  pssFilters: any;
  @Input() isCourseSelected: boolean;
  @Input() uploadDownloadData: any;
  @Input() subSelection: any;

  @Output() pssFilterUpdateEvent = new EventEmitter<any>();
  @Output() resetFilterEvent = new EventEmitter<string>();

  @ViewChild(CourseNameComponent, { static: false }) filterCourseName;
  @ViewChild(DateFilterComponent, { static: false }) filterdate;
  @ViewChild(TypeFilterComponent, { static: false }) filtertype;
 
  pssRange: {
    Expertise?: string;
    StartDate: any;
    EndDate: any;
    CertificationStatus?: any;
    CertificationSubStatus?: any;
    CourseType: any;
    Vendor: any;
    Academy: any;
    Location?: any;
    CourseName: any;
    IsArrangeByDate: boolean;
  };
  orderBy: any;

  constructor(private _PssFilterService: PssFilterService) {}

  ngOnInit() {
    this.pssFilters = {
      loading: true,
      loaded: false,
      data: [],
      error: ''
    };
    this.orderBy = {
      type: 'date',
      text: 'Arrange by Course'
    };
    this.filterInit();
  }

  filterInit() {
    this._PssFilterService
      .getPssFilters()
      .then((result: any) => {
        //clear null value
        result.CertificationStatuses.forEach((element, key) => {
          if (element === null) {
            result.CertificationStatuses.splice(key, 1);
          }
        });
        result.CertificationSubStatuses.forEach((element, key) => {
          if (element === null) {
            result.CertificationSubStatuses.splice(key, 1);
          }
        });
        this.pssFilters.data = result;
        this.pssFilters.loaded = true;
        this.pssFilters.loading = false;
      })
      .catch(error => {
        this.pssFilters.loaded = true;
        this.pssFilters.loading = false;
        this.pssFilters.error = 'An Error has occured during adding';
      });
  }

  updateFilter(event) {
    switch (event) {
      case 'course name':
        break;

      case 'date':
        break;
      case 'Type':
        break;

      default:
        break;
    }

    this.updatePssItems();
  }

  updatePssItems() {
    this.pssRange = {
      StartDate: this.filterdate.startDate.apiFormat,
      EndDate: this.filterdate.endDate.apiFormat,

      Expertise: this.filterTypeReduce(
        this.filtertype.SelectedFilter.Expertise
      ),
      CertificationStatus: this.filterTypeReduce(
        this.filtertype.SelectedFilter.CertificationStatuses
      ),
      CertificationSubStatus: this.filterTypeReduce(
        this.filtertype.SelectedFilter.CertificationSubStatuses
      ),
      CourseType: this.filterTypeReduce(
        this.filtertype.SelectedFilter.CourseTypes
      ),
      Vendor: this.filterTypeReduce(this.filtertype.SelectedFilter.Vendors),
      Academy: this.filterTypeReduce(this.filtertype.SelectedFilter.Academies),
      Location: this.filterTypeReduce(this.filtertype.SelectedFilter.Locations),

      CourseName: this.filterCourseName.courseName,
      IsArrangeByDate: 'date' === this.orderBy.type
    };
    this.pssFilterUpdateEvent.emit(this.pssRange);
  }

  filterTypeReduce(category) {
    if (category && 'All' !== category) {
      return category;
    } else {
      return '';
    }
  }

  orderByCourseDate(type) {
    if ('date' === type) {
      this.orderBy.type = 'course';
      this.orderBy.text = 'Arrange by Date';
    } else {
      this.orderBy.type = 'date';
      this.orderBy.text = 'Arrange by Course';
    }
    this.updateFilter('orderBy');
  }

  resetFilter(event) {
    this.orderBy = {
      type: 'date',
      text: 'Arrange by Course'
    };
    this.filterCourseName.courseNameInit();
    this.filterdate.dateInit();
    this.filtertype.typeInit();
    this.resetFilterEvent.emit('Type');    
  }

 
}
