import { Component, Input, OnInit } from '@angular/core';
import { Video } from '../../../details-page/mobile-course-details-page/mobile-course-details-page.component';
import { PlayerVarsIntroVideoO365 } from '../../../shared/video-players/o365-video-player/o365-player.config';

@Component({
  selector: 'yorbit-video-tile',
  templateUrl: './video-tile.component.html',
  styleUrls: ['./video-tile.component.scss']
})
export class VideoTileComponent implements OnInit {

  @Input('align') align:string;
  @Input('video') video:any;

  introVideoUrl: any;
  videoConfig: Video;
  initializeVideo:boolean = false;
  
  constructor() { }

  ngOnInit() {
    this.initVideo();
  }

  initVideo(){
    let videoId;
  
    //check for add
    if (this.video.VideoType.toUpperCase() === 'YOUTUBE') {
      videoId = this.video.VideoLink.split('embed/')[1];
    } else if (this.video.VideoType === 'O365') {
      videoId = this.video.id;
    }
   

  const data = {
    ContentId:videoId,
    UnitId: '',
    CourseId:videoId,
    ItemId: videoId,
    ItemType: 'Content',
    PathId: 0,
    VideoId: videoId,
    sourceUrl: this.video.VideoLink
  };
  this.videoConfig = {
    VideoDetails: data,
    VideoPlayerId: 'IntroVideo' + videoId,
    CanCaptureProgress: false,
    PlayerVars: PlayerVarsIntroVideoO365,
    showInfoMsg: false
  };
  setTimeout(() => {
    this.initializeVideo = true;
  }, 1500);
  }


}
