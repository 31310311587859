<!--
  use as
    <yorbit-share-content
    [card]="{
      'contentData':contentDetails,
      'sharedLocation':routepage}"
    ></yorbit-share-content>

    legends
    -> contentData set to card content details as in
    browse, search, new and trending,
    detail page - package details
    routepage -> routepage name in string

-->
<div
  class="custom-mat-outline"
  fxFlex="30px"
  fxFlexLayout="center center"
  (click)="openDialog()"
  [ngClass]="{'mousePointer':card.enableShare, 'mouseDisabled':!card.enableShare}"
>
  <!-- <mat-icon>radio_button_unchecked</mat-icon>
  <mat-icon fxFlex="30px" [ngClass]="{'overlap-icon':true}"> share </mat-icon> -->
  <div
    class="yorbitSprite featureShare"
    [ngClass]="{
      disable: !card.enableShare,
      iconForAccordion: card.sharedLocation == 'Details-Page-Accordion'
    }"
    title="{{
      card.enableShare
        ? 'Click To Share This Course/Package'
        : 'Courses And Packages Of Accounts/Projects Cannot Be Shared'
    }}"
    fxFlex="30px"
  ></div>
</div>
