import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurAiStrategyComponent } from './our-ai-strategy.component';
import { OurAiStrategyRoutingModule } from './our-ai-strategy-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { MatIconModule } from '@angular/material/icon';
import { VideoPlayersModule } from '../shared/video-players/video-players.module';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';
import { DiversityAndInclusionModule } from '../diversity-and-inclusion/diversity-and-inclusion.module';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { OurAIStrategyVideoTileComponent } from './our-aistrategy-video-tile/our-aistrategy-video-tile.component';
import { OurAIStrategyRelatedVideosComponent } from './our-aistrategy-related-videos/our-aistrategy-related-videos.component';


@NgModule({
  declarations: [
    OurAiStrategyComponent,
    OurAIStrategyVideoTileComponent,
    OurAIStrategyRelatedVideosComponent,
  ],
  imports: [
    CommonModule,
    OurAiStrategyRoutingModule,
    FlexLayoutModule,
    MatDividerModule,
    ReusableUiModule,
    MatIconModule,
    VideoPlayersModule,
    ContentTilesModule,
    DiversityAndInclusionModule,
    FormsModule,
    MatDialogModule
  ]
})
export class OurAiStrategyModule { }
