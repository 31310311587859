<mat-dialog-content>
  <div *ngIf="!isFeedbackListLoading" class="course-feedback-container">
    <div fxLayout="column" *ngIf="
      !isCourseFeedbackSubmitted &&
      showMandatoryFeedbackList &&
      !courseFeedbackPostError
    " fxLayoutAlign="start center" class="course-feedback-holder">
      <div class="feedBack-course-details" fxLayout="column" fxLayoutAlign="center center">
        <!-- <div (click)="close()">Close</div> -->
        <div class="msg1">{{ feedBackMsg1 }}</div>
        <div class="msg2">{{ feedBackMsg2 | uppercase }}</div>
      </div>

      <div class="msg3">{{ feedBackMsg3 }}</div>

      <div class="feedback-rating-section" fxLayout="column" fxLayoutAlign="start center">
        <div class="smiley" fxLayout="column" fxLayoutAlign="center center">
          <!-- class="iconSpriteImage iconSmiley{{selectedStarIndex}}" -->
          <div *ngIf="selectedStarIndex == 0" class="yorbitSprite iconSmileyThinking"></div>
          <div *ngIf="selectedStarIndex == 1" class="yorbitSprite iconSmileyVerySad"></div>
          <div *ngIf="selectedStarIndex == 2" class="yorbitSprite iconSmileySad"></div>
          <div *ngIf="selectedStarIndex == 3" class="yorbitSprite iconSmileyNeutral"></div>
          <div *ngIf="selectedStarIndex == 4" class="yorbitSprite iconSmileyHappy"></div>
          <div *ngIf="selectedStarIndex == 5" class="yorbitSprite iconSmileyVeryHappy"></div>
          <div class="smiley-text">{{ smileyText[selectedStarIndex] }}</div>
        </div>
        <div class="ratings" fxLayout="row" fxLayoutAlign="space-evenly center">
          <div class="" *ngFor="let star of starsCollection; let i = index" (mouseover)="selectedStarIndex = i + 1" (click)="isRatingGiven = true">
            <mat-icon *ngIf="i + 1 <= selectedStarIndex ? true : false" class="start_selected">star</mat-icon>
            <mat-icon *ngIf="i + 1 <= selectedStarIndex ? false : true" class="start_unselected">star_outline</mat-icon>
          </div>
        </div>
      </div>

      <div class="feedback-comments-section" *ngIf="selectedStarIndex != 0 && isRatingGiven">
        <textarea rows="3" placeholder="Optional comments..." [(ngModel)]="feedbackComments" class="input-comments"></textarea>
      </div>

      <div class="feedback-comment-category" *ngIf="selectedStarIndex != 0 && isRatingGiven">
        <div *ngIf="selectedStarIndex > 3" class="msg">What went well?</div>
        <div *ngIf="selectedStarIndex <= 3" class="msg">
          What could be better?
        </div>
        <div class="categories" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
          <div fxFlex="45%" class="category mousePointer" *ngFor="let category of commentCategories; let i = index" [ngClass]="{ categorySelected: category.selected }"
            (click)="selectCategory(i)">
            {{ category.Name }}
          </div>
        </div>
      </div>

      <div class="submit-btn" *ngIf="selectedStarIndex != 0 && isRatingGiven">
        <button (click)="postFeedback()" *ngIf="!postFeedbackInProgress" class="action-button">Submit</button>
        <button [disabled]="true" *ngIf="postFeedbackInProgress" class="action-button">In Progress...</button>
      </div>
    </div>
    <div *ngIf="
      !isCourseFeedbackSubmitted &&
      !showMandatoryFeedbackList &&
      !courseFeedbackPostError &&
      nonMandatoryFeedbackList.length > 0
    " fxLayout="column" fxLayoutAlign="start center" class="course-feedback-holder">
      <div class="NonMandatoryInfo-Heading">
        <!-- You have completed quite a few courses in YORBIT! -->
        Congratulations for completing these courses on Shoshin School
      </div>
      <div class="NonMandatoryInfo-SubHeading">
        <!--
        Would you like to take out few minutes and give feedback for the courses you have completed previously?
      -->
        It will be great if you can take a few minutes to share your feedback
      </div>
      <div class="NonMandatoryCoursesInfo" fxLayout="column" fxLayoutAlign="center center">
        <div class="info">
          Select the course for which you would like to give feedback:
        </div>
        <div class="NonMandatoryCoursesList">

          <div class="course mousePointer" *ngFor="let course of nonMandatoryFeedbackList; let i = index" [ngClass]="{ selectedCourse: selected[course.CourseId] }">
            <div class="courseName" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <input type="radio" name="radiogroup" (click)="filterCourse(course)">
              <div fxFlex class="textTruncate"> {{ course.CourseName }}</div>
            </div>
            <!--
              <input class="inputRadioBtn pull-right" type="checkbox" checked="{{selected.course.courseName}}" [ngModel]="selected[course.courseName]" [ngChange]="filterCourse(selected[course.courseName],course)"/>
            -->

          </div>
        </div>
      </div>
      <div class="proceed-buttons">
        <div class="submit-btn" fxLayout="row">
          <button fxFlex="50" [disabled]="selectedNonMandatoryCourseId==''" class="action-button mousePointer custom" [ngClass]="{'mouseDisabled':selectedNonMandatoryCourseId==''}" (click)="loadFeedbackForCourse()">
            Yes
          </button>
          <button fxFlex="50" class="action-button mousePointer" (click)="closeFeedbackForNonMandatory()">
            No
          </button>
        </div>
      </div>
      <!--
      <div class="never-show-button">
          <input type="checkbox" class="never-show-checkbox pull-left"/>
          <div class="never-show-msg pull-left">
              Don't show this message again
          </div>
      </div>
    -->
    </div>
    <div *ngIf="isCourseFeedbackSubmitted && !courseFeedbackPostError" fxLayout="column" fxLayoutAlign="center center">
      <div class="feedBackSuccess">
        <div *ngIf="false" class="icon-image-withRecommendations" [ngClass]="{
          'icon-image-noRecommendations': !recommendationsAvailable
        }">
          <div class="yorbitSprite iconSmileyHappy"></div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" class="successNote-withRecommendations" [ngClass]="{
          'successNote-noRecommendations': !recommendationsAvailable
        }">
          <!-- Thank you for your feedback(s)! -->
          <div class="yorbitSprite iconSmileyHappy"></div>
          <div class="errorMsg">Thank you for sharing feedback(s).</div>
          <div class="errorMsg">Continue to learn at your pace!</div>
          <div class="submit-btn">
            <button (click)="close()" class="action-button">Ok</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isCourseFeedbackSubmitted && courseFeedbackPostError" fxLayout="column" fxLayoutAlign="center center">
      <div class="feedBackSuccess" fxLayoutAlign="center center">
        <div *ngIf="false" class="icon-image-withRecommendations" [ngClass]="{
          'icon-image-noRecommendations': !recommendationsAvailable
        }">
          <div class="yorbitSprite iconSmileyVerySad sadmargin"></div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" class="successNote-withRecommendations" [ngClass]="{
          'successNote-noRecommendations': !recommendationsAvailable
        }">
          <!-- Thank you for your feedback(s)! -->
          <div class="yorbitSprite iconSmileyVerySad sadmargin"></div>
          <div class="errorMsg">{{ errMsg1 }}</div>
          <div class="errorMsg">{{ errMsg2 }}</div>
          <div class="submit-btn">
            <button (click)="close()" class="action-button">Ok</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="course-feedback-container" *ngIf="isFeedbackListLoading">
    <app-preloader></app-preloader>
  </div>
</mat-dialog-content>