import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlaylistService } from '../../playlist.service';

@Component({
  selector: 'yorbit-certificate-upload-popup',
  templateUrl: './certificate-upload-popup.component.html',
  styleUrls: ['./certificate-upload-popup.component.scss']
})
export class CertificateUploadPopupComponent implements OnInit {
  description: string = '';
  
  
  constructor(public dialogRef: MatDialogRef<CertificateUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private playlistService: PlaylistService,) { }

  ngOnInit(): void {
    //console.log(this.playlistService.notClearReason);
    this.description = 'Are you sure you want to Submit with status as <br> <b>"</b>Not Cleared - '+this.playlistService.notClearReason+'<b>"?</b>' ;
  }

  save() {
    this.dialogRef.close("not clear");
  }

  close() {
    this.dialogRef.close("");
  }
  // closeDialog() {
  //   this.dialogRef.close();
  // }
}
