<!-- Header -->
<mat-sidenav-container [hasBackdrop]="true" fxLayout="row" class="app-container" [ngStyle.xs]="{ height: '100vh' }"
  *ngIf="userId !== ''&& !isFrame" fxFlex>
  <mat-sidenav *ngIf="!globals.hideApp" fxShow.lt-md="true" #drawer [mode]="'over'" fxLayout="column"
    fxLayoutAlign="start stretch" fxFlex="220px">
    <div class="user-profile" fxLayout="column" fxFlex="80px">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <mat-icon *ngIf="userImageFromGraphDetails == null" fxFlex="60px" fxFlexOffset="10px"
          [ngStyle]="{ 'height.em': 1, 'font-size.em': 4 }">person</mat-icon>
        <img class="user-image" *ngIf="userImageFromGraphDetails != null" fxFlex="60px"
          [src]="userImageFromGraphDetails" />
        <div fxFlex fxFlexOffset="10px" fxLayout="column" fxLayoutAlign="start stretch">
          <div class="user-name">{{ userNameFromGraphDetails }}</div>
          <div class="user-id" fxFlexOffset="5px">{{ userId }}</div>
        </div>
      </div>
    </div>
    <!--div
      class="yorbit-points"
      fxFlex="40px"
      fxLayout="row"
      fxLayoutAlign="center start"
    >
      <div fxFlex="80vw" fxLayout="column" fxLayoutAlign="center center">
        <mat-divider fxFlex="1px"></mat-divider>
        <div fxFlex="39px" fxLayout="column" fxLayoutAlign="center center">
          <div>Yorbit Points: {{ yorbitPoints }}</div>
        </div>
      </div> 
    </div>
    <mat-divider></mat-divider-->
    <div class="nav-bar-links-container" fxLayout="column" fxFlex>
      <div [ngClass]="{'borderAnimation-FutureSkillPage': isFutureSkillPage, 'borderAnimation': !isFutureSkillPage}"
        fxLayout="column" ngAnimatedBorderWrapper>
        <a fxFlex (click)="drawer.close(); openFutureSkill()" fxLayoutAlign="center center">
          <img ngAnimatedBorder [ngStyle.lt-md]="{ width: '115px', height: '40px' }" class="futureSkillButton"
            src="../../assets/images/Future_Skills_Logo.jpg" />
        </a>
      </div>
      <div fxFlex="40px" fxLayout="column" *ngFor="let option of navbarLinks" fxLayoutAlign="center stretch">
        <a mat-button fxLayout="row" fxFlex="39px" fxLayoutAlign="start center"
          (click)="drawer.close(); openPopup(option.Name)" [routerLink]="option.Link">
          <div>{{ option.Name }}</div>
        </a>
        <mat-divider fxFlex="1px"></mat-divider>
      </div>

      <div class="drawerstyle" fxLayout="column" fxLayoutAlign="center stretch">
        <div  fxFlex="40px" fxLayout="column" *ngFor="let option of navbarLinksNew" fxLayoutAlign="center stretch">
          <a mat-button fxLayout="row" class="routerlinkfordrawer" fxFlex="39px" fxLayoutAlign="start center" (click)="drawer.close(); openPopup(option.Name)"
            [routerLink]="option.Link">
            <div>{{ option.Name }}</div>
          </a>
          <div class="line"></div>
        </div>
      </div>
      

    

      <div  fxFlex="40px" fxLayout="column" fxLayoutAlign="center stretch">
        <a mat-button fxLayout="row" fxFlex="39px" fxLayoutAlign="start center" (click)="logOut()">
          <div>Log Out</div>
        </a>
      </div>
    </div>
    <div class="footer-xs" fxFlex="100px" fxLayout="column" fxLayoutAlign="center center" fxShow.lt-md="true"
      fxShow.gt-sm="false">
      <app-footer fxFlex="75px" fxLayoutAlign="center center"></app-footer>
    </div>
  </mat-sidenav>
  <mat-sidenav-content #matSidenavContent fxLayout="column" fxFlex (scroll)="onScroll($event)">
    <yorbit-header *ngIf="!globals.hideApp" fxFlex="80px" fxFlex.xs="50px" (openSideNav)="drawer.open()" [id]="userId" [EmpId]="EmpId"> </yorbit-header>
    <!-- Site contents -->
    <!-- <yorbit-manager-feedback></yorbit-manager-feedback> -->
    <router-outlet class="routeOutlets"></router-outlet>
    <!-- Footer -->
    <app-footer *ngIf="!globals.hideApp" fxFlex="80px" fxShow.lt-md="false" fxShow.gt-sm="true" fxFlex.lt-md="120px"
      fxLayoutAlign="center center"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="!isFrame && userId == ''" class="app-container">
  <div fxFlex fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="10px">
    <img [ngStyle.lt-md]="{ width: '200px' }" [ngStyle] = "{ width: '200px' }" src="../../assets/images/Yorbit-Identity.png" />
    <div class="loading-bar">
      <span class="track-wrap">
        <span class="track1"></span>
      </span>
    </div>
    <div class="showclickherestyle" *ngIf="showClickHereMsg">
      <div class="clickhere" (click)="reload()">
        Click Here</div>
      if you are not redirected to Shoshin School.
    </div>
    <div class="helpmessage" *ngIf="showClickHereMsg && showHelpMessage">
      <div class="requeststeps">Request you to follow the below steps if you land on this page.</div> 
         
      <ol>
        <li>Open a new browser instance - clear cache, cookies, and any saved passwords.
          If you are using Chrome, navigate to Settings -> Privacy and Security -> Clear browsing data and Cookies and other site data
        </li>
        <li>Reopen the browser and login to Shoshin School using the link <a [href]="globals.yorbitUrl">{{globals.yorbitUrl}}</a></li>
        <li>Please login with the user name <b>firstname.PS#&#64;ltimindtree.com</b></li>
        <li>If this doesn’t work, please open an incognito window and login to <a [href]="globals.yorbitUrl">{{globals.yorbitUrl}}</a> with user name <a href="">firstname.PS#&#64;ltimindtree.com</a></li>
        <li>Pleasereach ou to   <span  (mouseenter)="openiSupportMenu($event)"
          (mouseout)="closeiSupportMenu()"><a 
          href={{globals.isupportUrl}} target="_blank">
          {{globals.isupportUrl}}</a> </span>   if you still face an issue to login</li>
          <app-tooltip
          [showsTooltip]="showsTooltip"
          [topPosition]="topPosition"
          [leftPosition]="leftPosition">
        </app-tooltip>
                
        </ol>
    </div>
  </div>
</div>