import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() progress: any;
  @Input() expertise: string;

  constructor() {}

  ngOnInit() {}
  ngOnChanges() {

    this.progress = parseInt(this.progress);
    //console.log("Progress Bar", this.progress);
  }
  ngOnDestroy() {}
}
