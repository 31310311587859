import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { APIResponse, Messages } from './../my-page.interface';

@Component({
  selector: 'yorbit-my-page-popup',
  templateUrl: './my-page-popup.component.html',
  styleUrls: ['./my-page-popup.component.scss']
})
export class MyPagePopupComponent implements OnInit {
  params: any;
  msg : string;

  constructor(
    public dialogRef: MatDialogRef<MyPagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.params = data;
  }

  ngOnInit() {
    if(this.params.context === "CrossSkill" || this.params.context === "FutureSkill"){
      this.msg = "Skill " + "<b>'" + this.params.msg +"'</b>" + " is successfully added to your Learning Path";
    }
  }
  close(){
    this.dialogRef.close();
  }
}
