import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import { PssEvaluationService } from './../pss-evaluation.service';
import { CheckboxList, CourseAcademy, CheckboxDisableList, YorbitCourseRequestList } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-pss-academy',
  templateUrl: './pss-academy.component.html',
  styleUrls: ['./pss-academy.component.scss']
})
export class PssAcademyComponent implements OnInit {
  dataList: YorbitCourseRequestList[] = []; 
  academyList: CheckboxList[] = [];
  changedItem: YorbitCourseRequestList[] = [];
  uniqueAcademyList: string[] = [];
  checkedCourseAcademyList: string[] = [];
  changedCourseAcademy: CheckboxDisableList[] = [];
  filteredDataList: YorbitCourseRequestList[] = [];
  checkedCourseList: string[] = [];
  checkedAcademyList: string[] = [];
  searchText:any;
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedCourseVendorList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  checkedEvaluatorVendorList: string[] = [];
  displayList: CheckboxDisableList[] = [];
  changedAcademyList: CheckboxDisableList[] = [];

  @Output() isAcademyFiltered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _PssEvaluationService: PssEvaluationService) { }

  ngOnInit() {
    ////console.log('openonig');
    this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
    this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
    this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
    this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
    this.checkedLearningOPMSPOCList = this._PssEvaluationService.checkedLearningOPMSPOCList;
    this.checkedSubStatusList = this._PssEvaluationService.checkedSubStatusList;
    this.checkedEvaluatorVendorList = this._PssEvaluationService.checkedEvaluatorVendorList;
    this.dataList = this._PssEvaluationService.dataList;
    this.checkedCourseAcademyList = [];
    //this.academyList = this._PssEvaluationService.selectedAcademyList;
    this.checkedCourseAcademyList = this._PssEvaluationService.checkedCourseAcademy;
    ////console.log('academyLength', this.checkedAcademyList.length);
    //if(this.checkedAcademy.length == 0)
      this.GetAcademiesList();
    //else
      //this.GetFilteredAcademiesList();
    //if(this.checkedCourseAcademyList.length > 0) {
      
    //}
  }

  GetAcademiesList() {
    this.filteredDataList = this._PssEvaluationService.filterRecords('Academy');

    ////console.log('.....', this.dataList);
    //For Displaying all unique Sub status list
    for(let i=0; i<this.dataList.length;i++) {
      if(this.uniqueAcademyList.indexOf(this.dataList[i].Academy) == -1) {
        this.uniqueAcademyList.push(this.dataList[i].Academy);
      }
    }

    this.uniqueAcademyList = this.uniqueAcademyList.filter(x => x != null && x.trim() != '');
    //Checking if atleast any one of the filter is applied
    //If no filter is applied, Display all the unique vendor list without disabling any option
    //and selecting any option
    if(this.checkedCourseList.length == 0 
      && this.checkedLearningOPMSPOCList.length == 0
      && this.checkedCourseTypeList.length == 0
      && this.checkedCourseVendorList.length == 0
      && this.checkedSubStatusList.length == 0
      && this.checkedEvaluatorVendorList.length == 0) {
      this.uniqueAcademyList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: true
        }
      }).forEach(item=>this.displayList.push(item));
    }
    //If any filter is applied, disable all the options and deselect all the options
    else {
      this.uniqueAcademyList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
    }
    ////console.log('Filterdata', this.filteredDataList);
    ////console.log('fasond', this.displayList);
    this.enableFilteredSubStatusList();
  } 
  
  //For enabling the Sub status list for which is only present in the FilteredDataList
  enableFilteredSubStatusList() {
    ////console.log('enablefilter', this.filteredDataList);
    for(let i=0; i<this.filteredDataList.length; i++) {
      this.changedAcademyList = [];
      this.changedAcademyList = this.displayList.filter(item => item.value === this.filteredDataList[i].Academy);
      this.changedAcademyList[0].isEnabled = true;
      this.changedAcademyList[0].isSelected = false;
    }
    ////console.log('filterdatafadsfads', this.displayList);
    this.preserveSelected();
  }
  
  //For selecting the Sub status list which was previously selected
  preserveSelected() {
    for(let i=0; i<this.checkedAcademyList.length; i++) {
      this.changedAcademyList = [];
      this.changedAcademyList = this.displayList.filter(item => item.value === this.checkedAcademyList[i]);
      this.changedAcademyList[0].isEnabled = true;
      this.changedAcademyList[0].isSelected = true;
      ////console.log('changeditem', this.changedAcademyList);
    }
    ////console.log('DisplayVenodr', this.displayList);
  }

  GetFilteredAcademiesList() {
    this.displayList = [];
    this._PssEvaluationService.getFilterRecords().subscribe(data=>{
      this.dataList = data as YorbitCourseRequestList[];
      ////console.log(this.dataList,'fasdfads');
      for(let i=0; i<this.dataList.length;i++) {
        if(this.uniqueAcademyList.indexOf(this.dataList[i].Academy) == -1) {
          this.uniqueAcademyList.push(this.dataList[i].Academy);
        }
      }
      this.uniqueAcademyList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
      //var distinctCheckedAcademies = Array.from(new Set(GetFilteredAcademiesList.map((item: string) => item)));
      for(let i=0; i<this.checkedAcademyList.length; i++) {
        this.changedCourseAcademy = this.displayList.filter(item => item.value === this.checkedAcademyList[i]);
        this.changedCourseAcademy[0].isEnabled = true;
        this.changedCourseAcademy[0].isSelected = true;
      }
      ////console.log('Display',this.displayList);
      this._PssEvaluationService.selectedDataList = this.dataList;
    });
  }

  updateCheckedOptions(option, event) {
    ////console.log(this.checkedAcademyList.indexOf(option));
      if(this.checkedAcademyList.indexOf(option) == -1)
        this.checkedAcademyList.push(option);
      else
        this.checkedAcademyList.splice(this.checkedAcademyList.indexOf(option),1);
    this._PssEvaluationService.checkedAcademyList = this.checkedAcademyList;
    ////console.log("Checked", this.checkedAcademyList);
    this.isAcademyFiltered.emit(this.checkedAcademyList.length>0);
  }
}
