import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentTileLpService } from '../content-tile-lp.service';

@Component({
  selector: 'yorbit-learners-intent-popup',
  templateUrl: './learners-intent-popup.component.html',
  styleUrls: ['./learners-intent-popup.component.scss']
})
export class LearnersIntentPopupComponent implements OnInit, OnDestroy {
  showPreloader: boolean;
  intentComments: any;
  intentId: any;
  tooltip = 'Please select learning intent.';
  tooltip2 = 'Please enter comments.';
  intentList: [];
  List: Array<
    {
      IntentId: Number,
      IntentName: String,
      LearnerIntentDescription: String,
      ApproverIntentDescription: String
    }
  > = [];
  popupRefresh: string;

  constructor(
    private dialogRef: MatDialogRef<LearnersIntentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public cardDetails: any,
    private contentTileLpService: ContentTileLpService
  ) { }


  ngOnInit() {
    this.showPreloader = true;
    setTimeout(() => {
      this.popupRefresh = localStorage.getItem('PopupRefresh');
      if (this.popupRefresh != null && this.popupRefresh != undefined) {
        localStorage.removeItem('PopupRefresh');
        this.closePopUp();
        // this.backToLP();
      } else {
        this.showPreloader = false;
        this.getLearningIntentList();
      }
    }, 2000);
    // if(this.intentId == undefined){
    //   this.tooltip = 'Please select learning intent.';
    // }else if(this.intentId == undefined && this.intentComments == ''){
    //   this.tooltip2 = 'Please enter comments.';
    // }
  }

  closePopUp() {
    console.log("LLLLLLLLLLLLLLLleeeeeearn", this.cardDetails);
    //close modal
    this.dialogRef.close(
      {
        openApproval: false,
        approvalData: {
          LearnerIntentId: this.intentId,
          LearnerIntentRemarks: this.intentComments,
          GetIntent: true,
          Prerequisite: this.cardDetails.Prerequisite,
          validationResponse: this.cardDetails.validationResponse
        }
      }
    );
  }

  submitIntent() {
    if (this.intentId != 1) {
      // this.intentComments = 'null'
      this.intentComments = '';
    }
    // ////console.log('submitIntent lists',this.intentId )
    // ////console.log('submitIntent comments',this.intentComments )
    this.dialogRef.close({
      openApproval: true,
      approvalData: {
        LearnerIntentId: this.intentId,
        LearnerIntentRemarks: this.intentComments,
        GetIntent: false,
        Prerequisite: this.cardDetails.Prerequisite,
        validationResponse: this.cardDetails.validationResponse,
        classroom: this.cardDetails.classroom
      }
    });
  }

  goBack() {
    this.dialogRef.close({
      openApproval: true,
      approvalData: {
        LearnerIntentId: this.intentId,
        LearnerIntentRemarks: this.intentComments,
        GetIntent: true,
        Prerequisite: this.cardDetails.Prerequisite,
        validationResponse: this.cardDetails.validationResponse,
        classroom: this.cardDetails.classroom
      }
    });
  }
  clearComments() {
    this.intentComments = '';
  }
  getLearningIntentList() {
    this.contentTileLpService.getLearningIntentList('learner').then((res: any) => {
      const intentList = res;
      intentList.forEach((ele: any) => {
        this.List.push({
          IntentId: ele.IntentId,
          IntentName: ele.IntentName,
          LearnerIntentDescription: ele.LearnerIntentDescription,
          ApproverIntentDescription: ele.ApproverIntentDescription
        });
      })
    })
  }

  ngOnDestroy() {
    if (
      localStorage.getItem('PopupRefresh') != null &&
      localStorage.getItem('PopupRefresh') != undefined
    ) {
      ////console.log('Local Storage being cleared');
      localStorage.removeItem('PopupRefresh');
    }
  }

}
