import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayPropertyFilter'
})
/**
 * use as
 * In HTML
 *  <div *ngFor="let content of (contentList | arrayPropertyFilter:{'property':'IsMandatory','flag':false})">
 *
 * In TS
 * const filterPipe = new ArrayPropertyFilterPipe();
 * const nonMandatoryLP = filterPipe.transform(LP,{'property':'IsMandatory','flag':false});
 *
 * legends
 * array -> any array of object | as here 'contentList'
 * filter -> includes the 'property' to filter, 'flag' to match a value | as here 'IsMandatory' and boolean false
*/

export class ArrayPropertyFilterPipe implements PipeTransform {

  transform(array: any, filter?: Ifilter): any {
    if(!array || !filter){
      return array
    }else{
    return array.filter(item => item[filter.property] === filter.flag);
    }
  }

}

interface Ifilter{
  property?: string;
  flag?: any
}
