import {
  Component,
  OnInit,
  Input,
  Inject,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../../shared/user-details/store';

import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import { NewAndTrendingService } from '../../services/new-and-trending.service';
import { ArrayPropertyFilterPipe } from '@YorbitWorkspace/pipes';
import { AddedContentDataService } from '../../services/added-content-data.service';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { WINDOW } from '../../services/window.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LearningPathService } from '../../../learning-path/learning-path.service';
import { ILPBreadcrumbs } from '../../../learning-path/learning-path-breadcrumbs.interface';
import { LPBreadcrumbsStateModel } from '../../../learning-path/learning-path-breadcrumbs.model';
import { MatDialog } from '@angular/material/dialog';
import { ContentTileDeletePopupComponent } from '../content-tile-lp/content-tile-delete-popup/content-tile-delete-popup.component';
import { DataTemplateDialogComponent } from '../data-template-dialog/data-template-dialog.component';
import { Globals } from '../../../globals';

@Component({
  selector: 'yorbit-package-tile',
  templateUrl: './package-tile.component.html',
  styleUrls: ['./package-tile.component.scss']
})
export class PackageTileComponent implements OnInit {
  @Input('cardDetails') cardDetails: any;
  @Input('featuredIn') featuredIn: any;
  @Output() removeHighlightStyle = new EventEmitter<any>();

  config: Ienvironment;
  roleList: fromRoleAccessStore.IroleData;
  enableShare: boolean;
  isAddedToLP: boolean;
  browseContentTileSub: any;
  coursesList: Array<any>;
  arrayFilterPipe: ArrayPropertyFilterPipe;
  numberOfPeopleRated: number;
  assignerImageUrl: any;
  assignerName: any;
  loadCoursesListFormAPI: boolean;
  loadedCoursesListFormAPI: boolean;
  ContentTileCompSubcriptions: any;
  LPBreadcrumbs: ILPBreadcrumbs;
  showDeleteIcon: boolean;
  noDataAvailable: boolean;
  counter = 0;
  constructor(
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private newAndTrendingService: NewAndTrendingService,
    private _envSvc: EnvironmentService,
    private addedContentDataService: AddedContentDataService,
    private router: Router,
    private _graphSvc: GraphDataService,
    @Inject(WINDOW) private _window: any,
    private sanitizer: DomSanitizer,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private lpService: LearningPathService,
    private dialog: MatDialog,
    private globals: Globals
  ) {
    this.isAddedToLP = false;
    this.browseContentTileSub = {};

    this.coursesList = [];
    this.loadCoursesListFormAPI = true;
    this.loadedCoursesListFormAPI = false;
    this.ContentTileCompSubcriptions = {};
    this.LPBreadcrumbs = LPBreadcrumbsStateModel;
    this.showDeleteIcon = false;

    this.roleList = {};
    this.enableShare = true;
    this.assignerImageUrl = null;
    this.arrayFilterPipe = new ArrayPropertyFilterPipe();
    this.config = this._envSvc.getEnvironment();
    this.numberOfPeopleRated = 0;
    this.noDataAvailable = false;
  }

  ngOnInit() {
    if (this.cardDetails) this.onFirstLoad();
  }

  onFirstLoad() {
    this.cardDetails.IsContentNew = false;
    this.cardDetails.IsContentTrending = false;
    if ('learningPath' !== this.featuredIn) {
      this.isContentNew();
      this.isContentTrending();
      //this.isCardAddedToLP();
      this.getRoles();
      this.loadedCoursesListFormAPI = true;
    } else {
      this.getCoursesInsidePackage();
      if (
        this.cardDetails.AssignedBy != null &&
        this.cardDetails.AssignedBy != ''
      ) {
        this.getAssignerDetails();
      } else {
        this.subscribeToBreadCrumbEvents();
      }
    }
  }

  isContentNew() {
    const minutes = 1000 * 60;
    const hours = minutes * 60;
    const day = hours * 24;
    const today = new Date();
    const difference =
      Date.parse(today.toDateString()) -
      Date.parse(this.cardDetails.CreatedDate);
    const daysBetween = Math.round(difference / day);
    if (14 >= daysBetween) {
      this.cardDetails.IsContentNew = true;
    } else {
      this.cardDetails.IsContentNew = false;
    }
    //logic to enable/disable share button for Project Packages
    if (
      this.cardDetails.AccountPackage == true ||
      this.cardDetails.AccountPackage == 'true' ||
      this.cardDetails.ProjectPackage == true ||
      this.cardDetails.ProjectPackage == 'true'
    ) {
      this.enableShare = false;
    } else {
      this.enableShare = true;
    }
  }
  isContentTrending() {
    this.newAndTrendingService.getNewAndTrendingList().subscribe((list: any) => {
      if (list.trendingPackages && list.trendingPackages.packages) {
        if (
          this.arrayFilterPipe.transform(list.trendingPackages.packages, {
            property: 'UniqueId',
            flag: this.cardDetails.UniqueId
          }).length != 0
        ) {
          this.cardDetails.IsContentTrending = true;
        }
      }
    });
  }
  goToDetailsPage(content) {
    //////console.log(content);
    if ('learningPath' !== this.featuredIn) {
      let webLink, URL, shouldOpenInNewTab;
      if (
        this.featuredIn === 'Recomendation Course' ||
        this.featuredIn === 'Prerequisite Course' ||
        this.featuredIn === 'Prerequisite Course in Course Request'
      ) {
        shouldOpenInNewTab = true;
      } else {
        shouldOpenInNewTab = false;
      }
      if (
        content.AccountPackage === 'true' ||
        content.ProjectPackage === 'true' ||
        content.AccountPackage === true ||
        content.ProjectPackage === true
      ) {
        if (shouldOpenInNewTab) {
          //URL = this.config.apiUrlAPYammer;
          URL =
            this.config.apiUrlAPYammer.split('/detailsPage')[0] +
            '/PdetailsPage/';
        } else {
          URL = 'PdetailsPage/';
        }
      } else {
        if (shouldOpenInNewTab) {
          URL = this.config.apiUrlYorbitYammer;
          URL =
            this.config.apiUrlYorbitYammer.split('/detailsPage')[0] +
            '/detailsPage/';
        } else {
          URL = 'detailsPage/';
        }
      }
      if (
        content.ItemType === 'FamilyPackage' ||
        content.ItemType === 'Course'
      ) {
        //for yorbit content
        if (
          (content.AccountPackage == null ||
            content.AccountPackage === 'false' ||
            content.AccountPackage === false) &&
          (content.ProjectPackage == null ||
            content.ProjectPackage === 'false' ||
            content.ProjectPackage === false)
        ) {
          webLink =
            URL + content.ItemType + '/' + content.Expertise + '/' + content.Id;
        }
        //for account and project content
        else {
          if (content.AccountId === undefined) {
            content.AccountId = content.Account;
          }
          if (content.ProjectId === undefined) {
            content.ProjectId = content.Project;
          }
          if (
            content.AccountPackage === true ||
            content.AccountPackage === 'true'
          ) {
            content.ProjectId = 0;
            content.AccountPackage = true;
            content.ProjectPackage = false;
          } else {
            content.AccountPackage = false;
            content.ProjectPackage = true;
          }
          // content.UniqueId = content.Id;
          webLink =
            URL +
            content.UniqueId +
            '/' +
            content.AccountPackage +
            '/' +
            content.ProjectPackage +
            '/' +
            content.AccountId +
            '/' +
            content.ProjectId +
            '/' +
            content.ItemType +
            '/' +
            content.Expertise +
            '/' +
            content.Id;
        }
      }
      if (shouldOpenInNewTab) {
        window.open(webLink, '_blank');
      } else {
        this.router.navigate([webLink]);
      }
    }
  }

  getRoles() {
    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe(roleList => {
        this.roleList = roleList;
      });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getAssignerDetails() {
    this._graphSvc.getUserImage(this.cardDetails.AssignedBy).subscribe(
      data => {
        this.assignerImageUrl = this.createImageURL(data);
      },
      error => {
        this.assignerImageUrl = null;
      }
    );
    this._graphSvc.getUserName(this.cardDetails.AssignedBy).subscribe(
      data => {
        this.assignerName = data.displayName;
      },
      error => {
        this.assignerName = this.cardDetails.AssignedBy;
      }
    );
  }
  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }

  getCoursesInsidePackage() {
    this.addedContentDataService.contentAddedDetailsForPackagesLoaded.subscribe(
      isLoaded => {
        if (isLoaded) {
          // //console.log(
          //   'packagescourses loaded package tile comp',
          //   this.cardDetails.ItemId
          // );
          // //console.log(
          //   'packagescourses data lp comp',
          //   this.addedContentDataService.packageCourseMappings[
          //   this.cardDetails.ItemId
          //   ]
          // );
          if (
            this.addedContentDataService.packageCourseMappings[
            this.cardDetails.ItemId
            ]
          ) {
            this.coursesList = this.addedContentDataService.packageCourseMappings[
              this.cardDetails.ItemId
            ];
            this.loadedCoursesListFormAPI = true;
          } else {
            this.loadedCoursesListFormAPI = true;
            this.coursesList = [];
          }
          // this.ContentTileCompSubcriptions.getCoursesInPackages = this.userDetailsStore
          //   .select(fromUserDetailsStore.getPackagesListWithCourses)
          //   .subscribe(PackagesListWithCourses => {
          //     if (
          //       PackagesListWithCourses != undefined &&
          //       PackagesListWithCourses[this.cardDetails.ItemId] != undefined
          //     ) {
          //       this.coursesList =
          //         PackagesListWithCourses[this.cardDetails.ItemId];
          //       this.loadedCoursesListFormAPI = true;
          //     } else {
          //       this.loadCoursesListFormAPI = true;
          //       this.coursesList = [];
          //       //if (this.loadCoursesListFormAPI) {
          //       // this.lpService.loadCoursesInsidePackageToStore(
          //       //   this.cardDetails.AssignedBy,
          //       //   this.cardDetails.ItemId,
          //       //   this.cardDetails.AccountId,
          //       //   this.cardDetails.ProjectId
          //       // );
          //       //}
          //     }
          //   });
        }
      }
    );
  }

  subscribeToBreadCrumbEvents() {
    //create breadcrumbs model and interface to init it in different components as well instead in the service
    this.ContentTileCompSubcriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        if (breadcrumbs.LPDetails.LPId != null) {
          this.LPBreadcrumbs = breadcrumbs;
          if (
            this.LPBreadcrumbs.LPDetails.IsLPMandatory ||
            this.LPBreadcrumbs.PackageDetails.PackageId != null
          ) {
            this.showDeleteIcon = false;
          } else {
            this.showDeleteIcon = true;
          }
        }
      });
  }

  deleteItemFromLP() {
    this.removeHighlightStyle.emit(true);
    let isDeletable = true;
    let hasCourseInProgress = false;
    for (
      let courseIndex = 0;
      courseIndex < this.coursesList.length;
      courseIndex++
    ) {
      let course = this.coursesList[courseIndex];
      if (
        course.ItemExpertise == '101' &&
        parseInt(course.PackageProgress.Progress) > 0
      ) {
        hasCourseInProgress = true;
      }
      this.userDetailsStore
        .select(
        fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
          course.ItemId
        )
        )
        .subscribe(entity => {
          if (
            entity.WorflowStatus == null ||
            entity.WorflowStatus == undefined
          ) {
            entity.WorflowStatus = '';
          }
          course.WorflowStatus = entity.WorflowStatus.toLowerCase();
        });
      if (
        (course.ItemExpertise == '201' || course.ItemExpertise == '301') &&
        ('' != course.WorflowStatus &&
          'request' != course.WorflowStatus &&
          'cancelled' != course.WorflowStatus &&
          'request cancelled' != course.WorflowStatus &&
          'rejected' != course.WorflowStatus &&
          'preapproved' != course.WorflowStatus &&
          'pre-approved' != course.WorflowStatus &&
          'send request' != course.WorflowStatus &&
          'not started' != course.WorflowStatus)
      ) {
        isDeletable = false;
        break;
      }
    }
    if (isDeletable) {
      //delete popup
      this.dialog.open(ContentTileDeletePopupComponent, {
        id: 'ContentTileDeletePopupComponent',
        panelClass: 'popupDialogContainer',
        disableClose: true,
        //dialogue-box-data
        data: {
          LPId: this.LPBreadcrumbs.LPDetails.LPId,
          ItemId: this.cardDetails.ItemId,
          ItemExpertise: this.cardDetails.ItemExpertise,
          ItemType: this.cardDetails.ItemType,
          HasCourseInProgress: hasCourseInProgress,
          IsAccount: this.cardDetails.AccountPackage,
          IsProject: this.cardDetails.ProjectPackage,
          ItemName: this.cardDetails.ItemName,
          ProgressList: this.cardDetails.ProgressList
        }
      });
      this.dialog
        .getDialogById('ContentTileDeletePopupComponent')
        .afterClosed()
        .subscribe(deleteResponse => {
          if (deleteResponse) {
            this.coursesList.forEach(course => {
              this.globals.packageCourseList.splice(
                this.globals.packageCourseList.indexOf(course.ItemId),
                1
              );
            });
          }
        });
    } else {
      //show alert msg
      this.dialog.open(DataTemplateDialogComponent, {
        panelClass: 'popupDialogContainer',
        disableClose: true,
        //dialogue-box-data
        data: {
          data:
          'A ' +
          this.cardDetails.ItemExpertise +
          ' package cannot be deleted if there are any 201/301 course(s) in progress',
          itemName: this.cardDetails.ItemName
        }
      });
    }
  }
}
