<!-- <div height="16em"> -->

<mat-card   fxFlexFill fxLayout="column" class="lp-content-tile border{{LPBreadcrumbs.LPDetails.LPName.toUpperCase() !== globals.degreedLPName.toUpperCase() ?cardDetails.ItemExpertise:'Degreed'}} mousePointer skill-target-course" fxLayoutAlign="start stretch" [ngClass]="{
    'skill-target-course':
      isTargetCourse &&
      (LPBreadcrumbs.LPDetails.LPName != globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase()) &&
      LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' &&   this.LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName,
    'compliance-courses-info':
      isTargetCourse &&
      (LPBreadcrumbs.LPDetails.LPName == globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName == globals.BEACLpName.toUpperCase() ||  LPBreadcrumbs.LPDetails.LPName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' ||  LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName)
  }">
  <mat-card-header *ngIf="
      ((LPBreadcrumbs.LPDetails.LPName != globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase()) && LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName) ||
      ((LPBreadcrumbs.LPDetails.LPName == globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName ==globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName) &&
        !isTargetCourse)
    " fxLayout="column" fxFlex="100px" [ngClass]="LPBreadcrumbs.LPDetails.LPName.toUpperCase() !== globals.degreedLPName.toUpperCase() ? 'bg' + cardDetails.ItemExpertise + ' content-tile-lp-header' : 'bgDegreed content-tile-lp-header'">
    <mat-icon class="delete-icon" [ngClass.lt-lg]="{ showDeleteIcon: true }" *ngIf="
        (cardDetails.ItemType == 'Course' && showDeleteIcon) ||
        ((cardDetails.ItemType == 'Package' ||
          cardDetails.ItemType == 'FamilyPackage') &&
          loadedCoursesListFormAPI &&
          showDeleteIcon)
      " (click)="deleteItemFromLP(); $event.stopPropagation()">clear</mat-icon>
    <div fxLayout="row" fxFlex="20px" *ngIf="LPBreadcrumbs.LPDetails.LPName.toUpperCase() !== globals.degreedLPName.toUpperCase()">
      <div class="item-expertise">{{ cardDetails.ItemExpertise }}</div>
    </div>
    <div fxLayout="row" fxFlex="20px" *ngIf="LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase()">
      <div class="item-expertise">D</div>
    </div>
    <div fxLayout="row" fxFlex="50px">
      <!--add styles to trucate text after two lines-->
      <div fxFlex="185px" class="course-name">{{ cardDetails.ItemName }}</div>
    </div>

  </mat-card-header>
  <mat-card-header *ngIf="
     ((LPBreadcrumbs.LPDetails.LPName == globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName ==  globals.BEACLpName.toUpperCase())) &&
      isTargetCourse
    " fxLayout="column" class="content-tile-lp-header mousePointer mandatory-header">
    <!--add styles to trucate text after two lines-->
    <div class="compliance-info-msg-1">
     <span [innerHTML]="message"></span>
      <span class="colorred"><sup>*</sup></span>
    </div>
    <div class="compliance-info-msg-2">
      <sup>*</sup><b>All courses need to be completed and re-taken every year</b>
    </div>
  </mat-card-header>
  <mat-card-header *ngIf="
  (LPBreadcrumbs.LPDetails.LPName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName) &&
   isTargetCourse
 " fxLayout="column" class="content-tile-lp-header mousePointer mandatory-header">
    <!--add styles to trucate text after two lines-->
    <div class="compliance-info-msg-1">
      Complete <b>Sexual Harassment Prevention Training - USA</b> course present in this learning path
      <span class="colorred"><sup>*</sup></span>
    </div>
    <div class="compliance-info-msg-2">
      <sup>*</sup><b>This course needs to be re-taken once every year</b>
    </div>
  </mat-card-header>
  <span class="timeboundleaningdate"
    *ngIf="cardDetails.IsTimeBound==true &&  'course completed' !== courseStatus.toLowerCase()"
    [ngStyle]="{ 'background-color': checkTimeBoundCourseExpiryDate1(cardDetails.DueDate) ? '#E72037':'#394959'}">
    <div class="datetext">{{checkTimeBoundCourseExpiryDate(cardDetails.DueDate)?'Expired Date':'Due Date'}} :
      {{getFormattedDate(cardDetails.DueDate)}} </div>
  </span>
  <mat-card-content fxLayout="column" class="card-content mousePointer" fxLayoutAlign="start stretch" fxFlex="163px"
    [ngStyle]="{
      'max-height': isTargetCourse ? 'unset !important' : '163px',
      'min-height': isTargetCourse ? 'unset !important' : '163px'
    }" [ngClass]="{
      'card-content-skill-target-course': isTargetCourse,
      'no-border':
        isTargetCourse &&
        (LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName ===globals.BEACLpName.toUpperCase() ||
        LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName), 'degreed-tile-content': LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase() 
    }" [ngClass.xs]="{ 'card-content-skill-target-course-xs': isTargetCourse }">
    <!-- request id-->
    <div [ngClass]="{'requestIdColorForSkillPath': isTargetCourse, 'requestId-container' : !isTargetCourse}">
      <div *ngIf="
      cardDetails.ItemType == 'Course' &&
      (cardDetails.ItemExpertise == '201' ||
        cardDetails.ItemExpertise == '301') &&
      !(
        LPBreadcrumbs.LPDetails.LPName.toLowerCase() == 'migrated' &&
        LPBreadcrumbs.LPDetails.IsLPMandatory
      ) &&
      !(cardDetails.RequestId == '0' || cardDetails.RequestId == null)
      &&
      !(
        cardDetails.WorflowStatus == 'Not Started' || cardDetails.WorflowStatus == 'Cancelled' || cardDetails.WorflowStatus == 'Request Cancelled' || cardDetails.WorflowStatus == 'Failed'
      )" [ngStyle]="{'margin': isTargetCourse ? '10px 0px' : '0px'}"> Course Request ID : {{ cardDetails.RequestId }}</div>
    </div>
    <div *ngIf="(cardDetails.ItemExpertise == '101' && isTargetCourse &&
    (LPBreadcrumbs.LPDetails.LPName != globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase()) &&
    LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName )"
      [ngClass]="{'requestIdColorForSkillPath': isTargetCourse, 'requestId-container' : !isTargetCourse}">
      <div [ngStyle]="{'margin': isTargetCourse ? '10px 0px' : '0px'}"></div>
      <!-- space -->
    </div>
    <div fxFlex fxLayoutAlign="start stretch" fxLayout="column" class="assignedBy-courseList-container">
      <div fxFlex="50px" [ngStyle]="{'margin-top': '0px', 'margin-bottom':'14px'}" fxLayout="row"
        fxLayoutAlign="stretch start" *ngIf="
          ((cardDetails.AssignedBy != null &&
            cardDetails.AssignedBy != '' &&
            isTargetCourse) ||
            LPBreadcrumbs.LPDetails.LPId == 'ASSIGNED' || cardDetails.IsTimeBound==true) &&
          LPBreadcrumbs.LPDetails.LPName !==
          globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() && 
            LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' &&
            LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName
        ">
        <div fxFlex="45px" fxLayout="column" class="assignedBy-img-container">
          <img *ngIf="assignerImageUrl != null" fxFlex="45px" class="assignedBy-img" [src]="assignerImageUrl" />
          <mat-icon *ngIf="assignerImageUrl == null" fxFlex="45px" class="assignedBy-img">person</mat-icon>
        </div>
        <div fxFlex="130px" fxFlexOffset="10px" class="assignedBy-details" fxLayout="column">
          <div class="header">Assigned by</div>
          <div class="name" fxFlex="28px">{{ assignerName }}</div>
        </div>

      </div>


      <div fxLayout="column" *ngIf="
      (cardDetails.ItemType == 'Course' ||
        ((cardDetails.ItemType == 'FamilyPackage' ||
          cardDetails.ItemType == 'Package') &&
          !loadedCoursesListFormAPI)) &&
      LPBreadcrumbs.LPDetails.LPName !== globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() &&
      LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName && isTargetCourse
    ">
        <div fxFlex="70px" fxLayout="column" *ngIf="
    (cardDetails.ItemType == 'Course' ||
      ((cardDetails.ItemType == 'FamilyPackage' ||
        cardDetails.ItemType == 'Package') &&
        !loadedCoursesListFormAPI)) &&
    LPBreadcrumbs.LPDetails.LPName !== globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() &&
    LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName && isTargetCourse
  ">
          <ng-template [ngTemplateOutlet]="cloudLabAndCourseStatus"></ng-template>

        </div>
        <div *ngIf="(isTargetCourse && (LPBreadcrumbs.LPDetails.LPName != globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA'))">
          <div fxLayout="row" fxLayoutAlign="start center" class="templatefooter">
            <ng-template [ngTemplateOutlet]="footer" [ngTemplateOutletContext]="{targetCourse: isTargetCourse}"></ng-template>
          </div>
        </div>

      </div>

      <div fxFlex class="course-list-holder" fxLayout="column"
        [ngClass.xs]="{ 'course-list-holder-target-course-xs': isTargetCourse }" *ngIf="
          (((cardDetails.ItemType == 'Package' ||
            cardDetails.ItemType == 'FamilyPackage') &&
            loadedCoursesListFormAPI) ||
            isTargetCourse) &&
          (LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName ===globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName)
        ">
        <ng-template [ngTemplateOutlet]="courseListHolder"></ng-template>
        <div *ngIf="coursesList.length == 0">
          No courses available
        </div>
      </div>
    </div>


    <div *ngIf="
        !retakeDateLoading &&
        isTargetCourse &&
        (LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA')
      " fxLayout="column">
      <div class="mandatory-lp-progress-holder" *ngIf="!retakeDateLoading && canShowRetakeDate()">
        <round-progress [current]="totalRemainingDaysForRetake" [max]="320" [color]="'#7b8f9f'" [background]="'#d5d6d9'"
          [radius]="45" [stroke]="5"></round-progress>
        <div class="mandatory-lp-progress-label" fxLayout="column">
          <div class="mandatory-lp-retake-label">Retake Date:</div>
          <div class="mandatory-lp-retake-date"
            *ngIf="LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName ===globals.BEACLpName.toUpperCase()">
            {{ isDateValid(globals.mandatoryRetakeDate) ? getTime(globals.mandatoryRetakeDate, 'dd/MMM/yyyy') : 'NA' }}
          </div>
          <div class="mandatory-lp-retake-date"
            *ngIf="LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName">
            {{ isDateValid(globals.mandatoryRetakeDateForPoshUSA) ? getTime(globals.mandatoryRetakeDateForPoshUSA,
            'dd/MMM/yyyy') : 'NA'
            }}
          </div>
        </div>
      </div>
      <!--
      <div *ngIf="!retakeDateLoading && showCourseAdditionMsg && LPBreadcrumbs.LPDetails.LPName ===
      'CODE OF CONDUCT'" class="compliance-msg-target-tile">
        {{newAdditionMsg}}
      </div>
      -->
      <div fxLayout="column" class="mandatory-footer-msg" *ngIf="

          ((LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName ===globals.BEACLpName.toUpperCase()) && globals.complianceLPDetails != null && globals.mandatoryLPProgress == '100')||((LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName ) && globals.complianceLPDetailsForPoshUSA!= null && globals.mandatoryLPProgressForPoshUSA == '100')
        ">
        <div>Learning path Completion Date :</div>
        <div>
          {{ learningPathCompletionDate == null ? 'NA' : learningPathCompletionDate }}
        </div>
      </div>
    </div>
    <div fxFlex="70px" fxLayout="column" *ngIf="
        (cardDetails.ItemType == 'Course' ||
          ((cardDetails.ItemType == 'FamilyPackage' ||
            cardDetails.ItemType == 'Package') &&
            !loadedCoursesListFormAPI)) &&
            !(LPBreadcrumbs.LPDetails.LPName === 'ASSIGNED'&&isMandateCourse())&&
        LPBreadcrumbs.LPDetails.LPName !== globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() &&
        LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName && !isTargetCourse
      ">
      <ng-template [ngTemplateOutlet]="cloudLabAndCourseStatus"></ng-template>
    </div>
    <div fxLayout="column" *ngIf="
        cardDetails.ItemType == 'Course' &&
        ((LPBreadcrumbs.LPDetails.LPName === 'ASSIGNED'&&isMandateCourse())||LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName)
      ">
      <div fxFlex="50px" fxLayoutAlign="start center" fxLayout="row" class="cloud-lab-icon-holder"
        [ngStyle]="{'margin-bottom' : cardDetails.ItemExpertise == '101' ? '-10px' : '10px' }"
        *ngIf="!isTargetCourse && isCloudLabAvailable">
        <div class="icon bg{{cardDetails.ItemExpertise}}" fxLayoutAlign="center center" fxLayout="column">
          <div class="yorbitSprite internalCloudLabSmall"></div>
        </div>
        <div class="label mousePointer" title="Click to see cloud lab details">
          Cloud Lab Available
        </div>
      </div>
      <div fxFlex="50px" fxLayout="column" fxLayoutAlign="end stretch">
        <div fxFlexOffset="10px" fxLayout="row" fxFlex="24px" fxLayoutAlign="end center" *ngIf="
            ((((LPBreadcrumbs.LPDetails.LPName === 'ASSIGNED'&&isMandateCourse())||LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase()) && globals.mandatoryLPProgress == '100') || (((LPBreadcrumbs.LPDetails.LPName === 'ASSIGNED'&&isMandateCourse())||LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA') && globals.mandatoryLPProgressForPoshUSA == '100')) &&
            isTargetCourse &&
            learningPathCompletionDate != null
          " class="cursorauto">
          <div class="yorbitSprite course-completed-tick-icon level-{{
              cardDetails.ItemExpertise
            }}"></div>
        </div>
        <div fxFlexOffset="10px" fxFlex="row" fxFlex="24px" fxLayoutAlign="space-around center" *ngIf="
            courseStatus.toLowerCase() == 'course completed' &&
            ((((LPBreadcrumbs.LPDetails.LPName === 'ASSIGNED'&&isMandateCourse())||LPBreadcrumbs.LPDetails.LPName ===
            globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName) &&
              !isTargetCourse) ||
              ((LPBreadcrumbs.LPDetails.LPName === 'ASSIGNED'&&isMandateCourse())||LPBreadcrumbs.LPDetails.LPName !==
              globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName))
          ">
          <!-- <mat-icon>done</mat-icon> -->
          <div *ngIf="
              ((LPBreadcrumbs.LPDetails.LPName === 'ASSIGNED'&&isMandateCourse())||
              LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase() ||
              LPBreadcrumbs.LPDetails.LPName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName) && !isTargetCourse
            " fxLayout="column" class="mandatory-footer-msg-custom">
            <div>Course Completion Date:</div>
            <div class="customfont">
              {{ isDateValid(getCompletedDate(cardDetails.ItemId)) ? getTime(cardDetails.ModifiedDate, 'dd/MMM/yyyy') : 'NA' }}
            </div>
          </div>
          <div class="yorbitSprite course-completed-tick-icon level-{{
              cardDetails.ItemExpertise
            }}"></div>
        </div>
        <div fxLayout="row" fxFlex="40px">
          <button fxFlexFill class="courseWorkflow mousePointer bg{{
              cardDetails.ItemExpertise
            }} course-status  border{{ cardDetails.ItemExpertise }}" *ngIf="
              !isTargetCourse &&
              (cardDetails.ItemExpertise == '201' ||
                cardDetails.ItemExpertise == '301' ||
                (cardDetails.ItemExpertise == '101' &&
                  courseStatus.toLowerCase() == 'course completed') ||
                  (cardDetails.ItemExpertise == '101' &&
                  courseStatus.toLowerCase() == 'course in-progress' && isAScormCourse()))
            " title="{{
              isTargetCourse && !canStartSkillPath
                ? 'To access this course you will need to complete the other courses in the skill plan or declare it'
                : declareWorkflowStatus && cardDetails.ItemExpertise !== '101'
                ? 'Declared course cannot be started'
                : ''
            }}" [disabled]="isTargetCourse && !canStartSkillPath" [ngClass]="{
              mouseDisabled: isTargetCourse && !canStartSkillPath,
              buttonMode: 'start course' === courseStatus.toLowerCase() || 
              'retake course' === courseStatus.toLowerCase(),
              statusMode:
                'start course' !== courseStatus.toLowerCase() &&
                'course completed' !== courseStatus.toLowerCase() && 
                'retake course' !== courseStatus.toLowerCase(),
              completedMode: 'course completed' === courseStatus.toLowerCase(),
              scormButtonMode : isAScormCourse() && !isDateValid(getCompletedDate(cardDetails.ItemId)), 
              'btn-degreed' : LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase()
            }" fxFlex (click)="getApprovalFor201($event)">
            <!-- <span *ngIf="isAScormCourse() && isDateValid(getCompletedDate(cardDetails.ItemId))">{{ courseStatus }}</span> 
           <span *ngIf="isAScormCourse() && !isDateValid(getCompletedDate(cardDetails.ItemId))">{{ "Start Course" }}</span> 

           <span *ngIf="!isAScormCourse()" >{{ courseStatus }}</span>  -->
            {{courseStatus}}
          </button>
          <app-progress-bar *ngIf="
           (cardDetails.ItemExpertise == '101' &&
                (courseStatus.toLowerCase() != 'course completed' &&
                  courseStatus.toLowerCase() == 'start course' && courseStatus.toLowerCase() == 'retake course' ) &&
                !isTargetCourse && canShowProgress) || 
              isTargetCourse || (courseStatus.toLowerCase() == '' && isMandateCourse())
            " fxFlex fxLayout="column" fxLayoutAlign="end stretch" [expertise]="LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase() ? 'Degreed' : isTargetCourse ? '000' : cardDetails.ItemExpertise"
            [progress]="
                    isTargetCourse
                ? (learningPathCompletionDate == null
                  ? '0'
                  :((LPBreadcrumbs.LPDetails.LPName ===
                  globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase()) ? (contentTileLpService.MandatoryLPProgressStatus | async) : (contentTileLpService.MandatoryLPProgressStatus | async)))
                : cardDetails.PackageProgress.Progress
            "></app-progress-bar>
        </div>
      </div>
    </div>
  </mat-card-content>
  <div class="divider" fxFlex="1px" *ngIf="
      (cardDetails.ItemType == 'Package' ||
        cardDetails.ItemType == 'FamilyPackage') &&
      loadedCoursesListFormAPI
    ">
    <mat-divider></mat-divider>
  </div>
  <mat-card-footer
    *ngIf="!(isTargetCourse && (LPBreadcrumbs.LPDetails.LPName != globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName))"
    fxLayout="row" fxFlex="40px" fxLayoutAlign="start center" [ngClass]="{
      'no-border':
        (LPBreadcrumbs.LPDetails.LPName === globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName) &&
        isTargetCourse , 'no-padding' : ShowQuizPromptForExternalQ(), 'degreed-tile-footer': LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase()}">
    <ng-template *ngIf="!(isTargetCourse && (LPBreadcrumbs.LPDetails.LPName != globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName))"
      [ngTemplateOutlet]="footer" [ngTemplateOutletContext]="{
          targetCourse: isTargetCourse
        }"></ng-template>
  </mat-card-footer>
  <mat-card-footer class="targetcoursestyle"
  *ngIf="(isTargetCourse && (LPBreadcrumbs.LPDetails.LPName != globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName))"
  (click)="$event.stopPropagation()">
    <div class="coursesunderproj">Courses under the project</div>
    <div class="heightcustom">
      <div fxFlex class="course-list-holder customcursor" fxLayout="column" [ngClass.xs]="{ 'course-list-holder-target-course-xs': isTargetCourse }"
        [ngStyle]="{'margin': (isTargetCourse && cardDetails.RequestId) ? '0px 0 22px' : '14px 0'}" *ngIf="
        (((cardDetails.ItemType == 'Package' ||
          cardDetails.ItemType == 'FamilyPackage') &&
          loadedCoursesListFormAPI) ||
          isTargetCourse) &&
        LPBreadcrumbs.LPDetails.LPName !==
        globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() &&
          LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' &&
          LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName
      " (click)="$event.stopPropagation()">
      <ng-template [ngTemplateOutlet]="courseListHolder"></ng-template>
    </div>
   <div *ngIf="coursesList.length == 0" class="nocourseavail">
        No courses available
      </div>
    </div>
  </mat-card-footer>
  <div class="externalCertificateContainer" *ngIf="
      cardDetails.Type &&
      'external certification' === cardDetails.Type.toLowerCase()
    ">
    <div class="yorbitSprite externalCertificate"></div>
  </div>
  <div class="declare-flag-pos" *ngIf="showDeclareIcon && declareWorkflowStatusLoaded && !courseIdAceingLeadership && LPBreadcrumbs.LPDetails.LPName.toUpperCase() !== globals.degreedLPName.toUpperCase()">
    <div class="yorbitSprite icon-declare-flag " [ngClass]="{
        courseDeclaredIcon: declareWorkflowStatus,
        declareDisabled: isCourseWorkflowStarted
      }" (click)="declareSkill($event)" title="{{
        isCourseWorkflowStarted
          ? showToolTip(cardDetails.WorflowStatus)
          : declareWorkflowStatus
          ? declareStatusMessage
          : 'Declare this skill'
      }}"></div>
  </div>
</mat-card>
<!-- </div> -->

<ng-template #footer let-data="targetCourse">
  <div *ngIf="((LPBreadcrumbs.LPDetails.LPName !== globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase()) && (LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA') && (LPBreadcrumbs.LPDetails.LPName !== 'MIGRATED')) &&
   show201CourseTileMsg" class="quiz-prompt-msg mousePointer"
    [ngClass]="{'leftalign': (courseStatus.toLowerCase() == 'request sent' || 'processing' == courseStatus.toLowerCase()),'target-tile-msg-color':isTargetCourse}">
    Click to view Course Workflow
  </div>
  <div *ngIf="false && !show201CourseTileMsg && courseStatus.toLowerCase() == 'not cleared'">
    <button (click)="cancelNotClearedCourse($event)">
      Start Course
    </button>
  </div>
  <div fxFlex fxLayoutAlign="start center" *ngIf="
      LPBreadcrumbs.LPDetails.LPName !== globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() &&
      LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName &&
      !isTargetCourse
    ">
    <div class="content-count" *ngIf="
        cardDetails.ItemExpertise == '101' &&
        cardDetails.ItemType == 'Course' &&
        !(
          cardDetails.AccountPackage == true ||
          cardDetails.ProjectPackage == true
        ) &&
        !(ShowQuizPromptForExternalQ() || ShowQuizPrompt())
        && (-1 == scormCourseIds.indexOf(cardDetails.ItemId))
      ">
      {{ cardDetails.VideoCount }} Content(s)
    </div>
    <div class="content-count" *ngIf="
        cardDetails.ItemExpertise == '101' &&
        cardDetails.ItemType == 'Course' &&
        (cardDetails.AccountPackage == true ||
          cardDetails.ProjectPackage == true) &&
          !(ShowQuizPromptForExternalQ() || ShowQuizPrompt())
      ">
      {{ cardDetails.VideoCount }} File(s)
    </div>
    <!-- <div class="content-count" *ngIf="(cardDetails.ItemExpertise == '201'|| cardDetails.ItemExpertise == '301') && cardDetails.ItemType=='Course'">{{credits}} Credit(s)
        </div> -->
    <div class="content-count" *ngIf="
        (cardDetails.ItemType == 'Package' ||
          cardDetails.ItemType == 'FamilyPackage') &&
        loadedCoursesListFormAPI
      ">
      {{ coursesList.length }} Course(s)
    </div>
  </div>
  <div fxFlex fxLayoutAlign="end center" class="course-action-link"
    (click)="cancelApprovalFor201(); $event.stopPropagation()" *ngIf="
      LPBreadcrumbs.LPDetails.LPName !== globals.CocLpName.toUpperCase() &&  LPBreadcrumbs.LPDetails.LPName !==  globals.BEACLpName.toUpperCase() &&
      LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName &&
      (cardDetails.ItemExpertise == '201' ||
        cardDetails.ItemExpertise == '301') &&
      ('request sent' == courseStatus.toLowerCase() ||
        'processing' == courseStatus.toLowerCase())
    ">
    <div class="mousePointer">
      Cancel
    </div>
  </div>
  <div *ngIf="(LPBreadcrumbs.LPDetails.LPName ===
  globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName === 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName) && !isTargetCourse &&
  canShowQuizPrompt()" class="quiz-prompt-msg">
    It's time to attempt the Quiz.
  </div>
  <div *ngIf="LPBreadcrumbs.LPDetails.LPName !==
  globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName &&
  ShowQuizPrompt()" class="quiz-prompt-msg">
    It's time to attempt the Quiz.
  </div>
  <div *ngIf="LPBreadcrumbs.LPDetails.LPName !==
  globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName !== 'SEXUAL HARASSMENT PREVENTION TRAINING - USA'&& LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName && 
  ShowQuizPromptForExternalQ() " class="quiz-prompt-msg">
    Watch content & attempt the Quiz.
    <!--Watch content and attempt quiz.-->
  </div>
</ng-template>
<ng-template #courseListHolder>
  <div *ngFor="let course of coursesList; let i = index" [ngStyle.gt-sm]="{'cursor':'auto !important'}">
    <div fxLayout="row" class="targetcoursestyle" fxLayoutAlign="start center">
      <div *ngIf="!isTargetCourse">{{ i + 1 + '. ' }}</div>
      <div fxFlex="30px" *ngIf="isTargetCourse">
        <mat-icon
          *ngIf="isTargetCourse && (LPBreadcrumbs.LPDetails.LPName !=
        globals.CocLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != globals.BEACLpName.toUpperCase() && LPBreadcrumbs.LPDetails.LPName != 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' && LPBreadcrumbs.LPDetails.LPName.toUpperCase() != this.globals.newPOSHUSLpName )"
          [ngClass]="{'skill-path-completed-course' : course.Completed }"
          class="skill-path-completed-course-{{cardDetails.ItemExpertise}}">done</mat-icon>
        <mat-icon
          *ngIf="isTargetCourse && (LPBreadcrumbs.LPDetails.LPName ==
        globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName ==globals.BEACLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == this.globals.newPOSHUSLpName )"
          [ngClass]="{'completed-course': course.Completed,'custom-keyboard-done': course.Completed}">done</mat-icon>
      </div>
      <div fxFlex [ngClass.lt-md]="'mousePointer'" (click)="showCourseFromSkillPath(course.ItemId, $event)" fxLayoutAlign="start center"
        [ngStyle]="{ overflow: 'hidden' }">
        <div class="item-name colorblack" fxFlex>
          {{ course.ItemName }}
        </div>
        <div [fxShow.xs]="isTargetCourse" [fxShow.gt-xs]="false">
          <mat-icon class="keyboard_arrow_right custom-keyboard-right-arrow">keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #cloudLabAndCourseStatus>
  <div fxFlex="70px" fxLayout="column" fxLayoutAlign="end stretch">
    <div fxFlex="30px" fxLayoutAlign="start center" fxLayout="row" class="cloud-lab-icon-holder"
      *ngIf="cardDetails.ItemType == 'Course' && isCloudLabAvailable && LPBreadcrumbs.LPDetails.LPName !== 'MIGRATED'">
      <div class="icon bg{{cardDetails.ItemExpertise}}" fxLayoutAlign="center center" fxLayout="column">
        <div class="yorbitSprite internalCloudLabSmall"></div>
      </div>
      <div class="label mousePointer" title="Click to see cloud lab details"
        [ngStyle]="{'color':isTargetCourse ? '#000':'#000'}">
        Cloud Lab Available
      </div>
    </div>

    <div fxFlexOffset="10px" fxFlex="row" fxFlex="24px" fxLayoutAlign="center center"
      *ngIf="courseStatus.toLowerCase() == 'course completed'">
      <!-- <mat-icon>done</mat-icon> -->
      <div class="yorbitSprite  course-completed-tick-icon level-{{LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase() ? 'Degreed' : cardDetails.ItemExpertise}}"></div>
    </div>
    <div fxLayout="row" fxFlex="40px">
      <button fxFlexFill class="courseWorkflow mousePointer bg{{
              cardDetails.ItemExpertise
            }} course-status  border{{ cardDetails.ItemExpertise }}" *ngIf="
              cardDetails.ItemExpertise == '201' ||
              cardDetails.ItemExpertise == '301' ||
              (cardDetails.ItemExpertise == '101' &&
                courseStatus.toLowerCase() == 'course completed' &&
                cardDetails.ItemType == 'Course') ||
                (cardDetails.ItemExpertise == '101' &&
                courseStatus.toLowerCase() == 'course in-progress' &&
                cardDetails.ItemType == 'Course')||
              cardDetails.ItemType == 'FamilyPackage' ||
              cardDetails.ItemType == 'Package'
            " title="{{
              isTargetCourse && !canStartSkillPath
                ? 'To access this course you will need to complete the other courses in the skill plan or declare it'
                : declareWorkflowStatus && cardDetails.ItemExpertise !== '101'
                ? 'Declared course cannot be started'
                : ''
            }}" [disabled]="isTargetCourse && !canStartSkillPath" [ngClass]="{
              mouseDisabled:
                (isTargetCourse && !canStartSkillPath) ||
                (declareWorkflowStatus && cardDetails.ItemExpertise !== '101'),
              buttonMode: 'start course' === courseStatus.toLowerCase() ||
              'retake course' === courseStatus.toLowerCase(),
              statusMode:
                'start course' !== courseStatus.toLowerCase() &&
                'course completed' !== courseStatus.toLowerCase() &&
                'retake course' !== courseStatus.toLowerCase(),
                'btn-degreed' : (LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase() && 'course completed' !== courseStatus.toLowerCase()),
              completedMode: 'course completed' === courseStatus.toLowerCase()
            }" fxFlex (click)="getApprovalFor201($event)">
            <!-- ,bgTargetCourse:isTargetCourse-->
            <!-- {{ courseStatus }} -->
            {{ LPBreadcrumbs.LPDetails.LPName.toUpperCase() !== globals.degreedLPName.toUpperCase() ? courseStatus : ('start course' === courseStatus.toLowerCase() ? 'start pathway' : ('course completed' === courseStatus.toLowerCase() ? 'pathway completed' : 'pathway in-progress'))}}
          </button>         
      <app-progress-bar *ngIf="
                cardDetails.ItemExpertise == '101' &&
              courseStatus.toLowerCase() != 'course completed'&&
              (courseStatus.toLowerCase() != 'course in-progress')
               &&
              !(
                cardDetails.ItemType == 'FamilyPackage' ||
                cardDetails.ItemType == 'Package'
              )
            " fxFlex fxLayout="column" fxLayoutAlign="end stretch" [expertise]="LPBreadcrumbs.LPDetails.LPName.toUpperCase() === globals.degreedLPName.toUpperCase() ? 'Degreed' : cardDetails.ItemExpertise" [progress]="cardDetails.PackageProgress?.Progress || '0'"></app-progress-bar>
    </div>
  </div>
</ng-template>