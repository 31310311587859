import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { BellNotificationService } from '../../bell-notifications.service';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as NotificationsActions from '../actions/notifications.actions';
@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private bellNotificationService$: BellNotificationService
  ) {}
  
  getNewNotifications$ = createEffect(() => this.actions$.pipe(
  ofType('GET_NEW_NOTIFICATIONS'),
    map((action: NotificationsActions.GetNewNotifications) => action.payload),
    switchMap(payload => {
      return this.bellNotificationService$
        .getOldNotificationsFromDB(payload, false)
        .pipe(
          map(res => {
            return new NotificationsActions.GetNewNotificationsSuccess(res);
          }),
          catchError(error =>
            of(new NotificationsActions.GetNewNotificationsFailure())
          )
        );
    })
  ));

  
  getOldNotifications$ = createEffect(() => this.actions$.pipe(
  ofType('GET_OLD_NOTIFICATIONS'),
    map((action: NotificationsActions.GetOldNotifications) => action.payload),
    switchMap(payload => {
      return this.bellNotificationService$
        .getOldNotificationsFromDB(payload, true)
        .pipe(
          map(res => {
            return new NotificationsActions.GetOldNotificationsSuccess(res);
          }),
          catchError(error =>
            of(new NotificationsActions.GetOldNotificationsFailure())
          )
        );
    })
  ));
}
