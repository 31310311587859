import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, args: any): any {
    if (!array || !args) {
      return array;
    } else {
      if (args.order.toLowerCase() == 'ascending') {
        array.sort((a: any, b: any) => {
          if (a[args.property] < b[args.property]) {
            return -1;
          }
          if (a[args.property] > b[args.property]) {
            return 1;
          }

          return 0;
        });

        return array;
      }
      else if (args.order.toLowerCase() == 'descending'){
        array.sort((a: any, b: any) => {
          if (a[args.property] < b[args.property]) {
            return 1;
          }
          if (a[args.property] > b[args.property]) {
            return -1;
          }

          return 0;
        });

        return array;
      }
    }
  }
}
