<div class="pageSizeContainer">
  <div class="pageSizeLabel">
    Number of items per page
  </div>
  <mat-form-field class="pageSizeSelect">
  <mat-select
    [(value)]="paginateFilter.pageSizeLimit"

    panelClass="pssPaginateSelect"
    (selectionChange)="onPageSizeLimitChange()"
    disableOptionCentering
  >
    <mat-option *ngFor="let value of pageSizeOptions" [value]="value">
      {{ value }}
    </mat-option>
</mat-select>
</mat-form-field>
</div>
<div class="pageSizeDisplayData">
  <mat-paginator
  class="pssPaginate"
  [length]="paginateFilter.pageLength"
  [pageSize]="paginateFilter.pageSizeLimit"
  [hidePageSize]="true"
  [pageIndex]="pageIndex"
  (nextPage)="onPaginate()"
  (page)="onPaginate()"
  >
</mat-paginator>
</div>
