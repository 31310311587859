import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { PssService } from '../pss.service';
@Injectable({ providedIn: 'root' })
export class CanActivatePss implements CanActivate, CanActivateChild {
  constructor(private pssservice: PssService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    //if session expiry pop up is open dont resolve route
    if (
      localStorage.getItem('isSessionExpiryPopupOpen') != null &&
      parseInt(localStorage.getItem('isSessionExpiryPopupOpen')) == 1
    ) {
      return false;
    } else {
      // if there are any pending certification  date changes then return false
      if (this.pssservice.certDateChangesList.length != 0) {
        this.pssservice.updateCertDateChangesStatus({
          status: true,
          url: state.url
        });
        return false;
      } else {
        this.pssservice.updateCertDateChangesStatus({ status: false });
        return true;
      }
    }
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // if there are any pending certification  date changes then return false
    //Child,route', route);
    //////console.log('canActivateChild,state', state);
    if (
      localStorage.getItem('isSessionExpiryPopupOpen') != null &&
      parseInt(localStorage.getItem('isSessionExpiryPopupOpen')) == 1
    ) {
      return false;
    } else {
      if (this.pssservice.certDateChangesList.length != 0) {
        this.pssservice.updateCertDateChangesStatus({
          status: true,
          url: state.url
        });
        return false;
      } else {
        this.pssservice.updateCertDateChangesStatus({ status: false });
        return true;
      }
    }
  }
}
