import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, share } from 'rxjs/operators';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewAndTrendingService {
  config: Ienvironment;
  newAndTrendingList: any;
  newAndTrendingListpromise: any;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.newAndTrendingList = [];
  }
  getNewAndTrendingList() {
    // if (this.newAndTrendingListpromise == undefined) {
    //   this.newAndTrendingListpromise = new Promise((resolve, reject) => {
    //     if (this.newAndTrendingList.length == 0) {
    //       this.http
    //         .get(this.config.apiUrl + 'HomePage/TrendingAndNew')
    //         .toPromise()
    //         .then(list => {
    //           this.newAndTrendingList = list;
    //           resolve(list);
    //         });
    //     } else {
    //       resolve(this.newAndTrendingList);
    //     }
    //   });
    //   return this.newAndTrendingListpromise;
    // } else {
    //   return this.newAndTrendingListpromise;
    // }

    // return this.http.get(this.config.apiUrl + 'HomePage/TrendingAndNew')

    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    });

    return this.http.get(this.config.apiUrl + 'HomePage/TrendingAndNew', { headers: headers });
  }
}
