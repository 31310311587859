<mat-dialog-content>
  <div class="closePopupBtn" *ngIf="canClosePopup">
    <button class="mat-button mat-icon-button"  (click)="closePopUp()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title" title="{{ cardDetails.ItemName }}">
    <div class="heading">{{ cardDetails.ItemName }}</div>
  </div>
  <div class="confirmation-box" *ngIf="!requestCancellation" fxLayout="column">
    <div class="message" fxFlex="40px">
      {{ message }}
    </div>
    <div class="cancelRequest-select mousePointer" fxLayout="column">
      <div
        class="selected-option"
        (click)="isSelectedOption = !isSelectedOption"
      >
        <div fxFlex class="commentOrReason">
          {{ commentOrReason }}
        </div>
        <mat-icon fxFlex="20px" *ngIf="isSelectedOption"
          >keyboard_arrow_down</mat-icon
        >
        <mat-icon fxFlex="20px" *ngIf="!isSelectedOption"
          >keyboard_arrow_up</mat-icon
        >
      </div>
      <div class="options" *ngIf="!isSelectedOption" fxLayout="column">
        <div
          class="option"
          *ngFor="let option of cancellation"
          (click)="commentOrReason = option; isSelectedOption = true"
        >
          {{ option }}
        </div>
      </div>
    </div>
    <div
      class="cancelRequest-TextArea"
      *ngIf="commentOrReason == 'Others'"
      fxFlex="40px"
      fxLayout="column"
    >
      <textarea
        placeholder="Reason for cancellation"
        [(ngModel)]="reasonForCancelling"
        fxFlex="40px"
      ></textarea>
      <div
        class="mandatory-msg"
        *ngIf="mandatoryMsg != null"
        fxFlex="10px"
      ></div>
    </div>
    <div class="actionButtonContainer">
      <button class="actionButton action-button" 
      title="{{(commentOrReason == 'Others' && reasonForCancelling == '')?'Please give a reason':''}}"
      [ngClass]="{'disabled':commentOrReason == 'Others' && reasonForCancelling == ''}"
      [disabled]="commentOrReason == 'Others' && reasonForCancelling == ''" (click)="cancelRequest()">
        Yes
      </button>
      <button class="actionButton action-button" (click)="closePopUp()">
        No
      </button>
    </div>
  </div>
  <div
    class="processing-request-info"
    *ngIf="requestCancellation"
    fxLayout="column"
  >
    <div class="message" *ngIf="showPreloader" fxFlex="30px">
      <div>Cancellation in progress, please wait...</div>
    </div>
    <div class="message" *ngIf="!showPreloader" fxFlex="30px">
      <div>{{ validationResponse }}</div>
    </div>
    <div class="actionButtonContainer" *ngIf="!showPreloader">
      <button class="action-button" (click)="closePopUp()">OK</button>
    </div>
    <div class="message" *ngIf="showPreloader">
      <app-preloader></app-preloader>
    </div>
  </div>
</mat-dialog-content>
