import { Injectable } from '@angular/core';
import { AppComponent } from './app.component';
@Injectable()
export class Globals {
  //Customer Access flag
  isCustomerAccess = false;

  //Manager Feedback Popup
  isManagerFeedbackPopupShown = false;
  managerFeedbackList = [];

  //Course Feedback Popup
  isCourseFeedbackPopupShown = false;
  courseFeedbackList = [];

  isLearnerCompliant = false;
  topLearnersData = [];
  topCoachesData = [];
  topFacilitatorsData = [];
  //course ids for which last completed date has to be shown in lp
  courseListForShowingLastCompletedDate = [
    '2290',
    '793',
    '192',
    '3222',
    '3223',
    '1416',
    '4126', // posh usa course
    '5178',
    '5184',
    '5189',
    '5206',
    '1171',
    '10136',
    '10137',
    '10138',
    '10139',
    '10140',
    '10424',
    '10425',
      '10426',
      '10427',
    '10285'//LGBT course
  ]; // prod
  poshUSACourseId = ['4126','10424',  '10425',  '10426',  '10427'];

  LGBTCourseId = ['10285'];
  LGBTCourseName = "LGBT+ Inclusion: Making Way for All";
  //courseListForShowingLastCompletedDate = ["2290","793","192","2793","2794"]; //dev
  courseListFor100PercentVideoProgress = ['3934', '3935', '3936', '3937','1171','2290'];
  //declareBlockedCourses
  declareBlockedCourses = ['3720', '3934', '3935', '3936', '3937','10285'];
  declareBlockedAceingLeadership = ['3536','5228'];

  MId = '';
  EmpId=''
  spark_url = 'https://mindtree.myhbp.org/lde/';
  HMM_url = 'https://mindtree.myhbp.org/hmm12/home.html';
  // competency = '';
  competencyList: string[] = [
    'C4',
    'C5',
    'C6',
    'C7',
    'C8',
    'C9.1',
    'C9.2',
    'C10',
    'CXO',
    'C11',
    'C12',
    'Corporate Management',
    'E1','E2','E3','E4','E5'
  ];
  //competencyList2: string[] = ['C4', 'C5', 'C6'];

  mandatoryLPUnlimitedReminderList = [
    'C10',
    'C11',
    'C12',
    'CXO',
    'Corporate Management',
    'E1','E2','E3','E4','E5'
  ];

  homePageCards = [];
  mqAlias = 0;

  packageCourseList = [];
  coursesPackageMapping = {};
  isRecommendationsClicked = false;
  isSkillProfileClicked = false;
  isPersonalisedRecommendationsClicked = false;

  lastDateRegistration = new Date(2022, 4, 31); //Oct 31
  lastDateCompletion = new Date(2022, 4, 31); // Mar 15
  lastDateCertificateReimbursement = new Date(2023, 2, 15); // Mar 15
  promotionCycle = ' for April 2022 Promotion Cycle';

  businessEthicsNotCompleted = false;
  mandatoryReminderCount = -1;
  mandatoryRetakeDate = null;
  complianceLPDetails = null;
  mandatoryLPProgress = null;
  isNewJoinee = false;
  isDateAPICalled = false;
  userName = null;

  mandatoryReminderCountForPoshUSA = -1;
  mandatoryRetakeDateForPoshUSA = null;
  complianceLPDetailsForPoshUSA = null;
  mandatoryLPProgressForPoshUSA = null;
  isNewJoineeForPoshUSA = false;
  isDateAPICalledForPoshUSA = false;
  counterPOSHUSA = -1;

  NASSCOMRecommendations = [];
  showReleasePopup = null;
  hideApp = false;

  myCDMs = [];
  myRms = [];
  toolTipMsg = '';
  cdmNameList = [];
  CocLpName='Mandatory Training';
  BEACLpName='Business Ethics and Compliance';
  LGBT='LGBT+ Inclusion: Making Way for All';
  LpName = '';
  newPOSHUSLpName = 'USA POSH LEARNING';
  degreedLPName = "Pathways";

  restrictedLPNames = [
    'ASSIGNED',
    'IDP',
    'APPROVED 201',
    'MIGRATED',
    this.BEACLpName.toUpperCase(),
    this.CocLpName.toUpperCase(),
    this.newPOSHUSLpName.toUpperCase(),
    'SEXUAL HARASSMENT PREVENTION TRAINING - USA',
    'SKILLING PROJECTS',
    'ZANSHIN',
    'BACKUP-MANDATORYLP',
    'MY DEVELOPMENT PLAN',
    'DEALLOCATED LEARNINGPATH',
    'TOP TALENT',
    'MANDATORY LEARNING PATH',
    'MANDATORY LEARNING PATHS',
    'PERSONAL LEARNING PATH',
    'PERSONAL LEARNING PATHS',
    'TIME BOUND LEARNING',
      'ROLE BASED LEARNING',
      'MSC INDUSTRIAL',
    this.degreedLPName.toUpperCase()
  ];
  LWFNotAvailableLPs = ['MIGRATED'];
  //popup expiry time offset
  offsetTimeoutForIdTokenRefresh = 5;
  loggedInUserCompetency = '';
  //isContentUploadSaveAlertOpen = false;


   //201 Content Upload
  //allowedAoList = ["M1070389", "M1069024", "M1019251", "M1049508", "M1048784", "M1048467", "M1019415", "M1032565", "M1048205", "M1056003", "M1035866", "M1053819", "M1069860", "M8071076", "M1065034", "M1074950", "M1021996", "M1070305", "M1070381", "M1081461", "M1031868", "M1019552", "M1062484", "M1015841", "M1069394", "M8081321", "M1029624", "M1078094", "M9010515", "M1040958", "M1083977", "M1084898", "M1062484", "M1019552", "M1068976", "M1028977"];
   //201 Content Verification page
  // allowedPSSList = ["M1070389", "M1079283", "M1048784", "M1032547", "M1048693", "M1048467", "M1019415", "M1019251", "M1032598", "M1032565", "M1048205", "M1056003", "M1035866", "M1053819", "M1048472", "M9011637", "M1046369", "M1070305", "M1070381", "M1081461", "M1079289"];
   //101 and Create Quiz
 //  allowedDevList = ["M1070389", "M1021996", "M1065034", "M1049508", "M1040958", "M1053819", "M1048784", "M1019415", "M1048205", "M1056003", "M1048467", "M1019251", "M1070305", "M1070381", "M1081461", "M1031868", "M1019552", "M1062484", "M1015841", "M1069394", "M8081321", "M1069024", "M1029624", "M1078094", "M8071076", "M1032565", "M9010515", "M1084898", "M1062484", "M1019552", "M1068976", "M1069860", "M1028977"];
   //Package Content Upload
 // allowedPCUList = ["M1070389", "M1019415", "M1048467", "M1048784", "M1048205", "M1056003", "M1053819", "M1019251", "M1070305", "M1070381", "M1081461", "M1069860"];
   //301 Content Upload
  // allowed301List = ["M1070389", "M1048467", "M1048205", "M1056003", "M1070305", "M1070381", "M1081461"];


   livingMindFullVideoId='99999'
   livingMindFullyVideoLink='https://yorbit.streaming.mediaservices.windows.net/86ee8867-b45c-45fb-b6f2-bbbff9184433/living-mindfully-video-mp4.ism/manifest(encryption=cbc)';

  // scormCourseIds=["4973", "4971", "4997","5178","5184","5189","5206","10070","10071","10072","10073","10074", "10362","10363","10364","10365"];//dev
   scormCourseIds=["4973", "4971", "4997","5178","5184","5189","5206","10136","10137","10138","10139","10140", "10424",  "10425",  "10426",  "10427", "10285"];//prod
  //  scormCourseIds=["5065","5139",]; //Prod

   scormResumeIds=[];

   sharedUniqueId='';

   scormTimerInterval = 300000;
  yorbitUrl = 'https://shoshin.ltimindtree.com/';
  isupportUrl = 'https://isupport.ltimindtree.com/knowp';
  scormCompletionPopIds=[];
  
  contentTypeMaster:Array<{type:string,category:string}>=[];
  //typeCourse:string[]=[];
  //typeAssessment:string[]=[];
  typeCourse:any[] = [];
  typeAssessment:any[] = [];
  //typetf3:string[]=[];
  typetf3:any[] = [];
  courselabel:string[]=[];
  //courseTypeMaster=['TF3Course','TF3Assessment','TF3ExternalCertification','TF3SMEEvaluation'];
}
