import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'courseType',
  pure: false
})
export class CourseTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value !== undefined && value.length !== 0 && args.length !== 0) {
      let returnedValue;
      if (args.length !== 0) {
        returnedValue = [];
        value.forEach(element => {
          args.forEach(ele => {
            if (element.Type === undefined) {
              if (element.CourseType === ele) {
                returnedValue.push(element);
                return returnedValue;
              }
            } else {
              if (element.Type === ele) {
                returnedValue.push(element);
                return returnedValue;
              }
            }
          });
        });
      } else {
        returnedValue = value;
      }
      return returnedValue;
    } else {
      return value;
    }
  }
}
