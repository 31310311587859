<mat-dialog-content>
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title">
    <div class="heading">{{ "Certificate Upload" }}</div>
  </div>

  <!-- message -->
  <div class="popup-content">
    <div fxLayout="row" class="message" fxLayoutAlign="center center">
      <div><b>Kindly select from the given option.</b></div>
    </div>
    <div fxLayout="row" class="message">
      Course was approved/assigned through
      Shoshin School ?
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="input-btn">
        <input class="mousePointer" type="radio" (change)="radioButtonForCertificateUploadClicked()" value="Yes"
          [(ngModel)]="radioValueForCertificateUpload" id="certUploadYes" />
        <label for="certUploadYes" class="label">
          Yes
        </label>
      </div>
      <div class="input-btn">
        <input class="mousePointer" type="radio" (change)="radioButtonForCertificateUploadClicked()" value="No"
          [(ngModel)]="radioValueForCertificateUpload" id="certUploadNo" />
        <label for="certUploadNo"  class="label">
          No
        </label>
      </div>
    </div>
    <div fxLayoutAlign="center center" class="action-button-holder">
      <button class="action-button mousePointer" [disabled]="!enableBtn" [ngClass]="{'disabled':!enableBtn}" (click)="goToCertificateUploadPage()">
        Proceed
      </button>
    </div>
  </div>


</mat-dialog-content>