import { Component, OnInit, Inject } from '@angular/core';
import { PopupService } from '../popup.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { Globals } from '../../../globals';
import { OrderbyDatePipe } from '@YorbitWorkspace/pipes';

@Component({
  selector: 'yorbit-course-feedback',
  templateUrl: './course-feedback.component.html',
  styleUrls: ['./course-feedback.component.scss']
})
export class CourseFeedbackComponent implements OnInit {
  mandatoryFeedbackList: Array<any>;
  nonMandatoryFeedbackList: Array<any>;
  isFeedbackListLoading: boolean;
  isCourseFeedbackSubmitted: boolean;
  showMandatoryFeedbackList: boolean;
  starsCollection: Array<any>;
  feedBackMsg1: string;
  feedBackMsg2: string;
  feedBackMsg3: string;
  selectedStarIndex: number;
  feedbackComments: string;
  smileyText: Array<string>;
  selected: Array<any>;
  mandatoryFeedbackListIndex: number;
  nonMandatoryFeedbackListIndex: number;
  feedbackForCourse: any;
  commentCategories: Array<any>;
  recommendations: Array<any>;
  showPreloaderForRecommendations: boolean;
  isRatingGiven: boolean;
  selectedFeedbackAttributes: Array<any>;
  selectedNonMandatoryCourseId: any;
  courseFeedbackPostError: boolean;
  errMsg1: string;
  errMsg2: string;
  postFeedbackInProgress:boolean;

  constructor(
    private _popupSvc: PopupService,
    private dialogRef: MatDialogRef<CourseFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private globals: Globals,
    private orderByDate: OrderbyDatePipe
  ) {}

  ngOnInit() {
    this.postFeedbackInProgress = false;
    this.globals.isCourseFeedbackPopupShown = true;
    this.isFeedbackListLoading = false;
    this.showMandatoryFeedbackList = true;
    this.mandatoryFeedbackList = new Array();
    this.nonMandatoryFeedbackList = new Array();
    this.getPendingFeedbackList();
  }

  getPendingFeedbackList() {
    this.isFeedbackListLoading = true;
    if(this.globals.courseFeedbackList.length > 0){
      this.isFeedbackListLoading = false;
          this.mandatoryFeedbackList = this.filterByManatoryFlag(true, this.globals.courseFeedbackList);
          if (this.mandatoryFeedbackList.length > 0) {
            this.nonMandatoryFeedbackList = this.filterByManatoryFlag(
              false,
              this.globals.courseFeedbackList
            );
          this.setPopupData();
        }else{
          this.dialogRef.close();
        }
    }
    else{
    this._popupSvc.getPendingCourseFeedBackList().subscribe(
      data => {
        this.isFeedbackListLoading = false;
        if (data !== null && data.length > 0) {
          this.mandatoryFeedbackList = this.filterByManatoryFlag(true, data);
          if (this.mandatoryFeedbackList.length > 0) {
            this.nonMandatoryFeedbackList = this.filterByManatoryFlag(
              false,
              data
            );
            this.setPopupData();
          }  else{
            this.dialogRef.close();
          }
        }
        else{
          this.dialogRef.close();
        }
      },
      error => {
        this.isFeedbackListLoading = false;
      }
    );
    }
  }

  filterByManatoryFlag(isMandatory, data) {
    let list = [];
    if (isMandatory) {
      list = data.filter((row, index) => {
        if (
          row.IsFeedbackMandatory === true ||
          row.IsFeedbackMandatory === 'true'
        ) {
          return row;
        }
      });
    } else if (!isMandatory) {
      list = data.filter((row, index) => {
        if (
          row.IsFeedbackMandatory === false ||
          row.IsFeedbackMandatory === 'false'
        ) {
          return row;
        }
      });
    }
    return list;
  }

  setPopupData() {
    this.showMandatoryFeedbackList = true;
    this.isCourseFeedbackSubmitted = false;
    this.feedBackMsg1 = 'You have successfully completed the course';
    this.feedBackMsg3 = 'How did you like this course?';
    this.selectedStarIndex = 0;
    this.feedbackComments = '';
    this.smileyText = ['', 'Terrible', 'Bad', 'Okay', 'Good', 'Great'];
    this.starsCollection = new Array(5);
    this.selected = [];
    this.isRatingGiven = false;

    this.mandatoryFeedbackList = this.orderByDate.transform(
      this.mandatoryFeedbackList,
      'CompletedDate',
      false
    );

    this.nonMandatoryFeedbackList = this.orderByDate.transform(
      this.nonMandatoryFeedbackList,
      'CompletedDate',
      false
    );

    this.nonMandatoryFeedbackList.forEach(value => {
      if (this.selected[value.CourseId] !== undefined) {
        this.selected[value.CourseId] = false;
      }
    });
    this.mandatoryFeedbackListIndex = 0;
    this.nonMandatoryFeedbackListIndex = 0;
    this.feedbackForCourse = this.mandatoryFeedbackList[
      this.mandatoryFeedbackListIndex
    ];

    this.setDefaultCommentCategories();

    this.feedBackMsg2 = this.feedbackForCourse.CourseName;
    this.recommendations = [];
    this.showPreloaderForRecommendations = false;
  }

  close() {
    this.dialogRef.close();
  }

  setDefaultCommentCategories() {
    if (this.feedbackForCourse.ItemExpertise === '101') {
      this.commentCategories = [
        { Name: 'Content', selected: false },
        { Name: 'Structure', selected: false },
        { Name: 'Relevance', selected: false },
        { Name: 'Overall Experience', selected: false }
      ];
    } else {
      this.commentCategories = [
        { Name: 'Content', selected: false },
        { Name: 'Faculty', selected: false },
        { Name: 'Structure', selected: false },
        { Name: 'Relevance', selected: false },
        { Name: 'Project/Assignment', selected: false },
        { Name: 'Lab', selected: false },
        { Name: 'Overall Experience', selected: false }
      ];
    }
  }

  selectCategory(index) {
    if (this.commentCategories[index].selected === false) {
      this.commentCategories[index].selected = true;
    } else {
      this.commentCategories[index].selected = false;
    }
  }

  postFeedback() {
    this.selectedFeedbackAttributes = [];
    this.courseFeedbackPostError = false;
    this.commentCategories.forEach((value, key) => {
      if (value.selected) {
        this.selectedFeedbackAttributes.push(value.Name);
      }
    });
    this.feedbackForCourse.CourseId = Number(this.feedbackForCourse.CourseId);
    const payloadData = {
      CourseId: this.feedbackForCourse.CourseId,
      Rating: this.selectedStarIndex,
      Attributes: this.selectedFeedbackAttributes,
      Comment: this.feedbackComments
    };
    this.postFeedbackInProgress = true;
    this._popupSvc.postCourseFeedback(payloadData).subscribe(
      data => {
        this.postFeedbackInProgress = false;
        if (data === true || data === 'true') {
          this.updateAchievementList();
          this.resetCommentCategories();
          this.showNextCourse();
        } else {
          this.courseFeedbackPostError = true;
          this.isCourseFeedbackSubmitted = true;
          this.errMsg1 = 'Oops... Something went wrong!';
          this.errMsg2 =
            'Request you to refresh the page and give feedback again';
        }
      },
      error => {
        this.postFeedbackInProgress = false;
        this.courseFeedbackPostError = true;
        this.isCourseFeedbackSubmitted = true;
        this.errMsg1 = error;
        this.errMsg2 =
          'Request you to refresh the page and give feedback again';
      }
    );
  }

  filterCourse(course) {
    this.nonMandatoryFeedbackList.forEach((value, key) => {
      if (this.selected[value.CourseName] !== undefined) {
        this.selected[value.CourseId] = false;
      }
    });
    this.selected[course.CourseId] = true;
    this.selectedNonMandatoryCourseId = course.CourseId;
  }
  updateAchievementList() {
    this._popupSvc.updateAchievementList(this.feedbackForCourse.CourseId).then(
      data => {
        if (data) {
          this._popupSvc.getUserDetails().then(userData => {
          });
        }
      },
      error => {
      }
    );
  }
  resetCommentCategories() {
    this.commentCategories.forEach((value, key) => {
      this.commentCategories[key].selected = false;
    });
  }
  showNextCourse() {
    if (
      this.mandatoryFeedbackList[this.mandatoryFeedbackListIndex + 1] !==
      undefined
    ) {
      this.mandatoryFeedbackListIndex = this.mandatoryFeedbackListIndex + 1;
      this.feedbackForCourse = this.mandatoryFeedbackList[
        this.mandatoryFeedbackListIndex
      ];
      this.setDefaultCommentCategories();
      this.feedBackMsg2 = this.feedbackForCourse.CourseName;
      this.selectedStarIndex = 0;
      this.isRatingGiven = false;
      this.feedbackComments = '';
      this.showMandatoryFeedbackList = true;
    } else if (this.nonMandatoryFeedbackList.length !== 0) {
      if (this.mandatoryFeedbackListIndex === 0) {
        this.mandatoryFeedbackList = [];
      }
      this.showMandatoryFeedbackList = false;
      this.selectedNonMandatoryCourseId = '';
      this.isCourseFeedbackSubmitted = false;
      this.courseFeedbackPostError = false;
    } else {
      this.isCourseFeedbackSubmitted = true;
      this.courseFeedbackPostError = false;
    }
  }

  loadFeedbackForCourse() {
    this.isCourseFeedbackSubmitted = false;
    this.selectedStarIndex = 0;
    this.isRatingGiven = false;
    this.feedbackComments = '';
    this.showMandatoryFeedbackList = true;

    this.feedbackForCourse = this.nonMandatoryFeedbackList.filter(val => {
      return String(val.CourseId) === String(this.selectedNonMandatoryCourseId);
    })[0];

    this.setDefaultCommentCategories();
    this.feedBackMsg2 = this.feedbackForCourse.CourseName;

    this.nonMandatoryFeedbackList.forEach((value, key) => {
      this.selected[value.CourseId] = false;
      if (String(this.feedbackForCourse.CourseId) === String(value.CourseId)) {
        this.nonMandatoryFeedbackList.splice(key, 1);
      }
    });
  }

  closeFeedbackForNonMandatory() {
    this.isCourseFeedbackSubmitted = true;
  }
}
