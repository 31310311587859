import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { GraphDataService } from '@YorbitWorkspace/graph';

@Injectable({
  providedIn: 'root'
})
export class PssContentApprovalService {
  config: any;
  contentOwnerName: string;
  lopmSPOC: string;

  constructor(
    private http: HttpClient,
    private _envService: EnvironmentService,
    private _graph: GraphDataService
  ) {
    this.config = this._envService.getEnvironment();
    this.contentOwnerName="";
  }
constructcourseCompletionType(data){
  let updatedResponse=[];
  data.forEach(element => {
    let statusMsg=element.TypeDescription;
    switch (element.TypeDescription) {
      case "No. of Course Completion Certificates":
        statusMsg="No. of course completion certificate(s)"
        break;
      case "No. of Badges":
        statusMsg="No. of Badge(s)"
        break;
      case "No. of Badges":
        statusMsg="No. of Badge(s)"
        break;
      case "No. of Screen Shots":
        statusMsg="No. of Screen Shot(s)"
        break;
      case "No. of Letter Heads":
        statusMsg="No. of Letter Head(s)"
        break;
      default:
        statusMsg = element.TypeDescription;
        break;
    }
    element.TypeDescription=statusMsg;
    updatedResponse.push(element)
  });
  return updatedResponse;
}
  getLabel(element, tabId) {
    let generatedLabel = '';
    switch (element) {
      case 'isInfrastructure':
        generatedLabel = 'Infrastructure';
        break;
      case 'loginType':
        generatedLabel = 'Course Login Type';
        break;
      case 'isLearningMaterial':
        generatedLabel = 'Learning Material';
        break;
      case 'courseCompletionGroup':
        generatedLabel =
          'Proof of Course Completion to be Submitted by Learner';
        break;
      case 'classRoomGroup':
        generatedLabel = 'Class Room';
        break;
      case 'projectGroup':
        generatedLabel = 'Project Work';
        break;
      case 'assessmentGroup':
        generatedLabel = 'Assessment';
        break;
      case 'infrastructure':
      case 'infrastructureType':
      case 'isLoginRequired':
      case 'coursesNameForSelfRegistration':
      case 'linktoCoursesForSelfRegistration':
      case 'loginDetails':
      case 'coursesNameForLearningOPMTeam':
      case 'linktoCoursesForLearningOPMTeam':
      case 'preSessionGroup':
      case 'duringSessionGroup':
      case 'postSessionGroup':
      case '':
        generatedLabel = '';
        break;
      default:
        generatedLabel = element;
    }
    return generatedLabel;
  }

  getContent(content, type,badgeName,courseModeOfPaymentList) {

    let responseData;
    switch (type) {
      case 'basic':
        responseData = this.getBasicContentData(content,badgeName,courseModeOfPaymentList);
        break;

      case 'operation':
        break;

      default:
        break;
    }
    return responseData;
  }
  getBasicContentData(content,badgeName,courseModeOfPaymentList) {  

    var payload = [
      {
        label: 'Name',
        value: content.Name
      },
      {
        label: 'Expertise',
        value: content.Expertise
      },
      {
        label: 'Academy',
        value: content.Academy
      },
      {
        label: 'Genre',
        value: content.Genre
      },
      {
        label: 'Skill',
        value: content.Skill
      },
      content.Expertise != '301' ? {
        label: 'Topics Covered',
        value: content.TopicCovered
      } : null,
      content.Expertise == '301' ? {
        label: 'Topics Covered',
        value: content.TopicsCovered
      } : null,
      {
        label: 'Description',
        value: content.Description
      },
      content.Expertise == '301' ? {
        label: 'Is Course Internal?',
        value: content.Internal == true ? "Yes" : "No"
      } : null,

      {
        label: 'Course Completion Certificate to be generated by Shoshin School ?',
        value: content.IsCertificateAvailable == true ?  "Yes" : "No"
      },
      {
        label: 'Course Type',
        value: content.Type
      },
      // {
      //   label: 'Is Course Internal?',
      //   value: content.Internal?"Yes":"No"
      // },
      {
        label: 'Name of Vendor',
        value: content.Vendor
      },
      {
        label: 'Is Course Paid?',
        value: content.Paid?"Yes":"No"
      },
      {
        label: 'Course Price',
        value: content.Price == 'NA' || content.Price == null ? null : content.Price
      },
      content.Expertise == '301' ? {
        label: 'Is Course Hidden?',
        value: content.IsHidden == 0 ? "No" : "Yes"
      } : null
      ,
       content.Expertise == '301' ? {
        label: 'Is Course Restricted?',
        value: content.IsRestricted == 0 ? "No" : "Yes"
      } : null
      ,
      // {
      //   label : "Currency",
      //   value:content.Academy,
      // },
      content.Expertise != '301' ? {
        label: 'Payment Mode',
        value:  content.Paid? this.getPaymentMode(content.paymentMode,courseModeOfPaymentList) : 'Not Available'
      } : null,
      {
        label: 'Course Duration',
        value: content.Duration
      },
      {
        label: 'Is Learning Business Partner Approval Required?',
        value: content.ManagerApproval?"Yes":"No"
      },
      content.Expertise == '301' ? {
        label: 'Pre-Requisite Course ID(s)',
        value: content.NewPreRequisiteCourseIDs == 'NA' || content.NewPreRequisiteCourseIDs == null ? null : content.NewPreRequisiteCourseIDs
      } : null,
      content.Expertise == '301' ? {
        label: 'Post Recommended Course ID(s)',
        value: content.NewPostRecommendedCourseIDs == 'NA' || content.NewPostRecommendedCourseIDs == null ? null : content.NewPostRecommendedCourseIDs
      } : null,
      {
        label: 'Is PreRequisite Mandatory?',
        value: content.IsPreRequisiteMandatory?"Yes":"No"
      },
      content.Expertise != '301' ? {
        label: 'Who can take this Course?',
        value: this.getContentValue(
          'Eligibility',
          content.Eligibility
        )
      } : null,
      content.Expertise != '301' ? {
        label: 'Course Accessibility',
        value: this.getContentValue(
          'CourseAccessibility',
          content.Accessibility
        )
      } : null,
      {
        label: 'Credits',
        value: (content.Credits !== null && content.Credits !== '')? content.Credits.toString().trim():content.Credits
      },

      content.Expertise != '301' ? {
        label: 'Course Classification',
        value: this.getContentValue(
          'CourseClassification',
          content.Classification
        )
      } : null,
      {
        label: 'Badge Name',
        value: badgeName?badgeName:content.Badge
      },
      {
        label: 'Expert PSID(s)',
        value: content.ExpertMids?content.ExpertMids.replace(/@mindtree.com/gi,''):content.ExpertMids
      },
      {
        label: 'Content Partner',
        value: this.contentOwnerName?this.contentOwnerName:content.ContentOwner
      },
      content.Expertise == '301' ? {
        label: 'Learning OPM SPOC',
        value: this.lopmSPOC
      } : null
      ,
      {
        label: 'Is Cloud Lab Available?',
        value: content.IsCloudLabAvailable == true ? "Yes" : "No"
      },
      {
        label: 'Course Proficiency',
        value: content.Proficiency
      },
      content.Expertise != '301' ? {
        label: 'Introductory Video',
        value: content.IsIntroductoryVideo ? 'Available' : 'Not Available'
      } : null,
      content.Expertise != '301' ? {
        label: 'Video Type',
        value: content.SourceType == 'O365 Video' ? 'Azure Media Services' : content.SourceType
      }: null,
      content.Expertise != '301' ?{
        label: 'Video Link',
        value: content.IntroductoryVideo
      }: null,
      {
        label: 'Skill Prism ID',
        value: content.SkillIds
      },
      {
        label: 'Is Mail Alert Required?',
        value: content.IsMailAlertRequired == true ? "Yes" : "No"
      },
      // {
      //   label : "Is Course Launched only for Providing Badge and Credits?",
      //   value:content.CreditSpecial,
      // }
    ];
    // if(content.Expertise == '301') {
    //   payload.push({
    //     label: 'Is Course Hidden?',
    //     value: content.IsHidden == 0 ? "No" : "Yes"
    //   },
    //   {
    //     label: 'Is Course Restricted?',
    //     value: content.IsRestricted == 0 ? "No" : "Yes"
    //   },);
    // }
   payload = payload.filter(x => x != null);
   //console.log(content.Price);
    return payload;
  }

  getStackAndSkill(content) {
    var StackAndSkill = JSON.parse(content.StackAndSkills);
    if(StackAndSkill == null || StackAndSkill.length == 0) {
      return;
    }
    var Parsed = [];
    StackAndSkill.forEach((ele)=>{
     Parsed.push({
       stack : ele.Stack,
       skill : ele.Skills.join(", ")
     });
    });
    return Parsed;
  }

  getPaymentMode(paymentMode,courseModeOfPaymentList){
    let payload="";
    if(0<courseModeOfPaymentList.length){
      courseModeOfPaymentList.forEach(element => {
        if(element.Id==paymentMode){
          payload=element.Mode
        }
      });
    }else{
      payload=(1==paymentMode)?"B2C":(2==paymentMode)?"B2B":"B2D";
    }

    return payload;
  }

  updateCourseInfrastructure(infrastructurePayload) {
    return this.http
      .post<any>(
        this.config.apiUrl + 'updateCourseInfrastructure',
        infrastructurePayload
      )
      .toPromise();
  }

  updateCourseLoginDetails(courseLoginPayload) {
    return this.http
      .post<any>(
        this.config.apiUrl + 'updateCourseLoginDetails',
        courseLoginPayload
      )
      .toPromise();
  }
  updateCourseCompletionDetails(courseCompletionPayload) {
    return this.http
      .post<any>(
        this.config.apiUrl + 'updateCourseCompletionDetails',
        courseCompletionPayload
      )
      .toPromise();
  }
  updateClassRoomDetails(ClassRoomPayload) {
    return this.http
      .post<any>(
        this.config.apiUrl + 'updateClassRoomDetails',
        ClassRoomPayload
      )
      .toPromise();
  }
  updateProjectWorkDetails(projectWorkPayload) {
    return this.http
      .post<any>(
        this.config.apiUrl + 'updateProjectWorkDetails',
        projectWorkPayload
      )
      .toPromise();
  }

  pssVerificationAction(payload) {
    return this.http
      .post<any>(this.config.apiUrl + 'PssContentVerification', payload)
      .toPromise();
  }

  updateCreditUpdate(payload) {
    return this.http
      .post<any>(this.config.apiUrl + 'PssContentSpecialUpdate', payload)
      .toPromise();
  }

  getAllPssStatus() {
    return this.http
      .get<any>(this.config.apiUrl + 'getAllPssStatus')
      .toPromise();
  }
  getBadgeDetails(badgeId){
    return this.http
      .get<any>(this.config.apiUrl + 'GetBadgeDetail/'+badgeId)
      .toPromise();
  }

  getContentValue(type, id) {
    let contentValue = 'NA';
    switch (type) {
      case 'CourseAccessibility':
        return 1 == id
          ? 'Hidden'
          : 2 == id
            ? 'Restricted'
            : 3 == id
              ? 'Open'
              : 'NA';
      case 'CourseClassification':
        switch (id) {
          case "1":
            contentValue = '201A';
            break;
          case "2":
            contentValue = 'Application 201B';
            break;
          case "3":
            contentValue = 'Context Aligned 201B';
            break;
          case "4":
            contentValue = 'Depth 201B';
            break;
          case "5":
            contentValue = 'Validation 201B';
            break;
        }
        return contentValue;
      case "Eligibility":
        switch (id) {
          case "1":
            contentValue = 'Onsite Minds';
            break;
          case "2":
            contentValue = 'Offshore Minds';
            break;
          case "3":
            contentValue = 'Both (Onsite Minds and Offshore Minds)';
            break;
        }
        return contentValue;
    }
  }

  getExperts(experts){
    let expertMidsSplit = [];
    let expertString = '';
    if (experts !== null) {
      expertMidsSplit = experts.split('@mindtree.com');
    }
    if(expertMidsSplit.length > 0){
      for(let i = 0; i< expertMidsSplit.length; i++){
        expertString += expertMidsSplit[i];
      }
    }
    return expertString;
  }
}
