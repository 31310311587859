import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { BrowseComponent } from '../browse.component';
import { AcademyViewComponent } from '../academy-view/academy-view.component';
import { GenreViewComponent } from '../genre-view/genre-view.component';
const routes: Routes = [
  {
    path: '',
    component: BrowseComponent,
    children: [
      {
        path: 'academy/:academyId',
        component: AcademyViewComponent,
        data: {
          title: 'Academy Content'
        }
      },
      {
        path: 'academy/:academyId/genre/:genreId',
        component: GenreViewComponent,
        data: {
          title: 'Genres view'
        }
      },
      {
        path: 'accountsAndProjects',
        component: AcademyViewComponent,
        data: { title: 'ACCOUNTS' }
      },
      {
        path: 'accountsAndProjects/:accountId/:projectId',
        component: GenreViewComponent,
        data: { title: 'ACCOUNTS' }
      },
      {
        path: 'academy/:academyId/genre/:genreId/livingmindfully',
        component: GenreViewComponent,
        data: {
          title: 'Genres view'
        }
      }
    ]
  }
];
@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BrowseRouterModule {}
