<mat-dialog-content fxFlex fxFlex.gt-xs="470px">
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popupTitle">
    <div class="title">Declare your Skill</div>
  </div>

  <div class="popup-content">

    <div fxLayout="column" *ngIf="showTermsofUse">
      <div fxLayout="row" fxFlexOffset="10px" *ngFor="let term of termsOfUse;let index=index">
          <div fxFlex=26px>
            <div class="termsOfUseIndex">
              {{index+1}}
            </div>
            </div>
          <div fxFlex>{{term}}</div>
      </div>

      <div class="actionButtonContainer">
          <button (click)="closeDialog()"
          class="actionButton action-button">
            Close
          </button>

          <button
            (click)="showTermsofUse=false"
            class="actionButton action-button"
         >
            Proceed
          </button>
        </div>
    </div>

    <div class="send-report" *ngIf="!showTermsofUse && !((declareSkillSuccess || declareSkillFailed) && showPopup)">

      <div
        fxLayoutGap="5px"
        class="contentSpace"
      >
        <div fxFlex>
          <div class="declareDropdownBox">

            <mat-form-field fxFlex
            [ngClass]="{ mouseDisabled: declareSubmitInProgress }">
            <input
            matInput [(ngModel)]="skillName"
            placeholder="Search Skill"
            value=""
            (keyup)="searchSkill(skillName,$event)"
            [matAutocomplete]="auto"
            [disabled]="declareSubmitInProgress"/>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="skillSelectHandler($event)">
                    <mat-option class="declareOption" *ngFor="let skillObject of skillList" [value]="skillObject">
                      <div fxLayout="row">
                        <div fxFlex="48px"  class="expertiseBg bg{{skillObject.Expertise}}">
                          {{skillObject.Expertise}}
                        </div>
                        <div title="{{skillObject.Skill}}" fxFlexOffset="10px" class="skillListName">
                          {{skillObject.Skill}}
                        </div>
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                  <button
                  mat-button
                  *ngIf="!searched"
                  matSuffix
                  mat-icon-button
                  aria-label="search"
                  class="autoCompleteButton"
                  (click)="searchSkill(skillName)"
                >
                <mat-icon>search</mat-icon>
                </button>
                  <button
                    mat-button
                    *ngIf="searched"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                  class="autoCompleteButton"
                    (click)="onClear()"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  </mat-form-field>

          </div>
        </div>
      </div>


      <div *ngIf="''!==skillSelected.Skill&&''!==skillSelected.Expertise">


      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutGap="5px"
        class="contentSpace"
      >
      <div fxFlex.gt-xs="150px">
          Skill Name:
        </div>
        <div fxFlex>
          {{this.skillSelected.Skill}}
        </div>
      </div>

      <div
        fxLayout.xs="row"
        fxLayoutGap="5px"
        class="contentSpace"
      >
      <div fxFlex.gt-xs="150px">
          Skill Expertise:
        </div>
        <div fxFlex>
          {{this.skillSelected.Expertise}}
        </div>
      </div>

      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutGap="5px"
        class="contentSpace"
      >
      <div fxFlex.gt-xs="150px">
          Select mode of Acquiring:
        </div>
        <div fxFlex>
          <div class="declareDropdownBox">
            <select
              class="declareDropdown"
              [(ngModel)]="modeOfAcquiredSelected"
              [disabled]="declareSubmitInProgress"
              [ngClass]="{ mouseDisabled: declareSubmitInProgress }"
            >
              <option [ngValue]="option"
              [disabled]="option === modeOfAcquired[0]" [hidden]="option===modeOfAcquired[0]"
              *ngFor="let option of modeOfAcquired">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>

    </div>

      <div class="actionButtonContainer">
        <button (click)="closeDialog()"
        class="actionButton action-button"
        [disabled]="declareSubmitInProgress">
          Close
        </button>

        <button
          (click)="submitDeclare()"
          class="actionButton action-button"
          title="{{(''===skillSelected.Skill&&''===skillSelected.Expertise)
          ?'Search and Select a skill to declare'
          :(modeOfAcquired[0]===modeOfAcquiredSelected)
          ?'Select mode of Acquired':'Declare Skill'
        }}"
          [ngClass]="{
            mouseDisabled:(''===skillSelected.Skill&&''===skillSelected.Expertise)
            ||modeOfAcquired[0]===modeOfAcquiredSelected
          }"
          *ngIf="!declareSubmitInProgress"
          [disabled]="(''===skillSelected.Skill&&''===skillSelected.Expertise)
          ||modeOfAcquired[0]===modeOfAcquiredSelected">
          Declare
        </button>
        <button
          *ngIf="declareSubmitInProgress"
          class="actionButton action-button"
          [disabled]="true"
        >
          In progress...
        </button>
      </div>

    </div>

    <div *ngIf="(declareSkillSuccess || declareSkillFailed) && showPopup">
      <div class="dialogue-box-modal overflowClass">
        <div class="dialogue-box-table">
          <div class="dialogue-box-row">
            <!-- Declare Popup-->
            <div class="dialogue-box-cell">
              <div
                class="declarePopUp-box-data"
                *ngIf="declareSkillCallCompleted"
              >
                <div
                  class="mousePointer closeButton iconSpriteImage iconCrossInPopups"
                  (click)="closeDialog()"
                ></div>
                <div
                  *ngIf="declareSkillSuccess"
                  class="skillDeclareSuccessPopup"
                >
                  You have successfully declared {{ skillName }}! <br />This
                  skill has been added to your Skill Profile!
                </div>
                <div *ngIf="declareSkillFailed" class="skillDeclareFailedPopup">
                  {{errorMessage}}
                </div>


      <div class="actionButtonContainer">
          <button (click)="closeDialog()"
          class="actionButton action-button">
          OK
          </button>
        </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</mat-dialog-content>
