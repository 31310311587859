import { Pipe, PipeTransform } from '@angular/core';
import { CheckboxList } from 'apps/yorbit-ui/src/app/pss/pss-evaluation/pss-evaluation.interface';

@Pipe({
  name: 'searchFilter'
})

/**
 * This is pipe is created exclusively for searching any text in "CheckBoxList".
 * Please don't use this pipe for search text in normal string list.
 */
export class SearchTextPipe implements PipeTransform {

  transform(items: CheckboxList[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;

    searchText = searchText.toLowerCase();

    let filteredItems = items.filter(it => {
      return it ? it["value"].toLowerCase().includes(searchText) : [];
    });
    return filteredItems;
  }

}
