import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from '../push-notifications.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-subscribe-push-notifications',
  templateUrl: './subscribe-push-notifications.component.html',
  styleUrls: ['./subscribe-push-notifications.component.scss']
})
export class SubscribePushNotificationsComponent implements OnInit {
  message: string;
  subscriptionState: string | null;
  constructor(
    private pushService: PushNotificationService,
    private dialogRef: MatDialogRef<SubscribePushNotificationsComponent>
  ) {
    this.message =
      'Allow us to send you personalized notifications from Shoshin School. Notifications may include alerts, updates, badges. These can be configured in settings';
    this.subscriptionState = null;
  }

  ngOnInit() {}
  // allow() {
  //   this.subscriptionState = 'processing';
  //   if ('PushManager' in window) {
  //     this.pushService.getFirebaseMsgInstance().then(firebaseMsgInstance => {
  //       if (firebaseMsgInstance != null) {
  //         firebaseMsgInstance
  //           .requestPermission()
  //           .then(() => {
  //             //show popup preloader
  //             return firebaseMsgInstance.getToken();
  //           })
  //           .then(token => {
  //             ////console.log("push token",token);
  //             if (token) {
  //               this.subscriptionState = 'processed';
  //               this.message = 'You have successfully subscribed to Yorbit';
  //               this.pushService
  //                 .storeSubscriptionId(token)
  //                 .toPromise()
  //                 .then(response => {});
  //               this.pushService
  //                 .subscribeUserToGlobalTopic(token)
  //                 .toPromise()
  //                 .then(response => {})
  //                 .catch(err => {});
  //             }
  //           })
  //           .catch(err => {
  //             ////console.log(err)
  //             this.subscriptionState = 'processed';
  //             //show preloader =false;
  //             if (err.code == 'messaging/permission-blocked') {
  //               this.message =
  //                 'Push Subscriptions are blocked. Kindly unblock and subscribe again';
  //               this.pushService.getPushSubscription.next('blocked');
  //             } else if (err.code == 'messaging/permission-default') {
  //               // this comes when user neither clicked on allow nor blocked
  //             } else {
  //               this.message =
  //                 'Unable to process your request. Please try again';
  //             }
  //           });
  //       }
  //     });
  //   } else {
  //     this.subscriptionState = 'processed';
  //     this.message = 'This feature is not supported in your browser.';
  //   }
  // }
  closeDialog() {
    this.dialogRef.close();
  }
}
