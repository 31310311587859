import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchEvaluatorPopupComponent } from './search-evaluator-popup/search-evaluator-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-search-evaluator',
  templateUrl: './search-evaluator.component.html',
  styleUrls: ['./search-evaluator.component.scss']
})
export class SearchEvaluatorComponent implements OnInit {

  @Input() evaluatorDetails: any;
  @Output() updateEvaluatorFromPopup = new EventEmitter<any>();

  constructor(private _popup: MatDialog) {}

  ngOnInit() {}
  openDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //shareDialogContainer

      dialogConfig.data = {
        type:this.evaluatorDetails.type,
        list:this.evaluatorDetails.list
      };

      const dialogref=this._popup.open(SearchEvaluatorPopupComponent, dialogConfig);

      dialogref.afterClosed().subscribe(data => {
        if(null!=data){
          this.updateEvaluatorFromPopup.emit(data);
        }
      });

    }

}
