<div class="lp-detailed-package-container" fxFlex fxLayout="column" [ngClass.xs]="{'lp-detailed-package-container-xs':true}"
*ngIf="'rolebased'!=category">
  <div class="backdrop" (click)="removeHighlightStyle('null')" [ngClass.gt-xs]="{'complianceAndSkillingLp':(LPBreadcrumbs.LPDetails.LPCategory=='skilling' || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance')}" fxFlexFill *ngIf="highlightedContentId!=''"></div>
  <div class="header" fxFlex="50px" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlexOffset="20px" fxShow.xs="true" fxShow.gt-xs="false" (click)="goBackToLP()">
      <mat-icon>arrow_back_ios</mat-icon>
    </div>
    <div fxFlexOffset="20px" fxFlexOffset.xs="5px" class="expertise">{{LPBreadcrumbs.PackageDetails.Expertise}}</div>
    <div fxFlexOffset="20px" fxFlexOffset.xs="5px" fxFlex class="textTruncate">{{LPBreadcrumbs.PackageDetails.PackageName}}</div>
    <div fxFlex="50px" class="watermark" fxFlexAlign="start">P</div>
  </div>
  <div fxFlex class="courses-container" >
    <div class="body"  id="scrollable-content" fxFlex fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="start center"
    *ngIf="!pageLoading && pageLoadedSuccessfully && coursesList.length!=0">


      <div fxFlex.xs="300px"
      [class.mat-elevation-z8]="item.ItemType+item.ItemId==highlightedContentId"
      [ngClass]="{'highlight-contenttile':item.ItemType+item.ItemId==highlightedContentId}" [id]="item.ItemType+item.ItemId"
      fxFlex.gt-xs="224px" *ngFor="let item of coursesList" class="content-tile-holder" fxLayout.gt-xs="column">
        <yorbit-content-tile-lp fxFlex.xs="224px" fxFlex.gt-xs="300px" [card-details]='item' (click)="navigateTo(item)"></yorbit-content-tile-lp>
      </div>

    </div>
    <div fxFlexFill *ngIf="pageLoading" fxLayoutAlign="center center">
      <app-preloader></app-preloader>
    </div>
    <div *ngIf="!pageLoading && !pageLoadedSuccessfully" fxFlexFill fxLayoutAlign="center center">
      Error in loading page.Please try again.
    </div>
    <div fxFlexFill *ngIf="!pageLoading && pageLoadedSuccessfully && coursesList.length==0"  fxLayoutAlign="center center">
      No courses available.
    </div>
  </div>
</div>

<!-- RBL package -->
<div class="lp-detailed-package-container" fxFlex fxLayout="column" [ngClass.xs]="{'lp-detailed-package-container-xs':true}"
*ngIf="'rolebased'==category">
  <div class="backdrop" (click)="removeHighlightStyle('null')" [ngClass.gt-xs]="{'complianceAndSkillingLp':(LPBreadcrumbs.LPDetails.LPCategory=='skilling' || LPBreadcrumbs.LPDetails.LPCategory=='myCompliance')}" fxFlexFill *ngIf="highlightedContentId!=''"></div>
  <div class="rblHeader" fxFlex="19px" fxLayout="row" fxLayoutAlign="start center">
  </div>

  <div fxFlex="60px" fxLayout="row" fxLayoutAlign="space-between center" class="rblPackDetails">

    <div class="" fxFlex="50%">
      <div class="rblPackName textTruncate" title="{{packageEntity.ItemName}}">
        {{packageEntity.ItemName}}
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div class="frlIcon">F</div>
        <div class="frlText textTruncate" title="{{rblData.Family}}">{{rblData.Family}}</div>
      </div>

      <div fxLayout="row">
        <div class="frlIcon">R</div>
        <div class="frlText textTruncate" title="{{rblData.Role}}">{{rblData.Role}}</div>
      </div>

      <div fxLayout="row">
        <div class="frlIcon">L</div>
        <div class="frlText textTruncate" title="{{rblData.Level}}">{{rblData.Level}}</div>
      </div>
    </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="rblContentType">{{rblData.IsAdvanced?'Advanced':'Basic'}}</div>
    <div class="courseNo">{{rblData.ContentCount}} Course{{(rblData.ContentCount>1)?'s':''}}</div>
  </div>

  <span class="material-icons mousePointer lgIcon"
  (click)="goBackToLP()">close</span>

  </div>

  <div fxFlex class="courses-container" >
    <div class="body setMaxHeight"  id="scrollable-content"  fxLayoutAlign.xs="start center"
    *ngIf="!pageLoading && pageLoadedSuccessfully && coursesList.length!=0">


    <div  *ngFor="let categoryObj of coursesList" >
      <div class="category" >
        {{categoryObj.Category}}
      </div>

        <div>
          <div fxFlex.xs="300px"
          [class.mat-elevation-z8]="item.ItemType+item.ItemId==highlightedContentId"
          [ngClass]="{'highlight-contenttile':item.ItemType+item.ItemId==highlightedContentId}" [id]="item.ItemType+item.ItemId"
          fxFlex.gt-xs="224px"
          *ngFor="let item of categoryObj.Courses"
          class="content-tile-holder" fxLayout.gt-xs="column">
          <yorbit-content-tile-lp fxFlex.xs="224px" fxFlex.gt-xs="300px" [card-details]='item' (click)="navigateTo(item)"></yorbit-content-tile-lp>
        </div>
      </div>
  </div>

    </div>
    <div fxFlexFill *ngIf="pageLoading" fxLayoutAlign="center center">
      <app-preloader></app-preloader>
    </div>
    <div *ngIf="!pageLoading && !pageLoadedSuccessfully" fxFlexFill fxLayoutAlign="center center">
      Error in loading page.Please try again.
    </div>
    <div fxFlexFill *ngIf="!pageLoading && pageLoadedSuccessfully && coursesList.length==0"  fxLayoutAlign="center center">
      No courses available.
    </div>
  </div>
</div>
