import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScormPlayerService {
  config: Ienvironment;
  suspendData:any;

 

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService
    ) {
    this.config = this._envSvc.getEnvironment();

    this.suspendData = <BehaviorSubject<any>>(
      new BehaviorSubject(null)
    );
   }


   getScormDataById(id) {
    return this.http
      .get(this.config.apiUrl + 'Course/ScormData/'+id)
      .toPromise();
  }

  updateScormData(payload,badgeId) {
    return this.http
      .post(this.config.apiUrl + 'Course/UpdateScormDetails?id='+payload.Id+'&badgeId='+badgeId, payload).toPromise();
  }

  getScormDataByMID() {
    return this.http
      .get(this.config.apiUrl + "Course/ScormDataByMID")
      .toPromise();
  }

  updateSuspendDataToCaptureProgress(obj) {
    this.suspendData.next(obj);
  }

  watchSuspendData(){
    this.suspendData
   // .pipe(debounceTime(1000))
    .subscribe(suspendData => {
      //call API to update LearnerScormData
    });
  }
}
