import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Globals } from '../../globals';
import { RiskBrigadeService } from '../../shared/services/risk-brigade.service';
@Component({
  selector: 'yorbit-risk-brigade-home',
  templateUrl: './risk-brigade-home.component.html',
  styleUrls: ['./risk-brigade-home.component.scss']
})
export class RiskBrigadeHomeComponent implements OnInit {

  isDataLoading:boolean=false;
  video:any;
  speaker:any;
  knowledgeHub:any;
  showVideo=true;

  constructor(
    private globals: Globals, private _riskBrigadeService:RiskBrigadeService) { }

  ngOnInit() {
    this.getNetworkData()
  }

  getNetworkData(){
    var videoRequest=this._riskBrigadeService.getVideos();
    var speakerRequest=this._riskBrigadeService.getSpeaker();
    var knowledgeHubRequest=this._riskBrigadeService.getKnowledgehub();
    this.isDataLoading=true;
    forkJoin([videoRequest,speakerRequest,knowledgeHubRequest]).subscribe(response=>{
      this.isDataLoading = false;
       var videoResponse:any=response[0];
       var speakerResponse:any=response[1];
       var knowledgeHubResponse:any=response[2];
       //console.log(response);
        if(videoResponse && videoResponse.length>0){
          this.video= videoResponse.filter((item)=>item.PageLocation=='home page')[0]
          this.video.id=this.globals.livingMindFullVideoId
          this.video.VideoLink=this.globals.livingMindFullyVideoLink
        }

        if(speakerResponse){
          this.speaker= speakerResponse.filter((item)=>item.PageLocation=='home page')[0]
        }
        //console.log(knowledgeHubResponse);

        if(knowledgeHubResponse){
          
          this.knowledgeHub=knowledgeHubResponse;
          //console.log(this.knowledgeHub);
        }
    
    },
    error => {
      this.isDataLoading = false;
    })
  }

  popUpOpened(event){
    //console.log(event);
    this.showVideo=!event;
  }
}
