import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badgeQuarter'
})
export class BadgeQuarterPipe implements PipeTransform {
  transform(badges: any, year: any, quarter: any): any {
    if (badges.length > 0) {
      return badges.filter(function(badge) {
        const date = new Date(badge.CompletedDate);
        if (year === date.getFullYear()) {
          if (quarter === 'Q1') {
            if (date.getMonth() >= 0 && date.getMonth() <= 2) {
              return true;
            }
          } else if (quarter === 'Q2') {
            if (date.getMonth() >= 3 && date.getMonth() <= 5) {
              return true;
            }
          } else if (quarter === 'Q3') {
            if (date.getMonth() >= 6 && date.getMonth() <= 8) {
              return true;
            }
          } else if (quarter === 'Q4') {
            if (date.getMonth() >= 9 && date.getMonth() <= 11) {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    } else {
      return badges;
    }
  }
}
