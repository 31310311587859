import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-pss-status-message',
  templateUrl: './pss-status-message.component.html',
  styleUrls: ['./pss-status-message.component.scss']
})
export class PssStatusMessageComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PssStatusMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      ////console.log('backdrop click');
      this.dialogRef.close();
    });
  }

  closePopup() {
    this.dialogRef.close();
  }
}
