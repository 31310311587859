<mat-dialog-content>
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" [disabled]="courseDownload.disableProceed" 
    [ngClass]="{'disabled':courseDownload.disableProceed}" (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="popup-content">
    <div class="popupMessage" [innerHtml]="courseDownload.message" *ngIf="courseDownload.actionMessage != 'Download Completed.'">
     
    </div>
    <div class="popupMessage" *ngIf="courseDownload.actionMessage != 'Download Completed.'">
      {{courseDownload.subMessage}}
    </div>
    <div class="actionButtonContainer" *ngIf="courseDownload.actionMessage != 'Download Completed.'">
      <button class="actionButton action-button" [disabled]="courseDownload.disableProceed"
      [ngClass]="{'disabled':courseDownload.disableProceed}" (click)="proceed()">{{courseDownload.leftButton}}</button>
      <button class="actionButton action-button"  [disabled]="courseDownload.disableProceed"
      [ngClass]="{'disabled':courseDownload.disableProceed}" (click)="closePopup()">{{courseDownload.RightButton}}</button>
    </div>
    <div class="infoMessage" *ngIf="courseDownload.actionMessage != 'Download Completed.'">
      {{courseDownload.couresRequestMessage}}
    </div>
    <div class="statusMessage" *ngIf="''!==courseDownload.actionMessage" [ngClass]="{'completed-status':courseDownload.actionMessage == 'Download Completed.'}">
      {{courseDownload.actionMessage}}
    </div>
  </div>
</mat-dialog-content>