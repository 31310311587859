import * as fromRolebasedStoreAction from '../actions/rolebased-store.action';

export interface IrolebasedDetailsReducerState {
  data: IrolebasedData[];
  loading: boolean;
  loaded: boolean;
}
export interface IrolebasedData {
  UniqueId: string;
  ContentCount: number;
  IsAdvanced: boolean;
  Family: string;
  Role: string;
  Level: string;
  LevelSalaryGrade: string;
  Id:string;
  SpecializationGroup: string;
  CoursesWithCategory: { CourseIds: string; Category: string }[];
}

export const rolebasedStatusInit = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = rolebasedStatusInit,
  action: fromRolebasedStoreAction.RolebasedAction
): IrolebasedDetailsReducerState {
  switch (action.type) {
    case fromRolebasedStoreAction.ROLEBASED_GET_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case fromRolebasedStoreAction.ROLEBASED_GET_DETAILS_SUCCESS: {
      const data: IrolebasedData[] = updateStatus(state.data, action.payload);
      return {
        ...state,
        data,
        loading: false,
        loaded: true
      };
    }
    case fromRolebasedStoreAction.ROLEBASED_GET_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
  }
  return state;
}

export const getRolebasedStatus = (state: IrolebasedDetailsReducerState) =>
  state.data;
export const getRolebasedLoading = (state: IrolebasedDetailsReducerState) =>
  state.loading;
export const getRolebasedLoaded = (state: IrolebasedDetailsReducerState) =>
  state.loaded;

function updateStatus(data, payload) {
  let uniqueDetails = [];
  payload.forEach(row => {
    if (data.find(datum => datum.UniqueId == row.UniqueId)) {

    } else {
      uniqueDetails.push(row);
    }
  });
  if (uniqueDetails.length > 0) {
    return [...data, ...uniqueDetails];
  }
  return data;
}
