import * as fromUserDetailAction from '../actions/user-details.action';
import { userDetailsInitialState } from '../user-detail.model';
import { IuserDetails, LearningPath } from '../user-details.interface';

export interface IuserDetailsReducerState {
  data: IuserDetails;
  loading: boolean | null;
  loaded: boolean | null;
  error: string | null;
}
export const userDetailsInit = {
  data: userDetailsInitialState,
  loading: null,
  loaded: null,
  error: null
};

export function reducer(
  state = userDetailsInit,
  action: fromUserDetailAction.UserDetailsAction
): IuserDetailsReducerState {
  switch (action.type) {
    case fromUserDetailAction.USER_DETAILS_GET_DETAILS: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case fromUserDetailAction.USER_DETAILS_GET_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }
    case fromUserDetailAction.USER_DETAILS_GET_DETAILS_SUCCESS: {
      let actionPayload={...action.payload};
      if (!state.data.PackagesListWithCourses) {
        //action.payload.PackagesListWithCourses = {};
        actionPayload={...actionPayload,PackagesListWithCourses : {}}
      }
      return {
        ...state,
        data: actionPayload,
        loading: false,
        loaded: true
      };
    }
    case fromUserDetailAction.USER_DETAILS_ADD_LEARNING_PATH: {
      const data = state.data;
      let learningPath = [];
      if (
        null !== data.LearningPaths &&
        0 < data.LearningPaths.length
      ) {
        learningPath = [...data.LearningPaths, action.payload];
      } else {
        learningPath.push(action.payload);
      }
      data.LearningPaths = learningPath.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.PathId).indexOf(obj.PathId) === pos;
      });
      return {
        ...state,
        data,
        loading: false,
        loaded: true
      };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_PACKAGE_COURSES_LIST: {
      const data = state.data;
      const newEntry = action.payload;
      data.PackagesListWithCourses = {
        ...data.PackagesListWithCourses,
        ...newEntry
      };
      //console.log('packageslistwithcourses', data.PackagesListWithCourses);
      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_PACKAGE_COURSES_PROGRESS: {
      const data = state.data;
      const newEntry = action.payload;
      for (
        let index = 0;
        index < data.PackagesListWithCourses[action.payload.PackageId].length;
        index++
      ) {
        if (
          data.PackagesListWithCourses[action.payload.PackageId][index]
            .ItemId == action.payload.CourseId
        ) {
          data.PackagesListWithCourses[action.payload.PackageId][
            index
          ].PackageProgress.Progress =
            action.payload.Progress;
          break;
        }
      }
      return { ...state, data: data };
    }
    case fromUserDetailAction.UPDATE_LP_RESUME_DETAILS: {
      const data = state.data;
      const newResumeData = action.payload;
      data.LearningPlanToResume = {
        ...data.LearningPlanToResume,
        ...newResumeData
      };
      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_WORKFLOW_STATUS: {
      const data = state.data;
      const courseId = action.payload.ItemId;
      const packageId = action.payload.PackageId;
      const lpId = action.payload.LPId;
      const isLpMandatory = action.payload.IsLPMandatory;
      if (packageId == null) {
        if (isLpMandatory) {
          data.LearningPaths.forEach(lp => {
            if (lp.PathName == lpId) {
              lp.PackageList.forEach(course => {
                if (course.ItemId == courseId) {
                  course.WorflowStatus = action.payload.WorflowStatus;
                  course.RequestId = action.payload.RequestId;
                }
              });
            }
          });
        } else {
          data.LearningPaths.forEach(lp => {
            if (lp.PathId == lpId) {
              lp.PackageList.forEach(course => {
                if (course.ItemId == courseId) {
                  course.WorflowStatus = action.payload.WorflowStatus;
                  course.RequestId = action.payload.RequestId;
                }
              });
            }
          });
        }
      }
      data.CourseProgressList.forEach(course => {
        if (course.CourseId.toString() == courseId.toString()) {
          course.WorflowStatus = action.payload.WorflowStatus;
          course.RequestID = action.payload.RequestId;
        }
      });

      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_DELETE_CONTENT_INSIDE_LEARNING_PATH: {
      const data = state.data;
      const itemId = action.payload.ItemId;
      const itemType = action.payload.ItemType;
      const lpId = action.payload.LPId;
      for (let lpIndex = 0; lpIndex < data.LearningPaths.length; lpIndex++) {
        if (data.LearningPaths[lpIndex].PathId == lpId) {
          for (
            let itemIndex = 0;
            itemIndex < data.LearningPaths[lpIndex].PackageList.length;
            itemIndex++
          ) {
            if (
              data.LearningPaths[lpIndex].PackageList[itemIndex].ItemId ==
              itemId &&
              data.LearningPaths[lpIndex].PackageList[itemIndex].ItemType ==
              itemType &&
              !data.LearningPaths[lpIndex].PackageList[itemIndex].IsDeleted
            ) {
              data.LearningPaths[lpIndex].PackageList[
                itemIndex
              ].IsDeleted = true;
              break;
            }
          }
          break;
        }
      }

      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_DELETE_LEARNING_PATH: {
      const data = state.data;
      const lpId = action.payload.PathId;
      for (let lpIndex = 0; lpIndex < data.LearningPaths.length; lpIndex++) {
        if (
          data.LearningPaths[lpIndex].PathId == lpId &&
          !data.LearningPaths[lpIndex].IsDeleted
        ) {
          data.LearningPaths[lpIndex].IsDeleted = true;
          break;
        }
      }
      data.LearningPaths = [...data.LearningPaths];
      return { ...state, data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_COURSE_PROGRESS: {
      const data = state.data;
      const lpId = action.payload.LPId;
      if (action.payload.PackageId == null) {
        for (let lpIndex = 0; lpIndex < data.LearningPaths.length; lpIndex++) {
          if (
            ((!data.LearningPaths[lpIndex].IsMandatory &&
              data.LearningPaths[lpIndex].PathId == lpId) ||
              (data.LearningPaths[lpIndex].IsMandatory &&
                data.LearningPaths[lpIndex].PathName == lpId)) &&
            !data.LearningPaths[lpIndex].IsDeleted
          ) {
            let packageList = data.LearningPaths[lpIndex].PackageList;
            for (
              let itemIndex = 0;
              itemIndex < packageList.length;
              itemIndex++
            ) {
              if (
                packageList[itemIndex].ItemId == action.payload.CourseId &&
                !packageList[itemIndex].IsDeleted
              ) {
                packageList[itemIndex].PackageProgress.Progress =
                  action.payload.Progress;
                break;
              }
            }
            break;
          }
        }
      }
      for (
        let courseIndex = 0;
        courseIndex < data.CourseProgressList.length;
        courseIndex++
      ) {
        if (
          data.CourseProgressList[courseIndex].CourseId ==
          action.payload.CourseId
        ) {
          data.CourseProgressList[courseIndex].Progress =
            action.payload.Progress;
          break;
        }
      }

      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_UNIT_PROGRESS: {
      const data = state.data;
      const lpId = action.payload.LPId;
      for (let lpIndex = 0; lpIndex < data.LearningPaths.length; lpIndex++) {
        if (
          ((!data.LearningPaths[lpIndex].IsMandatory &&
            data.LearningPaths[lpIndex].PathId == lpId) ||
            (data.LearningPaths[lpIndex].IsMandatory &&
              data.LearningPaths[lpIndex].PathName == lpId)) &&
          !data.LearningPaths[lpIndex].IsDeleted
        ) {
          let packageList = data.LearningPaths[lpIndex].PackageList;
          for (let itemIndex = 0; itemIndex < packageList.length; itemIndex++) {
            if (
              packageList[itemIndex].ItemId == action.payload.ItemId &&
              !packageList[itemIndex].IsDeleted
            ) {
              packageList[itemIndex].ProgressList.push(action.payload.Progress);
              break;
            }
          }
          break;
        }
      }

      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_VIDEO_PROGRESS: {
      let data = { ...state.data };
      for (let lpIndex = 0; lpIndex < data.LearningPaths.length; lpIndex++) {
        if (!data.LearningPaths[lpIndex].IsDeleted &&
          (((!data.LearningPaths[lpIndex].IsMandatory || (data.LearningPaths[lpIndex].IsMandatory && data.LearningPaths[lpIndex].IsSkillPlan)) &&
            data.LearningPaths[lpIndex].PathId == action.payload.LPId) ||
            (data.LearningPaths[lpIndex].IsMandatory &&
              data.LearningPaths[lpIndex].PathName == action.payload.LPId))
        ) {
          data.LearningPaths[lpIndex].PackageList.forEach(entity => {
            if (entity.ItemId == action.payload.ItemId && !entity.IsDeleted) {
              let hasVideoProgressEntity = false;
              entity.ProgressList.forEach(video => {
                if (
                  video.ContentId ==
                  action.payload.VideoProgressDetails.ContentId.toString()
                ) {
                  hasVideoProgressEntity = true;
                  video.ContentId = action.payload.VideoProgressDetails.ContentId.toString();
                  video.CourseId = action.payload.VideoProgressDetails.CourseId.toString();
                  video.Progress = action.payload.VideoProgressDetails.Progress;
                  video.CurrentTime =
                    action.payload.VideoProgressDetails.CurrentTime;
                  entity.ProgressList.push(video);
                  //video = { ...action.payload.VideoProgressDetails };
                }
              });
              if (!hasVideoProgressEntity) {
                let videoProgress = {};
                videoProgress[
                  'ContentId'
                ] = action.payload.VideoProgressDetails.ContentId.toString();
                videoProgress[
                  'CourseId'
                ] = action.payload.VideoProgressDetails.CourseId.toString();
                videoProgress['Progress'] =
                  action.payload.VideoProgressDetails.Progress;
                videoProgress['CurrentTime'] =
                  action.payload.VideoProgressDetails.CurrentTime;
                entity.ProgressList.push(videoProgress);
              }
            }
          });
        }
      }
      //return Object.assign({}, state, { data: data })
      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_VIDEO_PROGRESS_FOR_READDED_COURSES: {
      let data = { ...state.data };
      const lpId = action.payload.LearningPathId;
      for (let lpIndex = 0; lpIndex < data.LearningPaths.length; lpIndex++) {
        if (
          ((!data.LearningPaths[lpIndex].IsMandatory &&
            data.LearningPaths[lpIndex].PathId == lpId) ||
            (data.LearningPaths[lpIndex].IsMandatory &&
              data.LearningPaths[lpIndex].PathName == lpId)) &&
          !data.LearningPaths[lpIndex].IsDeleted
        ) {
          data.LearningPaths[lpIndex].PackageList.forEach(entity => {
            if (((entity.ItemId == action.payload.CourseId && entity.ItemType == "Course") || (entity.ItemId == action.payload.ItemId && entity.ItemType.indexOf("Package") != -1)) && !entity.IsDeleted) {
              action.payload.ProgressList.forEach(val => {
                if (entity.ProgressList.filter(x => x.ContentId == val.ContentId).length == 0) {
                  entity.ProgressList.push(val);
                }
              })
            }
          });
        }
      }
      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_DELETED_VIDEO_PROGRESS: {
      let data = { ...state.data };
      const progresslist = action.payload;
      progresslist.forEach(val => {
        var progress = data.DeletedVideoProgressListBackup.filter(x => {
          return x['ContentId'] == val['ContentId'];
        });
        if (progress != null && progress.length != 0) {
          data.DeletedVideoProgressListBackup[
            data.DeletedVideoProgressListBackup.findIndex(
              x => x.ContentId == val.ContentId
            )
          ].CurrentTime =
            val.CurrentTime;
        } else {
          data.DeletedVideoProgressListBackup.push(val);
        }
      });
      //return Object.assign({}, state, { data: data })
      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_CLEAR_DELETED_VIDEO_PROGRESS: {
      let data = { ...state.data };
      const videoIdsList = action.payload;
      data.DeletedVideoProgressListBackup = data.DeletedVideoProgressListBackup.filter(x => {
        return videoIdsList.indexOf(x.ContentId) == -1
      });
      //return Object.assign({}, state, { data: data })
      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_ACHIEVEMENTS: {
      const data = state.data;
      const badgeId = action.payload.badgeId;
      const badgeStatus = action.payload.badgeStatus;
      const quizScore = action.payload.quizScore;
      //const badgeImage = action.payload.badgeImage;
      for (
        let badgeIndex = 0;
        badgeIndex < data.Achievements.Badges.length;
        badgeIndex++
      ) {
        if (
          data.Achievements.Badges[badgeIndex].BadgeId.toString() ===
          badgeId.toString()
        ) {
          if (badgeStatus) {
            data.Achievements.Badges[badgeIndex].Status = 'true';
          } else {
            data.Achievements.Badges[badgeIndex].Status = 'false';
          }
          if (quizScore) {
            data.Achievements.Badges[badgeIndex].Score = quizScore;
          }
          // if (badgeImage)
          //   data.Achievements.Badges[badgeIndex].BadgeImage = badgeImage;
        }
      }
      return { ...state, data: data };
    }
    case fromUserDetailAction.USER_DETAILS_UPDATE_QUIZ_DETAILS: {
      const data = state.data;
      for (
        let quizIndex = 0;
        quizIndex < data.QuizDetails.length;
        quizIndex++
      ) {
        if (data.QuizDetails[quizIndex].QuizId === action.payload.QuizId) {
          data.QuizDetails[quizIndex].CompletedDate =
            action.payload.CompletedDate;
          data.QuizDetails[quizIndex].HasPassed = action.payload.HasPassed;
          data.QuizDetails[quizIndex].Progress = action.payload.Progress;
          data.QuizDetails[quizIndex].RetakeQuiz = action.payload.RetakeQuiz;
        }
      }
      return { ...state, data: data };
    }

    case fromUserDetailAction.USER_DETAILS_ADD_QUIZ_DETAILS: {
      const data = state.data;
      data.QuizDetails.push(action.payload);
      return { ...state, data: data };
    }
  }
  return state;
}

export const getUserDetails = (state: IuserDetailsReducerState) => state.data;
export const getLearningPath = (state: IuserDetailsReducerState) => {
  const learningPaths = state.data.LearningPaths;
  let entities;
  if (learningPaths && 0 !== learningPaths.length) {
    entities = state.data.LearningPaths.reduce(
      // tslint:disable-next-line:no-shadowed-variable
      (entities: IlearningPathEntities, learningPath) => {
        return {
          ...entities,
          [learningPath.PathId]: learningPath
        };
      },
      {
        ...entities
      }
    );
  } else {
    entities = [];
  }

  return Object.keys(entities).map(id => entities[parseInt(id, 10)]);

  // return entities;
};

export const getUserDetailsLoaded = (state: IuserDetailsReducerState) =>
  state.loaded;
export const getUserDetailsLoading = (state: IuserDetailsReducerState) =>
  state.loading;

export interface IlearningPathEntities {
  [id: number]: LearningPath;
}
