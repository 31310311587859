<!-- <div class="push-notifications-popup">
  <div mat-dialog-title *ngIf="subscriptionState == null">Yorbit would like to send you Push Notifications.</div>
  <div mat-dialog-content>
    <div class="msg" *ngIf="subscriptionState == null || subscriptionState == 'processed'">
      {{message}}
    </div>
    <div class="msg" *ngIf="subscriptionState == 'processing'">
//preloader
    </div>
  </div>
  <div mat-dialog-actions *ngIf="subscriptionState == null" fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-button (click)="later()">Later</button>
    <button mat-button (click)="allow()" cdkFocusInitial>Allow</button>
  </div>
</div> -->
<mat-dialog-content>
  <div class="closePopupBtn">
    <button mat-button mat-icon-button (click)="closeDialog()">
      <mat-icon class="closematicon">close</mat-icon>
    </button>
  </div>
  <!--Title-->
  <div class="popup-title">
    <div class="heading notifications-header">Shoshin School would like to send you Push Notifications</div>
  </div>
  <div class="description message" *ngIf="subscriptionState == null || subscriptionState == 'processed'">
    {{message}}
  </div>
  <div class="msg" *ngIf="subscriptionState == 'processing'">
    <!--preloader-->
    <app-preloader></app-preloader>
  </div>
  <!--Mat divider-->
  <mat-divider *ngIf="subscriptionState == null"></mat-divider>
  <!--Action buttons-->
  <div class="action-btn" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="subscriptionState == null">
    <button class="action-button" (click)="closeDialog()">CANCEL</button>
    <button class="action-button" (click)="allow()" cdkFocusInitial>ALLOW</button>
  </div>
</mat-dialog-content>