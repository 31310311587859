import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentTileDeletePopupService } from './content-tile-delete-popup.service';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../../../shared/user-details/store';
import { AddedContentDataService } from '../../../services/added-content-data.service';
@Component({
  selector: 'yorbit-content-tile-delete-popup',
  templateUrl: './content-tile-delete-popup.component.html',
  styleUrls: ['./content-tile-delete-popup.component.scss']
})
export class ContentTileDeletePopupComponent implements OnInit {
  processing: boolean;
  processed: boolean;
  processedSuccessfully: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ContentTileDeletePopupComponent>,
    private deleteSerive: ContentTileDeletePopupService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private addedContentDataService: AddedContentDataService
  ) {
    this.processing = false;
    this.processed = false;
    this.processedSuccessfully = false;
  }

  ngOnInit() {
    // if (this.data.ItemType == 'FamilyPackage') {
    //   this.data.ItemType = 'Pacakge';
    // }
  }
  onDeleteConfirmation() {
    this.processing = true;
    this.deleteSerive
      .removeContent(
      this.data.ItemId,
      this.data.LPId,
      this.data.ItemType,
      this.data.IsProject,
      this.data.IsAccount
      )
      .then(response => {
        this.processing = false;
        this.processed = true;
        if (response == true) {
          this.processedSuccessfully = true;
          // delete content item from added content details
          let addedContentDetails = {
            PathId: this.data.LPId,
            PathName: '',
            IsMandatory: false,
            IsCrossSkill: false,
            IsFutureSkill: false,
            IsSkillPlan: false,
            Id: this.data.ItemId,
            PackageId: this.data.ItemType == 'Course' ? '' : this.data.ItemId,
            Expertise: '',
            Name: '',
            AssignedBy: '',
            Type: this.data.ItemType,
            IsAccount: this.data.IsAccount,
            IsProject: this.data.IsProject
          };
          let addedContentType = '';
          if (this.data.IsAccount || this.data.IsProject) {
            if (this.data.ItemType == 'Course') {
              addedContentType = 'NonYorbitCourses';
            } else {
              addedContentType = 'NonYorbitPackages';
            }
          } else {
            if (this.data.ItemType == 'Course') {
              addedContentType = 'YorbitCourses';
            } else {
              addedContentType = 'YorbitPackages';
            }
          }
          this.addedContentDataService.deleteDataFromAddedLPContentDetails(
            addedContentType,
            addedContentDetails
          );
          this.addedContentDataService.contentAddedDetailsLoaded.next(true);
          this.userDetailsStore.dispatch({
            type: '[UserDetails] Delete Content Inside LP',
            payload: {
              ItemId: this.data.ItemId,
              LPId: this.data.LPId,
              ItemType: this.data.ItemType
            }
          });
          this.userDetailsStore.dispatch({
            type: '[UserDetails] Update Deleted Video Progress',
            payload: this.data.ProgressList
          });
        }
      });
  }
  closePopup() {
    this.dialogRef.close(this.processedSuccessfully);
  }
}
