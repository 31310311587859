import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SparkIntegrationService {
  config: Ienvironment;
  competency: string;
  isC4nabv: BehaviorSubject<boolean>;
  //isC7nbelow: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.isC4nabv = new BehaviorSubject(false);
    //this.isC7nbelow = new BehaviorSubject(false);
  }

  getCompetencydetails(Mid) {
    return this.http
      .post(this.config.apiUrl + 'User/Competency',{"Id":Mid})
      .toPromise();
  }
  getCompStatus() {
    return this.isC4nabv.asObservable();
  }
  // getCompStatus2() {
  //   return this.isC7nbelow.asObservable();
  // }
}
