import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { Globals } from '../../globals';
@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  config: Ienvironment;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService, private globals: Globals) {
    this.config = this._envSvc.getEnvironment();

  }

  getUserDetails() {
    return this.http
      .get<any>(this.config.apiUrl + 'user/details')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getGlobalCDMRole(){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let promise = new Promise((resolve, reject) => {
        this.http
          .get(this.config.apiUrl + 'User/CheckGLOBALCDMRole',{headers: headers})
          .toPromise()
          .then(GlobalCDMRole => {
            resolve(GlobalCDMRole);
          })
          .catch(error => {
            resolve(false)
            return promise;
          });;
    });
    return promise;
  }

  migrateUser(){
    return this.http
    .get<any>(this.config.apiUrl + 'user/migrate')
    .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getMandatoryLpName() {
    return this.http.get(this.config.apiUrl + "User/GetMandatoryLPName");
  }
}
