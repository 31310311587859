<div class="hmm-spark-container"
[ngClass]="{'preloader':showPreloader}"
[ngClass.gt-lg]="['gt-lg']"
[ngClass.lg]="['lg']"
[ngClass.md]="['md']"
[ngClass.sm]="['sm']"
[ngClass.xs]="['xs']"
fxFlexFill
fxLayout="column" fxLayoutAlign="start stretch" fxLayoutAlign.lt-md="start stretch">
  <div *ngIf="!showPreloader && IsC4nabv" class="banner-holder" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start stretch" (mouseleave)="setIntervalForSlider(5000)"
  (mouseenter)="pauseSlider($event)">
    <div fxFlex  class="banner" (click)="sliderActiveIndex==0?redirectToHMM():redirectToSpark()" fxLayoutAlign="start stretch" [ngClass]="{'hmm':sliderActiveIndex==0,'spark':sliderActiveIndex==1}">
    <!-- <div fxFlex="50px" class="nav-arrows left" fxLayoutAlign="center center" (click)="showPreviousSlide()">
      <div class="iconSpriteImage prev-icon" ></div>
    </div> -->
    <img fxShow.xs="false" fxShow.gt-xs="true"  src="../../assets/images/desktop-HMM-banner.jpg" *ngIf="sliderActiveIndex==0"/>
    <img fxShow.xs="false" fxShow.gt-xs="true" src="../../assets/images/desktop-spark-banner.jpg" *ngIf="sliderActiveIndex==1"/>
   
    <!-- <img fxShow.xs="true" fxShow.gt-xs="false"  src="../../assets/images/mobile-HMM-banner.jpg" *ngIf="sliderActiveIndex==0" (click)="redirectToHMM()"/>
    <img fxShow.xs="true" fxShow.gt-xs="false" src="../../assets/images/mobile-spark-banner.png" *ngIf="sliderActiveIndex==1" (click)="redirectToSpark()"/>
     -->
    <!-- <div fxFlex="50px" class="nav-arrows right" fxLayoutAlign="center center" (click)="showNextSlide()">
      <div class="iconSpriteImage next-icon"></div>
    </div> -->
    </div>
  </div>
  <div fxFlex="50px" fxLayoutAlign="center center">
  <div
    class="bullets"
    *ngFor="let slide of bullets; let index = index"
    (click)="showImageOfIndex(index)"
    [ngClass]="{ active: index == sliderActiveIndex }"
  ></div>
  </div>
  </div>
  <div *ngIf="!showPreloader && !categorySelected && IsC4nabv" class="navigation" fxFlex="34px" fxLayoutAlign="start start" fxLayoutAlign.xs="start center">
<mat-icon class="left" class="link" (click)="navigateTo('home')">chevron_left</mat-icon>
<div fxFlexOffset="5px" class="link" (click)="navigateTo('home')">Home</div>
<mat-icon>chevron_right</mat-icon>
<div>HMM</div>
  </div>
  <div *ngIf="!showPreloader && !categorySelected && IsC4nabv" class="categories" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" fxLayoutGap.xs="15px">
  <div class="section" *ngFor="let category of categories"  fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap.md="10px"  fxLayoutGap.sm="10px">
    <div class="header" fxFlex="40px" fxLayoutAlign="start center">
      <div fxFlex fxShow.xs="false">
        {{category}}
        </div>
      <div fxFlex fxShow.xs="true" fxShow.gt-xs="false" (click)="selectCategory(category)">
      {{category}}
      </div>
      <div fxshow.xs="true" fxShow.gt-xs="false"><mat-icon>chevron_right</mat-icon></div>
    </div>
    <div class="courses-holder" fxShow.xs="false" fxShow.gt-xs="true" fxFlex="330px" fxFlex.md="310px" fxFlex.sm="310px" [ngClass]="{'showMore':!showMore[category]}" fxLayout="row wrap" fxLayoutAlign="start start">
      <div
      class="content-tile-holder"
      *ngFor="
        let item of courses[category];let index = index
      "
      fxLayout="column"
      fxLayoutAlign="center center"
      >
      <div
      *ngIf="
        (!roleList.CUSTOMER || (roleList.CUSTOMER && item.HasCustomerAccess)) &&
    (item.ItemType=='Course')
      "
      fxFlex="330px"
      fxFlex.md="300px"
      fxFlex.sm="300px"
      fxLayoutAlign="center center"
    >
      <yorbit-content-tile-browse
        [cardDetails]="item"
        [featuredIn]="'Browse'"
        fxFlex="225px"
        fxLayoutAlign="start start"
        fxFlexFill
    
      >
      </yorbit-content-tile-browse>
    </div>
    </div>
    </div>
    <div *ngIf="displayShowMore[category]" class="show-more" fxShow.xs="false" fxShow.gt-xs="true"  fxFlex="20px" fxLayoutAlign="center center">
      <div (click)="showMore[category]=!showMore[category]">
        {{showMore[category]?'Show More':'Show Less'}}
      </div>
    </div>
  </div>
  </div>
  <div  *ngIf="!showPreloader && categorySelected && IsC4nabv" fxShow.gt-xs="false" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="navigation" fxFlex="50px" fxLayoutAlign="start center">
        <mat-icon class="left" class="link" (click)="categorySelected=false">chevron_left</mat-icon>
        <div fxFlexOffset="5px" class="link" (click)="categorySelected=false">HMM</div>
        <mat-icon>chevron_right</mat-icon>
        <div>{{selectedCategory}}</div>
      </div>
      <div  fxFlex="50px" fxLayoutAlign="end center">
<div fxFlex class="search-bar" fxLayoutAlign="start center">
  <input fxFlex [(ngModel)]="searchModel" (onblur)="showSearchBarInLP = false"
  (ngModelChange)="updateSearchModel()" fxFlex placeholder="Search courses" />
  <mat-icon class="close-search-icon" *ngIf="showSearchBar" (click)="searchModel='';updateSearchModel();">clear</mat-icon>
  <mat-icon class="search-mat-icon" *ngIf="!showSearchBar">search</mat-icon> 
</div>

      </div>
      <div class="courses-holder" fxLayout="column" fxLayoutAlign="start center" 
      fxLayoutGap="20px"
      >
        <div
        class="content-tile-holder"
        *ngFor="
          let item of filteredList
        "
        fxLayout="column"
        fxLayoutAlign="center center"
        >
        <div
        *ngIf="
          (!roleList.CUSTOMER || (roleList.CUSTOMER && item.HasCustomerAccess)) &&
      (item.ItemType=='Course')
        "
        fxFlex="300px"
        fxLayoutAlign="center center"
      >
        <yorbit-content-tile-browse
          [cardDetails]="item"
          [featuredIn]="'Browse'"
          fxFlex="225px"
          fxLayoutAlign="start start"
          fxFlexFill
      
        >
        </yorbit-content-tile-browse>
      </div>
      </div>
      <div fxFlexFill fxLayoutAlign="center center" *ngIf="filteredList.length==0">
      No Courses available with "{{searchModel}}"
      </div>
      </div>
  </div>
  <div *ngIf="showPreloader" fxFlexFill fxLayoutAlign="center center">
    <app-preloader></app-preloader>
  </div>
  <div *ngIf="!showPreloader && !IsC4nabv" class="info" [ngClass.xs]="['xs']" fxFlexFill fxLayoutAlign="center center" fxLayout.gt-md="row" fxLayout.lt-lg="column">
    <div fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayoutAlign.gt-xs="start start" fxLayoutGap="5px"><div> Sorry! This page has restricted access.</div><div> Please explore other courses on Shoshin School.</div></div><div fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayoutAlign.gt-xs="start start">  <div>In case of further assistance, please raise a ticket on  </div>
   <a href={{globals.isupportUrl}} target="_blank">{{globals.isupportUrl}}</a>. </div>
  </div>
</div>