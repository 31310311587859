import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { GraphDataService } from './graph-data.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { graphReducer, effectsGraph } from './store';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GraphApiInterceptor } from './graph-api.interceptor';
@NgModule({
  imports: [CommonModule,
    RouterModule,
    StoreModule.forFeature('Graph', graphReducer),
    EffectsModule.forFeature(effectsGraph)
  ],
  providers: [GraphDataService,
    { 
      provide: HTTP_INTERCEPTORS, useClass: GraphApiInterceptor, multi:true
    }]
})
export class GraphModule {}
