<div class="packageHolder mousePointer" (click)="goToDetailsPage(cardDetails)" title="Click to view the course/package details">
  <div class="folderTab301"></div>
  <div class="folderTab201"></div>
  <div class="folderTab101"></div>
  <div class="folderTab"></div>
  <div class="folderBackground"></div>
  <div class="folderBackgroundPatch"></div>

  <div class="cardHolder">
    <mat-card fxFlexFill fxLayout="column" fxLayoutAlign="start stretch" *ngIf="!noDataAvailable" class="browse-content-tile">
      <mat-card-header fxLayout="column" fxFlex="60px" class="content-tile-browse-header">
        <mat-icon class="delete-icon" [ngClass.lt-lg]="{'showDeleteIcon':true}" *ngIf="showDeleteIcon" (click)="deleteItemFromLP();$event.stopPropagation()">clear</mat-icon>
        <div fxLayout="row" fxFlex="50px">
          <!--add styles to trucate text after two lines-->
          <div fxFlex="100%" class="course-name" title="{{ cardDetails.Name }}">{{ cardDetails.Name||cardDetails.ItemName }}</div>
        </div>
      </mat-card-header>

      <mat-card-content fxFlex="163px" fxLayout="column" class="card-content" fxLayoutAlign="start stretch" *ngIf="loadedCoursesListFormAPI">
        <div fxFlex fxLayoutAlign="start stretch" fxLayout="column" *ngIf="'learningPath'===featuredIn" class="assignedBy-courseList-container">
          <div fxFlex="50px" fxFlexOffset="10px" fxLayout="row" fxLayoutAlign="stretch start" *ngIf="(cardDetails.AssignedBy!=null) || (LPBreadcrumbs.LPDetails.LPId=='ASSIGNED')">
            <div fxFlex="45px" fxLayout="column" class="assignedBy-img-container">
              <img fxFlex="45px" *ngIf="assignerImageUrl!=null" class="assignedBy-img" [src]="assignerImageUrl" />
              <mat-icon *ngIf="assignerImageUrl == null" fxFlex="45px" class="assignedBy-img">person</mat-icon>
            </div>
            <div fxFlex="130px" fxFlexOffset="10px" class="assignedBy-details" fxLayout="column">
              <div class="header">Assigned by</div>
              <div class="name" fxFlex="28px">{{assignerName}}</div>
            </div>
          </div>
          <div fxFlex class="course-list-holder" fxLayout="column" [ngClass.xs]="{'course-list-holder-target-course-xs':isTargetCourse}">
            <div *ngFor="let course of coursesList, let i = index">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div>{{i+1 +". "}}</div>
                <div fxFlex class="mousePointer" fxLayoutAlign="start center" [ngStyle]="{'overflow':'hidden'}">
                  <div class="item-name" fxFlex>{{course.ItemName}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="coursesList.length==0">
              No courses available
            </div>
          </div>
        </div>


        <div *ngIf="'learningPath'!==featuredIn" class="course-description" fxFlex>
          {{ cardDetails.Description }}
        </div>
        <div class="icons-bar" fxFlex="65px" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="!roleList.CUSTOMER&&('learningPath'!==featuredIn)">
          <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center">
            <yorbit-assign-content fxFlex="30px" fxFlexOffset="11px" *ngIf="
          roleList != undefined &&
          (roleList.ACM || roleList.PCM || roleList.CDM || roleList.GLOBALCDM)
        " [card]="{
          contentData: cardDetails
        }" (click)="stopPropagation($event)" class="cardFeatureButtons"></yorbit-assign-content>
            <div fxFlex="15px" fxLayoutAlign="center center" class="added-count">
            </div>
          </div>
          <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center" *ngIf="cardDetails.IsContentTrending" title="This Course/Package is Trending on Shoshin School">
            <div fxFlex="30px" fxFlexOffset="11px" (click)="stopPropagation($event)" class="cardFeatureButtons yorbitSprite iconTrending"></div>
            <div fxFlex="15px" fxLayoutAlign="center center" class="added-count">
            </div>
          </div>
          <!-- Card Feature Buttons-->
          <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center">
            <div (click)="stopPropagation($event)" class="cardFeatureButtons" fxFlex="30px" fxFlexOffset="11px" fxLayoutAlign="center center">
              <div class="yorbitSprite featureAdd" title="{{
          cardDetails.TotalUsers
        }} LTIMindtree Limited Associates Have Added This Course/Package" fxFlex="30px"></div>
            </div>
            <div fxFlex="15px" fxLayoutAlign="center center" class="added-count">
              {{ cardDetails.TotalUsers }}
            </div>
          </div>
          <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center" [ngClass]="{'mouseDisabled':!enableShare}">
            <yorbit-share-content fxFlex="30px" fxFlexOffset="11px" fxLayoutAlign="center center" (click)="stopPropagation($event)" class="cardFeatureButtons"
              [card]="{
            contentData: cardDetails,
            sharedLocation: featuredIn,
            enableShare:enableShare
          }"></yorbit-share-content>
            <div fxFlex="15px" fxLayoutAlign="center center" class="shared-count">
              {{ cardDetails.TotalShares }}
            </div>
          </div>
          <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center">
            <yorbit-expert fxFlex="30px" fxLayoutAlign="center center" fxFlexOffset="11px" (click)="stopPropagation($event)" class="cardFeatureButtons"
              [card]="{
            contentData: cardDetails,
            expertLocation: featuredIn
          }"></yorbit-expert>
            <div fxFlex="15px" fxLayoutAlign="center center" class="experts-count">
              {{ null === cardDetails.Experts ? 0 : cardDetails.Experts?.length }}
            </div>
          </div>
        </div>
        <!-- Card Feature Buttons Ends -->
      </mat-card-content>
      <mat-card-content fxFlex="163px" fxLayout="column" class="card-content" fxLayoutAlign="end stretch" *ngIf="!loadedCoursesListFormAPI">
        <div class="loading-status courses-status-{{
      cardDetails.ItemExpertise
    }}">
          Loading...
        </div>
      </mat-card-content>
      <div class="divider" fxFlex="1px" *ngIf="loadedCoursesListFormAPI">
        <mat-divider></mat-divider>
      </div>
      <mat-card-footer class="card-footer" fxLayout="row" fxFlex="36px" fxLayoutAlign="start center" *ngIf="loadedCoursesListFormAPI">
        <div fxFlex fxLayoutAlign="start center">
          <div class="content-count" *ngIf="cardDetails.ItemType == 'Course' && cardDetails.Expertise == '101'">
            {{ cardDetails.ContentCount }} Unit(s)
          </div>
          <div class="content-count" *ngIf="cardDetails.ItemType == 'Course' && (cardDetails.Expertise == '201' || cardDetails.Expertise == '301')">
            {{ cardDetails.Credits }} Credit(s)
          </div>
          <div class="content-count" *ngIf="
          cardDetails.ItemType == 'Package' ||
          cardDetails.ItemType == 'FamilyPackage'">
            {{ featuredIn=='learningPath'? coursesList.length: cardDetails.ContentCount}} Course(s)
            <!-- {{ cardDetails.ContentCount }} Course(s) -->
          </div>
        </div>
        <div fxFlex="60px" fxLayout="column" fxLayoutAlign="center stretch">
          <yorbit-add-content (click)="stopPropagation($event)" class="cardFeatureButtons" fxFlex="30px" fxLayoutAlign="center center"
            *ngIf="!roleList.CUSTOMER&&('learningPath'!==featuredIn)" [card]="{
        contentData: cardDetails,
        skillProfile: {
          recommendationType: '',
          duration: ''
        },
        'addType':'button',
        addedLocation: featuredIn
      }"></yorbit-add-content>
        </div>
      </mat-card-footer>
      <div class="externalCertificateContainer" *ngIf="cardDetails.Type&&'external certification'===cardDetails.Type.toLowerCase()">
        <div class="yorbitSprite externalCertificate"></div>
      </div>
    </mat-card>
  </div>

</div>