<div class="solution-file-download" fxFlex fxLayoutAlign="end center">
  
  <div class="yorbitSprite icon" title="{{!isSolutionFileAvailable?'Project Solution file link is not availalbe':'Click here to download the solution file'}}"
      (click)="open(34021)"></div>

      <div fxLayoutAlign="end center">
        <div fxFlexOffset="-20px">{{downloadStatus}}</div>
    </div>

      <div *ngIf="showPreloader">
        <app-preloader></app-preloader>
</div>
<div *ngIf="!contentLoadedSuccessfully && !showPreloader">
    Technical issue in loading content. Please try again
</div>
