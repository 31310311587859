import { ActionReducerMap, createSelector,createFeatureSelector} from '@ngrx/store';

import * as fromCloudlabReducer from './cloudlab-store.reducer';

export interface IcloudlabState{
  cloudlab:fromCloudlabReducer.IcloudlabDetailsReducerState
}
export const cloudlabReducerCollection=fromCloudlabReducer.reducer;

export const cloudlabReducers:ActionReducerMap<IcloudlabState>={
  cloudlab:fromCloudlabReducer.reducer
}
export const getCloudlabState= createFeatureSelector<IcloudlabState>('cloudlab')

export const getCloudlabReducerState=createSelector(
  getCloudlabState,
  (state:IcloudlabState)=>state.cloudlab
)

export const getCloudlabStatus= createSelector(
  getCloudlabReducerState,
  fromCloudlabReducer.getCloudlabStatus
)

export const getCloudlabLoading = createSelector(
  getCloudlabReducerState,
  fromCloudlabReducer.getCloudlabLoading
)

export const getCloudlabLoaded= createSelector(
  getCloudlabReducerState,
  fromCloudlabReducer.getCloudlabLoaded
)

export * from './cloudlab-store.reducer';
