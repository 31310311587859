import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { LearningPathService } from '../learning-path.service';
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationEnd
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../shared/user-details/store';
import { AddedContentDataService } from '../../shared/services/added-content-data.service';
import {
  LearningPath,
  PackageList
} from '../../shared/user-details/store/user-details.interface';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import {
  LPBreadcrumbsStateModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
  IUnitDetailsModel
} from '../learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from '../learning-path-breadcrumbs.interface';
import { Subscriber } from 'rxjs';
import { CloudlabStoreService } from '../../shared/cloudlab-store/cloudlab-store.service';
import * as fromRoleBasedStore from '../../shared/content-tiles/rolebased-store/store';
import { DetailsPageService } from '../../details-page/details-page.service';

@Component({
  selector: 'yorbit-lp-package-view',
  templateUrl: './lp-package-view.component.html',
  styleUrls: ['./lp-package-view.component.scss']
})
export class LPPackageViewComponent
  implements OnInit, OnDestroy, AfterViewInit {
  filterPipe = new ArrayPropertyFilterPipe();
  LPPackageCompSubscriptions: any;
  learningPathsLoadedSuccessfully: boolean = false;
  LPBreadcrumbs: ILPBreadcrumbs;
  LearningPath: LearningPath;
  packageEntity: PackageList;
  coursesList: Array<any>;
  pageLoading: boolean;
  pageLoadedSuccessfully: boolean;
  highlightedContentId = '';
  addedLinkClick = false;
  category: any;
  categoryList: any[];
  rblData: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private cloudlabStoreService: CloudlabStoreService,
    private addedContentDataService: AddedContentDataService,
    private roleBasedStore: Store<fromRoleBasedStore.IrolebasedData>,
    private detailsPageService : DetailsPageService
  ) {
    this.LPPackageCompSubscriptions = {};
    this.LearningPath = {};
    this.packageEntity = {};
    this.coursesList = [];
    this.rblData={};
    this.pageLoading = true;
    this.learningPathsLoadedSuccessfully = false;
    this.pageLoadedSuccessfully = false;
  }

  ngOnInit() {
    this.category="";
    this.LPPackageCompSubscriptions.getUserDetailLoaded = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailLoaded)
      .subscribe(loaded => {
        //console.log("user details loaded");
        //console.log("sub 1");
        if (loaded && !this.learningPathsLoadedSuccessfully) {
          this.learningPathsLoadedSuccessfully = true;
          this.subscribeToRouterEvents();
          this.subscribeToBreadcrumbs();
        }
        else if (loaded && this.learningPathsLoadedSuccessfully) {
          ////console.log("unsub 1");
          this.LPPackageCompSubscriptions.getUserDetailLoaded.unsubscribe();
        }
      })
  }
  ngAfterViewInit() {
    if (this.addedLinkClick) {
      setTimeout(() => {
        if (document.getElementById(this.highlightedContentId) != undefined) {
          this.highlightAndNavigateToSearchedTile();
        }
      }, 1000);
    }
  }
  subscribeToBreadcrumbs() {
    this.LPPackageCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        //console.log("sub 3");
        this.LPBreadcrumbs = breadcrumbs;
      });
  }
  subscribeToRouterEvents() {
    this.LPPackageCompSubscriptions.routerParamsSubscriptions = this.activatedRoute.params.subscribe(
      params => {
        //console.log("sub 4");
        //console.log("route params", params);
        this.LPPackageCompSubscriptions.getLearningPathListEntitiesSub = this.userDetailsStore
          .select(fromUserDetailsStore.getLearningPathListEntities)
          .subscribe(res => {
            if (
              res[params.lpId.toUpperCase()] != undefined && params.packageId != undefined
            ) {
              this.LearningPath = res[params.lpId.toUpperCase()];
              this.category=params.category;
              this.coursesList = [];
              this.getPackageDetails(params.packageId,params.category);
            }
          });
        //console.log("sub 4 end fn");
        this.LPPackageCompSubscriptions.getLearningPathListEntitiesSub.unsubscribe();
        //console.log("unsub 4");
      }
    );
  }

  getPackageDetails(packageId,category) {

    //console.log("lp breadcrumbs update from getPackageDetails");
    if(category== "rolebased"){
      let packages = this.filterPipe.transform(this.LearningPath.PackageList, {
        property: 'ItemType',
        flag: 'RoleBasedPackage'
      });
      let familyPackages = this.filterPipe.transform(
        this.LearningPath.PackageList,
        {
          property: 'ItemType',
          flag: 'FamilyPackage'
        }
      );
      let undeletedEntities = this.filterPipe.transform(packages, {
        property: 'IsDeleted',
        flag: false
      });
      this.packageEntity = this.filterPipe.transform(undeletedEntities, {
        property: 'ItemId',
        flag: packageId
      })[0];
      this.lpService.updateBreadcrumbs(
        this.LearningPath,
        this.packageEntity,
        null,
        null,
        null
      );
      this.getCoursesInsideRBLPackage(packageId,packages,familyPackages);
    }else{
      let packages = this.filterPipe.transform(this.LearningPath.PackageList, {
        property: 'ItemType',
        flag: 'Package'
      });
      let familyPackages = this.filterPipe.transform(
        this.LearningPath.PackageList,
        {
          property: 'ItemType',
          flag: 'FamilyPackage'
        }
      );
      let allPackageEntitiesInLP = packages.concat(familyPackages);
      let undeletedEntities = this.filterPipe.transform(allPackageEntitiesInLP, {
        property: 'IsDeleted',
        flag: false
      });
      this.packageEntity = this.filterPipe.transform(undeletedEntities, {
        property: 'ItemId',
        flag: packageId
      })[0];
      this.lpService.updateBreadcrumbs(
        this.LearningPath,
        this.packageEntity,
        null,
        null,
        null
      );
      this.getCoursesInsidePackage(this.packageEntity);
    }
  }
  getCoursesInsideRBLPackage(packageId,packages,familyPackages) {
    this.LPPackageCompSubscriptions.contentAddedDetailsForPackagesLoadedSub = this.addedContentDataService.contentAddedDetailsForPackagesLoaded.subscribe(
      isLoaded => {

        if (isLoaded && this.coursesList.length == 0) {

          //rbl List
          this.roleBasedStore
        .select(fromRoleBasedStore.getRolebasedDetailsByPackageId(packageId))
        .subscribe(rblData => {
          if(rblData){
            //start
            //this.coursesList = rblData.CoursesWithCategory
            this.pageLoading = false;
            this.rblData=rblData;

            if (rblData.CoursesWithCategory.length > 0) {
              let list = [];
              let category=[];
              rblData.CoursesWithCategory.forEach((course, index) => {
                let splitArray=[];
                if("string"== typeof course.CourseIds){
                  course.CourseIds.split(',').forEach((id)=>{
                    splitArray.push(id);
                    list.push(id);
                  })
                }else{
                  course.CourseIds.forEach((id)=>{
                    splitArray.push(id);
                    list.push(id);
                  })
                }

                if(-1==category.indexOf(course.Category)){
                  category.push(course.Category);
                  this.coursesList.push({
                    Category:course.Category,
                    Courses:[]
                  })
                }


                splitArray.forEach(id=>{

                this.detailsPageService.getPackageDetails(id,"Course").toPromise().then(
                  (response)=>{

                    let courseDetails = {
                      ItemId: response.Id.toString(),
                      RequestId: "",
                      ItemType: response.ItemType,
                      ItemName: response.Name,
                      ItemExpertise: response.Expertise,
                      ImageUrl: '',
                      VideoCount: response.ContentCount,
                      Badges: [],
                      BadgeIds: null,
                      PackageProgress: {
                        Progress: 0,
                        ConsumedTime: 0,
                        TimeLeft: 0,
                        TotalTime: 0
                      },
                      ProgressList: [],
                      Declaration: false,
                      IsMandatory: true,
                      AssignedBy: '',
                      AssignorRole: null,
                      Department: null,
                      IsCancelable: false,
                      AccountPackage: response.AccountPackage,
                      ProjectPackage: response.ProjectPackage,
                      Category: null,
                      AccountId: response.Account,
                      ProjectId: response.Project,
                      WorflowStatus: "",
                      SubStatus: "",
                      DueDate: '0001-01-01T00:00:00',
                      IsDeleted: false,
                      CreatedDate: '0001-01-01T00:00:00',
                      ModifiedDate: '0001-01-01T00:00:00',
                      DeletedDate: '0001-01-01T00:00:00',
                      CompletedDate: '0001-01-01T00:00:00',
                      IsInIDP: false,
                      IsBlocked: false
                    };
                    for (let index = 0; index < this.coursesList.length; index++) {
                      const element = this.coursesList[index];
                      if(this.coursesList[index].Category==course.Category){
                        this.coursesList[index].Courses.push(courseDetails);
                      }

                    }
                    //this.coursesList.push(courseDetails);
                    //(courseDetails.ItemId,course.Category)
                  })
                });


              });

              if (list.length > 0) {
                this.cloudlabStoreService.fetchLabsInBatch(list);
              }
            }
            this.pageLoadedSuccessfully = true;
            if (!this.LPPackageCompSubscriptions.routeQueryParamsSubscriptions) {
              this.LPPackageCompSubscriptions.routeQueryParamsSubscriptions = this.activatedRoute.queryParams.subscribe(
                params => {
                 // console.log('params', params);
                  // Defaults to 0 if no query param provided.
                  if (
                    params['courseId'] != undefined &&
                    params['packageId'] != undefined
                  ) {
                    //scroll content till the content tile
                    this.addedLinkClick = true;
                    this.highlightedContentId = 'Course' + params['courseId'];
                    setTimeout(() => {
                      if (
                        document.getElementById(this.highlightedContentId) !=
                        undefined
                      ) {
                        this.highlightAndNavigateToSearchedTile();
                      }
                    }, 3000);
                  } else {
                    this.addedLinkClick = false;
                    this.highlightedContentId = '';
                  }
                }
              );
            }

            //end

          }else{
            this.coursesList = [];
            this.pageLoading = false;
            this.pageLoadedSuccessfully = true;
          }
        });

        }
      }
    );
    let allPackageEntitiesInLP = packages.concat(familyPackages);
    let undeletedEntities = this.filterPipe.transform(allPackageEntitiesInLP, {
      property: 'IsDeleted',
      flag: false
    });
    this.packageEntity = this.filterPipe.transform(undeletedEntities, {
      property: 'ItemId',
      flag: packageId
    })[0];
    //console.log("lp breadcrumbs update from getPackageDetails");
    this.lpService.updateBreadcrumbs(
      this.LearningPath,
      this.packageEntity,
      null,
      null,
      null
    );
    this.getCoursesInsidePackage(this.packageEntity);
  }
  getCoursesInsidePackage(packageEntity) {
    this.LPPackageCompSubscriptions.contentAddedDetailsForPackagesLoadedSub = this.addedContentDataService.contentAddedDetailsForPackagesLoaded.subscribe(
      isLoaded => {
        // //console.log(
        //   'packagescourses loaded package view comp',
        //   packageEntity.ItemId,
        //   isLoaded
        // );
        if (isLoaded && this.coursesList.length == 0) {
          // //console.log(
          //   'packagescourses loaded lp comp',
          //   packageEntity.ItemId
          // );
          // //console.log(
          //   'packagecourses data lp comp',
          //   this.addedContentDataService.packageCourseMappings[
          //   packageEntity.ItemId
          //   ]
          // );
          if (
            this.addedContentDataService.packageCourseMappings &&
            this.addedContentDataService.packageCourseMappings[
            packageEntity.ItemId
            ] != undefined
          ) {
            this.coursesList = this.addedContentDataService.packageCourseMappings[
              packageEntity.ItemId
            ];
            this.pageLoading = false;

            if (this.coursesList.length > 0) {
              let list = [];
              this.coursesList.forEach((course, index) => {
                list.push(course.ItemId);
              });
              if (list.length > 0) {
                this.cloudlabStoreService.fetchLabsInBatch(list);
              }
            }
            this.pageLoadedSuccessfully = true;
            if (!this.LPPackageCompSubscriptions.routeQueryParamsSubscriptions) {
              this.LPPackageCompSubscriptions.routeQueryParamsSubscriptions = this.activatedRoute.queryParams.subscribe(
                params => {
                  //console.log('params', params);
                  // Defaults to 0 if no query param provided.
                  if (
                    params['courseId'] != undefined &&
                    params['packageId'] != undefined
                  ) {
                    //scroll content till the content tile
                    this.addedLinkClick = true;
                    this.highlightedContentId = 'Course' + params['courseId'];
                    setTimeout(() => {
                      if (
                        document.getElementById(this.highlightedContentId) !=
                        undefined
                      ) {
                        this.highlightAndNavigateToSearchedTile();
                      }
                    }, 1000);
                  } else {
                    this.addedLinkClick = false;
                    this.highlightedContentId = '';
                  }
                  // //console.log(
                  //   ' this.highlightedContentId',
                  //   this.highlightedContentId
                  // );
                }
              );
            }
          } else {
            this.coursesList = [];
            this.pageLoading = false;
            this.pageLoadedSuccessfully = true;
          }
        }
      }
    );
  }
  navigateTo(data) {
    if (data.AccountId == '' || data.AccountId == undefined) {
      data.AccountId = null;
    } else {
    }
    if (data.ProjectId == '' || data.ProjectId == undefined) {
      data.ProjectId = null;
    }
    this.router.navigate(
      [
        'course/' +
        data.ItemId.trim() +
        '/account/' +
        data.AccountPackage +
        '/project/' +
        data.ProjectPackage +
        '/accountId/' +
        data.AccountId +
        '/projectId/' +
        data.ProjectId +
        '/tabs/playlist'
      ],
      { relativeTo: this.activatedRoute }
    );
  }
  goBackToLP() {
    this.lpService.updateBreadcrumbs(
      this.LearningPath,
      null,
      null,
      null,
      null
    );
    this.router.navigate([
      'learningpath/category/' +
      this.LPBreadcrumbs.LPDetails.LPCategory +
      '/id/' +
      this.LPBreadcrumbs.LPDetails.LPId
    ]);
  }
  highlightAndNavigateToSearchedTile() {
    //console.log(document.getElementById(this.highlightedContentId));
    let ele = document.getElementById(this.highlightedContentId);
    //console.log('this.highlightedContentId', this.highlightedContentId);
    //console.log('ele.offsetTop', ele.offsetTop);
    //console.log('ele.top', ele.getBoundingClientRect().top);
    if (ele.offsetTop > 300) {
      document.getElementById('scrollable-content').scrollTop = ele.offsetTop - 100 - 20 * Math.floor(ele.offsetTop / 200);
      //ele.getBoundingClientRect().top;
    } else if (ele.getBoundingClientRect().top < 0) {
      if (ele.offsetTop < 300) {

        document.getElementById('scrollable-content').scrollTop = 0;
      }
      else {
        document.getElementById('scrollable-content').scrollTop = Math.ceil(ele.offsetTop)
      }

    }
  }
  removeHighlightStyle(event) {
    this.highlightedContentId = '';
    this.addedLinkClick = false;
    if (event) {
      this.router.navigate([], { replaceUrl: true });
    }
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions;
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.LPPackageCompSubscriptions) {
      let subscriber = this.LPPackageCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
}
