import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-chrome-browser-suggestion',
  templateUrl: './chrome-browser-suggestion.component.html',
  styleUrls: ['./chrome-browser-suggestion.component.scss']
})
export class ChromeBrowserSuggestionComponent implements OnInit {
  message: string;
  constructor( private dialogRef: MatDialogRef<ChromeBrowserSuggestionComponent>) {
    this.message = 'Shoshin School works best on Chrome browser v80.0.0.0 and above. We suggest you to visit Shoshin School on Chrome.';
   }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
