<mat-dialog-content>
  <div class="closePopupBtn" (click)="closePopup()">
    <!-- <mat-icon class="floatRight close-icon" (click)="close()">close</mat-icon> -->
    <button class="mat-button mat-icon-button" >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="center">
    <div class="popup-title">
      <div class="heading">
        {{ "Learning Path Creation Error" }}
      </div>
    </div>
    <div class="msg" [innerHTML]="msg"></div>
    <div class="action-btn-holder">
      <button (click)="closePopup()" class="action-button">OK</button>
    </div>
  </div>
</mat-dialog-content>

  