import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { CanDeactivate } from '@angular/router';
import { QuizComponent } from './quiz/quiz.component';
@Injectable({
  providedIn: 'root'
})
export class QuizService {
  _baseURL: string;
  quizSubmitPayload: any;

  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
    // this.quizSubmitPayload = <BehaviorSubject<any>>new BehaviorSubject(null);
  }

  updateAttempts(entityId, entityType, courseDetails) {
    if (courseDetails.IsProject) {
      var repoType = 'Project';
      const Url =
        this._baseURL +
        'UpdateAttempts/' +
        repoType +
        '/' +
        entityId +
        '/' +
        entityType +
        '/' +
        courseDetails.ProjectId;
      return this._http.get<any>(Url);
    } else if (courseDetails.IsAccount) {
      var repoType = 'Account';
      const Url =
        this._baseURL +
        'UpdateAttempts/' +
        repoType +
        '/' +
        entityId +
        '/' +
        entityType +
        '/' +
        courseDetails.AccountId;
      return this._http.get<any>(Url);
    } else {
      var repoType = 'Yorbit';
      const Url =
        this._baseURL +
        'UpdateAttempts/' +
        repoType +
        '/' +
        entityId +
        '/' +
        entityType +
        '/' +
        '0';
      return this._http.get<any>(Url);
    }
  }

  getQuizByCourseId(entityId, entityType, courseDetails) {
    if (courseDetails.IsProject) {
      const Url =
        this._baseURL +
        'ProjectQuiz/' +
        entityId +
        '/' +
        entityType +
        '/' +
        courseDetails.ProjectId;
      return this._http.get<any>(Url);
    } else if (courseDetails.IsAccount) {
      const Url =
        this._baseURL +
        'AccountQuiz/' +
        entityId +
        '/' +
        entityType +
        '/' +
        courseDetails.AccountId;
      return this._http.get<any>(Url);
    } else {
      const Url = this._baseURL + 'Quiz/' + entityId + '/' + entityType;
      return this._http.get<any>(Url);
    }
  }

  submitQuiz(answers, courseDetails) {
    if (courseDetails.IsProject) {
      const Url =
        this._baseURL + 'ProjectQuiz/Result/' + courseDetails.ProjectId;
      return this._http.post<any>(Url, answers);
    } else if (courseDetails.IsAccount) {
      const Url =
        this._baseURL + 'AccountQuiz/Result/' + courseDetails.AccountId;
      return this._http.post<any>(Url, answers);
    } else {
      const Url = this._baseURL + 'Quiz/Result';
      return this._http.post<any>(Url, answers);
    }
  }

  mandatoryDeclaration(badgeId) {
      const Url = this._baseURL + 'Quiz/UpdateYorbitBadgeandDeclarationStatus/'+badgeId;
      return this._http.post<any>(Url, {"badgeId":badgeId});
  }
  getMandatoryDeclarationPending() {
    const Url = this._baseURL + 'Quiz/GetDeclarationStatusDetails';
    return this._http.get<any>(Url);
}


  updateQuizSubmitPayload(obj) {
    this.quizSubmitPayload = obj;
  }
  callSubmitOnRefresh(quizPayload) {
    const courseDetails = quizPayload.CourseDetails;
    const answers = quizPayload.Answers;
    if (courseDetails.IsProject) {
      const Url =
        this._baseURL + 'ProjectQuiz/Result/' + courseDetails.ProjectId;
      return this._http.post<any>(Url, answers);
    } else if (courseDetails.IsAccount) {
      const Url =
        this._baseURL + 'AccountQuiz/Result/' + courseDetails.AccountId;
      return this._http.post<any>(Url, answers);
    } else {
      const Url = this._baseURL + 'Quiz/Result';
      return this._http.post<any>(Url, answers);
    }
  }
}

@Injectable()
export class QuizGuardDeactivate implements CanDeactivate<QuizComponent> {
  canExitQuiz: boolean;
  hasAttempts: boolean;
  constructor(private quizService: QuizService) {}
  canDeactivate(component: QuizComponent): boolean {
    if (this.canExitQuiz == false) {
      let msg = 'Are you sure you want to exit quiz?';
      if (this.hasAttempts) {
        msg =
          'Closing the quiz will lead to increment in attempt. Do you want to proceed?';
      }
      let res = confirm(msg);
      if (res) {
        if (localStorage.getItem('QuizRefresh') != null) {
          localStorage.removeItem('QuizRefresh');
        }
        // let quizPayload = this.quizService.quizSubmitPayload;
        // if (quizPayload != null) {
        //   this.quizService
        //     .callSubmitOnRefresh(quizPayload)
        //     .toPromise()
        //     .then(
        //       success => {
        //         ////console.log('Quiz Update Success');
        //         return res;
        //       },
        //       error => {
        //         ////console.log('Quiz Update Failes');
        //         return res;
        //       }
        //     );
        // }
      }
      return res;
    }
    if (localStorage.getItem('QuizRefresh') != null) {
      localStorage.removeItem('QuizRefresh');
    }
    return true;
  }
}
