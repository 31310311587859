import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'yorbit-account-content-completion-confirmation',
  templateUrl: './account-content-completion-confirmation.component.html',
  styleUrls: ['./account-content-completion-confirmation.component.scss']
})
export class AccountContentCompletionConfirmationComponent implements OnInit {
  message1: string;
  message2: string;
  constructor(
    private dialogRef: MatDialogRef<
      AccountContentCompletionConfirmationComponent
    >,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.message1 = "Are you sure you want to mark this topic as completed ?  ";
    this.message2 = "Note: Completion flag cannot be reverted. ";
  }

  ngOnInit() {}
  onConfirm() {
    this.dialogRef.close(true);
  }
  later() {
    this.dialogRef.close(false);
  }

  closePopup(){
    this.dialogRef.close(false);
  }
}
