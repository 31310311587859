<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div class="searchDiv">
      <input [(ngModel)]="searchText" type="text" class="textBoxStyle" placeholder="Search by course name">
  </div>
  <div class="font12 scrollHorizontal row floatLeft" fxLayout="column wrap" fxLayoutAlign="start start">
    <div class="courseList font12" *ngFor='let course of displayList | searchFilter: searchText'>
      <div fxLayout="row" fxFlex>
        <input type="checkbox" [disabled]="!course.isEnabled" [checked]="course.isSelected" (change)="updateCheckedOptions(course.value, $event)">      
        {{course.value}}
      </div>
    </div>
  </div>
</div>