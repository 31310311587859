import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'yorbit-quiz-confirmation-popup',
  templateUrl: './quiz-confirmation-popup.component.html',
  styleUrls: ['./quiz-confirmation-popup.component.css']
})
export class QuizConfirmationPopupComponent implements OnInit {
  message1: string;
  constructor(
    private dialogRef: MatDialogRef<QuizConfirmationPopupComponent>
  ) {
    this.message1 = "Are you sure you want to mark this topic as completed ?  ";
  }

  ngOnInit() {}
  onConfirm() {
    this.dialogRef.close(true);
  }
  later() {
    this.dialogRef.close(false);
  }

}
