import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CloudLabService {
  _baseURL: string;

  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
  }

  getCloudLabDetails(courseId) {
    return this._http.get<any>(
      this._baseURL + 'Course/GetLabRequest/' + courseId + '/lab'
    );
  }

  requestCloudLabAccess(payload) {
    return this._http.post<any>(
      this._baseURL +
        'Course/UpdateRequest/' +
        payload.CourseId +
        '/' +
        payload.CloudLabUniqueId +
        '/lab',
      {}
    );
  }
}


// <div *ngIf="false">
// <!-- Cloud Lab Details-->
// <div fxLayout="column" fxLayoutAlign="center center" class="cloud-details-holder">
//   <!-- Cloud lab is not available -->
//   <div class="details-NA" *ngIf="cloud.cloudLabDetails == null" style="border-bottom:none !important">
//     This course does not have a Cloud Lab yet.
//   </div>
//   <!-- Internal Cloud lab is available -->
//   <div class="details" *ngIf="cloud.cloudLabDetails != null">
//     <div class="cloud-lab-name">
//       {{ cloud.cloudLabDetails.CloudLabName }}
//     </div>
//     <!--
//   Cloud lab is not requested yet or if C2 ops team has rejected the request
// -->
//     <div *ngIf="
//     cloud.cloudLabDetails.AccessStatus == '' ||
//     cloud.cloudLabDetails.AccessStatus == 'Access Rejected' ||
//     cloud.cloudLabDetails.AccessStatus == 'Not Yet Approved'
//   " fxLayout="column" fxLayoutAlign="center center">
//       <div class="info">
//         Cloud Lab gives you access to trending technologies for a hands-on experience.
//       </div>
//       <div class="info">Request access now!</div>
//       <div class="icon-holder">
//         <div class="yorbitSprite cloud-access-denied"></div>
//       </div>
//       <div class="action-btn">
//         <button class="disabled" *ngIf="requestCloudAccessProcessing[cloudIndex]" [disabled]="true">
//       Requesting...
//     </button>
//         <button class="buttonRequestCloudAccess" (click)="requestCloudLabAccess(cloud.cloudLabDetails, cloudIndex)" *ngIf="!requestCloudAccessProcessing[cloudIndex]"
//           [disabled]="
//         requestCloudAccessProcessing[cloudIndex] ||
//         disableCloudLabRequestButton[cloudIndex] ||
//         disableOtherCloudLabReqButtons()
//       " [ngClass]="{
//         'disabled':
//           requestCloudAccessProcessing[cloudIndex] ||
//           disableCloudLabRequestButton[cloudIndex] ||
//           disableOtherCloudLabReqButtons()
//       }" title="{{disableCloudLabRequestButton[cloudIndex]? statusMessage[cloudIndex]:''}}">
//       Request the Access to Cloud Lab
//     </button>
//       </div>
//       <div class="request-status" *ngIf="requestCloudAccessError[cloudIndex]">
//         Some error occured. Please try again.
//       </div>
//     </div>
//     <!-- Cloud lab request is sent by the learner -->
//     <div *ngIf="cloud.cloudLabDetails.AccessStatus == 'Access Requested'" fxLayout="column" fxLayoutAlign="center center">
//       <div class="info">
//         We have taken note of your request and the same shall be processed within 2 working days. For further details, please write
//         to Vlabs.Yorbit@mindtree.com.
//       </div>
//       <div class="info">Happy Learning!</div>
//       <div class="action-btn ">
//         <button class="disabled" [disabled]="true">
//             Cloud Lab Access Request Sent
//     </button>
//       </div>
//       <div class="icon-holder">
//         <div class="yorbitSprite cloud-access-waiting"></div>
//       </div>
//     </div>
//     <!-- Cloud lab access is granted -->
//     <div *ngIf="cloud.cloudLabDetails.AccessStatus == 'Access Granted'" fxLayout="column" fxLayoutAlign="center center">
//       <div class="info">
//         This course provides you with real time case studies for a hands-on experience via Cloud Lab. <br /> You have
//         access to Cloud lab for 1 month or 40 hours whichever is early.
//       </div>
//       <div class="info">Happy Learning!</div>
//       <div class="info">Cloud Lab Access</div>
//       <div class="icon-holder">
//         <div class="yorbitSprite cloud-access-granted"></div>
//       </div>
//     </div>
//     <!-- Cloud lab access is expired -->
//     <div *ngIf="cloud.cloudLabDetails.AccessStatus == 'Access Revoked'" fxLayout="column" fxLayoutAlign="center center">
//       <div class="info">
//         Your Cloud Lab access for this course has expired. Please refer HELP for more information.
//       </div>
//       <div class="icon-holder">
//         <div class="yorbitSprite cloud-access-denied"></div>
//       </div>
//     </div>
//     <!-- Cloud lab is completed -->
//     <div *ngIf="
//     cloud.cloudLabDetails.AccessStatus == 'Course Already Completed'
//   " fxLayout="column" fxLayoutAlign="center center">
//       <div class="info">
//         Cannot request Cloud Lab for an already completed course.
//       </div>
//     </div>
//   </div>
//   <!-- External Cloud lab is available -->
//   <div class="details">

//   </div>
// </div>
// </div>