import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadPopupService } from './file-upload-popup.service';
import { FileSaverService } from 'ngx-filesaver';
import { Conentupload101Service } from './../../ao/content-upload-101/conentupload-101.service';
import * as XLSX from 'xlsx';
import { finalize } from 'rxjs';


enum Status {
  Ready = 'ready',
  Submitted = 'submitted',
  Success = 'success',
  Error = 'error',
}

@Component({
  selector: 'yorbit-file-upload-popup',
  templateUrl: './file-upload-popup.component.html',
  styleUrls: ['./file-upload-popup.component.scss']
})
export class FileUploadPopupComponent implements OnInit {
  actionMessage: string;
  fileName: any;
  scormFile: FormData;
  templateDownloadInProgress: boolean;
  excelUploadInProgress: boolean;
  isUploadButtonDisabled: boolean;
  storeData: string | ArrayBuffer;
  secondSheetName: string;
  uploadStatus: Status = Status.Ready;
  downloadStatus: Status = Status.Ready;
  popupData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FileUploadPopupComponent>,
    private _fileUploadPopupService: FileUploadPopupService,
    private _fileSaverService: FileSaverService,
    private _contentUpload101Service: Conentupload101Service
  ) { }

  ngOnInit() {
    this.fileName = 'Select a File';
    this.templateDownloadInProgress = false;
    this.excelUploadInProgress = false;
    this.isUploadButtonDisabled = true;
    this.actionMessage = '';
  }

  downloadTemplate() {
    // this.templateDownloadInProgress = true;
    // this._fileUploadPopupService
    //   .downloadTemplateForUpload()
    //   .then(res => {
    //     this.templateDownloadInProgress = false;
    //     this.downloadExcel(res);
    //   })
    //   .catch(error => {
    //     this.templateDownloadInProgress = false;
    //     this.errorDownload(error);
    //   });
  }

  // downloadExcel(res) {
  //   const blob = new Blob([<any>res.body], {
  //     type: 'application/octet-stream'
  //   });

  //   this._fileSaverService.save(blob, 'UploadTemplate.xlsx');
  //   this.downloadStatus = Status.Success;
  //   this.actionMessage = 'Download Completed';
  //   this.hideActionMessage();
  // }

  // errorDownload(error) {
  //   this.downloadStatus = Status.Error;
  //   const errorMessage = this.data.isCourseSelected
  //     ? 'Download Failed'
  //     : 'Download Failed or there is no data available in internal certificate details.';
  //   this.actionMessage = errorMessage;
  // }

  hideActionMessage() {
    setTimeout(() => {
      this.actionMessage = '';
    }, 3000);
  }

  closePopup() {
    if (this.data.id === 'ScormFileUpload') {
      this.dialogRef.close(this.popupData);
    }
  }

  onFilesAdded(files) {
    this.actionMessage = '';
    if (this.uploadStatus === Status.Success || this.uploadStatus === Status.Error) {
      this.uploadStatus = Status.Ready;
    }

    const file = files[0];
    const size = file.size / (1024 * 1024);

    this.fileName = file.name;

    if (size > 200) {
      this.uploadStatus = Status.Error;
      this.actionMessage = 'File size should be less than 200MB';
      return;
    }
    const extension = file.name.split('.').pop().toLowerCase();
    if (extension === 'zip') {
      this.scormFile = file;
      this.isUploadButtonDisabled = false;
    } else {
      this.uploadStatus = Status.Error;
      this.actionMessage = 'Please upload a zip file';
    }
  }

  upload() {
    this.uploadStatus = Status.Submitted;
    this.actionMessage = 'Validating and uploading the file...';
    if (this.data.id === 'ScormFileUpload') {
      this._contentUpload101Service.validateAndUploadScormFiles(this.data.courseUniqueId, this.scormFile)
        .pipe(
          finalize(() => {
            this.hideActionMessage();
          })
        )
        .subscribe({
          next: (data) => {
            this.popupData = data;
            this.uploadStatus = data[0].IsValid ? Status.Success : Status.Error;
            this.actionMessage = data[0].IsValid ? 'File successfully validated, uploading process will take sometime.' : 'Please upload a valid scorm zip file';
          },
          error: (error) => {
            this.uploadStatus = Status.Error;
            this.actionMessage = 'Error while uploading file, try after sometime';
          }
        });
    }
  }
}

