<mat-dialog-content>
    <!--Close Popup Icon-->
    <div class="closePopupBtn">
        <button class="mat-button mat-icon-button" (click)="closePopup()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <!--Popup Header-->
    <div class="popup-title">
        <div class="heading">
            {{data.title}}
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="popupContent">
        <div class="message1">
            {{message1}}
        </div>
        <div class="message2">
            {{message2}}
        </div>
        <!--Mat Divider-->
        <mat-divider></mat-divider>

        <div class="actionButtonContainer">
            <button class="actionButton action-button" (click)="onConfirm()">Yes</button>
            <button class="actionButton action-button" (click)="later()">No</button>
        </div>
    </div>
</mat-dialog-content>