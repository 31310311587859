import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yorbit-read-more-text',
  templateUrl: './read-more-text.component.html',
  styleUrls: ['./read-more-text.component.scss']
})
export class ReadMoreTextComponent implements AfterViewInit {

 
 
     //maximum height of the container
     @Input() maxHeight: number = 195;
 
     //set these to false to get the height of the expended container 
     public isCollapsed: boolean = false;
     public isCollapsable: boolean = false;
 
     constructor(private elementRef: ElementRef) {
     }
 
     ngAfterViewInit() {
         let currentHeight = this.elementRef.nativeElement.getElementsByTagName('div')[0].offsetHeight;
        //collapsable only if the contents make container exceed the max height
        //console.log(currentHeight)
         if (currentHeight > this.maxHeight) {
             this.isCollapsed = true;
             this.isCollapsable = true;
         }
     }
 
 }