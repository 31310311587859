import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SystemService } from '../../../shared/services/system.service';
import { RiskBrigadeVideoPopupComponent } from '../../risk-brigade-video-popup/risk-brigade-video-popup.component';
@Component({
  selector: 'yorbit-knowledge-hub-tile',
  templateUrl: './knowledge-hub-tile.component.html',
  styleUrls: ['./knowledge-hub-tile.component.scss']
})
export class KnowledgeHubTileComponent implements OnInit {
  @Input('item') item;

  constructor(private _systemSvc:SystemService,
    private _popup: MatDialog) { }

  ngOnInit() {
  }

  gotoArticle(){
    if(this.item.Type==='article' || this.item.Type==='publication'){
      window.open(this.item.URL, '_blank');
    }else {
      this.openVideoPopup();
    }
  }

  openVideoPopup(){
    //console.log(this.item)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
   
      dialogConfig.data = {
        VideoId: this.item.VideoId,
        VideoLink: this.item.URL,
        Title: this.item.Name ,
        VideoType: this.item.VideoType
      };
     //console.log(dialogConfig.data)
    this._popup.open(RiskBrigadeVideoPopupComponent, dialogConfig);
  }

}
