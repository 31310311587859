import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  description: string;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() {
    if (this.data.popup == 'EditScoreCard') {
      this.description = 'This evaluation will be rejected and resent to the evaluator. Do you want to proceed?';
    }
    else if (this.data.popup == 'DeleteSolution') {
      this.description = 'Existing solution will be deleted. Do you want to proceed?';
    }
    else if (this.data.popup == 'SendAlert') {
      this.description = 'Alert will be send to selected' + this.data.requests.length+'requests. Do you want to proceed?';
    }
  }
  onClick(value) {
    this.dialogRef.close(value);
  }
}
