import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AssignContentPopupComponent } from './assign-content-popup/assign-content-popup.component';
import { AssignConfirmationPopupComponent } from './assign-confirmation-popup/assign-confirmation-popup.component';
import { AssignResponsePopupComponent } from './assign-response-popup/assign-response-popup.component';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import { Store } from '@ngrx/store';
import { Globals } from '../../../globals';
import { HeaderService } from '../../../header/header.service';
import { AccountService } from '../../../account/account.service';

@Component({
  selector: 'yorbit-assign-content',
  templateUrl: './assign-content.component.html',
  styleUrls: ['./assign-content.component.scss']
})
export class AssignContentComponent implements OnInit {
  @Input() card: any;
  userRoleAccess$: any;
  hideForAccount: boolean;
  availableRoles: { ACM: boolean; PCM: boolean };

  constructor(
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private _popup: MatDialog,
    private _globals: Globals,
    private headerService: HeaderService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.hideForAccount = false;
    this.availableRoles = {
      ACM: true,
      PCM: true
    };
    if (
      this.card.contentData.AccountPackage === true ||
      this.card.contentData.ProjectPackage === true ||
      this.card.contentData.AccountPackage === 'true' ||
      this.card.contentData.ProjectPackage === 'true'
    ) {
      this.userRoleAccess$ = this.userRoleAccessStore.select(
        fromRoleAccessStore.getRoleAccessList
      );
      this.userRoleAccess$.subscribe(userRole => {
        if (!(userRole.ACM || userRole.PCM)) {
          this.hideForAccount = true;
        } else {
          if (
            this.headerService.accountProjectList &&
            0 < this.headerService.accountProjectList.length
          ) {
            this.checkAccountProjectRole(this.headerService.accountProjectList);
          } else {
            this.accountService
              .getUsersAccountAndProjectList()
              .subscribe(list => {
                this.checkAccountProjectRole(list);
              });
          }
        }
      });
    }
  }
  checkAccountProjectRole(accountProjectList) {
    this.hideForAccount = true;
    this.availableRoles = {
      ACM: false,
      PCM: false
    };
    accountProjectList.forEach(content => {
      if (content.AccountId == this.card.contentData.Account) {
        if (content.IsACM == 'ACM') {
          this.availableRoles = {
            ACM: true,
            PCM: false
          };
          content.ProjectList.forEach(project => {
            if (project.ProjectId == this.card.contentData.Project) {
              if (project.IsPCMOrTM == 'PCM') {
                this.availableRoles.PCM = true;
              }
            }
          });
          this.hideForAccount = false;
        } else {
          content.ProjectList.forEach(project => {
            if (project.ProjectId == this.card.contentData.Project) {
              if (project.IsPCMOrTM == 'PCM') {
                this.availableRoles.PCM = true;
                this.hideForAccount = false;
              }
            }
          });
        }
      }
    });
  }

  openDialog() {
    if (
      this._globals.courseListForShowingLastCompletedDate.includes(
        this.card.contentData.Id
      )
    ) {
      let data = {
        canAssign: false,
        assignData: {
          itemName: this.card.contentData.Name,
          mandatoryLP: ''
        }
      };
      if (this._globals.poshUSACourseId.includes(this.card.contentData.Id)) {
        // data.assignData.mandatoryLP =
        //   'Sexual Harassment Prevention Training - USA';
        data.assignData.mandatoryLP =
            this._globals.newPOSHUSLpName;
      } else if(this._globals.LGBTCourseId.includes(this.card.contentData.Id)){
         data.assignData.mandatoryLP = 'Assigned'
      }    
      else {
        data.assignData.mandatoryLP = this._globals.LpName=='coc'? this._globals.CocLpName:this._globals.BEACLpName;
      }
      this.openResponseDialog(data);
    } else {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'assignDialogContainer';
      dialogConfig.data = this.card.contentData;
      dialogConfig.data.availableRoles = this.availableRoles;

      const dialogRef = this._popup.open(
        AssignContentPopupComponent,
        dialogConfig
      );

      dialogRef.afterClosed().subscribe(response => {
        if ('push' === response.event) {
          this.openConfirmationDialog(response.data);
        }
      });
    }
  }

  openConfirmationDialog(data) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'assignDialogContainer';
    dialogConfig.data = data;

    const dialogRef = this._popup.open(
      AssignConfirmationPopupComponent,
      dialogConfig
    );

    // dialogRef.afterClosed().subscribe(response => {
    //   if ('response' === response.event) {
    //     response.data.canAssign = true;
    //     this.openResponseDialog(response.data);
    //   }
    // });
  }

  openResponseDialog(data) {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'assignDialogContainer';

    dialogConfig.data = data;

    this._popup.open(AssignResponsePopupComponent, dialogConfig);
  }
}
