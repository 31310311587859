<mat-dialog-content >
  <!--class="shareList"-->
  <!--Close Popup Icon-->
  <div class="closeShare">
    <button mat-button mat-icon-button (click)="closeShare()"
    [disabled]="shareState.loading">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!--Course Name-->
  <div class="courseName">{{ data.Title }}</div>

  <!--Mat divider-->
  <mat-divider></mat-divider>

  <!--Search bar-->
  <form *ngIf="!shareState.loading && !shareState.loaded" class="searchBlock">
    <mat-form-field class="searchUserContainer">
      <input
        type="text"
        placeholder="Search People"
        aria-label="Number"
        matInput
        [formControl]="ShareControl"
        [matAutocomplete]="auto"
        [(ngModel)]="searchUserInput"
        (keyup)="searchUser(searchUserInput, $event)"
        [errorStateMatcher]="validateUserAdded"
      />
      <mat-error> {{ UserAddedValidateError }} </mat-error>

      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayEmpty"
        (optionSelected)="userSelectHandler($event)"
      >
        <mat-option *ngFor="let user of suggestionList" [value]="user">
          <yorbit-share-user-profile
            [user]="user"
            (click)="addUserFromSuggestionList(user)"
          ></yorbit-share-user-profile>
        </mat-option>
      </mat-autocomplete>

      <button
        mat-button
        *ngIf="searchUserInput"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="resetUserInput()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>

  <!--Selected user list-->
  <div class="userListContainer" *ngIf="!(0 >= userListToShare.length) && !shareState.loading && !shareState.loaded">
    <div class="userList" *ngFor="let user of userListToShare" fxFlexLayout="row" fxLayoutAlign="center center">
      <diV fxFlex="80">
        <yorbit-share-user-profile
          class="userProfile"
          [user]="user"
        ></yorbit-share-user-profile>
      </diV>
      <div fxFlex="20">
          <button
          class="removeUser"
          mat-button
          mat-icon-button
          (click)="removeUserFromSuggestionList(user)"
          *ngIf="!shareState.loading && !shareState.loaded"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!--Action buttons-->
  <div
    class="action-btn"
    *ngIf="!shareState.loading && !shareState.loaded"
  >
    <button
      class="action-button shareButton"
      (click)="shareToUser()"
      matBadge="{{ userListToShare.length }}"
      [matBadgeHidden]="0 >= userListToShare.length"
      [ngClass]="{'disabled':userListToShare.length === 0}"
    >
      Share
    </button>
    <button class="action-button shareButton" (click)="closeShare()">Cancel</button>
  </div>

  <!--Share status-->
  <div *ngIf="shareState.loading && !shareState.loaded" class="action-btn">
    <div>Sharing...</div>
  </div>
  <div *ngIf="!shareState.loading && shareState.loaded" class="shareStatus">
    {{ shareStatus }}
  </div>
  <mat-divider *ngIf="!shareState.loading && shareState.loaded"></mat-divider>
  <div class="nameListHolder" *ngIf="!shareState.loading && shareState.loaded">
    <div *ngFor="let user of userListToShare; let i=index" class="nameList">
      {{i+1 + "."+ user.displayName}}
    </div>
  </div>
  <div class="action-btn"  *ngIf="!shareState.loading && shareState.loaded">
      <button class="action-button shareButton" (click)="closeShare()">OK</button>
  </div>

  <!--People Count-->
  <mat-divider></mat-divider>
  <div class="peopleCount">
    {{data.ItemType=='FamilyPackage'?'Package':data.ItemType}} shared with {{peopleCount}} associate(s)
  </div>
</mat-dialog-content>
