import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleAccessService {
  config: Ienvironment;

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService
  ) {
    this.config = this._envSvc.getEnvironment();
  }

  getRoles() {
    return this.http
      .get<any>(this.config.apiUrl + 'Users/GetRoles')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getAllLpAcmPcm(){
    return this.http
    .get<any>(this.config.apiUrl + 'Users/GetLpAcmPcm')
    .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

}
