<div fxLayout="column">
    <div class="filterAndPagination">
        <div fxLayout="row" class="paginationParentDiv">
            <div class="paginationSubDiv" fxLayout="row">
                <div class="pagination" fxLayout="row" *ngIf="!isRecordsLoading">
                </div>
            </div>
            <!-- <div class="buttonDiv">
            <div class="reset" fxFlex="200px" fxLayout="column" fxLayoutAlign="center center">
                <button class="resetButton mousePointer" aria-label="Reset" (click)="resetFilter()" fxLayout="row"
                    fxLayoutAlign="center center" fxLayoutGap="10px">
                    <div fxFlexOffset="10px">Reset All Filters</div>
                    <div class="yorbitSprite resetSmallSpriteIcon"></div>
                </button>
            </div>
            <div class="apply-search">
                <button fxFlex fxLayoutAlign="center center" class="dateGoButton apply-search" mat-raised-button
                    aria-label="Go" (click)="applyFilters()">
                    Apply & Search
                </button>
            </div>
        </div> -->
        </div>
    </div>
    <div class="subsection" *ngIf="!showPreloaders && !noDataFound && selectedTab=='externalrequest-actioned'"
        fxLayout="column" fxLayoutAlign="start stretch">
        <div fxFlex fxLayout="row" fxLayoutAlign="start stretch">
            <div class="certification-tabs accepted" [ngClass]="{'selected':selectedTab == 'approved'}"
                (click)="changeSubselection('approved')" fxLayoutAlign="center center" fxFlex="100px">
                Accepted
            </div>
            <div class="certification-tabs denied" [ngClass]="{'selected':selectedTab == 'denied'}"
                (click)="changeSubselection('denied')" fxLayoutAlign="center center" fxFlex="100px">
                Denied
            </div>
        </div>
        <mat-divider fxFlex="1px"></mat-divider>
        <div>
        </div>
    </div>
    <div class="pss-certificate-request" fxLayoutGap="20px" fxLayout="column" *ngIf="!isRecordsLoading">
        <div fxLayout="row wrap" fxLayoutAlign="start start" *ngFor="let request of requests; let index = index">
            <div *ngIf="request.showCourseName" class="course-name-header" fxFlex="100%">
                {{ request.CourseName }}
            </div>
            <div fxFlex="100%">
                <div class="request-details" fxFlex>
                    <div fxFlex="200px" fxLayout="column" class="user-profile" fxLayoutAlign="start stretch">
                        <div class="section request-id" fxLayout="row wrap">
                            <div class="header" fxFlex="80px">Request ID </div>:
                            <div class="value" fxFlex="70px">{{ request.RequestId }}</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="section" fxLayout="column" fxLayoutGap="10px">
                            <div class="name" title="{{ request.Name }}">{{ request.Name }}</div>
                            <div class="mid">
                                <div class="header" fxFlex="80px">PSID</div>
                                <div class="divider-colon">:</div>
                                <div class="value">{{ request.MID }}</div>
                            </div>
                            <div class="mid">
                                <div class="header" fxFlex="80px">Joining Date</div>
                                <div class="divider-colon">:</div>
                                <div class="value">{{ request.JoiningDate | date: "yyyy/MM/dd" }}</div>
                            </div>
                            <div class="mid">
                                <div class="header" fxFlex="80px">Price</div>
                                <div class="divider-colon">:</div>
                                <div class="value" style="width: 100%;">
                                    <p style="margin-top: 0px;width:95%;word-wrap: break-word;">{{ request.Price }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-divider vertical="true"></mat-divider>
                    <div class="section course-details" fxLayout="column" fxLayoutGap="10px" fxFlex>
                        <div fxLayout="row">
                            <div class="header" fxFlex="120px">Course Name</div>
                            <div class="divider-colon">:</div>
                            <div class="value" fxFlex title="{{ request.CourseName }}" fxFlexOffset="5px">
                                <a class="hyperlink" target="_blank"
                                    href="{{environment.getEnvironment().appUrl+'detailsPage/Course/'+ request.Expertise+'/'+ request.CourseId}}">
                                    {{ request.CourseName }}
                                </a>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div class="header" fxFlex="120px">Course ID</div>
                            <div class="divider-colon">:</div>
                            <div class="value" fxFlex title="{{ request.CourseId }}" fxFlexOffset="5px">
                                {{ request.CourseId }}
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div class="header" fxFlex="120px">Academy</div>
                            <div class="divider-colon">:</div>
                            <div class="value" fxFlex title="{{ request.Academy }}" fxFlexOffset="5px">
                                {{ request.Academy }}
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div class="header" fxFlex="120px">Genre</div>
                            <div class="divider-colon">:</div>
                            <div class="value" fxFlex title="{{ request.Genre }}" fxFlexOffset="5px">
                                {{ request.Genre }}
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div class="header" fxFlex="120px">Skill</div>
                            <div class="divider-colon">:</div>
                            <div class="value" fxFlex title="{{ request.Skill }}" fxFlexOffset="5px">
                                {{ request.Skill }}
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div class="header" fxFlex="120px">Course Type</div>
                            <div class="divider-colon">:</div>
                            <div class="value" fxFlex title="{{ request.CourseType }}" fxFlexOffset="5px">
                                {{ request.CourseType }}
                            </div>
                        </div>
                    </div>
                    <mat-divider vertical="true"></mat-divider>
                    <div fxFlex="170px" class="date-details">
                        <div class="section" fxLayout="column" fxLayoutGap="10px">
                            <div class="upload-date" fxLayout="column" fxLayoutGap="5px">
                                <div class="header">Date of Certification:</div>
                                <div>
                                    <div fxFlex [ngClass]="{ fieldEdited: request.IsCertificationDateChanged }">
                                        {{ request.Certification_dt | date: "MM/dd/yyyy" }}
                                    </div>
                                </div>
                            </div>
                            <div class="upload-date" fxLayout="column" fxLayoutGap="5px">
                                <div class="header">Date of Request: </div>
                                <div>
                                    {{ request.RequestedDate | date: "MM/dd/yyyy" }}
                                </div>
                            </div>
                            <div class="upload-date" fxLayout="column" fxLayoutGap="5px">
                                <div class="header">Date of Expiry: </div>
                                <div *ngIf="request.Expiry_Dt == '0001-01-01T00:00:00'">
                                    No Expiry
                                </div>
                                <div *ngIf="request.Expiry_Dt != '0001-01-01T00:00:00'">
                                    {{ request.Expiry_Dt | date: "MM/dd/yyyy" }}
                                </div>
                            </div>
                            <div class="PSS-Score" fxLayout="column" fxLayoutGap="5px" *ngIf="selectedTab!='pending'">
                                <div class="header">Score: </div>
                                <div>
                                    {{ request.PSSScore == null || request.PSSScore == "NULL" ? 'NA' : request.PSSScore}}                                
                                </div>
                              </div>
                            <button *ngIf="request.certificatestatus == 'OnHold'" fxFlex="35px"
                                class="downloadButton mousePointer" aria-label="Reset" fxLayout="row"
                                fxLayoutAlign="center center" fxLayoutGap="15px">
                                <div>On Hold</div>
                            </button>
                        </div>
                    </div>
                    <mat-divider vertical="true"></mat-divider>

                    <div class="section attachment-download" fxFlex="170px" fxLayout="column" fxLayoutGap="10px">
                        <div class="field-label" *ngIf="selectedTab=='pending'">
                            <label class="field-name">Certification Score
                                <!-- <sup class="mandatory-field-indicator">*</sup> -->
                            </label>

                        </div>
                        <!-- <div fxLayout="row" fxLayoutAlign="space-between center"> -->
                        <div fxLayout="row" fxLayoutAlign="space-between center"
                            *ngIf="showCertificationScore[request.RequestId] && selectedTab=='pending'">
                            <!-- <input [(ngModel)]="CertificationScore" class="Score-field"
                placeholder="Score"/>-->
                            <div fxFlex [ngClass]="{ fieldEdited: request.certificateScoreChanged }">
                                {{ request.LearnerScore && request.LearnerScore != "NULL"  ? ((request.LearnerScore) | slice:0:5) :
                                'NA' }}
                            </div>
                            <div fxFlex="25px" class="yorbitSprite editSpriteIcon mousePointer"
                                (click)="editScore(tileSetup.requestInProgress, request.RequestId, index)">
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center"
                            *ngIf="!showCertificationScore[request.RequestId] && selectedTab=='pending'">
                            <div>
                                <input [(ngModel)]="request.LearnerScore" matInput value="{{request.LearnerScore}}" class="score-change" style="width:80%">
                            </div>
                            <div *ngIf="!showCertificationScore[request.RequestId]" fxFlex="25px" class="yorbitSprite roundedCrossSpriteIcon mousePointer" (click)="scoreChange(request.RequestId, index)"></div>
                        </div>
                        <div class="mandatory-indicator" *ngIf="ScoreErrorMsg[request.RequestId] && showCertificationScore[request.RequestId] && request.LearnerScore != ''">
                            {{ScoreErrorMsg[request.RequestId]}}
                        </div>
                       
                        <!-- <div *ngIf="!ScoreValidate"class="mandatory-indicator">
                            Please enter a valid score
                          </div> -->
                        <div class="header">Attachment</div>
                        <div class="download-attachement" fxFlex="50px" fxLayout="column" fxLayoutAlign="start stretch">
                            <button fxFlex="35px" class="downloadButton mousePointer" aria-label="Reset"
                                (click)="downloadCertificate(request.filepath)" fxLayout="row"
                                fxLayoutAlign="center center" fxLayoutGap="15px">
                                <div>Download</div>
                                <div class="mousePointer yorbitSprite downloadSpriteIconWhite "></div>
                            </button>
                        </div>
                        <div *ngIf="request.Remarks&&request.Remarks.trim() != ''">
                            <div class="remarksHeader">Comments </div>
                            <div class="remarksDiv">{{request.Remarks.trim()}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="request-actions" *ngIf="selectedTab=='pending'" fxLayout="column" fxLayoutGap="15px"
                    fxFlexOffset="20px" fxFlex>
                    <div class="CommentsHeader">Comments: </div>
                    <textarea fxFlex="140px" placeholder="Write comments" [ngClass]="{
          mouseDisabled:
            tileSetup.requestInProgress[request.RequestId] || disableOtherRequest
        }" [disabled]="
          tileSetup.requestInProgress[request.RequestId] || disableOtherRequest
        " [(ngModel)]="tileSetup.textAreaInput[request.RequestId]"></textarea>
                    <div class="action-buttons" fxFlex="30px" fxLayoutGap="15px" F fxLayoutAlign="end end">
                        <button class="accept" fxFlex="100px" [ngClass]="{
            active: tileSetup.acceptButtonTitle[request.RequestId] == 'accepted',
            mouseDisabled:
            (request.IsCertificationDateChanged && (
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''
            ) )|| tileSetup.requestInProgress[request.RequestId] ||
              disableOtherRequest ||
              (tileSetup.acceptButtonTitle[request.RequestId] | lowercase) ==
                'failed' || ScoreError[request.RequestId] || !showCertificationScore[request.RequestId] 
          }" title="{{
            (request.IsCertificationDateChanged && (
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''
            ) )
              ? 'Please enter comments to accept the request !'
              : 'Accept This Request'
          }}" [disabled]="
          (request.IsCertificationDateChanged && (
            tileSetup.textAreaInput[request.RequestId] == null ||
            tileSetup.textAreaInput[request.RequestId] == ''
          ) )|| tileSetup.requestInProgress[request.RequestId] ||
            disableOtherRequest ||
            (tileSetup.acceptButtonTitle[request.RequestId] | lowercase) ==
              'failed' || ScoreError[request.RequestId] || !showCertificationScore[request.RequestId] 
          " (click)="approveRequest(request)">
                            {{ tileSetup.acceptButtonTitle[request.RequestId] }}
                        </button>
                        <button class="deny" fxFlex="100px" [ngClass]="{
            active: tileSetup.denyButtonTitle[request.RequestId] == 'denied',
            mouseDisabled:
              tileSetup.textAreaInput[request.RequestId] == null ||
              tileSetup.textAreaInput[request.RequestId] == ''||
              tileSetup.requestInProgress[request.RequestId] ||
              disableOtherRequest ||
              (tileSetup.denyButtonTitle[request.RequestId] | lowercase) ==
                'failed' || ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
          }" title="{{
            (
            tileSetup.textAreaInput[request.RequestId] == null ||
            tileSetup.textAreaInput[request.RequestId] == ''
           )
              ? 'Please enter comments to deny the request !'
              : 'Deny this request'
          }}" [disabled]="
            tileSetup.textAreaInput[request.RequestId] == null ||
            tileSetup.textAreaInput[request.RequestId] == ''||
            tileSetup.requestInProgress[request.RequestId] ||
            disableOtherRequest ||
            (tileSetup.denyButtonTitle[request.RequestId] | lowercase) == 'failed' || ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
          " (click)="denyRequest(request)">
                            {{ tileSetup.denyButtonTitle[request.RequestId] }}
                        </button>
                        <button *ngIf="request.certificatestatus != 'OnHold'" class="accept" fxFlex="100px" [ngClass]="{
                            active: tileSetup.holdButtonTitle[request.RequestId] == 'on hold',
                            mouseDisabled:
                              tileSetup.textAreaInput[request.RequestId] == null ||
                              tileSetup.textAreaInput[request.RequestId] == ''||
                              tileSetup.requestInProgress[request.RequestId] ||
                              disableOtherRequest ||
                              (tileSetup.holdButtonTitle[request.RequestId] | lowercase) ==
                                'failed' || ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
                          }" title="{{
                            (
                            tileSetup.textAreaInput[request.RequestId] == null ||
                            tileSetup.textAreaInput[request.RequestId] == ''
                           )
                              ? 'Please enter comments to Hold the request !'
                              : 'Deny this request'
                          }}" [disabled]="
                            tileSetup.textAreaInput[request.RequestId] == null ||
                            tileSetup.textAreaInput[request.RequestId] == ''||
                            tileSetup.requestInProgress[request.RequestId] ||
                            disableOtherRequest ||
                            (tileSetup.holdButtonTitle[request.RequestId] | lowercase) == 'failed' || ScoreError[request.RequestId] || !showCertificationScore[request.RequestId]
                          " (click)="holdRequest(request)">
                            {{ tileSetup.holdButtonTitle[request.RequestId] }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--preloader section-->
    <div *ngIf="showPreloader" fxFlexFill fxLayoutAlign="center center">
        <app-preloader></app-preloader>
    </div>
    <!--No data info section-->
    <div *ngIf="!showPreloader && requests.length==0 && filtersApplied" class="noRequestsInfo" fxFlexFill
        fxLayoutAlign="center center">
        No requests available.
    </div>
</div>