
import { CourseRequestService } from './course-request.service';
import { Component, OnInit } from '@angular/core';

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CourseRequestPopupComponent } from './course-request-popup/course-request-popup.component';
import { APIResponse } from './course-request.interface';

@Component({
  selector: 'yorbit-course-request',
  templateUrl: './course-request.component.html',
  styleUrls: ['./course-request.component.scss']
})
export class CourseRequestComponent implements OnInit {
  courseReqDialog: MatDialogRef<CourseRequestPopupComponent>;

  taxonomy: any[];
  academyList: any[];
  genreList: any[];
  skillList: any[];
  genreSkillList: any[];
  selectedAcademy: string;
  selectedGenre: string;
  selectedSkill: string;
  description: string;
  isFormValid: boolean;
  reasons: string[];
  isDataLoading: boolean;
  submitInProgress: boolean;
  selectedReason: string;
  skillNotFound: boolean;
  noResultsForAcademy: boolean;
  noResultsForGenre: boolean;
  noResultsForSkill: boolean;
  taxonomyLoading: boolean;

  constructor(
    private _courseRequestSvc: CourseRequestService,
    private _dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.selectedAcademy = '';
    this.selectedGenre = '';
    this.selectedSkill = '';
    this.selectedReason = '';
    this.reasons = ['Self-Development', 'Project Requirement'];
    this.taxonomyLoading = true;
    this.getTaxonomy();
  }

  filterAcademy() {
    this.academyList = [];
    this.taxonomy.forEach(value => {
      this.academyList.push(value.Academy);
    });
    if (this.academyList.length > 0) {
      return this.academyList;
    } else {
      //   this.noResultsForAcademy = true;
      return [];
    }
  }

  filterGenre() {
    this.genreList = [];
    this.genreSkillList = [];
    this.taxonomy.forEach(value => {
      if (this.selectedAcademy !== '') {
        if (
          value.Academy.toLowerCase() === this.selectedAcademy.toLowerCase()
        ) {
          this.genreSkillList = value.GenreSkillList;
        }
      }
    });
    if (this.genreSkillList.length > 0) {
      this.genreSkillList.forEach(value => {
        this.genreList.push(value.Genre);
      });
      // this.noResultsForGenre = false;
      return this.genreList;
    } else {
      //this.noResultsForGenre = true;
      return [];
    }
  }

  filterSkill() {
    this.skillList = [];
    if (this.genreSkillList.length > 0) {
      this.genreSkillList.forEach(value => {
        if (this.selectedGenre !== '') {
          if (value.Genre.toLowerCase() === this.selectedGenre.toLowerCase()) {
            this.skillList = value.Skills;
          }
        }
      });
      if (this.skillList.length > 0) {
        return this.skillList;
      } else {
        // this.noResultsForSkill = true;
        return [];
      }
    } else {
      return [];
    }
  }

  resetFormFields() {
    this.submitInProgress = false;
    this.selectedAcademy = '';
    this.selectedSkill = '';
    this.selectedGenre = '';
    this.skillNotFound = false;
    this.description = '';
    this.selectedReason = '';
    this.validateCourseRequestForm();
  }

  validateCourseRequestForm() {
    //Check if mandatory fields have data
    let count = 0;
    if (
      this.selectedAcademy != undefined &&
      this.selectedAcademy != '' &&
      this.selectedAcademy != null
    ) {
      count++;
    }
    if (
      this.selectedGenre != undefined &&
      this.selectedGenre != '' &&
      this.selectedGenre != null
    ) {
      count++;
    }

    if (this.skillNotFound) {
      count++;
    } else if (!this.skillNotFound) {
      if (
        this.selectedSkill != undefined &&
        this.selectedSkill != '' &&
        this.selectedSkill != null
      ) {
        count++;
      }
    }

    if (
      this.selectedReason != undefined &&
      this.selectedReason != '' &&
      this.selectedReason != null
    ) {
      count++;
    }
    if (
      this.description != undefined &&
      this.description != '' &&
      this.description != null
    ) {
      count++;
    }

    if (count == 5) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  resetGenre() {
    this.selectedGenre = '';
    this.selectedSkill = '';
  }

  resetSkill() {
    this.selectedSkill = '';
  }

  getTaxonomy() {
    this._courseRequestSvc.getTaxonomy().subscribe(
      (response: any) => {
        this.taxonomyLoading = false;
        if (response.AcademyList.length > 0) {
          this.taxonomy = response.AcademyList;
          // this.filterAcademy();
        } else {
          this.taxonomy = [];
        }
      },
      (error: any) => {
        this.taxonomyLoading = false;
        this.taxonomy = [];
      }
    );
  }

  submitCourseRequest() {
    const payload = {
      Academy: this.selectedAcademy,
      Genre: this.selectedGenre,
      Skill: this.selectedSkill,
      IsCantFindSkill: this.skillNotFound,
      Description: this.description,
      Reason: this.selectedReason
    };
    if (this.isFormValid) {
      this.submitInProgress = true;
      this._courseRequestSvc.submitNewCourseRequest(payload).subscribe(
        (response: any) => {
          this.resetFormFields();
          if (response == true) {
            this.openDialog(APIResponse.Success);
          } else if (response == false) {
            this.openDialog(APIResponse.Error);
          }
        },
        error => {
          this.resetFormFields();
          this.openDialog(APIResponse.Error);
        }
      );
    } else {
    }
  }

  openDialog(context) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      context: context
    };
    this._dialog.open(CourseRequestPopupComponent, dialogConfig);
  }
}
