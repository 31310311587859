import { Pipe, PipeTransform } from '@angular/core';
/**
 * use as
 * (your-date-string| date: 'dd/MM/yyyy') for 11.11.1111 format
 * (your-date-string| date: 'dd/MM/yyyy') for 11.11.1111 format
 */
@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  transform(dateInput: any, format: any): any {
    const date = new Date(dateInput);
    const monthIndex = date.getMonth();
    const datedd = date.getDate();
    const fullYear = date.getFullYear();
    switch (format) {
      case 'dd/MMM':
        const monthArray = [
          'JAN',
          'FEB',
          'MAR',
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC'
        ];
        if (0 > monthIndex || 11 < monthIndex) {
          return 'NA';
        } else {
          return datedd + ' ' + monthArray[monthIndex];
        }
      case 'dd/mm/yyyy':
        return datedd + '/' + (monthIndex + 1) + '/' + fullYear;
      case 'dd/MM/yyyy':
        let dd = '0';
        let mm = '0';
        if (date.getDate() < 10) {
          dd = '0' + date.getDate();
        } else {
          dd = date.getDate().toString();
        }
        if (date.getMonth() + 1 < 10) {
          mm = '0' + (date.getMonth() + 1);
        } else {
          mm = (date.getMonth() + 1).toString();
        }
        return dd + '/' + mm + '/' + fullYear;
      case 'MM/dd/yyyy':
        let d = '0';
        let m = '0';
        if (date.getDate() < 10) {
          d = '0' + date.getDate();
        } else {
          d = date.getDate().toString();
        }
        if (date.getMonth() + 1 < 10) {
          m = '0' + (date.getMonth() + 1);
        } else {
          m = (date.getMonth() + 1).toString();
        }
        return m + '/' + d + '/' + fullYear;
      default:
        return (
          date.getDate() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getFullYear()
        );
    }
  }
}
