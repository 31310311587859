import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yorbit-related-video',
  templateUrl: './related-video.component.html',
  styleUrls: ['./related-video.component.scss']
})
export class RelatedVideoComponent implements OnInit {
  @Input('videoData') videoData: any;

  constructor() {}

  ngOnInit(): void {}
}
