import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from "lodash"
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { AoModule } from '../../../ao.module';
import { ShareContentPopupService } from '../../../../shared/card-feature/share-content/share-content-popup/share-content-popup.service';
import { ContentTileBrowseService } from '../../../../shared/content-tiles/content-tile-browse/content-tile-browse.service';
import { ShareContentService } from '../../../../shared/card-feature/share-content/share-content.service';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'yorbit-search-evaluator-popup',
  templateUrl: './search-evaluator-popup.component.html',
  styleUrls: ['./search-evaluator-popup.component.scss']
})
export class SearchEvaluatorPopupComponent implements OnInit {
  searchUserInput: string;
  suggestionList: any;
  userListToShare: any[];
  validateUserAdded: ErrorStateMatcher = {
    isErrorState: (control: FormControl) => {
      return this.UserAddedValidateFail||this.noSuggestionError;
    }
  };
  UserAddedValidateFail: any;
  UserAddedValidateError: string;
  shareStatus: string;
  peopleCount: number;
  peopleCountMsg: string;
  limitList: number;
  noteForUser: string;
  initialLoad: boolean;
  noSuggestionError: boolean;
  popupTitle: string;

  constructor(
    public dialogRef: MatDialogRef<AoModule>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _shareContentPopupService: ShareContentPopupService,
    private _shareContentService: ShareContentService,
    private mediaObserver: MediaObserver,
    private _contentTileBrowseService: ContentTileBrowseService
  ) {}

  ShareControl = new FormControl();

  ngOnInit() {
    this.userListToShare = [];
    this.UserAddedValidateFail = false;
    this.noSuggestionError=false;
    this.popupTitle='Select Evaluator';
    if(this.data.type=='contentOwner'){
      this.popupTitle='Select Content Owner';
    }
    else if(this.data.type == 'lopmspoc') {
      this.popupTitle='Select LOPM SPOC';
    }
    this.loadList();
  }

  loadList() {
    //type,list
    this.initialLoad=false;
    if(this.data.list&&null!=this.data.list&&""!=this.data.list&&_.isEmpty({})!=this.data.list&&0!=this.data.list.length){
    this.initialLoad=true;
    }
    switch (this.data.type) {
      case 'classroom':
        this.limitList = 1;
        this.noteForUser = 'Note: You can choose only one Evaluator at a time';
        this.getUser(this.data.list);
        break;
      case 'project':
        this.limitList = 1;
        this.noteForUser = 'Note: You can choose only one Evaluator at a time';
        this.getUser(this.data.list);
        break;
      case 'assessment':
        this.limitList = 5;
        this.noteForUser = 'Note: You can choose upto 5 Evaluators';
        this.data.list.forEach(element => {
          this.getUser(element.evaluatorEmail);
        });
        case 'contentOwner':
        this.limitList = 1;
        this.noteForUser = '';
        this.getUserbyMid(this.data.list);
        break;

        case 'lopmspoc':
        this.limitList = 1;
        this.noteForUser = '';
        this.getUserbyMid(this.data.list);
        break;

      default:
        break;
    }
  }
  getUser(userMail) {
    if(userMail&&null!=userMail&&""!=userMail){
      this._shareContentPopupService
      .getUserListByMail(userMail)
      .then((response: any) => {
        this.addUserFromSuggestionList(response.value[0]);
      });
    }
  }

  getUserbyMid(mid) {
    if(mid&&null!=mid&&""!=mid){
      this._shareContentPopupService
      .getUserListByMailId(mid)
      .then((response: any) => {
        this.addUserFromSuggestionList(response.value[0]);
      });
    }
  }
  searchUser(user, event) {
    let keyEventCheck = true;
    this.UserAddedValidateFail = false;
    if (
      1 !== event.key.length &&
      'Backspace' !== event.key &&
      'Delete' !== event.key
    ) {
      keyEventCheck = false;
    }
    if (
      user.length >= 3 &&
      (keyEventCheck ||
        this.mediaObserver.isActive('xs') ||
        this.mediaObserver.isActive('sm'))
    ) {
      this._shareContentPopupService
        .getSuggestionList(user)
        .then((response: any) => {
          if(response&&0<response.value.length){
            this.suggestionList = response.value;
            this.noSuggestionError=false;
          }else{
            this.noSuggestionError=true;
            this.suggestionList = [];
          }
        });
    }
    if ('Enter' === event.key) {
      this.addUserFromSuggestionList(user);
    }
  }

  addUserFromSuggestionList(user) {
    this.resetUserInput();
    if(1===this.limitList){
      this.userListToShare=[];
    }
    const userlist = this.userListToShare;
    const userPresentInList = userlist.filter(
      item => item.userPrincipalName === user.userPrincipalName
    );
    if (0 === userPresentInList.length) {
      this.userListToShare = [...this.userListToShare, user];
    } else {
      this.UserAddedValidateFail = true;
      this.UserAddedValidateError = 'User has already been added';
    }
    if(this.initialLoad){
      if(1===this.limitList){
        this.initialLoad=false;
      }else{
        if(this.userListToShare.length===this.data.list.length){
          this.initialLoad=false;
        }
      }
    }
  }
  removeUserFromSuggestionList(user) {
    const userlist = this.userListToShare;
    this.userListToShare = userlist.filter(item => item !== user);
  }

  resetUserInput() {
    this.suggestionList = [];
    this.searchUserInput = '';
    this.UserAddedValidateFail = false;
  }

  shareToUser() {
    if (0 < this.userListToShare.length) {
      this.dialogRef.close(this.userListToShare);
    } else {
      this.UserAddedValidateFail = true;
      this.UserAddedValidateError = 'List is empty';
    }
  }

  closeShare() {
    this.dialogRef.close(null);
  }

  displayEmpty() {
    return null;
  }

  userSelectHandler(event) {
    event.option.deselect();
  }
}
