<div>
  <div class="reportsHeader">
      <button mat-icon-button (click)="dashboardList.toggle()">
          <mat-icon>menu</mat-icon></button>Shoshin School Reports</div>

  <mat-sidenav-container class="" autosize>
      <mat-sidenav #dashboardList [mode]="dashboardSlidermode" opened class="dashboardNavBar"
                   fixedInViewport="false" fixedTopGap="0"
                   fixedBottomGap="0" (keydown.escape)="dashboardList.close()">
                   <div class="groupHeader" *ngFor="let group of groupList">
                     <div class="groupTitle">
                       {{group.name}}
                     </div>
                      <div class="dashboardTitle" *ngFor="let dashboard of group.dashboard"
                      (click)="loadDashboard(dashboard)"
                      [ngClass]="{'selectedDashboard': selectedDashboard===dashboard.id}">
                          {{dashboard.displayName}}
                      </div>
                      <mat-divider></mat-divider>
                    </div>
      </mat-sidenav>

      <mat-sidenav-content>
          <div id="pbi-container" class="pbiContainer" *ngIf="!errorOccured"></div>
          <div class="errorMessage" *ngIf="errorOccured">{{errorMessage}}</div>
      </mat-sidenav-content>
    </mat-sidenav-container>

</div>
<div class="popupTransition" *ngIf="reportPopup">
  <div class="reportHeader">
   <div>
     {{reportTitle}}
   </div>
   <button class="closeReport" mat-button mat-icon-button (click)="closeReportPopup()">
     <mat-icon>close</mat-icon>
   </button>
  </div>
  <div id="reportEmbed" class="reportEmbed"></div>
</div>
