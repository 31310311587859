<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="searchDiv">
        <input [(ngModel)]="searchText" type="text" class="textBoxStyle" placeholder="Search by course sub status">
    </div>
    <div class="font12 scrollHorizontal row floatLeft" fxLayout="column wrap" fxLayoutAlign="start start">
<div class="subStatusList font12" *ngFor='let subStatus of displayList | searchFilter: searchText'>
    <div fxLayout="row" fxFlex>
    <input type="checkbox" class="floatLeft" [disabled]="!subStatus.isEnabled" [checked]="subStatus.isSelected" (change)="updateCheckedOptions(subStatus.value, $event)">
    <div>{{subStatus.value}}</div>
</div>
</div>
</div>
