import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileModelDirective } from './file-model.directive';
import { AutofocusDirective } from './autofocus.directive';
import { ChangeLabelDirective } from './change-label.directive';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
@NgModule({
  imports: [CommonModule],
  declarations: [
    FileModelDirective,
    AutofocusDirective,
    ChangeLabelDirective,
    AutocompleteOffDirective
  ],
  providers: [
    FileModelDirective,
    AutofocusDirective,
    ChangeLabelDirective,
    AutocompleteOffDirective
  ],
  exports: [
    FileModelDirective,
    AutofocusDirective,
    ChangeLabelDirective,
    AutocompleteOffDirective
  ]
})
export class DirectivesModule {}
