import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoleBasedLearningStoreService } from '../../../role-based-learning-store/role-based-learning-store.service';
import { RblReportIssuePopupComponent } from '../rbl-report-issue-popup/rbl-report-issue-popup.component';

@Component({
  selector: 'yorbit-role-road-map',
  templateUrl: './role-road-map.component.html',
  styleUrls: ['./role-road-map.component.scss']
})
export class RoleRoadMapComponent implements OnInit {
  empRoleTagging: any;


  constructor(
    private rblStoreService: RoleBasedLearningStoreService,
    private _dialog: MatDialog,
    private router:Router
  ) {
    this.getMindsRBLMapping();
   }

  ngOnInit(): void {
  }

  getMindsRBLMapping() {
    this.rblStoreService.getEmployeeRoleTagging().subscribe(
      empRoleTagging => {
        this.empRoleTagging=empRoleTagging;
      },
      error => {}
    );
  }

  reportIssue() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      RoleTagging: this.empRoleTagging
    };
    this._dialog.open(RblReportIssuePopupComponent, dialogConfig);
  }

  navigateToRBL(type){
    this.router.navigate(['role-based-learning/' + type]);
  }
}
