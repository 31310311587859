import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  aboutUs : Array<string>;
  aboutUsMailLink : string;
  constructor() {
    const data = this.getAboutUs();
    this.aboutUs = data.aboutUs;
    this.aboutUsMailLink = data.aboutUsMailLink;
  }

  ngOnInit() {
  }

  getAboutUs(){
    let data;
    const isCustomer=(-1!==window.location.href.indexOf('external'))
    if (isCustomer) {
        data = {
            "aboutUs": [
                "Raj is into a new project which uses Hadoop. He has given his first successful demo to the customer. A few months earlier, he was a novice to Hadoop and was figuring out a way to acquire this skill. Rather than attend a regular classroom, he chose to learn from the free course available on Cloudera.<br/><br/>",
                "Increasingly we see conventional learning methods giving way to online and virtual training as the preferred way of acquiring new skills. Keeping up with this need is SHOSHIN SCHOOL. <br/><br/>",
                "SHOSHIN SCHOOL is loaded with a wealth of information, up to date with the latest trends and technologies, and equipped with features guaranteed to provide you an experience like never before. SHOSHIN SCHOOL is our very own, home grown Learning Platform- built in LTIMindtree. <br/><br/>",
                "SHOSHIN SCHOOL gives you the flexibility to learn at your pace, place and time. Pave your own learning journey by choosing the courses you wish to learn. Courses cover a wide technology spectrum and include curated videos, in-house created videos and reading material put together by SMEs. <br/><br/>"
            ],
            "aboutUsMailLink": "Reach our team at " + " " + "<a href='mailto:yorbit.feedback@mindtree.com'>yorbit.feedback@mindtree.com</a>" + " " + " for any feedback/ suggestions."
        };
    } else {
        data = {
            "aboutUs": [
                "Raj is into a new project which uses Hadoop. He has given his first successful demo to the customer. A few months earlier, he was a novice to Hadoop and was figuring out a way to acquire this skill. Rather than attend a regular classroom, he chose to learn from the free course available on Cloudera.<br/><br/>",
                "Increasingly we see conventional learning methods giving way to online and virtual training as the preferred way of acquiring new skills. Keeping up with this need is SHOSHIN SCHOOL. <br/><br/>",
                "SHOSHIN SCHOOL is loaded with a wealth of information, up to date with the latest trends and technologies, and equipped with features guaranteed to provide you an experience like never before. SHOSHIN SCHOOL is our very own, home grown Learning Platform - built in LTIMindtree for LTIMindtree. <br/><br/>",
                "SHOSHIN SCHOOL gives you the flexibility to learn at your pace, place and time. Pave your own learning journey by choosing the courses you wish to learn. Courses cover a wide technology spectrum and include curated videos, in-house created videos, reading material, try-it-out problems, assignments and assessments put together by SMEs. <br/><br/>",
                "Collect LearningMiles, earn Badges, share experiences and have fun learning on SHOSHIN SCHOOL – your doorway into a world of possibilities. <br/><br/>"
            ],
            "aboutUsMailLink": "Reach our team at " + " " + "<span><a href='mailto:yorbit.feedback@mindtree.com'>yorbit.feedback@mindtree.com</a></span>" + " " + " for any feedback/suggestions."
        };
    }
    return data;
  }

}
