<mat-dialog-content class="rblCourseViewPopup">
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div>
    <div class="lp-detailed-package-container" fxFlex fxLayout="column"
      [ngClass.xs]="{'lp-detailed-package-container-xs':true}">
      <div class="rblHeader" fxFlex="19px" fxLayout="row" fxLayoutAlign="start center">


      </div>
      <div fxFlex class="courses-container">
        <div class="body setMaxHeight" id="scrollable-content" fxLayoutAlign.xs="start center"
          *ngIf="!pageLoading && pageLoadedSuccessfully && splGrpDetails.length!=0">

          <div *ngFor="let categoryObj of coursesList">
            <div class="category">
              {{categoryObj.Category}}
            </div>

            <div>
              <div fxFlex.xs="300px"
                [ngClass]="{'highlight-contenttile':item.ItemType+item.ItemId==highlightedContentId}"
                [id]="item.ItemType+item.ItemId" fxFlex.gt-xs="224px" *ngFor="let item of categoryObj.Courses"
                class="content-tile-holder" fxLayout.gt-xs="column">

                <!--
                  <yorbit-content-tile-lp fxFlex.xs="224px" fxFlex.gt-xs="300px" [card-details]='item'
                  (click)="navigateTo(item)"></yorbit-content-tile-lp>
                -->
                <yorbit-content-tile-browse
                class="mousePointer"
                [cardDetails]="item"
                [featuredIn]="'RBLBrowse'"
                fxFlex="225px"
                fxLayoutAlign="start start"
                fxFlexFill
              >
              </yorbit-content-tile-browse>

              </div>
            </div>
          </div>

        </div>
        <div fxFlexFill *ngIf="pageLoading" fxLayoutAlign="center center">
          <app-preloader></app-preloader>
        </div>
        <div *ngIf="!pageLoading && !pageLoadedSuccessfully" fxFlexFill fxLayoutAlign="center center">
          Error in loading page.Please try again.
        </div>
        <div fxFlexFill *ngIf="!pageLoading && pageLoadedSuccessfully && coursesList.length==0"
          fxLayoutAlign="center center">
          No courses available.
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>
