import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from "@ngrx/store";
import { effectRole} from './store';
import {roleReducer} from './store/reducers/role.reducer'
import { EffectsModule } from '@ngrx/effects';
import { RoleAccessService } from './role-access.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('role',roleReducer),
    EffectsModule.forFeature(effectRole)
  ],
  providers:[RoleAccessService]
})
export class RoleAccessModule {}
