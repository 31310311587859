export const PlayerVarsYoutube = {
  rel: 0,
  iv_load_policy: 3,
  fs: 1,
  autoplay: 0,
  enablejsapi: 1,
  modestbranding: 1
};
export const OptionalParams = {
  CanCaptureProgress: true,
  VideoPlayerId: 'video-player'
};
