<mat-toolbar class="header-container mat-elevation-z2" fxLayout="column">
  <mat-toolbar-row fxLayout="row" fxFlexOrder="1" fxFlex="50px">
    <!-- <div class="navigationbar-title" fxFlex.gt-sm="180px" fxFlex.lt-md="170px" fxLayout="row" fxLayoutAlign="start center"> -->
    <div class="navigationbar-title" fxFlex.gt-sm="140px" fxFlex.lt-md="170px" fxLayout="row"
      fxLayoutAlign="start center">
      <div class="navigation-menu" fxFlex="40px" fxLayoutAlign="center center" fxShow.lt-md="true" fxHide.gt-sm="true"
        (click)="openSideNav.emit()">
        <mat-icon>menu</mat-icon>
      </div>
      <!-- <div class="title" [ngStyle]="{ 'height.em': 1.5 }" fxLayout="column"> -->
      <div class="title" fxLayout="column">
        <a fxFlex [routerLink]="['']" fxLayoutAlign="center center">
          <!-- <img [ngStyle.lt-md]="{ width: '6em', height: '1.2em' }" src="../../assets/images/Yorbit-Identity.png" /> -->
          <img [ngStyle.lt-md]="{ width: '6em'}" [ngStyle]="{'width': '155px'}"
            src="../../assets/images/Yorbit-Identity.png" />
        </a>
      </div>
    </div>
    <div class="explore-browse-button mousePointer" fxFlexOffset="25px" fxFlex="120px" fxShow.lt-md="false"
      fxShow.gt-sm="true" fxLayoutAlign="center stretch" fxLayout="column" fxFlexAlign="stretch"
      [matMenuTriggerFor]="browseView" #exploreMenuTrigger="matMenuTrigger" title="Explore Courses On Shoshin School">
      <button fxFlex="28px" fxLayoutAlign="center center" class="mousePointer">
        <div fxFlexOffset="10px">Explore</div>
        <mat-icon fxFlexOffset="5px">apps</mat-icon>
      </button>
    </div>
    <div class="search-holder" fxFlexOffset="15px" fxFlexOffset.sm="10px" fxFlex="300px" fxFlex.sm="220px"
      fxHide.xs="true" [fxShow.gt-xs]="true" fxLayout="row" fxLayoutAlign="start center">
      <input class="searchInputBox" (keypress)="keyDownFunction($event)" [(ngModel)]="search" fxFlex="300px"
        fxFlex.sm="220px" type="text" placeholder="Search for a course/package" title="Search for a course/package" />
      <div fxFlex="20px" fxFlexOffset="-30px" class="preloader-section" *ngIf="preloaderForSearch">
        <!-- <div class="searchPreloader">
          <div class="preloaderContainerGeneric">
            <div class="Box1"></div>
            <div class="Box2"></div>
          </div>
        </div> -->
        <div class="preloader-chasing-squares-ForSearch">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <mat-icon class="close-icon mousePointer" fxFlex="20px" fxFlexOffset="-30px"
        *ngIf="hideSearchIcon && !preloaderForSearch" (click)="hideSearchIcon = false; search = ''"
        title="Clear Search Box">close</mat-icon>
      <mat-icon class="searchIcon mousePointer" fxFlex="20px" fxLayout="row" fxFlexOffset="-30px"
        fxLayoutAlign="center center" *ngIf="!hideSearchIcon && !preloaderForSearch" (click)="doSearch()"
        title="Search">
        search
      </mat-icon>
    </div>



    <div class="action-items-menu" fxFlex fxLayout="row" fxLayoutAlign="end center">
      <button mat-button fxFlex="35px" fxLayout="row" fxLayoutAlign="center center" [fxShow.xs]="!hideSearchIcon"
        fxShow.gt-xs="false" (click)="hideSearchIcon = true">
        <mat-icon>search</mat-icon>
      </button>
      <!--Functions page icon-->
      <!-- <button fxFlex fxLayout="row" fxHide.lt-sm="true" class="sparkLink" (click)="redirectToSpark()" fxLayoutAlign="center center"
          title="Click to Explore Harvard Manage Mentor Spark" *ngIf="IsC7nabv === true">
          <img class="sparkImg" src="../../assets/images/HMM_Spark_logo.png" />
        </button>
        <button fxFlex fxLayout="row" fxHide.lt-sm="true" class="sparkLink" (click)="redirectToHMM()" fxLayoutAlign="center center"
          title="Click to Explore Harvard Manage Mentor" *ngIf="isC7nbelow === true">
          <img class="HMMImg" src="../../assets/images/HMM-logo.jpg" />
        </button> -->

      <div *ngIf="false"
        [ngClass]="{'borderAnimation-FutureSkillPage': isFutureSkillPage, 'borderAnimation': !isFutureSkillPage}"
        title="Click to view content from NASSCOM's Future Skills" fxLayout="column" fxHide fxShow.gt-xs="true"
        ngAnimatedBorderWrapper [ngStyle.gt-xs]="{'margin-right':'10px'}">
        <a fxFlex (click)="openFutureSkill()" fxLayoutAlign="center center">
          <img ngAnimatedBorder class="futureSkillButton" src="../../assets/images/Future_Skills_Logo.jpg" />
        </a>
      </div>
      <!-- 
      <button mat-button fxFlex="50px" fxLayout="row" fxLayoutAlign="center center"
        [matMenuTriggerFor]="functionsPageDropdown" *ngIf="!roleList.CUSTOMER" fxHide.lt-md="true" fxShow.gt-sm="true"
        title="Project Caselets">
        <div class="mousePointer yorbitSprite functionsPageIcon"></div>
      </button>
      <mat-menu #functionsPageDropdown="matMenu" xPosition="before" fxLayout="column" [overlapTrigger]="false"
        class="hamburger-menu-comp functionsPageDropdown" fxHide.lt-md="true" fxShow.gt-sm="true">
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" (click)="openProjectCaselets()">
          <div>Project Caselets</div>
        </a>
      </mat-menu> -->
      <!-- account icon -->
      <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxShow.lt-sm="false" fxFlex="45px"
        *ngIf="roleList.CUSTOMER || roleList.ACM || roleList.PCM || roleList.TM" class="noFocus"
        title="Go To Account Page">
        <div class="mousePointer yorbitSprite accountIcon" (click)="goToAccountPage()"></div>
      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxShow.lt-sm="false" fxFlex="45px"
        *ngIf="!roleList.CUSTOMER" [routerLink]="['/learningpath']" class="noFocus" title="Go to My Learning Path">
        <div class="mousePointer yorbitSprite learningPathIcon"></div>
      </div>

      <div mat-button fxFlex="50px" fxLayout="column" fxLayoutAlign="center center" *ngIf="!roleList.CUSTOMER"
        class="noFocus" title="View Shoshin School Notifications">
        <yorbit-bell-notifications fxFlex="35px" fxLayout="column" fxLayoutAlign="center center">
        </yorbit-bell-notifications>
      </div>
      <div class="user-profile positionRelative noFocus mousePointer" mat-button fxLayout="row" fxFlex="50px"
        fxLayoutAlign="center center" fxHide.xs="true" fxShow.gt-xs="true" *ngIf="!roleList.CUSTOMER"
        [routerLink]="['/my-page/', EmpId, 'self']" title="My Profile Page">
        <div [ngClass]="{'imageHolder':userImageFromGraphDetails == null}">
          <mat-icon *ngIf="userImageFromGraphDetails == null">person</mat-icon>
          <img class="user-image" *ngIf="userImageFromGraphDetails != null" fxFlex="35px"
            [src]="userImageFromGraphDetails" />
        </div>

        <div *ngIf="!isCompliant" class="yorbitSprite nonCompliantIcon nonCompliantIconPos"
          title="Learning policy Non-Compliant"></div>
      </div>
      <!-- <button class="overflow-menu" fxFlex="50px" fxLayout="row" fxLayoutAlign="center center" mat-button [matMenuTriggerFor]="overFlowMenu"
        fxShow.lt-md="true" fxHide.gt-sm="true">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #overFlowMenu="matMenu" xPosition="before" fxLayout="column" [overlapTrigger]="false" class="overflow-menu-comp"
        fxShow.lt-md="true" fxHide.gt-sm="true">
        <a mat-menu-item (click)="logOut()">Log out</a>
      </mat-menu> -->
      <button mat-button fxFlex="50px" fxLayout="row" fxLayoutAlign="center center" [matMenuTriggerFor]="hamburgerMenu"
        fxHide.lt-md="true" fxShow.gt-sm="true">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #hamburgerMenu="matMenu" xPosition="before" fxLayout="column" [overlapTrigger]="false"
        class="hamburger-menu-comp hambugerMenu" fxHide.lt-md="true" fxShow.gt-sm="true">

        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/learningpath']"
          *ngIf="!roleList.CUSTOMER">
          <div>Learning Path</div>
        </a>
        <mat-divider *ngIf="!roleList.CUSTOMER"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/browse']">
          <div>Explore</div>
        </a>
        <mat-divider></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" (click)="routeToApprovals('cdm')"
          *ngIf="!roleList.CUSTOMER && roleList.CDM">
          <div>Learning Business Partner</div>
        </a>
        <mat-divider *ngIf="!roleList.CUSTOMER && roleList.CDM"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" (click)="routeToApprovals('rm')"
          *ngIf="roleList.RM">
          <div>Reporting Manager</div>
        </a>
        <mat-divider *ngIf="!roleList.CUSTOMER && roleList.RM"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/content-partner']"
          *ngIf="!roleList.CUSTOMER && roleList.AO">
          <div>Content Partner</div>
        </a>
        <mat-divider *ngIf="!roleList.CUSTOMER && roleList.AO"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/sme']"
          *ngIf="!roleList.CUSTOMER && roleList.SME">
          <div>SME Evaluation</div>
        </a>
        <mat-divider *ngIf="!roleList.CUSTOMER && roleList.SME"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/pss/course-requests']"
          *ngIf="!roleList.CUSTOMER && roleList.PSS">
          <div>PSS</div>
        </a>
        <mat-divider *ngIf="!roleList.CUSTOMER && roleList.PSS"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/c2ops']"
          *ngIf="!roleList.CUSTOMER && roleList.C2OPS">
          <div>L&D Operations</div>
        </a>
        <mat-divider *ngIf="!roleList.CUSTOMER && roleList.C2OPS"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center"
          [routerLink]="['/behavioral-assessment-assignment']" *ngIf="!roleList.CUSTOMER && roleList.BEAS">
          <div>Behavioral Assessment</div>
        </a>
        <!-- Classroom -->
        <mat-divider *ngIf="!roleList.CUSTOMER && roleList.BEAS"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/classroom']"
          *ngIf="!roleList.CUSTOMER && roleList.C2OPS">
          <div>Classroom</div>
        </a>
        <!-- <mat-divider *ngIf="!roleList.CUSTOMER && roleList.C2OPS"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" [routerLink]="['/course-request']" *ngIf="!roleList.CUSTOMER">
          <div>New Course Request</div>
        </a> -->
        <mat-divider *ngIf="!roleList.CUSTOMER"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" *ngIf="!roleList.CUSTOMER"
          (click)="openPopup()">
          <div>Certification Upload</div>
        </a>
        <!--
        <mat-divider *ngIf="!roleList.CUSTOMER"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" *ngIf="!roleList.CUSTOMER"
          [routerLink]="['/powerbireports']">
          <div>PowerBI Reports</div>
        </a>
        -->
        <mat-divider *ngIf="!roleList.CUSTOMER"></mat-divider>
        <a mat-button fxLayout="row" fxFlex="240px" fxLayoutAlign="start center" (click)="logOut()">
          <div>Log Out</div>
        </a>
        <mat-divider></mat-divider>
      </mat-menu>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row fxFlexOrder="2" fxLayout="row" class="header-two" [ngClass.xs]="['xs']" fxFlex="30px"
    fxLayoutAlign="center">

    <ul class="nav navbar-nav">
      <!-- <li *ngIf="IsC4nabv" (click)="goToHMMSparkPage()"><a [class.selected-menu]="currentRoute=='browse/HMM-Spark'">Harvard Business School</a></li> -->
      <li><a [routerLink]="['/diversity-and-inclusion']"
          [class.selected-menu]="currentRoute=='diversity-and-inclusion'">Diversity, Equity & Inclusion</a></li>
      <li *ngIf="aceingLeadershipAssigned==true"><a [routerLink]="['/aceing-leadership']"
          [class.selected-menu]="currentRoute=='aceing-leadership'"> Aceing Leadership
        </a></li>
      <li *ngIf="rblAssignedFlag"><a [routerLink]="['/role-based-learning/rbl']"
          [class.selected-menu]="currentRoute.includes('role-based-learning')">Role Based Learning</a></li>

      <!-- <li *ngIf="IsC4nabv" (click)="goToHMMSparkPage()"><a [class.selected-menu]="currentRoute=='browse/HMM-Spark'">Harvard Business School</a></li> -->
      <li><a [routerLink]="['/environment-and-sustainability']"
          [class.selected-menu]="currentRoute=='environment-and-sustainability'">Environment & Sustainability</a></li>
      <li *ngIf='riskBrigadeAssignedFlag==true'><a [routerLink]="['/riskbrigade/home']"
          [class.selected-menu]="currentRoute.includes('riskbrigade')">Risk Brigade</a></li>
          <li><a [routerLink]="['/our-ai-strategy']"
            [class.selected-menu]="currentRoute=='our-ai-strategy'"> Our AI Strategy
      </a></li>
    </ul>
  </mat-toolbar-row>


  <mat-toolbar-row fxFlex="45px" class="search-holder-xs" [fxShow.xs]="hideSearchIcon" fxHide.gt-xs="true"
    fxFlexLayout="row" fxLayoutAlign="start center">
    <input class="searchInputBox" fxFlexOffset="20px" fxFlex type="text" placeholder="Search here..."
      (keypress)="keyDownFunction($event)" [(ngModel)]="search" />

    <div fxFlex="20px" fxFlexOffset="-30px" class="preloader-section" *ngIf="preloaderForSearch">
      <!-- <div class="searchPreloader">
        <div class="preloaderContainerGeneric">
          <div class="Box1"></div>
          <div class="Box2"></div>
        </div>
      </div> -->
      <div class="preloader-chasing-squares-ForSearch">
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
      </div>
    </div>

    <mat-icon class="close-icon mousePointer clear-text" *ngIf="search.length != 0 && !preloaderForSearch"
      (click)="search = ''" title="Clear Search Box">close</mat-icon>
    <mat-icon class="mousePointer search-icon" fxFlex="20px" fxLayout="row" fxFlexOffset="-30px"
      fxLayoutAlign="center center" *ngIf="!preloaderForSearch" (click)="doSearch()" title="Search">
      search
    </mat-icon>
    <mat-icon class="closetext" fxFlex="25px" (click)="hideSearchIcon = false">close</mat-icon>
  </mat-toolbar-row>
</mat-toolbar>
<mat-menu #browseView="matMenu" xPosition="before" [overlapTrigger]="false" class="browse-menu-comp">
  <yorbit-academy-view [viewMode]="'popover'" [exploreMenuTrigger]="exploreMenuTrigger"></yorbit-academy-view>
</mat-menu>