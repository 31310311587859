import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TopLearnersService {
  config: Ienvironment;
  topLearnersList: any;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.topLearnersList = [];
    // this.topLearnersList = [
    //   {
    //     MID: 'M1032598',
    //     Courses301: 'Test',
    //     Courses201: 'Tst',
    //     Courses101: 'Test',
    //     OrderInList: '2',
    //     Quarter: 'Q4',
    //     Year: '2020',
    //     IsDeleted: false,
    //     Comments:
    //       'In template driven forms, we need to create the model form controls by adding the ngModel directive and the name attribute. Thus, wherever we want Angular to access our data from forms, add ngModel to that tag as shown above. Now, if we have to read the emailid and passwd, we need to add the ngModel across it.'
    //   },
    //   {
    //     MID: 'M1032598',
    //     Courses301: 'Test',
    //     Courses201: 'Tst',
    //     Courses101: 'Test',
    //     OrderInList: '2',
    //     Quarter: 'Q4',
    //     Year: '2020',
    //     IsDeleted: false,
    //     Comments:
    //       'In template driven forms, we need to create the model form controls by adding the ngModel directive and the name attribute. Thus, wherever we want Angular to access our data from forms, add ngModel to that tag as shown above. Now, if we have to read the emailid and passwd, we need to add the ngModel across it.'
    //   },
    //   {
    //     MID: 'M1032598',
    //     Courses301: 'Test',
    //     Courses201: 'Tst',
    //     Courses101: 'Test',
    //     OrderInList: '2',
    //     Quarter: 'Q4',
    //     Year: '2020',
    //     IsDeleted: false,
    //     Comments:
    //       'In template driven forms, we need to create the model form controls by adding the ngModel directive and the name attribute. Thus, wherever we want Angular to access our data from forms, add ngModel to that tag as shown above. Now, if we have to read the emailid and passwd, we need to add the ngModel across it.'
    //   },
    //   {
    //     MID: 'M1032598',
    //     Courses301: 'Test',
    //     Courses201: 'Tst',
    //     Courses101: 'Test',
    //     OrderInList: '2',
    //     Quarter: 'Q4',
    //     Year: '2020',
    //     IsDeleted: false,
    //     Comments:
    //       'In template driven forms, we need to create the model form controls by adding the ngModel directive and the name attribute. Thus, wherever we want Angular to access our data from forms, add ngModel to that tag as shown above. Now, if we have to read the emailid and passwd, we need to add the ngModel across it.'
    //   },
    //   {
    //     MID: 'M1032598',
    //     Courses301: 'Test',
    //     Courses201: 'Tst',
    //     Courses101: 'Test',
    //     OrderInList: '2',
    //     Quarter: 'Q4',
    //     Year: '2020',
    //     IsDeleted: false,
    //     Comments:
    //       'In template driven forms, we need to create the model form controls by adding the ngModel directive and the name attribute. Thus, wherever we want Angular to access our data from forms, add ngModel to that tag as shown above. Now, if we have to read the emailid and passwd, we need to add the ngModel across it.'
    //   }
    // ];
  }
  getTopLearners() {
    let promise = new Promise((resolve, reject) => {
      if (this.topLearnersList.length === 0) {
        this.http
          .get(this.config.apiUrl + 'User/TopLearnersQuarterWise')
          .toPromise()
          .then(list => {
            if (list != null) {
              this.topLearnersList = list;
            } else {
              this.topLearnersList = [];
            }
            resolve(this.topLearnersList);
          });
      } else {
        resolve(this.topLearnersList);
      }
    });
    return promise;
  }

  getTopCoaches() {
    return this.http.get(this.config.apiUrl + 'User/GetTopCoaches').toPromise();
  }
  getTopFacilitators() {
    return this.http.get(this.config.apiUrl + 'User/GetTopFacilitators').toPromise();
  }
}
