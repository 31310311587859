<mat-dialog-content>
  
  <div class="timeboundtextstyle" fxLayout="column">

      <div class="popup-title" >  
        <div class="heading-custom"  fxLayout="row" fxLayoutAlign="center center" >
          <img src="../../../../assets/images/time_bound_learning/timer_icon.png"
           class="timer_icon" 
           [ngStyle]="timeBoundCourseList.length>1?{'width':'20px'} : {'width': '40px'}"/>
          <div class="title" *ngIf="timeBoundCourseList.length==1">Time Bound <br/> Learning!</div>
          <div class="title multipleTilesTitle" *ngIf="timeBoundCourseList.length>1">Time Bound  Learning!</div>
        </div>
      </div>
      <div fxLayout="row" class="content" [class.spinner]="loading"  >
        <div *ngFor="let item of sortedTimeBoundData; let i=index" fxLayout="row" >
          <div  fxLayoutAlign="center center"  >
            <div fxLayout="column" fxLayoutGap="10px" [ngClass]="{ timeUpPopupSingleCourse: timeBoundCourseList.length==1 }" class="timeUpPopup">
              <div class="messageDiv" fxLayout="column" fxLayoutGap="10px">
                <div  [ngClass]="{ 'content-title-hieght': timeBoundCourseList.length>1 }" class="content-title">
                  A time-bound mandatory course “{{item.itemName}}” is waiting for your action
                </div>
                <div *ngIf="!checkExpiryDate(item.dueDate)" class="due-date "  fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{ 'color': checkTimeBoundCourseExpiryDate1(item.dueDate) ? '#E72037':'#394959'}">
                  Due date :   <div class="date" fxLayoutAlign="center center">{{getFormattedDate(item.dueDate)}}</div>
                </div>
                <div   fxLayout="row" fxLayoutAlign="center">
                <span class="expired-on" *ngIf="checkExpiryDate(item.dueDate)"> Expired On :  {{getFormattedDate(item.dueDate)}} </span>
                <!-- <div class="date" *ngIf="checkExpiryDate(item.dueDate)" fxLayoutAlign="center center">{{getFormattedDate(item.dueDate)}}</div> -->
                </div>
                
                  <div class="okButtonDiv">
                    <button class="actionButton action-button okayButton"  [disabled]="loading" (click)="takeAction(item,i)">
                        Take Action
                      </button>
                  </div>
              </div>
            </div>
          </div>
  
          <div class="line" *ngIf="i<timeBoundCourseList.length-1"> </div>
        </div>
        
      </div>
      
     
  </div>
</mat-dialog-content>