import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { LearnerRecognitionComponent } from './learner-recognition/learner-recognition.component';
import { RecognitionProfileComponent } from './learner-recognition/recognition-profile/recognition-profile.component';
import { NasscomModule } from '../shared/nasscom/nasscom.module';
import { GeneralInfoComponent } from '../shared/global-popups/general-info/general-info.component';
import { WfhCoursesComponent } from './wfh-courses/wfh-courses.component';
import { CourseList } from './wfh-courses/wfh-course-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { TopLearnersQuarterWiseModule } from '../shared/top-learners-quarter-wise/top-learners-quarter-wise.module';
import { TopCoachesModule } from '../shared/top-coaches/top-coaches.module';
import { TopFacilitatorsModule } from '../shared/top-facilitators/top-facilitators.module';
import { BannerWithVideoComponent } from './homepage-banner/banner-with-video/banner-with-video.component';
import { BannerWithLogoComponent } from './homepage-banner/banner-with-logo/banner-with-logo.component';
import { BannerWithoutLogoComponent } from './homepage-banner/banner-without-logo/banner-without-logo.component';
import { VideoPlayersModule } from '../shared/video-players/video-players.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ContentTilesModule,
    NgbCarouselModule,
    ReusableUiModule,
    NasscomModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    TopLearnersQuarterWiseModule,
    TopCoachesModule,
    TopFacilitatorsModule,
    NgbModule,
    VideoPlayersModule
  ],
  declarations: [
    HomeComponent,
    LearnerRecognitionComponent,
    RecognitionProfileComponent,
    WfhCoursesComponent,
    BannerWithVideoComponent,
    BannerWithLogoComponent,
    BannerWithoutLogoComponent
  ],

  providers: [CourseList]
})
export class HomeModule {}
