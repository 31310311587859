<mat-dialog-content>
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="noRequestsMessage">
    {{message}}
  </div>
  <div class="action-btn">
    <button (click)="closeDialog()" class="action-button">Ok</button>
  </div>
</mat-dialog-content>