import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContentUploadService } from '../../ao/content-upload/content-upload.service';
import { PssContentApprovalService } from './pss-content-approval.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PssContentUpdatePopupComponent } from './pss-content-update-popup/pss-content-update-popup.component';
import { PssContentActionPopupComponent } from './pss-content-action-popup/pss-content-action-popup.component';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { SystemService } from '../../shared/services/system.service';
import { DownloadBlobPopupComponent } from '../../shared/download-blob-popup/download-blob-popup.component';
import { Conentupload101Service } from '../../ao/content-upload-101/conentupload-101.service';
import { ContentUpload101PopupsComponent } from '../../ao/content-upload-101/content-upload101-popups/content-upload101-popups.component';

@Component({
  selector: 'yorbit-pss-content-approval',
  templateUrl: './pss-content-approval.component.html',
  styleUrls: ['./pss-content-approval.component.scss']
})
export class PssContentApprovalComponent implements OnInit {
  @Input() ContentData: any;
  @Output() gotoCourseSelection = new EventEmitter<any>();

  content: any;
  keys: string[];
  values: any[];
  operationalCourseDetails: any;
  basicCourseData = [];
  CreditSpecial: any;
  StackAndSkill = [];

  infrastructureInterface: { infrastructure: any; infrastructureType: any };
  isEditDiabled: boolean;
  infrastructureValue: { infrastructure: any; infrastructureType: any };
  courseLoginInterface: { courseLogin: any; courseLoginType: any };
  courseLoginValue: {
    CoursesNameForLearningOPMTeam: any[];
    CoursesNameForSelfRegistration: any[];
    LinktoCoursesForLearningOPMTeam: any[];
    LinktoCoursesForSelfRegistration: any[];
    LoginType: any[];
    LoginDetails: any;
  };
  learningMaterialDetails: any[];
  courseCompletionInterface: {
    courseCompletion: any[];
    courseCompletionType: any[];
  };
  courseCompletionValue: {
    courseCompletion: any[];
    courseCompletionType: any[];
  };
  classRoomDetails: any;
  assessmentInterface: {
    assessment: string[];
    assessmentType: { assessmentId: number; title: string }[];
  };
  projectWorkDetails: any;
  textareaEmptyPlaceholder: string;
  contentLoaded: {
    basicData: boolean;
    infrastructure: boolean;
    courseLogin: boolean;
    courseCompletion: boolean;
  };
  loadContentError: {
    basicData: boolean;
    infrastructure: boolean;
    courseLogin: boolean;
    courseCompletion: boolean;
    learningMaterial: boolean;
    classRoom: boolean;
    projectWork: boolean;
    assessment: boolean;
    getAllPssStatus: boolean;
  };
  uploadedFiles: {
    projectWorkEvaluationTemplate: any[];
    projectWorkProjectMaterial: any[];
    classRoomEvaluationTemplate: any[];
    classRoomAssignmentProblemStatement: any[];
    courseCompletionTemplate: any[];
  };
  uploadStatus: {
    projectWorkEvaluationTemplate: any[];
    projectWorkProjectMaterial: any[];
    classRoomEvaluationTemplate: any[];
    classRoomAssignmentProblemStatement: any[];
    courseCompletionTemplate: any[];
  };
  editedStatus: {
    projectWorkEvaluationTemplate: any[];
    projectWorkProjectMaterial: any[];
    classRoomEvaluationTemplate: any[];
    classRoomAssignmentProblemStatement: any[];
    courseCompletionTemplate: any[];
  };
  contentOwnerName: any;
  badgeName: string;
  certificateStatusList: any[];
  certificateSubStatusList: any[];
  certificateSeletedState: { Status: any; SubStatus: any; StatusIndex: number; };
  PssCertStatus: any = null;
  PssCertSubStatus: any = null;
  canUploadFile: {};
  cacheEvaluatorData: any;
  refDocLink: any;
  quizDetails: any;

  constructor(
    private _contentUploadSvc: ContentUploadService,
    private pssContentApprovalService: PssContentApprovalService,
    private contentUploadService: ContentUploadService,
    private _updatePopup: MatDialog,
    private _GraphDataService: GraphDataService,
    private _contentUpload101Service: Conentupload101Service,
    private _SystemService: SystemService
  ) {
    this.textareaEmptyPlaceholder = 'Field cannot be empty';
    this.certificateStatusList = [];
    this.refDocLink = '';
    this.certificateSeletedState = {
      Status: null,
      SubStatus: null,
      StatusIndex: 0
    };
    this.initLoaderAndErrorObj();
    this.uploadStatus = {
      projectWorkEvaluationTemplate: [],
      projectWorkProjectMaterial: [],
      classRoomEvaluationTemplate: ['Upload'],
      classRoomAssignmentProblemStatement: ['Upload'],
      courseCompletionTemplate: []
    };

    this.cacheEvaluatorData = {
      ClassRoom: [],
      Project: []
    }
    this.canUploadFile = {};
  }

  ngOnInit() {
    //this.certificateStatusList = ['Course In-Progress', 'Course completed']
    this.content = null;
    this.CreditSpecial = this.ContentData.CreditSpecial;
    this.isEditDiabled = true;
    this.learningMaterialDetails = [];
    this.uploadedFiles = {
      projectWorkEvaluationTemplate: [],
      projectWorkProjectMaterial: [],
      classRoomEvaluationTemplate: [],
      classRoomAssignmentProblemStatement: [],
      courseCompletionTemplate: ['', '', '', '']
    };
    this.editedStatus = {
      projectWorkEvaluationTemplate: [],
      projectWorkProjectMaterial: [],
      classRoomEvaluationTemplate: [],
      classRoomAssignmentProblemStatement: [],
      courseCompletionTemplate: []
    };

    if (this.ContentData.Expertise == '301') {
      this.content = this.ContentData;
      this._GraphDataService.getUserName(this.ContentData.ContentOwner).subscribe(res => {
        this.pssContentApprovalService.contentOwnerName = res.displayName;

        this._GraphDataService.getUserName(this.ContentData.LearningOPMSPOC).subscribe(res => {
          this.pssContentApprovalService.lopmSPOC = res.displayName;
        }).add(() => {
          this.ContentData.Price = (this.ContentData.Price == null || this.ContentData.Price == "NA" || this.ContentData.Price.trim() == "") ? "NA" : this.ContentData.Price;
          this.setcontentData(this.ContentData, []);
          this.StackAndSkill = this.pssContentApprovalService.getStackAndSkill(this.ContentData);
        });
      });
    }
    else {
      this._contentUploadSvc.getCourseModeOfPayment().subscribe(
        (response: any) => {
          let courseModeOfPaymentList;
          if (response != null && response.length > 0) {
            courseModeOfPaymentList = response;
          } else {
            courseModeOfPaymentList = [];
          }
          this.getContentUpload201Details(this.ContentData.UniqueId, courseModeOfPaymentList);
        },
        (error: any) => {
          this.getContentUpload201Details(this.ContentData.UniqueId, []);
        }
      );
    }
  }

  getContentUpload201Details(courseUniqueId, courseModeOfPaymentList) {
    this._contentUploadSvc
      .getContentUpload201Details(courseUniqueId)
      .toPromise()
      .then((response: any) => {
        this.content = null;
        if (response != null) {
          // console.log(this.content)
          // console.log(this.ContentData)
          this.content = response;
          this.CreditSpecial = this.content.CreditSpecial;
          this._contentUploadSvc.getCourseProcedureReferenceDocument(courseUniqueId)
            .toPromise()
            .then((refDocument: any) => {
              this.refDocLink = refDocument
            })
          this._GraphDataService
            .getUserName(this.content.ContentOwner)
            .toPromise()
            .then(username => {
              this.pssContentApprovalService.contentOwnerName = username.displayName;
              this.setcontentData(response, courseModeOfPaymentList);
            })
            .catch(err => {
              this.pssContentApprovalService.contentOwnerName = this.content.ContentOwner;
              this.setcontentData(response, courseModeOfPaymentList);
            });
        }
      })
      .catch(error => {
        //this.content = null;
        this.LoadDataFailed('basic');
      });
  }

  setcontentData(response, courseModeOfPaymentList) {
    this.pssContentApprovalService.getBadgeDetails(response.Badge).then(badgeDetails => {
      this.basicCourseData = this.pssContentApprovalService.getContent(
        response,
        'basic',
        badgeDetails.BadgeName,
        courseModeOfPaymentList
      );
      this.contentLoaded.basicData = true;
    }).catch(error => {
      this.basicCourseData = this.pssContentApprovalService.getContent(
        response,
        'basic',
        null,
        courseModeOfPaymentList
      );
      this.contentLoaded.basicData = true;
    })
    if (this.ContentData.Expertise != '301') {
      this.setOperationalData();
    }
  }
  setOperationalData() {
    this.getInfrastructureDetails();
    this.getCourseLoginDetails();
    this.setlearningMaterial();
    this.getProofOfCompletionDetails();
    this.getClassRoomDetails();
    this.getProjectWork();
    this.getAssessment();
    this.getPssStatus();
    this.getQuizData();
  }

  getPssStatus() {
    var StatusList = [{ "Status": "Course In-Progress", "SubStatus": "Yet to submit project" },
    { "Status": "Course In-Progress", "SubStatus": "Yet to submit assignment" },
    { "Status": "Course In-Progress", "SubStatus": "Yet to attempt assessment" },
    { "Status": "Course completed", "SubStatus": "Course completed" }]

    StatusList.forEach(StatusObj => {
      let pushed = false;
      if (
        this.certificateStatusList &&
        this.certificateStatusList.length > 0
      ) {
        this.certificateStatusList.forEach(element => {
          if (element.Status === StatusObj.Status) {
            if (!element.SubStatus.includes(StatusObj.SubStatus)) {
              element.SubStatus.push(StatusObj.SubStatus);
            }
            pushed = true;
          }
          else {
            this.certificateStatusList.push({
              Status: StatusObj.Status,
              SubStatus: [StatusObj.SubStatus]
            });
            pushed = true;
          }
        });
      }

      if (!pushed) {
        this.certificateStatusList.push({
          Status: StatusObj.Status,
          SubStatus: [StatusObj.SubStatus]
        });
      }
    });


    if (this.content.CourseCompletionGroup) {
      let index = 0;
      for (let i = 0; i < this.certificateStatusList.length; i++) {
        if (this.content.CourseCompletionGroup.Status == this.certificateStatusList[i].Status) {
          index = i;
        }
      }
      this.PssCertStatus = this.content.CourseCompletionGroup.Status;
      this.PssCertSubStatus = this.content.CourseCompletionGroup.SubStatus;
      this.certificateSeletedState = {
        Status: this.content.CourseCompletionGroup.Status,
        SubStatus: this.content.CourseCompletionGroup.SubStatus,
        StatusIndex: index
      }
    } else {
      this.PssCertStatus = this.certificateStatusList[0].Status;
      this.PssCertSubStatus = this.certificateStatusList[0].SubStatus;
      this.certificateSeletedState = {
        Status: this.certificateStatusList[0].Status,
        SubStatus: this.certificateStatusList[0].SubStatus,
        StatusIndex: 0
      }
    }

  }
  /* setPssStatus(event,type){
    if("Status"==type){
      //console.log('status', event.target.value, type);
      let index=0;
      for (let i = 0; i < this.certificateStatusList.length; i++) {
        if (event.target.value == this.certificateStatusList[i].Status) {
          index = i;
        }
      }
      this.certificateSeletedState={
       Status:event.target.value,
       SubStatus:this.certificateStatusList[index].SubStatus[0],
       StatusIndex:index
      }
    }else{
      //SubStatus
      this.certificateSeletedState.SubStatus = event.target.value;
    }
  } */
  /**
   * Infrastructure  code starts
   */
  getInfrastructureDetails() {
    this.contentUploadService
      .getCourseInfrastructure()
      .toPromise()
      .then(infrastructure => {
        this.contentUploadService
          .getCourseInfrastructureType()
          .toPromise()
          .then(infrastructureType => {
            this.content.Infrastructure = this.content.Infrastructure
              ? this.content.Infrastructure
              : [false, false, false, false, true];
            this.content.InfrastructureType = this.content.InfrastructureType
              ? this.content.InfrastructureType
              : ['', '', '', '', '', '', ''];
            this.setInfrastructureValue(infrastructure, infrastructureType);
            // //console.log(this.infrastructureInterface);
          })
          .catch(error => {
            this.LoadDataFailed('infrastructure');
          });
      })
      .catch(error => {
        this.LoadDataFailed('infrastructure');
      });
  }
  setInfrastructureValue(infrastructure, infrastructureType) {
    this.contentLoaded.infrastructure = false;
    this.infrastructureInterface = {
      infrastructure: [],
      infrastructureType: []
    };
    this.infrastructureValue = {
      infrastructure: [],
      infrastructureType: []
    };
    this.infrastructureInterface = {
      infrastructure: infrastructure,
      infrastructureType: infrastructureType
    };

    this.infrastructureValue = {
      infrastructure: this.CheckAllFalseOrEmpty(this.content.Infrastructure)
        ? [false, false, false, false, true]
        : [...this.content.Infrastructure],
      infrastructureType: [...this.content.InfrastructureType]
    };
    this.contentLoaded.infrastructure = true;
  }

  infrastructureSelected(elementIndex, event) {
    if (elementIndex == '4') {
      for (let index = 0; index < 4; index++) {
        this.infrastructureValue.infrastructure[index] = false;
      }
      this.infrastructureValue.infrastructure[4] = event.checked;
    } else {
      this.infrastructureValue.infrastructure[4] = false;
      this.infrastructureValue.infrastructure[elementIndex] = event.checked;
    }
  }

  saveInfrastructure() {
    let isvalid = true;

    this.infrastructureValue.infrastructure.forEach((infrastructure, key) => {
      if (infrastructure && isvalid) {
        if (key != '4') {
          this.infrastructureInterface.infrastructureType.forEach(
            (infrastructureType, typeKey) => {
              if (infrastructureType.CourseInfrastructureId == key + 1) {
                if (
                  this.infrastructureValue.infrastructureType[typeKey] &&
                  null !=
                  this.infrastructureValue.infrastructureType[typeKey] &&
                  '' != this.infrastructureValue.infrastructureType[typeKey]
                ) {
                } else {
                  isvalid = false;
                }
              }
            }
          );
        }
      }
    });
    return isvalid;
  }

  setInfrastructureTypeNull(infrastructureValue, infrastructureTypeValue) {
    this.infrastructureInterface.infrastructureType.forEach(
      (infrastructureType, typeKey) => {
        if (
          !infrastructureValue[infrastructureType.CourseInfrastructureId - 1]
        ) {
          infrastructureTypeValue[typeKey] = null;
        }
      }
    );
    return infrastructureTypeValue;
  }

  /**
   * Course login code Starts
   */
  getCourseLoginDetails() {
    this.contentUploadService
      .getCourseLogin()
      .toPromise()
      .then(courseLogin => {
        this.contentUploadService
          .getCourseLoginType()
          .toPromise()
          .then(courseLoginType => {
            this.courseLoginInterface = {
              courseLogin: courseLogin,
              courseLoginType: this.updateLoginTypeTitle(courseLoginType)
            };

            this.content.CoursesNameForSelfRegistration = this.content
              .CoursesNameForSelfRegistration
              ? this.content.CoursesNameForSelfRegistration
              : [];
            this.content.LinktoCoursesForSelfRegistration = this.content
              .LinktoCoursesForSelfRegistration
              ? this.content.LinktoCoursesForSelfRegistration
              : [];
            this.content.CoursesNameForLearningOPMTeam = this.content
              .CoursesNameForLearningOPMTeam
              ? this.content.CoursesNameForLearningOPMTeam
              : [];
            this.content.LinktoCoursesForLearningOPMTeam = this.content
              .LinktoCoursesForLearningOPMTeam
              ? this.content.LinktoCoursesForLearningOPMTeam
              : [];
            this.content.LoginType = this.content.LoginType
              ? this.content.LoginType
              : [];

            this.setCourseLoginValue();
          })
          .catch(error => {
            this.LoadDataFailed('courseLogin');
          });
      })
      .catch(error => {
        this.LoadDataFailed('courseLogin');
      });
  }
  updateLoginTypeTitle(data) {
    let updatedResponse = [];
    data.forEach(element => {
      let statusMsg = element.LoginType;
      switch (element.LoginType) {
        case "Link to Course":
          statusMsg = "Link(s) to Course"
          break;
        case "Link to Courses in Vendor Site":
          statusMsg = "Link(s) to Courses in Vendor Site"
          break;
        case "Course Name in Vendor Site":
          statusMsg = "Course Name(s) in Vendor Site"
          break;
        default:
          statusMsg = element.LoginType;
          break;
      }
      element.LoginType = statusMsg;
      updatedResponse.push(element)
    });
    return updatedResponse;
  }
  setCourseLoginValue() {
    this.courseLoginValue = {
      CoursesNameForSelfRegistration: [
        ...this.content.CoursesNameForSelfRegistration
      ],
      LinktoCoursesForSelfRegistration: [
        ...this.content.LinktoCoursesForSelfRegistration
      ],
      CoursesNameForLearningOPMTeam: [
        ...this.content.CoursesNameForLearningOPMTeam
      ],
      LinktoCoursesForLearningOPMTeam: [
        ...this.content.LinktoCoursesForLearningOPMTeam
      ],
      LoginType: [...this.content.LoginType],
      LoginDetails: this.content.LoginDetails
    };
    if (this.CheckAllFalseOrEmpty(this.courseLoginValue.LoginType)) {
      this.content.LoginType = [true, false, false, false];
      this.courseLoginValue.LoginType = [true, false, false, false];
    }
    this.contentLoaded.courseLogin = true;
  }
  courseLoginSelected(elementIndex, event) {
    ////console.log(event);
    for (let index = 0; index < 4; index++) {
      this.courseLoginValue.LoginType[index] = false;
    }
    this.courseLoginValue.LoginType[elementIndex] = event.checked;
  }
  courseLoginChange(index, type, value) {
    /*
    switch (type) {
      case 'loginDetails':
        this.courseLoginValue.LoginDetails = value;
        break;
      case 'loginCourseNameOpm':
        this.courseLoginValue.CoursesNameForLearningOPMTeam[index] = value;
        break;
      case 'loginCourseLinkOpm':
        this.courseLoginValue.LinktoCoursesForLearningOPMTeam[index] = value;
        break;
      case 'loginCourseLinkSelf':
        this.courseLoginValue.LinktoCoursesForSelfRegistration[index] = value;
        break;
      case 'loginCourseNameSelf':
        this.courseLoginValue.CoursesNameForSelfRegistration[index] = value;
        break;
      default:
        break;
    }
    */
  }

  addCourseLoginNameAndLink(type, nameOrLink) {
    if ('self' === type) {
      if (nameOrLink == 'name')
        this.courseLoginValue.CoursesNameForSelfRegistration.push('');
      else if (nameOrLink == 'link')
        this.courseLoginValue.LinktoCoursesForSelfRegistration.push('');
      else if (nameOrLink == 'both') {
        this.courseLoginValue.CoursesNameForSelfRegistration.push('');
        this.courseLoginValue.LinktoCoursesForSelfRegistration.push('');
      }
    } else {
      if (nameOrLink == 'name')
        this.courseLoginValue.CoursesNameForLearningOPMTeam.push('');
      else if (nameOrLink == 'link')
        this.courseLoginValue.LinktoCoursesForLearningOPMTeam.push('');
      else if (nameOrLink == 'both') {
        this.courseLoginValue.CoursesNameForLearningOPMTeam.push('');
        this.courseLoginValue.LinktoCoursesForLearningOPMTeam.push('');
      }
    }
  }

  saveCourseLogin() {
    let isvalid = true;
    if (this.courseLoginValue.LoginType[1]) {
      isvalid =
        this.checkArrayStringNotEmpty(
          this.courseLoginValue.LinktoCoursesForSelfRegistration
        ) &&
        this.checkArrayStringNotEmpty(
          this.courseLoginValue.CoursesNameForSelfRegistration
        );
    } else if (this.courseLoginValue.LoginType[2]) {
      isvalid =
        this.checkStringNotEmpty(this.courseLoginValue.LoginDetails) &&
        this.checkArrayStringNotEmpty(
          this.courseLoginValue.LinktoCoursesForLearningOPMTeam
        ) &&
        this.checkArrayStringNotEmpty(
          this.courseLoginValue.CoursesNameForLearningOPMTeam
        );
    }
    return isvalid;
  }

  /**
   *  Learning Material Starts
   */
  setlearningMaterial() {
    this.learningMaterialDetails = [];
    try {
      if (
        this.content.PreSessionGroup &&
        this.checkStringNotEmpty(
          this.content.PreSessionGroup.PreSessionLearningMaterialTextBox
        ) &&
        this.checkStringNotEmpty(
          this.content.PreSessionGroup.PreSessionLearningMaterialLink
        )
      )
        this.learningMaterialDetails.push({
          name: this.content.PreSessionGroup.PreSessionLearningMaterialTextBox,
          file: this.content.PreSessionGroup.PreSessionLearningMaterialLink,
          title: 'Pre-Session Learning Material'
        });

      if (
        this.content.DuringSessionGroup &&
        this.checkStringNotEmpty(
          this.content.DuringSessionGroup.DuringSessionLearningMaterialTextBox
        ) &&
        this.checkStringNotEmpty(
          this.content.DuringSessionGroup.DuringSessionLearningMaterialLink
        )
      )
        this.learningMaterialDetails.push({
          name: this.content.DuringSessionGroup
            .DuringSessionLearningMaterialTextBox,
          file: this.content.DuringSessionGroup
            .DuringSessionLearningMaterialLink,
          title: 'During Session Learning Material'
        });

      if (
        this.content.PostSessionGroup &&
        this.checkStringNotEmpty(
          this.content.PostSessionGroup.PostSessionLearningMaterialTextBox
        ) &&
        this.checkStringNotEmpty(
          this.content.PostSessionGroup.PostSessionLearningMaterialLink
        )
      )
        this.learningMaterialDetails.push({
          name: this.content.PostSessionGroup
            .PostSessionLearningMaterialTextBox,
          file: this.content.PostSessionGroup.PostSessionLearningMaterialLink,
          title: 'Post-Session Learning Material'
        });
    } catch (error) {
      this.LoadDataFailed('learningMaterial');
    }
  }

  /**
   * Proof Of Course Completion Details
   */
  getProofOfCompletionDetails() {
    this.courseCompletionInterface = {
      courseCompletion: [],
      courseCompletionType: []
    };
    this.courseCompletionValue = {
      courseCompletion: [],
      courseCompletionType: []
    };

    this.contentUploadService
      .getCourseCompletion()
      .toPromise()
      .then(courseCompletion => {
        this.contentUploadService
          .getCourseCompletionType()
          .toPromise()
          .then(courseCompletionType => {
            this.courseCompletionInterface = {
              courseCompletion: courseCompletion,
              courseCompletionType: this.pssContentApprovalService.constructcourseCompletionType(courseCompletionType)
            };
            this.uploadStatus.courseCompletionTemplate = [];
            this.courseCompletionInterface.courseCompletionType.forEach(
              (value: any) => {
                if (0 == (value.Id + 1) % 3) {
                  this.uploadStatus.courseCompletionTemplate[value.Id] =
                    'Upload';
                  this.editedStatus.courseCompletionTemplate[value.Id] = false;
                }
              }
            );
            this.setCourseCompletionValue();

            ////console.log('completion ', this.courseCompletionInterface);
            this.contentLoaded.courseCompletion = true;
          })
          .catch(error => {
            this.LoadDataFailed('courseCompletion');
          });
      })
      .catch(error => {
        this.LoadDataFailed('courseCompletion');
      });
  }
  setCourseCompletionValue() {
    if (this.content.CourseCompletionGroup) {
      this.courseCompletionValue = {
        courseCompletion: [
          ...this.content.CourseCompletionGroup.CourseCompletionFormArray
        ],
        courseCompletionType: [
          ...this.content.CourseCompletionGroup.CourseCompletionTypeFormArray
        ]
      };
    }
  }
  /*
  courseCompletionSelected(elementIndex, event) {
    this.courseCompletionValue.courseCompletion[elementIndex] = event.checked;
  }
  courseCompletionTypeChange(index, value) {
    this.courseCompletionValue.courseCompletionType[index] = value;
  }
*/
  saveCourseCompletion() {
    let isvalid = true;
    ////console.log('hit', this.courseCompletionValue);

    this.courseCompletionValue.courseCompletion.forEach(
      (courseCompletion, key) => {
        if (courseCompletion && isvalid) {
          this.courseCompletionInterface.courseCompletionType.forEach(
            (courseCompletionType, typeKey) => {
              if ((courseCompletionType.CourseCompletionId == key + 1) && ((typeKey + 1) % 3) != 0) {
                if (
                  this.courseCompletionValue.courseCompletionType[typeKey] &&
                  null !=
                  this.courseCompletionValue.courseCompletionType[typeKey] &&
                  '' != this.courseCompletionValue.courseCompletionType[typeKey]
                ) {
                } else {
                  isvalid = false;
                }
              }
            }
          );
        }
      }
    );

    // if (
    //   isvalid &&
    //   this.courseCompletionValue &&
    //   this.courseCompletionValue.courseCompletion[0]
    // ) {
    //   if (
    //     null == this.certificateSeletedState.Status ||
    //     null == this.certificateSeletedState.SubStatus
    //   ) {
    //     isvalid = false;
    //   }
    // }

    return isvalid;
  }

  setCourseCompletionTypeNull(
    courseCompletionValue,
    courseCompletionTypeValue
  ) {
    this.courseCompletionInterface.courseCompletionType.forEach(
      (courseCompletionType, typeKey) => {
        if (
          !courseCompletionValue[courseCompletionType.CourseCompletionId - 1]
        ) {
          courseCompletionTypeValue[typeKey] = null;
        }
      }
    );
    return courseCompletionTypeValue;
  }

  /**
   * ClassRoom functions
   */
  getClassRoomDetails() {
    //this.classRoomDetails = this.content.ClassRoomGroup;
    //Shallow copy
    try {
      this.classRoomDetails = JSON.parse(
        JSON.stringify(this.content.ClassRoomGroup)
      );

      this.classRoomDetails.ClassRoom.forEach((value, key) => {
        this.uploadStatus.classRoomEvaluationTemplate[key] = 'Upload';
        this.uploadStatus.classRoomAssignmentProblemStatement[key] = 'Upload';
        this.editedStatus.classRoomEvaluationTemplate[key] = false;
        this.editedStatus.classRoomAssignmentProblemStatement[key] = false;

        this.setEvaluatorData(value, key, 'classroom');
      });
    } catch (error) {
      this.LoadDataFailed('classRoom');
    }
  }
  setEvaluatorData(data, index, type) {
    const evaluatorNullObj = {
      EvaluatorName: '',
      EvaluatorEmailID: '',
      EvaluatorVendor: ''
    }
    const evaluatorObj = {
      EvaluatorName: data.EvaluatorName,
      EvaluatorEmailID: data.EvaluatorEmailID,
      EvaluatorVendor: data.EvaluatorVendor
    }
    switch (type) {
      case 'classroom':
        if ("Internal" == data.EvaluatorType) {
          this.cacheEvaluatorData.ClassRoom[index] = {
            internal: evaluatorObj,
            external: evaluatorNullObj
          }
        } else {
          this.cacheEvaluatorData.ClassRoom[index] = {
            internal: evaluatorNullObj,
            external: evaluatorObj
          }
        }
        break;
      case 'project':
        if ("Internal" == data.EvaluatorType) {
          this.cacheEvaluatorData.Project[index] = {
            internal: evaluatorObj,
            external: evaluatorNullObj
          }
        } else {
          this.cacheEvaluatorData.Project[index] = {
            internal: evaluatorNullObj,
            external: evaluatorObj
          }
        }
        break;

      default:
        break;
    }
  }
  saveClassRoomDetails() {
    let isvalid = true;
    if (this.classRoomDetails) {
      this.classRoomDetails.ClassRoom.forEach((classroom, key) => {

        if (classroom && isvalid) {
          var checkEvaluatorName = this.checkStringNotEmpty(classroom.EvaluatorName);
          var checkEvaluatorEmailID = this.checkStringNotEmpty(classroom.EvaluatorEmailID);
          // var checkEvaluatorVendor = this.checkStringNotEmpty(classroom.EvaluatorVendor);
          // var checkEvaluator = (classroom.IsAssignmentRequired == "Yes") && checkEvaluatorName && checkEvaluatorEmailID;
          //  && checkEvaluatorVendor;

          // if (
          //   !(this.checkStringNotEmpty(classroom.VendorOrFaultyName) && checkEvaluator)
          // ) {
          //   isvalid = false;
          // }
        }

        /*
        if (classroom && isvalid) {
          if (
            !(
              this.checkStringNotEmpty(classroom.VendorOrFaultyName) ||
              (classroom.IsAssignmentRequired=="Yes"&&
              this.checkStringNotEmpty(classroom.EvaluatorName) &&
              this.checkStringNotEmpty(classroom.EvaluatorEmailID) &&
              this.checkStringNotEmpty(classroom.EvaluatorVendor)
              )
            )
          ) {
            isvalid = false;
          }
        }
        */
      });
    }
    return isvalid;
  }
  /**
   * Project function
   */
  getProjectWork() {
    try {
      this.uploadedFiles.projectWorkEvaluationTemplate.fill(
        '',
        0,
        this.content.ProjectGroup.ProjectWorkDetails.length
      );
      this.uploadedFiles.projectWorkProjectMaterial.fill(
        '',
        0,
        this.content.ProjectGroup.ProjectWorkDetails.length
      );

      this.projectWorkDetails = JSON.parse(
        JSON.stringify(this.content.ProjectGroup)
      );


      this.projectWorkDetails.ProjectWorkDetails.forEach((value, key) => {
        this.uploadStatus.projectWorkEvaluationTemplate[key] = 'Upload';
        this.uploadStatus.projectWorkProjectMaterial[key] = 'Upload';
        this.editedStatus.projectWorkEvaluationTemplate[key] = false;
        this.editedStatus.projectWorkProjectMaterial[key] = false;

        this.setEvaluatorData(value, key, 'project');
      });
    } catch (error) {
      this.LoadDataFailed('projectWork');
    }
  }

  saveProjectWorkDetails() {
    let isvalid = true;
    if (this.projectWorkDetails) {
      this.projectWorkDetails.ProjectEvaluationDetails.forEach(
        (evaluation, key) => {
          if (evaluation && isvalid) {
            if (
              !(
                this.checkStringNotEmpty(evaluation.EvaluatorName) &&
                this.checkStringNotEmpty(evaluation.EvaluatorEmailID) &&
                this.checkStringNotEmpty(evaluation.EvaluatorVendor)
              )
            ) {
              isvalid = false;
            }
          }
        }
      );
    }
    return isvalid;
  }

  /**
   * Assessment function
   */
  getAssessment() {
    this.assessmentInterface = {
      assessment: [
        'Internal Cloud Lab',
        'Vendor Provided Cloud Lab',
        'White Listed Software',
        'Genie Request Software',
        'None of the above'
      ],
      assessmentType: [
        {
          assessmentId: 0,
          title: 'Internal cloud lab'
        },
        {
          assessmentId: 1,
          title: 'Vendor provided cloud lab'
        },
        {
          assessmentId: 2,
          title: 'Name of White listed software'
        },
        {
          assessmentId: 2,
          title: 'Link to download white listed software'
        },
        {
          assessmentId: 3,
          title: 'Name of Software'
        },
        {
          assessmentId: 3,
          title: 'Version of Software'
        }
      ]
    };
  }

  /**
   * Common functions, initiator and loader
   */
  gotoCourseMenu() {
    this.gotoCourseSelection.emit('');
  }
  initLoaderAndErrorObj() {
    this.contentLoaded = {
      basicData: false,
      infrastructure: false,
      courseLogin: false,
      courseCompletion: false
    };
    this.loadContentError = {
      basicData: false,
      infrastructure: false,
      courseLogin: false,
      courseCompletion: false,
      learningMaterial: false,
      classRoom: false,
      projectWork: false,
      assessment: false,
      getAllPssStatus: false
    };
  }
  LoadDataFailed(type) {
    switch (type) {
      case 'basic':
        this.loadContentError.basicData = true;
        this.content = null;
        break;
      case 'infrastructure':
        this.loadContentError.infrastructure = true;
        break;
      case 'courseLogin':
        this.loadContentError.courseLogin = true;
        break;
      case 'courseCompletion':
        this.loadContentError.courseCompletion = true;
        break;
      case 'learningMaterial':
        this.loadContentError.learningMaterial = true;
        break;
      case 'classRoom':
        this.loadContentError.classRoom = true;
        break;
      case 'projectWork':
        this.loadContentError.projectWork = true;
        break;
      case 'assessment':
        this.loadContentError.projectWork = true;
        break;
      case 'getAllPssStatus':
        this.loadContentError.getAllPssStatus = true;
        break;

      default:
        break;
    }
  }
  checkArrayStringNotEmpty(arrayList) {
    let isvalid = true;
    if (arrayList && 0 < arrayList.length) {
      arrayList.forEach(element => {
        if (isvalid) isvalid = this.checkStringNotEmpty(element);
      });
    } else {
      isvalid = false;
    }
    return isvalid;
  }
  checkStringNotEmpty(strValue) {
    return strValue && null != strValue && '' !== strValue ? true : false;
  }
  trackByFunction(index, item) {
    return index;
  }
  fileUpdate(entity, type, index) {
    if (this.canUploadFile[type] != undefined) {
      this.canUploadFile[type][index] = true;
    } else {
      this.canUploadFile[type] = {};
      this.canUploadFile[type][index] = true;
    }
    const fileEntity = entity.length == 0 ? null : entity[0];
    const uploadedFileSize =
      fileEntity == null ? 0 : fileEntity.size / (1024 * 1024);
    const extension = fileEntity.name
      .split('.')
      .pop()
      .toLowerCase();
    if (uploadedFileSize <= 5 && (extension == 'zip' || extension == '7z')) {
      let fileType = '';
      let fileSubType = '';
      let status = 'Upload';
      switch (type) {
        case 'ProjectWorkDetailsEvaluationTemplate':
          this.uploadedFiles.projectWorkEvaluationTemplate[index] = fileEntity;
          //  this.uploadStatus.projectWorkEvaluationTemplate = 'Upload';
          fileType = 'projectdetails';
          fileSubType = 'evaluator';
          break;
        case 'ProjectMaterial':
          this.uploadedFiles.projectWorkProjectMaterial[index] = fileEntity;
          // this.uploadStatus.projectWorkProjectMaterial[index] = 'Upload';

          fileType = 'projectdetails';
          fileSubType = 'Material';
          break;
        case 'ClassRoomEvaluationTemplate':
          this.uploadedFiles.classRoomEvaluationTemplate[index] = fileEntity;
          //this.uploadStatus.classRoomEvaluationTemplate[index] = 'Upload';

          fileType = 'classroomdetails';
          fileSubType = 'evaluator';
          break;
        case 'courseCompletionTemplate':
          this.uploadedFiles.courseCompletionTemplate[
            (index + 1) / 3 - 1
          ] = fileEntity;
          fileType = 'coursecompletiondetails';
          fileSubType = this.courseCompletionInterface.courseCompletionType[
            index
          ].TypeDescription;
          break;
        case 'ClassRoomAssignmentProblemStatement':
          this.uploadedFiles.classRoomAssignmentProblemStatement[
            index
          ] = fileEntity;
          fileType = 'classroomdetails';
          fileSubType = 'ProblemStatement';
          break;
        default:
          break;
      }
      if (fileType != '' && fileSubType != '')
        this.setUploadStatus(status, fileType, fileSubType, index);
      if (uploadedFileSize == 0) this.canUploadFile[type][index] = false;
    } else {
      this.canUploadFile[type][index] = false;
    }

    //if (fileEntity.type === 'application/x-zip-compressed') {

    //}
  }
  uploadFile(type, index) {
    let fileEntity, fileType: string, fileSubType: string;

    switch (type) {
      case 'ProjectWorkDetailsEvaluationTemplate':
        fileEntity = this.uploadedFiles.projectWorkEvaluationTemplate[index];
        fileType = 'projectdetails';
        fileSubType = 'evaluator';
        break;
      case 'ProjectMaterial':
        fileEntity = this.uploadedFiles.projectWorkProjectMaterial[index];
        fileType = 'projectdetails';
        fileSubType = 'Material';
        break;
      case 'ClassRoomEvaluationTemplate':
        fileEntity = this.uploadedFiles.classRoomEvaluationTemplate[index];
        fileType = 'classroomdetails';
        fileSubType = 'evaluator';
        break;
      case 'courseCompletionTemplate':
        fileEntity = this.uploadedFiles.courseCompletionTemplate[
          (index + 1) / 3 - 1
        ];
        fileType = 'coursecompletiondetails';
        fileSubType = this.courseCompletionInterface.courseCompletionType[index]
          .TypeDescription;
        //this.uploadStatus.courseCompletionTemplate[index] = 'Upload';
        break;
      case 'ClassRoomAssignmentProblemStatement':
        fileEntity = this.uploadedFiles.classRoomAssignmentProblemStatement[
          index
        ];
        fileType = 'classroomdetails';
        fileSubType = 'ProblemStatement';
        break;
    }
    const uploadedFileSize = fileEntity.size / (1024 * 1024);
    const extension = fileEntity.name
      .split('.')
      .pop()
      .toLowerCase();
    if (type !== 'All') {
      this.apiCallForBlob(fileEntity, fileType, fileSubType, index);
    }
  }

  apiCallForBlob(
    fileEntity,
    fileType: string,
    fileSubType: string,
    index: number
  ) {
    this.setUploadStatus('Uploading...', fileType, fileSubType, index);
    this._contentUploadSvc
      .uploadToBlob(
        fileEntity,
        this.content.UniqueId,
        this.content.Academy,
        this.content.Name,
        fileType,
        fileSubType,
        fileType === 'classroomdetails' || fileType === 'projectdetails'
          ? String(index + 1)
          : null
      )
      .then((response: any) => {
        let statusMsg = '';
        if (response != null && response.Status) {
          statusMsg = 'Uploaded';
          this.setUploadStatus(statusMsg, fileType, fileSubType, index);
        } else {
          statusMsg = 'Upload Failed';
          response = { Msg: '', Status: false };
          this.setUploadStatus(statusMsg, fileType, fileSubType, index);
        }
        if (fileType === 'classroomdetails') {
          if (fileSubType === 'evaluator') {
            this.classRoomDetails.ClassRoom[index].EvaluationTemplateLink =
              response.Msg;
          } else if (fileSubType === 'ProblemStatement') {
            this.classRoomDetails.ClassRoom[index].AssignmentContentLink =
              response.Msg;
          }
        } else if (fileType === 'projectdetails') {
          if (fileSubType === 'Material') {
            this.projectWorkDetails.ProjectWorkDetails[
              index
            ].ProjectMaterialLink =
              response.Msg;
          } else if (fileSubType === 'evaluator') {
            this.projectWorkDetails.ProjectWorkDetails[
              index
            ].EvaluationTemplateLink =
              response.Msg;
          }
        } else if ('coursecompletiondetails' == fileType) {
          this.courseCompletionValue.courseCompletionType[index] = response.Msg;
        }
        //delete this.canUploadFile[fileType][index];
      })
      .catch((error: any) => {
        let statusMsg = 'Upload Failed';
        this.setUploadStatus(statusMsg, fileType, fileSubType, index);
      });
  }
  saveAll() {
    this.openUpdatePopup();
    /*
  this.saveInfrastructure();
  this.saveCourseLogin();
  this.saveCourseCompletion();
  this.saveClassRoomDetails();
  this.saveProjectWorkDetails();
  */
  }
  resetAll() {
    this.contentLoaded.basicData = false;
    this.setInfrastructureValue(
      this.infrastructureInterface.infrastructure,
      this.infrastructureInterface.infrastructureType
    );
    this.setCourseLoginValue();
    this.setCourseCompletionValue();
    this.getClassRoomDetails();
    this.getProjectWork();
    this.isEditDiabled = !this.isEditDiabled;
    this.CreditSpecial = this.content.CreditSpecial;
    this.contentLoaded.basicData = true;
    this.uploadStatus = {
      projectWorkEvaluationTemplate: ['Upload'],
      projectWorkProjectMaterial: ['Upload'],
      classRoomEvaluationTemplate: ['Upload'],
      classRoomAssignmentProblemStatement: ['Upload'],
      courseCompletionTemplate: ['Upload']
    };
  }
  openUpdatePopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    let validationUpdate = {
      Infrastructure: false,
      CourseLogin: false,
      CourseCompletion: false,
      ClassRoom: false,
      ProjectWork: false
    };
    let nullUpdate = {
      Infrastructure: false,
      CourseLogin: false,
      CourseCompletion: false,
      ClassRoom: false,
      ProjectWork: false
    };

    let infrastructurePayload = {},
      courseLoginPayload = {},
      courseCompletionPayload = {},
      classRoomPayload = {},
      projectWorkPayload = {};

    if (this.saveInfrastructure()) {
      infrastructurePayload = {
        UniqueId: this.ContentData.UniqueId,
        infrastructure: this.CheckAllFalseOrEmpty(
          this.infrastructureValue.infrastructure
        )
          ? [false, false, false, false, true]
          : this.infrastructureValue.infrastructure,
        infrastructureType: this.setInfrastructureTypeNull(
          this.infrastructureValue.infrastructure,
          this.infrastructureValue.infrastructureType
        )
      };
      validationUpdate.Infrastructure = true;
    }
    if (this.saveCourseLogin()) {
      courseLoginPayload = {
        UniqueId: this.ContentData.UniqueId,
        LoginType: this.CheckAllFalseOrEmpty(this.courseLoginValue.LoginType)
          ? [true, false, false, false]
          : this.courseLoginValue.LoginType,
        LinktoCoursesForSelfRegistration: this.courseLoginValue.LoginType[1]
          ? this.courseLoginValue.LinktoCoursesForSelfRegistration
          : null,
        CoursesNameForSelfRegistration: this.courseLoginValue.LoginType[1]
          ? this.courseLoginValue.CoursesNameForSelfRegistration
          : null,
        LinktoCoursesForLearningOPMTeam: this.courseLoginValue.LoginType[2]
          ? this.courseLoginValue.LinktoCoursesForLearningOPMTeam
          : null,
        CoursesNameForLearningOPMTeam: this.courseLoginValue.LoginType[2]
          ? this.courseLoginValue.CoursesNameForLearningOPMTeam
          : null,
        LoginDetails: this.courseLoginValue.LoginType[2]
          ? this.courseLoginValue.LoginDetails
          : null
      };
      validationUpdate.CourseLogin = true;
    }
    if (this.saveCourseCompletion()) {
      ////console.log('ddd', this.courseCompletionValue.courseCompletion, this.courseCompletionValue.courseCompletionType);
      if (this.courseCompletionValue) {
        courseCompletionPayload = {
          UniqueId: this.ContentData.UniqueId,
          CourseCompletion: this.courseCompletionValue.courseCompletion,
          CourseCompletionType: this.setCourseCompletionTypeNull(
            this.courseCompletionValue.courseCompletion,
            this.courseCompletionValue.courseCompletionType
          ),
          Status: this.courseCompletionValue.courseCompletion[0]
            ? this.certificateSeletedState.Status
            : null,
          SubStatus: this.courseCompletionValue.courseCompletion[0]
            ? this.certificateSeletedState.SubStatus
            : null
        };
      } else {
        nullUpdate.CourseCompletion = true;
      }

      validationUpdate.CourseCompletion = true;
    }

    if (this.saveClassRoomDetails()) {
      if (this.classRoomDetails) {
        classRoomPayload = {
          UniqueId: this.ContentData.UniqueId,
          IsClassRoomRequired: this.classRoomDetails.IsClassRoomRequired,
          ClassroomExecution: this.classRoomDetails.ClassroomExecution,
          ClassRoom: this.classRoomDetails.ClassRoom
        };
      } else {
        classRoomPayload = {
          UniqueId: this.ContentData.UniqueId,
          IsClassRoomRequired: null,
          ClassroomExecution: null,
          ClassRoom: null
        };
        nullUpdate.ClassRoom = true;
      }
      validationUpdate.ClassRoom = true;
    }
    if (this.saveProjectWorkDetails()) {
      if (this.projectWorkDetails) {
        projectWorkPayload = {
          UniqueId: this.ContentData.UniqueId,
          HasProjectWork: this.projectWorkDetails.HasProjectWork,
          HasJumbleProject: this.projectWorkDetails.HasJumbleProject,
          ProjectWorkDetails: this.projectWorkDetails.ProjectWorkDetails,
          HasProjectEvaluation: this.projectWorkDetails.HasProjectEvaluation,
          ProjectEvaluationDetails: this.projectWorkDetails
            .ProjectEvaluationDetails
        };
      } else {
        projectWorkPayload = {
          UniqueId: this.ContentData.UniqueId,
          HasProjectWork: null,
          HasJumbleProject: null,
          ProjectWorkDetails: null,
          HasProjectEvaluation: null,
          ProjectEvaluationDetails: null
        };
        nullUpdate.ProjectWork = true;
      }
      validationUpdate.ProjectWork = true;
    }

    //addDialogContainer
    const creditPayload = {
      UniqueId: this.ContentData.UniqueId,
      creditSpecial: this.CreditSpecial
    };
    dialogConfig.data = {
      validationUpdate: validationUpdate,
      nullUpdate: nullUpdate,
      creditPayload: creditPayload,
      infrastructurePayload: infrastructurePayload,
      courseLoginPayload: courseLoginPayload,
      courseCompletionPayload: courseCompletionPayload,
      classRoomPayload: classRoomPayload,
      projectWorkPayload: projectWorkPayload
    };

    const dialogRef = this._updatePopup.open(
      PssContentUpdatePopupComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(response => {
      if (response.CreditSpecial) {
        this.content.CreditSpecial = this.CreditSpecial;
      }
      if (response.Infrastructure) {
        if (
          this.CheckAllFalseOrEmpty(this.infrastructureValue.infrastructure)
        ) {
          this.content.Infrastructure = [false, false, false, false, true];
          this.infrastructureValue.infrastructure = [
            false,
            false,
            false,
            false,
            true
          ];
        } else {
          this.content.Infrastructure = [
            ...this.infrastructureValue.infrastructure
          ];
        }
        this.content.InfrastructureType = [
          ...this.infrastructureValue.infrastructureType
        ];
      } else {
        this.setInfrastructureValue(
          this.infrastructureInterface.infrastructure,
          this.infrastructureInterface.infrastructureType
        );
      }
      if (response.CourseLogin) {
        this.content.CoursesNameForSelfRegistration = [
          ...this.courseLoginValue.CoursesNameForSelfRegistration
        ];
        this.content.LinktoCoursesForSelfRegistration = [
          ...this.courseLoginValue.LinktoCoursesForSelfRegistration
        ];
        this.content.LinktoCoursesForLearningOPMTeam = [
          ...this.courseLoginValue.LinktoCoursesForLearningOPMTeam
        ];
        if (this.CheckAllFalseOrEmpty(this.courseLoginValue.LoginType)) {
          this.content.LoginType = [true, false, false, false];
          this.courseLoginValue.LoginType = [true, false, false, false];
        } else {
          this.content.LoginType = [...this.courseLoginValue.LoginType];
        }
        this.content.LoginDetails = this.courseLoginValue.LoginDetails;
      } else {
        this.setCourseLoginValue();
      }
      if (this.content.CourseCompletionGroup) {
        if (response.CourseCompletion) {
          this.content.CourseCompletionGroup.CourseCompletionFormArray = [
            ...this.courseCompletionValue.courseCompletion
          ];
          this.content.CourseCompletionGroup.CourseCompletionTypeFormArray = [
            ...this.courseCompletionValue.courseCompletionType
          ];
        } else {
          this.setCourseCompletionValue();
        }
      }

      if (this.content.ClassRoomGroup) {
        if (response.ClassRoom) {
          this.content.ClassRoomGroup = JSON.parse(
            JSON.stringify(this.classRoomDetails)
          );
          this.classRoomDetails.ClassRoom.forEach((value, key) => {
            this.uploadStatus.classRoomEvaluationTemplate[key] = 'Upload';
            this.uploadStatus.classRoomAssignmentProblemStatement[key] =
              'Upload';
            this.editedStatus.classRoomEvaluationTemplate[key] = false;
            this.editedStatus.classRoomAssignmentProblemStatement[key] = false;
          });
        } else {
          this.getClassRoomDetails();
        }
      }

      if (this.content.ProjectGroup) {
        if (response.ProjectWork) {
          this.content.ProjectGroup = JSON.parse(
            JSON.stringify(this.projectWorkDetails)
          );
          this.projectWorkDetails.ProjectWorkDetails.forEach((value, key) => {
            this.uploadStatus.projectWorkEvaluationTemplate[key] = 'Upload';
            this.uploadStatus.projectWorkProjectMaterial[key] = 'Upload';
            this.editedStatus.projectWorkEvaluationTemplate[key] = false;
            this.editedStatus.projectWorkProjectMaterial[key] = false;
          });
        } else {
          this.getProjectWork();
        }
      }
      // if(!response.CreditSpecial &&
      //   !response.Infrastructure &&
      //   !response.CourseLogin &&
      //   ((this.content.CourseCompletionGroup && !response.CourseCompletion) || !response.CourseCompletion) &&
      //   ((this.content.ClassRoomGroup && !response.ClassRoom) || !response.ClassRoom) &&
      //   ((this.content.ProjectGroup && !response.ProjectWork) || !response.ProjectWork)
      // ){
      //   this.isEditDiabled = false;
      // }else{
      // this.isEditDiabled = true;
      // }
      this.isEditDiabled = true;
    });
  }

  approveOrDenyCourse(type) {

    //If it is a 301 course and requires to be approved, directly send the request and show status as popup
    if (this.ContentData.Expertise == '301' && type == 'approve') {

      const payload = {
        UniqueId: this.ContentData.UniqueId,
        pssAction: 'Approved',
        remarks: "",
        spoc: this.ContentData.LearningOPMSPOC,
        creditSpecial: false
      };

      //Check if the user wishes to proceed with the verification of content
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = "Confirmation";

      this._updatePopup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {

        //If the user wishes to verify then continue
        if (data.toLowerCase() == 'yes') {
          this.pssContentApprovalService.pssVerificationAction(payload).then(res => {
            if (res == 'success') {

              const popupConfig = new MatDialogConfig();
              popupConfig.disableClose = true;
              popupConfig.autoFocus = true;
              popupConfig.panelClass = 'popupDialogContainer';
              popupConfig.data = "VerificationSuccess";
              this._updatePopup.open(ContentUpload101PopupsComponent, popupConfig).afterClosed().subscribe(data => {
                this.gotoCourseMenu();
              });
            }

            else {
              const config = new MatDialogConfig();
              config.disableClose = true;
              config.autoFocus = true;
              config.panelClass = 'popupDialogContainer';
              config.data = "VerificationFail";
              this._updatePopup.open(ContentUpload101PopupsComponent, config).afterClosed().subscribe(data => {
                return;
              });
            }
          });
        }
      });
    }

    //If it is a 301 course that requires denial, or a 201 course for approval or denial
    else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';

      dialogConfig.data = {
        type: type,
        UniqueId: this.ContentData.UniqueId,
        creditSpecial: this.CreditSpecial,
        spoc: this.ContentData.LearningOPMSPOC
      };

      const dialogRef = this._updatePopup.open(
        PssContentActionPopupComponent,
        dialogConfig
      );

      dialogRef.afterClosed().subscribe(actionSuccess => {
        if (actionSuccess) {
          this.gotoCourseMenu();
        }
      });
    }
  }

  openDownloadFileWithSasKey(url) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      url: url
    };

    const dialogRef = this._updatePopup.open(
      DownloadBlobPopupComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(response => {
    });
  }
  // Check all false
  CheckAllFalseOrEmpty(list: boolean[] | null) {
    if (list && list.length > 0) {
      return list.every(item => item === false);
    } else {
      return true;
    }
  }

  deleteLoginData(index, list) {
    if (list && list.length > 0) {
      list.splice(index, 1);
    }
  }

  setUploadStatus(status, fileType, fileSubType, index) {
    if (fileType === 'classroomdetails') {
      if (fileSubType === 'evaluator') {
        this.uploadStatus.classRoomEvaluationTemplate[index] = status;
        if ('Uploaded' == status) {
          this.editedStatus.classRoomEvaluationTemplate[index] = true;
        }
      } else if (fileSubType === 'ProblemStatement') {
        this.uploadStatus.classRoomAssignmentProblemStatement[index] = status;
        if ('Uploaded' == status) {
          this.editedStatus.classRoomAssignmentProblemStatement[index] = true;
        }
      }
    } else if (fileType === 'projectdetails') {
      if (fileSubType === 'Material') {
        this.uploadStatus.projectWorkProjectMaterial[index] = status;
        if ('Uploaded' == status) {
          this.editedStatus.projectWorkProjectMaterial[index] = true;
        }
      } else if (fileSubType === 'evaluator') {
        this.uploadStatus.projectWorkEvaluationTemplate[index] = status;
        if ('Uploaded' == status) {
          this.editedStatus.projectWorkEvaluationTemplate[index] = true;
        }
      }
    } else if ('coursecompletiondetails' == fileType) {
      this.uploadStatus.courseCompletionTemplate[index] = status;
      if ('Uploaded' == status) {
        this.editedStatus.courseCompletionTemplate[index] = true;
      }
    }
  }

  updateEvaluatorFromPopup(event, type, index) {
    ////console.log(event);
    switch (type) {
      case 'classroom':
        this.classRoomDetails.ClassRoom[index].EvaluatorName =
          event[0].displayName;
        this.classRoomDetails.ClassRoom[index].EvaluatorVendor =
          'Mindtree Internal';
        this.classRoomDetails.ClassRoom[index].EvaluatorEmailID = event[0].mail;
        break;

      case 'project':
        this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorName =
          event[0].displayName;
        this.projectWorkDetails.ProjectEvaluationDetails[
          index
        ].EvaluatorVendor =
          'Mindtree Internal';
        this.projectWorkDetails.ProjectEvaluationDetails[
          index
        ].EvaluatorEmailID =
          event[0].mail;
        break;

      default:
        break;
    }
  }

  internalExternalRadioChange(type, index) {
    switch (type) {
      case 'classroom':
        if ("Internal" == this.classRoomDetails.ClassRoom[index].EvaluatorType) {
          this.cacheEvaluatorData.ClassRoom[index].external.EvaluatorName = this.classRoomDetails.ClassRoom[index].EvaluatorName;
          this.cacheEvaluatorData.ClassRoom[index].external.EvaluatorEmailID = this.classRoomDetails.ClassRoom[index].EvaluatorEmailID;
          this.cacheEvaluatorData.ClassRoom[index].external.EvaluatorVendor = this.classRoomDetails.ClassRoom[index].EvaluatorVendor;

          this.classRoomDetails.ClassRoom[index].EvaluatorName = this.cacheEvaluatorData.ClassRoom[index].internal.EvaluatorName;
          this.classRoomDetails.ClassRoom[index].EvaluatorEmailID = this.cacheEvaluatorData.ClassRoom[index].internal.EvaluatorEmailID;
          this.classRoomDetails.ClassRoom[index].EvaluatorVendor = this.cacheEvaluatorData.ClassRoom[index].internal.EvaluatorVendor;
        } else {
          this.cacheEvaluatorData.ClassRoom[index].internal.EvaluatorName = this.classRoomDetails.ClassRoom[index].EvaluatorName;
          this.cacheEvaluatorData.ClassRoom[index].internal.EvaluatorEmailID = this.classRoomDetails.ClassRoom[index].EvaluatorEmailID;
          this.cacheEvaluatorData.ClassRoom[index].internal.EvaluatorVendor = this.classRoomDetails.ClassRoom[index].EvaluatorVendor;

          this.classRoomDetails.ClassRoom[index].EvaluatorName = this.cacheEvaluatorData.ClassRoom[index].external.EvaluatorName;
          this.classRoomDetails.ClassRoom[index].EvaluatorEmailID = this.cacheEvaluatorData.ClassRoom[index].external.EvaluatorEmailID;
          this.classRoomDetails.ClassRoom[index].EvaluatorVendor = this.cacheEvaluatorData.ClassRoom[index].external.EvaluatorVendor;
        }
        break;

      case 'project':
        this.cacheEvaluatorData.Project[index].EvaluatorName = this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorName;

        if ("Internal" == this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorType) {
          this.cacheEvaluatorData.Project[index].external.EvaluatorName = this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorName;
          this.cacheEvaluatorData.Project[index].external.EvaluatorEmailID = this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorEmailID;
          this.cacheEvaluatorData.Project[index].external.EvaluatorVendor = this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorVendor;

          this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorName = this.cacheEvaluatorData.Project[index].internal.EvaluatorName;
          this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorEmailID = this.cacheEvaluatorData.Project[index].internal.EvaluatorEmailID;
          this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorVendor = this.cacheEvaluatorData.Project[index].internal.EvaluatorVendor;
        } else {
          this.cacheEvaluatorData.Project[index].internal.EvaluatorName = this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorName;
          this.cacheEvaluatorData.Project[index].internal.EvaluatorEmailID = this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorEmailID;
          this.cacheEvaluatorData.Project[index].internal.EvaluatorVendor = this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorVendor;

          this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorName = this.cacheEvaluatorData.Project[index].external.EvaluatorName;
          this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorEmailID = this.cacheEvaluatorData.Project[index].external.EvaluatorEmailID;
          this.projectWorkDetails.ProjectEvaluationDetails[index].EvaluatorVendor = this.cacheEvaluatorData.Project[index].external.EvaluatorVendor;
        }
        break;
      default:
        break;
    }
  }


  getQuizData() {
    this._contentUpload101Service.getQuizDataFor101(this.ContentData.UniqueId).subscribe(data => {
      //console.log(data)
      if (data != null && data.questionsAndAnswer != null) {
        this.quizDetails = data;
      }
    });
  }
}


