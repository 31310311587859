import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddContentComponent } from './add-content.component';
import { AddPopupComponent } from './add-popup/add-popup.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ReusableUiModule} from '@YorbitWorkspace/reusable-ui';
// import {MatTooltipModule} from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  imports: [
    CommonModule,

    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ReusableUiModule
  ],
  declarations: [AddContentComponent, AddPopupComponent],
  exports: [
    AddContentComponent, AddPopupComponent
  ],
})
export class AddContentModule { }
