import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RiskBrigadeService } from '../../../shared/services/risk-brigade.service';
import * as fromUserDetailsStore from '../../../shared/user-details/store';

@Component({
  selector: 'yorbit-course-progress-advance',
  templateUrl: './course-progress-advance.component.html',
  styleUrls: ['./course-progress-advance.component.scss']
})
export class CourseProgressAdvanceComponent implements OnInit {

  selectedFilter: string = 'all';
  isDataLoading:boolean=false;
  dataList:any=[];
  pageNumber=1;
  showMore=true;
  userLpData:any;

  constructor(private _riskBrigadeService:RiskBrigadeService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>) { }

  ngOnInit() {
    this.getLPData();
  }


  getLPData(){
    this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailLoaded)
      .subscribe(loaded => {
        this.userDetailsStore.select(fromUserDetailsStore.getLearningPathListEntities)
        .subscribe(res => {
           this.userLpData=res; 
          this.resetPagination();
          this.getAPIData();
        });
      });
  }

  getAPIData(){
    this.isDataLoading=true;
    this._riskBrigadeService.getProgressData('advance',this.selectedFilter,this.pageNumber).then(
      (data: any) => {
        this.isDataLoading = false;
        if(data!=null){
          data.forEach(progressCourse => {
                  for (let key in this.userLpData) {
                let value = this.userLpData[key];
                // Use `key` and `value`
                value.PackageList.forEach(element => {
                  if(element.ItemId==progressCourse.CourseID){
                  this.dataList.push(element)
                  }
                });
              }
          });
          //console.log(this.dataList)
          return this.dataList;
        }else{
          this.showMore=false;
        }
      },
      error => {
         this.isDataLoading = false;
         if(this.pageNumber==1){
          this.dataList = [];
          //console.log('Error', error);
         }
         return this.dataList;
      }
    );
  }

  resetPagination(){
    this.pageNumber=1;
    this.dataList =[]
    this.showMore=true;
  }

  clickSelectedFilter(filter) {
    this.selectedFilter = filter;
   this.resetPagination();
    this.getAPIData();
  }

  getMoreRecords(){
    this.pageNumber+=1;
    this.getAPIData();
  }

}
