import { createSelector, createFeatureSelector} from '@ngrx/store';
import * as fromCloudlabReducer from '../reducers/cloudlab-store.reducer';
import { IcloudlabState } from '../reducers';

export const getCloudlabSelector = createFeatureSelector<IcloudlabState>('cloudlab');

export const getCloudlabListSelector =createSelector(
  getCloudlabSelector,
  (state:IcloudlabState)=>state.cloudlab.data
);

export const getCloudlabStatusByEntities= createSelector(
  getCloudlabListSelector,
  (cloudlabStatusList)=>{
    let cloudlabEntities;
    if(cloudlabStatusList && 0!==cloudlabStatusList.length){
      cloudlabEntities=cloudlabStatusList.reduce(
        (entities,status:fromCloudlabReducer.IcloudlabData)=>{
          return{
            ...entities,
            [status.CourseId]:status
          }
        },{}
      )
    }else{
      cloudlabEntities={};
    }
    return cloudlabEntities;
  }
)

export const getCloudlabStatusByCourseId= id=>createSelector(
  getCloudlabStatusByEntities,
  (cloudlabStatus)=> {
    return cloudlabStatus[id]
  }
)


