import { Component, Inject, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { Globals } from '../../../globals';
import { TopLearnersService } from '../../services/top-learners.service';
import { WINDOW } from '../../services/window.service';
import { CoachesAndFacilitatorPopupComponent } from '../../top-coaches/coaches-and-facilitator-popup/coaches-and-facilitator-popup.component';

@Component({
  selector: 'yorbit-top-facilitators',
  templateUrl: './top-facilitators.component.html',
  styleUrls: ['./top-facilitators.component.scss']
})
export class TopFacilitatorsComponent implements OnInit {

  isTopfacilitatorsLoading: boolean = true;
  topfacilitators = [];
  bullets = [];
  sliderActiveIndex = 0;
  mediaBreakPoint = '';
  mobile: boolean = false;
  inMobileView: boolean = false;
  numberOfTopFacilitators: number = 6;
  constructor(
    private _topLearnersService: TopLearnersService,
    private _graphSvc: GraphDataService,
    private globals: Globals,
    @Inject(WINDOW) private _window: any,
    private sanitizer: DomSanitizer,
    private mediaObserver: MediaObserver,
    private _popup: MatDialog) {
    this.subscribeToMediaObserver();
  }

  ngOnInit(): void {
    this.getTopfacilitators();
  }
  subscribeToMediaObserver() {
    this.mediaObserver.media$.subscribe((media: MediaChange) => {
      this.mediaBreakPoint = media.mqAlias;
      if (this.mediaBreakPoint == 'xs') {
        this.numberOfTopFacilitators = 3;
        this.mobile = true;
      } else if (this.mediaBreakPoint >= 'sm') {
        this.numberOfTopFacilitators = 6;
        this.mobile = false;
      } else {
        this.numberOfTopFacilitators = 6;
        this.mobile = false;
      }
      if(this.topfacilitators.length > 0 && this.sliderActiveIndex!=0){
        this.bullets = new Array(
          Math.ceil(this.topfacilitators.length / this.sliderActiveIndex)
        );
      }
    });
  }

  getTopfacilitators() {
    this.isTopfacilitatorsLoading = true;
    this._topLearnersService.getTopFacilitators().then(
      (data: any) => {
        this.isTopfacilitatorsLoading = false;
        for (let i = 0; i < data.length; i++) {
          data[i].Image = null;
        }
        this.globals.topFacilitatorsData = data;
        this.topfacilitators = this.globals.topFacilitatorsData;
        if (this.mobile) {
          this.numberOfTopFacilitators = 3;
        } else {
          this.numberOfTopFacilitators = 6;
        }
        for (let i = 0; i < this.topfacilitators.length; i++) {
          if (this.globals.topFacilitatorsData[i].Image == null) {
            this._graphSvc
              .getUserImage(this.globals.topFacilitatorsData[i].MID)
              .subscribe(
                data => {
                  this.globals.topFacilitatorsData[i].Image = this.createImageURL(data);
                },
                error => {
                  this.globals.topFacilitatorsData[i].Image = null;
                }
              );
          }
        }
        this.bullets = new Array(
          Math.ceil(this.topfacilitators.length / this.numberOfTopFacilitators)
        );
      });
  }
  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }
  showNextSlide() {
    if (this.sliderActiveIndex == Math.ceil(this.topfacilitators.length / this.numberOfTopFacilitators) - 1) {
      this.sliderActiveIndex = this.bullets.length - 1;
    } else {
      this.sliderActiveIndex++;
    }
  }
  showNextSlideInDesktopView(index) {
    if (!this.mobile) {
      this.sliderActiveIndex = index;
    }
  }

  showPreviousSlide() {
    if (this.sliderActiveIndex > 0) {
      this.sliderActiveIndex--;
    } else {
      this.sliderActiveIndex = 0;
    }
  }
  openInformation(topfacilitator, i) {
    if (this.mobile) {
      var bgColor;
      if (i == 0 && this.sliderActiveIndex % 2 == 0) {
        bgColor = 'rgba(27, 199, 199)';
      } else if (i == 1 && this.sliderActiveIndex % 2 == 0) {
        bgColor = 'rgba(63, 148, 247)';
      } else if (i == 2 && this.sliderActiveIndex % 2 == 0) {
        bgColor = 'rgba(235, 231, 95)';
      } else if (i == 0 && this.sliderActiveIndex % 2 == 1) {
        bgColor = 'rgba(23, 196, 116)';
      } else if (i == 1 && this.sliderActiveIndex % 2 == 1) {
        bgColor = 'rgba(48, 185, 235)';
      } else if (i == 2 && this.sliderActiveIndex % 2 == 1) {
        bgColor = 'rgba(245, 186, 53)';
      }
      var data = { title: "TopFacilitator", data: topfacilitator, bgColor: bgColor };
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = data;
      const response = this._popup.open(CoachesAndFacilitatorPopupComponent, dialogConfig);
    }
  }
}
