import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountFormatterService {

  constructor() { }
  CountFormatter(count) {
    if (count >= 1000000000) {
        return (count / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    else if (count >= 1000000) {
        return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    else if (count >= 1000) {
        return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    else {
        return count;
    }
}
}
