import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'yorbit-risk-brigade-progress',
  templateUrl: './risk-brigade-progress.component.html',
  styleUrls: ['./risk-brigade-progress.component.scss']
})
export class RiskBrigadeProgressComponent implements OnInit {
  selectedArticleFilter: string = 'all';

  constructor(private _router:Router) { }

  ngOnInit() {
  }

  clickSelectedFilter(filter) {
    this.selectedArticleFilter = filter;
  }

  gotoFoundationCourses(){
    this._router.navigate(['/riskbrigade/progress/foundation-courses'])
  }

  gotoAdvanceCourses(){
    this._router.navigate(['/riskbrigade/progress/advance-courses'])
  }

}
