<mat-dialog-content class="popupDialog">
  <!--Close Popup Icon-->
  <div class="closePopupDialog">
    <button mat-button mat-icon-button (click)="onClick('No')">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <!--Popup Header-->
  <div class="popupHeader">Confirmation</div>
  
  <!--Mat Divider-->
  <mat-divider></mat-divider>
  
  <div class= "messageText">
   {{description}}
  </div>
  
   <!--Mat Divider-->
  <mat-divider></mat-divider>

  <div class="actionButtonContainer">
    <div class="yesButtonDiv">
      <button mat-raised-button class="actionButton yes-btn" 
      (click)="onClick('Yes')">Yes</button>
    </div>
    <div class="noButtonDiv">
      <button mat-raised-button class="actionButton no-btn" (click)="onClick('No')">No</button>
    </div>
  </div>
</mat-dialog-content>
  
