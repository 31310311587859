<div class="background" fxLayout="column">
  <div class="message1">
    We'll be back soon.
  </div>
  <div class="message2">
    Shoshin School is currently down as we are working on making things better for you.
  </div>
  <div class="message2">
    Regret for the inconvenience caused.
  </div>
</div>