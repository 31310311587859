export { RootStoreModule } from './root-store.module';
import { AuthEffects } from '@YorbitWorkspace/auth';

import {
  UserDetailsEffects
} from '../shared/user-details/store';
import { BrowseEffects } from '../browse/store';


export const rootReducer = {
};
export const rootEffect = [
  AuthEffects,
  UserDetailsEffects,
  BrowseEffects
];
