import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'yorbit-banner-without-logo',
  templateUrl: './banner-without-logo.component.html',
  styleUrls: ['./banner-without-logo.component.scss']
})
export class BannerWithoutLogoComponent implements OnInit {

  constructor(private _router: Router,) { }

  ngOnInit(): void {
  }
  openCourse(){
    this._router.navigate(['/browse/academy/1/genre/84/livingmindfully']);
  }

}
