export const PlayerVarsO365 = {
  key: "d0167b1c-9767-4287-9ddc-e0fa09d31e02",
  playback: {
    muted: false,
    autoplay: false,
    seeking: true
  },
  ui: true,
  nativeControlsForTouch: false,
  autoplay: false,
  controls: true,
  poster: '',
  logo: { enabled: false },
  playbackSpeed: {
    enabled: true,
    initialSpeed: 1.0,
    speedLevels: [
      { name: 'x2.0', value: 2.0 },
      { name: 'x1.75', value: 1.75 },
      { name: 'x1.5', value: 1.5 },
      { name: 'x1.25', value: 1.25 },
      { name: 'normal', value: 1.0 },
      { name: 'x0.75', value: 0.75 },
      { name: 'x0.5', value: 0.5 }
    ]
  }
};
export const OptionalParams = {
  CanCaptureProgress: true,
  VideoPlayerId: 'azure-media-player-container'
};

export const PlayerVarsIntroVideoO3651 = {
  nativeControlsForTouch: false,
  autoplay: false,
  controls: true,
  poster: '',
  logo: { enabled: false },
  playbackSpeed: {
    enabled: true,
    initialSpeed: 1.0,
    speedLevels: [
      { name: 'x2.0', value: 2.0 },
      { name: 'x1.75', value: 1.75 },
      { name: 'x1.5', value: 1.5 },
      { name: 'x1.25', value: 1.25 },
      { name: 'normal', value: 1.0 },
      { name: 'x0.75', value: 0.75 },
      { name: 'x0.5', value: 0.5 }
    ]
  }
};


 
export const PlayerVarsIntroVideoO365 = {
  key: "d0167b1c-9767-4287-9ddc-e0fa09d31e02",
  playback: {
    muted: false,
    autoplay: false,
    seeking: true
  },
  ui: true
};