
<div class="cnt">
  <img src="../../assets/images/riskbrigade/speaker-container-left-puzzle.png" class="left-puzzle">
    

  <div fxLayout="row" class="header center" >
        My Progress
  </div>
  
  <div fxLayout="row" class="tabs center">
    <div #foundationCourses class="tab" fxFlex="100" [routerLink]="['/riskbrigade/progress/foundation-courses']" routerLinkActive="active"
    (click)="gotoFoundationCourses()">Foundation Courses</div>
    <!-- <div #advanceCourses class="tab" fxFlex="50" [routerLink]="['/riskbrigade/progress/advance-courses']"  routerLinkActive="active"
    (click)="gotoAdvanceCourses()">Advance Courses</div> -->
  </div>



  <div class="center">
    <router-outlet></router-outlet>
  </div>
  

  <img src="../../assets/images/riskbrigade/speaker-container-right-puzzle.png" class="right-puzzle"> 

</div>

