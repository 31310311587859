import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByProperty'
})

/**
 * use as
 * In HTML
 *  <div *ngFor="let content of (contentList | OrderByPropertyPipe:{'property':'name'})">
 *
 * In TS
 * const sortPipe = new OrderByPropertyPipe();
 * const sortedList = sortPipe.transform(contentList,{'property':'name'});
 *
 * legends
 * array -> any array of object | as here 'contentList'
 * args -> includes the 'property' to sort as here 'name'
 */
export class OrderByPropertyPipe implements PipeTransform {
  transform(array: any, args: Ifilter): any {
    if (!array || !args) {
      return array;
    } else {
      array.sort((a: any, b: any) => {
        if (a[args.property] < b[args.property]) {
          return -1;
        }
        if (a[args.property] > b[args.property]) {
          return 1;
        }

        return 0;
      });

      return array;
    }
  }
}

interface Ifilter {
  property?: string;
}
