import { Component, Inject, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { Globals } from '../../../globals';
import { TopLearnersService } from '../../services/top-learners.service';
import { WINDOW } from '../../services/window.service';
import { CoachesAndFacilitatorPopupComponent } from '../coaches-and-facilitator-popup/coaches-and-facilitator-popup.component';

@Component({
  selector: 'yorbit-top-coaches',
  templateUrl: './top-coaches.component.html',
  styleUrls: ['./top-coaches.component.scss']
})
export class TopCoachesComponent implements OnInit {

  isTopCoachesLoading: boolean = true;
  topCoaches = [];
  bullets = [];
  sliderActiveIndex = 0;
  mediaBreakPoint = '';
  mobile: boolean = false;
  numberOfTopCoaches: number = 5;
  constructor(
    private _topLearnersService: TopLearnersService,
    private _graphSvc: GraphDataService,
    private globals: Globals,
    @Inject(WINDOW) private _window: any,
    private sanitizer: DomSanitizer,
    private mediaObserver: MediaObserver,
    private _popup: MatDialog
  ) {
    this.subscribeToMediaObserver();
  }

  ngOnInit(): void {
    this.getTopCoaches();
  }

  subscribeToMediaObserver() {
    this.mediaObserver.media$.subscribe((media: MediaChange) => {
      this.mediaBreakPoint = media.mqAlias;
      if (this.mediaBreakPoint == 'xs') {
        this.mobile = true;
      } else if (this.mediaBreakPoint >= 'sm') {
        this.mobile = false;
      } else {
        this.mobile = false;
      }
    });
  }

  getTopCoaches() {
    this.isTopCoachesLoading = true;
    this._topLearnersService.getTopCoaches().then(
      (data: any) => {
        this.isTopCoachesLoading = false;
        for (let i = 0; i < data.length; i++) {
          data[i].Image = null;
        }
        this.globals.topCoachesData = data;
        this.topCoaches = this.globals.topCoachesData;
        if(this.mobile) {
          this.numberOfTopCoaches = this.topCoaches.length;
        } else {
          this.numberOfTopCoaches = 5;
        }
        for (let i = 0; i < this.topCoaches.length; i++) {
          if (this.globals.topCoachesData[i].Image == null) {
            this._graphSvc
              .getUserImage(this.globals.topCoachesData[i].MID)
              .subscribe(
                data => {
                  this.globals.topCoachesData[i].Image = this.createImageURL(data);
                },
                error => {
                  this.globals.topCoachesData[i].Image = null;
                }
              );
          }
        }
        this.bullets = new Array(
          Math.ceil(this.topCoaches.length / this.numberOfTopCoaches)
        );
      });
  }
  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }
  showNextSlide(index) {
    this.sliderActiveIndex = index;
  }
  openInformation(topCoach, i) {
    if (this.mobile) {
      var bgColor;
      if (i % 5 == 0) {
        bgColor = 'rgba(158, 200, 98, 0.6)';
      } else if (i % 5 == 1) {
        bgColor = 'rgba(67, 171, 232, 0.6)';
      } else if (i % 5 == 2) {
        bgColor = 'rgba(10, 204, 204, 0.6)';
      } else if (i % 5 == 3) {
        bgColor = 'rgba(29, 183, 213, 0.6)';
      } else if (i % 5 == 4) {
        bgColor = 'rgba(71, 195, 138, 0.6)';
      }
      var data = { title: "TopCoach", data: topCoach, bgColor: bgColor};
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = data;
      const response = this._popup.open(CoachesAndFacilitatorPopupComponent, dialogConfig);
    }
  }
}
