<!--
<iframe src="{{url}}"
title="Iframe Example" class="fullScreen"
></iframe>


<iframe [src]="url"
title="Iframe Example" class="fullScreen"
></iframe>


<iframe src="./../../../../assets/scorm/scorm.html""
title="Iframe Example" class="fullScreen"
></iframe>

-->
<div class="scormFullScreenContainer">
  <div class="scormFullScreen" (click)="goFullScreen()">
    View Full Screen
  </div>
</div>

<div id="scormPlayerContainer" class="fullScreen max">
</div>
