import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,  
  MatDialog,
  MatDialogConfig
} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../../user-details/store';
import { Observable } from 'rxjs';
import { LearningPath } from '../../../user-details/store/user-details.interface';
import { LearningPathService } from '../../../../learning-path/learning-path.service';
import { FormControl, Validators } from '@angular/forms';
import { AddContentComponent } from '../add-content.component';
import { AddContentService } from './add-content.service';
import { getLearningPath } from '../../../user-details/store/reducers/user-details.reducer';
import { ArrayPropertyFilterPipe } from '@YorbitWorkspace/pipes';
import { IdpService } from '../../../../idp/idp.service';
import { Router } from '@angular/router';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { CreateLpPopupComponent } from '../../../create-lp/create-lp-popup/create-lp-popup.component';
import { CloudlabStoreService } from '../../../cloudlab-store/cloudlab-store.service';
import { AddedContentDataService } from '../../../services/added-content-data.service';
import { Globals } from 'apps/yorbit-ui/src/app/globals';

@Component({
  selector: 'yorbit-add-popup',
  templateUrl: './add-popup.component.html',
  styleUrls: ['./add-popup.component.scss']
})
export class AddPopupComponent implements OnInit {
  learningPathEntities$: Observable<{ [id: number]: LearningPath }>;
  getUserCreatedLPs$: Observable<any>;
  getSkillingLPs$: Observable<any>;
  getLearningPathLoading$: Observable<boolean>;
  getLearningPathLoaded$: Observable<boolean>;
  learningPathValidateFail: boolean;
  learningPathTitle: string;
  lastLpTitle: string;
  validateLearningPath: ErrorStateMatcher = {
    isErrorState: (control: FormControl) => {
      return this.learningPathValidateFail;
    }
  };
  learningPathFormControl = new FormControl('', [Validators.required]);
  learningPathValidateError: string;
  addConfirmationPopup: boolean;
  AddTolearningPathDetails: any;
  addInfo: string;
  addProcess: { loading: boolean; loaded: boolean };
  existingLearningPath$: { [id: number]: LearningPath };
  existingSkillPath$: { [id: number]: LearningPath };
  createNewClicked: boolean;
  createNewLPInProgress: boolean;
  noLP: boolean;
  isContentAdded: boolean;
  showActionBtn: boolean;
  type: string;
  IDPpathId: any;
  riskBrigadeType=false;
  riskBrigadeItemType : string = "RiskBrigadeCourse";

  constructor(
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _LearningPathService: LearningPathService,
    public dialogRef: MatDialogRef<AddContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _AddContentService: AddContentService,
    private snackBar: MatSnackBar,
    private idpService: IdpService,
    private _router: Router,
    private _InsightService: AppInsightService,
    private _dialog: MatDialog,
    private addedContentDataService: AddedContentDataService,
    private cloudlabStoreService: CloudlabStoreService,
    private global: Globals
  ) {
    this.learningPathValidateFail = false;
    this.addConfirmationPopup = false;
    this.addInfo = 'Please select a learning path from existing or create new';
    this.addProcess = {
      loading: false,
      loaded: false
    };
  }
  ngOnInit() {
    this.learningPathTitle = '';
    this.type = 'others';
    this.createNewClicked = false;
    this.showActionBtn = false;
    if (this.data != null) {
      this.getLearningPath();
    } else {
      this.createNewClicked = true;
    }
    if (this.idpService.isValidIDPLink) {
      this.addToIdpSetup();
    }

  }

  getLearningPath() {
    //To Add Courses in the Rist Brigade Learning Path
    if(this.data.ItemType==this.riskBrigadeItemType){
    //Get Mandatory Learning Path for Risk
    this.getUserCreatedLPs$ = this.userDetailsStore.select(
      fromUserDetailsStore.getMandatoryLPsSelector
    );
    this.getUserCreatedLPs$.subscribe(res => {
      if (res.length === 0) {
        this.noLP = true;
      } else {
        this.noLP = false;
      }
      console.log("rrrrrrrrrrrrrrr", res);
     this.AddTolearningPathDetails = res.find(x => x.PathName == "Risk Brigade");
     if(this.AddTolearningPathDetails!=undefined){
      this.addConfirmationPopup = true;
      this.addInfo =        'Add content to ' + "'" + this.AddTolearningPathDetails.PathName + "'" + ' Learning path';
     }
     this.riskBrigadeType=true;
    });
  }



    this.noLP = false;
    this.userDetailsStore.dispatch(
      new fromUserDetailsStore.UserDetailsGetDetails()
    );
    this.getLearningPathLoading$ = this.userDetailsStore.select(
      fromUserDetailsStore.getUserDetailLoading
    );
    this.getLearningPathLoaded$ = this.userDetailsStore.select(
      fromUserDetailsStore.getUserDetailLoaded
    );
    // this.learningPathEntities$ = this.userDetailsStore.select(
    //   fromUserDetailsStore.getUserDetailLearningPath
    // );

    // this.learningPathEntities$.subscribe(res => {
    //   const filterPipe = new ArrayPropertyFilterPipe();
    //   const nonMandatoryLP = filterPipe.transform(res, {
    //     property: 'IsMandatory',
    //     flag: false
    //   });
    //   const existingLP = filterPipe.transform(nonMandatoryLP, {
    //     property: 'IsDeleted',
    //     flag: false
    //   });

    //   if (existingLP.length === 0) {
    //     this.noLP = true;
    //   } else {
    //     this.noLP = false;
    //   }
    //   this.existingLearningPath$ = existingLP;
    // });

  

    this.getUserCreatedLPs$ = this.userDetailsStore.select(
      fromUserDetailsStore.getUserCreatedLPsSelector
    );
    this.getUserCreatedLPs$.subscribe(res => {
      res = res.filter(x => x.PathName !== this.global.degreedLPName);
      if (res.length === 0) {
        this.noLP = true;
      } else {
        this.noLP = false;
      }
      this.existingLearningPath$ = res;
    });

    this.getSkillingLPs$ = this.userDetailsStore
      .select(fromUserDetailsStore.getSkillPathsSelector);
    this.getSkillingLPs$.subscribe(res => {
      if (res.length === 0) {
        this.existingSkillPath$ = [];
      } else {
        this.existingSkillPath$ = res;
      }
    });

  }
  addContentToLearningPath(learningPath) {
    this.AddTolearningPathDetails = learningPath;
    this.addConfirmationPopup = true;
    this.addInfo =
      'Add content to ' + "'" + learningPath.PathName + "'" + ' Learning path';

    //this.proceedToAdd();
  }
  ProceedToAdd() {
    if(this.data?.contentData!=null || this.data?.contentData!=undefined){
      this.addedContentDataService
      .IsContentAddedToLP(this.data.contentData)
      .then((response: any) => {
        if (response.IsAdded) {
          var error={status:409};
          this.errorAddingToLP(error);
        }else{
          this.addContentToLp();
        }
      });
    }else{
      this.addContentToLp();
    }
  }

  addContentToLp(){
    this.addProcess.loading = true;
    if (this.idpService.isValidIDPLink) {
      this.addToIdp();
    } else {
      this._AddContentService
        .addContent(this.AddTolearningPathDetails, this.data)
        .then((result: any) => {
          this.type = 'self';
          setTimeout(() => {
            this.showActionBtn = true;
            this.addProcess.loaded = true;
            this.addProcess.loading = false;
            this.addInfo =
              'Content successfully added to ' +
              "<span class='clickable'>" +
              this.AddTolearningPathDetails.PathName +
              '</span>';
          }, 1000);

          this.isContentAdded = true;
          if (this.data.ItemType.toLowerCase() == 'course' || this.data.ItemType.toLowerCase() == 'riskbrigadecourse') {
            this.cloudlabStoreService.fetchLabsInBatch([this.data.Id]);
          }
          const insightPayload = {
            Type: this.data.ItemType,
            Action: 'Added',
            Location: this.data.addedLocation
          };
          this._InsightService.trackEvent('Add Content', insightPayload);
        })
        .catch(error => {
          this.errorAddingToLP(error);
        });
    }
  }

  errorAddingToLP(error:any){
    this.type = 'others';
          this.showActionBtn = true;
          this.addProcess.loaded = true;
          this.addProcess.loading = false;
          this.isContentAdded = false;
          if (error.status === 409) {
            this.addInfo = 'Content already exists';
            this.isContentAdded = true;
          } else {
            this.addInfo = 'An Error has occured during adding';
          }
          const insightPayload = {
            Action: 'Add click event',
            Category: 'Add',
            Description:
            'Added on ' +
            this.data.addedLocation +
            ', with reason ' +
            this.addInfo
          };
          this._InsightService.trackEvent('Error', insightPayload);
  }



  goBackToLPSelection() {
    this.addInfo = 'Please select a learning path from the following';
    this.addConfirmationPopup = false;
  }
  createLearningPath() {
    this.createNewLPInProgress = true;
    this._LearningPathService
      .checkLpExistAndAdd(
      this.learningPathTitle.trim(),
      this.existingLearningPath$,
      this.existingSkillPath$
      )
      .then(result => {
        this.createNewLPInProgress = false;
        this.learningPathTitle = '';

        // this.userDetailsStore.dispatch(
        //   new fromUserDetailsStore.UserDetailsAddLearningPath(result)
        // );
        this.createNewClicked = false;
        this.openSnackBar();
        // this.addContentToLearningPath(result);
      })
      .catch(error => {
        this.createNewLPInProgress = false;
        this.learningPathValidateFail = true;
        this.learningPathValidateError = error.errorMessage;
        //this.openLPCreationDialog(error.errorMessage);
      });
  }
  createLPKeyEvent(event) {
    // clear error message on any key event
    this.learningPathValidateFail = false;
    // create learning path on enter
    if (13 === event.keyCode) this.createLearningPath();
  }
  closeAdd() {
    this.dialogRef.close(this.isContentAdded);
  }
  openSnackBar() {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'end';
    this.snackBar.open(
      'Learning Path has been created successfully!',
      undefined,
      config
    );
  }
  actionBtnClicked() {
    if (this.isContentAdded) {
      this.closeAdd();
    } else if (!this.isContentAdded) {
      this.addConfirmationPopup = false;
    }
    this.createNewClicked = false;
    this.showActionBtn = false;
    this.addInfo = 'Please select a learning path from existing or create new';
  }
  addToIdpSetup() {
    this.addConfirmationPopup = true;
    this.addInfo = 'Add content to My Development Plan learning path.';

    if (
      'true' == this.data.AccountPackage ||
      'true' == this.data.ProjectPackage
    ) {
      this.messagePopup('IDP_APorPPDisable');
    }
  }
  addToIdp() {
    this.idpService
      .addCourseToIDP(this.data)
      .then(response => {
        this.showActionBtn = true;
        this.addProcess.loaded = true;
        this.addProcess.loading = false;
        this.type = 'idp';
        this.addInfo =
          'Content successfully added to ' +
          "<span class='clickable'>" +
          'My Development Plan' +
          '</span>';

        this._AddContentService.updateIdpContent(response);
        this.idpService.isValidIDPLink = false;
        this.idpService.idpGuid = '';
        this.isContentAdded = true;
        const insightPayload = {
          Type: this.data.ItemType,
          Action: 'Added',
          Location: this.data.addedLocation
        };
        this._InsightService.trackEvent('Add Content', insightPayload);
      })
      .catch(error => {
        this.type = 'other';
        const insightPayload = {
          Action: 'Error on Adding to IDP',
          Category: 'Add',
          Description:
          'Added on ' + this.data.addedLocation + ', with reason ' + error
        };
        this._InsightService.trackEvent('Error', insightPayload);
        if (
          error ===
          'FAILED- Course already exist in the My Development Plan/another Learning Path.'
        ) {
          this.messagePopup('packageExists');
        } else if (
          error === 'FAILED - User has already completed the course/package.'
        ) {
          this.messagePopup('completedCourseIDP');
        } else if (error === 'Invalid User Guid') {
          this.messagePopup('invalidIDPGuid');
        } else {
          this.messagePopup('IDPError');
        }
      });
  }

  messagePopup(state) {
    switch (state) {
      case 'packageExists':
        this.addInfo = 'Content already exists';
        break;
      case 'IDP_APorPPDisable':
        this.addInfo =
          'Account or project package is not addable into My Development Plan learning path.';
        break;
      case 'completedCourseIDP':
        this.addInfo = 'You have already completed this course/package.';
        break;
      case 'invalidIDPGuid':
        this.addInfo = 'Authentication from My Development Plan Failed!';
        break;
      case 'IDPError':
        this.addInfo =
          'Unable to add Course/Package to My Development Plan LP, please try again later.';
        break;
    }
    this.showActionBtn = false;
    this.addProcess.loaded = true;
    this.addProcess.loading = false;
    this.isContentAdded = false;
  }
  navigateAcrossLP(type) {
    if (type === 'self') {
      this.closeAdd();
      setTimeout(() => {
        if(this.riskBrigadeType){
          this._router.navigate([
            'learningpath/category/' +
            'self' +
            '/id/' +
            this.AddTolearningPathDetails.PathName
          ]);
        }else{
        this._router.navigate([
                  'learningpath/category/' +
                  'self' +
                  '/id/' +
                  this.AddTolearningPathDetails.PathId
                ]);
        }
      }, 1000);
    } else if (type === 'idp') {
      this.closeAdd();
      setTimeout(() => {
        this._router.navigate([
          'learningpath/category/mandatory/id/My Development Plan'
        ]);
      }, 1000);
    }
  }

  openLPCreationDialog(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      Msg: msg
    };
    this._dialog.open(CreateLpPopupComponent, dialogConfig);
  }
}
