import { Component, OnInit } from '@angular/core';
import { MyPageService } from '../my-page.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../globals';

@Component({
  selector: 'yorbit-external-learnings',
  templateUrl: './external-learnings.component.html',
  styleUrls: ['./external-learnings.component.scss']
})
export class ExternalLearningsComponent implements OnInit {
  constructor(
    private _mypageSvc: MyPageService,
    private route: ActivatedRoute,
    private globals: Globals,
    private router: Router
  ) {}

  isExternalLearningsLoading: boolean;
  externalLearningCourses: any;
  dataCopy: any;
  role: string;
  MId: string;
  url:string;
  isFilterApplied: boolean;
  filter: Array<boolean>;
  certificateUploadURL: string;

  ngOnInit() {
    this.externalLearningCourses = [];
    this.isFilterApplied = false;
    this.filter = [false, false, false];
    this.filter[0] = true;
    this.route.params.subscribe(params => {
      this.role = params.role;
      this.MId = params.id;
    });

   this.url= this._mypageSvc.getCertificateUploadURL();
    let link =
      '<a [href]="url">' + this.url + '</a>';
    this.certificateUploadURL =
      "Use the 'Certificate Upload' feature on Shoshin School " +
      link +
      ' to upload your learnings.';
    this.getAllExternalLearnings();
  }

  getAllExternalLearnings() {
    this.isExternalLearningsLoading = true;
    this.isFilterApplied = false;
    this.externalLearningCourses = [];
    this.dataCopy = [];
    let MID = '';
    if (this.role === 'self') {
      MID = this.globals.MId;
    } else if (this.role == 'learner') {
      MID = this.MId;
    }
    this._mypageSvc.getAllExternalLearningsByLearner(MID).subscribe(
      data => {
        this.isExternalLearningsLoading = false;
        this.externalLearningCourses = data;
        this.dataCopy = data.map(x => x);
      },
      error => {
        this.isExternalLearningsLoading = false;
      }
    );
  }

  getPending() {
    this.externalLearningCourses = [];
    this.isFilterApplied = true;
    this.clearFilters();
    this.filter[1] = true;
    this.dataCopy.forEach(element => {
      if (element.CertificateStatus.toLowerCase() == 'pending') {
        this.externalLearningCourses.push(element);
      }
    });
  }

  getActioned() {
    this.externalLearningCourses = [];
    this.isFilterApplied = true;
    this.clearFilters();
    this.filter[2] = true;
    this.dataCopy.forEach(element => {
      if (element.CertificateStatus.toLowerCase() != 'pending') {
        this.externalLearningCourses.push(element);
      }
    });
  }
  removeFilter() {
    this.externalLearningCourses = [];
    this.isFilterApplied = false;
    this.clearFilters();
    this.filter[0] = true;
    this.dataCopy.forEach(element => {
      this.externalLearningCourses.push(element);
    });
  }

  clearFilters() {
    this.filter.forEach((row, index) => {
      this.filter[index] = false;
    });
  }

  navigateToCertificateUpload(){
   // this.router.navigate([this.url]);
   this.router.navigate(['certificateUpload']);
  }
}
