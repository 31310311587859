import {
  Injectable,
  NgModuleFactory,
 // NgModuleFactoryLoader,
  Inject,
  Injector,
  ComponentFactory
} from '@angular/core';
import { Observable, from } from 'rxjs';

import {
  DynamicComponentManifest,
  DYNAMIC_COMPONENT,
  DYNAMIC_COMPONENT_MANIFESTS,
  DYNAMIC_MODULE
} from './dynamic-module-loader-manifest.interface';

@Injectable()
export class DynamicComponentLoaderService {
  moduleFactoryLoader: any;
  constructor(
    @Inject(DYNAMIC_COMPONENT_MANIFESTS)
    private manifests: DynamicComponentManifest[],
   // private moduleFactoryLoader: NgModuleFactoryLoader<any>,
    private injector: Injector
  ) {}
  getComponentFactory<T>(componentId: string): Observable<ComponentFactory<T>> {
    const manifest = this.manifests.find(m => m.componentId === componentId);
    if (!manifest) {
      throw new Error(
        `DynamicComponentLoader: Unknown componentId "${componentId}"`
      );
    }
    const path = manifest.loadChildren;

    const compFactoryPromise = this.load<T>(path, componentId, this.injector);
    return from(compFactoryPromise);
    
  }
  load<T>(path, componentId, injector):Promise<ComponentFactory<T>> {
    return new this.moduleFactoryLoader
      .load(path)
    .then(ngModuleFactory =>
      this.loadFactory<T>(ngModuleFactory, componentId, injector)
    );
  }
  loadFactory<T>(ngModuleFactory: NgModuleFactory<any>,
    componentId: string,
    injector?: Injector):Promise<ComponentFactory<T>> {
    
      const moduleRef = ngModuleFactory.create(this.injector);
      // Read from the moduleRef injector and locate the dynamic component type
      const dynamicComponentType = moduleRef.injector.get(DYNAMIC_COMPONENT);
      if (!dynamicComponentType) {
        const dynamicModule: DynamicComponentManifest = moduleRef.injector.get(DYNAMIC_MODULE);
        if (!dynamicModule) {
          throw new Error(
            'DynamicComponentLoader: Dynamic module for' +
              ` componentId "${componentId}" does not contain` +
              ' DYNAMIC_COMPONENT or DYNAMIC_MODULE as a provider.'
          );
        }
        if (dynamicModule.componentId !== componentId) {
          throw new Error(
            'DynamicComponentLoader: Dynamic module for' +
              `${componentId} does not match manifest.`
          );
        }
        const path = dynamicModule.loadChildren;

        if (!path) {
          throw new Error(`${componentId} unknown!`);
        }

        return this.load<T>(path, componentId, injector);
      }
      // Resolve this component factory
      return Promise.resolve(
        moduleRef.componentFactoryResolver.resolveComponentFactory<T>(
          dynamicComponentType
        )
      );
    };

}
