<mat-dialog-content class="expertList">
  <!--Close Popup Icon-->
  <div class="closeExpert">
    <button mat-button mat-icon-button (click)="closeExpert()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!--Course Name-->
  <div class="courseName">{{ data.Title }}</div>

  <!--Mat Divider-->
  <mat-divider></mat-divider>

  <!--Experts Label-->
  <div class="expertLabel">Experts</div>

  <!--Mat Divider-->
  <mat-divider></mat-divider>

  <!--No Experts Message-->
  <div *ngIf="data.Experts == null || (data.Experts !== null && data.Experts.length == 0)" class="noExpertMsg">
    No Experts found for this course
  </div>

  <!--Expert List-->
  <div class="expertListContainer" *ngIf="data.Experts !== null && data.Experts.length > 0">
    <div class="expertList" *ngFor="let expert of data.Experts">
      <yorbit-expert-profile class="expertProfile" [expert]="expert"></yorbit-expert-profile>
    </div>
  </div>
</mat-dialog-content>