import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { throwError,BehaviorSubject, Observable} from 'rxjs';
//import { PARAMETERS } from '@angular/core/src/util/decorators';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { ContentUploadStatusPopupComponent } from '../content-upload-status-popup/content-upload-status-popup.component';
import { CanDeactivate } from '@angular/router';
import { ContentUploadComponent } from './content-upload.component';

@Injectable({
  providedIn: 'root'
})
export class ContentUploadService {
  _baseURL: string;
  canLoadContentUploadComponent: BehaviorSubject<
    boolean | null
  > = new BehaviorSubject<boolean>(null);
  isApproved: boolean;
  isInCUComponent: boolean;

  constructor(
    private _http: HttpClient,
    private _envSvc: EnvironmentService // public sessionTimeoutAlert: MatDialogRef<ContentUploadStatusPopupComponent>
  ) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
    this.isApproved = false;
  }
  getStatusToShowContentUploadComp() {
    return this.canLoadContentUploadComponent.asObservable();
  }
  getTaxonomy() {
    return this._http
      .get<any>(this._baseURL + 'Course/GetTaxonomy')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getVendor() {
    return this._http
      .get<any>(this._baseURL + 'GetVendorList')
      .pipe(catchError((error: any) => (error.json())));
  }

  getCourseTypeList(expertise) {
    return this._http
      .get<any>(this._baseURL + 'GetCourseType/'+expertise)
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getCourseModeOfPayment() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseModeOfPayment')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getCourseClassification() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseClassification')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getCourseInfrastructure() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseInfrastructure')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getCourseInfrastructureType() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseInfrastructureType')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getCourseLogin() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseLogin')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getCourseLoginType() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseLoginType')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getCourseSessionType() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseSessionType')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getCourseCompletion() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseCompletion')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getCourseCompletionType() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseCompletionType')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getCourseEligibility() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseEligibility')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getCourseAccessibility() {
    return this._http
      .get<any>(this._baseURL + 'GetCourseAccessibility')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  insertContentFormFor201(formData, header, courseUniqueId, type) {
    const URL = 'ContentInsertFor201/' + courseUniqueId + '/' + type;
    return this._http.post<any>(this._baseURL + URL, formData, header);
  }
  uploadToBlob(
    file,
    courseUniqueId: string,
    academy: string,
    courseName: string,
    fileType: string,
    fileSubType: string,
    sessionId: string
  ): any {
    const fd = new FormData();
    const payload = {
      courseUniqueId: courseUniqueId,
      academy: academy,
      courseName: courseName,
      fileType: fileType,
      fileSubType: fileSubType
    };
    fd.append('courseUniqueId', courseUniqueId);
    fd.append('academy', academy);
    fd.append('courseName', courseName);
    fd.append('fileType', fileType);
    fd.append('fileSubType', fileSubType);
    fd.append('sessionId', sessionId);

    fd.append('file', file);
    //const httpHeaders = new HttpHeaders().append('Content-Type', 'undefined');
    if(fileType=='CourseProcedureReferenceDocument'){
      return this._http
      .post(this._baseURL + 'AzureBlob/Upload/201CourseProcedureReferenceDocument/'+courseUniqueId, fd)
      .toPromise();
    }
    else{
    return this._http
      .post(this._baseURL + 'AzureBlob/Upload/ContentUpload201FilesFromUI/', fd)
      .toPromise();
  }
  }

  checkIfCourseNameExists(courseName) {
    let payload = {
      CourseName: courseName
    };
    return this._http
      .post<any>(this._baseURL + 'CheckCourseNameExists', payload)
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }
  getAllYorbitcourses() {
    return this._http
      .get<any>(this._baseURL + 'GetAllCourses')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }
  getContentUpload201Details(courseUniqueId) {
    return this._http
      .get<any>(this._baseURL + 'GetContentUpload201Details/' + courseUniqueId)
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }
  getCourseProcedureReferenceDocument(courseUniqueId) {
    return this._http
      .get<any>(this._baseURL + 'Course/CourseProcedureReferenceDocument/' + courseUniqueId)
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }
  checkCourseNameExistsForEdit(courseName, courseUniqueId) {
    let payload = {
      CourseName: courseName
    };
    const URL = 'CheckCourseNameExistsForEdit/' + courseUniqueId;
    return this._http
      .post<any>(this._baseURL + URL, payload)
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  resetAssessmentGroup(assessmentGroup) {
    (assessmentGroup.assesmentInfrastructure = [
      false,
      false,
      false,
      false,
      true
    ]),
      (assessmentGroup.assesmentInfrastructureType = [
        null,
        null,
        null,
        null,
        null,
        null,
        'None of the Above'
      ]),
      (assessmentGroup.assessmentDescription = null),
      (assessmentGroup.assessmentScheduledDate = null);
    (assessmentGroup.assessmentType = ''),
      (assessmentGroup.questionPaperLink = ''),
      (assessmentGroup.evaluationTemplate = ''),
      (assessmentGroup.evaluationTemplateLink = ''),
      (assessmentGroup.evaluatorEmail = ''),
      (assessmentGroup.evaluatorName = ''),
      (assessmentGroup.evaluatorType = ''),
      (assessmentGroup.evaluatorVendor = ''),
      (assessmentGroup.isAssessmentScheduled = false),
      (assessmentGroup.maxScore = ''),
      (assessmentGroup.onlineQuestionPaper = ''),
      (assessmentGroup.onlineQuestionPaperLink = ''),
      (assessmentGroup.passingScore = ''),
      (assessmentGroup.questionPaper = ''),
      (assessmentGroup.questionPaperLink = ''),
      (assessmentGroup.toolName = '');
    return assessmentGroup;
  }

  getBadgeDetails(badgeId) {
    const URL = 'GetBadgeDetail/' + parseInt(badgeId);
    return this._http
      .get<any>(this._baseURL + URL)
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  deleteBlobFile(url) {
    const URL = 'DeleteBlobFileWithUrl';
    let payload = {
      Url: url
    };
    return this._http
      .post<any>(this._baseURL + URL, payload)
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  deleteBlobEntriesFromTable(type, value, uniqueId) {
    const URL = 'DeleteTableEntryAfterBlobDeletion';
    let payload = {
      CourseUniqueId: uniqueId,
      Type: type,
      Value: value
    };
    return this._http
      .post<any>(this._baseURL + URL, payload)
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }
  getContentUploadUserRoles() {
    return this._http
      .get<any>(this._baseURL + 'GetContentUploadUserRoles')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }
  getCourseProficiencyList() {
    return this._http
      .get<any>(this._baseURL + 'CourseProficiency');
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }
}

@Injectable()
export class ContentUploadGuardDeactivate
  implements CanDeactivate<ContentUploadComponent> {
  canExitQuiz: boolean;
  hasAttempts: boolean;
  constructor(private cuService: ContentUploadService) {}
  canDeactivate(component: ContentUploadComponent): boolean {
    if (this.cuService.isInCUComponent) {
      //  if (!this.cuService.isApproved && this.cuService.isInCUComponent) {
      let msg = '';
      if (!this.cuService.isApproved) {
        msg =
          'Changes you made (if any) and unsaved will be lost. Are you sure you want to Exit Form?';
      } else {
        msg = 'Are you sure you want to Exit Form?';
      }
      let res = confirm(msg);
      return res;
    }
    return true;
  }

}
