<div class="outer">
  <h2>PACKAGE <span>Content Upload Form</span></h2>

  <div class="first-half">
    <h5>Package Type</h5>

    <!--Radio buttons for selecting course type-->
    <form name="packageUpload" [formGroup]='packageUploadFormGroup'>

      <input type="radio" name="courseType" value="yorbit" formControlName="courseType" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
      <label for="yorbitCourse">Shoshin School</label>

      <input type="radio" name="courseType" value="account" formControlName="courseType" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
      <label for="accountCourse">Account</label>

      <input type="radio" name="courseType" value="project" formControlName="courseType" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
      <label for="projectCourse">Project</label>

      <!--Upper half-->

      <!--Form for Yorbit Course-->
      <!--Dropdown for selecting Academy name-->
      <div *ngIf="packageUploadFormGroup.get('courseType').value == 'yorbit'">
        <br />
        <div class="inputGroup1Yorbit">
          <label for="academy">Academy</label>
          <select name="academy" formControlName="academy" (change)="changeGenreOptions()" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
            <option value="" selected disabled *ngIf="academyHiddenState == false">Select an Academy</option>
            <option *ngFor="let academy of academyList" title="{{academy}}" [value]="academy">
              {{(academy.length>30)?(academy.slice(0,30)+"..."):(academy) }}</option>
          </select> <br />
          <span class="error" *ngIf="academyError == true">* Please choose an academy to which the package
            belongs</span>
        </div>

        <!--Dropdown for selecting Genre name-->
        <div class="inputGroup2Yorbit">
          <label for="genre">Genre</label>
          <select name="genre" formControlName="genre" (change)="changeSkillOptions()"  [title]="genreTitle" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
            <option value="" selected disabled *ngIf="genreHiddenState == false">Select a Genre</option>
            <option *ngFor="let genres of genreList" title="{{genres}}" [value]="genres">
              {{(genres.length>30)?(genres.slice(0,30)+"..."):(genres) }}</option>
          </select>
          <br />
          <span class="error" *ngIf="genreError == true">* Please choose a genre to which the package belongs</span>
        </div>

        <!--Dropdown for selecting Skill name-->
        <div class="inputGroup3Yorbit">
          <label for="skill">Skill</label>
          <select name="skill" formControlName="skill" (change)="enableSkillValidation()"  [title]="skillTitle" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
            <option value="" selected disabled *ngIf="skillHiddenState == false">Select a Skill</option>
            <option *ngFor="let skills of skillList" title="{{skills}}" [value]="skills">
              {{(skills.length>30)?(skills.slice(0,30)+"..."):(skills) }}</option>
          </select>
          <br />
          <span class="error" *ngIf="skillError == true">* Please choose a skill to which the package belongs</span>
        </div>
      </div>

      <!--Form for Project and Account Course
    Both has similar UI, so render the required fields using *ngIf-->

      <!--Dropdown for selecting Account name-->
      <div
        *ngIf="packageUploadFormGroup.get('courseType').value == 'project' || packageUploadFormGroup.get('courseType').value == 'account' ">
        <br />
        <div class="inputGroup1">
          <label for="accountName">Account</label>
          <select name="accountName" formControlName="account" (change)="filterAccountCategories(); filterProjects()" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
            <option value="" selected disabled *ngIf="accountHiddenState == false">Select an Account</option>
            <option *ngFor="let accountName of accounts;" title="{{accountName.AccountName}}"
              [value]="accountName.AccountName">
              {{(accountName.AccountName.length>30)?(accountName.AccountName.slice(0,30)+"..."):(accountName.AccountName)
              }}</option>
          </select>
          <br />
          <span class="error" *ngIf="accountError == true">* Please choose an account to which the package
            belongs</span>
        </div>

        <!--Dropdown for selecting Project-->
        <div class="inputGroup2" *ngIf="packageUploadFormGroup.get('courseType').value == 'project'" [title]="projectTitle">
          <label for="projectName">Project</label>
          <select name="projectName" formControlName="project" (change)="filterProjectCategories()" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
            <option value="" selected disabled *ngIf="projectHiddenState == false">Select a Project</option>
            <option *ngFor="let project of projects;" title="{{project.ProjectName}}" [value]="project.ProjectName">
              {{(project.ProjectName.length>30)?(project.ProjectName.slice(0,30)+"..."):(project.ProjectName) }}
            </option>
          </select>
          <br />
          <span class="error" *ngIf="projectError == true">* Please choose a project to which the package belongs</span>
        </div>


        <!--Dropdown for selecting Category-->
        <div class="inputGroup2">
          <label for="categoryName">Category</label>
          <select name="categoryName" formControlName="category" (change)="validateCategory()"  [title]="categoryTitle" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
            <option value="" selected disabled *ngIf="categoryHiddenState == false">Select a Category</option>
            <option *ngFor="let category of categories" title="{{category}}" [value]="category">
              {{(category.length>30)?(category.slice(0,30)+"..."):(category) }}</option>
          </select>
          <br />


          <!--Button for adding new category in Account and Project Packages-->
          <br />
          <div class="inputGroup4" *ngIf="selectedContent == undefined">
            <label for="newCategory">Add New Category</label><br />
            <input type="text" name="customCategory" formControlName="customCategory" placeholder="Enter category name" (keyup)="validateInput('category')" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
            <button name="add" type="button"
              (click)="addNewCategory()" [disabled]="status" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">Add</button>
          </div>
          <span class="error" *ngIf="categoryExistsError == true">* Category already exists</span> 
          <br *ngIf="categoryExistsError == true"/>
          <span class="error" *ngIf="customCategoryError == true">* Please enter a valid category</span> 
          <br *ngIf="customCategoryError == true"/>
          <span class="error" *ngIf="categoryError == true">* Please choose a category to which the package belongs</span>
        </div>
        <br />
      </div>
    </form>
  </div>

  <!--Second half of the UI-->
  <div class="second-half">

    <form name="packageDetails" [formGroup]="packageUploadFormGroup">

      <!--Left part of second half-->

      <!--Input for entering package name-->
      <div class="left">
        <label for="packageName">Package Name</label>
        <input type="text" name="packageName" placeholder="Enter a package name" formControlName="packageName"
          (keyup)="validateInput('name')" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
        <br />
        <span class="error" *ngIf="packageNameError == true">* Please enter a package name</span>
        <span class="error" *ngIf="packageNameExistsError == true">* The package name already exists</span>
        <br />

        <!--Input for entering Package Description-->
        <label for="packageDesc">Package Description</label>
        <textarea name="packageDesc" formControlName="packageDesc"
          placeholder="Enter package description (minimum 150 characters)" (keyup)="validateInput('description')" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
    </textarea > <br />
        <span class="error" *ngIf="packageDescError == true">* Please enter atleast 150 characters</span>
      </div>

      <div class="right-outer">
        <!--Wrapper class for course selection boxes-->

        <!--Header for course selection boxes. Displayed above the course selection boxes-->
        <div class="header">
          <label [ngClass]="[packageUploadFormGroup.get('courseType').value == 'yorbit' ? 'extraPadding' : '' ]">
            Courses under this package
          </label>
        </div>

        <!--Header buttons for Yorbit Course-->
        <div class="options"
             [ngClass]="[packageUploadFormGroup.get('courseType').value == 'yorbit' ? 'alignOptionsYorbit options' : 'options', packageUploadFormGroup.get('courseType').value == 'account' ? 'alignOptionsYorbitAccount options' : 'options', packageUploadFormGroup.get('courseType').value == 'project' ? 'alignOptionsProject options' : 'options'  ]">
          <div class="deleteIcon" (click)="deleteYorbitCourse()" title="Select a course to delete" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
          <div class="downIcon" (click)="moveYorbitCourseDown()" title="Select a course to Re-order" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
          <div class="upIcon" (click)="moveYorbitCourseUp()" title="Select a course to Re-order" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
        </div>

        <!--Header buttons for Account Course-->
        <div *ngIf="packageUploadFormGroup.get('courseType').value != 'yorbit'"
             [ngClass]="[ packageUploadFormGroup.get('courseType').value == 'project' ? 'alignOptionsProject options' : 'options'  ,packageUploadFormGroup.get('courseType').value == 'account' ? 'alignOptionsAccountAccount options' : 'options']">
          <div class="deleteIcon" (click)="deleteAccountCourse()" title="Select a course to delete" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
          <div class="downIcon" (click)="moveAccountCourseDown()" title="Select a course to Re-order" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
          <div class="upIcon" (click)="moveAccountCourseUp()" title="Select a course to Re-order" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
        </div>

        <!--Header buttons for Project Course-->
        <div class="options alignOptionsProject" *ngIf="packageUploadFormGroup.get('courseType').value == 'project'">
          <div class="deleteIcon" (click)="deleteProjectCourse()" title="Select a course to delete" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
          <div class="downIcon" (click)="moveProjectCourseDown()" title="Select a course to Re-order" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
          <div class="upIcon" (click)="moveProjectCourseUp()" title="Select a course to Re-order" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']"></div>
        </div>

        <!--Yorbit Course selction box - displayed in all 3 package types. Padding is reduced for project package to make space for 3 boxes -->
        <div
          [ngClass]="[packageUploadFormGroup.get('courseType').value == 'project' || packageUploadFormGroup.get('courseType').value == 'account'  ? 'right reducePadding' : 'right', packageUploadFormGroup.get('courseType').value == 'project' && selectedYorbitCoursesList.length > 0 ? 'reducePadding displayInlineBlock' : 'right displayTableCell']">
      

          <!--Height and width of the boxes are adjusted according to the type of project selcted to make space-->
          <div class="coursesUnderPackage"
            [ngClass]="[packageUploadFormGroup.get('courseType').value == 'project'? 'coursesUnderPackage customWHP' : 'coursesUnderPackage', selectedContent != undefined ? 'disabledDiv' : '']">
            <ul>
              <li *ngFor="let selectedCourse of selectedYorbitCoursesList; let i = index; trackBy: getDataLengthYorbit;"
                class="yorbitCourses" [ngClass]="yorbitCourseIndex == i ? 'lightGrayBg' : 'transparentBg'" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
                <span class="content yorbitContent" *ngIf="packageUploadFormGroup.get('courseType').value != 'project'"
                  [ngClass]="yorbitCourseIndex == i ? 'lightGrayBg' : 'transparentBg'"
                  (click)="setYorbitCourseIndex($event)" [attr.index]="i">
                  {{ (selectedCourse.Name + " " + selectedCourse.Expertise).length>33?(selectedCourse.Name + " " +
                  selectedCourse.Expertise).slice(0,34) + "..." : (selectedCourse.Name + " " + selectedCourse.Expertise)
                  }}
                </span>

                <span class="content yorbitContent" *ngIf="packageUploadFormGroup.get('courseType').value == 'project'"
                  (click)="setYorbitCourseIndex($event)" [attr.index]="i"
                  [ngClass]="yorbitCourseIndex == i ? 'lightGrayBg' : 'transparentBg'">
                  {{ (selectedCourse.Name + " " + selectedCourse.Expertise).length>24?(selectedCourse.Name + " " +
                  selectedCourse.Expertise).slice(0,25) + "..." : (selectedCourse.Name + " " + selectedCourse.Expertise)
                  }}
                </span>
              </li>
            </ul>
          </div>
          <br />

          <a (click)="openYorbitCourses()" *ngIf="packageUploadFormGroup.get('courseType').value == 'yorbit'" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">Select
            Courses</a>
          <a (click)="openYorbitCourses()" *ngIf="packageUploadFormGroup.get('courseType').value != 'yorbit'" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">Select
            Shoshin School Courses</a>
          <span class="error"
            *ngIf="yorbitCoursesSelectedError == true && packageUploadFormGroup.get('courseType').value != 'project'">*
            Please choose two or more courses</span>

        </div>

        <!--Account Course selction box - displayed in account and project package types. Padding is reduced for project package to make space for 3 boxes -->
        <div
          *ngIf="packageUploadFormGroup.get('courseType').value == 'account' || packageUploadFormGroup.get('courseType').value == 'project'"
          [ngClass]="[packageUploadFormGroup.get('courseType').value == 'project'  ? 'right reducePadding' : 'right', packageUploadFormGroup.get('courseType').value == 'project' && selectedYorbitCoursesList.length > 0 ? 'reducePadding displayInlineBlock' : 'right displayTableCell', selectedContent != undefined ? 'disabledDiv' : '']">
       
          <!--Height and width of the boxes are adjusted according to the package type selcted to make space-->
          <div
            [ngClass]="[packageUploadFormGroup.get('courseType').value == 'project'? 'coursesUnderPackage customWHP' : 'coursesUnderPackage', selectedContent != undefined ? 'disabledDiv' : '']">
            <ul>
              <li
                *ngFor="let selectedCourse of selectedAccountCoursesList; let i = index; trackBy: getDataLengthAccount;"
                class="accountCourses" [ngClass]="actCourseIndex == i ? 'lightGrayBg' : 'transparentBg'" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
                <span class="accountContent content" 
                      (click)="setactCourseIndex($event)" 
                      [attr.index]="i"
                      [ngClass]="actCourseIndex == i ? 'lightGrayBg' : 'transparentBg'">
                  {{ (selectedCourse.Name + " " + selectedCourse.Expertise).length>30?(selectedCourse.Name + " " +
                  selectedCourse.Expertise).slice(0,31) + "..." : (selectedCourse.Name + " " + selectedCourse.Expertise)
                  }}
                </span>
              </li>
            </ul>
          </div>

          <br />

          <a (click)="openAccountCourses()" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">Select Account Courses</a>
          <span class="error"
            *ngIf="selectedAccountCoursesError== true && packageUploadFormGroup.get('courseType').value != 'project'">*
            Please choose one or more courses</span>

        </div>

        <!--Project Course selction box - displayed in account and project package types. Padding is reduced for project package to make space for 3 boxes -->
        <div *ngIf="packageUploadFormGroup.get('courseType').value == 'project'"
          [ngClass]="[packageUploadFormGroup.get('courseType').value == 'project'  ? 'right reducePadding' : 'right', packageUploadFormGroup.get('courseType').value == 'project' && selectedYorbitCoursesList.length > 0 ? 'reducePadding displayInlineBlock' : 'right displayTableCell', selectedContent != undefined ? 'disabledDiv' : '']">
         
          <!--Height and width of the boxes are adjusted according to the package type selcted to make space-->
          <div
            [ngClass]="[packageUploadFormGroup.get('courseType').value == 'project'? 'coursesUnderPackage customWHP' : 'coursesUnderPackage', selectedContent != undefined ? 'disabledDiv' : '']">
            <ul>
              <li *ngFor="let selectedCourse of selectedProjectCoursesList;let i = index; trackBy: getDataLengthProject"
                class="projectCourses"
                [ngClass]="projectCourseIndex == i ? 'lightGrayBg' : 'transparentBg'" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">
                <span class="content projectContent" 
                      (click)="setProjectCourseIndex($event)" 
                      [attr.index]="i"
                      [ngClass]="projectCourseIndex == i ? 'lightGrayBg' : 'transparentBg'">
                  {{ (selectedCourse.Name + " " + selectedCourse.Expertise).length>30?(selectedCourse.Name + " " +
                  selectedCourse.Expertise).slice(0,28) + "..." : (selectedCourse.Name + " " + selectedCourse.Expertise)
                  }}
    
                </span>
              </li>
            </ul>
          </div>
          <br />

          <a (click)="openProjectCourses()" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">Select Project Courses</a>
          <span class="error"
            *ngIf="selectedProjectCoursesError == true && packageUploadFormGroup.get('courseType').value != 'project'">*
            Please choose one or more courses</span>
        </div>
        <div class="error-group" *ngIf="packageUploadFormGroup.get('courseType').value == 'project'">
          <span [ngClass]="yorbitCoursesSelectedError == true ? 'error visible' : 'error hidden'">* Please choose one
            more courses</span>
          <span [ngClass]="selectedAccountCoursesError == true ? 'error visible' : 'error hidden'">* Please choose one
            or more courses</span>
          <span [ngClass]="selectedProjectCoursesError == true ? 'error visible' : 'error hidden'">* Please choose one
            or more courses</span>
        </div>
      </div>


    </form>



  </div>
</div>

<div class="buttons">
  <a (click)="onExit()">EXIT FORM</a>
  <button name="proceed" type="button" (click)="proceedAfterValidation()" [disabled]="!(selectedContent == undefined)" [ngClass]="[selectedContent == undefined ? 'allowed' : 'restricted']">PROCEED</button>
</div>