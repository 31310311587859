import { Component, OnInit } from '@angular/core';
import { RiskBrigadeService } from '../../../shared/services/risk-brigade.service';

@Component({
  selector: 'yorbit-advance-courses',
  templateUrl: './advance-courses.component.html',
  styleUrls: ['./advance-courses.component.scss']
})
export class AdvanceCoursesComponent implements OnInit {
  selectedFilter: string = 'all';
  isDataLoading:boolean=false;
  dataList:any=[];
  pageNumber=1;
  showMore=true;
  riskBrigadeItemType : string = "RiskBrigadeCourse";

  constructor(private _riskBrigadeService:RiskBrigadeService) { }

  ngOnInit() {
    this.resetPagination();
    this.getAPIData();
  }

  getAPIData(){
    this.isDataLoading=true;
    this._riskBrigadeService.getLearnData('advance',this.selectedFilter,this.pageNumber).then(
      (data: any) => {
        this.isDataLoading = false;
        if(data!=null){
          if(data.length<20){
            this.showMore=false;
          }
          data.forEach(element => {
            element["ItemType"]=this.riskBrigadeItemType
          });
          this.dataList = this.dataList.concat(data);
          return this.dataList;
        }else{
          this.showMore=false;
        }
      },
      error => {
         this.isDataLoading = false;
         if(this.pageNumber==1){
          this.dataList = [];
          //console.log('Error', error);
         }
         return this.dataList;
      }
    );
  }

  resetPagination(){
    this.pageNumber=1;
    this.dataList =[]
    this.showMore=true;
  }

  clickSelectedFilter(filter) {
    this.selectedFilter = filter;
   this.resetPagination();
    this.getAPIData();
  }

  getMoreRecords(){
    this.pageNumber+=1;
    this.getAPIData();
  }
}
