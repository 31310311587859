import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { YoutubeVideoPlayerComponent } from './youtube-video-player/youtube-video-player.component';
import { O365VideoPlayerComponent } from './o365-video-player/o365-video-player.component';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { VideoPlayerPopupComponent } from "./video-player-popup/video-player-popup.component";
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ScormPlayerComponent } from './scorm-player/scorm-player.component';
import { ScormCompletionPopupComponent } from './scorm-player/scorm-completion-popup/scorm-completion-popup.component';
import { ExternalVideoPlayerComponent } from './external-video-player/external-video-player.component';
import { OtherContentsComponent } from './other-contents/other-contents.component';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/datepicker';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReusableUiModule
  ],
  declarations: [YoutubeVideoPlayerComponent, O365VideoPlayerComponent, VideoPlayerPopupComponent, ScormPlayerComponent, ScormCompletionPopupComponent, ExternalVideoPlayerComponent, OtherContentsComponent],
  exports: [YoutubeVideoPlayerComponent, O365VideoPlayerComponent,ScormPlayerComponent],
 
})
export class VideoPlayersModule {}
