import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ContentTileBrowseService {
  updatedShareCount: BehaviorSubject<any>;
  constructor() {
    this.updatedShareCount = new BehaviorSubject(null);
  }
  getUpdatedShareCount() {
    return this.updatedShareCount.asObservable();
  }
  updateShareCount(data) {
    this.updatedShareCount.next(data);
  }
}
