import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BrowseService } from "../../browse.service";
import * as browseActions from '../actions/browse.action';
import { switchMap, map, catchError, concat, concatMap } from "rxjs/operators";
import { of } from "rxjs";

@Injectable()
export class BrowseEffects{
  userDetailsService: any;
  constructor(
    private actions$: Actions,
    private browseService:BrowseService
  ){}

  
  getAcademyList$ = createEffect(() => this.actions$.pipe(
    ofType(browseActions.BROWSE_GET_ACADEMY_LIST),
      switchMap(() => {
        return this.browseService.getAcademyList().pipe(
          map(
            academyList =>
              new browseActions.BrowseGetAcademyListSuccess(academyList)
          ),
          catchError(error =>
            of(new browseActions.BrowseGetAcademyListFail(error))
          )
        );
      })
    ));

    
    getAcademyContent$ = createEffect(() => this.actions$.pipe(
    ofType(browseActions.BROWSE_GET_ACADEMY_CONTENT),
        switchMap((action:browseActions.BrowseGetAcademyContent) => {
          return this.browseService.getAcademyContent(action.payload).pipe(
            map(
              academyContent =>{
                const Response ={
                  Content:academyContent,
                  AcademyData:action.payload
                }
                return new browseActions.BrowseGetAcademyContentSuccess(Response)
              }
            ),
            catchError(error =>
              of(new browseActions.BrowseGetAcademyContentFail(error))
            )
          );
        })
      ));

}
