import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'characterFilter'
})

/**
 * use as
 * In HTML
 *  <div *ngFor="let Character of (characterArrayList | characterFilter:{'property':'Name','search':'SomeString'})">
 *
 * or
 * for array of string
 *  <div *ngFor="let Character of (characterArrayList | characterFilter:{'search':'SomeString'})">
 *
 *
 * In TS
 * const filterPipe = new CharacterFilterPipe();
 * const filteredCharacterContent = filterPipe.transform(characterArrayList,{'property':'Name','search':'SomeString'});
 * or
 * for array of string
 * const filteredCharacterContent = filterPipe.transform(characterArrayList,{'search':'SomeString'});
 *
 * legends
 * value -> any array of object | string | as here 'characterArrayList'
 * filter -> includes the 'property' to filter (in case it is array of string leave property empty), 'list' to match a value | as here 'Name' and string 'search':'SomeString'
*/

export class CharacterFilterPipe implements PipeTransform {

  transform(value: any, filter?: Ifilter): any {
    if(!value || !filter || 0===filter.search.length){
      return value
    }else{
      let content;
      const result = value.filter(item => {
        if(!filter.property){
          content = item.toLowerCase()
        } else {
          content = item[filter.property].toLowerCase()
        }
        const state=(-1 !== content.indexOf(filter.search.toLowerCase()))
        return state
      });
      return result
    }
  }
}

interface Ifilter{
  property?: string;
  search?: string;
}
