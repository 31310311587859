<mat-dialog-content>
  <div class="closePopupBtn">
    <button
      class="mat-button mat-icon-button"
      (click)="closePopup()"
      [disabled]="processing"
      [ngClass]="{ disabled: processing }"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title" title="{{ data.ItemName }}">
    <div class="heading">{{ data.ItemName }}</div>
  </div>
  <div *ngIf="processing == false && processed == false" class="popup-content">
    <div *ngIf="data.HasCourseInProgress">
      <div class="message1">
        This {{ data.ItemExpertise }} is in progress. Are you sure you want to
        delete {{ data.ItemName }}?
      </div>
      <div class="message1">
        Your progress will be remembered by the system and will reflect if you
        choose to take this course again
      </div>
    </div>
    <div *ngIf="!data.HasCourseInProgress">
      <div class="message1">
        Are you sure you want to delete {{ data.ItemName }}?
      </div>
    </div>
    <div class="actionButtonContainer" *ngIf="!createNewLPInProgress">
      <button
        class="actionButton action-button"
        (click)="onDeleteConfirmation()"
      >
        Yes
      </button>
      <button class="actionButton action-button" (click)="closePopup()">
        No
      </button>
    </div>
  </div>
  <div *ngIf="processing == true">
    <app-preloader></app-preloader>
  </div>
  <div *ngIf="processed == true" class="popup-content">
    <div class="message1" *ngIf="processedSuccessfully">
      {{
        ((data.ItemType | lowercase) === 'familypackage'
          ? 'Package'
          : data.ItemType) +
          ' ' +
          'has been deleted successfully'
      }}
    </div>
    <div class="message1" *ngIf="!processedSuccessfully">Please try later.</div>
    <div class="actionButtonContainer">
      <button class="action-button" (click)="closePopup()">OK</button>
    </div>
  </div>
</mat-dialog-content>
