import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  apiUrl: string;
  cdmMappings = null;
  constructor(
    private http: HttpClient,
    private envService: EnvironmentService
  ) {
    this.apiUrl = this.envService.getEnvironment().apiUrl;
  }

  getCDMMapping() {
    if (this.cdmMappings == null) {
      return this.http
        .get(this.apiUrl + 'CDM/DepartmentMappings')
        .toPromise()
        .then(res => {
          this.cdmMappings = res;
          return res;
        })
        .catch(error => {
          return [];
        });
    } else {
      var promise = new Promise((res, rej) => {
        res(this.cdmMappings);
      });
      return promise;
    }
  }
}
