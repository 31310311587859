import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yorbit-core-councile-tile',
  templateUrl: './core-councile-tile.component.html',
  styleUrls: ['./core-councile-tile.component.scss']
})
export class CoreCouncileTileComponent implements OnInit {

  @Input('coreCouncil') coreCouncil:any;
  
  constructor() { }

  ngOnInit() {
  }


  getNameInitials(){
    var names = this.coreCouncil.Name.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}
