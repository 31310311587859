<div class="related-videos-container" fxLayout="column" [ngClass.xs]="['xs']" [ngClass.md]="['md']"
  [ngClass.gt-md]="['gt-md']">
  <div class="video-thumb" fxFlex="1 1 50%"
    [style.background-image]="videoData.ThumNailImageURl?'url(' + videoData.ThumNailImageURl + ')' :'url(../../../../assets/images/DandI/demo-video.jpg)'"
    fxLayout="row" fxLayoutAlign="center center">
    <img class="video-icon" src="../../../../assets/images//DandI/ic-play.png" alt="" [ngClass.xs]="['xs']"
      [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']">
  </div>
  <div class="video-description" fxFlex="1 1 50%" fxLayout="column wrap" fxLayoutGap.xs="5px" fxLayoutGap.md="5px"
    fxLayoutGap.gt-md="7px">
    <div class="title" [ngClass.xs]="['xs']" *ngIf="videoData.Name">{{videoData.Name}}</div>
    <div class="description" [ngClass.xs]="['xs']" [ngClass.md]="['md']">{{videoData.Description}}</div>

    <div>
      <span class="video-tag">
        Video
      </span>
    </div>
  </div>
</div>