import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import * as fromUserDetailsStore from '../user-details/store';
import { Store } from '@ngrx/store';
import { LearningPathService } from '../../learning-path/learning-path.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkflowServiceService {
  // courseReqId: any;
  config: Ienvironment;
  reqIdWorkflowPair: any;
  reqIdList: any;
  subs: any;
  canShowWorkflowMsg: any;
  canNotShowWorkflowMsg: any;

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _lpSvc: LearningPathService
  ) {
    this.config = this._envSvc.getEnvironment();
    //Workflow status from DB
    this.canShowWorkflowMsg = ['APPROVED', 'PENDING', 'UNDER REVIEW'];
    this.canNotShowWorkflowMsg = [
      //Workflow status from DB
      'CANCELLED',
      'COMPLETED',
      'NOT STARTED',
      'ON HOLD',
      'REJECTED',
      //Workflow status manipulated
      'START COURSE',
      'COURSE COMPLETED',
      'PRE-APPROVED',
      'COURSE COMPLETED - RESIGNED'
     // 'NOT CLEARED'
    ];
    this.reqIdList = [];
    this.reqIdWorkflowPair = <BehaviorSubject<any[]>>new BehaviorSubject([]);
  }

  getSavedWorkflowStatus(courseRequestId) {}

  getWorkflowStatusforId(courseRequestId, data) {
    const promise = new Promise((resolve, reject) => {
      let isPresent =
        data.filter(row => row.requestID == courseRequestId).length == 0
          ? false
          : true;
      if (!isPresent) {
        this.http
          .get(
            this.config.apiUrl +
              'Course/RequestStatusDetails/' +
              courseRequestId
          )
          .toPromise()
          .then(
            (list: any) => {
              if (!isPresent) {
                data.push({
                  requestID: courseRequestId,
                  WorkflowStatus:
                    list && list.WorkflowStatus ? list.WorkflowStatus : null
                });
                this.reqIdWorkflowPair.next(data);
              }
              resolve(true);
            },
            error => {
              resolve(false);
            }
          );
        // }
        // });
      }
    });
    return promise;
  }

  getAll201WorkflowStatusOld() {
    const userDefinedPaths = this._lpSvc.getUserCreatedLPs();
    const systemDefinedPaths = this._lpSvc.getSystemGeneratedLPs();
    const skillPaths = this._lpSvc.getSkillPaths();

    if (userDefinedPaths) {
      userDefinedPaths.forEach(lp => {
        this.getValidRequestIds(lp);
      });
    }
    if (systemDefinedPaths) {
      systemDefinedPaths.forEach(lp => {
        this.getValidRequestIds(lp);
      });
    }
    if (skillPaths) {
      skillPaths.forEach(lp => {
        this.getValidRequestIds(lp);
      });
    }
    if (this.reqIdList.length > 0) {
      this.reqIdList.forEach(reqId => {
        const sub = this.reqIdWorkflowPair.subscribe(list => {
          // if (sub) sub.unsubscribe();
          let isPresent =
            list.filter(row => row.requestID == reqId).length == 0
              ? false
              : true;
          if (!isPresent) {
            this.getWorkflowStatusforId(reqId, list).then(data => {
              //do nothing
            });
          }
        });
      });
    }
  }
  getAll201WorkflowStatus() {
    this.http
      .get(this.config.apiUrl + 'Course/AllRequestStatusDetails')
      .toPromise()
      .then((res: any) => {
        if (res && res.length > 0) {
          this.reqIdWorkflowPair.next(res);
        }
      });
  }
  getValidRequestIds(lp) {
    if (lp.PackageList.length > 0) {
      lp.PackageList.forEach((item, index) => {
        if (
          item.ItemExpertise == '201' &&
          item.ItemType.toLowerCase() == 'course'
        ) {
          if (item.RequestId != null && item.RequestId > 0) {
            if (this.reqIdList.indexOf(item.RequestId) == -1) {
              this.reqIdList.push(item.RequestId);
            }
          }
        }
      });
    }
  }
}
