import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VideoPlayersService } from '../../shared/video-players/video-players.service';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../shared/user-details/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'yorbit-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  quizProgressPopup: boolean;
  showActionBtn: boolean;
  message1: any;
  message2: any;
  btnLabel: string;
  canRetakeQuiz: boolean;
  progressSub: Subscription;
  attemptsCheck: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private videoPlayerService: VideoPlayersService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>
  ) {
    this.data = data;
    this.quizProgressPopup = this.data.quizProgressPopup ? true : false;
    this.showActionBtn = true;
    this.message1 = this.data.message1;
    this.message2 = this.data.message2;
    this.btnLabel = 'OK';
    this.canRetakeQuiz = false;
    this.attemptsCheck = this.data.attemptsCheck ? true : false;
  }

  ngOnInit() {
    if (
      (this.data.isMandatoryCourse || this.data.isVideoProgressMandated) &&
      this.quizProgressPopup &&
      !this.attemptsCheck
    ) {
      this.message1 =
        'Please wait while we are checking your video progress...';
      this.message2 = '';
      // this.videoPlayerService.updateQuizPopUpStream(true);
      this.captureCourseProgress();
    } else if (
      !this.data.isMandatoryCourse &&
      !this.data.isVideoProgressMandated &&
      this.quizProgressPopup &&
      !this.attemptsCheck
    ) {
      this.message1 = this.data.message1;
      this.message2 = this.data.message2;
      this.btnLabel = 'OK';
      this.canRetakeQuiz = false;
    }
  }

  closeDialog(data) {
    this.dialogRef.close(data);
  }

  captureCourseProgress() {
    this.showActionBtn = false;
    setTimeout(() => {
      this.videoPlayerService.captureCachedProgress().then(data => {
        this.progressSub = this.userDetailsStore
          .select(
            fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
              this.data.courseId
            )
          )
          .subscribe(course => {
            if (course && course.CourseId) {
              course.Progress = parseInt(course.Progress);
              if (this.data.isMandatoryCourse && course.Progress >= 50) {
                this.message1 = 'Content Progress: ' + course.Progress + '%';
                this.btnLabel = this.data.takeOrRetake + ' Quiz';
                this.canRetakeQuiz = true;
              } else if (
                this.data.isVideoProgressMandated &&
                course.Progress == 100
              ) {
                this.message1 = 'Content Progress: ' + course.Progress + '%';
                this.btnLabel = this.data.takeOrRetake + ' Quiz';
                this.canRetakeQuiz = true;
              } else {
                this.message1 = 'Content Progress: ' + course.Progress + '%';
                if (this.data.isMandatoryCourse) {
                  this.message2 =
                    'Watch at least <span style="font-weight:bold;color:#fff">50%</span> of the course content to ' +
                    this.data.takeOrRetake +
                    ' the quiz.';
                } else if (this.data.isVideoProgressMandated) {
                  this.message2 =
                    'Please watch <span style="font-weight:bold;color:#fff">100%</span> of the course content to ' +
                    this.data.takeOrRetake +
                    ' the quiz.';
                }
                this.btnLabel = 'OK';
                this.canRetakeQuiz = false;
              }
            }
            this.showActionBtn = true;
          });
        if (this.progressSub) this.progressSub.unsubscribe();
      });
    }, 4000);
  }

  ngOnDestroy() {
    if (this.progressSub) this.progressSub.unsubscribe();
  }
}
