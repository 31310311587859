import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-create-lp-popup',
  templateUrl: './create-lp-popup.component.html',
  styleUrls: ['./create-lp-popup.component.scss']
})
export class CreateLpPopupComponent implements OnInit {
 
  msg:string;
  constructor(@Inject(MAT_DIALOG_DATA) data,
  private dialogRef: MatDialogRef<CreateLpPopupComponent>) {
    
    this.msg = data.Msg;
  }

  ngOnInit() {}

  closePopup(){
    this.dialogRef.close(true);
  }

}
