<mat-dialog-content>
  <div class="closePopup">
    <button mat-button mat-icon-button (click)="closeUpdatePopup()">
        <mat-icon>close</mat-icon>
      </button>
  </div>
  <div class="header">
  <span>Download File</span>
  </div>
  <div *ngIf="errorFlag" class="errMsg">Download failed. Please try later</div>
  <app-preloader *ngIf="!errorFlag"></app-preloader>

</mat-dialog-content>
