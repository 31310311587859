// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseJSONURL: 'assets/json/',
  baseUrl: 'https://dip-api.mindtree.com/gateway/',
  authService: 'https://dip-api.mindtree.com/gateway/auth-service',
  mdmService: 'https://dip-api.mindtree.com/gateway/mdm-service',
  employeeService:
    'https://dip-api.mindtree.com/gateway/employee-resume-service',
  learningService:
    'https://dip-api.mindtree.com/gateway/learning-service',
  courseService: 'https://dip-api.mindtree.com/gateway/course-service',
  digitalProfileURL: 'https://digital.mindtree.com/profile',
  production: false
};
//staging
// export const environment = {
//   baseJSONURL: 'assets/json/',
//   baseUrl: 'https://dip-api-staging.mindtree.com/gateway/',
//   authService: 'https://dip-api-staging.mindtree.com/gateway/auth-service',
//   mdmService: 'https://dip-api-staging.mindtree.com/gateway/mdm-service',
//   employeeService:
//     'https://dip-api-staging.mindtree.com/gateway/employee-resume-service',
//   learningService:
//     'https://dip-api-staging.mindtree.com/gateway/learning-service',
//   courseService: 'https://dip-api-staging.mindtree.com/gateway/course-service',
//   digitalProfileURL: 'https://digital-staging.mindtree.com/profile',

//   production: false
// };
//dev
// export const environment = {
//   baseJSONURL: 'assets/json/',
//   baseUrl: 'https://bridge-builders.mindtree.com/igx-cmn-dev/',
//   authService:
//     'https://bridge-builders.mindtree.com/igx-cmn-dev/gateway/auth-service',
//   mdmService:
//     'https://bridge-builders.mindtree.com/igx-cmn-dev/gateway/mdm-service',
//   employeeService:
//     'https://bridge-builders.mindtree.com/igx-cmn-dev/gateway/employee-resume-service',
//   learningService:
//     'https://bridge-builders.mindtree.com/igx-cmn-dev/gateway/learning-service',
//   courseService:
//     'https://bridge-builders.mindtree.com/igx-cmn-dev/gateway/course-service',
//   production: false
// };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
