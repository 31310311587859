import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PssEditScoreCardPopupComponent } from '../pss-edit-score-card-popup/pss-edit-score-card-popup.component';
import { PssRemapEvaluatorComponent } from '../pss-remap-evaluator/remap-evaluator.component';
import { PssEvaluationService } from '../pss-evaluation.service';
import { PssPublishPopupComponent } from '../pss-publish-popup/pss-publish-popup.component';
import { PssErrorUploadPopupComponent } from '../pss-error-upload-popup/pss-error-upload-popup.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DownloadPopup } from '../download-popup/download-popup.component';
import { ErrorDownloadPopup } from '../error-download-popup/error-download-popup.component';
import { FilenameFromUrlPipe } from "../../../../../../../libs/pipes/src/lib/FilenameFromUrlPipe";
import { ItemTypePipe } from 'libs/pipes/src/lib/item-type.pipe';
//import { splitClasses } from '@angular/compiler';
import { DownloadDocumentPopupComponent } from '../download-document-popup/download-document-popup.component';
import { EmailAlertList, PublishResult, PublishSelectedPopupDataList, UpdateScoreDetails, EvaluatorDetails, PssLog } from '../pss-evaluation.interface';
import { select } from '@ngrx/store';
import * as XLSX from './../.././../../../../../node_modules/xlsx';
import { PopupMessagesComponent } from '../popup-messages/popup-messages.component';
import { SystemService } from '../../../shared/services/system.service';

@Component({
  selector: 'yorbit-pss-evaluation-content',
  templateUrl: './pss-evaluation-content.component.html',
  styleUrls: ['./pss-evaluation-content.component.scss']
})
export class PssEvaluationContentComponent implements OnInit {

  @Input() requestList: any[];
  @Input() totalRecords: number;
  @Output() isContentCompDisabled = new EventEmitter<boolean>();

  applySearch: any;
  @Input() unitTile: any;
  selectedAll: boolean = true;
  selectedArr: string[] = new Array();
  @Input() contentIndex: any;
  fileUrl;
  evalSheetUrl;
  courseId: null;
  courseStatus: any;
  fileName: string;
  isProblemStatementAvailable: boolean;
  isEvaluationTemplateAvailable: boolean;
  downloadStatus: string;
  showPreloader: boolean;
  contentLoadedSuccessfully: boolean;
  errorsUrl;
  solutionsUrl: string[] = [];
  questionsUrl;
  scorecardURL: any;
  scoreCard: string[] = [];
  scoreData: any[];
  selectedRecords: any[] = [];
  listRecords: any[] = [];
  listRecords1: any[] = [];
  selectedEvaluationRequestIds: any[] = [];
  emailAlertList: EmailAlertList[] = [];
  alertData: any[] = [];
  publishDisplayData: PublishSelectedPopupDataList[] = [];
  isScoreChanged: boolean = false;
  isErrorFileChanged: boolean = false;
  isScoreCardChanged: boolean = false;
  fieldsEdited: string = "";
  isEditedByOPM: boolean = false;
  scoreDetails: UpdateScoreDetails;
  selectedPublishPopupDataList: PublishSelectedPopupDataList[] = [];
  allPublishPopupDataList: PublishSelectedPopupDataList[] = [];
  scoreDetailsList: UpdateScoreDetails[] = [];
  publishScoreDetails: UpdateScoreDetails[] = [];
  selectedScoreDetailsList: UpdateScoreDetails[] = [];
  publishResult: PublishResult[] = [];
  selectedPublishResult: PublishResult[] = [];
  publishEmailData: any[] = [];
  scoreCardToolTip: string = "";
  evaluationSheetToolTip: string = "";
  problemStatementToolTip: string = "";
  remapEvaluatorToolTip: string = "";
  solutionToolTip: string = "";
  errorToolTip: string = "";
  logData: any[] = [];
  selectedOption: any = 5;
  count: number = 0;
  result: any[] = [];
  startingNumber: number;
  endingNumber: number;
  isPreviousButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  isCommonButtonsDisabled: boolean;
  isDownloadScorecardDisabled: boolean = false;

  evaluatorList: EvaluatorDetails[] = [];
  updatedResult: string = "";
  updatedScore: number;
  updatedComments: string = "";
  closeResult: string;
  isPostSLA: boolean;
  emailAlert: EmailAlertList[] = [];
  summarySheetNotAvailable: string = "Not Available";
  alertMessage: string;
  isRecordsLoading: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private _popup: MatDialog,
    private _PssEvaluationService: PssEvaluationService,
    private _systemSvc :SystemService
  ) { }

  ngOnInit() {
    this.startingNumber = 1;
    this.endingNumber = +this.selectedOption;
    this.isNavigateButtonsDisabled();
    this.requestListInit();

    this.isCommonButtonsDisabled = true;

    ////console.log('init', this.allPublishPopupDataList);
  }

  multiSelect: boolean = false;

  public getRecordCount() {
    return this.requestList.length;
  }

  onScoreCardHover(index: string) {
    this.scoreCardToolTip = this.requestList[index].ScoreCard
      .substring(this.requestList[index].ScoreCard.lastIndexOf('/') + 1);
  }

  onEvaluationSheetHover(index: string) {
    this.evaluationSheetToolTip = this.requestList[index].EvaluationSummarySheet
      .substring(this.requestList[index].EvaluationSummarySheet.lastIndexOf('/') + 1);
  }

  onRemapIconHover(index: string) {
    if(!this.requestList[index].HasMultipleEvaluators)
      this.remapEvaluatorToolTip = 'This course has only one evaluator';
    if(!(this.requestList[index].SubStatus.includes('Result awaited') && (this.requestList[index].Result == "Not-Cleared" || this.requestList[index].Result == null)))
      this.remapEvaluatorToolTip = 'Sorry! Currently, you cannot remap evaluator';
  }

  onProblemStatementHover(index: string) {
    this.problemStatementToolTip = this.requestList[index].ProblemStatement
      .substring(this.requestList[index].ProblemStatement.lastIndexOf('/') + 1);
  }

  onSolutionHover(index: string) {
    this.solutionToolTip = this.requestList[index].SolutionFile
      .substring(this.requestList[index].SolutionFile.lastIndexOf('/') + 1);
  }

  onErrorHover(index: string) {
    this.errorToolTip = this.requestList[index].ErrorFile
      .substring(this.requestList[index].ErrorFile.lastIndexOf('/') + 1);
  }

  onFileComplete(data: any) {
    ////console.log(data);
    alert('File uploaded successfully');
  }


  openSolutionAttemptwise(result) {
    ////console.log('entering into AttemptWise method');
    ////console.log(result, 'requestid');
    if (Number(result.Attempts) == 1) {

    if(result.SolutionFile!=null)
    {
      this._systemSvc.getBlobSasKey(result.SolutionFile)
      .then((response:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri,'_blank');
        }
        catch{}
      });
    }
      //window.open(result.SolutionFile, '_blank');

      return false;
    }
    else {
      if(result.RequestType.toLowerCase() == 'project') {
        this._PssEvaluationService.getLearnerProjectSolutionFile(result.MID, result.CourseUniqueId).subscribe(data => {
          this.solutionsUrl = data as string[];
          if (Object.keys(this.solutionsUrl).length > 0) {
            const dialogConfig = new MatDialogConfig();
            ////console.log('ResponseURL', this.solutionsUrl);
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'SolutionFileDownloadDialogContainer';
            dialogConfig.data = this.solutionsUrl;

            const dialogRef = this._popup.open(
              DownloadPopup,
              dialogConfig

            );
            dialogRef.afterClosed().subscribe((data: any) => {
              this.closeResult = `Closed with: ${data}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            })
          }
          else
          {
            if(result.SolutionFile!=null)
            {
              this._systemSvc.getBlobSasKey(result.SolutionFile)
              .then((response:any)=>{
                try{
                  let decodedUri = this._systemSvc.getAtoBDecode(response);
                  window.open(decodedUri,'_blank');
                }
                catch{}
              });
            }
            //window.open(result.SolutionFile, '_blank');
          }
        });
      }
      else if(result.RequestType.toLowerCase() == 'assignment') {
        this._PssEvaluationService.getLearnerAssignmentSolutionFile(result.RequestId, result.AssignmentSessionId).subscribe(data => {
          this.solutionsUrl = data as string[];
          if (Object.keys(this.solutionsUrl).length > 0) {
            const dialogConfig = new MatDialogConfig();
            ////console.log('ResponseURL', this.solutionsUrl);
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'SolutionFileDownloadDialogContainer';
            dialogConfig.data = this.solutionsUrl;

            const dialogRef = this._popup.open(
              DownloadPopup,
              dialogConfig

            );
            dialogRef.afterClosed().subscribe((data: any) => {
              this.closeResult = `Closed with: ${data}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            })
          }
          else
          {
            if(result.SolutionFile!=null)
            {
              this._systemSvc.getBlobSasKey(result.SolutionFile)
              .then((response:any)=>{
                try{
                  let decodedUri = this._systemSvc.getAtoBDecode(response);
                  window.open(decodedUri,'_blank');
                }
                catch{}
              });
            }
           // window.open(result.SolutionFile, '_blank');
          }
        });
      }
    }

      }

  openScorecardAttemptwise(result) {
    ////console.log('entering into AttemptWise method');
    ////console.log(result, 'requestid');
    if (Number(result.Attempts) == 1) {
      if(result.ScoreCard !=null)
      {
        this._systemSvc.getBlobSasKey(result.ScoreCard)
        .then((response:any)=>{
          try{
            let decodedUri = this._systemSvc.getAtoBDecode(response);
            window.open(decodedUri,'_blank');
          }
          catch{}
        });
      }
      //window.open(result.ScoreCard, '_blank');
      return false;
    }

    var uniqueId = "";
    if(result.RequestType.toLowerCase() == "project")
      uniqueId = result.CourseUniqueId;
    else
      uniqueId = result.AssignmentSessionId;

    this._PssEvaluationService.getLearnerScorecard(result.MID, uniqueId, result.RequestId).subscribe(data => {
      this.scoreCard = data as string[];
      if (Object.keys(this.scoreCard).length > 0) {
        const dialogConfig = new MatDialogConfig();
        ////console.log('ResponseURL', this.scoreCard);
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'SolutionFileDownloadDialogContainer';
        dialogConfig.data = this.scoreCard;

        const dialogRef = this._popup.open(
          DownloadPopup,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((data: any) => {
          this.closeResult = `Closed with: ${data}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        })
      }
      else
      {
        if(result.ScoreCard!=null)
        {
          this._systemSvc.getBlobSasKey(result.ScoreCard)
          .then((response:any)=>{
            try{
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri,'_blank');
            }
            catch{}
          });
        }
      //window.open(result.ScoreCard, '_blank');
      }
    });
  }

  editScore(
    index: number,
    EvaluationRequestId: number,
    EvaluationType: string,
    ScoreSecured: number,
    MinimumScore: number,
    MaximumScore: number
  ) {
    this.scoreData = [EvaluationRequestId, EvaluationType, ScoreSecured, MinimumScore, MaximumScore];
    const dialogConfig = new MatDialogConfig();
    var oldScore = ScoreSecured;
    var oldResult = this.requestList.filter(x => x.EvaluationRequestId == EvaluationRequestId)[0].Result;
    ////console.log('ScoreData', this.scoreData, 'afsdfasd', ScoreSecured);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'expertDialogContainer';
    dialogConfig.data = this.scoreData;
    ////console.log('dialogConfig', dialogConfig);

    const dialogRef = this._popup.open(
      PssEditScoreCardPopupComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((data: any) => {
      this.scoreDetails = data as UpdateScoreDetails;
      for (let i = 0; i < this.requestList.length; i++) {
        if (this.requestList[i].EvaluationRequestId == EvaluationRequestId) {
          if (this.requestList[i].Score != this.scoreDetails.Score && this.scoreDetails.Result != 'Reject Evaluation') {
            this._PssEvaluationService.isScoreChanged = true;
            this.requestList[i].Score = this.scoreDetails.Score;
            this.requestList[i].Result = this.scoreDetails.Result;
            this.requestList[i].Comments = this.scoreDetails.Comments;
            const pssLogScore = new PssLog();
            pssLogScore.FieldsEdited = 'Score';
            pssLogScore.RequestId = this.requestList.filter(x => x.EvaluationRequestId == EvaluationRequestId)[0].RequestId;
            pssLogScore.ValueBeforeEdit = oldScore.toString();
            pssLogScore.ValueAfterEdit = this.requestList.filter(x => x.EvaluationRequestId == EvaluationRequestId)[0].Score;
            this._PssEvaluationService.updatePssLog(pssLogScore).subscribe(data => {
              ////console.log('isUpdate', data);
            });

            const pssLogResult = new PssLog();
            pssLogResult.FieldsEdited = 'Result';
            pssLogResult.RequestId = this.requestList.filter(x => x.EvaluationRequestId == EvaluationRequestId)[0].RequestId;
            pssLogResult.ValueBeforeEdit = oldResult;
            pssLogResult.ValueAfterEdit = this.requestList.filter(x => x.EvaluationRequestId == EvaluationRequestId)[0].Result;
            this._PssEvaluationService.updatePssLog(pssLogResult).subscribe(data => {
              ////console.log('isUpdate', data);
            });
          }
        }
      }
      this.isScoreChanged = this._PssEvaluationService.isScoreChanged;

      if (this.isScoreChanged) {
        if (!this.scoreDetailsList.some(item => item.EvaluationRequestId == this.scoreDetails.EvaluationRequestId))
          this.scoreDetailsList.push(this.scoreDetails);
        else {
          for (let i = 0; i < this.scoreDetailsList.length; i++) {
            if (this.scoreDetailsList[i].EvaluationRequestId == this.scoreDetails.EvaluationRequestId) {
              this.scoreDetailsList[i].Comments = this.scoreDetails.Comments;
              this.scoreDetailsList[i].Result = this.scoreDetails.Result == "Clear" ? "Clear" : "Error";
              this.scoreDetailsList[i].Score = this.scoreDetails.Score;
            }
          }
        }

        for (let i = 0; i < this.allPublishPopupDataList.length; i++) {
          if (this.allPublishPopupDataList[i].EvaluationRequestId == EvaluationRequestId) {
            this.allPublishPopupDataList[i].Score = this.scoreDetails.Score;
            this.allPublishPopupDataList[i].Result = this.scoreDetails.Result == "Clear" ? "Clear" : "Error";
            this.allPublishPopupDataList[i].IsEditedByOPM = true;

            if (!this.allPublishPopupDataList[i].FieldsEdited.includes("'Score'")) {
              if (this.allPublishPopupDataList[i].FieldsEdited == "NA")
                this.allPublishPopupDataList[i].FieldsEdited = "";
              else
                this.allPublishPopupDataList[i].FieldsEdited += ", ";
              this.allPublishPopupDataList[i].FieldsEdited += "'Score'";
            }
          }
        }
      }
      if(this.scoreDetails.Result == 'Reject Evaluation') {
        this.requestList[index].IsPublishButtonDisabled = true;
        if(EvaluationType.toLowerCase() == 'project') {
          this.requestList[index].SubStatus = "Result awaited for project";
        }
        else
          this.requestList[index].SubStatus = "Result awaited for assignment";

          this.allPublishPopupDataList = [];
          this.requestList.map(item => {
            let obj = {
              EvaluationRequestId: item.EvaluationRequestId,
              RequestId: item.RequestId,
              Score: item.Score,
              Result: item.Result,
              IsEditedByOPM: false,
              FieldsEdited: "NA"
            }
            if (item.SubStatus.toLowerCase() == 'learningopm team to verify and publish result' && item.Result == 'Not-Cleared') {
              if (this.allPublishPopupDataList.find((test) => test.RequestId === obj.RequestId) === undefined) {
                this.allPublishPopupDataList.push(obj);
              }
            }
          });
      }
    });
    ////console.log('ScoreChange', this.allPublishPopupDataList);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onChange(value) {
    this.isRecordsLoading = true;
    ////console.log('newValue', value);
    this.selectedOption = value;
    this.count = 0;
    this.isNavigateButtonsDisabled();
    this._PssEvaluationService.getFilteredData(this.selectedOption, this.count, 'next').subscribe(
      data => {
        this.result = data as any[];
        this.requestList = this.result['PSSCertificateRequestItemList'];
        this.ngOnInit();
        this.isRecordsLoading = false;
      }
    );
  }

  requestListInit() {
    this.requestList.map(item=>{
      let obj={
        EvaluationRequestId: item.EvaluationRequestId,
        RequestId: item.RequestId,
        Score: item.Score,
        Result: item.Result,
        IsEditedByOPM: false,
        FieldsEdited: "NA"
      }

      if (item.SubStatus != null && item.SubStatus.toLowerCase() == 'learningopm team to verify and publish result' && item.Result == 'Not-Cleared')
        item.IsPublishButtonDisabled = false;
      else
        item.IsPublishButtonDisabled = true;
      if (!item.IsPublishButtonDisabled) {
        if (this.allPublishPopupDataList.find((test) => test.RequestId === obj.RequestId) === undefined) {
          this.allPublishPopupDataList.push(obj);
        }
      }
    });
  }

  isNavigateButtonsDisabled() {
    if(this.endingNumber > this.totalRecords)
      this.endingNumber = this.totalRecords;
    if(this.endingNumber >= this.totalRecords)
      this.isNextButtonDisabled = true;
    else
      this.isNextButtonDisabled = false;

    if(this.startingNumber == 1)
      this.isPreviousButtonDisabled = true;
    else
      this.isPreviousButtonDisabled = false;
  }

  getNextOrPrevious(value: string) {
    this.isRecordsLoading = true;
    this.multiSelect = false;
    this.selectedEvaluationRequestIds = [];
    ////console.log('next', this.selectedOption);
    ////console.log('value', value);
    if (value == "next") {
      this.count = +this.selectedOption + this.count ;
      this.startingNumber = +this.selectedOption + this.startingNumber;
      this.endingNumber = +this.selectedOption + this.endingNumber;
      if(this.endingNumber > this.totalRecords)
        this.endingNumber = this.totalRecords;
      this.isNavigateButtonsDisabled();
    }
    else if (value == "previous") {
      this.count = this.count - this.selectedOption;
      this.startingNumber = this.startingNumber - this.selectedOption;
      this.endingNumber = +this.selectedOption + this.startingNumber - 1;
      this.isNavigateButtonsDisabled();
    }
    this._PssEvaluationService.getFilteredData(this.selectedOption, this.count, value).subscribe(
      data => {
        this.isRecordsLoading = false;
        this.result = data as any[];
        this.requestList = this.result['PSSCertificateRequestItemList'];
        this.requestListInit();
      }
    );
  }

  changeEvaluator(index: string, evaluationRequestId: number, requestType: string, courseId: string, sessionId: string) {
    ////console.log('sessionId', sessionId);
    var courseOrSessionId = "";
    if(sessionId == 'NA')
      courseOrSessionId = courseId;
    else
      courseOrSessionId = sessionId;
    var oldEvaluator = this.requestList.filter(x => x.EvaluationRequestId == evaluationRequestId)[0].EvaluatorName;
    this._PssEvaluationService
      .getEvaluatorListForRemap(requestType, courseOrSessionId)
      .subscribe(data => {
        this.evaluatorList = data as EvaluatorDetails[];
        if(this.evaluatorList != null) {
          if (this.evaluatorList.length > 1) {
            const dialogConfig = new MatDialogConfig();
            var oldEvaluatorObject = this.evaluatorList.filter(x => x.EvaluatorName == oldEvaluator)[0];
            if (oldEvaluatorObject != undefined) {
              this.evaluatorList.splice(this.evaluatorList.indexOf(oldEvaluatorObject), 1);
            }
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'remapDialogContainer';
            dialogConfig.data = this.evaluatorList;
            const dialogRef = this._popup.open(
              PssRemapEvaluatorComponent,
              dialogConfig
            );
            dialogRef.afterClosed().subscribe(result => {
              if (result != null) {
                ////console.log('Result', result);
                this.requestList[index].EvaluatorName = result.EvaluatorName;
                var updateData = { EvaluationRequestId: evaluationRequestId, EvaluationRequestType: requestType, MID: result.MID };
                this._PssEvaluationService
                  .updateEvalauator(updateData)
                  .subscribe(data => {
                    ////console.log('return data', data);
                    if (data) {
                      const pssLog = new PssLog();
                      pssLog.FieldsEdited = 'Evaluator';
                      pssLog.RequestId = this.requestList.filter(x => x.EvaluationRequestId == evaluationRequestId)[0].RequestId;
                      pssLog.ValueBeforeEdit = oldEvaluator;
                      pssLog.ValueAfterEdit = this.requestList.filter(x => x.EvaluationRequestId == evaluationRequestId)[0].EvaluatorName;
                      this._PssEvaluationService.updatePssLog(pssLog).subscribe(data => {
                        ////console.log('isUpdate', data);
                      });
                    }
                  });
              }
            });
          }
          else {
            this.remapEvaluatorToolTip = "No Evaluators Available";
          }
        }
      });
  }


  downloadDocumentType() {
    /*   const modelRef = this.modalService.open(DownloadDocumentPopupComponent);
      modelRef.componentInstance.requestId = requestId;
      modelRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }); */

    this.listRecords = [];


    //this.selectedAll = false;
    // this.requestList.forEach((request,index)=>{



    /*   if(request.checkBoxChecked==true)
      {
        this.listRecords.push(request);
      } */
    var iteratedRec;

    for (let j = 0; j < this.selectedEvaluationRequestIds.length; j++) {

      iteratedRec = this.requestList.find(x => x.EvaluationRequestId === this.selectedEvaluationRequestIds[j]);
      this.listRecords.push(iteratedRec);
    }
    //   if(!this.selectedAll && this.listRecords.length== this.requestList.length)
    // {
    //   this.selectedAll = true;
    // }
    const dialogConfig = new MatDialogConfig();
    ////console.log('SelectedEvalIDs', 'listrec', this.selectedEvaluationRequestIds, this.listRecords);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = this.listRecords;
    /*  if(!this.selectedAll)
     {
       dialogConfig.data=this.requestList;
       ////console.log('asdfghjkl',  this.requestList);
     }
     else {


     // dialogConfig.data = this.selectedEvaluationRequestIds;
       ////console.log('123456789', this.selectedEvaluationRequestIds);
     } */
    const dialogRef = this._popup.open(
      DownloadDocumentPopupComponent,
      dialogConfig
    );


    /*   if (this.selectedRecords.indexOf(this.selectedEvaluationRequestIds[j].EvaluationRequestId) != -1) {
        this.listRecords.push(this.selectedEvaluationRequestIds[j]);
      } */

  }
  // });





  sendIndividualAlert(evaluationRequestId: number) {
    var selectedRequestList;
    for (let i = 0; i < this.requestList.length; i++) {
      if (this.requestList[i].EvaluationRequestId == evaluationRequestId)
        selectedRequestList = this.requestList[i];
    }
    this.emailAlert = [];
    this.emailAlert.push({
      EvaluationRequestId: selectedRequestList.EvaluationRequestId,
      RequestId: selectedRequestList.RequestId,
      CourseName: selectedRequestList.CourseName,
      EvaluatorName: selectedRequestList.EvaluatorName,
      EvaluatorEmail: selectedRequestList.EvaluatorEmail,
      MID: selectedRequestList.MID,
      SubmissionDate: selectedRequestList.SubmissionDate,
      SLAHitDate: selectedRequestList.SLA_Date,
      DaysPostSLA: selectedRequestList.PostSLA
    });

    ////console.log('Input', this.emailAlert);
    this._PssEvaluationService.sendSelectedSLAALert(this.emailAlert).subscribe(
      data => {
        ////console.log('result', data);
      });
  }

  isPublishButtonDisabled(evaluationRequestId: number) {
    var individualRequest = this.requestList.find(item => item.EvaluationRequestId == evaluationRequestId);
    if (individualRequest.Status == "Course In-Progress" && individualRequest.SubStatus == 'LearningOPM team to Verify and Publish Result')
      return false;
    else
      return true;
  }

  downloadLog(requestId: number) {
    this._PssEvaluationService.downloadLogData(requestId).subscribe(
      data => {
        this.logData = data as any[];
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.logData);//converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'Request_' + requestId + '_Log.xlsx');
      }
    )
  }

  publishChanges(element, text, evaluationRequestId: number) {
    element.textContent = 'Published';
    const index = this.requestList.findIndex(x => x.EvaluationRequestId == evaluationRequestId)
    this.requestList[index].IsPublishButtonDisabled = true;
    var selectedRequestList;

    for (let i = 0; i < this.requestList.length; i++) {
      if (this.requestList[i].EvaluationRequestId == evaluationRequestId)
        selectedRequestList = this.requestList[i];
    }

    this.selectedPublishPopupDataList = [];
    for (let i = 0; i < this.allPublishPopupDataList.length; i++) {
      if (this.allPublishPopupDataList[i].EvaluationRequestId == evaluationRequestId) {
        this.publishDisplayData = this.allPublishPopupDataList.slice(i, i + 1);
        this.publishDisplayData.map(item => {
          return {
            EvaluationRequestId: item.EvaluationRequestId,
            RequestId: item.RequestId,
            Score: item.Score,
            Result: item.Result,
            IsEditedByOPM: item.IsEditedByOPM,
            FieldsEdited: item.FieldsEdited
          }
        }).forEach(item => this.selectedPublishPopupDataList.push(item));
      }
    }

    this.publishResult = [];
    this.publishResult.push({
      EvaluationRequestId: selectedRequestList.EvaluationRequestId,
      RequestId: selectedRequestList.RequestId,
      CourseName: selectedRequestList.CourseName,
      RequestType: selectedRequestList.RequestType,
      LearnerId: selectedRequestList.MID,
      Result: selectedRequestList.Result,
      Score: selectedRequestList.Score,
      Scorecard: selectedRequestList.ScoreCard,
      MaximumScore: selectedRequestList.MaxScore,
      Comments: selectedRequestList.Comments
    });

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = this.selectedPublishPopupDataList;

    const dialogRef = this._popup.open(PssPublishPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this._PssEvaluationService.updateScore(this.scoreDetails).subscribe(
          data => {}
        );
        this._PssEvaluationService.publishSelectedResult(this.publishResult)
          .subscribe(data => {
            if (data > 0) {
              //////console.log('data', data);
              element.textContent = text;
              element.disabled = true;
              this.requestList[index].IsPublishButtonDisabled = true;
              this.allPublishPopupDataList = [];
            this.requestList.map(item => {
            let obj = {
              EvaluationRequestId: item.EvaluationRequestId,
              RequestId: item.RequestId,
              Score: item.Score,
              Result: item.Result,
              IsEditedByOPM: false,
              FieldsEdited: "NA"
            }
            if (!item.IsPublishButtonDisabled) {
              if (this.allPublishPopupDataList.find((test) => test.RequestId === obj.RequestId) === undefined) {
                this.allPublishPopupDataList.push(obj);
              }
            }
          });
              //const index = this.requestList.findIndex(x => x.EvaluationRequestId == evaluationRequestId)
              //this.requestList[index].IsPublishButtonDisabled = true;
            }

          });
      }
      else {
        this.requestList[index].IsPublishButtonDisabled = false;
      }
    });
  }

  isScoreCardAvailable(index: string) {
    if (this.requestList[index].Attempts == 1 && this.requestList[index].Status == 'Course In-Progress'
      && this.requestList[index].SubStatus.includes('Result awaited'))
      return false;
    if (this.requestList[index].ScoreCard.length > 0)
      return true;
    else
      return false;
  }

  isEvaluationSheetAvailable(index: string) {
    if (this.requestList[index].Attempts == 1 && this.requestList[index].Status == 'Course In-Progress'
      && this.requestList[index].SubStatus.includes('Result awaited'))
      return false;
    if (this.requestList[index].EvaluationSummarySheet.length > 0)
      return true;
    else
      return false;
  }

  uploadErrorFile(evaluationRequestId: number, requestType: string) {
    var oldErrorFile = this.requestList.filter(x => x.EvaluationRequestId == evaluationRequestId)[0].ErrorFile;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = { uploadFile: 'errorfile', evaluationRequestId: evaluationRequestId, requestType: requestType };
    const dialogRef = this._popup.open(
      PssErrorUploadPopupComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response.Status) {
        const pssLog = new PssLog();
        pssLog.FieldsEdited = 'ErrorFile';
        pssLog.RequestId = this.requestList.filter(x => x.EvaluationRequestId == evaluationRequestId)[0].RequestId;
        pssLog.ValueBeforeEdit = oldErrorFile == null ? 'NA' : oldErrorFile;
        pssLog.ValueAfterEdit = response.Msg;
      this._PssEvaluationService.updatePssLog(pssLog).subscribe(data => {
        ////console.log('isUpdate', data);
      });
      this.isErrorFileChanged = this._PssEvaluationService.isErrorFileChanged;
      if (this.isErrorFileChanged) {
        for (let i = 0; i < this.allPublishPopupDataList.length; i++) {
          if (this.allPublishPopupDataList[i].EvaluationRequestId == evaluationRequestId) {
            this.allPublishPopupDataList[i].IsEditedByOPM = true;

            if (!this.allPublishPopupDataList[i].FieldsEdited.includes("'Error File'")) {
              if (this.allPublishPopupDataList[i].FieldsEdited == "NA")
                this.allPublishPopupDataList[i].FieldsEdited = "";
              else
                this.allPublishPopupDataList[i].FieldsEdited += ", ";
              this.allPublishPopupDataList[i].FieldsEdited += "'Error File'";
            }
          }
        }
      }
    }
    });
    ////console.log('ErrorChange', this.allPublishPopupDataList);
  }

  uploadScoreCard(evaluationRequestId: number, requestType: string) {
    var oldScoreCard = this.requestList.filter(x => x.EvaluationRequestId == evaluationRequestId)[0].ScoreCard;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = { uploadFile: 'scorecard', evaluationRequestId: evaluationRequestId, requestType: requestType };
    const dialogRef = this._popup.open(
      PssErrorUploadPopupComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response.Status) {
        this.isScoreCardChanged = this._PssEvaluationService.isScoreCardChanged;
        const pssLog = new PssLog();
        pssLog.FieldsEdited = 'ScoreCard';
        pssLog.RequestId = this.requestList.filter(x => x.EvaluationRequestId == evaluationRequestId)[0].RequestId;
        pssLog.ValueBeforeEdit = oldScoreCard == null ? 'NA' : oldScoreCard;
        pssLog.ValueAfterEdit = response.Msg;
      this._PssEvaluationService.updatePssLog(pssLog).subscribe(data => {
        ////console.log('isUpdate', data);
      });
      if (this.isScoreCardChanged) {
        for (let i = 0; i < this.allPublishPopupDataList.length; i++) {
          if (this.allPublishPopupDataList[i].EvaluationRequestId == evaluationRequestId) {
            this.allPublishPopupDataList[i].IsEditedByOPM = true;

            if (!this.allPublishPopupDataList[i].FieldsEdited.includes("'Score Card'")) {
              if (this.allPublishPopupDataList[i].FieldsEdited == "NA")
                this.allPublishPopupDataList[i].FieldsEdited = "";
              else
                this.allPublishPopupDataList[i].FieldsEdited += ", ";
              this.allPublishPopupDataList[i].FieldsEdited += "'Score Card'";
            }
          }
        }
      }
    }
    });


    ////console.log('ScoreChange', this.allPublishPopupDataList);
  }
  deleteSolution() {
    //delete solution from blob
    //confirmation popup
    const confirmDialogRef = this._popup.open(ConfirmationDialogComponent, {
      disableClose: true,
      panelClass: "popupDialogContainer",
      data: { popup: 'DeleteSolution' }
    });


    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'Yes') {
          //service call to delete solution
        }
      }
    });
  }

  downloadScoreCard(index: string) {
    if(this.requestList[index].ScoreCard!=null)
    {
      this._systemSvc.getBlobSasKey(this.requestList[index].ScoreCard)
      .then((response:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri,'_parent');
        }
        catch{}
      });
    }
    //window.open(this.requestList[index].ScoreCard, '_parent');
  }

  downloadProblemStatement(index: string) {
    if(this.requestList[index].ProblemStatement!=null)
    {
      this._systemSvc.getBlobSasKey(this.requestList[index].ProblemStatement)
      .then((response:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri,'_parent');
        }
        catch{}
      });
    }
   // window.open(this.requestList[index].ProblemStatement, '_parent');
  }

  downloadEvaluationSheet(index: string) {
    if(this.requestList[index].EvaluationSummarySheet !=null)
    {
      this._systemSvc.getBlobSasKey(this.requestList[index].EvaluationSummarySheet)
      .then((response:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri,'_parent');
        }
        catch{}
      });
    }
    //window.open(this.requestList[index].EvaluationSummarySheet, '_parent');
  }

  downloadErrorFile(index: string) {
    if(this.requestList[index].ErrorFile !=null)
    {
      this._systemSvc.getBlobSasKey(this.requestList[index].ErrorFile)
      .then((response:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(response);
          window.open(decodedUri,'_parent');
        }
        catch{}
      });
    }
    //window.open(this.requestList[index].ErrorFile, '_parent');
  }

  saveSelected(evaluationRequestId, event) {
    ////console.log(this.requestList)
    this.listRecords = [];
    this.selectedAll = false;
    this.requestList.forEach((request, index) => {
      if (request.EvaluationRequestId == true) {
        this.listRecords.push(request);
      }
    });
    if (this.listRecords.length == this.requestList.length) {
      this.selectedAll = true;
    }
    ////console.log(evaluationRequestId, 'REquestid', this.selectedRecords);
    // if (!this.selectedAll) {

    ////console.log('abcde', this.selectedRecords);

  }

  RetrieveSelectedRecords(selectedRecords, requestList) {

    ////console.log(requestList);
    ////console.log('entering to retrieving method');


    for (let j = 0; j < requestList.length; j++) {
      if (this.selectedRecords.indexOf(requestList[j].EvaluationRequestId) != -1) {
        this.listRecords.push(requestList[j]);

      }
    }


    this.listRecords = this.listRecords.filter((el, i, a) => i === a.indexOf(el));
    ////console.log('abcdefghijk', this.listRecords);


    /*
            for (let i=0; i< this.listRecords.length;i++){
            if (this.listRecords1.indexOf(this.listRecords[i].RequestId) == -1){
              this.listRecords1.push(this.listRecords[i]);
             }  */


    /*  this.listRecords.forEach((a) => {
       if(this.uniqueRecords.indexOf(a.requestId)<0){
         this.uniqueRecords.push(a.requestId);
       }
     }); */


    /*   selectedRecords.forEach((selectedRecords)=>requestList.forEach((id)=>
      {
        if(selectedRecords===requestList.requestId)
        {
          listRecords.push(requestList.ProblemStatement);
          listRecords.push(requestList.ErrorFile);
          listRecords.push(requestList.ScoreCard);
          listRecords.push(requestList.Solutions);
          listRecords.push(requestList.EvaluationSummarySheet);
        }

        }
      )); */
    return this.listRecords;

  }



  downloadEvaluationSheet1() {
    //this.courseId='DI_1141';
    ////console.log('courseid', this.courseId, this.isEvaluationTemplateAvailable);
    if (this.courseId != null || this.courseId != 'undefined') {
      this.downloadStatus = 'Downloading';

    }
    this._PssEvaluationService.getEvaluationTemplate(this.courseId).then((response: any) => {
      var link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.href = response;
      link.download = response.substr(response.lastIndexOf('/') + 1);
      link.click();
      this.downloadStatus = 'Evaluation template downloaded';
    })
      .catch(err => {
        this.downloadStatus = 'Not downloaded';
      })
  }
  sendAlertToSelectedRequests() {
    //delete solution from blob
    //confirmation popup
    //send selected requests number in noOfRequests
    const confirmDialogRef = this._popup.open(ConfirmationDialogComponent, {
      disableClose: true,
      panelClass: "popupDialogContainer",
      data: { popup: 'SendAlert', noOfRequests: 0 }
    });


    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'Yes') {
          //service call to delete solution
        }
      }
    });
  }

  onSelectChange(evaluationRequestId: number) {
    if (this.selectedEvaluationRequestIds.indexOf(evaluationRequestId) == -1)
      this.selectedEvaluationRequestIds.push(evaluationRequestId);
    else
      this.selectedEvaluationRequestIds.splice(this.selectedEvaluationRequestIds.indexOf(evaluationRequestId), 1);

    if(this.selectedEvaluationRequestIds.length == 0)
      this.isCommonButtonsDisabled = true;
    else
      this.isCommonButtonsDisabled= false;
  }

  onSelectAllChange() {
    if (this.multiSelect == true) {
      this.multiSelect = false;
      this.selectedEvaluationRequestIds = [];
    } else {
      this.multiSelect = true;
      this.selectedEvaluationRequestIds = [];
      for (let i = 0; i < this.requestList.length; i++) {
        this.selectedEvaluationRequestIds.push(this.requestList[i].EvaluationRequestId);
      }
    }
    if(this.selectedEvaluationRequestIds.length == 0)
      this.isCommonButtonsDisabled = true;
    else
      this.isCommonButtonsDisabled= false;
  }

  publishSelectedRequests() {
    this.selectedPublishPopupDataList = [];
    this.allPublishPopupDataList = [];
    this.requestList.map(item => {
    let obj = {
      EvaluationRequestId: item.EvaluationRequestId,
      RequestId: item.RequestId,
      Score: item.Score,
      Result: item.Result,
      IsEditedByOPM: false,
      FieldsEdited: "NA"
    }
    if (!item.IsPublishButtonDisabled) {
      if (this.allPublishPopupDataList.find((test) => test.RequestId === obj.RequestId) === undefined) {
        this.allPublishPopupDataList.push(obj);
      }
    }
  });
    for (let i = 0; i < this.allPublishPopupDataList.length; i++) {
      for (let j = 0; j < this.selectedEvaluationRequestIds.length; j++) {
        if (this.allPublishPopupDataList[i].EvaluationRequestId == this.selectedEvaluationRequestIds[j]) {
          this.publishDisplayData = this.allPublishPopupDataList.slice(i, i + 1);
          this.publishDisplayData.map(item => {
            return {
              EvaluationRequestId: item.EvaluationRequestId,
              RequestId: item.RequestId,
              Score: item.Score,
              Result: item.Result,
              IsEditedByOPM: item.IsEditedByOPM,
              FieldsEdited: item.FieldsEdited
            }
          }).forEach(item => this.selectedPublishPopupDataList.push(item));
        }
      }
    }

    this.selectedScoreDetailsList = [];
    for (let i = 0; i < this.scoreDetailsList.length; i++) {
      for (let j = 0; j < this.selectedEvaluationRequestIds.length; j++) {
        if (this.scoreDetailsList[i].EvaluationRequestId == this.selectedEvaluationRequestIds[j]) {
          this.publishScoreDetails = this.scoreDetailsList.slice(i, i + 1);
          this.publishScoreDetails.map(item => {
            return {
              EvaluationRequestId: item.EvaluationRequestId,
              RequestType: item.RequestType,
              Score: item.Score,
              Result: item.Result,
              Comments: item.Comments
            }
          }).forEach(item => this.selectedScoreDetailsList.push(item));
        }
      }
    }

    this.selectedPublishResult = [];
    for (let i = 0; i < this.requestList.length; i++) {
      for (let j = 0; j < this.selectedEvaluationRequestIds.length; j++) {
        if (this.requestList[i].EvaluationRequestId == this.selectedEvaluationRequestIds[j]) {
          this.publishEmailData = this.requestList.slice(i, i + 1);
          this.publishEmailData.map(item => {
            return {
              EvaluationRequestId: item.EvaluationRequestId,
              RequestId: item.RequestId,
              RequestType: item.RequestType,
              Scorecard: item.ScoreCard,
              Score: item.Score,
              Result: item.Result,
              LearnerId: item.MID,
              CourseName: item.CourseName,
              MaximumScore: item.MaxScore,
              Comments: item.Comments
            }
          }).forEach(item => this.selectedPublishResult.push(item));
        }
      }
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = this.selectedPublishPopupDataList;

    const dialogRef = this._popup.open(PssPublishPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this._PssEvaluationService.updateScoreForSelectedRequests(this.selectedScoreDetailsList).subscribe(
          data => {}//console.log('apiresponse', data)
        );
        this._PssEvaluationService.publishSelectedResult(this.selectedPublishResult)
          .subscribe(data => {
            window.alert("Published successfully");
          });
          this.isContentCompDisabled.emit(true);
      }
    });
  }

  alertSelectedRequests() {
    if (this.multiSelect == true) {
      this.selectedEvaluationRequestIds = [];
      for (let i = 0; i < this.requestList.length; i++) {
        this.selectedEvaluationRequestIds.push(this.requestList[i].EvaluationRequestId);
      }
    }
    this.emailAlertList = [];
    for (let i = 0; i < this.requestList.length; i++) {
      for (let j = 0; j < this.selectedEvaluationRequestIds.length; j++) {
        if (this.requestList[i].EvaluationRequestId == this.selectedEvaluationRequestIds[j] && this.requestList[i].SubStatus.includes('Result awaited')) {
          this.alertData = this.requestList.slice(i, i + 1);
          this.alertData.map(item => {
            return {
              EvaluationRequestId: item.EvaluationRequestId,
              RequestId: item.RequestId,
              CourseName: item.CourseName,
              EvaluatorName: item.EvaluatorName,
              EvaluatorEmail: item.EvaluatorEmail,
              MID: item.MID,
              SubmissionDate: item.SubmissionDate,
              SLAHitDate: item.SLA_Date,
              DaysPostSLA: item.PostSLA
            }
          }).forEach(item => this.emailAlertList.push(item));
        }
      }
    }
    ////console.log(this.emailAlertList, 'emaillist');
    this._PssEvaluationService.sendSelectedSLAALert(this.emailAlertList).subscribe(
      data => {
        ////console.log('output1', data);
        var tuple = data as number;
        const dialogConfig = new MatDialogConfig();
        if(data == 0)
          this.alertMessage = 'Alert mail is not sent, as the selected evaluation request(s) is not pending with any evaluator(s)';
        else
          this.alertMessage = 'Alert mail is sent to ' + data + ' evaluator(s) with whom the evaluation request(s) is pending';


        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = this.alertMessage;

        const dialogRef = this._popup.open(PopupMessagesComponent, dialogConfig);
      }
    );
  }
}
