import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { createEffect } from '@ngrx/effects';
import { NotificationsReducer } from './store';
import { NotificationsEffects } from './store';
import { BellNotificationService } from './bell-notifications.service';
import { PushNotificationsModule } from '../../push-notifications/push-notifications.module';
import { NotificationsInitState } from './store/notifications.model';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

@NgModule({
  imports: [
    FlexLayoutModule,
    StoreModule.forFeature('BellNotification', NotificationsReducer),
    EffectsModule.forFeature([NotificationsEffects]),
    PushNotificationsModule,
    MatDialogModule,
    MatMenuModule,
    ReusableUiModule
  ],
  declarations: [],
  exports: [],
  providers: [BellNotificationService]
})
export class BellNotificationModule {}
