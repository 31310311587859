<div *ngIf="cuuserroles &&(-1!=cuuserroles.indexOf('pssVerification'))">
<yorbit-content-verification
*ngIf="!courseApproval"
[role]="'opm'"
(courseSelectedForVerification)="verifyCourse($event)"
></yorbit-content-verification>
<yorbit-pss-content-approval
*ngIf="courseApproval"
[ContentData]="selectedContent"
(gotoCourseSelection)="enableCourseSelection($event)"
></yorbit-pss-content-approval>
</div>


<!-- Remove below code after Prod Restricted release -->
<div *ngIf="cuuserroles&&(-1==cuuserroles.indexOf('pssVerification'))" class="inheritHeight">
  <div class="background">
    <div class=message>
      <div>Content Upload Verification Coming Soon!</div>
      <div>Stay Tuned!</div>
      </div>
    </div>
</div>
