<div class="footer-container">
  <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="center center">
    <div fxFlex="210px" fxFlexOrder="0" fxFlexOrder.lt-md="1" fxLayoutAlign="center center" class="mindtree-logo-container">
      <a fxFlex="210px" href="https://www.ltimindtree.com/" target="_blank" fxLayout="column">
        <!-- <img fxFlex="45px" src="assets/images/Logo_Full_Tag.png" /> -->
        <img fxFlex fxFlex.xs="50px" src="assets/images/LTIMindtreeLimitedLogoLinearBlue.svg" 
        [ngStyle.xs]="{'width':'100%'}" [ngClass.gt-xs]="'imgDimension'"/>
      </a>
    </div>
    <div fxFlex="450px" fxFlex.lt-md="100%" fxShow.gt-sm="true" fxShow.lt-md="false" fxFlexOrder.lt-md="0" fxLayout="column"
         fxLayoutAlign="center center">
      <div fxFlex.lt-md="30px" fxLayout="column" fxLayoutAlign="center center">
        <div class="navigation-bar-links" fxFlex="15px" fxLayout="row">
          <div class="nav-link mousePointer" fxFlex="80px" [routerLink]="['/info/aboutus']">About Us</div>
          <mat-divider vertical="true">
          </mat-divider>

          <div class="nav-link mousePointer" fxFlex="50px" [routerLink]="['/info/faq/General']">FAQ</div>
          <mat-divider vertical="true">
          </mat-divider>
          <!--
                    <div class="nav-link mousePointer" fxFlex="100px">User Guide</div>
                    <mat-divider vertical="true">
                    </mat-divider> -->

          <div class="nav-link mousePointer" fxFlex="100px" [routerLink]="['/info/termsOfUse']">Terms of Use</div>
          <mat-divider vertical="true">
          </mat-divider>
          <div class="nav-link mousePointer" fxFlex="80px" [routerLink]="['/info/feedback']">Feedback</div>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlexOrder.lt-md="2" fxFlex.lt-md="180px" fxFlexOffset.sm="20px">
      <div class="social-media-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="30px">
        <div class="media-icon" fxFlex="30px" fxLayoutAlign="center center">
          <a fxFlex="30px" href="https://www.linkedin.com/company/86813252/admin/" target="_blank" fxLayout="column">
            <img fxFlexFill src="assets/images/linked-in_social-icon.png" />
          </a>
        </div>
        <div class="media-icon" fxFlex="30px" fxLayoutAlign="center center">
          <a fxFlex="30px" href="https://www.facebook.com/LTIMindtree" target="_blank" fxLayout="column">
            <img fxFlexFill src="assets/images/fb_social-icon.png" />
          </a>
        </div>
        <div class="media-icon" fxFlex="30px" fxLayoutAlign="center center">
          <a fxFlex="30px" href="https://www.instagram.com/ltimindtree/" target="_blank" fxLayout="column">
            <img fxFlexFill src="assets/images/insta_social-icon.png" />
          </a>
        </div>
        <div class="media-icon" fxFlex="30px" fxLayoutAlign="center center">
          <a fxFlex="30px" href="https://twitter.com/LTI_Global" target="_blank" fxLayout="column">
            <img fxFlexFill src="assets/images/twitter_social-icon.png" />
          </a>
        </div>
      </div>
    </div>
    <div class="contact-us" fxFlexOrder.lt-md="3" fxFlex="170px" fxShow.lt-md="false" fxShow.gt-sm="true" fxLayoutAlign="center stretch"
         fxLayout="column" fxFlexOffset="20px">
      <button class="mousePointer" fxFlex="35px" (click)="sendMailToYorbitSupport()">CONTACT US</button>
    </div>
  </div>
</div>
