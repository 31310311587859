import { Injectable } from '@angular/core';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IdpService {
  _baseURL: string;
  isValidIDPLink = false;
  idpGuid = '';
  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
  }

  isValidIDPUser(guid) {
    const url = this._baseURL + 'IDP/Validate/' + guid;
    return this._http.get<any>(url).toPromise();
  }
  addCourseToIDP(itemDetails) {
    // let isAccountPackage = false;
    // if (
    //   (itemDetails.AccountPackage != undefined &&
    //     itemDetails.AccountPackage != null &&
    //     itemDetails.AccountPackage) ||
    //   (itemDetails.ProjectPackage != undefined &&
    //     itemDetails.ProjectPackage != null &&
    //     itemDetails.ProjectPackage)
    // ) {
    //   isAccountPackage = true;
    // }
    const payload = {
      learningPathId: '',
     // itemId: isAccountPackage ? itemDetails.uniqueId : itemDetails.Id,
     itemId: itemDetails.Id,
      itemType: itemDetails.ItemType,
      accountId: itemDetails.accountId,
      accountName: itemDetails.accountId,
      projectId: itemDetails.projectId,
      projectName: itemDetails.projectId,
      isAccount: itemDetails.AccountPackage
    };

    const url =
      this._baseURL + 'IDP/Add/Package/packageOrCourse?guid=' + this.idpGuid;
    return this._http.post<any>(url, payload).toPromise();
    //return $http( { method: "POST", url: URL, data: itemDetails } );
  }
}
