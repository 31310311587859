<!--
<div class="contentDiv">
    <div class="field-label" >
      <label class="field-name">Course UniqueId
        <sup class="mandatory-field-indicator">*</sup>
      </label>
    </div>
    <div class="check-uniqueId">
      <input [(ngModel)]="courseUniqueId" class="uniqueId-field"
        placeholder="Enter CourseUniqueId" />
        <div class="check-button" (click)="checkUniqueId(courseUniqueId)">Check</div>
    </div>
    <div *ngIf="message != null">
      {{message}}
    </div>
     <div class="upload-info-msg" [ngClass]="{'error-msg':fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['Post']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['Post'][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument']['Post'][1]))}">{{errorMsgs.course_ref_doc_err+ ' 25MB.'}}</div> 
    <div class="fileNameLabel"></div>
    <button mat-raised-button id="courseRefDoclinkbtn" class="anchorButton" (click)="downloadCourseRefLink(courseRefLink)"
        type="button">
        Download File
      </button>
    <br  *ngIf="courseRefLink!=null && courseRefLink!=''" />
    <div class="field-label" >
      <label class="field-name">Course Procedure Reference Material
        <sup class="mandatory-field-indicator">*</sup>
      </label>
    </div>
    <div class="check-uniqueId">
      <input [(ngModel)]="courseUniqueId" class="uniqueId-field"
        placeholder="Choose file" />
        <div class="check-button" (click)="checkUniqueId(courseUniqueId)">Upload</div>
    </div>
     <button mat-raised-button #ReferenceDocbtn id="ReferenceDocbtn" class="form-action-btn actionButton" 
    [ngClass]="{disabled:(fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument'][''][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument'][''][1])))||(eUploadFile['CourseProcedureReferenceDocument']==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''] ==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''][1]==undefined?true:false)))}"
    [disabled]="(fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument'][''][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument'][''][1])))||(eUploadFile['CourseProcedureReferenceDocument']==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''] ==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''][1]==undefined?true:false)))"
    (click)="uploadFile('CourseProcedureReferenceDocument', '', 1,$event)" type="button">Upload</button> 
  </div>
  -->



  <div *ngIf="cuuserroles &&(-1!=cuuserroles.indexOf('pssProcedure'))">

<div class="contentDiv">
    <div class="field-label" >
      <label class="field-name">Course UniqueId
        <sup class="mandatory-field-indicator">*</sup>
      </label>
    </div>
    <div class="check-uniqueId">
      <input [(ngModel)]="courseUniqueId" class="uniqueId-field"
        placeholder="Enter CourseUniqueId" (ngModelChange)="uniqueidUpdate()" />

         <button mat-button 
         class="check-button" 
         [disabled]="(courseUniqueId && courseUniqueId=='')"
          (click)="!(courseUniqueId && courseUniqueId=='')?checkUniqueId(courseUniqueId):''">Check
       </button>

       <button mat-button 
         class="check-button" *ngIf="downloadAvailable"
          (click)="downloadCourseRefLink()">Download File
       </button>

    </div>
    <div *ngIf="message != null && message != ''">
      {{message}}
    </div>

    <div fxLayout="row" *ngIf="displayUpload">
    <div class="fileUploadLabel">
      <input type="file" id="fileUpload" #file (change)="onFilesAdded(file.files, $event)" />
      <div class="fileNameLabel">{{fileName}}</div>
      <button mat-raised-button class="browseFileButton"
      [ngClass]="{'disabled':disableActionTab}"
        [disabled]="disableActionTab"
       (click)="file.click()">Browse</button>
  </div>

  <button mat-button 
    
    class="check-button" *ngIf="this.fileName!='Select a File'"
   (click)="uploadRefDoc()"> Upload
</button>

</div>
<div>{{errmsg}}</div>

    <!--
     <div class="upload-info-msg" [ngClass]="{'error-msg':fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['Post']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['Post'][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument']['Post'][1]))}">{{errorMsgs.course_ref_doc_err+ ' 25MB.'}}</div> 
    <div class="fileNameLabel"></div>
    <button mat-raised-button id="courseRefDoclinkbtn" class="anchorButton" (click)="downloadCourseRefLink(courseRefLink)"
        type="button">
        Download File
      </button>


    <br  *ngIf="courseRefLink!=null && courseRefLink!=''" />
    <div class="field-label" >
      <label class="field-name">Course Procedure Reference Material
        <sup class="mandatory-field-indicator">*</sup>
      </label>
    </div>
    <div class="check-uniqueId">
      <input [(ngModel)]="courseUniqueId" class="uniqueId-field"
        placeholder="Choose file" />
        <div class="check-button" (click)="checkUniqueId(courseUniqueId)">Upload</div>
    </div>
     <button mat-raised-button #ReferenceDocbtn id="ReferenceDocbtn" class="form-action-btn actionButton" 
    [ngClass]="{disabled:(fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument'][''][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument'][''][1])))||(eUploadFile['CourseProcedureReferenceDocument']==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''] ==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''][1]==undefined?true:false)))}"
    [disabled]="(fileUploadInvalid['CourseProcedureReferenceDocument']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument']['']==undefined?false:(fileUploadInvalid['CourseProcedureReferenceDocument'][''][1]==undefined?false:fileUploadInvalid['CourseProcedureReferenceDocument'][''][1])))||(eUploadFile['CourseProcedureReferenceDocument']==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''] ==undefined?true:(eUploadFile['CourseProcedureReferenceDocument'][''][1]==undefined?true:false)))"
    (click)="uploadFile('CourseProcedureReferenceDocument', '', 1,$event)" type="button">Upload</button> 
  </div>



-->

  
    </div>

    </div>
    
    <!-- Remove below code after Prod Restricted release -->
    <div *ngIf="cuuserroles&&(-1==cuuserroles.indexOf('pssProcedure'))" class="inheritHeight">
      <div class="background">
        <div class=message>
          <div>No Access Provided for Course Procedure Upload</div>
          </div>
        </div>
    </div>
    