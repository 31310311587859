import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class AoService {
  config: any;
  constructor(
    private http: HttpClient,
    private _envService: EnvironmentService
  ) {
    this.config = this._envService.getEnvironment();
  }

  getCoursePendingRequest(coursePayload) {
    return this.http.post<any>(
      this.config.apiUrl + 'Workflow/Requests/AOPending',
      coursePayload
    );
  }
  getCourseActionedRequest(coursePayload) {
    return this.http.post<any>(
      this.config.apiUrl + 'Workflow/Requests/AOActioned',
      coursePayload
    );
  }
  reassignCourseRequest(newAcademy, payload) {
    return this.http.post<any>(
      this.config.apiUrl +
        'Workflow/AONewCourse/Re-Assigned?redirectedAcademyName=' +
        newAcademy,
      payload
    );
  }
  acceptCourseRequest(payload) {
    return this.http.post<any>(
      this.config.apiUrl + 'Workflow/AONewCourse/Accept',
      payload
    );
  }
  denyCourseRequest(payload) {
    return this.http.post<any>(
      this.config.apiUrl + 'Workflow/AONewCourse/Denied',
      payload
    );
  }
  holdCourseRequest(payload) {
    return this.http.post<any>(
      this.config.apiUrl + 'Workflow/AONewCourse/OnHold',
      payload
    );
  }
  completeRequest(requestPayload, genre, skill) {
    return this.http.post<any>(
      this.config.apiUrl +
        'Workflow/AONewCourse/Completed?updatedSkillName=' +
        skill +
        '&updatedGenreName=' +
        genre,
      requestPayload
    );
  }

  download101Template() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/Download/101BulkUploadTemplate', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
  download201Template() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/Download/201BulkUploadTemplate', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
  uploadTemplateService(file) {
    return this.http
    .post<any>(this.config.apiUrl + 'ContentUpload/Skill3BulkUploadFor101or201',file);
  }

  getBulkUploadHistoryData(){
    return this.http
    .get<any>(this.config.apiUrl + 'GetBulkUploadHistoryData')
    .toPromise();
  }
}
