import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import * as fromAuthStore from '@YorbitWorkspace/auth';
import { Store } from '@ngrx/store';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

@Injectable()
export class PushNotificationService {
  userMID: string | null;
  headersForSubscription: HttpHeaders;
  config: any;
  getPushSubscription: BehaviorSubject<string | null>;
  firebase: any = window['firebase'];
  pushSubscriptionId = null;
  firebaseMsgInstance: any = null;
  serviceWorkerReg: any;

  constructor(
    private http: HttpClient,
    private _envService: EnvironmentService,
    private store: Store<fromAuthStore.IAuthState>,
    private mediaObserver: MediaObserver
  ) {
    this.config = this._envService.getEnvironment();
    this.headersForSubscription = new HttpHeaders()
      .set('Authorization', 'key=AIzaSyAeRAqIezRYXxAvHBSquOiSXJMdug1PAFo')
      .set('Content-Type', 'application/json');
    this.getPushSubscription = new BehaviorSubject('');
    this.getUserMID();
    this.getServiceworkerRegs();
  }
  getUserMID() {
    this.store.select(fromAuthStore.getAuthUserName).subscribe(res => {
      if (res !== undefined && res != null) {
        this.userMID = res.split('@')[0];
      }
    });
  }


  getPushSubscriptionId(firebaseMsgInstance) {
    if (this.pushSubscriptionId == null) {
      return firebaseMsgInstance
        .getToken()
        .then(response => {
          this.pushSubscriptionId = response;
          this.storeSubscriptionId(response);
          return Promise.resolve(this.pushSubscriptionId);
        })
        .catch(error => {
          ////console.log(error);
          return Promise.resolve('blocked');
        });
    } else {
      return Promise.resolve(this.pushSubscriptionId);
    }
  }
  storeSubscriptionId(subscriptionId) {
    //device:0 is desktop users
    //device:1 is mobile users
    let data = {
      MId: this.userMID,
      SubscriberId: subscriptionId,
      Device: 0
    };
    if (this.mediaObserver.isActive('xs')) {
      data.Device = 1;
    }

    return this.http.post(
      this.config.apiUrl + 'Notification/StoreSubscriberId',
      data
    );
  }
  subscribeUserToGlobalTopic(subId) {
    return this.http.post(
      'https://iid.googleapis.com/iid/v1/' +
        subId +
        '/rel/topics/GlobalMindtree',
      {},
      {
        headers: this.headersForSubscription
      }
    );
  }
  getServiceworkerRegs() {
    try {
      navigator.serviceWorker.getRegistration('/').then(reg => {
        if (reg) {
          this.serviceWorkerReg = reg;
        }
      });
    } catch (error) {}
  }
  addFirebaseEventListeners(firebaseMsgInstance) {
    // firebaseMsgInstance.onMessage((payload) => {
    //     if (this.serviceWorkerReg != null) {
    //         const options = {
    //             data: payload.notification.click_action,
    //             body: payload.notification.body,
    //             icon: payload.notification.icon
    //         };

    //         this.serviceWorkerReg.showNotification(payload.notification.title, options);
    //     }
    // });
    firebaseMsgInstance.onTokenRefresh(() => {
      this.getPushSubscriptionId(firebaseMsgInstance);
    });
  }
}
