<mat-form-field class="courseNameFullWidth">
    <input
      type="text"
      placeholder="Course Name"
      matInput
      [formControl]="courseNameControl"
      [matAutocomplete]="auto"
       [(ngModel)]="courseName"
    />
    <button mat-button *ngIf="courseName" matSuffix mat-icon-button aria-label="Clear" (click)="courseName='';onOptionSelected($event)">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)">
      <mat-option
        *ngFor="let option of (filteredOptions | async)"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
