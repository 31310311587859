import { Component, OnInit, OnDestroy } from '@angular/core';
import { IdpService } from './idp.service';
import { ActivatedRoute } from '@angular/router';
import { PopupService } from '../shared/global-popups/popup.service';
import { Subscriber } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { IdpPopupComponent } from './idp-popup/idp-popup.component';

@Component({
  selector: 'yorbit-idp',
  templateUrl: './idp.component.html',
  styleUrls: ['./idp.component.scss']
})
export class IdpComponent implements OnInit, OnDestroy {
  routeSub: any;
  showLoading: boolean;

  constructor(
    private idpService: IdpService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.showLoading = true;
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.validateIDPUser(params);
    });
  }
  //http://localhost:4200/IDP/18320191195548
  //https://yorbitst.azurewebsites.net/#/IDP?guid=18320191195548
  validateIDPUser(params) {
    const guid = params['guid'];
    this.idpService.idpGuid=guid;
    // Service call to check whether the guid is valid or not

    this.idpService
      .isValidIDPUser(guid)
      .then((response: any) => {
        if (response.isValidUser === true) {
          this.idpService.isValidIDPLink = true;
          // call IDP notification
          this.openPopup(true);
        }
      })
      .catch((error: any) => {
        if (error === null || error != null) {
          this.idpService.isValidIDPLink = false;
          this.openPopup(false);
        }
      });
  }
  openPopup(valid) {
    let message = '';
    if (valid) {
      message =
        'You have been redirected to SHOSHIN SCHOOL from My Development Plan Portal. In this session, you will only be allowed to add courses/packages to the My Development Plan Learning Path. Please do not refresh the page unless you want to end this session.';
    } else {
      message = 'Authentication from My Development Plan Failed !';
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'idpDialogContainer';
    dialogConfig.data = {
      message: message,
      isValid:valid
    };
    this.dialog.open(IdpPopupComponent, dialogConfig);
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    if (this.routeSub instanceof Subscriber) {
      this.routeSub.unsubscribe();
    }
  }
}
