import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { cloudlabReducers } from './store';
import { effectCloudlab } from './store/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('cloudlab', cloudlabReducers),
    EffectsModule.forFeature(effectCloudlab)
  ],
  declarations: []
})
export class CloudlabStoreModule {}
