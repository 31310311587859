import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { PssFiltersService } from '../pss-filters.service';
import { CoursePropAndStatusesFilterService } from './course-prop-and-statuses-filter.service';
import { Subscriber } from 'rxjs';
import { ThrottlingConstants } from '@azure/msal-common';
@Component({
  selector: 'yorbit-course-prop-and-statuses-filter',
  templateUrl: './course-prop-and-statuses-filter.component.html',
  styleUrls: ['./course-prop-and-statuses-filter.component.scss']
})
export class CoursePropAndStatusesFilterComponent
  implements OnInit, OnChanges, OnDestroy {
  //course filters update event
  @Output() updateCourseFilters: EventEmitter<any> = new EventEmitter<any>();
  //input selected tab
  @Input() selectedTab: string;
  @Input() classRoom;
  //variables declaration
  // course properites consists of the selected properties list depending on the tab selected
  courseProperties: Array<string>;
  //course properties api response
  courseFilters: any;
  //replace coursefilters with unfilteredOptions var
  unfilteredOptions: any;
  filteredOptions: any;
  //search with in the filters list
  searchOptions: string;
  //selectedCourseFilters property value is emitted whenever a change happened in its state
  //selected course filters is used to call api to get requests
  selectedCourseFilters: any;
  //number of filters applied
  filtersCount: number;
  //filters selection order
  filtersSelectionOrder: Array<string>;
  //statuses selection order
  statusesSelectionOrder: Array<string>;
  //active tab from course filters
  activeTab: string;
  courseFiltersSub: any;
  courseTaxonomyData: any;
  placeholderSearchText: any;
  ExternalTabSubscription: any;
  Genre = [];
  Skill = [];

  constructor(
    private _pssFiltersService: PssFiltersService,
    private _coursePropAndStatusesFilterService: CoursePropAndStatusesFilterService
  ) {
    this.courseProperties = [];
    this.courseFilters = {};
    this.filtersSelectionOrder = [];
    this.statusesSelectionOrder = [];
    this.searchOptions = '';
    this.activeTab = '';
    this.courseFiltersSub = {};
    this.placeholderSearchText = 'Approval Status';
    //subscribe to reset filters actions call
    this.resetFiltersSub();
    //subscribe for selected course filters,enabled course filters,filters count
    this.subscribeToSelectedOptions();
  }
  ngOnInit() {
    //call api for filters,certification status and sub status,201 and 301 courses
    this.getCourseFilters();
   // this.getCertificationStatusFilters();
   // this.getExternalCourses();
  }

  ngOnChanges(changeObj) {
    if (changeObj['selectedTab']) {
      //this.getCourseFilters();
      this.getCertificationStatusFilters();
      this.getExternalCourses();
      if (this.selectedTab == 'course-requests') {
        this.courseProperties = !this.classRoom ? [
          'ApprovalStatus',
          'Academy',
          'CourseName',
          'CourseType',
          'Vendor',
          'LearningOPMSPOC',
          'CertificationStatus',
          'CertificationSubStatus'
        ] : [
          'ApprovalStatus',
          'Academy',
          'CourseName',
          'CourseType',
          'Vendor',
        ];
        this.onSelection('ApprovalStatus', 'Approved', true);
      } else if (this.selectedTab.indexOf('certification') != -1) {
        this.courseProperties = [
          'Academy',
          'CourseName',
          'CourseType',
          'Expertise',
          'Vendor',
          'LearningOPMSPOC'
        ];
      } else if (this.selectedTab == 'externalrequest-pending' || this.selectedTab == 'externalrequest-actioned') {
        this.courseProperties = [
          'Academy',
          'Genre',
          'Skill',
          'CourseName',
          'LearningOPMSPOC'
        ];
      } else if (this.selectedTab == 'evaluation-requests') {
        this.courseProperties = [
          'Academy',
          'CourseName',
          'CourseType',
          'Vendor',
          'EvaluatorVendor',
          'CertificationSubStatus',
          'LearningOPMSPOC'
        ];
      }
    }
    this.activeTab = this.courseProperties[0];
  }

  getGenreAndSkillList() {
    this._pssFiltersService
      .getGenreandSkill()
      .subscribe((res: any) => {
        if (res) {
          this.courseTaxonomyData = res.AcademyList;
          this.courseTaxonomyData.forEach(element => {
            if (this.courseFilters["Academy"].includes(element["Academy"])) {
            element["GenreSkillList"].forEach(genres => {
                this.Genre.push(genres["Genre"]);
                genres["Skills"].forEach(Skills => {
                 this.Skill.push(Skills)
               })
              })
            }
          });
        }
        this.courseFilters["Genre"] = this.Genre;
        this.courseFilters["Skill"] = this.Skill;
      })
  }
  resetFiltersSub() {
    this.courseFiltersSub.resetFilterSub = this._coursePropAndStatusesFilterService
      .getResetFilterssub()
      .subscribe(res => {
        if (res) {
          this.searchOptions = '';
          if (this.selectedTab == 'course-requests') {
            this.onSelection('ApprovalStatus', 'Approved', true);
          }
        }
      });
  }
  subscribeToSelectedOptions() {
    this.courseFiltersSub.getCourseFiltersSub = this._coursePropAndStatusesFilterService
      .getSelectedFilters()
      .subscribe(res => {
        this.selectedCourseFilters = res;
        this.updateCourseFilters.emit({ data: this.selectedCourseFilters });
        this.filtersCount = 0;
        Object.keys(res).forEach(prop => {
          if (res[prop].length != 0) {
            this.filtersCount++;
          }
        });
      });
  }
  onTabSelection(filter, tab) {
    this.activeTab = tab;
    this.searchOptions = '';
    if (tab == 'ApprovalStatus') {
      this.placeholderSearchText = 'Approval Status';
    } else if (tab == 'CourseType') {
      this.placeholderSearchText = 'Course Type';
    } else if (tab == 'CourseName') {
      this.placeholderSearchText = 'Course Name';
    } else if (tab == 'Genre') {
      this.placeholderSearchText = 'Genre';
    } else if (tab == 'Skill') {
      this.placeholderSearchText = 'Skill'
    } else if (tab == 'Learning OPMSPOC') {
      this.placeholderSearchText = 'Learning OPM SPOC';
    } else if (tab == 'Certification Status') {
      this.placeholderSearchText = 'Course Status';
    } else if (tab == 'Certification SubStatus') {
      this.placeholderSearchText = 'Course Sub Status';
    } else {
      this.placeholderSearchText = tab;
    }
  }
  // filters api func def
  getCourseFilters() {
    this._coursePropAndStatusesFilterService.$pssFilters.subscribe(data => {
      let response = data.res;
      if (response != null) {
        //response.Expertise = ['201', '301'];
        let filterProperties = Object.keys(response);
        filterProperties.forEach(prop => {
          if (response[prop] == null || response[prop] == undefined) {
            response[prop] = [];
          }
        });
        this.courseFilters = response;
        this.getGenreAndSkillList();
      }
    });
  }
  //Get all the external courses 201 and 301's to interlink the course prperties
  getExternalCourses() {
    this._coursePropAndStatusesFilterService.getAllExternalCourses(
      this.selectedTab
    );
  }
  //get all certification statuses and substatuses with interlink between each
  getCertificationStatusFilters() {
    this._coursePropAndStatusesFilterService
      .getCertificationStatuses(this.selectedTab)
      .then(res => {});
  }
  onSelection(entity: string, value: string, checked: boolean) {
      this._coursePropAndStatusesFilterService.updateSelectedFilters(
        entity,
        value,
        checked
      );
      if (entity != 'ApprovalStatus') {
        this._coursePropAndStatusesFilterService.updateSelectionOrder(
          entity,
          value,
          checked
        );
        this.courseFilters = {
          ...this._coursePropAndStatusesFilterService.getCourseFilters(
            entity,
            checked
          )
        };
      }
  }
  removeSelectedFiltersFor(filter) {
    this._coursePropAndStatusesFilterService.removeSelectedFiltersFor(filter);
    this.courseFilters = {
      ...this._coursePropAndStatusesFilterService.getCourseFilters(
        filter,
        false
      )
    };
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.courseFiltersSub) {
      let subscriber = this.courseFiltersSub[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }


}
