import { Component, OnInit,Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'yorbit-pss-paginate',
  templateUrl: './pss-paginate.component.html',
  styleUrls: ['./pss-paginate.component.scss']
})
export class PssPaginateComponent implements OnInit {
@Input() paginateFilter:any;
@Output() paginateUpdateEvent = new EventEmitter<MatPaginator>();
@ViewChild(MatPaginator, { static: true }) pssPaginater: MatPaginator;
  pssPaginateResponse: any;
  pageSizeOptions: number[];
  pageSize: any;
  pageIndex: number;
  constructor() {
    this.pageSizeOptions=[20, 50,100,200,500]
    this.pageIndex = 0;
   }

  ngOnInit() {
    this.paginateFilter.pageSizeLimit=20;
  }
  onPageSizeLimitChange(){
    this.pssPaginater.pageSize=this.paginateFilter.pageSizeLimit;
    this.pssPaginater.firstPage();
    this.onPaginate();
  }
  onPaginate(){
  this.paginateUpdateEvent.emit(this.pssPaginater)
  }
}
