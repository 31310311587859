import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyPageComponent } from './my-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';


//store modules
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MyPageService } from './my-page.service';
import { myPageReducer, effectsMyPage } from './store';
import { DatePipe } from '@angular/common';

//resuable modules
import { PipesModule } from '@YorbitWorkspace/pipes';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { CardFeatureModule } from '../shared/card-feature/card-feature.module';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';

//Child components
import { MyPagePopupComponent } from './my-page-popup/my-page-popup.component';
import { LearningHistoryComponent } from './learning-history/learning-history.component';
import { LearningComplianceComponent } from './learning-compliance/learning-compliance.component';
import { SkillProfileComponent } from './skill-profile/skill-profile.component';
import { SkillingRecommendationsComponent } from './skilling-recommendations/skilling-recommendations.component';
import { LearningBadgesComponent } from './learning-badges/learning-badges.component';
import { BadgeDetailsComponent } from './badge-details/badge-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { DeclareSkillPopupComponent } from './skill-profile/declare-skill-popup/declare-skill-popup.component';
import { ExternalLearningsComponent } from './external-learnings/external-learnings.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTooltipModule,


    StoreModule.forFeature('MyPage', myPageReducer),
    EffectsModule.forFeature(effectsMyPage),
    PipesModule,
    FlexLayoutModule,
    FormsModule,
    ReusableUiModule,
    CardFeatureModule,
    ContentTilesModule
  ],
  declarations: [
    MyPageComponent,
    MyPagePopupComponent,
    LearningHistoryComponent,
    LearningComplianceComponent,
    SkillProfileComponent,
    SkillingRecommendationsComponent,
    LearningBadgesComponent,
    BadgeDetailsComponent,
    DeclareSkillPopupComponent,
    ExternalLearningsComponent,
  ],
  providers: [DatePipe],
})
export class MyPageModule {}
