import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { PssEvaluationService } from '../pss-evaluation.service';
import { UpdateScoreDetails } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-pss-edit-score-card-popup',
  templateUrl: './pss-edit-score-card-popup.component.html',
  styleUrls: ['./pss-edit-score-card-popup.component.scss']
})
export class PssEditScoreCardPopupComponent implements OnInit {
  actionMessage: string;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<PssEditScoreCardPopupComponent>,
    public confirmDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private _pssEvaluationService: PssEvaluationService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  requestId: number;
  evaluationType: string;
  scoreSecured: number;
  minimumScore: number;
  maximumScore: number;
  disableSubmit: boolean;
  oldScore: number;
  toolTipMessage: string = "";
  textAreaLength: number = 0;
  result: string = "Clear";
  comments: string;
  submitData: UpdateScoreDetails;
  isScoreSecuredDisabled: boolean = false;

  ngOnInit() {
    //////console.log('Data', this.data);
    this.requestId = this.data[0];
    this.evaluationType = this.data[1];
    this.scoreSecured = this.data[2];
    this.minimumScore = this.data[3];
    this.maximumScore = this.data[4];
    this.oldScore = this.data[2];
    this.disableSubmit = true;
    this.toolTipMessage = "Please update Scored Score and enter Remarks.";
    this.actionMessage='';
  }

  closePopup() {
    this.dialogRef.close();
    ////console.log(this.scoreSecured);
  }

  onSubmit() {
    this.submitData = {EvaluationRequestId: this.requestId, RequestType: this.evaluationType,
      Result: this.result, Comments: this.comments, Score: this.scoreSecured};
    if(this.result == "Clear") {
     
      this.dialogRef.close(this.submitData);
    }
    else if(this.result == "Reject Evaluation") {
      this.confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        panelClass: "expertDialogContainer",
        data: { popup: 'EditScoreCard' }
      });
    }

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(result == 'Yes') {
          this.dialogRef.close(this.submitData);
          this._pssEvaluationService.rejectEvaluation(this.submitData.EvaluationRequestId, this.submitData.RequestType).subscribe(
            data => {
              if(data) {
            }
            })
        }
      }
      this.confirmDialogRef = null;
    });
  }


  onRadioButtonChange(value) {
    this.result = value;
    ////console.log('Result', value);

    if(this.result == "Reject Evaluation") {
      this.isScoreSecuredDisabled = true;
      this.scoreSecured = this.data[2];
      if(this.textAreaLength > 0)
        this.disableSubmit = false;
      else {
        this.disableSubmit = true;
        this.toolTipMessage = "Please enter remarks."
      }
    }
    else if(this.result == 'Clear') {
      this.isScoreSecuredDisabled = false;
      this.onFocusOut();
    }
  }

  onTextAreaChange(event: any) {
    this.textAreaLength = event.target.value.length;
    this.comments
    ////console.log('Event', event.target.value);
    if(this.result == 'Reject Evaluation') {
      if(this.textAreaLength > 0) {
        this.disableSubmit = false;
      }
      else {
        this.disableSubmit = true;
        this.toolTipMessage = "Please enter remarks."
      }
    }
    else if(this.result == 'Clear') {
      this.onFocusOut();
    }
  }

  onFocusOut() {
    if(isNaN(this.scoreSecured)) {
      this.disableSubmit = true;
      this.toolTipMessage = "*Enter a valid score";
    }
    else if((this.scoreSecured - Math.floor(this.scoreSecured)) !== 0) {
      this.disableSubmit = true;
      this.toolTipMessage = "*Enter a valid score";
    }else if(this.oldScore == this.scoreSecured && this.textAreaLength == 0) {
      ////console.log('Old', this.oldScore, 'new', this.scoreSecured);
      this.disableSubmit = true;
      this.toolTipMessage = "Please update Scored Score and enter Remarks.";
    }
    else if(this.textAreaLength == 0){
      this.disableSubmit = true;
      this.toolTipMessage = "Please enter remarks.";
    }
    else if(this.oldScore == this.scoreSecured) {
      ////console.log('Old', this.oldScore, 'new', this.scoreSecured);
      this.disableSubmit = true;
      this.toolTipMessage = "Please update the Scored Score of the Learner.";
    }
    else if(this.oldScore > this.scoreSecured) {
      ////console.log('Old', this.oldScore, 'new', this.scoreSecured);
      this.disableSubmit = true;
      this.toolTipMessage = "Score cannot be lesser than the score given by evaluator.";
    }
    else if(this.scoreSecured > this.maximumScore) {
      ////console.log('score', this.scoreSecured, 'max', this.maximumScore);
      this.disableSubmit = true;
      this.toolTipMessage = "Score cannot be greater than the Maximum Score.";
    }
    else if(this.scoreSecured < this.minimumScore && this.result == "Clear") {
      this.disableSubmit = true;
      this.toolTipMessage = "Score connot be lesser than the Minimum Score if the Result is Clear";
    }
    else
      this.disableSubmit = false;
  }

}
