<div fxLayout="column">
   <!-- course Material download -->
   <div class="materialDiv mousePointer" fxFlex="60px" fxLayoutGap="5px"  *ngIf="courseMatAvailable">
    <div
    *ngIf="courseMatAvailable && courseMaterialType == 'Internal'"
      class="course-procedure-download"
      fxFlex
      fxLayoutAlign="end center">
      <div class="label">Download Course Material:</div>
      <div class="yorbitSprite downloadMaterialIcon"
        title="Click to download course material."
        (click)="downloadCourseMaterial()">
      </div>
    </div>

    <div
    *ngIf="courseMatAvailable && courseMaterialType == 'External'"
      class="course-procedure-download"
      fxFlex
      fxLayoutAlign="end center">
      <div class="label">Access Course Material:</div>
      <div class="yorbitSprite accessMaterialIcon"
        title="Click to access course material."
        (click)="downloadCourseMaterial()">
      </div>
    </div>
    </div>
  <mat-accordion [displayMode]="'flat'" class="playlist-accordion account-course-playlist">
    <mat-expansion-panel *ngFor="let unit of units" [expanded]="unit.UniqueId==selectedUnitId">
      <mat-expansion-panel-header title="{{unit.Name}}">
        {{unit.Name}}
      </mat-expansion-panel-header>
      <div class="unit-contents" fxLayout="column" fxLayoutAlign="start stretch">
        <div fxFlex="86px" fxLayout="column" fxLayoutAlign="start stretch" *ngFor="let content of unitContents[unit.UniqueId]">
          <div class="file" fxFlex="85px" fxLayout="row" fxLayoutAlign="start stretch">
            <div fxFlex="90px" fxFlex.xs="80px" class="check-box" [ngClass]="{mousePointer:!content.Completed}" fxLayoutAlign="center center"
              fxLayout="column">
              <mat-icon *ngIf="content.Completed">check_box</mat-icon>
              <mat-icon *ngIf="!content.Completed" (click)="showPopupToConfirmAccountContentCompletion(content)">check_box_outline_blank</mat-icon>
              <div class="label" *ngIf="content.Completed">Completed</div>
              <div class="label" *ngIf="!content.Completed">Mark as completed</div>
            </div>
            <div fxFlex="calc(100% - 90px)" fxLayout="column" class="title-and-file-url" fxLayoutAlign="start start">
              <div class="title textTruncate" fxFlex title="{{content.Title}}">
                {{content.Title}}
              </div>
              <div fxFlex class="url mousePointer" (click)="openExternalLink(content)">{{content.SourceUrl}}</div>
            </div>
          </div>
        </div>
        <div fxFlex="1px">
          <mat-divider></mat-divider>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="loading">
    <app-preloader></app-preloader>
  </div>
  <div fxLayout="row" fxFlexFill fxLayoutAlign="center center" *ngIf="loadedSuccessFully && units.length==0">
    No data available
  </div>
  <div fxLayout="row" fxFlexFill fxLayoutAlign="center center" *ngIf="!loading && !loadedSuccessFully">
    Technical error in loading data
  </div>
</div>
