import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  content: string;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data.id === 'CourseRequestReject') {
      this.content = "Classroom batch already started/completed.\nRequest ID: " + (this.data.requestsId.length > 5 ? this.data.requestsId.slice(0, 5) + "..." : this.data.requestsId.join(',')) + " rejected",
        this.content = this.content.replace(/\n/g, '<br>');
    }
  }

  confirm() {
    this.dialogRef.close('yes');
  }

  close() {
    this.dialogRef.close('no');
  }

}
