import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { YammerService } from './yammer.service';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

@Component({
  selector: 'yorbit-yammer',
  templateUrl: './yammer.component.html',
  styleUrls: ['./yammer.component.scss']
})
export class YammerComponent implements OnInit, OnChanges {
  @Input() card: any;
  config: Ienvironment;
  ContentDetails: any;

  constructor(
    private yammerService: YammerService,
    private _envSvc: EnvironmentService
  ) {
    this.config = this._envSvc.getEnvironment();
  }
  ngOnChanges(): void {
    if (this.card.contentData !== undefined) {
      this.ContentDetails = {
        ItemType: this.card.contentData.ItemType,
        Expertise:
          this.card.contentData.Expertise ||
          this.card.contentData.ItemExpertise,
        Id: this.card.contentData.Id || this.card.contentData.ItemId,
        Account:
          this.card.contentData.Account || this.card.contentData.AccountId,
        Project:
          this.card.contentData.Project || this.card.contentData.ProjectId,
        AccountPackage: this.card.contentData.AccountPackage,
        ProjectPackage: this.card.contentData.ProjectPackage,
        UniqueId: this.card.contentData.UniqueId
      };
      this.initYammer();
    }
  }
  ngOnInit(): void {}

  loadYammer(feederId, url) {
    const yam: any = window['yam'];

    try {
      yam.connect.embedFeed({
        container: '#embedded-feed',
        network: 'mindtree.com',
        feedType: 'open-graph',
        feedId: feederId,
        objectProperties: {
          url: url,
          type: 'page'
        },
        config: {
          header: false,
          footer: false,
          defaultGroupId: feederId
        }
      });
    } catch (err) {}
  }

  initYammer() {
    this.yammerService
      .getYammerFeederID(
        this.config.apiUrlYorbitYammer,
        this.ContentDetails.Account,
        this.ContentDetails.Project,
        this.ContentDetails.AccountPackage,
        this.ContentDetails.ProjectPackage
      )
      .then((response: any) => {
        let feederId, url, projectId;
        if (response == '7457097' || response == '9595419') {
          feederId = response;
          url =
            this.config.apiUrlYorbitYammer +
            this.ContentDetails.ItemType +
            '/' +
            this.ContentDetails.Expertise +
            '/' +
            this.ContentDetails.Id;
        } else {
          feederId = response.split('feedId=')[1].split('&')[0];
          if (!this.ContentDetails.ProjectPackage) {
            projectId = 0;
          }
          url =
            this.config.apiUrlAPYammer +
            '/' +
            this.ContentDetails.UniqueId +
            '/' +
            this.ContentDetails.AccountPackage +
            '/' +
            this.ContentDetails.ProjectPackage +
            '/' +
            this.ContentDetails.Account +
            '/' +
            projectId +
            '/' +
            this.ContentDetails.ItemType +
            '/' +
            this.ContentDetails.Id;
        }
        const yammerElement = document.getElementById('embedded-feed');
        while (yammerElement.hasChildNodes()) {
          yammerElement.removeChild(yammerElement.firstChild);
        }

        this.loadYammer(feederId, url);
      });
  }
}
