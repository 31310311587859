import { Component, OnInit } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'yorbit-blocked-push-notifications',
  templateUrl: './blocked-push-notifications.component.html',
  styleUrls: ['./blocked-push-notifications.component.scss']
})
export class BlockedPushNotificationsComponent implements OnInit {
public settingsUrl :string; 
  constructor( public dialogRef: MatDialogRef<BlockedPushNotificationsComponent>) { 
    if(window.navigator != undefined){
    var userAgentMobile = (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  )
  if (null == userAgentMobile) {
   this.settingsUrl="chrome://settings/content/notifications";
  }
  else{
    this.settingsUrl=" Settings > Advanced > Site settings > Notifications";
  }
}

  }

  ngOnInit() {
  }
  closeDialog(){
  this.dialogRef.close();
  }
}
