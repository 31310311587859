import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BadgeDetails, RoleBasedBadgeDetails } from '../my-page.interface';
import { MyPageService } from '../my-page.service';
import { GraphDataService } from '@YorbitWorkspace/graph';

import * as fromDeclareStore from '../../shared/declare-store/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'yorbit-badge-details',
  templateUrl: './badge-details.component.html',
  styleUrls: ['./badge-details.component.scss']
})
export class BadgeDetailsComponent implements OnInit {
  badge: any;
  badgeDetails: BadgeDetails;
  roleBasedBadgeDetails: RoleBasedBadgeDetails;
  badgeDetailsLoading: boolean;
  assignerNameLoading: boolean;
  mid: string;
  isDeclared: boolean;
  constructor(
    private dialogRef: MatDialogRef<BadgeDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _myPageSvc: MyPageService,
    private _graphSvc: GraphDataService,
    private _declareStore: Store<fromDeclareStore.IdeclareState>
  ) {
    this.badge = data.context;
    this.mid = data.mid;
    this.isDeclared = false;
  }

  ngOnInit() {    
    this.getBadgeDetails(this.badge);
  }

  close() {
    this.dialogRef.close();
  }

  getBadgeDetails(badge) {
    this.badgeDetails = null;
    this.roleBasedBadgeDetails = null;
    if (badge.Type == "YorbitLearning") {
      this.badgeDetailsLoading = true;
      this._myPageSvc.getBadgeDetails(badge.CourseId, this.mid).subscribe(
        data => {
          this.badgeDetailsLoading = false;

          if (data != null) {
            this.badgeDetails = data;
            this._declareStore
              .select(
                fromDeclareStore.getDeclareStatusByCourseId(badge.CourseId)
              )
              .subscribe(declareStatus => {
                ////console.log('declare status', declareStatus);
                if (
                  declareStatus != undefined &&
                  declareStatus.DeclareFlagStatus == 'DeclaredAndApproved'
                ) {
                  this.isDeclared = true;
                } else {
                  this.isDeclared = false;
                }
              });
            this.badgeDetails.DateOfCompletion = new Date(
              data.DateOfCompletion
            );
            if (data.AssignedBy != null && data.AssignedBy !== '') {
              this.badgeDetailsLoading = true;
              this._graphSvc.getUserName(data.AssignedBy).subscribe(
                name => {
                  this.badgeDetailsLoading = false;
                  this.badgeDetails.AssignerName = name.displayName;
                },
                error => {
                  this.badgeDetailsLoading = false;
                  this.badgeDetails.AssignerName = 'NA';
                }
              );
            }
          }
        },
        error => {}
      );
    } else if(badge.Type == "ExternalLearning") {
      this.badgeDetailsLoading = true;
      this._myPageSvc
        .getExternalLearningBadgeDetails(this.mid, this.badge.BadgeName)
        .subscribe(data => {
          this.badgeDetailsLoading = false;
          if (data != null) {
            let obj = {
              CourseName: data[0].CourseName,
              DateOfCompletion: data[0].Certificate_Date,
              ApprovalDate: data[0].ApprovalDate,
              Credit: data[0].Credits
            };
            this.badgeDetails = obj;
          }
        });
    } else if(badge.Type == "RoleBasedLearning") {
      this.badgeDetailsLoading = true;
      this._myPageSvc
        .getRoleBasedLearningBadgeDetails(this.badge.BadgeId)
        .subscribe(data => {
          this.badgeDetailsLoading = false;
          if (data != null) {
            let obj = {
              SalaryGrade: data.SalaryGrade,
              SpecializationGroup: data.SpecializationGroup,
              Specialization: data.Specialization,
              Family: data.Family,
              Role: data.Role,
              Level: data.Level
            };
            this.roleBasedBadgeDetails = obj;
          }
        });
    }
  }

  getDateFromString(stringDate) {
    return new Date(stringDate);
  }

  isDateValid(input) {
    if (input == undefined) return false;

    let invalidYear = 1;
    let date = new Date(input);
    if (date.getFullYear() == invalidYear) {
      return false;
    }
    return true;
  }
}
