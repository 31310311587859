import { Component, ElementRef, OnInit } from '@angular/core';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Globals } from '../globals';
import { DiversityAndInclusionService } from '../shared/services/diversity-and-inclusion.service';

@Component({
  selector: 'yorbit-our-ai-strategy',
  templateUrl: './our-ai-strategy.component.html',
  styleUrls: ['./our-ai-strategy.component.scss']
})
export class OurAiStrategyComponent implements OnInit {

  selectedArticleFilter: string = 'all';
  seeMoreFlag: boolean = false;

  videoList = [];
  articleList = [];
  filteredPackagesAndCoursesList = [];
  coursesList = [];

  isVideoLoading = false;
  isArticleLoading = false;
  isCoursesLoading = false;


  selectedVideo;
  selectedVideoCategory:String="";



  //innovative exchange video container
innovativeExchangeSeason1=[
  {
    Id : 1,
    ThumNailImageURl :'./../../../../courses/nonscorm/OurAIStrategy/nachiket.png',
    Title:'Leading the AI Wave – Nachiket Deshpande, Rohit Kedia and Krishnan Iyer',
    Description:"Explains, how to see “AI in Everything, Everything in AI and AI for Everyone” with industries perspective.",
    videolink:'./../../../../courses/nonscorm/OurAIStrategy/Leading the AI Wave – Nachiket Deshpande.pdf',
    Speaker:'Bablu Lawrence',
    Resources:'',
    ResourceType:''
  },
  {
    Id : 2,
    ThumNailImageURl :'https://img.youtube.com/vi/9H9BzHrmXus/0.jpg',
    Title:'LTIMindtree\'s AI Innovation Showcase: Nachiket Deshpande Walks Us Through Key Innovations | ET Now',
    Description:"Experience a unique insight into LTIMindtree’s AI Innovation Showcase, where artificial intelligence is integrated into all aspects, and every element is crafted with AI in mind. Learn about the company's substantial advancements in AI as COO Nachiket Deshpande emphasizes the pivotal innovations.",
    videolink:'https://www.youtube.com/embed/9H9BzHrmXus?si=ZBcxjTXJ2uAoFPyJ',
    Speaker:'Nayna Raut',
    Resources:'',
    ResourceType:''
  }

];

  seasonList = ['Season 1'];
  selectedSeason: any='Select Season';
  scrollButton: HTMLElement;

  constructor(
    private _diversityAndInclusionService: DiversityAndInclusionService,
    private _appInsight: AppInsightService,
    public globals: Globals,
    private hostElement: ElementRef
  ) {}

  ngOnInit(): void {
    
    this.selectedVideo=this.innovativeExchangeSeason1[0];
    this.updateIframeLink(this.selectedVideo.videolink);

    this._diversityAndInclusionService.data$.subscribe(data => {
      this.scrollFunction(data);
    });

  }

  clickOfReltatedVideoSelect(video) {
    const insightPayload = {​​
      LOCATION:"Diversity & Inclustion Related Videos",
      MID: this.globals.MId
    }​​;

    this._appInsight.trackEvent("D&I_Video",insightPayload)
    this.selectedVideo = video;
    this.updateIframeLink(this.selectedVideo.videolink);
  }

  clickSelectedFilter(filter) {
    this.selectedArticleFilter = filter;
  }

  clickOfArticleTile(articleVideo){
    //App Insight 
    const insightPayload = {​​
      LOCATION:"Diversity & Inclustion Videos",
      MID: this.globals.MId
    }​​;

    this._appInsight.trackEvent("D&I_Video",insightPayload)
   this.selectedVideo=articleVideo;
    this.updateIframeLink(this.selectedVideo.videolink);
   this.gotoVideoContainer();
  }

 
  gotoVideoContainer() {
    let el = document.getElementById('videoSection');
    ////console.log(el.scrollHeight)
    el.scrollIntoView({ behavior: 'smooth' });
  }

  updateIframeLink(link){
    const iframe = this.hostElement.nativeElement.querySelector('iframe');
    iframe.src = link;
  }

  seasonSelected(){
    console.log(this.selectedSeason);
  }

 

  scrollFunction(scrollPosition): void {
    this.scrollButton = document.getElementById('scrollBtn') 
    if (scrollPosition > 20 ) {
      this.scrollButton.style.display = 'block';
    } else {
      this.scrollButton.style.display = 'none';
    }
  }

  topFunction(): void {
    this._diversityAndInclusionService.scrollToTop(0);
  }

}
