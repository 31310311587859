import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'yorbit-learner-recognition',
    templateUrl: './learner-recognition.component.html',
    styleUrls: ['./learner-recognition.component.scss']
})
export class LearnerRecognitionComponent implements OnInit {
    learnerList: { "Sl No.": string; "MID": string; "Name": string; "Program": string; "isHidden": boolean; "imgURL"?:string }[];
    filteredLearnerList: { "Sl No.": string; "MID": string; "Name": string; "Program": string; "isHidden": boolean; "imgURL"?:string }[];


    constructor() { }

    ngOnInit() {
        this.learnerList = [
            {
                "Sl No.": "1",
                "MID": "M1027698",
                "Name": "Anjali Pillai",
                "Program": "Artificial Intelligence Nanodegree",
                "isHidden": true
            },
            {
                "Sl No.": "2",
                "MID": "M1030606",
                "Name": "Gaurav Saini",
                "Program": "Artificial Intelligence Nanodegree",
                "isHidden": true
            },
            {
                "Sl No.": "3",
                "MID": "M1000349",
                "Name": "Sudheer Kotagiri",
                "Program": "Deep Learning",
                "isHidden": true
            },
            {
                "Sl No.": "4",
                "MID": "M1040386",
                "Name": "Suresh Mallula",
                "Program": "Deep Learning",
                "isHidden": true
            },
            {
                "Sl No.": "5",
                "MID": "M1030112",
                "Name": "Arnav Vashistha",
                "Program": "React Nanodegree",
                "isHidden": true
            },
            {
                "Sl No.": "6",
                "MID": "M1036259",
                "Name": "Anirban Pramanik",
                "Program": "Computer Vision",
                "isHidden": true
            },
            {
                "Sl No.": "7",
                "MID": "M1043942",
                "Name": "Kritika Anand",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "8",
                "MID": "M1028134",
                "Name": "Akilan Manoharan",
                "Program": "Deep Learning",
                "isHidden": true
            },
            {
                "Sl No.": "9",
                "MID": "M1040377",
                "Name": "Kiran Kumar Amamcherla",
                "Program": "Deep Learning",
                "isHidden": true
            },
            {
                "Sl No.": "10",
                "MID": "M1027564",
                "Name": "Amit Saini",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "11",
                "MID": "M8080225",
                "Name": "Anand ParthaSarathy Pegada",
                "Program": "Android Basics Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "12",
                "MID": "M1038523",
                "Name": "Satheesh Oruganti",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "13",
                "MID": "M1021079",
                "Name": "Bibhuti Dalei",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "14",
                "MID": "M1027130",
                "Name": "Deepika Bownan Surendran",
                "Program": "Data Scientist Nanodegree",
                "isHidden": true
            },
            {
                "Sl No.": "15",
                "MID": "M1026887",
                "Name": "Devashis Kant",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "16",
                "MID": "M1027608",
                "Name": "Mrityunjay Kumar",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "17",
                "MID": "M1045576",
                "Name": "Avinash Mishra",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "18",
                "MID": "M1000880",
                "Name": "Ananth Vishwamurthy",
                "Program": "Deep Learning",
                "isHidden": true
            },
            {
                "Sl No.": "19",
                "MID": "M1001886",
                "Name": "Balaji Seshadri",
                "Program": "Deep Learning",
                "isHidden": true
            },
            {
                "Sl No.": "20",
                "MID": "M1039796",
                "Name": "Lakshminarayana Golla",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "21",
                "MID": "M1036365",
                "Name": "Arvinth Manivasakan",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "22",
                "MID": "M1030527",
                "Name": "Sanjog Yadav",
                "Program": "Machine Learning Engineer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "23",
                "MID": "M1031810",
                "Name": "Kalaiprabha Lingappan",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "24",
                "MID": "M1023447",
                "Name": "Chandan Singh",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "25",
                "MID": "M1044972",
                "Name": "Bhamidipati Kishore",
                "Program": "Android Basics Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "26",
                "MID": "M1030493",
                "Name": "Rashid Khan",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "27",
                "MID": "M1032380",
                "Name": "Shweta Sirsikar",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "28",
                "MID": "M1037225",
                "Name": "Sohan Bakale",
                "Program": "Android Developer Nanodegree Program",
                "isHidden": true
            },
            {
                "Sl No.": "29",
                "MID": "M1011060",
                "Name": "Karthik Sahukar",
                "Program": "AI Programming with Python",
                "isHidden": true
            },
            {
                "Sl No.": "30",
                "MID": "M1048092",
                "Name": "Vinayak Navale",
                "Program": "Data Scientist Nanodegree",
                "isHidden": true
            },
            {
                "Sl No.": "31",
                "MID": "M1038523",
                "Name": "Satheesh Oruganti",
                "Program": "iOS Developer Nanodegree",
                "isHidden": true
            },
            {
                "Sl No.": "32",
                "MID": "M1047363",
                "Name": "Kumar Saurabh",
                "Program": "Deep Reinforcement",
                "isHidden": true
            },
            {
                "Sl No.": "33",
                "MID": "M1050466",
                "Name": "Pratibha Janardhan",
                "Program": "Artificial Intelligence Nanodegree",
                "isHidden": true
            },
            {
                "Sl No.": "34",
                "MID": "M1042139",
                "Name": "Amit Tulshiram Derkar ",
                "Program": "Digital Reset Program",
                "isHidden": true,
                "imgURL" : "assets/images/Recognition/Amit Derkar.jpg"
            },
            {
                "Sl No.": "35",
                "MID": "M1032819",
                "Name": "Anil Palakeizh Radhakrishnan",
                "Program": "Digital Reset Program",
                "isHidden": false,
                "imgURL" : "assets/images/Recognition/Anil P.jpg"
            },
            {
                "Sl No.": "36",
                "MID": "M1047532",
                "Name": "Ashish Shridharan",
                "Program": "Digital Reset Program",
                "isHidden": false,
                "imgURL" : "assets/images/Recognition/Ashish Shridharan.jpg"
            },
            {
                "Sl No.": "37",
                "MID": "M1040633",
                "Name": "Ashish Srivastava",
                "Program": "Digital Reset Program",
                "isHidden": false,
                "imgURL" : "assets/images/Recognition/Ashish Srivastava.jpg"
            },
            {
                "Sl No.": "38",
                "MID": "M1043703",
                "Name": "Srinivas Srimushnam",
                "Program": "Digital Reset Program",
                "isHidden": true,
                "imgURL" : "assets/images/Recognition/Srinivas Srimushnam.jpg"
            },
            {
                "Sl No.": "39",
                "MID": "M1041546",
                "Name": "Venkatesh Agarwal",
                "Program": "Digital Reset Program",
                "isHidden": false,
                "imgURL" : "assets/images/Recognition/Venkatesh.jpg"
            },
        ]
        this.filterLearnersList();
    }

    filterLearnersList() {
        this.filteredLearnerList = this.learnerList.filter(x => x.isHidden === false);
    }
}
