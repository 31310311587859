<!--
  use as
    <yorbit-add-content
    [card]="{
      'contentData':contentDetails,
      'skillProfile':{
        'recommendationType':type,
        'duration':duration
      },
      'addedLocation':routepage.
      'addType':'button' or 'icon'
    }"
    ></yorbit-add-content>

    legends
    -> contentData set to card content details as in browse, search,
    new and trending, detail page - package details

    routepage -> routepage name in string

    addType -> button - addable, icon - not addable

    for functions other than skilling
    -> recommendationType '',
    -> duration ''
-->
<div class="yorbitSprite featureAdd" title="{{
    card.contentData.TotalUsers
  }} LTIMindtree Limited Associates Have Added This Course/Package" fxFlex="30px" [ngClass]="{
    add: true,
    mouseAuto: true,
    iconForAccordion: card.addedLocation == 'Details-Page-Accordion'
  }" *ngIf="card.addType === 'icon'"></div>
<div>
<button class="addBtn" (click)="isAddedToLP?navigateTo():!(card.contentData.IsRestricted || isAddedToLP) ? openDialog(): (Ismobile ? toggleTooltip() : '')"
  #tooltip="matTooltip"
    *ngIf="showButton && card.addType === 'button'"
    [ngClass]="{'addedLink':isAddedToLP,
    'addDisabled': (card.contentData.IsRestricted)||((card.addedLocation == 'RBLBrowse')&&!isAddedToLP),
    'mat-tooltiptitle': (card.contentData.IsRestricted && isAddedToLP)}"
    matTooltip="{{
      (card.contentData.IsRestricted && !isAddedToLP)
    ? restrictedCourseToolTip
    : ((card.contentData.IsRestricted && isAddedToLP) ? addTitle : '')
    }}"
    [disabled]="(card.addedLocation == 'RBLBrowse')&&!isAddedToLP"
    title="{{
    card.contentData.IsRestricted||((card.addedLocation == 'RBLBrowse')&&!isAddedToLP)
      ? ''
      : addTitle
  }}">
  <!--{{ isAddedToLP ? 'Navigate'+card.contentData.ItemType=='Course'?'Course':'Package' : 'Add' }}-->
  {{ isAddedToLP ? 'Added' : 'Add' }}
</button>
</div>
