<div class="external-learnings" fxLayout="column">
  <div class="title" *ngIf="!isExternalLearningsLoading" fxLayout="row" fxLayoutGap="10px">
    <div class="filter mousePointer" [ngClass]="{'active': filter[0]}" (click)="removeFilter()">All</div>
    <div class="filter mousePointer" [ngClass]="{'active': filter[1]}" (click)="getPending()">Pending</div>
    <div class="filter mousePointer" [ngClass]="{'active': filter[2]}" (click)="getActioned()">Actioned</div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="20px" class="scrollable-block" *ngIf="!isExternalLearningsLoading && externalLearningCourses.length > 0">
    <div fxLayout="column" *ngFor="let course of externalLearningCourses" class="course-details" fxFlex="190px">
      <div class="tile-title" *ngIf="false">External Course Learning</div>
      <div fxFlex class="course-name">
        <div class="name">{{course.CourseName}}</div>
      </div>
      <div class="course-status">
        {{course.CertificateStatus.toLowerCase() == 'pending' ? 'AWAITING VALIDATION' : (course.CertificateStatus.toLowerCase() ==
        'accepted' ? 'APPROVED' : course.CertificateStatus)}}
        <div class="badge">
          <img src='../../../assets/images/external-learning-badge-1.png' />
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" *ngIf="!isExternalLearningsLoading && externalLearningCourses.length == 0 && !isFilterApplied" fxLayoutAlign="center center"
    class="no-course-block">
    <div class="no-course-msg-title">No External Learnings Done.</div>
    <div class="no-course-msg-1">External Learnings are courses not present in Shoshin School, but learners do the Certifications/Trainings outside of Shoshin School.</div>
    <div class="no-course-msg-2">
      If there are any such External Learnings done by you, then get badges/credits for such learnings now.<br/> Use the
      'Certification Upload' feature on Shoshin School <span class="hyperlink" (click)="navigateToCertificateUpload()">{{url}}</span> to upload your learnings.
      <!-- <span [innerHTML]="certificateUploadURL">        
      </span> -->
    </div>
  </div>
  <div fxLayout="column" *ngIf="!isExternalLearningsLoading && externalLearningCourses.length == 0 && isFilterApplied" fxLayoutAlign="center center"
    class="no-course-block">
    <div class="no-course-msg-title">No Data Found.</div>
  </div>
  <div *ngIf="isExternalLearningsLoading" class="preloader-block" fxLayout="column" fxLayoutAlign="center center">
    <app-preloader></app-preloader>
  </div>
</div>