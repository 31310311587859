import { Action } from '@ngrx/store';

export const GET_MY_PAGE_LEARNING_HISTORY =
  '[MyPage] Get User Learning History';
export const GET_MY_PAGE_LEARNING_HISTORY_SUCCESS =
  '[MyPage] Get User Learning History Success';
export const GET_MY_PAGE_LEARNING_HISTORY_FAIL =
  '[MyPage] Get User Learning History Fail';

export const GET_MY_PAGE_COMPLIANCE = '[MyPage] Get User Compliance';
export const GET_MY_PAGE_COMPLIANCE_SUCCESS =
  '[MyPage] Get User Compliance Success';
export const GET_MY_PAGE_COMPLIANCE_FAIL = '[MyPage] Get User Compliance Error';

export const GET_MY_PAGE_SKILL_PROFILE = '[MyPage] Get User Skill Profile';
export const GET_MY_PAGE_SKILL_PROFILE_SUCCESS =
  '[MyPage] Get User Skill Profile Success';
export const GET_MY_PAGE_SKILL_PROFILE_FAIL =
  '[MyPage] Get User Skill Profile Fail';

export const GET_MY_PAGE_CORE_SKILLS = '[MyPage] Get User Core Skills';
export const GET_MY_PAGE_CORE_SKILLS_SUCCESS =
  '[MyPage] Get User Core Skills Success';
export const GET_MY_PAGE_CORE_SKILLS_FAIL =
  '[MyPage] Get User Core Skills Fail';

export const GET_MY_PAGE_SUPPORT_SKILLS = '[MyPage] Get User Support Skills';
export const GET_MY_PAGE_SUPPORT_SKILLS_SUCCESS =
  '[MyPage] Get User Support Skills Success';
export const GET_MY_PAGE_SUPPORT_SKILLS_FAIL =
  '[MyPage] Get User Support Skills Fail';

export const GET_MY_PAGE_CROSS_SKILLS = '[MyPage] Get User Cross Skills';
export const GET_MY_PAGE_CROSS_SKILLS_SUCCESS =
  '[MyPage] Get User Cross Skills Success';
export const GET_MY_PAGE_CROSS_SKILLS_FAIL =
  '[MyPage] Get User Cross Skills Fail';

export const GET_MY_PAGE_CROSS_SKILL_PATH =
  '[MyPage] Get User Cross Skill Path';
export const GET_MY_PAGE_CROSS_SKILL_PATH_SUCCESS =
  '[MyPage] Get User Cross Skill Path Success';
export const GET_MY_PAGE_CROSS_SKILL_PATH_FAIL =
  '[MyPage] Get User Cross Skill Path Fail';

export const GET_MY_PAGE_PRIMARY_SKILL = '[MyPage] Get User Primary Skills';
export const GET_MY_PAGE_PRIMARY_SKILL_SUCCESS =
  '[MyPage] Get User Primary Skills Success';
export const GET_MY_PAGE_PRIMARY_SKILL_FAIL =
  '[MyPage] Get User Primary Skills Fail';

export const GET_MY_PAGE_FUTURE_SKILLS = '[MyPage] Get User Future Skills';
export const GET_MY_PAGE_FUTURE_SKILLS_SUCCESS =
  '[MyPage] Get User Future Skills Success';
export const GET_MY_PAGE_FUTURE_SKILLS_FAIL =
  '[MyPage] Get User Future Skills Fail';

export const GET_MY_PAGE_FUTURE_SKILL_PATH =
  '[MyPage] Get User Future Skill Path';
export const GET_MY_PAGE_FUTURE_SKILL_PATH_SUCCESS =
  '[MyPage] Get User Future Skill Path Success';
export const GET_MY_PAGE_FUTURE_SKILL_PATH_FAIL =
  '[MyPage] Get User Future Skill Path Fail';

export class GetMyPageLearningHistory implements Action {
  readonly type = GET_MY_PAGE_LEARNING_HISTORY;
  constructor(public payload: any) {}
}
export class GetMyPageLearningHistorySuccess implements Action {
  readonly type = GET_MY_PAGE_LEARNING_HISTORY_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPageLearningHistoryFail implements Action {
  readonly type = GET_MY_PAGE_LEARNING_HISTORY_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPageCompliance implements Action {
  readonly type = GET_MY_PAGE_COMPLIANCE;
  constructor(public payload: any) {}
}
export class GetMyPageComplianceSuccess implements Action {
  readonly type = GET_MY_PAGE_COMPLIANCE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPageComplianceFail implements Action {
  readonly type = GET_MY_PAGE_COMPLIANCE_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPageSkillProfile implements Action {
  readonly type = GET_MY_PAGE_SKILL_PROFILE;
  constructor(public payload: any) {}
}
export class GetMyPageSkillProfileSuccess implements Action {
  readonly type = GET_MY_PAGE_SKILL_PROFILE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPageSkillProfileFail implements Action {
  readonly type = GET_MY_PAGE_SKILL_PROFILE_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPageCoreSkills implements Action {
  readonly type = GET_MY_PAGE_CORE_SKILLS;
  constructor(public payload: any) {}
}
export class GetMyPageCoreSkillsSuccess implements Action {
  readonly type = GET_MY_PAGE_CORE_SKILLS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPageCoreSkillsFail implements Action {
  readonly type = GET_MY_PAGE_CORE_SKILLS_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPageSupportSkills implements Action {
  readonly type = GET_MY_PAGE_SUPPORT_SKILLS;
  constructor(public payload: any) {}
}
export class GetMyPageSupportSkillsSuccess implements Action {
  readonly type = GET_MY_PAGE_SUPPORT_SKILLS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPageSupportSkillsFail implements Action {
  readonly type = GET_MY_PAGE_SUPPORT_SKILLS_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPageCrossSkills implements Action {
  readonly type = GET_MY_PAGE_CROSS_SKILLS;
  constructor(public payload: any) {}
}
export class GetMyPageCrossSkillsSuccess implements Action {
  readonly type = GET_MY_PAGE_CROSS_SKILLS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPageCrossSkillsFail implements Action {
  readonly type = GET_MY_PAGE_CROSS_SKILLS_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPageCrossSkillPath implements Action {
  readonly type = GET_MY_PAGE_CROSS_SKILL_PATH;
  constructor(public payload: any) {}
}
export class GetMyPageCrossSkillPathSuccess implements Action {
  readonly type = GET_MY_PAGE_CROSS_SKILL_PATH_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPageCrossSkillPathFail implements Action {
  readonly type = GET_MY_PAGE_CROSS_SKILL_PATH_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPagePrimarySkill implements Action {
  readonly type = GET_MY_PAGE_PRIMARY_SKILL;
  constructor(public payload: any) {}
}
export class GetMyPagePrimarySkillSuccess implements Action {
  readonly type = GET_MY_PAGE_PRIMARY_SKILL_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMyPagePrimarySkillFail implements Action {
  readonly type = GET_MY_PAGE_PRIMARY_SKILL_FAIL;
  constructor(public payload: any) {}
}

export class GetMyPageFutureSkills implements Action{
  readonly type=GET_MY_PAGE_FUTURE_SKILLS;
  constructor(public payload:any){}
}
export class GetMyPageFutureSkillsSuccess implements Action{
  readonly type=GET_MY_PAGE_FUTURE_SKILLS_SUCCESS;
  constructor(public payload:any){}
}
export class GetMyPageFutureSkillsFail implements Action{
  readonly type=GET_MY_PAGE_FUTURE_SKILLS_FAIL;
  constructor(public payload:any){}
}

export class GetMyPageFutureSkillPath implements Action{
  readonly type=GET_MY_PAGE_FUTURE_SKILL_PATH;
  constructor(public payload:any){}
}
export class GetMyPageFutureSkillPathSuccess implements Action{
  readonly type=GET_MY_PAGE_FUTURE_SKILL_PATH_SUCCESS;
  constructor(public payload:any){}
}
export class GetMyPageFutureSkillPathFail implements Action{
  readonly type=GET_MY_PAGE_FUTURE_SKILL_PATH_FAIL;
  constructor(public payload:any){}
}

export type MyPageActions =
  | GetMyPageLearningHistory
  | GetMyPageLearningHistorySuccess
  | GetMyPageLearningHistoryFail
  | GetMyPageCompliance
  | GetMyPageComplianceSuccess
  | GetMyPageComplianceFail
  | GetMyPageSkillProfile
  | GetMyPageSkillProfileSuccess
  | GetMyPageSkillProfileFail
  | GetMyPageCoreSkills
  | GetMyPageCoreSkillsSuccess
  | GetMyPageCoreSkillsFail
  | GetMyPageSupportSkills
  | GetMyPageSupportSkillsSuccess
  | GetMyPageSupportSkillsFail
  | GetMyPageCrossSkills
  | GetMyPageCrossSkillsSuccess
  | GetMyPageCrossSkillsFail
  | GetMyPageCrossSkillPath
  | GetMyPageCrossSkillPathSuccess
  | GetMyPageCrossSkillPathFail
  | GetMyPagePrimarySkill
  | GetMyPagePrimarySkillSuccess
  | GetMyPagePrimarySkillFail
  | GetMyPageFutureSkills
  | GetMyPageFutureSkillsSuccess
  | GetMyPageFutureSkillsFail
  | GetMyPageFutureSkillPath
  | GetMyPageFutureSkillPathSuccess
  | GetMyPageFutureSkillPathFail

