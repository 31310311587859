import {INotifications} from "./notifications.interface";

export const NotificationsInitState : INotifications = {
   Payload:{
    AdminMessageCollection:[],
    BellResultCollection:[],
    CommonMessageCollection:[],
   },
    AllNotifications:[],
    NewNotificationsLoading:true,
    NewNotificationsLoadedSuccesfully:false,
    OldNotificationsLoading:false,
    OldNotificationsLoadedSuccesfully:false
}