import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YoutubeVideoPlayerComponent } from '../youtube-video-player/youtube-video-player.component';

@Component({
  selector: 'yorbit-video-player-popup',
  templateUrl: './video-player-popup.component.html',
  styleUrls: ['./video-player-popup.component.scss']
})
export class VideoPlayerPopupComponent implements OnInit {
  textMsg: string;
  addToLP;
  constructor(
    public dialogRef: MatDialogRef<YoutubeVideoPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.addToLP=false
    this.textMsg="video"
    if(this.dialogData&& this.dialogData.type=="scorm"){
      this.textMsg="content"
    }
    
    if(this.dialogData && this.dialogData.addToLp!=undefined){
      this.addToLP=this.dialogData.addToLp;
    }else{
      this.addToLP=false
    }
  }
  close() {
    this.dialogRef.close();
  }

  addToLp(){
    this.dialogRef.close('AddToLp');
  }
}
