import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { PlayerVarsO365, OptionalParams } from './bitmovinnew-player.config';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { VideoPlayersService } from '../shared/video-players/video-players.service';
import { PlaylistService } from '../shared/playlist/playlist.service';
import { Subscriber } from 'rxjs';
import { LearningPathService } from '../learning-path/learning-path.service';
import * as fromUserDetailsStore from '../shared/user-details/store'
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as _ from 'lodash';
import {
  LPBreadcrumbsStateModel,
  ILPDetailsModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
} from '../learning-path/learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from '../learning-path/learning-path-breadcrumbs.interface';
import { VideoPlayerPopupComponent } from '../shared/video-players/video-player-popup/video-player-popup.component';
import { Globals } from '../globals';
import { filter } from 'rxjs/operators';
import { VideoProgressMandateCoursesService } from '../shared/services/video-progress-mandate-courses.service';
import { AddPopupComponent } from '../shared/card-feature/add-content/add-popup/add-popup.component';

import { Player, PlayerEvent } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player-ui';

@Component({
  selector: 'yorbit-bitmovinnewcomponent',
  templateUrl: './bitmovinnewcomponent.component.html',
  styleUrls: ['./bitmovinnewcomponent.component.scss']
})
export class BitmovinnewcomponentComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() PlayerConfig: any;
  player: InstanceType<typeof Player> | null = null;
  bitmplayerConfig: any;
  ampService: any;
  videoPlayerId: any;
  videoCompSubscriptions: any;
  playlist: any;
  currentVideoId: string | number;
  progressCaptureAllowed: boolean;
  routeParams: any;
  playerVar: any;
  currentPlaylistIndex: any;
  fullPlaylistLoaded: boolean;
  isCompliance: boolean;
  stopSeeking: boolean;
  previousPlayerTime: number;
  canPlayAll: boolean;
  LPBreadcrumbs: ILPBreadcrumbs;
  showPreloader: boolean;
  canShowMessage: boolean;
  progressCaptureSetIntervalSubscription: any;
  is25PercentCaptured: boolean;
  is50PercentCaptured: boolean;
  is75PercentCaptured: boolean;
  is90PercentCaptured: boolean;
  isProgressPopupOpen: boolean;
  isVideoProgressMandatedCourse: boolean;
  canSeekMandatoryVideo: boolean;
  videoLoadedFirstTime: boolean;
  isVideoLoadedForFirstTime = true;
  indexForShowingVideoMsg: number = 0;
  indexForShowingVideoMsgToClose: number = 0;
  multiContentCompletion: any;
  @Input() isIntroVideo?: boolean;
  @Input() IsAccordionVideo?: boolean;
  @Input() contentData;
  @ViewChild('MyVideoContainer', { read: ElementRef }) videoContainer: ElementRef<HTMLElement>;


  @Output() closePopupEventEmitter = new EventEmitter<string>();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private videoPlayersService: VideoPlayersService,
    private playlistService: PlaylistService,
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _popup: MatDialog,
    private globals: Globals,
    private videoProgressMandateCoursesService: VideoProgressMandateCoursesService
  ) {
    this.videoPlayerId = 'bitmovin-video-player-container';
    this.ampService = window['amp'];
    this.videoCompSubscriptions = {};
    this.playlist = [];
    this.currentVideoId = '';
    this.currentPlaylistIndex = -1;
    this.fullPlaylistLoaded = false;
    this.isCompliance = false;
    this.stopSeeking = false;
    this.previousPlayerTime = 0;
    this.LPBreadcrumbs = { ...LPBreadcrumbsStateModel };
    this.videoLoadedFirstTime = true;
    this.bitmplayerConfig = {
      key: 'b17c51f3-906f-49e1-b421-24037d6c1eb9',
      ui: false,
    };
  }
  subscribeToCanPlayAllFlag() {
    this.playlistService.getCanPlayAllVideosFlag().subscribe((value) => {
      this.canPlayAll = value;
    });
  }

  ngOnInit() {
    this.canShowMessage = false;
    this.showPreloader = true;
    this.playerVar = this.PlayerConfig
      ? this.PlayerConfig.PlayerVars
      : PlayerVarsO365;
    //can capture progress if the course is added to lp
    this.progressCaptureAllowed = this.PlayerConfig
      ? this.PlayerConfig.CanCaptureProgress
      : OptionalParams.CanCaptureProgress;
    this.videoPlayerId = this.PlayerConfig
      ? this.PlayerConfig.VideoPlayerId
      : OptionalParams.VideoPlayerId;
    this.isProgressPopupOpen = false;

    this.isVideoProgressMandatedCourse = false;
    this.canSeekMandatoryVideo = false;
    this.videoLoadedFirstTime = true;
    this.getVideoPauseStatus();
    if (this.IsAccordionVideo) {
      this.pauseIntroVideo();
    }
    this.videoPlayersService.getCompletionStatus().subscribe((res) => {
      this.multiContentCompletion = res;
    });



  }

  getVideoPauseStatus() {
    this.videoPlayersService.getVideoPauseStatus().subscribe((isPaused) => {
      if (isPaused && this.player != null && !this.isVideoLoadedForFirstTime) {
        this.player.pause();
      }
    });
  }

  ngAfterViewInit() {
    console.log(this.playerVar)
    // this.player = this.ampService(this.videoPlayerId, this.playerVar);
    this.player = new Player(this.videoContainer.nativeElement, this.bitmplayerConfig);
    UIFactory.buildDefaultUI(this.player)
    this.registerEventHandler();
    if (this.PlayerConfig !== undefined && this.PlayerConfig !== null) {
      if (
        this.PlayerConfig.VideoDetails.sourceUrl.indexOf(
          'cloudfront.net'
        ) == -1
      ) {
        this.canShowMessage = true;
        this.showPreloader = false;
        if (this.player != null) {
          this.player.pause();
        }
      } else {
        this.canShowMessage = false;
        this.loadVideo(this.PlayerConfig.VideoDetails);
      }
      if (this.isIntroVideo) {
        this.videoPlayersService.introductoryVideoPlayerObj = this.player;
      }
    } else {
      this.subscribeToCanPlayAllFlag();
      this.subscribeToRouteParams();
      this.subscribeToVideoPlaylist();
      this.subscribeToBreadCrumbEvents();
      this.subscribeToRouterEvents();

    }


  }



  ngOnDestroy() {
    this.resetTimer();
    //this.videoPlayersService.updateCachedVideoDetailsToCaptureProgress(null);
    if (!this.isVideoLoadedForFirstTime) {
      this.getVideoProgress('onDestroy');
    }
    setTimeout(() => {
      this.unsubscribeAllSubscriptions();
      if (this.player != null) {
        this.player.pause();
        this.destroyPlayer();
      }
    }, 1000);
  }

  subscribeToBreadCrumbEvents() {
    this.videoCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe((breadcrumbs) => {
        if (breadcrumbs.VideoDetails.VideoId !== null)
          this.LPBreadcrumbs = breadcrumbs;
        if (
          this.routeParams.videoId &&
          this.LPBreadcrumbs.CourseDetails.CourseId != null &&
          this.LPBreadcrumbs.CourseDetails.CourseId != undefined
        ) {
          this.isCompliance = this.isMandateCourse(
            this.LPBreadcrumbs.CourseDetails.CourseId
          );
          // this.canSeekMandatoryVideo = this.getAttemptsCount(this.LPBreadcrumbs.CourseDetails.CourseId) > 0 ? true : false;
          this.isVideoProgressMandatedCourse =
            this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
              this.LPBreadcrumbs.CourseDetails.CourseId
            );
          this.canSeekMandatoryVideo =
            this.videoPlayersService.canSeekMandatoryVideo(
              this.LPBreadcrumbs.CourseDetails.CourseId
            );
        } else if (this.routeParams.id) {
          this.isCompliance = this.isMandateCourse(this.routeParams.id);
          this.isVideoProgressMandatedCourse =
            this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
              this.routeParams.id
            );
          this.canSeekMandatoryVideo =
            this.videoPlayersService.canSeekMandatoryVideo(this.routeParams.id);
        } else {
          this.isCompliance = false;
          this.isVideoProgressMandatedCourse = false;
        }
      });
  }

  subscribeToRouteParams() {
    this.videoCompSubscriptions.routeParamsSubscriptions =
      this.activatedRoute.params.subscribe((params) => {
        this.videoLoadedFirstTime = true;
        this.routeParams = params;
        if (this.routeParams.id != undefined) {
          this.isCompliance = this.isMandateCourse(params.id);
          // this.canSeekMandatoryVideo = this.getAttemptsCount(params.id) > 0 ? true : false;
          this.isVideoProgressMandatedCourse =
            this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
              params.id
            );
          this.canSeekMandatoryVideo =
            this.videoPlayersService.canSeekMandatoryVideo(params.id);
        } else if (this.routeParams == _.isEmpty({})) {
          this.isCompliance = false;
          this.isVideoProgressMandatedCourse = false;
        }
        if (this.fullPlaylistLoaded) {
          this.playlistService.updateSelectedVideoId({
            source: 'bitm',
            id: this.routeParams['videoid'],
            playlistLength: this.playlist.length,
            allUnitsLoaded: true,
          });
        }
        this.playlist.forEach((video, key) => {
          if (
            video.videoId.toString() === this.routeParams['videoid'].toString()
          ) {
            this.currentPlaylistIndex = key;
            if (
              video.sourceUrl.indexOf(
                'cloudfront.net'
              ) == -1
            ) {
              this.canShowMessage = true;
              this.showPreloader = false;

              if (this.player != null) {
                this.player.pause();
              }
              this.showPreloader = false;
            } else {
              this.canShowMessage = false;
              this.loadVideo(video);
            }
          }
        });
        this.currentVideoId = params['videoId'];
      });
  }

  subscribeToVideoPlaylist() {
    this.videoCompSubscriptions.playlistSubscription = this.videoPlayersService
      .getVideosPlaylist()
      .subscribe((data: any) => {
        if (data.playlist.length === 0) {
          this.playlistService.updateSelectedVideoId({
            source: 'bitm',
            id: this.routeParams['videoid'],
            playlistLength: 0,
            allUnitsLoaded: false,
          });
        }
        if (data.allUnitsLoaded && data.playlist.length !== 0) {
          this.fullPlaylistLoaded = true;
          this.playlistService.updateSelectedVideoId({
            source: 'bitm',
            id: this.routeParams['videoid'],
            playlistLength: data.playlist.length,
            allUnitsLoaded: data.allUnitsLoaded,
          });
          this.playlist = data.playlist;
          this.playlist.forEach((video, key) => {
            if (
              video.videoId.toString() ===
              this.routeParams['videoid'].toString()
            ) {
              this.currentPlaylistIndex = key;
              if (
                video.sourceUrl.indexOf(
                  'cloudfront.net'
                ) == -1
              ) {
                this.canShowMessage = true;
                this.showPreloader = false;

                if (this.player != null) {
                  this.player.pause();
                }
                this.showPreloader = false;
              } else {
                this.canShowMessage = false;

                this.loadVideo(video);
              }
            }
          });
        }
      });
  }

  loadVideo(video) {
    if (this.progressCaptureAllowed) {
      this.resetProgressRange();
    }
    this.showPreloader = true;
    let ItemId;

    if (
      this.LPBreadcrumbs.PackageDetails != null &&
      this.LPBreadcrumbs.PackageDetails.PackageId != null
    ) {
      ItemId = this.LPBreadcrumbs.PackageDetails.PackageId;
    } else {
      ItemId = this.LPBreadcrumbs.CourseDetails.CourseId;
    }

    this.PlayerConfig ? (video.videoId = video.ContentId) : '';

    const sourceConfig = {
      dash: video.sourceUrl,
    };

    this.player.load(sourceConfig)
      .then(() => {
        this.showPreloader = false;
        // you can also get notified when subscribed to SourceLoaded event.
        console.log(this.player.getAvailableVideoQualities());
        this.player.setVideoQuality("video_2600000");

        //console.log(this.player.getVideoQuality());
        this.videoPlayersService.updateCachedVideoDetailsToCaptureProgress(
          null
        );
        //Added this condition to stop auto play of intro video in details page
        if (this.playerVar.autoplay && this.progressCaptureAllowed) {
          // this.player.play();
        }
        //  this.player.startTimerAfterDelay(20);
        this.captureProgressInIntervals(ItemId);
        console.log(this.playlist)
        console.log(this.currentPlaylistIndex)

        if (this.playlist[this.currentPlaylistIndex]) {
          let startSeconds = parseInt(
            this.playlist[this.currentPlaylistIndex].resumeTime
          );
          this.previousPlayerTime = startSeconds - 1;
          this.player.seek(startSeconds);
        }

        //  this.player.buffer.setTargetLevel(MKBufferType.ForwardDuration,10000,MKMediaType.Video);

      })
      .catch((error) => {
        console.error(error);
        console.error("An error occurred while loading the source!");
      });


  }

  registerEventHandler() {
    const that = this;


    this.player.on(this.player.exports.PlayerEvent.SourceLoaded, (event) => {
      console.log("Video Loaded")

      if (that.videoLoadedFirstTime) {
        console.log("Video Loaded First Time")
        that.videoLoadedFirstTime = false;
        console.log(that.playlist)
        if (that.playlist[that.currentPlaylistIndex]) {
          let startSeconds = parseInt(
            that.playlist[that.currentPlaylistIndex].resumeTime
          );
          that.previousPlayerTime = startSeconds - 1;
          that.player.seek(startSeconds);
        }
        that.videoLoadedFirstTime = false;
      }
    });

    this.player.on(PlayerEvent.Play,() => {
      if (that.PlayerConfig && that.PlayerConfig.showInfoMsg === true) {
        if (that.indexForShowingVideoMsg < 1) {
          that.videoCompSubscriptions.warningMsgPopupSubscription = setTimeout(
            () => {
              //console.log(that.player.currentTime())
              if (that.indexForShowingVideoMsg < 1) {
                that.player.pause();
                that.openpopup();
              }
              that.indexForShowingVideoMsg++;
            }, 5000);
        }
      }
      if (that.videoLoadedFirstTime) {
        that.videoLoadedFirstTime = false;
        let startSeconds = parseInt(
          that.playlist[that.currentPlaylistIndex].resumeTime
        );
        that.previousPlayerTime = startSeconds - 1;
        that.player.seek(startSeconds);
        that.videoLoadedFirstTime = false;
      }
      // console.log(this.progressCaptureAllowed)
      if (that.progressCaptureAllowed) {
        that.getVideoProgress('playing');
      }

    });

    // this.player.on($.MKPlayerEvent.VideoQualityChanged, (event) => {
    //   console.log(event);
    // })

    this.player.on(PlayerEvent.Paused, () => {
      if (that.isCompliance) {
        if (that.stopSeeking) {
          that.player.seek(that.previousPlayerTime);
        }
      }
      if (that.PlayerConfig && that.PlayerConfig.showInfoMsg === true) {
        that.player.seek(that.previousPlayerTime);
      }

      if (that.progressCaptureAllowed) {
        that.getVideoProgress('pause');
      }
    });


    this.player.on(PlayerEvent.TimeChanged, () => {

      if (that.PlayerConfig && that.PlayerConfig.showInfoMsg === true) {
        if (Math.floor(that.player.getCurrentTime()) > 59) {
          that.player.pause();
          if (that.indexForShowingVideoMsgToClose < 1) {
            that.player.pause();
            that.openPopupAndCloseVideo();
            that.indexForShowingVideoMsgToClose++;
          }
        }
      }
      if (this.previousPlayerTime !== Math.floor(that.player.getCurrentTime())) {
        if (
          Math.floor(that.player.getCurrentTime()) - that.previousPlayerTime >
          2
        ) {
          if (that.isCompliance && !that.canSeekMandatoryVideo) {
            that.stopSeeking = true;
            that.player.seek(that.previousPlayerTime);
          } else if (
            that.PlayerConfig &&
            that.PlayerConfig.showInfoMsg === true
          ) {
            that.player.seek(that.previousPlayerTime);
          } else {
            that.stopSeeking = false;
            that.previousPlayerTime = Math.floor(that.player.getCurrentTime());
          }
        } else if (
          that.previousPlayerTime > Math.floor(that.player.getCurrentTime())
        ) {
          that.stopSeeking = false;
        } else {
          that.stopSeeking = false;
          that.previousPlayerTime = Math.floor(that.player.getCurrentTime());
        }
      }
    });


    this.player.on(PlayerEvent.PlaybackFinished, () => {
      if (that.progressCaptureAllowed) {
        that.getVideoProgress('ended');
      }
    });

    this.player.on(PlayerEvent.Seeked, () => {

      if (that.progressCaptureAllowed && !that.isCompliance) {
        that.getVideoProgress('seeked');
      }
    });


  }

  playNextVideo() {
    if (this.progressCaptureAllowed) {
      this.resetProgressRange();
    }
    if (this.canPlayAll) {
      if (this.playlist[this.currentPlaylistIndex + 1]) {
        let sourceType = this.playlist[
          this.currentPlaylistIndex + 1
        ].sourceType.toLowerCase();
        let sourceNav =
          sourceType == 'youtube' ||
            sourceType == 'o365' ||
            sourceType == 'scorm' ||
            sourceType == 'bitm'
            ? sourceType
            : 'others';
        this.router.navigate(
          [
            '../../../' + sourceNav + '/videoId/' +
            this.playlist[this.currentPlaylistIndex + 1].videoId,
          ],
          {
            relativeTo: this.activatedRoute,
          }
        );
      }
    }
  }

  getVideoProgress(event) {
    let videoDetailsToCaptureProgress: any;
    let isLastVideoInPlaylist = false;
    if (this.PlayerConfig !== undefined && this.PlayerConfig != null) {
      videoDetailsToCaptureProgress = {
        ...this.PlayerConfig.VideoDetails,
      };
      //get path id from user details store
      this.userDetailsStore
        .select(fromUserDetailsStore.getUnDeletedLPsSelector)
        .subscribe((lps) => {
          lps.forEach((lp) => {
            lp.PackageList.forEach((content) => {
              if (this.PlayerConfig.VideoDetails.ItemId === content.ItemId) {
                videoDetailsToCaptureProgress['PathId'] = lp.PathId;
              }
            });
          });
        });
    } else {
      videoDetailsToCaptureProgress = {
        ContentId: this.LPBreadcrumbs.VideoDetails.VideoId,
        UnitId: this.LPBreadcrumbs.UnitDetails.UnitId,
        CourseId: this.LPBreadcrumbs.CourseDetails.CourseId,
        ItemId: this.LPBreadcrumbs.PackageDetails.PackageId,
        Expertise: this.LPBreadcrumbs.CourseDetails.Expertise,
      };
      if (
        this.LPBreadcrumbs.LPDetails.LPCategory === 'mandatory' ||
        this.LPBreadcrumbs.LPDetails.LPCategory === 'myCompliance'
      ) {
        this.userDetailsStore
          .select(
            fromUserDetailsStore.getLearningPathByIdEntities(
              this.LPBreadcrumbs.LPDetails.IsLPMandatory
                ? this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
                : this.LPBreadcrumbs.LPDetails.LPId
            )
          )
          .subscribe((lp) => {
            videoDetailsToCaptureProgress['PathId'] = lp.PathId;
          });
      } else {
        videoDetailsToCaptureProgress['PathId'] =
          this.LPBreadcrumbs.LPDetails.LPId;
      }
      if (this.LPBreadcrumbs.PackageDetails.PackageId == null) {
        videoDetailsToCaptureProgress.ItemId =
          this.LPBreadcrumbs.CourseDetails.CourseId;
        videoDetailsToCaptureProgress.ItemType = 'Course';
      } else {
        videoDetailsToCaptureProgress.ItemType = 'FamilyPackage';
      }
      if (this.currentPlaylistIndex + 1 === this.playlist.length) {
        isLastVideoInPlaylist = true;
      } else {
        isLastVideoInPlaylist = false;
      }
    }

    if (this.progressCaptureAllowed) {
      const currentTime = this.player.getCurrentTime();
      const duration = this.player.getDuration();
      if (event == 'ended' || duration - currentTime < 3) {
        videoDetailsToCaptureProgress.CurrentTime = duration;
        videoDetailsToCaptureProgress.TotalTime = duration;
        this.playlist[this.currentPlaylistIndex].resumeTime = 0;
        this.playlist = [...this.playlist];
        //Progress capture 100%
        if (
          this.canSeekMandatoryVideo ||
          (!this.canSeekMandatoryVideo &&
            videoDetailsToCaptureProgress.CurrentTime -
            this.previousPlayerTime <
            2)
        ) {
          let payload = {};
          payload['ContentId'] = videoDetailsToCaptureProgress['ContentId'];
          payload['UnitId'] = videoDetailsToCaptureProgress['UnitId'];
          payload['CourseId'] = videoDetailsToCaptureProgress['CourseId'];
          payload['ItemId'] = videoDetailsToCaptureProgress['ItemId'];
          payload['ItemType'] = videoDetailsToCaptureProgress['ItemType'];
          payload['PathId'] = videoDetailsToCaptureProgress['PathId'];
          payload['Expertise'] = videoDetailsToCaptureProgress['Expertise'];
          payload['Time'] = videoDetailsToCaptureProgress['CurrentTime'];
          payload['TotalTime'] = videoDetailsToCaptureProgress['TotalTime'];
          //update lp resume details
          if (
            payload['UnitId'] != null &&
            payload['ContentId'] != null &&
            payload['TotalTime'] != 0
          ) {
            this.videoPlayersService
              .captureProgressNoSubs(payload['PathId'], payload)
              .toPromise()
              .then((res) => {
                this.videoPlayersService.updateFetchCompletionStatus(this.multiContentCompletion, this.LPBreadcrumbs, this.playlist[this.currentPlaylistIndex].videoId).then(result => {
                  if (result.Response && this.LPBreadcrumbs.VideoDetails.VideoId == this.routeParams['videoid']) {
                    this.playNextVideo();
                  }
                }).catch((error) => {
                  console.log("Something went wrong:", error);
                });
              });
          }
        }
        //Ends
      } else {
        if (currentTime > this.playlist[this.currentPlaylistIndex].resumeTime) {
          this.playlist[this.currentPlaylistIndex].resumeTime = currentTime;
          this.playlist = [...this.playlist];
        }
        videoDetailsToCaptureProgress.CurrentTime = currentTime;
        videoDetailsToCaptureProgress.TotalTime = duration;
      }
      videoDetailsToCaptureProgress.IsLastVideoInPlaylist =
        isLastVideoInPlaylist;


      if (event != 'cacheVideoProgress') {
        if (
          (this.isCompliance || this.isVideoProgressMandatedCourse) &&
          (this.canSeekMandatoryVideo ||
            (!this.canSeekMandatoryVideo &&
              videoDetailsToCaptureProgress.CurrentTime -
              this.previousPlayerTime <
              2))
        ) {
          this.videoPlayersService.updateVideoDetailsToCaptureProgress(
            videoDetailsToCaptureProgress
          );
          this.videoPlayersService.updateCachedVideoDetailsToCaptureProgress(
            videoDetailsToCaptureProgress
          );

        } else {
          //rbl check
          if ('Role Based Learning' == videoDetailsToCaptureProgress.PathId) {
            this.userDetailsStore
              .select(fromUserDetailsStore.getUnDeletedLPsSelector)
              .subscribe((lps) => {
                lps.forEach((lp) => {
                  if ('Role Based Learning' == lp.PathName) {
                    videoDetailsToCaptureProgress['PathId'] = lp.PathId;
                  }
                });
              });

            videoDetailsToCaptureProgress['ItemType'] = 'RoleBasedPackage';

            this.videoPlayersService.updateVideoDetailsToCaptureProgress(
              videoDetailsToCaptureProgress
            );
          } else {
            this.videoPlayersService.updateVideoDetailsToCaptureProgress(
              videoDetailsToCaptureProgress
            );
          }
        }
      } else if (
        (this.isCompliance || this.isVideoProgressMandatedCourse) &&
        event == 'cacheVideoProgress' &&
        !this.isProgressPopupOpen &&
        videoDetailsToCaptureProgress.TotalTime > 0 &&
        (this.canSeekMandatoryVideo ||
          (!this.canSeekMandatoryVideo &&
            videoDetailsToCaptureProgress.CurrentTime -
            this.previousPlayerTime <
            2))
      ) {
        let currentTime = videoDetailsToCaptureProgress.CurrentTime;
        let totalTime = videoDetailsToCaptureProgress.TotalTime;
        let progressRange = this.videoPlayersService.getPercentageRange(
          currentTime,
          totalTime
        );
        if (
          (progressRange == 0 && !this.is25PercentCaptured) ||
          (progressRange == 1 && !this.is50PercentCaptured) ||
          (progressRange == 2 && !this.is75PercentCaptured) ||
          (progressRange == 3 && !this.is90PercentCaptured)
        ) {
          this.videoPlayersService.updateVideoDetailsToCaptureProgress(
            videoDetailsToCaptureProgress
          );

          this.videoPlayersService.updateCachedVideoDetailsToCaptureProgress(
            videoDetailsToCaptureProgress
          );
          this.videoPlayersService.captureCachedProgress().then((data) => {
            //do nothing
          });
          switch (progressRange) {
            case 0:
              this.is25PercentCaptured = true;
              break;
            case 1:
              this.is50PercentCaptured = true;
              break;
            case 2:
              this.is75PercentCaptured = true;
              break;
            case 3:
              this.is90PercentCaptured = true;
              break;
            default:
              break;
          }
        }
      }
    }
  }

  constructPayloadForGetProgress(videoDetails) {
    let payload = {};
    payload['ContentId'] = videoDetails['ContentId'];
    payload['UnitId'] = videoDetails['UnitId'];
    payload['CourseId'] = videoDetails['CourseId'];
    payload['ItemId'] = videoDetails['ItemId'];
    payload['ItemType'] = videoDetails['ItemType'];
    payload['PathId'] = videoDetails['PathId'];
    payload['Expertise'] = videoDetails['Expertise'];
    payload['Time'] = videoDetails['CurrentTime'];
    payload['TotalTime'] = videoDetails['TotalTime'];
    return payload;
  }

  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.videoCompSubscriptions) {
      let subscriber = this.videoCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }

  destroyPlayer() {
    this.player.destroy();
    if (this.progressCaptureSetIntervalSubscription != null) {
      clearInterval(this.progressCaptureSetIntervalSubscription);
      this.progressCaptureSetIntervalSubscription = null;
    }
  }

  openpopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      type: 'video',
    };
    const response = this._popup.open(VideoPlayerPopupComponent, dialogConfig);
    response.afterClosed().subscribe((res) => {
      //do something after pop up close
      this.player.play();
    });
  }

  openPopupAndCloseVideo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      type: 'video',
      addToLp: true,
      close: true,
    };
    const response = this._popup.open(VideoPlayerPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      //console.log(res);
      //do something after pop up close
      this.closePopupEventEmitter.emit('true');
      if (res == 'AddToLp') {
        this.openAddToLpDialog();
      }
    });
  }

  openAddToLpDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //addDialogContainer
    dialogConfig.data = {
      contentName: this.contentData.Name,
      Id: this.contentData.Id,
      ItemType: this.contentData.ItemType,
      uniqueId: this.contentData.UniqueId,
      accountId: this.contentData.Account,
      projectId: this.contentData.Project,
      AccountPackage: this.contentData.AccountPackage,
      ProjectPackage: this.contentData.ProjectPackage,
      Title: this.contentData.Name,
      skillProfile: {
        recommendationType: '',
        duration: '',
      },
      addedLocation: 'Details-Page',
      contentData: this.contentData,
    };

    const dialogRef = this._popup.open(AddPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((response) => { });
  }

  subscribeToRouterEvents() {
    this.videoCompSubscriptions.routerEventsSub = this.router.events.subscribe(
      (event: any) => {
        if (event.url != undefined) {
          if (event.url.split('playlist')[1] == '') {
            if (this.LPBreadcrumbs.LPDetails.LPId != null) {
              if (this.LPBreadcrumbs.PackageDetails.PackageId != null) {
                this.router.navigate([
                  'learningpath/category/' +
                  this.LPBreadcrumbs.LPDetails.LPCategory +
                  '/id/' +
                  this.LPBreadcrumbs.LPDetails.LPId +
                  '/package/' +
                  this.LPBreadcrumbs.PackageDetails.PackageId,
                ]);
              } else {
                this.router.navigate([
                  'learningpath/category/' +
                  this.LPBreadcrumbs.LPDetails.LPCategory +
                  '/id/' +
                  this.LPBreadcrumbs.LPDetails.LPId,
                ]);
              }
            }
          }
        }
      }
    );
  }

  isMandateCourse(courseId) {
    if (courseId != undefined) {
      const id = courseId.toString();
      if (
        this.globals.courseListForShowingLastCompletedDate.indexOf(id) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  captureProgressInIntervals(courseId) {
    let isMandatory = this.isMandateCourse(courseId);
    let isVideoProgressMandatedCourse =
      this.videoProgressMandateCoursesService.isVideoProgressMandatedTo100(
        courseId
      );
    if (
      this.progressCaptureAllowed &&
      !this.videoCompSubscriptions.quizPopupSubscription
    ) {
      this.videoCompSubscriptions.quizPopupSubscription =
        this.videoPlayersService
          .getQuizPopupOpenStatus()
          .subscribe((isPopupOpen) => {
            if (isPopupOpen) {
              this.resetTimer();
              this.isProgressPopupOpen = isPopupOpen;
              if (this.player != null) this.player.pause();
            } else {
              if (
                this.progressCaptureAllowed &&
                this.progressCaptureSetIntervalSubscription == null
              ) {
                //  start timer after 3 seconds
                this.startTimerAfterDelay(3000);
              }
            }
          });
    }
    if (isMandatory || isVideoProgressMandatedCourse) {
      //Start a background thread to cache the progress
      if (
        this.progressCaptureAllowed &&
        this.progressCaptureSetIntervalSubscription == null
      ) {
        this.progressCaptureSetIntervalSubscription = setInterval(() => {
          //Cache progress of the player every 5 seconds
          if (this.progressCaptureSetIntervalSubscription != null) {
            this.getVideoProgress('cacheVideoProgress');
          }
        }, 10000);
      }
    }
  }

  resetTimer() {
    if (this.progressCaptureSetIntervalSubscription != null) {
      clearInterval(this.progressCaptureSetIntervalSubscription);
      this.progressCaptureSetIntervalSubscription = null;
    }
  }

  startTimerAfterDelay(delay) {
    setTimeout(() => {
      this.progressCaptureSetIntervalSubscription = setInterval(() => {
        //Cache progress of the player every 5 seconds
        this.getVideoProgress('cacheVideoProgress');
      }, 10000);
    }, delay);
  }

  resetProgressRange() {
    this.is25PercentCaptured = false;
    this.is50PercentCaptured = false;
    this.is75PercentCaptured = false;
    this.is90PercentCaptured = false;
  }

  getAttemptsCount(courseId): number {
    let entityid = courseId.toString();
    let result = 0;
    let attemptsCountSubscription = this.userDetailsStore
      .select(fromUserDetailsStore.getQuizByCourseIdSelector(entityid))
      .subscribe((quiz) => {
        if (attemptsCountSubscription) {
          attemptsCountSubscription.unsubscribe();
        }
        if (quiz && quiz.EntityId) {
          result = quiz.AttemptsCount;
        } else {
          return result;
        }
      });
    return result;
  }

  pauseIntroVideo() {
    if (this.videoPlayersService.introductoryVideoPlayerObj != null) {
      this.videoPlayersService.introductoryVideoPlayerObj.pause();
    }
  }
}