<mat-dialog-content>
    <!--Close Popup Icon-->
    <div class="closePopupBtn">
        <button class="mat-button mat-icon-button" *ngIf="uploadStatus !== 'submitted'" (click)="closePopup()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <!--Popup Header-->
    <div class="popup-title">
        <div class="heading">
            {{data.heading}}
        </div>
    </div>

    <div class="popup-content" fxLayout="column" fxLayoutAlign="center center">
        <!--Upload & Download-->
        <div class="uploadAndDownload">
            <button mat-raised-button class="downloadTemplateButton" *ngIf="data.hasDownloadTemplate"
                (click)="downloadTemplate()" [disabled]="templateDownloadInProgress"
                [ngClass]="{'disabled':templateDownloadInProgress}">
                Download default template</button>
            <div class="messageText" style="margin-top:-20px"
                *ngIf="data.hasDownloadTemplate && templateDownloadInProgress">Downloading...</div>
            <div class="fileUploadLabel">
                <input type="file" id="fileUpload" #file (change)="onFilesAdded(file.files)" />
                <div class="fileNameLabel">{{fileName}}</div>
                <button mat-raised-button class="browseFileButton"
                    [ngClass]="{'disabled': uploadStatus === 'submitted'}" [disabled]="uploadStatus === 'submitted'"
                    (click)="file.click()">Browse</button>
            </div>
        </div>
        <div class="messageText2">Please choose only .zip file of size less than 200MB.</div>
        <!--No Experts Message-->
        <div class="messageText">
            Note: Please clear browser cache (Ctrl+F5) and rename the file uniquely before every upload.<br />
        </div>
        <div class="actionButtonContainer">
            <button class="actionButton action-button" [disabled]="isUploadButtonDisabled || uploadStatus !== 'ready'"
                [ngClass]="{'disabled':isUploadButtonDisabled || uploadStatus !== 'ready'}"
                (click)="upload()">Upload</button>
            <button class="actionButton action-button" [disabled]="uploadStatus === 'submitted'"
                [ngClass]="{'disabled': uploadStatus === 'submitted'}" (click)="closePopup()">Cancel</button>
        </div>
        <div class="statusMessage" [ngStyle]="{'color': uploadStatus === 'success' ? 'green' : 'red'}"
            *ngIf="''!==actionMessage">
            {{actionMessage}}
        </div>
    </div>
</mat-dialog-content>