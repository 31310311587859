import { Component, OnInit, ViewChild } from '@angular/core';
import { DateFilterComponent } from '../pss-filter/date-filter/date-filter.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PssEvaluationService } from './pss-evaluation.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { PssAcademyComponent } from './pss-academy/pss-academy.component';
import { InputParameters, YorbitCourseRequestList } from './pss-evaluation.interface';
import * as XLSX from './../../../../../../node_modules/xlsx';
import { delay } from 'q';
import { PopupMessagesComponent } from './popup-messages/popup-messages.component';
import { PssDownloadPopupComponent } from '../pss-filters/pss-download-popup/pss-download-popup.component';

@Component({
  selector: 'yorbit-pss-evaluation',
  templateUrl: './pss-evaluation.component.html',
  styleUrls: ['./pss-evaluation.component.scss']
})
export class PssEvaluationComponent implements OnInit {

  @ViewChild('d', { static: false }) datepicker;
  divCondtion: boolean = true;
  startDate: any;
  endDate: any;
  maxDate: any;
  arrangeByCourseList: any[];
  @ViewChild(DateFilterComponent, { static: false }) filterdate;
  selectedFilterType: string;
  isRecordsLoading: boolean = false;
  selectedOpmSpocList: string[] = [];
  dataList: YorbitCourseRequestList[] = [];
  evaluatorType: string;
  selectedFromDate: any;
  selectedEndDate: any;
  isCourseNameFiltered: boolean = false;
  isAcademyFiltered: boolean = false;
  isCourseTypeFiltered: boolean = false;
  isEvalVendorFiltered: boolean = false;
  isCourseVendorFiltered: boolean = false;
  isSubStatusFiltered: boolean = false;
  isLearningOPMFiltered: boolean = false;
  inputObject: InputParameters = new InputParameters();
  checkedCourseList: string[] = [];
  checkedAcademyList: string[] = [];
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  checkedCourseVendorList: string[] = [];
  checkedEvaluatorVendorList: string[] = [];
  searchText: string;
  checkBoxList: string[] = [];
  minCertDate: any;
  result: any[];
  filteredResult: any[];
  searchTextList: any[] = [];
  isSearchInvalid: boolean = false;
  isToDateInvalid: boolean = false;
  isFromDateInvalid: boolean = false;
  searchRequestIds: number[] = [];
  searchMids: string[] = [];
  count: number = 0;
  toDateErrorText: string = "";
  fromDateErrorText: string = "";
  showLoading: any;

  pssEvaluationRequestList: any[];
  isLoadEvaluationList: boolean = false;
  isNoRecordsText: boolean = false;
  isDueForPublishing: boolean = false;
  isSlaHitRequests: boolean = false;
  isProjectRequests: boolean = false;
  isAssignmentRequests: boolean = false;
  isFilteredChecked: boolean = true;
  isAnyFilterApplied: boolean = false;
  isOnlyOneDateSelected: boolean = false;
  downloadFilteredMessage: string = "";

  constructor(private _PssEvaluationService: PssEvaluationService,
    private _popup: MatDialog) { }

  ngOnInit() {
    this.selectedFromDate = null;
    this.selectedEndDate = null;
    this._PssEvaluationService.checkedAcademyList = [];
    this._PssEvaluationService.checkedCourseAcademy = [];
    this._PssEvaluationService.checkedCourseList = [];
    this._PssEvaluationService.checkedCourseTypeList = [];
    this._PssEvaluationService.checkedCourseVendorList = [];
    this._PssEvaluationService.checkedEvaluatorVendorList = [];
    this._PssEvaluationService.checkedLearningOPMSPOCList = [];
    this._PssEvaluationService.checkedSubStatusList = [];
    this.showLoading = {
      contentData: false
    };
    this.maxDate = new Date();
    ////console.log(this.selectedFilterType);
    ////console.log('BooleAN', this.isAcademyFiltered, this.isCourseNameFiltered);
    this.getDataList();

  }

  getDataList() {
    this._PssEvaluationService.getFilterRecords().subscribe(data => {
      this.dataList = data as YorbitCourseRequestList[];
      this.showLoading.contentData = true;
      this._PssEvaluationService.getDataList(this.dataList, 'Academy');
      this.selectedFilterType = 'Academy';
      this.filterTypeChange(this.selectedFilterType);
    });

  }

  updatePssItems() {
    this.startDate = this.filterdate.startDate.apiFormat;
    this.endDate = this.filterdate.endDate.apiFormat;
    ////console.log(this.startDate);
    ////console.log(this.endDate);
  }

  filterTypeChange(filterTypeChange: string) {
    this.isAcademyFiltered = this._PssEvaluationService.checkedAcademyList.length > 0;

    this.selectedFilterType = filterTypeChange;
    if (filterTypeChange == "Course Name" || filterTypeChange == "Course Vendor")
      this.divCondtion = true;
    else
      this.divCondtion = false;
  }

  clearSelection(filterType: string) {
    this.selectedFilterType = "";
    switch (filterType) {
      case 'Academy':
        this._PssEvaluationService.checkedAcademyList = [];
        this.isAcademyFiltered = false;
        break;
      case 'Course Name':
        this._PssEvaluationService.checkedCourseList = [];
        this.isCourseNameFiltered = false;
        break;
      case 'Course Type':
        this._PssEvaluationService.checkedCourseTypeList = [];
        this.isCourseTypeFiltered = false;
        break;
      case 'Course Vendor':
        this._PssEvaluationService.checkedCourseVendorList = [];
        this.isCourseVendorFiltered = false;
        break;
      case 'Evaluator/Vendor Name':
        this._PssEvaluationService.checkedEvaluatorVendorList = [];
        this.isEvalVendorFiltered = false;
        break;
      case 'Certification Sub Status':
        this._PssEvaluationService.checkedSubStatusList = [];
        this.isSubStatusFiltered = false;
        break;
      case 'Learning OPM SPOC':
        this._PssEvaluationService.checkedLearningOPMSPOCList = [];
        this.isLearningOPMFiltered = false;
        break;
    }
  }

  academyFiltered(isFiltered: boolean) {
    this.isAcademyFiltered = isFiltered;
  }
  courseNameFiltered(isFiltered: boolean) {
    this.isCourseNameFiltered = isFiltered;
  }
  courseTypeFiltered(isFiltered: boolean) {
    this.isCourseTypeFiltered = isFiltered;
  }
  courseVendorFiltered(isFiltered: boolean) {
    this.isCourseVendorFiltered = isFiltered;
  }
  evalVendorFiltered(isFiltered: boolean) {
    this.isEvalVendorFiltered = isFiltered;
  }
  learningOPMFiltered(isFiltered: boolean) {
    this.isLearningOPMFiltered = isFiltered;
  }
  subStatusFiltered(isFiltered: boolean) {
    this.isSubStatusFiltered = isFiltered;
  }

  receiveMessage($event) {
    ////console.log($event);
    this.selectedOpmSpocList = $event;
  }

  onSelectionChange(value) {
    if (value == 'dueForPublishing') {
      if (this.isDueForPublishing == false)
        this.isDueForPublishing = true;
      else
        this.isDueForPublishing = false;
    }
    if (value == 'slaHitRequests') {
      if (this.isSlaHitRequests == false)
        this.isSlaHitRequests = true;
      else
        this.isSlaHitRequests = false;
    }
    if (value == 'projectRequests') {
      if (this.isProjectRequests == false)
        this.isProjectRequests = true;
      else
        this.isProjectRequests = false;
    }
    if (value == 'assignmentRequests') {
      if (this.isAssignmentRequests == false)
        this.isAssignmentRequests = true;
      else
        this.isAssignmentRequests = false;
    }
  }

  checkForFilters() {
    if (this.isAcademyFiltered || this.isCourseNameFiltered || this.isCourseTypeFiltered ||
      this.isEvalVendorFiltered || this.isCourseVendorFiltered || this.isSubStatusFiltered ||
      this.isLearningOPMFiltered || this.evaluatorType != null || this.isDueForPublishing ||
      this.isSlaHitRequests || this.isProjectRequests || this.isAssignmentRequests ||
      this.searchMids.length > 0 || this.searchRequestIds.length > 0 ||
      this.selectedFromDate != null || this.selectedEndDate != null) {
      if (this.selectedFromDate != null && this.selectedEndDate != null) { 
        this.isAnyFilterApplied = true;
        this.isOnlyOneDateSelected = false;
      }
      else if (this.selectedFromDate == null && this.selectedEndDate == null)
        this.isAnyFilterApplied = true;
      else {
        this.isOnlyOneDateSelected = true;
        this.isAnyFilterApplied = false;
      }
    }
    else {
      this.isAnyFilterApplied = false;
    }
  }

  applySearch() {
    this.checkForFilters();
    if(this.isOnlyOneDateSelected || this.isSearchInvalid || !this.isAnyFilterApplied) {
    if(this.isOnlyOneDateSelected && this.isSearchInvalid)
      this.downloadFilteredMessage = "Please apply valid Start date, End date and PSID(s) to fetch learners requests."
    else if(this.isOnlyOneDateSelected && !this.isSearchInvalid)
      this.downloadFilteredMessage = "Please apply valid StartDate and EndDate to fetch learners requests.";
    else if(!this.isOnlyOneDateSelected && this.isSearchInvalid)
      this.downloadFilteredMessage = "Please enter valid PSID(s) or Request ID(s) to fetch learners requests.";
    else if (!this.isAnyFilterApplied) 
      this.downloadFilteredMessage = 'Apply filter to search records';

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = this.downloadFilteredMessage;

      const dialogRef = this._popup.open(PopupMessagesComponent, dialogConfig);
    }
    else {
      if (!this.isOnlyOneDateSelected) {
        this.isNoRecordsText = false;
        this.isRecordsLoading = true;
        this.count = 0;
        this.isLoadEvaluationList = false;
        ////console.log('substatus', this._PssEvaluationService.checkedAcademyList);
        this.checkedSubStatusList = this._PssEvaluationService.checkedSubStatusList;
        this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
        this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
        this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
        this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
        this.checkedLearningOPMSPOCList = this._PssEvaluationService.checkedLearningOPMSPOCList;
        this.checkedEvaluatorVendorList = this._PssEvaluationService.checkedEvaluatorVendorList;
        ////console.log('Start Date:', this.selectedFromDate, 'To Date: ', this.selectedEndDate);
        this.searchMids = this.searchMids.map(function (item) { return item.toUpperCase() });

        this.inputObject.StartDate = this.selectedFromDate;
        this.inputObject.EndDate = this.selectedEndDate;
        this.inputObject.Academy = this.checkedAcademyList;
        this.inputObject.CourseUID = this.checkedCourseList;
        this.inputObject.CourseType = this.checkedCourseTypeList;
        this.inputObject.CourseVendor = this.checkedCourseVendorList;
        this.inputObject.EvaluatorVendor = this.checkedEvaluatorVendorList;
        this.inputObject.SPOC = this.checkedLearningOPMSPOCList;
        this.inputObject.SubStatus = this.checkedSubStatusList;
        this.inputObject.SearchMid = this.searchMids;
        this.inputObject.SearchRequestId = this.searchRequestIds;
        this.inputObject.EvaluationType = this.evaluatorType;
        this.inputObject.DueForPublish = this.isDueForPublishing;
        this.inputObject.ProjectRequest = this.isProjectRequests;
        this.inputObject.AssignmentRequest = this.isAssignmentRequests;
        this.inputObject.SLAHitRequest = this.isSlaHitRequests;
        this._PssEvaluationService.inputObject = this.inputObject;

        ////console.log('Input', this.inputObject);

        if (!this.isToDateInvalid && !this.isSearchInvalid) {
          this._PssEvaluationService.getFilteredData(5, 0, 'next').subscribe(
            data => {
              this.isRecordsLoading = false;
              this.isLoadEvaluationList = true;
              this.result = data as any[];
              this.pssEvaluationRequestList = this.result['PSSCertificateRequestItemList'];
              this.count = this.result['count'];
              this.pssEvaluationRequestList.map(item => {
                if (item.SubStatus.toLowerCase() === 'learningopm team to verify and publish result')
                  item.IsPublishButtonDisabled = false;
                else
                  item.IsPublishButtonDisabled = true;
              });
              if (this.count == 0) {
                this.isNoRecordsText = true;
                this.isLoadEvaluationList = false;
              }
            }
          );
        }
        else {
          const dialogConfig = new MatDialogConfig();   
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'popupDialogContainer';
          dialogConfig.data = 'Apply filter to search records';
    
          const dialogRef = this._popup.open(PopupMessagesComponent, dialogConfig);
        }
      }
    }
  }

  async resetAllFilters() {
    this.selectedFromDate = null;
    this.selectedEndDate = null;
    this.isLoadEvaluationList = false;
    this.isAcademyFiltered = false;
    this.isCourseNameFiltered = false;
    this.isCourseTypeFiltered = false;
    this.isEvalVendorFiltered = false;
    this.isCourseVendorFiltered = false;
    this.isSubStatusFiltered = false;
    this.isLearningOPMFiltered = false;
    this.isDueForPublishing = false;
    this.isSlaHitRequests = false;
    this.isProjectRequests = false;
    this.isAssignmentRequests = false;
    this.isOnlyOneDateSelected = false;
    this.isAnyFilterApplied = false;
    this.evaluatorType = null;
    this.searchText = "";
    this.searchRequestIds = [];
    this.searchMids = [];
    this._PssEvaluationService.checkedAcademyList = [];
    this._PssEvaluationService.checkedCourseList = [];
    this._PssEvaluationService.checkedCourseTypeList = [];
    this._PssEvaluationService.checkedCourseVendorList = [];
    this._PssEvaluationService.checkedLearningOPMSPOCList = [];
    this._PssEvaluationService.checkedEvaluatorVendorList = [];
    this._PssEvaluationService.checkedSubStatusList = [];
    this.toDateErrorText = "";
    this.isToDateInvalid = false;
    this.fromDateErrorText = "";
    this.isFromDateInvalid = false;
    this.isSearchInvalid = false;
    if (this.selectedFilterType == "Academy") {
      this.filterTypeChange("");
      await delay(10);
    }
    this.selectedFilterType = "Academy";
    this.filterTypeChange(this.selectedFilterType);
  }

  openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  fromDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.selectedFromDate = event.value;
    const todayDate = new Date();
    if (this.selectedFromDate > todayDate) {
      this.isFromDateInvalid = true;
      this.fromDateErrorText = "*From Date should not be greater than today's date";
    }
    else {
      this.isFromDateInvalid = false;
      this.fromDateErrorText = "";
    }
    if (this.selectedFromDate <= this.selectedEndDate) {
      this.isToDateInvalid = false;
    }
    else if (this.selectedEndDate != null) {
      this.isToDateInvalid = true;
      this.toDateErrorText = '*To date should be greater than From Date';
    }
    ////console.log('From', this.selectedFromDate);
  }

  toDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.selectedEndDate = event.value;
    const todayDate = new Date();
    if (this.selectedEndDate > todayDate) {
      this.isToDateInvalid = true;
      this.toDateErrorText = "*To Date should not be greater than today's date";
    }
    else if (this.selectedEndDate < this.selectedFromDate) {
      this.isToDateInvalid = true;
      this.toDateErrorText = '*To date should be greater than From Date';
    }
    else {
      this.isToDateInvalid = false;
    }
    ////console.log('To', this.selectedEndDate);
  }

  downloadRecords() {
    this.checkForFilters();
    if (!this.isAnyFilterApplied && this.isFilteredChecked) {
      const dialogConfig = new MatDialogConfig();
      if (this.isOnlyOneDateSelected)
        this.downloadFilteredMessage = 'Select both From and To dates to download filtered records';
      else
        this.downloadFilteredMessage = 'Apply filter to download filtered records';

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = this.downloadFilteredMessage;

      const dialogRef = this._popup.open(PopupMessagesComponent, dialogConfig);      
    }
    else {
      if (this.isFilteredChecked) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = {
          selectedTab: 'evaluation-requests',
          getAll: !this.isFilteredChecked
        };
        this._popup.open(PssDownloadPopupComponent, dialogConfig);
      }
      else {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = {
          selectedTab: 'evaluation-requests',
          getAll: !this.isFilteredChecked
        };
        this._popup.open(PssDownloadPopupComponent, dialogConfig);
      }
    }
  }

  closeContentComponent($event) {
    if ($event)
      this.isLoadEvaluationList = false;
  }

  validateSearchText() {
    this.searchTextList = [];
    this.searchMids = [];
    this.searchRequestIds = [];
    this.isSearchInvalid = false;
    if (this.searchText != null) {
      const learnerList = this.searchText
        .replace(/\n|\t|" "/g, ',')
        .replace(/(\r\n|\n|\r)/gm, '')
        .split(/,|;| /)
        .filter(mid => mid);
      learnerList.filter((mid, i) => learnerList.indexOf(mid) === i);
      this.searchTextList = [...this.searchTextList, ...learnerList];
    }
    ////console.log('learnerlist', this.searchTextList);
    const midRegex = /^([0-9]+)$/;
    const requestIdRegex = /^([0-9]+)$/;
    if (true || midRegex.test(this.searchTextList[0])) {
      this.searchTextList.forEach(element => {
        if (false || !midRegex.test(element))
          this.isSearchInvalid = true;
      });
      if (!this.isSearchInvalid) {
        this.searchMids = this.searchTextList;
      }
    }
    else if (requestIdRegex.test(this.searchTextList[0])) {
      this.searchTextList.forEach(element => {
        if (!requestIdRegex.test(element))
          this.isSearchInvalid = true;
      });
      if (!this.isSearchInvalid) {
        this.searchRequestIds = this.searchTextList;
      }
    }
    else if (this.searchText == "")
      this.isSearchInvalid = false;
    else
      this.isSearchInvalid = true;
  }
}
