<div class="tile-container" fxLayout="column">
  <div class="tile-cover" fxFlex="1 1 45%"
    [style.background-image]="item.ThumbnailImageUrl?'url(' + item.ThumbnailImageUrl + ')' :'url(../../../../../assets/images/riskbrigade/Risk_brigade_AzureVideo_default_thumbnail.jpg)'"
    fxLayout="row" fxLayoutAlign="center center">
    <img *ngIf="item.Type=='Video'" class="video-icon" src="../../../../assets/images//riskbrigade/icon-play.png" alt=""  (click)="openVideoPopup()">

  </div>
  <div class="tile-description" fxFlex="1 1 42%" fxLayout="column wrap" fxLayoutGap="10px">

    <div>
      <span class="news-article"  *ngIf="item.Type=='article'">
        News Article
      </span>

      <span class="video" *ngIf="item.Type=='Video'">
        Video
      </span>

      <span class="publication" *ngIf="item.Type=='publication'">
        Publication
      </span>
    </div>

    <div class="title" [title]="item?.Name">{{item?.Name}}</div>
    <div class="description" [title]="item?.Description">{{item?.Description}}
    </div>

  </div>
  <div class="button" fxFlex="1 1 13%" fxLayoutAlign="end center" (click)="gotoArticle()">
      <div class="row" fxLayout="row" fxLayoutAlign="center"><div class="lable">{{item.Type=='publication'?'Download':item.Type=='Video'?'Watch Video':'Read More'}}</div><img *ngIf="item.Type=='publication'" src="../../assets/images/riskbrigade/icon-download.png" alt=""></div>  
  </div>
</div>
