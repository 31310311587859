import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PowerbiComponent } from './powerbi.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { PowerbiService } from './powerbi.service';
import { PipesModule } from '@YorbitWorkspace/pipes';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  imports: [CommonModule, NgxPowerBiModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatDividerModule,

      PipesModule],
  declarations: [PowerbiComponent],
  providers: [PowerbiService],
  exports: [NgxPowerBiModule]
})
export class PowerbiModule {}
