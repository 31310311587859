<div fxLayout="row wrap" class="nasscom-recommendations-holder" *ngIf="!isRecommendationsLoading && !isRecommendationsFailed && nasscomFutureSkills.length > 0">
  <div fxFlex="100%" class="future-skill-title" *ngIf="showHeader">MICRO LEARNING CONTENT FROM FUTURE SKILLS</div>
  <div fxFlex="100%" class="scroll-box overflow-theme-transparent" *ngIf="!isRecommendationsLoading && !isRecommendationsFailed" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row"  class="future-skills-container" fxLayoutAlign="start center" fxHide fxShow.gt-xs="true">
        <ng-template [ngTemplateOutlet]="futureSkills" [ngTemplateOutletContext]="{
          lower:0,
          upper:4
        }"></ng-template>
    </div>
    <div fxLayout="row" class="future-skills-container" fxLayoutAlign="start center" fxHide fxShow.gt-xs="true" *ngIf="number == 8">
      <ng-template [ngTemplateOutlet]="futureSkills" [ngTemplateOutletContext]="{
        lower:4
      }"></ng-template>
  </div>
    <div fxLayout="column" fxHide fxShow.xs="true" fxLayoutAlign="start center">
      <ng-template [ngTemplateOutlet]="futureSkillsMobile" ></ng-template>
    </div>
  </div>
  <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="center center" class="scroll-box" *ngIf="">
    Please reload the page...
  </div>
</div>
<div div fxLayout="column" class="nasscom-recommendations-holder" *ngIf="isRecommendationsLoading && !isRecommendationsFailed">
  <app-preloader></app-preloader>
</div>
<ng-template #futureSkills let-upper="upper" let-lower="lower">
  <div fxLayout="column" *ngFor="let futureSkill of getSkills(lower,upper); let i = index" fxFlex="180px" class="future-skill mousePointer"
    (click)="redirectToNasscomSite(futureSkill)" title="Click to View">
    <div class="thumbnail" [ngStyle]="{'background-image': 'url(' + futureSkill.ThumbnailImage + ');'}">
      <div class="technology">
        {{futureSkill.Technology}}
      </div>
    </div>
    <div class="data" fxLayout="column">
      <div class="title" title="{{futureSkill.Title}}">
        {{futureSkill.Title}}
      </div>
      <div class="description" *ngIf="showDescription">
        {{futureSkill.Description}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #futureSkillsMobile>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div (click)="previous(navCount)" class="mousePointer">
        <div class="iconSpriteImage newAndTrendingPrev" [ngClass]="{
              'grey-out-elm': startCount <= 0,
              'active-elm': startCount > 0
            }"></div>
      </div>    
      <div fxLayout="column" *ngIf="futureSkillXS !== null"
        fxFlex="180px" class="future-skill mousePointer" (click)="redirectToNasscomSite(futureSkillXS)" title="Click to View">
        <div class="thumbnail" [ngStyle]="{'background-image': 'url(' + futureSkillXS.ThumbnailImage + ');'}">
          <div class="technology" >
            {{futureSkillXS.Technology}}
          </div>
        </div>
        <div class="data" fxLayout="column">
          <div class="title" title="{{futureSkillXS.Title}}">
            {{futureSkillXS.Title}}
          </div>
          <div class="description" *ngIf="showDescription">
            {{futureSkillXS.Description}}
          </div>
        </div>
      </div>
      <div (click)="next(navCount)"  class="mousePointer">
        <div class="iconSpriteImage newAndTrendingNext" [ngClass]="{
              'grey-out-elm': endCount > nasscomFutureSkills.length - 1,
              'active-elm': endCount < nasscomFutureSkills.length - 1
            }"></div>
      </div>
    </div>
    <div fxLayoutAlign="center center" class="paginate">
      {{ startCount + 1 + ' of ' + nasscomFutureSkills.length }}
    </div>
</ng-template>
