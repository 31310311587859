<mat-dialog-content class="popupDialog">
  <!--Close Popup Icon-->
  <div class="closePopupDialog">
    <button mat-button mat-icon-button (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <!--Popup Header-->
  <div class="popupHeader">Scorecard</div>
  
  <!--Mat Divider-->
  <mat-divider></mat-divider>
  
  <table class="tableStyle">
    <tr>
      <td class="tableRow">Scored</td>
      <td class="tableRow">Minimum</td>
      <td class="tableRow">Maximum</td>
    </tr>
    <tr>
      <td><input type="text" class="textBox" [disabled]="isScoreSecuredDisabled" [(ngModel)]="scoreSecured"
        (keyup)="onFocusOut()"></td>
      <td class="textBoxDiv">{{minimumScore}}</td>
      <td class="textBoxDiv">{{maximumScore}}</td>
    </tr>
  </table>
  <mat-divider></mat-divider>

  <div class="resultText">
    Result :
  </div>
  <div class="resultDiv">
    <input type="radio" class="clearedRadio" name="result" value="Cleared" 
      (change)="onRadioButtonChange('Clear')" checked>Cleared
    <input type="radio" class="rejectRadio" name="result" value="Reject Evaluation"
      (change)="onRadioButtonChange('Reject Evaluation')" >Reject Evaluation
  </div>

  <mat-divider></mat-divider>
  
  <!--No Experts Message-->
  <div class= "messageText">
    <div class="remarksText">
      Remarks<sup class="redColor">*</sup>
    </div>
    <div>
      <textarea class="textAreaDiv" rows="5" [(ngModel)]="comments" (keyup)="onTextAreaChange($event)"></textarea>
    </div>
  </div>
  
   <!--Mat Divider-->
  <mat-divider></mat-divider>

  <div>
    <div class="submitButtonDiv" matTooltip="{{toolTipMessage}}" 
    [matTooltipDisabled]="!disableSubmit" matTooltipClass="tooltipStyle">
      <button mat-raised-button class="actionButton upload-btn" [disabled]="disableSubmit" 
      (click)="onSubmit()">Submit</button>
    </div>
    <div class="cancelButtonDiv">
      <button mat-raised-button class="actionButton cancel-btn" (click)="closePopup()">Cancel</button>
    </div>
  </div>
</mat-dialog-content>
  
  