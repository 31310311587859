import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountAssignGapCourse'
})
export class AccountAssignGapCoursePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    ////console.log(value, args);
    if (value !== undefined && value.length !== 0 && args.length !== 0) {
      let returnedValue;
      if (args.length !== 0) {
        returnedValue = [];
        value.forEach(element => {
          args.forEach(ele => {
            if (element.CourseUniqueId === ele.CourseUniqueId) {
              returnedValue.push(element);
              return returnedValue;
            }
          });
        });
      } else {
        returnedValue = value;
      }
      return returnedValue;
    } else {
      return value;
    }
  }

}
