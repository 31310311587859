import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBrowseContent'
})
export class FilterBrowseContentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value !== undefined && value.length !== 0) {
      if (
        args.Skill.length == 0 &&
        args.Expertise.length == 3 &&
        args.Type.length == 0 &&
        args.SearchBy.length == 0
      ) {
        return value;
      } else {
        return value.filter(element => {
          if (element.Type === undefined) {
            element.Type = element.CourseType;
          }
          return (
            (args.Skill.indexOf(element.Skill) != -1 ||
              args.Skill.indexOf(element.Category) != -1 ||
              args.Skill.length === 0) &&
            (args.Expertise.indexOf(element.Expertise) != -1 ||
              args.Expertise.length === 3) &&
            (args.Type.indexOf(element.Type) != -1 || args.Type.length === 0) &&
            (element.Name.indexOf(args.SearchBy) != -1 ||
              element.Expertise.indexOf(args.SearchBy) != -1 ||
              element.ItemType.indexOf(args.SearchBy) != -1 ||
              args.SearchBy === '')
          );
        });
      }
    } else {
      return value;
    }
  }
}
