import { Injectable } from '@angular/core';
import { Ienvironment, EnvironmentService } from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PssFilterService {
  config: Ienvironment;
  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService
    ) {
    this.config = this._envSvc.getEnvironment();
     }

     getPssFilters() {
      return this.http
        .get<any>(this.config.apiUrl + 'PSS/Filters')
        .toPromise();
    }



}
