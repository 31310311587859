import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationEnd
} from '@angular/router';
import { LearningPathService } from './learning-path.service';
import * as fromUserDetailsStore from '../shared/user-details/store';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, scan, take } from 'rxjs/operators';
import { IuserDetails } from '../shared/user-details/store/user-details.interface';
import { PopupTriggerService } from '../shared/services/popup-trigger.service';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { LPBreadcrumbsStateModel } from './learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from './learning-path-breadcrumbs.interface';
import { Subscriber } from 'rxjs';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AddPopupComponent } from '../shared/card-feature/add-content/add-popup/add-popup.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CreateLpComponent } from '../shared/create-lp/create-lp/create-lp.component';
import { CreateLpPopupComponent } from '../shared/create-lp/create-lp-popup/create-lp-popup.component';
import { AddedContentDataService } from '../shared/services/added-content-data.service';
import { Globals } from '../globals';
import { PopupService } from '../shared/global-popups/popup.service';
import { CourseFeedbackComponent } from '../shared/global-popups/course-feedback/course-feedback.component';
import { WorkflowServiceService } from '../shared/services/workflow-service.service';
import { ContentTileLpService } from '../shared/content-tiles/content-tile-lp/content-tile-lp.service';
import { CloudlabStoreService } from '../shared/cloudlab-store/cloudlab-store.service';

@Component({
  selector: 'yorbit-learning-path',
  templateUrl: './learning-path.component.html',
  styleUrls: ['./learning-path.component.scss'],
  inputs: ['fxFlex']
})
export class LearningPathComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('LPnavbar', { static: true }) LPnavbar: any;
  @ViewChild('lpTextbox', { static: false }) lpTextboxField: ElementRef;
  @ViewChild('autoComplete', { static: false }) searchAutoComplete: MatAutocomplete;
  mouseHoveredOnIconTick: boolean = false;
  mediaBreakPoint: string;
  LPCategories: Array<string>;
  hasSideNavBackdrop: boolean;
  LPnavbarMode: string;
  mandatoryLps: Array<any>;
  skillingLps: Array<any>;
  myComplianceLp: Array<any>;
  userCreatedLps: Array<any>;
  LPPageSubscriptions: any = {};
  LPBreadcrumbs: ILPBreadcrumbs;
  learningPathsLoading: boolean;
  learningPathsLoadedSuccessfully: boolean;
  searchLPModel: string;
  resumeDetails: any;
  arrayFilterPipe: any;
  showSearchBarInLP: boolean;
  canSearchInLP: boolean;
  currentLP: any;
  lpContent: any;
  createLpEditMode: boolean;
  createNewLPInProgress: boolean;
  learningPathTitle: string = '';
  lpCreated: boolean;
  addedLinkClick: boolean = false;
  highlightedLPId = '';
  showSearchResults = false;
  searchResultsLoaded = false;
  searchResults = [];
  degreedLps: Array<any>;
  constructor(
    private dialog: MatDialog,
    private mediaObserver: MediaObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _dialog: MatDialog,
    private globals: Globals,
    private _popUpSvc: PopupService,
    private snackBar: MatSnackBar,
    private _popupTriggerService: PopupTriggerService,
    private _workflowService: WorkflowServiceService,
    private _cloudlabStoreSvc: CloudlabStoreService,
    public _contentTileLpService: ContentTileLpService,
    private addedContentDataService: AddedContentDataService
  ) {
    this.currentLP = {};
    this.lpContent = [];
    this.mediaBreakPoint = '';
    this.createLpEditMode = false;
    this.LPCategories = ['self', 'myCompliance', 'mandatory', 'skilling'];
    this.learningPathsLoading = true;
    this.learningPathsLoadedSuccessfully = false;
    this.mandatoryLps = [];
    this.skillingLps = [];
    this.myComplianceLp = [];
    this.userCreatedLps = [];
    this.searchLPModel = '';
    this.resumeDetails = null;
    this.arrayFilterPipe = new ArrayPropertyFilterPipe();
    this.showSearchBarInLP = false;
    this.canSearchInLP = false;
    this.showSearchResults = false;
    this.searchResults = [];
    this.searchResultsLoaded = false;
    this.degreedLps = [];
  }
  ngOnInit() {
    this.learningPathsLoadedSuccessfully = false;
    this.userDetailsStore.dispatch(
      new fromUserDetailsStore.UserDetailsGetDetails()
    );

    //Update user degreed course completion in docdb
    this.lpService.updateDegreedUserDocdb().subscribe(() => {
      console.log("degreed docdb update");
    });

    //set inital route by passing argument true
    this.getLearningPaths(true);
    this.LPPageSubscriptions.canTriggerPopupsOnLoadSub = this._popupTriggerService.canTriggerPopupsOnLoad.subscribe(
      canTriggerPopupOnLoad => {
        if (canTriggerPopupOnLoad) {
          this.checkFeedback();
        }
      }
    );
    if (localStorage.getItem('QuizRefresh')) {
      localStorage.removeItem('QuizRefresh');
    }

  }
  ngAfterViewInit() {
    this.loadLPNavbarView();
    if (this.addedLinkClick) {
      if (document.getElementById(this.highlightedLPId) != undefined) {
        //console.log('scrollToTargetedLP ng after view init');
        this.scrollToTargetedLP();
      }
    }
  }
  getLearningPaths(setInitialRoute) {
    if (this.LPPageSubscriptions.getUserDetailLoaded) {
      this.LPPageSubscriptions.getUserDetailLoaded.unsubscribe();
      this.LPPageSubscriptions.getUserDetailLoaded = null;
    }
    // this.lpService.updateDegreedUserDocdb().subscribe(res => {
    //   console.log("degreed docdb update", res);
    // });
    this.LPPageSubscriptions.getUserDetailLoaded = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailLoaded)
      .subscribe(loaded => {
        //console.log("user details loaded");
        if (loaded && !this.learningPathsLoadedSuccessfully) {
          this.LPPageSubscriptions.getUserCreatedLPsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getUserCreatedLPsSelector)
            .subscribe(res => {
              this.userCreatedLps = res.filter(path => path.PathName.toLowerCase() !== this.globals.degreedLPName.toLowerCase());
            });
          this.LPPageSubscriptions.getUserCreatedLPsSelector.unsubscribe();
          this.LPPageSubscriptions.getUserCreatedLPsSelector = null;
          this.LPPageSubscriptions.getSkillPathsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getSkillPathsSelector)
            .subscribe(res => {
              this.skillingLps = res.filter(path => path.PathName.toLowerCase() !== this.globals.degreedLPName.toLowerCase());
            });
          this.LPPageSubscriptions.getSkillPathsSelector.unsubscribe();
          this.LPPageSubscriptions.getSkillPathsSelector = null;
          this.LPPageSubscriptions.getSystemGeneratedMandatoryLPsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getSystemGeneratedMandatoryLPsSelector)
            .subscribe(res => {
              if (res.length > 0) {
                // console.log(res);
                this.mandatoryLps = res.filter(path => {
                  if (
                    (path.PathName.toLowerCase() !==
                      this.globals.CocLpName.toLowerCase() && path.PathName.toLowerCase() !== this.globals.BEACLpName.toLowerCase()) &&
                    path.PathName.toLowerCase() !==
                    'sexual harassment prevention training - usa' &&
                    path.PathName.toUpperCase() != this.globals.newPOSHUSLpName &&
                    path.PathName.toLowerCase() !== this.globals.degreedLPName.toLowerCase()
                  ) {
                    return true;
                  }
                });

                this.mandatoryLps = this.mandatoryLps.filter(path => {
                  if (path.PathName.toLowerCase() == 'risk brigade' && path.PackageList.length == 0) {
                    return false
                  }
                  return true;
                });




                let complianceLPs = res.filter(path => {
                  if (
                    (path.PathName.toLowerCase() ===
                      this.globals.CocLpName.toLowerCase() || path.PathName.toLowerCase() === this.globals.BEACLpName.toLowerCase()) ||
                    path.PathName.toLowerCase() ===
                    'sexual harassment prevention training - usa' ||
                    path.PathName.toUpperCase() === this.globals.newPOSHUSLpName &&
                    path.PathName.toLowerCase() !== this.globals.degreedLPName.toLowerCase()
                  ) {
                    return true;
                  }
                });
                if (complianceLPs.length > 0) {
                  let orderList = [];
                  let lpList = [];
                  //Ordering the complianceLPOrder in ascening order just in case it is shuffled
                  this.lpService.complianceLPOrder.forEach((row, index) => {
                    let lpOrder = null;
                    lpOrder = this.lpService.complianceLPOrder.filter(
                      x => x.Order == index + 1
                    )[0];
                    if (lpOrder) {
                      orderList.push(lpOrder);
                    }
                  });
                  orderList.forEach((row, index) => {
                    let lp = null;
                    lp = complianceLPs.filter(
                      ele =>
                        row.PathName.toLowerCase() == ele.PathName.toLowerCase()
                    )[0];
                    if (lp) {
                      if (lpList.length == 0) {
                        lpList.push(lp);
                      } else {
                        let exist = lpList.filter(
                          x =>
                            x.PathName.toLowerCase() == row.PathName.toLowerCase()
                        )[0];
                        if (!exist) {
                          lpList.push(lp);
                        }
                      }
                    }
                  });
                  this.myComplianceLp = lpList;
                };
                //this.mandatoryLps = res;
              }
            });
          this.LPPageSubscriptions.getSystemGeneratedMandatoryLPsSelector.unsubscribe();
          this.LPPageSubscriptions.getSystemGeneratedMandatoryLPsSelector = null;
          this.LPPageSubscriptions.getDegreedUserLPsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getDegreedUserLPsSelector)
            .subscribe(res => {
              this.degreedLps = res.filter(path => path.PathName.toLowerCase() === this.globals.degreedLPName.toLowerCase());
              // console.log("storeeeeeeeeeeeeee", res, this.degreedLps);
            });
          this.LPPageSubscriptions.getDegreedUserLPsSelector.unsubscribe();
          this.LPPageSubscriptions.getDegreedUserLPsSelector = null;
          this.LPPageSubscriptions.contentAddedDetailsLoadedSub = this.addedContentDataService.contentAddedDetailsLoaded.subscribe(res => {
            if (res) {
              this.addedContentDataService.updatePackageCourseMappingsInStore();
              this.canSearchInLP = loaded;
            }
          });

          this.subscribeMediaChanges();
          this.subscribeToBreadCrumbEvents();
          this.learningPathsLoadedSuccessfully = loaded;
          this.subscribeToRouterEvents();
          this.getResumeDetails();
          this.getCloudLabs();
          this._workflowService.getAll201WorkflowStatus();
        } else if (loaded && this.learningPathsLoadedSuccessfully) {
          this.LPPageSubscriptions.getUserCreatedLPsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getUserCreatedLPsSelector)
            .subscribe(res => {
              this.userCreatedLps = res.filter(path => path.PathName.toLowerCase() !== this.globals.degreedLPName.toLowerCase());
            });
          this.LPPageSubscriptions.getUserCreatedLPsSelector.unsubscribe();
          this.LPPageSubscriptions.getUserCreatedLPsSelector = null;
          this.LPPageSubscriptions.getSkillPathsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getSkillPathsSelector)
            .subscribe(res => {
              this.skillingLps = res.filter(path => path.PathName.toLowerCase() !== this.globals.degreedLPName.toLowerCase());
            });
          this.LPPageSubscriptions.getSkillPathsSelector.unsubscribe();
          this.LPPageSubscriptions.getSkillPathsSelector = null;
          this.LPPageSubscriptions.getSystemGeneratedMandatoryLPsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getSystemGeneratedMandatoryLPsSelector)
            .subscribe(res => {
              if (res.length > 0) {
                this.mandatoryLps = res.filter(path => {
                  if (
                    (path.PathName.toLowerCase() !==
                      this.globals.CocLpName.toLowerCase() && path.PathName.toLowerCase() !== '') &&
                    path.PathName.toLowerCase() !==
                    'sexual harassment prevention training - usa' &&
                    path.PathName.toUpperCase() !== this.globals.newPOSHUSLpName &&
                    path.PathName.toLowerCase() !== this.globals.degreedLPName
                  ) {
                    return true;
                  }
                });
                let complianceLPs = res.filter(path => {
                  if (
                    (path.PathName.toLowerCase() ===
                      this.globals.CocLpName.toLowerCase() || path.PathName.toLowerCase() === this.globals.BEACLpName.toLowerCase()) ||
                    path.PathName.toLowerCase() ===
                    'sexual harassment prevention training - usa' ||
                    path.PathName.toUpperCase() === this.globals.newPOSHUSLpName &&
                    path.PathName.toLowerCase() !== this.globals.degreedLPName.toLowerCase()
                  ) {
                    return true;
                  }
                });
                if (complianceLPs.length > 0) {
                  let orderList = [];
                  let lpList = [];
                  //Ordering the complianceLPOrder in ascening order just in case it is shuffled
                  this.lpService.complianceLPOrder.forEach((row, index) => {
                    let lpOrder = null;
                    lpOrder = this.lpService.complianceLPOrder.filter(
                      x => x.Order == index + 1
                    )[0];
                    if (lpOrder) {
                      orderList.push(lpOrder);
                    }
                  });
                  orderList.forEach((row, index) => {
                    let lp = null;
                    lp = complianceLPs.filter(
                      ele =>
                        row.PathName.toLowerCase() == ele.PathName.toLowerCase()
                    )[0];
                    if (lp) {
                      if (lpList.length == 0) {
                        lpList.push(lp);
                      } else {
                        let exist = lpList.filter(
                          x =>
                            x.PathName.toLowerCase() == row.PathName.toLowerCase()
                        )[0];
                        if (!exist) {
                          lpList.push(lp);
                        }
                      }
                    }
                  });
                  this.myComplianceLp = lpList;
                };
                //this.mandatoryLps = res;
              } else {
                this.mandatoryLps = [];
              }
            });
          this.LPPageSubscriptions.getSystemGeneratedMandatoryLPsSelector.unsubscribe();
          this.LPPageSubscriptions.getSystemGeneratedMandatoryLPsSelector = null;
          this.LPPageSubscriptions.getDegreedUserLPsSelector = this.userDetailsStore
            .select(fromUserDetailsStore.getDegreedUserLPsSelector)
            .subscribe(res => {
              this.degreedLps = res.filter(path => (path.PathName.toLowerCase() === this.globals.degreedLPName.toLowerCase()));
            });
          this.LPPageSubscriptions.getDegreedUserLPsSelector.unsubscribe();
          this.LPPageSubscriptions.getDegreedUserLPsSelector = null;
          if (setInitialRoute) {
            this.initialLPLoad();
          }
        }
      });
  }

  learningPath(lp) {
    // console.log(lp)
  }
  initialLPLoad() {
    if (this.myComplianceLp.length != 0) {
      this.navigateAcrossLP(
        'myCompliance',
        this.myComplianceLp[0].PathName.toUpperCase(),
        this.myComplianceLp[0]
      );
    } else if (this.mandatoryLps.length != 0) {
      this.navigateAcrossLP(
        'mandatory',
        this.mandatoryLps[0].PathName,
        this.mandatoryLps[0].PathName
      );
    } else if (this.skillingLps.length != 0) {
      this.navigateAcrossLP(
        'skilling',
        this.skillingLps[0].PathId,
        this.skillingLps[0].PathName
      );
    } else if (this.userCreatedLps.length != 0) {
      this.navigateAcrossLP(
        'self',
        this.userCreatedLps[0].PathId,
        this.userCreatedLps[0].PathName
      );
    } else if (this.degreedLps.length != 0) {
      this.navigateAcrossLP(
        'degreed',
        this.degreedLps[0].PathId,
        this.degreedLps[0].PathName
      )
    } else {
      this.lpService.updateBreadcrumbs(null, null, null, null, null);
      this.router.navigate(['learningpath']);
    }
  }
  navigateAcrossLP(category, lpId, item) {
    this.currentLP = item;
    this.lpContent = item.PackageList;
    if (this.mediaObserver.isActive('xs')) {
      this.LPnavbar.close();
    };
    this.router.navigate(['learningpath/category/' + category + '/id/' + lpId]);
  }
  getResumeDetails() {
    this.LPPageSubscriptions.getResumeDetailsSub = this.userDetailsStore
      .select(fromUserDetailsStore.getLPResumeDetails)
      .subscribe(resumeDetails => {
        ////console.log('resume details update store lp comp');
        if (resumeDetails && resumeDetails.ItemId) {
          this.resumeDetails = resumeDetails;
        }
      });
  }
  subscribeToRouterEvents() {
    this.LPPageSubscriptions.routerEventsSub = this.router.events
      .subscribe((event: any) => {
        if (event.url == '/learningpath') {
          this.initialLPLoad();
        } else {

        }
      });
    this.LPPageSubscriptions.routeQueryParamsSubscriptions = this.activatedRoute.queryParams.subscribe(
      params => {
        //console.log('params', params);
        // Defaults to 0 if no query param provided.
        if (
          params['courseId'] != undefined ||
          params['packageId'] != undefined ||
          params['lpId'] != undefined
        ) {
          //scroll content till the content tile
          this.addedLinkClick = true;
          if (params['lpId'] != undefined) {
            this.highlightedLPId = 'lp' + params['lpId'].toLowerCase();
          }
          this.loadLPNavbarView();
          if (document.getElementById(this.highlightedLPId) != undefined) {
            //console.log('scrollToTargetedLP ng after view init');
            this.scrollToTargetedLP();
          }
        } else if (this.router.url == '/learningpath') {
          this.initialLPLoad();
        }
        //console.log(' this.highlightedLPId', this.highlightedLPId);
      }
    );
  }
  updateSearchModelOld() {
    this.lpService.updateSearchModel(this.searchLPModel);
  }
  handleRecentSearches() {
    if (this.searchLPModel.length == 0 && this.searchResults.length > 0) {
      this.searchResults = [];
    }
  }
  updateSearchModel(event) {
    this.searchResultsLoaded = false;
    if (event.length > 0) {
      this.showSearchResults = true;
      this.searchResults = [{ Name: "preloader" }];
      this.searchResultsLoaded = false;
      this.LPPageSubscriptions.searchSub = from(event).pipe(
        debounceTime(1500),
        scan((total, n) => total.toString() + n.toString())
      ).subscribe(text => {
        ////console.log("this.searchLPModel text", this.searchLPModel);
        this.addedContentDataService
          .searchForContentInsideLP(this.searchLPModel)
          .then((searchResults: any) => {
            if (searchResults) { // Ensure searchResults is not null or undefined
              var searchResults1 = this.removeDuplicates(searchResults, "Id");
              console.log(searchResults1);
              this.searchResultsLoaded = true;
              if (Array.isArray(searchResults1) && searchResults1.length > 0) { // Check if searchResults1 is an array and has elements
                this.searchResults = searchResults1;
              } else {
                this.searchResults = [];
                console.log("empty works");
              }
            } else {
              this.searchResults = [];
              this.searchResultsLoaded = true; // Ensure searchResultsLoaded is set to true
              console.log("searchResults is null or undefined");
            }
          })
          .catch(error => {
            console.error("Error in searchForContentInsideLP:", error);
            this.searchResults = [];
            this.searchResultsLoaded = true; // Ensure searchResultsLoaded is set to true in case of error
          });
        console.log(this.searchResults, this.searchResults.length, this.searchLPModel.length, this.searchResultsLoaded);
      });
    } else {
      this.showSearchResults = false;
      this.searchResults = [];
    }
  }

  removeDuplicates(myArray, Prop) {
    if (!myArray) return []; // Added check to return an empty array if myArray is null or undefined
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }


  navigateToSearchedContent(item) {
    this.showSearchResults = false;
    this.searchResults = [];
    this.searchLPModel = '';
    //click action
    let addedLPDetails = {
      LPId: '',
      LPCategory: '',
      CourseId: '',
      PackageId: null
    };
    if (item.Type == 'Course') {
      addedLPDetails.CourseId = item.Id;
      if (item.PackageId) {
        addedLPDetails.PackageId = item.PackageId;
      }
    } else {
      addedLPDetails.CourseId = null;
      addedLPDetails.PackageId = item.PackageId;
    }
    if (item.IsMandatory) {
      if (item.IsSkillPlan || item.IsCrossSkill || item.IsFutureSkill) {
        addedLPDetails.LPCategory = 'skilling';
        addedLPDetails.LPId = item.PathId;
      } else if (
        item.PathName.toLowerCase() == this.globals.CocLpName.toLowerCase() || item.PathName.toLowerCase() == this.globals.BEACLpName.toLowerCase() ||
        item.PathName.toLowerCase() ==
        'sexual harassment prevention training - usa' ||
        item.PathName.toUpperCase() == this.globals.newPOSHUSLpName
      ) {
        addedLPDetails.LPCategory = 'myCompliance';
        addedLPDetails.LPId = item.PathName;
      } else if (item.PathName.toLowerCase() == 'role based learning') {
        addedLPDetails.LPCategory = 'rolebased'
        addedLPDetails.LPId = item.PathName;
      } else {
        addedLPDetails.LPCategory = 'mandatory';
        addedLPDetails.LPId = item.PathName;
      }
    } else if (item.PathName.toLowerCase() === this.globals.degreedLPName.toLowerCase()) {
      addedLPDetails.LPCategory = 'degreed';
      addedLPDetails.LPId = item.PathId;
    } else {
      addedLPDetails.LPCategory = 'self';
      addedLPDetails.LPId = item.PathId;
    }
    //send other route data to highlight the content in LP
    if ((item.Type == 'Course' && !item.PackageId) || item.Type != 'Course') {
      this.router.navigate(
        [
          'learningpath/category/' +
          addedLPDetails.LPCategory +
          '/id/' +
          addedLPDetails.LPId
        ],
        {
          queryParams: {
            lpId: addedLPDetails.LPId,
            courseId: addedLPDetails.CourseId,
            packageId: addedLPDetails.PackageId
          }
        }
      );
    } else {
      this.router.navigate(
        [
          'learningpath/category/' +
          addedLPDetails.LPCategory +
          '/id/' +
          addedLPDetails.LPId +
          '/package/' +
          addedLPDetails.PackageId
        ],
        {
          queryParams: {
            lpId: addedLPDetails.LPId,
            courseId: addedLPDetails.CourseId,
            packageId: addedLPDetails.PackageId
          }
        }
      );
    }
  }
  subscribeMediaChanges() {
    this.LPPageSubscriptions.mediaChangeSubscription = this.mediaObserver.media$.subscribe(
      (media: MediaChange) => {
        //////console.log("media query subs",media);
        this.mediaBreakPoint = media.mqAlias;
        //add this.router.url=='/learningpath' check in the below if condition for xs
        if (media.mqAlias == 'xs') {
          this.hasSideNavBackdrop = true;
          this.LPnavbarMode = 'over';
        } else if (media.mqAlias != 'xs') {
          this.hasSideNavBackdrop = false;
          this.LPnavbarMode = 'side';
        }
        if (this.LPnavbar != undefined) {
          this.openLPNavBar();
        }
      }
    );
  }
  subscribeToBreadCrumbEvents() {
    //create breadcrumbs model and interface to init it in different components as well instead in the service
    this.LPPageSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        if (breadcrumbs.LPDetails.LPId) {
          this.LPPageSubscriptions.getLearningPathByIdEntitiesInsideBreadCrumbSub = this.userDetailsStore
            .select(
              fromUserDetailsStore.getLearningPathByIdEntities(
                //  breadcrumbs.LPDetails.IsLPMandatory ? breadcrumbs.LPDetails.LPName:breadcrumbs.LPDetails.LPId
                breadcrumbs.LPDetails.LPId.toUpperCase()
              )
            )
            .subscribe(lp => {
              if (lp) {
                this.lpContent = lp.PackageList;
              }
              else {
                this.lpContent = [];
              }
            });
        }
        this.LPBreadcrumbs = breadcrumbs;
        console.log("LPBreadcrumbs", this.LPBreadcrumbs)
        if (this.LPBreadcrumbs.CourseDetails.CourseId) {
          this.LPnavbar.close();
        } else {
          if (!this.mediaObserver.isActive('xs')) {
            this.LPnavbar.open();
          }
        }
      });
  }

  scrollToTargetedLP() {
    let ele = document.getElementById(this.highlightedLPId);
    //console.log('this.highlightedLPId', this.highlightedLPId);
    //console.log('ele.offsetTop', ele.offsetTop);
    //console.log('ele.top', ele.getBoundingClientRect().top);
    if (ele.offsetTop > 300) {
      document.getElementById('scrollable-lp').scrollTop = ele.offsetTop - 300;
      //ele.getBoundingClientRect().top;
    } else if (ele.offsetTop < 0) {
      document.getElementById('scrollable-lp').scrollTop = ele.scrollTop;
    }
    setTimeout(() => {
      if (this.mediaBreakPoint == 'xs') {
        this.LPnavbar.close();
      }
    }, 2000);
  }

  //navigateto function comes in lp content tile comp
  navigateTo(data) {
    if (data == 'LP') {
      this.router.navigate([
        'learningpath/category/' +
        this.LPBreadcrumbs.LPDetails.LPCategory +
        '/id/' +
        this.LPBreadcrumbs.LPDetails.LPId
      ]);
    } else if (data == 'Package') {
      this.router.navigate([
        'learningpath/category/' +
        this.LPBreadcrumbs.LPDetails.LPCategory +
        '/id/' +
        this.LPBreadcrumbs.LPDetails.LPId +
        '/package/' +
        this.LPBreadcrumbs.PackageDetails.PackageId
      ]);
    }
  }
  loadLPNavbarView() {
    if (this.mediaObserver.isActive('xs')) {
      this.hasSideNavBackdrop = true;
      this.LPnavbarMode = 'over';
    } else if (this.mediaObserver.isActive('gt-xs')) {
      this.hasSideNavBackdrop = false;
      this.LPnavbarMode = 'side';
    }
    //console.log('opn nav bar loadLPNavbarView');
    //this.LPnavbar.close();
  }

  openLPNavBar() {
    if (this.LPBreadcrumbs != undefined) {
      if (
        this.LPBreadcrumbs.CourseDetails.CourseId != null &&
        this.mediaBreakPoint != 'xs'
      ) {
        this.LPnavbar.close();
      } else {
        //console.log('opn nav bar openLPNavBar');
        this.LPnavbar.open();
      }
      this.LPPageSubscriptions.mediaChangeSubscription.unsubscribe();
    }
  }
  navbarOpenedChange(event) {
    //console.log('navbarOpenedChange', event);
    if (event && this.addedLinkClick) {
      if (document.getElementById(this.highlightedLPId) != undefined) {
        //console.log('scrollToTargetedLP ng after view init');
        this.scrollToTargetedLP();
      }
    }
  }
  createNewLP() {
    this.createLpEditMode = true;
    setTimeout(() => {
      this.lpTextboxField.nativeElement.focus();
    }, 100);
    /*
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.disableClose = true;
    this.dialog.open(CreateLpComponent,dialogConfig);
    */
  }
  onInputFocusOut(event) {
    if (this.learningPathTitle.length > 0) {

    }
    else if (!this.mouseHoveredOnIconTick) {
      this.createLpEditMode = false;
      this.learningPathTitle = ''
    }
  }
  onMouseEnterOnIconTick() {
    this.mouseHoveredOnIconTick = true;
  }
  onMouseLeaveOnIconTick() {
    this.mouseHoveredOnIconTick = false;
  }
  clearInputTextName(event) {
    this.createLpEditMode = false;
    this.learningPathTitle = ''
  }
  createLPKeyEvent(event) {
    if (13 === event.keyCode) this.createLearningPath(event);
  }
  createLearningPath(event) {
    this.createNewLPInProgress = true;
    this.mouseHoveredOnIconTick = false;
    this.lpService
      .checkLpExistAndAdd(
        this.learningPathTitle,
        this.userCreatedLps,
        this.skillingLps
      )
      .then(result => {
        this.createLpEditMode = false;
        this.createNewLPInProgress = false;
        this.lpCreated = true;
        this.learningPathTitle = '';
        //initial route flag set to false
        this.getLearningPaths(false);
        // this.userDetailsStore.dispatch(
        //   new fromUserDetailsStore.UserDetailsAddLearningPath(result)
        // );
        //  this.dialogRef.close();
        this.openSnackBar('Learning Path has been created successfully!');
        // this.addContentToLearningPath(result);
      })
      .catch(error => {
        this.learningPathTitle = '';
        this.createLpEditMode = false;
        this.createNewLPInProgress = false;
        this.lpCreated = false;
        // this.openSnackBar(error.errorMessage);
        this.openLPDialog(error.errorMessage);
      });
  }
  openSnackBar(message) {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'end';
    // config.panelClass="custom-snackbar";
    this.snackBar.open(message, undefined, config);
  }
  deleteLP(lp) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'popupDialogContainer';
    //dialogue-box-data
    dialogConfig.data = {};
    dialogConfig.data.title = lp.PathName;
    dialogConfig.disableClose = true;
    // let deleteDialogBox: MatDialogRef<any>;
    if (lp.PackageList.length === 0) {
      dialogConfig.data.message1 =
        'Are you sure you want to delete ' + lp.PathName + '?';
      dialogConfig.data.showButtons = true;
      // dialogConfig.width = '350px';
      // dialogConfig.height = '130px';
      let deleteDialogBox = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      deleteDialogBox.afterClosed().subscribe(data => {
        if (data) {
          this.lpService.deleteLearningPath(lp).then(response => {
            if (response) {
              this.userDetailsStore.dispatch(
                new fromUserDetailsStore.DeleteLearningPath(lp)
              );
              this.userDetailsStore.dispatch(
                new fromUserDetailsStore.DeleteLearningPath(lp)
              );
              this.addedContentDataService.deleteAddedLPContentDetailsAfterLPRemoval(
                lp,
                lp.PackageList
              );
              this.addedContentDataService.contentAddedDetailsLoaded.next(
                true
              );
              if (this.router.url.split("id/")[1] == lp.PathId) {
                //set initial route
                this.getLearningPaths(true);
              }
              else {
                this.getLearningPaths(false);

              }
            }
          });
        }
      });
    } else {
      this.canRemoveLP(lp).then((response: any) => {
        if (response.status) {
          // dialogConfig.height = '200px';
          // dialogConfig.width = '550px';
          dialogConfig.data.message1 =
            'There may be some course/package(s) in progress. Are you sure you want to delete ' +
            lp.PathName +
            '?';
          dialogConfig.data.message2 =
            'Your progress will be remembered by the system and will reflect if you chose to take this course again.';
          dialogConfig.data.showButtons = true;
        } else {
          dialogConfig.data.message1 =
            'This Learning Path has 201/301 course(s) in Progress/Approved/Completed state and hence cannot be deleted.';
          dialogConfig.data.message2 = null;
          dialogConfig.data.showButtons = false;
        }
        let deleteDialogBox = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        deleteDialogBox.afterClosed().subscribe(data => {
          if (data) {
            this.lpService.deleteLearningPath(lp).then(response => {
              if (response) {
                this.userDetailsStore.dispatch(
                  new fromUserDetailsStore.DeleteLearningPath(lp)
                );
                this.addedContentDataService.deleteAddedLPContentDetailsAfterLPRemoval(
                  lp,
                  lp.PackageList
                );
                this.addedContentDataService.contentAddedDetailsLoaded.next(
                  true
                );
                if (this.router.url.split("id/")[1].toString() == lp.PathId.toString()) {
                  //set initial route
                  this.getLearningPaths(true);
                }
                else {
                  this.getLearningPaths(false);
                }
              }
            });
          }
        });
      });
    }
  }
  canRemoveLP(lp) {
    let promise = new Promise((resolve, reject) => {
      let lpHasWorkflowStartedCourses = false;
      let coursesListWith201and301 = lp.PackageList.filter(content => {
        return (
          !content.IsDeleted &&
          ('201' == content.ItemExpertise || '301' == content.ItemExpertise) &&
          !(content.WorflowStatus == null || content.WorflowStatus == '') &&
          content.ItemType.toLowerCase() == 'course'
        );
      });
      lpHasWorkflowStartedCourses = this.validateCoursesForWorkflowStatus(
        coursesListWith201and301
      );
      if (lpHasWorkflowStartedCourses) {
        //delete not possible
        resolve({ status: false, reason: '' });
      } else {
        let PackagesInLP = lp.PackageList.filter(content => {
          return (
            !content.IsDeleted &&
            (content.ItemType.toLowerCase() == 'package' ||
              content.ItemType.toLowerCase() == 'familypackage')
          );
        });
        if (PackagesInLP.length == 0) {
          //delete possible
          resolve({ status: true, reason: '' });
        } else {
          let noOfPackagesValidated = 0;
          let packageContains201InProgress = false;
          this.LPPageSubscriptions.contentAddedDetailsForPackagesLoadedSub = this.addedContentDataService.contentAddedDetailsForPackagesLoaded.subscribe(
            isLoaded => {
              if (isLoaded) {
                PackagesInLP.forEach(content => {
                  // //console.log(
                  //   'packagecourses loaded lp comp',
                  //   content.ItemId
                  // );
                  // //console.log(
                  //   'packagescourses data lp comp',
                  //   this.addedContentDataService.packageCourseMappings[
                  //   content.ItemId
                  //   ]
                  // );

                  if (
                    this.addedContentDataService.packageCourseMappings[
                    content.ItemId
                    ]
                  ) {
                    let coursesList = this.addedContentDataService.packageCourseMappings[
                      content.ItemId
                    ];
                    packageContains201InProgress = this.validateCoursesForWorkflowStatus(
                      coursesList
                    );
                    noOfPackagesValidated++;
                    if (packageContains201InProgress) {
                      //delete not possible
                      resolve({ status: false, reason: '' });
                      //return true;
                    } else {
                      if (noOfPackagesValidated == PackagesInLP.length) {
                        //delete possible
                        resolve({ status: true });
                        //return true;
                      }
                    }
                  } else {

                  }
                });
              }
            });
        }
      }
    });
    return promise;
  }
  validateCoursesForWorkflowStatus(courses) {
    let hasWorkflowStartedCourses = courses.some(content => {
      if (
        ('201' == content.ItemExpertise || '301' == content.ItemExpertise) &&
        !(content.WorflowStatus == null || content.WorflowStatus == '') &&
        content.ItemType.toLowerCase() == 'course'
      ) {
        content.WorflowStatus = content.WorflowStatus.toLowerCase();
        if (
          !(
            'request' == content.WorflowStatus ||
            'cancelled' == content.WorflowStatus ||
            'request cancelled' == content.WorflowStatus ||
            'rejected' == content.WorflowStatus ||
            'preapproved' == content.WorflowStatus ||
            'pre-approved' == content.WorflowStatus ||
            'send request' == content.WorflowStatus ||
            'not started' == content.WorflowStatus
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    return hasWorkflowStartedCourses;
  }
  resumeLearningPath() {
    if (this.resumeDetails && this.resumeDetails != null) {
      //get path category
      if (
        this.resumeDetails.ItemId == '' ||
        this.resumeDetails.ItemId == 'null'
      ) {
        this.resumeDetails.ItemId = null;
      }
      this.lpService.updateResumeStatus(true);
      let category = 'self';
      let lpId = this.resumeDetails.PathId;
      this.LPPageSubscriptions.getLearningPathsSelectorSub = this.userDetailsStore
        .select(fromUserDetailsStore.getLearningPathsSelector)
        .subscribe(lps => {
          let lp = this.arrayFilterPipe.transform(lps, {
            property: 'PathId',
            flag: this.resumeDetails.PathId
          })[0];

          if (
            lp.PathName.toLowerCase() == this.globals.CocLpName.toLowerCase() || lp.PathName.toLowerCase() == this.globals.BEACLpName.toLowerCase() ||
            lp.PathName.toLowerCase() ==
            'sexual harassment prevention training - usa' ||
            lp.PathName.toUpperCase() == this.globals.newPOSHUSLpName
          ) {
            category = 'myCompliance';
            lpId = lp.PathName;
          } else if (lp.IsMandatory && lp.IsSkillPlan) {
            category = 'skilling';
          } else if (lp.IsMandatory && !lp.IsSkillPlan) {
            category = 'mandatory';
            lpId = lp.PathName;
          } else {
            category = 'self';
          }
          ////console.log('lp', lp);
        });
      let packageId = null;
      if (this.resumeDetails.ItemType == 'FamilyPackage') {
        packageId = this.resumeDetails.ItemId;
      } else {
        packageId = null;
      }
      this.router.navigate(
        [
          'category/' +
          category +
          '/id/' +
          lpId +
          '/package/' +
          packageId +
          '/course/' +
          this.resumeDetails.CourseId +
          '/account/false/project/false/accountId/null/projectId/null/tabs/playlist'
        ],
        {
          relativeTo: this.activatedRoute
        }
      );
    }
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //courseFeedbackDialogContainer
    this._dialog.open(CourseFeedbackComponent, dialogConfig);
  }
  checkFeedback() {
    if (!this.globals.isCustomerAccess) {
      this._popUpSvc.isCourseFeedbackPending().then(res => {
        if (res === true) {
          this._popUpSvc.getPendingCourseFeedBackList().subscribe(
            data => {
              if (data !== null && data.length > 0) {
                let mandatoryList = this.arrayFilterPipe.transform(data, {
                  property: 'IsFeedbackMandatory',
                  flag: true
                });
                if (mandatoryList.length != 0) {
                  this.globals.courseFeedbackList = data;
                  this.openDialog();
                }
              }
            },
            error => {
              ////console.log('Error in getting Course feedback', error);
            }
          );
        } else {
          //do nothing
        }
      });
    }
  }
  openLPDialog(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      Msg: msg
    };
    this._dialog.open(CreateLpPopupComponent, dialogConfig);
  }
  getCloudLabs() {
    //  if(this.globals.packageCourseList.length > 0){
    this._cloudlabStoreSvc.fetchLabsInBatch(this.globals.packageCourseList);
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.LPPageSubscriptions) {
      let subscriber = this.LPPageSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
}
