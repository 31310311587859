import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoPopupComponent } from '../../../shared/accordion/video-popup/video-popup.component';
import { RiskBrigadeVideoPopupComponent } from '../../risk-brigade-video-popup/risk-brigade-video-popup.component';

@Component({
  selector: 'yorbit-speaker-tile',
  templateUrl: './speaker-tile.component.html',
  styleUrls: ['./speaker-tile.component.scss']
})
export class SpeakerTileComponent implements OnInit {

  @Input('align') align:string;
  @Input('speaker') speaker:any;
  @Output() popupOpened = new EventEmitter();
  
  constructor(
    private _popup: MatDialog) { }

  ngOnInit() {
  }

  openVideoPopup(){
    this.popupOpened.emit(true);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
   
      dialogConfig.data = {
        VideoId: this.speaker.VideoId,
        VideoLink: this.speaker.VideoLink,
        Title: this.speaker.Title,
        VideoType: this.speaker.VideoType
      };
     
    var dialogRef=this._popup.open(RiskBrigadeVideoPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.popupOpened.emit(false);
    });
  }

}
