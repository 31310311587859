<mat-dialog-content>
  
    <div class="popupHeading">{{heading}}
      <button class="closeButton mat-button mat-icon-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    
  <div *ngIf="data == 'createContent'">
    <div class="expertiseDiv">
     <div>
       <div class="expertiseTile tile101" *ngIf="(cuuserroles && (-1 != cuuserroles.indexOf('101')))" [ngClass]="{onClickEffect: expertise=='101'}" (click)="selectExpertise(101)">101</div>
       <div class="expertiseTile tile101 inactive" *ngIf="(cuuserroles && (-1 == cuuserroles.indexOf('101')))" [ngClass]="{onClickEffect: expertise=='101'}">101</div>
       <div class="expertiseTile tile201"  *ngIf="(cuuserroles && (-1 != cuuserroles.indexOf('201')))" [ngClass]="{onClickEffect: expertise=='201'}" (click)="selectExpertise(201)">201</div>
       <div class="expertiseTile tile201 inactive"  *ngIf="(cuuserroles && (-1 == cuuserroles.indexOf('201')))" [ngClass]="{onClickEffect: expertise=='201'}">201</div>
       <div class="expertiseTile tile301" *ngIf="(cuuserroles && (-1 != cuuserroles.indexOf('301')))" [ngClass]="{onClickEffect: expertise=='301'}" (click)="selectExpertise(301)">301</div>
       <div class="expertiseTile tile301 inactive" *ngIf="(cuuserroles && (-1 == cuuserroles.indexOf('301')))" [ngClass]="{onClickEffect: expertise=='301'}">301</div>
       
     </div>
     <div>
        <div class="expertiseTile packageTile" *ngIf="(cuuserroles && (-1 != cuuserroles.indexOf('package')))" [ngClass] = "{onClickEffect: expertise=='package'}" (click)="selectExpertise('package')">Package</div>
        <div class="expertiseTile packageTile inactive" *ngIf="(cuuserroles && (-1 == cuuserroles.indexOf('package')))"  [ngClass] = "{onClickEffect: expertise=='package'}">Package</div>
      </div>
     </div>
   </div>
    
  <div *ngIf="data == 'createQuiz'">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="expertiseTile tile101" *ngIf="(cuuserroles && (-1 != cuuserroles.indexOf('101')))" [ngClass]="{onClickEffect: courseExpertise=='101'}" (click)="courseExpertiseSelected(101)">
        101
      </div>
      <div class="expertiseTile tile201"  *ngIf="(cuuserroles && (-1 != cuuserroles.indexOf('201')))" [ngClass]="{onClickEffect: courseExpertise=='201'}" (click)="courseExpertiseSelected(201)">
        201
      </div>
    </div>


    <form class="searchForm" [formGroup]="searchForm">
    <div class="searchDiv">
        <input class="searchText" [ngStyle]="courseExpertise=='201'?{'border-color':'#22b1a8'} : {'border-color': '#318cf1'}" type="text" (change)="onInputChange()" placeholder="Search course"
       matInput [formControl]="courseDropDown" [matAutocomplete]="auto">
       <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.Name}}
        </mat-option>
      </mat-autocomplete>
          <mat-icon [ngStyle]="courseExpertise=='201'?{'color':'#22b1a8'} : {'color': '#318cf1'}" class="searchIcon" (click)="search()">search</mat-icon>
    </div>
  </form>
  </div>
  
  <div *ngIf="data == 'AddCategory'">
      <div>
        <input  [(ngModel)]="newCategory" class="categoryText"
         type="text"  (change)="onAddingNewCategory()"
        placeholder="Add new category"/>
      </div>
  </div>

  <div class="errorMessage" *ngIf="!isValid">*Please select a valid course from the drop down</div>
  <div class="errorMessage" *ngIf="!isExpertiseValid">*Please select an option to procced</div>

  <div class="errorMessage" *ngIf="!isValidCategory">*Please enter valid new category</div>
  <!--<div class="errorMessage" *ngIf="isAddCategoryDisabled">*Please select account or project</div>-->
  <div class="proceedDiv">
    <button (click)="proceed()" class="proceed-button">PROCEED</button>
  </div>

</mat-dialog-content>
