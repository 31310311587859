

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import { MatIconModule} from '@angular/material/icon';

import { PipesModule } from '@YorbitWorkspace/pipes';

import { PreloaderComponent } from './preloader/preloader.component';
import { FooterComponent } from './footer/footer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { FaqComponent } from './faq/faq.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RatingsComponent } from './ratings/ratings.component';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatDividerModule} from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { FeedbackComponent } from './feedback/feedback.component';
import { iSupportTooltipComponent } from './iSupportTooltip/isupport-tooltip.component';

export const reusableUiRoutes: Route[] = [
  {  path: 'info',
  children: [
    {path:'aboutus',component:AboutusComponent,data: { title: 'About us'}},
    {path:'faq/:category',component:FaqComponent,data: { title: 'FAQ' }},
    {path:'termsOfUse',component:TermsOfUseComponent,data: { title: 'Terms of use'}},
    {path:'feedback',component:FeedbackComponent,data: { title: 'Feedback' }},
    {path:'aboutus/external',component:AboutusComponent,data: { title: 'About us'}},
    {path:'faq/external/:category',component:FaqComponent,data: { title: 'FAQ'}},
    {path:'termsOfUse/external',component:TermsOfUseComponent,data: { title: 'Terms of use'}}
  ]}
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    RouterModule.forChild(reusableUiRoutes),
    PipesModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatExpansionModule,
    FormsModule
  ],
  declarations: [
    PreloaderComponent,
    FooterComponent,
    AboutusComponent,
    FaqComponent,
    TermsOfUseComponent,
    ProgressBarComponent,
    RatingsComponent,
    FeedbackComponent,
    iSupportTooltipComponent
  ],
  exports: [
    PreloaderComponent,
    FooterComponent,
    AboutusComponent,
    FaqComponent,
    TermsOfUseComponent,
    ProgressBarComponent,
    RatingsComponent,
    FeedbackComponent,
    iSupportTooltipComponent
  ]
})
export class ReusableUiModule {}
