import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpClient
} from '@angular/common/http';
import { Observable, switchMap, tap } from 'rxjs';
import { GraphDataService } from './graph-data.service';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';

@Injectable()
export class GraphApiInterceptor implements HttpInterceptor {
  baseUrl: string;
  constructor(
    private graphDataService: GraphDataService,
     private _envSvc: EnvironmentService,
     private _http: HttpClient) {
      this.baseUrl = this._envSvc.getEnvironment().apiUrl;
    }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
 
    if(request.url.includes('https://graph.microsoft.com/v1.0/users/')){

      

      var ltiMindtreeregex=/(([0-9]\s{0})+@ltimindtree.com)/
      const emindtreeRegEx = /(([Mm0-9]\s{0})+@ltimindtree.com)/
     var result=request.url.match(ltiMindtreeregex)
     if(result==null){
      return next.handle(request);
     }else{
      var psId=result[0].split('@')
      if(psId.length<8){
       ltiMindtreeregex=emindtreeRegEx
       result=request.url.match(emindtreeRegEx)
       psId=result[0].split('@')
      }

      var tmpPSId=request.url.split('users/')[1].split('/')[0]
      if(tmpPSId.includes('?')){
        tmpPSId=tmpPSId.split('?')[0]
      }

      if(tmpPSId.split('@')[0].includes('.')){
        return next.handle(request);
      }else{
        return this.graphDataService.getUserEmailWithPSId(psId[0]).pipe(
          switchMap(data => {
             //replace .M with 6
             if(data[0].includes('.M')){
                data=data[0].replace('.M','.6')
             }
             let newUrl=request.url.replace(ltiMindtreeregex,data)
   
               const dupReq = request.clone({ url: newUrl });
             return next.handle(dupReq);
              
          })
        );
      }
      
   
     }
    }else{
      return next.handle(request);
    }
    
  }
}
