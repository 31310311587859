import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'yorbit-risk-brigade-learn',
  templateUrl: './risk-brigade-learn.component.html',
  styleUrls: ['./risk-brigade-learn.component.scss']
})
export class RiskBrigadeLearnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
