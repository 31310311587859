import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../shared/user-details/store';
import { BehaviorSubject, Observable, take } from 'rxjs';
import {
  LPBreadcrumbsStateModel,
  ILPDetailsModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
  IUnitDetailsModel
} from './learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from './learning-path-breadcrumbs.interface';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { Globals } from '../globals';

@Injectable()
export class LearningPathService {
  _addLpUrl = 'User/Add/LearningPath/';
  config: Ienvironment;
  _breadcrumbs: BehaviorSubject<any>;
  _searchLPModel: BehaviorSubject<any>;
  _resumeLPStatus: BehaviorSubject<boolean>;
  filterPipe: any;
  courses: any;
  skillPathPackageList: Array<any>;
  complianceLPOrder: Array<any>;
  badgeStatus: BehaviorSubject<Array<any>>;
  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private globals: Globals
  ) {
    this.config = this._envSvc.getEnvironment();
    let breadcrumbs = { ...LPBreadcrumbsStateModel };
    this._breadcrumbs = new BehaviorSubject(breadcrumbs);
    this._searchLPModel = new BehaviorSubject('');
    this._resumeLPStatus = new BehaviorSubject(false);
    this.filterPipe = new ArrayPropertyFilterPipe();
    let scormUpdateModel = [];
    this.globals.scormCourseIds.forEach(course => {
      scormUpdateModel.push({ 'courseId': course, 'status': false });
    })
    this.badgeStatus = new BehaviorSubject(scormUpdateModel);
    this.courses = {};
    this.skillPathPackageList = [];
    this.complianceLPOrder = [{
      'PathName': this.globals.LpName == 'coc' ? this.globals.CocLpName : this.globals.BEACLpName,
      'Order': 1
    },
    {
      'PathName': 'Sexual Harassment Prevention Training - USA',
      'Order': 2
    },
    {
      'PathName': this.globals.newPOSHUSLpName,
      'Order': 3
    },
  ]
  }
  getScormBadge() {
    return this.badgeStatus.asObservable();
  }
  updateScormBadge(scormCourseId, scormCourseStatus) {
    let sub = this.getScormBadge().pipe(take(1)).subscribe(data=>{
      if(data.length != 0){
        let index = data.findIndex(x => x.courseId == scormCourseId);
        if(index != -1){
          data[index].status = scormCourseStatus;
          this.badgeStatus.next(data);
        }
      }
        sub.unsubscribe();     
    })
   
   // this.badgeStatus.next(data);
  }
  getResumeStatus() {
    return this._resumeLPStatus.asObservable();
  }
  updateResumeStatus(data) {
    this._resumeLPStatus.next(data);
  }
  getSearchLPModel() {
    return this._searchLPModel.asObservable();
  }
  updateSearchModel(data) {
    this._searchLPModel.next(data);
  }
  getBreadCrumbs() {
    return this._breadcrumbs.asObservable();
  }
  updateBreadcrumbs(
    lpEntity,
    packageEntity,
    courseEntity,
    unitEntity,
    videoEntity
  ) {
    //lp details
    let LPBreadcrumbs = { ...LPBreadcrumbsStateModel };
    if (lpEntity != null) {
      if (lpEntity.PathId) {
        LPBreadcrumbs.LPDetails.LPId = lpEntity.PathId;
        LPBreadcrumbs.LPDetails.IsLPMandatory = lpEntity.IsMandatory;
        if (
          lpEntity.PathName.toLowerCase() == this.globals.CocLpName.toLowerCase() || lpEntity.PathName.toLowerCase() == this.globals.BEACLpName.toLowerCase() ||
          lpEntity.PathName.toLowerCase() ==
          'sexual harassment prevention training - usa' ||
          lpEntity.PathName.toUpperCase() == this.globals.newPOSHUSLpName
        ) {
          LPBreadcrumbs.LPDetails.LPCategory = 'myCompliance';
          LPBreadcrumbs.LPDetails.LPId = lpEntity.PathName;
        } else if (lpEntity.IsSkillPlan) {
          LPBreadcrumbs.LPDetails.LPCategory = 'skilling';
        } else if (lpEntity && lpEntity.IsAssessment) {
          LPBreadcrumbs.LPDetails.LPCategory = 'Assessment';
          LPBreadcrumbs.LPDetails.LPId = lpEntity.PathName;
        } else if (lpEntity.IsMandatory && "Role Based Learning" == lpEntity.PathName) {
          LPBreadcrumbs.LPDetails.LPCategory = 'rolebased';
          LPBreadcrumbs.LPDetails.LPId = lpEntity.PathName;
        } else if (lpEntity.IsMandatory) {
          LPBreadcrumbs.LPDetails.LPCategory = 'mandatory';
          LPBreadcrumbs.LPDetails.LPId = lpEntity.PathName;
        } else if (lpEntity.IsTimeBound) {
          LPBreadcrumbs.LPDetails.LPCategory = 'Time_Bound_Learning';
          LPBreadcrumbs.LPDetails.LPId = lpEntity.PathName;
        } else {
          LPBreadcrumbs.LPDetails.LPCategory = 'self';
        }
        LPBreadcrumbs.LPDetails.LPName = lpEntity.PathName;
      } else {
        LPBreadcrumbs.LPDetails.LPId = lpEntity.LPId;
        LPBreadcrumbs.LPDetails.LPName = lpEntity.LPName;
        LPBreadcrumbs.LPDetails.LPCategory = lpEntity.LPCategory;
        LPBreadcrumbs.LPDetails.IsLPMandatory = lpEntity.IsLPMandatory;
        LPBreadcrumbs.LPDetails.SkillPlanId = lpEntity.SkillPlanId;
      }
      //lp name
    } else {
      LPBreadcrumbs.LPDetails = { ...ILPDetailsModel };
    }
    //package details
    if (packageEntity) {
      if (packageEntity.ItemExpertise != undefined) {
        LPBreadcrumbs.PackageDetails.Expertise = packageEntity.ItemExpertise;
      } else {
        LPBreadcrumbs.PackageDetails.Expertise = packageEntity.Expertise;
      }
      if (packageEntity.ItemName != undefined) {
        LPBreadcrumbs.PackageDetails.PackageName = packageEntity.ItemName;
      } else {
        LPBreadcrumbs.PackageDetails.PackageName = packageEntity.PackageName;
      }
      if (packageEntity.ItemId != undefined) {
        LPBreadcrumbs.PackageDetails.PackageId = packageEntity.ItemId;
      } else {
        LPBreadcrumbs.PackageDetails.PackageId = packageEntity.PackageId;
      }
      if (packageEntity.AccountPackage != undefined) {
        LPBreadcrumbs.PackageDetails.IsAccount = packageEntity.AccountPackage;
      } else {
        LPBreadcrumbs.PackageDetails.IsAccount = packageEntity.IsAccount;
      }
      if (packageEntity.ProjectPackage != undefined) {
        LPBreadcrumbs.PackageDetails.IsProject = packageEntity.ProjectPackage;
      } else {
        LPBreadcrumbs.PackageDetails.IsProject = packageEntity.IsProject;
      }
      if (
        LPBreadcrumbs.PackageDetails.IsProject == true ||
        LPBreadcrumbs.PackageDetails.IsAccount == true
      ) {
        if (packageEntity.UniqueId != undefined) {
          LPBreadcrumbs.PackageDetails.PackageId = packageEntity.UniqueId;
        }
      }
      LPBreadcrumbs.PackageDetails.AccountId = packageEntity.AccountId;
      LPBreadcrumbs.PackageDetails.ProjectId = packageEntity.ProjectId;
    } else {
      LPBreadcrumbs.PackageDetails = { ...IPackageDetailsModel };
    }
    if (courseEntity) {
      //Expertise
      if (courseEntity.ItemExpertise != undefined) {
        LPBreadcrumbs.CourseDetails.Expertise = courseEntity.ItemExpertise;
      } else {
        LPBreadcrumbs.CourseDetails.Expertise = courseEntity.Expertise;
      }
      //course name
      if (courseEntity.ItemName != undefined) {
        LPBreadcrumbs.CourseDetails.CourseName = courseEntity.ItemName;
      } else if (courseEntity.Name != undefined) {
        LPBreadcrumbs.CourseDetails.CourseName = courseEntity.Name;
      } else {
        LPBreadcrumbs.CourseDetails.CourseName = courseEntity.CourseName;
      }
      //Course Id
      if (courseEntity.ItemId != undefined) {
        LPBreadcrumbs.CourseDetails.CourseId = courseEntity.ItemId;
      } else if (courseEntity.Id != undefined) {
        LPBreadcrumbs.CourseDetails.CourseId = courseEntity.Id;
      } else {
        LPBreadcrumbs.CourseDetails.CourseId = courseEntity.CourseId;
      }
      //is account package
      if (courseEntity.AccountPackage != undefined) {
        LPBreadcrumbs.CourseDetails.IsAccount = courseEntity.AccountPackage;
      } else {
        LPBreadcrumbs.CourseDetails.IsAccount = courseEntity.IsAccount;
      }
      //is project package
      if (courseEntity.ProjectPackage != undefined) {
        LPBreadcrumbs.CourseDetails.IsProject = courseEntity.ProjectPackage;
      } else {
        LPBreadcrumbs.CourseDetails.IsProject = courseEntity.IsProject;
      }
      //is internal
      if (courseEntity.Internal != undefined) {
        LPBreadcrumbs.CourseDetails.IsInternal = courseEntity.Internal;
      } else {
        LPBreadcrumbs.CourseDetails.IsInternal = courseEntity.IsInternal;
      }
      //accountid
      if (courseEntity.AccountId != undefined) {
        LPBreadcrumbs.CourseDetails.AccountId = courseEntity.AccountId;
      } else if (courseEntity.Account != undefined) {
        LPBreadcrumbs.CourseDetails.AccountId = courseEntity.Account;
      }
      //accountid
      if (courseEntity.ProjectId != undefined) {
        LPBreadcrumbs.CourseDetails.ProjectId = courseEntity.ProjectId;
      } else if (courseEntity.Project != undefined) {
        LPBreadcrumbs.CourseDetails.ProjectId = courseEntity.Project;
      }
      if (
        LPBreadcrumbs.CourseDetails.IsProject == true ||
        LPBreadcrumbs.CourseDetails.IsAccount == true
      ) {
        if (courseEntity.UniqueId != undefined) {
          LPBreadcrumbs.CourseDetails.CourseId = courseEntity.UniqueId;
        }
      }
      if (courseEntity.Vendor != null && courseEntity.Vendor != undefined && courseEntity.Vendor != '') {
        LPBreadcrumbs.CourseDetails.Vendor = courseEntity.Vendor;
      }
      this.userDetailsStore
        .select(
          fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
            LPBreadcrumbs.CourseDetails.CourseId
          )
        ).subscribe(course => {
          //if(course.WorflowStatus != null && course.WorflowStatus != undefined && course.WorflowStatus != ''){
          LPBreadcrumbs.CourseDetails.Workflow = course.WorflowStatus;
          //}
        })
      LPBreadcrumbs.CourseDetails.VideoCount = courseEntity.VideoCount;
    } else {
      LPBreadcrumbs.CourseDetails = { ...ICourseDetailsModel };
    }
    if (unitEntity) {
      //Unit details
      LPBreadcrumbs.UnitDetails.UnitName = unitEntity.Name;
      if (unitEntity.AccountPackage || unitEntity.ProjectPackage) {
        LPBreadcrumbs.UnitDetails.UnitId = unitEntity.UniqueId;
      } else {
        LPBreadcrumbs.UnitDetails.UnitId = unitEntity.Id;
      }
      if (unitEntity.AccountPackage) {
        LPBreadcrumbs.UnitDetails.IsAccount = unitEntity.AccountPackage;
      } else {
        LPBreadcrumbs.UnitDetails.IsAccount = unitEntity.IsAccount;
      }
      if (unitEntity.ProjectPackage != undefined) {
        LPBreadcrumbs.UnitDetails.IsProject = unitEntity.ProjectPackage;
      } else {
        LPBreadcrumbs.UnitDetails.IsProject = unitEntity.IsProject;
      }
      if (unitEntity.Account != undefined) {
        LPBreadcrumbs.UnitDetails.AccountId = unitEntity.Account;
      } else {
        LPBreadcrumbs.UnitDetails.AccountId = unitEntity.AccountId;
      }
      if (unitEntity.Project != undefined) {
        LPBreadcrumbs.UnitDetails.ProjectId = unitEntity.Project;
      } else {
        LPBreadcrumbs.UnitDetails.ProjectId = unitEntity.ProjectId;
      }
      LPBreadcrumbs.UnitDetails.ContentCount = unitEntity.ContentCount;
    } else {
      LPBreadcrumbs.UnitDetails = { ...IUnitDetailsModel };
    }
    if (videoEntity) {
      //video details
      LPBreadcrumbs.VideoDetails.VideoType = videoEntity.SourceType;
      LPBreadcrumbs.VideoDetails.VideoId = videoEntity.Id;
      LPBreadcrumbs.VideoDetails.VideoName = videoEntity.Title;
      LPBreadcrumbs.UnitDetails.IsAccount = videoEntity.AccountPackage;
      LPBreadcrumbs.UnitDetails.IsProject = videoEntity.ProjectPackage;
      LPBreadcrumbs.UnitDetails.AccountId = videoEntity.Account;
      LPBreadcrumbs.UnitDetails.ProjectId = videoEntity.Project;
      LPBreadcrumbs.VideoDetails.IsCompliance = videoEntity.Compliance;
    } else {
      LPBreadcrumbs.VideoDetails = { ...IVideoDetailsModel };
    }
    //console.log(LPBreadcrumbs)
    this._breadcrumbs.next(LPBreadcrumbs);
  }

  quizAvailabilityCheck(data) {
    if (data.IsAccount) {
      return this.http
        .post(
          this.config.apiUrl +
          'AccountQuiz/CheckAndAdd/' +
          data.courseId +
          '/' +
          data.entityType +
          '/' +
          data.AccountId,
          {}
        )
        .toPromise();
    } else if (data.IsProject) {
      return this.http
        .post(
          this.config.apiUrl +
          'ProjectQuiz/CheckAndAdd/' +
          data.courseId +
          '/' +
          data.entityType +
          '/' +
          data.ProjectId,
          {}
        )
        .toPromise();
    } else {
      return this.http
        .post(
          this.config.apiUrl +
          'Quiz/CheckAndAdd/' +
          data.courseId +
          '/' +
          data.entityType,
          {}
        )
        .toPromise();
    }
  }
  checkLpExistAndAdd(_learningPathTitle, _learningPaths, _skillPaths) {
    const regExp = /([\s]{2,})/gi;
    const space = ' ';
    const lpWithSpacesRemoved = _learningPathTitle
      .trim()
      .replace(regExp, space);
    const lpTitle =
      undefined !== _learningPathTitle && null !== _learningPathTitle
        ? _learningPathTitle.trim()
        : '';
    if (
      '' !== lpTitle &&
      null != lpTitle &&
      !this.globals.restrictedLPNames.includes(
        lpWithSpacesRemoved.toUpperCase()
      )
    ) {
      const nonDeletedLPs = _learningPaths.filter(val => {
        return val.IsDeleted === false;
      });
      const nonDeletedSkillPaths = _skillPaths.filter(val => {
        return val.IsDeleted === false;
      });
      let pathExists = false;
      let skillPathExists = false;
      nonDeletedLPs.forEach(lp => {
        if (lp.PathName.toLowerCase() === lpTitle.toLowerCase()) {
          pathExists = true;
        }
      });
      nonDeletedSkillPaths.forEach(lp => {
        if (lp.PathName.toLowerCase() === lpTitle.toLowerCase()) {
          skillPathExists = true;
        }
      });
      if (pathExists || skillPathExists) {
        const promise = new Promise(() => {
          const response = {
            errorMessage:
              'Learning Path with name ' +
              lpTitle.toUpperCase() +
              ' already Exists.'
          };
          throw response;
        });
        return promise;
      } else {
        return this.addLearningPath(lpTitle)
          .then(result => {
            this.userDetailsStore.dispatch(
              new fromUserDetailsStore.UserDetailsAddLearningPath(result)
            );
            const promise = new Promise(resolve => {
              const response = result;
              resolve(response);
            });
            return promise;
          })
          .catch(error => {
            const promise = new Promise(() => {
              const response = {
                errorMessage:
                  'Sorry! An error has occurred. Retry after sometime. <br/>If the issue persists, please raise a ticket on <a href="' + this.globals.isupportUrl + '">' + this.globals.isupportUrl + '</a>'
              };
              throw response;
            });
            return promise;
          });
      }
    } else {
      const promise = new Promise(() => {
        let msg = 'Sorry! Title cannot be empty.';
        if (
          this.globals.restrictedLPNames.includes(
            lpWithSpacesRemoved.toUpperCase()
          )
        ) {
          msg =
            'Sorry! Learning Path with name ' +
            lpTitle.toUpperCase() +
            ' is not allowed.';
        }

        const response = {
          errorMessage: msg
        };
        throw response;
      });
      return promise;
    }
  }
  deleteLearningPath(lp) {
    return this.http
      .delete(this.config.apiUrl + 'User/Remove/LearningPath/' + lp.PathId)
      .toPromise();
  }
  addLearningPath(_LearningPathTitle) {
    const title = {
      Name: _LearningPathTitle
    };
    return this.http
      .post(this.config.apiUrl + this._addLpUrl, title)
      .toPromise();
  }

  getCoursesInsidePackage(id, account, project) {
    if (
      (null == account && null == project) ||
      (account == undefined && project == undefined)
    ) {
      //Yorbit
      return this.http
        .get(this.config.apiUrl + 'Courses/PackageIncludingHiddenCourses/' + id)
        .toPromise();
    } else {
      let Url,
        payload = {
          AccountOrProjectId: '',
          PackageId: id
        };
      if (null != project) {
        //project
        Url =
          this.config.apiUrl + 'Course/Project/NonYorbitPackageIncludingHidden';
        payload.AccountOrProjectId = project != null ? project.trim() : null;
      } else {
        //account
        Url =
          this.config.apiUrl + 'Course/Account/NonYorbitPackageIncludingHidden';
        payload.AccountOrProjectId = account != null ? account.trim() : null;
      }
      return this.http.post(Url, payload).toPromise();
    }
  }
  loadCoursesInsidePackageToStore(assignedBy, packageId, accountId, projectId) {
    let packageDetails = {};
    packageDetails[packageId] = [];
    return this.getCoursesInsidePackage(packageId, accountId, projectId).then(
      (courseIds: Array<String>) => {
        if (courseIds != null) {
          this.userDetailsStore
            .select(fromUserDetailsStore.getCourseProgressListSelector)
            .subscribe(courseProgressList => {
              courseIds.forEach(courseId => {
                let courseDetails = this.filterPipe.transform(
                  courseProgressList,
                  {
                    property: 'CourseId',
                    flag: courseId.toString()
                  }
                )[0];
                if (courseDetails != undefined) {
                  if (courseDetails.Duration == null) {
                    courseDetails.Duration = '00:00:00';
                  }

                  let course = {
                    ItemId: courseDetails.CourseId,
                    RequestId: courseDetails.RequestID,
                    ItemType: 'Course',
                    ItemName: courseDetails.CourseName,
                    ItemExpertise: courseDetails.Expertise,
                    ImageUrl: '',
                    VideoCount: courseDetails.ContentCount,
                    Badges: [],
                    BadgeIds: null,
                    PackageProgress: {
                      Progress: courseDetails.Progress,
                      ConsumedTime: courseDetails.ConsumedTime,
                      TimeLeft: courseDetails.TimeLeft,
                      TotalTime: courseDetails.Duration
                    },
                    ProgressList: [],
                    Declaration: false,
                    IsMandatory: true,
                    AssignedBy: assignedBy,
                    AssignorRole: null,
                    Department: null,
                    IsCancelable: false,
                    AccountPackage: courseDetails.IsAccount,
                    ProjectPackage: courseDetails.IsProject,
                    Category: null,
                    AccountId: courseDetails.AccountId,
                    ProjectId: courseDetails.ProjectId,
                    WorflowStatus: courseDetails.WorflowStatus,
                    SubStatus: courseDetails.SubStatus,
                    DueDate: '0001-01-01T00:00:00',
                    IsDeleted: false,
                    CreatedDate: '0001-01-01T00:00:00',
                    ModifiedDate: '0001-01-01T00:00:00',
                    DeletedDate: '0001-01-01T00:00:00',
                    CompletedDate: '0001-01-01T00:00:00',
                    IsInIDP: false,
                    IsBlocked: false
                  };
                  this.userDetailsStore
                    .select(
                      fromUserDetailsStore.getBadgesByCourseIdSelector(courseId)
                    )
                    .subscribe(badge => {
                      //////console.log("badges",badge);
                      if (
                        badge != undefined &&
                        badge != null &&
                        badge.BadgeId
                      ) {
                        course.Badges = [];
                        course.Badges.push(badge);
                      }
                    });
                  packageDetails[packageId].push(course);
                }
              });
            });
        }
        this.userDetailsStore.dispatch(
          new fromUserDetailsStore.UserDetailsUpdatePackageCoursesList(
            packageDetails
          )
        );
        return packageDetails[packageId];
      }
    );
  }
  getCourseDetails(id, type, isAccountRelated, payload) {
    if (this.courses[id] != undefined) {
      let promise = new Promise((resolve, reject) => {
        resolve(this.courses[id]);
      });
      return promise;
    } else {
      if (!isAccountRelated) {
        if (type == 'Course') {
          return this.http.get(this.config.apiUrl + 'Course/' + id).toPromise();
        } else {
          return this.http
            .get(this.config.apiUrl + 'Package/' + id + '/' + type)
            .toPromise();
        }
      } else {
        if (type == 'Course') {
          return this.http
            .post(this.config.apiUrl + 'Course', payload)
            .toPromise();
        } else {
          return this.http
            .post(this.config.apiUrl + 'Package', payload)
            .toPromise();
        }
      }
    }
  }
  storeCourseDetails(course) {
    if (course != null) {
      if (course.AccountPackage == 'true' || course.ProjectPackage == 'true') {
        //this.courses[course.UniqueId] = course;
      } else {
        //this.courses[course.Id] = course;
      }
    }
  }
  getRecommendationDetails(id) {
    return this.http
      .get<any>(this.config.apiUrl + 'recommendations/byCourse/' + id)
      .toPromise();
  }
  setSkillPathPackageList(list) {
    this.skillPathPackageList = list;
  }
  getSkillPathPackageList() {
    return this.skillPathPackageList;
  }
  getAttemptDetails(courseId, isYorbitQuiz) {
    return this.http.get<any>(
      this.config.apiUrl +
      'Quiz/GetAttemptDetails/' +
      courseId +
      '/' +
      isYorbitQuiz
    );
  }

  getUserCreatedLPs() {
    let result = null;
    this.userDetailsStore
      .select(fromUserDetailsStore.getUserCreatedLPsSelector)
      .subscribe(res => {
        if (res) {
          const _userDefinedPaths = res;
          const nonDeletedLPs = _userDefinedPaths.filter(val => {
            return val.IsDeleted === false;
          });
          result = nonDeletedLPs;
        }
        return result;
      });
    return result;
  }
  getSkillPaths() {
    let result = null;
    this.userDetailsStore
      .select(fromUserDetailsStore.getSkillPathsSelector)
      .subscribe(res => {
        if (res) {
          const _skillPaths = res;
          const nonDeletedSkillPaths = _skillPaths.filter(val => {
            return val.IsDeleted === false;
          });
          result = nonDeletedSkillPaths;
        }
        return result;
      });
    return result;
  }
  getSystemGeneratedLPs() {
    let result = null;
    this.userDetailsStore
      .select(fromUserDetailsStore.getSystemGeneratedMandatoryLPsSelector)
      .subscribe(res => {
        if (res) {
          const _systemDefinedPaths = res;
          const nonDeletedSystemDefinedPaths = _systemDefinedPaths.filter(
            val => {
              return val.IsDeleted === false;
            }
          );
          result = nonDeletedSystemDefinedPaths;
        }
        return result;
      });
    return result;
  }

  getRBLPackage() {
    return this.http.get<any>(
      this.config.apiUrl + "RoleBasedLearning/GetRoleBasedPackagesofUser");
  }

  updateDegreedUserDocdb() {
    return this.http.post<any>(this.config.apiUrl + "UpdateDocDbDegreedUserCourse", null);
  }

  checkClassroomCourse(courseId: string) {
    return this.http.get(this.config.apiUrl + "Course/CheckClassroomCourse/" + courseId);
  }

}
