import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PssContentApprovalComponent } from '../../pss/pss-content-approval/pss-content-approval.component';
import { SystemService } from '../services/system.service';

@Component({
  selector: 'yorbit-download-blob-popup',
  templateUrl: './download-blob-popup.component.html',
  styleUrls: ['./download-blob-popup.component.scss']
})
export class DownloadBlobPopupComponent implements OnInit {
  errorFlag: boolean;

  constructor(
    public dialogRef: MatDialogRef<PssContentApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _systemService: SystemService
  ) { }

  ngOnInit() {
    this.errorFlag=false;
    this.onFirstLoad();
  }
  onFirstLoad() {
    this._systemService
    .getBlobSasKey(this.data.url)
    .then(sasUrl => {
      var decodedUrl=this._systemService.getAtoBDecode(sasUrl.toString());
      window.open(decodedUrl, "_blank");
      this.closeUpdatePopup();
    })
    .catch(error => {
      this.errorFlag=true;
      //console.log("sastoken",error)
    });
  }

  closeUpdatePopup() {
    this.dialogRef.close();
  }

}
