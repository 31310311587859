<mat-dialog-content>
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closeDialog(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title">
    <div class="title">Publish Result</div>
  </div>
  <div class="confirmationDialog"  *ngIf = "data.length > 0" fxLayout="column">
    <div class="publish-info" fxFlex="45px" fxLayoutAlign="center center">
      Requests will be published for the below Request ID/IDs:
    </div>
    <mat-divider fxFlex="1px"></mat-divider>
    <div class="request-details" fxLayout="column" fxLayoutAlign="start stretch">
      <div class="header" fxLayoutAlign="start center" fxFlex="30px">
        <div fxFlex="80px" class="title">Req ID</div>
        <div fxFlex="100px" class="title">Score</div>
        <div fxFlex="100px" class="title">Result</div>
        <div fxFlex="200px" class="title">Edited by Learning OPM</div>
        <div fxFlex="200px" class="title">Fields Edited</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start stretch" class="requests-holder">
        <div
          class="request"
          *ngFor="let request of data"
          fxLayoutAlign="start center"
          fxFlex="30px"
        >
          <div fxFlex="80px" class="value">{{ request.RequestId }}</div>
          <div fxFlex="100px" class="value">{{ request.Score }}</div>
          <div fxFlex="100px" class="value">{{ request.Result }}</div>
          <div fxFlex="200px" class="value">
            {{ request.IsEditedByOPM ? 'YES' : 'NO' }}
          </div>
          <div fxFlex="200px" class="value">{{ request.FieldsEdited }}</div>
        </div>
      </div>
    </div>
    <mat-divider fxFlex="1px"></mat-divider>
    <div
      class="actionButtonContainer"
      fxFlex="50px"
      fxLayoutAlign="center center"
    >
      <button class="actionButton action-button" (click)="closeDialog(true)">
        Yes
      </button>
      <button class="actionButton action-button" (click)="closeDialog(false)">
        No
      </button>
    </div>
  </div>
  <div class="noRequestsMessage" *ngIf = "data.length == 0">
    Selected request is yet to be evaluated and hence, cannot be Published.
  </div>
</mat-dialog-content>
