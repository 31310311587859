import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
@Injectable()
export class PlaylistService {
  config: Ienvironment;
  selectedVideoDetails: any;
  videoDetailsToCaptureProgress: any;
  canPlayAllVideos: any;
  notClearReason:string;
  popUpHeading:string;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.selectedVideoDetails = <BehaviorSubject<any>>new BehaviorSubject({
      source: null,
      id: 0,
      playlistLength: 0,
      allUnitsLoaded: false
    });

    this.canPlayAllVideos = <BehaviorSubject<boolean>>new BehaviorSubject(true);
  }
  getContentInsideCourse(id) {
    return this.http.get(
      this.config.apiUrl + 'LearningPlan/Packages/Course/' + id
    );
  }

  getContentInsideUnit(id) {
    return this.http.get(
      this.config.apiUrl + 'LearningPlan/Packages/Unit/' + id
    );
  }

  getContentInsideCourse_PP(payload: Object) {
    return this.http.post(
      this.config.apiUrl + 'LearningPlan/Course/Units',
      payload
    );
  }
  getContentInsideUnit_PP(payload: Object) {
    return this.http.post(
      this.config.apiUrl + 'LearningPlan/Unit/Contents',
      payload
    );
  }
  updateSelectedVideoId(data) {
    this.selectedVideoDetails.next(data);
  }
  getSelectedVideoId() {
    return this.selectedVideoDetails.asObservable();
  }

  captureAccountContentProgress(payload) {
    return this.http
      .post(this.config.apiUrl + 'User/ProjectPackage/ItemProgress', payload)
      .toPromise();
  }
  getCanPlayAllVideosFlag() {
    return this.canPlayAllVideos.asObservable();
  }
  updateCanPlayAllVideosFlag(data) {
    this.canPlayAllVideos.next(data);
  }
  getCourseProcedure(courseId) {
    return this.http
      .get(this.config.apiUrl + 'User/CourseProcedureDownload/' + courseId)
      .toPromise();
  }
  getLearnerFlowStatus(id) {
    return this.http.get(
      this.config.apiUrl + 'Course/Request/CourseBirdsEyeView/' + id
    );
  }
  getLearnerFlowOrderDetails(Uid) {
    return this.http.get(
      this.config.apiUrl + 'LearningPlan/WorkflowOrder/' + Uid
    );
  }
  uploadCertificateToBlob(file, courseId): any {
    const fd = new FormData();
    fd.append('file', file, file.name);
    //const httpHeaders = new HttpHeaders().append('Content-Type', 'undefined');
    return this.http
      .post(
        this.config.apiUrl + 'AzureBlob/Upload/InternalCertificate/' + courseId,
        fd
      )
      .toPromise();
  }
  uploadCertificateToSql(certData): Observable<any> {
    return this.http
      .post(this.config.apiUrl + 'PSSUploadSql/InternalCertificate', certData)
      .pipe(
        tap((res: Response) => res),
        catchError((e: Response) => throwError(e))
      );
  }
  getProblemStatementForProjectOrAssignment(type, Id) {
    return this.http
      .get(
        this.config.apiUrl +
        'Course/ProjectOrAssignemntProblemStatementDownload/' +
        type +
        '/' +
        Id
      )
      .toPromise();
  }
  getScoreCardForProjectOrAssignment(mid, id, requestid) {
    return this.http
      .get(
        this.config.apiUrl +
        'Course/ProjectOrAssignmentScoreCardDetails/' +
        mid +
        '/' +
        id +
        '/' +
        requestid
      )
      .toPromise();
  }
  getErrorFileForProjectOrAssignment(mid, id, requestid) {
    return this.http
      .get(
        this.config.apiUrl +
        'Course/ProjectOrAssignmentErrorFile/' +
        mid +
        '/' +
        id +
        '/' +
        requestid
      )
      .toPromise();
  }
  uploadProject(file, courseuid, requestid) {
    const fd = new FormData();
    fd.append('file', file);
    //const httpHeaders = new HttpHeaders().append('Content-Type', 'undefined');
    return this.http
      .post(
        this.config.apiUrl +
        'Blob/UploadProject/' +
        courseuid +
        '/' +
        requestid,
        fd
      )
      .toPromise();
  }
  uploadAssignment(file, mid, requestid, sessionId) {
    const fd = new FormData();
    fd.append('file', file);
    //const httpHeaders = new HttpHeaders().append('Content-Type', 'undefined');
    return this.http
      .post(
        this.config.apiUrl +
        'Blob/UploadAssignment/' +
        mid +
        '/' +
        requestid +
        '/' +
        sessionId,
        fd
      )
      .toPromise();
  }
  updateUserDeletedVideoProgress(payload) {
    return this.http
      .post(this.config.apiUrl + 'User/Add/DeletedVideoProgress', payload)
      .toPromise();
  }



  getQuizDeatilsFor201(mid, courseId) {
    return this.http.get(
      this.config.apiUrl + 'GetQuizDetailsFor201/' + mid + '/' + courseId
    );
  }

  get201QuizId(courseid) {
    return this.http.get(
      this.config.apiUrl + 'GetQuiz/' + courseid
    );
  }

  cancelNotClearedCourse(RequestId, reasonForCancelling, CourseId) {
    //console.log('fasd', role);
    let obj = {
      ItemsIds: [RequestId],
      Comments: reasonForCancelling,
      courseId: [CourseId]
    };
    return this.http.post(
      this.config.apiUrl +
      'Course/CDM/Cancel', obj)
      .toPromise();
  }

  checkIfSMEEvaluated(Uid) {
    return this.http.get(
      this.config.apiUrl + 'LearningPlan/WorkflowOrder/isSMEEvaluated' + Uid
    );
  }

  getMKIoPlayerUrls(streamingURL){
    return this.http
    .get<any>(this.config.apiUrl+ 'MKIO/streamingLocators/listPaths?sourceUrl='+streamingURL)
    .pipe(catchError((error: any) =>throwError(()=> error)));
  }
  getCompletedClassRoomUser(userId, courseId) {
    return this.http.get(this.config.apiUrl + `User/GetClassRoomInstances/${userId}/${courseId}`);
  }

  getClassroomSessionDetails(userId, courseUId) {
    return this.http.get(this.config.apiUrl + `User/GetClassRoomSessionDetails/${userId}/${courseUId}`);
  }

   //CDP Approval
  cdpUploadToBlob(file, courseId): any {
    const fd = new FormData();
    fd.append('file', file, file.name);
    //const httpHeaders = new HttpHeaders().append('Content-Type', 'undefined');
    return this.http
      .post(
        this.config.apiUrl + 'AzureBlob/Upload/CDPApproval/' + courseId,
        fd
      )
      .toPromise();
  }

  resetQuiz(payload){
    return this.http
      .post<any>(this.config.apiUrl + 'User/details/resetQuiz/'+ payload.userId + '/' + payload.courseId, {})
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }
}

