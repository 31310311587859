<div fxLayout="column" class="course-requests-comp" fxLayoutGap="20px">
  <!--requests section-->
  <div *ngFor="let request of requests;let index = index" fxLayout="row wrap">
      <div *ngIf="request.showCourseName" class="course-name-header" fxFlex="100%">
          {{ request.CourseName }}
      </div>
    <div class="contentDetailedTable" fxFlex="100%">
      <div class="indexNoTile floatLeft"  fxFlex="20px">{{ contentIndex+index+1 }}</div>
      <div fxFlex
        class="requestDataTile floatLeft"
        fxLayout="column"
        fxLayoutAlgin="start stretch"
      >
        <div class="requestId font14">
          Request Id: <b>{{ request.RequestId }}</b>
        </div>
        <mat-divider></mat-divider>
        <div class="requestedUser">
          <div class="font12">{{ request.Mid }}</div>
          <div class="font14 fontBold">{{ request.Name }}</div>
        </div>
      </div>
      <div class="courseLinksTile floatLeft" fxFlex fxLayout="column" fxLayoutGap="10px">
        <div class="font14 fontBold">{{ request.CourseName }}</div>
        <div class="font12 courseLinkContainer">
          LINK:<a
            class="courseLink font12"
            href="{{ request.CourseLink }}"
            rel="noreferrer"
            target="_blank"
            >{{ request.CourseLink }}</a
          >
        </div>
      </div>
      <div class="courseDataTile floatLeft" fxFlex fxLayout="column" fxLayoutGap="5px">
        <div class="font14 fontBold">Course Data</div>
        
        <div fxLayout="row" fxLayoutAlign="start start">
            <div class="header" fxFlex>Academy</div>
            <span class="divider-colon">:</span>
            <div class="value" fxFlex title="{{ request.Academy }}" fxFlexOffset="5px">
              {{ request.Academy }}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
            <div class="header" fxFlex>Course Type</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.CourseType }}"  fxFlexOffset="5px">
              {{ request.CourseType }}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
            <div class="header" fxFlex>Vendor Name</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex title="{{ request.Vendor }}"  fxFlexOffset="5px">
              {{ request.Vendor }}
            </div>
        </div>
        <div fxLayout="row" *ngIf="request.Cost != '' && request.Cost!=null" fxLayoutAlign="start start">
            <div class="header" fxFlex>Cost</div>
            <div class="divider-colon" >:</div>
            <div class="value" fxFlex title="{{ request.Cost }}" fxFlexOffset="5px">
              {{ request.Cost }}
            </div>
        </div>
        <div fxLayout="row" *ngIf="request.Cost == null || request.Cost == ''" fxLayoutAlign="start start">
            <div class="header" fxFlex>Cost</div>
            <div class="divider-colon">:</div>
            <div class="value" fxFlex fxFlexOffset="5px">
             NA
            </div>
        </div>
      </div>
      
      <div class="dateDetailsTile floatLeft" fxFlex fxLayout="column" fxLayoutGap="5px">
        <div class="font14 fontBold">Date Of Request</div>
        <div
          class="font12"
          *ngIf="isDateValid(request.DateOfRequest)"
          [innerHTML]="formatDate(request.DateOfRequest, 'MM/dd/yyyy')"
        ></div>
        <div class="font12" *ngIf="!isDateValid(request.DateOfRequest)">NA</div>
        <div class="font14 fontBold" *ngIf="request.DateOfApproval.split(' ')[0].trim() != '01/01/0001' && request.DateOfApproval.split(' ')[0].trim() != '1/1/0001' && request.DateOfApproval.split(' ')[0].trim() != '01/01/2001' && request.DateOfApproval.split(' ')[0].trim() != '1/1/2001'">Date Of Approval</div>
        <div
          class="font12"
          *ngIf="isDateValid(request.DateOfApproval) && request.DateOfApproval.split(' ')[0].trim() != '01/01/0001' && request.DateOfApproval.split(' ')[0].trim() != '1/1/0001' && request.DateOfApproval.split(' ')[0].trim() != '01/01/2001' && request.DateOfApproval.split(' ')[0].trim() != '1/1/2001'"
          [innerHTML]="formatDate(request.DateOfApproval, 'MM/dd/yyyy')"
        ></div>
        <div class="font12" *ngIf="!isDateValid(request.DateOfApproval) && (request.DateOfApproval.split(' ')[0].trim() != '01/01/0001' && request.DateOfApproval.split(' ')[0].trim() != '1/1/0001' && request.DateOfApproval.split(' ')[0].trim() != '01/01/2001' && request.DateOfApproval.split(' ')[0].trim() != '1/1/2001')">NA</div>
      </div>
      <div class="actionedByTile floatLeft" fxFlex>
        <div
          *ngIf="'AUTO APPROVED' != request.ActionedBy"
          fxLayout="column"
          fxLayoutGap="5px"
        >
          <div class="font14 fontBold">Actioned By:</div>
          <div
            class="font14  textTruncate"
            [ngClass]="{fontBold:request.ActionedByName!=''}"
            title="{{ request.ActionedByName }}"
          >
            {{ request.ActionedByName }}
          </div>
          <div
            class="font12 textTruncate"
            title="{{ request.UserJobTitle | uppercase }},{{
              request.LearnerDepartment | uppercase
            }}"
            *ngIf="request.ActionedByName != 'NA'"
          >
            ({{ request.UserJobTitle | uppercase }},
            {{ request.LearnerDepartment | uppercase }})
          </div>
          <div class="acceptedRequest font12" *ngIf="request.IsAssigned">
            Assigned
          </div>
          <div class="font14 fontBold" *ngIf="request.CDMIntent != null  && request.CDMIntent.length!=0">
            Learning Business Partner Intent:
          </div>
          <div
            class="acceptedRequest font12"
            *ngIf="request.IsSpecialCaseOfAssignment"
          >
            Special Case
          </div>
          <div class="acceptedRequest font12" *ngIf="request.CDMIntent != null && request.CDMIntent.length!=0">
            ({{ request.CDMIntent }})
          </div>
        </div>
        <div *ngIf="'AUTO APPROVED' == request.ActionedBy">
          <div class="acceptedRequest font12">Auto Approved</div>
        </div>
      </div>
      <div class="contentStatus floatLeft" fxFlex fxLayout="column" fxLayoutGap="5px">
        <div
          class="font12 fontBold status"
          *ngIf="
            request.CertificationStatus != null &&
            request.CertificationStatus != ''
          "
          fxLayoutAlign="center center"
        >
          {{ request.CertificationStatus }}
        </div>
        <div
          class="font12 subStatus"
          *ngIf="request.SubStatus != null && request.SubStatus != ''"
        >
          {{ request.SubStatus }}
        </div>
        <div
          class="font12 fontBold status"
          *ngIf="
            request.CertificationStatus == null ||
            request.CertificationStatus == ''
          "
          fxLayoutAlign="center center"
        >
          {{ request.WorkflowStatus }}
        </div>
      </div>
    </div>
  </div>
  <!--preloader section-->
  <div *ngIf="showPreloader"  fxFlexFill fxLayoutAlign="center center">
<app-preloader></app-preloader>
  </div>
  <!--No data info after filters applied-->
  <div *ngIf="!showPreloader && requests.length==0 && filtersApplied" class="noRequestsInfo"  fxFlexFill fxLayoutAlign="center center">
    No requests available.
  </div>
 
</div>
  