import { Action } from '@ngrx/store';

export const BROWSE_GET_ACADEMY_LIST = '[Browse] Load Academy';
export const BROWSE_GET_ACADEMY_LIST_FAIL = '[Browse] Load Academy Fail';
export const BROWSE_GET_ACADEMY_LIST_SUCCESS = '[Browse] Load Academy Success';

export const BROWSE_SET_BROWSE_DETAILS = '[Browse] Set Browse Details';

export const BROWSE_GET_ACADEMY_CONTENT = '[Browse] Load Academy Content';
export const BROWSE_GET_ACADEMY_CONTENT_FAIL = '[Browse] Load Academy Content Fail';
export const BROWSE_GET_ACADEMY_CONTENT_SUCCESS = '[Browse] Load Academy Content Success';


export class BrowseGetAcademyList implements Action {
  readonly type = BROWSE_GET_ACADEMY_LIST;
}

export class BrowseGetAcademyListFail implements Action {
  readonly type = BROWSE_GET_ACADEMY_LIST_FAIL;
  constructor(public payload: any){}
}

export class BrowseGetAcademyListSuccess implements Action {
  readonly type = BROWSE_GET_ACADEMY_LIST_SUCCESS;
  constructor(public payload: any){}
}

export class BrowseGetAcademyContent implements Action {
  readonly type = BROWSE_GET_ACADEMY_CONTENT;
  constructor(public payload:any){}
}

export class BrowseGetAcademyContentFail implements Action {
  readonly type = BROWSE_GET_ACADEMY_CONTENT_FAIL;
  constructor(public payload: any){}
}

export class BrowseGetAcademyContentSuccess implements Action {
  readonly type = BROWSE_GET_ACADEMY_CONTENT_SUCCESS;
  constructor(public payload: any){}
}

export type BrowseAcademyActions =
|BrowseGetAcademyList
|BrowseGetAcademyListFail
|BrowseGetAcademyListSuccess
|BrowseGetAcademyContent
|BrowseGetAcademyContentFail
|BrowseGetAcademyContentSuccess;
