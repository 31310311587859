import { createSelector, createFeatureSelector} from '@ngrx/store';
import * as fromRolebasedReducer from '../reducers/rolebased-store.reducer';
import { IrolebasedState } from '../reducers';

export const getRolebasedSelector = createFeatureSelector<IrolebasedState>('rolebased');

export const getRolebasedListSelector =createSelector(
  getRolebasedSelector,
  (state:IrolebasedState)=>state.rolebased.data
);

export const getRolebasedStatusByEntities= createSelector(
  getRolebasedListSelector,
  (rolebasedStatusList)=>{
    let rolebasedEntities;
    if(rolebasedStatusList && 0!==rolebasedStatusList.length){
      rolebasedEntities=rolebasedStatusList.reduce(
        (entities,status:fromRolebasedReducer.IrolebasedData)=>{
          return{
            ...entities,
            [status.Id]:status
          }
        },{}
      )
    }else{
      rolebasedEntities={};
    }
    return rolebasedEntities;
  }
)

export const getRolebasedDetailsByPackageId= id=>createSelector(
  getRolebasedStatusByEntities,
  (rolebasedStatus)=> {
    return rolebasedStatus[id]
  }
)
