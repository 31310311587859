import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class TimeBoundLearningService {
  config: Ienvironment;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
   }

  getTimeBoundCourses(userId){
    return this.http.post(this.config.apiUrl + 'User/GetTimeBoundCourses',{"Id":userId});
  }

  assignTimeBoundCourses(courseData){
    return this.http.post<any>(
      this.config.apiUrl + 'User/Assign/TimeBoundCourses',
      courseData
    );
  }

}
