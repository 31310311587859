<div class="banner" (click)="openCourse()">
    <div class="strip">
        <div class="title-strip" fxHide.sm="true" fxHide.xs="true">
            <img fxFlex="100%" src="./../../assets/images/behavioral branding banner/title_strip_large.jpg">
        </div>
        <div class="title-strip-mobile" fxHide.gt-sm="true" [ngClass.sm]="['sm']">
            <img fxFlex="100%" src="./../../assets/images/behavioral branding banner/title_strip_large_mobile_version.jpg">
        </div>
    </div>
    <div class="body" fxLayout.gt-sm="row" fxLayout.xs="column" fxLayout.sm="column">
        <div class="title" [ngClass.sm]="['sm']" [ngClass.xs]="['xs']" [ngClass.gt-sm]="['gt-sm']">
            <img id="image" src="./../../assets/images/behavioral branding banner/title_on_strip.png" width="230px">
        </div>
        <div class="information" [ngClass.sm]="['sm']" [ngClass.xs]="['xs']" [ngClass.gt-sm]="['gt-sm']">
            <p class="heading">An exclusive initiative for LTIMindtree Associates by LTIMindtree Associates</p>
            <p class="paragraph">A holistic program to enable LTIMindtree Associates to live mindfully with self- awareness,
                resilience, focus and strength and achieve excellence – professionally & personally.</p>
        </div>
    </div>
</div>