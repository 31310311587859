<div class="course-properties-tab">
  <mat-tab-group>
    <mat-tab
      *ngFor="let filter of courseProperties"
      fxLayout="row"
      fxLayoutAlign="start start"
    >
      <ng-template mat-tab-label>
        <div fxFlex fxLayout="row">
          <!--icon to show when the filter options are selected-->
          <div fxFlex="30px" *ngIf="selectedCourseFilters[filter].length!=0" class="yorbitSprite resetSmallSpriteIcon resetIconPosition" (click)="removeSelectedFiltersFor(filter)"></div>
          <!--filter name-->
          <div fxFlex class="filter-name approval-status" (click)="onTabSelection(filter,'Approval Status')" *ngIf="filter=='ApprovalStatus'">Approval Status</div>
          <div fxFlex class="filter-name" (click)="onTabSelection(filter,filter)" *ngIf="filter!='ApprovalStatus'&& filter!='CertificationStatus' && filter!='Vendor' && filter!='CertificationSubStatus' && filter !='CourseType' && filter !='CourseName'&& filter !='LearningOPMSPOC'&& filter !='Genre' && filter !='Skill'">{{ filter }}</div>
          <div fxFlex class="filter-name" (click)="onTabSelection(filter,'Course Type')" *ngIf="filter=='CourseType'">Course Type</div>
          <div fxFlex class="filter-name" (click)="onTabSelection(filter,'Course Name')" *ngIf="filter=='CourseName'">Course Name</div>
          <div fxFlex class="filter-name" (click)="onTabSelection(filter,'Genre')" *ngIf="filter=='Genre'">Genre</div>
          <div fxFlex class="filter-name" (click)="onTabSelection(filter,'Skill')" *ngIf="filter=='Skill'">Skill</div>
          <div fxFlex class="filter-name" (click)="onTabSelection(filter,'Course Vendor')" *ngIf="filter=='Vendor'">Course Vendor</div>
          <div fxFlex class="filter-name" (click)="onTabSelection(filter,'Learning OPMSPOC')" *ngIf="filter=='LearningOPMSPOC'">Learning OPM SPOC</div>
          <div fxFlex class="filter-name certification-status" (click)="onTabSelection(filter,'Certification Status')" *ngIf="filter=='CertificationStatus'">Course Status</div>
          <div fxFlex class="filter-name certification-substatus" (click)="onTabSelection(filter,'Certification SubStatus')" *ngIf="filter=='CertificationSubStatus'">Course Sub Status</div>
          <!--indication for active tab-->
          <div fxFlex="24px" class="right-arrow">
          <mat-icon>keyboard_arrow_right</mat-icon>
          </div>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="filter-section" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
          <div class="search-options" fxFlex="40px" fxLayoutAlign="end center">
            <input matInput #searchByCourseFiltres autocomplete="off" fxFlex="200px" [(ngModel)]="searchOptions" placeholder="Search by {{placeholderSearchText}}"/>
            <button
            class="cross-icon"
          mat-button
          *ngIf="searchOptions.length != 0"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchOptions=''"
        >
          <mat-icon>close</mat-icon>
        </button>   
          </div>
          <mat-divider fxFlex="1px"></mat-divider>
          <!--filter options with check box to select and unselect each options-->
          <div #scrollingBlock class="options-container overflow-theme-dark" fxFlex fxLayout="column wrap" fxLayoutAlign="start start">
              <virtual-scroller #scroll [items]="courseFilters[filter]|searchArray:searchOptions" [parentScroll]="scrollingBlock">
                <div *ngFor="let value of scroll.viewPortItems">
                  <div class="option">
                    <input fxFlex="20px"
                    type="checkbox"
                    [ngModel]="selectedCourseFilters[filter].indexOf(value)!=-1"
                    (ngModelChange)="onSelection(filter,value,$event)"
                    />
                    <div class="value" fxFlex="270px" fxLayoutAlign="start start" fxLayout="row wrap">{{ value }}</div>
                  </div>
                </div>
                <div *ngIf="scroll.viewPortItems?scroll.viewPortItems.length==0:false" fxFlexFill fxLayoutAlign="center center">
                  Not Available
                </div>
              </virtual-scroller>  
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

