import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleBasedLearningComponent } from './role-based-learning.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RblCoursePopupComponent } from './rbl-course-popup/rbl-course-popup.component';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@YorbitWorkspace/pipes';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

@NgModule({
  declarations: [RoleBasedLearningComponent, RblCoursePopupComponent],
  imports: [
      CommonModule, FlexLayoutModule, FormsModule, MatDialogModule, MatIconModule,
    ContentTilesModule,
    PipesModule,
    ReusableUiModule
  ],
  entryComponents: [RblCoursePopupComponent]
})
export class RoleBasedLearningModule {}
