import * as fromRoleReducer from './role.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRoleReducerState } from './role.reducer';
export {IRoleReducerState} from './role.reducer';

export { IroleData } from './role.reducer';

export const roleAccessState= createFeatureSelector<IRoleReducerState>('role')

export const getRoleAccessList =createSelector(
  roleAccessState,
  fromRoleReducer.getRoleAccessList
)

export const getRoleAccessListLoaded =createSelector(
  roleAccessState,
  fromRoleReducer.getRoleAccessListLoaded
)

export const getRoleAccessListLoading =createSelector(
  roleAccessState,
  fromRoleReducer.getRoleAccessListLoading
)
