import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScormPlayerComponent } from '../scorm-player.component';
import * as fromUserDetailsStore from '../../../../shared/user-details/store'
import { Store } from '@ngrx/store';

@Component({
  selector: 'yorbit-scorm-completion-popup',
  templateUrl: './scorm-completion-popup.component.html',
  styleUrls: ['./scorm-completion-popup.component.scss']
})
export class ScormCompletionPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ScormPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    ) { }

  ngOnInit(): void {
  }
  close() {
    if(this.data.courseCompletion){
      this.userDetailsStore.dispatch(new fromUserDetailsStore.UserDetailsGetDetails());
    }
    this.dialogRef.close();
  }
}
