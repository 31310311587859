<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="external-playlist"
  *ngIf="!showPreloader && contentLoadedSuccessfully && course.Expertise!='101'"
>
  <!-- <mat-divider fxFlex="1px"></mat-divider> -->
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <div fxFlex="70px" fxLayoutAlign="stretch center" class="course-status">
      <div fxLayout="row" fxFlex>
        <!-- status -->
        <div fxLayout="column" fxFlex fxLayoutAlign="center start">
          <div>Course Status:</div>
          <div class="status" fxFlexOffset="2px" fxLayoutAlign="center center">
            <!-- <div>{{ workFlowPhaseDetails.Status == null ? courseStatus : workFlowPhaseDetails.Status }}</div> -->
             <div>{{courseStatus}}</div>
          </div>
        </div>
        <!-- course procedure download -->
        <div
        *ngIf="
        (course.Expertise == '201' ||  course.Expertise == '301' )
      "
          class="course-procedure-download"
          fxFlex
          fxLayoutAlign="end center"
        >
          <div *ngIf="isCourseProcedureIconEnabled"  class="label">Download Course Procedure here:</div>
          <div *ngIf="isCourseProcedureIconEnabled"
            class="yorbitSprite icon"
            [ngClass]="{ disableMouse: isCourseProcedureAvailable == false }"
            title="{{
              !isCourseProcedureAvailable
                ? 'This course does not have a course procedure'
                : 'Click here to download the course procedure'
            }}"
            (click)="downloadCourseProcedure()"
          ></div>
        </div>
      </div>
    </div>
    <div fxLayoutAlign="end center">
      <div class="downloadstatustext" fxFlexOffset="-20px">{{ downloadStatus }}</div>
    </div>
  </div>
  <!-- sub-status -->
  <div class="workflowphasedetailstext"
    *ngIf="
    workFlowPhaseDetails.SubStatus!=undefined &&
    (
      workFlowPhaseDetails.SubStatus !== null &&
      workFlowPhaseDetails.SubStatus !== ''
    )
    "
  >
    <div>Sub-Status:</div>
    <div class="sub-status" *ngIf="(workFlowPhaseDetails.PhaseDetails && workFlowPhaseDetails.PhaseDetails.Phase !=null) && workFlowPhaseDetails.SubStatus!=undefined && workFlowPhaseDetails.SubStatus.toLowerCase() =='learningopm team to verify and publish result'">
      Result awaited for <span class="phasedetailstext">{{workFlowPhaseDetails.PhaseDetails.Phase}}</span>
    </div>
    <div class="sub-status" *ngIf="workFlowPhaseDetails.SubStatus!=undefined && workFlowPhaseDetails.SubStatus.toLowerCase() !='learningopm team to verify and publish result'">
      {{(workFlowPhaseDetails.SubStatus.toLowerCase() == "learner to upload the course completion certificate on yorbit" ? "Learner to upload the course completion certificate on Shoshin School" : workFlowPhaseDetails.SubStatus)}}
    </div>
  </div>
  <mat-divider  
    fxFlex="1px"
  ></mat-divider>
  <!-- learner's workflow starts here -->
  <app-preloader *ngIf="!workFlowLoaded && course.Expertise == '201' && !isLWFRestricted"></app-preloader>
 <yorbit-learners-workflow [course]="course" [packageId]="packageId" *ngIf="workFlowLoaded" [workFlowPhaseDetails]="workFlowPhaseDetails" (updateWFStatus)="updateWFStatus($event)" ></yorbit-learners-workflow>
  <!-- learner's workflow ends here -->
</div>

 <div
    *ngIf="
    course.Expertise=='101'
    "
    fxLayout="column"
  fxLayoutAlign="start stretch"
  >
  <!-- course Material download -->
  <div class="materialDiv mousePointer" fxFlex="60px" fxLayoutGap="5px"  *ngIf="courseMatAvailable">
  <div
  *ngIf="courseMatAvailable && courseMaterialType == 'Internal'"
    class="course-procedure-download"
    fxFlex
    fxLayoutAlign="end center">
    <div class="label">Download Course Material:</div>
    <div class="yorbitSprite downloadMaterialIcon"
      title="Click to download course material."
      (click)="downloadCourseMaterial()">
    </div>
  </div>

  <div
  *ngIf="courseMatAvailable && courseMaterialType == 'External'"
    class="course-procedure-download"
    fxFlex
    fxLayoutAlign="end center">
    <div class="label">Access Course Material:</div>
    <div class="yorbitSprite accessMaterialIcon"
      title="Click to access course material."
      (click)="downloadCourseMaterial()">
    </div>
  </div>
  </div>

  <mat-divider fxFlex="1px"></mat-divider>
<div fxFlexOffset="50px" [ngStyle]="{'padding':'0 30px'}" fxLayoutAlign="center center">

  This course will take place outside SHOSHIN SCHOOL
</div>
  </div>
<div *ngIf="showPreloader">
  <app-preloader></app-preloader>
</div>
<div *ngIf="!contentLoadedSuccessfully && !showPreloader">
  Technical issue in loading content. Please try again
</div>
