<div fxLayout="row" fxLayoutAlign="start stretch" class="request-row">
  <div class="request-data req-id-col" fxLayout="column">
    <div class="req-id" fxLayout="row">
      <div fxFlex="40" class="bold-label">Request ID</div>
      <div fxFlex="10" class="bold-label">:</div>
      <div fxFlex="50">{{ requestData.Id }}</div>
    </div>
    <div class="user-details" fxLayout="row">
      <div class="user-img" fxFlex="40">
        <img [src]="imageUrl" />
      </div>
      <div class="user-name" fxLayout="column" fxFlex="60">
        <div class="MID">{{ requestData.CreatedBy }}</div>
        <div class="name bold-label value">{{ graphUserName }}</div>
      </div>
    </div>
  </div>
  <div
    class="request-data course-details-col"
    fxLayout="column">
    <div class="academy" fxLayout="row">
      <div fxFlex="40" class="bold-label">Academy</div>
      <div fxFlex="10" class="bold-label">:</div>
      <div fxFlex="50" class="value">{{ requestData.Academy }}</div>
    </div>
    <div class="genre" fxLayout="row">
      <div fxFlex="40" class="bold-label">Genre</div>
      <div fxFlex="10" class="bold-label">:</div>
      <div fxFlex="50" class="value" *ngIf="!nestedTabs.Accepted">
        {{ requestData.Genre }}
      </div>
      <div fxFlex="50" class="value" *ngIf="nestedTabs.Accepted">
        <select [(ngModel)]="updatedGenre" (change)="genreUpdate()">
          <option
            *ngFor="
              let genre of genreFilter(
                requestData.Academy,
                requestData.Genre,
                requestData.Id
              )
            "
            [value]="genre"
          >
            {{ genre }}
          </option>
        </select>
      </div>
    </div>
    <div class="skill" fxLayout="row">
      <div fxFlex="40" class="bold-label">Skill</div>
      <div fxFlex="10" class="bold-label">:</div>
      <div fxFlex="50" class="value" *ngIf="!nestedTabs.Accepted">
        {{ requestData.Skill == 'Not Applicable' ? '-NA-' : requestData.Skill }}
      </div>
      <div fxFlex="50" class="value" *ngIf="nestedTabs.Accepted">
        <select [(ngModel)]="updatedSkill">
          <option
            value=""
            *ngIf="
              updatedSkill !== 'Not Applicable' &&
              (updatedSkill === '' ||
                updatedSkill === undefined ||
                updatedSkill == null)
            "
            [disabled]="true"
          >
            Choose a Skill
          </option>

          <option
            [value]="requestData.Skill"
            *ngIf="skillFilter(updatedGenre).length == 0"
            [disabled]="requestData.Genre != updatedGenre"
          >
            {{ requestData.Skill }}
          </option>
          <option
            *ngFor="let skill of skillFilter(updatedGenre)"
            value="{{ skill }}"
          >
            {{ skill }}
          </option>
          <option
          value="Not Applicable"
          *ngIf="updatedSkill == 'Not Applicable'"
          >
          <!--[disabled]="true"-->
          Skill not Chosen
        </option>
        </select>
      </div>
    </div>
    <div class="date" fxLayout="row">
      <div fxFlex="40" class="bold-label">Date of Request</div>
      <div fxFlex="10" class="bold-label">:</div>
      <div fxFlex="50" class="value">
        {{ requestData.CreatedOn | date: 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>
  <div class="request-data learner-comments-col" fxLayout="column">
    <div class="bold-label" *ngIf="pendingTab">Reason Description</div>
    <div class="bold-label" *ngIf="!pendingTab">Content partner's Comments</div>
    <div class="description value" *ngIf="pendingTab">
      {{ requestData.Description }}
    </div>
    <div
      class="description value"
      *ngIf="!pendingTab"
      [ngClass]="{
        labelGreyOut:
          requestData.AOComments == 'Not Applicable' ||
          requestData.AOComments == null
      }"
    >
      {{
        requestData.AOComments == 'Not Applicable' ||
        requestData.AOComments == null
          ? '-NA-'
          : requestData.AOComments
      }}
    </div>
  </div>

  <div
    class="request-data reassign-req-col"
    fxLayout="column"
    *ngIf="pendingTab"
  >
    <div class="bold-label">Reassign Request</div>
    <div>
      <select
        [(ngModel)]="reassignAcademy"
        [disabled]="
          checkIfActioned(requestStatus) || requestStatus === 'Processing...' || reassignStatus === 're-assigned'
        "
        [ngClass]="{
          'mouseDisabled':
            checkIfActioned(requestStatus) || requestStatus == 'Processing...' || reassignStatus === 're-assigned'
        }"
        title="{{
          checkIfActioned(requestStatus)
            ? 'This request is already actioned'
            : ''
        }}"
      >
        <option value="" selected disabled>Choose an Academy</option>
        <option
          *ngFor="let academy of taxonomy"
          [disabled]="
            (academy | lowercase) == (requestData.Academy | lowercase)
          "
          title="{{
            (academy | lowercase) == (requestData.Academy | lowercase)
              ? 'You cannot reassign to the same Academy'
              : ''
          }}"
          >{{ academy }}</option
        >
      </select>
    </div>
    <div
      class="reassign-btn"
      *ngIf="reassignStatus == undefined || reassignStatus == 'error'"
      (click)="openPopup(reassignAcademy, requestData, requestStatus, 'reassign')
      "
      title="{{
        reassignAcademy == '' || reassignAcademy == undefined
          ? 'Please choose an Academy'
          : checkIfActioned(requestStatus)
          ? 'This request is already actioned'
          : ''
      }}"
    >
      <button
        class="action-button"
        [ngClass]="{
          'mouseDisabled':
            reassignAcademy == '' ||
            reassignAcademy == undefined ||
            requestStatus == 'accepted' ||
            requestStatus == 'denied' ||
            requestStatus == 'on hold' ||
            requestStatus == 'Processing...',
          'btnGreyOut':
            reassignAcademy == '' ||
            reassignAcademy == undefined ||
            requestStatus == 'accepted' ||
            requestStatus == 'denied' ||
            requestStatus == 'on hold'
        }"
        [disabled]="
          reassignAcademy == '' ||
          reassignAcademy == undefined ||
          requestStatus == 'accepted' ||
          requestStatus == 'denied' ||
          requestStatus == 'on hold' ||
          requestStatus == 'Processing...'
        "
      >
        Re-assign
      </button>
    </div>
    <div
      class="reassign-btn mousePointer mouseDisabled btnGreyOut"
      *ngIf="reassignStatus != undefined && reassignStatus != 'error'"
    >
      <button class="action-button mouseDisabled" [disabled]="true">
        {{ reassignStatus }}
      </button>
    </div>
    <div>
      <div class="errortext" *ngIf="reassignStatus == 'error'">
        Something went wrong. <br />Please try later.
      </div>
    </div>
  </div>
  <div
    class="request-data action-col"
    fxLayout="column"
    *ngIf="pendingTab"
    fxLayoutAlign="center center"
  >
    <div class="comments">
      <textarea
        class="value"
        placeholder="Write comments"
        [(ngModel)]="AOComments"
        [ngClass]="{'mouseDisabled':checkIfActioned(requestStatus),
        'labelGreyOut':checkIfActioned(requestStatus) ||
        requestStatus === 'Processing...' || reassignStatus === 'Processing...' ||
        (requestStatus|lowercase) === 're-assigned'}"
        [disabled]="
          checkIfActioned(requestStatus) ||
          requestStatus == 'Processing...' ||
          reassignStatus === 'Processing...' ||
          (reassignStatus | lowercase) == 're-assigned'
        "
      >
      </textarea>
    </div>
    <div
      class="action-btns"
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="undefined == requestStatus || 'error' === requestStatus"
    >
      <!--Accept-->
      <button
        class="action-button"
        (click)="
          openPopup(requestData.Id, AOComments, reassignStatus, 'accept')
        "
        [ngClass]="{
          'mouseDisabled':
            reassignStatus === 're-assigned' ||
            reassignStatus === 'Processing...'
        }"
        title="{{
          reassignStatus === 're-assigned'
            ? 'This request is already Re-Assigned'
            : ''
        }}"
      >
        Accept
      </button>
      <!--Deny-->
      <button
        class="action-button"
        (click)="
          openPopup(requestData.Id, AOComments, reassignStatus, 'deny')
        "
        [ngClass]="{
          'mouseDisabled':
            AOComments === undefined ||
            AOComments === '' ||
            (reassignStatus === 're-assigned' ||
              reassignStatus === 'Processing...')
        }"
        title="{{
          reassignStatus === 're-assigned'
            ? 'This request is already Re-Assigned'
            : isCommentBoxEmpty(AOComments)
            ? 'Please fill comments'
            : ''
        }}"
      >
        Deny
      </button>
      <!--On Hold-->
      <button
        class="action-button"
        (click)="openPopup(requestData.Id, AOComments, reassignStatus, 'hold')
        "
        [ngClass]="{
          'mouseDisabled':
            AOComments == undefined ||
            AOComments == '' ||
            (reassignStatus === 're-assigned' ||
              reassignStatus === 'Processing...')
        }"
        title="{{
          reassignStatus === 're-assigned'
            ? 'This request is already Re-Assigned'
            : isCommentBoxEmpty(AOComments)
            ? 'Please fill comments'
            : ''
        }}"
      >
        OnHold
      </button>
    </div>
    <!--Processing Status-->
    <div
      class="action-btns statusBox"
      *ngIf="undefined != requestStatus && 'error' != requestStatus"
    >
      <button class="action-button mouseDisabled" [disabled]="true">
        {{ requestStatus }}
      </button>
    </div>
  </div>
  <div
    class="request-data action-col"
    fxLayout="column"
    *ngIf="!pendingTab && nestedTabs.Accepted"
    fxLayoutAlign="center center"
  >
    <textarea
      class="newRequestComments"
      placeholder="Write comments"
      [(ngModel)]="completeReqComment"
      [disabled]="
        (!completeReqInProgress && isReqComplete) ||
        (completeReqInProgress && !isReqComplete)
      "
      [ngClass]="{
        'mouseDisabled': !completeReqInProgress && isReqComplete,
        'labelGreyOut':
          (!completeReqInProgress && isReqComplete) ||
          (completeReqInProgress && !isReqComplete)
      }"
    ></textarea>
    <div
      class="action-btns"
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="!completeReqInProgress && !isReqComplete"
    >
      <button
        class="action-button"
        [ngClass]="{
          'btnGreyOut':
            completeReqComment == '' || completeReqComment == undefined,
            'mouseDisabled':completeReqComment == '' || completeReqComment == undefined
        }"
        (click)="completeNewCourseRequest(requestData, completeReqComment)"
        [disabled]="(completeReqComment == '' || completeReqComment == undefined)"
        title="{{
          completeReqComment == '' || completeReqComment == undefined
            ? 'Please fill comments'
            : ''
        }}"
      >
        Complete
      </button>
    </div>
    <div
      class="action-btns"
      *ngIf="!completeReqInProgress && !isReqComplete && completeReqErr"
    >
      Something went wrong.<br />
      Please try again.
    </div>
    <div class="action-btns" *ngIf="completeReqInProgress && !isReqComplete">
      <button class="action-button mouseDisabled btnGreyOut" [disabled]="true">
        Processing...
      </button>
    </div>
    <div class="action-btns" *ngIf="!completeReqInProgress && isReqComplete">
      <button class="action-button mouseDisabled btnGreyOut" [disabled]="true">
        Completed
      </button>
    </div>
  </div>

  <div
    class="request-data action-col"
    fxLayout="column"
    *ngIf="!pendingTab && nestedTabs.OnHold"
    fxLayoutAlign="center center"
  >
    <textarea
      class="newRequestComments"
      placeholder="Write comments"
      [(ngModel)]="onHoldComment"
      [disabled]="isAcceptedOrDenied() || requestStatus == 'Processing...'"
      [ngClass]="{
        'mouseDisabled': isAcceptedOrDenied(),
        'labelGreyOut': isAcceptedOrDenied() || requestStatus == 'Processing...'
      }"
    ></textarea>
    <div
      class="action-btns"
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="
        undefined === requestStatus ||
        'error' === requestStatus ||
        'on hold' === requestStatus
      "
    >
      <button
        class="action-button"
        (click)="acceptNewCourseRequest(requestData.Id, onHoldComment, 'NA')"
      >
        Accept
      </button>
      <button
        class="action-button"
        [ngClass]="{
          'mouseDisabled': onHoldComment == '' || onHoldComment == undefined,
          'btnGreyOut':isCommentBoxEmpty(onHoldComment)
        }"
        title="{{
          isCommentBoxEmpty(onHoldComment) ? 'Please fill comments' : ''
        }}"
        (click)="denyNewCourseRequest(requestData.Id, onHoldComment, 'NA')"
      >
        Deny
      </button>
    </div>

    <div
      class="action-btns"
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="
        undefined != requestStatus &&
        'error' != requestStatus &&
        'on hold' != requestStatus
      "
    >
     <button [disabled]="true" class="action-button mouseDisabled">
        {{ requestStatus }}
     </button>
    </div>
  </div>
</div>
