import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PssEvaluationService } from '../pss-evaluation.service';
import { CheckboxList, YorbitCourseRequestList, CheckboxDisableList } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-pss-course-vendor',
  templateUrl: './pss-course-vendor.component.html',
  styleUrls: ['./pss-course-vendor.component.scss']
})
export class PssCourseVendorComponent implements OnInit {
  searchText: string;
  dataList: YorbitCourseRequestList[] = [];
  courseVendorList: CheckboxList[] = [];
  checkedCourseVendorList: string[] = [];
  changedItem: YorbitCourseRequestList[] = [];
  filteredDataList: YorbitCourseRequestList[] = [];
  checkedCourseList: string[] = [];
  checkedAcademyList: string[] = [];
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  displayList: CheckboxDisableList[] = [];
  uniqueVendorList: string[] = [];
  changedVendorList: CheckboxDisableList[] = [];
  @Output() isCourseVendorFiltered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _PssEvaluationService: PssEvaluationService) { }

  ngOnInit() {
    this.courseVendorList = [];
    this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
    this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
    this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
    this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
    this.dataList = this._PssEvaluationService.dataList;
    //this.courseVendorList = this._PssEvaluationService.selectedCourseVendorList;
    if(this.courseVendorList.length == 0) {
      this.GetCourseVendorList(); 
    }
  }

  GetCourseVendorList() {
    this.filteredDataList = this._PssEvaluationService.filterRecords('CourseVendor');
    ////console.log('.....', this.filteredDataList);

    //For Displaying all unique Sub status list
    for(let i=0; i<this.dataList.length;i++) {
      if(this.uniqueVendorList.indexOf(this.dataList[i].CourseVendor) == -1) {
        this.uniqueVendorList.push(this.dataList[i].CourseVendor);
      }
    }

    this.uniqueVendorList = this.uniqueVendorList.filter(x => x != null && x.trim() != '');
    //Checking if atleast any one of the filter is applied
    //If no filter is applied, Display all the unique vendor list without disabling any option
    //and selecting any option
    if(this.checkedCourseList.length == 0 
      && this.checkedAcademyList.length == 0
      && this.checkedCourseTypeList.length == 0
      && this.checkedLearningOPMSPOCList.length == 0
      && this.checkedSubStatusList.length == 0) {
      this.uniqueVendorList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: true
        }
      }).forEach(item=>this.displayList.push(item));
    }
    //If any filter is applied, disable all the options and deselect all the options
    else {
      this.uniqueVendorList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
    }
    ////console.log('Filterdata', this.filteredDataList);
    ////console.log('fasond', this.displayList);
    this.enableFilteredSubStatusList();
  }

  //For enabling the Sub status list for which is only present in the FilteredDataList
  enableFilteredSubStatusList() {
    ////console.log('enablefilter', this.filteredDataList);
    for(let i=0; i<this.filteredDataList.length; i++) {
      this.changedVendorList = [];
      this.changedVendorList = this.displayList.filter(item => item.value === this.filteredDataList[i].CourseVendor);
      this.changedVendorList[0].isEnabled = true;
      this.changedVendorList[0].isSelected = false;
    }
    ////console.log('filterdatafadsfads', this.displayList);
    this.preserveSelected();
  }

  //For selecting the Sub status list which was previously selected
  preserveSelected() {
    for(let i=0; i<this.checkedCourseVendorList.length; i++) {
      this.changedVendorList = [];
      this.changedVendorList = this.displayList.filter(item => item.value === this.checkedCourseVendorList[i]);
      this.changedVendorList[0].isEnabled = true;
      this.changedVendorList[0].isSelected = true;
      ////console.log('changeditem', this.changedVendorList);
    }
    ////console.log('DisplayVenodr', this.displayList);
  }

  updateCheckedOptions(option, event) {
    if(this.checkedCourseVendorList.indexOf(option) == -1)
      this.checkedCourseVendorList.push(option);
    else
      this.checkedCourseVendorList.splice(this.checkedCourseVendorList.indexOf(option),1);
    this._PssEvaluationService.checkedCourseVendorList = this.checkedCourseVendorList;
    this.isCourseVendorFiltered.emit(this.checkedCourseVendorList.length>0);
  }
}
