import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ICoreSkills,
  ISkill,
  ICrossSkillList,
  ICrossSkillPath,
  IFutureSkillList,
  IFutureSkillPaths,
  IFutureSkillPath
} from '../store/my-page-details.interface';
import { Observable, from } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromMyPageStore from '../store';
import * as fromUserDetailsStore from '../../shared/user-details/store';
import { MyPageService } from '../my-page.service';
import { AddedContentDataService } from '../../shared/services/added-content-data.service';
import { MyPagePopupComponent } from '../my-page-popup/my-page-popup.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CloudlabStoreService } from '../../shared/cloudlab-store/cloudlab-store.service';

@Component({
  selector: 'yorbit-skilling-recommendations',
  templateUrl: './skilling-recommendations.component.html',
  styleUrls: ['./skilling-recommendations.component.scss']
})
export class SkillingRecommendationsComponent implements OnInit {
  @Input('MId') MId: string;
  @Input('role') role: string;
  coreSkills: ICoreSkills;
  supportSkills: Array<ISkill>;
  crossSkillNames: Array<string>;
  crossSkillPath: Array<ICrossSkillList>;
  futureSkillNames: Array<string>;
  futureSkillPath: any;
  showMoreColumn_Core: Array<boolean>;
  showMoreColumn_Support: Array<boolean>;
  showMoreColumn_Cross: Array<boolean>;
  showMoreColumn_Future: Array<boolean>;
  showMoreColumn_FutureNested: Array<any>;
  crossSkills_buffer: Array<any>;
  pl4: string;

  addingCoreSkillInProgress: boolean;
  addingSupportSkillInProgress: boolean;
  addingCrossSkillInProgress: boolean;
  addingFutureSkillInProgress: boolean;

  addingCoreSkill: Array<boolean>;
  addingSupportSkill: Array<boolean>;
  addingCrossSkill: Array<boolean>;
  addingFutureSkill: Array<boolean>;

  columnIndex: object;
  dummyArray: any;
  coreSkillLoading: boolean;
  supportSkillLoading: boolean;
  crossSkillLoading: boolean;
  futureSkillLoading: boolean;
  lastVisitedIndex: number;
  skillAddedFlag: Array<boolean> = [];
  noSkillsFound: Array<boolean> = [];
  crossSkillPathLoading: Array<boolean>;

  courseListInSkillPath: any;

  constructor(
    private route: ActivatedRoute,
    private _myPageSvc: MyPageService,
    private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    private _dialog: MatDialog,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private cloudlabStoreService: CloudlabStoreService,
    private addedContentDataService: AddedContentDataService
  ) {}

  ngOnInit() {
    this.supportSkills = [];
    this.crossSkillNames = [];
    this.crossSkillPath = [];
    this.futureSkillNames = [];
    this.futureSkillPath = [];

    this.coreSkillLoading = true;
    this.supportSkillLoading = true;
    this.crossSkillLoading = true;
    this.futureSkillLoading = true;

    this.lastVisitedIndex = -1;
    this.courseListInSkillPath = [];

    if (this.role === 'self') {
      this.myPageStore
        .select(fromMyPageStore.getMyPagePrimarySkill)
        .subscribe(data => {
          this.pl4 = data.PrimaryL4;
        });
      this.getCoreSkills();
      this.getSupportSkills();
      this.getCrossSkills();
      this.getFutureSkills();
    } else {
      this.getPL4OfLearner();
    }

    this.showMoreColumn_Core = [];

    this.showMoreColumn_Support = [];

    this.showMoreColumn_Cross = [];

    this.dummyArray = [];
  }

  getCoreSkills() {
    this.myPageStore
      .select(fromMyPageStore.getMyPageCoreSkillsLoading)
      .subscribe(isLoading => {
        if (!isLoading) {
          this.myPageStore
            .select(fromMyPageStore.getMyPageCoreSkills)
            .subscribe(data => {
              this.coreSkillLoading = false;
              this.coreSkills = data;
              this.showMoreColumn_Core = new Array(
                this.coreSkills.CoreSkillCourses.length
              ).fill(false);
            });
        }
      });
  }

  getSupportSkills() {
    this.myPageStore
      .select(fromMyPageStore.getMyPageSupportSkillsLoading)
      .subscribe(isLoading => {
        if (!isLoading) {
          this.myPageStore
            .select(fromMyPageStore.getMyPageSupportSkills)
            .subscribe(data => {
              this.supportSkillLoading = false;
              this.supportSkills = data;
              this.showMoreColumn_Support = new Array(
                this.supportSkills.length
              ).fill(false);
            });
        }
      });
  }

  getCrossSkills() {
    this.myPageStore
      .select(fromMyPageStore.getMyPageCrossSkillsLoading)
      .subscribe(isLoading => {
        if (!isLoading) {
          this.myPageStore
            .select(fromMyPageStore.getMyPageCrossSkills)
            .subscribe(data => {
              if (data instanceof Array) {
                this.crossSkillLoading = false;
                this.crossSkillPath = data;
                this.showMoreColumn_Cross = new Array(
                  this.crossSkillPath.length
                ).fill(false);
                this.addingCrossSkill = new Array(
                  this.crossSkillPath.length
                ).fill(false);
              }
            });
        }
      });
  }

  getFutureSkills() {
    this.futureSkillLoading = true;
    this.futureSkillPath = [];
    this.skillAddedFlag = [];
    this.noSkillsFound = [];
    const that = this;

    this.myPageStore
      .select(fromMyPageStore.getMyPageFutureSkillsLoading)
      .subscribe(isLoading => {
        if (!isLoading) {
          that.myPageStore
            .select(fromMyPageStore.getMyPageFutureSkills)
            .subscribe(data => {
              this.futureSkillLoading = false;
              that.futureSkillPath = data.futureSkills;
              that.showMoreColumn_Future = new Array(
                that.futureSkillPath.length
              );
              //that.showMoreColumn_Future.fill(false);
              that.settingsForFutureSkillColumn();
            });
        }
      });
  }

  collapseOtherRowsInCol(rowIndex, column) {
    column.forEach((value, index) => {
      column[index] = false;
    });
    column[rowIndex] = true;
  }

  collapseAll(column) {
    column.forEach((value, index) => {
      column[index] = false;
    });
  }

  collapseOtherFutureSkill(parentIndex) {
    this.showMoreColumn_Future.forEach((value, index) => {
      this.showMoreColumn_Future[index] = false;
    });
    this.showMoreColumn_Future[parentIndex] = true;

    if (this.lastVisitedIndex === -1) {
      this.lastVisitedIndex = parentIndex;
    }
    this.collapseAll(this.showMoreColumn_FutureNested[this.lastVisitedIndex]);
  }

  getPL4OfLearner() {
    this._myPageSvc.getUserPrimarySkill(this.MId, 'learner').subscribe(
      data => {
        if (data.PrimaryL4 !== '') {
          this.pl4 = data.PrimaryL4;
          this.getRecommendationsForLearner(data.PrimaryL4);
        }
      },
      error => {}
    );
  }

  getRecommendationsForLearner(pl4) {
    this.crossSkillLoading = true;
    this.futureSkillLoading = true;
    this.coreSkillLoading = true;
    this.supportSkillLoading = true;

    //Core Skills
    this._myPageSvc.getCoreSkills(pl4).subscribe(
      skills => {
        this.coreSkills = skills;
        this.coreSkillLoading = false;
        this.showMoreColumn_Core = new Array(
          this.coreSkills.CoreSkillCourses.length
        ).fill(false);
      },
      error => {}
    );
    //Support Skills
    this._myPageSvc.getSupportSkills(pl4).subscribe(
      skills => {
        this.supportSkills = skills;
        this.supportSkillLoading = false;
        this.showMoreColumn_Support = new Array(this.supportSkills.length).fill(
          false
        );
      },
      error => {}
    );
    //Cross Skills
    this._myPageSvc.getCrossSkillsNames(pl4).subscribe(
      skills => {
        this.crossSkillNames = skills;
        this.crossSkillLoading = false;
        this.showMoreColumn_Cross = new Array(this.crossSkillPath.length).fill(
          false
        );
        this.addingCrossSkill = new Array(this.crossSkillPath.length).fill(
          false
        );

        if (this.crossSkillNames.length > 0) {
          this.crossSkillPathLoading = new Array(
            this.crossSkillNames.length
          ).fill(true);

          for (let i = 0; i < this.crossSkillNames.length; i++) {
            this.crossSkillPathLoading[i] = true;
            this.crossSkillPath[i] = {
              CrossSkillName: this.crossSkillNames[i],
              CrossSkillPath: null
            };
            this._myPageSvc
              .getCrossSkillsList(pl4, this.crossSkillNames[i])
              .subscribe(
                data => {
                  this.crossSkillPathLoading[i] = false;
                  this.crossSkillPath[i] = {
                    CrossSkillName: this.crossSkillNames[i],
                    CrossSkillPath: data
                  };
                },
                error => {}
              );
          }
        }
      },
      error => {}
    );

    // Future Skills
    this._myPageSvc.getFutureSkills(pl4).subscribe(skills => {
      this.futureSkillLoading = false;
      this.futureSkillPath = [];
      this.futureSkillNames = skills;
      this.showMoreColumn_Future = new Array(this.futureSkillNames.length);
      this.showMoreColumn_Future.fill(false);
      this.futureSkillNames.forEach((value, key) => {
        this.futureSkillPath[key] = {
          FutureSkillName: value,
          FutureSkillPaths: [],
          IsLoading: true,
          IsAdded: false
        };
        this._myPageSvc.getFutureSkillPath(value, pl4).subscribe(
          data => {
            this.futureSkillPath[key].IsLoading = false;
            if (data.length > 0) {
              let count = 0;
              for (let i = 0; i < data.length; i++) {
                if (
                  data[i].IsTargetSkillAlreadyAdded === false &&
                  data[i].TargetSkill.CourseId !== null &&
                  data[i].TargetSkill.CourseId !== ''
                ) {
                  this.futureSkillPath[key].FutureSkillPaths.push(data[i]);
                } else if (data[i].IsTargetSkillAlreadyAdded === true) {
                  count++;
                }
              }
              if (count === data.length) {
                this.futureSkillPath[key].IsAdded = true;
              } else {
                this.futureSkillPath[key].IsAdded = false;
              }
            }
            this.settingsForFutureSkillColumn();
          },
          error => {}
        );
      });
    });
  }

  settingsForFutureSkillColumn() {
    const that = this;
    this.addingFutureSkill = [];
    that.showMoreColumn_FutureNested = new Array(that.futureSkillPath.length);
    that.skillAddedFlag = new Array(that.futureSkillPath.length);
    that.futureSkillPath.forEach((value, parentIndex) => {
      that.showMoreColumn_FutureNested[parentIndex] = new Array(
        that.futureSkillPath[parentIndex].FutureSkillPaths.length
      );
    });
  }

  constructInputForAddCourse(skill, type) {
    let card = {
      contentData: {},
      addedLocation: '',
      addType: 'button',
      skillProfile: {}
    };
    (card.contentData = {
      Name: skill.CourseName,
      Id: skill.CourseId,
      ItemType: 'Course',
      UniqueId: null,
      Account: null,
      Project: null,
      AccountPackage: false,
      ProjectPackage: false
    }),
      (card.addedLocation = 'SkillingRecommendations'),
      (card.skillProfile = {
        recommendationType: type,
        duration: skill.Duration
      });

    return card;
  }

  addCrossSkillPathToLP(skill, index, type) {
    const payload = this.constructPayloadToAddSkillPath(skill.CrossSkillPath);
    this.addingCrossSkillInProgress = true;
    this.addingCrossSkill[index] = true;
    this._myPageSvc.addCrossSkill(payload, type).subscribe(
      data => {
        this.cloudlabStoreService.fetchLabsInBatch(this.courseListInSkillPath);
        this.addingCrossSkillInProgress = false;
        this.addingCrossSkill[index] = false;
        if (data.Status.toLowerCase() === 'success') {
          let crossSkillIndex = -1;
          for (let i = 0; i < this.crossSkillPath.length; i++) {
            if (
              this.crossSkillPath[i].CrossSkillPath.TargetSkill.CourseId ===
              skill.CrossSkillPath.TargetSkill.CourseId
            ) {
              crossSkillIndex = i;
              break;
            }
          }
          if (crossSkillIndex !== -1) {
            this.crossSkillPath.splice(crossSkillIndex, 1);
            if (this.role === 'self') {
              this.myPageStore.dispatch(
                new fromMyPageStore.GetMyPageCrossSkillPathSuccess(
                  this.crossSkillPath
                )
              );
            }
          }
          this.showMoreColumn_Cross[index] = true;

          this.userDetailsStore.dispatch(
            new fromUserDetailsStore.UserDetailsGetDetails()
          );

          let getUserDetailLoadedSub = this.userDetailsStore
            .select(fromUserDetailsStore.getUserDetailLoaded)
            .subscribe(data => {
              if (data) {
                this.updateAddedContentDetailsAfterSkillPathAddition(
                  payload,
                  type
                );
                this.openDialog('CrossSkill', payload.TargetSkill.SkillName);
                if (getUserDetailLoadedSub) {
                  getUserDetailLoadedSub.unsubscribe();
                }
              }
            });
        }
      },
      error => {
        this.addingCrossSkillInProgress = false;
        this.addingCrossSkill[index] = false;
      }
    );
  }

  addFutureSkillPathToLP(skill, parentIndex, childIndex, type) {
    const payload = this.constructPayloadToAddSkillPath(skill);
    this.addingFutureSkillInProgress = true;
    this.addingFutureSkill[childIndex] = true;
    this._myPageSvc.addCrossSkill(payload, type).subscribe(
      data => {
        this.cloudlabStoreService.fetchLabsInBatch(this.courseListInSkillPath);
        this.addingFutureSkillInProgress = false;
        this.addingFutureSkill[parentIndex] = false;
        if (data.Status.toLowerCase() === 'success') {
          let futureSkillIndex = -1;
          for (
            let i = 0;
            i < this.futureSkillPath[parentIndex].FutureSkillPaths.length;
            i++
          ) {
            if (
              this.futureSkillPath[parentIndex].FutureSkillPaths[i].TargetSkill
                .CourseId === skill.TargetSkill.CourseId &&
              this.futureSkillPath[parentIndex].FutureSkillPaths[i].TargetSkill
                .SkillName === skill.TargetSkill.SkillName
            ) {
              futureSkillIndex = i;
              break;
            }
          }
          if (futureSkillIndex !== -1) {
            this.futureSkillPath[parentIndex].FutureSkillPaths.splice(
              futureSkillIndex,
              1
            );

            if (
              this.futureSkillPath[parentIndex].FutureSkillPaths.length === 0
            ) {
              this.futureSkillPath.splice(parentIndex, 1);
            }

            if (this.role === 'self') {
              this.myPageStore.dispatch(
                new fromMyPageStore.GetMyPageFutureSkillPathSuccess({
                  futureSkills: this.futureSkillPath
                })
              );
            }
            this.showMoreColumn_Future[parentIndex] = true;

            this.userDetailsStore.dispatch(
              new fromUserDetailsStore.UserDetailsGetDetails()
            );

            let getUserDetailLoadedSub = this.userDetailsStore
              .select(fromUserDetailsStore.getUserDetailLoaded)
              .subscribe(data => {
                if (data) {
                  this.updateAddedContentDetailsAfterSkillPathAddition(
                    payload,
                    type
                  );
                  this.openDialog('FutureSkill', payload.TargetSkill.SkillName);
                  if (getUserDetailLoadedSub) {
                    getUserDetailLoadedSub.unsubscribe();
                  }
                }
              });
          }
        }
      },
      error => {
        this.addingFutureSkillInProgress = false;
        this.addingFutureSkill[parentIndex] = false;
      }
    );
  }
  updateAddedContentDetailsAfterSkillPathAddition(payload, type) {
    let userDetailsSub = this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailObject)
      .subscribe(userDetails => {
        if (userDetails) {
          let learningPath = userDetails.LearningPaths.filter(
            x =>
              !x.IsDeleted &&
              x.IsMandatory &&
              x.IsSkillPlan &&
              x.PathName == payload.TargetSkill.SkillName
          )[0];
          let courses = [];
          if (payload.TargetSkill) {
            courses.push({
              Id: payload.TargetSkill.CourseId,
              ItemType: 'Course',
              Expertise: payload.TargetSkill.Expertise,
              CourseName: payload.TargetSkill.CourseName
            });
          }
          if (payload.TransitionSkills) {
            payload.TransitionSkills.forEach(element => {
              courses.push({
                Id: element.CourseId,
                ItemType: 'Course',
                Expertise: element.Expertise,
                CourseName: element.CourseName
              });
            });
          }
          courses.forEach(content => {
            let addedContentDetails = {
              PathId: learningPath.PathId,
              PathName: learningPath.PathName,
              IsMandatory: true,
              IsCrossSkill: type == 'CrossSkill',
              IsFutureSkill: type == 'FutureSkill',
              IsSkillPlan: true,
              Id: content.Id,
              PackageId: '',
              Expertise: content.Expertise,
              Name: content.CourseName,
              AssignedBy: '',
              Type: content.ItemType,
              IsAccount: false,
              IsProject: false
            };
            let addedContentType = 'YorbitCourses';
            this.addedContentDataService.addDataToAddedLPContentDetails(
              addedContentType,
              addedContentDetails
            );
          });
        }
      });
    userDetailsSub.unsubscribe();
  }
  constructPayloadToAddSkillPath(skill) {
    this.courseListInSkillPath = [];
    let payload = {
      TotalDuration: skill.TotalDuration,
      TargetSkill: {
        SkillName: skill.TargetSkill.SkillName,
        CourseId: skill.TargetSkill.CourseId,
        CourseName: skill.TargetSkill.CourseName,
        Expertise: skill.TargetSkill.Expertise
      },
      TransitionSkills: []
    };
    for (let i = 0; i < skill.TransitionSkills.length; i++) {
      const buffer = {
        SkillName: skill.TransitionSkills[i].SkillName,
        CourseId: skill.TransitionSkills[i].CourseId,
        CourseName: skill.TransitionSkills[i].CourseName,
        Expertise: skill.TransitionSkills[i].Expertise
      };
      payload.TransitionSkills.push(buffer);
      this.courseListInSkillPath.push(buffer.CourseId);
    }
    this.courseListInSkillPath.push(skill.TargetSkill.CourseId);
    return payload;
  }

  openDialog(context, skillPath) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      context: context,
      msg: skillPath
    };
    this._dialog.open(MyPagePopupComponent, dialogConfig);
  }
}
