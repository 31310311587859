import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector
} from '@ngrx/store';

import * as fromAuthReducer from './auth.reducer';

export interface IAuthState {
  Auth: fromAuthReducer.IAuthReducerState;
}

export const authReducers: ActionReducerMap<IAuthState> = {
  Auth: fromAuthReducer.reducer
};

export const AuthReducerCollection = fromAuthReducer.reducer;

export const reducers: ActionReducerMap<IAuthState> = {
  Auth: fromAuthReducer.reducer
};

export const getAuthstate = createFeatureSelector<IAuthState>('Auth');

export const getAuthReducerState = createSelector(
  getAuthstate,
  (state: IAuthState) => state.Auth
);

export const getAuthUserInfo = createSelector(
  getAuthReducerState,
  (state: fromAuthReducer.IAuthReducerState) => state
);

export const getAuthUserName = createSelector(
  getAuthReducerState,
  (state: fromAuthReducer.IAuthReducerState) => state.userInfo.userName
);
