import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchOrderBy',
  pure: false
})
export class SearchOrderByPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    ////console.log(value, args);
    let returnedValue;
    if (args == null) {
      returnedValue = value;
      return returnedValue;
    } else if (args === 'AvgRating') {
      value.sort((a: any, b: any) => {
        if (a[args] > b[args]) {
          return -1;
        } else if (a[args] < b[args]) {
          return 1;
        } else {
          return 0;
        }
      });
      returnedValue = value;
      return returnedValue;
    } else if (args === 'CreatedDate') {
      let newVal, date1, date2;
      newVal = value.sort((a: any, b: any) => {
        date1 = new Date(a.CreatedDate);
        date2 = new Date(b.CreatedDate);

        if (date1 < date2) {
          return 1;
        } else if (date1 > date2) {
          return -1;
        } else {
          return 0;
        }
      });
      returnedValue = newVal;
      return returnedValue;
    }
  }
}
