import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../user-details/store';
import { Globals } from '../../globals';
import { GraphDataService } from '@YorbitWorkspace/graph';
@Injectable({
  providedIn: 'root'
})
export class PopupService {
  _baseURL: string;
  PopupPageSubscriptions: any = {};
  //  isFeedbackPending : boolean;
  isSessionTimeoutPopupOpen: any;
  constructor(
    private _http: HttpClient,
    private _envSvc: EnvironmentService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private globals: Globals,
    private _graphSvc: GraphDataService
  ) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
    this.isSessionTimeoutPopupOpen = <BehaviorSubject<boolean>>(
      new BehaviorSubject(false)
    );
  }

  getManagerFeebback() {
    return this._http
      .get<any>(this._baseURL + 'Course/GetManagerFeedback')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  postManagerFeedback(payload) {
    return this._http
      .post(this._baseURL + 'Course/PostManagerFeedback', payload)
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getUserDetails() {
    return new Promise((resolve, reject) => {
      this.userDetailsStore
        .select(fromUserDetailsStore.getUserDetailObject)
        .subscribe(data => {
          if (data.id !== '') {
            resolve(data);
          }
        });
    });
  }

  isCourseFeedbackPending() {
    let isFeedbackPending = false;
    return new Promise((resolve, reject) => {
      this.userDetailsStore
        .select(fromUserDetailsStore.getUserDetailObject)
        .subscribe(
        data => {
          if (data.id !== '') {
            const feedbackList = data.Achievements.Badges.filter(row => {
              if (
                row.Status === 'true' &&
                row.isMigrated === false &&
                row.IsFeedbackSet === false &&
                row.IsExternalCertificate === false &&
                row.IsFeedbackMandatory === true
              ) {
                return row;
              }
            });
            if (feedbackList.length > 0) {
              isFeedbackPending = true;
            } else {
              isFeedbackPending = false;
            }
            resolve(isFeedbackPending);
          }
        },
        error => {
          reject('Something went wrong.');
        }
        );
    });
  }

  isManagerFeedbackPending() {
    let isManagerFeedbackPending = false;
    return new Promise((resolve, reject) => {
      if (
        !this.globals.isManagerFeedbackPopupShown &&
        this.globals.managerFeedbackList.length === 0
      ) {
        this.getManagerFeebback().subscribe(
          data => {
            if (data.length > 0) {
              isManagerFeedbackPending = true;
              this.globals.managerFeedbackList = data;
              this.globals.managerFeedbackList.forEach((value, index) => {
                this._graphSvc.getUserName(value.MID).subscribe(
                  graphData => {
                    value.name = graphData.displayName;
                  },
                  error => {
                    value.name = value.MID;
                  }
                );
              });
              resolve(true);
            } else resolve(false);
          },
          error => {
            reject(false);
          }
        );
      } else if (
        !this.globals.isManagerFeedbackPopupShown &&
        this.globals.managerFeedbackList.length > 0
      ) {
        resolve(true);
      } else if (this.globals.isManagerFeedbackPopupShown) {
        resolve(false);
      }
    });
  }

  getPendingCourseFeedBackList() {
    return this._http
      .get<any>(this._baseURL + 'Course/Feedback/Pending')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  postCourseFeedback(payload) {
    return this._http.post(this._baseURL + 'Course/Feedback', payload);
  }

  updateAchievementList(feedbackCourseId) {
    return new Promise((resolve, reject) => {
      if(this.PopupPageSubscriptions.getUserDetailObject){
        this.PopupPageSubscriptions.getUserDetailObject.unsubscribe();
        this.PopupPageSubscriptions.getUserDetailObject = null;
      }
      this.PopupPageSubscriptions.getUserDetailObject = this.userDetailsStore
        .select(fromUserDetailsStore.getUserDetailObject)
        .subscribe(loaded =>
        data => {
          
          data.Achievements.Badges.forEach((badge, key) => {
            if (String(badge.CourseId) === String(feedbackCourseId)) {
              if (data.Achievements.Badges[key] !== undefined) {
                data.Achievements.Badges[key].IsFeedbackSet = true;
              }
            }
          });
          this.userDetailsStore.dispatch(
            new fromUserDetailsStore.UserDetailsGetDetailsSuccess(data)
          );
          this.PopupPageSubscriptions.getUserDetailObject.unsubscribe();
          resolve(true);
        },    
        error => {
          reject(false);
        }
        );
    });
  }

  updateSessionTimeoutPopupStatus(isOpen: boolean) {
    this.isSessionTimeoutPopupOpen.next(isOpen);
  }

  getSessionTimeoutPopupStatus() {
    return this.isSessionTimeoutPopupOpen.asObservable();
  }
}
