<!--
  use as
    <yorbit-expert
    [card]="{
      'contentData':contentData,
      'expertLocation':routepage}"
    ></yorbit-expert>

    legends
    -> contentData set to card details as in
    browse, search, new and trending,
    detail page - package details
    routepage -> routepage name in string

-->
<div
  class="custom-mat-outline"
  fxFlex="30px"
  fxFlexLayout="center center"
  (click)="openDialog()"
>
  <!-- <mat-icon>radio_button_unchecked</mat-icon>
  <mat-icon fxFlex="30px" [ngClass]="{'overlap-icon':true}"> person_outline</mat-icon> -->

  <div
    class="yorbitSprite featureExpert"
    title="Click To View Experts For This Course/Package"
    fxFlex="30px"
    [ngClass]="{
      iconForAccordion: card.expertLocation == 'Details-Page-Accordion'
    }"
  ></div>
</div>
