import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {
  private appInsights: ApplicationInsights;

  constructor() { }

  insightInit(key){
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key
      }
    });
    appInsights.loadAppInsights();
    this.appInsights = appInsights;
  }

  trackPage(page){
   this.appInsights.trackPageView({ name: page });
  }
  trackEvent(eventName, eventObj){
   this.appInsights.trackEvent({ name: eventName }, eventObj);
  }

  trackUser(user){
   this.appInsights.setAuthenticatedUserContext(user);
  }
}
