import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-learner-batch-selection',
  templateUrl: './learner-batch-selection.component.html',
  styleUrls: ['./learner-batch-selection.component.scss']
})
export class LearnerBatchSelectionComponent implements OnInit {
  selectedBatchId: string;

  constructor(private dialogRef: MatDialogRef<LearnerBatchSelectionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {


  }

  ngOnInit(): void {
    if (!this.data.batchList || this.data.batchList.length === 0) {
      this.dialogRef.updateSize('400px');
    } else {
      this.data.batchList = this.data.batchList.sort((a, b) => { return Number(a.BatchId.split('-')[1]) - Number(b.BatchId.split('-')[1]) });
    }
  }

  onRadioChange(event: any) {
    this.selectedBatchId = event.target.value;
  }

  save() {
    if (this.selectedBatchId) {
      this.dialogRef.close(this.selectedBatchId);
    }
  }

  close() {
    this.dialogRef.close(null);
  }

}
