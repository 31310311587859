<mat-dialog-content class="editedPssPopup">
  <div class="close">
    <button mat-button mat-icon-button (click)="close(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- header -->
  <div class="popup-title heading">
    Confirmation
  </div>
  <mat-divider></mat-divider>
  <!-- message -->
  <div class="popup-content">
    <div class="message">
      Any unsaved changes will be lost. <br/> Do you want to continue?
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="ButtonContainer">
    <button class="action-button buttonSpace mousePointer" (click)="close(true)">
      Proceed
    </button>
    <button class="action-button buttonSpace mousePointer" (click)="close(false)">
      Cancel
    </button>
  </div>
  <mat-divider></mat-divider>
</mat-dialog-content>