import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountDashboard'
})
export class AccountDashboardPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args === undefined || args === '') {
      return value;
    } else {
      value = value || [];
      let out = [];
      out = Object.assign([], value).filter(
        item => item.RoleName.toLowerCase().indexOf(args.toLowerCase()) > -1
      );
      // value.forEach(item => {
      //   if (item.RoleName.toLowerCase() === args.toLowerCase()) {
      //     out.push(item);
      //   }
      // });
      return out;
    }
  }
}
