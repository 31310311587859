import * as fromDeclareStoreAction from './../actions/declare-store.action';

export interface IdeclareStatusReducerState {
  data: IdeclareStatus[];
  loading: boolean;
  loaded: boolean;
}
export interface IdeclareStatus {
  CourseId: number;
  DeclareFlagStatus: string;
}
export const declareStatusInit = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = declareStatusInit,
  action: fromDeclareStoreAction.DeclareAction
): IdeclareStatusReducerState {
  switch (action.type) {
    case fromDeclareStoreAction.DECLARE_GET_STATUS_ALL_COURSES: {
      return {
        ...state,
        loading: true
      };
    }
    case fromDeclareStoreAction.DECLARE_GET_STATUS_SUCCESS: {
      const data: IdeclareStatus[] = updateStatus(state.data, action.payload);
      return {
        ...state,
        data,
        loading: false,
        loaded: true
      };
    }
    case fromDeclareStoreAction.DECLARE_GET_STATUS_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
  }
  return state;
}

export const getDeclareStatus = (state: IdeclareStatusReducerState) =>
  state.data;
export const getDeclareLoading = (state: IdeclareStatusReducerState) =>
  state.loading;
export const getDelcareLoaded = (state: IdeclareStatusReducerState) =>
  state.loaded;

function updateStatus(data, statusList) {
  //const updatedData=[...data,...status]
  //return updatedData;
  statusList.forEach(status => {
    if (data.find(declareObj => declareObj.CourseId === status.CourseId)) {
      data = data.map(declareObj => {
        if (declareObj.CourseId === status.CourseId) {
          return status;
        }
        return declareObj;
      });
    } else {     
        let output = [];
        data.forEach((row,index)=>{
          output.push(row);
        });
        output.push(status);
      //  data = [...data, ...status];
        data = [...output];     
    }
  });
  return data;
}
