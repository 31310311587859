import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PssEvaluationService } from '../pss-evaluation.service';

@Component({
  selector: 'yorbit-pss-error-upload-popup',
  templateUrl: './pss-error-upload-popup.component.html',
  styleUrls: ['./pss-error-upload-popup.component.scss']
})
export class PssErrorUploadPopupComponent implements OnInit {
  fileNotSelected: boolean;
  showErrorMsg: boolean;
  uploadedFile: any;
  errorMsg: string;
  warningMsg: string;
  permittedSizeLimit: number;
  title: string;
  description: string;
  scoreCardInput: any;
  returnData: any;

  constructor(
    private dialogRef: MatDialogRef<PssErrorUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _pssEvaluationService: PssEvaluationService
  ) {
    //////console.log('data', this.data);
    this.fileNotSelected = true;
    this.showErrorMsg = false;
    this.errorMsg = '';
    this.permittedSizeLimit = 15000000;
    if (this.data.uploadFile == 'errorfile') {
      this.title = 'Error File Upload';
      this.description='Error File:';
      this.warningMsg = 'Please note the existing error file will be replaced.'
    } else if (this.data.uploadFile == 'scorecard') {
      this.title = 'Score Card Upload';
      this.description='Score Card:';
      this.warningMsg = 'Please note the existing score card will be replaced.'
    }
  }

  ngOnInit() {}
  closeDialog() {
    this.dialogRef.close(false);
  }
  
   uploadFile() { 
    const fd = new FormData();
    fd.append("file", this.uploadedFile,this.uploadedFile.name);
    //service calls to upload files
    if (this.data.uploadFile == 'errorfile') {
      this._pssEvaluationService.isErrorFileChanged = false;
      this._pssEvaluationService.uploadErrorFileToBlob(fd, this.data.evaluationRequestId, this.data.requestType)
      .subscribe(data => {
        this.returnData = data;
        ////console.log('ReturnDatat', this.returnData.Status, this.returnData);
        if(this.returnData.Status) {
          this._pssEvaluationService.isErrorFileChanged = true;
          ////console.log('ErrorFileChanged', this._pssEvaluationService.isErrorFileChanged);
          this.dialogRef.close(this.returnData);
        }
      });
    } else if (this.data.uploadFile == 'scorecard') {
      this._pssEvaluationService.isScoreCardChanged = false;
      this._pssEvaluationService.uploadScoreCardToBlob(fd, this.data.evaluationRequestId, this.data.requestType)
      .subscribe(data => {
        this.returnData = data;
        if(this.returnData.Status) {
          this._pssEvaluationService.isScoreCardChanged = true;
          ////console.log('scorecaredchanged', this._pssEvaluationService.isScoreCardChanged);
          this.dialogRef.close(this.returnData);
        }
      });;
    } else if (this.data.uploadFile == 'solutionupload') {
    }
  }

  fileModel(event) {
    ////console.log('event', event);
    this.fileNotSelected = false;
    if (event.file == undefined) {
      this.fileNotSelected = true;
      this.showErrorMsg = false;
      this.errorMsg = '';
    } else if (event.file.type == 'application/x-zip-compressed') {
      if (event.file.size > this.permittedSizeLimit) {
        this.showErrorMsg = true;
        this.errorMsg =
          'File size should not exceed ' +
          this.permittedSizeLimit / 1000000 +
          'MB';
      } else {
        this.showErrorMsg = false;
        this.uploadedFile = event.file;
      }
    } else {
      this.showErrorMsg = true;
      this.errorMsg = 'Zip files can only be uploaded';
    }
  }
}
