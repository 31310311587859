<div fxFlex="100%" fxShow class="top-coaches-parent-container" fxLayout="row" fxLayoutAlign="center stretch">
    <div *ngIf="isTopCoachesLoading" fxFlexFill fxLayoutAlign="center center">
        <app-preloader></app-preloader>
    </div>
    <div class="top-coaches-sub-container" *ngIf="!isTopCoachesLoading" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row" class="top-coaches-holder">
            <div fxLayout="row" class="left-heading-decoration">
                <hr class="left-heading-line">
                <div class="left-heading-dot"></div>
            </div>
            <div fxFlex.gt-xs="300px" fxFlex.xs="210px" class="title" fxLayoutAlign.xs="center center"
                fxLayoutAlign.gt-xs="center center" fxLayoutGap="15px" fxLayout.xs="column" fxLayout.gt-xs="row">
                TOP COACHES
            </div>
            <div fxLayout="row" class="right-heading-decoration">
                <div class="right-heading-dot"></div>
                <hr class="right-heading-line">
            </div>
        </div>
        <div fxLayout="row" class="top-coaches-tiles-parent">
            <div fxLayout="row" fxFlex.xs="340px">
                <div fxLayout="column" input type="text" class="top-coaches-tiles"
                    *ngFor="let topCoach of topCoaches | slice:numberOfTopCoaches*sliderActiveIndex:numberOfTopCoaches*sliderActiveIndex+numberOfTopCoaches; let i = index"
                    [ngClass]="{ parentDivBgColor1: i%5 == 0, parentDivBgColor2: i%5 == 1, parentDivBgColor3: i%5 == 2, parentDivBgColor4: i%5 == 3, parentDivBgColor5: i%5 == 4 }">
                    <div class="top-coach-content" (click)="openInformation(topCoach, i)" fxLayout="column"
                        fxLayoutAlign="start center" fxLayoutGap="20px">
                        <div class="first-half"
                            [ngClass]="{topBgColor1: i%5 == 0, topBgColor2: i%5 == 1, topBgColor3: i%5 == 2, topBgColor4: i%5 == 3, topBgColor5: i%5 == 4 }">
                            <div class="image-div" [ngStyle]="{'padding': topCoach.Image != null ? '10px' : '0px' }">
                                <img class="image" *ngIf="topCoach.Image != null" [src]="topCoach.Image"
                                    fxFlex="130px" />
                            </div>
                            <div class="coach-name">
                                {{topCoach.Name}}
                            </div>
                            <div class="coach-mid">
                                ({{topCoach.MID}})
                            </div>
                        </div>
                        <div class="second-half" fxLayout="column"
                            [ngClass]="{bottomBgColor1: i%5 == 0, bottomBgColor2: i%5 == 1, bottomBgColor3: i%5 == 2, bottomBgColor4: i%5 == 3, bottomBgColor5: i%5 == 4 }">
                            <div *ngIf='topCoach.TotalHours > 0' fxLayout="row" class="hours-part">
                                <div class="hours-value">
                                    {{topCoach.TotalHours}}
                                </div>
                                <div class="hours-text">
                                    Total hours spent in coaching
                                </div>
                            </div>
                            <div class="citation-text" [ngClass]="{withoutHour: topCoach.TotalHours == 0}">
                                {{topCoach.Citation}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="50px" fxLayoutAlign="center center" *ngIf="bullets.length>1 && !mobile">
            <div class="bullets" *ngFor="let slide of bullets; let index = index"
                [ngClass]="{ active: index == sliderActiveIndex }" (click)="showNextSlide(index)"></div>
        </div>
    </div>

</div>