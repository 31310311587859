import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-cert-date-change-alert',
  templateUrl: './cert-date-change-alert.component.html',
  styleUrls: ['./cert-date-change-alert.component.scss']
})
export class CertDateChangeAlertComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CertDateChangeAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
  closePopup(status) {
    this.dialogRef.close(status);
  }
}
