<div class="banner">
        <div class="strip"  [ngClass.lt-md]="['lt-md']">
     
            <img class="title" [ngClass.lt-md]="['lt-md']" id="image"  src="/../../assets/images/behavioral branding banner/title_on_strip.png" width="100px">
    
         </div>
    
    <div class="body" [ngClass.gt-sm]="['gt-sm']">
        <div class="body-part" fxLayout.gt-sm="row" fxLayout.xs="column" fxLayout.sm="column" [ngClass.sm]="['sm']"
            [ngClass.xs]="['xs']" [ngClass.gt-sm]="['gt-sm']" fxLayoutGap.gt-sm="20px">
            <img class="logo" fxHide.sm="true" fxHide.xs="true"
                src="./../../assets/images/behavioral branding banner/Living Mindfully Thumbnail 2.jpg" width="195px" height="100px">
            <img class="logo-mobile" fxHide.gt-sm="true"
                src="./../../assets/images/behavioral branding banner/Living Mindfully Thumbnail_mobile_version.jpg">
            <div class="details" fxLayout.gt-sm="column" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap.gt-sm="20px" fxLayoutGap.xs="20px" fxLayoutGap.sm="20px" [ngClass.sm]="['sm']"
                [ngClass.xs]="['xs']" [ngClass.gt-sm]="['gt-sm']">
                <span class="heading" [ngClass.sm]="['sm']" [ngClass.xs]="['xs']" [ngClass.gt-sm]="['gt-sm']">An
                    exclusive initiative for LTIMindtree Associates by LTIMindtree Associates</span>
                <div class="underheading" fxLayout.gt-sm="row" fxLayout.xs="column" fxLayout.sm="column"
                    fxLayoutGap.gt-sm="20px" fxLayoutGap.xs="20px" fxLayoutGap.sm="20px">
                    <span class="paragraph" [ngClass.sm]="['sm']" [ngClass.xs]="['xs']" [ngClass.gt-sm]="['gt-sm']">A
                        holistic program to enable LTIMindtree Associates to live mindfully with self-
                        awareness, <br> resilience, focus and strength and achieve excellence – professionally &
                        personally.</span>
                    <button type="button" class="button" [ngClass.sm]="['sm']" [ngClass.xs]="['xs']"
                        [ngClass.gt-sm]="['gt-sm']" (click)="openCourse()">GET STARTED</button>
                </div>
            </div>

        </div>
    </div>
</div>