import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AddPopupComponent } from './add-popup/add-popup.component';
import { AddedContentDataService } from '../../services/added-content-data.service';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { MyPageService } from '../../../my-page/my-page.service'; //MyPageService to get CDM code
import { Globals } from '../../../globals';
import { MatTooltip } from '@angular/material/tooltip';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'yorbit-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.scss']
})
export class AddContentComponent implements OnInit {
  @Input() card: any; //
  isAddedToLP: boolean;
  addTitle: string;
  cdms: any; //CDM msg
  rms: any; //CDM msg
  Ismobile: boolean;
  restrictedCourseToolTip: string;
  addedLPDetails: any;
  showButton = false;
  @ViewChild('tooltip', { static: false }) manualTooltip: MatTooltip;
  counter = 0;
  constructor(
    private router: Router,
    private _popup: MatDialog,
    private addedContentDataService: AddedContentDataService,
    private _InsightService: AppInsightService,
    private _myPageSvc: MyPageService, //private mypageservice
    private globals: Globals,
    private mediaObserver: MediaObserver
  ) {
    this.addedLPDetails = null;
    this.isAddedToLP = false;
    this.addTitle = 'Add Course/Package To Learning Path';
    this.restrictedCourseToolTip = this.globals.toolTipMsg;
    this.mediaObserver.media$.subscribe(
      (media: MediaChange) => {
        //////console.log('media change', media.mqAlias);
        if (media.mqAlias === 'xs') {
          this.Ismobile = true;
        } else if (media.mqAlias !== 'xs') {
          this.Ismobile = false;
        }
      },
      error => {
        ////console.log('media change error', error);
      }
    );
    this.constructRestrictedToolTipMsg();
  }

  ngOnInit() {
    this.addedContentDataService.contentAddedDetailsLoaded.subscribe(res => {
      this.showButton = res;
      this.counter++;
      // //console.log(
      //   'add counter sub',
      //   this.card.contentData.Name,
      //   this.counter++
      // );
      if (res) {
        this.isCardAddedToLP();
      }
    });
  }

  isCardAddedToLP() {
    this.addedContentDataService
      .IsContentAddedToLP(this.card.contentData)
      .then((response: any) => {
        if (response.IsAdded) {
          this.isAddedToLP = true;
          this.addedLPDetails = response.addedLPDetails;
          this.addTitle = 'Navigate to Learning Path';
        }
      });
  }
  navigateToOld() {
    //click action
    //send other route data to highlight the content in LP
    this.router.navigate(
      [
        'learningpath/category/' +
        this.addedLPDetails.LPCategory +
        '/id/' +
        this.addedLPDetails.LPId
      ],
      {
        queryParams: {
          lpId: this.addedLPDetails.LPId,
          courseId: this.addedLPDetails.CourseId,
          packageId: this.addedLPDetails.PackageId
        }
      }
    );
  }
  navigateTo() {
    //click action
    //send other route data to highlight the content in LP
    if (this.addedLPDetails.PackageId && this.addedLPDetails.CourseId) {
      this.router.navigate(
        [
          'learningpath/category/' +
          this.addedLPDetails.LPCategory +
          '/id/' +
          this.addedLPDetails.LPId +
          '/package/' +
          this.addedLPDetails.PackageId
        ],
        {
          queryParams: {
            lpId: this.addedLPDetails.LPId,
            courseId: this.addedLPDetails.CourseId,
            packageId: this.addedLPDetails.PackageId
          }
        }
      );
    } else {
      this.router.navigate(
        [
          'learningpath/category/' +
          this.addedLPDetails.LPCategory +
          '/id/' +
          this.addedLPDetails.LPId
        ],
        {
          queryParams: {
            lpId: this.addedLPDetails.LPId,
            courseId: this.addedLPDetails.CourseId,
            packageId: this.addedLPDetails.PackageId
          }
        }
      );
    };
  }
  openDialog() {
    try {
      const insightPayload = {
        Type: this.card.contentData.ItemType,
        Action: 'Clicked',
        Location: this.card.addedLocation
      };
      this._InsightService.trackEvent('Add Content', insightPayload);
    } catch (error) {
      const insightPayload = {
        Action: 'Add click event',
        Category: 'Add',
        Description: 'Added on ' + this.card.addedLocation
      };
      this._InsightService.trackEvent('Error', insightPayload);
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //addDialogContainer

    dialogConfig.data = {
      contentName: this.card.contentData.Name,
      Id: this.card.contentData.Id,
      ItemType: this.card.contentData.ItemType,
      uniqueId: this.card.contentData.UniqueId,
      accountId: this.card.contentData.Account,
      projectId: this.card.contentData.Project,
      AccountPackage: this.card.contentData.AccountPackage,
      ProjectPackage: this.card.contentData.ProjectPackage,
      Title: this.card.contentData.Name,
      skillProfile: this.card.skillProfile,
      addedLocation: this.card.addedLocation
    };

   // console.log(dialogConfig);
    const dialogRef = this._popup.open(AddPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      this.isCardAddedToLP();
    });
  }
  constructRestrictedToolTipMsg() {
    if (this.globals.myCDMs.length > 0) {
      //If CDMs are available for minds
      //this.restrictedCourseToolTip = "This course can only be assigned by your CDM(s): " + this.globals.myCDMs;
      //this.restrictedCourseToolTip = this.globals.toolTipMsg + this.globals.myCDMs;
      let cdmNamesConcated = '';
      this.globals.cdmNameList.forEach((name, index) => {
        cdmNamesConcated += name;
      });
      this.restrictedCourseToolTip = this.globals.toolTipMsg + cdmNamesConcated;
    } else if (this.globals.myRms.length > 0) {
      //redirect to RM
      // this.restrictedCourseToolTip='To get this course assigned, please seek a mail approval from your RM and forward the same to yorbit.support@mindtree.com';
      this.restrictedCourseToolTip = this.globals.toolTipMsg;
    }
  }
  toggleTooltip() {
    this.manualTooltip.toggle();
  }
}
