import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromCloudlabAction from './../actions/cloudlab-store.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CloudlabStoreService } from '../../cloudlab-store.service';

@Injectable()
export class CloudlabEffect {
  constructor(
    private actions$: Actions,
    private cloudlabStoreService: CloudlabStoreService
  ) {}

  
  getCloudlabFlag$ = createEffect(() => this.actions$.pipe(
    ofType(fromCloudlabAction.CLOUDLAB_GET_DETAILS),
      switchMap((action: fromCloudlabAction.CloudlabGetStatus) => {
        return this.cloudlabStoreService
          .getCloudLabDetails(action.payload)
          .pipe(
            map(cloudlabDetails => {
              return new fromCloudlabAction.CloudlabGetStatusSuccess(
                cloudlabDetails
              );
            }),
            catchError(error =>
              of(new fromCloudlabAction.CloudlabGetStatusError(error))
            )
          );
      })
    ));
}
