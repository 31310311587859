<div class="cnt border101" fxLayout="row" fxLayoutAlign="space-between center" 
[ngClass]="cardDetails?.ItemExpertise==='101'?'border101':cardDetails?.ItemExpertise==='201'?'border201':'border301'"  >
  <div class="course-details" fxLayout="column">
    <div class="expertise" 
    [ngClass]="cardDetails?.ItemExpertise==='101'?'course101':cardDetails?.ItemExpertise==='201'?'course201':'course301'" >{{cardDetails?.ItemExpertise}}</div>
    <div class="name">{{cardDetails?.ItemName}}</div>
  </div>
  <div class="progress" fxLayout="row" fxLayoutAlign="center">
      <div class="status" fxLayout="column"  dir="rtl">
        <div class="title">: Status</div>
        <div class="current-status">{{courseStatus=='Course Completed'?'Completed':courseStatus=='Not Started'?'Not Started':'Ongoing'}}</div>
      </div>

      <img [src]="courseStatus=='Course Completed'?'../../assets/images/riskbrigade/icon-completed.png':courseStatus=='Not Started'?'../../assets/images/riskbrigade/icon-notstarted.png':'../../assets/images/riskbrigade/icon-ongoing.png'" alt="">
  </div>

</div>