import { Component, OnInit, Inject } from '@angular/core';
import { PssContentApprovalComponent } from '../pss-content-approval.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { PssContentApprovalService } from '../pss-content-approval.service';
import { FormControl } from '@angular/forms';
import { MediaObserver } from '@angular/flex-layout';
import { ShareContentPopupService } from '../../../shared/card-feature/share-content/share-content-popup/share-content-popup.service';

@Component({
  selector: 'yorbit-pss-content-action-popup',
  templateUrl: './pss-content-action-popup.component.html',
  styleUrls: ['./pss-content-action-popup.component.scss']
})
export class PssContentActionPopupComponent implements OnInit {
  popupText: { initText: string; successText: string; errorText: string };
  actionActivated: boolean;
  actionCompleted: boolean;
  actionSuccess: boolean;
  remarksText: string;
  spocMid: any;
  midRegex: RegExp;
  ShareControl = new FormControl();
  suggestionList: any[];
  searchUserInput: string;
  UserAddedValidateFail: boolean;
  noSuggestionError: boolean;
  userListToShare: any[];
  UserAddedValidateError: string;
  validateUserAdded: ErrorStateMatcher = {
    isErrorState: (control: FormControl) => {
      return this.UserAddedValidateFail||this.noSuggestionError;
    }
  };

  constructor(
    public dialogRef: MatDialogRef<PssContentApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pssContentApprovalService: PssContentApprovalService,
    private mediaObserver: MediaObserver,
    private _shareContentPopupService: ShareContentPopupService,
  ) {}

  ngOnInit() {

    this.userListToShare = [];

    this.remarksText = '';
    this.spocMid='';
    this.midRegex = /^([0-9]+)$/;

    ////console.log(this.data);
    if(this.data.spoc) {
      this.spocMid = this.data.spoc;
    }
    else {
      this.spocMid = '';
    }
    
    this.onFirstLoad();
  }
  onFirstLoad() {
    this.actionActivated = false;
    this.actionCompleted = false;
    this.actionSuccess = false;
    if ('approve' == this.data.type) {
      this.popupText = {
        initText: 'Verify and Launch the course?',
        successText: 'The content has been verified and successfully launched on Shoshin School',
        errorText: 'Course Launch failed'
      };
    } else {
      this.popupText = {
        initText: 'Decline and revert the course to respective Content Partner?',
        successText: 'The course has been reverted back to the Content Partner',
        errorText: 'Course revert failed'
      };
    }
  }
  proceedAction() {
    this.actionActivated = true;
    this.actionCompleted = false;
    const payload = {
      UniqueId: this.data.UniqueId,
      pssAction: 'approve' == this.data.type ? 'Approved' : 'Denied',
      remarks: this.remarksText,
      creditSpecial:this.data.creditSpecial,
      spoc:this.spocMid.trim()
    };
    this.pssContentApprovalService
      .pssVerificationAction(payload)
      .then(response => {
        this.actionActivated = false;
        this.actionCompleted = true;
        if ( response == "success" ) {
          this.actionSuccess = true;
        } else {
          this.actionSuccess = false;
        }
      })
      .catch(error => {
        this.actionSuccess = false;

        this.actionCompleted = true;
        this.actionActivated = false;
      });
  }

  resetUserInput() {
    this.suggestionList = [];
    this.searchUserInput = '';
    this.UserAddedValidateFail = false;
  }
  searchUser(user, event) {
    let keyEventCheck = true;
    this.UserAddedValidateFail = false;
    if (
      1 !== event.key.length &&
      'Backspace' !== event.key &&
      'Delete' !== event.key
    ) {
      keyEventCheck = false;
    }
    if (
      user.length >= 3 &&
      (keyEventCheck ||
        this.mediaObserver.isActive('xs') ||
        this.mediaObserver.isActive('sm'))
    ) {
      this._shareContentPopupService
        .getSuggestionList(user)
        .then((response: any) => {
          if(response&&0<response.value.length){
            this.suggestionList = response.value;
            this.noSuggestionError=false;
          }else{
            this.noSuggestionError=true;
            this.suggestionList = [];
          }
        });
    }
    if ('Enter' === event.key) {
      this.addUserFromSuggestionList(user);
    }
  }

  addUserFromSuggestionList(user) {
    this.resetUserInput();
    this.userListToShare=[];
    const userlist = this.userListToShare;
    const userPresentInList = userlist.filter(
      item => item.userPrincipalName === user.userPrincipalName
    );
    if (0 === userPresentInList.length) {
      this.userListToShare = [...this.userListToShare, user];
      this.spocMid=user.userPrincipalName.split("@")[0];;
    } else {
      this.UserAddedValidateFail = true;
      this.UserAddedValidateError = 'User has already been added';
    }

  }
  removeUserFromSuggestionList(user) {
    const userlist = this.userListToShare;
    this.userListToShare = userlist.filter(item => item !== user);
    this.spocMid='';
  }

  displayEmpty() {
    return null;
  }

  userSelectHandler(event) {
    event.option.deselect();
  }

  /**
   * Common functions
   */
  closeUpdatePopup() {
    this.dialogRef.close(this.actionSuccess);
  }
}
