<div #MyVideoContainer id="video-container" fxLayoutAlign="center center" [ngStyle]="{'height.px':'400'}" ></div>


<div *ngIf="canShowMessage && !showPreloader" class="video-container  bgBlack" fxLayout="column" fxLayoutAlign="center center"
    [ngStyle.xs]="{'height.px':'320'}">
    <div *ngIf="isCompliance" class="playerNotworkingMsg">
        <div>Sorry, this video cannot be played. We are working towards resolving the same.</div>
        <div>Your email will not be blocked until this issue is resolved.</div>
        <div>Hence, no action is required from your end.</div>
    </div>
    <div *ngIf="!isCompliance" class="playerNotworkingMsg">
        <div>Sorry, this video cannot be played. We are working towards resolving the same.</div>
        <div>No action is required from your end.</div>
        <div>We will be back soon!</div>
    </div>
</div>