import { Component, OnInit, Input, ViewChild, ElementRef, Inject } from '@angular/core';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { WINDOW } from '../../../services/window.service';

@Component({
  selector: 'yorbit-expert-profile',
  templateUrl: './expert-profile.component.html',
  styleUrls: ['./expert-profile.component.scss']
})
export class ExpertProfileComponent implements OnInit {
  @Input() expert: any;
  @ViewChild('expertImage', { static: true }) userImage: ElementRef;
  expertName: string;
  expertMailId: any;

  constructor(
    private graphDataService: GraphDataService,
    @Inject(WINDOW) private _window: any
  ) { }

  ngOnInit() {
    this.expertName = this.expert;
    this.expertMailId = this.expert;
    this.getExpertImageFromGraph();
    this.getExpertMailIdFromGraph();
    this.getExpertNameFromGraph();
  }

  mailToExpert() {
    if(this.expertMailId.toLowerCase().includes("@ltimindtree.com")){
      window.location.href = "mailto:" + this.expertMailId;
    }
  }

  getExpertImageFromGraph() {
    this.graphDataService
      .getExpertImage(this.expert)
      .subscribe(res => {
        this.userImage.nativeElement.src = this._window.URL.createObjectURL(
          res
        );
      });
  }

  getExpertMailIdFromGraph() {
    const Mid = (this.expert.split('@'))[0];
    this.graphDataService
      .getUserMailId(Mid)
      .subscribe(res => {
        this.expertMailId = res.mail
      });
  }
  getExpertNameFromGraph() {
    const Mid = (this.expert.split('@'))[0];
    this.graphDataService
      .getUserName(Mid)
      .subscribe(res => {
        this.expertName = res.displayName
      });
  }

}
