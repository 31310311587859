import { Component, OnInit, Input, Inject } from '@angular/core';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '../../shared/services/window.service';
import { AoService } from '../ao.service';

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ContentUploadStatusPopupComponent } from '../content-upload-status-popup/content-upload-status-popup.component';

@Component({
  selector: 'yorbit-new-course-request-approval',
  templateUrl: './new-course-request-approval.component.html',
  styleUrls: ['./new-course-request-approval.component.scss']
})
export class NewCourseRequestApprovalComponent implements OnInit {
  @Input('pendingTab') pendingTab: string;
  @Input('requestData') requestData: any;
  @Input('taxonomy') taxonomy: any;
  @Input('requestStatus') requestStatus: string;
  @Input('reassignAcademy') reassignAcademy: string;
  @Input('reassignStatus') reassignStatus: string;
  @Input('nestedTabs') nestedTabs: any;
  @Input('taxonomyList') taxonomyList: any;
  @Input('showAccepted') showAccepted: boolean;
  @Input('showDenied') showDenied: boolean;
  @Input('showOnHold') showOnHold: boolean;
  @Input('showCompleted') showCompleted: boolean;

  graphUserName: string;
  graphUserImage: Blob;
  imageUrl: any;
  imageLoaded: boolean;
  AOComments: string;
  updatedAcademy: string;
  updatedGenre: string;
  updatedSkill: string;
  genreList: Array<any>;
  genreSkillList: Array<any>;
  skillList: Array<any>;
  completeReqComment: string;
  completeReqInProgress: boolean;
  isReqComplete: boolean;
  completeReqErr: boolean;
  onHoldComment: string;

  constructor(
    private graphSvc: GraphDataService,
    @Inject(WINDOW) private _window: any,
    private sanitizer: DomSanitizer,
    private _AOSvc: AoService,
    private _popup: MatDialog
  ) {}

  ngOnInit() {
    this.nestedTabs = {
      Accepted: this.showAccepted,
      Denied: this.showDenied,
      OnHold: this.showOnHold,
      Completed: this.showCompleted
    };
    this.graphUserName = this.requestData.CreatedBy;
    this.getUserImage(this.requestData.CreatedBy);
    this.getUserName(this.requestData.CreatedBy);
    this.reassignAcademy = '';
    this.AOComments = '';
    if (this.nestedTabs.Accepted) {
      this.setTaxonomy();
    }
  }

  getUserImage(Mid) {
    this.imageLoaded = false;
    this.graphSvc.getUserImage(Mid).subscribe(data => {
      this.graphUserImage = data;
      this.imageUrl = this.createImageURL(this.graphUserImage);
      this.imageLoaded = true;
    });
  }
  getUserName(Mid) {
    this.graphSvc.getUserName(Mid).subscribe(data => {
      this.graphUserName = data.displayName;
    });
  }
  createImageURL(imageBlob) {
    const imageURL = this._window.URL.createObjectURL(imageBlob);
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }
  checkIfActioned(reqId) {
    if (
      reqId === 'accepted' ||
      reqId === 'denied' ||
      reqId === 'on hold' ||
      reqId === 're-assigned'
    ) {
      return true;
    }
    return false;
  }

  reassignCourseRequest(newAcademy, request, reqStatus) {
    if (
      newAcademy !== '' &&
      newAcademy !== null &&
      newAcademy !== undefined &&
      reqStatus !== 'accepted' &&
      reqStatus !== 'denied' &&
      reqStatus !== 'on hold' &&
      reqStatus !== 'Processing...'
    ) {
      const payload = {
        RequestId: request.Id,
        AoComment: ''
      };
      this.reassignStatus = 'Processing...';
      this._AOSvc.reassignCourseRequest(newAcademy, payload).subscribe(
        data => {
          this.reassignAcademy = '';
          this.reassignStatus = data;
        },
        error => {
          this.reassignAcademy = '';
          this.reassignStatus = 'error';
        }
      );
    }
  }

  acceptNewCourseRequest(requestId, comment, reassignStatus) {
    if (
      reassignStatus !== 're-assigned' &&
      reassignStatus !== 'Processing...'
    ) {
      const coursePayload = {
        RequestId: requestId,
        AoComment: comment === undefined ? '' : comment
      };
      this.requestStatus = 'Processing...';
      this._AOSvc.acceptCourseRequest(coursePayload).subscribe(data => {
        this.actionNewCourseRequest(data);
      });
    }
  }

  denyNewCourseRequest(requestId, comment, reassignStatus) {
    if (
      comment !== '' &&
      comment !== undefined &&
      comment !== null &&
      reassignStatus !== 're-assigned' &&
      reassignStatus !== 'Processing...'
    ) {
      const coursePayload = {
        RequestId: requestId,
        AoComment: comment === undefined ? '' : comment
      };
      this.requestStatus = 'Processing...';
      this._AOSvc.denyCourseRequest(coursePayload).subscribe(data => {
        this.actionNewCourseRequest(data);
      });
    }
  }

  holdNewCourseRequest(requestId, comment, reassignStatus) {
    if (
      comment !== '' &&
      comment !== undefined &&
      comment !== null &&
      reassignStatus !== 're-assigned' &&
      reassignStatus !== 'Processing...'
    ) {
      const coursePayload = {
        RequestId: requestId,
        AoComment: comment === undefined ? '' : comment
      };
      this.requestStatus = 'Processing...';
      this._AOSvc.holdCourseRequest(coursePayload).subscribe(data => {
        this.actionNewCourseRequest(data);
      });
    }
  }

  actionNewCourseRequest(response) {
    this.requestStatus = response;
    this.reassignAcademy = '';
  }

  isCommentBoxEmpty(comments) {
    if (comments === undefined || comments === '' || comments === null) {
      return true;
    }
    return false;
  }

  setTaxonomy() {
    this.updatedAcademy = this.requestData.Academy;
    this.updatedGenre = this.requestData.Genre;
    this.updatedSkill = this.requestData.Skill;
  }

  genreFilter(academy, genreChosenByLearner, requestId) {
    this.genreList = [];
    //this.updatedSkill[requestId] = "";
    let genreExists = false;
    this.taxonomyList.forEach((value, key) => {
      if (academy !== null && academy !== undefined) {
        if (value.Academy.toLowerCase() === academy.toLowerCase()) {
          this.genreSkillList = value.GenreSkillList;
        }
      }
    });

    if (this.genreSkillList.length > 0) {
      for (let k = 0; k < this.genreSkillList.length; k++) {
        this.genreList.push(this.genreSkillList[k].Genre);
      }

      for (let i = 0; i < this.genreList.length; i++) {
        if (
          genreChosenByLearner.toLowerCase() === this.genreList[i].toLowerCase()
        ) {
          genreExists = true;
          break;
        } else {
          continue;
        }
      }

      if (genreExists === true) {
        return this.genreList;
      } else {
        let returnArr = new Array();
        returnArr[0] = genreChosenByLearner;
        for (let count = 0; count < this.genreList.length; count++) {
          returnArr.push(this.genreList[count]);
        }
        return returnArr;
      }
    } else return [];
  }

  skillFilter(genre) {
    this.skillList = [];
    if (this.genreSkillList.length > 0) {
      this.genreSkillList.forEach((value, key) => {
        if (genre != null && genre != undefined) {
          if (value.Genre.toLowerCase() == genre.toLowerCase()) {
            this.skillList = value.Skills;
          }
        }
      });

      if (this.skillList.length > 0) {
        return this.skillList;
      } else return [];
    } else return [];
  }

  completeNewCourseRequest(request, comment) {
    const payload = {
      RequestId: request.Id,
      AoComment: comment
    };
    const genre = this.updatedGenre;
    const skill = this.updatedSkill;

    this.completeReqInProgress = true;
    this.completeReqErr = false;
    this._AOSvc.completeRequest(payload, genre, skill).subscribe(
      data => {
        this.completeReqInProgress = false;
        this.isReqComplete = true;
        if (data.toLowerCase() === 'completed') {
          this.completeReqErr = false;
        } else if (data.toLowerCase() === 'error') {
          this.completeReqErr = true;
        }
      },
      error => {
        this.completeReqInProgress = false;
        this.isReqComplete = false;
        this.completeReqErr = true;
      }
    );
  }
  isAcceptedOrDenied() {
    if (this.requestStatus === 'accepted' || this.requestStatus === 'denied') {
      return true;
    }
    return false;
  }

  openPopup(
    reassignAcademyOrId,
    requestDataOrComments,
    requestStatus,
    feature
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      msg: ''
    };

    let type = 'courseRequest';
    let popupData = {
      msg: '',
      title: 'New Course Request'
    };
    dialogConfig.data = {
      data: popupData,
      width: '250px',
      height: '250px',
      type: type
    };

    switch (feature) {
      case 'reassign':
        popupData.msg =
          'Are you sure you want to Re-assign this request to ' +
          reassignAcademyOrId +
          ' Academy?';
        break;
      case 'accept':
        popupData.msg = 'Are you sure you want to Accept this request?';
        break;
      case 'deny':
        popupData.msg = 'Are you sure you want to Deny this request?';
        reassignAcademyOrId + ' ?';
        break;
      case 'hold':
        popupData.msg = 'Are you sure you want to Hold this request?';
        break;
    }

    const response = this._popup.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(isActionConfirmed => {
      //do something after pop up close
      if (isActionConfirmed) {
        switch (feature) {
          case 'reassign':
            this.AOComments = '';
            this.reassignCourseRequest(
              reassignAcademyOrId,
              requestDataOrComments,
              requestStatus
            );
            break;
          case 'accept':
            this.reassignAcademy = '';
            this.acceptNewCourseRequest(
              reassignAcademyOrId,
              requestDataOrComments,
              requestStatus
            );
            break;
          case 'deny':
            this.reassignAcademy = '';
            this.denyNewCourseRequest(
              reassignAcademyOrId,
              requestDataOrComments,
              requestStatus
            );
            break;
          case 'hold':
            this.reassignAcademy = '';
            this.holdNewCourseRequest(
              reassignAcademyOrId,
              requestDataOrComments,
              requestStatus
            );
            break;
        }
      }
    });
  }

  genreUpdate() {
    this.updatedSkill = 'Not Applicable';
  }
}
