import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssignContentComponent } from '../assign-content.component';
import { AssignContentService } from '../assign-content.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'yorbit-assign-confirmation-popup',
  templateUrl: './assign-confirmation-popup.component.html',
  styleUrls: ['./assign-confirmation-popup.component.scss']
})
export class AssignConfirmationPopupComponent implements OnInit {
  assignData: {
    itemName: any;
    UserIds: any;
    itemId: any;
    itemType: any;
    itemExpertise: any;
  };
  intentControl = new FormControl('', [Validators.required]);
  // assignInProgress: boolean;
  constructor(
    public dialogRef: MatDialogRef<AssignContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private assignContentService: AssignContentService
  ) {

    // console.log("********************************************************")
    // console.log(this.data)
  }

  ngOnInit() {
    // this.assignInProgress=false;
  }


  // sendCdmPushData() {
  //   const assignData = this.data.assignData;
  //   const isPP = this.data.PPCheck;
  //   const assignAs = this.data.assignAsRole;
  //   let isProjectPackage;

  //   if (null != assignData.UserIds) {
  //     if (this.data.AccountPackage != null) {
  //       if (
  //         this.data.AccountPackage === true ||
  //         this.data.ProjectPackage === true ||
  //         this.data.AccountPackage === 'true' ||
  //         this.data.ProjectPackage === 'true'
  //         ) {
  //           isProjectPackage = true;
  //         } else {
  //           isProjectPackage = false;
  //         }
  //       } else {
  //         isProjectPackage = false;
  //       }
  //       this.assignInProgress=true;
  //     this.assignContentService
  //       .cdmPushContent(assignData, assignAs, isPP)
  //       .then((r: any) => {
  //         if (null !== r) {
  //           const cdmStatusResult = [];
  //           this.assignInProgress= false;
  //           for (const field of Object.keys(r)) {
  //             cdmStatusResult.push({ Mid: field, Status: r[field] });
  //         }
  //           for (let i = 0; i < cdmStatusResult.length; i++) {
  //             if ('Success' === cdmStatusResult[i].Status) {
  //               // if (this.data.AccountPackage || this.data.ProjectPackage) {
  //               //   const cdmPushedContent = {
  //               //     IsMandatory: true,
  //               //     ItemDuration: assignData.duration,
  //               //     ItemExpertise: assignData.itemExpertise.toString(),
  //               //     ItemId: assignData.itemId.toString(),
  //               //     ItemName: assignData.itemName.toString(),
  //               //     ItemType: assignData.itemType.toString()
  //               //   };
  //               // } else {
  //               //   const cdmPushedContent = {
  //               //     IsMandatory: true,
  //               //     ItemDuration: assignData.duration.toString(),
  //               //     ItemExpertise: assignData.itemExpertise.toString(),
  //               //     ItemId: assignData.itemId.toString(),
  //               //     ItemName: assignData.itemName.toString(),
  //               //     ItemType: assignData.itemType.toString()
  //               //   };
  //               // }
  //               break;
  //             }
  //           }

  //           this.dialogRef.close({
  //             data: {
  //               assignData: this.data.assignData,
  //               responseData:cdmStatusResult,
  //               NoteMessageForAssign:this.data.NoteMessageForAssign
  //             }
  //           });
  //         }
  //       });
  //   }
  // }


  closeAssign() {
    this.dialogRef.close();
  }
}
