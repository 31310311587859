import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HMMAndSparkService {
  config: Ienvironment;

  _baseURL: string;
  personalizedRecommendations: any;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();

    this._baseURL = this._envSvc.getEnvironment().apiUrl;
  }

  getCoursesByVendor(vendor) {
    return this.http.get<any>(
      this.config.apiUrl + 'Browse/GetPackagesByVendor/' + vendor
    );
  }
}
