import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "@YorbitWorkspace/global-environments";
@Injectable({
    providedIn: 'root'
  })
  export class ContentUploadPackageService {
    _baseURL: string;

    constructor(private _http: HttpClient,
      private _envSvc: EnvironmentService) {
      this._baseURL = this._envSvc.getEnvironment().apiUrl;
    }

  getTaxanomy() {
    return this._http.get<any>(this._baseURL+'Course/GetTaxonomy');
  }

  insertYorbitPackage(packageInsert : any, packageUniqueId : any, packageType: string) {
         return this._http.post<any>(this._baseURL + 'PackageInsertorUpdate/' + packageUniqueId + '/' + packageType,packageInsert);
  }


  }