import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class YorbitCoursesService {
  config: Ienvironment;
  baseUrl:string;
  CoursesList: any;
  AccountCoursesList:any;
  YorbitCoursesList:any;
  ProjectCoursesList:any;


  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.baseUrl = this.config.apiUrl;
    this.CoursesList = [];
    this.AccountCoursesList = [];
    this.YorbitCoursesList = [];
    this.ProjectCoursesList=[];
  } 

  getAllYorbitCourses(){
    return this.http
    .get<any>(this.baseUrl + 'GetAllCourses')
    .pipe(catchError((error: any) => throwError(()=> error.json())));
  }
  getAllYorbit101Courses(){
    return this.http
    .get<any>(this.baseUrl + 'GetAllYorbit101Courses')
    .pipe(catchError((error: any) => throwError(()=> error.json())));
  }

  getAllAccountCourses() {
    return this.http
    .get<any>(this.baseUrl + 'GetAllAccountCourses');
  }

  getAllProjectCourses() {
    return this.http
    .get<any>(this.baseUrl + 'GetAllProjectCourses')
    .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }

  getContentCourseType() {
    return this.http
      .get<any>(this.baseUrl + 'GetAllCourseType')
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }
}
