import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  Router
} from '@angular/router';
import { PssService } from '../app/pss/pss.service';
import { VideoPlayersService } from './shared/video-players/video-players.service';
@Injectable({ providedIn: 'root' })
export class CanActivateRouteChange implements CanActivate, CanActivateChild {
  constructor(
    private pssservice: PssService,
    private router: Router,
    private videoPlayersService: VideoPlayersService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return true;
    // //console.log('canActivate state', state);
    // //console.log('canActivate url', this.router.url);
    // //if session expiry pop up is open dont resolve route
    // if (
    //   localStorage.getItem('isSessionExpiryPopupOpen') != null &&
    //   parseInt(localStorage.getItem('isSessionExpiryPopupOpen')) == 1
    // ) {
    //   return false;
    // } else {
    //   // if there are any pending certification  date changes then return false
    //   if (this.pssservice.certDateChangesList.length != 0) {
    //     this.pssservice.updateCertDateChangesStatus({
    //       status: true,
    //       url: state.url
    //     });
    //     return false;
    //   } else {
    //     this.pssservice.updateCertDateChangesStatus({ status: false });
    //     return true;
    //   }
    // }
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // if there are any pending certification  date changes then return false
    //Child,route', route);
    ////console.log('canActivateChild state', state);
    ////console.log('canActivateChild url', this.router.url);

    if (
      localStorage.getItem('isSessionExpiryPopupOpen') != null &&
      parseInt(localStorage.getItem('isSessionExpiryPopupOpen')) == 1
    ) {
      return false;
    } else if (
      this.router.url.toLowerCase().indexOf('learningpath') != -1 &&
      this.router.url.toLowerCase().indexOf('videoid') != -1
    ) {
      this.videoPlayersService.updateVideoPauseStatus(true);
      return new Observable<boolean>(observer => {
        setTimeout(() => {
          observer.next(true);
          observer.complete();
        }, 1000);
      });
    } else {
      if (this.pssservice.certDateChangesList.length != 0) {
        this.pssservice.updateCertDateChangesStatus({
          status: true,
          url: state.url
        });
        return false;
      } else {
        this.pssservice.updateCertDateChangesStatus({ status: false });
        return true;
      }
    }
  }
}
