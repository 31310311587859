import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IntentListService {
  config: Ienvironment;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }

  getLearningIntentList() {
    return this.http
      .get<any>(
        this.config.apiUrl +
        'Course/GetLearningIntentList/learningpartner')
      .toPromise();
  }

}
