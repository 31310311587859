import { Component, NgModule, OnInit } from '@angular/core';
import { HMMAndSparkService } from './hmm-and-spark.service';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CharacterFilterPipe } from 'libs/pipes/src';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Globals } from '../globals';
import { SparkIntegrationService } from '../shared/services/spark-integration.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Component({
  selector: 'yorbit-hmm-and-spark',
  templateUrl: './hmm-and-spark.component.html',
  styleUrls: ['./hmm-and-spark.component.scss']
})

export class HMMAndSparkComponent implements OnInit {
  courses;
  displayShowMore = {};
  categories = ['Leading Yourself', 'Leading Others', 'Leading The Business'];
  roleList: fromRoleAccessStore.IroleData;
  showPreloader = true;
  showMore = {};
  bullets = new Array(2);
  sliderActiveIndex = 0;
  setIntervalSubscription = null;
  categorySelected = false;
  selectedCategory = '';
  characterFilterPipe = new CharacterFilterPipe();
  filteredList = [];
  searchModel = '';
  showSearchBar = false;
  coursesInsideLeadingYourself = [
    'BH_206',
    'BH_216',
    'BH_233',
    'BH_239',
    'BH_242',
    'BH_243'
  ];
  coursesInsideLeadingOthers = [
    'BH_208',
    'BH_213',
    'BH_214',
    'BH_215',
    'BH_217',
    'BH_219',
    'BH_221',
    'BH_224',
    'BH_225',
    'BH_226',
    'BH_228',
    'BH_230',
    'BH_232',
    'BH_236',
    'BH_240',
    'BH_241'
  ];
  coursesInsideLeadingTheBusiness = [
    'BH_203',
    'BH_204',
    'BH_205',
    'BH_207',
    'BH_209',
    'BH_210',
    'BH_211',
    'BH_212',
    'BH_218',
    'BH_220',
    'BH_222',
    'BH_223',
    'BH_227',
    'BH_229',
    'BH_231',
    'BH_234',
    'BH_235',
    'BH_237',
    'BH_238'
  ];
  IsC4nabv = true;

  constructor(
    private _HMMAndSparkService: HMMAndSparkService,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private router: Router,
    private _insightService: AppInsightService,
    private globals: Globals,
    private _sparkService: SparkIntegrationService,
    private mediaObserver: MediaObserver
  ) {
    this.courses = {};
    this.categories.forEach(val => {
      this.courses[val] = [];
      this.showMore[val] = true;
      this.displayShowMore[val] = false;
    });
    this.subscribeToCompetencyStatus();
    this.router.navigate(['']);
  }

  ngOnInit() {}

  navigateTo(route) {
    if (route == 'home') {
      this.router.navigate(['/']);
    }
  }
  setIntervalForSlider(duration) {
    if (this.setIntervalSubscription == null) {
      this.setIntervalSubscription = setInterval(() => {
        if (this.sliderActiveIndex == this.bullets.length - 1) {
          this.sliderActiveIndex = 0;
        } else {
          this.sliderActiveIndex++;
        }
        //////console.log(this.sliderActiveIndex);
      }, duration);
    }
  }
  // showNextSlide() {
  //   if (this.sliderActiveIndex == this.bullets.length - 1) {
  //     this.sliderActiveIndex = 0;
  //   } else {
  //     this.sliderActiveIndex++;
  //   }
  //   if (this.setIntervalSubscription != null) {
  //     clearInterval(this.setIntervalSubscription);
  //     this.setIntervalSubscription = null;
  //   } else {
  //     this.setIntervalForSlider(5000);
  //   }
  // }
  pauseSlider($event) {
    if (this.setIntervalSubscription != null) {
      clearInterval(this.setIntervalSubscription);
      this.setIntervalSubscription = null;
    }
  }
  showImageOfIndex(index) {
    this.sliderActiveIndex = index;
    if (this.setIntervalSubscription != null) {
      clearInterval(this.setIntervalSubscription);
      this.setIntervalSubscription = null;
      this.setIntervalForSlider(5000);
    }
  }
  // showPreviousSlide() {
  //   if (this.sliderActiveIndex == 0) {
  //     this.sliderActiveIndex = this.bullets.length - 1;
  //   } else {
  //     this.sliderActiveIndex--;
  //   }
  //   if (this.setIntervalSubscription != null) {
  //     clearInterval(this.setIntervalSubscription);
  //     this.setIntervalSubscription = null;
  //     this.setIntervalForSlider(5000);
  //   }
  // }
  redirectToSpark() {
    let payload = {
      action: 'HMM Spark Banner Clicked',
      location: 'HMM Spark Page',
      trackEvent: 'Harvard Manage Mentor Spark'
    };
    this.trackNewFeaturesInsights(payload);
    window.open(this.globals.spark_url, '_blank');
  }
  trackNewFeaturesInsights(payload) {
    const insightPayload = {
      Action: payload.action,
      Location: payload.location
    };
    this._insightService.trackEvent(payload.trackEvent, insightPayload);
  }
  redirectToHMM() {
    let payload = {
      action: 'HMM Banner Clicked',
      location: 'HMM Spark Page',
      trackEvent: 'Harvard Manage Mentor'
    };
    this.trackNewFeaturesInsights(payload);
    window.open(this.globals.HMM_url, '_blank');
  }
  selectCategory(category) {
    this.selectedCategory = category;
    this.categorySelected = true;
    this.filteredList = [...this.courses[this.selectedCategory]];
  }
  updateSearchModel() {
    if (this.searchModel.length > 0) {
      this.showSearchBar = true;
      this.filteredList = this.characterFilterPipe.transform(
        this.courses[this.selectedCategory],
        {
          property: 'Name',
          search: this.searchModel
        }
      );
    } else {
      this.showSearchBar = false;
      this.filteredList = [...this.courses[this.selectedCategory]];
    }
  }
  subscribeToCompetencyStatus() {
    this._sparkService.getCompStatus().subscribe(IsC4nabv => {
      this.IsC4nabv = IsC4nabv;
      //this.IsC4nabv = true;
      if (this.IsC4nabv) {
        this.userRoleAccessStore
          .select(fromRoleAccessStore.getRoleAccessList)
          .subscribe(roleList => {
            this.roleList = roleList;
            this._HMMAndSparkService
              .getCoursesByVendor('Harvard Business School')
              .subscribe(res => {
                //////console.log('res', res);
                if (res != null) {
                  let courses = res.packages;
                  courses.forEach(course => {
                    if (
                      this.coursesInsideLeadingYourself.indexOf(
                        course.UniqueId
                      ) != -1
                    ) {
                      this.courses['Leading Yourself'].push(course);
                    } else if (
                      this.coursesInsideLeadingOthers.indexOf(
                        course.UniqueId
                      ) != -1
                    ) {
                      this.courses['Leading Others'].push(course);
                    } else if (
                      this.coursesInsideLeadingTheBusiness.indexOf(
                        course.UniqueId
                      ) != -1
                    ) {
                      this.courses['Leading The Business'].push(course);
                    }
                  });
                  this.mediaObserver.media$.subscribe(
                    (media: MediaChange) => {
                      //////console.log('media change', media.mqAlias);
                      if (media.mqAlias === 'sm') {
                        this.categories.forEach(val => {
                          if (this.courses[val].length > 3) {
                            this.displayShowMore[val] = true;
                          } else {
                            this.displayShowMore[val] = false;
                          }
                        });
                      } else if (media.mqAlias == 'md') {
                        this.categories.forEach(val => {
                          if (this.courses[val].length > 4) {
                            this.displayShowMore[val] = true;
                          } else {
                            this.displayShowMore[val] = false;
                          }
                        });
                      } else if (media.mqAlias == 'lg') {
                        this.categories.forEach(val => {
                          if (this.courses[val].length > 5) {
                            this.displayShowMore[val] = true;
                          } else {
                            this.displayShowMore[val] = false;
                          }
                        });
                      }
                    },
                    error => {
                      ////console.log('media change error', error);
                    }
                  );
                  this.setIntervalForSlider(5000);
                  this.showPreloader = false;
                } else {
                  this.IsC4nabv = false;
                  this.showPreloader = false;
                }
              });
          });
      } else {
        this.showPreloader = false;
      }
    });
  }
}
