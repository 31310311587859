<div class="cnt">
  <img src="../../assets/images/riskbrigade/speaker-container-left-puzzle.png" class="left-puzzle">
      

  <div fxLayout="row" class="header center" fxLayoutAlign="space-between center" >
    <div class="section-header">Knowledge Hub</div>
    <div fxLayout="row"  fxLayoutAlign="end center"
      fxLayoutAlign.xs="start center">
      <div *ngFor="let item of ['all','video','article','Publication']" class="filter-tag"
        [class.selected-filter-tag]="selectedFilter === item" (click)="clickSelectedFilter(item)">
        <label>{{item}}</label>
      </div>
      <img class="reload-icon" (click)="clickSelectedFilter('all')"
        src="../../../../assets/images/DandI/ic-reload.png" alt="">
    </div>
  </div>
  <div class="center">
    <div class="tiles" fxLayout="row wrap" fxLayoutGap="15px 20px grid" *ngIf="dataList">
      <yorbit-knowledge-hub-tile *ngFor="let item of dataList" [item]="item"></yorbit-knowledge-hub-tile>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isDataLoading==true">
        <app-preloader></app-preloader>
      </div>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="selectedFilter=='all' && !isDataLoading && dataList.length==0">
        <span  class="no-data-found">
            Article(s), Publication(s) and Video(s) currently not available
        </span>
      </div>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="selectedFilter=='video' && !isDataLoading && dataList.length==0">
        <span  class="no-data-found">
            Video(s) currently not available
        </span>
      </div>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="selectedFilter=='article' && !isDataLoading && dataList.length==0">
        <span  class="no-data-found">
          Article(s) currently not available
        </span>
      </div>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="selectedFilter=='Publication' && !isDataLoading && dataList.length==0">
        <span  class="no-data-found">
          Publication(s) currently not available
        </span>
      </div>
      <div  fxLayoutAlign="center" class="show-more" (click)="getMoreRecords()" *ngIf="showMore==true">
        Show More
       </div>
    </div>
  </div>
  
  <img src="../../assets/images/riskbrigade/speaker-container-right-puzzle.png" class="right-puzzle"> 
</div>