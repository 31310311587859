<div class="banner">

    <div class="strip"  [ngClass.lt-md]="['lt-md']">
     
        <img class="title" [ngClass.lt-md]="['lt-md']" id="image"  src="/../../assets/images/behavioral branding banner/title_on_strip.png" width="100px">

     </div>

    

    <div class="body-part" fxLayout.lg="row" fxLayout.gt-sm="row"  fxLayout.lt-md="column"
        fxLayout.s-m="column" [ngClass.xs]="['xs']" [ngClass.gt-md]="['gt-md']"  fxLayoutGap.lt-md="20px" fxLayoutGap.gt-sm="40px">
        <div class="video" fxFlex.gt-sm="40" fxLayout="row"  fxLayoutAlign.gt-sm="end center" >
            <yorbit-o365-video-player fxFlex="100"  *ngIf="initializeVideo == true" [PlayerConfig]="videoConfig">
            </yorbit-o365-video-player>
        </div>

        <div fxFlex.gt-sm="50" class="details" fxLayout="column" fxLayoutGap.lt-md="10px" fxLayoutGap.gt-sm="17px" [ngClass.lt-md]="['lt-md']">
            <img class="logo" fxHide.sm="true" fxHide.xs="true"
                src="./../../assets/images/behavioral branding banner/Living Mindfully Thumbnail.jpg" width="130px">
                
            <img class="logo-mobile" fxHide.gt-sm="true" 
                src="./../../assets/images/behavioral branding banner/Living Mindfully Thumbnail_mobile_version.jpg">

            <span class="heading" [ngClass.lt-md]="['lt-md']">
                An exclusive initiative for LTIMindtree Associates by LTIMindtree Associates</span>
            <span class="paragraph" [ngClass.lt-md]="['lt-md']" >A holistic program to enable LTIMindtree Associates to live
                mindfully with self-
                awareness, resilience, focus and strength and achieve excellence– professionally & personally.</span>
            <button type="button" class="button" [ngClass.lt-md]="['lt-md']" (click)="openCourse()">GET STARTED</button>
        </div>
    </div>