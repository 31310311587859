<div fxFlex="100%" fxShow class="top-facilitators-parent-container" fxLayout="row" fxLayoutAlign="center stretch">
    <div *ngIf="isTopfacilitatorsLoading" fxFlexFill fxLayoutAlign="center center">
        <app-preloader></app-preloader>
    </div>
    <div class="top-facilitators-sub-container" *ngIf="!isTopfacilitatorsLoading" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row" class="top-facilitators-holder">
            <div fxLayout="row" class="left-heading-decoration">
                <hr class="left-heading-line">
                <div class="left-heading-dot"></div>
            </div>
            <div fxFlex.gt-xs="360px" fxFlex.xs="270px" class="title" fxLayoutAlign.xs="center center"
                fxLayoutAlign.gt-xs="center center" fxLayoutGap="15px" fxLayout.xs="column" fxLayout.gt-xs="row">
                TOP FACILITATORS
            </div>
            <div fxLayout="row" class="right-heading-decoration">
                <div class="right-heading-dot"></div>
                <hr class="right-heading-line">
            </div>
        </div>
        <div class="top-facilitators-tile-holder" fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="row" input type="text" class="top-facilitators-tiles" 
                *ngFor="let topfacilitator of topfacilitators | slice:numberOfTopFacilitators*sliderActiveIndex:numberOfTopFacilitators*sliderActiveIndex+numberOfTopFacilitators; let i = index">
                <div fxLayout="column">
                    <div class="top-facilitator-content" [ngClass.xs]="{'content-without-hour': topfacilitator.TotalHours == 0}" (click)="openInformation(topfacilitator, i)" fxLayout="column"
                        fxLayoutAlign="start center"
                        [ngClass]="{tileBgColor1: i == 0, tileBgColor2: i == 1, tileBgColor3: i == 2, tileBgColor4: i == 3, tileBgColor5: i == 4, tileBgColor6: i == 5 }">
                        <div fxLayout="row">
                            <div class="first-half" fxLayout="column" fxLayoutAlign="center center">
                                <div class="image-div">
                                    <img class="image" *ngIf="topfacilitator.Image != null"
                                        [src]="topfacilitator.Image" />
                                </div>
                                <div class="mobile-name-mid" fxHide fxShow.xs fxLayout="row">
                                    <div class="hoursDiv" *ngIf="topfacilitator.TotalHours > 0" fxLayout="column">
                                        <div class="hours-value">
                                            {{topfacilitator.TotalHours}}
                                        </div>
                                        <div class="hours-text">
                                            Total hours spent in coaching
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="second-half" fxLayout="column">
                                <div fxLayout="row">
                                    <div class="nameAndMid" fxLayout="column">
                                        <div class="facilitator-name">
                                            {{topfacilitator.Name}}
                                        </div>
                                        <div class="facilitator-mid">
                                            ({{topfacilitator.MID}})
                                        </div>
                                    </div>
                                    <div *ngIf="topfacilitator.TotalHours > 0" class="hoursDiv" fxLayout="row"
                                        fxHide.xs>
                                        <div class="hours-value">
                                            {{topfacilitator.TotalHours}}
                                        </div>
                                        <div class="hours-text">
                                            Total hours spent in coaching
                                        </div>
                                    </div>
                                </div>
                                <div class="citation-text">
                                    {{topfacilitator.Citation}}
                                </div>
                            </div>
                            <div class="side-strip"
                                [ngClass]="{sideStripColor1: i == 0, sideStripColor2: i == 1, sideStripColor3: i == 2, sideStripColor4: i == 3, sideStripColor5: i == 4, sideStripColor6: i == 5 }"
                                [ngClass.xs]="{sideStripColor1: i == 0 && sliderActiveIndex % 2 == 0, sideStripColor2: i == 1 && sliderActiveIndex % 2 == 0, sideStripColor3: i == 2 && sliderActiveIndex % 2 == 0, sideStripColor4: i == 0 && sliderActiveIndex % 2 == 1, sideStripColor5: i == 1 && sliderActiveIndex % 2 == 1, sideStripColor6: i == 2 && sliderActiveIndex % 2 == 1 }">
                            </div>
                        </div>
                        <div class="bottom-strip"
                            [ngClass.gt-xs]="{bottomStripColor1: i == 0, bottomStripColor2: i == 1, bottomStripColor3: i == 2, bottomStripColor4: i == 3, bottomStripColor5: i == 4, bottomStripColor6: i == 5 }"
                            [ngClass.xs]="{bottomStripColor1: i == 0 && sliderActiveIndex % 2 == 0, bottomStripColor2: i == 1 && sliderActiveIndex % 2 == 0, bottomStripColor3: i == 2 && sliderActiveIndex % 2 == 0, bottomStripColor4: i == 0 && sliderActiveIndex % 2 == 1, bottomStripColor5: i == 1 && sliderActiveIndex % 2 == 1, bottomStripColor6: i == 2 && sliderActiveIndex % 2 == 1 }">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="80px" fxLayoutAlign="center center" *ngIf="bullets.length>1">
            <div fxHide fxShow.xs class="spriteIcons leftIcon" (click)="showPreviousSlide()"
                [ngClass]="{greyedOut: sliderActiveIndex == 0}">
            </div>
            <div class="bullets" *ngFor="let slide of bullets; let index = index"
                [ngClass]="{ active: index == sliderActiveIndex }" (click)="showNextSlideInDesktopView(index)">
            </div>
            <div fxHide fxShow.xs class="spriteIcons rightIcon" (click)="showNextSlide()"
                [ngClass]="{greyedOut: sliderActiveIndex == bullets.length-1}">
            </div>
        </div>
    </div>
</div>
