import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileSaverService } from 'ngx-filesaver';
import { PssFiltersService } from '../../pss-filters/pss-filters.service';
import * as XLSX from 'xlsx';
import { PssEvaluationService } from '../../pss-evaluation/pss-evaluation.service';

@Component({
  selector: 'yorbit-pss-download-popup',
  templateUrl: './pss-download-popup.component.html',
  styleUrls: ['./pss-download-popup.component.scss']
})
export class PssDownloadPopupComponent implements OnInit {
  courseDownload: {
    message: any;
    subMessage: string;
    leftButton: string;
    RightButton: string;
    disableProceed: boolean;
    actionMessage: string;
    fileName: string;
    couresRequestMessage: string;
  };
  compSubscription: any;
  filteredResult: any[];
  result: any[];
  evaluationRequestFileName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PssDownloadPopupComponent>,
    private _pssFiltersService: PssFiltersService,
    private _FileSaverService: FileSaverService,
    private _PssEvaluationService: PssEvaluationService
  ) {}

  ngOnInit() {
    this.setupPopup();
    this.compSubscription={};
  }

  setupPopup() {
    let message;
    let courseInfoMessage;
    if (this.data.getAll) {
      const courseMessage = "Selecting 'Yes' will result in downloading all course requests.";
      const certificateMessage =
        "Selecting 'Yes' will result in downloading all certificate requests including those which are pending and actioned.";
        const ExternalcertificateMessage =
        "Selecting 'Yes' will result in downloading all Externalcertificate requests including those which are pending and actioned.";
      const evaluationMessage =
        "Selecting 'Yes' will result in downloading all evaluation requests";
      if (this.data.selectedTab == 'course-requests') {
        courseInfoMessage = "Please note all Cancelled and Completed requests prior to 1st Jan 2021 as well as Rejected and Resigned Associate's course requests data are excluded.";
        message = courseMessage;
      } else if (this.data.selectedTab.indexOf('certification') != -1) {
        message = certificateMessage;
      } else if (this.data.selectedTab.indexOf('externalrequest') != -1) {
        message = ExternalcertificateMessage;
      } else if (this.data.selectedTab == 'evaluation-requests') {
        message = evaluationMessage;
      }
    } else {
      const courseMessage =
        "Selecting 'Yes' will result in downloading the filtered course requests.";
      const certificateMessage =
        "Selecting 'Yes' will result in downloading the filtered certificate requests.";
        const ExternalcertificateMessage =
        "Selecting 'Yes' will result in downloading the filtered Externalcertificate requests.";
      const evaluationMessage =
        "Selecting 'Yes' will result in downloading the filtered evaluation requests";
      if (this.data.selectedTab == 'course-requests') {
        message = courseMessage;
      } else if (this.data.selectedTab.indexOf('certification') != -1) {
        message = certificateMessage;
      } else if (this.data.selectedTab.indexOf('externalrequest') != -1) {
        message = ExternalcertificateMessage;
      } else if (this.data.selectedTab == 'evaluation-requests') {
        message = evaluationMessage;
      }
    }

    const date = new Date();
    let fileNameSetup = '';
    if (this.data.selectedTab == 'course-requests') {
      fileNameSetup = "201/301's";
    } else if (this.data.selectedTab.indexOf('certification') != -1) {
      fileNameSetup = 'Certificate Requests';
    } else if (this.data.selectedTab.indexOf('externalrequest') != -1) {
      fileNameSetup = 'externalrequest';
    } else if (this.data.selectedTab == 'evaluation-requests') {
      fileNameSetup = 'Evaluation Requests';
    }
    const fileNameForAll = 'All ' + fileNameSetup + date;
    const fileNameForFiltered = date + '';

    const fileName = this.data.getAll ? fileNameForAll : fileNameForFiltered;
    this.evaluationRequestFileName = fileName;
    this.courseDownload = {
      message: message,
      subMessage: 'Are you sure you want to proceed?',
      leftButton: 'Yes',
      RightButton: 'Cancel',
      disableProceed: false,
      actionMessage: '',
      fileName: fileName,
      couresRequestMessage: courseInfoMessage,
    };
  }

  proceed() {
    this.courseDownload.actionMessage = 'Processing request...';
    this.courseDownload.disableProceed = true;

    if (this.data.getAll) {
      this.executeAllDownload();
    } else {
      this.executeFilterDownload();
    }
  }

  executeAllDownload() {
    if (this.data.selectedTab == 'course-requests') {
      this._pssFiltersService.downloadCourseFullList().subscribe(res => {
        ////console.log('res', res);
        const ws = XLSX.utils.json_to_sheet(res); //converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // save to file
        XLSX.writeFile(wb, this.courseDownload.fileName + '.csv');
        this.courseDownload.disableProceed = false;
        this.courseDownload.actionMessage = 'Download Completed.';
        this.compSubscription = this.dialogRef.backdropClick().subscribe(() => {
          // Close the dialog
          ////console.log('backdrop click');
          this.dialogRef.close();
        });
      });
      /* .catch(error => {
          this.errorDownload(error);
        }); */
    } else if (this.data.selectedTab.indexOf('certification') != -1) {
      this._pssFiltersService
        .downloadCertificateFullList()
        .then(res => {
          this.downloadExcel(res);
        })
        .catch(error => {
          this.errorDownload(error);
        });
    }else if (this.data.selectedTab.indexOf('externalrequest') != -1) {
      this._pssFiltersService
        .downloadExternalCertificateFullList()
        .then(res => {
          this.downloadExcel(res);
        })
        .catch(error => {
          this.errorDownload(error);
        });
    }
     else if (this.data.selectedTab == 'evaluation-requests') {
      this._PssEvaluationService.getAllDataForDownloading().subscribe(
        data => {
          this.result = data as any[];
          //////console.log('Datasss', this.result, data);
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.result);//converts a DOM TABLE element to a worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, this.evaluationRequestFileName+'.xlsx');
          this.courseDownload.disableProceed = false;
          this.courseDownload.actionMessage = 'Download Completed.';
          this.compSubscription = this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close();
          });
        });
    }
  }
  executeFilterDownload() {
    if (this.data.selectedTab == 'course-requests') {
      this._pssFiltersService
        .downloadCourseForRange(this.data.pssFilterRange)
        .subscribe(res => {
          ////console.log('res', res);
          const ws = XLSX.utils.json_to_sheet(res); //converts a DOM TABLE element to a worksheet
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

          // save to file
          XLSX.writeFile(wb, this.courseDownload.fileName + '.csv');
          this.courseDownload.disableProceed = false;
          this.courseDownload.actionMessage = 'Download Completed.';
          this.compSubscription = this.dialogRef.backdropClick().subscribe(() => {
            // Close the dialog
            ////console.log('backdrop click');
            this.dialogRef.close();
          });
        });
    } else if (this.data.selectedTab.indexOf('certification') != -1) {
      this._pssFiltersService
        .downloadCertificateForRange(
          this.data.subSelection,
          this.data.pssFilterRange
        )
        .then(res => {
          this.downloadExcel(res);
        })
        .catch(error => {
          this.errorDownload(error);
        });
    } else if (this.data.selectedTab.indexOf('externalrequest') != -1) {
      this._pssFiltersService
        .downloadExternalcertificateForRange(
          this.data.subSelection,
          this.data.pssFilterRange
        )
        .then(res => {
          this.downloadExcel(res);
        })
        .catch(error => {
          this.errorDownload(error);
        });
    } else if (this.data.selectedTab == 'evaluation-requests') {
      this._PssEvaluationService.getFilteredDataForDownloading().subscribe(
        data => {
          this.filteredResult = data as any[];
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filteredResult);//converts a DOM TABLE element to a worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, this.evaluationRequestFileName+'.xlsx');
          this.courseDownload.disableProceed = false;
          this.courseDownload.actionMessage = 'Download Completed.';
          this.compSubscription = this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close();
          });
        });
    }
  }
  downloadExcel(res) {
    const blob = new Blob([<any>res.body], {
      type: 'application/octet-stream'
    });

    this._FileSaverService.save(blob, this.courseDownload.fileName + '.xlsx');
    this.courseDownload.disableProceed = false;
    this.courseDownload.actionMessage = 'Download Completed.';
    //this.closePopup();
    this.compSubscription = this.dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      ////console.log('backdrop click');
      this.dialogRef.close();
    });
  }
  errorDownload(error) {
    this.courseDownload.disableProceed = false;
    if(this.data.selectedTab != 'externalrequest') {
      const errorMessage =
        this.data.selectedTab == 'course-requests'
          ? 'Download Failed.'
          : 'Download failed or there is no data available in internal certificate details.';
      this.courseDownload.actionMessage = errorMessage;
    }
    else {
      const errorMessage1 =
        this.data.selectedTab == 'externalrequest'
          ? 'Download Failed.'
          : 'Download failed or there is no data available in External certificate details.';
      this.courseDownload.actionMessage = errorMessage1;
    }
    this.compSubscription = this.dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      ////console.log('backdrop click');
      this.dialogRef.close();
    });
  }

  closePopup() {
    this.dialogRef.close();
  }
}
