
<mat-dialog-content class="lpList">
    <div class="headerTitle">My Development Plan</div>
  <div class="closeAdd">
      <button mat-button mat-icon-button (click)="closeIDP()">
          <mat-icon>close</mat-icon>
        </button>
  </div>
  <div class="idpMessage">
    {{data.message}}
  </div>
<div *ngIf="!data.isValid" class="reroute" (click)="goBackToIdpPortal()">
  <div>Go back to My Development Plan Portal</div>
</div>
  <div
  *ngIf="data.isValid"
    class="action-btn"
  >
    <button (click)="closeIDP()">Ok</button>

  </div>
</mat-dialog-content>
