import { Component, OnInit } from '@angular/core';
import { PowerbiService } from './powerbi.service';
import { AdalService } from 'adal-angular4';
import { NgxPowerBiService } from 'ngx-powerbi';
import { Embed } from 'embed';
import { OrderByPropertyPipe } from '@YorbitWorkspace/pipes';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'yorbit-powerbi',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.scss']
})
export class PowerbiComponent implements OnInit {
  allowedGroupList: string[];
  groupList: any;
  selectedDashboard: any;
  pbiContainerElement: HTMLElement;
  ngxPowerBiService: NgxPowerBiService;
  embedElement: Embed;
  reportTitle: string;
  reportPopup: boolean;
  dashboardSlidermode: string;
  errorMessage: string;
  errorOccured: boolean;

  constructor(
    private powerbiService: PowerbiService,
    private adalService: AdalService
  ) {
    this.ngxPowerBiService = new NgxPowerBiService();
  }

  ngOnInit() {
    this.dashboardSlidermode = 'side';
    this.errorOccured = false;
    this.reportPopup = false;

    this.groupList = [];
    this.allowedGroupList = [
      'bf235cde-e1a4-4fa0-87fb-15e3cf84dd8a',
      '01cdabfb-9695-4dd7-b361-8cb50ea4d516'
    ];
    this.initReports();
  }

  screenResize(width) {
    if (width < 600) this.dashboardSlidermode = 'over';
  }

  initReports() {
    this.powerbiService.getGroup().then((groupResponse: any) => {
      if (groupResponse.value.length) {
        groupResponse.value.forEach(element => {
          if (-1 !== this.allowedGroupList.indexOf(element.id)) {
            this.groupList.push(element);
          }
        });
      }

      if (0 === this.groupList.length) {
        this.TriggerError('noGroup');
      } else {
        const filterPipe = new OrderByPropertyPipe();
        this.groupList = filterPipe.transform(this.groupList, {
          property: 'name'
        });
      }

      this.groupList.forEach(group => {
        this.powerbiService
          .getPowerBiByGroup(group.id, 'dashboards')
          .then((dashBoardResponse: any) => {
            const filterPipe = new OrderByPropertyPipe();
            group.dashboard = filterPipe.transform(dashBoardResponse.value, {
              property: 'displayName'
            });

            //vmReport.groupList[key].dashboard.sort(nameSort);

            if (group.id === this.groupList[0].id) {
              this.loadDashboard(this.groupList[0].dashboard[0]);
            }
            // if (group.id === this.groupList[0].id) {
            //   this.loadDashboard(this.groupList[0].dashboard[0]);
            // }
          })
          .catch(function(response) {
            if (group.id === this.groupList[0].id) {
              this.TriggerError('dashboards');
            }
          });
      });
    });
  }

  loadDashboard(dashboard) {
    this.errorOccured = false;

    this.adalService
      .acquireToken('https://analysis.windows.net/powerbi/api')
      .subscribe(accesstoken => {
        const config = this.getConfig(
          'dashboard',
          dashboard.embedUrl,
          dashboard.id,
          accesstoken
        );
        // Get a reference to the embedded dashboard HTML element
        const container = document.getElementById('pbi-container');
        while (container.hasChildNodes()) {
          container.removeChild(container.firstChild);
        }

        const dashboardContainer = document.createElement('div');
        dashboardContainer.id = dashboard.id;
        dashboardContainer.style.width = '100%';
        dashboardContainer.style.height = '100%';

        container.appendChild(dashboardContainer);

        this.embedElement = this.ngxPowerBiService.embed(
          dashboardContainer,
          config
        );
        this.selectedDashboard = dashboard.id;

        this.embedElement.off('error');
        this.embedElement.on('error', event => {
          this.TriggerError('dashboard');
          this.embedElement.off('error');
        });

        this.embedElement.off('tileClicked');
        this.embedElement.on('tileClicked', event => {
          this.loadReport(event.detail);
        });
      });
  }

  loadReport(data) {
    this.reportTitle = 'Getting Report...';
    this.reportPopup = true;
    const idObj = this.getParameter(data.reportEmbedUrl);

    this.powerbiService
      .getPowerBiByGroup(idObj.groupId, 'reports')
      .then((response: any) => {
        const ReportTile = response.value.filter(
          element => element.id === idObj.reportId
        )[0];
        this.reportTitle = ReportTile.name;
        this.adalService
          .acquireToken('https://analysis.windows.net/powerbi/api')
          .subscribe(accesstoken => {
            const config = this.getConfig(
              'report',
              ReportTile.embedUrl,
              ReportTile.id,
              accesstoken
            );
            config.pageName = data.pageName;

            const container = document.getElementById('reportEmbed');
            while (container.hasChildNodes()) {
              container.removeChild(container.firstChild);
            }

            const reportContainer = document.createElement('div');
            reportContainer.id = idObj.reportId;
            reportContainer.style.width = '100%';
            reportContainer.style.height = '100%';
            container.appendChild(reportContainer);

            //var reportContainer = document.getElementById('reportEmbed')

            const report = this.ngxPowerBiService.embed(
              reportContainer,
              config
            );
            report.off('loaded');
            report.on('loaded', function() {
              this.reportElement = report;
            });
          });
      });
  }

  closeReportPopup() {
    this.reportPopup = false;
  }

  getParameter(url) {
    const regexGroup = new RegExp(/[?&]groupId(=([^&#]*)|&|#|$)/);
    const regexReport = new RegExp(/[?&]reportId(=([^&#]*)|&|#|$)/);
    const obj = {
      groupId: '',
      reportId: ''
    };

    let result = regexGroup.exec(url);
    obj.groupId = result[2];
    result = regexReport.exec(url);
    obj.reportId = result[2];
    return obj;
  }
  getConfig(contentType, embedUrl, id, accesstoken) {
    const config: any = {
      type: contentType,
      embedUrl: embedUrl,
      accessToken: accesstoken
    };
    if ('dashboard' === contentType) {
      //Dashboard
      config.dashboardId = id;
    } else {
      //Report
      config.id = id;
    }

    return config;
  }
  TriggerError(error) {
    switch (error) {
      case 'noGroup':
        this.errorMessage = 'You do not belong to any associated report Group';
        this.errorOccured = true;
        break;

      case 'dashboard':
        this.errorMessage = 'Error loading Dashboard';
        this.errorOccured = true;
        break;

      default:
        break;
    }
  }
}
