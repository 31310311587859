import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromRolebasedAction from '../actions/rolebased-store.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RolebasedStoreService } from '../../rolebased-store.service';


@Injectable()
export class RolebasedEffect {
  constructor(
    private actions$: Actions,
    private rolebasedStoreService: RolebasedStoreService
  ) {}

  @Effect()
  getRolebasedDetails$ = this.actions$.pipe(
  ofType(fromRolebasedAction.ROLEBASED_GET_DETAILS),
      switchMap((action: fromRolebasedAction.RolebasedGetStatus) => {
        return this.rolebasedStoreService
          .getRBLPackageofUser()
          .pipe(
            map(RolebasedDetails => {
              return new fromRolebasedAction.RolebasedGetStatusSuccess(
                RolebasedDetails
              );
            }),
            catchError(error =>
              of(new fromRolebasedAction.RolebasedGetStatusError(error))
            )
          );
      })
    );
}
