<div
  fxLayout="column"
  fxLayoutAlign="center center"
  [ngStyle.gt-xs]="{ padding: '2% 10%' }"
  class="terms-of-use"
>
  <div class="terms-of-use-header" fxLayoutAlign="start center">
    Terms of Use
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" class="terms-of-use-info">
    <div class="disclaimer">
      {{ disclaimer }}
    </div>
    <div class="termsOfUse">
      {{ termsOfUse }}
    </div>
    <div
      *ngFor="let item of termsOfUseBulletin; let i = index"
      [ngStyle.xs]="{ padding: '5px 5px !important' }"
      class="termsBullet" fxLayout="row"
    >
      <!-- <div fxFlex="5">{{ i + 1 + '.' }}</div> -->
      <div class="termsIndex" fxFlex="8">
        <span>{{i+1 + ". "}}</span>
      </div>
      <div class="termsDefinition" fxFlex="92">
      {{ item.A }}
      </div>
    </div>
  </div>
</div>
