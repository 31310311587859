import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { CertDateChangeAlertComponent } from '../cert-date-change-alert/cert-date-change-alert.component';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { ArrayPropertyFilterPipe } from '@YorbitWorkspace/pipes';
import { Subscriber } from 'rxjs';
import { CdmService } from '../../cdm/cdm.service';
import { SystemService } from '../../shared/services/system.service';
import { WINDOW } from '../../shared/services/window.service';
import { PssStatusMessageComponent } from '../pss-status-message/pss-status-message.component';
import { PssService } from '../pss.service';
import { PssFiltersService } from '../pss-filters/pss-filters.service';
import { CoursePropAndStatusesFilterService } from '../pss-filters/course-prop-and-statuses-filter/course-prop-and-statuses-filter.service';
import { Input } from '@angular/core';
import { identifierName } from '@angular/compiler';
//import { type } from 'os';
//import { isNullOrUndefined } from 'util';

@Component({
  selector: 'yorbit-external-certificate',
  templateUrl: './external-certificate.component.html',
  styleUrls: ['./external-certificate.component.scss']
})

export class ExternalCertificateComponent implements OnInit, OnDestroy {


  
  startDate: { value: any; date: any; month: any; apiFormat: any };
  endDateForm: FormControl;
  startDateForm: FormControl;
  usersListLoaded: boolean;
  selectedTab: string;
  endDate: { value: any; date: any; month: any; apiFormat: any };
  filters: any;
  requests: Array<any>;
  masterRequests: Array<any>;
  incorrectMIdFormat: boolean;
  noDataFound:boolean;
  courseNames: Array<string>;
  requestsLoading: boolean;
  showSearchErrorByName: boolean;
  incorrectReqIDFormat: boolean;
  routeParamsSub:any;
  orderBy: any;
  searched: boolean;
  showCertificationDate: any[];
  showCertificationScore: any[];
  certificationScore: any;
  ScoreError:{}[];
  ScoreErrorMsg:{}[];
  requestsLimit: number;
  mixedFormatEntries: boolean;
  searchModel: any;
  pssFiltersSub: any;
  downloadAllSelected: boolean;
  SearchRequestId: [];
  showPreloaders:boolean;
  SearchMid: Array<string>;
  requestsIndex: number;
  totalRequestsLength: number;
  approvalsComponentSubscriptions: any;
  loadedRequestsLength: number;
  requestFilters: any;
  anyCourseFiltersSelected: boolean;
  requestsLoadedSuccessfully: boolean;
  searchStatus: boolean;
  arrayPropertyFilter: any;
  requestActionsPayload: any;
  certificationComponentSubscriptions: any;
  suggestionList: any;
  startingIndex: number;
  startingNumber: number;
  pageIndex: number;
  endingNumber: number;
  contentIndex: number;
  selectedOption: any = 10;
  filtersApplied: boolean;
  isPreviousButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  isRecordsLoading: boolean = false;
  count: number = 0;
  ExternalcertRequestsFilters: any;
  showPreloader: boolean;
  pageSizeOptions: number[];
  searchBy: string; 
  userRole: string;
  public seletedSpoc: string;
  learningSpocList: string[] = [];
  learningSpocMIDs: string[] = [];
  disableOtherRequest = false;
  paginateFilter: {
    pageSizeLimit: number;
    pageLength: number;
    pageIndex: number;
    PaginationContentIndex: number;
  };
  maxDate = new Date();
  minDate = new Date(2015, 0, 1);
  tileSetup: {
    textAreaInput: any[];
    requestInProgress: any[];
    acceptButtonTitle: any[];
    denyButtonTitle: any[];
    holdButtonTitle: any[];

  };
  filteredSkillList: any;
  ExternalcertificationTabSubscription: any;
  ExternalcertificationActionedAPISub: any;
  ScoreValidate:boolean=true;
  constructor(
    private cdmService: CdmService,
    private _graphSvc: GraphDataService,
    private sanitizer: DomSanitizer,
    private _systemSvc: SystemService,
    private _pssService: PssService,
    private pssFiltersService: PssFiltersService,
    private dialog: MatDialog,
    private _GraphDataService: GraphDataService,
    private activatedRoute: ActivatedRoute,
    private environment: EnvironmentService,
    @Inject(WINDOW) private _window: any
  ) {
    this.userRole = '';
    this.requests = [];
    this.requestsLoading = true;
    this.courseNames = [];
    this.requestsLoadedSuccessfully = false;
    this.searchStatus = false;
    this.selectedTab = '';
    this.showPreloader = false;
    this.showCertificationDate = [];
    this.showCertificationScore = [];
    this.contentIndex = 0;
    this.ExternalcertificationTabSubscription = {};
    this.ExternalcertificationActionedAPISub = {};
    
    this.arrayPropertyFilter = new ArrayPropertyFilterPipe();
    // this.requestActionsPayload = {
    //   ItemsIds: [],
    //   courseId: [],
    //   Comments: null
    // };
    this.requestActionsPayload = [];
    this.startingIndex = 0;
    this.requestsLimit = 20;
    this.requestsIndex = 0;
    this.pssFiltersSub = {};
    this.totalRequestsLength = 0;
    this.anyCourseFiltersSelected = false;
    this.filtersApplied = false;
    this.loadedRequestsLength = 0;
    this.routeParamsSub = {};
    this.ExternalcertRequestsFilters = {};
    this.approvalsComponentSubscriptions = {};
    this.ExternalcertificationTabSubscription = {};
    this.showPreloader= true;
    this.noDataFound=false;
    this.tileSetup = {
      textAreaInput: [],
      requestInProgress: [],
      acceptButtonTitle: [],
      denyButtonTitle: [],
      holdButtonTitle:[]

    };
    this.ScoreErrorMsg = [];
    this.ScoreError = [];
  }
  ngOnInit(): void {
    this.subscribeToRouterParams();
    //subscribe to the filter options  and call api to get results on every update
    this.subscribeTExternaloCertRequestsFilters();
    //subscribe to results loaded status
    this.subscribeToLoadResultsStatus();
  }

  subscribeToRouterParams() {
    this.ExternalcertificationTabSubscription.routeParamsSub = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.selectedTab = params['type'];
        this.contentIndex = 0;
        this.requests = [];
        this.showPreloader = false;
        this.filtersApplied = false;
        this.ExternalcertRequestsFilters = {};
        this.courseNames = [];
        this.disableOtherRequest = false;
        this.showCertificationDate = [];
        this.showCertificationScore = [];
        this.tileSetup = {
          textAreaInput: [],
          requestInProgress: [],
          acceptButtonTitle: [],
          denyButtonTitle: [],
          holdButtonTitle:[]
        };
      }
    );
  }
  subscribeTExternaloCertRequestsFilters() {
    this.ExternalcertificationTabSubscription.certFiltersSub = this.pssFiltersService
      .getExternalCertificateRequestsFilters()
      .subscribe((res: any) => {
        //res wil have filter options and url parameters
        if (res != null) {
          //filters applied
          this.requests = [];
          this.filtersApplied = true;
          //show preloader
          this.showPreloader = true;
          this.ExternalcertRequestsFilters = res.filters;
          //fetch data from api
          this.getRequests(res.filters, res.urlParams, res.subSelection);
        }
      });
  }
  subscribeToLoadResultsStatus() {
    this.ExternalcertificationTabSubscription.canResultsLoadStatus = this._pssService
      .getResultsLoadedStatus()
      .subscribe((res: any) => {
        if (res.count == 0) {
          this.contentIndex = 0;
          this.requests = [];
          this.showPreloader = false;
          this.ExternalcertRequestsFilters = {};
          this.courseNames = [];
          this.disableOtherRequest = false;
          this.showCertificationDate = [];
          this.showCertificationScore = [];
          this.tileSetup = {
            textAreaInput: [],
            requestInProgress: [],
            acceptButtonTitle: [],
            denyButtonTitle: [],
            holdButtonTitle:[]
          };
        }
        if (!res.status) {
          this.filtersApplied = false;
          this.cancelActionedReqAPICallsInProgresss();
        }
      });
  }
  getRequests(postdata, urlParams, subSelection) {
    if (this.selectedTab == 'pending') {
      this._pssService
        .getPssExternalCertificatePendingItems(postdata, urlParams)
        .then((res: any) => {
          //hide preloader and show the data
          this.showPreloader = false;
          this._pssService.updateResultsLoadedStatus({
            status: true,
            count: res.count
          });

          //update total pagination count as the length of the paginator
          this.pssFiltersService.updateTotalPaginationCount(res.count);
          //content index
          this.courseNames = [];
          this.contentIndex = urlParams.count;
          this.requests = res.PSSCertificateRequestItemListForExternal;
          this.getGraphDetailsOfUsers();
        });
    } else if (this.selectedTab == 'actioned') {
      //cancel any pending apicalls in accepted or denied tabs
      this.ExternalcertificationActionedAPISub[
        subSelection
      ] = this._pssService
        .getPssExternalCertificateActionedItems(postdata, urlParams, subSelection)
        .subscribe((res: any) => {
          //hide preloader and show the data
          this._pssService.updateResultsLoadedStatus({
            status: true,
            count: res.count
          });
          this.showPreloader = false;
          //update total pagination count as the length of the paginator
          this.pssFiltersService.updateTotalPaginationCount(res.count);
          //content index
          this.contentIndex = urlParams.count;
          this.requests = res.PSSCertificateRequestItemListForExternal;
          this.getGraphDetailsOfUsers();
        });
    }
  }
  cancelActionedReqAPICallsInProgresss() {
    for (let subscriberKey in this.ExternalcertificationActionedAPISub) {
      let subscriber = this.ExternalcertificationActionedAPISub[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  getGraphDetailsOfUsers() {
    // getting the graph details of user and setting it to the model
    this.requests.forEach((value, key) => {
      if (!this.ExternalcertRequestsFilters.IsArrangeByDate) {
        if (this.courseNames.indexOf(value.CourseName) == -1) {
          this.courseNames.push(value.CourseName);
          value.showCourseName = true;
        } else {
          value.showCourseName = false;
        }
      } else {
        value.showCourseName = false;
      }
      this.ExternalcertificationTabSubscription.getUserNameFromMIDSub = this._GraphDataService
        .getUserName(value.MID)
        .subscribe(responseUserName => {
          value.Name = responseUserName.displayName;
        });
      if (null !== value.LastmodifiedBy) {
        this.ExternalcertificationTabSubscription.getLastmodifiedByNameSub = this._GraphDataService
          .getUserName(value.LastmodifiedBy)
          .subscribe(responseUserName => {
            value.ActionedByName = responseUserName.displayName;
          });
      }
      //set initial values for requests
      this.showCertificationDate[value.RequestId] = true;
      this.showCertificationScore[value.RequestId] = true;
      value.UserGivenCertDate = value.Certification_CompletionDate;
      this.requests[key].Certification_CompletionDateForAPI =
        new Date(value.UserGivenCertDate).getMonth() +
        1 +
        '-' +
        new Date(value.UserGivenCertDate).getDate() +
        '-' +
        new Date(value.UserGivenCertDate).getFullYear();
      value.IsCertificationDateChanged = false;
      this.tileSetup.textAreaInput[value.RequestId] = [];
      this.tileSetup.requestInProgress[value.RequestId] = false;
      this.tileSetup.acceptButtonTitle[value.RequestId] = 'accept';
      this.tileSetup.denyButtonTitle[value.RequestId] = 'deny';
      this.tileSetup.holdButtonTitle[value.RequestId] = 'hold';
      this.showCertificationScore[value.RequestId] = true;
      value.UserGivenCertDate = value.LearnerScore;
    });
  }
  
 // valueSelected(selectedSpoc: string) {
   // selectedSpoc = this.learningSpocMIDs[this.learningSpocList.findIndex(x=>x == selectedSpoc)];
   // this.seletedSpoc = selectedSpoc;
   // this.certificationComponentSubscriptions.subscribeToFilters = this.cdmService
     // .getRequestsFilters()
     // .subscribe(filterObj => {
      //  this.startingIndex = parseInt(filterObj['index']);
       // this.requests = [];
       // this.requestsLoading = true;
       // this.requestsLoadedSuccessfully = false;
       // this._pssService
        //  .getExternalCertificateApproval(
        //    this.selectedOption,
        //    filterObj['index'],
        //    filterObj['nextOrPrevious'],
        //    this.selectedTab,
        //    selectedSpoc
        //  )
         // .then((requests: any) => {
         //   if (requests == null) {
         //     requests = [];
         //   }
          //  this.learningSpocList = [];
          //  this.requestsLoadedSuccessfully = true;
          //  this.requestsLoading = false;
         //   this.requests = requests.ExternalCertificateList;
         //   this.learningSpocList = requests.SpocList;
           
         //   this.getSocialDetailsForRequests(this.requests);
          //  this.totalRequestsLength = requests.RequestCount;
          //  if (this.totalRequestsLength < this.requestsLimit) {
          //    this.loadedRequestsLength = this.totalRequestsLength;
          //  }
          //  this.startingNumber = 1;
          //  this.endingNumber = +this.selectedOption;
         //   if (this.endingNumber > this.totalRequestsLength)
          //    this.endingNumber = this.totalRequestsLength;
        //    this.isRecordsLoading = false;
        //    this.isNavigateButtonsDisabled();
       //   })
         // .catch(err => {
         //   this.requestsLoadedSuccessfully = false;
         //   this.requestsLoading = false;
        //    this.requests = [];
       //   });
    //  });

    // this.requests = this.masterRequests.filter(item => item.LearningOPMSPOC === selectedSpoc);
    // this.totalRequestsLength = this.requests.length;
    //         if (this.totalRequestsLength < this.requestsLimit) {
    //           this.loadedRequestsLength = this.totalRequestsLength;
    //         }
    //         this.startingNumber = 1;
    //         this.endingNumber = +this.selectedOption;
    //         if (this.endingNumber > this.totalRequestsLength)
    //           this.endingNumber = this.totalRequestsLength;
  //}
  clearPayload() {
    // this.requestActionsPayload = {
    //   ItemsIds: [],
    //   courseId: [],
    //   Comments: null
    // };
    this.requestActionsPayload = [];
  }
  //onNextRequestsBtnClick() {
  //  const index = this.requestsIndex + this.requestsLimit;
   // this.cdmService.updateRequestsFilters({
   //   ...this.requestFilters,
    //  nextOrPrevious: 'next',
    //  index: index
   // });
 // }
 // onPreviousRequestsBtnClick() {
  //  const index = this.requestsIndex - this.requestsLimit;
   // this.cdmService.updateRequestsFilters({
   //   ...this.requestFilters,
   //   nextOrPrevious: 'previous',
   //   index: index
   // });
//  }

 // changeSubselection(value) {
 //   this.selectedTab = value;
 //   this.learningSpocList = [];
   // this.getCertificationData();

 // }
//  getCertificationData() {
  //  this.isRecordsLoading = true;
  //  this.certificationComponentSubscriptions = this.cdmService
   //   .getRequestsFilters()
    //  .subscribe(filterObj => {
     //   this.startingIndex = parseInt(filterObj['index']);
      //  this.requests = [];
       // this.requestsLoading = true;
       // this.requestsLoadedSuccessfully = false;
       // this._pssService
         // .getExternalCertificateApproval(
         //   this.selectedOption,
         //   filterObj['index'],
        //    filterObj['nextOrPrevious'],
        //    this.selectedTab
        //  )
       //   .then((requests: any) => {
         //   if (requests == null) {
         //     requests = [];
         //   }
        //    this.learningSpocList = [];
          //  this.requestsLoadedSuccessfully = true;
         //   this.requestsLoading = false;
          //  this.requests = requests.ExternalCertificateList;
          //  this.learningSpocList = requests.SpocList;  
           // this.learningSpocMIDs = requests.SpocListMids;
            
           // this.getSocialDetailsForRequests(this.requests);
           // this.totalRequestsLength = requests.RequestCount;
           // if (this.totalRequestsLength < this.requestsLimit) {
           //   this.loadedRequestsLength = this.totalRequestsLength;
          //  }
           // this.startingNumber = 1;
          //  this.endingNumber = +this.selectedOption;
           // if (this.endingNumber > this.totalRequestsLength)
           //   this.endingNumber = this.totalRequestsLength;
           // this.isRecordsLoading = false;
           // this.isNavigateButtonsDisabled();
         // })
         // .catch(err => {
         //   this.requestsLoadedSuccessfully = false;
           // this.requestsLoading = false;
           // this.requests = [];
          //});
     // });
 // }

 // getNextOrPrevious(value: string) {
  //  this.isRecordsLoading = true;
   // if (value == "next") {
   //   this.count = +this.selectedOption + this.count;
   //   this.startingNumber = +this.selectedOption + this.startingNumber;
    //  this.endingNumber = Number(this.selectedOption) + Number(this.endingNumber);
    //  if (this.endingNumber > this.totalRequestsLength)
    //    this.endingNumber = this.totalRequestsLength;
     // this.isNavigateButtonsDisabled();
   // }
   // else if (value == "previous") {
    //  this.count = this.count - this.selectedOption;
    //  this.startingNumber = this.startingNumber - this.selectedOption;
     // this.endingNumber = +this.selectedOption + this.startingNumber - 1;
     // this.isNavigateButtonsDisabled();
   // }
 // }

//  onChange(value) {

  //  this.isRecordsLoading = true;
    // console.log('newValue', this.seletedSpoc);
   // this.selectedOption = value;
   // this.startingNumber = 1;
   // this.endingNumber = this.selectedOption;
   // this.count = 0;
 // }

 /// isNavigateButtonsDisabled() {
  //  if (this.endingNumber > this.totalRequestsLength)
  //    this.endingNumber = this.totalRequestsLength;
  //  if (this.endingNumber >= this.totalRequestsLength)
   //   this.isNextButtonDisabled = true;
   // else
  //    this.isNextButtonDisabled = false;

 //   if (this.startingNumber == 1)
 //     this.isPreviousButtonDisabled = true;
 //   else
 //     this.isPreviousButtonDisabled = false;
 // }

  //getSocialDetailsForRequests(requests) {
    //requests.forEach(request => {
      //request.comments = '';
      //this.tileSetup.textAreaInput[request.RequestId] = [];
      //this.tileSetup.requestInProgress[request.RequestId] = false;
      //this.tileSetup.acceptButtonTitle[request.RequestId] = 'accept';
      //this.tileSetup.denyButtonTitle[request.RequestId] = 'deny';
      //this.tileSetup.holdButtonTitle[request.RequestId] = 'hold';
      //if (request.UserId) {
        //request.RequestedBy = request.UserId;
      //}
      //if (request.Filepath) {
        //request.fileAzuredbPath = request.Filepath;
      //}
      //if (request.CertificationDate) {
        //request.Certification_dt = request.CertificationDate;
      //}
      //if (request.ExpiryDate) {
        //if (request.ExpiryDate == '0001-01-01T00:00:00') {
          //request.IsCheck_noexpiry = true;
        //} else {
          //request.IsCheck_noexpiry = false;
        //}
        //request.Expiry_Dt = request.ExpiryDate;
      //}
      //this.getGraphDetails(request);
      //to check the status of pre-requisite course
      /* just empty the array */
   // });
  //}

  //getGraphDetails(request) {
    //this.certificationComponentSubscriptions.graphUserImageSub = this._graphSvc
      //.getUserImage(request.RequestedBy)
      //.subscribe(
        //data => {
          //request.userImage = this.createImageURL(data);
        //},
        //error => {
          //request.userImage = null;
        //}
      //);
    //this.certificationComponentSubscriptions.graphUserNameSub = this._graphSvc
      //.getUserName(request.RequestedBy)
      //.subscribe(
        //data => {
          //request.Name = data.displayName;
        //},
        //error => {
          //request.Name = request.UserId;
        //}
      //);
    //if (request.AssignedBy !== '' && request.AssignedBy != null) {
      //this.certificationComponentSubscriptions.graphAssignerNameSub = this._graphSvc
       //.getUserName(request.AssignedBy)
       //.subscribe(
         // response => {
           // request.AssignerName = response.displayName;
          //},
          //err => {
            //request.AssignerName = request.AssignedBy;
          //}
        //);
    //}
  //}
  //createImageURL(imageBlob) {
    //const imageURL = this._window.URL.createObjectURL(imageBlob);
    //return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  //}
  approveRequest(request) {
    request.comments = this.tileSetup.textAreaInput[request.RequestId];
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.acceptButtonTitle[request.RequestId] = 'Accepting...';
    this.clearPayload();

    if (request.CourseId != 0 && request.CourseId != '') {
      // this.requestActionsPayload.ItemsIds.push(request.RequestId);
      // this.requestActionsPayload.Comments = request.comments;
      // this.requestActionsPayload.courseId = [request.CourseId];
      let payload = {
        RequestId: request.RequestId,
        Comments: request.comments,
        IsMindOnBenchOnApproval: false,
        ApprovalIntentId: 0,
        ApprovalIntentComments: '',
        CourseId: request.CourseId,
        PSSScore:request.LearnerScore
      };
      this.requestActionsPayload.push(payload);
      request.approveInProgress = true;
      let isCertificate = true;
      //api to approve
      this.cdmService
        .approveRequests(this.requestActionsPayload, isCertificate,this.userRole)
        .then(response => {
          this.disableOtherRequest = false;
          request.approveInProgress = false;
          request.approved = true;
          if (response[request.RequestId][0].CertificateStatus == "Approved") {
            this.tileSetup.acceptButtonTitle[request.RequestId] = 'Accepted';
          } else {
            this.tileSetup.acceptButtonTitle[request.RequestId] = 'Failed';
            this.tileSetup.requestInProgress[request.RequestId] = false;
          }
        })
        .catch(err => {
          request.approveInProgress = false;
          request.approved = false;
        });
    } else {
      //show error msg
      request.showWarning = true;
    }
  }
  denyRequest(request) {
    request.comments = this.tileSetup.textAreaInput[request.RequestId];
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.denyButtonTitle[request.RequestId] = 'Denying...';
    this.clearPayload();
    if (
      request.comments.length != 0 &&
      request.CourseId != 0 &&
      request.CourseId != ''
    ) {
      // this.requestActionsPayload.ItemsIds.push(request.RequestId);
      // this.requestActionsPayload.Comments = request.comments;
      // this.requestActionsPayload.courseId = [request.CourseId];

      let payload = {
        RequestId: request.RequestId,
        Comments: request.comments,
        IsMindOnBenchOnApproval: false,
        ApprovalIntentId: 0,
        ApprovalIntentComments: '',
        CourseId: request.CourseId,
        PSSScore:request.LearnerScore
      };
      this.requestActionsPayload.push(payload);
      request.denyInProgress = true;
      let isCertificate = true;

      //call api to deny
      this.cdmService
        .denyRequests(this.requestActionsPayload, isCertificate,this.userRole)
        .then(response => {
          this.disableOtherRequest = false;
          request.denyInProgress = false;
          request.denied = true;
          if (response[request.RequestId][0].CertificateStatus == "Rejected") {
            this.tileSetup.denyButtonTitle[request.RequestId] = 'Denied';
          } else {
            this.tileSetup.denyButtonTitle[request.RequestId] = 'Failed';
            this.tileSetup.requestInProgress[request.RequestId] = false;
          }
        })
        .catch(err => {
          request.denyInProgress = false;
          request.denied = false;
        });
    } else {
      //show error msg
      request.showWarning = true;
    }
  }
  holdRequest(request) {
    request.comments = this.tileSetup.textAreaInput[request.RequestId];
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.holdButtonTitle[request.RequestId] = 'Holding...';
    this.clearPayload();
    if (
      request.comments.length != 0 &&
      request.CourseId != 0 &&
      request.CourseId != ''
    ) {
      // this.requestActionsPayload.ItemsIds.push(request.RequestId);
      // this.requestActionsPayload.Comments = request.comments;
      // this.requestActionsPayload.courseId = [request.CourseId];

      let payload = {
        RequestId: request.RequestId,
        Comments: request.comments,
        IsMindOnBenchOnApproval: false,
        ApprovalIntentId: 0,
        ApprovalIntentComments: '',
        CourseId: request.CourseId
      };
      this.requestActionsPayload.push(payload);

      request.holdInProgress = true;
      let isCertificate = true;
      //api to onhold
      this.cdmService
        .holdRequests(this.requestActionsPayload, isCertificate)
        .then(response => {
          this.disableOtherRequest = false;
          request.holdInProgress = false;
          request.onHold = true;
          if (response[request.RequestId][0].CertificateStatus == "OnHold") {
            this.tileSetup.holdButtonTitle[request.RequestId] = 'On Hold';
          } else {
            this.tileSetup.holdButtonTitle[request.RequestId] = 'Failed';
            this.tileSetup.requestInProgress[request.RequestId] = false;
          }
        })
        .catch(err => {
          request.holdInProgress = false;
          request.onHold = false;
        });
    } else {
      //show error msg
      request.showWarning = true;
    }
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.certificationComponentSubscriptions) {
      let subscriber = this.certificationComponentSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  //certification
  downloadCertificate(previewLink) {
    //let link = "https://yorbitdev.blob.core.windows.net/certificates/M1035893_04-07-2017_746_Edureka_Certificate_for_NodeJS.pdf";
    if (previewLink != null) {
      this._systemSvc.getBlobSasKey(previewLink)
        .then((response: any) => {
          try {
            var decodedUri = this._systemSvc.getAtoBDecode(response);
            window.open(decodedUri, '_blank');
          }
          catch { }
        });
    }
    //this._window.open(previewLink, '_blank');

    // win.document.write( "<img src=" + previewLink + "height='100' width='100' alt='You will see a preview of your certificate here'/>" );
    //  win.document.write( "<embed src=" + previewLink + " width='600' height='500' alt='pdf' pluginspage='http://www.adobe.com/products/acrobat/readstep2.html'>" );
    // win.document.write( "<object width='400' height='400' data=" + 'https://yorbitdev.blob.core.windows.net/certificates/M1035893_04-07-2017_Edureka_Certificate_for_NodeJS.pdf' + "></object>");
    //win.document.write( "<iframe src=app/assets/img/" + link + "style='width: 100%; height: 100%;' frameborder='0' scrolling='no'>Sample</iframe>" );
  }


  onDateChangeEvent(event, dateType) {
    this.setDateValue(event.value, dateType);
    this.filters = {
      ...this.filters,
      StartDate: this.startDate.apiFormat,
      EndDate: this.endDate.apiFormat
    };
  }
  setDateValue(value, dateType) {
    if ('start' === dateType) {
      this.startDate = {
        value: value,
        date: value.getDate(),
        month: value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:
          value.getMonth() +
          1 +
          '-' +
          value.getDate() +
          '-' +
          value.getFullYear()
      };
    } else if ('end' === dateType) {
      let nextDay = new Date(value);
      nextDay.setDate(value.getDate() + 1);
      this.endDate = {
        value: value,
        date: value.getDate(),
        month: value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:
          nextDay.getMonth() +
          1 +
          '-' +
          nextDay.getDate() +
          '-' +
          nextDay.getFullYear()
      };
    }
  }

  accept(request) {
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.acceptButtonTitle[request.RequestId] = 'accepting...';
    if (
      null === this.tileSetup.textAreaInput[request.RequestId] ||
      this.tileSetup.textAreaInput[request.RequestId].length === 0
    ) {
      this.tileSetup.textAreaInput[request.RequestId] = null;
    }
    const obj = {
      RequestId: request.RequestId,
      AcceptOrDeny: 'ACCEPTED',
      CourseId: request.CourseId,
      CourseUniqueId: request.CourseUniqueId,
      Remarks: this.tileSetup.textAreaInput[request.RequestId],
      LearnerId: request.MID,
      Expertise: request.Expertise,
      Certi_CompletionDate: request.Certification_CompletionDateForAPI,
      Learner_Score:request.LearnerScore,
      PSS_Score:request.PSSScore || request.LearnerScore
    };
    this._pssService
      .takeActionOnPendingRequest(obj)
      .then(response => {
        this.disableOtherRequest = false;
        if (response === 'ACCEPTED') {
          this._pssService.delReqIdFromUnsavedList(request.RequestId);
          this.tileSetup.acceptButtonTitle[request.RequestId] = 'accepted';
        } else {
          //Failed popup
          // $state.go( "NoAutoCloseModal.popupTmpl", {
          //     'selectedContext': "PSSInternalCertPostActionMessage",
          //     data: {
          //         "message": response
          //     }
          // } );
          this.tileSetup.acceptButtonTitle[request.RequestId] = 'Failed';
          this.tileSetup.requestInProgress[request.RequestId] = false;
          this.disableOtherRequest = false;
          this.openMessagePopup(response);
        }
      })
      .catch(function (error) {
        this.tileSetup.acceptButtonTitle[request.RequestId] = 'Failed';
        this.tileSetup.requestInProgress[request.RequestId] = false;
        this.disableOtherRequest = false;
        this.openMessagePopup(error);
      });
  }

  openMessagePopup(message) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //pssMessageDialogContainer
    dialogConfig.data = {
      message: message
    };
    this.dialog.open(PssStatusMessageComponent, dialogConfig);
  }
  deny(request) {
    this.tileSetup.requestInProgress[request.RequestId] = true;
    this.disableOtherRequest = true;
    this.tileSetup.denyButtonTitle[request.RequestId] = 'denying...';
    const obj = {
      RequestId: request.RequestId,
      AcceptOrDeny: 'DENIED',
      CourseId: request.CourseId,
      CourseUniqueId: request.CourseUniqueId,
      Remarks: this.tileSetup.textAreaInput[request.RequestId],
      LearnerId: request.MID,
      Expertise: request.Expertise,
      Certi_CompletionDate: request.Certification_CompletionDateForAPI,
      Learner_Score:request.LearnerScore,
      PSS_Score:request.PSSScore || request.LearnerScore
    };
    this._pssService
      .takeActionOnPendingRequest(obj)
      .then(response => {
        this.disableOtherRequest = false;
        if (response === 'DENIED') {
          this._pssService.delReqIdFromUnsavedList(request.RequestId);
          this.tileSetup.denyButtonTitle[request.RequestId] = 'denied';
        } else {
          this.tileSetup.denyButtonTitle[request.RequestId] = 'Failed';
          this.tileSetup.requestInProgress[request.RequestId] = false;
          this.disableOtherRequest = false;
          this.openMessagePopup(response);
        }
      })
      .catch(function (error) {
        this.tileSetup.denyButtonTitle[request.RequestId] = 'Failed';
        this.disableOtherRequest = false;
        this.tileSetup.requestInProgress[request.RequestId] = false;
        this.openMessagePopup(error);
      });
  }

  editScore(requestInProgress, requestId, id) {
    if(this.requests[id].LearnerScore == "NULL") {
      this.requests[id].LearnerScore = "";
    }
    if(!requestInProgress[requestId]) {
      this.showCertificationScore[requestId] = false;
    }
  }
  scoreChange(requestId, index) {

    this.showCertificationScore[requestId] = true;

    let regexp = /^(?!0+$)\d{1,3}$/;
    let result = false;
    if(this.requests[index].LearnerScore != null){
     result = regexp.test(this.requests[index].LearnerScore);
    }
    
    if (result && !Number.isNaN(this.requests[index].LearnerScore)) {
      this.requests[index].PSSScore = this.requests[index].LearnerScore;
      this.ScoreError[requestId] = false;
      this.ScoreErrorMsg[requestId] = '';
    } else if(this.requests[index].LearnerScore){
      this.ScoreError[requestId] = true;
      this.ScoreErrorMsg[requestId] =
        'Please enter a valid score';
        console.log("error occured");
    }
  }
//   ScoreValidation(val){
//   let regexp = /^\d+(\.\d{1,2})?$/;
//   let result = regexp.test(val);
//   if (result) {
//     this.ScoreError = false;
//     this.ScoreErrorMsg = '';
//   } else {
//     this.ScoreError = true;
//     this.ScoreErrorMsg =
//       'Please enter a valid score';
//       console.log("error occured");
//   }
// }
}
