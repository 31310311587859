import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdpComponent } from '../idp.component';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';
import { Router } from '@angular/router';

@Component({
  selector: 'yorbit-idp-popup',
  templateUrl: './idp-popup.component.html',
  styleUrls: ['./idp-popup.component.scss']
})
export class IdpPopupComponent implements OnInit {
  config:Ienvironment;

  constructor(
    public dialogRef: MatDialogRef<IdpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _envSvc: EnvironmentService,
    private _route: Router,
  ) { }

  ngOnInit() {
    this.config=this._envSvc.getEnvironment();
  }
  closeIDP() {
    this._route.navigate(['/']);
    this.dialogRef.close();
  }
  goBackToIdpPortal(){
  }
}
