import { Injectable } from '@angular/core';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RoleBasedLearningStoreService {
  config: Ienvironment;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }

  getFamilyRoleLevelTaxonomy() {
    return this.http.get(
      this.config.apiUrl + 'RoleBasedLearning/GetFamilyRoleLevelTaxonomy'
    );
  }

  getEmployeeRoleTagging() {
    return this.http.get(
      this.config.apiUrl + 'RoleBasedLearning/GetEmployeeRoleTagging'
    );
  }

  getSpecializationGroupDetails(FRLSUniqueId){
    return this.http.get(
      this.config.apiUrl + 'RoleBasedLearning/GetSpecializationGroupDetails/' + FRLSUniqueId
    );
  }

  reportIssueInRBL(formData) {
    const URL = 'ReportIssueInRBL';
    return this.http.post<any>(this.config.apiUrl + URL, formData);
  }

  getRoleTaggingEmpData(){
    return this.http.get(
      this.config.apiUrl+'RoleBasedLearning/GetRoleTaggingEmpData'
    );
  }
}
