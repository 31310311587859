import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector
} from '@ngrx/store';

import * as fromGraphReducer from './graph.reducer';
import { IUserGraphProfile } from '../../graph.interface';

// export interface IGraphState {
//   Graph: fromGraphReducer.IGraphReducerState;
// }

// export const graphReducers: ActionReducerMap<IGraphState> = {
//   Graph: fromGraphReducer.reducer
// };

// export const GraphReducerCollection = fromGraphReducer.reducer;

// export const reducers: ActionReducerMap<IGraphState> = {
//   Graph: fromGraphReducer.reducer
// };

export const getGraphState = createFeatureSelector<fromGraphReducer.IGraphReducerState>('Graph');

export const getGraphReducerState = createSelector(
  getGraphState,
  (state: fromGraphReducer.IGraphReducerState) => state
);

export const getGraphUserName = createSelector(
  getGraphReducerState,
  fromGraphReducer.getName
);

export const getGraphUserFirstName = createSelector(
  getGraphReducerState,
  fromGraphReducer.getFirstName
);

export const getGraphUserJobTitle = createSelector(
  getGraphReducerState,
  fromGraphReducer.getJobTitle
);

export const getGraphUserImage = createSelector(
  getGraphReducerState,
  fromGraphReducer.getUserImage
);

export * from './graph.reducer';
