import { Action } from '@ngrx/store';


export const GET_GRAPH_USER_NAME = '[Graph] Get Name';
export const GET_GRAPH_USER_NAME_SUCCESS  = '[Graph] Get Name Success';
export const GET_GRAPH_USER_NAME_FAIL = '[Graph] Get Name Error';

export const GET_GRAPH_USER_FIRST_NAME = '[Graph] Get First Name';
export const GET_GRAPH_USER_FIRST_NAME_SUCCESS = '[Graph] Get First Name Success';
export const GET_GRAPH_USER_FIRST_NAME_FAIL = '[Graph] Get First Name Error';

export const GET_GRAPH_USER_JOB_TITLE = '[Graph] Get Job Title';
export const GET_GRAPH_USER_JOB_TITLE_SUCCESS = '[Graph] Get Job Title Success';
export const GET_GRAPH_USER_JOB_TITLE_FAIL = '[Graph] Get Job Title Fail';

export const GET_GRAPH_USER_IMAGE = '[Graph] Get Image';
export const GET_GRAPH_USER_IMAGE_SUCCESS = '[Graph] Get Image Success';
export const GET_GRAPH_USER_IMAGE_FAIL = '[Graph] Get Image Fail';

export class GetGraphUserName implements Action{
  readonly type = GET_GRAPH_USER_NAME;
  constructor(public payload:any){}
}

export class GetGraphUserNameSuccess implements Action{
  readonly type = GET_GRAPH_USER_NAME_SUCCESS;
  constructor(public payload:any){}
}

export class GetGraphUserNameFail implements Action{
  readonly type = GET_GRAPH_USER_NAME_FAIL;
  constructor(public payload:any){}
}

export class GetGraphUserFirstName implements Action{
  readonly type = GET_GRAPH_USER_FIRST_NAME;
  constructor(public payload:any){}
}

export class GetGraphUserFirstNameSuccess implements Action{
  readonly type = GET_GRAPH_USER_FIRST_NAME_SUCCESS;
  constructor(public payload:any){
  }
}

export class GetGraphUserFirstNameFail implements Action{
  readonly type = GET_GRAPH_USER_FIRST_NAME_FAIL;
  constructor(public payload:any){
  }
}

export class GetGraphUserJobTitle implements Action{
  readonly type = GET_GRAPH_USER_JOB_TITLE;
  constructor(public payload:any){}
}

export class GetGraphUserJobTitleSuccess implements Action{
  readonly type = GET_GRAPH_USER_JOB_TITLE_SUCCESS;
  constructor(public payload:any){}
}

export class GetGraphUserJobTitleFail implements Action{
  readonly type = GET_GRAPH_USER_JOB_TITLE_FAIL;
  constructor(public payload:any){}
}

export class GetGraphUserImage implements Action{
  readonly type = GET_GRAPH_USER_IMAGE;
  constructor(public payload:any){}
}

export class GetGraphUserImageSuccess implements Action{
  readonly type = GET_GRAPH_USER_IMAGE_SUCCESS;
  constructor(public payload:any){}
}
export class GetGraphUserImageFail implements Action{
  readonly type = GET_GRAPH_USER_IMAGE_FAIL;
  constructor(public payload:any){}
}


export type GraphActions =
 GetGraphUserName
 | GetGraphUserNameSuccess
 | GetGraphUserNameFail
 | GetGraphUserFirstName
 | GetGraphUserFirstNameSuccess
 | GetGraphUserFirstNameFail
 | GetGraphUserJobTitle
 | GetGraphUserJobTitleSuccess
 | GetGraphUserJobTitleFail
 | GetGraphUserImage
 | GetGraphUserImageSuccess
 | GetGraphUserImageFail
