<mat-form-field class="pssSearch">
  <input
    matInput [(ngModel)]="search"
    placeholder="Search by Name Or PSID"
    value=""
    (keyup)="searchUser(search,$event)"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="userSelectHandler($event)">
      <mat-option *ngFor="let user of suggestionList" [value]="user">
        <yorbit-share-user-profile [user]='user' (click)="getMid(user)"></yorbit-share-user-profile>
      </mat-option>
    </mat-autocomplete>
  <button
  mat-button
  *ngIf="!searched"
  matSuffix
  mat-icon-button
  aria-label="search"
  (click)="searchResult(search)"
>
<mat-icon>search</mat-icon>
</button>
  <button
    mat-button
    *ngIf="searched"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="onClear()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>



