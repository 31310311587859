import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateLpComponent } from './create-lp/create-lp.component';
import {CreateLpService} from './create-lp.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CreateLpPopupComponent } from './create-lp-popup/create-lp-popup.component';
@NgModule({
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,

    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CreateLpComponent, CreateLpPopupComponent],
  providers:[CreateLpService]
})
export class CreateLpModule { }
