<mat-dialog-content>
  <div class="closePopup">
    <button mat-button mat-icon-button (click)="closeUpdatePopup()" [disabled]="actionActivated" [ngClass]="{ disabled: actionActivated}">
        <mat-icon>close</mat-icon>
      </button>
  </div>
  <div class="header">
  <span *ngIf="'approve' == this.data.type && !actionCompleted">Launch Course</span>
  <span *ngIf="'approve' != this.data.type && !actionCompleted">Decline Course Upload</span>
  <span *ngIf="actionCompleted && actionSuccess">Information</span>
  <span *ngIf="actionCompleted && !actionSuccess">Information</span>
  </div>
  <mat-divider></mat-divider>

  <div class="spacePadding textCenterAlign">
    <div *ngIf="!actionActivated && !actionCompleted">
      <div class="popupMessage">
        {{popupText.initText}}
      </div>
      <div *ngIf="'approve'!=data.type">
        <div ngClass="fieldLabel">
          <label>
                  Remarks
                  <span class="mandatory-field-indicator">*</span>
                </label>
        </div>
        <div class="text-area-desc topics-covered">
          <div>
            <textarea [(ngModel)]="remarksText" class="" placeholder="Enter remarks"></textarea>
          </div>
        </div>
        <div *ngIf="(''==remarksText.trim())&&('approve'!=data.type)" class="error-msg">
          {{''==remarksText.trim()?'Remarks cannot be empty':''}}
        </div>

      </div>

      <div *ngIf="'approve'==data.type">

        <mat-form-field class="searchUserContainer">
          <input
            type="text"
            placeholder="Find Associate for Spoc"
            aria-label="Number"
            matInput
            [formControl]="ShareControl"
            [matAutocomplete]="auto"
            [(ngModel)]="searchUserInput"
            (keyup)="searchUser(searchUserInput, $event)"
            [errorStateMatcher]="validateUserAdded"
          />
          <!--
            <mat-error> {{(noSuggestionError)?"": UserAddedValidateError }} </mat-error>
          -->

          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displayEmpty"
            (optionSelected)="userSelectHandler($event)"

          >
            <mat-option *ngFor="let user of suggestionList" [value]="user">
              <yorbit-share-user-profile
                [user]="user"
                (click)="addUserFromSuggestionList(user)"
              ></yorbit-share-user-profile>
            </mat-option>
          </mat-autocomplete>

          <button
            mat-button
            *ngIf="searchUserInput"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="resetUserInput()"
            class="deteleButtonNoStyle"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div *ngIf="noSuggestionError" class="error-msg">Please enter a valid PSID or name</div>

        <div class="userListContainer" *ngIf="!(0 >= userListToShare.length)">
          <div class="userList" *ngFor="let user of userListToShare" fxFlexLayout="row" fxLayoutAlign="center center">
            <diV fxFlex="80">
              <yorbit-share-user-profile
                class="userProfile"
                [user]="user"
              ></yorbit-share-user-profile>
            </diV>
            <div fxFlex="20">
                <button
                class="removeUser"
                mat-button
                mat-icon-button
                (click)="removeUserFromSuggestionList(user)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <!--

          <div ngClass="fieldLabel">
            <label>
              SPOC
              <span class="mandatory-field-indicator">*</span>
              :
            </label>
            <input [(ngModel)]="spocMid" class="spocInput" placeholder="Enter SPOC MID" />
            <div *ngIf="(''==remarksText.trim())&&('approve'!=data.type)||('approve'==data.type&&(''==spocMid.trim()||!midRegex.test(spocMid.trim())))"
            class="error-msg">
            {{''==spocMid.trim()?'SPOC MID cannot be empty':!midRegex.test(spocMid.trim())?'Please enter MID in the format of ‘Mxxxxxxx’.':''}}
          </div>
        </div>
      -->
      </div>

      <div class="action-btn-holder">
        <button class="action-button" [disabled]="(''==remarksText.trim())&&('approve'!=data.type)||('approve'==data.type&&(''==spocMid.trim()))"
          title="{{
                  ('approve'!=data.type)?(''==remarksText.trim()?'Remark cannot be empty':'Click to Proceed'):
                  ((''==spocMid.trim())?'Spoc field cannot be empty':(!midRegex.test(spocMid)?'Please enter mid only':'Click to Proceed'))
                }}" [ngClass]="{mouseDisabled: (''==remarksText.trim()&&('approve'!=data.type))||('approve'==data.type&&(''==spocMid.trim()))}"
          (click)="proceedAction()">Proceed</button>
        <button class="action-button space-left" (click)="closeUpdatePopup()">Cancel</button>
      </div>
    </div>

    <div *ngIf="actionActivated && !actionCompleted">
      <app-preloader></app-preloader>
    </div>
    <div *ngIf="actionCompleted">
      <div class="popupMessage">
        {{(actionSuccess)?popupText.successText:popupText.errorText}}
      </div>
      <div class="action-btn-holder">
        <button class="action-button space-left" (click)="closeUpdatePopup()">OK</button>
      </div>
    </div>
  </div>

</mat-dialog-content>
