import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationsInitState } from './store/notifications.model';
import {
  INotifications,
  NotificationsCollection,
  NotificationEntities
} from './store/notifications.interface';
import * as NotificationsStoreObj from './store';
import * as AuthStoreObj from '@YorbitWorkspace/auth';
import * as notificationsActions from './store/actions';
import { Subscription } from 'rxjs';
import { BellNotificationService } from './bell-notifications.service';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { BlockedPushNotificationsComponent } from '../../push-notifications/blocked-push-notifications/blocked-push-notifications.component';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { OrderbyDatePipe } from '@YorbitWorkspace/pipes';
import { PushNotificationService } from '../../push-notifications/push-notifications.service';
@Component({
  selector: 'yorbit-bell-notifications',
  templateUrl: './bell-notifications.component.html',
  styleUrls: ['./bell-notifications.component.scss']
})
export class BellNotificationsComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
  userMID: string;
  stateSubscription: Subscription;
  notificationsState: INotifications;
  notificaitonEntities: NotificationEntities;
  notificationsCollection: NotificationsCollection[]; //notifications list from server
  notificationsShownToUser: NotificationsCollection[]; //show 10 notifications at a time to user
  newNotificationsCount: number;
  notificationsPointer: number;
  notificationCategories: Array<string>;
  isPushNotificationsBlocked: boolean;
  showPreloader: boolean;
  contentLoadedSuccessfully: boolean;
  constructor(
    private store: Store<INotifications>,
    private bellNotificationService: BellNotificationService,
    private _InsightService: AppInsightService,
    private dialog: MatDialog,
    private orderbyDatePipe: OrderbyDatePipe,
    private pushService: PushNotificationService
  ) {
    this.newNotificationsCount = 0;
    this.notificationsPointer = 0;
    this.notificationsShownToUser = [];
    this.notificationCategories = [
      '201',
      '301',
      'Assignment',
      '101',
      'Recommendation',
      'Social',
      'Generic',
      'IP compliance',
      'CloudLab',
      "Manager's feedback",
      'ALM/PLM access management',
      'PSS workflows',
      'AO Nomination'
    ];
    this.isPushNotificationsBlocked = false;
    this.showPreloader = true;
    this.contentLoadedSuccessfully = false;
  }
  getNewNotification() {
    this.newNotificationsCount = 0;
    if (!this.notificationsState.NewNotificationsLoadedSuccesfully) {
      this.store.dispatch({
        type: 'GET_NEW_NOTIFICATIONS',
        payload: this.userMID
      });
    }
  }
  getOldNotifications() {
    this.store.dispatch({
      type: 'GET_OLD_NOTIFICATIONS',
      payload: this.userMID
    });
  }
  ngOnInit() {
    this.getPushNotificationStatus();
    this.subscribeToNotificationsStore();
    this.getUserMID();
    //check push subscription
  }
  getPushNotificationStatus() {
    this.pushService.getPushSubscription.subscribe(token => {
      //////console.log('push subscription token bell comp', token);
      if (token == 'blocked') {
        this.isPushNotificationsBlocked = true;
      } else {
        this.isPushNotificationsBlocked = false;
      }
    });
  }
  getUserMID() {
    this.store.select(AuthStoreObj.getAuthUserName).subscribe(res => {
      if (res != undefined && res != null) {
        this.userMID = res.split('@')[0];
        this.getNewBellNotificationsCount(this.userMID);
      }
    });
  }
  subscribeToNotificationsStore() {
    this.stateSubscription = this.store
      .select(NotificationsStoreObj.getNotificationsState)
      .subscribe((res: any) => {
        this.notificationsState = res;
        this.showPreloader = res.NewNotificationsLoading;
        this.contentLoadedSuccessfully = res.NewNotificationsLoadedSuccesfully;
      });
    let notificaitonsEntitiesSubscription = this.store
      .select(NotificationsStoreObj.getAllNotificationEntities)
      .subscribe(res => {
        this.notificaitonEntities = res;
      });
    let notificaitonsListSubscription = this.store
      .select(NotificationsStoreObj.getAllNotificationsList)
      .subscribe(res => {
        this.notificationsCollection = this.orderbyDatePipe.transform(
          res,
          'CreatedDate'
        );
        if (
          (this.notificationsState.NewNotificationsLoadedSuccesfully &&
            this.notificationsCollection.length != 0 &&
            this.notificationsPointer == 0) ||
          this.notificationsState.OldNotificationsLoadedSuccesfully
        ) {
          this.loadNextTenNotifications();
        }
      });
    this.stateSubscription.add(notificaitonsEntitiesSubscription)
    this.stateSubscription.add(notificaitonsListSubscription);
  }
  getNewBellNotificationsCount(mid) {
    let getNewNotificationsountSub = this.bellNotificationService
      .getBellNotificationsCount(mid)
      .subscribe((response: any) => {
        this.newNotificationsCount = response;
        if (response != 0 && this.trigger.menuOpen) {
          this.store.dispatch({ type: 'GET_NEW_NOTIFICATIONS' });
        }
      });
    this.stateSubscription.add(getNewNotificationsountSub);
  }
  updateNotificationsActionedStatus(notification) {
    this.notificaitonEntities[notification.Id].IsActioned = true;

    let updateActionedStatusSub = this.bellNotificationService
      .updateNotificationsActionedStatus(
        this.userMID,
        notification.Id,
        notification.Category
      )
      .subscribe(res => {});
    this.stateSubscription.add(updateActionedStatusSub);
  }
  onClickOfNotifications(notification) {
    if (!this.notificaitonEntities[notification.Id].IsActioned) {
      this.updateNotificationsActionedStatus(notification);
    }
    if (notification.Id == undefined) {
      notification.Id = notification.MessageId;
    }
    if (notification.DesktopLink != null) {
      if (notification.DesktopLink.indexOf('NotificationId') != -1) {
        var notificationId = notification.DesktopLink.split(
          'NotificationId='
        )[1];
        let userAgentMobile =
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i);
        if (null != userAgentMobile) {
          this._InsightService.trackEvent('AdminSend-BellNotification-Click', {
            NotificationId: notificationId,
            SelectedDevice: 'PWA'
          });
        } else {
          this._InsightService.trackEvent('AdminSend-BellNotification-Click', {
            NotificationId: notificationId,
            SelectedDevice: 'Desktop'
          });
        }

        if (notification.DesktopLink.indexOf('?NotificationId') != -1) {
          notification.DesktopLink = notification.DesktopLink.split(
            '?NotificationId'
          )[0];
        } else {
          notification.DesktopLink = notification.DesktopLink.split(
            '&NotificationId'
          )[0];
        }
      }
    } else {
      this._InsightService.trackEvent('Bell-Notification-Click-Event', {
        NotificationId: notification.Id,
        NotificationCategory: notification.Category
      });
    }
    //add navigation function call here
    this.navigateTo(notification);
    this.trigger.closeMenu();
  }
  navigateTo(notification) {
    switch (notification.Category) {
      case 'global':
        if (notification.DesktopLink != null) {
          window.open(notification.DesktopLink);
        }
        break;
      case 'admin':
        if (notification.DesktopLink != null) {
          window.open(notification.DesktopLink);
        }
        break;
      case '201':
        //$state.go("Route.LearningPath", { state: 'learningpath', 'learningPlanId': learningPlanId });
        window.open('/learningpath');
        break;
      case '301':
        //$state.go("Route.LearningPath", { state: 'learningpath', 'learningPlanId': learningPlanId });
        window.open('/learningpath');
        break;
      case 'Assignment':
        //$state.go("Route.LearningPath", { state: 'learningpath', 'learningPlanId': learningPlanId });
        window.open('/learningpath');
        break;
      case '101':
        //$state.go("Route.LearningPath", { state: 'learningpath', 'learningPlanId': learningPlanId });
        window.open('/learningpath');
        break;
      case 'Recommendation':
        break;
      case 'Social':
        if (notification.TemplateId == 27) {
          if (
            notification.ItemType == 'FamilyPackage' ||
            notification.ItemType == 'Package'
          ) {
            //$state.go("Route.DetailPackagePage", {
            //    type: notification.ItemType,
            //    expertise: notification.PackageExpertise,
            //    id: (notification.PackageId).toString()
            //});
            window.open(
              '/detailsPage/' +
                notification.ItemType +
                '/' +
                notification.PackageExpertise +
                '/' +
                notification.PackageId.toString()
            );
          } else if (notification.ItemType == 'Course') {
            //$state.go("Route.DetailPackagePage", {
            //    type: notification.ItemType,
            //    expertise: notification.Expertise,
            //    id: (notification.CourseId).toString()
            //});
            window.open(
              '/detailsPage/' +
                notification.ItemType +
                '/' +
                notification.Expertise +
                '/' +
                notification.CourseId.toString()
            );
          } else if (notification.ItemType == 'Unit') {
            //$state.go("Route.DetailPackagePage", {
            //    type: "Course",
            //    expertise: notification.UnitExpertise,
            //    id: (notification.UnitCourseId).toString()
            //});
            window.open(
              '/detailsPage/' +
                notification.ItemType +
                '/' +
                notification.UnitExpertise +
                '/' +
                notification.UnitCourseId.toString()
            );
          }
        }
        break;
      case 'Generic':
        break;
      case 'IPCompliance':
        break;
      case 'CloudLab':
        //$state.go("Route.LearningPath", { state: 'learningpath', 'learningPlanId': learningPlanId });
        window.open('/learningpath');
        break;
      case "Manager's feedback":
        break;
      case 'ALM/PLM access management':
        break;
      case 'PSS workflows':
        break;
      case 'AO Nomination':
        break;
      default:
        break;
    }
  }
  ngOnDestroy() {
    this.stateSubscription.unsubscribe();
  }
  loadNextTenNotifications() {
    if (this.notificationsPointer < this.notificationsCollection.length) {
      this.notificationsShownToUser = this.notificationsShownToUser.concat(
        this.notificationsCollection.slice(
          this.notificationsPointer,
          this.notificationsPointer + 10
        )
      );
      if (
        this.notificationsPointer + 10 >
        this.notificationsCollection.length
      ) {
        this.notificationsPointer = this.notificationsCollection.length;
      } else {
        this.notificationsPointer = this.notificationsPointer + 10;
      }
    } else if (
      this.notificationsPointer == this.notificationsCollection.length &&
      !this.notificationsState.OldNotificationsLoadedSuccesfully
    ) {
      this.getOldNotifications();
    }
  }
  stopEventPropagation(event) {
    event.stopImmediatePropagation();
  }
  getImageUrl(expertise) {
    if (expertise == '101') {
      return 'assets/images/Badges/badge_101.jpg';
    } else if (expertise == '201') {
      return 'assets/images/Badges/badge_201.jpg';
    } else if (expertise == '301') {
      return 'assets/images/Badges/badge_301.jpg';
    }
  }
  showDialogueForBlockedUsers() {
    const dialogRef = this.dialog.open(BlockedPushNotificationsComponent, {
      panelClass: 'popupDialogContainer'
    });
    dialogRef.afterClosed().subscribe(data => {});
  }
}
