<mat-dialog-content class="popupDialog" style="width: 410px !important; height: 170px !important;">
    <div class="closePopupDialog">
     <button mat-button mat-icon-button (click)="close()">
       <mat-icon>close</mat-icon>
     </button>
   </div>
 
 <div class="popupHeader">Retake Request</div>
 
   <mat-divider></mat-divider>
 
   <div class="messageText" [innerHTML]="description">
     <!-- {{description}} -->
   </div>
 
   <div class="actionButtonContainer" fxLayout ="row" fxLayoutAlign = "center center" fxLayoutGap="10px">
     <div class="">
       <button mat-raised-button class="actionButton yes-btn" (click)="save()">YES</button>
     </div>
     <div class="">
       <button mat-raised-button class="actionButton yes-btn" (click)="close()">NO</button>
     </div>
   </div>
   <!-- <div class="okButtonDiv">
     <button (click)="closeDialog()" class=" actionButton yes-btn ok-btn">OK</button>
   </div> -->
 </mat-dialog-content>