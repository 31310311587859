import { Component,Output,OnInit,EventEmitter,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { element } from '@angular/core/src/render3';
import { SystemService } from '../../../shared/services/system.service';

@Component({
  selector: 'yorbit-download-document-popup',
  templateUrl: './download-document-popup.component.html',
  styleUrls: ['./download-document-popup.component.scss']
})
export class DownloadDocumentPopupComponent implements OnInit {
  selectedOption: string = '';
  problemStatement:any=[];
  evaluationSheet:any=[];
  Solutions:any=[];
  ScoreCard:any=[];
  ErrorFile:any=[];
  isDownloadDisabled: boolean = true;


  datas:any;
  documentType: string = "";


  constructor(public dialogRef: MatDialogRef<DownloadDocumentPopupComponent>,
    private _systemSvc : SystemService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {


  }

  selectdocumentType(event:any)
  {
    this.selectedOption=event.target.value;
    this.isDownloadDisabled = false;
  }
  closePopup() {
    this.dialogRef.close();
    ////console.log('closed');

  }
  Download()
  {
    var isDownloaded = false;
    ////console.log('documentType',this.documentType);
    ////console.log('setofdata',this.data);
   // return false;
    this.data.forEach((element,index)=>{
      if(this.selectedOption==='ProblemStatement' && element.ProblemStatement != null && element.ProblemStatement.length > 0)
      {
        isDownloaded = true;

        this._systemSvc.getBlobSasKey(element.ProblemStatement)
          .then((response:any)=>{
            try{
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri);
            }
            catch{}
          });
        //window.open(element.ProblemStatement);
      }
      if(this.selectedOption==='EvaluationSummarySheet' && element.EvaluationSummarySheet != null && element.EvaluationSummarySheet.length > 0)
      {
        isDownloaded = true;

          this._systemSvc.getBlobSasKey(element.EvaluationSummarySheet)
          .then((response:any)=>{
            try{
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri,'_blank');
            }
            catch{}
          });

        //window.open(element.EvaluationSummarySheet  , '_blank');
      }
      if(this.selectedOption==='Solutions' && element.SolutionFile != null && element.SolutionFile.length > 0)
      {
        isDownloaded = true;

          this._systemSvc.getBlobSasKey(element.SolutionFile)
          .then((response:any)=>{
            try{
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri,'_blank');
            }
            catch{}
          });

        //window.open(element.SolutionFile, '_blank');
      }

      if(this.selectedOption==='ScoreCard' && element.ScoreCard != null && element.ScoreCard.length > 0)
      {
        isDownloaded = true;

          this._systemSvc.getBlobSasKey(element.ScoreCard)
          .then((response:any)=>{
            try{
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri,'_blank');
            }
            catch{}
          });

        //window.open(element.ScoreCard, '_blank');
      }
      if(this.selectedOption==='ErrorFile' && element.ErrorFile != null && element.ErrorFile.length > 0)
      {
        isDownloaded = true;

          this._systemSvc.getBlobSasKey(element.ErrorFile)
          .then((response:any)=>{
            try{
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri,'_blank');
            }
            catch{}
          });

        //window.open(element.ErrorFile, '_blank');
      }

    });
   if(isDownloaded == false) {
    var selectedText = "";
    if(this.selectedOption==='ProblemStatement')
      selectedText = 'Problem Statement';
    else if(this.selectedOption ==='EvaluationSummarySheet')
      selectedText = 'Evaluation Sheet';
    else if(this.selectedOption==='Solutions')
      selectedText = 'Solutions';
    else if(this.selectedOption==='ScoreCard')
      selectedText = 'Scorecard';
    else if(this.selectedOption==='ErrorFile')
      selectedText = 'Error File';
    if(selectedText != "")
      window.alert(selectedText +' is not available for the selected evaluation requests.');
   }
  }

}
