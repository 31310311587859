import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EvaluatorDetails } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-remap-evaluator',
  templateUrl: './remap-evaluator.component.html',
  styleUrls: ['./remap-evaluator.component.scss']
})
export class PssRemapEvaluatorComponent implements OnInit {

  selectedEvaluator: EvaluatorDetails;
  evaluatorList: EvaluatorDetails[] = [];
  disableSubmit: boolean = true;
  textAreaLength: number;

  constructor(public dialogRef: MatDialogRef<PssRemapEvaluatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.evaluatorList = this.data;
    //////console.log('datasss',this.data);
  }

  onTextAreaChange($event) {
    this.textAreaLength = $event.target.value.length;
    if(this.textAreaLength > 0 && this.selectedEvaluator.EvaluatorName != "") {
      this.disableSubmit = false;
    }
    else
      this.disableSubmit = true;
  }

  onSelectChange(event) {
    var name = event.target.value;
    this.selectedEvaluator = this.evaluatorList.filter(x => x.EvaluatorName == name)[0];
    if(this.selectedEvaluator.EvaluatorName != "" && this.textAreaLength > 0)
      this.disableSubmit = false;
    else
      this.disableSubmit = true;
  }

  onSubmit() {
    ////console.log('this.', this.selectedEvaluator);
    this.dialogRef.close(this.selectedEvaluator);
  }

  closePopup() {
    this.dialogRef.close();
  }

}
