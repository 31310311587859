import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { C } from '@angular/cdk/keycodes';
import { ContentUploadPackageComponent } from '../ao/content-upload-package/content-upload-package.component';

@Injectable({
  providedIn: 'root'
})
export class BrowseService {
  config: Ienvironment;
  selectedAcademy$: BehaviorSubject<any>;
  accountsList$: BehaviorSubject<any>;
  selectedGenre$: BehaviorSubject<any>;
  selectedSkill$: BehaviorSubject<any>;
  showCoursesInGenreView$: BehaviorSubject<boolean>;
  showSkillsInGenreView$: BehaviorSubject<boolean>;
  accountsList = [];

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.selectedAcademy$ = new BehaviorSubject(null);
    this.selectedGenre$ = new BehaviorSubject(null);
    this.showCoursesInGenreView$ = new BehaviorSubject(false);
    this.showSkillsInGenreView$ = new BehaviorSubject(false);
    this.selectedSkill$ = new BehaviorSubject([]);
    this.accountsList$ = new BehaviorSubject([]);
  }
  getSelectedAcademy() {
    return this.selectedAcademy$.asObservable();
  }
  updateSelectedAcademy(data) {
    this.selectedAcademy$.next(data);
  }
  getSelectedGenre() {
    return this.selectedGenre$.asObservable();
  }
  updateSelectedGenre(data) {
    this.selectedGenre$.next(data);
  }
  //academy and genre list
  getAcademyList() {
    return this.http
      .get<any>(this.config.apiUrl + 'Browse/Academy')
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }
  //content inside academy and genre list
  getAcademyContent(packagePayload) {
    return this.http
      .post<any>(this.config.apiUrl + 'Browse/Packages', packagePayload)
      .pipe(catchError((error: any) =>throwError(()=> error.json())));
  }
  //acounts and project list
  getAccountsList() {
    return this.accountsList$.asObservable();
  }
  getAccountsListFromAPI() {
    this.http
      .get(this.config.apiUrl + 'Browse/Accounts')
      .toPromise()
      .then((response: any) => {
        this.setAccountGenres(response.AccountsList);
      })
      .catch(err => {});
  }

  getAccountsProjectsCategories() {
    this.accountsList = [];
     return this.http.get(this.config.apiUrl + 'Browse/AccountsProjectsCategories')
      .toPromise();
      // .then((response: any) => {
      //   response.AccountsList.forEach(element => {
      //     this.accountsList.push(element);
      //   });
      //   this.accountsList.sort((a,b)=> a.AccountName.localeCompare(b.AccountName));
      // })
      // .catch(err => {});
      // return this.accountsList;
  }

  setAccountGenres(accountList) {
    accountList = accountList;
    let accountsProjectsList = [];
    if (accountList.length != 0) {
      accountList.forEach(account => {
        let genreTerms = [];
        let accountGenre: any = {
          AccountId: account.AccountId,
          ProjectId: null,
          UniqueId: account.AccountId,
          SkillTerms: [],
          Name: account.AccountName,
          IsAccount: true,
          IsProject: false
        };
        account.CategoryList.forEach(category => {
          accountGenre.SkillTerms.push({ Name: category });
        });
        accountGenre.SkillTerms.maxItems = 10;
        genreTerms.push(accountGenre);
        if (account.ProjectsList != null) {
          account.ProjectsList.forEach(project => {
            let projectGenre: any = {
              AccountId: account.AccountId,
              ProjectId: project.ProjectId,
              UniqueId: project.ProjectId,
              Name: project.ProjectName,
              SkillTerms: [],
              IsAccount: false,
              IsProject: true
            };
            project.CategoryList.forEach(category => {
              projectGenre.SkillTerms.push({ Name: category });
            });
            projectGenre.SkillTerms.maxItems = 10;
            genreTerms.push(projectGenre);
          });
        }
        accountsProjectsList.push({
          UniqueId: account.AccountId,
          Name: account.AccountName,
          GenreTerms: genreTerms
        });
      });
      this.accountsList$.next(accountsProjectsList);
    }
  }
  //content inside academy and genre list
  getAccountContent(payload) {
    return this.http
      .post(this.config.apiUrl + 'Browse/Accounts/Account', payload)
      .toPromise();
  }

  getAccountCourses(payload) {
    return this.http
      .post(this.config.apiUrl + 'Browse/Accounts/Courses', payload)
      .toPromise();
  }

  getProjectContent(payload) {
    return this.http
      .post(this.config.apiUrl + 'Browse/Accounts/Project', payload)
      .toPromise();
  }
  getShowCoursesFlagStatus() {
    return this.showCoursesInGenreView$.asObservable();
  }
  updateShowCoursesFlagStatus(data) {
    this.showCoursesInGenreView$.next(data);
  }
  getShowSkillsFlagStatus() {
    return this.showSkillsInGenreView$.asObservable();
  }
  updateShowSkillsFlagStatus(data) {
    this.showSkillsInGenreView$.next(data);
  }
  getSelectedSkill() {
    return this.selectedSkill$.asObservable();
  }
  updateSelectedSkill(data) {
    this.selectedSkill$.next(data);
  }
}
