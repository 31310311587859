<div class="background">
  <div class="block">
    <div class=message>
      Please enable Cookies and then <a class="hyperlink" (click)="refreshPage()">Click here</a> to reload the website.
    </div>
    <div class=message>
      To enable Cookies, Go to Browser Settings >  Privacy > Choose "Allow all cookies"
    </div>
  </div>
</div>
