<div *ngIf="quizLanding && !showPreloader" fxLayout="row" class="container" fxLayoutAlign="start center" fxLayout.lt-md="column"
  [ngClass.lt-md]="{'container-xs':true}">
  <!-- <div class="child" fxLayout.xs="column"> -->
  <div fxFlex.gt-sm="230px" fxFlex.lt-md="150px" fxLayout="row" class="leftDiv" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center"
  [ngStyle.lt-md]="{'width':'100% !important','height':'40% !important','background-color':'#394959'}">
    <div class="blue-strip" fxFlex.gt-sm="50" fxFlex.lt-md></div>
    <div class="empty-strip" fxFlex="50" fxShow.gt-sm="true" fxShow.lt-md="false"></div>
    <div fxLayout="row" fxFlex="125px" fxLayoutAlign="start center" Class="course-badge" [ngClass.lt-md]="['course-badge-mobile']">
      <img class="BadgeImage" src="{{landingPageBadgeImage}}" title="{{badgeName}}">
      <!-- <div class="positionRelative">
         <div class="badgename" title="{{badgeName}}">{{badgeName}}</div> 
      </div> -->
    </div>
  </div>
  <div fxLayout="column" class="rightDiv" fxFlex fxLayoutAlign="start start">
    <div class="course-name" [ngClass.lt-md]="{'course-name-mobile' : true}">
      {{ courseName }}
    </div>
    <div class="iconsDiv" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
      <div class="quizIcons" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutAlign.xs="start start" 
      fxLayoutAlign.sm="start start" *ngIf="!loadingConfigDetails">
        <!-- fxLayoutGap="11%" fxFlexOffset.gt-xs="20px" -->
        <!-- <div fxLayout.xs="row"> </div> -->
        <div *ngIf="quizDuration !== 0" fxFlex.gt-sm="150px" fxFlex.lt-md="50">
          <div class="quizDuration yorbitSprite"></div>
          <div>Quiz Duration:</div>
          <div><b>{{quizDuration+'mins'}}</b></div>
        </div>
        <div fxFlex.gt-sm="150px" fxFlex.lt-md="50">
          <div class="MCQs yorbitSprite"></div>
          <div><b>{{quesCount}}</b> Multiple</div>
          <div>Choice Question(s)</div>
        </div>
        <!-- <div fxLayout.xs="row">  </div> -->
        <div fxFlex.gt-sm="25" fxFlex.lt-md="50">
          <div class="minScore yorbitSprite"></div>
          <div>Secure minimum</div>
          <div><b>{{cutoff}}%</b> to clear quiz</div>
        </div>
        <div *ngIf="allowedattempt > 0" fxFlex.gt-sm="25" fxFlex.lt-md="50">
          <div class="attempts yorbitSprite"></div>
          <div>This is <b>{{currentDisplayAttempt + '/' + allowedattempt}}</b></div>
          <div>attempt(s)</div>
        </div>
      </div>
      <div class="quizIcons" fxLayout="column" fxLayoutAlign="center center" *ngIf="loadingConfigDetails">
        <div>
          <app-preloader></app-preloader>
        </div>
      </div>
      <div class="hr"></div>
      <div class="instructions">
        <ul>
          <li *ngIf="quizDuration !== 0">
            <b> Quiz will automatically terminate if you fail to attempt the same within the given time. The system
                will consider the questions answered within the time frame for evaluation.</b>
          </li>
          <li>Shoshin School quiz works best on Chrome browser.</li>
          <li>Questions where a
            <div class="radio yorbitSprite"></div> is displayed against the options, has <b>ONE</b> correct answer.
          </li>
          <li>Questions where a
            <div class="checkbox yorbitSprite"></div> is displayed against the options, have <b>TWO or MORE </b>correct answers.
          </li>
          <li>Answered question(s) will be displayed as
            <div class="number yorbitSprite"></div> and not answered question(s) will be displayed as
            <div class="Lightnumber yorbitSprite"></div>
          </li>
        </ul>
      </div>
      <div class="buttonsDiv" fxFlexOffset="10px" fxLayout="row" fxLayoutGap="20px" fxFlexOffset.xs="10px" fxLayoutAlign.xs="center center"
        fxLayoutAlign.sm="center center" [ngStyle.lt-md]="{'margin-bottom':'10px'}">
        <button fxFlex.lt-md="40" class="ResumeBtn" (click)="navigateToQuiz()" [disabled]="updatingQuizAttempts"
        [ngClass]="{'disabled':updatingQuizAttempts}">Start Quiz</button>
        <button fxFlex.lt-md="40" class="exitBtn" (click)="exitQuiz()" [disabled]="updatingQuizAttempts"
        [ngClass]="{'disabled':updatingQuizAttempts}">Cancel Quiz</button>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>

<yorbit-quiz *ngIf="!quizLanding && !showPreloader" [courseId]="courseId" [lpid]="lpid" [lpCategory]="lpCategory" [packageId]="packageId"></yorbit-quiz>

<div fxLayout="column" fxLayoutAlign="center center" *ngIf="showPreloader" class="container-xs">
  <div>
    <app-preloader *ngIf="showPreloader"></app-preloader>
  </div>
</div>
