import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';

@Injectable({
  providedIn: 'root'
})
export class CoursePropAndStatusesFilterService {
  //Environment Config
  config: any;
  //selected tab in pss page
  selectedTab: any;
  //list of selected filters
  selectedFilters: any;
  //behavioural subject consisting the list of selected filters
  $selectedFilters: BehaviorSubject<any>;
  //pss filters for courserequest page
  pssFiltersForCourseRequests: any;
  pssFiltersForPendingCertifications: any;
  pssFiltersForActionedCertification: any;
  //pss filters fir ExternalCertificateRequest page
  pssFiltersForExternalCertificateRequest :any;
  pssFiltersForPendingExternalCertificate: any;
  pssFiltersForActionedExternalCertificate:any;
  //201 and 301 courses list for courserquests page
  coursesListForCourseRequests: any;
  coursesListForPendingCertifications: any;
  coursesListForActionedCertification: any;
  coursesListForexternalrequestpending: any;
  coursesListForexternalrequestactioned: any;

  //pss filters and courses list subscription
  //api response
  $pssFilters: BehaviorSubject<any>;
  pssFiltersAPISubscription: any;
  previousFiltersList: any;
  $coursesList: BehaviorSubject<any>;
  coursesListAPISubscription: any;
  //certification statuses and substatus list
  certificationStatusesList: any;

  //resetfilters sub
  $resetFilters: BehaviorSubject<boolean>;

  //list of selected filters in the order of selection
  coursePropSelectionOrder: Array<string>;
  $coursePropSelectionOrder: BehaviorSubject<any>;

  //list of selected cert statuses in the order of selection
  courseStatusesSelectionOrder: Array<string>;
  $courseStatusesSelectionOrder: BehaviorSubject<any>;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.$resetFilters = new BehaviorSubject(false);

    //new implementation
    this.config = this._envSvc.getEnvironment();
    this.selectedTab = '';

    //distinct filters values subscriptions and variables initialization
    this.pssFiltersAPISubscription = {};
    this.pssFiltersForActionedCertification = null;
    this.pssFiltersForCourseRequests = null;
    this.pssFiltersForPendingCertifications = null;
    this.pssFiltersForActionedExternalCertificate = null;
    this.pssFiltersForExternalCertificateRequest = null;
    this.pssFiltersForPendingExternalCertificate = null;
    this.coursesListForexternalrequestpending = null;
    this.coursesListForexternalrequestactioned = null;
    this.$pssFilters = new BehaviorSubject({ loaded: false, res: null });
    this.previousFiltersList = {
      Academy: [],
      CourseType: [],
      ApprovalStatus: [],
      Location: [],
      Expertise: [],
      CourseName: [],
      Genre:[],
      Skill:[],
      Vendor: [],
      LearningOPMSPOC: [],
      EvaluatorVendor: [],
      CertificationStatus: [],
      CertificationSubStatus: []
    };
    //distinct courses list subscriptions and variables initialization
    this.coursesListAPISubscription = {};
    this.coursesListForActionedCertification = null;
    this.coursesListForCourseRequests = null;
    this.coursesListForPendingCertifications = null;
    this.$coursesList = new BehaviorSubject({ loaded: false, res: null });
    //initialization of selected filters and selection order
    this.setInitialValuesForSelectedFilters();
    this.setInitialValuesForSelectionOrder();
    //cert statuses and substatuses list
    this.certificationStatusesList = null;
  }
  setInitialValuesForSelectedFilters() {
    //selected filters init
    this.selectedFilters = {
      Academy: [],
      CourseType: [],
      ApprovalStatus: [],
      Location: [],
      Expertise: [],
      CourseName: [],
      Genre:[],
      Skill:[],
      Vendor: [],
      LearningOPMSPOC: [],
      EvaluatorVendor: [],
      CertificationStatus: [],
      CertificationSubStatus: []
    };
    this.$selectedFilters = new BehaviorSubject(this.selectedFilters);
  }
  setInitialValuesForSelectionOrder() {
    this.coursePropSelectionOrder = [];
    this.$coursePropSelectionOrder = new BehaviorSubject([]);

    this.courseStatusesSelectionOrder = [];
    this.$courseStatusesSelectionOrder = new BehaviorSubject([]);
  }
  getcourseFiltersSubscription() {
    return this.$pssFilters.asObservable();
  }
  getcoursesListSubscription() {
    return this.$coursesList.asObservable();
  }
  //get filters selectionOrder
  getcoursePropSelectionOrder() {
    return this.$coursePropSelectionOrder.asObservable();
  }
  //get certificaiton statuses selection order
  getStatusesSelectionOrder() {
    return this.$courseStatusesSelectionOrder.asObservable();
  }
  //get all the pss filters
  getPssFilters(selectedTab) {
    for (let subscriberKey in this.pssFiltersAPISubscription) {
      let subscriber = this.pssFiltersAPISubscription[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
    this.selectedTab = selectedTab;
    if (selectedTab == 'course-requests') {
      if (this.pssFiltersForCourseRequests == null) {
        this.pssFiltersAPISubscription.courseRequests = this.http
          .get<any>(this.config.apiUrl + 'PSS/Filters/courserequests')
          .subscribe(res => {
            this.pssFiltersForCourseRequests = { ...res };
            this.previousFiltersList = res;
            this.$pssFilters.next({ loaded: true, res: res });
          });
      } else {
        this.previousFiltersList = { ...this.pssFiltersForCourseRequests };
        this.$pssFilters.next({
          loaded: true,
          res: this.pssFiltersForCourseRequests
        });
      }
    } else if (selectedTab == 'certification-pending') {
      this.pssFiltersAPISubscription.certificationPending = this.http
        .get<any>(this.config.apiUrl + 'PSS/Filters/pendingcertification')
        .subscribe(res => {
          this.pssFiltersForPendingCertifications = { ...res };
          this.previousFiltersList = res;
          this.$pssFilters.next({ loaded: true, res: res });
        });
      // this.pssFiltersForPendingCertifications = null;
      // if (this.pssFiltersForPendingCertifications == null) {
      // } else {
      //   this.previousFiltersList = {
      //     ...this.pssFiltersForPendingCertifications
      //   };
      //   this.$pssFilters.next({
      //     loaded: true,
      //     res: this.pssFiltersForPendingCertifications
      //   });
      // }
    } else if (selectedTab == 'certification-actioned') {
      this.pssFiltersAPISubscription.certificationActioned = this.http
        .get<any>(this.config.apiUrl + 'PSS/Filters/actionedcertification')
        .subscribe(res => {
          this.pssFiltersForActionedCertification = { ...res };
          this.previousFiltersList = res;
          this.$pssFilters.next({ loaded: true, res: res });
        });
      // this.pssFiltersForActionedCertification = null;
      // if (this.pssFiltersForActionedCertification == null) {
      // } else {
      //   this.previousFiltersList = {
      //     ...this.pssFiltersForActionedCertification
      //   };

      //   this.$pssFilters.next({
      //     loaded: true,
      //     res: this.pssFiltersForActionedCertification
      //   });
      // }
    }
    else if (selectedTab == 'externalrequest-pending') {
      this.pssFiltersAPISubscription.ExternalcertificationPending =this.http
        .get<any>(this.config.apiUrl + 'PSS/Filters/externalrequest-pending')
        .subscribe(res => {
          this.pssFiltersForPendingExternalCertificate = { ...res };
          this.previousFiltersList = res;
          this.$pssFilters.next({ loaded: true, res: res });
        });
    } else if (selectedTab == 'externalrequest-actioned') {
      this.pssFiltersAPISubscription.ExternalcertificationActioned = this.http
        .get<any>(this.config.apiUrl + 'PSS/Filters/externalrequest-actioned')
        .subscribe(res => {
          this.pssFiltersForActionedExternalCertificate = { ...res };
          this.previousFiltersList = res;
          this.$pssFilters.next({ loaded: true, res: res });
        });
  }
}
  //get all the 201 and 301 courses
  getAllExternalCourses(selectedTab) {
    for (let subscriberKey in this.coursesListAPISubscription) {
      let subscriber = this.coursesListAPISubscription[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
    this.coursesListAPISubscription = {};
    if (selectedTab == 'course-requests') {
      if (this.coursesListForCourseRequests == null) {
        this.coursesListAPISubscription.courseRequests = this.http
          .get<any>(
            this.config.apiUrl + 'PSS/GetCourseFiltersForPSSPage/courserequests'
          )
          .subscribe(res => {
            this.coursesListForCourseRequests = res;
            this.$coursesList.next({ loaded: true, res: res });
          });
      } else {
        this.$coursesList.next({
          loaded: true,
          res: this.coursesListForCourseRequests
        });
      }
    } else if (selectedTab == 'certification-pending') {
      this.coursesListAPISubscription.certificationPending = this.http
        .get<any>(
          this.config.apiUrl +
            'PSS/GetCourseFiltersForPSSPage/pendingcertification'
        )
        .subscribe(res => {
          this.coursesListForPendingCertifications = res;
          this.$coursesList.next({ loaded: true, res: res });
        });
      // this.coursesListForPendingCertifications = null;
      // if (this.coursesListForPendingCertifications == null) {
      // } else {
      //   this.$coursesList.next({
      //     loaded: true,
      //     res: this.coursesListForPendingCertifications
      //   });
      // }
    } else if (selectedTab == 'certification-actioned') {
      this.coursesListAPISubscription.certificationActioned = this.http
        .get<any>(
          this.config.apiUrl +
            'PSS/GetCourseFiltersForPSSPage/actionedcertification'
        )
        .subscribe(res => {
          this.coursesListForActionedCertification = res;
          this.$coursesList.next({ loaded: true, res: res });
        });
      // this.coursesListForActionedCertification = null;
      // if (this.coursesListForActionedCertification == null) {
      // } else {
      //   this.$coursesList.next({
      //     loaded: true,
      //     res: this.coursesListForActionedCertification
      //   });
      // }
    }
    else if (selectedTab == 'externalrequest-pending') {
      this.coursesListAPISubscription.ExternalcertificationPending = this.http
        .get<any>(
          this.config.apiUrl +
            'PSS/GetCourseFiltersForPSSPage/externalrequest-pending'
        )
        .subscribe(res => {
          this.coursesListForexternalrequestpending = res;
          this.$coursesList.next({ loaded: true, res: res });
        });
      // this.coursesListForPendingCertifications = null;
      // if (this.coursesListForPendingCertifications == null) {
      // } else {
      //   this.$coursesList.next({
      //     loaded: true,
      //     res: this.coursesListForPendingCertifications
      //   });
      // }
    } else if (selectedTab == 'externalrequest-actioned') {
      this.coursesListAPISubscription.ExternalcertificationActioned = this.http
        .get<any>(
          this.config.apiUrl +
            'PSS/GetCourseFiltersForPSSPage/externalrequest-actioned'
        )
        .subscribe(res => {
          this.coursesListForexternalrequestactioned = res;
          this.$coursesList.next({ loaded: true, res: res });
        });
      // this.coursesListForActionedCertification = null;
      // if (this.coursesListForActionedCertification == null) {
      // } else {
      //   this.$coursesList.next({
      //     loaded: true,
      //     res: this.coursesListForActionedCertification
      //   });
      // }
    }
  }
  //get certification Statuses
  getCertificationStatuses(selectedTab) {
    let promise = new Promise((resolve, reject) => {
      if (this.certificationStatusesList == null) {
        this.http
          .get<any>(this.config.apiUrl + 'PSS/StatusMaster')
          .toPromise()
          .then(res => {
            this.certificationStatusesList = res;
            resolve(this.certificationStatusesList);
            //return res;
          });
      } else {
        resolve(this.certificationStatusesList);
      }
    });
    return promise;
  }
  //get selected Filters
  getSelectedFilters() {
    return this.$selectedFilters.asObservable();
  }
  //update selected Filters
  updateSelectedFilters(entity, value, checked) {
    if (checked) {
      this.selectedFilters[entity].push(value);
    } else {
      this.selectedFilters[entity].splice(
        this.selectedFilters[entity].indexOf(value),
        1
      );
    }
    this.$selectedFilters.next(this.selectedFilters);
  }
  //update filtersSelectedOrder list
  //if the list is empty add initial state
  updateSelectionOrder(filterName, value, checked) {
    if (
      filterName != 'CertificationStatus' &&
      filterName != 'CertificationSubStatus'
    ) {
      if (checked && this.coursePropSelectionOrder.indexOf(filterName) == -1) {
        this.coursePropSelectionOrder.push(filterName);
      } else if (!checked) {
        if (this.selectedFilters[filterName].length == 0) {
          this.coursePropSelectionOrder.splice(
            this.coursePropSelectionOrder.indexOf(filterName),
            1
          );
        }
      }
      this.$coursePropSelectionOrder.next(this.coursePropSelectionOrder);
    } else {
      if (
        checked &&
        this.courseStatusesSelectionOrder.indexOf(filterName) == -1
      ) {
        this.courseStatusesSelectionOrder.push(filterName);
      } else if (!checked) {
        this.courseStatusesSelectionOrder.splice(
          this.courseStatusesSelectionOrder.indexOf(filterName),
          1
        );
      }
      this.$courseStatusesSelectionOrder.next(
        this.courseStatusesSelectionOrder
      );
    }
  }
  //filter courses list
  getCourseFilters(selectedFilterName, checked) {
    let coursesList = [...this.$coursesList.getValue().res];
    let pssfilters = { ...this.$pssFilters.getValue().res };
    let filters = {
      ApprovalStatus: pssfilters['ApprovalStatus'],
      Academy: [],
      CourseType: [],
      Expertise: [],
      CourseName: [],
      Genre:[],
      Skill:[],
      Vendor: [],
      LearningOPMSPOC: [],
      EvaluatorVendor: [],
      CertificationStatus: [],
      CertificationSubStatus: []
    };
    if (
      selectedFilterName != 'CertificationStatus' &&
      selectedFilterName != 'CertificationSubStatus'
    ) {
      //leaving cert status and sub status as of previous values bcz course filters are not linked to cert statuses
      filters['CertificationStatus'] = pssfilters['CertificationStatus'];
      filters['CertificationSubStatus'] = pssfilters['CertificationSubStatus'];
      this.coursePropSelectionOrder.forEach(filter => {
        //filtering courses in the same order of selection
        filters[filter] = [...pssfilters[filter]];
        coursesList = coursesList.filter(course => {
            return (
              this.selectedFilters[filter]
                .toString()
                .toLowerCase()
                .split(',')
                .indexOf(course[filter].toLowerCase()) != -1
            );
        });
        pssfilters = {
          Academy: [],
          CourseType: [],
          Expertise: [],
          CourseName: [],
          Genre:[],
          Skill:[],
          Vendor: [],
          LearningOPMSPOC: [],
          EvaluatorVendor: [],
          CertificationStatus: [],
          CertificationSubStatus: []
        };
        coursesList.forEach(course => {
          Object.keys(course).forEach(prop => {
            if (pssfilters[prop].indexOf(course[prop]) == -1) {
              pssfilters[prop].push(course[prop]);
            }
          });
        });
       });
      Object.keys(pssfilters).forEach(prop => {
        if (
          this.coursePropSelectionOrder.indexOf(prop) == -1 &&
          prop != 'CertificationStatus' &&
          prop != 'CertificationSubStatus'
        ) {
          filters[prop] = pssfilters[prop];
        }
      });
      this.previousFiltersList = filters;
    } else if (this.selectedTab == 'course-requests') {
      let filteredOptions = {
        CertificationStatus: [],
        CertificationSubStatus: []
      };
      if (this.courseStatusesSelectionOrder.length == 0) {
        let pssfilters = { ...this.pssFiltersForCourseRequests };
        this.previousFiltersList['CertificationStatus'] = [
          ...pssfilters['CertificationStatus']
        ];
        this.previousFiltersList['CertificationSubStatus'] = [
          ...pssfilters['CertificationSubStatus']
        ];
      } else if (
        this.courseStatusesSelectionOrder[0] == 'CertificationStatus'
      ) {
        let selectedStatuses =
          this.selectedFilters['CertificationStatus'].length != 0
            ? this.selectedFilters['CertificationStatus']
                .toString()
                .toLowerCase()
                .split(',')
            : this.selectedFilters['CertificationStatus'];
        this.certificationStatusesList.forEach(obj => {
          if (
            selectedStatuses.indexOf(obj.CertificationStatus.toLowerCase()) !=
              -1 &&
            filteredOptions['CertificationSubStatus'].indexOf(
              obj.CertificationSubStatus
            ) == -1
          ) {
            filteredOptions['CertificationSubStatus'].push(
              obj.CertificationSubStatus
            );
          }
        });
        this.previousFiltersList['CertificationSubStatus'] = [
          ...filteredOptions['CertificationSubStatus']
        ];
        this.previousFiltersList['CertificationStatus'] = [
          ...pssfilters['CertificationStatus']
        ];
      } else if (
        this.courseStatusesSelectionOrder[0] == 'CertificationSubStatus'
      ) {
        let selectedStatuses =
          this.selectedFilters['CertificationSubStatus'].length != 0
            ? this.selectedFilters['CertificationSubStatus']
                .toString()
                .toLowerCase()
                .split(',')
            : this.selectedFilters['CertificationSubStatus'];
        this.certificationStatusesList.forEach(obj => {
          if (
            selectedStatuses.indexOf(
              obj.CertificationSubStatus.toLowerCase()
            ) != -1 &&
            filteredOptions['CertificationStatus'].indexOf(
              obj.CertificationStatus
            ) == -1
          ) {
            filteredOptions['CertificationStatus'].push(
              obj.CertificationStatus
            );
          }
        });
        this.previousFiltersList['CertificationStatus'] = [
          ...filteredOptions['CertificationStatus']
        ];
        this.previousFiltersList['CertificationSubStatus'] = [
          ...pssfilters['CertificationSubStatus']
        ];
      }
    }
    return this.previousFiltersList;
  }
  getResetFilterssub() {
    return this.$resetFilters.asObservable();
  }
  resetFilters() {
    this.selectedFilters = {
      Academy: [],
      CourseType: [],
      ApprovalStatus: [],
      Location: [],
      Expertise: [],
      CourseName: [],
      Genre:[],
      Skill:[],
      Vendor: [],
      LearningOPMSPOC: [],
      EvaluatorVendor: [],
      CertificationStatus: [],
      CertificationSubStatus: []
    };
    this.$selectedFilters.next(this.selectedFilters);
    this.coursePropSelectionOrder = [];
    this.$coursePropSelectionOrder.next(this.coursePropSelectionOrder);

    this.courseStatusesSelectionOrder = [];
    this.$courseStatusesSelectionOrder.next(this.courseStatusesSelectionOrder);
    if (
      this.selectedTab == 'course-requests' &&
      this.pssFiltersForCourseRequests != null
    ) {
      this.$pssFilters.next({
        loaded: true,
        res: this.pssFiltersForCourseRequests
      });
    } else if (
      this.selectedTab == 'certification-pending' &&
      this.pssFiltersForPendingCertifications != null
    ) {
      this.$pssFilters.next({
        loaded: true,
        res: this.pssFiltersForPendingCertifications
      });
    } else if (
      this.selectedTab == 'certification-actioned' &&
      this.pssFiltersForActionedCertification != null
    ) {
      this.$pssFilters.next({
        loaded: true,
        res: this.pssFiltersForActionedCertification
      });
    }
      else if (
        this.selectedTab == 'externalrequest-pending' &&
        this.pssFiltersForPendingExternalCertificate != null
      ) {
        this.$pssFilters.next({
          loaded: true,
          res: this.pssFiltersForPendingExternalCertificate
        });
      } else if (
        this.selectedTab == 'externalrequest-actioned' &&
        this.pssFiltersForActionedExternalCertificate != null
      ) {
        this.$pssFilters.next({
          loaded: true,
          res: this.pssFiltersForActionedExternalCertificate
        });
    }
    this.$resetFilters.next(true);
  }
  removeSelectedFiltersFor(filter) {
    //update selected filters
    this.selectedFilters[filter] = [];
    this.$selectedFilters.next(this.selectedFilters);
    //update selection order
    this.updateSelectionOrder(filter, null, false);
  }
}
