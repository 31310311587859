import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { PssService } from '../pss.service';
import { PssFiltersService } from '../pss-filters/pss-filters.service';
import { DatePipe } from '@angular/common';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscriber } from 'rxjs';
@Component({
  selector: 'yorbit-course-requests',
  templateUrl: './course-requests.component.html',
  styleUrls: ['./course-requests.component.scss']
})
export class CourseRequestsComponent implements OnInit, OnDestroy {

  @Input() hasClassRoom: boolean;
  //content index is the index of the starting request in current paginated page
  contentIndex: number;
  //api response
  requests: Array<any>;
  //flag to hide/show preloader
  showPreloader: boolean;
  //flag to show whether filters are applied or not
  filtersApplied: boolean;
  //filters selected from pss filters comp to fetch requests
  courseRequestsFilters: any;
  //unique course names list from the fetched requests
  courseNames: Array<string>;
  courseRequestsTabSubscription: any;
  courseRequestsAPISub: any;
  constructor(
    private pssFiltersService: PssFiltersService,
    private _pssService: PssService,
    private _GraphDataService: GraphDataService,
    private activatedRoute: ActivatedRoute
  ) {
    this.contentIndex = 0;
    this.requests = [];
    this.showPreloader = false;
    this.filtersApplied = false;
    this.courseRequestsFilters = {};
    this.courseNames = [];
    this.courseRequestsTabSubscription = {};
    this.courseRequestsAPISub = {};
  }

  ngOnInit() {
    this.subscribetToRouterParams();

    //subscribe to the filter options  and call api to get results on every update
    this.subscribeToCourseRequestsFilters();
    this.subscribeToLoadResultsStatus();
  }
  subscribeToLoadResultsStatus() {
    this.courseRequestsTabSubscription.canResultsLoadStatus = this._pssService
      .getResultsLoadedStatus()
      .subscribe((res: any) => {
        if (res.count == 0) {
          this.contentIndex = 0;
          this.requests = [];
          this.showPreloader = false;
          this.courseRequestsFilters = {};
          this.courseNames = [];
        }
        if (!res.status) {
          this.filtersApplied = false;
          this.cancelCourseReqAPICallsInProgresss();
        }
      });
  }
  cancelCourseReqAPICallsInProgresss() {
    for (let subscriberKey in this.courseRequestsAPISub) {
      let subscriber = this.courseRequestsAPISub[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  subscribetToRouterParams() {
    this.courseRequestsTabSubscription.routeParams = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.contentIndex = 0;
        this.requests = [];
        this.showPreloader = false;
        this.filtersApplied = false;
        this.courseRequestsFilters = {};
        this.courseNames = [];
      }
    );
  }
  subscribeToCourseRequestsFilters() {
    this.courseRequestsTabSubscription.courseReqFiltersSub = this.pssFiltersService
      .getCourseRequestsFilters()
      .subscribe((res: any) => {
        //res wil have filter options and url parameters
        if (res != null) {
          this.requests = [];
          //filters applied
          this.filtersApplied = true;
          //show preloader
          this.showPreloader = true;
          //fetch data from api
          this.getRequests(res.filters, res.urlParams);
        }
      });
  }
  getRequests(postdata, urlParams) {
    this.courseRequestsFilters = postdata;
    this.courseRequestsAPISub[
      'courserequests'
    ] = this._pssService
      .getCourseRequests(postdata, urlParams, this.hasClassRoom)
      .subscribe((res: any) => {
        //hide preloader and show the data
        this.showPreloader = false;
        this._pssService.updateResultsLoadedStatus({
          status: true,
          count: res.count
        });
        //update total pagination count as the length of the paginator
        this.pssFiltersService.updateTotalPaginationCount(res.count);
        //content index
        this.contentIndex = urlParams.count;
        this.courseNames = [];
        if (res.pssItems != null) {
          this.requests = res.pssItems;
        }
        this.getGraphDetailsOfUsers();
      });
  }
  formatDate(date, format) {
    const result = new Date(date);
    return new DatePipe('en-US').transform(result, format);
  }
  isDateValid(date) {
    const result = new Date(date);
    if (result.getFullYear() == 1) {
      return false;
    } else {
      return true;
      //  return this.datePipe.transform(date,'dd/MM/yyyy');
    }
  }
  getGraphDetailsOfUsers() {
    // getting the graph details of user and setting it to the model
    this.requests.forEach((request, key) => {
      if (!this.courseRequestsFilters.IsArrangeByDate) {
        if (this.courseNames.indexOf(request.CourseName) == -1) {
          this.courseNames.push(request.CourseName);
          request.showCourseName = true;
        } else {
          request.showCourseName = false;
        }
      } else {
        request.showCourseName = false;
      }
      this.courseRequestsTabSubscription.getUserNameByMIDSub = this._GraphDataService
        .getUserName(request.Mid)
        .subscribe(responseUserName => {
          request.Name = responseUserName.displayName;
        });
      if (
        null !== request.ActionedBy && request.ActionedBy != "" && 'AUTO APPROVED' !== request.ActionedBy
      ) {
        this.courseRequestsTabSubscription.getActionedByNameSub = this._GraphDataService
          .getUserName(request.ActionedBy)
          .subscribe(responseUserName => {
            request.ActionedByName = responseUserName.displayName;
          });
        this.courseRequestsTabSubscription.getDepartmentSub = this._GraphDataService
          .getLearnerDepartment(request.ActionedBy)
          .subscribe(responseUserDepartment => {
            request.LearnerDepartment = responseUserDepartment.department;
          });
        if (request.ActionedBy != null && request.ActionedBy !== '') {
          this.courseRequestsTabSubscription.getJobTitleSub = this._GraphDataService
            .getUserJobTitle(request.ActionedBy)
            .subscribe(responseUserJobTitle => {
              request.UserJobTitle = responseUserJobTitle.jobTitle;
            });
        }
      } else {
        request.ActionedByName = 'NA';
        request.LearnerDepartment = '';
        request.UserJobTitle = '';
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.courseRequestsTabSubscription) {
      let subscriber = this.courseRequestsTabSubscription[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
}
