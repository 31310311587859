export const categoryOptions = [
  'My compliance status is incorrect',
  'Current cycle duration is incorrect',
  'Link to learning policy is incorrect',
  'My Primary L4 is incorrect',
  'Acquired credit(s) is wrong',
  'Others'
];

export const complianceInfo = [
  //'The displayed compliance status is as on June 30th 2020. Courses that are completed or marked as completed post this date will not be considered for this Policy Cycle (i.e. July 2020).',
  //'Credits acquired post June 30th will reflect once the current promotion cycle is over.',
  //Delete below two after jan 15th
  //'This page displays credits acquired for courses completed on or before <b>15th Dec 2021</b>.',
  //'For courses completed <b>post 15th Dec 2021</b>, the acquired credits will reflect on this page from <b>17th Jan 2022</b>.',
  //end

  //generic messages
  // 'This page displays courses considered for compliance for <b>April 2023</b> promotion cycle.',
  // '101 courses and Behavioral Academy courses with Zero Credit are not considered for learning policy compliance.',
  // 'Behavioral Academy courses with 1 or 2 Credits are considered for learning policy compliance.'
  'The learning policy harmonization for <b>LTIMindtree</b> is currently in progress. The new policy link as well as the learning credits requirement will be updated soon.'
  //end of generic message//

  //'The compliance status and current cycle are not applicable for C1 minds who are due for promotion on Mar or Sept cycle.'

  //Frozen message
  // 'This page displays courses considered for compliance for <b>July 2021</b> promotion cycle which were <b>acquired on or before 10th June 2021.</b>',
  // '<b>Credits acquired post 10th June 2021</b> will be considered for the <b>next compliance cycle</b> that is Jan 2022.',
  // 'Once the course is marked to completed, the credits associated with the course will be displayed immediately on the Learning History page when you click on the badge.',
  // '101 courses and Behavioral Academy courses with Zero Credit are not considered for learning policy compliance.',
  // 'Behavioral Academy courses with 1 or 2 Credits are considered for learning policy compliance.'
];
