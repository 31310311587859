import { Action } from '@ngrx/store';

// load Auth
export const AUTH_GET_USER_PROFILE = '[Auth] Get UserProfile';
export const AUTH_GET_USER_PROFILE_FAIL = '[Auth] Get UserProfile Fail';
export const AUTH_GET_USER_PROFILE_SUCCESS = '[Auth] Get UserProfile Success';

export class AuthGetUserProfile implements Action {
  readonly type = AUTH_GET_USER_PROFILE;
}

export class AuthGetUserProfileFail implements Action {
  readonly type = AUTH_GET_USER_PROFILE_FAIL;
  constructor(public payload: any) {}
}

export class AuthGetUserProfileSuccess implements Action {
  readonly type = AUTH_GET_USER_PROFILE_SUCCESS;
  constructor(public payload: any) {}
}

// action Type
export type AuthAction =
  AuthGetUserProfile
  | AuthGetUserProfileSuccess
  | AuthGetUserProfileFail
