<mat-dialog-content>
    <!-- <div class="headerTitle">PSS Certificate Request Error</div> -->
    <div class="closePopupBtn">
      <button class="mat-button mat-icon-button" (click)="closePopup(false)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="popup-content">
      <div class="message">
        There are few unsaved certification date changes. Do you want to continue? 
      </div>
      <div class="action-btn">
          <button (click)="closePopup(true)" class="action-button">Yes</button>
          <button (click)="closePopup(false)" class="action-button">No</button>
      </div>
    </div>
  </mat-dialog-content>