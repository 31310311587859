import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../user-details/store';

import { LearningPathService } from '../../../learning-path/learning-path.service';
import { ArrayPropertyFilterPipe } from '@YorbitWorkspace/pipes';
import { Router } from '@angular/router';
import { CreateLpPopupComponent } from '../create-lp-popup/create-lp-popup.component';

@Component({
  selector: 'yorbit-create-lp',
  templateUrl: './create-lp.component.html',
  styleUrls: ['./create-lp.component.scss']
})
export class CreateLpComponent implements OnInit {
  learningPathFormControl = new FormControl('', [Validators.required]);
  createNewLPInProgress: boolean;
  learningPathTitle: string;
  userCreatedLps: Array<any>;
  skillPaths: Array<any>;
  learningPathValidateFail: boolean;
  learningPathValidateError: string;
  lpCreated: boolean;
  validateLearningPath: ErrorStateMatcher = {
    isErrorState: (control: FormControl) => {
      return this.learningPathValidateFail;
    }
  };
  constructor(
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private dialogRef: MatDialogRef<CreateLpComponent>,
    private snackBar: MatSnackBar,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    this.learningPathValidateFail = false;
    this.getLearningPathEntities();
  }

  ngOnInit() {
    this.createNewLPInProgress = false;
    this.lpCreated = false;
  }
  getLearningPathEntities() {
    this.userDetailsStore
      .select(fromUserDetailsStore.getUserCreatedLPsSelector)
      .subscribe(lps => {
        this.userCreatedLps = lps;
      });

    this.userDetailsStore.select(fromUserDetailsStore.getSkillPathsSelector).subscribe(lps => {
      this.skillPaths = lps;
    });
  }
  createLearningPath() {
    this.createNewLPInProgress = true;
    this.lpService
      .checkLpExistAndAdd(this.learningPathTitle, this.userCreatedLps, this.skillPaths)
      .then(result => {
        // this.lpTitle = this.learningPathTitle;
        this.createNewLPInProgress = false;
        this.lpCreated = true;
        // this.learningPathTitle = '';
        // this.userDetailsStore.dispatch(
        //   new fromUserDetailsStore.UserDetailsAddLearningPath(result)
        // );
        //  this.dialogRef.close();
        this.openSnackBar();
        // this.addContentToLearningPath(result);
      })
      .catch(error => {
        this.createNewLPInProgress = false;
        this.lpCreated = false;
        this.learningPathValidateFail = true;
        this.learningPathValidateError = error.errorMessage;
        // this.openDialog(error.errorMessage);
      });
  }
  createLPKeyEvent(event) {
    // clear error message on any key event
    this.learningPathValidateFail = false;
    // create learning path on enter
    if (13 === event.keyCode) this.createLearningPath();
  }
  closePopup() {
    if (this.lpCreated) {
      this.dialogRef.close();
      this._router.navigate(['learningpath']);
    } else {
      this.dialogRef.close();
    }
  }
  openSnackBar() {
    let config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'end';
    this.snackBar.open(
      'Learning Path has been created successfully!',
      undefined,
      config
    );
  }

  openDialog(msg) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';

    dialogConfig.data = {
      Msg: msg
    };
    this._dialog.open(CreateLpPopupComponent, dialogConfig);
  }
}
