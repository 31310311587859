import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { Observable } from 'rxjs';
//import { truncate } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class DeclarePopupService {
  config: Ienvironment;
  badgeImageForDeclare = {
    '101': 'assets/images/Badges/badge_declare_101.jpg',
    '201': 'assets/images/Badges/badge_declare_201.jpg',
    '301': 'assets/images/Badges/badge_declare_301.jpg'
  };
  acquiredBadge = {
    skill: 'assets/images/Badges/badge_101.jpg',
    compliance: 'assets/images/Badges/badge_101.jpg'
  };
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }

  getSkillDataForDeclare(id) {
    return this.http
      .get(this.config.apiUrl + 'User/SkillName?courseId=' + id)
      .toPromise();
  }

  declareASkill(data) {
    return this.http
      .post(this.config.apiUrl + 'User/DeclareSkill/Submit', data)
      .toPromise();
  }

  getBadgeImageForDeclare(expertise) {
    return this.badgeImageForDeclare[expertise];
  }

  declareBadge(BadgeImage) {
    if (
      this.badgeImageForDeclare[101] === BadgeImage ||
      this.badgeImageForDeclare[201] === BadgeImage ||
      this.badgeImageForDeclare[301] === BadgeImage
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkClassroomCourse(courseId: string): Observable<boolean> {
    return this.http.get<boolean>(this.config.apiUrl + "Course/CheckClassroomCourse/" + courseId);

  }
}
