import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

export interface SearchData {
  title: string;
  results: Array<Object>;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  config: Ienvironment;
  public searchResults: any;
  accountProjectList: any;
  futureSkillButtonAnimation: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.searchResults = {};
    this.accountProjectList = [];
    this.config = this._envSvc.getEnvironment(); 
  }
  
  getSearchData(data): Observable<any> {
    return this.http.post(this.config.apiUrl + 'Search/Azure', data).pipe(
      tap((res: Response) => res),
      catchError((e: Response) => throwError(e))
    );
  }
  saveSearchedData(title, res) {
    this.searchResults[title] = res;
  }
  getSavedSearchedData(title) {
    if (
      this.searchResults[title] == undefined ||
      this.searchResults[title] == null
    ) {
      return null;
    } else {
      return this.searchResults[title];
    }
  }
  saveAccountData(res) {
    this.accountProjectList = res;
  }
  getSavedAccountData() {
    return this.accountProjectList;
  }
}
