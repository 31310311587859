import { Action } from '@ngrx/store';
export const UPDATE_LP_RESUME_DETAILS =
  '[UserDetials] Update LP Resume Details';
export const USER_DETAILS_GET_DETAILS = '[UserDetails] Load Details';
export const USER_DETAILS_GET_DETAILS_FAIL = '[UserDetails] Load Details Fail';
export const USER_DETAILS_GET_DETAILS_SUCCESS =
  '[UserDetails] Load Details Success';
export const USER_DETAILS_ADD_LEARNING_PATH = '[UserDetails] Add LearningPath';
export const USER_DETAILS_UPDATE_PACKAGE_COURSES_LIST =
  '[UserDetails] Update PacakgeCoursesList';
export const USER_DETAILS_UPDATE_WORKFLOW_STATUS =
  '[UserDetails] Update WorkflowStatus';
export const USER_DETAILS_DELETE_LEARNING_PATH = '[UserDetails] Delete LP';
export const USER_DETAILS_DELETE_CONTENT_INSIDE_LEARNING_PATH =
  '[UserDetails] Delete Content Inside LP';
export const USER_DETAILS_UPDATE_COURSE_PROGRESS =
  '[UserDetails] Update Course Progress';
export const USER_DETAILS_UPDATE_VIDEO_PROGRESS =
  '[UserDetails] Update Video Progress';
export const USER_DETAILS_UPDATE_VIDEO_PROGRESS_FOR_READDED_COURSES =
  '[UserDetails] Update Video Progress For Readded Courses';
export const USER_DETAILS_UPDATE_DELETED_VIDEO_PROGRESS =
  '[UserDetails] Update Deleted Video Progress';
export const USER_DETAILS_CLEAR_DELETED_VIDEO_PROGRESS =
  '[UserDetails] Clear Deleted Video Progress';
export const USER_DETAILS_UPDATE_ACHIEVEMENTS =
  '[UserDetails] Update Achievements';
export const USER_DETAILS_UPDATE_QUIZ_DETAILS =
  '[UserDetails] Update Quiz Details';
export const USER_DETAILS_ADD_QUIZ_DETAILS = '[UserDetails] Add Quiz Details';
export const USER_DETAILS_UPDATE_UNIT_PROGRESS =
  '[UserDetails] Update Unit Progress';
export const USER_DETAILS_UPDATE_PACKAGE_COURSES_PROGRESS =
  '[UserDetails] Update Course Progress In Package';

export class UserDetailsGetDetails implements Action {
  readonly type = USER_DETAILS_GET_DETAILS;
}

export class UserDetailsGetDetailsFail implements Action {
  readonly type = USER_DETAILS_GET_DETAILS_FAIL;
  constructor(public payload: any) { }
}

export class UserDetailsGetDetailsSuccess implements Action {
  readonly type = USER_DETAILS_GET_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}

export class UserDetailsAddLearningPath implements Action {
  readonly type = USER_DETAILS_ADD_LEARNING_PATH;
  constructor(public payload: any) { }
}

export class UserDetailsUpdatePackageCoursesList implements Action {
  readonly type = USER_DETAILS_UPDATE_PACKAGE_COURSES_LIST;
  constructor(public payload: any) { }
}
export class UpdateLPResumeDetails implements Action {
  readonly type = UPDATE_LP_RESUME_DETAILS;
  constructor(public payload: any) { }
}
export class UpdateWorkflowStatus implements Action {
  readonly type = USER_DETAILS_UPDATE_WORKFLOW_STATUS;
  constructor(public payload: any) { }
}
export class DeleteLearningPath implements Action {
  readonly type = USER_DETAILS_DELETE_LEARNING_PATH;
  constructor(public payload: any) { }
}
export class DeleteContentInsideLearningPath implements Action {
  readonly type = USER_DETAILS_DELETE_CONTENT_INSIDE_LEARNING_PATH;
  constructor(public payload: any) { }
}
export class UpdateCourseProgress implements Action {
  readonly type = USER_DETAILS_UPDATE_COURSE_PROGRESS;
  constructor(public payload: any) { }
}
export class UpdateVideoProgress implements Action {
  readonly type = USER_DETAILS_UPDATE_VIDEO_PROGRESS;
  constructor(public payload: any) { }
}
export class UpdateVideoProgressForReaddedCourses implements Action {
  readonly type = USER_DETAILS_UPDATE_VIDEO_PROGRESS_FOR_READDED_COURSES;
  constructor(public payload: any) { }
}

export class UpdateDeletedVideoProgressBackUp implements Action {
  readonly type = USER_DETAILS_UPDATE_DELETED_VIDEO_PROGRESS;
  constructor(public payload: any) { }
}
export class ClearDeletedVideoProgressBackUp implements Action {
  readonly type = USER_DETAILS_CLEAR_DELETED_VIDEO_PROGRESS;
  constructor(public payload: any) { }
}
export class UpdateAchievements implements Action {
  readonly type = USER_DETAILS_UPDATE_ACHIEVEMENTS;
  constructor(public payload: any) { }
}
export class UpdateQuizDetails implements Action {
  readonly type = USER_DETAILS_UPDATE_QUIZ_DETAILS;
  constructor(public payload: any) { }
}
export class AddQuizDetails implements Action {
  readonly type = USER_DETAILS_ADD_QUIZ_DETAILS;
  constructor(public payload: any) { }
}
export class UpdateUnitProgress implements Action {
  readonly type = USER_DETAILS_UPDATE_UNIT_PROGRESS;
  constructor(public payload: any) { }
}
export class UpdateCourseProgressInPackage implements Action {
  readonly type = USER_DETAILS_UPDATE_PACKAGE_COURSES_PROGRESS;
  constructor(public payload: any) { }
}

export type UserDetailsAction =
  | UserDetailsGetDetails
  | UserDetailsGetDetailsFail
  | UserDetailsGetDetailsSuccess
  | UserDetailsAddLearningPath
  | UserDetailsUpdatePackageCoursesList
  | UpdateLPResumeDetails
  | UpdateWorkflowStatus
  | DeleteLearningPath
  | DeleteContentInsideLearningPath
  | UpdateCourseProgress
  | UpdateVideoProgress
  | UpdateVideoProgressForReaddedCourses
  | UpdateDeletedVideoProgressBackUp
  | ClearDeletedVideoProgressBackUp
  | UpdateAchievements
  | UpdateQuizDetails
  | AddQuizDetails
  | UpdateUnitProgress
  | UpdateCourseProgressInPackage;
