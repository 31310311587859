<mat-dialog-content class="popupDialog">
  <!--Close Popup Icon-->
  <div class="closePopupDialog">
    <button mat-button mat-icon-button (click)="closePopup()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <!--Popup Header-->
  <div class="popupHeader">ErrorFile Download</div>

<div class="modal-body">
<!-- <div class="content"> -->

    <div  ngClass="{'disable': data.length > 0 && data[0]}">
      <input class="attemptstyle" type="checkbox"  [disabled]="!data[0]" (change)="selectURL($event, data[0])"  value="data[0]"><b>Attempt1</b>
   
    </div>

  <div></div>
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    <div class="attemptstyle" ngClass="{'disable': data.length > 0 && data[1]}">
      <input type="checkbox" [disabled]="!data[1]" (change)="selectURL($event, data[1])" value="data[1]"> <b>Attempt2</b>

    </div>
  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    <div class="attemptstyle" ngClass="{'disable': data.length > 0 && data[2]}">
        <input type="checkbox" [disabled]="!data[2]" (change)="selectURL($event, data[2])" value="data[2]"> <b>Attempt3</b>
       
    </div>
    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

   </div>
<!-- </div> -->

<div class="actionButtonContainer">
  <div>
    <button mat-raised-button class="actionButton download-btn" (click)="Download()" [disabled]="urls.length === 0">Download</button>
  </div>
</div>


</mat-dialog-content>
