import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { DatepickerViewModel } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import {MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'yorbit-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  currentDate : Date;
  today: Date;
  message: string;
  promotionCycle: string;
  day: any;
  month: any;
  year: any;
  currentMonth: any;  
  constructor(private _globals: Globals, public snackBarRef: MatSnackBarRef<ToastComponent> ) {    
  }

  ngOnInit() {
    this.currentDate = new Date();
    this.today = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
  

    if (this.today <= this._globals.lastDateRegistration) {
      this.message = "Last date to register 201/301 course";
      this.day = this._globals.lastDateRegistration.getDate();
      this.month = this._globals.lastDateRegistration.getMonth();
      this.getCurrentMonth();
      this.year = this._globals.lastDateRegistration.getFullYear();
      this.promotionCycle = this._globals.promotionCycle;
    }
    else if (this.today <= this._globals.lastDateCompletion) {
      this.message = "Last date to complete 201/301 course";     
      this.day = this._globals.lastDateCompletion.getDate();
      this.month = this._globals.lastDateCompletion.getMonth();
      this.getCurrentMonth();
      this.year =this._globals.lastDateCompletion.getFullYear();
      this.promotionCycle = this._globals.promotionCycle;
    }else if(this.today <= this._globals.lastDateCertificateReimbursement){
      this.message = "Cut-off date to claim the external certification or self-paced course Reimbursement.";     
      this.day = this._globals.lastDateCertificateReimbursement.getDate();
      this.month = this._globals.lastDateCertificateReimbursement.getMonth();
      this.getCurrentMonth();
      this.year =this._globals.lastDateCertificateReimbursement.getFullYear();
      this.promotionCycle =''
    }
  }

  getCurrentMonth() {
    switch (this.month) {
      case 0:
        this.currentMonth = "January";
        break;
      case 1:
        this.currentMonth = "February";
        break;
      case 2:
        this.currentMonth = "March";
        break;
      case 3:
        this.currentMonth = "April";
        break;
      case 4:
        this.currentMonth = "May";
        break;
      case 5:
        this.currentMonth = "June";
        break;
      case 6:
        this.currentMonth = "July";
        break;
      case 7:
        this.currentMonth = "August";
        break;
      case 8:
        this.currentMonth = "September";
        break;
      case 9:
        this.currentMonth = "October";
        break;
      case 10:
        this.currentMonth = "November";
        break;
      case 11:
        this.currentMonth = "December";
        break;
    }
  }

  closeSnackBar(){
    this.snackBarRef.dismiss();
  }
}
