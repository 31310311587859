import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { ContentTileLpService } from '../shared/content-tiles/content-tile-lp/content-tile-lp.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationComponent } from '../classroom/dialog/confirmation/confirmation.component';
@Injectable({
  providedIn: 'root'
})
export class CdmService {
  config: Ienvironment;
  filtersObj: any;
  role: BehaviorSubject<string | null>;
  loadedRequestsDetails: BehaviorSubject<any>;
  filtersForRequests: BehaviorSubject<any>;
  filtersForPendingRequests: BehaviorSubject<any>;
  isSearchInProgress: BehaviorSubject<boolean>;
  filtersForReviewRequests: BehaviorSubject<any>;
  filtersForActionedRequests: BehaviorSubject<any>;
  filtersForAssignedRequests: BehaviorSubject<any>;
  filtersForSearch: BehaviorSubject<any>;
  searchResults: BehaviorSubject<any>;
  intentList: any;
  requests: any = 0;
  actionedReqLoading: BehaviorSubject<any>;
  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private contentTileLpService: ContentTileLpService,
    private _popup: MatDialog
  ) {
    this.config = this._envSvc.getEnvironment();
    this.filtersObj = {
      limit: 20,
      index: 0,
      nextOrPrevious: 'next',
      acceptedRequests: false,
      actionedNo: false,
      actionedYes: false,
      arrangeByCourse: false,
      arrangeByDate: false,
      billablestatus: '',
      competency: '',
      courseName: '',
      deniedRequests: false,
      isACMorPCMAssigned: false,
      isOther201sInProgress: false,
      location: '',
      onHoldRequests: false,
      preRequisiteCoursesCompleted: false,
      preRequisiteCoursesNonCompleted: false,
      resignationstatus: '',
      searchMids: [],
      PeopleOwner: ''
    };
    this.intentList = [];
    this.loadedRequestsDetails = new BehaviorSubject({
      loadedRequestsLength: 0,
      totalRequestsLength: 0
    });
    this.filtersForRequests = new BehaviorSubject({
      ...this.filtersObj
    });
    this.isSearchInProgress = new BehaviorSubject(false);
    this.searchResults = new BehaviorSubject([]);
    this.role = new BehaviorSubject(null);
    this.actionedReqLoading = new BehaviorSubject(true);
  }
  getRoleOfUser() {
    return this.role.asObservable();
  }
  updateRoleOfUser(data) {
    this.role.next(data);
  }
  getLoadedRequestsDetails() {
    return this.loadedRequestsDetails.asObservable();
  }
  updateLoadedRequestsDetails(data) {
    this.requests = data.loadedRequestsLength;
    this.loadedRequestsDetails.next(data);
  }
  getRequestsFilters() {
    return this.filtersForRequests.asObservable();
  }
  updateRequestsFilters(data) {
    this.filtersForRequests.next({ ...this.filtersObj, ...data });
  }
  getSearchStatus() {
    return this.isSearchInProgress.asObservable();
  }
  updateSearchStatus(data) {
    this.isSearchInProgress.next(data);
  }
  getSearchResults() {
    return this.searchResults.asObservable();
  }
  updateSearchResults(data) {
    this.searchResults.next(data);
  }
  getPendingRequests(limit, index, nextOrPrevious, data, userRole) {
    let URL = '';
    if (userRole === 'cdm') {
      URL =
        'Workflow/Requests/Pending/' +
        limit +
        '/' +
        index +
        '/' +
        nextOrPrevious;
    } else if (userRole === 'rm') {
      URL =
        'Workflow/Requests/RMPending/' +
        limit +
        '/' +
        index +
        '/' +
        nextOrPrevious;
    }
    return this.http.post(this.config.apiUrl + URL, data).toPromise();
  }
  getLearningIntentList() {
    return this.http
      .get<any>(this.config.apiUrl + 'Course/GetLearningIntentList/learningpartner')
      .toPromise();
  }
  getReviewRequests(limit, index, nextOrPrevious, data, userRole) {
    let URL = '';
    if (userRole === 'cdm') {
      URL =
        'Workflow/Requests/UnderReview/' +
        limit +
        '/' +
        index +
        '/' +
        nextOrPrevious;
    } else if (userRole === 'rm') {
      URL =
        'Workflow/Requests/RMUnderReview/' +
        limit +
        '/' +
        index +
        '/' +
        nextOrPrevious;
    }
    return this.http.post(this.config.apiUrl + URL, data).toPromise();
  }
  getActionedRequests(limit, index, nextOrPrevious, data, userRole) {
    let URL = '';
    if (userRole === 'cdm') {
      URL =
        'Workflow/Requests/Actioned/' +
        limit +
        '/' +
        index +
        '/' +
        nextOrPrevious;
    } else if (userRole === 'rm') {
      URL =
        'Workflow/Requests/RMActioned/' +
        limit +
        '/' +
        index +
        '/' +
        nextOrPrevious;
    }
    return this.http.post(this.config.apiUrl + URL, data).toPromise();
  }
  getAssignedRequests(limit, index, nextOrPrevious, data) {
    return this.http
      .post(
        this.config.apiUrl +
        'Workflow/Requests/Assigned/' +
        limit +
        '/' +
        index +
        '/' +
        nextOrPrevious,
        data
      )
      .toPromise();
  }
  getSearchResultsFromAPI(
    limit,
    index,
    nextOrPrevious,
    data,
    isCertificationSelected,
    userRole
  ) {
    if (isCertificationSelected) {
      this.getCertificationSearchResults(data['searchMids'][0]);
    }
    let URL = '';
    if (userRole == 'cdm') {
      URL = 'Workflow/CDM/Search/' + limit + '/' + index + '/' + nextOrPrevious;
    } else if (userRole == 'rm') {
      URL = 'Workflow/RMSearch/' + limit + '/' + index + '/' + nextOrPrevious;
    }
    this.http
      .post(this.config.apiUrl + URL, data)
      .toPromise()
      .then((requests: any) => {
        if (requests == null) {
          requests.ActionedRequestsList = [];
          requests.RequestCount = 0;
        }
        this.updateLoadedRequestsDetails({
          loadedRequestsLength: requests.ActionedRequestsList.length,
          totalRequestsLength: requests.RequestCount
        });
        this.updateSearchResults(requests.ActionedRequestsList);
      })
      .catch(err => {
        this.updateSearchResults([]);
      });
  }
  getCertificationSearchResults(searchMids) {
    this.http
      .post(
        this.config.apiUrl +
        'Workflow/CDM/CertificateSearch?searchMids=' +
        searchMids,
        {}
      )
      .toPromise()
      .then((requests: any) => {
        this.updateLoadedRequestsDetails({
          loadedRequestsLength: requests.length,
          totalRequestsLength: requests.length
        });
        this.updateSearchResults(requests);
      })
      .catch(err => {
        this.updateSearchResults([]);
      });
  }
  getCertificateApprovalCDMorRM(role, limit, index, nextOrPrevious) {
    let url;
    //////console.log(role);
    if (role === 'cdm') {
      url = 'Get/ExternalCertificateDetails/';
    } else {
      url = 'Get/RMExternalCertificateDetails/';
    }
    return this.http
      .post(
        this.config.apiUrl + url + limit + '/' + index + '/' + nextOrPrevious,
        {}
      )
      .toPromise();
  }
  getPSSFilters() {
    return this.http.get(this.config.apiUrl + 'PSS/Filters').toPromise();
  }
  getCompetencyList() {
    return this.http
      .get(this.config.apiUrl + 'Workflow/CapacityReport/CompetencyList')
      .toPromise();
  }
  getLocationList() {
    var url = new URL(this.config.apiUrl + 'Workflow/CapacityReport/LocationList');
    return this.http
      .get(url.href)
      .toPromise();
  }
  getBillableStatusList() {
    return this.http
      .get(this.config.apiUrl + 'Workflow/CapacityReport/BillableStatusList')
      .toPromise();
  }
  searchUser(name) {
    // var url="https://graph.windows.net/mindtree.com/users?api-version=1.6&$filter=startswith(displayName,'"+name+"') or startswith(userPrincipalName,'"+name+"')&$top=5";
    var url = "https://graph.microsoft.com/v1.0/users?$count=true&$filter=startswith(displayName,'" + name + "') or endsWith(userPrincipalName,'" + name + "%40ltimindtree.com')&$top=5&ConsistencyLevel=eventual";
    return this.http
      .get(url)
      .toPromise();
  }
  approveRequests(payload, isCertificate, userRole) {
    if (userRole == null || userRole == "") {
      userRole = "NA"
    }
    return this.http
      .post(
        this.config.apiUrl + 'Course/Approve/items/' + isCertificate + '/' + userRole,
        payload
      )
      .toPromise();
  }
  denyRequests(payload, isCertificate, userRole) {
    if (userRole == null || userRole == "") {
      userRole = "NA"
    }
    return this.http
      .post(
        this.config.apiUrl + 'Course/Reject/items/' + isCertificate + '/' + userRole,
        payload
      )
      .toPromise();
  }
  holdRequests(payload, isCertificate) {
    return this.http
      .post(
        this.config.apiUrl + 'Workflow/CDM/OnHold/' + isCertificate,
        payload
      )
      .toPromise();
  }
  getCourseStatusInsidePackage(requestId) {
    return this.http
      .post(this.config.apiUrl + 'Workflow/CDM/201Status/' + requestId, {})
      .toPromise();
  }

  setIsMindOnBench(payload) {
    return this.http
      .post(
        this.config.apiUrl + 'Course/UpdateIsMindOnBenchOnApproval',
        payload
      )
      .toPromise();
  }

  getIntents() {
    if (this.intentList.length == 0) {
      this.contentTileLpService.getLearningIntentList('learningpartner').then((res: any) => {
        const intentList = res;
        intentList.forEach((ele: any) => {
          this.intentList.push({
            IntentId: ele.IntentId,
            IntentName: ele.IntentName,
            LearnerIntentDescription: ele.LearnerIntentDescription,
            ApproverIntentDescription: ele.ApproverIntentDescription
          });
        });
      });
    }
  }
  updateActionedReqLoading(val) {
    this.actionedReqLoading.next(val);
  }
  getActionedReqLoading() {
    return this.actionedReqLoading.asObservable();
  }

  getPeopleOwnerList() {
    return this.http.get(this.config.apiUrl + 'Workflow/CapacityReport/PeopleOwner').toPromise();
  }

  getcoursestatus(workflowstatus) {

    return this.http.get(this.config.apiUrl + 'Workflow/Requests/Pendingcourses/' + workflowstatus).toPromise();
  }

  classroomConfirmationPopup(requestsId: any[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "popupDialogContainer"
    dialogConfig.width = "400px";
    dialogConfig.data = {
      id: "CourseRequestReject",
      title: "Classroom Course Rejected",
      requestsId: requestsId
    }
    const afterDialogClosed = this._popup.open(ConfirmationComponent, dialogConfig);
    afterDialogClosed.afterClosed().subscribe(() => { });
  }
  getRequestHistory(payload){
    return this.http.get(this.config.apiUrl + `Workflow/Requests/CancelledRequests/${payload.UserId}/${payload.RequestId}`);
  }
  postData(payload: any, urlParam:any): Observable<any> {
    const Url =
    this.config.apiUrl +
    'PSS/CertificateRequest/Pending/' +
    urlParam.limit +
    '/' +
    urlParam.count +
    '/' +
    urlParam.nextOrPrevious;
    return this.http.post<any>(Url, payload);
  }
}
