<mat-dialog-content class="videoPopup">
    <div class="close">
        <button mat-button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <!-- header -->
    <div class="header">{{videoTitle}}</div>
    <mat-divider></mat-divider>

    <yorbit-youtube-video-player *ngIf="!data.Type || (data.Type && data.Type == '' || data.Type == null || data.Type == 'youtube') " [PlayerConfig]="videoConfig"></yorbit-youtube-video-player>
    <video *ngIf="data.Type == 'stream' && isStreamVideoLoaded==true"  controls>
        <source [src]="getSanitizedURL()">
    </video>
</mat-dialog-content>