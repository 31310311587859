import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'yorbit-course-name',
  templateUrl: './course-name.component.html',
  styleUrls: ['./course-name.component.scss']
})
export class CourseNameComponent implements OnInit {
  options: any;
  courseName: string;
  @Output() courseNameUpdateEvent = new EventEmitter<string>();
  constructor(){
  }
  @Input() filterData: any;
  courseNameControl = new FormControl();
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.courseNameInit();
  }
  courseNameInit(){
    this.courseName='';
    this.options= this.filterData.data.CourseNames;
    this.filteredOptions = this.courseNameControl.valueChanges.pipe(
      startWith(''),
      map(value => this.namefilter(value))
    );
  }

  namefilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if(filterValue&&this.options){
      return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  onOptionSelected(event){
    this.courseNameUpdateEvent.emit("course name")
  }

}
