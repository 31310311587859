import { ILPBreadcrumbs,IPackageDetails,ICourseDetails, IVideoDetails ,IUnitDetails} from './learning-path-breadcrumbs.interface';

export const ILPDetailsModel={
    LPCategory: null,
    LPName: null,
    IsLPMandatory: null,
    LPId: null,
    SkillPlanId:null,
}
export const IPackageDetailsModel:IPackageDetails={
    PackageName: null,
    PackageId: null,
    Expertise: null,
    IsAccount:false,
    IsProject:false,
    AccountId:null,
    ProjectId:null
}
export const ICourseDetailsModel:ICourseDetails={
    CourseName: null,
    CourseId: null,
    Expertise: null,
    IsAccount:false,
    IsProject:false,
    AccountId:null,
    ProjectId:null,
    VideoCount:0,
    IsInternal:false,
    Vendor:null,
    Workflow:null
}
export const IUnitDetailsModel:IUnitDetails={
    UnitName: null,
    UnitId: null,
    IsAccount:false,
    IsProject:false,
    AccountId:null,
    ProjectId:null,
    ContentCount:0
}
export const IVideoDetailsModel:IVideoDetails={
    VideoType: null,
    VideoId: null,
    VideoName: null,
    IsAccount:false,
    IsProject:false,
    AccountId:null,
    ProjectId:null,
    IsCompliance:false
}
export const LPBreadcrumbsStateModel: ILPBreadcrumbs = {
    LPDetails:{...ILPDetailsModel},
    PackageDetails:{...IPackageDetailsModel},
    CourseDetails:{...ICourseDetailsModel},
    UnitDetails:{...IUnitDetailsModel},
    VideoDetails:{...IVideoDetailsModel}
 };
