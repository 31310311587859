<mat-dialog-content>
  <div class="closePopup">
    <button mat-button mat-icon-button (click)="closeUpdatePopup()" [disabled]="saveActivated && !(this.completed.Infrastructure&&
    this.completed.CourseLogin&&
    this.completed.CourseCompletion&&
    this.completed.ClassRoom&&
    this.completed.ProjectWork)" [ngClass]="{ disabled: saveActivated && !(this.completed.Infrastructure&&
      this.completed.CourseLogin&&
      this.completed.CourseCompletion&&
      this.completed.ClassRoom&&
      this.completed.ProjectWork)}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="header">
    <span *ngIf="saveActivated">Information</span>
    <span *ngIf="!saveActivated && (0 < validationList.invalid.length)">Information</span>
    <span *ngIf="!saveActivated && !(0 < validationList.invalid.length)">Save Details</span>  
  </div>
  <mat-divider></mat-divider>

  <div class="spacePadding">
    <div class="content-font" *ngIf="!saveActivated">
      <div>
        <div class="textCenterAlign title-font">
          {{((!(validationList.invalid.length > 0))?('Edited fields have been validated successfully. \n Do you want to Save the details?'):('Sorry the form cannot be saved! Please review the details provided.'))}}
        </div>
      </div>
      <!--
      <div *ngIf="0<validationList.valid.length">
        <div class="textCenterAlign title-font">
          Validation successful for the following contents, and will be saved.
        </div>
        <div *ngFor="let validList of validationList.valid;let listIndex=index" class="space-left validation-msg-font">
          {{listIndex+1}}. {{validList}}
        </div>
      </div>
      <div *ngIf="0<validationList.invalid.length">
        <div class="textCenterAlign title-font">
          Validation failed for the following contents, and will not be saved.
        </div>
        <div *ngFor="let invalidList of validationList.invalid;let listIndex=index" class="space-left validation-msg-font">
          {{listIndex+1}}. {{invalidList}}
        </div>
      </div>
    -->
      <div class="action-btn-holder">
        <button class="action-button"
        title="{{0<validationList.invalid.length?'Validation failed for the Edited contents, and will not be saved.':''}}"
        [ngClass]="{mouseDisabled:(0<validationList.invalid.length)}"
        [disabled]="(0<validationList.invalid.length)" 
        *ngIf="!(0<validationList.invalid.length)" (click)="saveAll()">Save</button>
        <button class="action-button space-left" (click)="closeUpdatePopup()">
           {{(!(validationList.invalid.length>0))?('Cancel'):('OK')}}
        </button>        
      </div>
    </div>
    <div *ngIf="saveActivated">
      <app-preloader *ngIf="!(this.completed.Infrastructure&&
                              this.completed.CourseLogin&&
                              this.completed.CourseCompletion&&
                              this.completed.ClassRoom&&
                              this.completed.ProjectWork)"></app-preloader>
      <div *ngIf="(this.completed.Infrastructure &&
            this.completed.CourseLogin &&
            this.completed.CourseCompletion &&
            this.completed.ClassRoom &&
            this.completed.ProjectWork)">
        <div class="textCenterAlign">
          Your modifications have been saved successfully.
        </div>
        <div class="action-btn-holder">
          <button class="action-button space-left" (click)="closeUpdatePopup()">OK</button>
        </div>
      </div>

      <div>
      </div>
    </div>
  </div>

</mat-dialog-content>
