import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class ContentTileLpService {
  config: Ienvironment;
  MandatoryLPProgressStatus: any;
  isTargetCourse$: any;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.MandatoryLPProgressStatus = <BehaviorSubject<number>>(
      new BehaviorSubject(0)
    );
    this.isTargetCourse$ = <BehaviorSubject<boolean>>(
      new BehaviorSubject(false)
    );
  }
  getDeclareWorkflowStatus(payload) {
    /**
     * payload:{ CourseId:courseId, Expertise:expertise}
     *
     */
    return this.http
      .post(
        this.config.apiUrl +
        'SkillPlan/DeclareFlagStatus'
        ,
        payload
      )
      .toPromise();
  }

  getMandatoryDates(lpname) {
    return this.http
      .get<any>(
        this.config.apiUrl +
        'User/Mandatory/Dates/' + lpname)
      .toPromise();
  }

  getLearningIntentList(role) {
    //console.log('fasd', role);
    return this.http
      .get<any>(
        this.config.apiUrl +
        'Course/GetLearningIntentList/' + role)
      .toPromise();
  }

  getMandatoryLPProgressStatus() {
    return this.MandatoryLPProgressStatus.asObservable();
  }
  updateMandatoryLPProgressStatus(isProgress: number) {
    this.MandatoryLPProgressStatus.next(isProgress);
  }

  getIsTargetCourse$() {
    return this.isTargetCourse$.asObservable();
  }
  updateIsTargetCourse$(isTargetCourse: boolean) {
    this.isTargetCourse$.next(isTargetCourse);
  }
}
