<div class="evaluation-template-download" fxFlex fxLayoutAlign="end center">
  
  <div class="yorbitSprite icon" 
      (click)="downloadEvaluationSheet()"></div>

      <div fxLayoutAlign="end center">
        <div fxFlexOffset="-20px">{{downloadStatus}}</div>
    </div>

      <div *ngIf="showPreloader">
        <app-preloader></app-preloader>
</div>
<div *ngIf="!contentLoadedSuccessfully && !showPreloader">
    Technical issue in loading content. Please try again
</div>
