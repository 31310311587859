import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CoursesPopupComponent } from '../../shared/yorbit-courses/courses-popup/courses-popup.component';
import { ContentUploadPackageService } from './content-upload-package.service';
import { BrowseService } from '../../browse/browse.service';
import { DatePipe, getLocaleDateTimeFormat } from '@angular/common';
import { ChildActivationStart } from '@angular/router';
import { ContentUpload101PopupsComponent } from '../content-upload-101/content-upload101-popups/content-upload101-popups.component';
import { ReturnStatement } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentUploadStatusPopupComponent } from '../content-upload-status-popup/content-upload-status-popup.component';
import { YorbitCoursesComponent } from '../../shared/yorbit-courses/courses/yorbit-courses.component';
import { YorbitCoursesService } from '../../shared/services/yorbit-courses.service';


@Component({
  selector: 'yorbit-content-upload-package',
  templateUrl: './content-upload-package.component.html',
  styleUrls: ['./content-upload-package.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentUploadPackageComponent implements OnInit {

  constructor(private _contentUploadPackageService: ContentUploadPackageService,
    private _popup: MatDialog,
    private _browse: BrowseService,
    private _datePipe: DatePipe,
    private _messagePopUp: MatDialog,
    private _snackBar: MatSnackBar,
    private _courseComponent: YorbitCoursesService) { }

  @Output() enableContentVerificationComponent = new EventEmitter<any>();
  @Input() selectedContent;

  //List for storing values
  academyList = [];
  taxonomy = [];
  genreList = [];
  genreSkillList = [];
  skillList = [];
  //filteredPreReqCourses = [];

  //for storing selected project ID
  projectID: number = 0;

  //for storing selected account ID
  accountID: string;

  //for the course selection boxes
  selectedYorbitCoursesList = [];
  selectedAccountCoursesList = [];
  selectedProjectCoursesList = [];
  selectedYorbitCoursesUniqueIDS = [];
  selectedAccountCoursesUniqueIDS = [];
  selectedProjectCoursesUniqueIDS = [];

  //for the dropdowns
  accounts = [];
  projects = [];
  categories = [];

  //Error variables for showing error messages at the bottom of the input elements
  academyError: boolean = false;
  genreError: boolean = false;
  skillError: boolean = false;
  packageNameError: boolean = false;
  packageDescError: boolean = false;
  yorbitCoursesSelectedError: boolean = false;
  isOptionsShown: boolean = false;
  yorbitCourseIndex: number = -1;
  actCourseIndex: number = -1;
  projectCourseIndex: number = -1;
  accountError: boolean = false;
  projectError: boolean = false;
  categoryError: boolean = false;
  selectedAccountCoursesError: boolean = false;
  selectedProjectCoursesError: boolean = false;
  packageNameExistsError: boolean = false;
  customCategoryError: boolean = false;
  categoryExistsError: boolean = false;
  academyHiddenState = false;
  genreHiddenState = false;
  skillHiddenState = false;
  accountHiddenState = false;
  projectHiddenState = false;
  categoryHiddenState = false;
  status = true;

  //Form group for the form controls
  packageUploadFormGroup = new FormGroup({
    courseType: new FormControl('yorbit'),
    academy: new FormControl(''),
    genre: new FormControl({ value: '', disabled: true }),
    skill: new FormControl({ value: '', disabled: true }),
    account: new FormControl(''),
    project: new FormControl({ value: '', disabled: true }),
    category: new FormControl({ value: '', disabled: true }),
    customCategory: new FormControl({ value: '', disabled: true }),
    packageName: new FormControl({ value: '', disabled: false }),
    packageDesc: new FormControl({ value: '', disabled: false })
  });

  //Title on hover for dropdowns
  genreTitle = "Please select an Academy";
  skillTitle = "Please select a Genre";
  projectTitle = "Please select an Account";
  categoryTitle = "Please select a Project";


  ngOnInit(): void {

    //Getting account details from getAccountsProjectsCategories() in browse.service.ts
    this._browse.getAccountsProjectsCategories().then((res)=>{
      this.accounts = res['AccountsList'];
    }).then(()=>{
          this.accounts.sort((a,b) => a.AccountName.localeCompare(b.AccountName));
          this.accounts.forEach((ele) => {
        if (this.selectedContent && ele.AccountName == this.selectedContent['RepoName']) {
            var Projects = ele['ProjectsList'] != null ? ele['ProjectsList'] : [];
            Projects.forEach(element => {
            if (element.ProjectId == this.selectedContent.RepoId) {
              this.projects.push({ "ProjectName": element['ProjectName'] });
              this.packageUploadFormGroup.get('project').setValue(element['ProjectName']);
            }
          });
       
        }
      });
    });

    if (this.selectedContent != undefined) {

      var YorbitCourseData = [];
      var YorbitCourses = [];
      var AccountCourseData = [];
      var AccountCourses = [];
      var ProjectCourseData = [];
      var ProjectCourses = [];
      var ACP = [];
      this.genreTitle = "";
      this.skillTitle = "";
      this.projectTitle = "";
      this.categoryTitle = "";

      //If yorbit courses are there
      if (this.selectedContent.YorbitCourseUniqueIds != null && this.selectedContent['YorbitCourseUniqueIds'].trim() != "") {
        YorbitCourseData = this.selectedContent.YorbitCourseUniqueIds.split(",");
        this._courseComponent.getAllYorbitCourses().subscribe(res => {
          YorbitCourses = res;
           
          //extract name from json response
          YorbitCourseData.forEach(Cid => {
            this.selectedYorbitCoursesList.push(YorbitCourses.filter(x => String(x.UniqueId).trim() == String(Cid).trim())[0]);
          });
          this.selectedYorbitCoursesList = this.selectedYorbitCoursesList.filter(x => x != undefined);
        });
      }

      //if account courses are there
      if (this.selectedContent.AccountCourseUniqueIds != null && this.selectedContent['AccountCourseUniqueIds'].trim() != "") {
        AccountCourseData = this.selectedContent.AccountCourseUniqueIds.split(",");
        this._courseComponent.getAllAccountCourses().subscribe(res => {
          AccountCourses = res;

          //extract name from json response
          AccountCourseData.forEach(Cid => {
            this.selectedAccountCoursesList.push(AccountCourses.filter(x => x.UniqueId == Cid)[0]);
          });
          this.selectedAccountCoursesList = this.selectedAccountCoursesList.filter(x => x != undefined);
        });
      }

      //if project courses are there
      if (this.selectedContent.ProjectCourseUniqueIds != null && this.selectedContent['ProjectCourseUniqueIds'].trim() != "") {
        ProjectCourseData = this.selectedContent.ProjectCourseUniqueIds.split(",");
        this._courseComponent.getAllProjectCourses().subscribe(res => {
          ProjectCourses = res;

          //extract name from json response
          ProjectCourseData.forEach(Cid => {
            this.selectedProjectCoursesList.push(ProjectCourses.filter(x => x.UniqueId == Cid)[0]);
          });
          this.selectedProjectCoursesList = this.selectedProjectCoursesList.filter(x => x != undefined);
        });
      }

      //  return;
      this.packageUploadFormGroup.get('courseType').setValue(String(this.selectedContent.RepoType).toLowerCase());
      this.packageUploadFormGroup.get('courseType').disable();

      this.packageUploadFormGroup.get('packageName').setValue(this.selectedContent.Name);
      this.packageUploadFormGroup.get('packageName').disable();

      this.packageUploadFormGroup.get('packageDesc').setValue(this.selectedContent.Description);
      this.packageUploadFormGroup.get('packageDesc').disable();

      this.packageUploadFormGroup.get('academy').disable();
      this.academyList.push(this.selectedContent.Genre);
      this.packageUploadFormGroup.get('academy').setValue(this.selectedContent.Academy);

      this.packageUploadFormGroup.get('genre').disable();
      this.genreList.push(this.selectedContent.Genre);
      this.packageUploadFormGroup.get('genre').setValue(this.selectedContent.Genre);

      this.packageUploadFormGroup.get('skill').disable();
      this.skillList.push(this.selectedContent.Skill);
      this.packageUploadFormGroup.get('skill').setValue(this.selectedContent.Skill);

      this.packageUploadFormGroup.get('account').disable();
      this.packageUploadFormGroup.get('account').setValue(this.selectedContent.RepoName);

      this.packageUploadFormGroup.get('category').disable();
      this.categories.push(this.selectedContent.Category);
      this.packageUploadFormGroup.get('category').setValue(this.selectedContent.Category);


      // this.accounts.forEach((ele) => {
      //   if (ele.AccountName == this.selectedContent.RepoName) {
      //     var Projects = ele['ProjectsList'];
      //     Projects.forEach(element => {
      //       if (element.ProjectId == this.selectedContent.RepoId) {
      //         this.projects.push({ "ProjectName": element['ProjectName'] });
      //         this.packageUploadFormGroup.get('project').setValue(element['ProjectName']);
      //       }
      //     });
      //   }
      // });

    }

    //When shifted to another package type, reset the form and errors using valueChanges event
    this.packageUploadFormGroup.get('courseType').valueChanges.subscribe((res) => {
      this.yorbitCourseIndex = -1;
      this.actCourseIndex = -1;
      this.projectCourseIndex = -1;
      this.selectedYorbitCoursesList = [];
      this.selectedYorbitCoursesUniqueIDS = [];
      this.selectedAccountCoursesList = [];
      this.selectedAccountCoursesUniqueIDS = [];
      this.selectedProjectCoursesList = [];
      this.selectedProjectCoursesUniqueIDS = [];
      this.categories = [];
      this.packageUploadFormGroup.get('academy').setValue('');
      this.packageUploadFormGroup.get('genre').setValue('');
      this.packageUploadFormGroup.get('genre').disable();
      this.packageUploadFormGroup.get('skill').setValue('');
      this.packageUploadFormGroup.get('skill').disable();
      this.packageUploadFormGroup.get('account').setValue('');
      this.packageUploadFormGroup.get('category').setValue('');
      this.packageUploadFormGroup.get('category').disable();
      this.packageUploadFormGroup.get('project').setValue('');
      this.packageUploadFormGroup.get('project').disable();
      this.packageUploadFormGroup.get('packageName').setValue('');
      this.packageUploadFormGroup.get('packageDesc').setValue('');
      this.packageUploadFormGroup.get('customCategory').setValue('');
      this.packageUploadFormGroup.get('customCategory').disable();


      this.academyError = false;
      this.genreError = false;
      this.skillError = false;
      this.packageNameError = false;
      this.packageDescError = false;
      this.yorbitCoursesSelectedError = false;

      this.accountError = false;
      this.categoryError = false;
      this.packageNameError = false;
      this.packageDescError = false;
      this.selectedAccountCoursesError = false;

      this.projectError = false;
      this.selectedProjectCoursesError = false;

      this.packageNameExistsError = false;
      this.projectID = 0;
      this.academyHiddenState = false;
      this.genreHiddenState = false;
      this.skillHiddenState = false;
      this.accountHiddenState = false;
      this.projectHiddenState = false;
      this.categoryHiddenState = false;
      this.customCategoryError = false;
      this.categoryExistsError = false;

      //Resetting the error messages
      this.genreTitle = "Please select an Academy";
      this.skillTitle = "Please select a Genre";
      this.projectTitle = "Please select an Account";

      switch (res) {
        case "project":
          this.categoryTitle = "Please select a Project";
          break;
        case "account":
          this.categoryTitle = "Please select an Account";
          break;
        default:
          this.categoryTitle = "Please select an Account";
          break;
      }

      //Disabling add new category button
      this.status = true;
    });


    this.getTaxanomy();
    this.getYorbitCourses();
    this.getAccountCourses();
    this.getAllProjectCourses();
  }

  //Filtering projects in Project Package type
  filterProjects(): void {

    if (this.packageUploadFormGroup.get('courseType').value == "project") {
      if (this.packageUploadFormGroup.get('account').value == "") {
        this.accountError = true;
        this.packageUploadFormGroup.get('project').setValue('');
        this.packageUploadFormGroup.get('project').disable();
        this.packageUploadFormGroup.get('category').setValue('');
        this.packageUploadFormGroup.get('category').disable();
        this.packageUploadFormGroup.get('customCategory').setValue('');
        this.packageUploadFormGroup.get('customCategory').disable();
      }
      else {
        this.accountError = false;
        this.packageUploadFormGroup.get('project').enable();
        this.accountHiddenState = true;
        this.projectTitle = "";

        this.accounts.forEach((ele) => {
          if (ele.AccountName == this.packageUploadFormGroup.get('account').value) {
            this.accountID = ele.AccountId;
            if (ele.ProjectsList == null) {
              this.projects = [];
            }
            else {
              this.projects = ele.ProjectsList;
              this.projects.sort((a, b) => a.ProjectName.localeCompare(b.ProjectName));
            }
          }
        });

      }
    }
    else {
      return;
    }
  }

  //Used to filter category list for projects in Project Package type
  filterProjectCategories(): void {

    if (this.packageUploadFormGroup.get('project').value != '') {
      this.projectHiddenState = true;
      this.projectTitle = "";
      this.status = false;
      this.categoryTitle = "";
    }


    this.categories = [];
    this.accounts.forEach((ele) => {

      if (ele.AccountName == this.packageUploadFormGroup.get('account').value && ele.ProjectsList === null) {
        this.categories = [];
      }
      else {

        if (ele.AccountName == this.packageUploadFormGroup.get('account').value && ele.ProjectsList != null) {
          ele.ProjectsList.forEach((elements) => {
            if (elements.ProjectName == this.packageUploadFormGroup.get('project').value) {
              this.projectID = elements.ProjectId;
              if (elements.CategoryList == null) {
                this.categories = [];
              }
              else {
                elements.CategoryList.forEach(category => {

                  if (category.trim() != "") {
                    this.categories.push(category);
                    this.categories.sort();
                  }
                });
              }
            }
          });
        }
      }
    });

    this.validateProjectPackage();
  }

  //Called to validate the project package form
  validateProjectPackage(): void {

    //if account is not selected, disabled all fields after since they are dependent on account value
    if (this.packageUploadFormGroup.get('account').value == "") {
      this.accountError = true;
      this.packageUploadFormGroup.get('project').setValue('');
      this.packageUploadFormGroup.get('project').disable();

      this.packageUploadFormGroup.get('category').setValue('');
      this.packageUploadFormGroup.get('category').disable();

      this.packageUploadFormGroup.get('customCategory').setValue('');
      this.packageUploadFormGroup.get('customCategory').disable();
    }
    else {
      this.accountError = false;
      this.packageUploadFormGroup.get('project').enable();
    }


    if (this.packageUploadFormGroup.get('project').value == "") {
      this.projectError = true;
      this.packageUploadFormGroup.get('category').setValue('');
      this.packageUploadFormGroup.get('category').disable();

      this.packageUploadFormGroup.get('customCategory').setValue('');
      this.packageUploadFormGroup.get('customCategory').disable();
      this.status = true;
    }
    else {
      this.projectError = false;
      this.packageUploadFormGroup.get('category').setValue('');
      this.packageUploadFormGroup.get('category').enable();
      this.packageUploadFormGroup.get('customCategory').enable();
    }

    if (this.packageUploadFormGroup.get('category').value != "") {
      this.categoryTitle = "";
    }
  }


  //Filtering account categories in account package type
  filterAccountCategories(): void {

    this.categories = [];
    if (this.packageUploadFormGroup.get('courseType').value == "account") {
      if (this.packageUploadFormGroup.get('account').value == "") {
        this.accountError = true;
        this.packageUploadFormGroup.get('category').disable();
        this.packageUploadFormGroup.get('customCategory').disable();

        this.packageUploadFormGroup.get('customCategory').setValue('');
        this.packageUploadFormGroup.get('customCategory').disable();
      }
      else {
        this.accountError = false;
        this.packageUploadFormGroup.get('category').enable();
        this.packageUploadFormGroup.get('customCategory').enable();
        this.status = false;
        this.accountHiddenState = true;
        this.categoryTitle = "";
      }
      this.accounts.forEach((ele) => {
        if (ele.AccountName == this.packageUploadFormGroup.get("account").value) {
          this.accountID = ele.AccountId;
          if (ele.CategoryList == null) {
            this.categories = [];
          }
          else {
            ele.CategoryList.forEach(element => {
              this.categories.push(element);
              this.categories.sort();
            });
          }
        }
      });
    }
    else {
      return;
    }

  }

  /* Common method for validating input content
  type: type of input field
  - name : Validating name of package
  - description : Validating Package Description
  - category : Validating add new category textbox
  */
  validateInput(type: string) {
    if (type == 'name') {

      if (this.packageUploadFormGroup.get('packageName').value.split(" ").length == this.packageUploadFormGroup.get('packageName').value.length + 1 &&
        this.packageUploadFormGroup.get('packageName').value != "") {
        this.packageNameError = true;
      }
      else {
        this.packageNameError = false;
      }
    }

    else if (type == 'description') {

      if (this.packageUploadFormGroup.get('packageDesc').value.split(" ").length == this.packageUploadFormGroup.get('packageDesc').value.length + 1 ||
        this.packageUploadFormGroup.get('packageDesc').value.trim().length < 150 &&
        this.packageUploadFormGroup.get('packageDesc').value != "") {
        this.packageDescError = true;
      }
      else {
        this.packageDescError = false;
      }
    }

    else if (type == 'category') {

      var customCategory = String(this.packageUploadFormGroup.get('customCategory').value).toLowerCase();
      
      //If special characters are found or if all are spaces
      if ((customCategory.split(" ").length == customCategory.length + 1) &&
        customCategory != "") {
        this.customCategoryError = true;
      }
      else {
        this.customCategoryError = false;
      }

      //If category already exists
      if (this.categories.findIndex(value => value.toLowerCase().trim() == customCategory) != -1 &&
        customCategory != "") {
        this.categoryExistsError = true;
      }
      else {
        this.categoryExistsError = false;
      }
    }
  }

  //Method for adding a new category for both account and project package type
  addNewCategory(): void {
    var customCategory = String(this.packageUploadFormGroup.get("customCategory").value);

    //If category already exists
    if (this.categories.findIndex(value => value.toLowerCase().trim() == customCategory.toLowerCase().trim()) != -1) {
      this.categoryExistsError = true;
      return;
    }
    else {
      this.categoryExistsError = false;
    }

    //Check for valid category name
    if (customCategory.toLowerCase().trim() != "") {
      this.categories.push(customCategory);
      this.packageUploadFormGroup.get("customCategory").setValue('');
      this._snackBar.open("A new Category has been added succesfully!", '', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.customCategoryError = false;
    } else {
      this.customCategoryError = true;
      return;
    }

  }

  //validating to check if a valid category is chosen or not
  validateCategory(): void {
    if (this.packageUploadFormGroup.get('category').value == "") {
      this.categoryError = true;
    }
    else {
      this.categoryError = false;
      this.categoryHiddenState = true;
      this.categoryTitle = "";
    }
  }

  //Called from ngOnInit to get the academy details for SHOSHIN SCHOOL Package type
  getTaxanomy() {
    this.academyList = [];
    this.taxonomy = [];
    this._contentUploadPackageService.getTaxanomy().subscribe(
      (response: any) => {
        if (response !== null && response.AcademyList != undefined && response.AcademyList.length > 0) {
          this.taxonomy = response.AcademyList;
          this.taxonomy.forEach(value => {
            this.academyList.push(value.Academy);
          });
          this.academyList.sort((a,b) => a.localeCompare(b));
        }
        else {
          this.taxonomy = [];
        }
      },
      (error: any) => {
        this.taxonomy = [];
      }

    );
  }

  //called to change values in Genre List when Academy value changes
  changeGenreOptions(): void {
    if (this.packageUploadFormGroup.get('academy').value == "") {
      this.academyError = true;
    }
    else {
      this.academyError = false;
      this.academyHiddenState = true;
    }

    this.filterGenre();
  }

  //called to change values in Skill List when Genre value changes
  changeSkillOptions(): void {
    if (this.packageUploadFormGroup.get('genre').value == "") {
      this.genreError = true;
    }
    else {
      this.genreError = false;
      this.genreHiddenState = true;
      this.genreTitle = "";
    }

    this.filterSkill();
  }

  //called to validate skill dropdown value
  enableSkillValidation(): void {
    if (this.packageUploadFormGroup.get('skill').value == "") {
      this.skillError = true;
    }
    else {
      this.skillError = false;
      this.skillHiddenState = true;
      this.skillTitle = "";
    }
  }

  //Filtering genres for Yorbit Package
  filterGenre() {
    this.genreList = [];
    this.genreSkillList = [];
    this.packageUploadFormGroup.get('genre').setValue('');
    if (this.taxonomy !== undefined) {
      this.taxonomy.forEach(value => {
        if (this.packageUploadFormGroup.get('academy').value !== '') {
          this.packageUploadFormGroup.get('genre').enable();
          this.genreTitle = "";
          if (
            value.Academy.toLowerCase() ===
            this.packageUploadFormGroup.get('academy').value.toLowerCase()
          ) {
            this.genreSkillList = value.GenreSkillList;
          }
        }
        else {
          this.packageUploadFormGroup.get('genre').disable();
          this.packageUploadFormGroup.get('skill').disable();
        }
      });
      if (this.genreSkillList.length > 0) {
        this.genreSkillList.forEach(value => {
          this.genreList.push(value.Genre);
        });
        this.packageUploadFormGroup.controls['skill'].setValue('');
        this.packageUploadFormGroup.get('skill').disable();
        //return this.genreList;
      } else {
        return [];
      }
    }
    this.genreList.sort((a,b) => a.localeCompare(b));
  }


  //Filtering skills for Yorbit Package
  filterSkill() {
    this.skillList = [];
    this.packageUploadFormGroup.controls['skill'].setValue('');
    if (this.genreSkillList.length > 0) {
      this.genreSkillList.forEach(value => {
        if (this.packageUploadFormGroup.get('genre').value !== '' &&
          this.packageUploadFormGroup.get('academy').value !== '') {
          this.packageUploadFormGroup.get('skill').enable();
          this.skillTitle = "";
          if (
            value.Genre.toLowerCase() ===
            this.packageUploadFormGroup.get('genre').value.toLowerCase()
          ) {
            this.skillList = value.Skills;
          }

        }
        else {
          this.packageUploadFormGroup.get('skill').disable();
        }
      });
      if (this.skillList.length > 0) {
        //return this.skillList;
      } else {
        return [];
      }
    } else {
      return [];
    }
    this.skillList.sort((a,b) => a.localeCompare(b));
  }


  //Called to open Account Courses selection popup
  openAccountCourses() {

    if(this.selectedContent != undefined) {
      return;
    }

    this.selectedAccountCoursesUniqueIDS = [];
    if (this.packageUploadFormGroup.get('account').value == "") {
      this.accountError = true;
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: {
        selectedCourses: this.selectedAccountCoursesList,
        title: 'Account Courses',
        isAccount: true,
        accountId: this.accounts.find(acc => this.packageUploadFormGroup.get('account').value == acc.AccountName).AccountId
      },
      width: '250px',
      height: '250px'
    };
    var freshCoursesList = [];
    const response = this._popup.open(CoursesPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      if (res.canSubmit) {
        if (res.selectedCourses && res.selectedCourses.length > 0) {

          res.selectedCourses.forEach((course, key) => {
            freshCoursesList.push(course);
            this.selectedAccountCoursesUniqueIDS.push(JSON.parse(JSON.stringify(course)).UniqueId);
          })
          this.selectedAccountCoursesList = freshCoursesList;
        }
      } else {
        // do nothing
      }
    }).add(() => {
      this.validateCourses();
    });
  }

  //Called to open Project courses selection popup
  openProjectCourses() {

    if(this.selectedContent != undefined) {
      return;
    }

    this.selectedProjectCoursesUniqueIDS = [];
    if (this.packageUploadFormGroup.get('project').value == "") {
      this.projectError = false;
      return;
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: {
        selectedCourses: this.selectedProjectCoursesList,
        title: 'Project Courses',
        isProject: true,
        projectId: this.projects.find(c => c.ProjectName == this.packageUploadFormGroup.get('project').value).ProjectId
      },
      width: '250px',
      height: '250px'
    };
    var freshCoursesList = [];
    const response = this._popup.open(CoursesPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      if (res.canSubmit) {
        if (res.selectedCourses && res.selectedCourses.length > 0) {

          res.selectedCourses.forEach((course, key) => {
            freshCoursesList.push(course);
            this.selectedProjectCoursesUniqueIDS.push(JSON.parse(JSON.stringify(course)).UniqueId);
          })
          this.selectedProjectCoursesList = freshCoursesList;
        }
      } else {
        // do nothing
      }
    }).add(() => {
      this.validateCourses();
    });
  }

  //Called to open Yorbit courses selection popup
  openYorbitCourses() {

    if(this.selectedContent != undefined) {
      return;
    }

    this.selectedYorbitCoursesUniqueIDS = [];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: {
        selectedCourses: this.selectedYorbitCoursesList,
        title: 'Shoshin School Courses',
        expertise: 'Package',
      },
      width: '250px',
      height: '250px'
    };
    var freshCoursesList = [];
    const response = this._popup.open(CoursesPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      if (res.canSubmit) {
        if (res.selectedCourses && res.selectedCourses.length > 0) {

          res.selectedCourses.forEach((course, key) => {
            freshCoursesList.push(course);
            this.selectedYorbitCoursesUniqueIDS.push(JSON.parse(JSON.stringify(course)).UniqueId);
          })
          this.selectedYorbitCoursesList = freshCoursesList;
        }
      } else {
        // do nothing
      }
    }).add(() => {
      this.validateCourses();
    });
  }

  //Validate selected Courses
  /*  Yorbit Package - Minimum 2 courses
      Account Package - Minimum 1 course of any two - Yorbit courses or Account courses
      Project Package - Minimum 1 course of any three - Yorbit courses or Account courses or Project courses*/

  validateCourses() {
    if (this.packageUploadFormGroup.get('courseType').value == "yorbit") {
      if (this.selectedYorbitCoursesList.length < 2) {
        this.yorbitCoursesSelectedError = true;
        this.yorbitCourseIndex = -1;
      }
      else {
        this.yorbitCourseIndex = -1;
        this.yorbitCoursesSelectedError = false;
      }
    }

    else if (this.packageUploadFormGroup.get('courseType').value == "account") {
      if (this.selectedYorbitCoursesList.length == 0 && this.selectedAccountCoursesList.length == 0) {
        this.actCourseIndex = this.yorbitCourseIndex = -1;
        this.selectedAccountCoursesError = true;
        this.yorbitCoursesSelectedError = true;
      }
      else {
        this.actCourseIndex = this.yorbitCourseIndex = -1;
        this.selectedAccountCoursesError = false;
        this.yorbitCoursesSelectedError = false;
      }
    }
    else {
      if (this.selectedYorbitCoursesList.length == 0 &&
        this.selectedAccountCoursesList.length == 0 &&
        this.selectedProjectCoursesList.length == 0) {
        this.actCourseIndex = this.yorbitCourseIndex = this.projectCourseIndex = -1;
        this.selectedAccountCoursesError = true;
        this.yorbitCoursesSelectedError = true;
        this.selectedProjectCoursesError = true;
      }
      else {
        this.actCourseIndex = this.yorbitCourseIndex = this.projectCourseIndex = -1;
        this.selectedAccountCoursesError = false;
        this.yorbitCoursesSelectedError = false;
        this.selectedProjectCoursesError = false;
      }
    }
  }

  //Returns the list size for the package type of courseType. Used for trackBy in ngFor in course selection box as the list items change
  getDataLengthYorbit(index, item): number {
    return this.selectedYorbitCoursesList == undefined ? 0 + this.yorbitCourseIndex : this.selectedYorbitCoursesList.length + this.yorbitCourseIndex;
  }

  //Returns the list size for the package type of courseType. Used for trackBy in ngFor in course selection box as the list items change
  getDataLengthAccount(index, item): number {
    return this.selectedAccountCoursesList == undefined ? 0 + this.actCourseIndex : this.selectedAccountCoursesList.length + this.actCourseIndex;
  }

  //Returns the list size for the package type of courseType. Used for trackBy in ngFor in course selection box as the list items change
  getDataLengthProject(index, item): number {
    return this.selectedProjectCoursesList == undefined ? 0 + this.projectCourseIndex : this.selectedProjectCoursesList.length + this.projectCourseIndex;
  }



  //Exiting form
  onExit() {
    var errorMsg =
      'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?';

    let dataPayload = {
      msg: errorMsg,
      title: 'Exit Form'
    };
    let type = 'exit';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: dataPayload,
      width: '250px',
      height: '250px',
      type: type
    };
    const response = this._popup.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(res => {
      if (!res) {
        this.enableContentVerificationComponent.emit('package');
      }
      else {
        return;
      }
    });
  }

  //To show the options in Yorbit courses box
  showOptions(): void {

    var HTMLElementsList = document.getElementsByClassName('options');
    for (var i = 0; i < HTMLElementsList.length; i++) {
      HTMLElementsList[i].classList.add("showOptions");
    }

    this.isOptionsShown = true;
  }


  //Used for setting the index of a yorbit course when a course is selected in the box. Used for moving the course up, down or delete it.
  setYorbitCourseIndex(event: any): void {
    if(this.selectedContent != undefined) {
      return;
    }
    this.yorbitCourseIndex = Number(event.target.getAttribute('index'));
  }

  //Used for setting the index of an account course when a course is selected in the box. Used for moving the course up, down or delete it.
  setactCourseIndex(event: any): void {
    if(this.selectedContent != undefined) {
      return;
    }
    this.actCourseIndex = Number(event.target.getAttribute('index'));
  }


  //Used for setting the index of a project course when a course is selected in the box. Used for moving the course up, down or delete it.
  setProjectCourseIndex(event: any): void {
    if(this.selectedContent != undefined) {
      return;
    }
    this.projectCourseIndex = Number(event.target.getAttribute('index'));
  }

  //Used for deleting a selected shoshin school course
  deleteYorbitCourse(): void {
    if (this.yorbitCourseIndex < 0 || this.selectedContent != undefined) {
      return;
    }
    this.selectedYorbitCoursesList = this.selectedYorbitCoursesList.filter((ele, index) => index != this.yorbitCourseIndex);
    this.selectedYorbitCoursesUniqueIDS = this.selectedYorbitCoursesUniqueIDS.filter((ele, index) => index != this.yorbitCourseIndex);
    this.yorbitCourseIndex = -1;

    this.validateCourses();
  }

  //Used for deleting a selected account course
  deleteAccountCourse(): void {
    if (this.actCourseIndex < 0 || this.selectedContent != undefined) {
      return;
    }
    this.selectedAccountCoursesList = this.selectedAccountCoursesList.filter((ele, index) => index != this.actCourseIndex);
    this.selectedAccountCoursesUniqueIDS = this.selectedAccountCoursesUniqueIDS.filter((ele, index) => index != this.actCourseIndex);
    this.actCourseIndex = -1;

    this.validateCourses();
  }

  //Used for deleting a selected project course
  deleteProjectCourse(): void {

    if (this.projectCourseIndex < 0 || this.selectedContent != undefined) {
      return;
    }

    this.selectedProjectCoursesList = this.selectedProjectCoursesList.filter((ele, index) => index != this.projectCourseIndex);
    this.selectedProjectCoursesUniqueIDS = this.selectedProjectCoursesUniqueIDS.filter((ele, index) => index != this.projectCourseIndex);
    this.projectCourseIndex = -1;

    this.validateCourses();
  }

  //For moving the course up to choose the order
  moveYorbitCourseUp(): void {

    //If top most course is being tried to be moved perform no action
    if (this.yorbitCourseIndex <= 0 || this.selectedContent != undefined)
      return;

    else {
      //performing exchange
      var prevCourse = this.selectedYorbitCoursesList[this.yorbitCourseIndex - 1];
      this.selectedYorbitCoursesList[this.yorbitCourseIndex - 1] = this.selectedYorbitCoursesList[this.yorbitCourseIndex];
      this.selectedYorbitCoursesList[this.yorbitCourseIndex] = prevCourse;

      prevCourse = this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex - 1];
      this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex - 1] = this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex];
      this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex] = prevCourse;
    }

    this.yorbitCourseIndex = this.yorbitCourseIndex - 1;
  }

  //For moving the course up to choose the order
  moveAccountCourseUp(): void {

    //If top most course is being tried to be moved perform no action
    if (this.actCourseIndex <= 0 || this.selectedContent != undefined)
      return;

    else {
      //performing exchange
      var prevCourse = this.selectedAccountCoursesList[this.actCourseIndex - 1];
      this.selectedAccountCoursesList[this.actCourseIndex - 1] = this.selectedAccountCoursesList[this.actCourseIndex];
      this.selectedAccountCoursesList[this.actCourseIndex] = prevCourse;

      prevCourse = this.selectedAccountCoursesUniqueIDS[this.actCourseIndex - 1];
      this.selectedAccountCoursesUniqueIDS[this.actCourseIndex - 1] = this.selectedAccountCoursesUniqueIDS[this.actCourseIndex];
      this.selectedAccountCoursesUniqueIDS[this.actCourseIndex] = prevCourse;
    }

    this.actCourseIndex = this.actCourseIndex - 1;

  }

  //For moving the course up to choose the order
  moveProjectCourseUp(): void {

    //If top most course is being tried to be moved perform no action
    if (this.projectCourseIndex <= 0 || this.selectedContent != undefined)
      return;

    else {
      //performing exchange
      var prevCourse = this.selectedProjectCoursesList[this.projectCourseIndex - 1];
      this.selectedProjectCoursesList[this.projectCourseIndex - 1] = this.selectedProjectCoursesList[this.projectCourseIndex];
      this.selectedProjectCoursesList[this.projectCourseIndex] = prevCourse;

      prevCourse = this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex - 1];
      this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex - 1] = this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex];
      this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex] = prevCourse;
    }

    this.projectCourseIndex = this.projectCourseIndex - 1;
  }

  //For moving the course down to choose the order
  moveYorbitCourseDown(): void {

    //If bottom most course is being tried to be moved perform no action
    if (this.yorbitCourseIndex >= (this.selectedYorbitCoursesList.length - 1) || this.yorbitCourseIndex == -1 || this.selectedContent != undefined)
      return;

    else {
      //performing exchange
      var thisCourse = this.selectedYorbitCoursesList[this.yorbitCourseIndex];
      this.selectedYorbitCoursesList[this.yorbitCourseIndex] = this.selectedYorbitCoursesList[this.yorbitCourseIndex + 1];
      this.selectedYorbitCoursesList[this.yorbitCourseIndex + 1] = thisCourse;

      thisCourse = this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex];
      this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex] = this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex + 1];
      this.selectedYorbitCoursesUniqueIDS[this.yorbitCourseIndex + 1] = thisCourse;
    }

    this.yorbitCourseIndex++;
  }

  //For moving the course down to choose the order
  moveProjectCourseDown(): void {

    //If bottom most course is being tried to be moved perform no action
    if (this.projectCourseIndex >= (this.selectedProjectCoursesList.length - 1) || this.projectCourseIndex == -1 || this.selectedContent != undefined)
      return;

    else {
      //performing exchange
      var thisCourse = this.selectedProjectCoursesList[this.projectCourseIndex];
      this.selectedProjectCoursesList[this.projectCourseIndex] = this.selectedProjectCoursesList[this.projectCourseIndex + 1];
      this.selectedProjectCoursesList[this.projectCourseIndex + 1] = thisCourse;

      thisCourse = this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex];
      this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex] = this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex + 1];
      this.selectedProjectCoursesUniqueIDS[this.projectCourseIndex + 1] = thisCourse;
    }

    this.projectCourseIndex += 1;
  }

  //For moving the course down to choose the order
  moveAccountCourseDown(): void {

    //If bottom most course is being tried to be moved perform no action
    if (this.actCourseIndex >= (this.selectedAccountCoursesList.length - 1) || this.actCourseIndex == -1 || this.selectedContent != undefined)
      return;

    else {
      //performing exchange
      var thisCourse = this.selectedAccountCoursesList[this.actCourseIndex];
      this.selectedAccountCoursesList[this.actCourseIndex] = this.selectedAccountCoursesList[this.actCourseIndex + 1];
      this.selectedAccountCoursesList[this.actCourseIndex + 1] = thisCourse;

      thisCourse = this.selectedAccountCoursesUniqueIDS[this.actCourseIndex];
      this.selectedAccountCoursesUniqueIDS[this.actCourseIndex] = this.selectedAccountCoursesUniqueIDS[this.actCourseIndex + 1];
      this.selectedAccountCoursesUniqueIDS[this.actCourseIndex + 1] = thisCourse;
    }

    this.actCourseIndex++;
  }


  //validation method once the form is submitted
  proceedAfterValidation(): void {

    //validation for yorbit course
    if (this.packageUploadFormGroup.get('courseType').value == "yorbit") {

      if (this.packageUploadFormGroup.get('academy').value == "") {
        this.academyError = true;
      }
      else {
        this.academyError = false;
      }

      if (this.packageUploadFormGroup.get('genre').value == "") {
        this.genreError = true;
      }
      else {
        this.genreError = false;
      }

      if (this.packageUploadFormGroup.get('skill').value == "") {
        this.skillError = true;
      }
      else {
        this.skillError = false;
      }

      if (this.packageUploadFormGroup.get('packageName').value.trim() == "") {
        this.packageNameError = true;
      }
      else {
        this.packageNameError = false;
      }

      if (this.packageUploadFormGroup.get('packageDesc').value.trim() == "" ||
        this.packageUploadFormGroup.get('packageDesc').value.length < 150) {
        this.packageDescError = true;
      }
      else {
        this.packageDescError = false;
      }

      if (this.selectedYorbitCoursesList.length < 2) {
        this.yorbitCoursesSelectedError = true;
      }
      else {
        this.yorbitCoursesSelectedError = false;
      }

      if (this.yorbitCoursesSelectedError ||
        this.academyError ||
        this.genreError ||
        this.skillError ||
        this.packageNameError ||
        this.packageDescError) {
        return;
      }
      else {

        //JSON object for package with Yorbit Courses
        var YorbitPackage = {
          "uniqueId": null,
          "PackageName": this.packageUploadFormGroup.get("packageName").value,
          "Description": this.packageUploadFormGroup.get("packageDesc").value,
          "Expertise": null,
          "Academy": this.packageUploadFormGroup.get("academy").value,
          "Skill": this.packageUploadFormGroup.get("skill").value,
          "Genre": this.packageUploadFormGroup.get("genre").value,
          "Category": null,
          "YorbitCourseUniqueIds": this.selectedYorbitCoursesUniqueIDS,
          "ModifiedBy": null,
          "ModifiedDate": this._datePipe.transform(Date.now(), 'yyyy-MM-ddThh:mm:ss.SSS') + "Z",
          "RepoType": "Yorbit",
          "RepoId": "Yorbit",
          "RepoName": "Yorbit",
          "AccountCourseUniqueIDs": [],
          "ProjectCourseUniqueIDs": [],
          "CoursesOrder": [],
          "Duration": null,
          "PackageStatus": null
        }


        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = "Confirmation";

        this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {

          //if user decides to upload package then continue 
          if (data == "Yes") {
            this._contentUploadPackageService.insertYorbitPackage(YorbitPackage, null, "submit").subscribe(resp => {

              if (resp == null) {

                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "PackageFail"
                this._messagePopUp.open(ContentUpload101PopupsComponent, dialogConfig);
              }
              else if (resp == "EXISTS") {
                this.packageNameExistsError = true;
                return;
              }
              else {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "PackageSuccess";
                this._messagePopUp.open(ContentUpload101PopupsComponent, dialogConfig);
                this._messagePopUp.afterAllClosed.subscribe(() => {
                  this.enableContentVerificationComponent.emit('package');
                });
              }
            });
          }
          else {
            return;
          }
        });
      }
    }

    //validation for account courses
    if (this.packageUploadFormGroup.get('courseType').value == "account") {

      if (this.packageUploadFormGroup.get('account').value == "") {
        this.accountError = true;
      }
      else {
        this.accountError = false;
      }


      if (this.packageUploadFormGroup.get('category').value == "") {
        this.categoryError = true;
      }
      else {
        this.categoryError = false;
      }

      if (this.packageUploadFormGroup.get('packageName').value.trim() == "") {
        this.packageNameError = true;
      }
      else {
        this.packageNameError = false;
      }

      if (this.packageUploadFormGroup.get('packageDesc').value.trim() == "" ||
        this.packageUploadFormGroup.get('packageDesc').value.length < 150) {
        this.packageDescError = true;
      }
      else {
        this.packageDescError = false;
      }

      this.validateCourses();

      if (this.yorbitCoursesSelectedError ||
        this.selectedAccountCoursesError ||
        this.accountError ||
        this.categoryError ||
        this.packageNameError ||
        this.packageDescError) {
        return;
      }
      else {
        //JSON object for package with Account Courses
        var AccountPackage = {
          "uniqueId": null,
          "PackageName": this.packageUploadFormGroup.get("packageName").value,
          "Description": this.packageUploadFormGroup.get("packageDesc").value,
          "Expertise": null,
          "Academy": null,
          "Skill": null,
          "Genre": null,
          "Category": this.packageUploadFormGroup.get("category").value,
          "YorbitCourseUniqueIds": this.selectedYorbitCoursesUniqueIDS,
          "ModifiedBy": null,
          "ModifiedDate": this._datePipe.transform(Date.now(), 'yyyy-MM-ddThh:mm:ss.SSS') + "Z",
          "RepoType": "Account",
          "RepoId": this.accountID,
          "RepoName": this.packageUploadFormGroup.get("account").value,
          "AccountCourseUniqueIDs": this.selectedAccountCoursesUniqueIDS,
          "ProjectCourseUniqueIDs": [],
          "CoursesOrder": null,
          "Duration": null,
          "PackageStatus": null
        }


        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = "Confirmation";

        this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {

          //if user decides to upload package then continue 
          if (data == "Yes") {
            this._contentUploadPackageService.insertYorbitPackage(AccountPackage, null, "submit").subscribe(resp => {

              if (resp == null) {

                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "PackageFail"
                this._messagePopUp.open(ContentUpload101PopupsComponent, dialogConfig);
              }
              else if (resp == "EXISTS") {
                this.packageNameExistsError = true;
                return;
              }
              else {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "PackageSuccess"
                this._messagePopUp.open(ContentUpload101PopupsComponent, dialogConfig);
                this._messagePopUp.afterAllClosed.subscribe(() => {
                  this.enableContentVerificationComponent.emit('package');
                });
              }
            });
          }
          else {
            return;
          }
        });

      }
    }

    //validation for project courses
    if (this.packageUploadFormGroup.get('courseType').value == "project") {

      if (this.packageUploadFormGroup.get('account').value == "") {
        this.accountError = true;
      }
      else {
        this.accountError = false;
      }

      if (this.packageUploadFormGroup.get('project').value == "") {
        this.projectError = true;
        this.status = true;
      }
      else {
        this.projectError = false;
      }


      if (this.packageUploadFormGroup.get('category').value == "") {
        this.categoryError = true;
      }
      else {
        this.categoryError = false;
      }

      if (this.packageUploadFormGroup.get('packageName').value.trim() == "") {
        this.packageNameError = true;
      }
      else {
        this.packageNameError = false;
      }

      if (this.packageUploadFormGroup.get('packageDesc').value.trim() == "" ||
        this.packageUploadFormGroup.get('packageDesc').value.length < 150) {
        this.packageDescError = true;
      }
      else {
        this.packageDescError = false;
      }
      this.validateCourses();

      if (this.selectedProjectCoursesError ||
        this.yorbitCoursesSelectedError ||
        this.selectedAccountCoursesError ||
        this.accountError ||
        this.categoryError ||
        this.packageNameError ||
        this.packageDescError) {
        return;
      }
      else {
        //JSON object for package with Project Courses
        var ProjectPackage = {
          "uniqueId": null,
          "PackageName": this.packageUploadFormGroup.get("packageName").value,
          "Description": this.packageUploadFormGroup.get("packageDesc").value,
          "Expertise": null,
          "Academy": null,
          "Skill": null,
          "Genre": null,
          "Category": this.packageUploadFormGroup.get("category").value,
          "YorbitCourseUniqueIds": this.selectedYorbitCoursesUniqueIDS,
          "ModifiedBy": null,
          "ModifiedDate": this._datePipe.transform(Date.now(), 'yyyy-MM-ddThh:mm:ss.SSS') + "Z",
          "RepoType": "Project",
          "RepoId": this.projectID,
          "RepoName": this.packageUploadFormGroup.get("account").value,
          "AccountCourseUniqueIDs": this.selectedAccountCoursesUniqueIDS,
          "ProjectCourseUniqueIDs": this.selectedProjectCoursesUniqueIDS,
          "CoursesOrder": null,
          "Duration": null,
          "PackageStatus": null
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'popupDialogContainer';
        dialogConfig.data = "Confirmation";

        this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {

          //if user decides to upload package then continue 
          if (data == "Yes") {
            this._contentUploadPackageService.insertYorbitPackage(ProjectPackage, null, "submit").subscribe(resp => {

              if (resp == null) {

                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "PackageFail"
                this._messagePopUp.open(ContentUpload101PopupsComponent, dialogConfig);
              }
              else if (resp == "EXISTS") {
                this.packageNameExistsError = true;
                return;
              }
              else {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "PackageSuccess"
                this._messagePopUp.open(ContentUpload101PopupsComponent, dialogConfig);
                this._messagePopUp.afterAllClosed.subscribe(() => {
                  this.enableContentVerificationComponent.emit('package');
                });
              }
            });
          }
          else {
            return;
          }
        });
      }
    }
  }

  getYorbitCourses(){
    this._courseComponent.getAllYorbitCourses().subscribe(
      data => {
        data = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
        //   resolve(data);
        this._courseComponent.YorbitCoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved"); 
      });
  }
  getAccountCourses(){
    this._courseComponent.getAllAccountCourses().subscribe(
      data => {
        data = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
        this._courseComponent.AccountCoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
      }
    );
  }
  getAllProjectCourses(){
    this._courseComponent.getAllProjectCourses().subscribe(
      data => {
        data = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
        this._courseComponent.ProjectCoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");
      }
    );
  }
}