<mat-dialog-content>
<div class="badges-container">
  <!-- <div class="action-icon-holder">
    <mat-icon class="floatRight" (click)="close()">close</mat-icon>
  </div> -->
  <div class="popup-title">
Badges earned {{quarter+" "+year}}
  </div>
  <div class="closePopupBtn" (click)="close()">
    <!-- <mat-icon class="floatRight close-icon" (click)="close()">close</mat-icon> -->
    <button class="mat-button mat-icon-button" >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content-holder">
    <div
        *ngFor="let badge of badges"
        class="myPageBadge mousePointer"        
        title="{{badge.BadgeName}}"
        (click)="openDialog(badge)"
      >
        <img class="badgeSize" *ngIf="badge.CourseId != null" src="assets/images/Badges/badge_{{(badge.IsAcquired?'':'declare_')+badge.Expertise}}.png" />
        <img class="badgeSize" *ngIf="badge.CourseId == null" src="assets/images/Badges/badge_external_learning.jpg" />
        <!-- <div class="titleOnBadge">
          <div>{{ badge.BadgeName }}</div>
        </div> -->
        <div class="titleHolder expertise{{badge.Expertise}}"  fxLayoutAlign="center center">
          <div class="titleBelowBadge">{{ badge.BadgeName }}</div>
        </div>
        <div class="badgeEarnedDate">
          {{ badge.CompletedDate | date: 'dd/MMM' }}
        </div>
      </div>
  </div>
</div>
</mat-dialog-content>
