import { T } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleBasedLearningStoreService } from '../../../role-based-learning-store/role-based-learning-store.service';


@Component({
  selector: 'yorbit-rbl-report-issue-popup',
  templateUrl: './rbl-report-issue-popup.component.html',
  styleUrls: ['./rbl-report-issue-popup.component.scss']
})
export class RblReportIssuePopupComponent implements OnInit {

  selectedCategory = '';
  comments = '';
  emailstring = '';
  isCategorySelected: boolean = false;
  isCommentsFilled: boolean = false;
  issues = [
    { value: 'My role tagging is incorrect' },
    { value: 'My specialization is incorrect' },
    { value: 'My package mapping is incorrect' },
    { value: 'No package is assigned to me' },
    { value: 'Courses inside my package is not relevant' },
    { value: 'Others' }
  ];


  constructor(private dialogRef: MatDialogRef<RblReportIssuePopupComponent>,
    private rblService: RoleBasedLearningStoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  selectChange() {
    if (this.selectedCategory != '')
      this.isCategorySelected = true;
    else
      this.isCategorySelected = false;
  }

  commentsChange() {
    if (this.comments != '')
      this.isCommentsFilled = true;
    else
      this.isCommentsFilled = false;
  }

  submit() {
    if (this.selectedCategory == '')
      this.isCategorySelected = false;
    if (this.comments == '')
      this.isCommentsFilled = false;

    const roleTagging = this.data.RoleTagging;
    if(this.isCategorySelected && this.isCommentsFilled) {
      const formData = {
        Category: this.selectedCategory,
        Comments: this.comments
      }
      this.rblService.reportIssueInRBL(formData).subscribe(data => {
        this.close();
        this._snackBar.open("Thanks, Your issue has been noted","Ok", {
          duration: 2000,
          panelClass: "success-dialog"
        });
      });
    }
    
  }

}
