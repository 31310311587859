<mat-dialog-content>
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closePopup()" [disabled]="createNewLPInProgress"
    [ngClass]="{'disabled':createNewLPInProgress}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title">
    <div class="heading">Create new learning path</div>
  </div>
  <div class="parentDiv" fxLayout="column" fxLayoutAlign="center center">
    <div class="inputText" *ngIf="!createNewLPInProgress && !lpCreated" >
      <mat-form-field class="newLpInput">
        <input
          matInput
          [(ngModel)]="learningPathTitle"
          placeholder="Enter Learning Path Title"
          value=""
          (keyup)="createLPKeyEvent($event)"
          [formControl]="learningPathFormControl"
          [errorStateMatcher]="validateLearningPath"
          md-autofocus
          [disabled]="createNewLPInProgress"
          [ngClass]="{ disabled: createNewLPInProgress }"
        />
        <mat-error> {{ learningPathValidateError }} </mat-error>
        <button
          mat-button
          *ngIf="learningPathTitle"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="learningPathTitle = ''"
          [disabled]="createNewLPInProgress"
          [ngClass]="{ disabled: createNewLPInProgress }"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actionButtonContainer" *ngIf="!createNewLPInProgress && !lpCreated" >
      <button
        class="actionButton action-button"
        (click)="createLearningPath()"
      >
        Create
      </button>
      <button
      class="actionButton action-button"
        (click)="closePopup()"
      >
        Cancel
      </button>
    </div>
    <div class="createButtonContainer" *ngIf="createNewLPInProgress && !lpCreated">
      <button class="actionButton action-button disabled" [disabled]="true">Creating...</button>
    </div>
    <div class="createButtonContainer" *ngIf="lpCreated">
      <div>Learning Path {{" " + learningPathTitle + " "}} has been created</div>
      <button class="actionButton action-button" (click)="closePopup()">OK</button>
    </div>
  </div>
</mat-dialog-content>
