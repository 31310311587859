import { ActionReducerMap, createSelector,createFeatureSelector} from '@ngrx/store';

import * as fromRolebasedReducer from './rolebased-store.reducer';

export interface IrolebasedState{
  rolebased:fromRolebasedReducer.IrolebasedDetailsReducerState
}
export const rolebasedReducerCollection=fromRolebasedReducer.reducer;

export const rolebasedReducers:ActionReducerMap<IrolebasedState>={
  rolebased:fromRolebasedReducer.reducer
}
export const getRolebasedState= createFeatureSelector<IrolebasedState>('rolebased')

export const getRolebasedReducerState=createSelector(
  getRolebasedState,
  (state:IrolebasedState)=>state.rolebased
)

export const getRolebasedStatus= createSelector(
  getRolebasedReducerState,
  fromRolebasedReducer.getRolebasedStatus
)

export const getRolebasedLoading = createSelector(
  getRolebasedReducerState,
  fromRolebasedReducer.getRolebasedLoading
)

export const getRolebasedLoaded= createSelector(
  getRolebasedReducerState,
  fromRolebasedReducer.getRolebasedLoaded
)

export * from './rolebased-store.reducer';
