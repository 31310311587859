import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '@YorbitWorkspace/pipes';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';
import { PlaylistModule } from '../shared/playlist/playlist.module';
import { YammerModule } from '../shared/yammer/yammer.module';
import { AddContentModule } from '../shared/card-feature/add-content/add-content.module';
import { LearningPathComponent } from './learning-path.component';
import { LearningPathRoutingModule } from './learning-path-routing/learning-path-routing.module';
import { LPDetailedComponent } from './lp-detailed/lp-detailed.component';
import { LPPackageViewComponent } from './lp-package-view/lp-package-view.component';
import { LPCourseViewComponent } from './lp-course-view/lp-course-view.component';
import { LearningPathService } from './learning-path.service';
import { CloudLabComponent } from './cloud-lab/cloud-lab.component';

//add video players in reuseable module
import { VideoPlayersModule } from '../shared/video-players/video-players.module';
import { CreateLpModule } from '../shared/create-lp/create-lp.module';
import { LpCourseDescriptionComponent } from './lp-course-view/lp-course-description/lp-course-description.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LinkyModule } from 'angular-linky';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { NasscomModule } from '../shared/nasscom/nasscom.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    FlexLayoutModule,

    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDividerModule,
    MatMenuModule,
    MatAutocompleteModule,
    ReusableUiModule,
    ContentTilesModule,
    LearningPathRoutingModule,
    VideoPlayersModule,
    PlaylistModule,
    YammerModule,
    AddContentModule,
    CreateLpModule,
    LinkyModule,
    NasscomModule
  ],
  exports: [],
  declarations: [
    LearningPathComponent,
    LPDetailedComponent,
    LPPackageViewComponent,
    LPCourseViewComponent,
    LpCourseDescriptionComponent,
    CloudLabComponent,
    ConfirmationDialogComponent
  ],
  providers: [LearningPathService]
})
export class LearningPathModule {}
