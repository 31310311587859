import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddContentModule } from './add-content/add-content.module';
import { ShareContentModule } from './share-content/share-content.module';
import { ExpertModule } from './expert/expert.module';
import { AssignContentModule } from './assign-content/assign-content.module';


@NgModule({
  imports: [
    CommonModule,
    AddContentModule,
    ShareContentModule,
    ExpertModule,
    AssignContentModule
  ],
  declarations: [],
  exports: [AddContentModule, ShareContentModule,ExpertModule,AssignContentModule]
})
export class CardFeatureModule { }
