import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KnowledgeHubComponent } from './knowledge-hub/knowledge-hub.component';
import { RiskBrigadeCommunityComponent } from './risk-brigade-community/risk-brigade-community.component';
import { RiskBrigadeHomeComponent } from './risk-brigade-home/risk-brigade-home.component';
import { AdvanceCoursesComponent } from './risk-brigade-learn/advance-courses/advance-courses.component';
import { FoundationCoursesComponent } from './risk-brigade-learn/foundation-courses/foundation-courses.component';
import { RiskBrigadeLearnComponent } from './risk-brigade-learn/risk-brigade-learn.component';
import { CourseProgressAdvanceComponent } from './risk-brigade-progress/course-progress-advance/course-progress-advance.component';
import { CourseProgressComponent } from './risk-brigade-progress/course-progress/course-progress.component';
import { RiskBrigadeProgressComponent } from './risk-brigade-progress/risk-brigade-progress.component';
import { RiskBrigadeComponent } from './risk-brigade.component';

const routes: Routes = [
  { path: '', component: RiskBrigadeComponent, 
  children: [
    {
      path: 'home',
      pathMatch: 'full',
      component:RiskBrigadeHomeComponent
    },
    {
      path: 'knwoledge-hub',
      pathMatch: 'full',
      component:KnowledgeHubComponent
    },
    {
      path: 'learn',
      component:RiskBrigadeLearnComponent,
      children:[
        {
          path:'foundation-courses',
          pathMatch:'full',
          component:FoundationCoursesComponent
        },
        {
          path:'advance-courses',
          pathMatch:'full',
          component:AdvanceCoursesComponent
        }
      ]
    },
    {
      path: 'community',
      pathMatch: 'full',
      component:RiskBrigadeCommunityComponent
    },
    {
      path: 'progress',
      component:RiskBrigadeProgressComponent,
      children:[
        {
          path:'foundation-courses',
          pathMatch:'full',
          component:CourseProgressComponent
        },
        {
          path:'advance-courses',
          pathMatch:'full',
          component:CourseProgressAdvanceComponent
        }
      ]
    }
  ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiskBrigadeRoutingModule { }
