import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import  jwt_decode from 'jwt-decode';
import { AdalService } from 'adal-angular4';
@Component({
  selector: 'yorbit-preloader-popup',
  templateUrl: './preloader-popup.component.html',
  styleUrls: ['./preloader-popup.component.scss']
})
export class PreloaderPopupComponent implements OnInit {
  adalFrameTimerSubscription: any;
  config: any;
  renewalFailed: boolean;
  constructor(
    private dialogRef: MatDialogRef<PreloaderPopupComponent>,
    private _envSvc: EnvironmentService,
    private adalService: AdalService,
    @Inject(MAT_DIALOG_DATA) public data: any,  ) {
    this.adalFrameTimerSubscription = null;
    this.renewalFailed = false;
    this.config = this._envSvc.getEnvironment();
    this.dialogRef.beforeClosed().subscribe(() => { });
  }

  ngOnInit() {
    // if (this.dialogRef.id == 'SessionInformationPreloader') {
    //   localStorage.setItem('isSessionExpiryPopupOpen', '0');
    //   // this.adalService.clearCacheForResource(this.config.adalConfig.clientId);
    //   this.adalService
    //     .acquireToken(this.config.adalConfig.clientId)
    //     .toPromise()
    //     .then(data => {
    //       console.warn('renewal success api');
    //       this.close('keeplearning');
    //       //////console.log('renewal success end api');
    //     })
    //     .catch(error => {
    //       console.warn('renewal failed api', error);
    //       if (this.adalFrameTimerSubscription != null) {
    //         clearTimeout(this.adalFrameTimerSubscription);
    //         this.adalFrameTimerSubscription = null;
    //       }
    //       this.adalFrameTimerSubscription = setTimeout(() => {
    //         //dialog.close();
    //         const token = window.localStorage.getItem(
    //           'adal.access.token.key' + this.config.adalConfig.clientId
    //         );
    //         const decodedToken = jwt_decode(token);
    //         const tokenExpiresIn = decodedToken['exp'];
    //         const currentEpochTime = Math.floor(new Date().getTime() / 1000.0);
    //         const epochDiffInMins = Math.floor(
    //           (tokenExpiresIn - currentEpochTime) / 60
    //         ); // Epoch time difference in minutes
    //         //if (epochDiffInMins < 6) {
    //         if (epochDiffInMins < 5) {
    //           //token is not renewed
    //           //show error msg
    //           console.warn('renewal failed inside timer');
    //           this.renewalFailed = true;

    //           //////console.log('renewal failed timer');
    //         } else {
    //           //token renewed.
    //           //check if preloader popup is till acitve
    //           console.warn('renewal success inside timer');
    //           this.close('keeplearning');
    //           //////console.log('renewal success end timer');
    //         }
    //       }, 7000);

    //     });


    // }
  }
  // close(status) {
  //   if (this.adalFrameTimerSubscription != null) {
  //     clearTimeout(this.adalFrameTimerSubscription);
  //     this.adalFrameTimerSubscription = null;
  //   }
  //   this.dialogRef.close(status);
  // }
}
