import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserDetailsService } from './user-details.service';
import { userDetailsReducers, effectsUserDetails } from './store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('userDetails', userDetailsReducers),
    EffectsModule.forFeature(effectsUserDetails)
  ],
  providers: [UserDetailsService]
})
export class UserDetailsModule {}
