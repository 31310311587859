import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  baseUrl: string;
  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this.baseUrl = this._envSvc.getEnvironment().apiUrl;
  }

  sendFeedback(feedbackData){
    const Url = this.baseUrl + "User/Mail/Feedback/NotifyAdmin";
    return this._http
      .post<any>(Url,feedbackData)
      .pipe(catchError((error: any) => throwError(()=> error.json())));
  }
}
