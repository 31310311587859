<div class="pssPage" fxLayout="row">
  <nav class="navigationPane" mat-tab-nav-bar fxFlex="200px" fxLayout="column" fxLayoutAlign="start start">
    <a mat-tab-link fxFlex="40px" class="course-requests" [ngClass]="{'active':url.indexOf('course-requests')!=-1}" (click)="confirmChangeTab($event,'course-requests')"
      routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
      <!--[routerLink]="'course-requests'"-->
      Course Request
    </a>
    <div class="certification-requests" [ngClass]="{'active':url.indexOf('/certification')!=-1}" fxLayout="column">
      <mat-expansion-panel [expanded]="url.indexOf('/certification')!=-1" (opened)="onCertificationPanelOpen($event)">
        <mat-expansion-panel-header>
          <mat-panel-title> 
            Certificate Request
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutGap="12px">

          <a mat-tab-link fxFlex="16px"  (click)="confirmChangeTab($event,'pending')" [routerLink]="'certification/pending'" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
            [ngClass]="{'active-link':url.indexOf('pending')!=-1}">
     Pending
   </a>
          <a mat-tab-link fxFlex="16px"  (click)="confirmChangeTab($event,'actioned')" [routerLink]="'certification/actioned'" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
            [ngClass]="{'active-link':url.indexOf('actioned')!=-1}">
     Actioned
   </a>
   <!--" -->
        </div>

      </mat-expansion-panel>
    </div>
    <div class="excertification-requests" [ngClass]="{'active':url.indexOf('/externalCertificate')!=-1}" fxLayout="column">
      <mat-expansion-panel [expanded]="url.indexOf('externalCertificate')!=-1" (opened)="onExternalCertificationPanelOpen($event)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            External Certificate Request
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutGap="12px">

          <a mat-tab-link fxFlex="16px"  (click)="confirmChangeTab($event,'expending')" [routerLink]="'externalCertificate/pending'" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
            [ngClass]="{'active-link':url.indexOf('externalCertificate/pending')!=-1}">
     Pending
   </a>
          <a mat-tab-link fxFlex="16px"  (click)="confirmChangeTab($event,'exactioned')" [routerLink]="'externalCertificate/actioned'" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
            [ngClass]="{'active-link':url.indexOf('externalCertificate/actioned')!=-1}">
     Actioned
   </a>
   <!--" -->
        </div>

      </mat-expansion-panel>
    </div>
    <a mat-tab-link fxFlex="40px" class="evaluation-requests" (click)="confirmChangeTab($event,'evaluation-requests')" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive" [ngClass]="{'active':url.indexOf('evaluation-requests')!=-1}">
        Evaluation Request
      </a>
    <a mat-tab-link fxFlex="40px" class="evaluation-requests" (click)="redirectToUrl('content-verification')" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive" [ngClass]="{'active':url.indexOf('content-verification')!=-1}">
        Content Verification
        </a>
    <a mat-tab-link fxFlex="40px" class="course-procedure-upload" (click)="redirectToUrl('course-procedure-upload')" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive" [ngClass]="{'active':url.indexOf('course-procedure-upload')!=-1}">
        Course Procedure Upload
        </a>
  </nav>
  <div fxLayout="column" fxLayoutAlign="start stretch" class="filters-requests-container" fxFlex>


    <div class="filters-comp" *ngIf="(url.indexOf('evaluation-requests')==-1)&&(url.indexOf('content-verification')==-1) && (url.indexOf('course-procedure-upload')==-1)">
      <yorbit-pss-filters fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill></yorbit-pss-filters>
    </div>
    <div class="routerstyle">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
