<mat-dialog-content class="popupDialog"
  [ngStyle]="{'width': showUnitsInfo || showStackAndSkillInfo  ? '700px' : (showErrorFields ? '450px' : '400px') }">
  <div class="closePopupDialog">
    <button mat-button mat-icon-button (click)="onClick('No')">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!--Popup Header-->
  <div class="popupHeader" *ngIf="isConfirmation && !showErrorFields">Confirmation</div>
  <div class="popupHeader" *ngIf="!isConfirmation && !showErrorFields">Information</div>
  <div class="popupHeader" *ngIf="showErrorFields">Field Validation</div>

  <!--Mat Divider-->
  <mat-divider></mat-divider>

  <div class="messageText" *ngIf="!showErrorFields && !showUnitsInfo && !showStackAndSkillInfo">
    {{description}}
  </div>

  <div class="messageText leftAlign" *ngIf="showStackAndSkillInfo">
    <div>
      <p class="infoText">
        Stack represents collection of Skills which will be acquired on completion of the course. <br />
        A course can have multiple Stacks with one or more skills under it. <br />
        Also, a course can have a maximum of 10 Stacks and 10 Skills under each stack. <br />
        An example of Stack and Skills is shown below :
      </p>

      <table class="exTable">
        <tr>
          <th>Stack</th>
          <th>Skill</th>
        </tr>

        <tr>
          <td>Testing</td>
          <td>Automation Testing, Regression Testing</td>
        </tr>

        <tr>
          <td>Agile</td>
          <td>Agile Methodology</td>
        </tr>
      </table>

    </div>
  </div>

  <div class="messageText leftAlign" *ngIf="showUnitsInfo">
    <div>
      <p class="infoText">
        <b>Unit Name:</b>
        A Course can have multiple Units. Please provide a valid Unit Name for the Course. <br />
      </p>

      <p class="infoText">
        <b>Topic Name:</b>
        The mentioned Unit can have one or more Topics. Please provide a valid Topic Name.<br />
      </p>

      <p class="infoText">
        <b>Video types:</b>
        YouTube and Azure are the two sources for providing Video content for a Unit.<br />
      </p>

      <p class="infoText">
        <b>Source link:</b>
        Please provide a valid source link based on the selected content type.<br />
      </p>

      <span class="infoText">
        1. Account/Project Courses: A valid link for the source of the content.<br />
        2. Shoshin School Courses:<br />
        <p class="videoType">i. <i>YouTube</i> - Source link:
          Sample URL format - https://www.youtube.com/embed/hAsRTy7UlN4<br /></p>

        <p class="videoType">ii. <i>Azure</i> - Source link:
          Upload the video using 'UPLOAD AZURE VIDEOS' button. Once uploaded, copy the "SourceUrl" to Source link.<br />
        </p>
        <p class="videoType">iii. <i>Scorm</i> -
          Please upload a valid Scorm zip file with version 1.2 or 2004 4th edition, the uploaded Scorm zip file will be
          extracted and validated by the system and the source url will be updated automatically.<br /><b>Note:</b>
          Please note the extration and validation may take some time to process.<br />
        </p>
      </span>


      <p class="infoText">
        <b>Duration:</b>
        The Topic duration must be mentioned in Hour:Min:Sec format.
        If the Topic duration is 1 hr 20 mins, then, mention 01:20:00 as the duration.
        If the Topic duration is 40 mins, then, mention 00:40:00 as the duration.
      </p>
    </div>
  </div>

  <div class="errorFieldsText" *ngIf="showErrorFields">
    <div class="errorFields">The following mandatory field(s) may be empty or invalid.</div>
    <div *ngFor="let dt of data; let i = index;">
      {{i+1}}.{{dt}}
    </div>

  </div>

  <!--Mat Divider-->
  <mat-divider></mat-divider>

  <div class="actionButtonContainer" *ngIf="isConfirmation">
    <div class="floatLeft">
      <button mat-raised-button class="actionButton yes-btn" (click)="onClick('Yes')">Yes</button>
    </div>
    <div class="noButtonDiv">
      <button mat-raised-button class="actionButton yes-btn" (click)="onClick('No')">No</button>
    </div>
  </div>
  <div class="okButtonDiv" *ngIf="!isConfirmation">
    <button (click)="closeDialog()" class=" actionButton yes-btn ok-btn">OK</button>
  </div>
</mat-dialog-content>