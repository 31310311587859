import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RiskBrigadeRoutingModule } from './risk-brigade-routing.module';
import { RiskBrigadeComponent } from './risk-brigade.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import {MatListModule} from  '@angular/material/list';
import { RiskBrigadeHomeComponent } from './risk-brigade-home/risk-brigade-home.component';
import { RiskBrigadeLearnComponent } from './risk-brigade-learn/risk-brigade-learn.component';
import { RiskBrigadeCommunityComponent } from './risk-brigade-community/risk-brigade-community.component';
import { RiskBrigadeProgressComponent } from './risk-brigade-progress/risk-brigade-progress.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VideoPlayersModule } from '../shared/video-players/video-players.module';
import { SpeakerTileComponent } from './tiles/speaker-tile/speaker-tile.component';
import { VideoTileComponent } from './tiles/video-tile/video-tile.component';
import { KnowledgeHubTileComponent } from './tiles/knowledge-hub-tile/knowledge-hub-tile.component';
import { KnowledgeHubComponent } from './knowledge-hub/knowledge-hub.component';
import { FoundationCoursesComponent } from './risk-brigade-learn/foundation-courses/foundation-courses.component';
import { AdvanceCoursesComponent } from './risk-brigade-learn/advance-courses/advance-courses.component';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';
import { CoreCouncileTileComponent } from './tiles/core-councile-tile/core-councile-tile.component';
import { CourseProgressComponent } from './risk-brigade-progress/course-progress/course-progress.component';
import { CourseProgressTileComponent } from './tiles/course-progress-tile/course-progress-tile.component';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { CourseProgressAdvanceComponent } from './risk-brigade-progress/course-progress-advance/course-progress-advance.component';
import { RiskBrigadeVideoPopupComponent } from './risk-brigade-video-popup/risk-brigade-video-popup.component';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { CardFeatureModule } from '../shared/card-feature/card-feature.module';
import { ReadMoreTextComponent } from './risk-brigade-home/read-more-text/read-more-text.component';

@NgModule({
  declarations: [RiskBrigadeComponent, RiskBrigadeHomeComponent, RiskBrigadeLearnComponent, RiskBrigadeCommunityComponent, RiskBrigadeProgressComponent, SpeakerTileComponent, VideoTileComponent, KnowledgeHubTileComponent, KnowledgeHubComponent, FoundationCoursesComponent, AdvanceCoursesComponent, CoreCouncileTileComponent, CourseProgressComponent, CourseProgressTileComponent, CourseProgressAdvanceComponent, RiskBrigadeVideoPopupComponent, ReadMoreTextComponent],
  imports: [
    CommonModule,
    RiskBrigadeRoutingModule,
    ReusableUiModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatListModule,
    FlexLayoutModule,
    VideoPlayersModule,
    ContentTilesModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,

    MatExpansionModule,
    MatButtonModule,
    CardFeatureModule
  ]
})
export class RiskBrigadeModule { }
