import { Component, OnInit, Input } from '@angular/core';
import * as complianceModel from './compliance.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MyPageService } from '../my-page.service';
import * as fromMyPageStore from '../store';
import { Store } from '@ngrx/store';
import { ICompliance } from '../store/my-page-details.interface';
import { Globals } from '../../globals';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'yorbit-learning-compliance',
  templateUrl: './learning-compliance.component.html',
  styleUrls: ['./learning-compliance.component.scss']
})
export class LearningComplianceComponent implements OnInit {
  categoryOptions: string[];
  complianceInfo: string[];
  reportCategory: string;
  reportComment: string;
  isSubmitInProgress: boolean;
  showReportButton: boolean;
  learnerNA: boolean;
  linkNA: boolean;
  connectWithCDM: boolean;
  linkAvailable: boolean;
  role: string;
  MId: string;
  submitFailed: boolean;
  isReportSent: boolean;
  complianceData: ICompliance;
  complianceDetails: any;
  isComplianceDetailsLoading: boolean;
  myCDMs: any;
  myRM: any;
  constructor(
    private _router: Router,
    private _myPageSvc: MyPageService,
    private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    private _graphSvc: GraphDataService,
    private route: ActivatedRoute,
    private globals: Globals,
    private _InsightService: AppInsightService
  ) {}

  ngOnInit() {
    this.categoryOptions = complianceModel.categoryOptions;
    this.complianceInfo = complianceModel.complianceInfo;
    this.reportCategory = '';
    this.reportComment = '';
    this.isSubmitInProgress = false;
    this.showReportButton = true;
    this.complianceDetails = null;
    this.linkNA = false;
    this.linkAvailable = false;
    this.learnerNA = false;
    this.connectWithCDM = false;
    this.isComplianceDetailsLoading = false;
    this.myCDMs = null;
    this.myRM = null;

    this.route.params.subscribe(params => {
      this.role = params.role;
      this.MId = params.id;
    });

    this.getComplianceDetails();
    this.getCDMandRM();
  }
  getCDMandRM() {
    this._myPageSvc.getCDMandRM(this.MId).then((response: any) => {
      ////console.log(response);
      if (response != null) {
        if (response.CDMs != undefined && response.CDMs.length != 0) {
          this.myCDMs = [];
          response.CDMs.forEach(cdm => {
            this._graphSvc.getUserName(cdm).subscribe(response => {
              this.myCDMs.push(response.displayName);
            });
          });
        } else if (response.RMMid != undefined && response.RMMid != null) {
          this._graphSvc.getUserName(response.RMMid).subscribe(response => {
            this.myRM = response.displayName;
          });
        }
      }
    });
  }
  submitReport() {
    const payload = {
      Category: this.reportCategory,
      Comment: this.reportComment,
      LearningPolicyURL: ''
    };

    if (this.linkAvailable === true) {
      payload.LearningPolicyURL = this.complianceDetails.CompliancePolicyUrl;
    } else {
      payload.LearningPolicyURL = '';
    }

    this.isSubmitInProgress = true;
    this.submitFailed = false;
    this.isReportSent = false;

    this._myPageSvc.sendReport(payload).subscribe(
      data => {
        this.isSubmitInProgress = false;
        this.showReportButton = true;
        this.reportCategory = '';
        this.reportComment = '';
        this.isReportSent = true;
      },
      error => {
        this.isSubmitInProgress = false;
        this.submitFailed = true;
        //reset input fields
        this.reportCategory = '';
        this.reportComment = '';
      }
    );
  }

  routeToComplianceFAQ() {
    const category = 'Learning Policy Compliance';
    this._router.navigate(['/info/faq', category]);
  }

  setPolicyLink(policyLink) {
    if (
      policyLink.toLowerCase() === 'na' ||
      policyLink.toLowerCase() === 'not applicable'
    ) {
      this.learnerNA = true;
    } else if (
      policyLink.toLowerCase() ===
      'to learn more about the learning policy applicable to you, connect with your Learning Business Partner(s)'
    ) {
      this.connectWithCDM = true;
    } else if (policyLink.toLowerCase() === 'policy link is not available') {
      this.linkNA = true;
    } else {
      this.linkAvailable = true;
    }
  }
  getTime(date, format) {
    const result = new Date(date);
    return new DatePipe('en-US').transform(result, format);
  }
  getComplianceDetails() {
    this.isComplianceDetailsLoading = true;
    if (this.role === 'self') {
      this.myPageStore
        .select(fromMyPageStore.getMyPageCompliance)
        .subscribe((res: any) => {
          this.isComplianceDetailsLoading = false;
          // res = {
          //   ComplianceStatus: 'Non-Compliant',
          //   CycleStartDate: '2019-01-01',
          //   CycleEndDate: '2019-06-30',
          //   CompliancePolicyUrl:
          //     'https://peoplehub.mindtree.com/sites/Policies/LandC/Pages/NewLearningPolicy.aspx',
          //   RequiredCredits: 6,
          //   AchievedCredits: 2,
          //   ComplianceCourses: [
          //     {
          //       CourseName: 'C# Programming',
          //       CourseId: '307',
          //       Expertise: '201',
          //       Credit: 1
          //     },
          //     {
          //       CourseName: 'Node JS',
          //       CourseId: '383',
          //       Expertise: '201',
          //       Credit: 1
          //     }
          //   ]
          // };
          res.CycleStartDate = this.getTime(
            new Date(res.CycleStartDate),
            'd MMM y '
          );
          res.CycleEndDate = this.getTime(
            new Date(res.CycleEndDate),
            'd MMM y '
          );
          this.complianceDetails = res;
          if(this)
          this.setPolicyLink(this.complianceDetails.CompliancePolicyUrl);
        });
    } else if (this.role === 'learner') {
      this._myPageSvc.getLearnerComplianceStatus(this.MId).subscribe(
        data => {
          this.isComplianceDetailsLoading = false;
          this.complianceDetails = data;
          if (
            this.complianceDetails.ComplianceStatus.toLocaleLowerCase() ===
            'non-compliant'
          ) {
            this.globals.isLearnerCompliant = false;
          } else {
            this.globals.isLearnerCompliant = true;
          }
          this.setPolicyLink(this.complianceDetails.CompliancePolicyUrl);
        },
        error => {}
      );
    }
  }
  TrackComplianceDetails() {
    const insightPayload = {
      Action: 'Compliance Details Checked',
      Location: 'My Page'
    };
    this._InsightService.trackEvent('Compliance Details', insightPayload);
  }
}
