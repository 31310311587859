import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'yorbit-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {
  @Output() dateUpdateEvent = new EventEmitter<string>();
  minDate = new Date(2015, 0, 1);
  maxDate = new Date();

  startDate: any;
  endDateForm: FormControl;
  startDateForm: FormControl;
  endDate: { value: any; date: any; month: any; apiFormat:any};


  constructor() { }

  ngOnInit() {
    this.dateInit();
  }

  dateInit(){
    const today=new Date();
    this.endDateForm = new FormControl(new Date(today));
    this.setDateValue(today,"end")
    this.startDateForm = new FormControl(new Date(today.setDate(today.getDate() - 1)).toISOString());
    this.setDateValue(today,"start")
  }

  onDateChangeEvent(event,dateType){
    this.setDateValue(event.value,dateType);
  }
  setDateValue(value,dateType){
    if("start"===dateType){
      this.startDate={
        value:value,
        date:value.getDate(),
        month:value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:(value.getMonth() + 1)+"-"+value.getDate()+"-"+value.getFullYear()
      }
    }else{
      this.endDate={
        value:value,
        date:value.getDate(),
        month:value.toLocaleString('en-us', { month: 'short' }),
        apiFormat:(value.getMonth() + 1)+"-"+value.getDate()+"-"+value.getFullYear()
      }
    }
  }

  filterDateChange(){
    this.dateUpdateEvent.emit("date")
  }
}
