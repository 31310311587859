import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehavioralAssessmentService } from '../../services/behavioral-assessment.service.';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'yorbit-assessment-tile',
  templateUrl: './content-tile-assessment.component.html',
  styleUrls: ['./content-tile-assessment.component.scss']
})
export class ContentTileAssessmentComponent implements OnInit {
  @Input('cardDetails') cardDetails: any;
  @Input('featuredIn') featuredIn: any;
  logedInUserId;
  cardData;

  constructor( private _behavioralAssessmentService:BehavioralAssessmentService,
    private appStore: Store<any>,
    private router:Router,
    private _systemSvc :SystemService) { }

  ngOnInit(): void {
    this.appStore.select('userDetails').subscribe(userDetails => {
      if (userDetails.user.loaded) {
        this.logedInUserId = userDetails.user.data.id;
        this.callGenerateReportAPI();
      }
    });
  }
 
  callGenerateReportAPI(){
    this._behavioralAssessmentService.getBlobLink(this.logedInUserId).subscribe(
      (response: any) => {
        //console.log(response)
        this.cardData=response;
      },
      (error: any) => {
        //console.log('error')
      }
    );

    
  }

  downloadReport(){
    if(this.cardData.status=='PDFgenerated'){
      //download Report
      this._systemSvc.getBlobSasKey(this.cardData.BlobLinks)
      .then((urlResponse:any)=>{
        try{
          let decodedUri = this._systemSvc.getAtoBDecode(urlResponse);
          window.open(decodedUri,'_blank');
        }
        catch{}
      });
    }else if(this.cardData.status=='Not Started'){
      this.router.navigate([
        'learningpath/behavioral-assessment/'
      ]);
    }
  }


}
