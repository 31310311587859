<mat-dialog-content *ngIf="!attemptsCheck">
  <div class="closePopupBtn">
    <button class="mat-button mat-icon-button" (click)="closeDialog(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="popup-title">
    <div class="title" title="{{data.title}}">{{ data.title }}</div>
  </div>
  <div class="confirmationDialog">
    <div [innerHtml]="message1 | linkify"></div>
    <div *ngIf="message2 != null" [innerHtml]="message2 | linkify">
      {{ message2 }}
    </div>
    <div *ngIf="quizProgressPopup && !showActionBtn">
      <app-preloader></app-preloader>
    </div>
    <div class="actionButtonContainer">
      <button *ngIf="!data.showButtons && !quizProgressPopup" class="actionButton action-button" (click)="closeDialog(false)">
        {{btnLabel}}
       </button>
      <button *ngIf="!data.showButtons && quizProgressPopup && showActionBtn" class="actionButton action-button" (click)="closeDialog(canRetakeQuiz)">
      {{btnLabel}}
       </button>
      <button *ngIf="data.showButtons" class="actionButton action-button" (click)="closeDialog(true)">
         Yes
       </button>
      <button *ngIf="data.showButtons" class="actionButton action-button" (click)="closeDialog(false)">
         No
       </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="attemptsCheck">
  <div class="popup-title">
    <div class="title" title="{{data.title}}">{{ data.title }}</div>
  </div>
  <div class="confirmationDialog">
    <div [innerHtml]="message1 | linkify"></div>   
    <div>
      <app-preloader></app-preloader>
    </div>
  </div>
</mat-dialog-content>