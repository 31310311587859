import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PssEvaluationService } from '../pss-evaluation.service';
import { CheckboxList, YorbitCourseRequestList, CheckboxDisableList } from '../pss-evaluation.interface';

@Component({
  selector: 'yorbit-pss-course-type',
  templateUrl: './pss-course-type.component.html',
  styleUrls: ['./pss-course-type.component.scss']
})
export class PssCourseTypeComponent implements OnInit {

  dataList: YorbitCourseRequestList[] = []; 
  courseTypeList: CheckboxList[] = [];
  changedItem: CheckboxList[];
  filteredDataList: YorbitCourseRequestList[] = [];
  uniqueCourseTypeList: string[] = [];
  public searchText:any;
  checkedCourseList: string[] = [];
  checkedAcademyList: string[] = [];
  checkedCourseTypeList: string[] = [];
  checkedSubStatusList: string[] = [];
  checkedLearningOPMSPOCList: string[] = [];
  checkedCourseVendorList: string[] = [];
  displayList: CheckboxDisableList[] = [];
  changedCourseTypeList: CheckboxDisableList[] = [];
  @Output() isCourseTypeFiltered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _PssEvaluationService: PssEvaluationService) { }

  ngOnInit() {
    this.courseTypeList = [];
    this.dataList = this._PssEvaluationService.dataList;
    this.checkedSubStatusList = this._PssEvaluationService.checkedSubStatusList;
    this.checkedCourseVendorList = this._PssEvaluationService.checkedCourseVendorList;
    this.checkedCourseList = this._PssEvaluationService.checkedCourseList;
    this.checkedAcademyList = this._PssEvaluationService.checkedAcademyList;
    this.checkedCourseTypeList = this._PssEvaluationService.checkedCourseTypeList;
    this.checkedLearningOPMSPOCList = this._PssEvaluationService.checkedLearningOPMSPOCList;
    this.GetCourseTypeList(); 
  }

  GetCourseTypeList() {
    this.filteredDataList = this._PssEvaluationService.filterRecords('CourseType');
    ////console.log('.....', this.filteredDataList);

    //For Displaying all unique Sub status list
    for(let i=0; i<this.dataList.length;i++) {
      if(this.uniqueCourseTypeList.indexOf(this.dataList[i].CourseType) == -1) {
        this.uniqueCourseTypeList.push(this.dataList[i].CourseType);
      }
    }

    this.uniqueCourseTypeList = this.uniqueCourseTypeList.filter(x => x != null && x.trim() != '');
    //Checking if atleast any one of the filter is applied
    //If no filter is applied, Display all the unique Sub status list without disabling any option
    //and selecting any option
    if(this.checkedCourseList.length == 0 
      && this.checkedAcademyList.length == 0
      && this.checkedCourseTypeList.length == 0
      && this.checkedLearningOPMSPOCList.length == 0
      && this.checkedSubStatusList.length == 0
      && this.checkedCourseVendorList.length == 0) {
      this.uniqueCourseTypeList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: true
        }
      }).forEach(item=>this.displayList.push(item));
    }
    //If any filter is applied, disable all the options and deselect all the options
    else {
      this.uniqueCourseTypeList.map(item => {
        return {
          value: item,
          isSelected: false,
          isEnabled: false
        }
      }).forEach(item=>this.displayList.push(item));
    }
    ////console.log('Filterdata', this.filteredDataList);
    ////console.log('fasond', this.displayList);
    this.enableFilteredSubStatusList();
  }

  //For enabling the Sub status list for which is only present in the FilteredDataList
  enableFilteredSubStatusList() {
    ////console.log('enablefilter', this.filteredDataList);
    for(let i=0; i<this.filteredDataList.length; i++) {
      this.changedCourseTypeList = [];
      this.changedCourseTypeList = this.displayList.filter(item => item.value === this.filteredDataList[i].CourseType);
      this.changedCourseTypeList[0].isEnabled = true;
      this.changedCourseTypeList[0].isSelected = false;
    }
    ////console.log('filterdatafadsfads', this.displayList);
    this.preserveSelected();
  }

  //For selecting the Sub status list which was previously selected
  preserveSelected() {
    for(let i=0; i<this.checkedCourseTypeList.length; i++) {
      this.changedCourseTypeList = [];
      this.changedCourseTypeList = this.displayList.filter(item => item.value === this.checkedCourseTypeList[i]);
      this.changedCourseTypeList[0].isEnabled = true;
      this.changedCourseTypeList[0].isSelected = true;
      ////console.log('changeditem', this.changedCourseTypeList);
    }
    ////console.log('DisplayVenodr', this.displayList);
  }

  updateCheckedOptions(option, event) {
    if(this.checkedCourseTypeList.indexOf(option) == -1)
      this.checkedCourseTypeList.push(option);
    else
      this.checkedCourseTypeList.splice(this.checkedCourseTypeList.indexOf(option),1);
    ////console.log('checked item', this.checkedCourseTypeList);
    this._PssEvaluationService.checkedCourseTypeList = this.checkedCourseTypeList;
    this.isCourseTypeFiltered.emit(this.checkedCourseTypeList.length>0);
  }
}
