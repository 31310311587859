import { Component, OnInit } from '@angular/core';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Globals } from '../globals';
import { DiversityAndInclusionService } from '../shared/services/diversity-and-inclusion.service';

@Component({
  selector: 'yorbit-diversity-and-inclusion',
  templateUrl: './diversity-and-inclusion.component.html',
  styleUrls: ['./diversity-and-inclusion.component.scss']
})
export class DiversityAndInclusionComponent implements OnInit {
  selectedArticleFilter: string = 'all';
  seeMoreFlag: boolean = false;

  videoList = [];
  articleList = [];
  filteredPackagesAndCoursesList = [];
  coursesList = [];

  isVideoLoading = false;
  isArticleLoading = false;
  isCoursesLoading = false;


  selectedVideo;
  selectedVideoCategory:String="";

  constructor(
    private _diversityAndInclusionService: DiversityAndInclusionService,
    private _appInsight: AppInsightService,
    public globals: Globals
  ) {}

  ngOnInit(): void {
   // this.getVideosForDiversityAndInclusion();
    this.getArticlesForDiversityAndInclusion();
    this.getCoursesForDiversityAndInclusion();
  }

  clickOfReltatedVideoSelect(video) {
    const insightPayload = {​​
      LOCATION:"Diversity & Inclustion Related Videos",
      MID: this.globals.MId
    }​​;

    this._appInsight.trackEvent("D&I_Video",insightPayload)
    this.selectedVideo = video;
    this.selectedVideoCategory=video.VideoCategory
  }

  clickSelectedFilter(filter) {
    this.selectedArticleFilter = filter;
    this.getArticlesForDiversityAndInclusion();
  }

  clickOfArticleTile(articleVideo){
    //App Insight 
    const insightPayload = {​​
      LOCATION:"Diversity & Inclustion Videos",
      MID: this.globals.MId
    }​​;

    this._appInsight.trackEvent("D&I_Video",insightPayload)
   this.selectedVideo=articleVideo;
   this.selectedVideoCategory=articleVideo.VideoCategory;
   this.getVideosForDiversityAndInclusion();
   this.gotoVideoContainer();
  }

  getVideosForDiversityAndInclusion() {
    this.isVideoLoading = true;
    this._diversityAndInclusionService.getVideosForDiversityAndInclusion(this.selectedVideoCategory).then(
      (data: any) => {
        this.isVideoLoading = false;
        this.videoList = data;
        if(this.selectedVideo==undefined){
          this.selectedVideo=this.videoList[0]
        }
      
        return data;

        
      },
      error => {
        this.isVideoLoading = false;
        this.videoList = [];
        ////console.log('Error', error);
        return this.videoList;
      }
    );
  }

  getArticlesForDiversityAndInclusion() {
    this.isArticleLoading = true;
    this._diversityAndInclusionService
      .getArticlesForDiversityAndInclusion(this.selectedArticleFilter)
      .subscribe(
        (response: any) => {
          this.isArticleLoading = false;
          if(response==null){
            this.articleList = [];
          }else{
            this.articleList = response;
            var tmpVideoList = [];
            this.articleList.forEach(element => {
              if(element.Type=='Video'){
                tmpVideoList.push(element)
              }
            });
            if(tmpVideoList.length > 0) {
            const rndInt = Math.floor(Math.random() * tmpVideoList.length-1) + 0
            this.clickOfArticleTile(tmpVideoList[rndInt]);
            }
          }
          return this.articleList;
        },
        (error: any) => {
          this.isArticleLoading = false;
          this.articleList = [];
          ////console.log('Error', error);
          return this.articleList;
        }
      );
  }

  getCoursesForDiversityAndInclusion() {
    this.isCoursesLoading = true;
    this._diversityAndInclusionService
      .getCoursesForDiversityAndInclusion()
      .then(
        (data: any) => {
          this.isCoursesLoading = false;
          this.coursesList = data;
          this.coursesList.forEach(element => {
            element["ItemType"]="Course"
          });
          console.log("diversity course----------------->",this.coursesList);
          return this.coursesList;
        },
        error => {
          this.isCoursesLoading = false;
          this.coursesList = [];
          ////console.log('Error', error);
          return this.coursesList;
        }
      );
  }

  gotoVideoContainer() {
    let el = document.getElementById('videoContainer');
    ////console.log(el.scrollHeight)
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
