<mat-card class="mousePointer" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch" *ngIf="!noDataAvailable"
  [ngClass]="['browse-content-tile', 'border' + cardDetails.Expertise]"
  (click)="saveDataToAppInsight();goToDetailsPage(cardDetails)" title="Click to view the course/package details">
  <mat-card-header fxLayout="column" fxFlex="100px"
    [ngClass]="['bg' + cardDetails.Expertise, 'content-tile-browse-header']">
    <div fxLayout="row" fxFlex="20px">
      <div class="item-expertise">{{ cardDetails.Expertise }}</div>
      <!--TF3 Coursetile highlight-->
      <div class="tf3-label"
        *ngIf="courseTypeMaster && (courseTypeMaster.Type === cardDetails.Type || courseTypeMaster.Type === cardDetails.CourseType)">
        {{ courseTypeMaster.Label }}
      </div>
    </div>
    <div fxLayout="row" fxFlex="50px">
      <!--add styles to trucate text after two lines-->
      <div fxFlex="100%" class="course-name" title="{{ cardDetails.Name }}">{{ cardDetails.Name }}</div>

    </div>
  </mat-card-header>

  <mat-card-content fxFlex="163px" fxLayout="column" class="card-content" fxLayoutAlign="start stretch">
    <!-- <div
      class="course-rating"
      fxFlex="34px"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <app-ratings
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="5x"
        *ngIf="
          avgRating != undefined && cardDetails.ItemType != 'FamilyPackage' && enableShare
        "
        [rating]="avgRating"
        [numberOfPeopleRated]="numberOfPeopleRated"
      ></app-ratings>
      <div
        fxFlex="40px"
        *ngIf="cardDetails.IsContentNew"
        fxFlexAlign="start"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <div
          class="new-content-flag"
          fxFlexOffset="2px"
          fxFlex="30px"
          fxLayoutAlign="center center"
        >
          New
        </div>
      </div>
    </div> -->
    <div class="course-description" fxFlex>
      {{ cardDetails.Description }}
    </div>
    <div class="icons-bar" fxFlex="65px" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px"
      *ngIf="!roleList.CUSTOMER">
      <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center">
        <yorbit-assign-content fxFlex="30px" fxFlexOffset="11px" *ngIf="
          roleList != undefined &&
          (roleList.ACM || roleList.PCM || roleList.CDM || roleList.GLOBALCDM)
          &&!('RBLBrowse'==featuredIn)
        " [card]="{
          contentData: cardDetails
        }" (click)="stopPropagation($event)" class="cardFeatureButtons"></yorbit-assign-content>
        <div fxFlex="15px" fxLayoutAlign="center center" class="added-count"></div>
      </div>
      <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center" *ngIf="cardDetails.IsContentTrending"
        title="This Course/Package is Trending on Shoshin School">
        <div fxFlex="30px" fxFlexOffset="11px" (click)="stopPropagation($event)"
          class="cardFeatureButtons yorbitSprite iconTrending"></div>
        <div fxFlex="15px" fxLayoutAlign="center center" class="added-count"></div>
      </div>

      <!-- Card Feature Buttons-->
      <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center">
        <div (click)="stopPropagation($event)" class="cardFeatureButtons" fxFlex="30px" fxFlexOffset="11px"
          fxLayoutAlign="center center">
          <div class="yorbitSprite featureAdd" title="{{
        cardDetails.TotalUsers
      }} LTIMindtree Limited Associates Have Added This Course/Package" fxFlex="30px"></div>
        </div>
        <div fxFlex="15px" fxLayoutAlign="center center" class="added-count">
          {{ cardDetails.TotalUsers }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center" [ngClass]="{ mouseDisabled: !enableShare }">
        <yorbit-share-content fxFlex="30px" fxFlexOffset="11px" fxLayoutAlign="center center"
          (click)="stopPropagation($event,'share',cardDetails)" class="cardFeatureButtons" [card]="{
          contentData: cardDetails,
          sharedLocation: featuredIn,
          enableShare:enableShare
        }"></yorbit-share-content>
        <div fxFlex="15px" fxLayoutAlign="center center" class="shared-count">
          {{ cardDetails.TotalShares }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="30px" fxLayoutAlign="start center">
        <yorbit-expert fxFlex="30px" fxLayoutAlign="center center" fxFlexOffset="11px" (click)="stopPropagation($event)"
          class="cardFeatureButtons" [card]="{
          contentData: cardDetails,
          expertLocation: 'featuredIn'
        }"></yorbit-expert>
        <div fxFlex="15px" fxLayoutAlign="center center" class="experts-count">
          {{ null === cardDetails.Experts ? 0 : cardDetails.Experts?.length }}
        </div>
      </div>
    </div>
    <!-- Card Feature Buttons Ends -->
  </mat-card-content>
  <div class="divider" fxFlex="1px">
    <mat-divider></mat-divider>
  </div>
  <mat-card-footer fxLayout="row" fxFlex="36px" fxLayoutAlign="start center" class="bgWhite">
    <div fxFlex fxLayoutAlign="start center">
      <div class="content-count" *ngIf="
          (cardDetails.ItemType == 'Course' || cardDetails.ItemType=='RiskBrigadeCourse') && cardDetails.Expertise == '101'
        ">
        {{ cardDetails.ContentCount }} Unit(s)
      </div>
      <div class="content-count" *ngIf="
          (cardDetails.ItemType == 'Course' || cardDetails.ItemType=='RiskBrigadeCourse') &&
          (cardDetails.Expertise == '201' || cardDetails.Expertise == '301')
        ">
        {{ cardDetails.Credits }} Credit(s)
      </div>
      <div class="content-count" *ngIf="
          cardDetails.ItemType == 'Package' ||
          cardDetails.ItemType == 'FamilyPackage'
        ">
        <!-- {{ coursesList.length }} Course(s) -->
        {{ cardDetails.ContentCount }} Course(s)
      </div>
    </div>
    <div fxFlex="60px" fxLayout="column" fxLayoutAlign="center stretch">
      <yorbit-add-content (click)="stopPropagation($event)" class="cardFeatureButtons" fxFlex="30px"
        fxLayoutAlign="center center" *ngIf="!roleList.CUSTOMER" [card]="{
          contentData: cardDetails,
          skillProfile: {
            recommendationType: '',
            duration: ''
          },
          addType: 'button',
          addedLocation: featuredIn
        }"></yorbit-add-content>
      <!-- <div (click)="stopPropagation($event)"
      class="cardFeatureButtons"
      fxFlex="30px"
      fxLayoutAlign="center center"
      *ngIf="!roleList.CUSTOMER">
        <button
        class="addBtn"
        (click)="openDialog()"
        [disabled]="cardDetails.IsRestricted || isAddedToLP"
        [ngClass]="{
          addDisabled: cardDetails.IsRestricted || isAddedToLP
        }"
        title="{{
          cardDetails.IsRestricted
            ? 'Sorry, this course is restricted and can only be assigned.'
            : (isAddedToLP?'Course/Package Present In Your Learning Path':'Add Course/Package To Learning Path')
        }}"
      >
        {{ isAddedToLP ? 'Added' : 'Add' }}
      </button>
      </div> -->
    </div>
  </mat-card-footer>
  <div class="externalCertificateContainer" *ngIf="
      (cardDetails.Type &&
      'external certification' === cardDetails.Type.toLowerCase()) || (cardDetails.CourseType &&'external certification' === cardDetails.CourseType.toLowerCase())
    ">
    <div class="yorbitSprite externalCertificate"></div>
  </div>


  <div class="declare-flag-pos" *ngIf="showDeclare">
    <div class="yorbitSprite icon-declare-flag " [ngClass]="{courseDeclaredIcon:declareWorkflowStatus}"
      (click)="declareSkill($event)" title="{{
        declareWorkflowStatus
          ? 'Declared'
          : 'Declare this skill'
      }}"></div>
  </div>
</mat-card>