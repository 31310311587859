import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<GeneralInfoComponent>
  ) {}

  ngOnInit() {
  
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
