import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  config: Ienvironment;

  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
  }

  getMindsAccessStatus(category) {
    // category: “NASSCOM” or “DEV”
    return this.http
      .get(this.config.apiUrl + 'AccessCheckForMind/' + category)
      .toPromise();
  }
  getNameFromCapacityReportWithMID(mid) {
    return this.http.get(this.config.apiUrl + 'User/GetNameByMID/' + mid);
  }
  detectMobileAgent() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getBlobSasKey(url){​
    return this.http.post(this.config.apiUrl + 'Blob/GetSasUri', {​"BlobUrl": url}​).toPromise();
  }

  getAtoBDecode(encode){
    var decode= decodeURIComponent(escape(window.atob(encode)));
    return decode;
  }
}
