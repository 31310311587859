import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  INotifications,
  NotificationsCollection,
  NotificationEntities
} from '../notifications.interface';
import { OrderbyDatePipe } from '@YorbitWorkspace/pipes';
export const getNotificationsState = createFeatureSelector<INotifications>(
  'BellNotification'
);

export const getAllNotificationEntities = createSelector(
  getNotificationsState,
  (data: INotifications) => {
    let AllNotifications = data.Payload.BellResultCollection.concat(
      data.Payload.AdminMessageCollection
    ).concat(data.Payload.CommonMessageCollection);
    const entities: NotificationEntities = flatten(
      new OrderbyDatePipe().transform(AllNotifications, 'CreatedDate')
    );
    
    return (Object.assign(data.AllNotifications, entities));
  }
);
export const getAllNotificationsList = createSelector(
  getAllNotificationEntities,
  entities => Object.keys(entities).map(key => entities[key])
);
function flatten(data) {
  if (data.length != 0) {
    const notificationEntities = data.reduce(
      (
        entities: NotificationEntities,
        notification: NotificationsCollection
      ) => {
        return {
          ...entities,
          [notification.Id]: notification
        };
      },
      {}
    );
    return notificationEntities;
  } else {
    return data;
  }
}

export * from './notifications.reducers';
