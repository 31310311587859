import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Conentupload101Service } from '../conentupload-101.service';
import { ContentUpload101PopupsComponent } from '../content-upload101-popups/content-upload101-popups.component';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { ValidateNumber, ValidateMinsSeconds, ValidateCutOffMarks, ValidateWhitespace } from '../contentupload-101-validators';
import { ContentUploadStatusPopupComponent } from '../../content-upload-status-popup/content-upload-status-popup.component';

@Component({
  selector: 'yorbit-quiz-form101',
  templateUrl: './quiz-form101.component.html',
  styleUrls: ['./quiz-form101.component.scss']
})
export class QuizForm101Component implements OnInit {

  public quizForm: FormGroup;
  yesNoRadio = ['Yes', 'No'];
  @Input() course;
  quizFormData: any;
  quizFormDataDummy: any;
  durationEmpty: boolean = false;
  durationError: boolean = false;
  durationErrorMessage: string = '';
  isValid: boolean = false;
  errorFields: string[] = [];
  erroredQuestions: number[] = [];
  isSubmittedOrApproved: boolean = false;
  submitted: boolean = false;
  @Output() enableContentVerificationComponent = new EventEmitter<any>();
  @Output() enableUnitsComponent = new EventEmitter<any>();
  @Output() enable101ContentUploadComponent = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
    private _contentUpload101Service: Conentupload101Service,
    public confirmDialogRef: MatDialogRef<ContentUpload101PopupsComponent>,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    //console.log(this.course)
   this.course['createQuiz']=this.course?.createQuiz?this.course?.createQuiz:false;
    this.quizForm = this.formBuilder.group({
      hasQuiz: 'No',
      isQuizTimeBoxed: 'No',
      isQuizRandomized: 'No',
      isAttemptsDefined: 'No',
      attempts: ['', [ValidateNumber]],
      noOfQuestions: ['', [ValidateNumber]],
      cutOffMarks: ['', [Validators.required, ValidateCutOffMarks]],
      durationHour: ['', [ValidateNumber]],
      durationMins: ['', [ValidateMinsSeconds]],
      durationSeconds: ['', [ValidateMinsSeconds]],
      questionsAndAnswer: this.formBuilder.array([this.addQuestionGroup()])
    });

    const formData = this.quizForm.getRawValue();
    this.quizFormDataDummy = JSON.parse(JSON.stringify(formData));
    this.quizFormDataDummy.questionsAndAnswer[0].options[0].onHover = false;
    this.quizFormDataDummy.questionsAndAnswer[0].options[1].onHover = false;

    this.quizForm.controls['durationHour'].disable();
    this.quizForm.controls['durationMins'].disable();
    this.quizForm.controls['durationSeconds'].disable();
    this.quizForm.controls['attempts'].disable();
    this.quizForm.controls['noOfQuestions'].disable();
    this.quizForm.controls['durationSeconds'].setValue("00");

    this._contentUpload101Service.getQuizDataFor101(this.course.UniqueId).subscribe(data => {
      if (data != null && data.questionsAndAnswer != null) {
        this.quizForm.controls['hasQuiz'].setValue('Yes');
        this.quizForm.get('cutOffMarks').setValue(data.CutOffMarks);
        if (data.isQuizTimeBoxed) {
          this.quizForm.get('durationHour').enable();
          this.quizForm.get('durationMins').enable();
          this.quizForm.get('durationHour').markAsUntouched();
          this.quizForm.get('durationMins').markAsUntouched();
          this.quizForm.get('isQuizTimeBoxed').setValue('Yes');
          this.quizForm.get('durationHour').setValue(data.DurationHour);
          this.quizForm.get('durationMins').setValue(data.DurationMins);
        }
        if (data.isQuizRandomized) {
          this.quizForm.get('noOfQuestions').enable();
          this.quizForm.get('isQuizRandomized').setValue('Yes');
          this.quizForm.get('noOfQuestions').setValue(data.noOfQuestions);
        }
        if (data.isAttemptsDefined) {
          this.quizForm.get('isAttemptsDefined').setValue('Yes');
          this.quizForm.get('attempts').enable();
          this.quizForm.get('attempts').setValue(data.Attempts);
        }
        for (let i = 0; i < data.questionsAndAnswer.length; i++) {
          if (i > 0)
            this.addQuestion();
          const question = this.quizForm.get('questionsAndAnswer')['controls'][i];
          question.controls['question'].setValue(data.questionsAndAnswer[i].Question);
          for (let j = 0; j < data.questionsAndAnswer[i].options.length; j++) {
            if (j > 1)
              this.addOption(i);
            const options = this.quizForm.get('questionsAndAnswer')['controls'][i].controls['options'].controls[j];
            options.controls['option'].setValue(data.questionsAndAnswer[i].options[j].option);
            options.controls['isCorrectAnswer'].setValue(data.questionsAndAnswer[i].options[j].isCorrectAnswer);
          }
        }
      }
      const formData = this.quizForm.getRawValue();
      this.quizFormDataDummy = [];
      this.quizFormDataDummy = JSON.parse(JSON.stringify(formData));
      this.quizFormDataDummy.questionsAndAnswer[0].options[0].onHover = false;
      this.quizFormDataDummy.questionsAndAnswer[0].options[1].onHover = false;
      if (this.course.IsSubmittedOrApprovedCourse) {
        this.quizForm.disable();
        this.isSubmittedOrApproved = true;
      }
    });


    if (this.course.FromHomePage) {
      this.quizForm.get('hasQuiz').setValue('Yes');
    }
  }

  private addQuestionGroup(): FormGroup {
    return this.formBuilder.group({
      question: ['', [Validators.required, ValidateWhitespace]],
      options: this.formBuilder.array([this.optionsGroup(), this.optionsGroup()]),
      isMaxLimitReached: false,
      hasCorrectAnswer: false
    })
  }

  get getNewQuestion(): FormArray {
    return <FormArray>this.quizForm.get('questionsAndAnswer');
  }

  markRightAnswer(question, option) {
    if (!this.isSubmittedOrApproved) {
      this.quizFormDataDummy.questionsAndAnswer[question].hasCorrectAnswer = false;
      if (this.quizFormDataDummy.questionsAndAnswer[question].options[option].isCorrectAnswer)
        this.quizFormDataDummy.questionsAndAnswer[question].options[option].isCorrectAnswer = false;
      else
        this.quizFormDataDummy.questionsAndAnswer[question].options[option].isCorrectAnswer = true;

      for (let i = 0; i < this.quizFormDataDummy.questionsAndAnswer[question].options.length; i++) {
        if (this.quizFormDataDummy.questionsAndAnswer[question].options[i].isCorrectAnswer)
          this.quizFormDataDummy.questionsAndAnswer[question].hasCorrectAnswer = true;
        this.quizForm.value.questionsAndAnswer[question].hasCorrectAnswer = true;
      }
    }
  }

  addQuestion() {
    this.getNewQuestion.push(this.addQuestionGroup());
    this.quizFormDataDummy.questionsAndAnswer.push({
      hasCorrectAnswer: false,
      isMaxLimitReached: false,
      options: [{ option: '', isCorrectAnswer: false, onHover: false, onIndexHover: false }, { option: '', isCorrectAnswer: false, onHover: false, onIndexHover: false }],
      question: ''
    })
    if (this.quizForm.get('noOfQuestions').value <= this.quizForm.value.questionsAndAnswer.length) {
      this.quizForm.get('noOfQuestions').setErrors(null);
    }
  }

  addOption(index) {
    if (this.quizForm.value.questionsAndAnswer[index].options.length < 5) {
      (<FormArray>(<FormGroup>this.getNewQuestion.controls[index]).controls.options).push(this.optionsGroup());
      this.quizFormDataDummy.questionsAndAnswer[index].options.push({ option: '', isCorrectAnswer: false, onHover: false, onIndexHover: false });
    }
    else
      this.quizForm.value.questionsAndAnswer[index].isMaxLimitReached = true;
  }

  removeOption(question: number, option: number) {
    const control = (<FormArray>(<FormGroup>this.getNewQuestion.controls[question]).controls.options);
    control.removeAt(option);
    this.quizFormDataDummy.questionsAndAnswer[question].options.splice(option, 1);
    this.quizFormDataDummy.questionsAndAnswer[question].hasCorrectAnswer = false;
    for (let i = 0; i < this.quizFormDataDummy.questionsAndAnswer[question].options.length; i++) {
      if (this.quizFormDataDummy.questionsAndAnswer[question].options[i].isCorrectAnswer)
        this.quizFormDataDummy.questionsAndAnswer[question].hasCorrectAnswer = true;
      this.quizForm.value.questionsAndAnswer[question].hasCorrectAnswer = true;
    }
  }

  removeQuestion(i) {
    const control = <FormArray>this.quizForm.get('questionsAndAnswer');
    control.removeAt(i);
    this.quizFormDataDummy.questionsAndAnswer.splice(i, 1);
  }

  private optionsGroup(): FormGroup {
    return this.formBuilder.group({
      option: ['', [Validators.required, ValidateWhitespace]],
      isCorrectAnswer: [false]
    });
  }

  mouseEnter(question, option) {
    this.quizFormDataDummy.questionsAndAnswer[question].options[option].onHover = true;
  }

  mouseLeave(question, option) {
    this.quizFormDataDummy.questionsAndAnswer[question].options[option].onHover = false;
  }

  mouseIndexEnter(question, option) {
    this.quizFormDataDummy.questionsAndAnswer[question].options[option].onIndexHover = true;
  }

  mouseIndexLeave(question, option) {
    this.quizFormDataDummy.questionsAndAnswer[question].options[option].onIndexHover = false;
  }

  onAttemptsChange(value) {
    if (value == 'Yes')
      this.quizForm.controls['attempts'].enable();
    else if (value == 'No') {
      if (this.quizForm.get('attempts').errors != null)
        this.quizForm.get('attempts').errors.errorMessage = '';
      this.quizForm.controls['attempts'].markAsUntouched();
      this.quizForm.controls['attempts'].setValue("");
      this.quizForm.controls['attempts'].disable();
    }
  }

  onRandomizedChange(value) {
    if (value == 'Yes')
      this.quizForm.controls['noOfQuestions'].enable();
    else if (value == 'No') {
      if (this.quizForm.get('noOfQuestions').errors != null)
        this.quizForm.get('noOfQuestions').errors.errorMessage = '';
      this.quizForm.controls['noOfQuestions'].markAsUntouched();
      this.quizForm.controls['noOfQuestions'].setValue("");
      this.quizForm.controls['noOfQuestions'].disable();
    }
  }

  noOfQuestionChange() {
    if (this.quizForm.get('noOfQuestions').value == '0' || this.quizForm.get('noOfQuestions').value == '00') {
      this.isValid = false;
      this.quizForm.controls['noOfQuestions'].markAsTouched();
      this.quizForm.controls['noOfQuestions'].markAsDirty();
      this.quizForm.get('noOfQuestions').setErrors({ 'error': true });
      this.quizForm.get('noOfQuestions').errors.errorMessage = 'No. of questions cannot be zero';
      this.errorFields.push('Number of Questions to be picked');
    }
  }

  attemptsChange() {
    if (this.quizForm.get('attempts').value == '0' || this.quizForm.get('attempts').value == '00') {
      this.isValid = false;
      this.quizForm.controls['attempts'].markAsTouched();
      this.quizForm.controls['attempts'].markAsDirty();
      this.quizForm.get('attempts').setErrors({ 'error': true });
      this.quizForm.get('attempts').errors.errorMessage = 'Attempts cannot be zero';
      this.errorFields.push('Attempts');
    }
  }

  durationChange(type) {
    this.durationErrorMessage = '';
    if (this.quizForm.get('durationHour').value == '') {
      this.durationError = true;
      this.durationEmpty = true;
      this.durationErrorMessage = 'Hours';
    }
    if ((this.quizForm.get('durationHour').value == '0' || this.quizForm.get('durationHour').value == '00')
      && (this.quizForm.get('durationMins').value == '0' || this.quizForm.get('durationMins').value == '00')) {
      this.durationError = true;
      this.durationEmpty = false;
      this.durationErrorMessage = "Both hours and minutes cannot be zero";
    }

    if (this.quizForm.get('durationMins').value == '') {
      this.durationError = true;
      this.durationEmpty = true;
      if (this.durationErrorMessage == '')
        this.durationErrorMessage = 'Minutes';
      else
        this.durationErrorMessage += ', Minutes';
    }
    if (this.durationErrorMessage == "") {
      if (this.quizForm.get('durationHour').errors != null && this.quizForm.get('durationHour').errors.errorMessage == 'Please enter numbers only') {
        this.durationError = true;
        this.durationEmpty = false;
        this.durationErrorMessage = 'Please enter numbers only';
      }
      if (this.quizForm.get('durationMins').errors != null && this.quizForm.get('durationMins').errors.errorMessage == 'Please enter numbers only') {
        this.durationError = true;
        this.durationEmpty = false;
        this.durationErrorMessage = 'Please enter numbers only';
      }
    }

    if (this.durationEmpty && this.durationErrorMessage != '') {
      this.durationErrorMessage += ' cannot be empty';
    }
  }

  onTimeBoxedChange(value) {
    if (value == 'Yes') {
      this.quizForm.controls['durationHour'].enable();
      this.quizForm.controls['durationMins'].enable();
      this.quizForm.controls['durationHour'].markAsUntouched();
      this.quizForm.controls['durationMins'].markAsUntouched();
    }
    else if (value == 'No') {
      this.durationError = false;
      this.quizForm.controls['durationHour'].setValue("");
      this.quizForm.controls['durationMins'].setValue("");
      this.quizForm.controls['durationHour'].disable();
      this.quizForm.controls['durationMins'].disable();
      this.quizForm.controls['durationSeconds'].disable();
    }
  }

  onHasQuizChange(value) {
    if (value == 'No') {
      this.durationError = false;
      this.quizForm.get('isQuizTimeBoxed').setValue('No');
      this.quizForm.get('isQuizRandomized').setValue('No');
      this.quizForm.get('isAttemptsDefined').setValue('No');
      this.quizForm.controls['durationHour'].setValue("");
      this.quizForm.controls['durationMins'].setValue("");
      this.quizForm.controls['durationSeconds'].setValue("");
      this.quizForm.controls['noOfQuestions'].setValue("");
      this.quizForm.controls['attempts'].setValue("");
      this.quizForm.controls['durationHour'].disable();
      this.quizForm.controls['durationMins'].disable();
      this.quizForm.controls['durationSeconds'].disable();
      this.quizForm.controls['attempts'].disable();
      this.quizForm.controls['noOfQuestions'].disable();
    }
  }

  dropOption(event: CdkDragDrop<string[]>, question) {
    const controls = this.quizForm.get('questionsAndAnswer')['controls'][question];
    moveItemInArray(controls.get('options')['controls'], event.previousIndex, event.currentIndex);
  }

  moveUp(question) {
    moveItemInArray(this.quizForm.get('questionsAndAnswer')['controls'], question, question - 1);
    moveItemInArray(this.quizFormDataDummy.questionsAndAnswer, question, question - 1);
    moveItemInArray(this.quizForm.value.questionsAndAnswer, question, question - 1);
  }

  moveDown(question) {
    moveItemInArray(this.quizForm.get('questionsAndAnswer')['controls'], question, question + 1);
    moveItemInArray(this.quizFormDataDummy.questionsAndAnswer, question, question + 1);
    moveItemInArray(this.quizForm.value.questionsAndAnswer, question, question +1);
  }
  onExit() {
    var errorMsg =
      'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?';

    let dataPayload = {
      msg: errorMsg,
      title: 'Exit Form'
    };
    let type = 'exit';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: dataPayload,
      width: '250px',
      height: '250px',
      type: type
    };
    const response = this.dialog.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(res => {
      if (!res) {
        this.enableContentVerificationComponent.emit('101');
      }
      else {
        return;
      }
    });
  }

  onSave(saveOrSubmit) {
    this.isValid = true;
    this.submitted = true;
    this.erroredQuestions = [];
    this.errorFields = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');
    if (this.quizForm.get('hasQuiz').value == 'Yes') {
      const data = this.quizForm.getRawValue();
      this.quizFormData = JSON.parse(JSON.stringify(data));
      this.durationEmpty = false;
      for (let i = 0; i < this.quizFormDataDummy.questionsAndAnswer.length; i++) {
        for (let j = 0; j < this.quizFormDataDummy.questionsAndAnswer[i].options.length; j++) {
          if (this.quizFormDataDummy.questionsAndAnswer[i].options[j].isCorrectAnswer) {
            this.quizFormDataDummy.questionsAndAnswer[i].hasCorrectAnswer = true;
            this.quizForm.value.questionsAndAnswer[i].hasCorrectAnswer = true;
          }
        }
      }

      for (let i = 0; i < this.quizForm.value.questionsAndAnswer.length; i++) {
        if (this.quizForm.value.questionsAndAnswer[i].question.trim().length === 0) {
          this.isValid = false;
          this.erroredQuestions.push(i + 1);
        }
      }

      for (let i = 0; i < this.quizForm.value.questionsAndAnswer.length; i++) {
        for (let j = 0; j < this.quizForm.value.questionsAndAnswer[i].options.length; j++) {
          if (this.quizForm.value.questionsAndAnswer[i].options[j].option.trim().length === 0) {
            this.isValid = false;
            this.erroredQuestions.push(i + 1);
          }
        }
      }

      for (let i = 0; i < this.quizFormDataDummy.questionsAndAnswer.length; i++) {
        if (!this.quizFormDataDummy.questionsAndAnswer[i].hasCorrectAnswer) {
          this.isValid = false;
          this.erroredQuestions.push(i + 1);
        }
      }

      // for (let i = 0; i < this.quizForm.value.questionsAndAnswer.length; i++) {
      //   for (let j = 0; j < this.quizForm.value.questionsAndAnswer[i].options.length; j++) {
      //     if (this.quizForm.value.questionsAndAnswer[i].options[j].option.length == 0)
      //       this.isValid = false;
      //     this.erroredQuestions.push(i + 1);
      //   }
      // }

      if (this.quizForm.get('cutOffMarks').value == '') {
        this.isValid = false;
        this.quizForm.controls['cutOffMarks'].markAsTouched();
        this.quizForm.controls['cutOffMarks'].markAsDirty();
        this.quizForm.get('cutOffMarks').errors.errorMessage = 'Field cannot be empty';
        this.errorFields.push('CutOff Marks');
      }

      if (this.quizForm.get('cutOffMarks').errors != null) {
        this.isValid = false;
        this.errorFields.push('CutOff Marks');
      }

      if (this.quizForm.get('isQuizRandomized').value == 'Yes') {
        if (this.quizForm.get('noOfQuestions').value == '') {
          this.isValid = false;
          this.quizForm.controls['noOfQuestions'].markAsTouched();
          this.quizForm.controls['noOfQuestions'].markAsDirty();
          this.quizForm.get('noOfQuestions').setErrors({ 'error': true });
          this.quizForm.get('noOfQuestions').errors.errorMessage = 'Field cannot be empty';
          this.errorFields.push('Number of Questions to be picked');
        }

        if (this.quizForm.get('noOfQuestions').errors != null) {
          this.errorFields.push('Number of Questions to be picked');
          this.isValid = false;
        }

        if (this.quizForm.get('noOfQuestions').value == '0') {
          this.isValid = false;
          this.quizForm.controls['noOfQuestions'].markAsTouched();
          this.quizForm.controls['noOfQuestions'].markAsDirty();
          this.quizForm.get('noOfQuestions').setErrors({ 'error': true });
          this.quizForm.get('noOfQuestions').errors.errorMessage = 'No. of questions cannot be zero';
          this.errorFields.push('Number of Questions to be picked');
        }

        if (this.quizForm.get('noOfQuestions').value >= this.quizForm.value.questionsAndAnswer.length) {
          this.isValid = false;
          this.quizForm.controls['noOfQuestions'].markAsTouched();
          this.quizForm.controls['noOfQuestions'].markAsDirty();
          this.quizForm.get('noOfQuestions').setErrors({ 'error': true });
          this.quizForm.get('noOfQuestions').errors.errorMessage = 'Randomization question count should be less than the total questions';
          this.errorFields.push('Number of Questions to be picked');
        }
      }


      if (this.quizForm.get('isAttemptsDefined').value == 'Yes') {
        if (this.quizForm.get('attempts').value == '') {
          this.isValid = false;
          this.quizForm.controls['attempts'].markAsTouched();
          this.quizForm.controls['attempts'].markAsDirty();
          this.quizForm.get('attempts').errors.errorMessage = 'Field cannot be empty';
          this.errorFields.push('Attempts');
        }
        if (this.quizForm.get('attempts').value == '0') {
          this.isValid = false;
          this.quizForm.controls['attempts'].markAsTouched();
          this.quizForm.controls['attempts'].markAsDirty();
          this.quizForm.get('attempts').setErrors({ 'error': true });
          this.quizForm.get('attempts').errors.errorMessage = 'Attempts cannot be zero';
          this.errorFields.push('Attempts');
        }
        if (this.quizForm.get('attempts').errors != null) {
          this.isValid = false;
          this.errorFields.push('Attempts');
        }
      }

      if (this.quizForm.get('isQuizTimeBoxed').value == 'Yes') {
        this.durationErrorMessage = '';

        if ((this.quizForm.get('durationHour').value == '0' || this.quizForm.get('durationHour').value == '00')
          && (this.quizForm.get('durationMins').value == '0' || this.quizForm.get('durationMins').value == '00')) {
          this.durationError = true;
          this.isValid = false;
          this.durationEmpty = false;
          this.durationErrorMessage = "Both hours and minutes cannot be zero";
          this.errorFields.push('Duration');
        }

        if (this.quizForm.get('durationHour').errors != null
          || this.quizForm.get('durationMins').errors != null) {
          this.isValid = false;
          this.errorFields.push('Duration');
        }

        if (this.quizForm.get('durationHour').value === '') {
          this.durationEmpty = true;
          this.durationErrorMessage = 'Hours';
        }
        if (this.quizForm.get('durationMins').value === '') {
          this.durationEmpty = true;
          if (this.durationErrorMessage == '')
            this.durationErrorMessage = 'Minutes';
          else
            this.durationErrorMessage += ', Minutes';
        }

        if (this.durationEmpty) {
          this.durationError = true;
          this.isValid = false;
          this.durationErrorMessage += ' cannot be empty';
          this.errorFields.push('Duration');
        }
      }

      if (this.quizFormData.hasQuiz == "Yes")
        this.quizFormData.hasQuiz = true;
      else
        this.quizFormData.hasQuiz = false;

      if (this.quizFormData.isAttemptsDefined == "Yes")
        this.quizFormData.isAttemptsDefined = true;
      else
        this.quizFormData.isAttemptsDefined = false;

      if (this.quizFormData.isQuizRandomized == "Yes")
        this.quizFormData.isQuizRandomized = true;
      else
        this.quizFormData.isQuizRandomized = false;

      if (this.quizFormData.isQuizTimeBoxed == "Yes")
        this.quizFormData.isQuizTimeBoxed = true;
      else
        this.quizFormData.isQuizTimeBoxed = false;
      this.quizFormData.questionsAndAnswer = this.quizForm.value.questionsAndAnswer;
      for (let i = 0; i < this.quizForm.value.questionsAndAnswer.length; i++) {
        this.quizForm.value.questionsAndAnswer[i].hasCorrectAnswer = this.quizFormDataDummy.questionsAndAnswer[i].hasCorrectAnswer;
        for (let j = 0; j < this.quizForm.value.questionsAndAnswer[i].options.length; j++) {
          this.quizForm.value.questionsAndAnswer[i].options[j].isCorrectAnswer = this.quizFormDataDummy.questionsAndAnswer[i].options[j].isCorrectAnswer;
        }
      }


      this.errorFields = this.errorFields.filter(function (item, pos, arr) {
        return arr.indexOf(item) == pos;
      })
      this.erroredQuestions = this.erroredQuestions.filter(function (item, pos, arr) {
        return arr.indexOf(item) == pos;
      })

      if (this.erroredQuestions.length > 0) {
        var questionError = "Question(s)";
        for (let i = 0; i < this.erroredQuestions.length; i++) {
          if (i == 0)
            questionError += " " + this.erroredQuestions[i];
          else if (i > 0)
            questionError += ", " + this.erroredQuestions[i];
        }
        this.errorFields.push(questionError);
      }

      if (this.isValid) {
        if (saveOrSubmit == "submit") {
          this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
            disableClose: true,
            panelClass: "expertDialogContainer",
            data: 'Confirmation'
          });

          this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              if (result == 'Yes') {
                this._contentUpload101Service.updateQuizDataFor101(this.quizFormData, { headers }, this.course.UniqueId)
                  .subscribe(response => {
                    const isOnlyQuiz = this.course.FromHomePage ? "OnlyQuizSubmitMessage" : "QuizSubmitMessage";
                    this.confirmDialogRef = null;
                    if (response) {
                      this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
                        disableClose: true,
                        panelClass: "expertDialogContainer",
                        data: isOnlyQuiz
                      });
                      if (saveOrSubmit == "submit") {
                        this.confirmDialogRef.afterClosed().subscribe(() => {
                          this.enableContentVerificationComponent.emit('101');
                        });
                      }
                    }
                  });
                if (!this.course.FromHomePage) {
                  this._contentUpload101Service.submitCourse(this.course.UniqueId, { headers })
                    .subscribe(response => {
                    });
                }

              }
            }
            this.confirmDialogRef = null;
          });
          return true;
        }
        else {
          this._contentUpload101Service.updateQuizDataFor101(this.quizFormData, { headers }, this.course.UniqueId)
            .subscribe(response => {
              this.confirmDialogRef = null;
              if (response) {
                if(this.course?.fromContentUpload201){
                  return true;
                }
                this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
                  disableClose: true,
                  panelClass: "expertDialogContainer",
                  data: 'QuizSaveMessage'
                });
                if (saveOrSubmit == "submit") {
                  this.confirmDialogRef.afterClosed().subscribe(() => {
                    this.enableContentVerificationComponent.emit('101');
                  });
                }
              }
            });
            if(this.course?.fromContentUpload201){
              return true;
            }
        }
      }
      else {
        this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
          disableClose: true,
          panelClass: "expertDialogContainer",
          data: this.errorFields
        });
        this.confirmDialogRef.afterClosed().subscribe(() => {

        });
        return false;
      }
    } else {
      this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
        disableClose: true,
        panelClass: "expertDialogContainer",
        data: 'Confirmation'
      });

      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result == 'Yes') {
            if (saveOrSubmit == "submit") {
              this._contentUpload101Service.submitCourse(this.course.UniqueId, { headers })
                .subscribe(response => {
                  this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
                    disableClose: true,
                    panelClass: "expertDialogContainer",
                    data: 'QuizSubmitMessage'
                  });

                  this.confirmDialogRef.afterClosed().subscribe(() => {
                    this.enableContentVerificationComponent.emit('101');
                  });
                })
            } else {
              this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
                disableClose: true,
                panelClass: "expertDialogContainer",
                data: 'QuizSaveMessage'
              });
            }
          }
        }
        this.confirmDialogRef = null;
      });
      return false;
    }
  }

  onSubmit() {
    this.onSave('submit');
  }

  onBackPressed(){
    if (this.course.IsCourseInternal) {
      //Goto Unit Component
      this.enableUnitsComponent.emit(this.course);
    }
    else {
      //Goto Course Detail Component
      this.enable101ContentUploadComponent.emit(this.course.UniqueId);
    }
  }

}


