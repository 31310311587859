import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SiteDownComponent } from './site-down/site-down.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { CookiesDisabledComponent } from './cookies-disabled/cookies-disabled.component'

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  declarations: [UnauthorizedComponent, SiteDownComponent, CookiesDisabledComponent]
})
export class GuardFailPageModule { }
