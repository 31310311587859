import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-courses-popup',
  templateUrl: './courses-popup.component.html',
  styleUrls: ['./courses-popup.component.scss']
})
export class CoursesPopupComponent implements OnInit {
  showCourses: boolean;
  coursesSelected: any[] = [];
  title: any;
  expertise: any;
  uniqueId: any;

  //For selected Account and Courses projects, pass the required variable as true in dialog data
  isAccount: boolean = false;
  isProject: boolean = false;
  accountId: string = "";
  projectId: string = "";

  constructor(
    public dialogRef: MatDialogRef<CoursesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.coursesSelected = data.data.selectedCourses;
    this.title = data.data.title;
    this.expertise = data.data.expertise;
    this.uniqueId = data.data.uniqueId;

    /* If this data is passed then take it else set as false and follow the default functionality of 
    fetching yorbit courses */
    this.isAccount = data.data.isAccount ? data.data.isAccount : false;
    this.isProject = data.data.isProject ? data.data.isProject : false;
    this.accountId = data.data.accountId ? data.data.accountId : "";
    this.projectId = data.data.projectId ? data.data.projectId : "";
    //console.log(this.isAccount,this.isProject);
  }

  ngOnInit() {
    this.showCourses = true;
  }

  ngOnDestroy() {
    this.showCourses = false;
  }

  close(canSubmit) {
    this.dialogRef.close({
      canSubmit: canSubmit,
      selectedCourses: this.coursesSelected
    });
  }

  onSelect(event) {
    //console.log('event', event);
    if (event.courses && event.courses.length > 0) {
      this.coursesSelected = event.courses;
    }
    else {
      this.coursesSelected = this.coursesSelected;
    }
    this.close(event.canSubmit);
  }
}
