import { Component, OnInit, Input } from '@angular/core';
import {CountFormatterService} from '../count-formatter.service';;
@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss']
})
export class RatingsComponent implements OnInit {
  @Input('rating') rating;
  @Input() numberOfPeopleRated;
  limit: number;
  roundedOffVal : any;
  limit_Array = [];
  star_rate: Array<boolean> = [];
  star_half: Array<boolean> = [];
  star_border: Array<boolean> = [];

  constructor(private countFormatterService:CountFormatterService) {}

  ngOnInit() {
    this.limit = 5;
    this.roundedOffVal = 0;
    this.limit_Array = new Array(this.limit);
    for (let count = 0; count < this.limit; count++) {
        this.star_rate[count] = false;
        this.star_half[count] = false;
        this.star_border[count] = true;
    }
    this.setRating(this.rating);
    this.formatCount(this.numberOfPeopleRated);
  }

  setRating(rating){
    if(this.isFloat(rating)){
      const floats : any = (rating % 1).toFixed(2);
      if(floats > 0.5){
        this.roundedOffVal = Math.ceil(rating);
      }
      else if(floats <= 0.4){
        this.roundedOffVal = Math.floor(rating);
      }
      else{
        this.roundedOffVal = rating;
      }
      this.setRatingIcons(this.roundedOffVal);
    }
    else{
      this.setRatingIcons(this.rating);
    }
  }

  setRatingIcons(rating) {
    let iterator = 0;

    for (iterator = 0; iterator < rating; iterator++) {
      this.setFullRate(iterator);
    }
    if (this.isFloat(rating) === true) {
      this.setHalfRate(iterator - 1);
    }
  }

  setFullRate(i) {
    this.star_rate[i] = true;
    this.star_border[i] = false;
    this.star_half[i] = false;
  }

  setHalfRate(i) {
    this.star_rate[i] = false;
    this.star_border[i] = false;
    this.star_half[i] = true;
  }

  isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  
  formatCount(count){
    this.numberOfPeopleRated = this.countFormatterService.CountFormatter(count);
  }
}
