import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '../../../search/search.service';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import { Store } from '@ngrx/store';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import * as fromUserDetailsStore from '../../../shared/user-details/store';
import { LearningPathService } from './../../../learning-path/learning-path.service';
import { NewAndTrendingService } from '../../services/new-and-trending.service';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { DeclarePopupComponent } from '../declare-popup/declare-popup.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ContentTileLpService } from '../content-tile-lp/content-tile-lp.service';
import { SystemService } from '../../services/system.service';
import { DataTemplateDialogComponent } from '../data-template-dialog/data-template-dialog.component';
import * as fromDeclareStore from '../../declare-store/store';
import { ContentTileBrowseService } from './content-tile-browse.service';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Globals } from '../../../globals';

@Component({
  selector: 'yorbit-content-tile-browse',
  templateUrl: './content-tile-browse.component.html',
  styleUrls: ['./content-tile-browse.component.scss']
})
export class ContentTileBrowseComponent implements OnInit {
  @Input('cardDetails') cardDetails: any;
  @Input('featuredIn') featuredIn: any;
  courseRepresentationType: string;
  contentType: string;
  avgRating: string;
  numberOfPeopleRated: any;
  roleList: fromRoleAccessStore.IroleData;
  isAddedToLP: boolean;
  browseContentTileSub: any;
  coursesList: Array<any>;
  noDataAvailable: boolean;
  enableShare: boolean;
  arrayFilterPipe: any;
  config: Ienvironment;
  declareWorkflowStatus: boolean;
  showDeclare: boolean;
  courseTypeMaster: any;
  constructor(
    private searchService: SearchService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private lpService: LearningPathService,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private newAndTrendingService: NewAndTrendingService,
    private _envSvc: EnvironmentService,
    private router: Router,
    private _popup: MatDialog,
    private contentTileLpService: ContentTileLpService,
    private systemService: SystemService,
    private declareStore: Store<fromDeclareStore.IdeclareState>,
    private _contentTileBrowseService: ContentTileBrowseService,
    private _appInsightSvc: AppInsightService,
    private globals: Globals
  ) {
    this.isAddedToLP = false;
    this.browseContentTileSub = {};
    this.coursesList = [];
    this.roleList = {};
    this.enableShare = true;
    this.arrayFilterPipe = new ArrayPropertyFilterPipe();
    this.config = this._envSvc.getEnvironment();
    this.numberOfPeopleRated = 0;
  }

  ngOnInit() {
    //console.log("list of course -->", this.cardDetails);
    this.courseTypeMaster = this.globals.typetf3
      .filter(x => x.Type === this.cardDetails.Type || x.Type === this.cardDetails.CourseType)[0];
    //get ratings
    // console.log("courseTypeMaster..................", this.courseTypeMaster) 
    //console.log(this.cardDetails.Name, ": ", this.courseTypeMaster)
    // console.log("carddetails ------>",this.cardDetails)
    if (this.cardDetails && this.cardDetails.Id) {
      this.noDataAvailable = false;
      this.onFirstLoad();
    } else {
      this.noDataAvailable = true;
    }
    this.showDeclare =
      'Prerequisite Course in Course Request' === this.featuredIn &&
      !this.cardDetails.IsGlobalAndMandatory &&
      (this.cardDetails.ItemType.toLowerCase() == 'course' || this.cardDetails.ItemType.toLowerCase() == 'riskbrigadecourse') &&
      !(this.cardDetails.AccountPackage || this.cardDetails.ProjectPackage) &&
      !this.isDeclareBlocked();

    this.getDeclareWorkflowStatus(
      this.cardDetails.Id,
      this.cardDetails.Expertise
    );
    this.subscribeToShareCount();
  }
  onFirstLoad() {
    this.cardDetails.IsContentNew = false;
    this.cardDetails.IsContentTrending = false;
    this.isContentNew();
    this.isContentTrending();
    //ratings commented to check the preformance issues
    //this.getAvgRating(this.cardDetails);
    //this.isCardAddedToLP();
    this.getRoles();
  }
  subscribeToShareCount() {
    this.browseContentTileSub.shareCountSub = this._contentTileBrowseService
      .getUpdatedShareCount()
      .subscribe(count => {
        if (count != null) {
          if (this.globals.sharedUniqueId == '') {
            this.cardDetails.TotalShares = count;
          }
          else {
            if (this.cardDetails.UniqueId == this.globals.sharedUniqueId) {
              this.cardDetails.TotalShares = count;
            }
          }
        }
      }).add(() => {
        this.globals.sharedUniqueId = '';
      })
  }
  isContentNew() {
    let minutes = 1000 * 60;
    let hours = minutes * 60;
    let day = hours * 24;
    let today = new Date();
    let difference =
      Date.parse(today.toDateString()) -
      Date.parse(this.cardDetails.CreatedDate);
    let daysBetween = Math.round(difference / day);
    if (14 >= daysBetween) {
      this.cardDetails.IsContentNew = true;
    } else {
      this.cardDetails.IsContentNew = false;
    }
    //logic to enable/disable share button for Project Packages
    if (
      this.cardDetails.AccountPackage == true ||
      this.cardDetails.AccountPackage == 'true' ||
      this.cardDetails.ProjectPackage == true ||
      this.cardDetails.ProjectPackage == 'true'
    ) {
      this.enableShare = false;
    } else {
      this.enableShare = true;
    }
  }
  isContentTrending() {
    this.newAndTrendingService.getNewAndTrendingList().subscribe((list: any) => {
      if (list.trendingPackages && list.trendingPackages.packages) {
        if (
          this.arrayFilterPipe.transform(list.trendingPackages.packages, {
            property: 'UniqueId',
            flag: this.cardDetails.UniqueId
          }).length != 0
        ) {
          this.cardDetails.IsContentTrending = true;
        }
      }
    });
  }
  getAvgRating(data) {
    if (
      data.AccountPackage === 'true' ||
      data.ProjectPackage === 'true' ||
      data.AccountPackage === true ||
      data.ProjectPackage === true
    ) {
      this.avgRating = '0';
    } else {
      if (data.ItemType.toLowerCase() === 'course' || data.ItemType.toLowerCase() === 'riskbrigadecourse') {
        if (data.AvgRating === undefined) {
          // to get the feedback details
          let rating;
          this.searchService.getFeedbackReports(data.Id).subscribe(
            resp => {
              //////console.log('content tile feedback data:', resp);
              if (resp.AvgRating == null || resp.AvgRating === undefined) {
                this.avgRating = '0';
              } else {
                rating = resp.AvgRating * 1;
                this.numberOfPeopleRated = resp.courseFeedbackList.length;
                this.avgRating = rating.toFixed(1);
              }
            },
            error => {
              // //console.log(
              //   'error from content tile browse controller while calling feedback api',
              //   error
              // )
            });
        } else {
          this.avgRating = data.AvgRating;
          this.numberOfPeopleRated = data.CountOfFeedbackByMinds;
        }
      } else {
        this.avgRating = '0';
      }
    }

    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe(roleList => {
        this.roleList = roleList;
      });
  }
  getRoles() {
    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe(roleList => {
        this.roleList = roleList;
      });
  }
  goToDetailsPage(content) {
    //////console.log(content);
    let webLink, URL, shouldOpenInNewTab;
    if (
      this.featuredIn === 'Recomendation Course' ||
      this.featuredIn === 'Prerequisite Course' ||
      this.featuredIn === 'Prerequisite Course in Course Request' ||
      this.featuredIn === 'Recommended Course for a Learner' ||
      this.featuredIn === 'RBLBrowse' ||
      this.featuredIn === 'Similar Recommendation for a Course' ||
      this.featuredIn === 'ESG Course'
    ) {
      shouldOpenInNewTab = true;
    } else {
      shouldOpenInNewTab = false;
    }
    if (
      content.AccountPackage === 'true' ||
      content.ProjectPackage === 'true' ||
      content.AccountPackage === true ||
      content.ProjectPackage === true
    ) {
      if (shouldOpenInNewTab) {
        //URL = this.config.apiUrlAPYammer;
        URL =
          // this.config.apiUrlAPYammer.split('/detailsPage')[0] +
          // '/PdetailsPage/';
          this.config.apiUrlAPYammer;
      } else {
        URL = 'PdetailsPage/';
      }
    } else {
      if (shouldOpenInNewTab) {
        URL = this.config.apiUrlYorbitYammer;
        URL =
          // this.config.apiUrlYorbitYammer.split('/detailsPage')[0] +
          // '/detailsPage/';
          this.config.apiUrlYorbitYammer;
      } else {
        URL = 'detailsPage/';
      }
    }
    if (content.ItemType === 'FamilyPackage' || content.ItemType === 'Course' || content.ItemType === 'RiskBrigadeCourse') {
      //for yorbit content
      if (
        (content.AccountPackage == null ||
          content.AccountPackage === 'false' ||
          content.AccountPackage === false) &&
        (content.ProjectPackage == null ||
          content.ProjectPackage === 'false' ||
          content.ProjectPackage === false)
      ) {
        webLink =
          URL + content.ItemType + '/' + content.Expertise + '/' + content.Id;
      }
      //for account and project content
      else {
        if (content.AccountId === undefined) {
          content.AccountId = content.Account;
        }
        if (content.ProjectId === undefined) {
          content.ProjectId = content.Project;
        }
        if (
          content.AccountPackage === true ||
          content.AccountPackage === 'true'
        ) {
          content.ProjectId = 0;
          content.AccountPackage = true;
          content.ProjectPackage = false;
        } else {
          content.AccountPackage = false;
          content.ProjectPackage = true;
        }
        // content.UniqueId = content.Id;
        webLink =
          URL +
          content.UniqueId +
          '/' +
          content.AccountPackage +
          '/' +
          content.ProjectPackage +
          '/' +
          content.AccountId +
          '/' +
          content.ProjectId +
          '/' +
          content.ItemType +
          '/' +
          content.Expertise +
          '/' +
          content.Id;
      }
    }
    if (shouldOpenInNewTab) {
      window.open(webLink, '_blank');
    } else {
      this.router.navigate([webLink]);
    }
  }

  stopPropagation(event, action = '', cardDetails = null) {
    event.stopPropagation();
    if (action == 'share') {
      this.globals.sharedUniqueId = cardDetails.UniqueId;
    }
  }

  declareSkill(event) {
    event.stopPropagation();
    if (!this.declareWorkflowStatus) {
      //action on declare flag if a course is not requested for approval or not requested for schedule

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //addDialogContainer

      dialogConfig.data = {
        courseName: this.cardDetails.Name,
        courseId: this.cardDetails.Id,
        expertise: this.cardDetails.Expertise,
        skillPlanId: 0,
        learningPathId: 0,
        IsSkillPlan: false
      };

      const dialogRef = this._popup.open(DeclarePopupComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(response => {
        if (response) {
          let declareFlagStatus = [{ "CourseId": parseInt(this.cardDetails.Id), "DeclareFlagStatus": "DeclaredAndApproved" }]
          this.declareStore.dispatch(
            new fromDeclareStore.DeclareGetStatusSuccess(declareFlagStatus)
          );
          this.processDeclareWorkflow(
            "declaredandapproved"
          );
        }
      });
    } else {
      if (this.systemService.detectMobileAgent()) {
        const message = this.declareWorkflowStatus
          ? 'You have already declared this Course'
          : 'Declare this skill';

        this._popup.open(DataTemplateDialogComponent, {
          data: {
            data: message,
            itemName: this.cardDetails.ItemName
          },
          panelClass: 'popupDialogContainer',
          disableClose: true
        });
      }
    }
  }

  getDeclareWorkflowStatus(courseId, expertise) {
    if (this.showDeclare) {
      this.declareStore
        .select(fromDeclareStore.getDeclareStatusByCourseId(courseId))
        .subscribe(status => {
          if (status) {
            this.processDeclareWorkflow(status.DeclareFlagStatus);
          }
        });
    }
  }
  processDeclareWorkflow(status) {
    switch (status.toLowerCase()) {
      //not started status
      case 'not declared':
      case 'denied':
        this.declareWorkflowStatus = false;
        break;
      case 'declaredandapproved':
        this.declareWorkflowStatus = true;
        break;
      case 'approved':
      case 'pending':
      case 'no any 201 course is present':
        this.declareWorkflowStatus = true;
        break;
      default:
        this.declareWorkflowStatus = false;
        break;
    }
  }

  saveDataToAppInsight() {
    let evtName = '';
    const insightPayload = {
      ItemType: this.cardDetails.ItemType,
      Action: 'Click',
      Location: this.featuredIn
    };
    if (this.featuredIn == 'Recommended Course for a Learner') {
      evtName = 'Recommendation for a Learner';
    } else if (this.featuredIn == 'Similar Recommendation for a Course') {
      evtName = 'Recommendation based on a Course';
    } else {
      evtName = 'Course Recommendation';
    }
    this._appInsightSvc.trackEvent(evtName, insightPayload);
  }

  isDeclareBlocked() {
    let id = this.cardDetails.ItemId
      ? this.cardDetails.ItemId.toString()
      : this.cardDetails.Id.toString();
    if (
      this.globals.declareBlockedCourses.includes(this.cardDetails.ItemId) ||
      this.globals.courseListForShowingLastCompletedDate.includes(id) ||
      this.globals.declareBlockedAceingLeadership.includes(id)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
