import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  termsOfUseBulletin : Array<Object>;
  disclaimer : string;
  termsOfUse : string;
  constructor() {
    const data = this.getTermsOfUse();
    this.termsOfUseBulletin = data.termsOfUseBulletin;
    this.disclaimer = data.disclaimer;
    this.termsOfUse = data.termsOfUse;
  }

  ngOnInit() {

  }

  getTermsOfUse(){
      let data:any;
      const isCustomer=(-1!==window.location.href.indexOf('external'))
      if (isCustomer) {
          data = {
              "termsOfUseBulletin": [
                  { "A": "You shall use the Shoshin School application only for internal training proposes and not for any commercial purposes." },
                  { "A": "You shall not copy any part or whole of Shoshin School application for any purpose other than stated above." },
                  { "A": "You are authorized to view and play the content." },
                  { "A": "You shall not download, modify, print, copy, store, archive, distribute, transmit, display, perform, reproduce, publish, license, create, derivative works from, transfer or sell any content/part of content in any manner." },
                  { "A": "Any transmission through other channels, copying, reproducing, downloading in any medium, enabling as hyperlinks through other websites or portals or any channels are strictly prohibited." },
                  { "A": "You shall maintain the confidentiality and privacy of the contents shared with the Shoshin School application" },
                  { "A": "You shall not sell or sub license the Shoshin School application, in any form, as a stand-alone / separate product." },
                  { "A": "You shall not distribute the Shoshin School application." },
                  { "A": "Shoshin School hosts videos based on the consent obtained from Youtube." },
                  { "A": "All contents and materials available in the videos are the respective author’s independent creation and not copied or derived from any other third party sources or by violating any third party rights." },
                  { "A": "Authors are responsible for the originality of the contents of the videos and LTIMindtree Limited is not responsible for any claims of third party intellectual property violations." },
                  { "A": "The videos may include links to third party websites that are controlled and maintained by others. Any link to other websites is not an endorsement of such websites and you acknowledge and agree that LTIMindtree Limited is not responsible for the content or availability of any such sites" },
                  { "A": "LTIMindtree Limited is not responsible for inappropriate usage of this video by its partners and associates etc. for which such party accessing the video shall have direct and personal liabilities under applicable laws." },
                  { "A": "Any attempt to circumvent the technological measures through which these videos are protected including but not limited to downloading, watermarking, editing, quality enhancing etc. is strictly prohibited." },
                  { "A": "Any violation of the terms aforesaid may result in legal action at the sole discretion of LTIMindtree Limited. Customer shall indemnify LTIMindtree Limited, its Directors and associates from all claims, losses, liabilities and expenses in the event of any breach by Customer causing a claim upon LTIMindtree Limited, its directors or officers." }

              ],
              "disclaimer": "LTIMindtree Limited – Video Learning Content",
              "termsOfUse": "Terms of Use - Please read the conditions below carefully before using the video based learning content. Your access of the video implies that you accept all terms and conditions herein. If you do not agree with these terms, do not use, browse, proceed or play the video."
          };
      } else {
          data = {
              "termsOfUseBulletin": [
                  { "A": "These video contents are solely meant for internal training and self-learning activities of LTIMindtree Limited Associates." },
                  { "A": "You are authorized to view and play the content. You shall not download, modify, print, copy, store, archive, distribute, transmit, display, perform, reproduce, publish, license, create, derivative works from, transfer or sell any content/part of content in any manner. The objective of the video is to educate and train LTIMindtree Limited Associates. LTIMindtree Limited is not charging any fee to access these video. Any transmission through other channels, copying, reproducing, downloading in any medium, enabling as hyperlinks through other websites or portals or any channels are strictly prohibited." },
                  { "A": "Do not use or access these contents from LTIMindtree Limited’s Customer locations or on customer provided laptops or systems on customer networks." },
                  { "A": "Please maintain the confidentiality and privacy of the contents shared in these videos in the interest of LTIMindtree Limited and its business processes." },
                  { "A": "LTIMindtree Limited holds the right to withdraw, discontinue, modify, update, change, revise the video content at any point of time without prior notice." },
                  { "A": "Do not embed, use, provide reference to these videos in any way in any of the social or community media or outside LTIMindtree Limited premises." },
                  { "A": "All contents and materials available on this portal or videos are the respective author’s independent creation and not copied or derived from any other third party sources or by violating any third party rights and are compiled with reasonable due diligence. Authors are responsible for the originality of the contents of the portal and LTIMindtree Limited is not responsible for any claims of third party intellectual property violations." },
                  { "A": "LTIMindtree Limited respects IP rights, copyright, privacy of the products, logs, organizations used/mentioned in the contents of this portal including but not limited to videos, tutorials, PowerPoint slides, audios etc." },
                  { "A": "This video may include links to third party websites that are controlled and maintained by others. Any link to other websites is not an endorsement of such websites and you acknowledge and agree that LTIMindtree Limited is not responsible for the content or availability of any such sites." },
                  { "A": "LTIMindtree Limited will not be liable for any direct, indirect or consequential loss or damage whatever, including loss of business, opportunity, data, profits etc., arising out of or in connection with the use of these videos by any third parties." },
                  { "A": "LTIMindtree Limited does not guarantee the accuracy, completeness, timeliness, or otherwise endorse, the views or opinions expressed in this video." },
                  { "A": "This portal contains some of LTIMindtree Limited’s independent video creations which are  hosted based on the consent obtained from the author of the video. The author or the expert or the subject matter expert has granted permission to LTIMindtree Limited to use for educational purpose. You shall not copy any part or whole of the video for any purpose in any recording device." },
                  { "A": "LTIMindtree Limited is not responsible for inappropriate usage of these videos by its partners and associates etc. within or outside LTIMindtree Limited premises for which such party accessing the video shall have direct and personal liabilities under applicable laws." },
                  { "A": "Any attempt to circumvent the technological measures through which these videos are protected including but not limited to downloading, watermarking, editing, quality enhancing etc. is strictly prohibited." },
                  { "A": "Any violation of the terms aforesaid may result in legal action at the sole discretion of LTIMindtree Limited. You shall indemnify LTIMindtree Limited, its Directors and co-associates from all claims, losses, liabilities and expenses in the event of any breach by you causing a claim upon LTIMindtree Limited, its directors or officers." },
                  { "A": "The terms are governed by laws of India. All disputes arising herefrom shall be subject to jurisdiction of Bangalore courts." }
              ],
              "disclaimer": "LTIMindtree Limited – Video Learning Content",
              "termsOfUse": "Terms of Use - Please read the conditions below carefully before using the video based learning content. Your access of the video implies that you accept all terms and conditions herein. If you do not agree with these terms, do not use, browse, proceed or play the video."
          };
      }
      return data;
  }
}
