import {
  Directive,
  Input,
  Output,
  ElementRef,
  HostListener,
  EventEmitter,
  OnChanges
} from '@angular/core';

@Directive({
  selector: '[YorbitWorkspaceFileModel]'
})
export class FileModelDirective implements OnChanges {
  @Input() inputFor: string;
  @Input() modifyDOMElements: boolean;
  @Output() fileModel: EventEmitter<any> = new EventEmitter<any>();
  constructor(private element: ElementRef) {}
  @HostListener('change')
  onChange() {
    this.fileModel.emit({
      inputFor: this.inputFor,
      file: this.element.nativeElement.files[0]
    });
    if (this.modifyDOMElements) {
      if (this.element.nativeElement.files[0]) {
        // this.element.nativeElement.nextElementSibling.children.forEach(
        //   element => {
        //     if (element.className.indexOf('input-label') != -1) {
        //       element.attributes[
        //         'title'
        //       ].value = this.element.nativeElement.files[0].name;
        //       element.textContent = this.element.nativeElement.files[0].name;
        //     }
        //   }
        // );
        for (
          let index = 0;
          index < this.element.nativeElement.nextElementSibling.children.length;
          index++
        ) {
          if (
            this.element.nativeElement.nextElementSibling.children[
              index
            ].className.indexOf('input-label') != -1
          ) {
            this.element.nativeElement.nextElementSibling.children[
              index
            ].attributes[
              'title'
            ].value = this.element.nativeElement.files[0].name;
            this.element.nativeElement.nextElementSibling.children[
              index
            ].textContent = this.element.nativeElement.files[0].name;
          }
        }
        // this.element.nativeElement.nextElementSibling.children[1].attributes['title'].value=this.element.nativeElement.files[0].name;
        // this.element.nativeElement.nextElementSibling.children[1].textContent =this.element.nativeElement.files[0].name;
      } else {
        this.element.nativeElement.nextElementSibling.innerHTML =
          this.element.nativeElement.nextElementSibling.firstChild.outerHTML +
          "<div title=''>Select a file</div>";
      }
    }
  }
  ngOnChanges(changes){
    if(this.inputFor == 'clear'){
      //console.log(this.inputFor);
     //this.element.nativeElement.value = '';    
     this.element.nativeElement.nextElementSibling.innerHTML =
     this.element.nativeElement.nextElementSibling.firstChild.outerHTML +
     "<div title=''>Select a file</div>";
    }else if(this.inputFor == 'clearCU'){
      //console.log(this.inputFor);
     //this.element.nativeElement.value = '';    
     this.element.nativeElement.value = '';
    }
  }
}
