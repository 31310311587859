import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'yorbit-type-filter',
  templateUrl: './type-filter.component.html',
  styleUrls: ['./type-filter.component.scss']
})
export class TypeFilterComponent implements OnInit {
  @Input() filterData: any;
  @Input() isCourseSelected:boolean;
  @Output() typeUpdateEvent = new EventEmitter<string>();
  @Output() resetFilterEvent = new EventEmitter<string>();

  typeFilterForm = new FormControl();
  filterList: any;
  SelectedFilter: { Expertise?:string;CertificationStatuses?: string; CertificationSubStatuses?: string; CourseTypes: string; Vendors: string; Academies: string; Locations?: string; };
  showCourseFilter: boolean;
  constructor() {
   }

  ngOnInit() {
    this.typeInit();
  }
  typeInit(){
    this.filterList=this.filterData.data;
    this.filterList.Expertise=['201','301']
    this.SelectedFilter={
      CertificationStatuses:"All",
      CertificationSubStatuses:"All",
      CourseTypes:"All",
      Vendors:"All",
      Academies:"All",
      Locations:"All",
      Expertise:"All"
        }
  }
resetIndexGet(){

}
Reset(){
  this.resetFilterEvent.emit()
}
onTypeSelected(event){
  this.typeUpdateEvent.emit("Type")
}
}
