<div class="roadMapContainer">

  <div fxLayout="row">
    <div class="roleText roleFont">
      My Role
    </div>
    <div class="roleFont">
      :
    </div>

    <div class="roadMap">
      <div class="mapContainer">
        <div class="frlsTag">FAMILY</div>
        <div class="frlsTagText">{{empRoleTagging?empRoleTagging.Family:""}}</div>
      </div>
      <div class="mapContainerLine"></div>
      <div class="mapContainer">
        <div class="frlsTag">ROLE</div>
        <div class="frlsTagText">{{empRoleTagging?empRoleTagging.Role:""}}</div>
      </div>
      <div class="mapContainerLine"></div>
      <div class="mapContainer cursorPointer" (click)="navigateToRBL('level')">
        <div class="frlsTag">LEVEL</div>
        <div class="frlsTagText">{{empRoleTagging?empRoleTagging.Level:""}}</div>
      </div>
      <div class="mapContainerLine"></div>
      <div class="mapContainer cursorPointer" (click)="navigateToRBL('specialization')">
        <div class="frlsTag">SPECIALIZATIONS</div>
        <div class="frlsTagText">{{empRoleTagging?empRoleTagging.Specialization:""}}</div>
      </div>

    </div>
    <div>
      <button class="reportButton" (click)="reportIssue()">
        REPORT AN ISSUE
      </button>
    </div>

  </div>


  

</div>
