import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  EventEmitter,
  ElementRef,
  Output,
} from '@angular/core';
import { PlayerVarsYoutube, OptionalParams } from './youtube-player.config';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { VideoPlayersService } from '../video-players.service';
import * as YouTubePlayer from 'youtube-player';
import { PlaylistService } from '../../playlist/playlist.service';
import { Subscriber } from 'rxjs';
import { LearningPathService } from '../../../learning-path/learning-path.service';
import * as fromUserDetailsStore from '../../user-details/store';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  LPBreadcrumbsStateModel,
  ILPDetailsModel,
  ICourseDetailsModel,
  IPackageDetailsModel,
  IVideoDetailsModel,
} from '../../../learning-path/learning-path-breadcrumbs.model';
import { ILPBreadcrumbs } from '../../../learning-path/learning-path-breadcrumbs.interface';
import { interval } from 'rxjs';
import { VideoPlayerPopupComponent } from '../video-player-popup/video-player-popup.component';
import { filter } from 'rxjs/operators';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import { AddPopupComponent } from '../../card-feature/add-content/add-popup/add-popup.component';
import { Globals } from '../../../globals';

@Component({
  selector: 'yorbit-youtube-video-player',
  templateUrl: './youtube-video-player.component.html',
  styleUrls: ['./youtube-video-player.component.scss'],
})
export class YoutubeVideoPlayerComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() PlayerConfig: any;
  playerVar: any;
  player: any;
  youtubePlayerEvents: any;
  videoCompSubscriptions: any;
  playlist: any;
  fullPlayListLoaded: boolean;
  currentPlaylistIndex: number;
  currentVideoId: string;
  progressCaptureAllowed: boolean;
  routeParams: any;
  canPlayAll: boolean;
  LPBreadcrumbs: ILPBreadcrumbs;
  videoPlayerId: string;
  subscriptionToIframe: any;
  videoPlayerLoading: boolean;
  resumeVideo: boolean;
  warningMsgPopupSubscription: any;
  closeWindowwarningMsgPopupSubscription: any;
  indexForShowingVideoMsg: number;
  indexForShowingVideoMsgClosePopup: number;
  roleList: fromRoleAccessStore.IroleData;
  isQuizPopupOpen: boolean = false;
  isLinkBroken: boolean;
  videoIdInContentsTable: string;
  loadedVideoId: string = '';
  isVideoLoadedForFirstTime: boolean;
  isIntroVideoPlaying: boolean;
  multiContentCompletion: any;
  @Input() isIntroVideo?: boolean;
  @Input() IsAccordionVideo?: boolean;
  @Output() closePopupEventEmitter = new EventEmitter<string>();
  @Input() contentData;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private videoPlayersService: VideoPlayersService,
    private playlistService: PlaylistService,
    private lpService: LearningPathService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private elementRef: ElementRef,
    private _popup: MatDialog,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private globals: Globals
  ) {
    this.isLinkBroken = false;
    this.videoIdInContentsTable = '';
    this.playlist = [];
    this.currentPlaylistIndex = 0;
    this.videoCompSubscriptions = {};
    this.currentVideoId = '';
    this.fullPlayListLoaded = false;
    this.LPBreadcrumbs = { ...LPBreadcrumbsStateModel };
    this.videoPlayerId = OptionalParams.VideoPlayerId;
    this.videoPlayerLoading = true;
    this.resumeVideo = false;
    this.warningMsgPopupSubscription = null;
    this.closeWindowwarningMsgPopupSubscription = null;
    this.player = null;
    this.indexForShowingVideoMsg = 0;
    this.indexForShowingVideoMsgClosePopup = 0;
    this.userRoleAccessStore
      .select(fromRoleAccessStore.getRoleAccessList)
      .subscribe((roleList) => {
        this.roleList = roleList;
      });
    this.getVideoPauseStatus();
    this.isVideoLoadedForFirstTime = true;
  }

  ngOnInit() {
    //console.log(this.showInfoMsg);
    this.playerVar = this.PlayerConfig
      ? this.PlayerConfig.PlayerVars
      : PlayerVarsYoutube;
    //can capture progress if the course is added to lp
    this.progressCaptureAllowed = this.PlayerConfig
      ? this.PlayerConfig.CanCaptureProgress
      : OptionalParams.CanCaptureProgress;
    this.videoPlayerId = this.PlayerConfig
      ? this.PlayerConfig.VideoPlayerId
      : OptionalParams.VideoPlayerId;
    // this.iframeUpdate();
    let that = this;
    // if (this.IsAccordionVideo) {
    //   //check if introvideo is playing and pause it
    //   this.pauseIntroVideo();
    // }
  }
  getVideoPauseStatus() {
    this.videoPlayersService.getVideoPauseStatus().subscribe((isPaused) => {
      if (isPaused && this.player != null && !this.isVideoLoadedForFirstTime) {
        this.player.pauseVideo();
      }
    });
  }
  ngAfterViewInit() {
    if (this.PlayerConfig !== undefined && this.PlayerConfig != null) {
      this.videoIdInContentsTable = this.PlayerConfig.VideoDetails.ContentId;
      //get video id and load video from playerconfig
      if (this.player != null) {
        this.destroyPlayer();
      }
      this.videoPlayerLoading = false;
      this.loadedVideoId = this.PlayerConfig.VideoDetails.VideoId.toString();
      this.player = YouTubePlayer(this.videoPlayerId, {
        videoId: this.PlayerConfig.VideoDetails.VideoId,
        startSeconds: 0,
        playerVars: this.playerVar,
      });
      this.isVideoLoadedForFirstTime = true;

      this.registerEventHandler();
      // Adding this to stop autoplay on details page intro video
      if (this.progressCaptureAllowed) {
        // this.player.playVideo();
      }
      //Subscribe to quiz popup stream to play and pause the video for mandatory courses
      if (
        this.progressCaptureAllowed &&
        !this.videoCompSubscriptions.quizPopupSubscription
      ) {
        this.videoCompSubscriptions.quizPopupSubscription =
          this.videoPlayersService
            .getQuizPopupOpenStatus()
            .subscribe((isPopupOpen) => {
              this.isQuizPopupOpen = isPopupOpen;
              if (isPopupOpen) {
                if (this.player != null) this.player.pauseVideo();
              }
            });
      }
      //this.player.loadVideoById(this.PlayerConfig.VideoDetails.VideoId, 0);
    } else {
      this.subscribeToCanPlayAllFlag();
      this.subscribeToRouteParams();
      this.subscribeToVideoPlaylist();
      this.subscribeToBreadCrumbEvents();
      this.subscribeToRouterEvents();
    }
    //this.iframeUpdate();
  }
  /*Subscriptions for AutoPlay,RouterEvents,VideoPlayList,Breadcrumbs,Router required in LP*/

  subscribeToCanPlayAllFlag() {
    this.playlistService.getCanPlayAllVideosFlag().subscribe((value) => {
      this.canPlayAll = value;
    });
  }

  /*
Subsribe to Route Params
Update SelectedVideo data, so that in LP selected unit id ,video id and breadcrumbs are updated 
Play Video based on the resume video status set on click of resume lp icon
set the current playing video index if full playlist is loaded
*/
  subscribeToRouteParams() {
    this.videoCompSubscriptions.routeParamsSubscriptions =
      this.activatedRoute.params.subscribe((params) => {
        this.routeParams = params;
        this.currentVideoId = params['videoId'];

        /*Update SelectedVideo data, so that in LP selected unit id ,video id and breadcrumbs are updated */
        if (this.fullPlayListLoaded) {
          this.playlistService.updateSelectedVideoId({
            source: 'youtube',
            id: this.routeParams['videoId'],
            playlistLength: this.playlist.length,
            allUnitsLoaded: true,
          });
        }
        this.videoPlayersService.getCompletionStatus().subscribe((res) => {
          this.multiContentCompletion = res;
        });
        /* 
        Subscribe To Resume status
        Play video based on  status 
        */
        this.videoCompSubscriptions.resumeStatusSubscriptions = this.lpService
          .getResumeStatus()
          .subscribe((status) => {
            if (status) {
              this.videoCompSubscriptions.resumeDetailsSubscriptions =
                this.userDetailsStore
                  .select(fromUserDetailsStore.getLPResumeDetails)
                  .subscribe((resumeDetails) => {
                    if (this.player != null) {
                      this.destroyPlayer();
                    }
                    this.videoPlayerLoading = false;

                    this.isLinkBroken = false;
                    this.loadedVideoId = this.routeParams['videoId'].toString();

                    this.player = YouTubePlayer(this.videoPlayerId, {
                      playerVars: this.playerVar,
                    });

                    this.player.cueVideoById({
                      videoId: this.routeParams['videoId'],
                      startSeconds: resumeDetails.Time,
                    });
                    this.isVideoLoadedForFirstTime = true;
                    this.registerEventHandler();
                    if (
                      this.progressCaptureAllowed &&
                      !this.videoCompSubscriptions.quizPopupSubscription
                    ) {
                      this.videoCompSubscriptions.quizPopupSubscription =
                        this.videoPlayersService
                          .getQuizPopupOpenStatus()
                          .subscribe((isPopupOpen) => {
                            if (isPopupOpen) {
                              if (this.player != null) this.player.pauseVideo();
                            }
                          });
                    }
                  });
              this.videoCompSubscriptions.resumeDetailsSubscriptions.unsubscribe();
            } else {
              this.videoCompSubscriptions.getResumeDetailsForAllVideos =
                this.videoPlayersService
                  .getVideosPlaylist()
                  .subscribe((res) => {
                    if (
                      res != null &&
                      this.loadedVideoId !=
                      this.routeParams['videoId'].toString()
                    ) {
                      let playlist = null;
                      if (this.fullPlayListLoaded) {
                        playlist = [...this.playlist];
                      } else {
                        playlist = res.playlist;
                      }
                      playlist.forEach((video) => {
                        if (this.routeParams['videoId'] == video.videoId) {
                          if (this.player != null) {
                            this.destroyPlayer();
                          }
                          this.videoPlayerLoading = false;
                          this.loadedVideoId =
                            this.routeParams['videoId'].toString();

                          this.player = YouTubePlayer(this.videoPlayerId, {
                            playerVars: this.playerVar,
                          });
                          this.player.cueVideoById({
                            videoId: this.routeParams['videoId'],
                            startSeconds: video.resumeTime,
                          });
                          this.isVideoLoadedForFirstTime = true;

                          this.registerEventHandler();
                        }
                      });
                    }
                  });
              // this.registerEventHandler();
              if (
                this.progressCaptureAllowed &&
                !this.videoCompSubscriptions.quizPopupSubscription
              ) {
                this.videoCompSubscriptions.quizPopupSubscription =
                  this.videoPlayersService
                    .getQuizPopupOpenStatus()
                    .subscribe((isPopupOpen) => {
                      if (isPopupOpen) {
                        if (this.player != null) this.player.pauseVideo();
                      }
                    });
              }
              //this.player.playVideo();
            }
          });
        /*Unsubscribe Resume status subscription */
        this.videoCompSubscriptions.resumeStatusSubscriptions.unsubscribe();
        /*Update resume status to false as video is loaded */
        this.lpService.updateResumeStatus(false);
        /*Update current playing video index from playlist */
        this.playlist.forEach((video, key) => {
          if (video.videoId === this.routeParams['videoId']) {
            this.videoIdInContentsTable = video.contentId;
            this.currentPlaylistIndex = key;
          }
        });
      });
  }
  /* get full playlist and update selected video details and update current playing video index  */
  subscribeToVideoPlaylist() {
    this.videoCompSubscriptions.playlistSubscription = this.videoPlayersService
      .getVideosPlaylist()
      .subscribe((data: any) => {
        // if (data.resume) {
        //   if (this.player != null) {
        //     this.destroyPlayer();
        //   }
        //   this.player = YouTubePlayer(this.videoPlayerId, {
        //     videoId: this.currentVideoId,
        //     startSeconds: data.playlist[0].resumeTime
        //   });
        //   this.player.playVideo();
        //   this.registerEventHandler();
        //   // this.player.loadVideoById(
        //   //   this.currentVideoId,
        //   //   data.playlist[0].resumeTime
        //   // );
        //   this.resumeVideo = true;
        //   this.lpService.updateResumeStatus(false);
        // } else {
        //   this.resumeVideo = false;
        // }
        if (data.playlist.length === 0) {
          this.playlistService.updateSelectedVideoId({
            source: 'youtube',
            id: this.routeParams['videoId'],
            playlistLength: 0,
            allUnitsLoaded: false,
          });
        }
        if (data.allUnitsLoaded && data.playlist.length !== 0) {
          this.fullPlayListLoaded = true;
          this.playlistService.updateSelectedVideoId({
            source: 'youtube',
            id: this.routeParams['videoId'],
            playlistLength: data.playlist.length,
            allUnitsLoaded: data.allUnitsLoaded,
          });
          this.playlist = data.playlist;
          this.playlist.forEach((video, key) => {
            if (video.videoId.toString() === this.routeParams['videoId'].toString()) {
              this.videoIdInContentsTable = video.contentId;
              this.currentPlaylistIndex = key;
            }
          });
        }
      });
  }
  /* get LP Breadcrumbs */
  subscribeToBreadCrumbEvents() {
    this.videoCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe((breadcrumbs) => {
        this.LPBreadcrumbs = breadcrumbs;
      });
  }
  /*  */
  subscribeToRouterEvents() {
    this.videoCompSubscriptions.routerEventsSub = this.router.events
      //.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: any) => {
        if (event.url != undefined) {
          this.isLinkBroken = false;
          if (event.url.split('playlist')[1] == '') {
            if (this.LPBreadcrumbs.LPDetails.LPId != null) {
              if (this.LPBreadcrumbs.PackageDetails.PackageId != null) {
                this.router.navigate([
                  'learningpath/category/' +
                  this.LPBreadcrumbs.LPDetails.LPCategory +
                  '/id/' +
                  this.LPBreadcrumbs.LPDetails.LPId +
                  '/package/' +
                  this.LPBreadcrumbs.PackageDetails.PackageId,
                ]);
              } else {
                this.router.navigate([
                  'learningpath/category/' +
                  this.LPBreadcrumbs.LPDetails.LPCategory +
                  '/id/' +
                  this.LPBreadcrumbs.LPDetails.LPId,
                ]);
              }
            }
          }
        }
      });
  }
  /*Register Youtube player Event Handlers */
  registerEventHandler() {
    const that = this;
    this.youtubePlayerEvents = this.player.on(
      'stateChange',
      youtubeEventHandler
    );

    this.player.on('error', youtubeErrorHandler);
    function youtubeErrorHandler(event) {
      switch (event.data) {
        case 2:
          /* unstarted */
          break;
        case 5:
          /* unstarted */
          break;
        case 100:
          /* unstarted */
          break;
        case 101:
          /* unstarted */
          break;
        case 150:
          /* unstarted */
          break;
        default:
          that.isLinkBroken = true;
          break;
      }
    }
    function youtubeEventHandler(event) {
      const currentTime = event.target.getCurrentTime();
      const duration = event.target.getDuration();
      that.isVideoLoadedForFirstTime = false;

      switch (event.data) {
        case -1:
          /* unstarted */
          if (
            window.location.href.indexOf('detailsPage') != -1 &&
            window.location.href.indexOf('video') != -1
          ) {
            that.destroyPlayer();
          }
          if (that.progressCaptureAllowed) {
            //  that.player.playVideo();
          }
          // if (!that.progressCaptureAllowed) {
          //   //console.log(that.PlayerConfig);
          //   if (that.PlayerConfig.showInfoMsg === true) {
          //     let i = 0;
          //     if (i < 1) {
          //       that.warningMsgPopupSubscription = setTimeout(() => {
          //         that.player.pauseVideo();
          //         that.openpopup();
          //         i++;
          //       }, 8000);
          //     }
          //   }
          // }
          break;
        case 0:
          /* ended */
          if (that.progressCaptureAllowed) {
            that.getVideoProgress('ended', currentTime, duration);
          }
          // that.playNextVideo();            
          // setTimeout(() => {
          // }, 2000);
          break;
        case 1:
          /* playing */
          if (
            window.location.href.indexOf('detailsPage') != -1 &&
            window.location.href.indexOf('video') != -1
          ) {
            that.destroyPlayer();
          }
          if (that.progressCaptureAllowed) {
            that.getVideoProgress('playing', currentTime, duration);
          } else {
            if (
              that.PlayerConfig.showInfoMsg === true &&
              !that.roleList.CUSTOMER
            ) {
              that.warningMsgPopupSubscription = setTimeout(() => {
                if (that.indexForShowingVideoMsg < 1) {
                  that.player.pauseVideo();
                  that.openpopup();
                }
                that.indexForShowingVideoMsg++;
              }, 5000);

              that.closeWindowwarningMsgPopupSubscription = setTimeout(() => {
                if (that.indexForShowingVideoMsgClosePopup < 1) {
                  that.player.pauseVideo();
                  that.openPopupAndCloseVideo();
                }
                that.indexForShowingVideoMsgClosePopup++;
              }, 10000);
            }
          }

          break;
        case 2:
          /* paused */
          if (that.progressCaptureAllowed) {
            that.getVideoProgress('paused', currentTime, duration);
          }
          break;
        case 3:
          /* buffering */
          if (
            window.location.href.indexOf('detailsPage') != -1 &&
            window.location.href.indexOf('video') != -1
          ) {
            that.destroyPlayer();
          }
          if (that.progressCaptureAllowed) {
            that.getVideoProgress('buffering', currentTime, duration);
          }
          break;
        case 5:
          if (
            window.location.href.indexOf('detailsPage') != -1 &&
            window.location.href.indexOf('video') != -1
          ) {
            that.destroyPlayer();
          }
          /* video cued */
          break;

        default:
          this.isLinkBroken = false;
          if (
            window.location.href.indexOf('detailsPage') != -1 &&
            window.location.href.indexOf('video') != -1
          ) {
            that.destroyPlayer();
          }
          break;
      }
    }
  }
  /*Play Next video in the PlayList if canPlayAll flag set to true */
  playNextVideo() {
    this.isLinkBroken = false;
    if (this.canPlayAll) {
      if (this.playlist[this.currentPlaylistIndex + 1]) {
        let sourceType = this.playlist[
          this.currentPlaylistIndex + 1
        ].sourceType.toLowerCase();
        let sourceNav =
          sourceType == 'youtube' ||
            sourceType == 'o365' ||
            sourceType == 'scorm'
            ? sourceType
            : 'others';
        this.router.navigate(
          [
            '../../../' +
            sourceNav + '/videoId/' +
            this.playlist[this.currentPlaylistIndex + 1].videoId,
          ],
          {
            relativeTo: this.activatedRoute,
          }
        );
      }
    }
  }
  /* Capture Video Progress */
  getVideoProgress(event, currentTime, duration) {
    let videoDetailsToCaptureProgress: any;
    let isLastVideoInPlaylist = false;
    if (this.PlayerConfig !== undefined && this.PlayerConfig != null) {
      videoDetailsToCaptureProgress = {
        ...this.PlayerConfig.VideoDetails,
      };
      //get path id from user details store
      this.userDetailsStore
        .select(fromUserDetailsStore.getUnDeletedLPsSelector)
        .subscribe((lps) => {
          lps.forEach((lp) => {
            lp.PackageList.forEach((content) => {
              if (this.PlayerConfig.VideoDetails.ItemId === content.ItemId) {
                videoDetailsToCaptureProgress['PathId'] = lp.PathId;
              }
            });
          });
        });
    } else {
      videoDetailsToCaptureProgress = {
        ContentId: this.LPBreadcrumbs.VideoDetails.VideoId,
        UnitId: this.LPBreadcrumbs.UnitDetails.UnitId,
        CourseId: this.LPBreadcrumbs.CourseDetails.CourseId,
        ItemId: this.LPBreadcrumbs.PackageDetails.PackageId,
        Expertise: this.LPBreadcrumbs.CourseDetails.Expertise,
      };
      if (
        this.LPBreadcrumbs.LPDetails.LPCategory === 'mandatory' ||
        this.LPBreadcrumbs.LPDetails.LPCategory === 'myCompliance'
      ) {
        this.userDetailsStore
          .select(
            fromUserDetailsStore.getLearningPathByIdEntities(
              this.LPBreadcrumbs.LPDetails.IsLPMandatory
                ? this.LPBreadcrumbs.LPDetails.LPName.toUpperCase()
                : this.LPBreadcrumbs.LPDetails.LPId
              //this.LPBreadcrumbs.LPDetails.LPId
            )
          )
          .subscribe((lp) => {
            videoDetailsToCaptureProgress['PathId'] = lp.PathId;
          });
      } else {
        videoDetailsToCaptureProgress['PathId'] =
          this.LPBreadcrumbs.LPDetails.LPId;
      }
      if (this.LPBreadcrumbs.PackageDetails.PackageId == null) {
        videoDetailsToCaptureProgress.ItemId =
          this.LPBreadcrumbs.CourseDetails.CourseId;
        videoDetailsToCaptureProgress.ItemType = 'Course';
      } else {
        videoDetailsToCaptureProgress.ItemType = 'FamilyPackage';
      }
      if (this.currentPlaylistIndex + 1 === this.playlist.length) {
        isLastVideoInPlaylist = true;
      } else {
        isLastVideoInPlaylist = false;
      }
    }
    if (event == 'ended' || duration - currentTime < 3) {
      videoDetailsToCaptureProgress.CurrentTime = duration;
      videoDetailsToCaptureProgress.TotalTime = duration;
      this.playlist[this.currentPlaylistIndex].resumeTime = 0;
      this.playlist = [...this.playlist];

      // }
    } else {
      videoDetailsToCaptureProgress.CurrentTime = currentTime;
      videoDetailsToCaptureProgress.TotalTime = duration;
      this.playlist[this.currentPlaylistIndex].resumeTime = currentTime;
      this.playlist = [...this.playlist];
    }

    videoDetailsToCaptureProgress.IsLastVideoInPlaylist = isLastVideoInPlaylist;
    if (!this.isQuizPopupOpen) {
      this.videoPlayersService.updateVideoDetailsToCaptureProgress(
        videoDetailsToCaptureProgress
      );
      if (event == 'ended' || duration - currentTime < 3) {
        // let videoId = this.LPBreadcrumbs.VideoDetails.VideoId;
        setTimeout(() => {
          this.videoPlayersService.updateFetchCompletionStatus(this.multiContentCompletion, this.LPBreadcrumbs, this.LPBreadcrumbs.VideoDetails.VideoId).then(result => {
            if (result.Response) {
              this.playNextVideo();
            }
          }).catch((error) => {
            console.log("Something went wrong:", error);
          })
        }, 2000);
      }

    }
  }
  /*Show Video Popup to tell user to add course or package in order to capture progress in Details Page */
  openpopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {};
    const response = this._popup.open(VideoPlayerPopupComponent, dialogConfig);
    response.afterClosed().subscribe((res) => {
      //do something after pop up close
      this.player.playVideo();
    });
  }


  openPopupAndCloseVideo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      type: "video",
      addToLp: true,
      close: true
    };
    const response = this._popup.open(VideoPlayerPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      //do something after pop up close
      this.closePopupEventEmitter.emit('true')
      if (res == 'AddToLp') {
        this.openAddToLpDialog();
      }
    });
  }

  openAddToLpDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //addDialogContainer
    dialogConfig.data = {
      contentName: this.contentData.Name,
      Id: this.contentData.Id,
      ItemType: this.contentData.ItemType,
      uniqueId: this.contentData.UniqueId,
      accountId: this.contentData.Account,
      projectId: this.contentData.Project,
      AccountPackage: this.contentData.AccountPackage,
      ProjectPackage: this.contentData.ProjectPackage,
      Title: this.contentData.Name,
      skillProfile: {
        recommendationType: '',
        duration: '',
      },
      addedLocation: 'Details-Page',
      contentData: this.contentData,
    };

    console.log(dialogConfig.data)

    const dialogRef = this._popup.open(AddPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((response) => { });
  }

  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
    this.destroyPlayer();
    if (this.warningMsgPopupSubscription != null) {
      clearTimeout(this.warningMsgPopupSubscription);
    }

    if (this.closeWindowwarningMsgPopupSubscription != null) {
      clearTimeout(this.closeWindowwarningMsgPopupSubscription);
    }
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.videoCompSubscriptions) {
      let subscriber = this.videoCompSubscriptions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  destroyPlayer() {
    if (this.player != null) {
      this.player.destroy();
      this.player = null;
    }
  }
}
