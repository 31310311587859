import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PssComponent } from '../pss.component';

@Component({
  selector: 'yorbit-edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.scss']
})
export class EditPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PssComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit() {
    ////console.log(this.dialogData);
  }
  close(response) {
    this.dialogRef.close(response);
  }

}
