<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="searchDiv">
        <input [(ngModel)]="searchText" type="text" class="textBoxStyle" placeholder="Search by course evaluator/vendor">
    </div>
    <div class="font12 scrollHorizontal row floatLeft" fxLayout="column wrap" fxLayoutAlign="start start">
        <div class="evaluatorList font12" *ngFor='let evaluatorVendor of displayList | searchFilter: searchText'>
            <div fxLayout="row" fxFlex>
            <input type="checkbox" [disabled]="!evaluatorVendor.isEnabled" [checked]="evaluatorVendor.isSelected"
                (change)="updateCheckedOptions(evaluatorVendor.value)">
            <div>{{evaluatorVendor.value}}</div>
        </div>
    </div>
</div>