import { Injectable } from '@angular/core';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShareContentPopupService {
  constructor(private _graphDataService: GraphDataService) {}

  getSuggestionList(userName) {
    return this.getUserListFromGraph(userName).then(response => {
      const promise = new Promise(resolve => {
        response.value=response.value.filter(user=>{return  !user.mail.includes('@mindtree.com')});
        response.value.forEach(element => {
          element.userPrincipalName=element.userPrincipalName.split('.').slice(1).join('.')
        });
        //console.log(response)
        resolve(response);
      });
      return promise;
    });
  }

  getUserListFromGraph(userName) {
    return this._graphDataService.getUsersListByName(userName).toPromise();
  }
  getUserListByMail(mail) {
    return this._graphDataService.getUsersListByMail(mail).toPromise();
  }
  getUserListByMailId(id) {
    return this._graphDataService.getUsersListByMailId(id).toPromise();
  }

  isAnExtId(input) {
    return this._graphDataService.isAnExtId(input);
  }

  getSuggestionListFromCapacityReport(id) {
    return this._graphDataService.getDetailsFromCapacityReport(id);
  }
}
