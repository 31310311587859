import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentTileLpComponent } from '../content-tile-lp/content-tile-lp.component';
import { getSkillPathsSelector } from '../../user-details/store';
import { DeclarePopupService } from './declare-popup.service';
import { Globals } from '../../../globals';
import { Router } from '@angular/router';
import { ContentTileBrowseComponent } from '../content-tile-browse/content-tile-browse.component';
import * as fromDeclareStore from '../../declare-store/store';
import { DeclareStoreService } from '../../declare-store/declare-store.service';
import * as fromUserDetailsStore from '../../../shared/user-details/store';

import { Store } from '@ngrx/store';

@Component({
  selector: 'yorbit-declare-popup',
  templateUrl: './declare-popup.component.html',
  styleUrls: ['./declare-popup.component.scss']
})
export class DeclarePopupComponent implements OnInit {
  declareConfirmationPopup: boolean;
  skillname: string;
  declareSkillModeOfAquiring: any;
  disableDeclareSubmitButton: boolean;
  decalreSubmitButton: string;
  declareSkillModeOfAquiringList: string[];
  declareSkillCallCompleted: boolean;
  declareSkillSuccess: boolean;
  declareSkillFailed: boolean;
  decalrePopupRemarks: any;
  decalreErrorMessage: string;
  isClassroom: boolean;
  classroomCheckLoaded: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    // public dialogRef:
    //   | MatDialogRef<ContentTileLpComponent>
    //  | MatDialogRef<ContentTileBrowseComponent>,
    public dialogRef: MatDialogRef<ContentTileLpComponent>,
    private declarePopupService: DeclarePopupService,
    private declareStore: Store<fromDeclareStore.IdeclareState>,
    private declareStoreService: DeclareStoreService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private globals: Globals,
    private _route: Router
  ) {
    this.isClassroom = false;
    this.classroomCheckLoaded = false;
    this.checkClassroomCourse(this.data.courseId);
  }

  ngOnInit() {
    if (this.data != null) {
      if ('101' === this.data.expertise) {
        this.declareConfirmationPopup = false;
      } else {
        this.declareConfirmationPopup = true;
        // if (!this.isClassroom && this.classroomCheckLoaded) {
        // }
      }
      this.getSkill();
    }
    this.skillname = 'Loading...';
    this.decalreSubmitButton = 'Submit';
    this.declareSkillModeOfAquiringList = [
      'Select the mode',
      'Self-acquired in current project at LTIMindtree Limited',
      'Self-acquired in previous project at LTIMindtree Limited',
      'Have done a personal proof of concept (POC)',
      'Learnt it in my previous job',
      'Did a course outside Shoshin School',
      'Did a certification outside Shoshin School'
    ];
    this.declareSkillModeOfAquiring = this.declareSkillModeOfAquiringList[0];
  }
  getSkill() {
    this.declarePopupService
      .getSkillDataForDeclare(this.data.courseId)
      .then((response: any) => {
        this.skillname = response;
      });
  }
  showApproverCategory() {
    const mode = this.declareSkillModeOfAquiring.toLowerCase();
    switch (mode) {
      case 'select the mode':
        return 'Search your approver';
      case 'self-acquired in current project at LTIMindtree Limited':
      case 'self-acquired in previous project at LTIMindtree Limited':
        return 'Search RMs for approval';
      case 'have done a personal proof of concept (poc)':
      case 'learnt it in my previous job':
      case 'did a course outside shoshin school':
      case 'did a certification outside shoshin school':
        return 'Search Learning Partners for approval';
    }
  }
  declareModeOfAquireSelected() { }
  submitClickedForDeclarePopUP() {
    if (this.declareSkillModeOfAquiring === 'Select the mode') {
      this.decalreErrorMessage = 'Please select the mode of acquiring.';
    } else {
      this.decalreSubmitButton = 'Submitting...';
      this.disableDeclareSubmitButton = true;
      this.callApiForDeclareSubmit('');
    }
  }

  callApiForDeclareSubmit(certificateURL) {
    //////console.log( this.declareSkillManager );
    const data = {
      CourseId: this.data.courseId,
      Skill: this.skillname,
      Expertise: this.data.expertise,
      Approver: '',
      CertificateURL: certificateURL,
      ModeOfAquiring: this.declareSkillModeOfAquiring,
      Remarks: this.decalrePopupRemarks,
      IsCdmOrRm: '',
      SkillPlanId: this.data.skillPlanId,
      LearningPathId: this.data.learningPathId,
      IsSkillPlan: this.data.IsSkillPlan
    };
    //////console.log( "data for declare submit api:", data );
    this.declarePopupService
      .declareASkill(data)
      .then(response => {
        this.declareSkillCallCompleted = true;
        this.declareSkillSuccess = false;
        this.declareSkillFailed = false;
        if (response) {
          this.disableDeclareSubmitButton = true;
          this.decalreSubmitButton = 'Submitted';
          //$rootScope.$emit( 'decalreSkillSubmittedSucessfully' );
          this.declareSkillSuccess = true;
        }
      })
      .catch(error => {
        this.disableDeclareSubmitButton = true;
        this.decalreSubmitButton = 'Failed';
        this.declareSkillFailed = true;
      });
  }
  removeMid(mid, array) {
    return array.filter(item => item['MID'] !== mid);
  }
  closeDialog() {
    this.dialogRef.close(this.declareSkillSuccess);
  }
  gotoMyPage() {
    this.globals.isSkillProfileClicked = true;
    this.declareStore.dispatch(
      new fromDeclareStore.DeclareGetStatusByCourseIds([
        { CourseId: this.data.courseId, Expertise: this.data.expertise }
      ])
    );

    this.userDetailsStore
      .select(
        fromUserDetailsStore.getBadgesByCourseIdSelector(this.data.courseId)
      )
      .subscribe(badge => {
        this.userDetailsStore.dispatch(
          new fromUserDetailsStore.UpdateAchievements({
            badgeId: badge.BadgeId,
            badgeStatus: this.declareSkillSuccess,
            quizScore: null
          })
        );
      });
    this._route.navigate(['/my-page/' + this.globals.MId + '/self']);
    this.dialogRef.close(false);
  }

  checkClassroomCourse(courseId: string) {
    this.declarePopupService.checkClassroomCourse(courseId).subscribe({
      next: (res) => {
        this.classroomCheckLoaded = true;
        if (res) {
          this.isClassroom = true;
          console.log("checkClassroomCourse", this.isClassroom);
        }
      },
      error: () => {
        this.classroomCheckLoaded = true;
      }
    });
  }
}

