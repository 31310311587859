import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contentPropertyInArray'
})

/**
 * use as
 * In HTML
 *  <div *ngFor="let content of (contentList | contentPropertyInArray:{'property':'Expertise','list':['101','201']})">
 *
 * In TS
 * const filterPipe = new ContentPropertyInArrayPipe();
 * const filteredBrowseContent = filterPipe.transform(BrowseContent,{'property':'Expertise','list':['101','201']});
 *
 * legends
 * value -> any array of object | as here 'contentList'
 * filter -> includes the 'property' to filter, 'list' to match a value | as here 'Expertise' and string array ['101','201']
*/

export class ContentPropertyInArrayPipe implements PipeTransform {

  transform(value: any, filter?: Ifilter): any {
    if(!value || !filter || 0===filter.list.length){
      return value
    }else{
    return value.filter(item => (-1 !== filter.list.indexOf(item[filter.property])));
    }
  }

  }

interface Ifilter{
  property?: string;
  list?: any[];
}
