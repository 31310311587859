import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "@YorbitWorkspace/global-environments";
@Injectable({
    providedIn: 'root'
  })
  export class ContentUpload301Service {
    _baseURL: string;

    constructor(private _http: HttpClient,
                private _envSvc: EnvironmentService) {
      this._baseURL = this._envSvc.getEnvironment().apiUrl;
    }

  getTaxanomy() {
    return this._http.get<any>(this._baseURL+'Course/GetTaxonomy');
  }

  getVendorList() {
    return this._http.get<any>(this._baseURL+'GetVendorList');
  }

  getCourseType() {
    return this._http.get<any>(this._baseURL+'GetCourseType/' + '301');
  }

  insert301Content(contentObject: any,uniqueId: any) {
         return this._http.post<any>(this._baseURL + '/CourseInsertorUpdateFor301/'  + uniqueId, contentObject);
  }

  update301Status(uniqueID: any) {
     return this._http.post<any>(this._baseURL + '/UpdateCourseStatusToSubmitted/' + uniqueID,'');
  }
  
  getCourseProficiencyList() {
    return this._http
      .get<any>(this._baseURL + 'CourseProficiency');
      //.pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  // checkCourseNameExists(courseName: any) {
  //   return this._http.post<any>(this._baseURL + '/CheckCourseNameExists/', courseName);
  // }
  

  }