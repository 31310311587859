<div fxFlexFill [ngClass]="['progress'+progress,'course-progress-details','border'+expertise]" 
[ngStyle]="{'cursor': progress == 0 ? 'pointer' : 'auto'}" fxLayout="row" *ngIf="expertise != '000' || (expertise == '000' && progress > '0')">
  <div [ngClass]="['bgColor'+expertise,
  'progress-bar']"  [ngStyle]="{'width':progress + '%', 'border-radius':progress == 100 ? '5px'  : '0px'}" *ngIf="progress!=0">
    <!--<div class="progress-value" [ngStyle]="{'width':progress + '%'}" [ngClass]="{'progress-value-overlapping-bar':progress>30,'progress-value-nonoverlapping-bar':progress<=30}">
      {{progress}}%
    </div>-->
  </div>
  <div class="progress-value" *ngIf="progress!=0" [ngStyle]="{'width':progress + '%'}" 
  [ngClass]="{
    'progress-value-overlapping-bar':progress>30,
    'progress-value-nonoverlapping-bar':progress<=30, 
    'color-white':expertise === '000'    
  }">
    {{progress}}%
  </div>
  <div [ngClass]="['bgColor'+expertise,'start-course-button']" *ngIf="progress==0">{{expertise === 'Degreed' ? 'Start Pathway' : 'Start Course'}}</div> 
</div>

<!-- First tile of Code of Conduct LP , sexual harassment prevention training - usa -->
<div fxFlexFill [ngClass]="['progress'+progress,'course-progress-details']" fxLayout="row" *ngIf="expertise == '000' && progress == '0'">
  <div *ngIf="progress == '0' && expertise == '000'" [ngClass]="['border'+expertise ,'no-progress-status']">
    Yet to start learning
  </div>
</div>