<div class="container" fxLayout="column">
    <yorbit-youtube-video-player *ngIf="(introVideoUrl.SourceType|uppercase) === 'YOUTUBE' && relaodVideo==true"
        [PlayerConfig]=" videoConfig">
    </yorbit-youtube-video-player>
    <yorbit-o365-video-player *ngIf="introVideoUrl.SourceType == 'O365' && relaodVideo==true"
        [PlayerConfig]="videoConfig">
    </yorbit-o365-video-player>
    <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="!relaodVideo">
        <app-preloader></app-preloader>
    </div>
    <!-- <yorbit-o365-video-player *ngIf="introVideoUrl.SourceType == 'O365'
        [PlayerConfig]=" videoConfig"></yorbit-o365-video-player> -->

    <div class="video-header" *ngIf="videoData">{{videoData.Name}}</div>
    <div class="video-description" *ngIf="videoData"> {{videoData.Description}}</div>
</div>