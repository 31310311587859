<div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="searchDiv">
        <input type="text" [(ngModel)]="searchText" class="textBoxStyle" placeholder="Search by course vendor">
    </div>
    <div class="font12 scrollHorizontal row floatLeft" fxLayout="column wrap" fxLayoutAlign="start start">
        <div class="courseVendorList" *ngFor='let courseVendor of displayList | searchFilter: searchText'>
            <div fxLayout="row" fxFlex><input type="checkbox" [disabled]="!courseVendor.isEnabled" [checked]="courseVendor.isSelected" (change)="updateCheckedOptions(courseVendor.value, $event)">{{courseVendor.value}}</div>
        </div>
    </div>
</div>
