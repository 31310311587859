<!-- <div class="push-notifications-popup" >
    <div mat-dialog-title>Yorbit Notifications are either Temporarily or Permanently blocked</div>
    <div mat-dialog-content>
      <div class="msg">
        To enable Yorbit Notifications, remove Yorbit from blocklist in browser settings ( {{settingsUrl}} ). If Yorbit is not present in blocklist then clear browser cache and reload Yorbit.
    </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button  (click)="closeDialog()" cdkFocusInitial>Ok</button>
    </div>
</div> -->


<mat-dialog-content>
  <!--class="shareList"-->
  <!--Close Popup Icon-->
  <div class="closePopupBtn">
    <button mat-button mat-icon-button (click)="closeDialog()">
      <mat-icon class="closematicon">close</mat-icon>
    </button>
  </div>
  <!--Title-->
  <div class="popup-title">
    <div class="heading notifications-block-header">Push Notifications are blocked</div>
  </div>
  <div class="description blocked-message">
    To enable Shoshin School Notifications, remove Shoshin School from blocklist in browser notifications settings. If Shoshin School is not present in blocklist then clear browser cache and reload Shoshin School.    
  </div>
   <!--Mat divider-->
   <mat-divider></mat-divider>
  <!--Action buttons-->
  <div class="action-btn" fxLayoutAlign="center center">
    <button class="action-button" (click)="closeDialog()">OK</button>
  </div>
</mat-dialog-content>
