import { Injectable } from '@angular/core';
import { Ienvironment, EnvironmentService } from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeclareSkillPopupService {
  config: Ienvironment;

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
  ) {
    this.config = this._envSvc.getEnvironment();
   }

getSkillListToDeclare(skillName) {
  const payload={
    SkillNameToAutoComplete:skillName
  }
  return this.http
  .post(this.config.apiUrl +  "User/AutoComplete/ListOfSkills", payload)
  .toPromise();
}

}
