import { Component, OnInit, Input } from '@angular/core';
import { ShareContentPopupComponent } from './share-content-popup/share-content-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-share-content',
  templateUrl: './share-content.component.html',
  styleUrls: ['./share-content.component.scss']
})
export class ShareContentComponent implements OnInit {
  @Input() card: any;

  constructor(private _popup: MatDialog) {}

  ngOnInit() {}
  openDialog() {
    if (this.card.enableShare) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      //shareDialogContainer

      dialogConfig.data = {
        Id: this.card.contentData.Id,
        ItemType: this.card.contentData.ItemType,
        Title: this.card.contentData.Name,
        sharedLocation: this.card.sharedLocation,
        TotalShares: this.card.contentData.TotalShares
      };

      this._popup.open(ShareContentPopupComponent, dialogConfig);
    }
  }
}
