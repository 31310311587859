import { Component, OnInit, Input } from '@angular/core';
import { NasscomRecommendationsService } from '../nasscom-recommendations.service';
import { Globals } from '../../../globals';
import { AppInsightService } from '@YorbitWorkspace/app-insight';

@Component({
  selector: 'yorbit-nasscom-recommendations',
  templateUrl: './nasscom-recommendations.component.html',
  styleUrls: ['./nasscom-recommendations.component.scss']
})
export class NasscomRecommendationsComponent implements OnInit {
  isRecommendationsLoading: boolean;
  isRecommendationsFailed: boolean;
  nasscomFutureSkills: any;
  navCount: number;
  startCount: number;
  endCount: number;
  indexToShow: number;
  futureSkillXS: any;

  @Input() number: any;
  @Input() showHeader: boolean;
  @Input() showDescription: boolean;
  @Input() location: string;

  techCourseList: any;

  constructor(
    private _nasscomSvc: NasscomRecommendationsService,
    private globals: Globals,
    private _InsightService: AppInsightService
  ) {}

  ngOnInit() {
    this.nasscomFutureSkills = [];
    this.isRecommendationsLoading = false;
    this.isRecommendationsFailed = false;
    this.techCourseList = [];
    this.navCount = 0;
    this.startCount = 0;
    this.endCount = 0;
    this.indexToShow = 0;
    this.futureSkillXS = null;
    //console.log('fasd', this.number);

    if (this.globals.NASSCOMRecommendations.length > 0) {
      if (this.number == 4) {
        this.getSkillsFromDataList(this.globals.NASSCOMRecommendations);
      } else {
        this.nasscomFutureSkills = this.globals.NASSCOMRecommendations;
        this.futureSkillXS = this.nasscomFutureSkills[0];
      }
    } else {
      this.getFutureSkillRecommendations();
    }
  }

  getFutureSkillRecommendations() {
    this.isRecommendationsLoading = true;
    this.isRecommendationsFailed = false;
    this.nasscomFutureSkills = [];
    this._nasscomSvc.getNasscomRecommendations().subscribe(
      data => {
        this.isRecommendationsLoading = false;
        if (data && data.length > 0) {
          if (this.number === 4) {
            this.globals.NASSCOMRecommendations = data;
            this.getSkillsFromDataList(this.globals.NASSCOMRecommendations);
          } else {
            this.globals.NASSCOMRecommendations = data;
            this.nasscomFutureSkills = this.globals.NASSCOMRecommendations;
            this.futureSkillXS = this.nasscomFutureSkills[0];
          }
        }
      },
      error => {
        this.isRecommendationsLoading = false;
        this.isRecommendationsFailed = true;
        this.futureSkillXS = null;
        ////console.log('Error in loading NASSCOM Recommendations', error);
      }
    );
  }

  redirectToNasscomSite(skill) {
    this.trackFutureSkillInsights(this.location);
    var url = new URL( 'https://futureskillsnasscom.edcast.com/auth/lxp_oauth?current_host=RDk2b3ljS09uQ0krWjBib0NKa2xXUk9sVEprNXNpODVRTnBjak9makNOSFZQZUR4TXRxbWY4dDE0TCtTY00zbi0tZ2g5RktoeW9ONzJJeWRZa0NCb3ZTQT09--b4d90334abcfa9808103eb73a6187d6934ffca57&connector=eyJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijg3NTcifQ.V5Si81tkJ_nCeSOlWObqPpK2ZXnoAkHL2_r3raGkh_Q&provider=saml&origin='+  skill.Link);
    window.open(url.href, '_blank');
  }
  getUniqueTechnologies(data) {
    let list = [];
    data.forEach(element => {
      const index = list.indexOf(element.Technology);
      if (index === -1) {
        list.push(element.Technology);
      }
    });
    return list;
  }

  getSkillsFromDataList(data) {
    // let whileCounter = 0;
    let skillList = [];
    const techList = this.getUniqueTechnologies(data);
    techList.forEach((tech, index) => {
      this.techCourseList[index] = data.filter(
        element => element.Technology.toLowerCase() === tech.toLowerCase()
      );
    });

    let iterationIndex = 0;

    while (skillList.length < 4) {
      techList.forEach((tech, index) => {
        let list = this.techCourseList[index];
        // if (this.nasscomFutureSkills.length < 4 && this.techCourseList[index].length > (whileCounter + 1)) {
        //   skillList.push(this.techCourseList[index][whileCounter]);
        //}
        if (list.length > 0 && skillList.length < 4) {
          skillList.push(list[iterationIndex]);
          list.splice(iterationIndex, 1);
        }
      });
      //  whileCounter++;
      iterationIndex++;
    }
    this.nasscomFutureSkills = skillList;
    this.futureSkillXS = this.nasscomFutureSkills[0];
  }

  previous(count) {
    if (count - 1 !== -1) {
      this.navCount = --count;
      this.startCount = this.navCount;
      this.endCount = this.navCount + 1;
      this.futureSkillXS = this.nasscomFutureSkills[this.navCount];
    }
  }

  next(count) {
    if (count + 1 < this.nasscomFutureSkills.length) {
      this.navCount = ++count;
      this.startCount = this.navCount;
      this.endCount = this.navCount + 1;
      this.futureSkillXS = this.nasscomFutureSkills[this.navCount];
    }
  }

  trackFutureSkillInsights(location) {
    const insightPayload = {
      Action: 'NASSCOM Link Clicked',
      Location: location
    };
    this._InsightService.trackEvent('NASSCOM Future Skills', insightPayload);
  }

  getSkills(lower, upper) {
    //console.log('lower', lower);
    //console.log('upper', upper);
    if (upper != undefined) {
      return this.nasscomFutureSkills.slice(lower, upper);
    } else {
      return this.nasscomFutureSkills.slice(lower);
    }
  }
}
