import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PssFiltersService {
  filtersForCourseRequests: BehaviorSubject<any>;
  filtersForCertificateRequests: BehaviorSubject<any>;
  filtersForExternalCertificateRequests: BehaviorSubject<any>;
  filtersForEvaluationRequests: BehaviorSubject<any>;
  totalPaginatedRequests: BehaviorSubject<any>;
  config: any;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.filtersForCourseRequests = new BehaviorSubject(null);
    this.filtersForCertificateRequests = new BehaviorSubject(null);
    this.filtersForExternalCertificateRequests = new BehaviorSubject(null);
    this.filtersForEvaluationRequests = new BehaviorSubject(null);
    this.totalPaginatedRequests = new BehaviorSubject(0);
  }
  //course requests filters get and set methods
  getCourseRequestsFilters() {
    return this.filtersForCourseRequests.asObservable();
  }
  updateCourseRequestsFilters(data) {
    return this.filtersForCourseRequests.next(data);
  }
  //certificate requests filters get and set methods
  getCertificateRequestsFilters() {
    return this.filtersForCertificateRequests.asObservable();
  }
  updateCertificateRequestsFilters(data) {
    return this.filtersForCertificateRequests.next(data);
  }
  //ExternalCertificate Filters get and set Methods
  getExternalCertificateRequestsFilters(){
    return this.filtersForExternalCertificateRequests.asObservable();
  }
  updateExternalCertificateRequestsFilters(data) {
    return this.filtersForExternalCertificateRequests.next(data);
  }
  //Evaluation requests filters get and set methods
  getEvaluationRequestsFilters() {
    return this.filtersForEvaluationRequests.asObservable();
  }
  updateEvaluationRequestsFilters(data) {
    return this.filtersForEvaluationRequests.next(data);
  }
  //pagination data get and set methods
  getTotalPaginationCount() {
    return this.totalPaginatedRequests.asObservable();
  }
  updateTotalPaginationCount(data) {
    return this.totalPaginatedRequests.next(data);
  }
  //download all courses from course request tab
  downloadCourseFullList() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/Excel/All');
  }
  //downlaod all course requests from certification tab
  downloadCertificateFullList() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/InternalCertificateExcel/All', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
  //download all  Courses from Externalcertificate Tab
  downloadExternalCertificateFullList(){
    return this.http
    .get<any>(this.config.apiUrl + 'PSS/ExternalCertificateExcel/All', {
      responseType: 'arraybuffer' as 'json',
      observe:'response'
    })
    .toPromise();
  }
  //get the Genre and skill 
  getGenreandSkill() {
    return this.http
      .get<any>(this.config.apiUrl+ "/Course/GetTaxonomy");
  }
  //download all evalutaion course requests from evaluation requests tab
  downloadEvaluationFullList() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/DownloadAllEvaluationRequests', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
  //download filtered courses from course requests tab
  downloadCourseForRange(range) {
    return this.http
      .post<any>(this.config.apiUrl + 'PSS/Excel', range);
  }
  //download filtered certification requests from certificate tab
  //status is accepted or denied
  downloadCertificateForRange(status, range) {
    return this.http
      .post<any>(
        this.config.apiUrl +
          'PSS/InternalCertificateExcel/' +
          status +
          '/20/0/next',
        range,
        {
          responseType: 'arraybuffer' as 'json',
          observe: 'response'
        }
      )
      .toPromise();
  }
  // download filtered certificate requests from Externalcertificate Tab
  //status is accepted or denied
  downloadExternalcertificateForRange(status,range) {
    var status1 = (status == "pending")?"pending": (status == "accepted")?"approved":"rejected"
    return this.http
    .post<any>(
      this.config.apiUrl +
        'PSS/ExternalCertificateExcel/' +
        status1 +
        '/20/0/next',
        range,
      {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      }
    )
    .toPromise();
  }
  //download filtered evaluation requests from evaluation tab
  downloadEvaluationRequestsForRange( range) {
    return this.http
      .post<any>(
        this.config.apiUrl + 'PSS/DownloadFilteredRequests',
        range,
        {
          responseType: 'arraybuffer' as 'json',
          observe: 'response'
        }
      )
      .toPromise();
  }
  //download pss template button
  downloadTemplateForUpload() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/Download/PSSTemplate', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
  //Download ClassRoom template button
  downloadTemplateForClassRoomUpload() {
    return this.http
      .get<any>(this.config.apiUrl + 'PSS/Download/ClassRoomTemplate', {
        responseType: 'arraybuffer' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
  //upload file to change the statuses
  uploadService(file) {
    return this.http
    .post<any>(this.config.apiUrl + 'PSS/Upload',file);
  // return $http.post(apiUrl + "PSS/Upload", file, {
  //     transformRequest: angular.identity,
  //     headers: { 'Content-Type': undefined }
  // });
  }
  classRoomUploadService(file) {
    return this.http
    .post<any>(this.config.apiUrl + 'PSS/ClassRoomUpload',file);
  // return $http.post(apiUrl + "PSS/Upload", file, {
  //     transformRequest: angular.identity,
  //     headers: { 'Content-Type': undefined }
  // });
  }
}
