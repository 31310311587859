import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopLearnersComponent } from './top-learners/top-learners.component';
//import { IvyCarouselModule } from 'angular-responsive-carousel';
//import { NgxCarouselModule } from 'ngx-carousel';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
@NgModule({
  imports: [
    CommonModule,
    //IvyCarouselModule,
    FlexLayoutModule,
    MatGridListModule,
    MatIconModule,
    MatDividerModule,
    ReusableUiModule
  ],
  declarations: [TopLearnersComponent],
  exports: [TopLearnersComponent]
})
export class TopLearnersQuarterWiseModule {}
