import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchEvaluatorPopupComponent } from './search-evaluator-popup/search-evaluator-popup.component';
import { SearchEvaluatorComponent } from './search-evaluator.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareContentModule } from '../../../shared/card-feature/share-content/share-content.module';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

@NgModule({
  imports: [
    CommonModule,
    ShareContentModule,

    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatAutocompleteModule,

    FormsModule,
    ReactiveFormsModule,

    ReusableUiModule
  ],
  declarations: [
    SearchEvaluatorComponent,
    SearchEvaluatorPopupComponent
  ],
  
  exports: [
    SearchEvaluatorComponent,
    SearchEvaluatorPopupComponent
  ],
})
export class SearchEvaluatorModule { }
