import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { FileSaverService } from 'ngx-filesaver';
import * as XLSX from 'xlsx';
import { DatePipe, formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { CheckboxList } from '../../pss/pss-evaluation/pss-evaluation.interface';
import { AoService } from '../ao.service';


interface ContentResponse {
  UniqueId: string;
  PSID: string;
  UploadedFilename: string;
  UploadStatus: string;
  UploadedFileLink: string;
  LogfileLink: string;
  UploadedDate: string;
}

@Component({
  selector: 'yorbit-bulk-upload-template',
  templateUrl: './bulk-upload-template.component.html',
  styleUrls: ['./bulk-upload-template.component.scss']
})
export class BulkUploadTemplateComponent implements OnInit {
  //selectCourseExpertise: '101' | '201'='101';
  @ViewChild('fileform', { static: false }) fileform: ElementRef;
  templateDownloadInProgress: boolean;
  templateDownload201InProgress: boolean;
  disableProceed: boolean;
  actionMessage201: string;
  disableProceed201: boolean;
  actionMessage: string;
  isUploadSuccess: boolean = false;
  isfilegreater: boolean;
  fileName: any;
  fileToBeUploaded: FormData;
  isUploadButtonDisabled: boolean = true;
  isinvalidfile: boolean;
  excelUploadInProgress: boolean;

  distinctStatus: string[] = [];
  courseStatusFilter: CheckboxList[] = [];
  selectedCourseStatusFilter: CheckboxList[] = [];
  checkedStatus: string[] = [];
  selectAllStatus: boolean = false;

  masterContentList: any;
  public contentList: Array<ContentResponse>;

  displayedColumns: string[] = [
    'UniqueId',
    'UploadedFilename',
    'LogfileLink',
    'UploadedDate',
    'UploadStatus',
  ];

  constructor(
    private _AOSvc: AoService,
    private _FileSaverService: FileSaverService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getContentData();
    this.initializePage();
  }



  initializePage() {
    this.isfilegreater = false;
    this.fileName = 'Select a File';
    this.excelUploadInProgress = false;
    this.fileToBeUploaded = undefined;
    this.isUploadButtonDisabled = true;
    this.isUploadSuccess = false;
  }

  // courseExpertiseSelected(expert){
  //   this.selectCourseExpertise=expert;
  //   if(this.selectCourseExpertise=='101'){
  //     this.contentList=this.masterContentList.filter(ele=>ele.UploadedFilename.includes('101'))
  //   }else{
  //     this.contentList=this.masterContentList.filter(ele=>ele.UploadedFilename.includes('201'))
  //   }
  // }

  downloadTemplate() {
    this.templateDownloadInProgress = true;
    this._AOSvc
      .download101Template()
      .then((res) => {
        this.templateDownloadInProgress = false;
        this.downloadExcel(res);
      })
      .catch((error) => {
        this.templateDownloadInProgress = false;
        this.errorDownload(error);
      });
  }

  download201Template() {
    this.templateDownload201InProgress = true;
    this._AOSvc
      .download201Template()
      .then((res) => {
        this.templateDownload201InProgress = false;
        this.download201Excel(res);
      })
      .catch((error) => {
        this.templateDownload201InProgress = false;
        this.errorDownload(error);
      });
  }
  downloadExcel(res) {
    const blob = new Blob([<any>res.body], {
      type: 'application/octet-stream',
    });

    this._FileSaverService.save(blob, '101templatewithmetadata.xlsx');
    this.disableProceed = false;
    //this.actionMessage = 'Download Completed';
    this.hideActionMessage();
  }
  download201Excel(res) {
    const blob = new Blob([<any>res.body], {
      type: 'application/octet-stream',
    });

    this._FileSaverService.save(blob, '201templatewithmetadata.xlsx');
    this.disableProceed201 = false;
    //this.actionMessage201 = 'Download Completed';
    this.hideActionMessage();
  }
  errorDownload(error) {
    this.disableProceed201 = false;
    const errorMessage = 'Download Failed';
    this.actionMessage201 = errorMessage;
  }

  hideActionMessage() {
    this.fileform.nativeElement.value = null;
    setTimeout(() => {
      this.actionMessage = '';
      this.actionMessage201 = '';
      this.isUploadSuccess = false;
    }, 7000);
  }

  onFilesAdded(files, event) {
    console.log(files)
    if(files[0].size>153600){
      // If File size is greater that 200kb then dont process the file
      this.actionMessage =
            'File size should be 150kb only, if it is more that that then please split the data in two files and then upload';
          this.fileName = 'Select a File';
          this.isUploadButtonDisabled = true;
          this.hideActionMessage();
    }else{
      this.isfilegreater = false;
      const target: DataTransfer = <DataTransfer>event.target;
      if (files.length === 0) {
        this.fileName = 'Select a File';
        this.fileToBeUploaded = new FormData();
        this.isUploadButtonDisabled = true;
        this.isinvalidfile = true;
        return;
      }
      const formData = new FormData();
  
      for (const file of files) {
        formData.append(file.name, file, file.name);
        this.fileName = file.name;
        var fileextension = this.fileName.split('.');
        const uploadedFileSize = file.size / (1024 * 1024);
        if (uploadedFileSize > 10) {
          this.isfilegreater = true;
          this.isUploadButtonDisabled = true;
          this.fileToBeUploaded = null;
        } else {
          this.isfilegreater = false;
        }
        if (fileextension[1] == 'xlsx') {
          this.isinvalidfile = false;
        } else {
          this.isinvalidfile = true;
          this.isfilegreater = true;
        }
      }
      let reader = new FileReader();
      reader.onload = (e: any) => {
        var bstr = e.target.result;
        var workbook = XLSX.read(bstr, { type: 'binary' });
        // this.secondSheetName = workbook.SheetNames[1];
        // if(this.secondSheetName == 'MetaData') {
        this.actionMessage = '';
        this.isUploadButtonDisabled = false;
        // }
        // else {
        //   this.actionMessage = "Sorry! Seems like you are using the old version of the PSS upload default template. Please download the updated version and re-upload.";
        //   this.isUploadButtonDisabled = true;
        // }
      };
  
      reader.readAsBinaryString(target.files[0]);
      this.fileToBeUploaded = formData;
    }
   
  }

  upload() {
    this.actionMessage = 'Uploading...';
    this.excelUploadInProgress = true;
    this._AOSvc
      .uploadTemplateService(this.fileToBeUploaded)
      .subscribe((res) => {
        var response = res;
        if (response == 'Error' || response == 'File Uploaded') {
          this.isUploadSuccess = true;
          this.excelUploadInProgress = false;
          if(response=='Error'){
            this.isUploadSuccess = false;
            this.actionMessage =
            'There are error in the uploaded file.! For more details please refer to the log file sent to your email.';
          }else{
            this.actionMessage =
            'File has been successfully uploaded! For more details please refer to the log file sent to your email.';
          }
          
          this.fileName = 'Select a File';
          this.isUploadButtonDisabled = true;
          //this.fileToBeUploaded = null;
          this.hideActionMessage();
        } else {
          this.isUploadSuccess = false;
          this.excelUploadInProgress = false;
          this.actionMessage = 'Sorry, upload was not successful. Please check the mandatory fields in the excel template.';
          this.isUploadButtonDisabled = true;
          this.fileToBeUploaded = null;
          this.fileName = 'Select a File';
          this.hideActionMessage();
        }
        this.getContentData();
        // else if(response.length > 5) {
        //   var firstFiveElements = response.slice(0,5);
        //   this.excelUploadInProgress = false;
        //   this.actionMessage = "Sorry, upload has failed for the following request IDs "+firstFiveElements.toString()+" and more. For more details please refer to the log file sent to your email."
        // }
      });
  }
  closePopup() {
    this.fileName = 'Select a File';
    this.isUploadButtonDisabled = true;
    this.fileToBeUploaded = null;
    this.isfilegreater = false;
    this.hideActionMessage();
  }

  updateSelectAll(type) {
    if (type == 'CourseStatus') {
      this.selectAllStatus = !this.selectAllStatus;
      if (this.selectAllStatus) {
        this.checkedStatus = [];
        for (let i = 0; i < this.courseStatusFilter.length; i++) {
          this.courseStatusFilter[i].isSelected = true;
          this.checkedStatus.push(this.courseStatusFilter[i].value);
        }
      } else {
        this.checkedStatus = [];
        for (let i = 0; i < this.courseStatusFilter.length; i++) {
          this.courseStatusFilter[i].isSelected = false;
        }
      }

      // this.checkIfAnyFiltersApplied();

      this.updateSelectedCourseStatusFilter();
      this.loadContentData();
      // this.retainSelectedElements('CourseStatus');
    }
  }

  updateCourseStatusFilter(value) {
    this.selectedCourseStatusFilter.forEach((x) => (x.isSelected = false));
    if (this.checkedStatus.indexOf(value) == -1) this.checkedStatus.push(value);
    else this.checkedStatus.splice(this.checkedStatus.indexOf(value), 1);

    // this.checkIfAnyFiltersApplied();

    this.updateSelectedCourseStatusFilter();
    this.loadContentData();
    //this.retainSelectedElements('CourseStatus');
  }

  updateSelectedCourseStatusFilter() {
    this.selectedCourseStatusFilter.forEach((x) => (x.isSelected = false));
    this.courseStatusFilter.forEach((x) => (x.isSelected = false));
    for (let i = 0; i < this.checkedStatus.length; i++) {
      const index = this.selectedCourseStatusFilter.findIndex(
        (x) => x.value == this.checkedStatus[i]
      );
      if (index != null)
        this.selectedCourseStatusFilter[index].isSelected = true;
      const index2 = this.courseStatusFilter.findIndex(
        (x) => x.value == this.checkedStatus[i]
      );
      if (index2 != null) this.courseStatusFilter[index2].isSelected = true;
    }

    this.selectAllStatus = true;
    for (let i = 0; i < this.courseStatusFilter.length; i++) {
      if (!this.courseStatusFilter[i].isSelected) {
        this.selectAllStatus = false;
      }
    }
  }

  loadContentData() {
    this.contentList = [];
    this.contentList = this.masterContentList;
    if (this.checkedStatus.length > 0)
      this.contentList = this.contentList.filter((x) =>
        this.checkedStatus.includes(x.UploadStatus)
      );
  }

  retainSelectedElements(type) {
    if (type != 'CourseStatus') {
      this.courseStatusFilter = [];
      this.distinctStatus = [];

      this.distinctStatus = Array.from(
        new Set(this.contentList.map((x) => x.UploadStatus))
      );
      this.distinctStatus
        .map((item) => {
          return {
            value: item,
            isSelected: false,
          };
        })
        .forEach((item) => this.courseStatusFilter.push(item));

      this.updateSelectedCourseStatusFilter();
    }
  }

  downloadLogFile(fileLink) {
    window.open(fileLink);
  }

  getContentData() {
    this._AOSvc
      .getBulkUploadHistoryData()
      .then((res) => {
        this.contentList = res;
        this.masterContentList = res;
        this.courseStatusFilter=[];
        this.distinctStatus = Array.from(
          new Set(this.contentList.map((x) => x.UploadStatus))
        );
        this.distinctStatus
          .map((item) => {
            return {
              value: item.trim() == '' || item == null ? 'NA' : item.trim(),
              isSelected: false,
            };
          })
          .forEach((item) => this.courseStatusFilter.push(item));
        this.selectedCourseStatusFilter = this.courseStatusFilter;
      })
      .catch((error) => {});
  }

   formatDate(dateToFormat) {
    const format = 'dd/MM/yyyy hh:mm a';
    const locale = 'en-US';
    return formatDate(dateToFormat, format, locale);
  }
}

