<div
  fxLayout="column"
  fxLayoutAlign="center center"
  [ngStyle.gt-xs]="{ padding: '2% 10%' }"
>
  <div class="about-us-header" fxLayoutAlign="start center">
    About Us
  </div>
  <div class="about-us-info"
    *ngFor="let item of aboutUs"
    [innerHTML]="item" fxFlex="1 1 auto"
    fxLayoutAlign="start start"
    fxFlex="1 1 auto"
    [ngStyle.xs]="{'padding':'5px 10px'}"

  ></div>
  <div class="mail-link" [innerHTML]="aboutUsMailLink"></div>
</div>
