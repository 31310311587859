import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabFilter'
})
export class TabFilterPipe implements PipeTransform {

  transform(data: any[], tabId?: any): any {
    return data.filter(row => row.tabId === tabId);
  }

}
