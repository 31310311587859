  import { Component, OnInit } from '@angular/core';
import {  NavigationStart, Router } from '@angular/router';
import { RiskBrigadeService } from '../shared/services/risk-brigade.service';

@Component({
  selector: 'yorbit-risk-brigade',
  templateUrl: './risk-brigade.component.html',
  styleUrls: ['./risk-brigade.component.scss']
})
export class RiskBrigadeComponent implements OnInit  {

  currentRoute:string;

  constructor(private router:Router) { 
    this.currentRoute=this.router.url
    //console.log(this.currentRoute)
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url.replace('/', '');
        //console.log(this.currentRoute)
      }
     
      window.scrollTo(0, 0)
    });

  }

  ngOnInit() {

  }


}
