import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpertComponent } from './expert.component';
import { ExpertPopupComponent } from './expert-popup/expert-popup.component';
import { ExpertProfileComponent } from './expert-profile/expert-profile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule
  ],
  declarations: [ExpertComponent, ExpertPopupComponent, ExpertProfileComponent],
  exports:[ExpertComponent, ExpertPopupComponent]
})
export class ExpertModule { }
