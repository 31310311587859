import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IbrowseAcademyReducerState, IbrowseDetails } from './browse.reducer';
import * as fromBrowseReducer from './browse.reducer';
import { IBrowseAcademy } from '../browse-academy.interface';
export {IbrowseAcademyReducerState} from './browse.reducer';

export { reducer } from './browse.reducer';

export const browseAcademyState = createFeatureSelector<IbrowseAcademyReducerState>('browse');

export const getBrowseAcadmeyList = createSelector(
  browseAcademyState,
  fromBrowseReducer.getBrowseAcademy
)

export const getBrowseAcadmeyListLoaded = createSelector(
  browseAcademyState,
  fromBrowseReducer.getBrowseAcademyLoaded
)

export const getBrowseAcadmeyListLoading = createSelector(
  browseAcademyState,
  fromBrowseReducer.getBrowseAcademyLoading
)

export const getBrowseAcadmeyContentLoaded = createSelector(
  browseAcademyState,
  fromBrowseReducer.getBrowseAcademyContentLoaded
)

export const getBrowseAcadmeyContentLoading = createSelector(
  browseAcademyState,
  fromBrowseReducer.getBrowseAcademyContentLoading
)
