import { Component, OnInit, Inject } from '@angular/core';
import { PopupService } from '../popup.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Globals } from '../../../globals';
import { GraphDataService } from '@YorbitWorkspace/graph';

@Component({
  selector: 'yorbit-manager-feedback',
  templateUrl: './manager-feedback.component.html',
  styleUrls: ['./manager-feedback.component.scss']
})
export class ManagerFeedbackComponent implements OnInit {
  managerFeedbackList: Array<any>;
  pendingManagerFeedbackList: Array<any>;
  isManagerFeedbackSubmitted: boolean;
  showWarning: boolean;
  continueFeedbackPopup: boolean;
//  hideCrossIcon: boolean;
  popUpHeading: string;
  popUpDescription: string;
  showQuestionsForSelectedRating: Array<any>;
  option_no_reasons: Array<string>;
  option_yes_reasons: Array<object>;
  feedback_option_selected: Array<any>;
  reasonsChosenForYes: Array<any>;
  option_yes_no: Array<any>;
  showOptions: Array<any>;
  optionFilter: Array<any>;
  managerFeedbackComments: Array<any>;
  selectedStarRatingIndex: Array<any>;
  questionForSelectedRating: Array<any>;
  limitToArray: Array<any>;
  showErrorOnSubmit: boolean;
  option_selected: Array<any>;
  option_yes_selected: Array<any>;
  managerFeedbackProcessing: boolean;
  mgrFeedbackPostArray: Array<any>;
  feedbackDataLoaded: boolean;
  starsCollection: Array<any>;
  showGreet : boolean;

  constructor(
    private _popupSvc: PopupService,
    private dialogRef: MatDialogRef<ManagerFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private globals: Globals,
    private _graphSvc:GraphDataService
  ) {}

  ngOnInit() {
    this.pendingManagerFeedbackList = new Array();
    this.popUpHeading =
      'Your team member successfully completed the following course';
    this.popUpDescription =
      'How do you rate the overall effectiveness of the skilling program taken up by the associate?';

    this.starsCollection = new Array(5);
    this.getOptionsForManagerFeedback();

    this.isManagerFeedbackSubmitted = false;
    this.showWarning = false;
    this.feedbackDataLoaded = false;
    this.showErrorOnSubmit = false;
    this.continueFeedbackPopup = false;
    this.showGreet = false;

    if (this.globals.managerFeedbackList.length <= 0) {
      this._popupSvc.getManagerFeebback().subscribe(
        data => {
          this.feedbackDataLoaded = true;
          if(data.length>0){
          this.globals.managerFeedbackList = data;
          this.managerFeedbackList = this.globals.managerFeedbackList;

          this.globals.managerFeedbackList.forEach((value,index)=>{
            this._graphSvc.getUserName(value.MID).subscribe((graphData)=>{
              value.name = graphData.displayName;
            },
            (error)=>{
              value.name = value.MID;
            });
          });
          this.getPendingManagerFeedbackList();
          }
          else{
            this.dialogRef.close();
          }
        },
        error => {
          this.feedbackDataLoaded = true;
        }
      );
    } else {
      this.feedbackDataLoaded = true;
      this.managerFeedbackList = this.globals.managerFeedbackList;
      this.getPendingManagerFeedbackList();
    }
  }

  isPristine(){
    let count = 0;
    this.option_yes_no.filter(row=>{
      if(row === 'yes' || row === 'no'){
        count++;
      }
    });
    if(count >= 1){
      return false;
    }
    else return true;
  }

  closeDialog() {
    let count = 0;
    let isUnSaved = false;
    this.option_yes_no.filter(row=>{
      if(row === 'yes' || row === 'no'){
        count++;
      }
    });
    isUnSaved = (count>= 1 ? true :false)
    if (!this.isManagerFeedbackSubmitted && isUnSaved) {
      //show warning dialogue
      this.showWarning = true;
   //   this.hideCrossIcon = true;
    }
    else if(this.isManagerFeedbackSubmitted && !isUnSaved && !this.showGreet){
      // do nothing
      this.showGreet = true;
    }
    else{
      this.globals.isManagerFeedbackPopupShown = true;
      this.dialogRef.close();
    }
  }

  getOptionsForManagerFeedback() {
    this.option_no_reasons = [
      "Project doesn't have the opportunity",
      'Is on bench',
      'Moved to another Project',
      'Is working on another skill'
    ];
    this.option_yes_reasons = [
      { rating: 'bad', label: "Learner's application of skill", id: '1' },
      { rating: 'bad', label: 'Course effectiveness', id: '2' },
      { rating: 'good', label: 'Application of skill', id: '3' },
      { rating: 'good', label: 'Ability to work independently', id: '4' }
    ];
    this.showQuestionsForSelectedRating = new Array();
  }

  getPendingManagerFeedbackList() {
    //array to store the pending list got from the api
    this.pendingManagerFeedbackList = this.managerFeedbackList.map(row => row);

    //final array to be send to the post api
    this.feedback_option_selected = new Array();

    //array to capture the reasons chosen for yes
    this.reasonsChosenForYes = new Array(this.managerFeedbackList.length);

    //array to store yes/no
    this.option_yes_no = new Array(this.managerFeedbackList.length);

    //boolean array to determine whether to show options or not when yes/no is selected
    this.showOptions = new Array(this.managerFeedbackList.length);

    //option filter to show the questions based on rating
    this.optionFilter = new Array(this.managerFeedbackList.length);

    //array to store optional comments
    this.managerFeedbackComments = new Array(this.managerFeedbackList.length);

    //array to track the rating chosen among 1 to 5
    this.selectedStarRatingIndex = new Array(this.managerFeedbackList.length);

    //array to track the question for each rating selected
    this.questionForSelectedRating = new Array(this.managerFeedbackList.length);

    //initialize the rating array with 0.
    this.selectedStarRatingIndex.fill(0);

    this.option_yes_selected = new Array(this.managerFeedbackList.length);
    this.option_selected = new Array(this.managerFeedbackList.length);

    //limitToarray
    this.limitToArray = new Array(this.managerFeedbackList.length);
    this.limitToArray.fill(false);


  }

  saveManagerFeedback(parentIndex, optionValue, feedbackId) {
    if (this.option_yes_no[parentIndex] === 'no') {
      this.showErrorOnSubmit = false;
      this.showOptions[parentIndex] = false;
      this.feedback_option_selected[parentIndex] = {
        Id: feedbackId,
        GeneralQuestion: 'Is the associate working on the current skill?',
        GeneralAnswer: this.option_yes_no[parentIndex],
        Rating: this.selectedStarRatingIndex[parentIndex],
        FeedbackDetailquestionAnswers: [
          { DetailQuestion: '', DetailAnswer: optionValue }
        ],
        OptionalComment: null
      };
    } else if (this.option_yes_no[parentIndex] === 'yes') {
      this.feedback_option_selected[parentIndex] = {
        Id: feedbackId,
        GeneralQuestion: 'Is the associate working on the current skill?',
        GeneralAnswer: this.option_yes_no[parentIndex],
        Rating: this.selectedStarRatingIndex[parentIndex],
        FeedbackDetailquestionAnswers: this.reasonsChosenForYes[parentIndex],
        OptionalComment: this.managerFeedbackComments[parentIndex]
      };
    }
  }

  saveComments(index) {
    if (this.feedback_option_selected[index] !== undefined) {
      this.feedback_option_selected[
        index
      ].OptionalComment = this.managerFeedbackComments[index];
    }
  }

  saveOptionSelected(index) {
    //create a temp array to store the reasons chosen for yes
    if (this.reasonsChosenForYes[index] === undefined) {
      this.reasonsChosenForYes[index] = new Array();
    }
    //create the feedback object with required properties
    if (this.feedback_option_selected[index] !== undefined) {
      this.feedback_option_selected[index] = {
        Id: '',
        GeneralQuestion: 'Is the associate working on the current skill?',
        GeneralAnswer: '',
        Rating: '',
        FeedbackDetailquestionAnswers: [],
        OptionalComment: null
      };
    }
    if (this.option_yes_no[index] === 'yes') {
      this.limitToArray[index] = true;
      this.showOptions[index] = false;
      //clear the values selected for no if present
      if (this.option_selected[index] !== undefined) {
        this.option_selected[index] = '';
      }
    } else if (this.option_yes_no[index] === 'no') {
      this.limitToArray[index] = false;
      this.showOptions[index] = true;
      //clear the values selected for yes if present
      if (this.selectedStarRatingIndex[0]) {
        this.selectedStarRatingIndex[index] = 0;
      }
      if (this.option_yes_selected[index] !== undefined) {
        this.option_yes_selected[index] = [];
      }
      if (this.managerFeedbackComments[index] !== undefined) {
        this.managerFeedbackComments[index] = null;
      }
    }
  }

  changeQuestionByRating(index, ratingSelected) {
    //clear the values selected for other ratings
    if (this.option_yes_selected !== undefined) {
      this.option_yes_selected[index] = [];
    }
    if (this.managerFeedbackComments[index] !== undefined) {
      this.managerFeedbackComments[index] = null;
    }
    if (this.feedback_option_selected[index] !== undefined) {
      this.reasonsChosenForYes[index] = [];
      this.feedback_option_selected[index].FeedbackDetailquestionAnswers = [];
    }
    this.showOptions[index] = true;
    this.showQuestionsForSelectedRating[index] = true;
    switch (ratingSelected) {
      case 1:
      case 2:
      case 3:
        this.questionForSelectedRating[index] = 'Where does the gap exist?';
        this.optionFilter[index] = 'bad';
        break;
      case 4:
      case 5:
        this.questionForSelectedRating[index] =
          'What improvement did you observe in the learner?';
        this.optionFilter[index] = 'good';
        break;
    }
  }

  saveSelectedReasons(
    parentIndex,
    childIndex,
    optionValue,
    isChecked,
    feedbackId
  ) {
    if (isChecked) {
      this.reasonsChosenForYes[parentIndex].push({
        DetailQuestion: this.questionForSelectedRating[parentIndex],
        DetailAnswer: optionValue.label
      });
    } else if (!isChecked) {
      const getIndexOfDeselectedValue = this.reasonsChosenForYes[parentIndex]
        .map(function(x) {
          return x.DetailAnswer;
        })
        .indexOf(optionValue.label);
      if (getIndexOfDeselectedValue !== -1) {
        this.reasonsChosenForYes[parentIndex].splice(
          getIndexOfDeselectedValue,
          1
        );
      }
    }
    if (this.reasonsChosenForYes[parentIndex].length > 0) {
      this.showErrorOnSubmit = false;
    } else if (this.reasonsChosenForYes[parentIndex].length === 0) {
      this.showErrorOnSubmit = true;
    }
    this.saveManagerFeedback(parentIndex, optionValue, feedbackId);
  }

  validateManagerFeedback() {
    //validation before submit
    if (this.option_yes_no !== undefined && this.option_yes_no.length > 0) {
      this.option_yes_no.forEach((value, key) => {
        if (value === 'yes') {
          // check if rating is selected and options under it are chosen

          if (this.feedback_option_selected[key] === undefined) {
            this.showErrorOnSubmit = true;
          } else if (
            this.feedback_option_selected[key].FeedbackDetailquestionAnswers
              .length <= 0 ||
            this.selectedStarRatingIndex[key] <= 0
          ) {
            this.showErrorOnSubmit = true;
          } else {
            this.showErrorOnSubmit = false;
          }
        } else if (value === 'no') {
          // check if options under no is selected
          if (this.feedback_option_selected[key] === undefined) {
            this.showErrorOnSubmit = true;
          } else if (
            this.feedback_option_selected[key].FeedbackDetailquestionAnswers
              .length <= 0
          ) {
            this.showErrorOnSubmit = true;
          } else {
            this.showErrorOnSubmit = false;
          }
        }
      });
    } else {
      this.showErrorOnSubmit = true;
    }

    if (this.showErrorOnSubmit) {
      // do nothing
    } else {
      //submit feedback
      this.submitManagerFeedback();
    }
  }

  submitManagerFeedback() {
    this.managerFeedbackProcessing = true;
   // this.hideCrossIcon = true;
    this.mgrFeedbackPostArray = [];
    for (let count = 0; count < this.feedback_option_selected.length; count++) {
      if (
        this.feedback_option_selected[count] === '' ||
        this.feedback_option_selected[count] === undefined
      ) {
        continue;
      } else {
        this.mgrFeedbackPostArray.push(this.feedback_option_selected[count]);
      }
    }

    this._popupSvc.postManagerFeedback(this.mgrFeedbackPostArray).subscribe(
      data => {
        this.managerFeedbackProcessing = false;
       // this.hideCrossIcon = false;
        for (let i = 0; i < this.mgrFeedbackPostArray.length; i++) {
          for (let j = 0; j < this.managerFeedbackList.length; j++) {
            if (
              this.mgrFeedbackPostArray[i].Id ===
              this.managerFeedbackList[j].Id
            ) {
              this.managerFeedbackList.splice(j, 1);
            }
          }
        }

        this.pendingManagerFeedbackList = [];
        this.pendingManagerFeedbackList = this.managerFeedbackList.map(row => row);
        // to clear the input variables
        this.getPendingManagerFeedbackList();

        if (this.managerFeedbackList.length === 0) {
          this.isManagerFeedbackSubmitted = true;
          this.continueFeedbackPopup = false;
        //  this.hideCrossIcon = false;
          this.closeDialog();
        } else {
          this.continueFeedbackPopup = true;
        //  this.hideCrossIcon = true;
        }
      },
      error => {
        this.managerFeedbackProcessing = false;
      }
    );
  }

  continueGivingFeedback() {
    this.continueFeedbackPopup = false;
   // this.hideCrossIcon = false;
    this.getPendingManagerFeedbackList();
  }
}
