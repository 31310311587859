import { Component, OnInit, Input } from '@angular/core';
import { CourseList } from './wfh-course-list';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Globals } from '../../globals';
import { AppInsightService } from '@YorbitWorkspace/app-insight';

@Component({
  selector: 'yorbit-wfh-courses',
  templateUrl: './wfh-courses.component.html',
  styleUrls: ['./wfh-courses.component.scss']
})
export class WfhCoursesComponent implements OnInit {
  wfhcourses: any[] = [];
  wfhCoursesMobile: any[] = [];
  showCoursesMobile: boolean = false;

  constructor(
    private recomm: CourseList,
    public globals: Globals,
    private _InsightService: AppInsightService,
    private mediaObserver: MediaObserver
  ) {}

  ngOnInit() {
    this.subscribeMediaChanges();
    this.wfhcourses = [];
    // this.courses = this.recomm.list;
    this.getCourses(9);
  }

  subscribeMediaChanges() {
    let sub = this.mediaObserver.media$.subscribe((media: MediaChange) => {
      if (media.mqAlias == 'xs') {
        this.showCoursesMobile = true;
        this.getCoursesForMobile(3);
      } else {
        this.showCoursesMobile = false;
      }
    });
  }

  redirectToCourseSite(courseObj) {
    const insightPayload = {
      Vendor: courseObj.Vendor,
      Course: courseObj.Course,
      Link: courseObj.Link,
      Expertise: courseObj.exp,
      Mid: this.globals.MId
    };
    this._InsightService.trackEvent('WFH Courses', insightPayload);
    window.open(courseObj.Link, '_blank');
  }
  getCourses(limit) {
    this.wfhcourses = [];
    for (let i = 0; i < limit; i++) {
      this.wfhcourses.push(this.recomm.list[i]);
    }
  }

  getCoursesForMobile(limit) {
    this.wfhCoursesMobile = [];
    for (let i = 0; i < limit; i++) {
      this.wfhCoursesMobile.push(this.recomm.list[i]);
    }
    //return this.wfhcourses;
  }

  showMore(isMob) {
    if (!isMob) this.getCourses(this.recomm.list.length);
    else this.getCoursesForMobile(this.recomm.list.length);
  }
  showLess(isMob, limit) {
    if (!isMob) this.getCourses(limit);
    else this.getCoursesForMobile(limit);   
    if(!isMob) this.pageScroll(); 
  }
  //////console.log(this.wfhcourses);
  pageScroll() {  
    const ele: any = document.getElementById('wfh-recommendations-holder');
    if (ele) {
      ele.scrollIntoView(true);
      //window.scrollTo(0,0);
    }
  }
}
