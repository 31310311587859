<div fxLayout="column" class="top-learners-parent" 
>
  <div fxFlex.gt-xs="80px" class="header" fxLayoutAlign.xs="center center" fxLayoutAlign.gt-xs="center center"
    fxLayoutGap="15px" fxLayout="row"
    *ngIf="!isTopLearnersLoading && topLearnersList.length > 0">
    <div fxLayout="row" class="left-heading-decoration">
      <hr class="left-heading-line">
      <div class="left-heading-dot"></div>
    </div>
    <div class="title" fxFlex.gt-xs="280px" fxFlex.xs="210px">
      TOP LEARNERS
    </div>
    <div fxLayout="row" class="right-heading-decoration">
      <div class="right-heading-dot"></div>
      <hr class="right-heading-line">
    </div>
    <!--<div class="sub-title">
          ( {{topLearnersQuarter+" "+topLearnersYear}} )
        </div>-->
  </div>

  <div fxFlex="100%" fxShow class="top-learners-quarterwise-container" fxLayout="row" fxLayoutAlign="center stretch">
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="center center"
      *ngIf="!isTopLearnersLoading && topLearnersList.length > 0 && bullets.length>1">
      <!-- <mat-icon (click)="showPreviousSlide()">chevron_left</mat-icon> -->
      <div class="iconSpriteImage prev-icon" (click)="resetShowMore();showPreviousSlide()"></div>
    </div>
    <div *ngIf="isTopLearnersLoading" fxFlexFill fxLayoutAlign="center center">
      <app-preloader></app-preloader>
    </div>
    <div fxLayout="column" class="top-learners-holder" [ngClass.xs]="['xs']"
      *ngIf="!isTopLearnersLoading && topLearnersList.length > 0">

      <div fxLayout="row" fxFlex="342px" fxLayoutAlign="center center">
        <div class="carousel-holder" [ngClass.lt-lg]="['lt-lg']" [ngClass.gt-md]="['gt-md']" fxFlex.xs="262px"
          fxFlex.sm="524px" fxFlex.md="788px" fxFlex.gt-md="1048px" fxLayout="row" fxLayoutAlign="start stretch"
          (mouseleave)="setIntervalForSlider(5000)" (mouseenter)="pauseSlider($event)">

          <div fxFlex="262px" fxLayout="column"
            *ngFor="let learner of topLearnersList| slice:numberOfTopLearners*sliderActiveIndex:numberOfTopLearners*sliderActiveIndex+numberOfTopLearners">
            <div class="top-learner-card" fxFlex="342px" fxLayout="column" fxLayoutAlign="start center"
              fxLayoutGap="20px">
              <div class="top-learner-tile" fxLayout="column" fxLayoutAlign="center stretch">
                <div class="show-less-tile" fxFlex fxLayout="column" fxLayoutAlign="start stretch"
                  [ngClass.lt-lg]="['lt-lg']">
                  <div class="profile" fxLayoutAlign="start center" fxFlex="78px">
                    <img *ngIf="learner.Image != null" [src]="learner.Image" fxFlex="78px" />
                    <!-- <mat-icon fxFlex="78px" class="person" *ngIf="learner.Image == null">person</mat-icon> -->
                    <div fxFlex="78px" fxLayoutAlign="center center" *ngIf="learner.Image == null">
                      <div class="person-icon"></div>
                    </div>
                    <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxFlexOffset="10px">
                      <div class="title">
                        {{ learner.Name }}
                      </div>
                      <div class="mid">
                        ({{ learner.MID }})
                      </div>
                      <div class="role">
                        {{ learner.Designation }}
                      </div>
                    </div>
                  </div>
                  <mat-divider fxFlexOffset="10px" fxFlex="3px"></mat-divider>
                  <div fxFlexOffset="10px" class="courses-count" fxFlex="36px" fxLayoutAlign="start center">
                    <div class="holder" fxFlex="36px" fxLayoutAlign="center center">
                      {{
                      learner.Courses301 +
                      learner.Courses201 +
                      learner.Courses101
                      }}
                    </div>
                    <div fxFlexOffset="10px" fxFlex>Total number of Courses</div>
                  </div>
                  <div fxFlexOffset="10px" class="courses-Overview" fxFlex="32px" fxLayoutAlign="start stretch">
                    <div class="bg301 child" *ngIf="learner.Courses301 != 0" fxFlex [ngClass]="{
                  left: true,
                  right:
                    learner.Courses201 == 0 &&
                    learner.Courses101 == 0
                }" fxLayoutAlign="center center">
                      {{ learner.Courses301 }}
                      {{
                      learner.Courses301 == 1
                      ? "Course"
                      : "Courses"
                      }}
                    </div>
                    <mat-divider fxFlex="1px" vertical="true" *ngIf="
                  learner.Courses301 != 0 &&
                  learner.Courses201 != 0
                "></mat-divider>
                    <div class="bg201 child" fxFlex *ngIf="learner.Courses201 != 0" [ngClass]="{
                  left: learner.Courses301 == 0,
                  right: learner.Courses101 == 0
                }" fxLayoutAlign="center center">
                      {{ learner.Courses201 }}
                      {{
                      learner.Courses201 == 1
                      ? "Course"
                      : "Courses"
                      }}
                    </div>
                    <mat-divider fxFlex="1px" vertical="true" *ngIf="
                  learner.Courses201 != 0 &&
                  learner.Courses101 != 0
                "></mat-divider>
                    <div class="bg101 child" *ngIf="learner.Courses101 != 0" fxFlex [ngClass]="{
                  left:
                    learner.Courses301 == 0 &&
                    learner.Courses201 == 0,
                  right: true
                }" fxLayoutAlign="center center">
                      {{ learner.Courses101 }}
                      {{
                      learner.Courses101 == 1
                      ? "Course"
                      : "Courses"
                      }}
                    </div>
                  </div>
                  <div fxFlexOffset="15px" class="comments" fxFlex="65px">
                    {{ learner.Comments.slice(0, 160) }}
                  </div>
                  <div fxFlexOffset="15px" class="read-more" fxFlex="17px" fxLayoutAlign="start center"
                    [fxShow.lt-lg]="true" [fxShow.gt-md]="false">
                    <div (click)="resetShowMore();learner.ShowMore = true;trackEvent('showmore')">
                      Read More
                    </div>
                  </div>
                </div>
                <div class="show-more-tile" fxFlexFill [fxShow.lt-lg]="learner.ShowMore" fxLayout="column"
                  fxLayoutAlign="start stretch">
                  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="overflow">
                    <div class="comments">
                      {{ learner.Comments }}
                    </div>
                  </div>
                  <div fxFlexOffset="15px" class="read-less" fxFlex="10px" fxLayoutAlign="start center"
                    [fxShow.lt-lg]="true" [fxShow.gt-md]="false">
                    <div (click)="learner.ShowMore = false;trackEvent('showless')">
                      Read Less
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div fxFlex="35px" fxLayoutAlign="center center" *ngIf="bullets.length>1">
        <div class="bullets" *ngFor="let slide of bullets; let index = index"
          [ngClass]="{ active: index == sliderActiveIndex }"></div>
      </div>
    </div>
    <div fxFlex="50px" fxLayout="row" fxLayoutAlign="center center"
      *ngIf="!isTopLearnersLoading && topLearnersList.length > 0 && bullets.length>1">
      <!-- <mat-icon  (click)="showNextSlide()">chevron_right</mat-icon> -->
      <div class="iconSpriteImage next-icon" (click)="resetShowMore();showNextSlide()"></div>
    </div>

  </div>
</div>