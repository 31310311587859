import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alphabet'
})
export class AlphabetPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (args === undefined) {
      return value;
    } else {
      value = value || [];
      const out = [];
      value.forEach(item => {
        if (item.charAt(0).toLowerCase() === args) {
          out.push(item);
        }
      });
      return out;
    }
  }
}
