import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PssComponent } from '../pss.component';
import { CourseRequestsComponent } from '../course-requests/course-requests.component';
import { CertificationRequestsComponent } from '../certification-requests/certification-requests.component';

import { PssEvaluationComponent } from '../pss-evaluation/pss-evaluation.component';
import { PssOpmContentApprovalComponent } from '../pss-opm-content-approval/pss-opm-content-approval.component';
import { CanActivatePss } from './pss-router-guard';
import {ContentVerificationGuardDeactivate} from '../pss.service';
import { ExternalCertificateComponent } from '../external-certificate/external-certificate.component';
import { CourseProcedureUploadComponent } from '../course-procedure-upload/course-procedure-upload.component';

const routes: Routes = [
  {
    path: '',
    component: PssComponent,
    children: [
      {
        path: '',
        redirectTo: 'course-requests',
        pathMatch: 'full'
      },
      {
        path: 'course-requests',
        component: CourseRequestsComponent,
        data: {
          title: 'Pss Course requests'
        }
      },
      {
        path: 'certification/:type',
        component: CertificationRequestsComponent,
        data: {
          title: 'pss certification requests'
        }
      },
      {
        path: 'externalCertificate/:type',
        component: ExternalCertificateComponent,
        data: {
          title: 'pss external certification requests'
        }
      },
      {
        path: 'evaluation-requests',
        component: PssEvaluationComponent,
        data: {
          title: 'pss evaluation requests'
        }
      },
      {
        path: 'content-verification/:role',
        component: PssOpmContentApprovalComponent,
        canActivate: [CanActivatePss],
        data: {
          title: 'Content Verification'
        },
        canDeactivate: [ContentVerificationGuardDeactivate]
      },
      {
        path: 'course-procedure-upload',
        component: CourseProcedureUploadComponent,
        data: {
          title: 'Course procedure upload'
        }
      },
    ]
  }
];
@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers : [ContentVerificationGuardDeactivate]
})
export class PssRouterModule {}
