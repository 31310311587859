<div class="skilling-recommendations-container">
  <div class="skilling-recommendations-label">
    Skilling recommendations based on <span class="srl-style">{{pl4}}</span>
  </div>
  <div class="skilling-recommendations-content">
    <!-- shown on xl,lg and md screens -->
    <div fxShow fxHide.lt-md="true">
      <div class="skill-type-box">
        <ng-template [ngTemplateOutlet]="coreAndSupportSkills"></ng-template>
      </div>
      <div class="skill-type-box">
        <ng-template [ngTemplateOutlet]="crossSkills"></ng-template>
      </div>
      <div class="skill-type-box">
        <ng-template [ngTemplateOutlet]="futureSkills"></ng-template>
      </div>
    </div>
    <!-- shown on xs, sm screens -->
    <div fxHide fxShow.lt-md="true" [ngStyle]="{ padding: '5px 8px' }">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Core and Support Skilling
          </mat-expansion-panel-header>
          <ng-template [ngTemplateOutlet]="coreAndSupportSkills"></ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Cross Skilling
          </mat-expansion-panel-header>
          <ng-template [ngTemplateOutlet]="crossSkills"></ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            Future Skilling
          </mat-expansion-panel-header>
          <ng-template [ngTemplateOutlet]="futureSkills"></ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Reusable templates -->
  <!-- Core and Support skills holder-->
  <ng-template #coreAndSupportSkills>
    <div class="coreAndSupportSkills">
      <div fxShow fxHide.lt-md="true" class="skill-type-label">
        Core and Support Skilling
      </div>
      <div class="skill-data-container">
        <div class="skill-data-column" [ngStyle.lt-md]="{ 'overflow-y': 'initial' }">
          <!-- Core Skills -->
          <div class="skill-data skill-data-style">
            <div class="title">Core Skilling</div>
            <ng-template *ngIf="!coreSkillLoading" [ngTemplateOutlet]="skillListCoreAndSupport"
              [ngTemplateOutletContext]="{
                skills: coreSkills.CoreSkillCourses,
                toggleColumn: showMoreColumn_Core,
                isCoreSkill: true
              }"></ng-template>
            <div *ngIf="coreSkillLoading">
              <app-preloader></app-preloader>
            </div>
          </div>
          <!-- Support Skills -->
          <div class="skill-data skill-data-pt">
            <div class="title">Support Skilling</div>
            <ng-template *ngIf="!supportSkillLoading" [ngTemplateOutlet]="skillListCoreAndSupport"
              [ngTemplateOutletContext]="{
                skills: supportSkills,
                toggleColumn: showMoreColumn_Support,
                isCoreSkill: false
              }"></ng-template>
            <div *ngIf="supportSkillLoading">
              <app-preloader></app-preloader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Cross Skills holder -->
  <ng-template #crossSkills>
    <div class="crossSkills">
      <div fxShow fxHide.lt-md="true" class="skill-type-label" [ngStyle.lt-md]="{
          'border-left': '0',
          'border-right': '0',
          'overflow-y': 'initial'
        }">
        Cross Skilling
      </div>
      <div class="skill-data-container">
        <div *ngIf="!crossSkillLoading && crossSkillPath.length > 0" class="skill-data-column" [ngStyle.lt-md]="{
            'border-left': '0',
            'border-right': '0',
            'overflow-y': 'initial'
          }">
          <div class="skill-data skill-data-pb">
            <div class="skill-list">
              <div *ngFor="let row of crossSkillPath; let i = index" class="skill-row"
                [ngClass]="{ 'skill-row-highlight': showMoreColumn_Cross[i] }">
                <div class="table">
                  <div class="table-row">
                    <div class="table-cell skill-name">
                      {{ row.CrossSkillName }}
                    </div>
                    <div class="table-cell toggle-action" *ngIf="!showMoreColumn_Cross[i]"
                      (click)="collapseOtherRowsInCol(i, showMoreColumn_Cross)">
                      Show more
                    </div>
                    <div class="table-cell toggle-action" *ngIf="showMoreColumn_Cross[i]"
                      (click)="showMoreColumn_Cross[i] = false">
                      Show less
                    </div>
                  </div>
                </div>
                <div *ngIf="showMoreColumn_Cross[i]">
                  <div *ngIf="
                      !row.IsLoading &&
                      row.CrossSkillPath.TargetSkill.CourseId != null &&
                      row.CrossSkillPath.IsTargetSkillAlreadyAdded == false
                    ">
                    <div class="table">
                      <div class="table-row course-details table-row-h">
                        <div class="table-cell skill-path-duration">
                          {{ row.CrossSkillPath.TotalDuration + " Days"}}
                        </div>
                        <div class="table-cell course-action">
                          <button (click)="addCrossSkillPathToLP(row, i, 'CrossSkill')"
                            [disabled]="addingCrossSkillInProgress" [ngClass]="{'disabled':addingCrossSkillInProgress}"
                            *ngIf="!addingCrossSkill[i]" title="Add To Learning Path">
                            Add
                          </button>
                          <button [disabled]="true" class="disabled" *ngIf="addingCrossSkill[i]">
                            Adding...
                          </button>
                        </div>
                      </div>
                    </div>
                    <!--Transition Skill-->
                    <div class="spacing-btw-box" *ngFor="
                        let transSkill of row.CrossSkillPath.TransitionSkills|orderBy:{'property': 'Duration','order':'ascending'};
                        let transSkillIndex = index
                      ">
                      <div class="table bg_variant{{ transSkill.Expertise }}">
                        <div class="table-row skill-details">
                          <div class="table-cell skill-name">
                            {{
                            transSkillIndex + 1 + ' ' + transSkill.SkillName
                            }}
                          </div>
                          <div class="table-cell course-duration">
                            {{ transSkill.Duration + " Days" }}
                          </div>
                        </div>
                      </div>
                      <div class="table bg{{ transSkill.Expertise }}">
                        <div class="table-row skill-path-course">
                          <div class="table-cell course-expertise">
                            {{ transSkill.Expertise }}
                          </div>
                          <div class="table-cell course-name">
                            {{ transSkill.CourseName }}
                          </div>
                        </div>
                        <div class="table-row skill-path-course">
                          <div class="table-cell">
                            <!-- Spacer cell -->
                          </div>
                          <div class="table-cell course-status">
                            {{ transSkill.Status }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Target Skill-->
                    <div class="spacing-btw-box">
                      <div class="table bg_variant{{
                        row.CrossSkillPath.TargetSkill.Expertise
                      }} ">
                        <div class="table-row skill-details">
                          <div class="table-cell skill-name">
                            {{
                            row.CrossSkillPath.TransitionSkills.length +
                            1 +
                            ' ' +
                            row.CrossSkillPath.TargetSkill.SkillName
                            }}
                          </div>
                          <div class="table-cell course-duration">
                            {{ row.CrossSkillPath.TargetSkill.Duration + " Days" }}
                          </div>
                        </div>
                      </div>
                      <div class="table bg{{
                        row.CrossSkillPath.TargetSkill.Expertise
                      }}">
                        <div class="table-row skill-path-course">
                          <div class="table-cell course-expertise">
                            {{ row.CrossSkillPath.TargetSkill.Expertise }}
                          </div>
                          <div class="table-cell course-name">
                            {{ row.CrossSkillPath.TargetSkill.CourseName }}
                          </div>
                        </div>
                        <div class="table-row skill-path-course">
                          <div class="table-cell">
                            <!-- Spacer cell -->
                          </div>
                          <div class="table-cell course-status">
                            {{ row.CrossSkillPath.TargetSkill.Status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="row.IsLoading">
                    <div class="table">
                      <div class="table-row course-details table-row-h">
                        <div class="table-cell info-msg">
                          {{ 'Loading...' }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                      !row.IsLoading &&
                      (row.CrossSkillPath.TargetSkill.CourseId == null ||
                        row.CrossSkillPath.TargetSkill.CourseId == '') &&
                      !row.CrossSkillPath.IsTargetSkillAlreadyAdded
                    ">
                    <div class="table">
                      <div class="table-row course-details table-row-h">
                        <div class="table-cell info-msg">
                          {{ 'No Skills found.' }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                      !row.IsLoading &&
                      (row.CrossSkillPath.TargetSkill.CourseId == null ||
                      row.CrossSkillPath.TargetSkill.CourseId == '') &&
                      row.CrossSkillPath.IsTargetSkillAlreadyAdded
                    ">
                    <div class="table">
                      <div class="table-row course-details table-row-h">
                        <div class="table-cell info-msg">
                          {{
                          'You have added recommendations to your Learning Path.'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="skill-data-column" *ngIf="!crossSkillLoading && crossSkillPath.length == 0">
          <div class="info-msg">
            {{ 'No Recommended Cross Skills found for your PrimaryL4.' }}
          </div>
        </div>
        <div *ngIf="crossSkillLoading">
          <app-preloader></app-preloader>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Future Skills holder -->
  <ng-template #futureSkills>
    <div class="futureSkills">
      <div fxShow fxHide.lt-md="true" class="skill-type-label">
        Future Skilling
      </div>
      <div *ngIf="!futureSkillLoading && futureSkillPath.length > 0" class="skill-data-container sdc-style"
        [ngStyle.lt-md]="{ 'overflow-y': 'initial' }" [ngStyle.gt-sm]="{ 'overflow-y': 'auto' }">
        <div *ngFor="let fSkill of futureSkillPath; let parent = index">
          <div class="table future-skill-list">
            <div class="table-row">
              <div class="table-cell skill-name">
                {{ fSkill.FutureSkillName }}
              </div>
              <div class="table-cell toggle-arrows" *ngIf="!showMoreColumn_Future[parent]" (click)="
                  collapseOtherFutureSkill(parent); lastVisitedIndex = parent
                ">
                <mat-icon title="Show More">expand_more</mat-icon>
              </div>
              <div class="table-cell toggle-arrows" *ngIf="showMoreColumn_Future[parent]" (click)="
                  showMoreColumn_Future[parent] = false;
                  lastVisitedIndex = parent
                ">
                <mat-icon title="Show Less">expand_less</mat-icon>
              </div>
            </div>
          </div>
          <div *ngIf="showMoreColumn_Future[parent]">
            <ng-template [ngTemplateOutlet]="skillListCrossSkills" [ngTemplateOutletContext]="{
                skill: fSkill.FutureSkillPaths,
                parentIndex: parent,
                isLoading: fSkill.IsLoading,
                isAdded : fSkill.IsAdded
              }"></ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="!futureSkillLoading && futureSkillPath.length == 0" class="skill-data-container sdc-style">
        <div class="info-msg">
          {{ 'No Recommended future skills found for your PL4.' }}
        </div>
      </div>
      <div *ngIf="futureSkillLoading">
        <app-preloader></app-preloader>
      </div>
    </div>
  </ng-template>

  <!-- Core and Support skills -->
  <ng-template #skillListCoreAndSupport let-skill="skills" let-toggle="toggleColumn" let-isCore="isCoreSkill">
    <div class="skill-list">
      <div *ngFor="let row of skill; let i = index" class="skill-row" [ngClass]="{ 'skill-row-highlight': toggle[i] }">
        <div class="table" *ngIf="(isCore && i == 0) || !isCore">
          <div class="table-row">
            <div class="table-cell skill-name">{{ row.SkillName }}</div>
            <div class="table-cell toggle-action" *ngIf="!toggle[i]" (click)="collapseOtherRowsInCol(i, toggle)">
              Show more
            </div>
            <div class="table-cell toggle-action" *ngIf="toggle[i]" (click)="toggle[i] = false">
              Show less
            </div>
          </div>
        </div>
        <div *ngIf="toggle[i] && isCore">
          <div *ngFor="let coreSkill of skill">
            <div class="table">
              <div class="table-row course-details">
                <div class="table-cell course-duration cell-padding">
                  {{ coreSkill.Duration + " DAYS" }}
                </div>
                <div class="table-cell course-action row-padding"
                  *ngIf="(coreSkill.Status | lowercase) === 'not in lp'">
                  <yorbit-add-content [card]="constructInputForAddCourse(coreSkill, 'CoreSkill')"></yorbit-add-content>
                </div>
              </div>
            </div>
            <div class="table core-skill-border core-skill-border{{coreSkill.Expertise}}">
              <div class="table-row course-details">
                <div class="table-cell course-expertise cell-padding">
                  {{ coreSkill.Expertise }}
                </div>
                <div class="table-cell course-name cell-padding">
                  {{coreSkill.CourseName }}
                </div>
              </div>
              <div class="table-row course-details" *ngIf="(coreSkill.Status | lowercase) !== 'not in lp'">
                <div class="table-cell course-expertise cell-padding">
                  <!--Dummy cell-->
                </div>
                <div class="table-cell core-skill-status cell-padding">
                  {{coreSkill.Status}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="toggle[i] && !isCore">
          <div class="table">
            <div class="table-row course-details">
              <div class="table-cell course-name cell-padding">
                <div class="course-duration">
                  {{ row.Duration + " DAYS" }}
                </div>
                <!-- <div class="course-expertise expertise{{ row.Expertise }}">
                {{ row.Expertise }}
              </div>
              {{ row.CourseName }} -->
              </div>
              <div class="table-cell course-action row-padding" *ngIf="(row.Status | lowercase) === 'not in lp'">
                <yorbit-add-content [card]="constructInputForAddCourse(row, 'SupportSkill')"></yorbit-add-content>
              </div>
            </div>
          </div>
          <div class="table core-skill-border{{row.Expertise}}">
            <div class="table-row course-details">
              <div class="table-cell course-expertise cell-padding">
                {{ row.Expertise }}
              </div>
              <div class="table-cell course-name cell-padding">
                {{ row.CourseName }}
              </div>
            </div>
            <div class="table-row course-details" *ngIf="(row.Status | lowercase) !== 'not in lp'">
              <div class="table-cell course-expertise cell-padding">
                <!--dummy cell-->
              </div>
              <div class="table-cell core-skill-status cell-padding">
                {{row.Status}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="skill.length == 0" class="info-msg">
        No Skills found
      </div>
    </div>
  </ng-template>

  <!--Future skill Path-->
  <ng-template #skillListCrossSkills let-skill="skill" let-parent="parentIndex" let-isLoading="isLoading"
    let-isAdded="isAdded">
    <div class="skill-data-column" [ngStyle.lt-md]="{
        'border-left': '0',
        'border-right': '0',
        'overflow-y': 'initial'
      }">
      <div class="skill-data skill-data-pb">
        <div class="skill-list" *ngIf="!isLoading">
          <div *ngIf="skill.length > 0 && !isAdded">
            <div *ngFor="let row of skill; let i = index" class="skill-row" [ngClass]="{
                'skill-row-highlight': showMoreColumn_FutureNested[parent][i]
              }">
              <div class="table" *ngIf="
                  row.TargetSkill.CourseId !== null &&
                  row.TargetSkill.CourseId !== ''
                ">
                <div class="table-row">
                  <div class="table-cell skill-name">
                    {{ row.TargetSkill.SkillName }}
                  </div>
                  <div class="table-cell toggle-action" *ngIf="!showMoreColumn_FutureNested[parent][i]" (click)="
                      collapseOtherRowsInCol(
                        i,
                        showMoreColumn_FutureNested[parent]
                      )
                    ">
                    Show more
                  </div>
                  <div class="table-cell toggle-action" *ngIf="showMoreColumn_FutureNested[parent][i]"
                    (click)="showMoreColumn_FutureNested[parent][i] = false">
                    Show less
                  </div>
                </div>
              </div>
              <div *ngIf="showMoreColumn_FutureNested[parent][i]">
                <div>
                  <div class="table">
                    <div class="table-row course-details table-row-h">
                      <div class="table-cell skill-path-duration">
                        {{ row.TotalDuration + " Days" }}
                      </div>
                      <div class="table-cell course-action">
                        <button [disabled]="addingFutureSkillInProgress"
                          [ngClass]="{'disabled':addingFutureSkillInProgress}" *ngIf="!addingFutureSkill[i]" (click)="
                            addFutureSkillPathToLP(
                              row,
                              parent,
                              i,
                              'FutureSkill'
                            )
                          " title="Add To Learning Path">Add
                        </button>
                        <button [disabled]="true" class="disabled" *ngIf="addingFutureSkill[i]">Adding...</button>
                      </div>
                    </div>
                  </div>
                  <!--Transition Skill-->
                  <div class="spacing-btw-box" *ngFor="
                      let transSkill of row.TransitionSkills|orderBy:{'property': 'Duration','order':'ascending'};
                      let transSkillIndex = index
                    ">
                    <div class="table bg_variant{{ transSkill.Expertise }}">
                      <div class="table-row skill-details">
                        <div class="table-cell skill-name">
                          {{ transSkillIndex + 1 + ' ' + transSkill.SkillName }}
                        </div>
                        <div class="table-cell course-duration">
                          {{ transSkill.Duration + " Days" }}
                        </div>
                      </div>
                    </div>
                    <div class="table bg{{ transSkill.Expertise }}">
                      <div class="table-row skill-path-course">
                        <div class="table-cell course-expertise">
                          {{ transSkill.Expertise }}
                        </div>
                        <div class="table-cell course-name">
                          {{ transSkill.CourseName }}
                        </div>
                      </div>
                      <div class="table-row skill-path-course">
                        <div class="table-cell">
                          <!-- Spacer cell -->
                        </div>
                        <div class="table-cell course-status">
                          {{ transSkill.Status }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Target Skill-->
                  <div class="spacing-btw-box">
                    <div class="table bg_variant{{ row.TargetSkill.Expertise}}">
                      <div class="table-row skill-details">
                        <div class="table-cell skill-name">
                          {{
                          row.TransitionSkills.length +
                          1 +
                          ' ' +
                          row.TargetSkill.SkillName
                          }}
                        </div>
                        <div class="table-cell course-duration">
                          {{ row.TargetSkill.Duration + " Days" }}
                        </div>
                      </div>
                    </div>
                    <div class="table bg{{ row.TargetSkill.Expertise }}">
                      <div class="table-row skill-path-course">
                        <div class="table-cell course-expertise">
                          {{ row.TargetSkill.Expertise }}
                        </div>
                        <div class="table-cell course-name">
                          {{ row.TargetSkill.CourseName }}
                        </div>
                      </div>
                      <div class="table-row skill-path-course">
                        <div class="table-cell">
                          <!-- Spacer cell -->
                        </div>
                        <div class="table-cell course-status">
                          {{ row.TargetSkill.Status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="row.IsLoading">
                  <div class="table">
                    <div class="table-row course-details table-row-h">
                      <div class="table-cell skill-path-duration">
                        {{ 'Loading...' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isAdded" class="info-msg">
            You have added the skills to your learning path.
          </div>
          <div *ngIf="!isAdded && skill.length == 0" class="info-msg">
            No recommended courses found.
          </div>
        </div>
        <div *ngIf="isLoading">
          <app-preloader></app-preloader>
        </div>
      </div>
    </div>
    <div></div>
  </ng-template>
</div>