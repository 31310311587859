import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NasscomRecommendationsService {
  _baseURL: string;
  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
  }

  getNasscomRecommendations() {
    const url = this._baseURL + 'HomePage/NASSCOMRecommendations';
    return this._http.get<any>(url);
  }

  getFutureSkils() {
    const url = this._baseURL + 'HomePage/GetFutureSkills';
    return this._http.get<any>(url);
  }
}


