import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@YorbitWorkspace/global-environments';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseRequestService {
  _baseURL: string;
  constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
    this._baseURL = this._envSvc.getEnvironment().apiUrl;
  }

  getTaxonomy() {
    return this._http
      .get<any>(this._baseURL + 'Course/GetTaxonomy')
      .pipe(catchError((error: any) =>  throwError(()=> error.json())));
  }

  submitNewCourseRequest(payload) {
    return this._http
    .post(
      this._baseURL + 'Course/SetNewCourseRequest',
      payload
    )
    .pipe(catchError((error: any) => throwError(()=> error.json())));
}
}
