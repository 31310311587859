import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EnvironmentService, Ienvironment } from '@YorbitWorkspace/global-environments';

@Injectable()
export class BellNotificationService{
    config:Ienvironment;
constructor(
    private http:HttpClient,
    private _envService:EnvironmentService
){
this.config = this._envService.getEnvironment();
}

getBellNotificationsCount(mid) {
    return this.http.get(this.config.apiUrl + "Notification/GetUnseenCount?Mid=" + mid);
};
getOldNotificationsFromDB(mid, newData) {
    return this.http.post(this.config.apiUrl + "Notification/GetBellNotificationData?MId=" + mid + "&OldNotifications=" + newData, {});
}
getNewNotificationsFromDB(mid, newData) {
    return this.http.post(this.config.apiUrl + "Notification/GetBellNotificationData?MId=" + mid + "&OldNotifications=" + newData, {})
}
updateNotificationsActionedStatus(mid, messageId, category) {
    let isCommonNotification = false;
    if (category.toLowerCase() == "global") {
        isCommonNotification = true;
    }
    return this.http.post(this.config.apiUrl + "Notification/UpdateActioned?MId=" + mid + "&MessageId=" + messageId + "&CommonNotification=" + isCommonNotification, {})
}
}